import React, { useState } from 'react';
import { createAnalysis } from '../../../../../../../api/analysis';
import { createBulkOwning } from '../../../../../../../api/module1';
import useProjectData from '../../Hooks/useProjectData';
import { AllocationsReceiverExtraction } from '../../utils/allocationExtraction';
import convertToPairs from '../../utils/convertToPairs';
import extractionTextCode from '../../utils/extractionTextCode';
import extractOriginalLang from '../../utils/extractOriginalLang';

const useAnalysisOperations = (props) => {
  const {
    workloadId,
    filename,
    owning_id: owningId,
    allocation_id: allocationId,
    setMetaDAta,
    metaData,
    projectId,
    RecjectValue,
    userId,
    targetColumn,
    project,
    langCheck,
    languagesCode,
    selectedIds,
    onClose,
    setNotify,
  } = props;
  const [loading, setLoading] = useState(false);

  const { groups, users } = useProjectData({ project });

  function saveMetaData(newTableData, item) {
    const temp = newTableData;
    temp[item.row_id] = {
      ...temp[item.row_id],
      allocations: {
        ...temp[item.row_id].allocations,
        [item.allocationId]: {
          ...temp[item.row_id].allocations[item.allocationId],
          pbs: item.pbs,
          abs: item.abs,
          obs: item.obs,
          receiver: item.receiver,
        },
      },
    };
  }

  const handleAnalysis = async (body) => {
    const responsse = await createAnalysis(body);
    return responsse;
  };

  const EditMetaData = (response) => {
    const newTableData = {
      ...metaData,
    };

    const resObj = response[0];
    for (const ids of selectedIds) {
      if (resObj.id === ids.owning_id) {
        newTableData[ids.parentId] = {
          ...newTableData[ids.parentId],
          kpi_lifecycle: resObj.kpi_lifecycle,
          lifecycles: resObj.lifecycles,
          kpi_multiallocations: resObj.kpi_multiallocations,
          kpi_compliance: resObj.kpi_compliance,
          allocations: AllocationsReceiverExtraction(resObj.allocations, groups, users),
        };
      }
    }
    return newTableData;
  };

  const handleRejectAnalysis = async () => {
    setLoading(true);
    const body = [
      {
        project_id: projectId,
        owning_id: owningId,
        user_id: userId,
        workload_id: Number(workloadId),
        filename,
        allocation_id: allocationId,
        content: RecjectValue,
        action: 'reject',
      },
    ];
    try {
      const response = await createBulkOwning(body);

      const MetaDataRow = await EditMetaData(response);
      setLoading(false);
      return MetaDataRow;
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  const handleAnalysisSave = async (body) => {
    setLoading(true);
    try {
      const newTableData = {
        ...metaData,
      };
      const response = await handleAnalysis(body);
      const resObj = {} as any;
      for (const eachObj of response) {
        resObj[eachObj.id] = eachObj;
      }
      for (const ids of selectedIds) {
        if (resObj[ids.owning_id]) {
          newTableData[ids.parentId] = {
            ...newTableData[ids.parentId],
            kpi_lifecycle: resObj[ids.owning_id].kpi_lifecycle,
            lifecycles: resObj[ids.owning_id].lifecycles,
            kpi_multiallocations: resObj[ids.owning_id].kpi_multiallocations,
            kpi_compliance: resObj[ids.owning_id].kpi_compliance,
            allocations: AllocationsReceiverExtraction(
              resObj[ids.owning_id].allocations,
              groups,
              users
            ),
            analysis: resObj[ids.owning_id].analysis,
          };
        }
      }

      setMetaDAta(newTableData);
      setLoading(false);
      setNotify('Successfully Saved', 'success');
    } catch (error) {
      console.log(error);
      setLoading(false);
      setNotify('Error while analysis', 'error');
    }
  };

  const handleAnalysisSaveAll = async (body) => {
    setLoading(true);
    const newTableData = {
      ...metaData,
    };
    const response = await handleAnalysis(body);
    const resObj = {};
    for (const eachObj of response) {
      resObj[eachObj.id] = eachObj;
    }
    for (const ids of selectedIds) {
      if (resObj[ids.owning_id]) {
        newTableData[ids.parentId] = {
          ...newTableData[ids.parentId],
          kpi_lifecycle: resObj[ids.owning_id].kpi_lifecycle,
          lifecycles: resObj[ids.owning_id].lifecycles,
          kpi_multiallocations: resObj[ids.owning_id].kpi_multiallocations,
          kpi_compliance: resObj[ids.owning_id].kpi_compliance,
          allocations: AllocationsReceiverExtraction(
            resObj[ids.owning_id].allocations,
            groups,
            users
          ),
          analysis: resObj[ids.owning_id].analysis,
        };
      }
    }

    setMetaDAta(newTableData);
    setLoading(false);
    setNotify('Successfully Saved All', 'success');
    onClose();
  };

  const handleAllCompliant = async (payload) => {
    setLoading(true);
    const newTableData = {
      ...metaData,
    };

    const body = payload.map((eachItem) => {
      return {
        project_id: projectId,
        owning_id: eachItem.owning_id,
        workload_id: workloadId,
        filename,
        user_id: userId,
        compliance: 'compliant',
        rationale: [],
        comments: [],
        allocation_id: eachItem.allocationId,
        allocation: eachItem.allocation,
      };
    });
    const response = await handleAnalysis(body);
    const resObj = {};
    for (const eachObj of response) {
      resObj[eachObj.id] = eachObj;
    }

    for (const ids of payload) {
      if (resObj[ids.owning_id]) {
        newTableData[ids.parentId] = {
          ...newTableData[ids.parentId],
          kpi_lifecycle: resObj[ids.owning_id].kpi_lifecycle,
          lifecycles: resObj[ids.owning_id].lifecycles,
          kpi_multiallocations: resObj[ids.owning_id].kpi_multiallocations,
          kpi_compliance: resObj[ids.owning_id].kpi_compliance,
          allocations: AllocationsReceiverExtraction(
            resObj[ids.owning_id].allocations,
            groups,
            users
          ),
          analysis: resObj[ids.owning_id].analysis,
        };
      }
    }

    setMetaDAta(newTableData);
    setLoading(false);
    setNotify('All Compliant Successfully', 'success');
  };

  return {
    handleRejectAnalysis,
    handleAnalysisSave,
    handleAnalysisSaveAll,
    handleAllCompliant,
    loading,
  };
};

export default useAnalysisOperations;
