import React from 'react';

const MilestoneTabs = ({ tab, setTab }) => {
  const tabKey = {
    0: '0px',
    1: '75px',
    2: '150px',
    3: '225px',
    4: '300px',
    5: '375px',
    6: '450px',
  };
  return (
    <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          position: 'relative',
          width: 'max-content',
          backgroundColor: 'white',
          borderRadius: '30px',
          border: '1px solid #002A45',
        }}
      >
        <div
          style={{
            left: tabKey[tab],
            top: 0,
            position: 'absolute',
            width: '75px',
            height: '100%',
            background: '#002a45',
            borderRadius: '30px',
            transition: '0.5s',
          }}
        >
          {}
        </div>
        <button
          type="button"
          className="toggleBtn2"
          style={{
            cursor: 'pointer',
            width: '75px',
            fontSize: '0.9rem',
            fontWeight: 'bold',
            background: 'transparent',
            transition: 'color 0.5s',
            color: tab === 0 ? 'white' : 'black',
            border: 0,
            outline: 'none',
            position: 'relative',
            textAlign: 'center',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTab(0);
          }}
        >
          OTD
        </button>
        <button
          type="button"
          style={{
            cursor: 'pointer',
            width: '75px',
            fontSize: '0.9rem',
            fontWeight: 'bold',
            background: 'transparent',
            transition: 'color 0.5s',
            border: 0,
            color: tab === 1 ? 'white' : 'black',
            outline: 'none',
            position: 'relative',
            textAlign: 'center',
          }}
          onClick={() => {
            setTab(1);
          }}
        >
          EDP
        </button>
        <button
          type="button"
          style={{
            cursor: 'pointer',
            width: '75px',
            fontSize: '0.9rem',
            fontWeight: 'bold',
            background: 'transparent',
            transition: 'color 0.5s',
            border: 0,
            color: tab === 2 ? 'white' : 'black',
            outline: 'none',
            position: 'relative',
            textAlign: 'center',
          }}
          onClick={async () => {
            setTab(2);
          }}
        >
          Billing
        </button>
        <button
          type="button"
          style={{
            cursor: 'pointer',
            width: '75px',
            fontSize: '0.9rem',
            fontWeight: 'bold',
            background: 'transparent',
            transition: 'color 0.5s',
            border: 0,
            color: tab === 3 ? 'white' : 'black',
            outline: 'none',
            position: 'relative',
            textAlign: 'center',
          }}
          onClick={async () => {
            setTab(3);
          }}
        >
          DFQ
        </button>
        <button
          type="button"
          style={{
            cursor: 'pointer',
            width: '75px',
            fontSize: '0.9rem',
            fontWeight: 'bold',
            background: 'transparent',
            transition: 'color 0.5s',
            border: 0,
            color: tab === 4 ? 'white' : 'black',
            outline: 'none',
            position: 'relative',
            textAlign: 'center',
          }}
          onClick={async () => {
            setTab(4);
          }}
        >
          DOT
        </button>
        <button
          type="button"
          style={{
            cursor: 'pointer',
            width: '75px',
            fontSize: '0.9rem',
            fontWeight: 'bold',
            background: 'transparent',
            transition: 'color 0.5s',
            border: 0,
            color: tab === 5 ? 'white' : 'black',
            outline: 'none',
            position: 'relative',
            textAlign: 'center',
          }}
          onClick={async () => {
            setTab(5);
          }}
        >
          IOT
        </button>
        <button
          type="button"
          style={{
            cursor: 'pointer',
            width: '75px',
            fontSize: '0.9rem',
            fontWeight: 'bold',
            background: 'transparent',
            transition: 'color 0.5s',
            border: 0,
            color: tab === 6 ? 'white' : 'black',
            outline: 'none',
            position: 'relative',
            textAlign: 'center',
          }}
          onClick={async () => {
            setTab(6);
          }}
        >
          OTIF
        </button>
      </div>
    </div>
  );
};

export default MilestoneTabs;
