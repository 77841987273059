/* eslint-disable no-param-reassign */
import { Grid, makeStyles } from '@material-ui/core';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import Tree from 'rc-tree';
import React, { useState } from 'react';
import {
  isNodeRule,
  renderNodeChapter,
  renderNodeRule,
} from '../../Module3/components/RunStepper/StepRules';
import RuleDetails from './RuleDetails';

export const getChildRows = (item: any) => {
  if (item.children) {
    item.children = item.children.map((childItem: any) => {
      childItem.key = childItem.data.id;
      if (isNodeRule(childItem)) {
        childItem.title = renderNodeRule(childItem);
      } else {
        childItem.title = renderNodeChapter(childItem);
      }
      getChildRows(childItem);
      return childItem;
    });
  }
};

export const getTreeView = (rules: any) => {
  const myRules = rules?.map((rule: any) => {
    const myRule = rule;
    myRule.key = rule.data.id;
    if (isNodeRule(rule)) {
      myRule.title = renderNodeRule(rule);
    } else {
      myRule.title = renderNodeChapter(rule);
    }
    getChildRows(rule);
    return myRule;
  });
  return myRules;
};

export const useStyles = makeStyles((theme) => ({
  root: {
    background: ' #FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: ' 0px 8px 8px #00000029',
    border: '1px solid #002A45',
    borderRadius: '10px',
    opacity: 1,
  },
  tree: {
    '&.rc-tree .rc-tree-treenode': {
      minHeight: '38px',
      color: '#002A45',
    },
    '& .rc-tree-node-selected': {
      color: '#DC3223',
      fontWeight: 'bold',
      fontSize: 16,
      background: 'none',
    },
  },
  treeContainer: {
    paddingTop: '30px',
    overflowY: 'scroll',
    maxHeight: '70vh',
    zIndex: 400,
    position: 'static',
    backgroundColor: 'white',
    '&::-webkit-scrollbar': {
      width: '9px',
    },
    borderRadius: '10px',
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px #ccc',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#ccc',
      outline: '1px solid #708090',
      borderRadius: '3px',
    },
  },
}));

type Props = {
  rows?: any[];
  rows_tree?: any[];
  selected?: any[];
};

export default function RulesTreeContainer({ rows, rows_tree: treeData, selected }: Props) {
  const classes = useStyles();
  const [nodeSelection, setNodeSelection] = useState(undefined);

  const onSelect = (selectedKeys: any) => {
    // console.log('selectedKeys', selectedKeys);
    // eslint-disable-next-line no-param-reassign
    if (selectedKeys.length > 0) {
      const nodeFind = rows.find((rule: any) => selectedKeys.includes(rule.id));
      setNodeSelection(nodeFind);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="stretch"
      className={classes.root}
    >
      <Grid item lg={4} sm={4} md={4} className={classes.treeContainer}>
        <Tree
          // showLine
          treeData={treeData}
          showIcon={true}
          switcherIcon={(props) => {
            const { expanded, isLeaf } = props;

            if (isLeaf) return <ChevronRight style={{ visibility: 'hidden' }} />;
            return expanded ? <ChevronRight /> : <ExpandMore />;
          }}
          autoExpandParent={true}
          defaultExpandAll={true}
          defaultCheckedKeys={selected}
          onSelect={onSelect}
          // onExpand={this.onExpand}
          className={classes.tree}
        />
      </Grid>
      <Grid item lg={8} sm={8} md={8}>
        <RuleDetails data={nodeSelection} />
      </Grid>
    </Grid>
  );
}
