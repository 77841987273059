import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import FullScreenToogle from './FullScreenToogle';
import { BACKEND_URL } from '../../../../../../const';

const RenderPdfFile = ({ urlFile, Text, coordinates, propStyle }) => {
  const iframeRef = useRef(null);
  const [isFullScreen, setFullScreen] = useState(false);

  const sendMessageToIframe = () => {
    const highlightContent = {
      content: {
        text: Text,
      },
      position: {
        boundingRect: {
          x1: coordinates.x1,
          x2: coordinates.x2,
          y1: coordinates.y1,
          y2: coordinates.y2,
          width: coordinates.width,
          height: coordinates.height,
        },
        rects: [
          {
            x1: coordinates.x1,
            x2: coordinates.x2,
            y1: coordinates.y1,
            y2: coordinates.y2,
            width: coordinates.width,
            height: coordinates.height,
          },
        ],
        pageNumber: coordinates.page,
      },
      comment: {
        text: '',
        emoji: '',
      },
    };
    iframeRef.current.contentWindow.postMessage(highlightContent, BACKEND_URL.iframePdfHost);
    return null;
  };

  useEffect(() => {
    if (iframeRef.current) {
      const handleIframeLoad = () => {
        sendMessageToIframe();
        return null;
      };
      iframeRef?.current?.addEventListener('load', handleIframeLoad);

      return () => {
        iframeRef?.current?.removeEventListener('load', handleIframeLoad);
      };
    }
    return null;
  }, [iframeRef]);

  useMemo(() => {
    if (iframeRef.current) {
      const handleIframeLoad = () => {
        sendMessageToIframe();
        return null;
      };
      handleIframeLoad();
    }
  }, [urlFile, Text, coordinates]);

  return (
    <>
      <div
        style={
          isFullScreen
            ? {
                bottom: 0,
                height: '100vh',
                left: 0,
                margin: 0,
                maxHeight: '100vh',
                maxWidth: '100vw',
                padding: 0,
                position: 'fixed',
                right: 0,
                top: 0,
                width: '100vw',
                zIndex: 1300,
                backgroundColor: 'white',
              }
            : propStyle
        }
      >
        <iframe
          // name="pdf_iframe"
          ref={iframeRef}
          src={`${BACKEND_URL.iframePdfHost}?url=${encodeURI(urlFile || '')}`}
          title="fff"
          height="100%"
          width="100%"
        />
        <div
          style={
            isFullScreen
              ? { position: 'absolute', top: '10px', right: '20px' }
              : { position: 'absolute', top: '5px', right: '40px' }
          }
        >
          <FullScreenToogle isFullScreen={isFullScreen} onChangeFullScreen={setFullScreen} />
        </div>
      </div>
    </>
  );
};

export default RenderPdfFile;
