import React from 'react';
// import REXAnalysis from '../../../HOC/REXAnalysis';
import TextAnalysisMode from '../../../HOC/TextAnalysisMode';

const withModuleTextAnalyis = (WrappedComponent) => {
  // TextAnalsis operation states
  return (props) => {
    return <WrappedComponent {...props} />;
  };
};

const ModuleTextAnaylsis = withModuleTextAnalyis(TextAnalysisMode);

export default ModuleTextAnaylsis;
