import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Modal,
} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import FolderIcon from '@mui/icons-material/Folder';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { folderCreate } from '../../../api/folders';
import { getModalStyle, useStyles } from '../../../components/Core/Modal';
import { Project } from '../../../react-app-env';
import ProjectContext from '../ProjectContext';

type Props = {
  project_id: any;
  open: boolean;
  onClose: any;
};
function ModalFolderCreate({ project_id, open, onClose }: Props) {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [modalStyle] = React.useState(getModalStyle);
  const { projects, setProjects } = useContext(ProjectContext);
  const [name, setName] = React.useState<string | undefined>(undefined);

  const handleClose = () => {
    onClose();
    setName('');
    setError(false);
    setHelperText('');
  };

  const validetFolderName = (name: string): boolean => {
    if (project_id === projects[0]?.id) {
      setHelperText('');
      setError(false);
      return true;
    }
    const existingNames = projects?.folders?.map((f) =>
      String(f.name).toLowerCase().trim()
    ) as string[];
    if (existingNames?.includes(name.toLowerCase())) {
      setError(true);
      setHelperText(`Folder with name ${name} already exists`);
      return false;
    }
    setHelperText('');
    setError(false);
    return true;
  };

  const handleSubmit = async () => {
    if (name === undefined || String(name).length === 0) {
      setHelperText('Folder name is empty');
      setError(true);
      return;
    }

    // validate name
    if (!validetFolderName(name)) {
      return;
    }

    folderCreate({
      name,
      project_id,
    })
      .then((f: any) => {
        /**
         * merge folders for project id
         */
        // console.log(f);
        const p = projects.map((_p) => {
          if (_p.id === project_id)
            return {
              ..._p,
              folders: [...(_p.folders || []), f],
            };
          return _p;
        });
        // console.log('projects', p);
        setProjects(p);
        handleClose();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const InfoProjectName = ({ anchor, onClose }) => {
    const open = Boolean(anchor);
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        popover: {
          pointerEvents: 'none',
        },
        paper: {
          padding: theme.spacing(1),
        },
      })
    );
    const classes = useStyles();

    return (
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClose}
        disableRestoreFocus
      >
        <Typography>
          <li>Folder name can be 4-20 characters long</li>
          <li>Only characters [A-Z, a-z, 0-9, -, _] are allowed</li>
          <li>Folder name must start with a letter</li>
        </Typography>
      </Popover>
    );
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setTimeout(() => setAnchorEl(null), 1000);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Container>
        <div style={modalStyle} className={classes.paper}>
          <CancelIcon
            fontSize="small"
            style={{
              position: 'absolute',
              left: '95%',
            }}
            onClick={handleClose}
          />
          <FolderIcon fontSize="large" style={{ marginTop: 10 }} />
          <Grid>
            <h3 style={{ display: 'inline-flex', marginTop: 10 }}>
              <div>
                {/* <img src={logoIcon} alt="isens" style={{ width: 40, height: 40 }} /> */}
              </div>
              {/* Add a folder to project {project.name} */}
              Add a folder
              <InfoIcon
                style={{ marginLeft: 8 }}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              />
              <InfoProjectName anchor={anchorEl} onClose={handlePopoverClose} />
            </h3>
          </Grid>
          <FormControl component="fieldset" error={error}>
            <Grid container direction="column" justify="center" alignItems="center">
              <Grid item className={classes.input}>
                <Input
                  placeholder="Enter a folder name"
                  inputProps={{ 'aria-label': 'project-name' }}
                  onChange={handleChangeName}
                />
              </Grid>
              <Grid item className={clsx(classes.input)}>
                <center>
                  <FormHelperText
                    className={clsx(classes.error)}
                    style={{ textAlign: 'center', fontSize: '15px' }}
                  >
                    {helperText}
                  </FormHelperText>
                </center>
              </Grid>
            </Grid>
          </FormControl>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={8}>
              <Button
                className={classes.button}
                onClick={handleSubmit}
                style={{
                  color: 'white',
                  backgroundColor: '#DC3223',
                  width: '200px',
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Modal>
  );
}

export default ModalFolderCreate;
