import React, { useState } from 'react';
import { deleteAssignOwning, saveOwning, saveOwning2 } from '../../../../../../api/ownings';
import convertToPairs from '../utils/convertToPairs';
import extractionTextCode from '../utils/extractionTextCode';
import extractOriginalLang from '../utils/extractOriginalLang';
import {
  handelNewCaptureWithFile,
  handelNewCaptureWithoutFile,
  handleRepeatMergeData,
  handleSplitSubmit,
  handleTextAlter,
} from '../Api/metadataApis';
import { AllocationsReceiverExtraction } from '../utils/allocationExtraction';

function useMetaDataOperation(props: {
  targetColumn: any;
  groups: any;
  users: any;
  langCheck: any;
  languagesCode: any;
  projectId: any;
  workloadId: any;
  onChangeTableData: any;
  transCode: any;
  i18n: any;
  languages: any;
  setNotify: any;
  filename: any;
  rows?: any;
  refreshDATA?: any;
}) {
  const {
    targetColumn,
    groups,
    users,
    langCheck,
    languagesCode,
    projectId,
    workloadId,
    onChangeTableData,
    transCode,
    i18n,
    languages,
    setNotify,
    filename,
    rows: metaData,
    refreshDATA,
  } = props;
  const [loading, setLoading] = useState(false);

  const handelFuntions = (rowsSelection: any, row_types: any, Str: any, msg: any) => {
    const ids = rowsSelection.map((data) => Number(data));
    setLoading(true);

    handleTextAlter(
      ids,
      Str,
      targetColumn,
      groups,
      users,
      langCheck,
      languagesCode,
      projectId,
      workloadId,
      row_types,
      i18n,
      transCode
    )
      .then((extractedData) => {
        onChangeTableData(extractedData);
        setLoading(false);
        setNotify(msg, 'success');
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleConcat = (rowsSelection: any, row_types: any) => {
    handelFuntions(rowsSelection, row_types, ' ', 'Concatenated successfully!');
  };

  const handleMerge = (rowsSelection: any, row_types: any) => {
    handelFuntions(rowsSelection, row_types, '\n', 'Merged successfully!');
  };

  const handleSplit = async (formBody: any, rowsSelection: any, row_types: any) => {
    try {
      setLoading(true);
      const ids = rowsSelection.map((data) => Number(data))[0];
      const extractedData = await handleSplitSubmit(
        formBody,
        ids,
        targetColumn,
        groups,
        users,
        langCheck,
        languagesCode,
        projectId,
        workloadId,
        row_types,
        i18n,
        transCode
      );
      onChangeTableData(extractedData);
      setNotify('Splited successfully', 'success');
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRepeatHeader = async (rowsSelection: any, row_types: any) => {
    try {
      setLoading(true);
      const ids = rowsSelection.map((data) => Number(data));
      const extractedData = await handleRepeatMergeData(
        ids,
        targetColumn,
        '\n',
        groups,
        users,
        langCheck,
        languagesCode,
        projectId,
        workloadId,
        row_types,
        i18n,
        transCode
      );
      onChangeTableData(extractedData);
      setLoading(false);
      setNotify('Repeat header successfully!', 'success');
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteRow = (
    rowsSelection: any,
    row_types: any,
    rows: any,
    rowsDeleted: any[],
    filename: any
  ) => {
    try {
      setLoading(true);
      return deleteAssignOwning(rowsDeleted, projectId, Number(workloadId), filename)
        .then(() => {
          // const { OriginalLang } = extractOriginalLang(i18n, langCheck, languagesCode);
          const filteredrow = rows.filter((data) => {
            return !rowsSelection.includes(data.id);
          });
          // const extractedDAta = extractionTextCode(
          //   filteredrow,
          //   targetColumn,
          //   OriginalLang,
          //   transCode,
          //   groups,
          //   users
          // );
          setNotify('Deleted successfully', 'success');
          setLoading(false);
          return convertToPairs(filteredrow);
        })
        .catch((reason) => {
          console.error(reason);
        })
        .finally(() => {});
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const handelEditedRow = async (rows: any, newRow: any, id: any) => {
    const temp = Object.values(newRow);
    const isTargetColumn = targetColumn ?? 'Text';

    const payload = temp.map((data: any) => {
      return {
        ...data,
        [isTargetColumn]: data.i18n[transCode],
        allocations: Object.values(data.allocations),
      };
    });

    const { OriginalLang } = extractOriginalLang(i18n, langCheck, languagesCode);

    const language_codes = transCode === '-' ? OriginalLang : transCode;
    const response = await saveOwning(
      payload,
      'assignment',
      projectId,
      Number(workloadId),
      language_codes,
      targetColumn || 'Text',
      filename
    );

    if (response) {
      const key = Object.keys(newRow);
      const id = key[0];
      const owning_id = temp[0].id;

      const res = response.find((data) => {
        if (Number(data.id) === Number(owning_id)) {
          return true;
        }
        return false;
      });

      let tempObj = {};

      if (res) {
        const { i18n } = res || {};
        tempObj = {
          ...newRow[id],
          i18n,
        };
      } else {
        tempObj = {
          ...rows,
        };
      }

      const retsult = res ? { ...rows, [id]: { ...tempObj } } : { ...tempObj };
      return retsult;
    }

    return [];
  };

  const handelNewCapture = async (
    formBody: any,
    file: { file: any },
    rows: any,
    row_types: any,
    id: number
  ) => {
    if (file.file !== undefined && formBody.subtype === 'Image') {
      const extractedData = await handelNewCaptureWithFile(
        formBody,
        file,
        transCode,
        targetColumn,
        i18n,
        langCheck,
        languagesCode,
        id,
        groups,
        users,
        row_types
      );

      setNotify('Row added successfully', 'success');

      return extractedData;
    }

    const extractedData = await handelNewCaptureWithoutFile(
      formBody,
      file,
      transCode,
      targetColumn,
      i18n,
      langCheck,
      languagesCode,
      id,
      groups,
      users,
      row_types,
      languages
    );

    setNotify('Row added successfully', 'success');

    return extractedData;
  };

  async function handleSaveAlldata(editedRow, type) {
    setLoading(true);
    const newMetaData = {
      ...metaData,
    };
    const values = Object.values(editedRow);

    const { OriginalLang } = extractOriginalLang(i18n, langCheck, languagesCode);

    const language_codes = transCode === '-' ? OriginalLang : transCode;
    const response = await saveOwning2(
      values,
      type,
      projectId,
      Number(workloadId),
      filename,
      language_codes
    );
    const refreshResponse = await refreshDATA();
    setLoading(false);

    // const result = await extractRefreshData(refreshResponse);

    // console.log('result', result);

    // .then(
    //   (res) => {
    //     if (res) {
    //       for (const item of values) {
    //         newMetaData[item.row_id] = item;
    //       }
    //       onChangeTableData(newMetaData);
    //     }
    //   }
    // );
  }

  return {
    loading,
    handleConcat,
    handleMerge,
    handleSplit,
    handleRepeatHeader,
    handleDeleteRow,
    handelEditedRow,
    handelNewCapture,
    handleSaveAlldata,
  };
}

export default useMetaDataOperation;
