import React, { useEffect, useState } from 'react';
import getFrontendVersion from '../api/FrontendVersion';

const useFrontendVersionControl = () => {
  const [isValidLatestVersion, setIsValidLatestVersion] = useState(false);

  useEffect(() => {
    const checkVersion = async () => {
      const response = await getFrontendVersion();
      const storedVersion = localStorage.getItem('latestAppVersion');

      //   console.log('response', response);
      //   console.log('storedVersion', storedVersion);

      let isValid = false;
      if (storedVersion && response === storedVersion) {
        isValid = true;
      } else {
        const token = null;
        localStorage.setItem('latestAppVersion', response);
        // localStorage.setItem('appJWT', token);
        // localStorage.setItem('azJWT', token);
        localStorage.removeItem('appJWT');
        localStorage.removeItem('azJWT');
      }

      //   console.log(isValid);
      setIsValidLatestVersion(!isValid);
    };

    checkVersion();
  }, []);

  return { isValidLatestVersion };
};

export default useFrontendVersionControl;
