import React, { useContext } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import useProjectId from '../../app/useProjectId';
import ProjectContext from '../Project/ProjectContext';
import Module1Context, { Module1ContextProvider } from './Module1Context';
import RunContainer from './RunContainer';
import Run1Context, { Run1ContextProvider } from './RunContext';
import PageLayout from '../../layouts/PageLayout';
import Loading from '../../components/Core/Loading';

function Module1Router() {
  const { projectId, moduleroute } = useParams() as any;
  const { projects } = useContext(ProjectContext);

  const { project } = useProjectId(projectId, projects);

  if (project === undefined) return <Loading />;

  return (
    <Module1ContextProvider project={project}>
      <Module1Context.Consumer>
        {(): any => {
          return (
            <Switch>
              <Route
                exact
                path="/projects/:projectId/module1/:moduleroute/new"
                render={() => {
                  return (
                    <>
                      <Redirect to={`/projects/${projectId}/module1/${moduleroute}/run`} />
                    </>
                  );
                }}
              />
              <PageLayout
                exact
                path="/projects/:projectId/module1/:moduleroute/run"
                render={() => {
                  return (
                    <>
                      <Run1ContextProvider resources={[]}>
                        <Run1Context.Consumer>{() => <RunContainer />}</Run1Context.Consumer>
                      </Run1ContextProvider>
                    </>
                  );
                }}
              />
              <PageLayout
                exact
                path="/projects/:projectId/module1/:moduleroute/results/:workloadId"
                render={() => {
                  return (
                    <>
                      <Run1ContextProvider resources={[]}>
                        <Run1Context.Consumer>
                          {() => <RunContainer step={3} />}
                        </Run1Context.Consumer>
                      </Run1ContextProvider>
                    </>
                  );
                }}
              />
            </Switch>
          );
        }}
      </Module1Context.Consumer>
    </Module1ContextProvider>
  );
}

export default Module1Router;
