/**
 * @param urlHttp
 */

function downloadFileFromUrl(urlHttp: string, filename: string): any {
  const URL = process.env.REACT_APP_BACKEND_URL;
  const link = document.createElement('a');
  link.href = `${URL}${urlHttp}?filename=${encodeURI(filename)}`;

  document.body.appendChild(link);
  link.click();
}

export default downloadFileFromUrl;
