import React, { DragEventHandler } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { getCommonTooltipProps } from '../Styles/utils';
import { parseFromValuesOrFunc } from '../utils';

export const GrabHandleButton = ({ location, table, ...rest }: any) => {
  const {
    options: {
      icons: { DragHandleIcon },
      localization,
    },
  } = table;

  return (
    <Tooltip {...getCommonTooltipProps('top')} title={rest?.title ?? localization.move}>
      <IconButton
        aria-label={rest.title ?? localization.move}
        disableRipple
        draggable="true"
        size="small"
        {...rest}
        onClick={(e) => {
          e.stopPropagation();
          rest?.onClick?.(e);
        }}
        sx={(theme) => ({
          '&:active': {
            cursor: 'grabbing',
          },
          '&:hover': {
            backgroundColor: 'transparent',
            opacity: 1,
          },
          cursor: 'grab',
          m: '0 -0.1rem',
          opacity: location === 'row' ? 1 : 0.5,
          p: '2px',
          transition: 'all 150ms ease-in-out',
          ...(parseFromValuesOrFunc(rest?.sx, theme) as any),
        })}
        title={undefined}
      >
        <DragHandleIcon color="info" />
      </IconButton>
    </Tooltip>
  );
};

export default GrabHandleButton;
