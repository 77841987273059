import { Box, Chip, Grid } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import React from 'react';
import checkCompliance from './checkCompliance';
import { extractUserName } from '../../utils/extractdata';

const ComplianceMulti = ({ renderedCellValue, user_id, receiver }) => {
  const { label, color } = checkCompliance(renderedCellValue) || ('' as any);

  let labelStar = null;
  const extractReceiver = Number(extractUserName(receiver, 'id'));
  //   console.log('userid', extractReceiver, 'ori', user_id);
  if (Number(extractReceiver) === Number(user_id)) {
    labelStar = (
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {label}
        <StarIcon fontSize="small" />
      </Box>
    );
  }

  return (
    <>
      <Chip
        label={labelStar ?? label}
        title={label}
        style={{ backgroundColor: color, color: 'white', width: '150px' }}
      />
    </>
  );
};

export default ComplianceMulti;
