import React from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { IconButton } from '@mui/material';

function FullScreenToogle(props: any) {
  const { isFullScreen, onChangeFullScreen } = props;
  return (
    <>
      <IconButton
        onClick={() => {
          onChangeFullScreen((prev) => !prev);
        }}
        color="info"
      >
        {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </IconButton>
    </>
  );
}

export default FullScreenToogle;
