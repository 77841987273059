/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// import { Button } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import WorkSpaceContext from '../../../../WorkspceContext';
import DocAnalysisContext from '../../DocAnalysisContext';
// import WorkSpaceContext from '../../WorkspceContext';

const Container = styled.div`
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const TopBarMenu = styled.div`
  position: relative;
  border-bottom: 1px solid #d1d9ef;
`;

const Button = styled.button`
  padding: 0.4rem;
  border: none;
  background: transparent;
  font-size: 1.2em;
  width: 5rem;
  cursor: pointer;
`;

const Line = styled.div`
  width: 5rem;
  position: absolute;
  bottom: 0;
  height: 0.25rem;
  border-radius: 5px;
  background-color: #002a45;
  transition: 0.5s;
`;

type Props = {
  children: any;
};

const TabContainer = ({ children }: Props) => {
  const { toggleTab, setToggleTab } = useContext(DocAnalysisContext);
  const handChangeInTab = (e) => {
    e.stopPropagation();
    switch (e.target.name) {
      case 'Files':
        // setShowProcessFile(false);
        setToggleTab((prev) => {
          return {
            ...prev,
            left: '0rem',
          };
        });
        break;

      case 'Settings':
        setToggleTab((prev) => {
          return {
            ...prev,
            left: '5rem',
          };
        });
        break;
      case 'Activities':
        // setShowProcessFile(false);
        setToggleTab((prev) => {
          return {
            ...prev,
            left: '10rem',
          };
        });
        break;
      case 'Result':
        // setShowProcessFile(false);
        setToggleTab((prev) => {
          return {
            ...prev,
            left: '15rem',
          };
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Container>
        <TopBarMenu>
          <Button type="button" name="Files" onClick={handChangeInTab}>
            Files
          </Button>
          <Button type="button" name="Settings" onClick={handChangeInTab}>
            Settings
          </Button>
          <Button type="button" name="Activities" onClick={handChangeInTab}>
            Activities
          </Button>
          <Button type="button" name="Result" onClick={handChangeInTab}>
            Result
          </Button>
          <Line
            onClick={(e) => e.stopPropagation()}
            style={{
              left: toggleTab?.left,
            }}
          >
            {}
          </Line>
        </TopBarMenu>
        {children}
      </Container>
    </>
  );
};

export default TabContainer;
