/* eslint-disable react/button-has-type */
import { Box, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import TranslateIcon from '@mui/icons-material/Translate';
import { getAllProjectCreationLanguages } from '../../../../api/projects';
import FileTranslate from './FileTranslator';
import TranslationNew from './TextTranslation';
import './Translator.css';
import WorkSpaceFileTranslator from './WorkSpaceFileTranslator';
import FileTranslator from '../../../../components/DocumentTranslator/FileTranslator';
import WorkSpaceContext from '../../WorkspceContext';
import useAppToken from '../../../../app/useAppToken';

const mode = {
  Text_Translator: { left: '0%' },
  Document_Translator: { left: '50%' },
};

const Translator = () => {
  const [tab, setTab] = useState('Text_Translator');
  const [languageCode, setLanguageCode] = useState([]);
  const { files, setFiles, glossaryFiles, setGlossaryFiles, setSnack } = useContext(
    WorkSpaceContext
  );
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;
  const modeTab = { key: 'user', id: user_id };
  // const [files, setFiles] = useState([]);

  useEffect(() => {
    getAllProjectCreationLanguages().then((dbProjects) => {
      const db = dbProjects.map((item) => {
        return `${item.language}-${item.codes}`;
      });
      setLanguageCode(db);
    });
  }, []);

  return (
    <Box className="Translator-home">
      <div className="Translator-home-button-Box">
        <div style={mode[tab]} className="Translator-home-btn">
          {}
        </div>
        <button
          type="button"
          className="Translator-home-toggleBtn"
          style={mode[tab]?.left === '0%' ? { color: 'white' } : {}}
          onClick={() => {
            setTab('Text_Translator');
          }}
        >
          <TranslateIcon />
          Text Translate
        </button>
        <button
          type="button"
          className="Translator-home-toggleBtn"
          style={mode[tab]?.left === '50%' ? { color: 'white' } : {}}
          onClick={() => {
            setTab('Document_Translator');
          }}
        >
          <DocumentScannerIcon />
          Document Translate
        </button>
      </div>
      {tab === 'Text_Translator' && <TranslationNew languageCode={languageCode} home="Home" />}
      {tab === 'Document_Translator' && (
        // <WorkSpaceFileTranslator
        //   languageCode={languageCode}
        //   // files={files}
        //   // setFiles={setFiles}
        //   home="Home"
        // />
        <FileTranslator
          setSnack={setSnack}
          mode={modeTab}
          languageCode={languageCode}
          files={files}
          setFiles={setFiles}
          glossaryFiles={glossaryFiles}
          setGlossaryFiles={setGlossaryFiles}
        />
      )}
    </Box>
  );
};

export default Translator;
