import { BreakdownStructure, Category, Model } from 'GroupType';
import { BACKEND_URL } from '../const';
import axios from './axios';

// save selection by category
export const importBreakdownCustomRowsProject = async (
  body: BreakdownStructure[],
  project_id: number,
  category: Category
): Promise<any> => {
  try {
    const querystring = `project_id=${project_id}&category=${category}`;
    const response = await axios.put(`${BACKEND_URL.root}/breakdowns?${querystring}`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export type PayloadGroups = {
  SBS: Model[];
  RBS: Model[];
  PBS: Model[];
  ABS: Model[];
  OBS: Model[];
};
// export type GroupType2 = Group2.Category; // test

export const getAllImportedBreakdown = async (
  projectId: number,
  dis_nonpm: string | undefined = undefined
): Promise<PayloadGroups> => {
  try {
    const response = await axios.get(
      `${BACKEND_URL.root}/breakdowns?project_id=${projectId}&dis_nonpm=${dis_nonpm}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

type PayloadImport = Model[];

export const importGroups = async (
  projectId: number,
  formdata: any,
  category: Category
): Promise<PayloadImport> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/breakdowns/import?project_id=${projectId}&category=${category}`,
      formdata,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const getCaptureName = async (projectId: number) => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/projects/capture_name/${projectId}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export default importBreakdownCustomRowsProject;
