import { Chip, Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { number } from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getTimseries, KpiInput } from '../../api/analysis';
import {
  getAssignRequirement,
  getAssignRequirementAllocation,
  getAssignRequirementLifeCycle,
  getProjectManagement,
} from '../../api/assign';

import { getComplianceKPIs, getLifecycleKPIs } from '../../api/kpi';
import { isensTheme } from '../../app/isensTheme';
import {
  ALLOCATION_COLORS,
  ALLOCATION_LABELS,
  ASSIGN_LIFECYCLE_COLORS,
  ASSIGN_LIFECYCLE_LABELS,
  COMPLIANCE_COLORS,
  COMPLIANCE_LABELS,
  LIFECYCLE_COLORS,
  LIFECYCLE_LABELS,
} from '../../const';
import { searchUsers } from '../../api/users';
import useAppToken from '../../app/useAppToken';
import Module1Context from '../Module1/Module1Context';

const COLORS = [
  '#1e3246',
  '#dc3223',
  '#19aa6e',
  '#4b5a69',
  '#788291',
  '#d2d7dc',
  '#eb827d',
  '#fad7d2',
  '#d2f0e1',
  '#73cdaa',
  '#9b875f',
  '#afa082',
  '#ebe6dc',
  '#c3b9a0',
  '#C3DDF6',
  '#468AD1',
  '#F8A600',
  '#2f475f',
  '#95372f',
  '#7a231b',
  '#94a2b8',
  '#5b8f75',
  '#546c60',
  '#36443d',
  '#748c80',
  '#4fa7ff',
  '#174471',
  '#384a5c',
  '#acacad',
  '#bdbdbd',
];

type Props = {
  project_id: number;
  user_id?: number;
  title: string;
  onClose?: any;
  workload_id?: number;
  file_name?: string;
};

const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text
//       x={x}
//       y={y}
//       fill="white"
//       textAnchor={x > cx ? 'start' : 'end'}
//       dominantBaseline="central"
//       fontSize="16px"
//     >
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };
const renderCustomizedLabel = (
  { cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }: any,
  full = false
) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const displayLabel = () => {
    if (full) return <>{percent !== 0 ? `${`${name} - ${(percent * 100).toFixed(0)}%`}` : ''}</>;
    return <>{percent !== 0 ? `${`${(percent * 100).toFixed(3)}%`}` : ''}</>;
  };
  return (
    <text
      transform={`translate(${x},${y}) rotate(320)`} // 330
      fill="black"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize="9px"
      fontStretch="collapsed"
      fontFamily="sans-serif, Helvetica Neue, Lucida Grande, Arial"
    >
      {/* {`${(percent * 100).toFixed(0)}%`} */}
      {/* to remove 0% from graph display */}
      {percent !== 0 ? `${`${name.substring(0, 5)} ${(percent * 100).toFixed(3)}%`}` : ''}
      {/* {percent !== 0 ? `${`${(percent * 100).toFixed(3)}%`}` : ''} */}
      {/* by David {displayLabel()} */}
    </text>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '20px',
    border: `1px solid`,
    borderColor: theme.palette.primary.main,
    opacity: 1,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  select: {
    '& > *': { fontWeight: '60 !important', fontSize: theme.typography.body1 },
  },
  formControl: {
    backgroundColor: '#FFFFFF',
    width: '35%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  customTooltip: {
    background: 'rgba(0, 0, 0, 0.75)',
    borderRadius: '2px',
    color: 'white',
    padding: '5px',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
    pointerEvents: 'none',
    position: 'relative',
    transform: 'translate(-50%, -100%)',
    whiteSpace: 'nowrap',
  },
  label: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
}));

export default function AssignKpiContainer({
  project_id,
  workload_id,
  user_id,
  file_name,
  title,
  onClose,
}: Props) {
  const classes = useStyles();
  const { projectId } = useParams() as any;
  const [activeIndex1, setActivate1] = useState(0);
  const [pieDataCompliance, setPieDataCompliance] = useState([] as any);
  const [pieDataLifecycle, setPieDataLifecycle] = useState([] as any);
  const [timeseriesCompliance, setTimeseriesCompliance] = useState([] as any[]);
  const [timeseriesLifecycle, setTimeseriesLifecycle] = useState([] as any[]);
  const [barGraphStandards, setBarGraphStandards] = useState<any>([]);
  const [projectManagement, setProjectManagement] = useState<any>([]);
  const [barGraphStandardsChart, setBarGraphStandardsChart] = useState<any>([]);
  const [projectLeaqder, setProjectLeaqder] = useState<any>([]);
  const [pieDataLifecycleAllocation, setPieDataLifecycleAllocation] = useState<any>([]);
  const [obsValue, setObsValue] = React.useState('obs');
  const [kpiUser, setKpiUser] = useState<undefined>(undefined);
  const [projectUsers, setProjectUsers] = useState<any[]>([]);
  const { appJWT }: { appJWT: AppJwtType } = useAppToken();
  const { setSnack, project } = useContext(Module1Context);

  const onPieEnter1 = (_, index) => {
    setActivate1(index);
  };

  const CustomTooltip = (props) => {
    const { payload, cx, cy } = props;
    if (payload && payload.length) {
      return (
        <div className={classes.customTooltip} style={{ top: cy, left: cx }}>
          <p className={classes.label}>{`${payload[0].name}: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`PV ${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  useEffect(() => {
    searchUsers({ project_id: projectId }).then((dbusers: any[]) => {
      const myusers = dbusers.map((userdt) => {
        return {
          ...userdt.user,
          role_id: userdt.user_project.role_id,
        };
      });
      setProjectUsers(myusers);
    });
  }, []);

  useEffect(() => {
    const kpiInput = { project_id } as KpiInput;
    if (workload_id) kpiInput.workload_id = workload_id;
    if (file_name) kpiInput.file_name = file_name;
    if (kpiUser !== undefined) kpiInput.user_id = kpiUser;
    setProjectLeaqder([]);
    setProjectManagement([]);
    getProjectManagement(project_id, workload_id, obsValue, kpiUser).then((resp) => {
      setProjectManagement(resp);
      const leadercount = [];
      for (const objs of resp) {
        let values = 0;
        let name = '';
        const payload = {};
        for (const val of Object.values(objs)) {
          if (Number.isInteger(val)) {
            values += val;
          } else {
            name = val;
          }
        }
        payload.name = name;
        payload.value = values;
        leadercount.push(payload);
      }
      setProjectLeaqder(leadercount);
    });
    getAssignRequirementLifeCycle(project_id, workload_id, kpiUser).then((AssignData) => {
      const requirement = [];
      for (const objs of AssignData) {
        const resp = {};
        resp.name = objs.name;
        resp[objs.name] = objs.value;

        requirement.push(resp);
      }
      setBarGraphStandards(requirement);
      const AssignDataWithoutOwned = AssignData.filter((user) => user.name !== 'owned');
      setBarGraphStandardsChart(AssignDataWithoutOwned);
      // setBarGraphStandardsChart(AssignData);
    });
    const usersArray: { entity: any }[] = [];
    const usersArrayValue: { entity: any }[] = [];
    getAssignRequirementAllocation(project_id, workload_id).then((AssignData) => {
      AssignData.forEach((item: { id: any; allocation: any; count: any }) => {
        return usersArray.push({
          name: item.allocation,
          value: item.count,
        });
      });
      let counts = 0;
      let names = '';
      for (const user of usersArray) {
        const resp = {};
        if (user?.name && user.name.includes('_')) {
          counts += user.value;
          names = user.name;
        } else {
          resp.name = user.name;
          resp.value = user.value;
          usersArrayValue.push(resp);
        }
      }
      const resp = {};
      resp.name = names;
      resp.value = counts;
      usersArrayValue.push(resp);
      setPieDataLifecycleAllocation(usersArrayValue);
      setPieDataLifecycle(usersArray);
    });
    // for compliance
    getComplianceKPIs(kpiInput).then((output) => {
      setPieDataCompliance(output);
    });
  }, [project_id, workload_id, obsValue, kpiUser]);

  const handleKpiUsers = (e) => {
    if (e.target.value === 'all') {
      setKpiUser(undefined);
      return;
    }
    setKpiUser(e.target.value);
  };
  const handleChange = (event: SelectChangeEvent) => {
    setObsValue(event.target.value);
  };
  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        style={{
          marginTop: 4,
          marginBottom: 6,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        // className={clsx(classes.bloc)}
      >
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="label-select-document">Users</InputLabel>
          <Select
            label="Users"
            name="users"
            onChange={handleKpiUsers}
            defaultValue="all"
            className={classes.select}
          >
            <MenuItem key="all-documents" value="all" className={classes.select}>
              <Typography>All users</Typography>
            </MenuItem>
            {projectUsers &&
              projectUsers.map((cap: KpiId, index: number) => {
                return (
                  <MenuItem key={`user-${index}`} value={cap?.id} className={classes.select}>
                    <Typography>{cap?.name}</Typography>
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        {project?.entity === 'RSC' && (
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={obsValue}
              onChange={handleChange}
            >
              <MenuItem value="obs">OBS</MenuItem>
              <MenuItem value="abs">ABS</MenuItem>
            </Select>
          </FormControl>
        )}
      </Grid>
      {project?.entity === 'RSC' ? (
        <>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={0}
            className={classes.root}
          >
            {' '}
            {/* <FormControl sx={{ m: 1, minWidth: 120 }} style={{ marginLeft: 1200 }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={obsValue}
                onChange={handleChange}
              >
                <MenuItem value="obs">OBS</MenuItem>
                <MenuItem value="abs">ABS</MenuItem>
              </Select>
            </FormControl> */}
            <Grid item lg={12} sm={12} md={12}>
              <Typography variant="subtitle1">Requirements allocation status</Typography>
              {projectManagement.length > 0 && (
                <Grid lg={12} sm={12} md={12}>
                  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '100%', height: 350 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          // width={1200}
                          // height={350}
                          data={projectManagement}
                          margin={{
                            top: 5,
                            right: 10,
                            left: 15,
                            bottom: 8,
                          }}
                        >
                          <CartesianGrid strokeDasharray="1 1" />
                          <XAxis
                            dataKey="leader"
                            textAnchor="end"
                            tickFormatter={(value) =>
                              value.length > 10 ? `${value.slice(0, 15)}...` : value
                            }
                          />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          {/* {barGraphStandards.map((entry, index) => (
                  ))} */}
                          <Bar dataKey="characterized" fill="rgb(120, 130, 145)" />
                          <Bar dataKey="assigned" fill=" rgb(25, 170, 110)" />
                          {/* <Bar dataKey="owned" fill="rgb(0, 42, 69)" /> */}
                          <Bar dataKey="analyzed" fill="rgb(175, 160, 130)" />
                          <Bar dataKey="need-clarification" fill="rgb(3, 86, 138)" />
                          <Bar dataKey="recaptured" fill="rgb(255, 102, 51)" />
                          <Bar dataKey="rejected" fill="rgb(225, 90, 80)" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </Box>
                  <div style={{ textAlign: 'center' }}>
                    {projectLeaqder.map((d, index) => (
                      <Chip
                        label={`${d.name} (${d.value})`}
                        color="primary"
                        style={{
                          backgroundColor: ASSIGN_LIFECYCLE_COLORS[d.name],
                          marginRight: '3px',
                          marginTop: '5px',
                        }}
                        key={`chip-${index}`}
                      />
                    ))}
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={0}
          className={classes.root}
        >
          {' '}
          <FormControl sx={{ m: 1, minWidth: 120 }} style={{ marginLeft: 1200 }} size="small">
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={obsValue}
              onChange={handleChange}
            >
              <MenuItem value="obs">OBS</MenuItem>
              <MenuItem value="pbs">PBS</MenuItem>
              <MenuItem value="abs">ABS</MenuItem>
            </Select>
          </FormControl>
          <Grid item lg={12} sm={12} md={12}>
            <Typography variant="subtitle1">Requirements allocation status</Typography>
            {projectManagement.length > 0 && (
              <Grid lg={12} sm={12} md={12}>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ width: '100%', height: 350 }}>
                    {' '}
                    {/* Fixed height */}
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        // width={1500}
                        // height={350}
                        data={projectManagement}
                        margin={{
                          top: 5,
                          right: 10,
                          left: 15,
                          bottom: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis
                          dataKey="leader"
                          // angle={-25}
                          textAnchor="end"
                          tickFormatter={(value) =>
                            value.length > 10 ? `${value.slice(0, 15)}...` : value
                          }
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {/* {barGraphStandards.map((entry, index) => (
            ))} */}
                        <Bar dataKey="characterized" fill="rgb(120, 130, 145)" />
                        <Bar dataKey="assigned" fill=" rgb(25, 170, 110)" />
                        {/* <Bar dataKey="owned" fill="rgb(0, 42, 69)" /> */}
                        <Bar dataKey="analyzed" fill="rgb(175, 160, 130)" />
                        <Bar dataKey="need-clarification" fill="rgb(3, 86, 138)" />
                        <Bar dataKey="recaptured" fill="rgb(255, 102, 51)" />
                        <Bar dataKey="rejected" fill="rgb(225, 90, 80)" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Box>
                <div style={{ textAlign: 'center' }}>
                  {projectLeaqder.map((d, index) => (
                    <Chip
                      label={`${d.name} (${d.value})`}
                      color="primary"
                      style={{
                        backgroundColor: ASSIGN_LIFECYCLE_COLORS[d.name],
                        marginRight: '3px',
                        marginTop: '5px',
                      }}
                      key={`chip-${index}`}
                    />
                  ))}
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        spacing={0}
        className={classes.root}
      >
        <Grid item lg={12} sm={12} md={12}>
          <Typography variant="subtitle1">Requirements lifecycle</Typography>
          {barGraphStandards.length > 0 && (
            <>
              <Grid container>
                <Grid xs={9}>
                  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <BarChart
                      width={800}
                      height={300}
                      data={barGraphStandards}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="2 2" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      {/* {barGraphStandards.map((entry, index) => (
        ))} */}
                      <Bar dataKey="characterized" barSize={40} fill="rgb(120, 130, 145)" />
                      <Bar dataKey="assigned" barSize={40} fill=" rgb(25, 170, 110)" />
                      {/* <Bar dataKey="owned" barSize={40} fill="rgb(0, 42, 69)" /> */}
                      <Bar dataKey="analyzed" barSize={40} fill="rgb(175, 160, 130)" />
                      <Bar dataKey="need-clarification" barSize={40} fill="rgb(3, 86, 138)" />
                      <Bar dataKey="recaptured" barSize={40} fill="rgb(255, 102, 51)" />
                      <Bar dataKey="rejected" barSize={40} fill="rgb(225, 90, 80)" />
                    </BarChart>
                  </Box>
                </Grid>
                <Grid xs={3}>
                  <div style={{ textAlign: 'center', marginTop: '100px' }}>
                    {barGraphStandardsChart.map((d, index) => (
                      <Chip
                        label={`${ASSIGN_LIFECYCLE_LABELS[d.name]} (${d.value})`}
                        color="primary"
                        style={{
                          backgroundColor: ASSIGN_LIFECYCLE_COLORS[d.name],
                          marginRight: '3px',
                          marginTop: '5px',
                        }}
                        key={`chip-${index}`}
                      />
                    ))}
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        spacing={0}
        className={classes.root}
      >
        <Grid item lg={12} sm={12} md={12}>
          <Typography variant="subtitle1">Requirements compliance</Typography>
          <Grid container>
            <Grid xs={9}>
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <PieChart width={600} height={400}>
                  <Pie
                    isAnimationActive={true}
                    activeIndex={activeIndex1}
                    data={pieDataCompliance}
                    activeShape={renderActiveShape}
                    // cy="center"
                    cx="50%"
                    cy="50%"
                    legendType="circle"
                    // labelLine={false}
                    // label={renderCustomizedLabel}
                    // outerRadius={140}
                    innerRadius={90}
                    outerRadius={120}
                    fill="#8884d8"
                    dataKey="value"
                    onMouseEnter={onPieEnter1}
                  >
                    {pieDataCompliance.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COMPLIANCE_COLORS[entry.name]} />
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltip />} />
                </PieChart>
              </Box>
            </Grid>
            <Grid xs={3}>
              <div style={{ textAlign: 'center', marginTop: '100px' }}>
                {pieDataCompliance.map((d: any, index) => (
                  <Chip
                    label={`${COMPLIANCE_LABELS[d.name]} (${d.value})`}
                    color="primary"
                    style={{
                      backgroundColor: COMPLIANCE_COLORS[String(d.name)],
                      marginRight: '3px',
                      marginTop: '5px',
                    }}
                    key={`chip-${index}`}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item lg={12} sm={12} md={12}>
          <Typography variant="subtitle1">Requirements Allocation</Typography>
          <Grid container>
            <Grid item xs={10}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PieChart width={500} height={500}>
                  <Pie
                    isAnimationActive={true}
                    data={pieDataLifecycle}
                    // cx={510}
                    cy="center"
                    outerRadius={150}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    fill="#8884d8"
                    dataKey="value"
                    legendType="circle"
                    paddingAngle={1}
                    minAngle={5}
                  >
                    {pieDataLifecycle.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </Box>
            </Grid>
            <Grid xs={2}>
              <div style={{ textAlign: 'center', marginTop: '100px', marginLeft: '-200px' }}>
                {pieDataLifecycleAllocation.map((d, index) => (
                  <Chip
                    label={`${ALLOCATION_LABELS[d.name]} (${d.value})`}
                    color="primary"
                    style={{
                      backgroundColor: ALLOCATION_COLORS[d.name],
                      marginRight: '3px',
                      marginTop: '5px',
                    }}
                    key={`chip-${index}`}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </>
  );
}
