import { any } from 'prop-types';
import React, { useReducer, useState } from 'react';
// import {
//   excelInitialState,
//   filesreducers,
//   initialStateFiles,
// } from './Reducers/combinerReducersDoc';
// import excelFilereducers from './Reducers/excelFilereducer';

export interface RexAnalysisContextType {
  operation: any;
  setOperation: any;
  scopeSetting: any;
  setScopeSetting: any;
  selection: any;
  setSelection: any;
  fileSetting: any;
  workLoad: any;
  setWorkLoad: any;
  setFileSettings: any;
  toggleTab: any;
  setToggleTab: any;
}

const RexAnalysisContext = React.createContext<RexAnalysisContextType>({
  operation: any,
  setOperation: any,
  scopeSetting: any,
  setScopeSetting: any,
  selection: any,
  setSelection: any,
  fileSetting: any,
  workLoad: any,
  setWorkLoad: any,
  setFileSettings: any,
  toggleTab: {} as any,
  setToggleTab: (): any => {},
});

type Props = {
  children: any;
};

const RexAnalysisContextProvider = ({ children }: Props) => {
  const [operation, setOperation] = useState(0);
  const [scopeSetting, setScopeSetting] = useState([]);
  const [toggleTab, setToggleTab] = useState({
    left: '0rem',
  });
  const [selection, setSelection] = useState([]);
  const [fileSetting, setFileSettings] = useState({});
  const [workLoad, setWorkLoad] = useState(0);
  //   const [containerSerive, setContainerService] = useState({});
  //   const [state, dispatch] = useReducer(excelFilereducers, excelInitialState);

  return (
    <RexAnalysisContext.Provider
      value={{
        operation,
        setOperation,
        scopeSetting,
        setScopeSetting,
        selection,
        setSelection,
        fileSetting,
        setFileSettings,
        toggleTab,
        setToggleTab,
        workLoad,
        setWorkLoad,
      }}
    >
      {children}
    </RexAnalysisContext.Provider>
  );
};

export default RexAnalysisContext;

export { RexAnalysisContextProvider };
