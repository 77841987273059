const supportedfiles = [
  'xlsx',
  'csv',
  'pdf',
  'docx',
  'pptx',
  'ppt',
  'htm',
  'msg',
  'xls',
  'xlf',
  'html',
  'markdown',
  'mdown',
  'mkdn',
  'md',
  'mkd',
  'mdwn',
  'mdtxt',
  'mdtext',
  'rmd',
  'mthml',
  'mht',
  'doc',
  'odt',
  'odp',
  'ods',
  'rtf',
  'tsv',
  'tab',
  'txt',
];

export default supportedfiles;
