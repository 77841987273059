/* eslint-disable react/button-has-type */
import { Box, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import TranslateIcon from '@mui/icons-material/Translate';
// import FileTranslator from './FileTranslator';
import './Css/Schedule.css';
import ScheduleAnalysis from './ScheduleAnalysis';
import TabContainer from './Core/CustomerRFP/TabContainer';

const mode = {
  Customer_RFP: { left: '0%' },
  Schedule_Analysis: { left: '50%' },
};
const SupportedFiles = [
  'xlsx',
  'csv',
  'pdf',
  'docx',
  'pptx',
  'ppt',
  'htm',
  'msg',
  'xls',
  'xlf',
  'html',
  'markdown',
  'mdown',
  'mkdn',
  'md',
  'mkd',
  'mdwn',
  'mdtxt',
  'mdtext',
  'rmd',
  'mthml',
  'mht',
  'doc',
  'odt',
  'odp',
  'ods',
  'rtf',
  'tsv',
  'tab',
  'txt',
];
const ScheduleMain = () => {
  const [tab, setTab] = useState('Customer_RFP');
  const queryParams = window.location.pathname;
  const getLastPath = queryParams.split('/').pop();
  const history = useHistory();
  const [languageCode, setLanguageCode] = useState([]);
  const [files, setFiles] = useState([]);
  const { projectId, moduleroute } = useParams() as any;
  const [glossaryFiles, setGlossaryFiles] = useState([]);

  useEffect(() => {
    if (getLastPath === 'Schedule_Analysis') {
      setTab('Schedule_Analysis');
    }
  }, [getLastPath]);
  return (
    <Box className="Translator-home">
      <div className="Translator-home-button-Box">
        <div style={mode[tab]} className="Translator-home-btn">
          {}
        </div>
        <button
          type="button"
          className="Translator-home-toggleBtn"
          style={mode[tab]?.left === '0%' ? { color: 'white' } : {}}
          onClick={() => {
            setTab('Customer_RFP');
            history.push(`/projects/${projectId}/module6/m6/Customer_RFP/document`);
          }}
        >
          {/* <TranslateIcon /> */}
          Customer RFP
        </button>
        <button
          type="button"
          className="Translator-home-toggleBtn"
          style={mode[tab]?.left === '50%' ? { color: 'white' } : {}}
          onClick={() => {
            setTab('Schedule_Analysis');
            history.push(`/projects/${projectId}/module6/m6/Schedule_Analysis`);
          }}
        >
          {/* <DocumentScannerIcon /> */}
          Schedule Analysis
        </button>
      </div>

      {tab === 'Customer_RFP' && <TabContainer />}
      {tab === 'Schedule_Analysis' && <ScheduleAnalysis />}
    </Box>
  );
};

export default ScheduleMain;
