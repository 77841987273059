import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import React from 'react';

const ExpandContainer = ({ isChatExpandable, setRexChat, children }: any) => {
  return (
    <>
      <div
        style={
          isChatExpandable
            ? {
                bottom: 0,
                height: '100vh',
                left: 0,
                margin: 0,
                maxHeight: '100vh',
                maxWidth: '100vw',
                padding: 0,
                position: 'fixed',
                right: 0,
                top: 0,
                width: '100vw',
                zIndex: 1299,
                backgroundColor: 'white',
                // position: 'relative',
              }
            : {}
        }
      >
        <Button
          style={{ color: '#ffffff', position: 'absolute', right: 0, zIndex: 1299 }}
          type="button"
          aria-label="search"
          onClick={() => {
            setRexChat(false);
          }}
        >
          <FullscreenExitIcon />
        </Button>
        {children}
      </div>
    </>
  );
};

export default ExpandContainer;
