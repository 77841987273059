/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Dialog, DialogTitle, Grid, IconButton, makeStyles, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import useAppToken from '../../../app/useAppToken';
import Loading from '../../../components/Core/Loading';
import TableComponent from '../../../components/Datagrid/TableComponent';
import { rowComponent } from '../../../components/Datagrid/TStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    backgroundColor: theme.palette.action.hover,
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
  textField: {
    margin: '15px 5px',
    paddingRight: 10,
  },
  radio: {
    textAlign: 'center',
  },
  label: {
    marginRight: '30px',
  },
}));

type Props = {
  rows: any[];
  template?: {
    name: string;
    version: string;
  };
  onClose?: any;
};
// const columns = handleColumns(['id', 'function', 'mapping', 'example', 'created_at', 'updated_at']);

export default function ModalAdminUser({ rows, template, onClose }: Props) {
  const classes = useStyles();

  const [loading, setLoading] = useState<any>(false);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const { appJWT } = useAppToken() as any;
  const token: any = appJWT.user_id;

  const renderFunction = ({ value }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <>
        <b>{value}</b>
      </>
    );
  };

  const handleClose = () => {
    onClose();
  };
  const usersArray = [];
  rows.forEach((item: { name: any; role_id: any }) => {
    usersArray.push({
      name: item.name,
      role_id:
        item.role_id === 2
          ? 'Project Leader'
          : item.role_id === 3
          ? 'Performer'
          : item.role_id === 1
          ? 'Project Administrator'
          : null,
    });
  });
  return (
    <Paper>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          Projects && Role : {template?.version}
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {loading ? (
          <Loading />
        ) : (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={0}
            style={{ margin: 0 }}
          >
            <Grid item lg={12} sm={6} md={12}>
              {rows.length > 0 && (
                <Paper style={{ padding: 10 }}>
                  <ExpressGrid
                    rows={usersArray}
                    columns={[
                      { name: 'name', title: 'Project' },
                      { name: 'role_id', title: 'Role' },
                      // { name: 'projects_details', title: 'Projects' },
                    ]}
                  >
                    <FilteringState />
                    <IntegratedFiltering />
                    <SortingState defaultSorting={[{ columnName: 'id', direction: 'desc' }]} />
                    <IntegratedSorting />
                    <PagingState defaultCurrentPage={0} defaultPageSize={10} />
                    <IntegratedPaging />
                    <Table tableComponent={TableComponent} />
                    <TableHeaderRow showSortingControls />
                    <TableFilterRow rowComponent={rowComponent} />
                    <Toolbar />
                    <PagingPanel pageSizes={pageSizes} />
                  </ExpressGrid>
                </Paper>
              )}
            </Grid>
          </Grid>
        )}
      </Dialog>
    </Paper>
  );
}
