const stylesRequiremt = {
  muiTablePaperProps: {
    elevation: 0,
    sx: {
      borderRadius: '0',
    },
  },
  muiTopToolbarProps: {
    sx: {
      bgcolor: '#EEF1F9',
    },
  },

  muiBottomToolbarProps: {
    sx: {
      bgcolor: '#EEF1F9',
    },
  },
  muiTableHeadCellProps: {
    // simple styling with the `sx` prop, works just like a style prop in this example
    sx: {
      fontWeight: 'normal',
      fontFamily: 'AlstomRegular',
      fontSize: '14px',
      display: 'flex',
      padding: '0rem',
      paddingBottom: '0rem',
      paddingTop: '0rem',
      color: 'white',
      '& .Mui-TableHeadCell-Content': {
        paddingLeft: '5px',
        backgroundColor: '#002A45',
        color: 'white',
        // maxHeight: '5rem',
        flexBasis: '50%',

        '& span .MuiButtonBase-root .MuiTableSortLabel-root': {
          color: 'white',
        },
      },
      '& .MuiCollapse-root': {
        // padding: '0',
        flexBasis: '50%',
        border: 0,
        backgroundColor: 'white',
        color: 'white',
        width: '100%',
        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
  muiSelectAllCheckboxProps: {
    color: 'success',
    sx: {
      color: 'white',
      display: 'flex',
    },
  },
  muiSelectCheckboxProps: {
    color: 'secondary',
  },
  muiColumnActionsButtonProps: {
    sx: {
      color: 'white',
    },
  },
  muiColumnDragHandleProps: {
    sx: {
      color: 'white',
    },
  },

  muiFilterTextFieldProps: {
    sx: {
      color: 'white',
      // backgroundColor: 'white',
      width: '100%',
      padding: '0.2rem',
      marginBottom: '0.78rem',
    },
  },

  muiExpandAllButtonProps: {
    sx: {
      color: 'white',
    },
  },

  muiExpandButtonProps: {
    sx: {
      color: '#002A45',
    },
  },
  displayColumnDefOptions: {
    'mrt-row-expand': {
      enableResizing: true,
      size: 60,
    },
    'mrt-row-actions': { size: 60 },
  },
  muiTableBodyRowProps: ({ row }) => {
    return {
      // conditionally style expanded rows
      sx: {
        // fontWeight: row.getIsExpanded() ? 'bold' : 'normal',
        backgroundColor: row.subRows.length > 0 ? '#EEF1F9' : 'white',
      },
    };
  },
};

export default stylesRequiremt;
