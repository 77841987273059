import { BACKEND_URL } from '../const';
import axios from './axios';

// export interface KpiInput {
//   project_id: Number;
//   workload_id?: Number;
//   user_id?: Number;
//   file_name?: string;
//   file_id?: Number;
// }

// const createAnalysis = async (body: AnalysisData[]): Promise<any[]> => {
//   try {
//     const response = await axios.put(`${BACKEND_URL.root}/analysis`, body);
//     return response.data as any;
//   } catch (error) {
//     if (error.response) {
//       return Promise.reject(error.response.data);
//     }
//     return Promise.reject(error);
//   }
// };
const getAssignRequirementLifeCycle = async (
  ProjectId: number,
  WorkloadId: number,
  user_id?: any
): Promise<any> => {
  try {
    let querystring = `project_id=${ProjectId}&workload_id=${WorkloadId}`;
    if (user_id) querystring = `${querystring}&user_id=${user_id}`;
    const response = await axios.get(
      // eslint-disable-next-line no-self-assign
      // eslint-disable-next-line no-param-reassign
      `${BACKEND_URL.root}/kpi/lifecycle?${querystring}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const getAssignRequirementAllocation = async (
  ProjectId: number,
  WorkloadId: number
): Promise<any> => {
  try {
    const response = await axios.get(
      // eslint-disable-next-line no-self-assign
      // eslint-disable-next-line no-param-reassign
      `${BACKEND_URL.root}/kpi/allocation?project_id=${ProjectId}&workload_id=${WorkloadId}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getAlocationHistory = async (
  projectId: number,
  workloadId: number,
  allocationId: number
): Promise<any> => {
  try {
    const response = await axios.get(
      `${BACKEND_URL.root}/ownings/assign_history?project_id=${projectId}&workload_id=${workloadId}&owning_id=${allocationId}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getProjectManagement = async (
  ProjectId: number,
  WorkloadId: number,
  obsValue?: string,
  user_id?: any
): Promise<any> => {
  try {
    let querystring = `project_id=${ProjectId}&workload_id=${WorkloadId}`;
    if (obsValue) querystring = `${querystring}&obsValue=${obsValue}`;
    if (user_id) querystring = `${querystring}&user_id=${user_id}`;
    const response = await axios.get(
      // eslint-disable-next-line no-self-assign
      // eslint-disable-next-line no-param-reassign
      `${BACKEND_URL.root}/kpi/allifecycle?${querystring}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// Capture KPI
const getCaptureKPI = async (
  ProjectId: number,
  WorkloadId: number,
  file_name: any
): Promise<any> => {
  try {
    const response = await axios.get(
      // eslint-disable-next-line no-self-assign
      // eslint-disable-next-line no-param-reassign
      `${BACKEND_URL.root}/kpi/subtype?project_id=${ProjectId}&workload_id=${WorkloadId}&filename=${file_name}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getChararcterizerFlowKpi = async (ProjectId: number, WorkloadId: number): Promise<any> => {
  try {
    const response = await axios.get(
      // eslint-disable-next-line no-self-assign
      // eslint-disable-next-line no-param-reassign
      `${BACKEND_URL.root}/kpi/characterization?project_id=${ProjectId}&workload_id=${WorkloadId}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
export {
  getAssignRequirementLifeCycle,
  getAssignRequirementAllocation,
  getAlocationHistory,
  getProjectManagement,
  getCaptureKPI,
  getChararcterizerFlowKpi,
};
