/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Theme } from '@mui/system';
import OrganizationChart from '@dabeng/react-orgchart';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'; // Example icon import
import { Button, Typography } from '@material-ui/core';
import { getOrgAwareness } from '../../../../../api/users';
import Loading from '../../../../../components/Core/Loading';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    backgroundColor: '#002a45',
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  containerOrgAwareness: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #002a45',
    borderRadius: '8px',
    margin: '8px',
    height: '100%',
    width: '100%',
  },
  goBackButtonOrgAwareness: {
    margin: '8px',
  },
  headerOrgAwareness: {
    color: '#ffffff',
    fontWeight: 'bold',
    backgroundColor: '#002a45',
    width: '100%',
    borderRadius: '8px 8px 0 0',
    textAlign: 'center',
  },
  customNodeOrgAwareness: {
    padding: '10px',
    // border: '1px solid #ccc',
    // borderRadius: '8px',
    textAlign: 'center',
  },
  //   tooltip: {
  //     backgroundColor: '#002a45',
  //     color: '#ffffff',
  //     padding: '10px',
  //     borderRadius: '8px',
  //     maxWidth: '600px',
  //     width: '600px',
  //     textAlign: 'left',
  //   },
  tooltipRowOrgAwareness: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '10px',
  },
  keyOrgAwareness: {
    fontWeight: 'bold',
    fontSize: '18px',
    flexBasis: '30%',
  },
  valueOrgAwareness: {
    textAlign: 'left',
    color: '#658DA7',
    fontSize: '18px',
    flexBasis: '70%',
  },
}));

const DefaultChart = () => {
  const classes = useStyles();
  const history = useHistory();
  const [ds, setDs] = useState({});
  const [loading, setLoading] = useState(false);

  const formatData = (data: { name: any; title: any; children: any[] }) => {
    const formattedText = data.Purpose?.split('\n').map((line, index) => <p key={index}>{line}</p>);

    return {
      name: data.name || 'unknown',
      title: data.title || 'No Title',
      position: data.Position_Category || 'N/A',
      tooltip: {
        location: data.Location || 'N/A',
        medals: data.Kudos_and_Medals || 0,
        purpose: formattedText || 'No Purpose',
        diversity: `${(data.Diversity_percentage_of_females * 100).toFixed(2)}%` || 'N/A',
        peopleSkills: data.People_skills || 'N/A',
      },
      children: data.children ? data.children.map(formatData) : [],
    };
  };

  async function fetchData() {
    const response = await getOrgAwareness();
    setLoading(false);
    // Formatting the response data
    const formattedData = formatData(response);
    setDs(formattedData?.children[0]);
  }

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const handleGoBack = () => {
    history.goBack();
  };

  // Custom node renderer
  const CustomNode = ({ nodeData }) => {
    return (
      <CustomWidthTooltip
        title={
          <div>
            <div className={classes.tooltipRowOrgAwareness}>
              <span className={classes.keyOrgAwareness}>Location{'    :'}</span>
              <span className={classes.valueOrgAwareness}>{nodeData?.tooltip?.location}</span>
            </div>
            <div className={classes.tooltipRowOrgAwareness}>
              <span className={classes.keyOrgAwareness}>
                <WorkspacePremiumIcon
                  style={{ verticalAlign: 'middle', marginRight: '4px', color: 'yellow' }}
                />
                {'    :'}
              </span>
              <span className={classes.valueOrgAwareness}>{nodeData?.tooltip?.medals}</span>
            </div>
            <div className={classes.tooltipRowOrgAwareness}>
              <span className={classes.keyOrgAwareness}>Purpose{'    :'}</span>
              <span className={classes.valueOrgAwareness}>{nodeData?.tooltip?.purpose}</span>
            </div>
            <div className={classes.tooltipRowOrgAwareness}>
              <span className={classes.keyOrgAwareness}>Diversity % of Females{'    :'}</span>
              <span className={classes.valueOrgAwareness}>{nodeData?.tooltip?.diversity}</span>
            </div>
            <div className={classes.tooltipRowOrgAwareness}>
              <span className={classes.keyOrgAwareness}>People Skills{'    :'}</span>
              <span className={classes.valueOrgAwareness}>{nodeData?.tooltip?.peopleSkills}</span>
            </div>
          </div>
        }
        arrow
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              backgroundColor: '#002a45', // Set tooltip background color
            },
            '& .MuiTooltip-arrow': {
              color: '#002a45', // Ensures the arrow matches the background
              boxShadow: 0, // Remove shadow from the arrow, if necessary
            },
          },
        }}
      >
        <div className={classes.customNodeOrgAwareness}>
          <Typography style={{ fontWeight: 'bolder', fontSize: '20px' }}>
            {nodeData?.position || 'N/A'}
          </Typography>
          <Typography style={{ fontWeight: '500', fontSize: '16px' }}>
            {nodeData?.name || 'N/A'}
          </Typography>
          <Typography style={{ fontWeight: '300', fontSize: '14px' }}>
            {nodeData?.title || 'N/A'}
          </Typography>
        </div>
      </CustomWidthTooltip>
    );
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoBack}
        className={classes.goBackButtonOrgAwareness}
      >
        Go Back
      </Button>
      <div className={classes.containerOrgAwareness}>
        <Typography variant="h5" className={classes.headerOrgAwareness}>
          Oraganization awareness
        </Typography>
        {loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <OrganizationChart datasource={ds} pan={true} NodeTemplate={CustomNode} />
        )}
      </div>
    </>
  );
};

export default DefaultChart;
