/* eslint-disable no-continue */
/* eslint-disable no-loop-func */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import React, { memo, useCallback, useContext, useEffect } from 'react';
import {
  chatGPTApiPost,
  chatGPTApiPostPrivate,
  chatGPTApiPostRex,
  chatGPTApiPut,
  chatGPTApiPutPrivate,
  chatGPTApiPutRex,
} from '../../../../api/chat_gpt';

import useAppToken from '../../../../app/useAppToken';
import AlertSnack from '../../../../components/Alert/AlertSnack';
import M2GlobalContext from '../../../ContextMager/M2GlobalContext';
import Module2Context from '../../Module2Context';
import { ChatContext } from './ChatContext';
import { parseResponseAzureOpenAI, parseResponseGpt3 } from './tools/parseApiResponse';

const extractLayer = (subLayer) => {
  return subLayer.map((element) => element.label);
};

const InputPrompt = ({ init }: { init?: string }) => {
  const { state, dispatch } = useContext(ChatContext);
  const { project } = useContext(Module2Context);
  // const { state: stateSixlayer } = useContext(M2GlobalContext);
  const { appJWT } = useAppToken();

  const { state: rexState, dispatch: rexDispatch } = useContext(M2GlobalContext);
  const { similarityFilter } = rexState;
  // console.log('similarityFilter : ', similarityFilter);

  function openArray(arr) {
    const r = arr?.map((obj) => obj.value);
    return r.join(',');
  }
  const passedData = {
    Product_Line:
      similarityFilter?.dropDown1?.length !== 0 ? openArray(similarityFilter?.dropDown1) : null, // confirm
    Platform:
      similarityFilter?.dropDown2?.length !== 0 ? openArray(similarityFilter?.dropDown2) : null, // confirm
    Solution:
      similarityFilter?.dropDown5?.length !== 0 ? openArray(similarityFilter?.dropDown5) : null, // confirm
    Product:
      similarityFilter?.dropDown6?.length !== 0 ? openArray(similarityFilter?.dropDown6) : null, // confirm
    Project:
      similarityFilter?.dropDown3?.length !== 0 ? openArray(similarityFilter?.dropDown3) : null, // confirm
    Document_Profile:
      similarityFilter?.dropDown4?.length !== 0 ? openArray(similarityFilter?.dropDown4) : null, // confirm
  };

  function removeEmpty(passedData: any) {
    const newData = Object.fromEntries(
      Object.entries(passedData).filter(([_, value]) => value != null)
    );
    return newData;
  }
  function makeformattedString(passedData: any) {
    const formattedString = Object.keys(passedData)
      .map((key) => `${key} eq '${passedData[key]}'`)
      .join(' and ');
    return formattedString;
  }

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: 'SET_ERROR',
      payload: '',
    });
  };

  // const buildSixLayer = useMemo(() => {
  //   return {
  //     sixlayer: {
  //       product_lines: extractLayer(stateSixlayer.similarityFilter.dropDown1),
  //       platforms: extractLayer(stateSixlayer.similarityFilter.dropDown2),
  //       solutions: extractLayer(stateSixlayer.similarityFilter.dropDown5),
  //       products: extractLayer(stateSixlayer.similarityFilter.dropDown6),
  //       project_names: extractLayer(stateSixlayer.similarityFilter.dropDown3),
  //     },
  //   };
  // }, [stateSixlayer.similarityFilter]);

  const resetPrompt = () => {
    try {
      if (document.getElementById('chat-textbox')) {
        document.getElementById('chat-textbox').value = '';
        document.getElementById('chat-textbox').focus();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleSubmitPrompt = () => {
    const r = removeEmpty(passedData);
    const sixlayerFilter = makeformattedString(r);
    // console.log(sixlayerFilter);

    const promptText = document.getElementById('chat-textbox').value.trim();
    // validation text  not empty
    if (promptText.length === 0) {
      dispatch({
        type: 'SET_ERROR',
        payload: 'Please enter a valid text',
      });
      return;
    }
    resetPrompt();
    try {
      const abortControllerPost = new AbortController();
      const abortController = new AbortController();
      dispatch({
        type: 'ABORTCONTROLLER_ADD',
        payload: abortControllerPost,
      });
      dispatch({
        type: 'ABORTCONTROLLER_ADD',
        payload: abortController,
      });
      dispatch({
        type: 'LOADING_PROMPT',
        payload: true,
      });
      dispatch({
        type: 'PROMPT_START',
        payload: promptText,
      });
      if (state.gptEngine === 'public') {
        const streamMode = true;

        // new discussion public
        if (!state.discussionId) {
          chatGPTApiPost(
            promptText,
            { stream: streamMode, signal: abortControllerPost.signal },
            project
          )
            .then((responseNewDiscuss) => {
              dispatch({
                type: 'DISCUSSION_INIT_STATE',
                payload: responseNewDiscuss,
              });

              chatGPTApiPut(
                responseNewDiscuss.id,
                promptText,
                {
                  stream: streamMode,
                  token: appJWT.access_token,
                  signal: abortController.signal,
                },
                project
              )
                .then(async (response) => {
                  if (response?.body && streamMode) {
                    if (response.status !== 200) {
                      dispatch({
                        type: 'SET_ERROR',
                        payload: 'An error occurred while streaming',
                      });
                      return;
                    }
                    await parseResponseGpt3(response, dispatch);
                  } else {
                    dispatch({
                      type: 'SUBMIT_PROMPT',
                      payload: response,
                    });
                  }
                })
                .catch((e) => {
                  if (abortController.signal.aborted) return;
                  dispatch({
                    type: 'SET_ERROR',
                    payload: e.message,
                  });
                })
                .finally(() => {
                  dispatch({
                    type: 'LOADING_PROMPT',
                    payload: false,
                  });
                  resetPrompt();
                });
            })
            .catch((e) => {
              if (abortController.signal.aborted) return;
              dispatch({
                type: 'SET_ERROR',
                payload: e,
              });
              dispatch({
                type: 'LOADING_PROMPT',
                payload: false,
              });
              resetPrompt();
            });
        } else {
          // old discussion
          chatGPTApiPut(
            state.discussionId,
            promptText,
            {
              stream: streamMode,
              token: appJWT.access_token,
              signal: abortController.signal,
            },
            project
          )
            .then(async (response) => {
              if (response?.body && streamMode) {
                if (response.status !== 200) {
                  dispatch({
                    type: 'SET_ERROR',
                    payload: 'An error occurred while streaming',
                  });
                  dispatch({
                    type: 'LOADING_PROMPT',
                    payload: false,
                  });
                  resetPrompt();
                  return;
                }
                await parseResponseGpt3(response, dispatch);
              } else {
                dispatch({
                  type: 'SUBMIT_PROMPT',
                  payload: response,
                });
              }
            })
            .catch((e) => {
              if (abortController.signal.aborted) return;
              dispatch({
                type: 'SET_ERROR',
                payload: e.message,
              });
            })
            .finally(() => {
              dispatch({
                type: 'LOADING_PROMPT',
                payload: false,
              });
              resetPrompt();
            });
        }
      } else if (state.gptEngine === 'private') {
        const streamMode = true;
        // new discussion private
        if (!state.discussion?.id) {
          chatGPTApiPostPrivate(
            promptText,
            { stream: streamMode, signal: abortControllerPost.signal },
            project
            // buildSixLayer
          )
            .then((responseNewDiscuss) => {
              dispatch({
                type: 'DISCUSSION_INIT_STATE',
                payload: responseNewDiscuss,
              });

              chatGPTApiPutPrivate(
                responseNewDiscuss.id,
                promptText,
                {
                  stream: streamMode,
                  token: appJWT.access_token,
                  signal: abortController.signal,
                },
                project
              )
                .then(async (response) => {
                  if (response?.body && streamMode) {
                    await parseResponseAzureOpenAI(response, dispatch);
                    dispatch({
                      type: 'LOADING_PROMPT',
                      payload: false,
                    });
                  } else {
                    dispatch({
                      type: 'SUBMIT_PROMPT',
                      payload: response,
                    });
                  }
                })
                .catch((e) => {
                  if (abortController.signal.aborted) return;
                  dispatch({
                    type: 'SET_ERROR',
                    payload: e.message,
                  });
                })
                .finally(() => {
                  dispatch({
                    type: 'LOADING_PROMPT',
                    payload: false,
                  });
                  resetPrompt();
                });
            })
            .catch((e) => {
              dispatch({
                type: 'SET_ERROR',
                payload: e,
              });
              dispatch({
                type: 'LOADING_PROMPT',
                payload: false,
              });
              resetPrompt();
            });
        } else {
          chatGPTApiPutPrivate(
            state.discussionId,
            promptText,
            {
              stream: streamMode,
              token: appJWT.access_token,
              signal: abortController.signal,
            },
            project
          )
            .then(async (response) => {
              if (response?.body && streamMode) {
                await parseResponseAzureOpenAI(response, dispatch);
                dispatch({
                  type: 'LOADING_PROMPT',
                  payload: false,
                });
              } else {
                dispatch({
                  type: 'SUBMIT_PROMPT',
                  payload: response,
                });
              }
            })
            .catch((e) => {
              if (abortController.signal.aborted) return;
              dispatch({
                type: 'SET_ERROR',
                payload: e.message,
              });
            })
            .finally(() => {
              dispatch({
                type: 'LOADING_PROMPT',
                payload: false,
              });
              resetPrompt();
            });
        }
      } else if (state.gptEngine === 'rex') {
        const streamMode = true;
        // new discussion private
        if (!state.discussion?.id) {
          chatGPTApiPostRex(
            promptText,
            { stream: streamMode, signal: abortControllerPost.signal },
            project
            // buildSixLayer
          )
            .then((responseNewDiscuss) => {
              dispatch({
                type: 'DISCUSSION_INIT_STATE',
                payload: responseNewDiscuss,
              });

              chatGPTApiPutRex(
                responseNewDiscuss.id,
                promptText,
                {
                  stream: streamMode,
                  token: appJWT.access_token,
                  signal: abortController.signal,
                  filters: sixlayerFilter,
                },
                project
              )
                .then(async (response) => {
                  if (response?.body && streamMode) {
                    // await parseResponseGpt4Private(response, dispatch);
                    await parseResponseAzureOpenAI(response, dispatch);
                    dispatch({
                      type: 'LOADING_PROMPT',
                      payload: false,
                    });
                  } else {
                    dispatch({
                      type: 'SUBMIT_PROMPT',
                      payload: response,
                    });
                  }
                })
                .catch((e) => {
                  if (abortController.signal.aborted) return;
                  dispatch({
                    type: 'SET_ERROR',
                    payload: e.message,
                  });
                })
                .finally(() => {
                  dispatch({
                    type: 'LOADING_PROMPT',
                    payload: false,
                  });
                  resetPrompt();
                });
            })
            .catch((e) => {
              dispatch({
                type: 'SET_ERROR',
                payload: e,
              });
              dispatch({
                type: 'LOADING_PROMPT',
                payload: false,
              });
              resetPrompt();
            });
        } else {
          chatGPTApiPutRex(
            state.discussionId,
            promptText,
            {
              stream: streamMode,
              token: appJWT.access_token,
              signal: abortController.signal,
              filters: sixlayerFilter,
            },
            project
          )
            .then(async (response) => {
              if (response?.body && streamMode) {
                // await parseResponseGpt4Private(response, dispatch);
                await parseResponseAzureOpenAI(response, dispatch);
                dispatch({
                  type: 'LOADING_PROMPT',
                  payload: false,
                });
              } else {
                dispatch({
                  type: 'SUBMIT_PROMPT',
                  payload: response,
                });
              }
            })
            .catch((e) => {
              if (abortController.signal.aborted) return;
              dispatch({
                type: 'SET_ERROR',
                payload: e.message,
              });
            })
            .finally(() => {
              dispatch({
                type: 'LOADING_PROMPT',
                payload: false,
              });
              resetPrompt();
            });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmitPrompt = useCallback(() => _handleSubmitPrompt(), [
    state.discussionId,
    state.gptEngine,
  ]);

  useEffect(() => {
    if (init) {
      document.getElementById('chat-textbox').value = init;
      setTimeout(() => {
        handleSubmitPrompt();
      }, 200);
    }
  }, [init]);

  return (
    <Paper
      style={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        paddingLeft: 30,
        paddingRight: 30,
        boxShadow: 'None',
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            border: '1px solid #002A45',
            boxShadow: '1px solid #002A45',
            backgroundColor: state.loadingPrompt ? 'lightgrey' : '',
            // borderRadius: 10,
            padding: 2,
            borderRadius: 23,
            marginBottom: 5,
            background: '#F8F8F8',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: '18px',
          }}
        >
          <TextField
            disabled={state.loadingPrompt}
            multiline
            type="text"
            name="textbox"
            id="chat-textbox"
            style={{
              fontSize: '17px',
              padding: '2px',
              fontWeight: 'bold',
              width: '100%',
              border: 'none',
              borderRadius: '10px',
              borderBottom: 'none',
            }}
            InputProps={{ disableUnderline: true }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
                handleSubmitPrompt();
              }
            }}
            placeholder="Send a message"
          />
          &nbsp;
          <Box>
            <IconButton
              onClick={handleSubmitPrompt}
              style={{
                backgroundColor: '#002a45',
                color: 'white',
                padding: 10,
              }}
              size="small"
              disabled={state.loadingPrompt}
            >
              {state.loadingPrompt ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                <SendIcon />
              )}
            </IconButton>
          </Box>
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div>
            <Typography>Shift+enter for new line</Typography>
          </div>
        </div>
      </Grid>
      {state.errorMsg && state.errorMsg !== '' && (
        <AlertSnack
          msg={state.errorMsg}
          handleClose={handleCloseSnack}
          severity="error"
          duration={3000}
        />
      )}
    </Paper>
  );
};

export default memo(InputPrompt);
