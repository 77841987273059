import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TreeItem, TreeView } from '@material-ui/lab';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import logoCurrentModule from '../../assets/m5_blue.png';
import ProjectBreadCrumbs from '../../components/Core/ProjectBreadCrumbs';
import Module5Context from './Module5Context';
import ISafety from '../Module5/components/ISafety';
import IsafetyNew from './components/IsafetyNew';
import ISafetyTab from './components/IsafetyTab';
import TreeAllStyle, { useStyles } from '../../components/TreeMenu/TreeAllStyle';
import TreeArowIcons from '../../components/TreeMenu/TreeArowIcons';

function Module5() {
  const classes = TreeAllStyle();
  const arowClasses = TreeArowIcons();
  const { projectId } = useParams() as any;
  const { setSnackMsg, setSnackType } = useContext(Module5Context);

  useEffect(() => {
    // setSnackMsg('dddd');
    // setSnackType('success');
  }, []);

  return (
    <div className={classes.root}>
      <Grid container>
        {/* <Grid item xs={12}>
          <ProjectBreadCrumbs
            projectId={projectId}
            nodes={[
              {
                label: 'Safety & CyberSecurity',
                to: `#`,
                icon: (
                  <img
                    src={logoCurrentModule}
                    alt="Safety & CyberSecurity"
                    title="Safety & CyberSecurity"
                  />
                ),
              },
            ]}
          />
        </Grid> */}
        <TreeView
          className={classes.treeRoot}
          defaultCollapseIcon={<ExpandMoreIcon className={arowClasses.ArowdownStyle} />}
          defaultExpandIcon={<KeyboardArrowUpOutlinedIcon className={arowClasses.ArowupStyle} />}
          defaultExpanded={['']}
        >
          <TreeItem
            nodeId="0"
            label={
              <Typography variant="subtitle1" className={classes.treeLabel}>
                <span>
                  <SecurityOutlinedIcon className={classes.treeIcon} />
                </span>
                iSafety
              </Typography>
            }
            className={classes.treeitem}
            classes={{
              label: classes.label,
              selected: classes.label,
              content: classes.content,
              group: classes.group,
            }}
          >
            <ISafetyTab />
          </TreeItem>
          {/* <TreeItem
            nodeId="1"
            label={
              <Typography variant="subtitle1" className={classes.treeLabel}>
                iSafety- text to text
              </Typography>
            }
            className={classes.treeitem}
            classes={{ label: classes.label, selected: classes.label }}
          >
            <ISafety />
          </TreeItem>
          <TreeItem
            nodeId="2"
            label={
              <Typography variant="subtitle1" className={classes.treeLabel}>
                iSafety- text to Dataset
              </Typography>
            }
            className={classes.treeitem}
            classes={{ label: classes.label, selected: classes.label }}
          >
            <IsafetyNew />
          </TreeItem> */}
        </TreeView>
      </Grid>
    </div>
  );
}

export default Module5;
