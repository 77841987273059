import { Typography } from '@material-ui/core';
import { randomBytes } from 'crypto';
import { exit } from 'process';
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Workload3Create } from 'WorkloadType';
import { workloadCreate, workloadRun } from '../../../../api/workloads';
import Loading from '../../../../components/Core/Loading';
import Run1Context, { Resource } from '../../RunContext';

type Props = {
  onFinish?: any;
};

function StepCapture({ onFinish }: Props) {
  const history = useHistory();
  const { projectId } = useParams() as any;
  const runContext = useContext(Run1Context);
  const { resources, setResources, setWorkload } = runContext;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    // changed by Rahul have to verify  added one more condition => || res.scopeError !== null
    const resourcesOK = resources.filter(
      (res) => res.scopeError === null || res.scopeError !== null
    );
    const resourcesRun = resourcesOK.filter((res) => res.results === undefined && res.scope);
    const resourcesNoRun = resourcesOK.filter((res) => res.results !== undefined && !res.scope);
    const resourcesHasRun = resourcesOK.filter((res) => res.results !== undefined && res.scope);
    // validation csv / excel files
    const resourcesRunValidated = resourcesRun.filter((res) => {
      const { filename, scope, settings } = res;

      if (settings && ['csv', 'excel'].includes(scope?.FileType.toLowerCase())) {
        if (!('targetColumn' in settings)) {
          setError(`TargetColumn is missing : ${filename}`);
          return false;
        }
        // if columnNames is missing add target column
        if (!('columnNames' in settings)) {
          settings.columnNames = [settings?.targetColumn];
        }
      }
      return true;
    });
    if (resourcesRunValidated.length === 0) {
      return;
    }
    // no files to capture
    if (
      resourcesRunValidated.length === 0 &&
      resourcesNoRun.length === 0 &&
      resourcesHasRun.length === 0
    ) {
      setLoading(false);
      setError('No files are captured');
      return;
    }

    // skip capture
    if (resourcesRunValidated.length === 0 && resourcesHasRun.length > 0) {
      setLoading(false);
      if (onFinish) onFinish();
      return;
    }

    const resourcesRunModified = resourcesRunValidated.map((res: Resource) => {
      const { filename, scope, settings } = res;

      const startPage = Math.max(
        1,
        settings?.captureAll
          ? Number(scope?.StartPage)
          : Number(settings?.startPage ?? scope?.StartPage)
      );
      const endPage = Math.max(
        startPage,
        settings?.captureAll ? Number(scope?.EndPage) : Number(settings?.endPage ?? scope?.EndPage)
      );

      let body;
      let sheetname;
      let columnsData = [];
      // const texthere = settings.dataframe;
      function getRandomFileName() {
        const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
        const random = timestamp.substring(0, 15);
        // const random_number = timestamp + random;
        return String(random);
      }
      let nameNew: String = '';
      if (settings?.nameOfCapture === undefined) {
        nameNew = getRandomFileName();
      }

      switch (res.scope?.FileType.toLowerCase()) {
        case 'pdf':
          body = {
            FileName: filename,
            FilePath: scope?.FilePath,
            Container: scope?.Container,
            BlobName: scope?.Blob,
            FileType: String(scope?.FileType).toLowerCase(),
            Language: String(settings?.language ?? scope?.Language),
            StartPage: startPage,
            // EndPage: 1,
            EndPage: endPage,
            HeaderMargin: Number(settings?.headerMargin ?? scope?.HeaderMargin),
            FooterMargin: Number(settings?.footerMargin ?? scope?.FooterMargin),
            NameOfCapture: String(settings?.nameOfCapture ?? nameNew),
            TypesOfCapture: String(settings?.typesOfCapture ?? scope?.TypesOfCapture[0]),
            RegId: String(settings?.regId ?? ''),
            TableSwitch: !!settings?.extractTables,
            MergeBullet: !!settings?.mergeBullet,
            SmartHeaderFooter: !!settings?.smartHeaderFooter,
            LevelWiseHierarchy: !!settings?.LevelWiseHierarchy,
            SmartTableOfContent: !!settings?.smartTableOfContent,
            TranslateTo: settings?.translateTo,
            // fastm1: 'yes',
            fastm1Val: settings?.fastm1 || 'yes',
          };
          break;

        case 'word':
          body = {
            FileName: filename,
            FilePath: scope?.FilePath,
            Container: scope?.Container,
            BlobName: scope?.Blob,
            FileType: String(scope?.FileType).toLowerCase(),
            Language: String(settings?.language ?? scope?.Language),
            StartPage: startPage,
            EndPage: endPage,
            HeaderMargin: Number(settings?.headerMargin ?? scope?.HeaderMargin),
            FooterMargin: Number(settings?.footerMargin ?? scope?.FooterMargin),
            NameOfCapture: String(settings?.nameOfCapture ?? nameNew),
            TypesOfCapture: String(settings?.typesOfCapture ?? scope?.TypesOfCapture[0]),
            RegId: String(settings?.regId ?? ''),
            TableSwitch: !!settings?.extractTables,
            MergeBullet: !!settings?.mergeBullet,
            SmartHeaderFooter: !!settings?.smartHeaderFooter,
            SmartTableOfContent: !!settings?.smartTableOfContent,
            TranslateTo: settings?.translateTo,
            fastm1Val: 'no',
          };
          break;

        case 'csv':
          // include target column into ColumnNames
          columnsData = settings?.columnNames;
          if (!columnsData.includes(settings?.targetColumn)) {
            columnsData.unshift(settings?.targetColumn);
          }
          body = {
            FileName: filename,
            FilePath: scope?.FilePath,
            Container: scope?.Container,
            BlobName: scope?.Blob,
            FileType: String(scope?.FileType).toLowerCase(),
            TargetColumn: settings?.targetColumn,
            ColumnNames: columnsData,
            NameOfCapture: String(settings?.nameOfCapture ?? nameNew),
            Header: settings?.Header ?? false,
            TranslateTo: settings?.translateTo,
            fastm1Val: settings?.fastm1 || 'yes',
          };
          break;

        case 'excel':
          if (settings && 'sheetname' in settings === false) {
            sheetname = scope?.Sheets[0].SheetName;
          } else {
            sheetname = settings?.sheetname;
          }
          // include target column into ColumnNames
          columnsData = settings?.columnNames;
          if (!columnsData.includes(settings?.targetColumn)) {
            columnsData.unshift(settings?.targetColumn);
          }

          body = {
            FileName: filename,
            FilePath: scope?.FilePath,
            Container: scope?.Container,
            BlobName: scope?.Blob,
            FileType: String(scope?.FileType).toLowerCase(),
            Sheet: sheetname,
            TargetColumn: settings?.targetColumn,
            ColumnNames: columnsData,
            NameOfCapture: String(settings?.nameOfCapture ?? nameNew),
            Header: settings?.Header ?? false,
            TranslateTo: settings?.translateTo,
            fastm1Val: settings?.fastm1 || 'yes',
          };
          // console.log('excel body: ', body);
          break;

        default:
          break;
      }
      delete res.scope?.Text;
      delete res.settings?.dataframe;
      res.settingsModule = body;
      res.fastm1 = body.fastm1Val;
      settings.nameOfCapture = body.NameOfCapture;
      return res;
    });

    // store settings in workload
    // return;

    workloadCreate({
      project_id: projectId,
      app: 'module1',
      resources: resourcesRunModified,
    }).then((workloadNew: Workload3Create) => {
      workloadRun(workloadNew.id).then((workloadArifact: Workload3Create) => {
        // go to activity page
        window.history.pushState('', '', `/projects/${projectId}`);
        history.push(`/projects/${projectId}`);
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error !== '')
    return (
      <Typography variant="body1" style={{ color: 'red' }}>
        {error}
      </Typography>
    );
  if (loading) return <Loading />;

  return <div />;
}

export default StepCapture;
