import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, { useContext, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import CsvDoc from '../../../../../../assets/csv_icons.png';
import docIcon from '../../../../../../assets/docx_ico.png';
// import DocAnalysisContext from '../../../Knowledge360/DocAnalysisContext';
import csvFileReducer, { csvInitialState } from '../../../../Reducers/csvFileReducer';
import RexAnalysisContext from '../../RexAnalysisContext';
// import csvFileReducer, { csvInitialState } from '../../../Reducers/csvFileReducer';
// import DocAnalysisContext from '../../../DocAnalysisContext';

const LeftBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const RightBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const MainDiv = styled.div`
  display: flex;
`;

const CSVFileSettings = ({ settingsData, index }) => {
  const [state, dispatch] = useReducer(csvFileReducer, csvInitialState);
  const {
    operation,
    setOperation,
    scopeSetting,
    selection,
    setSelection,
    setScopeSetting,
    fileSetting,
    setFileSettings,
    // state,
    // dispatch,
  } = useContext(RexAnalysisContext);

  useEffect(() => {
    setFileSettings((prev) => {
      return {
        ...prev,
        [index]: {
          ...state,
        },
      };
    });
  }, [state]);

  // console.log(fileSetting);

  const HandleReg = (e, index) => {
    const obj = {
      columnName: e.target.value,
      filename: settingsData[0].FileName,
      columnNames: settingsData[0].ColumnNames,
    };
    dispatch({ type: 'change_columnName', payload: obj });
  };

  return (
    <>
      <MainDiv>
        <LeftBlock>
          {settingsData.map((c, indexIs) => (
            <FormControl variant="outlined">
              <span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                {c?.FileType === 'unsupported' ? <img src={docIcon} alt="isens" height="30" /> : ''}
                &nbsp;
                {c?.FileType === 'CSV' ? <img src={CsvDoc} alt="isens" height="30" /> : ''}
                {c?.FileType !== 'unsupported' ? (
                  <span>
                    <span style={{ color: 'red' }}>{c?.Error}</span>
                  </span>
                ) : (
                  ''
                )}
                <Tooltip title={c?.Blob} placement="top" arrow>
                  <b style={{ color: 'blue', wordWrap: 'break-word' }}>
                    {c?.Blob.substring(0, 25)}
                  </b>
                </Tooltip>
              </span>
            </FormControl>
          ))}
        </LeftBlock>
        <RightBlock>
          {settingsData.map((c, indexIs) => {
            return (
              <>
                {' '}
                {c.FileType === 'CSV' && (
                  <FormControl variant="outlined" size="small" style={{ width: '80%' }}>
                    <InputLabel
                      htmlFor="outlined-age-native-simple"
                      style={{ color: '#002A45', fontWeight: 'bold' }}
                    >
                      Column Name
                    </InputLabel>
                    <Select
                      native
                      label="Column Name"
                      name="columnname"
                      style={{ width: '100%' }}
                      onChange={(e) => HandleReg(e, indexIs)}
                      defaultValue=""
                    >
                      <option aria-label="None" value="" disabled />
                      {c?.ColumnNames !== null
                        ? c?.ColumnNames.map((cap: string, index: number) => (
                            <option key={`capture-type-${index}`} value={cap}>
                              &nbsp;&nbsp;{cap}
                            </option>
                          ))
                        : ''}
                    </Select>
                  </FormControl>
                )}
              </>
            );
          })}
        </RightBlock>
      </MainDiv>
    </>
  );
};

export default CSVFileSettings;
