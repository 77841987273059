import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  DataTypeProvider,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  ClickAwayListener,
  IconButton,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import PublishIcon from '@mui/icons-material/Publish';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
// import DragAndDrop from '../../../../../components/Core/DragAndDrop';
import WorkSpaceContext from '../../../../WorkspceContext';
import TStyles, { rowComponent, tUseStyles } from '../../../../../../components/Datagrid/TStyles';
// import formatBytes from '../../../../../../../formatBytes';

import ExcelDoc from '../../../../../../assets/icons8-excel-48.png';
import PptDoc from '../../../../../../assets/icons8-powerpoint-48.png';
import DocxDoc from '../../../../../../assets/icons8-microsoft-word-2019-48.png';
import PdfDoc from '../../../../../../assets/pdf_ins.png';
import CsvDoc from '../../../../../../assets/csv_icons.png';
// import DocAnalysisContext from '../../../Knowledge360/DocAnalysisContext';
import { fileDetails } from '../../../../../../api/module1';
import RexAnalysisContext from '../../RexAnalysisContext';

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
  { name: 'userName', title: 'Uploaded by' },
];

const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

const FilesContiner = () => {
  const [currentPage, setCurrentPage] = useState();
  const [pageSize, setPageSize] = useState(5);
  const pageSizes = [5, 10, 20, 50];
  const { docFiles, files, setDocFiles, setSnack } = useContext(WorkSpaceContext);
  const {
    operation,
    setOperation,
    scopeSetting,
    setScopeSetting,
    selection,
    setSelection,
    fileSetting,
    setToggleTab,
  } = useContext(RexAnalysisContext);
  // console.log(docFiles);
  const file = useMemo(() => {
    return files.filter((data) => {
      const fileExtension = data.filename.toLowerCase().split('.').pop();

      return ['pdf'].includes(fileExtension);
    });
  }, [files]);

  const docOperation = [
    'Similarity Analysis',
    'Document Similarity',
    'Compare Versions',
    'Extract Topics',
    // 'Translate',
    'Find Duplicates',
  ];

  const getFileDetails = (filename: any, filenameCopy: any, container: any) => {
    fileDetails(filename, filenameCopy, container).then((data) => {
      setToggleTab((prev) => {
        return {
          ...prev,
          left: '5rem',
        };
      });
      setScopeSetting([data.output]);
    });
  };

  const handleOneFileServise = (selectedKeys: any) => {
    const newselection: any = [0];
    if (selectedKeys[0] === undefined) {
      // eslint-disable-next-line no-param-reassign
      selectedKeys[0] = selectedKeys.slice(-1).pop();
    }
    // eslint-disable-next-line prefer-destructuring
    newselection[0] = selectedKeys[selectedKeys.length - 1];
    setSelection(newselection);
    if (selectedKeys.length !== 0) {
      const fileno = selectedKeys.slice(-1).pop();
      if (fileno !== undefined) {
        setScopeSetting([]);
        getFileDetails(file[fileno].filename, file[fileno].filename, file[fileno].container);
      }
    }
  };

  const handleSelectionChange = (selectedKeys: any) => {
    handleOneFileServise(selectedKeys);
  };

  const renderUploadDate = ({ row }) => {
    const dates = row.date.split(' ');
    return (
      <>
        {dates[0]} {dates[1].split('.')[0]}
      </>
    );
  };

  const renderFileName = ({ row }: any) => {
    const ext = row.filename.split('.');
    const extensions = {
      xlsx: ExcelDoc,
      pptx: PptDoc,
      csv: CsvDoc,
      pdf: PdfDoc,
      docx: DocxDoc,
    };
    return (
      <div
        style={{
          position: 'relative',
          padding: '0.3rem',
          width: 'max-content',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <img src={extensions[ext[ext.length - 1]]} height="30" width="30" alt="img" />
          <Typography>{row.filename}</Typography>
        </div>
        {row?.isNew && (
          <div
            style={{
              width: 'max-content',
              padding: '0.2rem',
              borderRadius: '10px',
              backgroundColor: '#19AA6E',
              color: 'white',
              position: 'absolute',
              top: '-12px',
              right: '-35px',
            }}
          >
            <Typography style={{ fontSize: '0.85em' }}>New !</Typography>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Box>
        <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
          <ExpressGrid rows={file} columns={columns}>
            <SelectionState selection={selection} onSelectionChange={handleSelectionChange} />
            <FilteringState />
            <IntegratedFiltering />
            <DataTypeProvider for={['filename']} formatterComponent={renderFileName} />
            <DataTypeProvider for={['date']} formatterComponent={renderUploadDate} />
            <SortingState />
            <IntegratedSorting />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <VirtualTable />
            <Table tableComponent={TableComponent} />
            <TableColumnResizing
              defaultColumnWidths={[
                { columnName: 'filename', width: '300px' },
                { columnName: 'size', width: '200px' },
                { columnName: 'folderName', width: '200px' },
                { columnName: 'date', width: '200px' },
                { columnName: 'userName', width: '200px' },
              ]}
            />
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            <TableSelection />
            <PagingPanel pageSizes={pageSizes} />
          </ExpressGrid>
        </Box>
      </Box>
    </>
  );
};
export default FilesContiner;
