import React, { useState, useRef } from 'react';
import Paper from '@mui/material/Paper';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  TableColumnResizing,
} from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { useReactToPrint } from 'react-to-print';
import Button from '@mui/material/Button';
import CommentTwoToneIcon from '@mui/icons-material/CommentTwoTone';
import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone';
import LocalPrintshopTwoToneIcon from '@mui/icons-material/LocalPrintshopTwoTone';
import MultiAllocTableComp from '../../../../components/Datagrid/MultiAllocTableComp';
import { tableAllocatorComponent } from '../../../../components/Datagrid/TStyles';

const tableHeaderComponents: React.FunctionComponent<Table.RowProps> = (props) => {
  return (
    <Table.TableHead
      {...props}
      style={{
        position: 'unset',
        top: 0,
        zIndex: 400,
      }}
    />
  );
};

const TableIs = ({ cardDetails }: any) => {
  const arrGeneral = Object.entries(cardDetails.general);
  const arrPhase = Object.entries(cardDetails.phase);
  const [questionHeight, setQuestionHeight] = useState<any>(200);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'CSS',
  });
  const [columns] = useState([
    { name: 'project', title: 'Project' },
    { name: 'fy', title: 'FY', width: 30 },
    { name: 'leadingBusiness', title: 'Leading Business' },
    { name: 'projectScope', title: 'Project Scope' },
    { name: 'region', title: 'Region' },
    { name: 'cluster', title: 'Cluster' },
    { name: 'country', title: 'Country' },
    { name: 'currentPhase', title: 'Current Phase' },
    { name: 'NPS', title: 'NPS' },
    { name: 'OSS', title: 'OSS' },
  ]);
  const [defaultColumnWidths] = useState([
    { columnName: 'project', width: 200 },
    { columnName: 'fy', width: 100 },
    { columnName: 'leadingBusiness', width: 200 },
    { columnName: 'projectScope', width: 200 },
    { columnName: 'region', width: 100 },
    { columnName: 'cluster', width: 200 },
    { columnName: 'country', width: 100 },
    { columnName: 'currentPhase', width: 100 },
    { columnName: 'NPS', width: 50 },
    { columnName: 'OSS', width: 50 },
  ]);
  const [rows, setRows] = useState([cardDetails]);

  return (
    <div>
      <style type="text/css">{'@media print{@page {size: A3 landscape}}'}</style>
      <Button onClick={handlePrint}>
        <LocalPrintshopTwoToneIcon fontSize="small" />
        <b style={{ color: 'blue' }}>Print this out!</b>
      </Button>
      <div ref={componentRef}>
        <Paper elevation={3} style={{ padding: '10px', marginTop: -1 }}>
          <Grid rows={rows} columns={columns}>
            <DataTypeProvider
              for={['project', 'leadingBusiness', 'projectScope', 'cluster', 'currentPhase']}
              formatterComponent={({ value }) => (
                <span
                  title={value}
                  style={{
                    whiteSpace: 'pre-line',
                    // fontWeight: 'bold',
                    color: '#002A45',
                    wordWrap: 'break-word',
                  }}
                >
                  {value}
                </span>
              )}
            />
            <Table
              tableComponent={MultiAllocTableComp}
              containerComponent={tableAllocatorComponent}
              headComponent={tableHeaderComponents}
            />
            <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
            <TableHeaderRow />
          </Grid>
        </Paper>
        <Paper>
          <div>
            {arrGeneral?.length > 0 && (
              <table style={{ width: '100%' }}>
                <thead
                  style={{
                    backgroundColor: '#002A45',
                    color: 'white',
                    top: 0,
                    position: 'sticky',
                  }}
                >
                  <th>
                    <b>Questions(General)</b>
                  </th>
                  <th>
                    <b>Results</b>
                  </th>
                </thead>
                <tbody>
                  {arrGeneral?.map((a, b) => {
                    return (
                      <tr>
                        <td width="90%" title={arrGeneral[b][0]}>
                          &nbsp;&nbsp; <QuizTwoToneIcon fontSize="small" /> {arrGeneral[b][0]}
                        </td>
                        <td title={arrGeneral[b][1]}>&nbsp;&nbsp; {arrGeneral[b][1]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          <hr />
          {arrPhase?.length > 0 && (
            <table style={{ width: '100%' }}>
              <thead
                style={{
                  backgroundColor: '#002A45',
                  color: 'white',
                }}
              >
                <th>
                  <b>Questions(Phase)</b>
                </th>
                <th>
                  <b>Results</b>
                </th>
              </thead>
              <tbody>
                {arrPhase?.map((a, b) => {
                  return (
                    <tr>
                      <td width="90%" title={arrPhase[b][0]}>
                        &nbsp;&nbsp; <QuizTwoToneIcon fontSize="small" /> {arrPhase[b][0]}
                      </td>
                      <td title={arrPhase[b][1]}>&nbsp;&nbsp; {arrPhase[b][1]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <hr />
          <table style={{ width: '100%' }}>
            <thead
              style={{
                backgroundColor: '#002A45',
                color: 'white',
              }}
            >
              <th>
                <b>Comment Section</b>
              </th>
              <th>
                <b>Comments</b>
              </th>
            </thead>
            <tbody>
              <tr>
                <td width="25%">
                  &nbsp;&nbsp;{' '}
                  <b>
                    <CommentTwoToneIcon fontSize="small" /> General
                  </b>
                </td>
                <td>{cardDetails?.generalComments}</td>
              </tr>
              <tr>
                <td width="25%">
                  &nbsp;&nbsp;{' '}
                  <b>
                    <CommentTwoToneIcon fontSize="small" /> Phase Comments
                  </b>
                </td>
                <td>{cardDetails?.phaseComments || 'NA'}</td>
              </tr>
              <tr>
                <td width="25%">
                  &nbsp;&nbsp;{' '}
                  <b>
                    <CommentTwoToneIcon fontSize="small" /> Global Comments
                  </b>
                </td>
                <td>{cardDetails?.globalComments}</td>
              </tr>
            </tbody>
          </table>
        </Paper>
      </div>
    </div>
  );
};

export default TableIs;
