import React, { useEffect, useMemo, useState } from 'react';
import { getProjectGroupUsers } from '../../../../../../../api/projects';
import useLoadResponse from '../../Hooks/useLoadResponse';
import {
  AnalysisAllocationExtraction,
  generatKeysForGroup,
} from '../../utils/allocationExtraction';

type Props = {
  projectId: any;
  setNotify: any;
  metaData: any;
  targetColumn: any;
  userId: any;
  workloadId: any;
  filename: any;
  langCheck: any;
  languagesCode: any;
  translationCode: any;
  setTranslationCode: any;
  setMetaDAta: any;
  setI18n: any;
  dispatch: any;
  setActionForFile: any;
  actionForFile: any;
  groups: any;
  users: any;
  onChange: any;
  displayColumns: any;
};

const useLoadAnalysisData = (props: Props) => {
  const {
    projectId,
    setNotify,
    metaData,
    targetColumn,
    userId,
    workloadId,
    filename,
    langCheck,
    languagesCode,
    translationCode,
    setTranslationCode,
    setMetaDAta,
    setI18n,
    dispatch,
    setActionForFile,
    actionForFile,
    groups,
    users,
    onChange,
    displayColumns,
  } = props;

  const [projectGroups, setProjectGroups] = useState();

  const { dataLoading, percentage, refreshDATA, refreshLoading } = useLoadResponse({
    projectId,
    workloadId,
    filename,
    type: 'analysis',
    langCheck,
    languagesCode,
    translationCode,
    setTranslationCode,
    setMetaDAta,
    setI18n,
    targetColumn,
    dispatch,
    setActionForFile,
    actionForFile,
    groups,
    users,
    onChange,
    displayColumns,
  });

  const generateKeysPairValue = (resp: any) => {
    const obj = {};
    for (const item of resp) {
      const keyGenerated = generatKeysForGroup(item);
      obj[keyGenerated] = item;
    }

    return obj;
  };

  useEffect(() => {
    getProjectGroupUsers(projectId)
      .then((resp) => {
        return generateKeysPairValue(resp);
      })
      .then((res) => {
        setProjectGroups(res);
      })
      .catch((error) => {
        setNotify(JSON.stringify(error.detail), 'error');
      });
  }, []);

  const anlayisRows = useMemo(() => {
    const values = Object.values(metaData);
    const AllocationsTemp = [] as any;

    for (const item of values) {
      AnalysisAllocationExtraction(AllocationsTemp, item, targetColumn, projectGroups, userId);
    }
    return AllocationsTemp;
  }, [metaData, projectGroups]);

  return { dataLoading, percentage, refreshDATA, anlayisRows, projectGroups, refreshLoading };
};

export default useLoadAnalysisData;
