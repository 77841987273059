import React, { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { Avatar, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { extractUserName } from '../../utils/extractdata';
import { lifeCycle } from '../../const/allocatorConst';

const AvatarComponent = ({ name }) => {
  return (
    <Avatar alt={name} sx={{ width: 24, height: 24, bgcolor: '#002A45' }}>
      {name[0] ?? ''}
    </Avatar>
  );
};

const childRowStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  // justifyContent: 'center',
  color: '#002A45',
};

const Allocation = ({ data }) => {
  const allocationsData = useMemo(() => {
    const header = [
      { lifecycle: 'Lifecycle', pbs: 'PBS', abs: 'ABS', obs: 'OBS', receiver: 'Receiver' },
    ];
    const values = Object.values(data);
    const body = values.map((item) => {
      const { lifecycle, pbs, abs, obs, receiver } = item || '';
      return { lifecycle, pbs, abs, obs, receiver };
    });

    return [...header, ...body];
  }, [data]);

  return (
    <>
      {allocationsData.length > 1 && (
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', gap: '5px' }}>
          {allocationsData.map((item, index) => {
            return (
              <div style={{ flex: 1, display: 'flex', fontSize: '1.1rem', marginLeft: '5rem' }}>
                <div style={{ ...childRowStyle, marginBottom: index === 0 ? '10px' : 'none' }}>
                  {index === 0 ? (
                    <b>{item.lifecycle}</b>
                  ) : (
                    <Chip
                      sx={{
                        width: 100,
                        backgroundColor: lifeCycle[item.lifecycle],
                        color: 'white',
                      }}
                      label={item.lifecycle}
                    />
                  )}
                </div>
                <div style={{ ...childRowStyle, marginBottom: index === 0 ? '10px' : 'none' }}>
                  {index === 0 ? (
                    <b>{item.pbs}</b>
                  ) : (
                    <Typography color="info">{item.pbs}</Typography> || 'tbd'
                  )}
                </div>
                <div style={{ ...childRowStyle, marginBottom: index === 0 ? '10px' : 'none' }}>
                  {index === 0 ? (
                    <b>{item.abs}</b>
                  ) : (
                    <Typography color="info">{item.abs}</Typography> || 'tbd'
                  )}
                </div>
                <div style={{ ...childRowStyle, marginBottom: index === 0 ? '10px' : 'none' }}>
                  {index === 0 ? (
                    <b>{item.obs}</b>
                  ) : (
                    <Typography color="info">{item.obs}</Typography> || 'tbd'
                  )}
                </div>
                <div style={{ ...childRowStyle, marginBottom: index === 0 ? '10px' : 'none' }}>
                  {index === 0 ? (
                    <b>{item.receiver}</b>
                  ) : (
                    (
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <AvatarComponent name={item.receiver} />{' '}
                        <Typography color="info">
                          {extractUserName(item?.receiver, 'name')}
                        </Typography>
                      </div>
                    ) || 'tbd'
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Allocation;
