import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { Box, Typography, Paper, CircularProgress } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getProjectRequirements } from '../../../api/wsNotifs';
import useAppToken from '../../../app/useAppToken';
import Loading from '../../../components/Core/Loading';
import TStyles, { rowComponent, tUseStyles } from '../../../components/Datagrid/TStyles';
import TabStyle from '../../../components/Layout/TabStyle';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}
type Props = {
  file_name?: any;
  workload_id?: any;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function ProjectRequirements({ file_name, workload_id }: Props) {
  const classesTab = TabStyle();
  const tableUseStyles = tUseStyles();
  const { projectId } = useParams() as any;
  const { appJWT } = useAppToken();
  const theme = useTheme();
  const [value] = React.useState(0);
  const [pageSizes] = useState([5, 10, 20, 50, 100]);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState<any>();
  const [secondTablerows, setsecondTableRows] = useState<any>([]);
  // table header
  useEffect(() => {
    const usersArray: { entity: any }[] = [];
    setLoading(true);
    setDisplay(false);
    getProjectRequirements(projectId, appJWT.user_id, file_name, workload_id).then((projectreq) => {
      projectreq.forEach(
        (item: { id: any; name: any; filename: any; project_id: any; row_capture: any }) => {
          return usersArray.push({
            name: item.name,
            id: item.id,
            filename: item.filename,
            project_id: item.project_id,
            workload_id: item.workload_id,
            role: item.role,
            Text: item.Text,
          });
        }
      );
      setTimeout(() => {
        setLoading(false);
        setDisplay(true);
        setsecondTableRows(usersArray);
      }, 2000);
    });
  }, [file_name, workload_id]);
  const TableComponentBase = ({ classes, ...restProps }: any) => (
    <Table.Table {...restProps} className={classes.tableStriped} />
  );
  const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);
  const columns = [
    { name: 'id', title: 'Id' },
    { name: 'Text', title: 'Text' },
    { name: 'name', title: 'Assign' },
    { name: 'filename', title: 'filename' },
    // { name: 'Link', title: 'Link' },
  ];

  const renderLinkAllocation = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    if (row.role === 3) {
      return (
        <Link
          to={`/projects/${row.project_id}/module1/results/${row.workload_id}?action=allocator`}
          style={{ textDecoration: 'none' }}
        >
          view
        </Link>
      );
    }
    return (
      <Link
        to={`/projects/${row.project_id}/module1/results/${row.workload_id}?action=characterizer`}
        style={{ textDecoration: 'none' }}
      >
        view
      </Link>
    );
  };
  return (
    <>
      {loading ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Loading />
        </div>
      ) : (
        ''
      )}
      {display && (
        <>
          <div className={classesTab.root} style={{ marginTop: 10 }}>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Paper className={tableUseStyles.panelMain}>
                <ExpressGrid rows={secondTablerows} columns={columns}>
                  {/* <DataTypeProvider for={['Link']} formatterComponent={renderLinkAllocation} /> */}
                  <SelectionState />
                  <FilteringState defaultFilters={[{ columnName: 'name', value: '' }]} />
                  <IntegratedFiltering />
                  <SortingState />
                  <IntegratedSorting />
                  <PagingState defaultCurrentPage={0} defaultPageSize={5} />
                  <IntegratedPaging />
                  <VirtualTable />
                  <Table tableComponent={TableComponent} />
                  <TableHeaderRow showSortingControls />
                  <TableFilterRow rowComponent={rowComponent} />
                  <PagingPanel pageSizes={pageSizes} />
                  <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
                </ExpressGrid>
              </Paper>
            </TabPanel>
            {/* </SwipeableViews> */}
          </div>
        </>
      )}
    </>
  );
}
export default ProjectRequirements;
