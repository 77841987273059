import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { CircularProgress, Paper } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllProjects } from '../../../api/module1';
import { getAll } from '../../../api/projects';
import useAppToken from '../../../app/useAppToken';
import Loading from '../../../components/Core/Loading';
import TStyles, { rowComponent, tUseStyles } from '../../../components/Datagrid/TStyles';
import formatDate, { formatLocal } from '../../../tools/formatDate';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
// table header
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

// table Filter style

const columns: any = [
  { name: 'project_name', title: 'Project Name' },
  { name: 'entity', title: 'Entity' },
  //   { name: 'users', title: 'ID' },
  //   { name: 'role_id', title: 'Job Title' },
  { name: 'users', title: 'Users' },
  { name: 'folders', title: 'Folders' },
  //   { name: 'Files', title: 'Files' },
  { name: 'created_on', title: 'Created On' },
  { name: 'groupUsers', title: 'My Role' },
  //   { name: 'My Role', title: 'My Role' },
  //   { name: 'Project Administrator', title: 'Project Administrator' },
  //   { name: 'Project Leaders', title: 'Project Leaders' },
  //   { name: 'project_name', title: 'Projects' },
];

function ProjectViews() {
  const classes = useStyles();
  const tableUseStyles = tUseStyles();
  // console.log(projects);
  const [files, setFiles] = useState<any[]>([]);
  const [selection, setSelection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState<any>();
  const [displayGrid, setDisplayGrid] = useState<any>();
  const [apiData, setApiData] = useState<any>({});
  const [pageSizes] = useState([5, 10, 20, 50]);
  const { appJWT } = useAppToken() as any;
  const token: any = appJWT.user_id;
  useEffect(() => {
    setLoading(true);
    setDisplay(false);
    getAllProjects().then((projects) => {
      //   console.log('icapture');

      // console.log(projects);
      const usersArray: { entity: any }[] = [];
      projects.forEach(
        (item: {
          id: any;
          users: any;
          entity: any;
          project_name: any;
          folders: any;
          created_at: any;
          groupusers: any;
        }) => {
          usersArray.push({
            project_name: renderLinkNotification(item.name, item.id),
            entity: item.entity,
            users: item.users.length,
            folders: item.folders.length,
            created_on: item.created_at,
            groupUsers: item.users.map((user: any) => {
              // console.log('hi', token);
              // eslint-disable-next-line eqeqeq
              if (user.user_id == token) {
                if (user.role_id === 2) {
                  return 'Project Leader';
                }
                if (user.role_id === 3) {
                  return 'Performer';
                }
                if (user.role_id === 1) {
                  return 'Project Administrator';
                }
                return null;
              }
              return null;
            }),
          });
        }
      );
      // console.log('Hi', usersArray);
      // eslint-disable-next-line consistent-return

      // setLoading(false);
      setTimeout(() => {
        setLoading(false);
        setDisplay(true);
        setFiles(usersArray);
      }, 2000);
    });
    // const arr = usersArray;
    // const ids = arr.map((o) => o.id);
    // const usersArrayFinal = arr.filter(({ id }, index) => !ids.includes(id, index + 1));
    // projects.forEach((item: { id: any; users: any }) => {
    //   console.log(item.entity);
    //   (item || []).forEach((users: any) => {
    //     usersArray.push({
    //       entity: item.entity,
    //   users: users.user.length,
    //   department: users.user.details?.department,
    //   site_code: users.user.details?.site_code,
    //   email: users.user.email,
    //   profile: users.user.details?.job_function,
    //   project_name: projects
    //     .map((project) => {
    //       if (users.project_id === project.id) {
    //         return project.name;
    //       }
    //       return null;
    //     })
    //     .filter((user) => {
    //       return user;
    //     }),
    //     });
    //   });
    //     // });
    //   })
  }, []);
  const renderLinkNotification = (row, project_id) => (
    <Link
      // id={`search-${row.id}`}
      // eslint-disable-next-line max-len

      to={`/projects/${project_id}`}
      // href="/dwdwdf"

      // target="_self"
      // rel="noreferrer"
      style={{ textDecoration: 'none' }}
    >
      {row}
    </Link>
  );

  let fileIs1: any;
  let fileContainer1: any;
  if (selection.length > 0 && selection[0] !== undefined && selection.length < 2) {
    fileIs1 = files[selection[0]].filename || '';
    fileContainer1 = files[selection[0]].container || '';
    // console.log(fileIs1);
  }

  function callIframeAPI() {
    // console.log(fileIs1);
    setDisplay(false);
    setTimeout(() => {
      setDisplay(true);
      setApiData(
        // eslint-disable-next-line max-len
        `https://isens-m4.azurewebsites.net/explorer/banner=true&card=true/container=${fileContainer1}&blob_name=${fileIs1}`
      );
    }, 1000);
  }

  const onChangeAttribute = (selection: any) => {
    const newselection: any = [0];
    if (selection[0] === undefined) {
      // eslint-disable-next-line no-param-reassign
      selection[0] = selection.slice(-1).pop();
    }
    // eslint-disable-next-line prefer-destructuring
    newselection[0] = selection[1];
    setSelection(newselection);
    if (selection.length === 1) {
      // console.log('if condition no action required: ', selection);
    } else {
      // console.log('else', selection.slice(-1).pop());
      const fileno = selection.slice(-1).pop();
      // getFileDetails(files[fileno].filename);
    }
    // console.log(files[selection[0]]);
  };

  return (
    <>
      {loading ? <Loading /> : ''}
      {display && (
        <>
          {/* <div>
          <span>
            <b>Select one zip file:</b>
          </span>
          <span>
            <br />
            {/* Total rows selected: {selection.length} 
            <span style={{ marginRight: '10px' }}>
              Selected File Name:<b>{fileIs1 || ''}</b>
            </span>
          </span>
          <br />
        </div> */}
          <div>
            <Paper className={tableUseStyles.panelMain}>
              <ExpressGrid rows={files} columns={columns}>
                <DataTypeProvider
                  for={['created_on']}
                  formatterComponent={({ value }) => (
                    <span>{formatDate(value, formatLocal('en'))}</span>
                  )}
                />
                <SelectionState />
                <FilteringState defaultFilters={[{ columnName: 'Name', value: '' }]} />
                <IntegratedFiltering />
                <SortingState />
                <IntegratedSorting />
                <PagingState defaultCurrentPage={0} defaultPageSize={5} />
                <IntegratedPaging />
                <VirtualTable />
                <Table tableComponent={TableComponent} />
                <TableHeaderRow showSortingControls />
                <TableFilterRow rowComponent={rowComponent} />
                <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
                <PagingPanel pageSizes={pageSizes} />
              </ExpressGrid>

              {/* <span style={{ float: 'right', marginRight: '10px' }}>
                Selected File Name: <b>{fileIs1 || 'NA'}</b>
              </span> */}
              <br />
              {/* {loading ? (
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                ''
              )} */}
              {/* <Button
              type="button"
              disabled={fileIs1 === 'NA' || fileIs1 === undefined}
              variant="contained"
              color="primary"
              onClick={callIframeAPI}
              style={{
                marginTop: '10px',
                height: '50px',
                width: '150px',
                float: 'right',
                marginRight: '10px',
                marginBottom: '10px',
              }}
            >
              Analyse
            </Button>
          </Paper>
          <br />
          {display && (
            <Grid>
              <Iframe
                url={apiData}
                width="99.99%"
                height="800px"
                id="myId"
                className="myClassname"
                // display="initial"
                position="relative"
                overflow="hidden"
                scrolling="no"
              />
            </Grid>
          )} */}
            </Paper>
          </div>
        </>
      )}
    </>
  );
}
export default ProjectViews;
