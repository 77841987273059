import { Box } from '@material-ui/core';
import React, { memo } from 'react';
import TabContainer from './core/TabContainer';
import UserWorkspaceTableContainer from './core/UserWorkspaceTableContainer';
import ProjectTableContainer from './core/ProjectTableContainer';
import { FileTranslatorContextProvider } from './FileTranslatorContext';

type Props = {
  setSnack: any;
  languageCode: any;
  files: any;
  mode: any;
  setFiles: any;
  glossaryFiles: any;
  setGlossaryFiles: any;
};

const FileTranslator = ({
  setSnack,
  languageCode,
  files,
  setFiles,
  mode,
  glossaryFiles,
  setGlossaryFiles,
}: Props) => {
  // console.log(mode);
  const RenderComponent = () => {
    switch (mode.key) {
      case 'user':
        return <UserWorkspaceTableContainer />;

      case 'project':
        return <ProjectTableContainer />;
      default:
        return null;
    }
  };
  return (
    <>
      <FileTranslatorContextProvider
        setSnack={setSnack}
        languageCode={languageCode}
        files={files}
        mode={mode}
        setFiles={setFiles}
        glossaryFiles={glossaryFiles}
        setGlossaryFiles={setGlossaryFiles}
      >
        <Box style={{ border: '1px solid #ccd6f2' }}>{RenderComponent}</Box>
      </FileTranslatorContextProvider>
    </>
  );
};

export default memo(FileTranslator);
