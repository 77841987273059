import { Plugin, Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import React from 'react';
import { LangCode } from './TranslationList';

export type WithPluginEnum =
  | 'Accept'
  | 'Activate'
  | 'Add'
  | 'Cancel'
  | 'Check'
  | 'Concat'
  | 'Copy'
  | 'Dashboard'
  | 'default'
  | 'Delete'
  | 'DeleteFolder'
  | 'Deselect'
  | 'Download'
  | 'Edit'
  | 'ExcelImport'
  | 'Favorite'
  | 'freetext'
  | 'FullWidth'
  | 'Height'
  | 'iSimilarizer'
  | 'Merge'
  | 'MergeRepeat'
  | 'NewCapture'
  | 'modal'
  | 'NewLine'
  | 'PieChart'
  | 'Refresh'
  | 'RefreshRed'
  | 'Reject'
  | 'Restore'
  | 'Save'
  | 'SaveRed'
  | 'Send'
  | 'Split'
  | 'Start Analysis'
  | 'Translation';
export type WithPluginProps = {
  onClick?: any;
  onTranslate?: any;
  name?: WithPluginEnum;
  items?: string[];
  onChange?: any;
  title?: string;
  mulitple?: boolean;
  loading?: boolean;
  languageCode?: string;
  languageOriginCode?: LangCode;
  languages?: LangCode[];
  hide?: boolean;
  showSel?: any;
};

function withPlugin<T extends WithPluginProps>(Component: any) {
  return (props: T) => {
    const { name } = props;
    return (
      <Plugin name={`toolbar-${name}`}>
        {/* DO not change the Template's f*cking name */}
        <Template name="toolbarContent">
          <TemplatePlaceholder />
          <Component {...(props as T)} name={name} />
        </Template>
      </Plugin>
    );
  };
}

export default withPlugin;
