import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ChangebleReadiness from './components/ChangebleReadiness';
import EnggReadiness from './components/EnggReadiness';
import EnggRedinessHome from './components/EnggRedinessHome';
import NonChangebleReadiness from './components/NonChangebleReadiness';
import Owning from './components/Owning';
import UserContact from './components/UserContact';
import UserInbox from './components/UserInbox';
import UserProfile from './components/UserProfile';
import TalentHR from './components/Talent-HR/TalentHR';
import MyObjectives from './components/MyObjectives/CreateObjective/MyObjectives';
import MyOrgChart from './components/MyObjectives/CheckObjective/MyOrgChart';
import OrgAwareness from './components/MyObjectives/OrgAwareness/OrgAwareness';
import NewTalentHR from './components/Talent-HR/NewTalentHR';

const UserRouter = () => {
  return (
    <Switch>
      <Route exact path="/me/profile" component={UserProfile} />
      <Route exact path="/me/Inbox" component={UserInbox} />
      <Route exact path="/me/readiness" component={EnggReadiness} />
      <Route exact path="/me/contact" component={UserContact} />
      <Route exact path="/me/MyiContribution" component={NewTalentHR} />
      <Route exact path="/me/createobjective" component={MyObjectives} />
      <Route exact path="/me/checkobjective" component={MyOrgChart} />
      <Route exact path="/me/organizationAwareness" component={OrgAwareness} />
      <Route exact path="/me/owning" component={Owning} />
      <Route exact path="/me/engg" component={NonChangebleReadiness} />
      <Route exact path="/me/enggSingle" component={ChangebleReadiness} />
      <Route exact path="/me/enggReadinessHome" component={EnggRedinessHome} />
    </Switch>
  );
};

export default UserRouter;
