/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useContext } from 'react';
import { Avatar, Button, FormControl, Grid, makeStyles } from '@material-ui/core';
import MultiSelect from 'react-multi-select-component';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { iSearcheSixLayerNew, textToText, textToTextInitialFilter } from '../../../api/module2';
import M2GlobalContext from '../../ContextMager/M2GlobalContext';
import m5 from '../../../assets/m5_blue.png';
import m1 from '../../../assets/m1_blue.png';
import m2 from '../../../assets/m2_blue.png';
import m3 from '../../../assets/m3_blue.png';
import m4 from '../../../assets/m4_blue.png';

const useStyles = makeStyles((theme: Theme) => ({
  multiselect: {
    '& .select-panel': {
      // overflowY: 'scroll',
      maxHeight: '22.5vh',
      overflowX: 'hidden',
    },
    '& .options': {
      overflowY: 'unset',
    },
  },
  formControlMulti: {
    // margin: theme.spacing(1),
    // minWidth: '100%',
    // maxWidth: '50%',
    // margin: theme.spacing(1),
    minWidth: 180,
    maxWidth: 180,
  },
  formControlMultiTextlebel: {
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
}));

const Sixlayer = ({ ChatGptFilter }: any) => {
  const selClasses = useStyles();
  // const [alstomEntities, setAlstomEntities] = useState<any>([]);
  const { state, dispatch } = useContext(M2GlobalContext);
  // console.log('state', state);
  const { similarityFilter } = state;
  const {
    simi_FilterPlatforms,
    simi_AlstomEntities,
    simi_FilterProjects,
    simi_FilterDocumentProfiles,
    simi_FilterSolution,
    simi_FilterProduct,
    dropDown1,
    dropDown2,
    dropDown3,
    dropDown4,
    dropDown5,
    dropDown6,
  } = similarityFilter;

  // console.log(similarityFilter);

  function multifilterCall(
    en: any,
    plat: any,
    proj: any,
    docprof: any,
    sol: any,
    prod: any,
    extra: any
  ) {
    const entityVal: any = [];
    const platformVal: any = [];
    const projectVal: any = [];
    const documentProfileVal: any = [];
    const solutionVal: any = [];
    const productVal: any = [];
    en?.forEach((o: any) => {
      entityVal.push(o.value);
    });
    plat?.forEach((o: any) => {
      platformVal.push(o.value);
    });
    sol?.forEach((o: any) => {
      solutionVal.push(o.value);
    });
    prod?.forEach((o: any) => {
      productVal.push(o.value);
    });
    proj?.forEach((o: any) => {
      projectVal.push(o.value);
    });
    docprof?.forEach((o: any) => {
      documentProfileVal.push(o.value);
    });

    textToText(entityVal, platformVal, solutionVal, productVal, projectVal, documentProfileVal)
      .then((payload: any) => {
        if (payload) {
          // console.log(payload);
          const entityAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.alstomEntities) {
            const item = payload.filters.alstomEntities[i];
            entityAfterFilter.push({
              label: item,
              value: item,
            });
          }
          // if (extra !== 'noChange_entity') {
          //   setAlstomEntities(entityAfterFilter);
          // }
          const platformAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.platforms) {
            const item = payload.filters.platforms[i];
            platformAfterFilter.push({
              label: item,
              value: item,
            });
          }

          const solutionAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.solutions) {
            const item = payload.filters.solutions[i];
            solutionAfterFilter.push({
              label: item,
              value: item,
            });
          }

          const productAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.products) {
            const item = payload.filters.products[i];
            productAfterFilter.push({
              label: item,
              value: item,
            });
          }

          // if (extra !== 'noChange_platform') {
          //   setFilterPlatforms(platformAfterFilter);
          // }
          const projectAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.projects) {
            const item = payload.filters.projects[i];
            projectAfterFilter.push({
              label: item,
              value: item,
            });
          }
          // if (extra !== 'noChange_project') {
          //   setFilterProjects(projectAfterFilter);
          // }
          const documentProfileAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.documentProfiles) {
            const item = payload.filters.documentProfiles[i];
            documentProfileAfterFilter.push({
              label: item,
              value: item,
            });
          }
          // if (extra !== 'noChange_documentProfile') {
          //   setFilterDocumentProfiles(documentProfileAfterFilter);
          // }

          switch (extra) {
            case 'change_entity': {
              const obj = {
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_entity', payload: obj });

              const obj2 = {
                filterPlatforms: platformAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterCountry: [],
              };
              dispatch({ type: 'noChange_in_productLine', payload: obj2 });

              break;
            }

            case 'change_platform': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_platform', payload: obj });

              const obj2 = {
                productLine: entityAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterCountry: [],
              };
              dispatch({ type: 'noChange_in_platform', payload: obj2 });
              break;
            }

            case 'change_solution': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_solution', payload: obj });

              const obj2 = {
                productLine: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterProduct: productAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterCountry: [],
              };
              dispatch({ type: 'noChange_in_Solution', payload: obj2 });

              break;
            }

            case 'change_product': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_product', payload: obj });

              const obj2 = {
                productLine: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterCountry: [],
              };
              dispatch({ type: 'noChange_in_Product', payload: obj2 });

              break;
            }

            case 'change_project': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_project', payload: obj });

              const obj2 = {
                productLine: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterCountry: [],
              };
              dispatch({ type: 'noChange_in_project', payload: obj2 });

              break;
            }

            case 'change_documentProfile': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };

              dispatch({ type: 'simi_noChange_in_documentProfile', payload: obj });

              const obj2 = {
                productLine: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
                filterProjects: projectAfterFilter,
                filterCountry: [],
              };
              dispatch({ type: 'noChange_in_documentProfile', payload: obj2 });

              break;
            }
            default:
              break;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }

  // function multifilterCallIsearcher(
  //   en: any,
  //   plat: any,
  //   sol: any,
  //   prod: any,
  //   proj: any,
  //   docprof: any,
  //   cont: any,
  //   extra: any,
  //   m: any
  // ) {
  //   const entityVal: any = [];
  //   const platformVal: any = [];
  //   const solutionVal: any = [];
  //   const productVal: any = [];
  //   const projectVal: any = [];
  //   const documentProfileVal: any = [];
  //   const countryVal: any = [];
  //   en.forEach((o: any) => {
  //     entityVal.push(o.value);
  //   });
  //   plat.forEach((o: any) => {
  //     platformVal.push(o.value);
  //   });
  //   sol.forEach((o: any) => {
  //     solutionVal.push(o.value);
  //   });
  //   prod.forEach((o: any) => {
  //     productVal.push(o.value);
  //   });
  //   proj.forEach((o: any) => {
  //     projectVal.push(o.value);
  //   });
  //   docprof.forEach((o: any) => {
  //     documentProfileVal.push(o.value);
  //   });
  //   cont.forEach((o: any) => {
  //     countryVal.push(o.value);
  //   });
  //   // console.log(m);
  //   if (m === 1) {
  //     iSearcheSixLayerNew(
  //       entityVal,
  //       platformVal,
  //       solutionVal,
  //       productVal,
  //       projectVal,
  //       documentProfileVal,
  //       countryVal
  //     )
  //       .then((payload: any) => {
  //         if (payload) {
  //           // console.log(payload);
  //           const entityAfterFilter: any = [];
  //           // eslint-disable-next-line guard-for-in
  //           for (const i in payload.filters?.productLines) {
  //             const item = payload.filters.productLines[i];
  //             entityAfterFilter.push({
  //               label: item,
  //               value: item,
  //             });
  //           }
  //           // if (extra !== 'noChange_entity') {
  //           //   setAlstomEntities2(entityAfterFilter);
  //           // }

  //           const platformAfterFilter: any = [];
  //           // eslint-disable-next-line guard-for-in
  //           for (const i in payload.filters?.platforms) {
  //             const item = payload.filters.platforms[i];
  //             platformAfterFilter.push({
  //               label: item,
  //               value: item,
  //             });
  //           }
  //           // if (extra !== 'noChange_platform') {
  //           //   setFilterPlatforms2(platformAfterFilter);
  //           // }
  //           const projectAfterFilter: any = [];
  //           // eslint-disable-next-line guard-for-in
  //           for (const i in payload.filters?.projects) {
  //             const item = payload.filters.projects[i];
  //             projectAfterFilter.push({
  //               label: item,
  //               value: item,
  //             });
  //           }
  //           // if (extra !== 'noChange_project') {
  //           //   setFilterProjects2(projectAfterFilter);
  //           // }
  //           const documentProfileAfterFilter: any = [];
  //           // eslint-disable-next-line guard-for-in
  //           for (const i in payload.filters?.documentProfiles) {
  //             const item = payload.filters.documentProfiles[i];
  //             documentProfileAfterFilter.push({
  //               label: item,
  //               value: item,
  //             });
  //           }
  //           const solutionAfterFilter: any = [];
  //           // eslint-disable-next-line guard-for-in
  //           for (const i in payload.filters?.solutions) {
  //             const item = payload.filters.solutions[i];
  //             solutionAfterFilter.push({
  //               label: item,
  //               value: item,
  //             });
  //           }
  //           const productAfterFilter: any = [];
  //           // eslint-disable-next-line guard-for-in
  //           for (const i in payload.filters?.products) {
  //             const item = payload.filters.products[i];
  //             productAfterFilter.push({
  //               label: item,
  //               value: item,
  //             });
  //           }
  //           const countryAfterFilter: any = [];
  //           // eslint-disable-next-line guard-for-in
  //           for (const i in payload.filters?.countries) {
  //             const item = payload.filters.countries[i];
  //             countryAfterFilter.push({
  //               label: item,
  //               value: item,
  //             });
  //           }

  //           switch (extra) {
  //             case 'change_entity': {
  //               const obj = {
  //                 filterPlatforms: platformAfterFilter,
  //                 filterSolution: solutionAfterFilter,
  //                 filterProduct: productAfterFilter,
  //                 filterProjects: projectAfterFilter,
  //                 filterDocumentProfiles: documentProfileAfterFilter,
  //                 filterCountry: countryAfterFilter,
  //               };
  //               dispatch({ type: 'noChange_in_entity', payload: obj });
  //               break;
  //             }

  //             case 'change_platform': {
  //               const obj = {
  //                 alstomEntities: entityAfterFilter,
  //                 filterSolution: solutionAfterFilter,
  //                 filterProduct: productAfterFilter,
  //                 filterProjects: projectAfterFilter,
  //                 filterDocumentProfiles: documentProfileAfterFilter,
  //                 filterCountry: countryAfterFilter,
  //               };
  //               dispatch({ type: 'noChange_in_platform', payload: obj });
  //               break;
  //             }
  //             case 'change_Solution': {
  //               const obj = {
  //                 alstomEntities: entityAfterFilter,
  //                 filterPlatforms: platformAfterFilter,
  //                 filterProduct: productAfterFilter,
  //                 filterProjects: projectAfterFilter,
  //                 filterDocumentProfiles: documentProfileAfterFilter,
  //                 filterCountry: countryAfterFilter,
  //               };
  //               dispatch({ type: 'noChange_in_Solution', payload: obj });
  //               break;
  //             }
  //             case 'change_Product': {
  //               const obj = {
  //                 alstomEntities: entityAfterFilter,
  //                 filterPlatforms: platformAfterFilter,
  //                 filterSolution: solutionAfterFilter,
  //                 filterProjects: projectAfterFilter,
  //                 filterDocumentProfiles: documentProfileAfterFilter,
  //                 filterCountry: countryAfterFilter,
  //               };
  //               dispatch({ type: 'noChange_in_Product', payload: obj });
  //               break;
  //             }

  //             case 'change_project': {
  //               const obj = {
  //                 alstomEntities: entityAfterFilter,
  //                 filterPlatforms: platformAfterFilter,
  //                 filterSolution: solutionAfterFilter,
  //                 filterProduct: productAfterFilter,
  //                 filterDocumentProfiles: documentProfileAfterFilter,
  //                 filterCountry: countryAfterFilter,
  //               };
  //               dispatch({ type: 'noChange_in_project', payload: obj });
  //               break;
  //             }

  //             case 'change_documentProfile': {
  //               const obj = {
  //                 alstomEntities: entityAfterFilter,
  //                 filterPlatforms: platformAfterFilter,
  //                 filterSolution: solutionAfterFilter,
  //                 filterProduct: productAfterFilter,
  //                 filterProjects: projectAfterFilter,
  //                 filterCountry: countryAfterFilter,
  //               };
  //               dispatch({ type: 'noChange_in_documentProfile', payload: obj });
  //               break;
  //             }
  //             case 'change_Country': {
  //               const obj = {
  //                 alstomEntities: entityAfterFilter,
  //                 filterPlatforms: platformAfterFilter,
  //                 filterSolution: solutionAfterFilter,
  //                 filterProduct: productAfterFilter,
  //                 filterProjects: projectAfterFilter,
  //                 filterDocumentProfiles: documentProfileAfterFilter,
  //               };
  //               dispatch({ type: 'noChange_in_Country', payload: obj });
  //               break;
  //             }
  //             default:
  //               break;
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //       .finally(() => {});
  //   }
  // }

  // multiselect dropdown custom labels
  const customProductLineValue = () => {
    if (!dropDown1?.length) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt="Module 5" style={{ height: 30, width: 30, marginRight: '5px' }} src={m2} />
          <b style={{ color: '#002A45' }}>Product Line</b>
        </div>
      );
    }
  };
  // eslint-disable-next-line consistent-return
  const customPlatformValue = () => {
    if (!dropDown2?.length) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt="Module 2" style={{ height: 30, width: 30, marginRight: '5px' }} src={m2} />
          <b style={{ color: '#002A45' }}>Platform</b>
        </div>
      );
    }
  };

  // eslint-disable-next-line consistent-return
  const customProjectValue = () => {
    if (!dropDown3?.length) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt="Module 2" style={{ height: 30, width: 30, marginRight: '5px' }} src={m2} />
          <b style={{ color: '#002A45' }}>Project</b>
        </div>
      );
    }
  };
  // eslint-disable-next-line consistent-return
  const customDocumentProfileValue = () => {
    if (!dropDown4?.length) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt="Module 2" style={{ height: 30, width: 30, marginRight: '5px' }} src={m2} />
          <b style={{ color: '#002A45' }}>Document Profile</b>
        </div>
      );
    }
  };

  // eslint-disable-next-line consistent-return
  const customSolutionValue = () => {
    if (!dropDown5?.length) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt="Module 2" style={{ height: 30, width: 30, marginRight: '5px' }} src={m2} />
          <b style={{ color: '#002A45' }}>Solution</b>
        </div>
      );
    }
  };

  // eslint-disable-next-line consistent-return
  const customProductValue = () => {
    if (!dropDown6?.length) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt="Module 2" style={{ height: 30, width: 30, marginRight: '5px' }} src={m2} />
          <b style={{ color: '#002A45' }}>Product</b>
        </div>
      );
    }
  };

  function onChangeProductLineValue(option: any) {
    // setDropDown1(option);
    dispatch({ type: 'change_simi_entity', payload: option });
    dispatch({ type: 'isearchser_productLine_change', payload: option });
    // extra argu implemented but not in use
    const extra = 'change_entity';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(option, dropDown2, dropDown3, dropDown4, dropDown5, dropDown6, extra);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  }

  function onChangePlatformValue(option: any) {
    // setDropDown1(option);
    dispatch({ type: 'change_simi_Platform', payload: option });
    dispatch({ type: 'isearchser_Platform_change', payload: option });

    const extra = 'change_platform';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, option, dropDown3, dropDown4, dropDown5, dropDown6, extra);
  }

  function onChangeProjectValue(option: any) {
    // setDropDown1(option);
    dispatch({ type: 'change_simi_Project', payload: option });
    dispatch({ type: 'isearchser_Project_change', payload: option });

    const extra = 'change_project';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, option, dropDown4, dropDown5, dropDown6, extra);
  }

  function onChangeDocumentProfile(option: any) {
    // setDropDown1(option);
    dispatch({ type: 'change_simi_DocumentProfile', payload: option });
    dispatch({ type: 'isearchser_DocProfile_change', payload: option });

    const extra = 'change_documentProfile';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, dropDown3, option, dropDown5, dropDown6, extra);
  }

  function onChangeSolution(option: any) {
    // setDropDown1(option);
    dispatch({ type: 'change_simi_Solution', payload: option });
    dispatch({ type: 'isearchser_Solution_change', payload: option });

    const extra = 'change_solution';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, dropDown3, dropDown4, option, dropDown6, extra);
  }

  function onChangeProduct(option: any) {
    // setDropDown1(option);
    dispatch({ type: 'change_simi_Product', payload: option });
    dispatch({ type: 'isearchser_Product_change', payload: option });
    const extra = 'change_product';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, dropDown3, dropDown4, dropDown5, option, extra);
  }
  const clearFilter = () => {
    dispatch({ type: 'clear_simiFilters' });
    dispatch({ type: 'clear_isearch_filter' });
  };

  return (
    <>
      <div>
        <Grid container spacing={ChatGptFilter === true ? 1 : 2}>
          <Grid item xs={2}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                className={selClasses.multiselect}
                // isOpen={true}
                options={simi_AlstomEntities ?? []}
                labelledBy="Select Product Line"
                value={dropDown1 ?? []}
                onChange={onChangeProductLineValue}
                valueRenderer={customProductLineValue}
                disableSearch={true}
                overrideStrings={{
                  allItemsAreSelected: dropDown1
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                className={selClasses.multiselect}
                // isOpen={true}
                options={simi_FilterPlatforms ?? []}
                labelledBy="Select Platform"
                value={dropDown2 ?? []}
                onChange={onChangePlatformValue}
                valueRenderer={customPlatformValue}
                disableSearch={true}
                overrideStrings={{
                  allItemsAreSelected: dropDown2
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                className={selClasses.multiselect}
                // isOpen={true}
                options={simi_FilterSolution ?? []}
                labelledBy="Select Solution"
                value={dropDown5 ?? []}
                onChange={onChangeSolution}
                valueRenderer={customSolutionValue}
                disableSearch={true}
                overrideStrings={{
                  allItemsAreSelected: dropDown5
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                className={selClasses.multiselect}
                // isOpen={true}
                options={simi_FilterProduct ?? []}
                labelledBy="Select Product"
                value={dropDown6 ?? []}
                onChange={onChangeProduct}
                valueRenderer={customProductValue}
                disableSearch={true}
                overrideStrings={{
                  allItemsAreSelected: dropDown6
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                className={selClasses.multiselect}
                // isOpen={true}
                options={simi_FilterProjects ?? []}
                labelledBy="Select Project"
                value={dropDown3 ?? []}
                onChange={onChangeProjectValue}
                valueRenderer={customProjectValue}
                disableSearch={true}
                overrideStrings={{
                  allItemsAreSelected: dropDown3
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            {!ChatGptFilter && (
              <>
                <FormControl variant="outlined" className={selClasses.formControlMulti}>
                  <MultiSelect
                    className={selClasses.multiselect}
                    // isOpen={true}
                    options={simi_FilterDocumentProfiles ?? []}
                    labelledBy="Select Document Profile"
                    value={dropDown4 ?? []}
                    onChange={onChangeDocumentProfile}
                    valueRenderer={customDocumentProfileValue}
                    disableSearch={true}
                  />
                </FormControl>
              </>
            )}
            {ChatGptFilter === true && (
              <Button
                type="button"
                variant="contained"
                // onClick={() => {}}
                style={{
                  height: '38px',
                  // width: '150px',
                  // marginTop: -5,
                  float: 'right',
                  // marginTop: 3,
                  // padding: '5px 0px 2px 0px',
                  backgroundColor: '#19aa6e',
                  color: 'white',
                  // boxShadow: '0px 0px 19px #00000016',
                }}
                // size="small"
                onClick={() => clearFilter()}
                // color="primary"
              >
                <FilterAltOutlinedIcon />
                Clear Filter
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Sixlayer;
