import { Box, Button, TextField, Typography, CircularProgress, Checkbox } from '@material-ui/core';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { iCaptureCsv, iCaptureExcel } from '../../../../../../../api/module1';
import {
  UserWorkloadCreate,
  userWorkloadRunPost,
  userWorkloadRunPostPDF,
  userWorkloadRunDocToDocPDF,
  UserWorkloadRunPostPdfCsv,
} from '../../../../../../../api/workloads';
import M2GlobalContext from '../../../../../../ContextMager/M2GlobalContext';
import WorkSpaceContext from '../../../../../WorkspceContext';
import DocAnalysisContext from '../../../DocAnalysisContext';

const Container = styled.div`
  background-color: #eef1f9;
  padding: 1rem;
  border-top: 1px solid #002a45;
`;

const CaptureNameSettings = () => {
  const { scopeSetting, operation, fileSetting, setToggleTab } = useContext(DocAnalysisContext);
  const docOperation = [
    'Similarity Analysis',
    'Document Similarity',
    'Compare Versions',
    'Extract Topics',
    // 'Translate',
    'Find Duplicates',
  ];
  const [loadingResult, setLoadingResult] = React.useState(false);
  const { setSnack } = useContext(WorkSpaceContext);
  const [captureName, setCaptureName] = React.useState('');
  const [boolValue, setBoolValue] = React.useState(false);
  const { state, dispatch } = useContext(M2GlobalContext);
  const { similarityFilter } = state;
  const {
    simi_AlstomEntitiesDef,
    simi_FilterProjectsDef,
    simi_FilterDocumentProfilesDef,
    simi_FilterPlatformsDef,
    simi_FilterSolutionDef,
    simi_FilterProductDef,
    dropDown1,
    dropDown2,
    dropDown3,
    dropDown4,
    dropDown5,
    dropDown6,
  } = similarityFilter;

  const HandleValidation = () => {
    const FileterkeysSettings = Object.keys(fileSetting);
    for (let i = 0; i < FileterkeysSettings.length - 1; i += 1) {
      const element = FileterkeysSettings[i];
      if (fileSetting[element]?.fileType === 'Excel') {
        if (!fileSetting[element]?.sheetName) {
          setSnack('Please Enter Excel Sheet Column', 'error');
          return false;
        }
        if (!fileSetting[element]?.targetColumn) {
          setSnack('Please Enter Excel Target Column', 'error');
          return false;
        }
      } else if (fileSetting[element].fileType === 'CSV') {
        if (!fileSetting[element]?.columnName) {
          setSnack('Please Enter CSV Column Name', 'error');
          return false;
        }
      }
    }
    if (!captureName) {
      setSnack('Please Enter Activity Name', 'error');
      return false;
    }
    return true;
  };

  function handleAnalyzePdfiles(service: string) {
    let servicesType: any = null;
    if (captureName.trim().length === 0) {
      setSnack('Enter activity name', 'error');
      return;
    }
    setLoadingResult(true);
    let fileone = null;
    let filetwo = null;
    if (scopeSetting[0].FileType === 'PDF') {
      fileone = {
        output: { NameOfCapture: captureName, FileName: scopeSetting[0].Blob },
        TextLength: 10000,
        settingsModule: {
          FileName: scopeSetting[0].Blob,
          FilePath: scopeSetting[0].FilePath,
          Container: scopeSetting[0].Container,
          BlobName: scopeSetting[0].Blob,
          id: scopeSetting[0].Blob,
          FileType: 'pdf',
          FileIndex: 0,
          Language: scopeSetting[0].Language,
          StartPage: 0,
          EndPage: 0,
          HeaderMargin: 0,
          FooterMargin: 0,
          TypesOfCapture: 'Paragraph_Capture',
          RegId: '',
          TableSwitch: true,
          MergeBullet: false,
          SmartHeaderFooter: true,
          SmartTableOfContent: true,
          TranslateTo: ['en'],
          NameOfCapture: captureName,
        },
      };
    }
    if (scopeSetting[1].FileType === 'PDF') {
      filetwo = {
        output: { NameOfCapture: captureName, FileName: scopeSetting[1].Blob },
        TextLength: 10000,
        settingsModule: {
          FileName: scopeSetting[1].Blob,
          FilePath: scopeSetting[1].FilePath,
          Container: scopeSetting[1].Container,
          BlobName: scopeSetting[1].Blob,
          id: scopeSetting[1].Blob,
          FileType: 'pdf',
          FileIndex: 1,
          Language: scopeSetting[1].Language,
          StartPage: 0,
          EndPage: 0,
          HeaderMargin: 0,
          FooterMargin: 0,
          TypesOfCapture: 'Paragraph_Capture',
          RegId: '',
          TableSwitch: true,
          MergeBullet: false,
          SmartHeaderFooter: true,
          SmartTableOfContent: true,
          TranslateTo: ['en'],
          NameOfCapture: captureName,
        },
      };
    }

    if (scopeSetting[0].FileType === 'PDF' && scopeSetting[1].FileType === 'PDF') {
      const bothPdfFile: any = [fileone, filetwo];
      if (service === 'DocToDoc') {
        servicesType = 'DocToDocPDF';
      }
      if (service === 'docdiffer') {
        servicesType = 'm2-docdifferPDF';
      }
      UserWorkloadCreate({
        app: 'module2',
        action: service,
        resources: bothPdfFile,
      })
        .then((newWorkload) => {
          userWorkloadRunDocToDocPDF(Number(newWorkload.id), bothPdfFile, servicesType)
            .then((data) => {
              // console.log('Run post', data);
              setToggleTab({ left: '10rem' });
              setLoadingResult(false);
            })
            .catch(() => {
              setSnack('Error while workload post.', 'error');
              setLoadingResult(false);
            });
        })
        .catch(() => {
          setSnack('Error while workload create.', 'error');
          setLoadingResult(false);
        })
        .finally(() => {});
    } else {
      let filePDF: any = null;
      let fileIndex: any = null;
      if (service === 'DocToDoc') {
        servicesType = 'm2-doctodoc-pdf-csv';
      }
      if (service === 'docdiffer') {
        servicesType = 'm2-docdiffer-pdf-csv';
      }
      if (scopeSetting[0].FileType === 'PDF') {
        filePDF = fileone;
        fileIndex = 1;
      }
      if (scopeSetting[1].FileType === 'PDF') {
        filePDF = filetwo;
        fileIndex = 0;
      }
      const allPromisesFun = [scopeSetting[fileIndex]].map((data, index) => {
        if (data.FileType === 'Excel') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: captureName,
            Sheet: fileSetting[index].sheetName,
            TargetColumn: fileSetting[index].targetColumn,
            ColumnNames: fileSetting[index].columns,
            Header: true,
          };
          return () => {
            return iCaptureExcel(body);
          };
        }

        if (data.FileType === 'CSV') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: captureName,
            TargetColumn: fileSetting[index].columnName,
            ColumnNames: fileSetting[index].columnNames,
            Header: true,
          };
          return () => {
            return iCaptureCsv(body);
          };
        }
        return null;
      });
      Promise.all(allPromisesFun.map((fn) => fn()))
        .then((result) => {
          let len1 = null;
          let len2 = null;
          if (fileIndex === 0) {
            const len1 =
              JSON.parse(result[0].output?.Text)?.length > 0
                ? JSON.parse(result[0].output?.Text)?.length
                : 0;
            len2 = 10000;
          }
          if (fileIndex === 1) {
            const len2 =
              JSON.parse(result[1].output?.Text)?.length > 0
                ? JSON.parse(result[1].output?.Text)?.length
                : 0;
            len1 = 10000;
          }
          if (len1 === 0 || len2 === 0) {
            setSnack('Invalid file format', 'error');
            return;
          }
          const TextLen = len1 > len2 ? len1 : len2;
          const filterScopeSettings = scopeSetting.map((data, index) => {
            if (data.FileType === 'Excel') {
              return {
                ...data,
                Sheet: data?.Sheets?.filter(
                  (data) => data.SheetName === fileSetting[index].sheetName
                )[0],
              };
            }
            return data;
          });

          const r = result.map((data, index) => {
            return {
              ...data,
              FileName: filterScopeSettings[index].Blob,
              scopeSettingOP: [filterScopeSettings[index]],
              TextLength: TextLen,
              filesPDF: filePDF,
            };
          });

          UserWorkloadCreate({
            app: 'module2',
            action: service,
            resources: r,
          })
            .then((workloadNew) => {
              if (service === 'DocToDoc') {
                UserWorkloadRunPostPdfCsv(Number(workloadNew.id), r, servicesType)
                  .then((result) => {
                    // console.log('post run', result);
                    setLoadingResult(false);
                    setToggleTab({ left: '10rem' });
                  })
                  .catch(() => {
                    setSnack('Error while workload post.', 'error');
                    setLoadingResult(false);
                  });
              } else {
                UserWorkloadRunPostPdfCsv(Number(workloadNew.id), r, servicesType)
                  .then((result) => {
                    // console.log('post run', result);
                    setLoadingResult(false);
                    setToggleTab({ left: '10rem' });
                  })
                  .catch(() => {
                    setSnack('Error while workload post.', 'error');
                    setLoadingResult(false);
                  });
              }
            })
            .catch(() => {
              setSnack('Error while workload create.', 'error');
              setLoadingResult(false);
            });
        })
        .catch(() => {
          setSnack('Error while capture.', 'error');
          setLoadingResult(false);
        });
    }
  }

  const TwoFileAnalysis = (service: string) => {
    const fileExtensionOne = scopeSetting[0]?.FileName.toLowerCase().split('.').pop();
    const fileExtensionTwo = scopeSetting[1]?.FileName.toLowerCase().split('.').pop();
    if (fileExtensionOne === 'pdf' || fileExtensionTwo === 'pdf') {
      handleAnalyzePdfiles(service);
    } else if (HandleValidation()) {
      setLoadingResult(true);
      const allPromisesFun = scopeSetting.map((data, index) => {
        if (data.FileType === 'Excel') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: captureName,
            Sheet: fileSetting[index].sheetName,
            TargetColumn: fileSetting[index].targetColumn,
            ColumnNames: fileSetting[index].columns,
            Header: true,
          };
          return () => {
            return iCaptureExcel(body);
          };
        }

        if (data.FileType === 'CSV') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: captureName,
            TargetColumn: fileSetting[index].columnName,
            ColumnNames: fileSetting[index].columnNames,
            Header: true,
          };
          return () => {
            return iCaptureCsv(body);
          };
        }
        return null;
      });
      Promise.all(allPromisesFun.map((fn) => fn()))
        .then((result) => {
          const len1 =
            JSON.parse(result[0].output?.Text)?.length > 0
              ? JSON.parse(result[0].output?.Text)?.length
              : 0;
          const len2 =
            JSON.parse(result[1].output?.Text)?.length > 0
              ? JSON.parse(result[1].output?.Text)?.length
              : 0;
          if (len1 === 0 || len2 === 0) {
            setSnack('Invalid file format', 'error');
            return;
          }
          const TextLen = len1 > len2 ? len1 : len2;
          const filterScopeSettings = scopeSetting.map((data, index) => {
            if (data.FileType === 'Excel') {
              return {
                ...data,
                Sheet: data?.Sheets?.filter(
                  (data) => data.SheetName === fileSetting[index].sheetName
                )[0],
              };
            }
            return data;
          });

          const r = result.map((data, index) => {
            return {
              ...data,
              FileName: filterScopeSettings[index].Blob,
              scopeSettingOP: [filterScopeSettings[index]],
              TextLength: TextLen,
            };
          });

          UserWorkloadCreate({
            app: 'module2',
            action: service,
            resources: r,
          })
            .then((workloadNew) => {
              if (service === 'DocToDoc') {
                userWorkloadRunPost(Number(workloadNew.id), r, `m2-${service.toLowerCase()}`)
                  .then((result) => {
                    // console.log('post run', result);
                    setLoadingResult(false);
                    setToggleTab({ left: '10rem' });
                  })
                  .catch(() => {
                    setSnack('Error while workload post.', 'error');
                    setLoadingResult(false);
                  });
              } else {
                userWorkloadRunPost(Number(workloadNew.id), { r }, `m2-${service.toLowerCase()}`)
                  .then((result) => {
                    // console.log('post run', result);
                    setLoadingResult(false);
                    setToggleTab({ left: '10rem' });
                  })
                  .catch(() => {
                    setSnack('Error while workload post.', 'error');
                    setLoadingResult(false);
                  });
              }
            })
            .catch(() => {
              setSnack('Error while workload create.', 'error');
              setLoadingResult(false);
            });
        })
        .catch(() => {
          setSnack('Error while capture.', 'error');
          setLoadingResult(false);
        });
    }
  };

  const docToDatasetPdfFile = () => {
    if (!captureName) {
      setSnack('Please Enter Activity Name', 'error');
      return;
    }
    // start here
    let entityIs: any = [];
    let platform: any = [];
    let sol: any = [];
    let prod: any = [];
    let project: any = [];
    let documentProfile: any = [];
    // console.log(alignment);
    dropDown1.forEach((obj: any) => {
      entityIs.push(obj.value);
    });
    dropDown2.forEach((obj: any) => {
      platform.push(obj.value);
    });
    dropDown3.forEach((obj: any) => {
      project.push(obj.value);
    });
    dropDown4.forEach((obj: any) => {
      documentProfile.push(obj.value);
    });
    dropDown5.forEach((obj: any) => {
      sol.push(obj.value);
    });
    dropDown6.forEach((obj: any) => {
      prod.push(obj.value);
    });

    if (entityIs.length === 0 || entityIs.length === simi_AlstomEntitiesDef.length) {
      entityIs = ['*'];
    }
    if (platform.length === 0 || platform.length === simi_FilterPlatformsDef.length) {
      platform = ['*'];
    }
    if (sol.length === 0 || sol.length === simi_FilterSolutionDef.length) {
      sol = ['*'];
    }
    if (prod.length === 0 || prod.length === simi_FilterProductDef.length) {
      prod = ['*'];
    }
    if (project.length === 0 || project.length === simi_FilterProjectsDef.length) {
      project = ['*'];
    }
    if (
      documentProfile.length === 0 ||
      documentProfile.length === simi_FilterDocumentProfilesDef.length
    ) {
      documentProfile = ['*'];
    }
    // done here
    const generateComplianceStatus = boolValue;
    const r = [
      {
        output: { NameOfCapture: captureName, FileName: scopeSetting[0].Blob },
        TextLength: 100000,
        settingsModule: {
          FileName: scopeSetting[0].Blob,
          FilePath: scopeSetting[0].FilePath,
          Container: scopeSetting[0].Container,
          BlobName: scopeSetting[0].Blob,
          id: scopeSetting[0].Blob,
          FileType: 'pdf',
          Language: scopeSetting[0].Language,
          StartPage: 0,
          EndPage: 0,
          HeaderMargin: 0,
          FooterMargin: 0,
          TypesOfCapture: 'Paragraph_Capture',
          RegId: '',
          TableSwitch: true,
          MergeBullet: false,
          SmartHeaderFooter: true,
          SmartTableOfContent: true,
          TranslateTo: ['en'],
          NameOfCapture: captureName,
          modeService: 'DocToDataset',
        },
        doctodatset: {
          businessEntity: entityIs,
          generateComplianceStatus,
          platform,
          solution: sol,
          product: prod,
          project,
          documentProfile,
        },
      },
    ];
    UserWorkloadCreate({
      app: 'module2',
      action: 'DocToDataset',
      resources: r,
    })
      .then((newWorkload) => {
        userWorkloadRunPostPDF(Number(newWorkload.id), r, 'm2-multi-text')
          .then((data) => {
            // console.log('Run post', data);
            setToggleTab({ left: '10rem' });
            setLoadingResult(false);
          })
          .catch(() => {
            setSnack('Error while workload post.', 'error');
            setLoadingResult(false);
          });
      })
      .catch(() => {
        setSnack('Error while workload create.', 'error');
        setLoadingResult(false);
      })
      .finally(() => {});
  };

  const SimilarityAnalysis = (service: string) => {
    if (scopeSetting[0]?.FileType === 'PDF') {
      docToDatasetPdfFile();
    } else if (HandleValidation()) {
      setLoadingResult(true);
      let entityIs: any = [];
      let platform: any = [];
      let project: any = [];
      let documentProfile: any = [];
      let sol: any = [];
      let prod: any = [];
      dropDown1.forEach((obj: any) => {
        entityIs.push(obj.value);
      });
      dropDown2.forEach((obj: any) => {
        platform.push(obj.value);
      });
      dropDown3.forEach((obj: any) => {
        project.push(obj.value);
      });
      dropDown4.forEach((obj: any) => {
        documentProfile.push(obj.value);
      });
      dropDown5.forEach((obj: any) => {
        sol.push(obj.value);
      });
      dropDown6.forEach((obj: any) => {
        prod.push(obj.value);
      });

      if (entityIs.length === 0 || entityIs.length === simi_AlstomEntitiesDef.length) {
        entityIs = ['*'];
      }
      if (platform.length === 0 || platform.length === simi_FilterPlatformsDef.length) {
        platform = ['*'];
      }
      if (project.length === 0 || project.length === simi_FilterProjectsDef.length) {
        project = ['*'];
      }
      if (
        documentProfile.length === 0 ||
        documentProfile.length === simi_FilterDocumentProfilesDef.length
      ) {
        documentProfile = ['*'];
      }
      if (sol.length === 0 || sol.length === simi_FilterSolutionDef.length) {
        sol = ['*'];
      }
      if (prod.length === 0 || prod.length === simi_FilterProductDef.length) {
        prod = ['*'];
      }

      const allPromisesFun = scopeSetting.map((data, index) => {
        if (data.FileType === 'Excel') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: 'm2-iSimilarizer_Doc2Dataset_Excel',
            Sheet: fileSetting[index].sheetName,
            TargetColumn: fileSetting[index].targetColumn,
            ColumnNames: fileSetting[index].columns,
            Header: true,
            businessEntity: entityIs,
            platform,
            project,
            documentProfile,
          };
          return () => {
            return iCaptureExcel(body);
          };
        }

        if (data.FileType === 'CSV') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: 'm2-iSimilarizer_Doc2Dataset_Csv',
            TargetColumn: fileSetting[index].columnName,
            ColumnNames: fileSetting[index].columnNames,
            Header: true,
          };
          return () => {
            return iCaptureCsv(body);
          };
        }
        return null;
      });

      Promise.all(allPromisesFun.map((fn) => fn()))
        .then((payload) => {
          const len =
            JSON.parse(payload[0].output.Text)?.length > 0
              ? JSON.parse(payload[0].output.Text)?.length
              : 0;
          if (len === 0) {
            setSnack('Invalid file format', 'error');
            return;
          }

          const rPayload = scopeSetting.map((data, index) => {
            if (data.FileType === 'Excel') {
              const r = {
                Text: payload[0].output.Text,
                TextLength: len,
                selectedColumn: fileSetting[index].targetColumn,
                generateComplianceBoolean: boolValue,
                entityPassed: entityIs,
                platformPassed: platform,
                projectPassed: project,
                documentProfilePassed: documentProfile,
                solutionPassed: sol,
                productPassed: prod,
                FileName: data.Blob,
                FilePath: data.FilePath,
                NameOfCapture: captureName,
                Sheet: fileSetting[index].sheetName,
              };
              return r;
            }
            if (data.FileType === 'CSV') {
              const r = {
                Text: payload[0].output.Text,
                TextLength: len,
                selectedColumn: fileSetting[index].columnName,
                generateComplianceBoolean: boolValue,
                entityPassed: entityIs,
                platformPassed: platform,
                projectPassed: project,
                documentProfilePassed: documentProfile,
                solutionPassed: sol,
                productPassed: prod,
                FileName: data.Blob,
                FilePath: data.FilePath,
                NameOfCapture: captureName,
              };
              return r;
            }
            return null;
          });
          UserWorkloadCreate({
            app: 'module2',
            action: 'DocToDataset',
            resources: rPayload,
          })
            .then((newWorkload) => {
              userWorkloadRunPost(
                Number(newWorkload.id),
                {
                  dataframe: { Text: payload[0].output.Text },
                  column_name: fileSetting[0].columnName ?? fileSetting[0].targetColumn,
                  generateComplianceStatus: boolValue,
                  businessEntity: entityIs,
                  platform,
                  project,
                  solution: sol,
                  product: prod,
                  documentProfile,
                },
                'm2-multi-text'
              )
                .then((data) => {
                  // console.log('Run post', data);
                  setToggleTab({ left: '10rem' });
                  setLoadingResult(false);
                })
                .catch(() => {
                  setSnack('Error while workload post.', 'error');
                  setLoadingResult(false);
                });
            })
            .catch(() => {
              setSnack('Error while workload create.', 'error');
              setLoadingResult(false);
            })
            .finally(() => {});
        })
        .catch(() => {
          setSnack('Error while capture', 'error');
          setLoadingResult(false);
        })
        .finally(() => {});
    }
  };

  const handleOneFileServise = (service: string) => {
    if (HandleValidation()) {
      setLoadingResult(true);
      console.log(scopeSetting);
      if (scopeSetting[0].FileType === 'PDF') {
        const r = [
          {
            output: { NameOfCapture: captureName, FileName: scopeSetting[0].Blob },
            TextLength: 100000,
            settingsModule: {
              FileName: scopeSetting[0].Blob,
              FilePath: scopeSetting[0].FilePath,
              Container: scopeSetting[0].Container,
              BlobName: scopeSetting[0].Blob,
              id: scopeSetting[0].Blob,
              FileType: 'pdf',
              Language: scopeSetting[0].Language,
              StartPage: 0,
              EndPage: 0,
              HeaderMargin: 0,
              FooterMargin: 0,
              TypesOfCapture: 'Paragraph_Capture',
              RegId: '',
              TableSwitch: true,
              MergeBullet: false,
              SmartHeaderFooter: true,
              SmartTableOfContent: true,
              TranslateTo: ['en'],
              NameOfCapture: captureName,
              modeService: 'SelfDoc',
            },
          },
        ];
        UserWorkloadCreate({
          app: 'module2',
          action: 'SelfDoc',
          resources: r,
        })
          .then((newWorkload) => {
            userWorkloadRunPostPDF(Number(newWorkload.id), r, 'm2-selfdoc')
              .then((data) => {
                // console.log('Run post', data);
                setToggleTab({ left: '10rem' });
                setLoadingResult(false);
              })
              .catch(() => {
                setSnack('Error while workload post.', 'error');
                setLoadingResult(false);
              })
              .finally(() => {});
          })
          .catch(() => {
            setSnack('Error while workload create.', 'error');
            setLoadingResult(false);
          })
          .finally(() => {});
      } else {
        const allPromisesFun = scopeSetting.map((data, index) => {
          if (data.FileType === 'Excel') {
            const body = {
              FilePath: data.FilePath,
              Container: data.Container,
              BlobName: data.Blob,
              NameOfCapture: captureName,
              Sheet: fileSetting[index].sheetName,
              TargetColumn: fileSetting[index].targetColumn,
              ColumnNames: fileSetting[index].columns,
              Header: true,
            };
            return () => {
              return iCaptureExcel(body);
            };
          }

          if (data.FileType === 'CSV') {
            const body = {
              FilePath: data.FilePath,
              Container: data.Container,
              BlobName: data.Blob,
              NameOfCapture: captureName,
              TargetColumn: fileSetting[index].columnName,
              ColumnNames: fileSetting[index].columnNames,
              Header: true,
            };
            return () => {
              return iCaptureCsv(body);
            };
          }
          return null;
        });

        Promise.all(allPromisesFun.map((fn) => fn()))
          .then((payload) => {
            // console.log(payload);
            const len =
              JSON.parse(payload[0]?.output?.Text)?.length > 0
                ? JSON.parse(payload[0]?.output?.Text)?.length
                : 0;
            if (len === 0) {
              setSnack('Invalid file format', 'error');
              return;
            }

            const filterScopeSettings = scopeSetting.map((data, index) => {
              if (data.FileType === 'Excel') {
                return {
                  ...data,
                  Sheet: data?.Sheets?.filter(
                    (data) => data.SheetName === fileSetting[index].sheetName
                  )[0],
                  SheetName: fileSetting[index].sheetName,
                  TargetColumn: fileSetting[index].targetColumn,
                };
              }

              if (data.FileType === 'CSV') {
                return {
                  ...data,
                  TargetColumn: fileSetting[index].columnName,
                  ColumnNames: fileSetting[index].columnNames,
                };
              }
              return data;
            });

            const r = payload.map((data, index) => {
              return {
                ...data,
                FileName: filterScopeSettings[index].Blob,
                scopeSettingOP: filterScopeSettings,
                TextLength: len,
              };
            });
            UserWorkloadCreate({
              app: 'module2',
              action: 'SelfDoc',
              resources: r,
            })
              .then((newWorkload) => {
                userWorkloadRunPost(Number(newWorkload.id), r, 'm2-selfdoc')
                  .then((data) => {
                    // console.log('Run post', data);
                    setToggleTab({ left: '10rem' });
                    setLoadingResult(false);
                  })
                  .catch(() => {
                    setSnack('Error while workload post.', 'error');
                    setLoadingResult(false);
                  })
                  .finally(() => {});
              })
              .catch(() => {
                setSnack('Error while workload create.', 'error');
                setLoadingResult(false);
              })
              .finally(() => {});
          })
          .catch(() => {
            setSnack('Error while capture', 'error');
            setLoadingResult(false);
          })
          .finally(() => {});
      }
    }
  };

  const handleExtractTopicsAnalysis = () => {
    if (HandleValidation()) {
      // setLoadingResult(true);
      // console.log(scopeSetting);
      const containerIs = scopeSetting[0].Container;
      const FilterData = scopeSetting.map((data, index) => {
        if (data.FileType === 'Excel') {
          return {
            Container: data.Container,
            Blob: data.Blob,
            SheetName: fileSetting[index].sheetName,
            TargetColumn: fileSetting[index].targetColumn,
          };
        }
        if (data.FileType === 'CSV') {
          return {
            Blob: data.Blob,
            ColumnNamesNoHeader: data.ColumnNamesNoHeader,
            Container: data.Container,
            LanguageCode: data.LanguageCode,
            TargetColumn: fileSetting[index].columnName,
            ColumnNames: fileSetting[index].columnNames,
            TextNoHeader: data.TextNoHeader,
          };
        }
        if (data.FileType === 'PDF') {
          return {
            Blob: data.Blob,
            Container: data.Container,
            LanguageCode: data.LanguageCode,
            TargetColumn: 'forced',
          };
        }
        return null;
      });

      const body = {
        Container: containerIs,
        BlobFiles: FilterData,
        nameOfCapture: captureName,
      };
      UserWorkloadCreate({
        app: 'module2',
        action: 'iner',
        resources: body,
      })
        .then((workloadNew) => {
          userWorkloadRunPost(Number(workloadNew.id), body, 'm2-onto-param-analysis')
            .then(() => {
              setToggleTab({ left: '10rem' });
              setLoadingResult(false);
            })
            .catch(() => {
              setSnack('Error while analyzing the file. Please check the input files.', 'error');
            })
            .finally(() => {});
        })
        .catch(() => {
          setSnack('Error while analyzing the file. Please check the input files.', 'error');
        })
        .finally(() => {});
    }
  };

  const handleAnalyze = () => {
    switch (docOperation[operation]) {
      case 'Similarity Analysis':
        SimilarityAnalysis('DocToDataset');
        break;
      case 'Document Similarity':
        TwoFileAnalysis('DocToDoc');
        break;
      case 'Compare Versions':
        TwoFileAnalysis('docdiffer');
        break;
      case 'Extract Topics':
        handleExtractTopicsAnalysis('iner');
        break;
      case 'Find Duplicates':
        handleOneFileServise('SelfDoc');
        break;
      default:
        break;
    }
  };

  const InputHandler = (e) => {
    setCaptureName(e.target.value);
  };

  const handleCheckboxBool = (e) => {
    setBoolValue(e.target.checked);
  };

  return (
    <>
      <Container>
        {scopeSetting.length === 0 ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography style={{ fontSize: '1rem', fontWeight: 'bold', color: 'rgb(0, 42, 69)' }}>
              Please select a File
            </Typography>
          </div>
        ) : (
          <div>
            <Box style={{ display: 'flex' }}>
              <Box style={{ flexBasis: '30%' }}>{}</Box>
              <Box
                style={{
                  flexBasis: '78%',
                  display: 'flex',
                  height: '2.5rem',
                  gap: '20px',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                {docOperation[operation] === 'Similarity Analysis' && (
                  <Box>
                    <Checkbox
                      defaultChecked={false}
                      onChange={handleCheckboxBool}
                      name="Checkbox"
                      color="primary"
                      value="yes"
                    />
                    Generate Compliance Matrix
                  </Box>
                )}
                <TextField
                  id="outlined-basic"
                  label="Enter Activity Name"
                  variant="outlined"
                  name="captureName"
                  onChange={InputHandler}
                  size="small"
                  style={{
                    width: '170px',
                    height: '38px',
                    marginTop: 6,
                    marginBottom: 6,
                    backgroundColor: 'white',
                  }}
                />
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleAnalyze}
                  startIcon={
                    loadingResult && <CircularProgress style={{ color: 'white' }} size={20} />
                  }
                  style={{
                    width: '150px',
                    height: '38px',
                    marginTop: 6,
                    marginBottom: 6,
                  }}
                >
                  Analyze
                </Button>
              </Box>
            </Box>
          </div>
        )}
      </Container>
    </>
  );
};

export default CaptureNameSettings;
