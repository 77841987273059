import { makeStyles, withStyles } from '@material-ui/core/styles';

const TreeAllStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  heading: {
    backgroundColor: theme.palette.primary.light,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
    borderRadius: 12,
  },
  resultPanel: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: '0px !important',
  },
  searchbtn: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 5,
    marginLeft: 10,
  },
  group: {
    margin: 0,
    padding: 0,
    marginLeft: '0px',
    marginRight: '17px',
  },
  treeRoot: {
    width: '100%',
    marginTop: theme.spacing(2),
    flexGrow: 1,
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    '& .MuiTreeItem-iconContainer svg': {
      fontSize: '18px',
      position: 'relative',
      left: '-20px',
    },
    '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: '#EEF1F9 !important',
    },
    border: '2px solid #002A45',
    borderRadius: '5px',
    // padding: '43px 40px 20px 51px',
    boxShadow: '0px 16px 32px #00000029',
    '&[aria-selected="true"][aria-expanded="true"]>div:nth-of-type(1)': {
      backgroundColor: 'red',
    },
  },
  content: {
    flexDirection: 'row-reverse',
  },
  treeitem: {
    width: '100%',
  },
  treeLabel: {
    borderBottom: '1px solid #002A45',
    paddingLeft: 10,
    color: '#002A45',
    font: 'normal normal bold 25px/28px Alstom',
    letterSpacing: '0px',
    padding: '0 0 10px 0',
    '&:hover': {
      background: '#EEF1F9',
    },
  },
  label: {
    '&hover': {
      backgroundColor: 'none',
    },
    '&.MuiTreeItem-label': {
      width: '100%',
      position: 'relative',
      paddingLeft: '0px',
    },
  },
  treeIcon: {
    width: '40px',
    height: '40px',
    transform: 'translateY(8px)',
    marginRight: '10px',
  },
  treeItems: {
    '&[aria-selected="true"][aria-expanded="true"]>div:nth-of-type(1)': {
      backgroundColor: 'red',
    },
  },
}));
export default TreeAllStyle;
