import { Box, Grid, Paper, Typography, Card, CardContent, CardHeader } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { ThemeProvider } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts';
// import ReactWordcloud from 'react-wordcloud';
import { TagCloud } from 'react-tagcloud';
// import Module1Context from '../../../Module1Context';
import Module1Context from '../../Project/ProjectContext';
import { extractEnt, resultStyle } from '../../../components/Filter/textAnalysis';
import { isensTheme, useStyles } from '../../../app/isensTheme';

// import inerjson from '../../../fixtures/iner.json';

const COLORS = ['#002884', '#9A2318', '#9A2318'];

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${
  y + height
}
          Z`;
const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function INer(val: any) {
  // console.log(val.data);
  const { data } = val;
  const [dataHere, setDataHere] = useState<any>(JSON.parse(data));
  // const [dataHere, setDataHere] = useState<any>(inerjson); // hardcoded fixture data
  const [systemData, setSystemData] = useState<any>([]);
  const [acronymData, setAcronymData] = useState<any>([]);
  const [activityData, setActivityData] = useState<any>([]);
  const [standardsData, setStandardsData] = useState<any>([]);
  const [cyberData, setCyberData] = useState<any>([]);
  const [projectData, setProjectData] = useState<any>([]);
  const [commercialData, setCommercialData] = useState<any>([]);
  const [financeData, setFinanceData] = useState<any>([]);
  const [contractualData, setContractualData] = useState<any>([]);
  const [dataGraph, setDataGraph] = useState<any>([]);
  // for bar graph
  const [barGraphSystemEntities, setBarGraphSystemEntities] = useState<any>([]);
  const [barGraphSystemAcronym, setBarGraphSystemAcronym] = useState<any>([]);
  const [barGraphActivites, setBarGraphActivities] = useState<any>([]);
  const [barGraphStandards, setBarGraphStandards] = useState<any>([]);
  const [barGraphCyber, setBarGraphCyber] = useState<any>([]);
  const [barGraphProject, setBarGraphProject] = useState<any>([]);
  const [barGraphCommercial, setBarGraphCommercial] = useState<any>([]);
  const [barGraphFinance, setBarGraphFinance] = useState<any>([]);
  const [barGraphContractual, setBarGraphContractual] = useState<any>([]);

  const [systemCloudData, setSystemCloudData] = useState<any>([]);
  const [acronymCloudData, setAcronymCloudData] = useState<any>([]);
  const [activityCloudData, setActivityCloudData] = useState<any>([]);
  const [standardsCloudData, setStandardsCloudData] = useState<any>([]);
  const [cyberCloudData, setCyberCloudData] = useState<any>([]);
  const [projectCloudData, setProjectCloudData] = useState<any>([]);
  const [commercialCloudData, setCommercialCloudData] = useState<any>([]);
  const [financeCloudData, setFinanceCloudData] = useState<any>([]);
  const [contractualCloudData, setContractualCloudData] = useState<any>([]);

  const { setSnack } = useContext(Module1Context);

  const classes2 = extractEnt();
  const classes3 = resultStyle();

  useEffect(() => {
    // console.log('dataHere: ', dataHere);
    const systemArr: any[] = [];
    const acronymArr: any[] = [];
    const activityArr: any[] = [];
    const standardsArr: any[] = [];
    const cyberArr: any[] = [];
    const projectArr: any[] = [];
    const commercialArr: any[] = [];
    const financeArr: any[] = [];
    const contractualArr: any[] = [];

    const systemCloudArr: any[] = [];
    const acronymCloudArr: any[] = [];
    const activityCloudArr: any[] = [];
    const standardsCloudArr: any[] = [];
    const cyberCloudArr: any[] = [];
    const projectCloudArr: any[] = [];
    const commercialCloudArr: any[] = [];
    const financeCloudArr: any[] = [];
    const contractualCloudArr: any[] = [];

    let systemDataTotal: Number = 0;
    let acronymDataTotal: Number = 0;
    let activityDataTotal: Number = 0;
    let standardsDataTotal: Number = 0;
    let cyberDataTotal: Number = 0;
    let projectDataTotal: Number = 0;
    let commercialDataTotal: Number = 0;
    let financeDataTotal: Number = 0;
    let contractualDataTotal: Number = 0;

    const initialValues = dataHere.entitiesFreq
      .sort((a: any, b: any) => {
        return a.count - b.count;
      })
      .reverse();
    // console.log(initialValues);
    // eslint-disable-next-line array-callback-return
    initialValues.map((d: { entType: string }, i: string | number) => {
      // system
      if (d.entType === 'System') {
        systemArr.push(initialValues[i]);
        systemCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        systemDataTotal += initialValues[i].count;
      }
      // Acronym
      if (d.entType === 'Acronym') {
        // console.log(d.entType);
        acronymArr.push(initialValues[i]);
        acronymCloudArr.push({
          value: initialValues[i].entAcronym, // entValue
          count: initialValues[i].count,
        });
        acronymDataTotal += initialValues[i].count;
      }
      // ABS_ent
      if (d.entType === 'ABS_ent') {
        // console.log(d.entType);
        activityArr.push(initialValues[i]);
        activityCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        activityDataTotal += initialValues[i].count;
      }
      // Standards
      if (d.entType === 'Standards') {
        // console.log(d.entType);
        standardsArr.push(initialValues[i]);
        standardsCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        standardsDataTotal += initialValues[i].count;
      }
      // cyber security
      if (d.entType === 'cybersecurity') {
        // console.log(d.entType);
        cyberArr.push(initialValues[i]);
        cyberCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        cyberDataTotal += initialValues[i].count;
      }
      // project management
      // cyber security
      if (d.entType === 'project management') {
        // console.log(d.entType);
        projectArr.push(initialValues[i]);
        projectCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        projectDataTotal += initialValues[i].count;
      }

      // commercial
      if (d.entType === 'Commercial') {
        // console.log(d.entType);
        commercialArr.push(initialValues[i]);
        commercialCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        commercialDataTotal += initialValues[i].count;
      }

      // finance
      if (d.entType === 'Finance') {
        // console.log(d.entType);
        financeArr.push(initialValues[i]);
        financeCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        financeDataTotal += initialValues[i].count;
      }

      // Contractual
      if (d.entType === 'Contractual') {
        // console.log(d.entType);
        contractualArr.push(initialValues[i]);
        contractualCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        contractualDataTotal += initialValues[i].count;
      }
    });

    setSystemData(systemArr);
    setAcronymData(acronymArr);
    setActivityData(activityArr);
    setStandardsData(standardsArr);
    setCyberData(cyberArr);
    setProjectData(projectArr);
    setCommercialData(commercialArr);
    setFinanceData(financeArr);
    setContractualData(contractualArr);

    setBarGraphSystemEntities(systemArr);

    setBarGraphSystemAcronym(
      acronymArr.sort((c: any, d: any) => {
        return c.value - d.value;
      })
    );
    setBarGraphActivities(activityArr);
    // console.log('activityDataTotal', activityDataTotal);
    // console.log('activtyArr', activityArr);

    setBarGraphStandards(standardsArr);
    setBarGraphCyber(cyberArr);
    setBarGraphProject(projectArr);
    setBarGraphCommercial(commercialArr);
    setBarGraphFinance(financeArr);
    setBarGraphContractual(contractualArr);

    setSystemCloudData(systemCloudArr);
    setAcronymCloudData(acronymCloudArr);
    setActivityCloudData(activityCloudArr);
    setStandardsCloudData(standardsCloudArr);
    setCyberCloudData(cyberCloudArr);
    setProjectCloudData(projectCloudArr);
    setCommercialCloudData(commercialCloudArr);
    setFinanceCloudData(financeCloudArr);
    setContractualCloudData(contractualCloudArr);

    const data = [
      { name: 'System Entities', count: systemDataTotal },
      { name: 'Acronyms', count: acronymDataTotal },
      { name: 'Activities', count: activityDataTotal },
    ];
    setDataGraph(data);
  }, []);
  return (
    <ThemeProvider theme={isensTheme}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Typography variant="h6">Extract Entities Details</Typography>
        {systemData.length > 0 && (
          <Box className={classes2.root}>
            <Box className={classes2.entitiesHead}>
              <Typography>System Entities</Typography>
            </Box>
            <Box className={classes2.entitesContent}>
              {systemData.map((item: any) => (
                <Chip
                  avatar={<Avatar>{item.count}</Avatar>}
                  label={item.entValue}
                  color="primary"
                  variant="outlined"
                  title={item.details}
                />
              ))}
            </Box>
          </Box>
        )}
        {activityData.length > 0 && (
          <Box className={classes2.root}>
            <Box className={classes2.entitiesHead}>
              <Typography>Activities</Typography>
            </Box>
            <Box className={classes2.entitesContent}>
              {activityData.map((item: any) => (
                <Chip
                  avatar={<Avatar>{item.count}</Avatar>}
                  label={item.entValue}
                  color="secondary"
                  variant="outlined"
                  title={item.details}
                />
              ))}
            </Box>
          </Box>
        )}
        {acronymData.length > 0 && (
          <Box className={classes2.root}>
            <Box className={classes2.entitiesHead}>
              <Typography>Acronyms</Typography>
            </Box>
            <Box className={classes2.entitesContent}>
              {acronymData.map((item: any) => (
                <Chip
                  avatar={
                    <Avatar
                      style={{
                        color: 'white',
                        backgroundColor: `${isensTheme.palette.green.light}`,
                      }}
                    >
                      {item.count}
                    </Avatar>
                  }
                  label={item.entAcronym} // entValue
                  color={isensTheme.palette.green.light}
                  variant="outlined"
                  title={item.details}
                />
              ))}
            </Box>
          </Box>
        )}
        {standardsData.length > 0 && (
          <Box className={classes2.root}>
            <Box className={classes2.entitiesHead}>
              <Typography>Standards</Typography>
            </Box>
            <Box className={classes2.entitesContent}>
              {standardsData.map((item: any) => (
                <Chip
                  avatar={
                    <Avatar
                      style={{
                        color: 'white',
                        backgroundColor: `${isensTheme.palette.primary.light}`,
                      }}
                    >
                      {item.count}
                    </Avatar>
                  }
                  label={item.entValue} // entValue
                  color={isensTheme.palette.primary.light}
                  variant="outlined"
                  title={item.details}
                />
              ))}
            </Box>
          </Box>
        )}
        {cyberData.length > 0 && (
          <Box className={classes2.root}>
            <Box className={classes2.entitiesHead}>
              <Typography>Cyber Security</Typography>
            </Box>
            <Box className={classes2.entitesContent}>
              {cyberData.map((item: any) => (
                <Chip
                  avatar={
                    <Avatar
                      style={{
                        color: 'white',
                        backgroundColor: `${isensTheme.palette.secondary.light}`,
                      }}
                    >
                      {item.count}
                    </Avatar>
                  }
                  label={item.entValue} // entValue
                  color={isensTheme.palette.secondary.light}
                  variant="outlined"
                  title={item.details}
                />
              ))}
            </Box>
          </Box>
        )}
        {projectData.length > 0 && (
          <Box className={classes2.root}>
            <Box className={classes2.entitiesHead}>
              <Typography>Project Management</Typography>
            </Box>
            <Box className={classes2.entitesContent}>
              {projectData.map((item: any) => (
                <Chip
                  avatar={<Avatar>{item.count}</Avatar>}
                  label={item.entValue}
                  color="primary"
                  variant="outlined"
                  title={item.details}
                />
              ))}
            </Box>
          </Box>
        )}
        {commercialData.length > 0 && (
          <Box className={classes2.root}>
            <Box className={classes2.entitiesHead}>
              <Typography>Commercial</Typography>
            </Box>
            <Box className={classes2.entitesContent}>
              {commercialData.map((item: any) => (
                <Chip
                  avatar={<Avatar>{item.count}</Avatar>}
                  label={item.entValue}
                  color="secondary"
                  variant="outlined"
                  title={item.details}
                />
              ))}
            </Box>
          </Box>
        )}
        {financeData.length > 0 && (
          <Box className={classes2.root}>
            <Box className={classes2.entitiesHead}>
              <Typography>Finance</Typography>
            </Box>
            <Box className={classes2.entitesContent}>
              {financeData.map((item: any) => (
                <Chip
                  avatar={
                    <Avatar
                      style={{
                        color: 'white',
                        backgroundColor: `${isensTheme.palette.green.light}`,
                      }}
                    >
                      {item.count}
                    </Avatar>
                  }
                  label={item.entValue}
                  color="primary"
                  variant="outlined"
                  title={item.details}
                />
              ))}
            </Box>
          </Box>
        )}
        {contractualData.length > 0 && (
          <Box className={classes2.root}>
            <Box className={classes2.entitiesHead}>
              <Typography>Contractual</Typography>
            </Box>
            <Box className={classes2.entitesContent}>
              {contractualData.map((item: any) => (
                <Chip
                  avatar={<Avatar>{item.count}</Avatar>}
                  label={item.entValue}
                  color="primary"
                  variant="outlined"
                  title={item.details}
                />
              ))}
            </Box>
          </Box>
        )}
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Box style={{ display: 'flex', gap: '1rem' }}>
            {barGraphSystemEntities.length > 0 && (
              <Card
                style={barGraphActivites.length > 0 ? { flexBasis: '50%' } : { flexBasis: '100%' }}
                Card
                className={classes3.cardRoot}
              >
                <CardHeader title="System Entites" />
                <CardContent>
                  <Box className={classes3.barGraph}>
                    <BarChart
                      width={
                        barGraphSystemEntities.length < 7 ? 300 : barGraphSystemEntities.length * 40
                      }
                      height={350}
                      data={barGraphSystemEntities}
                      layout="horizontal"
                      margin={{
                        bottom: 48,
                      }}
                    >
                      <XAxis
                        dataKey="Source_Text"
                        textAnchor="end"
                        sclaeToFit="true"
                        verticalAnchor="start"
                        interval={0}
                        angle="-45"
                        stroke="#000"
                        fontSize={10}
                      />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend verticalAlign="top" height={36} align="right" />
                      <Bar dataKey="count" fill="#205723" shape={<TriangleBar />} barSize={50} />
                    </BarChart>
                  </Box>
                </CardContent>
              </Card>
            )}
            {barGraphActivites.length > 0 && (
              <Card
                Card
                style={
                  barGraphSystemEntities.length > 0 ? { flexBasis: '50%' } : { flexBasis: '100%' }
                }
                className={classes3.cardRoot}
              >
                <CardHeader title="Activities" />
                <CardContent>
                  <Box className={classes3.barGraph}>
                    <BarChart
                      width={barGraphActivites.length < 7 ? 300 : barGraphActivites.length * 40}
                      height={350}
                      data={barGraphActivites}
                      layout="horizontal"
                      margin={{
                        bottom: 48,
                      }}
                    >
                      <XAxis
                        dataKey="Source_Text"
                        textAnchor="end"
                        sclaeToFit="true"
                        verticalAnchor="start"
                        interval={0}
                        angle="-45"
                        stroke="#000"
                        fontSize={10}
                      />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend verticalAlign="top" height={36} align="right" />
                      <Bar
                        dataKey="count"
                        fill={isensTheme.palette.secondary.light}
                        shape={<TriangleBar />}
                        barSize={50}
                      />
                    </BarChart>
                  </Box>
                </CardContent>
              </Card>
            )}
          </Box>

          <Box style={{ display: 'flex', gap: '1rem' }}>
            {barGraphSystemAcronym.length > 0 && (
              <Card
                Card
                style={barGraphStandards.length > 0 ? { flexBasis: '50%' } : { flexBasis: '100%' }}
                className={classes3.cardRoot}
              >
                <CardHeader title="Acronyms" />
                <CardContent>
                  <Box className={classes3.barGraph}>
                    <BarChart
                      width={
                        barGraphSystemAcronym.length < 7 ? 300 : barGraphSystemAcronym.length * 40
                      }
                      height={350}
                      data={barGraphSystemAcronym}
                      layout="horizontal"
                      margin={{
                        bottom: 48,
                      }}
                    >
                      <XAxis
                        dataKey="Source_Text"
                        textAnchor="end"
                        sclaeToFit="true"
                        verticalAnchor="start"
                        interval={0}
                        angle="-45"
                        stroke="#000"
                        fontSize={10}
                      />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend verticalAlign="top" height={36} align="right" />
                      <Bar
                        dataKey="count"
                        fill={isensTheme.palette.secondary.light}
                        shape={<TriangleBar />}
                        barSize={50}
                      />
                    </BarChart>
                  </Box>
                </CardContent>
              </Card>
            )}

            {barGraphStandards.length > 0 && (
              <Card
                Card
                style={barGraphSystemAcronym.length ? { flexBasis: '50%' } : { flexBasis: '100%' }}
                className={classes3.cardRoot}
              >
                <CardHeader title="Standards" />
                <CardContent>
                  <Box className={classes3.barGraph}>
                    <BarChart
                      width={barGraphStandards.length < 7 ? 300 : barGraphStandards.length * 40}
                      height={350}
                      data={barGraphSystemAcronym}
                      layout="horizontal"
                      margin={{
                        bottom: 48,
                      }}
                    >
                      <XAxis
                        dataKey="Source_Text"
                        textAnchor="end"
                        sclaeToFit="true"
                        verticalAnchor="start"
                        interval={0}
                        angle="-45"
                        stroke="#000"
                        fontSize={10}
                      />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend verticalAlign="top" height={36} align="right" />
                      <Bar
                        dataKey="count"
                        fill={isensTheme.palette.secondary.light}
                        shape={<TriangleBar />}
                        barSize={50}
                      />
                    </BarChart>
                  </Box>
                </CardContent>
              </Card>
            )}
          </Box>

          <Box style={{ display: 'flex', gap: '1rem' }}>
            {barGraphCyber.length > 0 && (
              <Card
                Card
                style={barGraphProject.length > 0 ? { flexBasis: '50%' } : { flexBasis: '100%' }}
                className={classes3.cardRoot}
              >
                <CardHeader title="Cyber Security" />
                <CardContent>
                  <Box className={classes3.barGraph}>
                    <BarChart
                      width={barGraphCyber.length < 7 ? 300 : barGraphCyber.length * 40}
                      height={350}
                      data={barGraphCyber}
                      layout="horizontal"
                      margin={{
                        bottom: 48,
                      }}
                    >
                      <XAxis
                        dataKey="Source_Text"
                        textAnchor="end"
                        sclaeToFit="true"
                        verticalAnchor="start"
                        interval={0}
                        angle="-45"
                        stroke="#000"
                        fontSize={10}
                      />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend verticalAlign="top" height={36} align="right" />
                      <Bar
                        dataKey="count"
                        fill={isensTheme.palette.secondary.light}
                        shape={<TriangleBar />}
                        barSize={50}
                      />
                    </BarChart>
                  </Box>
                </CardContent>
              </Card>
            )}

            {barGraphProject.length > 0 && (
              <Card
                Card
                style={barGraphCyber.length > 0 ? { flexBasis: '50%' } : { flexBasis: '100%' }}
                className={classes3.cardRoot}
              >
                <CardHeader title="Project Management" />
                <CardContent>
                  <Box className={classes3.barGraph}>
                    <BarChart
                      width={barGraphProject.length < 7 ? 300 : barGraphProject.length * 40}
                      height={350}
                      data={barGraphProject}
                      layout="horizontal"
                      margin={{
                        bottom: 48,
                      }}
                    >
                      <XAxis
                        dataKey="Source_Text"
                        textAnchor="end"
                        sclaeToFit="true"
                        verticalAnchor="start"
                        interval={0}
                        angle="-45"
                        stroke="#000"
                        fontSize={10}
                      />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend verticalAlign="top" height={36} align="right" />
                      <Bar
                        dataKey="count"
                        fill={isensTheme.palette.secondary.light}
                        shape={<TriangleBar />}
                        barSize={50}
                      />
                    </BarChart>
                  </Box>
                </CardContent>
              </Card>
            )}
          </Box>

          <Box style={{ display: 'flex', gap: '1rem' }}>
            {barGraphCommercial.length > 0 && (
              <Card
                Card
                style={barGraphFinance.length > 0 ? { flexBasis: '50%' } : { flexBasis: '100%' }}
                className={classes3.cardRoot}
              >
                <CardHeader title="Commercial" />
                <CardContent>
                  <Box className={classes3.barGraph}>
                    <BarChart
                      width={barGraphCommercial.length < 7 ? 300 : barGraphCommercial.length * 40}
                      height={350}
                      data={barGraphCommercial}
                      layout="horizontal"
                      margin={{
                        bottom: 48,
                      }}
                    >
                      <XAxis
                        dataKey="Source_Text"
                        textAnchor="end"
                        sclaeToFit="true"
                        verticalAnchor="start"
                        interval={0}
                        angle="-45"
                        stroke="#000"
                        fontSize={10}
                      />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend verticalAlign="top" height={36} align="right" />
                      <Bar
                        dataKey="count"
                        fill={isensTheme.palette.secondary.light}
                        shape={<TriangleBar />}
                        barSize={50}
                      />
                    </BarChart>
                  </Box>
                </CardContent>
              </Card>
            )}

            {barGraphFinance.length > 0 && (
              <Card
                Card
                style={
                  barGraphCommercial.length > 0
                    ? { flexBasis: '50%' }
                    : {
                        flexBasis: '100%',
                      }
                }
                className={classes3.cardRoot}
              >
                <CardHeader title="Commercial" />
                <CardContent>
                  <Box className={classes3.barGraph}>
                    <BarChart
                      width={barGraphFinance.length < 7 ? 300 : barGraphFinance.length * 40}
                      height={350}
                      data={barGraphFinance}
                      layout="horizontal"
                      margin={{
                        bottom: 48,
                      }}
                    >
                      <XAxis
                        dataKey="Source_Text"
                        textAnchor="end"
                        sclaeToFit="true"
                        verticalAnchor="start"
                        interval={0}
                        angle="-45"
                        stroke="#000"
                        fontSize={10}
                      />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend verticalAlign="top" height={36} align="right" />
                      <Bar
                        dataKey="count"
                        fill={isensTheme.palette.secondary.light}
                        shape={<TriangleBar />}
                        barSize={50}
                      />
                    </BarChart>
                  </Box>
                </CardContent>
              </Card>
            )}
          </Box>
          <Box style={{ display: 'flex', gap: '1rem' }}>
            {barGraphContractual.length > 0 && (
              <Card Card className={classes3.cardRoot}>
                <CardHeader title="Contractual" />
                <CardContent>
                  <Box className={classes3.barGraph}>
                    <BarChart
                      width={barGraphContractual.length < 7 ? 300 : barGraphContractual.length * 40}
                      height={350}
                      data={barGraphContractual}
                      layout="horizontal"
                      margin={{
                        bottom: 48,
                      }}
                    >
                      <XAxis
                        dataKey="Source_Text"
                        textAnchor="end"
                        sclaeToFit="true"
                        verticalAnchor="start"
                        interval={0}
                        angle="-45"
                        stroke="#000"
                        fontSize={10}
                      />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend verticalAlign="top" height={36} align="right" />
                      <Bar
                        dataKey="count"
                        fill={isensTheme.palette.secondary.light}
                        shape={<TriangleBar />}
                        barSize={50}
                      />
                    </BarChart>
                  </Box>
                </CardContent>
              </Card>
            )}
          </Box>
        </Box>
        <div>
          <div>
            <br />
            <Paper>
              {systemCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>System Entites</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={systemCloudData}
                        shuffle={true}
                        onMouseMove={(tag) =>
                          setSnack(`'${tag.value}',  count: '${tag.count}'`, 'info')
                        }
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
            <br />
            <Paper>
              {activityCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Activities</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={activityCloudData}
                        shuffle={true}
                        onMouseMove={(tag) =>
                          setSnack(`'${tag.value}',  count: '${tag.count}'`, 'info')
                        }
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
            <br />
            <Paper>
              {acronymCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Acronyms</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={acronymCloudData}
                        shuffle={true}
                        onMouseMove={(tag) =>
                          setSnack(`'${tag.value}',  count: '${tag.count}'`, 'info')
                        }
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
            <br />
            <Paper>
              {standardsCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Standards</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={standardsCloudData}
                        shuffle={true}
                        onMouseMove={(tag) =>
                          setSnack(`'${tag.value}',  count: '${tag.count}'`, 'info')
                        }
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
            <br />
            <Paper>
              {cyberCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Cyber Security</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={cyberCloudData}
                        shuffle={true}
                        onMouseMove={(tag) =>
                          setSnack(`'${tag.value}',  count: '${tag.count}'`, 'info')
                        }
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
            <br />
            <Paper>
              {projectCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Project Management</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={projectCloudData}
                        shuffle={true}
                        onMouseMove={(tag) =>
                          setSnack(`'${tag.value}',  count: '${tag.count}'`, 'info')
                        }
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
            <br />
            <Paper>
              {commercialCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Commercial</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={commercialCloudData}
                        shuffle={true}
                        onMouseMove={(tag) =>
                          setSnack(`'${tag.value}',  count: '${tag.count}'`, 'info')
                        }
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
            <br />
            <Paper>
              {financeCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Finance</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={financeCloudData}
                        shuffle={true}
                        onMouseMove={(tag) =>
                          setSnack(`'${tag.value}',  count: '${tag.count}'`, 'info')
                        }
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>

            <br />
            <Paper>
              {contractualCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Contractual</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={contractualCloudData}
                        shuffle={true}
                        onMouseMove={(tag) =>
                          setSnack(`'${tag.value}',  count: '${tag.count}'`, 'info')
                        }
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default INer;
