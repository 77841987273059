/**
 * This component renders a Apsys Page With Apsys Graph
 *  Get all Data related Regression Graph
 *  Css also fixed here
 * @param {string} component No params.
 * @returns {ReactNode} A React element that renders Apsys Graph.
 *  Copyright @ Alstom 2023
 *  auther @Ranjan
 */
import React, { useEffect, useState } from 'react';
import { Scatter, Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Grid } from '@material-ui/core';
import { Chart, registerables } from 'chart.js';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

// import { Chart, LinearScale, LineController, LineElement, PointElement, Title } from 'chart.js';

// Chart.register(LineController, LineElement, PointElement, LinearScale, Title);
Chart.register(...registerables);

const ApsysGraph = ({ dataApsy }) => {
  // const classes = useStyles();

  // const xrange = [3, 2.78];
  const xticksGap = 0.25;

  const xticks = [];
  // for (let i = dataApsy.xrange[0]; i >= dataApsy.xrange[1]; i -= xticksGap) {
  //   xticks.push(i);
  // }
  for (let i = dataApsy.xrange[0] + xticksGap; i < dataApsy.xrange[1]; i += xticksGap) {
    xticks.push(i);
  }
  const yticks = [];
  // for (let i = dataApsy.yrange[0]; i >= dataApsy.yrange[1]; i -= xticksGap) {
  //   yticks.push(i);
  // }
  for (let i = dataApsy.yrange[0] + xticksGap; i < dataApsy.yrange[1]; i += xticksGap) {
    yticks.push(i);
  }

  const newDataApsy = { ...dataApsy };
  newDataApsy.xtick = xticks;
  newDataApsy.ytick = yticks;

  const { domain, xlabel, ylabel, xrange, yrange, xtick, ytick, waves, rsquare } = newDataApsy;

  // Extract data points from the waves object
  const dataPoints = Object.keys(waves).reduce((acc, wave) => {
    const locations = waves[wave];
    Object.keys(locations).forEach((location) => {
      const [x, y] = locations[location];
      acc.push({ x, y, label: `${wave} - ${location}` });
    });
    return acc;
  }, []);
  // console.log('data points', dataPoints);

  // Extract regression lines from the rsquare object
  const regressionLines = Object.keys(rsquare).map((wave, i) => {
    const rsquareValue = rsquare[wave];
    const minX = xrange[0];
    const maxX = xrange[1];
    const minY = rsquareValue * minX;
    const maxY = rsquareValue * maxX;
    // console.log(
    //   'wave',
    //   Object.keys(waves).map((wave) => `Regression Line - ${wave}(${rsquareValue})`)
    // );
    return {
      x: [minX, maxX],
      y: [minY, maxY],
      label: `Regression Line - ${Object.keys(waves)[i]}(${rsquareValue})`,
    };
  });
  // Define a color scale
  const colorScale = scaleOrdinal(schemeCategory10);
  // Generate colors for each wave
  const generateColors = (waves) => {
    const colors = {};
    const waveKeys = Object.keys(waves);
    waveKeys.forEach((wave, index) => {
      const color = colorScale(index % 10); // Ensure color repeats after reaching the end of the palette
      const locations = waves[wave];
      Object.keys(locations).forEach((location) => {
        colors[`${wave}-${location}`.replace(/[ ]+/g, '')] = color;
      });
    });
    return colors;
  };
  const colors = generateColors(waves);
  // console.log('color', colors);
  const colorArr = ['red', 'green', 'blue', 'purple', 'yellow', 'orange', 'pink', 'brown'];
  const dataConfig = {
    datasets: [
      {
        label: 'Data Points',
        data: dataPoints,
        backgroundColor: dataPoints.map((point) => {
          // console.log('point', point.label.replace(/[ ]+/g, ''));
          return colors[point.label.replace(/[ ]+/g, '')];
        }),
        // backgroundColor: dataPoints.map((point) => colors[point]),
        pointStyle: 'custom', // Use custom point style
        // datalabels: {
        //   rotation: '50',
        //   align: 'start',
        //   anchor: 'end',
        //   offset: 6,
        //   font: {
        //     weight: 'bold',
        //   },
        //   color: 'rgb(0, 42, 69)', // Set the color of the label
        //   // formatter: (value, context) =>
        //   //   context.dataset.data[context.dataIndex].label.split(' - ')[1], // Display W17 or W18 value
        // },
        borderWidth: 10,
        pointRadius: 5,
      },
      ...regressionLines.map((line, index) => ({
        label: line.label,
        data: [
          { x: line.x[0], y: line.y[0] },
          { x: line.x[1], y: line.y[1] },
        ],
        type: 'line',
        // borderColor: `rgba(${210 + 0.2 * index}, ${40 + 0.2 * index}, 35, ${0.2 + 0.2 * index})`,
        borderColor: `${colorArr[index]}`,
        borderWidth: 2,
        fill: true,
        showLine: true,
        datalabels: {
          display: false,
          align: 'center',
          rotation: 40 * index,
          anchor: 'center',
          //
          color: colorArr[index],
          font: {
            weight: 'bold',
            size: 10,
          },
          formatter: (value, context) => {
            // Customize the format of the label as needed
            return `Regression - ${line.label.split('-')[1]}`;
          },
        },
      })),
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        title: {
          display: true,
          text: xlabel,
        },
        ticks: {
          min: xrange[0],
          max: xrange[1],
          stepSize: 0.3,
        },
        grid: {
          borderColor: 'rgb(0, 42, 69)',
          borderWidth: 2,
        },
      },
      y: {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: ylabel,
        },
        ticks: {
          min: yrange[0],
          max: yrange[1],
          stepSize: 0.5,
        },
        grid: {
          borderColor: 'rgb(0, 42, 69)',
          borderWidth: 2,
        },
      },
    },
    // plugins: {
    //   tooltip: {
    //     enabled: true,
    //     callbacks: {
    //       title: (context) => {
    //         // eslint-disable-next-line prefer-destructuring
    //         const dataIndex = context[0].dataIndex;
    //         // eslint-disable-next-line prefer-destructuring
    //         const dataset = context[0].dataset;
    //         const dataPoint = dataset.data[dataIndex];
    //         return dataPoint.label;
    //       },
    //       label: (context) => {
    //         // eslint-disable-next-line prefer-destructuring
    //         const dataIndex = context.dataIndex;
    //         // eslint-disable-next-line prefer-destructuring
    //         const dataset = context.dataset;
    //         const dataPoint = dataset.data[dataIndex];
    //         return `X: ${dataPoint.x}, Y: ${dataPoint.y}`;
    //       },
    //     },
    //   },
    // },
    plugins: {
      datalabels: {
        display: true,
        formatter: () => null,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: (context) => {
            // eslint-disable-next-line prefer-destructuring
            const dataIndex = context[0].dataIndex;
            // eslint-disable-next-line prefer-destructuring
            const dataset = context[0].dataset;
            const dataPoint = dataset.data[dataIndex];
            return dataPoint.label;
          },
          label: (context) => {
            // eslint-disable-next-line prefer-destructuring
            const dataIndex = context.dataIndex;
            // eslint-disable-next-line prefer-destructuring
            const dataset = context.dataset;
            const dataPoint = dataset.data[dataIndex];
            return `X: ${dataPoint.x}, Y: ${dataPoint.y}`;
          },
        },
      },
      // datalabels: {
      //   display: true,
      // },
    },
    // interaction: {
    //   mode: 'index',
    //   intersect: false,
    // },
  };

  return (
    <Grid
      container
      style={{
        backgroundColor: '#EEF1F9',
        border: '1px solid #002A45',
        paddingBottom: 30,
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: '#002A45',
          padding: 10,
          color: 'white',
          fontSize: 20,
        }}
      >
        Apsys Graph
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Scatter
            data={dataConfig}
            options={{
              ...options,
              plugins: {
                ...options.plugins,
                legend: {
                  ...options.plugins.legend,
                  position: 'bottom',
                },
              },
            }}
            plugins={[ChartDataLabels]}
            style={{
              width: '100%',
              height: '100%',
              marginBottom: 0,
              marginLeft: 3,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ApsysGraph;
