import {
  Column,
  DataTypeProvider,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnVisibility,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Paper } from '@material-ui/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import release from './release';
import { APP_VERSION, APP_NAME } from '../../const';

dayjs.extend(utc);
const columns = [
  {
    name: 'version',
    title: 'Version',
  },
  {
    name: 'branch',
    title: 'Branch',
  },
  {
    name: 'content',
    title: 'Commit',
  },
  {
    name: 'date',
    title: 'Date',
  },
] as Column[];

const renderBranch = ({ value }: DataTypeProvider.ValueFormatterProps) => {
  return <a href={`https://alm.alstom.com/git/ISenS/isens-apps/-/commits/${value}`}>{value}</a>;
};

const renderVersion = ({ value }: DataTypeProvider.ValueFormatterProps) => {
  return <>{value}</>;
};

const renderContent = ({ value }: DataTypeProvider.ValueFormatterProps) => {
  return <span title={value}>{value}</span>;
};

function VersionContainer() {
  return (
    <Paper>
      <h3>
        <a href="/">Name : {APP_NAME}</a>
      </h3>
      <h3>Version : {APP_VERSION}</h3>
      <ul>
        <li>CI_COMMIT_BRANCH: {process.env.REACT_APP_CI_COMMIT_BRANCH} </li>
        <li>CI_COMMIT_MESSAGE: {process.env.REACT_APP_CI_COMMIT_MESSAGE} </li>
        <li>
          CI_COMMIT_TIMESTAMP:
          {process.env.REACT_APP_CI_COMMIT_TIMESTAMP}
        </li>
        <li>CI_COMMIT_SHORT_SHA: {process.env.REACT_APP_CI_COMMIT_SHORT_SHA} </li>
        <li>CI_JOB_ID: {process.env.REACT_APP_CI_JOB_ID} </li>
      </ul>
      <ExpressGrid rows={release} columns={columns}>
        <DataTypeProvider for={['version']} formatterComponent={renderVersion} />
        <DataTypeProvider for={['branch']} formatterComponent={renderBranch} />
        <DataTypeProvider for={['content']} formatterComponent={renderContent} />
        <SortingState />
        <IntegratedSorting />
        <PagingState defaultCurrentPage={0} defaultPageSize={10} />
        <IntegratedPaging />
        <Table />
        <TableHeaderRow showSortingControls />
        <TableColumnVisibility defaultHiddenColumnNames={[]} />
        <Toolbar />
        <ColumnChooser />
        <PagingPanel pageSizes={[10, 20, 50]} />
      </ExpressGrid>
    </Paper>
  );
}

export default VersionContainer;
