import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableHeaderRow,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
  TableColumnResizing,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Bar,
  Sector,
} from 'recharts';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkOutlinedIcon from '@mui/icons-material/WorkOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Paper,
  IconButton,
} from '@material-ui/core';
import { List } from '@mui/material';
import { isEmpty } from '@antv/util';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import IsearcherStyle from '../../../components/Layout/IsearcherStyle';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../components/Datagrid/TStyles';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import NavigationGotoNew from '../../../components/Core/NavigationGotoNew';
import { ListItem2, resultStyle } from '../../../components/Filter/textAnalysis';
import teamsIcon from './coredata/teamsIcon.png';
import outlook from './coredata/outlook.png';
import MultiAllocTableComp from '../../../components/Datagrid/MultiAllocTableComp';

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${
  y + height
}
          Z`;
const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const COLORS_ENTITY = [
  '#002A45',
  '#4B5A69',
  '#017E4B',
  '#19AA6E',
  '#19AA6E',
  '#46B98C',
  '#AB0E00',
  '#DC3223',
  '#DC3223',
  '#E15A50',
];
const PIE_COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#F8A600', '#2f475f'];

const COLORS = [
  '#1e3246',
  '#dc3223',
  '#19aa6e',
  '#4b5a69',
  '#788291',
  '#d2d7dc',
  '#eb827d',
  '#fad7d2',
  '#d2f0e1',
  '#73cdaa',
  '#9b875f',
  '#afa082',
  '#ebe6dc',
  '#c3b9a0',
  '#C3DDF6',
  '#468AD1',
  '#F8A600',
  '#2f475f',
  '#95372f',
  '#7a231b',
  '#94a2b8',
  '#5b8f75',
  '#546c60',
  '#36443d',
  '#748c80',
  '#4fa7ff',
  '#174471',
  '#384a5c',
  '#acacad',
  '#bdbdbd',
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`PV ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const columns: any = [
  { name: 'artifactText', title: 'Text' },
  // { name: 'alstomEntity', title: 'Entity' },
  { name: 'productLine', title: 'Product Line' },
  { name: 'solution', title: 'Solution' },
  { name: 'platform', title: 'Platform' },
  { name: 'product', title: 'Product' },
  { name: 'project', title: 'Project' },
  { name: 'country', title: 'Country' },
  { name: 'documentProfile', title: 'Doc Profile' },
  { name: 'doorsArtifactAllocation', title: 'Allocation' },
  { name: 'documentTitle', title: 'Doc Title' },
  { name: 'documentPath', title: 'Doc Path' },
  { name: 'documentVersion', title: 'Doc Version' },
  { name: 'documentABSActivity', title: 'Doc ABS' },
  { name: 'documentOBSOwner', title: 'Doc OBS' },
  { name: 'artifactType', title: 'Artifact Type' },
  { name: 'lastModifiedBy', title: 'Last Modified By' },
  { name: 'lastModifiedOn', title: 'Last Modified On' },
  { name: 'url', title: 'URL' },
  { name: 'moduleLastModifiedBy', title: 'Module Last Modified By' },
  { name: 'moduleLastModifiedOn', title: 'Module Last Modified On' },
  { name: 'moduleUrl', title: 'Module Url' },
  { name: 'createdBy', title: 'Created By' },
  { name: 'createdOn', title: 'Created On' },
  { name: 'moduleCreatedBy', title: 'Module Created By' },
  { name: 'moduleCreatedOn', title: 'Module Created On' },
  { name: 'compliance', title: 'Compliance' },
  { name: 'artifactLifeCycle', title: 'Artifact Life Cycle' },
  { name: 'artifactActivity', title: 'Artifact Activity' },
];

function ExpertSearch({
  rowResult,
  alstomEntityWiseDistribution,
  platformWiseDistribution,
  projectWiseDistribution,
  documentProfileWiseDistribution,
  solutionWiseDistribution,
  countryWiseDistribution,
  productWiseDistribution,
  artifactWiseDistribution,
  expertDomain,
  nameClick,
  smartData,
  artifactKpi,
  TotalPeopleResult,
  timeTaken,
  payloadMain,
}) {
  // console.log('alstomEntityWiseDistribution', alstomEntityWiseDistribution);
  const exporterRef = useRef(null);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([
    'doorsArtifactAllocation',
    // 'documentTitle',
    'documentVersion',
    'documentABSActivity',
    'documentOBSOwner',
    'documentPath',
    'artifactType',
    'lastModifiedBy',
    'lastModifiedOn',
    'url',
    'moduleLastModifiedOn',
    'moduleLastModifiedBy',
    'moduleUrl',
    'createdBy',
    'createdOn',
    'moduleCreatedBy',
    'moduleCreatedOn',
    'compliance',
    'artifactLifeCycle',
    'artifactActivity',
  ]);
  const [graphType, setGraphType] = React.useState<any>('bar');
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const classes = IsearcherStyle();
  const classes2 = resultStyle();
  const [operation, setOperation] = useState(0);
  const [activeIndex0, setActivate0] = useState(0);
  const [activeIndex1, setActivate1] = useState(0);
  const [activeIndex2, setActivate2] = useState(0);
  const [activeIndex3, setActivate3] = useState(0);
  const [activeIndex4, setActivate4] = useState(0);
  const [activeIndex5, setActivate5] = useState(0);
  const [activeIndex6, setActivate6] = useState(0);
  const [activeIndex7, setActivate7] = useState(0);
  const [activeIndex8, setActivate8] = useState(0);

  const onPieEnter0 = (_, index) => {
    setActivate0(index);
  };

  const onPieEnter1 = (_, index) => {
    setActivate1(index);
  };
  const onPieEnter2 = (_, index) => {
    setActivate2(index);
  };
  const onPieEnter3 = (_, index) => {
    setActivate3(index);
  };
  const onPieEnter4 = (_, index) => {
    setActivate4(index);
  };
  const onPieEnter5 = (_, index) => {
    setActivate5(index);
  };
  const onPieEnter6 = (_, index) => {
    setActivate6(index);
  };
  const onPieEnter7 = (_, index) => {
    setActivate7(index);
  };
  const onPieEnter8 = (_, index) => {
    setActivate8(index);
  };
  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  useEffect(() => {
    if (sessionStorage.getItem('expertSearch') !== null) {
      setHiddenColumnNames(sessionStorage.getItem('expertSearch')?.split(','));
    }
  }, []);
  useEffect(() => {
    sessionStorage.setItem('expertSearch', hiddenColumnNames);
  }, [hiddenColumnNames]);

  useEffect(() => {
    if (pageSize > rowResult.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < rowResult.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);

  const TableComponentBase = ({ classes, ...restProps }: any) => (
    <Table.Table {...restProps} className={classes.tableStriped} />
  );

  const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'iSearcher_Report.xlsx');
    });
  };
  const handleChangeGraph = (event: any, passedData: string) => {
    setGraphType(passedData);
    if (passedData === '') {
      setGraphType('bar');
    }
  };

  const renderText = ({ value, row }: DataTypeProvider.ValueFormatterProps) => (
    <a
      id={`search-${row.id}`}
      // href="https://myworkplace.alstom.hub/Citrix/Alstom_PROD_StoreWeb/"
      href={row.url}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        urlSearch(String(row.url));
      }}
      style={{ textDecoration: 'none' }}
      title={value}
    >
      {value}
    </a>
  );

  function urlSearch(a: any) {
    // console.log(a);
  }
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (
    { cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }: any,
    full = false
  ) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const displayLabel = () => {
      if (full) return <>{percent !== 0 ? `${`${name} - ${(percent * 100).toFixed(0)}%`}` : ''}</>;
      return <>{percent !== 0 ? `${`${(percent * 100).toFixed(3)}%`}` : ''}</>;
    };
    return (
      <text
        transform={`translate(${x},${y}) rotate(320)`} // 330
        fill="black"
        dominantBaseline="central"
        textAnchor="middle"
        fontSize="9px"
        fontStretch="collapsed"
        fontFamily="sans-serif, Helvetica Neue, Lucida Grande, Arial"
      >
        {/* {`${(percent * 100).toFixed(0)}%`} */}
        {/* to remove 0% from graph display */}
        {percent !== 0 ? `${`${name.substring(0, 5)} ${(percent * 100).toFixed(3)}%`}` : ''}
        {/* {percent !== 0 ? `${`${(percent * 100).toFixed(3)}%`}` : ''} */}
        {/* by David {displayLabel()} */}
      </text>
    );
  };

  const renderCustomizedLabelFull = (params: any) => {
    return renderCustomizedLabel(params, true);
  };
  return (
    <>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        {isEmpty(payloadMain) === false ? (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Card className={classes.ExpCardBody1}>
                  <div
                    style={{
                      background: '#002A45 0% 0% no-repeat padding-box',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 10,
                      }}
                    >
                      <Typography variant="h5" component="div" style={{ color: '#FFFFFF' }}>
                        Related People from the domain
                      </Typography>
                    </div>
                  </div>
                  <Grid container>
                    <Grid item xs={3}>
                      <CardContent>
                        <List style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                          {expertDomain.map((items: any, i: any) => {
                            return (
                              <ListItem2
                                button
                                style={{ display: 'flex' }}
                                onClick={() => {
                                  setOperation(i);
                                }}
                                selected={operation === i}
                              >
                                <Typography
                                  variant="subtitle1"
                                  value={items}
                                  onClick={() => nameClick(items.name, true, payloadMain)}
                                >
                                  {items.name}
                                </Typography>
                              </ListItem2>
                            );
                          })}
                        </List>
                      </CardContent>
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', justifyContent: 'center' }}>
                      <CardContent className={classes2.expertBargrph}>
                        {expertDomain.length > 0 && (
                          <>
                            <BarChart
                              width={700}
                              height={500}
                              data={expertDomain}
                              style={{
                                paddingTop: '4rem',
                              }}
                              layout="horizontal"
                              margin={{
                                bottom: 190,
                              }}
                            >
                              <XAxis
                                dataKey="name"
                                textAnchor="end"
                                sclaeToFit="true"
                                verticalAnchor="start"
                                interval={0}
                                angle="-45"
                                stroke="#000"
                              />
                              <YAxis />
                              <CartesianGrid strokeDasharray="1 1" />
                              <Tooltip />
                              <Legend verticalAlign="top" align="right" />
                              <Bar
                                dataKey="Number Of Artifacts"
                                fill="#002A45"
                                stroke="#000000"
                                strokeWidth={1}
                                shape={<TriangleBar />}
                              >
                                <>
                                  {/* <LabelList dataKey="Number Of Artifacts" position="top" /> */}
                                  {expertDomain.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS_ENTITY[index]} />
                                  ))}
                                </>
                              </Bar>
                            </BarChart>
                          </>
                        )}
                      </CardContent>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Box>
              <Card>
                <CardHeader
                  title={`Details - ${smartData?.name}`}
                  style={{ backgroundColor: '#002A45', color: 'white' }}
                />
                <CardContent style={{ display: 'flex', gap: '16px' }}>
                  <Box style={{ flexBasis: '50%', backgroundColor: '#EEF1F9' }}>
                    <Box
                      style={{
                        display: 'flex',
                        gap: '0.5rem',
                        color: '#fff',
                        padding: '0.7rem',
                        borderRadius: '11px 0px 135px 0px',
                        background:
                          'transparent linear-gradient(96deg, #012434 0%, #003E5C 100%) 0% 0% no-repeat padding-box',
                      }}
                    >
                      <Box style={{ flexBasis: '10%' }}>
                        <IconButton style={{ backgroundColor: '#DC3223', color: 'white' }}>
                          <PersonOutlineOutlinedIcon />
                        </IconButton>
                      </Box>
                      <Box style={{ flexBasis: '70%', display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
                          {smartData?.name}
                        </Typography>
                        <Typography>{smartData?.designation}</Typography>
                      </Box>
                      <Box
                        style={{
                          flexBasis: '20%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          gap: '20px',
                          paddingRight: '2rem',
                        }}
                      >
                        {' '}
                        <a
                          href={`mailto:${smartData?.email}`}
                          target="_blank"
                          title="Email"
                          rel="noreferrer"
                        >
                          <img src={outlook} alt="outlook" />
                        </a>
                        <a
                          href={`https://teams.microsoft.com/l/chat/0/0?users=${smartData?.email}`}
                          target="_blank"
                          rel="noreferrer"
                          title="Chat"
                        >
                          <img src={teamsIcon} alt="teams" />
                        </a>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        marginTop: '1rem',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        padding: '1rem',
                      }}
                    >
                      <div className={classes.cardContentDiv1}>
                        <div className={classes.cardContentDiv2}>
                          <BusinessOutlinedIcon className={classes.cardIcon} />
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{
                              marginRight: 10,
                              marginLeft: 5,
                              color: '#DC3223',
                            }}
                          >
                            Business Unit
                          </Typography>
                          :
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ marginLeft: 5, fontWeight: 'bold' }}
                          >
                            {smartData?.profile.map((_a: any, i: any) => {
                              return (
                                <span
                                  style={{
                                    color: '#002A45',
                                    textOverflow: 'hidden',
                                  }}
                                >
                                  {smartData?.profile[i]}
                                  {smartData?.profile.length - 1 > i ? ', ' : ''}
                                </span>
                              );
                            })}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.cardContentDiv1}>
                        <div className={classes.cardContentDiv2}>
                          <DevicesOutlinedIcon className={classes.cardIcon} />
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{
                              marginRight: 37,
                              marginLeft: 5,
                              color: '#DC3223',
                            }}
                          >
                            Platform
                          </Typography>
                          :
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ marginLeft: 5, fontWeight: 'bold' }}
                          >
                            {smartData?.platforms.map((a: any, i: any) => {
                              return (
                                <span style={{ color: '#002A45' }}>
                                  {smartData?.platforms[i]}
                                  {smartData?.platforms.length - 1 > i ? ', ' : ''}
                                </span>
                              );
                            })}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.cardContentDiv1}>
                        <div className={classes.cardContentDiv2}>
                          <WorkOutlinedIcon className={classes.cardIcon} />
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{
                              marginRight: 48,
                              marginLeft: 5,
                              color: '#DC3223',
                            }}
                          >
                            Project
                          </Typography>
                          :
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ marginLeft: 5, fontWeight: 'bold' }}
                          >
                            {smartData?.projects.map((person: any, i: any) => {
                              return (
                                <span style={{ color: '#002A45' }}>
                                  {smartData?.projects[i]}
                                  {smartData?.projects.length - 1 > i ? ', ' : ''}
                                </span>
                              );
                            })}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.cardContentDiv1}>
                        <div className={classes.cardContentDiv2}>
                          <DescriptionIcon className={classes.cardIcon} />
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{
                              marginRight: 30,
                              marginLeft: 5,
                              color: '#DC3223',
                            }}
                          >
                            Document
                          </Typography>
                          :
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ marginLeft: 5, fontWeight: 'bold' }}
                          >
                            {smartData?.specifications.map((a: any, i: any) => {
                              return (
                                <span style={{ color: '#002A45' }}>
                                  {smartData?.specifications[i]}
                                  {smartData?.specifications.length - 1 > i ? ', ' : ''}
                                </span>
                              );
                            })}
                          </Typography>
                        </div>
                      </div>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      flexBasis: '50%',
                      border: '1px solid #002A45',
                      borderRadius: '5px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <PieChart className={classes2.pieChart} width={600} height={400}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex0}
                        data={artifactKpi}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter0}
                      >
                        {artifactKpi.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={PIE_COLORS[index]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </>
        ) : null}
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          <span>
            <b>{TotalPeopleResult}</b> results found
          </span>
          {/* <span style={{ marginLeft: '10px' }}>({timeTaken} seconds)</span> */}
        </Typography>
        <div>
          <div className={classes2.resultPanel} style={{ borderRadius: '20px' }}>
            {/* {console.log('rowRes', rowResult)} */}
            <ExpressGrid rows={rowResult} columns={columns}>
              <DataTypeProvider
                for={[
                  'productLine',
                  'artifactText',
                  'platform',
                  'project',
                  'documentProfile',
                  'artifactType',
                  'doorsArtifactAllocation',
                  'documentTitle',
                  'documentVersion',
                  'documentABSActivity',
                  'documentOBSOwner',
                  'documentPath',
                  'lastModifiedBy',
                  'lastModifiedOn',
                  'url',
                ]}
                formatterComponent={({ value }) => <span title={value}>{value}</span>}
              />
              <DataTypeProvider for={['url']} formatterComponent={renderText} />
              <DataTypeProvider for={['moduleUrl']} formatterComponent={renderText} />
              <FilteringState defaultFilters={[{ columnName: 'productLine', value: '' }]} />
              <IntegratedFiltering />
              <SortingState />
              <IntegratedSorting />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
              <IntegratedPaging />
              <Table
                // tableComponent={TableComponent}
                tableComponent={MultiAllocTableComp}
                containerComponent={tableAllocatorComponent}
                headComponent={tableHeaderComponent}
                columnExtensions={[
                  { columnName: 'alstomEntity', width: '100px' },
                  { columnName: 'platform', width: '200px' },
                  //   { columnName: 'Score', width: '100px' },
                  {
                    columnName: 'artifactText',
                    width: '300px',
                    wordWrapEnabled: toggleHeight,
                  },
                  {
                    columnName: 'documentPath',
                    width: '300px',
                    wordWrapEnabled: toggleHeight,
                  },
                  {
                    columnName: 'url',
                    width: '500px',
                    wordWrapEnabled: toggleHeight,
                  },
                ]}
              />
              <TableColumnResizing
                defaultColumnWidths={[
                  { columnName: 'Document_Title', width: 400 },
                  { columnName: 'artifactText', width: 300 },
                  // { columnName: 'alstomEntity', width: 100 },
                  { columnName: 'platform', width: 100 },
                  { columnName: 'productLine', width: 200 },
                  { columnName: 'project', width: 200 },
                  { columnName: 'product', width: 200 },
                  { columnName: 'solution', width: 200 },
                  { columnName: 'country', width: 200 },
                  { columnName: 'documentProfile', width: 150 },
                  { columnName: 'artifactType', width: 200 },
                  { columnName: 'doorsArtifactAllocation', width: 200 },
                  { columnName: 'documentTitle', width: 200 },
                  { columnName: 'documentVersion', width: 100 },
                  { columnName: 'documentABSActivity', width: 200 },
                  { columnName: 'documentOBSOwner', width: 200 },
                  { columnName: 'documentPath', width: 200 },
                  { columnName: 'lastModifiedBy', width: 200 },
                  { columnName: 'lastModifiedOn', width: 200 },
                  { columnName: 'url', width: 500 },
                  { columnName: 'moduleLastModifiedOn', width: 200 },
                  { columnName: 'moduleLastModifiedBy', width: 200 },
                  { columnName: 'moduleUrl', width: 500 },
                  { columnName: 'createdBy', width: 200 },
                  { columnName: 'createdOn', width: 200 },
                  { columnName: 'moduleCreatedBy', width: 200 },
                  { columnName: 'moduleCreatedOn', width: 200 },
                  { columnName: 'compliance', width: 200 },
                  { columnName: 'artifactLifeCycle', width: 200 },
                  { columnName: 'artifactActivity', width: 200 },
                ]}
              />
              {/* table header bold <TableHeaderRow showSortingControls cellComponent={TableHeaderCellWrap} /> */}
              <TableHeaderRow showSortingControls />
              <TableFilterRow rowComponent={rowComponent} />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={setHiddenColumnNames}
              />
              <Toolbar rootComponent={toolbarComponent} />
              <ColumnChooser />
              <ToolBarPlugin name="Download" onClick={startExport} />
              <ToolBarPlugin
                name="Height"
                title="Wrap Text"
                onClick={() => setToggleHeight(!toggleHeight)}
              />
              <PagingPanel
                pageSizes={pageSizes}
                containerComponent={(props) => (
                  <>
                    <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
                  </>
                )}
              />
            </ExpressGrid>
            <GridExporter ref={exporterRef} rows={rowResult} columns={columns} onSave={onSave} />
            <NavigationGotoNew
              setPageSize={setPageSize}
              fileLength={rowResult.length}
              pageSize={pageSize}
              dis={dis}
              setTemPage={setTemPage}
              handlePageNum={handlePageNum}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
        <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ToggleButtonGroup
            size="small"
            value={graphType}
            exclusive
            onChange={handleChangeGraph}
            className={classes.toggle}
          >
            <ToggleButton value="bar">
              <span>
                <b style={{ textTransform: 'none' }}>BAR GRAPH</b>
              </span>
            </ToggleButton>
            <ToggleButton value="pie">
              <span>
                <b style={{ textTransform: 'none' }}>PIE GRAPH</b>
              </span>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {graphType === 'pie' && (
          <Paper className={classes2.ToggleResultContainer}>
            <Box className={classes2.ToggleResBox}>
              {alstomEntityWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader title="ProductLine Wise Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart width={600} height={400} className={classes2.pieChart}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex1}
                        data={alstomEntityWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter1}
                      >
                        {alstomEntityWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS_ENTITY[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
              {platformWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader title="Platform Wise Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart width={600} height={400} className={classes2.pieChart}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex2}
                        data={platformWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter2}
                      >
                        {platformWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
            </Box>
            <Box className={classes2.ToggleResBox}>
              {solutionWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader title="Solution Wise Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart width={600} height={400} className={classes2.pieChart}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex6}
                        data={solutionWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter6}
                      >
                        {solutionWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS_ENTITY[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
              {productWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader title="Product Wise Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart width={600} height={400} className={classes2.pieChart}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex7}
                        data={productWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter7}
                      >
                        {productWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
            </Box>
            <Box className={classes2.ToggleResBox}>
              {projectWiseDistribution.length > 0 && (
                <Card Card className={classes2.cardRoot}>
                  <CardHeader title="Project Wise Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart className={classes2.pieChart} width={600} height={400}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex3}
                        data={projectWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter3}
                      >
                        {projectWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
              {documentProfileWiseDistribution.length > 0 && (
                <Card Card className={classes2.cardRoot}>
                  <CardHeader title="Document Profile Wise Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart className={classes2.pieChart} width={600} height={400}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex4}
                        data={documentProfileWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter4}
                      >
                        {documentProfileWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
            </Box>
            {/* <Box>
              {countryWiseDistribution.length > 0 && (
                <Card Card className={classes2.cardRoot2}>
                  <CardHeader title="Country Level Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart className={classes2.pieChart} width={600} height={400}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex8}
                        data={countryWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter8}
                      >
                        {countryWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
            </Box> */}
            {/* <Box>
              {artifactWiseDistribution.length > 0 && (
                <Card Card className={classes2.cardRoot2}>
                  <CardHeader title="Artifact Level Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart className={classes2.pieChart} width={600} height={400}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex5}
                        data={artifactWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter5}
                      >
                        {artifactWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
            </Box> */}
          </Paper>
        )}
        {graphType === 'bar' && (
          <Paper className={classes2.ToggleResultContainer}>
            <Box className={classes2.ToggleResBox}>
              {alstomEntityWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader title="Alstom Entity Wise Distribution" />
                  <CardContent>
                    <Box
                      className={
                        alstomEntityWiseDistribution.length > 5
                          ? classes2.barGraph
                          : classes2.barGraph2
                      }
                    >
                      <BarChart
                        width={300}
                        height={350}
                        data={alstomEntityWiseDistribution
                          .sort((a: any, b: any) => {
                            return a.value - b.value;
                          })
                          .reverse()}
                        layout="horizontal"
                        margin={{
                          bottom: 70,
                        }}
                      >
                        <XAxis
                          dataKey="name"
                          textAnchor="end"
                          sclaeToFit="true"
                          verticalAnchor="start"
                          interval={0}
                          angle="-20"
                          stroke="#000"
                          fontSize={10}
                        />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend verticalAlign="top" height={36} align="right" />
                        <Bar dataKey="value" fill="#DC3223" shape={<TriangleBar />} barSize={50} />
                      </BarChart>
                    </Box>
                  </CardContent>
                </Card>
              )}
              {platformWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader title="Platform Wise Distribution" />
                  <CardContent>
                    <Box
                      className={
                        platformWiseDistribution.length > 5 ? classes2.barGraph : classes2.barGraph2
                      }
                    >
                      <BarChart
                        width={
                          projectWiseDistribution.length < 7
                            ? 300
                            : projectWiseDistribution.length * 40
                        }
                        height={350}
                        data={platformWiseDistribution
                          .sort((a: any, b: any) => {
                            return a.value - b.value;
                          })
                          .reverse()}
                        layout="horizontal"
                        margin={{
                          bottom: 70,
                        }}
                      >
                        <XAxis
                          dataKey="name"
                          textAnchor="end"
                          sclaeToFit="true"
                          verticalAnchor="start"
                          interval={0}
                          angle="-20"
                          stroke="#000"
                          fontSize={10}
                        />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend verticalAlign="top" height={36} align="right" />
                        <Bar dataKey="value" fill="#1e3246" shape={<TriangleBar />} barSize={50} />
                      </BarChart>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Box>
            <Box className={classes2.ToggleResBox}>
              {solutionWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader title="Solution Wise Distribution" />
                  <CardContent>
                    <Box
                      className={
                        solutionWiseDistribution.length > 5 ? classes2.barGraph : classes2.barGraph2
                      }
                    >
                      <BarChart
                        width={300}
                        height={350}
                        data={solutionWiseDistribution
                          .sort((a: any, b: any) => {
                            return a.value - b.value;
                          })
                          .reverse()}
                        layout="horizontal"
                        margin={{
                          bottom: 70,
                        }}
                      >
                        <XAxis
                          dataKey="name"
                          textAnchor="end"
                          sclaeToFit="true"
                          verticalAnchor="start"
                          interval={0}
                          angle="-20"
                          stroke="#000"
                          fontSize={10}
                        />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend verticalAlign="top" height={36} align="right" />
                        <Bar dataKey="value" fill="#DC3223" shape={<TriangleBar />} barSize={50} />
                      </BarChart>
                    </Box>
                  </CardContent>
                </Card>
              )}
              {productWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader title="Product Wise Distribution" />
                  <CardContent>
                    <Box
                      className={
                        productWiseDistribution.length > 5 ? classes2.barGraph : classes2.barGraph2
                      }
                    >
                      <BarChart
                        width={
                          productWiseDistribution.length < 7
                            ? 300
                            : productWiseDistribution.length * 40
                        }
                        height={350}
                        data={productWiseDistribution
                          .sort((a: any, b: any) => {
                            return a.value - b.value;
                          })
                          .reverse()}
                        layout="horizontal"
                        margin={{
                          bottom: 70,
                        }}
                      >
                        <XAxis
                          dataKey="name"
                          textAnchor="end"
                          sclaeToFit="true"
                          verticalAnchor="start"
                          interval={0}
                          angle="-20"
                          stroke="#000"
                          fontSize={10}
                        />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend verticalAlign="top" height={36} align="right" />
                        <Bar dataKey="value" fill="#1e3246" shape={<TriangleBar />} barSize={50} />
                      </BarChart>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Box>
            <Box className={classes2.ToggleResBox}>
              {projectWiseDistribution.length > 0 && (
                <Card Card className={classes2.cardRoot}>
                  <CardHeader title="Project Wise Distribution" />
                  <CardContent>
                    <Box
                      className={
                        projectWiseDistribution.length > 5 ? classes2.barGraph : classes2.barGraph2
                      }
                    >
                      <BarChart
                        width={
                          projectWiseDistribution.length <= 7
                            ? 350
                            : projectWiseDistribution.length * 40
                        }
                        height={350}
                        data={projectWiseDistribution
                          .sort((a: any, b: any) => {
                            return a.value - b.value;
                          })
                          .reverse()}
                        layout="horizontal"
                        margin={{
                          bottom: 70,
                        }}
                      >
                        <XAxis
                          dataKey="name"
                          textAnchor="end"
                          sclaeToFit="true"
                          verticalAnchor="start"
                          interval={0}
                          angle="-20"
                          stroke="#000"
                          fontSize={10}
                        />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend verticalAlign="top" height={36} align="right" />
                        <Bar dataKey="value" fill="#205723" shape={<TriangleBar />} barSize={50} />
                      </BarChart>
                    </Box>
                  </CardContent>
                </Card>
              )}
              {documentProfileWiseDistribution.length > 0 && (
                <Card Card className={classes2.cardRoot}>
                  <CardHeader title="Document Profile Wise Distribution" />
                  <CardContent>
                    <Box
                      className={
                        documentProfileWiseDistribution.length > 5
                          ? classes2.barGraph
                          : classes2.barGraph2
                      }
                    >
                      <BarChart
                        width={
                          documentProfileWiseDistribution.length < 7
                            ? 300
                            : documentProfileWiseDistribution.length * 40
                        }
                        height={350}
                        data={documentProfileWiseDistribution
                          .sort((a: any, b: any) => {
                            return a.value - b.value;
                          })
                          .reverse()}
                        layout="horizontal"
                        margin={{
                          bottom: 70,
                        }}
                      >
                        <XAxis
                          dataKey="name"
                          textAnchor="end"
                          sclaeToFit="true"
                          verticalAnchor="start"
                          interval={0}
                          angle="-20"
                          stroke="#000"
                          fontSize={10}
                        />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend verticalAlign="top" height={36} align="right" />
                        <Bar dataKey="value" fill="#5e35b1" shape={<TriangleBar />} barSize={50} />
                      </BarChart>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Box>
            {/* <Box>
              {countryWiseDistribution.length > 0 && (
                <Card Card className={classes2.cardRoot2}>
                  <CardHeader title="Artifact Level Distribution" />
                  <CardContent
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      className={
                        countryWiseDistribution.length > 5 ? classes2.barGraph : classes2.barGraph2
                      }
                    >
                      <BarChart
                        width={
                          countryWiseDistribution.length === 1
                            ? 900
                            : countryWiseDistribution.length * 120
                        }
                        height={350}
                        data={countryWiseDistribution
                          .sort((a: any, b: any) => {
                            return a.value - b.value;
                          })
                          .reverse()}
                        layout="horizontal"
                        margin={{
                          bottom: 48,
                        }}
                      >
                        <XAxis
                          dataKey="name"
                          textAnchor="end"
                          sclaeToFit="true"
                          verticalAnchor="start"
                          interval={0}
                          angle="-45"
                          stroke="#000"
                          fontSize={10}
                        />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Legend verticalAlign="top" height={36} align="right" />
                        <Tooltip />
                        <Bar dataKey="value" fill="#4fa7f9" shape={<TriangleBar />} barSize={50} />
                      </BarChart>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Box> */}
            {/* <Box>
              {artifactWiseDistribution.length > 0 && (
                <Card Card className={classes2.cardRoot2}>
                  <CardHeader title="Artifact Level Distribution" />
                  <CardContent
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      className={
                        artifactWiseDistribution.length > 5 ? classes2.barGraph : classes2.barGraph2
                      }
                    >
                      <BarChart
                        width={
                          artifactWiseDistribution.length === 1
                            ? 900
                            : artifactWiseDistribution.length * 120
                        }
                        height={350}
                        data={artifactWiseDistribution
                          .sort((a: any, b: any) => {
                            return a.value - b.value;
                          })
                          .reverse()}
                        layout="horizontal"
                        margin={{
                          bottom: 48,
                        }}
                      >
                        <XAxis
                          dataKey="name"
                          textAnchor="end"
                          sclaeToFit="true"
                          verticalAnchor="start"
                          interval={0}
                          angle="-45"
                          stroke="#000"
                          fontSize={10}
                        />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Legend verticalAlign="top" height={36} align="right" />
                        <Tooltip />
                        <Bar dataKey="value" fill="#4fa7f9" shape={<TriangleBar />} barSize={50} />
                      </BarChart>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Box> */}
          </Paper>
        )}
      </Box>
    </>
  );
}

export default ExpertSearch;
