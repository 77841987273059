import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { parseFromValuesOrFunc } from '../utils';

const ColumnPinningButtons = ({ column, table, ...rest }: any) => {
  const {
    options: {
      icons: { PushPinIcon },
      localization,
    },
  } = table;

  const handlePinColumn = (pinDirection: 'left' | 'right' | false) => {
    column.pin(pinDirection);
  };

  return (
    <>
      <Box
        {...rest}
        sx={(theme) => ({
          minWidth: '70px',
          textAlign: 'center',
          ...(parseFromValuesOrFunc(rest?.sx, theme) as any),
        })}
      >
        {column.getIsPinned() ? (
          <Tooltip title={localization.unpin}>
            <IconButton onClick={() => handlePinColumn(false)} size="small">
              <PushPinIcon color="info" />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            <Tooltip title={localization.pinToLeft}>
              <IconButton onClick={() => handlePinColumn('left')} size="small">
                <PushPinIcon
                  color="info"
                  style={{
                    transform: 'rotate(90deg)',
                  }}
                />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title={localization.pinToRight}>
              <IconButton onClick={() => handlePinColumn('right')} size="small">
                <PushPinIcon
                  style={{
                    transform: 'rotate(-90deg)',
                  }}
                />
              </IconButton>
            </Tooltip> */}
          </>
        )}
      </Box>
    </>
  );
};

export default ColumnPinningButtons;
