import React, { useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { MutliAllocationExtraction } from '../utils/allocationExtraction';
import { extractUserName } from '../utils/extractdata';
import useRequirementOperation from '../AssignmentFlow/Hooks/useRequirementOperation';

const MultiAllocator = (props) => {
  const {
    open,
    handleClose,
    requirements,
    List_of_pbs,
    keys,
    metaData,
    setMetaData,
    groups,
    users,
    user_id,
    projectId,
    workloadId,
    filename,
  } = props;
  const [checkList, setCheckList] = useState([]);
  useMemo(() => {
    const list = List_of_pbs.map((Data, index) => {
      return {
        index,
        isChecked: false,
        name: Data,
      };
    });

    setCheckList(list);
  }, [List_of_pbs]);

  const selectedPBS = useMemo(() => {
    return checkList.filter((eachPBS: any) => {
      return eachPBS.isChecked;
    });
  }, [checkList]);

  const handleCheckBox = (event, index) => {
    if (checkList.length > 0) {
      checkList[index] = {
        ...checkList[index],
        isChecked: event.target.checked,
      };
    }
    const newList = [...checkList];
    setCheckList(newList);
  };

  const { loading, handleMultiAllocSubmit } = useRequirementOperation({
    metaData,
    groups,
    users,
    onChangeTableData: setMetaData,
    projectId,
    workloadId,
    filename,
  });

  const handleSubmit = () => {
    handleMultiAllocSubmit(selectedPBS, handleClose, keys, user_id);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
        <Box sx={{ width: '100%' }}>{loading && <LinearProgress />}</Box>
        <DialogTitle>
          <Typography variant="h1">Mutli Allocation</Typography>
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <Box
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto auto auto',
                gridRowGap: '5px',
              }}
            >
              {checkList.map((eachItem: any, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    color="info"
                    control={
                      <Checkbox
                        checked={eachItem.isChecked}
                        color="secondary"
                        onChange={(e) => {
                          handleCheckBox(e, index);
                        }}
                      />
                    }
                    label={eachItem.name}
                  />
                );
              })}
            </Box>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={selectedPBS.length === 0 || loading}
            onClick={() => {
              handleSubmit();
            }}
            variant="contained"
            color="info"
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MultiAllocator;
