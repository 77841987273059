import { Category, GroupData } from 'GroupType';
import React from 'react';

export type ProjectGroupsContextType = {
  allDefaultGroups: any;
  datatype: 'leaders' | Category;
  RBSCustom: GroupData;
  SBSCustom: GroupData;
  PBSCustom: GroupData;
  ABSCustom: GroupData;
  OBSCustom: GroupData;

  setRBSCustom: (group: GroupData) => any;
  setSBSCustom: (group: GroupData) => any;
  setPBSCustom: (group: GroupData) => any;
  setABSCustom: (group: GroupData) => any;
  setOBSCustom: (group: GroupData) => any;
};

const ProjectGroupsContext = React.createContext<ProjectGroupsContextType>({
  allDefaultGroups: {},
  datatype: 'leaders',
  RBSCustom: {
    category: 'RBS',
    label_ids: [],
  },
  SBSCustom: {
    category: 'SBS',
    label_ids: [],
  },
  PBSCustom: {
    category: 'PBS',
    label_ids: [],
  },
  ABSCustom: {
    category: 'ABS',
    label_ids: [],
  },
  OBSCustom: {
    category: 'OBS',
    label_ids: [],
  },
  setRBSCustom: () => {},
  setSBSCustom: () => {},
  setPBSCustom: () => {},
  setABSCustom: () => {},
  setOBSCustom: () => {},
});

export default ProjectGroupsContext;
