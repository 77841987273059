import { Paper } from '@material-ui/core';
import React from 'react';

const Owning = (setToken: any) => {
  return (
    <Paper style={{ textAlign: 'center' }}>
      <h1>Owning Details</h1>
    </Paper>
  );
};

export default Owning;
