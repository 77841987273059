import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState } from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import { makeStyles } from '@material-ui/core';
import TableIs from './TableIs';
import Loading from '../../../../components/Core/Loading';

const useStyles = makeStyles({
  loader: {
    position: 'relative',
    '& .MuiCircularProgress-root': {
      position: 'relative',
      margin: '100px auto',
      fontSize: '25px',
      width: '1em',
      height: '1em',
      borderRadius: '50%',
      textIndent: '-9999em',
      animation: 'load5 1.1s infinite ease',
      transform: 'translateZ(0)',
      // Moving this a little off the edge prevents horizontal scrollbar from flickering in and out
      top: 9,
      right: '50%',
      // textAlign: 'center',
    },
  },
  tabsSt: {
    '& .MuiTabs-scrollButtons': {
      '& .MuiSvgIcon-root': {
        color: 'white',
        border: '2px solid rgb(220, 50, 35)',
        borderRadius: 2,
      },
    },
  },
  eventDialogDelete: {
    position: 'relative',
    '& .MuiCircularProgress-root': {
      position: 'absolute',
      // Moving this a little off the edge prevents horizontal scrollbar from flickering in and out
      top: 0,
      right: '50%',
      // textAlign: 'center',
    },
    '& .MuiFab-sizeMedium': {
      position: 'absolute',
      top: 0,
      right: '50%',
      // right: 9,
      marginRight: -25,
      marginTop: -15,
      textAlign: 'center',
    },
  },
});

export default function ScrollableTabsButtonAuto({ data }: any) {
  const { numberOfSurveys, surveys, cssCards } = data || {};
  const classes = useStyles();
  const [surveyNo, setSurveyNo] = useState<Number>(numberOfSurveys);
  const [surveyAre, setSurveyAre] = useState<[]>(surveys);
  const [value, setValue] = React.useState(1);
  const [surveyId, setSurveyId] = React.useState(1);
  const [loader, setLoader] = React.useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setLoader(true);
    setTimeout(() => {
      setValue(newValue);
      // setSurveyId(newValue);
      setLoader(false);
    }, 500);
  };

  return (
    <Box sx={{}}>
      <span style={{ color: '#DC3223', fontWeight: 'bold' }}>
        Please select the required survey to see the details*
      </span>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        style={{
          backgroundColor: '#002A45',
        }}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#DC3223',
            height: 5,
          },
        }}
        className={classes.tabsSt}
      >
        {surveys?.map((a, b) => {
          return (
            <Tab
              label={`Survey ${b + 1}`}
              value={Number(a.id)}
              key={a.id}
              style={{
                fontWeight: 'bold',
                color: 'white',
              }}
              icon={<ArticleIcon />}
            />
          );
        })}
      </Tabs>
      <div style={{ marginTop: 10 }}>
        {/* {value === 1 && ''} */}
        {loader && <Loading />}
        {loader === false && value > 1 && <TableIs cardDetails={cssCards[value]} />}
      </div>
    </Box>
  );
}
