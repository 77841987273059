import { makeStyles } from '@material-ui/core';

export const drawerWidth = 272;
export const HomeuseStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    backgroundColor: '#EEF1F9',
    width: drawerWidth,
    // maxHeight: '100vh',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(12),
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    background: '#002A45 0% 0% no-repeat padding-box',
  },
  drwaerCloseStyle: {
    background: '#EEF1F9 0% 0% no-repeat padding-box',
    textAlign: 'center',
    padding: '5px 0 5px 0',
    borderBottom: '1px solid #495866',
  },
  drawerFooter: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
  },
  root: {
    flexDirection: 'row-reverse',
    maxWidth: drawerWidth,
    margin: '0',
    font: 'normal normal medium 20px/26px Alstom !important',
    color: theme.palette.primary.contrastText,
    '& > *': {
      color: theme.palette.primary.contrastText,
    },
  },
  treeview: {
    margin: '0',
    // height: 400,
    flexGrow: 1,
    maxWidth: drawerWidth,
    borderRadius: '0px',
    // label: {
    //   paddingLeft: 4000,
    // },
    '& .MuiTreeItem-label': {
      marginLeft: -7,
    },
    '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      background: 'none',
    },
    color: '#002A45 !important',
  },
  treeViewBottom: {
    margin: '0',
    // height: 400,
    flexGrow: 1,
    bottom: 0,
    position: 'fixed',
    maxWidth: drawerWidth,
    borderRadius: '0px',
    overflow: 'hidden',
    // label: {
    //   paddingLeft: 4000,
    // },
    '& .MuiTreeItem-label': {
      marginLeft: -7,
    },
    '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: '#DC3223 !important',
      color: '#FFFFFF !important',
      opacity: 1,
    },
    '& .Mui-selected > .MuiTreeItem-content': {
      backgroundColor: '#002A45 !important',
    },
  },
  treeNodeBottom: {
    '&:hover': {
      // backgroundColor: theme.palette.primary.contrastText,
      background: 'none !important',
      // borderRadius: '12px',
    },
    padding: '13px 108px 13px 0',
  },
  treeItem: {
    background: '#EEF1F9 0% 0% no-repeat padding-box !important',
    borderBottom: '1px solid #C4D4EC',
    opacity: 1,
    '& .MuiTreeItem-group': {
      marginLeft: 11,
    },
  },
  treeItemInside: {
    background: '#002A45 0% 0% no-repeat padding-box !important',
    borderBottom: '0.1px solid rgba(196, 212, 236, .2) !important',
    opacity: 1,
  },
  treeNode: {
    '&:hover': {
      background: 'none !important',
    },
    padding: '13px 0 13px 0',
  },
  treeNodeInside: {
    color: theme.palette.primary.contrastText,
    padding: '13px 0 13px 0',
  },
  iconOne: {
    margin: '0 10px 0 0',
    // color: '#002A45',
  },
  iconSecond: {
    margin: '0 10px 0 0',
    color: 'green',
  },
  subicon: {
    marginRight: '10px',
    fontSize: '15px',
  },
  successIcon: {
    backgroundColor: '#DC3223',
  },
  successIconDNs: {
    backgroundColor: 'green',
  },
  treeProjectLabel: {
    fontSize: 16,
    maxWidth: 180,
    flexGrow: 1,
    textOverflow: 'ellipsis',
    /* Nécessaires pour text-overflow */
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  link: {
    textDecoration: 'none',
    color: '#FFFFFF',
    display: 'flex',
  },
  tooltips: {
    background: '#002A45 0% 0% no-repeat padding-box !important',
  },
}));
