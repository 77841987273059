import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      style={{
        display: 'flex',
        backgroundColor: '#002A45',
        justifyContent: 'flex-end',
        padding: '0px',
        height: '1.5rem',
      }}
      {...other}
    >
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon style={{ color: 'white' }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function CustomDialog({ project, open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} />
      <DialogContent dividers style={{ backgroundColor: '#19AA6E' }}>
        <Typography style={{ fontSize: '1em', fontWeight: 'bold', color: '#002A45' }}>
          {`File successfully uploaded in the folder ${project?.folders[0]?.name}`}
        </Typography>
      </DialogContent>
    </BootstrapDialog>
  );
}

export default CustomDialog;
