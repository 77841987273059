import react from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const Container = styled.div`
  display: flex;
  gap: 20px; // Gap between cards
  justify-content: center;
  align-items: center;
  height: 80vh; // Height of the container
`;

const Card = styled(Link)`
  width: 350px; // Width of the card
  height: 350px; // Height of the card
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; // Text color
  font-weight: normal; // Text weight
  font-size: 24px; // Text size
  text-decoration: none;
  border-radius: 10px; // Optional: to give rounded corners
  position: relative; // For positioning animation
  overflow: hidden; // To contain children
  cursor: pointer;
  transition: transform 0.6s; // Smooth transition for hover
  animation: ${fadeIn} 1s ease-in-out;

  &.green {
    background-color: #19aa6e; // Change background color
  }

  &.red {
    background-color: #aa1919; // Change background color
  }

  &.blue {
    background-color: #002a45; // Change background color
  }

  &:hover svg {
    transform: translateY(130px); // Move icon down
  }

  &:hover span {
    transform: translateY(-100px); // Move text up
  }
`;

// Glassy effect box inside the card
const GlassyBox = styled.div`
  width: 90%; // Adjust width as necessary
  height: 90%; // Adjust height as necessary
  background: rgba(255, 255, 255, 0.1); // White with low opacity for glassy effect
  border-radius: 15px; // Slightly rounded corners
  backdrop-filter: blur(10px); // Blur effect for glass appearance
  border: 1px solid rgba(255, 255, 255, 0.2); // Optional: Light border for better visibility
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.span`
  transition: transform 0.6s; // Smooth transition on hover
  text-align: center; // Center text
`;

const Icon = styled.div`
  font-size: 120px; // Increase icon size
  margin-bottom: 10px; // Space between icon and text
  transition: transform 0.6s; // Smooth transition on hover
`;

const AlstomText = styled.span`
  font-size: 32px;
  font-weight: bold; // Make it bold
  color: white; // Change to white
  font-style: italic; // Italic style
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); // Add text shadow for depth
  text-align: center; // Center text
`;

export { Container, Card, Text, Icon, AlstomText, GlassyBox };
