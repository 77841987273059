import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { runSimilarizeList } from '../../../../../api/module2';
import Loading from '../../../../../components/Core/Loading';
import SelectPluginHoc from '../../../../../components/Datagrid/SelectPlugin';
import TableComponent from '../../../../../components/Datagrid/TableComponent';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import Module1Context from '../../../Module1Context';

type Props = {
  inputSimList: any[];
};

export const useStyles = makeStyles((theme) => ({
  root: {},
  bloc: {
    height: 250,
    borderRadius: 10,
    border: 'solid 1px;',
    borderColor: theme.palette.primary.light,
    margin: 10,
  },
  overflow: {
    height: 250,
    overflowY: 'scroll',
    wordWrap: 'break-word',
  },
  iconMargin: {
    marginRight: '16px',
    color: theme.palette.primary.dark,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.primary.light,
  },
  error: {
    color: theme.palette.error.main,
  },
  tabs: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  tabstyle: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px 5px 0 0',
    backgroundColor: theme.palette.primary.main,
    color: '#fff !important',
    fontSize: 14,
    fontWeight: 700,
  },
  pdfPanel: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    margin: 10,
    padding: '0px !important',
    minHeight: 250,
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
  },
  active: {
    background: theme.palette.success.main,
    border: 'solid 1px green',
    borderRadius: 5,
    padding: 5,
    color: 'white',
  },
  inactive: {
    background: theme.palette.error.main,
    border: 'solid 1px red',
    borderRadius: 5,
    padding: 5,
    color: 'white',
  },
  customWidth: {
    backgroundColor: theme.palette.primary.dark,
    fontSize: String(theme.typography.body2),
  },
}));

function Similarizer({ inputSimList }: Props) {
  const classes = useStyles();
  const iSimuRef = useRef(null);
  const { setSnack, project } = useContext(Module1Context);
  const [loading, setLoading] = useState(false);
  const [simuRows, setSimuRows] = useState<any>([]);
  const [simuColumns, setSimuColumns] = useState<any>([]);
  const [simuResults, setSimuResults] = useState<any>([]);
  const [listText, setListText] = useState<string[]>([]);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [columnWidths, setColumnWidths] = useState([] as any);
  const [columnsdataset, setColumnsdataset] = useState<any>([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([
    'Rank',
    'Product Line',
    'Platform',
    'Solution',
    'Product',
    'CT Code',
    'Artifact Type',
    'Artifact ID',
    'Artifact Covering Documents',
    'Artifact_Rationale',
    'Artifact MoP Class',
    'RBS',
    'PBS Allocation',
    'Created By',
    'Created On',
    'Document Title',
    'Document Profile',
    'Document Path',
    'Document Version',
    'Document ABS',
    'Document OBS',
    'Last Modified By',
    'Last Modified On',
    'URL',
    'Module URL',
    'Module Create By',
    'Module Created On',
    'Module Last Modified By',
    'Module Last Modified On',
  ]);

  const exporterSimuRef = useRef(null);

  const startSimuExport = useCallback(() => {
    exporterSimuRef.current.exportGrid();
  }, [exporterSimuRef]);

  const onSimuSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'iSimilarizer_Results.xlsx');
    });
  };

  const handleSimuRowsColumns = async (myRows) => {
    if (myRows && myRows.length > 0) {
      const myCols = Object.keys(myRows[0]).map((key) => ({
        name: key,
        title: key.toUpperCase(),
      })) as Column[];
      const resizeColumns = myCols.map((c, i) => {
        if (i === 0) {
          return {
            columnName: c.name,
            width: 300,
          };
        }
        if (i === 1) {
          return {
            columnName: c.name,
            width: 300,
          };
        }
        if (i === 2) {
          return {
            columnName: c.name,
            width: 300,
          };
        }
        if (i === 3) {
          return {
            columnName: c.name,
            width: 300,
          };
        }
        return {
          columnName: c.name,
          width: 300,
        };
      });
      setColumnWidths(resizeColumns);
      setColumnsdataset(myCols);
      setSimuRows(myRows);

      setSimuColumns(
        Object.keys(myRows[0]).map((key) => ({
          name: key,
          title: key.toUpperCase(),
        })) as Column[]
      );
    }
    // else {
    //   setSnack('No similar results found', 'info');
    // }
  };

  useEffect(() => {
    setLoading(true);
    try {
      iSimuRef?.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      //
    }
    let businessEntityData = ['Services'];
    if (project.entity === 'D&IS') {
      businessEntityData = ['Digital & Integrated Systems'];
    } else if (project.entity === 'RSC') {
      businessEntityData = ['Rolling Stock & Components'];
    }
    runSimilarizeList({
      inputList: inputSimList,
      businessEntity: businessEntityData,
    })
      .then((payload: any) => {
        if (Array.isArray(payload?.output)) {
          setListText(inputSimList);
          setSimuResults(payload.output);
          handleSimuRowsColumns(JSON.parse(payload?.output[0]?.result_data));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [inputSimList]);

  const handleSimuTextChange = (event) => {
    handleSimuRowsColumns(JSON.parse(simuResults[event.target.value]?.result_data));
  };

  // if (loading || inputSimList.length === 0) return <Loading />;

  return (
    <div>
      {/* {IS_DEBUG_MODE && (
        <ReactJson
          src={{
            simuRows,
            simuColumns,
            simuResults,
            listText,
          }}
          collapsed={true}
          theme="monokai"
        />
      )} */}
      <Grid item lg={12} sm={12} md={12} ref={iSimuRef}>
        <div className={classes.pdfPanel}>
          <Typography variant="h5" className={classes.heading}>
            iSimilarizer
          </Typography>
          <ExpressGrid rows={simuRows} columns={columnsdataset}>
            <DataTypeProvider
              for={['Text', 'Document Title', 'Project', 'RBS', 'PBS Allocation', 'Compliance']}
              formatterComponent={({ value }) => <span title={value}>{String(value)}</span>}
            />
            <SortingState />
            <IntegratedSorting />
            <Table
              // columnExtensions={[
              //   {
              //     columnName: 'Text',
              //     width: '300px',
              //     wordWrapEnabled: toggleHeight,
              //   },
              //   { columnName: 'Document Title', width: '200px' },
              //   { columnName: 'PBS Allocation', width: '150px' },
              //   { columnName: 'Compliance', width: '200px' },
              // ]}
              columnExtensions={[
                ...columnsdataset.map((c) => {
                  return {
                    columnName: c.name,
                    width: 'auto',
                    wordWrapEnabled: toggleHeight,
                  };
                }),
              ]}
              tableComponent={TableComponent}
            />
            <TableColumnResizing
              onColumnWidthsChange={setColumnWidths}
              columnWidths={columnWidths}
              resizingMode="nextColumn"
              // defaultColumnWidths={[
              //   ...simuColumns.map((c) => {
              //     if (c.name === 'Text') return { columnName: 'Text', width: 400 };
              //     if (c.name === 'Project') return { columnName: 'Project', width: 200 };
              //     if (c.name === 'Compliance') return { columnName: 'Compliance', width: 200 };
              //     if (c.name === 'Score') return { columnName: 'Score', width: 150 };
              //     return {
              //       columnName: c.name,
              //       width: Math.ceil(2200 / simuColumns.length),
              //     };
              //   }),
              // ]}
            />
            <TableHeaderRow showSortingControls />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={setHiddenColumnNames}
            />
            <Toolbar />

            <SelectPluginHoc onChange={handleSimuTextChange} items={listText} />
            <ColumnChooser />
            <ToolBarPlugin name="Download" onClick={startSimuExport} />
            <ToolBarPlugin
              name="Height"
              title="Wrap Text"
              onClick={() => setToggleHeight(!toggleHeight)}
            />
          </ExpressGrid>
          <GridExporter
            ref={exporterSimuRef}
            rows={simuRows}
            columns={columnsdataset}
            onSave={onSimuSave}
          />
        </div>
      </Grid>
    </div>
  );
}

export default memo(Similarizer);
