import React, { useContext, useEffect, useState } from 'react';
import { Project } from '../../react-app-env';
import formatBytes from '../../tools/formatBytes';

export interface Module6ContextType {
  project: Project;
  setProject: (d: Project) => any;
  files: any;
  setFiles: any;
}

const Module6Context = React.createContext<Module6ContextType>({
  project: {} as any,
  setProject: (): any => {},
  files: [] as any,
  setFiles: (): any => {},
});

type Props = {
  project: Project;
  children: any;
};

const Module6ContextProvider = ({ project, children }: Props) => {
  const [_project, _setProject] = useState(project);
  const [_files, _setFiles] = useState([]);

  useEffect(() => {
    const { folders } = project;
    const allResources = [] as any[];
    if (folders) {
      for (const eachFolder of folders) {
        if (eachFolder.resources && eachFolder.resources.length > 0)
          for (const r of eachFolder.resources) {
            const fileExtension = r.filename.toLowerCase().split('.').pop();
            if (
              //   fileExtension === 'pdf' ||
              fileExtension === 'csv' ||
              fileExtension === 'xls' ||
              fileExtension === 'xlsx' ||
              fileExtension === 'xlsm' ||
              fileExtension === 'xlsb'
              //   fileExtension === 'docx'
            ) {
              allResources.push({
                ...r,
                size: formatBytes(Number(r.size), 0),
                id: r.etag,
                folderName: eachFolder.name,
                folderId: eachFolder.id,
                isNew: false,
              });
            }
          }
      }
    }
    _setFiles(allResources.sort((a, b) => new Date(b.date) - new Date(a.date)));
  }, []);
  return (
    <Module6Context.Provider
      value={{
        project: _project,
        setProject: _setProject,
        files: _files,
        setFiles: _setFiles,
      }}
    >
      {children}
    </Module6Context.Provider>
  );
};

export default Module6Context;

export { Module6ContextProvider };
