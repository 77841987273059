import { Box, Button, List, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HomeIcon from '@mui/icons-material/Home';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import Fab from '@mui/material/Fab';
import AssessmentStyle, { ListItem } from './AssessmentStyle';
import ProjectBreadCrumbs from '../../../components/Core/ProjectBreadCrumbs';
import logoCurrentModule from '../../../assets/m2_blue.png';
import Questions from './Questuions';

const AssessmentOps = [
  'Requirements Management Process',
  'Requirements Management Method',
  'Tools',
  'Requirement Management Data',
  'Requirements Management Resources',
];

const objAssessment = [
  {
    name: 'Requirements Management Process',
    subTopic: [
      '1.1 Requirements Management Plan',
      '1.2 Reqirements Management Monitoring & Evaluation',
    ],
  },
  {
    name: 'Requirements Management Method',
    subTopic: [
      '2.1 identify & Capture & Allocate Requirements',
      '2.2 Requirement Owning and Analysis',
      '2.3 Task: Develop and Trace reqirements',
    ],
  },
  {
    name: 'Tools',
    subTopic: [
      '3.1 Requirement Management Tools',
      '3.2 Tools contributing to Requirement Management',
    ],
  },

  {
    name: 'Requirement Management Data',
    subTopic: [
      '4.1 Data Protection',
      '4.2 Attributes',
      '4.3 Links',
      '4.4 Configuration & Change Data',
    ],
  },
  {
    name: 'Requirements Management Resources',
    subTopic: ['5.1 Resources/Performers'],
  },
];
const SubOperation = ({ subAssementOps, setAssessOp }) => {
  const classes = AssessmentStyle();
  const [showQna, setShowQna] = useState(false);
  return (
    <>
      {!showQna && (
        <Box className={classes.subOperationListDiv}>
          <Typography style={{ fontSize: '2.2em', color: '#002A45', fontWeight: 'bold' }}>
            {subAssementOps.name}
          </Typography>
          <Box style={{ padding: '4rem', width: '100%' }}>
            <List style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
              {subAssementOps.subTopic.map((data) => {
                return (
                  <ListItem
                    button
                    onClick={() => {
                      setShowQna(true);
                    }}
                  >
                    {data}
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box className={classes.parentBtn}>
            <Fab
              size="medium"
              style={{ backgroundColor: 'red', color: 'white' }}
              onClick={() => {
                setAssessOp(-1);
              }}
            >
              <HomeIcon />
            </Fab>
          </Box>
        </Box>
      )}
      {showQna && <Questions setAssessOp={setAssessOp} />}
    </>
  );
};
const Assessment = () => {
  const classes = AssessmentStyle();
  const [assessOps, setAssessOp] = useState(-1);
  const { projectId, workloadId } = useParams() as any;

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {/* <ProjectBreadCrumbs
        projectId={projectId}
        nodes={[
          {
            label: 'Assessment',
            to: `#`,
            icon: <img src={logoCurrentModule} alt="Assessment" title="Assessment" />,
          },
        ]}
      /> */}
      <Box className={classes.root}>
        {assessOps === -1 && (
          <Box className={classes.parrentDiv}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1rem',
                gap: '10px',
              }}
            >
              <AssessmentIcon fontSize="large" />
              <Typography style={{ fontSize: '2.2em', color: '#002A45', fontWeight: 'bold' }}>
                iAssessment
              </Typography>
            </Box>
            <Box className={classes.operationListDiv}>
              <List className={classes.parentList}>
                {AssessmentOps.map((data, index) => {
                  return (
                    <ListItem
                      button
                      onClick={() => {
                        setAssessOp(index);
                      }}
                    >{`${index + 1} ${data}`}</ListItem>
                  );
                })}
              </List>
            </Box>
          </Box>
        )}
        {assessOps !== -1 && (
          <SubOperation subAssementOps={objAssessment[assessOps]} setAssessOp={setAssessOp} />
        )}
      </Box>
    </Box>
  );
};

export default Assessment;
