import { Snackbar, SnackbarContentProps } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import isEqual from 'react-fast-compare';

export interface AdminContextType {
  setSnackMsg: (msg: SnackbarContentProps['message']) => any;
  setSnackSeverity: (severity: AlertProps['severity']) => any;
  setSnack: (msg: string, severity?: AlertProps['severity'], duration?: number) => any;
}

const AdminContext = React.createContext<AdminContextType>({
  setSnackMsg: (): any => {},
  setSnackSeverity: (): any => {},
  // combi msg + severity
  setSnack: (): any => {},
});

type Props = {
  children: any;
};

const AdminContextProvider = ({ children }: Props): any => {
  const [snackMsg, setSnackMsg] = useState<SnackbarContentProps['message']>('');
  const [snackDuration, setSnackDuration] = useState<number>(3000);
  const [snackSeverity, setSnackSeverity] = useState<AlertProps['severity']>('success');

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackMsg('');
  };
  return (
    <AdminContext.Provider
      value={{
        setSnack: (
          msg: string,
          severity = 'success' as AlertProps['severity'],
          duration = 3000
        ) => {
          setSnackDuration(duration);
          setSnackMsg(msg);
          setSnackSeverity(severity);
        },
      }}
    >
      {snackMsg && (
        <Snackbar
          open={snackMsg !== ''}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={snackDuration}
          onClose={handleClose}
        >
          <Alert severity={snackSeverity}>{snackMsg}</Alert>
        </Snackbar>
      )}
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;

export { AdminContextProvider };
