import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const NoResultsFound = () => {
  return (
    <Box
      style={{
        border: '2px solid red',
        width: '100%',
        padding: '20px',
        borderRadius: '5px',
      }}
    >
      <Typography style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>
        Search text did not match any documents. Suggestions for better results:{' '}
      </Typography>
      <ul
        style={{
          color: '#002A45',
          fontSize: '0.989rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <li>Ensure all words are spelled correctly.</li>
        <li>Try different keywords.</li>
        <li>Try more generic keywords.</li>
      </ul>
    </Box>
  );
};

export default NoResultsFound;
