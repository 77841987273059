import { GroupData } from 'GroupType';
import { BACKEND_URL } from '../const';
import axios from './axios';

export interface ProjectUpdate {
  name?: string;
  description?: string;
  id?: string;
  entity?: string;
  types?: string;
  solution?: string;
  language?: string;
  prcode?: string;
  leadingunit?: string;
  region?: string;
  country?: string;
  platform?: string;
  product?: string;
  rules?: any;
  functions?: any;
  clanguage?: any;
  levelmodel?: any;
}

const getAll = async (isPublic = false): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/projects?${isPublic ? 'is_public' : ''}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getProjectbyID = async (project_id): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/projects?project_id=${project_id}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getAllLite = async (isPublic = false): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/projects/lite`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const getAllProjectDetailsById = async (projectId): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/projects/${projectId}/forproject`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getAllAdminProjects = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/projects?all_users`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const getAllAdminHistory = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/users/user/history`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getTemplates = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/public/projects/templates`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const projectCreate = async (body: ProjectUpdate): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/projects`, {
      name: body.name,
      description: body.description,
      user_id: 1,
      entity: body.entity,
      types: body.types,
      solution: body.solution,
      language: body.clanguage,
      prcode: body.prcode,
      platform: body.platform,
      product: body.product,
      leadingunit: body.leadingunit,
      region: body.region,
      country: body.country,
      modelpm: body.levelmodel,
    });
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getCTcodedetails = async (ctcode: any): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/project_six_layer/get/${ctcode}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getAllProjectCreationLanguages = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/notifications/languages`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const projectSave = async (body: ProjectUpdate): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/projects/${body.id}`, {
      name: body.name,
      rules: body.rules,
      functions: body.functions,
    });
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const projectsDelete = async (projectList: Number[]): Promise<any> => {
  try {
    const response = await axios.delete(`${BACKEND_URL.root}/projects`, { data: projectList });
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const adminHistoryDelete = async (body: Number[]): Promise<any> => {
  try {
    const response = await axios.delete(`${BACKEND_URL.root}/users`, {
      data: body,
    });
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getAllCustomedGroups = async (projectId: string): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/projects/${projectId}/groups`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const createProjectGroup = async (body: GroupData): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/projects/${body.project_id}/groups`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const updateProjectGroup = async (body: GroupData): Promise<any> => {
  try {
    const response = await axios.put(
      `${BACKEND_URL.root}/projects/${body.project_id}/groups/${body.id}`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const createProjectGroupUsers = async (body: any[], projectId: number): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/projects/${projectId}/group_users`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const projectUpdate = async (body: any[], projectId: number): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/projects/${projectId}/updateproject`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getProjectAnalysisDataSet = async (projectId: number): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/projects/analysis_dataset/${projectId}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getProjectGroupsUsers = async (projectId: number): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/projects/groups/${projectId}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

type outputStatsRequirements = {
  workload_id: number;
  filename: string;
  count: number;
};

const statsRequirements = async (projectId: number): Promise<outputStatsRequirements[]> => {
  try {
    const response = await axios.get(
      `${BACKEND_URL.root}/projects/${projectId}/stats/requirements`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getSafetyConfig = async (id: any, attributeName: any): Promise<any> => {
  try {
    const response = await axios.get(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/projects/${id}/getattributes/${attributeName}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const createSafetyConfig = async (projectId: any, data: any): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/projects/${projectId}/attributes`, data);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const editSafetyConfig = async (projectId: any, attributeId: any, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/projects/${projectId}/attributes/${attributeId}`,
      data
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const getProjectGroupUsers = async (projectId: number): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/projects/${projectId}/group_users`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getProjectGlossaryFiles = async (projectId: number, user_id: number): Promise<any> => {
  try {
    const response = await axios.get(
      `${BACKEND_URL.root}/translator_glossary/get/${user_id}?project_id=${projectId}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export {
  getAll,
  getAllLite,
  projectSave,
  projectCreate,
  getTemplates,
  getAllAdminProjects,
  getAllAdminHistory,
  projectsDelete,
  adminHistoryDelete,
  getAllCustomedGroups,
  getAllProjectDetailsById,
  createProjectGroup,
  projectUpdate,
  getAllProjectCreationLanguages,
  updateProjectGroup,
  createProjectGroupUsers,
  statsRequirements,
  getProjectbyID,
  // get config details
  getSafetyConfig,
  createSafetyConfig,
  editSafetyConfig,
  getProjectGroupUsers,
  getProjectGroupsUsers,
  getProjectAnalysisDataSet,
  getCTcodedetails,
  getProjectGlossaryFiles,
};
