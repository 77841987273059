import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import TableComponent from '../../../../components/Datagrid/TableComponent';
import { rowComponent } from '../../../../components/Datagrid/TStyles';
import { IS_DEBUG_MODE } from '../../../../const';
// import TStyles from '../../../../components/Datagrid/TStyles';
import formatBytes from '../../../../tools/formatBytes';
import RunContext from '../../RunContext';

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid black',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      // textAlign: 'center',
    },
    table: {
      border: '1px solid black',
      width: '100%',
      '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
        color: 'white',
      },
      '& .MuiSvgIcon-root': {
        color: 'white',
      },
      '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover': {
        color: 'white',
        '& .MuiSvgIcon-root': {
          color: 'red',
        },
      },
      '& .MuiTableRow-root.Mui-selected:hover': {
        color: 'red',
      },
      '& .MuiTableCell-head': {
        backgroundColor: '#002A45',
        color: 'white',
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

type Props = {
  folders: any;
  selected?: any;
  filterFiles?: string[];
};

export default function StepFiles({ folders, selected: selectedDefault, filterFiles }: Props) {
  const classes = useStyles();
  const [rows, setRows] = React.useState<any>([]);
  const [selection, setSelection] = useState(selectedDefault || []);
  const runContext = useContext(RunContext);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const { config, setConfig } = runContext;

  useEffect(() => {
    let allResources = [] as any[];
    if (folders) {
      for (const eachFolder of folders) {
        if (eachFolder.resources && eachFolder.resources.length > 0)
          for (const r of eachFolder.resources) {
            allResources.push({
              ...r,
              size: formatBytes(Number(r.size), 0),
              id: r.etag,
              folderName: eachFolder.name,
              folderId: eachFolder.id,
            });
            if (filterFiles) {
              allResources = allResources.filter((row: any) => {
                const fileExtension = row.filename.split('.').pop();
                return filterFiles.includes(fileExtension);
              });
            }
          }
      }
    }
    const selectionDefault: any[] = [];

    (config.resources || []).forEach((res) => {
      const i = allResources.findIndex((r) => r.id === res.id);
      if (i !== -1) selectionDefault.push(i);
    });
    setSelection(selectionDefault);
    setRows(allResources);
  }, []);

  const handleSelectionChange = (selectionRows: any) => {
    setSelection(selectionRows);
    const selectedRows = rows.filter((row, index) => selectionRows.includes(index));
    setConfig({ ...config, resources: selectedRows });
  };

  return (
    <div className={classes.root}>
      {IS_DEBUG_MODE && (
        <ReactJson src={{ selection, config, rows }} collapsed={true} theme="monokai" />
      )}
      <Grid rows={rows} columns={columns}>
        <FilteringState
        // defaultFilters={[{ columnName: 'filename', value: 'pdf' }]}
        />
        <IntegratedFiltering />
        <SelectionState selection={selection} onSelectionChange={handleSelectionChange} />
        <IntegratedSelection />
        <SortingState />
        <IntegratedSorting />
        <PagingState defaultCurrentPage={0} defaultPageSize={10} />
        <IntegratedPaging />
        <VirtualTable />
        <Table tableComponent={TableComponent} />
        <TableColumnResizing
          defaultColumnWidths={[
            { columnName: 'filename', width: 400 },
            { columnName: 'size', width: 400 },
            { columnName: 'folderName', width: 400 },
            { columnName: 'date', width: 500 },
          ]}
        />
        <TableHeaderRow showSortingControls />

        <TableFilterRow rowComponent={rowComponent} />
        <TableSelection showSelectAll />
        <PagingPanel pageSizes={pageSizes} />
      </Grid>
    </div>
  );
}
