import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  PagingState,
  IntegratedPaging,
  SortingState,
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel,
  Toolbar,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
} from '@devexpress/dx-react-grid-material-ui';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Search } from '@material-ui/icons';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { withStyles } from '@material-ui/core/styles';
import isafety from '../fixtures/isafety.json';
import { iSafety } from '../../../api/module5';
import Loading from '../../../components/Core/Loading';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import TStyles, { rowComponent, tUseStyles } from '../../../components/Datagrid/TStyles';

const marks = [
  {
    value: 5,
    label: '5',
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      margin: '0px 0px',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      border: '1px solid',
      borderRadius: '10px',
      padding: '10px',
    },
    searchbtn: {
      borderRadius: 35,
      marginLeft: -50,
      paddingBottom: 15,
    },
    searchbtnStyle: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '35px !important',
      },
    },
  })
);
const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

type PayloadText2Dataset = {
  fileType: string;
  Requirement: string;
  Score: string;
  designClosure: string;
  validationClosure: string;
};

function ISafety() {
  const classes = useStyles();
  const tableUseStyles = tUseStyles();
  const [row, setRow] = useState<any>([]);
  const [input, setInput] = useState<any>('');
  const [count, setCount] = useState<any>(5);
  const [score, setScore] = useState<any>(50);
  const [loading, setLoading] = useState(false);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };
  const [toggleHeight, setToggleHeight] = useState(false);

  const columns: any = [
    { name: 'fileType', title: 'fileType' },
    { name: 'Requirement', title: 'Requirement' },
    { name: 'Score', title: 'Score', width: '10px' },
    { name: 'designClosure', title: 'designClosure' },
    { name: 'validationClosure', title: 'validationClosure' },
  ];
  const handleEnterSearch = (e) => {
    if (e.key === 'Enter') {
      const textCleaned = String(input).trim();
      apiCallScoreWise(input, count, score);
    }
  };

  function apiCallScoreWise(input: any, count: any, score: any) {
    setLoading(true);
    iSafety(input, count)
      .then((payload: any) => {
        if (payload) {
          // console.log(payload);
          setLoading(false);
          const initRow = JSON.parse(payload.output);
          setRow(initRow.filter((initRow: PayloadText2Dataset) => initRow.Score >= score));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }

  const handleCountChange = (event, newValue) => {
    setCount(newValue);
    // eslint-disable-next-line func-names
    setTimeout(function () {
      apiCallScoreWise(input, newValue, score);
    }, 1000);
  };
  function HandleReg1(e: any) {
    setInput(e.currentTarget.value);
  }
  function buttonClick() {
    apiCallScoreWise(input, count, score);
  }

  const handleThresholdChangeFilter = (event, newValue) => {
    setScore(newValue);
    // eslint-disable-next-line func-names
    setTimeout(function () {
      apiCallScoreWise(input, count, newValue);
    }, 1000);
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12} style={{ marginTop: '30px', marginLeft: 0, minHeight: '150px' }}>
        <Grid container spacing={1}>
          <Grid style={{ marginLeft: '100px' }} item xs={12}>
            <TextField
              style={{ width: '90%' }}
              id="Hazard_name"
              label="Enter a text"
              defaultValue=""
              variant="outlined"
              onChange={HandleReg1}
              name="hazard_name"
              className={classes.searchbtnStyle}
              onKeyDown={handleEnterSearch}
            />
            <IconButton
              aria-label="search"
              onClick={buttonClick}
              color="primary"
              className={classes.searchbtn}
            >
              <Search />
            </IconButton>
          </Grid>
          <Grid container item xs={12} alignContent="center" justify="center">
            <Grid>
              <Typography id="discrete-slider-always" style={{ marginTop: 40, marginBottom: -20 }}>
                Number <br />
                of records:
              </Typography>
              <Slider
                name="count-slider"
                min={5}
                max={50}
                defaultValue={5}
                // getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-always"
                step={1}
                // marks={marks}
                valueLabelDisplay="on"
                style={{ width: '18rem', marginLeft: 75 }}
                onChangeCommitted={handleCountChange}
                aria-label="Number of records"
              />
            </Grid>
            <Grid style={{ marginLeft: 20 }}>
              <Typography id="discrete-slider-always" style={{ marginTop: 60, marginBottom: -20 }}>
                Score: <br />
              </Typography>
              <Slider
                name="count-slider"
                min={50}
                max={100}
                defaultValue={50}
                // getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-always"
                step={1}
                // marks={marks}
                valueLabelDisplay="on"
                style={{ width: '18rem', marginLeft: 75 }}
                onChangeCommitted={handleThresholdChangeFilter}
                aria-label="Number of records"
              />
            </Grid>
          </Grid>
        </Grid>

        {loading ? (
          <Loading />
        ) : (
          <Grid
            item
            xs={12}
            // style={{
            //   border: '1px solid',
            //   borderRadius: 5,
            //   marginTop: '20px',
            //   marginLeft: '-10px',
            // }}
          >
            {!loading && input && (
              <Typography className={classes.heading}>
                <b>Result for: </b>
                <span style={{ color: 'blue' }}>{input}</span>
              </Typography>
            )}

            <Grid className={tableUseStyles.panelMain}>
              <div style={{ padding: '0px', border: '1px' }}>
                <ExpressGrid rows={row} columns={columns}>
                  <DataTypeProvider
                    for={['Requirement', 'designClosure', 'validationClosure']}
                    formatterComponent={({ value }) => <span title={value}>{value}</span>}
                  />
                  <FilteringState defaultFilters={[{ columnName: 'Requirement', value: '' }]} />
                  <IntegratedFiltering />
                  <SortingState />
                  <IntegratedSorting />
                  <PagingState defaultCurrentPage={0} defaultPageSize={5} />
                  <IntegratedPaging />
                  <Table
                    tableComponent={TableComponent}
                    columnExtensions={[
                      {
                        columnName: 'Requirement',
                        width: '200px',
                        wordWrapEnabled: toggleHeight,
                      },
                      {
                        columnName: 'DesignClosure',
                        width: '200px',
                        wordWrapEnabled: toggleHeight,
                      },
                      {
                        columnName: 'ValidationClosure',
                        width: '200px',
                        wordWrapEnabled: toggleHeight,
                      },
                    ]}
                  />
                  <TableColumnResizing
                    defaultColumnWidths={[
                      { columnName: 'fileType', width: '200px' },
                      { columnName: 'Score', width: '200px' },
                      { columnName: 'Requirement', width: '200px' },
                      {
                        columnName: 'designClosure',
                        width: '200px',
                      },
                      {
                        columnName: 'validationClosure',
                        width: '200px',
                      },
                    ]}
                  />
                  <TableHeaderRow showSortingControls />
                  <TableFilterRow rowComponent={rowComponent} />
                  <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                    onHiddenColumnNamesChange={setHiddenColumnNames}
                  />
                  <Toolbar />
                  <ColumnChooser />
                  <ToolBarPlugin name="Download" onClick={startExport} />
                  <ToolBarPlugin
                    name="Height"
                    title="Wrap Text"
                    onClick={() => {
                      setToggleHeight(!toggleHeight);
                    }}
                  />
                  <PagingPanel pageSizes={pageSizes} />
                </ExpressGrid>
                <GridExporter ref={exporterRef} rows={row} columns={columns} onSave={onSave} />
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
export default ISafety;
