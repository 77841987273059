import React, { useState } from 'react';
import MilestoneTable from './MilestoneTable';
import MilestoneTabs from './MilestoneTabs';

const Milestone = ({ mileStoneData, loadMilestone }) => {
  const { OTD, Billing, DFQ, DOT, EDP, IOT, OTIF } = mileStoneData || {};
  const [tab, setTab] = useState(0);
  return (
    <>
      <MilestoneTabs tab={tab} setTab={setTab} />
      {tab === 0 && (
        <>
          <MilestoneTable rowData={OTD?.details} tableName="OTD" loadMilestone={loadMilestone} />
        </>
      )}
      {tab === 1 && (
        <>
          <MilestoneTable rowData={EDP?.details} tableName="EDP" loadMilestone={loadMilestone} />
        </>
      )}
      {tab === 2 && (
        <>
          <MilestoneTable
            rowData={Billing?.details}
            tableName="Billing"
            loadMilestone={loadMilestone}
          />
        </>
      )}
      {tab === 3 && (
        <>
          <MilestoneTable rowData={DFQ?.details} tableName="DFQ" loadMilestone={loadMilestone} />
        </>
      )}
      {tab === 4 && (
        <>
          <MilestoneTable rowData={DOT?.details} tableName="DOT" loadMilestone={loadMilestone} />
        </>
      )}
      {tab === 5 && (
        <>
          <MilestoneTable rowData={IOT?.details} tableName="IOT" loadMilestone={loadMilestone} />
        </>
      )}
      {tab === 6 && (
        <>
          <MilestoneTable rowData={OTIF?.details} tableName="OTIF" loadMilestone={loadMilestone} />
        </>
      )}
    </>
  );
};

export default Milestone;
