import React from 'react';
import { CircularProgress, CircularProgressProps } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { AppBar, Avatar, ThemeProvider, Toolbar, Tooltip } from '@mui/material';
import './LoaderStyle.css';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import logoIcons from '../../assets/logo-icon.png';

const useStyles = makeStyles({
  loader: {
    position: 'relative',
    '& .MuiCircularProgress-root': {
      position: 'relative',
      margin: '100px auto',
      fontSize: '25px',
      width: '1em',
      height: '1em',
      borderRadius: '50%',
      textIndent: '-9999em',
      animation: 'load5 1.1s infinite ease',
      transform: 'translateZ(0)',
      // Moving this a little off the edge prevents horizontal scrollbar from flickering in and out
      top: 9,
      right: '50%',
      // textAlign: 'center',
    },
  },
  eventDialogDelete: {
    position: 'relative',
    '& .MuiCircularProgress-root': {
      position: 'absolute',
      // Moving this a little off the edge prevents horizontal scrollbar from flickering in and out
      top: 0,
      right: '50%',
      // textAlign: 'center',
    },
    '& .MuiFab-sizeMedium': {
      position: 'absolute',
      top: 0,
      right: '50%',
      // right: 9,
      marginRight: -25,
      marginTop: -15,
      textAlign: 'center',
    },
  },
});

const Loading = (props: CircularProgressProps & { displayIcon?: boolean }) => {
  const classes = useStyles();
  const { displayIcon } = props;
  if (displayIcon) {
    return <CircularProgress {...props} color="secondary" />;
  }
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <div className={classes.eventDialogDelete}>
        <Fab aria-label="delete" size="medium">
          <Avatar alt="iSenS" src={logoIcons} />
        </Fab>
        <div className="loaderAllCss" {...props}>
          <Fab aria-label="delete" size="small">
            <Avatar alt="iSenS" src={logoIcons} />
          </Fab>
        </div>
        {/* <CircularProgress {...props} color="secondary" size={52} /> */}
      </div>
    </div>
  );
};

export default Loading;
