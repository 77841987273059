import React, { useContext, useState } from 'react';
import { createAnalysisDataset } from '../../../../../../../api/analysis_dataset';
import ProjectContext from '../../../../../../Project/ProjectContext';

const useListCompliance = (props) => {
  const { service, handleNCorGapCompliance, list, setGapList, setNcList, setNotify } = props;
  const { project, setProject } = useContext(ProjectContext);

  const creatNCorGap = async (projectId, service, name, rationale) => {
    return createAnalysisDataset(projectId, [
      {
        project_id: projectId,
        datatype: service,
        name,
        rationale,
      },
    ]).then((response) => {
      return response;
    });
  };

  const handleChange = (e) => {
    const rationaleExtract = list.find((data) => {
      return data.name === e.target.value;
    });

    handleNCorGapCompliance({
      compliance: e.target.value,
      rationale: rationaleExtract.rationale,
      created_at: rationaleExtract.created_at,
      analysis_dataset_id: rationaleExtract.id,
    });
  };

  const handleNewNCcompliance = async (projectId, name, rationale) => {
    const response = await creatNCorGap(projectId, 'nc', name, rationale);
    setProject({ ...project, analysis_dataset: response });
    setNcList(response.filter((r) => r.datatype === 'nc'));
    setNotify('Successfully nc created', 'success');
  };

  const handleNewGapcompliance = async (projectId, name, rationale) => {
    const response = await creatNCorGap(projectId, 'gap', name, rationale);
    setProject({ ...project, analysis_dataset: response });
    setGapList(response.filter((r) => r.datatype === 'gap'));
    setNotify('Successfully gap created', 'success');
  };

  return { handleChange, handleNewNCcompliance, handleNewGapcompliance };
};

export default useListCompliance;
