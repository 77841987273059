import React, { useCallback, useRef, useState } from 'react';
import {
  ColumnChooser,
  TableColumnVisibility,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import { Plugin, Template } from '@devexpress/dx-react-core';
import { Box, Grid, makeStyles, Select, Typography } from '@material-ui/core';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import TableComponent from '../../../components/Datagrid/TableComponent';
import {
  rowComponent,
  toolbarComponent,
  toolbarSceduleComponent,
} from '../../../components/Datagrid/TStyles';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';

const useStyle = makeStyles((theme) => ({
  tollbarStyle: {
    minHeight: 0,
    backgroundColor: '#EEF1F9',
    borderRadius: '20px',
    // border: '1px solid black',
    // borderRight: '1px solid black',
    '& .MuiIconButton-label': {
      color: '#002A45 !important',
      border: '1px solid white',
      borderRadius: '100%',
      padding: 5,
      background: 'white',
    },
    '& .MuiIconButton-root': {
      padding: 5,
    },
    // '&.MuiToolbar-root': {
    //   justifyContent: 'flex-end !important',
    //   color: 'black',
    // },
  },
}));

const MilestoneTable = ({ rowData, tableName, loadMilestone }) => {
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [toggleHeight, setToggleHeight] = useState(false);
  const exporterRef = useRef(null);
  const initialRowsData = rowData;
  const [rowsData, setRowsData] = useState(initialRowsData);
  const [inputValue, setInputValue] = useState('');
  const onChange = (event) => {
    setInputValue(event.target.value);
  };
  const classes = useStyle();

  const colData = [
    { name: 'Name', title: 'Name' },
    { name: 'Description', title: 'Description' },
    { name: 'Categories', title: 'Categories' },
    { name: 'Duration', title: 'Duration' },
    { name: 'Planned start', title: 'Planned start' },
    { name: 'Planned finish', title: 'Planned finish' },
    { name: 'Start date -1', title: 'Start date' },
    { name: 'End date -1', title: 'End date' },
    { name: 'validity', title: 'validity' },
    { name: 'reason', title: 'reason' },
  ];

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };
  // const handleSelect = (event) => {
  //   const selectedValue = event.target.value;
  //   setInputValue(selectedValue);
  //   setRowsData([...rowsData.filter((item) => item.validity === event.target.value)]);
  // };
  // Inside the MilestoneTable component

  // ...

  // Update the handleSelect function
  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    setInputValue(selectedValue);

    // Reset rowsData to initial data if filter value is empty
    if (selectedValue === '') {
      setRowsData(initialRowsData);
    } else {
      // Filter the initial data based on selectedValue
      const filteredData = initialRowsData.filter((item) => item.validity === selectedValue);
      setRowsData(filteredData);
    }
  };
  // const toolbarComponent: React.FunctionComponent<Toolbar.RootProps> = (props) => {
  //   return <Toolbar.Root {...props} className={classes.tollbarStyle} />;
  // };

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="stretch"
      spacing={0}
      style={{ marginTop: '5px' }}
    >
      <Grid item lg={12} sm={6} md={12}>
        {rowsData?.length > 0 ? (
          <Box
            style={{
              backgroundColor: '#EEF1F9',
              border: '1px solid #002A45',
              borderRadius: '5px',
            }}
          >
            <ExpressGrid rows={rowsData} columns={colData}>
              <FilteringState />
              <IntegratedFiltering />
              <SortingState defaultSorting={[{ columnName: 'id', direction: 'desc' }]} />
              <IntegratedSorting />
              <PagingState defaultCurrentPage={0} defaultPageSize={10} />
              <IntegratedPaging />
              <Table
                tableComponent={TableComponent}
                columnExtensions={[
                  ...colData.map((c, i) => {
                    return {
                      columnName: c.name,
                      width: 'auto',
                      wordWrapEnabled: toggleHeight,
                    };
                  }),
                ]}
              />
              <TableColumnResizing
                defaultColumnWidths={[
                  { columnName: 'Name', width: 100 },
                  { columnName: 'Description', width: 150 },
                  { columnName: 'Categories', width: 100 },
                  { columnName: 'Duration', width: 100 },
                  { columnName: 'Planned start', width: 100 },
                  { columnName: 'Planned finish', width: 100 },
                  { columnName: 'Start date -1', width: 100 },
                  { columnName: 'End date -1', width: 100 },
                  { columnName: 'validity', width: 100 },
                  { columnName: 'reason', width: 100 },
                ]}
              />
              <TableHeaderRow showSortingControls />
              <TableFilterRow rowComponent={rowComponent} />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={setHiddenColumnNames}
              />
              <Toolbar rootComponent={toolbarSceduleComponent} />
              {/* {console.log('input', inputValue)} */}
              <Plugin name="MyName">
                <Template name="toolbarContent">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 5,
                      color: '#002A45',
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      Validity :
                    </div>
                    <Select
                      style={{ color: 'black' }}
                      defaultValue=""
                      native
                      onChange={handleSelect}
                      // style={{ float: 'right' }}
                    >
                      <option aria-label="None" value="" disabled />
                      <option value="Valid">Valid</option>
                      <option value="Invalid">Invalid</option>
                    </Select>
                  </div>
                </Template>
              </Plugin>
              <ColumnChooser />
              <ToolBarPlugin name="Download" onClick={startExport} />
              <ToolBarPlugin
                name="Height"
                title="Wrap Text"
                onClick={() => {
                  setToggleHeight(!toggleHeight);
                }}
              />
              <PagingPanel pageSizes={pageSizes} />
            </ExpressGrid>
            <GridExporter ref={exporterRef} rows={rowsData} columns={colData} onSave={onSave} />
          </Box>
        ) : (
          !loadMilestone && (
            <Typography variant="subtitle1">File does not have {tableName} milestone</Typography>
          )
        )}
      </Grid>
    </Grid>
  );
};

export default MilestoneTable;
