import { useState, useEffect } from 'react';
import { Project } from '../react-app-env';

export default function useProjectId(
  projectId: number,
  projects: any[]
): { project: Project | undefined; setProject: any } {
  const [project, setProject] = useState<Project>();
  useEffect(() => {
    const p = projects.find((p) => Number(p.id) === Number(projectId)) as Project;
    // console.log(p);
    setProject(p);
  }, [projectId, projects]);

  return { project, setProject };
}
