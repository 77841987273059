import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormGroup } from '@mui/material';
import extractOriginalLang from '../utils/extractOriginalLang';

export default function ModalEdit({
  requirement,
  open,
  onConfirm,
  handleClose,
  targetColumn,
  translationCode,
  i18n,
  langCheck,
  languagesCode,
}) {
  const extractValue = React.useMemo(() => {
    return {
      Text: requirement.extractedText,
      comment: '',
      Source: '',
      Rationale: '',
      Validation_Evidence: '',
    };
  }, [requirement]);

  const [formValues, setFormValues] = React.useState(extractValue);

  const handleSubmit = () => {
    try {
      const { OriginalLang } = extractOriginalLang(i18n, langCheck, languagesCode);

      let result = {
        [requirement.row_id]: {
          ...requirement,
        },
      };

      const key = translationCode === '-' ? OriginalLang : translationCode;

      // if (targetColumn) {
      //   result = {
      //     [requirement.row_id]: {
      //       ...result[requirement.row_id],
      //       [targetColumn]: formValues.Text,
      //       i18n: {
      //         ...result[requirement.row_id].i18n,
      //         [key]: formValues.Text,
      //       },
      //     },
      //   };
      // } else {

      // }

      result = {
        [requirement.row_id]: {
          ...result[requirement.row_id],
          extractedText: formValues.Text,
          i18n: {
            ...result[requirement.row_id].i18n,
            [key]: formValues.Text,
          },
        },
      };

      result = {
        [requirement.row_id]: {
          ...result[requirement.row_id],
          comment: formValues.comment,
          Source: formValues.Source,
          Rationale: formValues.Rationale,
          Validation_Evidence: formValues.Validation_Evidence,
        },
      };

      // console.log(result);
      onConfirm(result);
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (e) => {
    const currentValue = {
      ...formValues,
    };

    const key = targetColumn ?? 'Text';

    switch (e.target.name) {
      case key:
        currentValue.Text = e.target.value;
        break;
      case 'comment':
        currentValue.comment = e.target.value;
        break;

      case 'Source':
        currentValue.Source = e.target.value;
        break;

      case 'Rationale':
        currentValue.Rationale = e.target.value;
        break;

      case 'Validation_Evidence':
        currentValue.Validation_Evidence = e.target.value;
        break;

      default:
        break;
    }
    setFormValues(currentValue);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
        <DialogContent>
          <FormGroup>
            {targetColumn !== undefined ? (
              <TextField
                multiline
                rows={4}
                margin="normal"
                style={{ textAlign: 'justify' }}
                name={targetColumn}
                label={targetColumn}
                value={formValues.Text || ''}
                onChange={onChange}
              />
            ) : (
              <TextField
                multiline
                rows={4}
                margin="normal"
                style={{ textAlign: 'justify' }}
                name="Text"
                label="Text"
                value={formValues.Text || ''}
                onChange={onChange}
              />
            )}

            <>
              <TextField
                multiline
                rows={4}
                margin="normal"
                style={{ textAlign: 'justify' }}
                name="comment"
                label="Comment"
                value={formValues.comment || ''}
                onChange={onChange}
              />
              <TextField
                multiline
                rows={4}
                margin="normal"
                style={{ textAlign: 'justify' }}
                name="Source"
                label="Source"
                value={formValues.Source || ''}
                onChange={onChange}
              />
              <TextField
                multiline
                rows={4}
                margin="normal"
                style={{ textAlign: 'justify' }}
                name="Rationale"
                label="Rationale"
                value={formValues.Rationale || ''}
                onChange={onChange}
              />
              <TextField
                multiline
                rows={4}
                margin="normal"
                style={{ textAlign: 'justify' }}
                name="Validation_Evidence"
                label="Validation Evidence"
                value={formValues.Validation_Evidence || ''}
                onChange={onChange}
              />
            </>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="info">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
