import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Chip, Grid, makeStyles, Tooltip } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import StarIcon from '@material-ui/icons/Star';
import TimelineIcon from '@material-ui/icons/Timeline';
import { AllocationData, LifeCycleData, Owning } from 'OwningType';
import React, { useContext } from 'react';
import { LIFECYCLE_COLORS, LIFECYCLE_LABELS } from '../../../../../const';
import formatDate, { formatLocal } from '../../../../../tools/formatDate';
import ProjectContext from '../../../../Project/ProjectContext';

export const statusLifecyle = {
  assign: 'assigned',
  reject: 'rejected',
  accept: 'owned',
  analyze: 'analyzed',
  capture: 'captured',
} as any;

export const lifecycleFilter = (
  lifecycles: LifeCycleData[],
  allocation: string | undefined = undefined,
  user_id: number | undefined = undefined
) => {
  // all ownings
  let lc1 =
    lifecycles.filter((lc) =>
      ['capture', 'assign', 'reject', 'accept', 'analyze'].includes(lc.action)
    ) || [];
  if (allocation) {
    lc1 = lc1.filter((lc) => lc.allocation === allocation);
  }
  // lifecycle for owner only
  if (user_id) {
    lc1 = lc1.filter(
      (lc) => Number(lc.content) === user_id || (lc.user_id === user_id && lc.action !== 'assign')
    );
  }
  // for clarification all people no filters
  const lc2 = lifecycles.filter((lc) => ['ask', 'reply'].includes(lc.action)) || [];

  return [lc1, lc2];
};

export const useStyles = makeStyles((theme) => ({
  root: {},
  customWidth: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '1rem',
  },
  latestAction: {
    backgroundColor: theme.palette.success.dark,
    border: '1px solid white',
  },
  clarifyIcon: {
    cursor: 'pointer',
  },
  kpiChip: {
    color: 'white',
    width: '100px !important',
    '&.MuiListItem-gutters': {
      width: '200px',
    },
  },
  listItemGutter: {
    // '&.MuiListItem-gutters': {
    //   width: 200,
    // },
  },
  kpiStar: {
    verticalAlign: 'bottom',
    fontSize: 8,
  },
}));

export function LifecyleCellMulti({ value, row }: { value: any; row: Owning }) {
  const classes = useStyles();
  const { userJWT } = useContext(ProjectContext);
  if (!row.Allocation) return null;

  const allocationLabels =
    row.allocations?.map((allocation: AllocationData) => allocation.allocation) || [];
  return (
    <List dense={true}>
      {allocationLabels.map((label, index) => {
        let bgColor = LIFECYCLE_COLORS.characterized;
        let lifecycle = LIFECYCLE_LABELS.characterized;
        const { kpi_multiallocations, lifecycles } = row;
        if (row.Characterization === 'Requirement') {
          if (!kpi_multiallocations || kpi_multiallocations?.length === 0) {
            bgColor = LIFECYCLE_COLORS.characterized;
            lifecycle = LIFECYCLE_LABELS.characterized;
            return (
              <ListItem key={`lifecycle-kpi-${index}`}>
                <Chip
                  label={lifecycle}
                  title={lifecycle}
                  className={classes.kpiChip}
                  style={{ backgroundColor: bgColor }}
                />{' '}
              </ListItem>
            );
          }
        }
        const allocation = kpi_multiallocations?.find((allo) => allo.allocation === label);
        if (allocation && kpi_multiallocations && kpi_multiallocations?.length > 0) {
          switch (allocation?.lifecycle) {
            // form assignment
            case 'assigned':
              bgColor = LIFECYCLE_COLORS.assigned;
              lifecycle = LIFECYCLE_LABELS.assigned;
              break;
            case 'owned':
              bgColor = LIFECYCLE_COLORS.owned;
              lifecycle = LIFECYCLE_LABELS.owned;
              break;
            case 'rejected':
              bgColor = LIFECYCLE_COLORS.rejected;
              lifecycle = LIFECYCLE_LABELS.rejected;
              break;

            // from compliance
            case 'analyzed':
              bgColor = LIFECYCLE_COLORS.analyzed;
              lifecycle = LIFECYCLE_LABELS.analyzed;
              break;
            case 'recaptured':
              bgColor = LIFECYCLE_COLORS.recaptured;
              lifecycle = LIFECYCLE_LABELS.recaptured;
              break;
            case 'need-clarification':
              bgColor = LIFECYCLE_COLORS.clarification;
              lifecycle = LIFECYCLE_LABELS.clarification;
              break;
            default:
              bgColor = LIFECYCLE_COLORS.characterized;
              lifecycle = LIFECYCLE_LABELS.characterized;
              break;
          }

          let lifecycleStar = null;

          if (Number(allocation?.user_id) === Number(userJWT.user_id)) {
            lifecycleStar = (
              <Grid container direction="row" alignItems="center">
                {lifecycle}
                <StarIcon fontSize="small" />
              </Grid>
            );
          }
          return (
            <ListItem key={`lifecycle-kpi-${index}`}>
              <Chip
                label={lifecycleStar ?? lifecycle}
                title={lifecycle}
                className={classes.kpiChip}
                style={{ backgroundColor: bgColor }}
              />
              <Tooltip
                title={lifecycles?.map((val: LifeCycleData, index: number) => (
                  <p
                    key={`lc-hist-${index}`}
                    className={(index === 0 && classes.latestAction) || ''}
                  >
                    {formatDate(val.created_at, formatLocal())} :{' '}
                    <b>{statusLifecyle[val.action]?.toUpperCase()}</b>{' '}
                    {val.action === 'rejected' && (
                      <>
                        <br />
                        {val.content}
                      </>
                    )}
                  </p>
                ))}
                classes={{ tooltip: classes.customWidth }}
              >
                <TimelineIcon />
              </Tooltip>
            </ListItem>
          );
        }
        return (
          <ListItem key={`lifecycle-kpi-${index}`}>
            <Chip
              label={lifecycle}
              title={lifecycle}
              className={classes.kpiChip}
              style={{ backgroundColor: bgColor }}
            />{' '}
          </ListItem>
        );
      })}
    </List>
  );
}

export function LifecyleCellMulti_2({ row: { row, allocation, lifecyclesIs } }: { row: any }) {
  const classes = useStyles();
  const { userJWT } = useContext(ProjectContext);

  let bgColor = LIFECYCLE_COLORS.characterized;
  let lifecycle = LIFECYCLE_LABELS.characterized;

  switch (allocation?.lifecycle) {
    // form assignment
    case 'assigned':
      bgColor = LIFECYCLE_COLORS.assigned;
      lifecycle = LIFECYCLE_LABELS.assigned;
      break;
    case 'owned':
      bgColor = LIFECYCLE_COLORS.owned;
      lifecycle = LIFECYCLE_LABELS.owned;
      break;
    case 'rejected':
      bgColor = LIFECYCLE_COLORS.rejected;
      lifecycle = LIFECYCLE_LABELS.rejected;
      break;

    // from compliance
    case 'analyzed':
      bgColor = LIFECYCLE_COLORS.analyzed;
      lifecycle = LIFECYCLE_LABELS.analyzed;
      break;
    case 'recaptured':
      bgColor = LIFECYCLE_COLORS.recaptured;
      lifecycle = LIFECYCLE_LABELS.recaptured;
      break;
    case 'need-clarification':
      bgColor = LIFECYCLE_COLORS.clarification;
      lifecycle = LIFECYCLE_LABELS.clarification;
      break;
    default:
      bgColor = LIFECYCLE_COLORS.characterized;
      lifecycle = LIFECYCLE_LABELS.characterized;
      break;
  }

  let lifecycleStar = null;

  if (Number(allocation?.receiver) === Number(userJWT.user_id)) {
    lifecycleStar = (
      <Grid container direction="row" alignItems="center" justify="center">
        <div style={{ display: 'flex' }}>
          {lifecycle}
          <StarIcon fontSize="small" />
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Chip
        label={lifecycleStar ?? lifecycle}
        title={lifecycle}
        className={classes.kpiChip}
        style={{ backgroundColor: bgColor }}
      />
      {/* {allocation?.id} */}
      <Tooltip
        title={lifecyclesIs?.map((val: LifeCycleData, index: number) =>
          allocation?.id === val?.allocation_id ? (
            <p key={`lc-hist-${index}`} className={(index === 0 && classes.latestAction) || ''}>
              {/* Id: {val?.allocation_id}<br />  */}
              {formatDate(val.created_at, formatLocal())} :{' '}
              <b>{statusLifecyle[val.action]?.toUpperCase()}</b>{' '}
              {val.action === 'reject' && (
                <>
                  <br />
                  {val.content}
                </>
              )}
            </p>
          ) : (
            ''
          )
        )}
        classes={{ tooltip: classes.customWidth }}
      >
        <TimelineIcon />
      </Tooltip>
    </>
  );
}

export function LifecyleCellKpi({ value, row }: DataTypeProvider.ValueFormatterProps) {
  const classes = useStyles();
  let bgColor = null;
  let lifecycle = null;
  switch (value) {
    // form assignment
    case 'assigned':
      bgColor = LIFECYCLE_COLORS.assigned;
      lifecycle = LIFECYCLE_LABELS.assigned;
      break;
    case 'owned':
      bgColor = LIFECYCLE_COLORS.owned;
      lifecycle = LIFECYCLE_LABELS.owned;
      break;
    case 'rejected':
      bgColor = LIFECYCLE_COLORS.rejected;
      lifecycle = LIFECYCLE_LABELS.rejected;
      break;

    // from compliance
    case 'analyzed':
      bgColor = LIFECYCLE_COLORS.analyzed;
      lifecycle = LIFECYCLE_LABELS.analyzed;
      break;
    case 'recaptured':
      bgColor = LIFECYCLE_COLORS.recaptured;
      lifecycle = LIFECYCLE_LABELS.recaptured;
      break;
    case 'need-clarification':
      bgColor = LIFECYCLE_COLORS.clarification;
      lifecycle = LIFECYCLE_LABELS.clarification;
      break;
    default:
      bgColor = LIFECYCLE_COLORS.characterized;
      lifecycle = LIFECYCLE_LABELS.characterized;
      break;
  }
  return (
    <ListItem className={classes.listItemGutter} key={`lifecycle-kpi-${row.id}`}>
      <Chip
        label={lifecycle}
        title={lifecycle}
        className={classes.kpiChip}
        style={{
          backgroundColor: bgColor,
          marginLeft: -15,
          marginRight: -25,
        }}
      />
    </ListItem>
  );
}

export default LifecyleCellKpi;
