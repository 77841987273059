/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import { Grid, Paper, Typography, Card, CardContent } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  Cell,
  Pie,
  PieChart,
} from 'recharts';
// import ReactWordcloud from 'react-wordcloud';
import { TagCloud } from 'react-tagcloud';
// import Module1Context from '../../../Module1Context';
import Module1Context from '../../../../Project/ProjectContext';

import { isensTheme } from '../../../../../app/isensTheme';
import { CustomWidthTooltip } from '../../../../../components/Core/CustomWidthTooltip';

// import inerjson from '../../../fixtures/iner.json';

const COLORS = ['#002884', '#9A2318', '#9A2318'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltipStyle: {
      textAlign: 'center',
      backgroundColor: 'white',
      fontWeight: 'bold',
      padding: 5,
      marginTop: 5,
    },
  })
);

function INer(val: any) {
  // console.log(val.data);
  const [dataHere, setDataHere] = useState<any>(JSON.parse(val.data));
  // const [dataHere, setDataHere] = useState<any>(inerjson); // hardcoded fixture data
  const [systemData, setSystemData] = useState<any>([]);
  const [acronymData, setAcronymData] = useState<any>([]);
  const [activityData, setActivityData] = useState<any>([]);
  const [standardsData, setStandardsData] = useState<any>([]);
  const [cyberData, setCyberData] = useState<any>([]);
  const [projectData, setProjectData] = useState<any>([]);
  const [commercialData, setCommercialData] = useState<any>([]);
  const [financeData, setFinanceData] = useState<any>([]);
  const [contractualData, setContractualData] = useState<any>([]);
  const [digitalData, setDigitalData] = useState<any>([]);
  const [dataGraph, setDataGraph] = useState<any>([]);
  // for bar graph
  const [barGraphSystemEntities, setBarGraphSystemEntities] = useState<any>([]);
  const [barGraphSystemAcronym, setBarGraphSystemAcronym] = useState<any>([]);
  const [barGraphActivites, setBarGraphActivities] = useState<any>([]);
  const [barGraphStandards, setBarGraphStandards] = useState<any>([]);
  const [barGraphCyber, setBarGraphCyber] = useState<any>([]);
  const [barGraphProject, setBarGraphProject] = useState<any>([]);
  const [barGraphCommercial, setBarGraphCommercial] = useState<any>([]);
  const [barGraphFinance, setBarGraphFinance] = useState<any>([]);
  const [barGraphContractual, setBarGraphContractual] = useState<any>([]);
  const [barGraphDigital, setBarGraphDigital] = useState<any>([]);

  const [systemCloudData, setSystemCloudData] = useState<any>([]);
  const [acronymCloudData, setAcronymCloudData] = useState<any>([]);
  const [activityCloudData, setActivityCloudData] = useState<any>([]);
  const [standardsCloudData, setStandardsCloudData] = useState<any>([]);
  const [cyberCloudData, setCyberCloudData] = useState<any>([]);
  const [projectCloudData, setProjectCloudData] = useState<any>([]);
  const [commercialCloudData, setCommercialCloudData] = useState<any>([]);
  const [financeCloudData, setFinanceCloudData] = useState<any>([]);
  const [contractualCloudData, setContractualCloudData] = useState<any>([]);
  const [digitalCloudData, setDigitalCloudData] = useState<any>([]);

  const { setSnack } = useContext(Module1Context);

  const classes = useStyles();
  useEffect(() => {
    // console.log('dataHere: ', dataHere);
    const systemArr: any[] = [];
    const acronymArr: any[] = [];
    const activityArr: any[] = [];
    const standardsArr: any[] = [];
    const cyberArr: any[] = [];
    const projectArr: any[] = [];
    const commercialArr: any[] = [];
    const financeArr: any[] = [];
    const contractualArr: any[] = [];
    const digitalArr: any[] = [];

    const systemCloudArr: any[] = [];
    const acronymCloudArr: any[] = [];
    const activityCloudArr: any[] = [];
    const standardsCloudArr: any[] = [];
    const cyberCloudArr: any[] = [];
    const projectCloudArr: any[] = [];
    const commercialCloudArr: any[] = [];
    const financeCloudArr: any[] = [];
    const contractualCloudArr: any[] = [];
    const digitalCloudArr: any[] = [];

    let systemDataTotal: Number = 0;
    let acronymDataTotal: Number = 0;
    let activityDataTotal: Number = 0;
    let standardsDataTotal: Number = 0;
    let cyberDataTotal: Number = 0;
    let projectDataTotal: Number = 0;
    let commercialDataTotal: Number = 0;
    let financeDataTotal: Number = 0;
    let contractualDataTotal: Number = 0;
    let digitalDataTotal: Number = 0;

    const initialValues = dataHere.entitiesFreq
      .sort((a: any, b: any) => {
        return a.count - b.count;
      })
      .reverse();
    // console.log(initialValues);
    // eslint-disable-next-line array-callback-return
    initialValues.map((d: { entType: string }, i: string | number) => {
      // system
      if (d.entType === 'System') {
        systemArr.push(initialValues[i]);
        systemCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        systemDataTotal += initialValues[i].count;
      }
      // Acronym
      if (d.entType === 'Acronym') {
        // console.log(d.entType);
        acronymArr.push(initialValues[i]);
        acronymCloudArr.push({
          // eslint-disable-next-line no-useless-concat
          value: `${initialValues[i].entAcronym  } `+` `, // entValue
          count: initialValues[i].count,
        });
        acronymDataTotal += initialValues[i].count;
      }
      // ABS_ent
      if (d.entType === 'ABS_ent') {
        // console.log(d.entType);
        activityArr.push(initialValues[i]);
        activityCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        activityDataTotal += initialValues[i].count;
      }
      // Standards
      if (d.entType === 'Standards') {
        // console.log(d.entType);
        standardsArr.push(initialValues[i]);
        standardsCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        standardsDataTotal += initialValues[i].count;
      }
      // cyber security
      if (d.entType === 'cybersecurity') {
        // console.log(d.entType);
        cyberArr.push(initialValues[i]);
        cyberCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        cyberDataTotal += initialValues[i].count;
      }
      // project management
      // cyber security
      if (d.entType === 'project management') {
        // console.log(d.entType);
        projectArr.push(initialValues[i]);
        projectCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        projectDataTotal += initialValues[i].count;
      }

      // commercial
      if (d.entType === 'Commercial') {
        // console.log(d.entType);
        commercialArr.push(initialValues[i]);
        commercialCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        commercialDataTotal += initialValues[i].count;
      }

      // finance
      if (d.entType === 'Finance') {
        // console.log(d.entType);
        financeArr.push(initialValues[i]);
        financeCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        financeDataTotal += initialValues[i].count;
      }

      // Contractual
      if (d.entType === 'Contractual') {
        // console.log(d.entType);
        contractualArr.push(initialValues[i]);
        contractualCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        contractualDataTotal += initialValues[i].count;
      }

      // Digital
      if (d.entType === 'Digital') {
        digitalArr.push(initialValues[i]);
        digitalCloudArr.push({
          value: initialValues[i].entValue,
          count: initialValues[i].count,
        });
        digitalDataTotal += initialValues[i].count;
      }
    });

    setSystemData(systemArr);
    setAcronymData(acronymArr);
    setActivityData(activityArr);
    setStandardsData(standardsArr);
    setCyberData(cyberArr);
    setProjectData(projectArr);
    setCommercialData(commercialArr);
    setFinanceData(financeArr);
    setContractualData(contractualArr);
    setDigitalData(digitalArr);

    setBarGraphSystemEntities(systemArr);

    setBarGraphSystemAcronym(
      acronymArr.sort((c: any, d: any) => {
        return c.value - d.value;
      })
    );
    setBarGraphActivities(activityArr);
    // console.log('activityDataTotal', activityDataTotal);
    // console.log('activtyArr', activityArr);

    setBarGraphStandards(standardsArr);
    setBarGraphCyber(cyberArr);
    setBarGraphProject(projectArr);
    setBarGraphCommercial(commercialArr);
    setBarGraphFinance(financeArr);
    setBarGraphContractual(contractualArr);
    setBarGraphDigital(digitalArr);

    setSystemCloudData(systemCloudArr);
    setAcronymCloudData(acronymCloudArr);
    setActivityCloudData(activityCloudArr);
    setStandardsCloudData(standardsCloudArr);
    setCyberCloudData(cyberCloudArr);
    setProjectCloudData(projectCloudArr);
    setCommercialCloudData(commercialCloudArr);
    setFinanceCloudData(financeCloudArr);
    setContractualCloudData(contractualCloudArr);
    setDigitalCloudData(digitalCloudArr);

    const data = [
      { name: 'System Entities', count: systemDataTotal },
      { name: 'Acronyms', count: acronymDataTotal },
      { name: 'Activities', count: activityDataTotal },
    ];
    setDataGraph(data);
  }, []);
  const customRenderer = (tag, size, color) => {
    return (
      <CustomWidthTooltip
        placement="bottom-start"
        arrow
        // className={classes.tooltipStyle}
        // open={true}
        title={
          <>
            <Card
              style={{
                background: '#DC3223 0% 0% no-repeat padding-box',
                color: 'white',
                fontWeight: 'bold',
                margin: 0,
                padding: 5,
              }}
            >
              {`'${tag.value}',  count: '${tag.count}'`}
            </Card>
          </>
        }
      >
        <span
          key={tag.value}
          style={{
            color,
          }}
          // className={`tag-${size}`}
          className={classes.tooltipStyle}
        >
          {tag.value}
        </span>
      </CustomWidthTooltip>
    );
  };

  return (
    <ThemeProvider theme={isensTheme}>
      <div style={{ marginTop: '12px' }}>
        <Typography variant="h6">Extract Entities Details</Typography>
        <Card
          style={{
            backgroundColor: '#EEF1F9',
            borderRadius: 5,
            border: '1px solid #002A45',
            overflowY: 'scroll',
            maxHeight: '70vh',
          }}
        >
          <CardContent>
            <Typography variant="h6" display="block">
              {systemData.length > 0 && (
                <span>
                  System Entities
                  {/* <hr /> */}
                </span>
              )}
            </Typography>
            {
              // eslint-disable-next-line array-callback-return
              systemData.length > 0 &&
                systemData.map((item: any) => (
                  <span>
                    <Chip
                      avatar={<Avatar>{item.count}</Avatar>}
                      label={item.entValue}
                      color="primary"
                      style={{ height: '30px', marginTop: '20px' }}
                      variant="outlined"
                      title={item.details}
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </span>
                ))
            }
            <Typography variant="h6" display="block">
              {activityData.length > 0 && (
                <span>
                  <hr />
                  Activities
                </span>
              )}
            </Typography>
            {
              // eslint-disable-next-line array-callback-return
              activityData.length > 0 &&
                activityData.map((item: any) => (
                  <span>
                    <Chip
                      avatar={<Avatar>{item.count}</Avatar>}
                      label={item.entValue}
                      color="secondary"
                      style={{ height: '30px', marginTop: '20px' }}
                      variant="outlined"
                      title={item.details}
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </span>
                ))
            }
            <Typography variant="h6" display="block">
              {acronymData.length > 0 && (
                <span>
                  <hr />
                  Acronyms
                </span>
              )}
            </Typography>
            {
              // eslint-disable-next-line array-callback-return
              acronymData.length > 0 &&
                acronymData.map((item: any) => (
                  <span>
                    <Chip
                      avatar={
                        <Avatar
                          style={{
                            color: 'white',
                            backgroundColor: `${isensTheme.palette.green.light}`,
                          }}
                        >
                          {item.count}
                        </Avatar>
                      }
                      label={item.entAcronym} // entValue
                      color={isensTheme.palette.green.light}
                      style={{
                        height: '30px',
                        marginTop: '20px',
                        backgroundColor: 'white',
                        borderColor: `${isensTheme.palette.green.light}`,
                      }}
                      variant="outlined"
                      title={item.details}
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </span>
                ))
            }
            <Typography variant="h6" display="block">
              {standardsData.length > 0 && (
                <span>
                  <hr />
                  Standards
                </span>
              )}
            </Typography>
            {
              // eslint-disable-next-line array-callback-return
              standardsData.length > 0 &&
                standardsData.map((item: any) => (
                  <span>
                    <Chip
                      avatar={
                        <Avatar
                          style={{
                            color: 'white',
                            backgroundColor: `${isensTheme.palette.primary.light}`,
                          }}
                        >
                          {item.count}
                        </Avatar>
                      }
                      label={item.entValue} // entValue
                      color={isensTheme.palette.primary.light}
                      style={{
                        height: '30px',
                        marginTop: '20px',
                        backgroundColor: 'white',
                        borderColor: `${isensTheme.palette.primary.light}`,
                      }}
                      variant="outlined"
                      title={item.details}
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </span>
                ))
            }
            <Typography variant="h6" display="block">
              {cyberData.length > 0 && (
                <span>
                  <hr />
                  Cyber Security
                </span>
              )}
            </Typography>
            {
              // eslint-disable-next-line array-callback-return
              cyberData.length > 0 &&
                cyberData.map((item: any) => (
                  <span>
                    <Chip
                      avatar={
                        <Avatar
                          style={{
                            color: 'white',
                            backgroundColor: `${isensTheme.palette.secondary.light}`,
                          }}
                        >
                          {item.count}
                        </Avatar>
                      }
                      label={item.entValue} // entValue
                      color={isensTheme.palette.secondary.light}
                      style={{
                        height: '30px',
                        marginTop: '20px',
                        backgroundColor: 'white',
                        borderColor: `${isensTheme.palette.secondary.light}`,
                      }}
                      variant="outlined"
                      title={item.details}
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </span>
                ))
            }

            <Typography variant="h6" display="block">
              {projectData.length > 0 && (
                <span>
                  <hr />
                  Project Management
                </span>
              )}
            </Typography>
            {
              // eslint-disable-next-line array-callback-return
              projectData.length > 0 &&
                projectData.map((item: any) => (
                  <span>
                    <Chip
                      avatar={<Avatar>{item.count}</Avatar>}
                      label={item.entValue}
                      color="primary"
                      style={{ height: '30px', marginTop: '20px' }}
                      variant="outlined"
                      title={item.details}
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </span>
                ))
            }

            <Typography variant="h6" display="block">
              {commercialData.length > 0 && (
                <span>
                  <hr />
                  Commercial
                </span>
              )}
            </Typography>
            {
              // eslint-disable-next-line array-callback-return
              commercialData.length > 0 &&
                commercialData.map((item: any) => (
                  <span>
                    <Chip
                      avatar={<Avatar>{item.count}</Avatar>}
                      label={item.entValue}
                      color="secondary"
                      style={{ height: '30px', marginTop: '20px' }}
                      variant="outlined"
                      title={item.details}
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </span>
                ))
            }

            <Typography variant="h6" display="block">
              {financeData.length > 0 && (
                <span>
                  <hr />
                  Finance
                </span>
              )}
            </Typography>
            {
              // eslint-disable-next-line array-callback-return
              financeData.length > 0 &&
                financeData.map((item: any) => (
                  <span>
                    <Chip
                      avatar={
                        <Avatar
                          style={{
                            color: 'white',
                            backgroundColor: `${isensTheme.palette.green.light}`,
                          }}
                        >
                          {item.count}
                        </Avatar>
                      }
                      label={item.entValue}
                      color="primary"
                      style={{ height: '30px', marginTop: '20px' }}
                      variant="outlined"
                      title={item.details}
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </span>
                ))
            }

            <Typography variant="h6" display="block">
              {contractualData.length > 0 && (
                <span>
                  <hr />
                  Contractual
                </span>
              )}
            </Typography>
            {
              // eslint-disable-next-line array-callback-return
              contractualData.length > 0 &&
                contractualData.map((item: any) => (
                  <span>
                    <Chip
                      avatar={<Avatar>{item.count}</Avatar>}
                      label={item.entValue}
                      color="primary"
                      style={{ height: '30px', marginTop: '20px' }}
                      variant="outlined"
                      title={item.details}
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </span>
                ))
            }

            <Typography variant="h6" display="block">
              {digitalData.length > 0 && (
                <span>
                  <hr />
                  Digital
                </span>
              )}
            </Typography>
            {
              // eslint-disable-next-line array-callback-return
              digitalData.length > 0 &&
                digitalData.map((item: any) => (
                  <span>
                    <Chip
                      avatar={<Avatar>{item.count}</Avatar>}
                      label={item.entValue}
                      color="secondary"
                      style={{ height: '30px', marginTop: '20px' }}
                      variant="outlined"
                      title={item.details}
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </span>
                ))
            }
          </CardContent>
        </Card>
        <br />
        <div>
          <Paper>
            <Grid container>
              {/* <Grid item xs={10}>
                <PieChart width={800} height={400}>
                  <Pie
                    isAnimationActive={true}
                    data={dataGraph}
                    cx={300}
                    cy={200}
                    outerRadius={150}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    fill="white"
                    dataKey="count"
                  >
                    {dataGraph.map((entry: any, index: any) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </Grid> */}
              {/* <Grid item xs={2}>
                <br />
                <br />
                <table>
                  <tr>
                    <td>
                      <b>System Entities</b>
                    </td>
                    <td>
                      <svg width="20" height="20">
                        <rect
                          style={{
                            width: '20px',
                            height: '20px',
                            fill: `${isensTheme.palette.primary.main}`,
                          }}
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Activities</b>
                    </td>
                    <td>
                      <svg width="20" height="20">
                        <rect
                          style={{
                            width: '20px',
                            height: '20px',
                            fill: `${isensTheme.palette.primary.light}`,
                          }}
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>System Acronym</b>
                    </td>
                    <td>
                      <svg width="20" height="20">
                        <rect
                          style={{
                            width: '20px',
                            height: '20px',
                            fill: `${isensTheme.palette.secondary.light}`,
                          }}
                        />
                      </svg>
                    </td>
                  </tr>
                </table>
              </Grid> */}
            </Grid>
          </Paper>
        </div>
        <div>
          {/* <Paper>
            <Grid container spacing={1}>
              {barGraphSystemEntities.length > 0 && (
                <>
                  <Grid item xs={4}>
                    <center>
                      <Typography variant="h6" display="block" style={{ marginLeft: '0px' }}>
                        <span>System Entites</span>
                      </Typography>
                    </center>
                    <BarChart
                      width={350}
                      style={{ maxWidth: 350 }}
                      height={barGraphSystemEntities.length * 30}
                      data={barGraphSystemEntities.sort((a: any, b: any) => {
                        return a.value - b.value;
                      })}
                      layout="vertical"
                      margin={{ left: 50, top: 10 }}
                    >
                      <XAxis type="number" />
                      <YAxis
                        type="category"
                        dataKey="entValue"
                        fontSize={10}
                        textAnchor="end"
                        sclaeToFit="true"
                        verticalAnchor="start"
                        interval={0}
                      />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      
                      <Bar dataKey="count" fill="#002884" barSize={20} />
                    </BarChart>
                  </Grid>
                </>
              )}
              {barGraphActivites.length > 0 && (
                <>
                  <Grid item xs={4}>
                    <center>
                      <Typography variant="h6" display="block" style={{ marginLeft: '0px' }}>
                        <span>Activities</span>
                      </Typography>
                    </center>

                    <BarChart
                      width={350}
                      style={{ maxWidth: 350 }}
                      height={barGraphActivites.length * 70}
                      data={barGraphActivites.sort((a: any, b: any) => {
                        return a.value - b.value;
                      })}
                      layout="vertical"
                      margin={{ left: 50, top: 10 }}
                    >
                      <XAxis type="number" />
                      <YAxis
                        type="category"
                        dataKey="entValue"
                        fontSize={10}
                        textAnchor="end"
                        sclaeToFit="true"
                        verticalAnchor="start"
                        interval={0}
                      />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                     
                      <Bar dataKey="count" fill={isensTheme.palette.secondary.light} barSize={20} />
                    </BarChart>
                  </Grid>
                </>
              )}
              {barGraphSystemAcronym.length > 0 && (
                <>
                  <Grid item xs={4}>
                    <center>
                      <Typography variant="h6" display="block" style={{ marginLeft: '0px' }}>
                        <span>Acronyms</span>
                      </Typography>
                    </center>

                    <BarChart
                      width={340}
                      style={{ maxWidth: 340 }}
                      height={barGraphSystemAcronym.length * 40}
                      data={barGraphSystemAcronym.sort((a: any, b: any) => {
                        return a.value - b.value;
                      })}
                      layout="vertical"
                      margin={{ left: 50, top: 10 }}
                    >
                      <XAxis type="number" />
                      <YAxis
                        type="category"
                        dataKey="entValue"
                        fontSize={10}
                        textAnchor="end"
                        sclaeToFit="true"
                        verticalAnchor="start"
                        interval={0}
                      />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                     
                      <Bar dataKey="count" fill={isensTheme.palette.green.light} barSize={20} />
                    </BarChart>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper> */}
          <Paper>
            {barGraphSystemEntities.length > 0 && (
              <Grid>
                <center>
                  <Typography variant="h6" display="block" style={{ marginLeft: '0px' }}>
                    <span>System Entites</span>
                  </Typography>
                </center>
                <Grid item xs={12} style={{ overflow: 'scroll' }}>
                  <BarChart
                    width={barGraphSystemEntities.length * 180}
                    height={200}
                    data={barGraphSystemEntities}
                    barSize={20}
                  >
                    <CartesianGrid strokeDasharray="0 0" />
                    <XAxis dataKey="entValue" fontSize={10} textAnchor="middle" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill="#002884" />
                  </BarChart>
                </Grid>
              </Grid>
            )}
            {barGraphActivites.length > 0 && (
              <Grid>
                <center>
                  <Typography variant="h6" display="block" style={{ marginLeft: '0px' }}>
                    <span>Activities</span>
                  </Typography>
                </center>
                <Grid item xs={12} style={{ overflow: 'scroll' }}>
                  <BarChart
                    width={barGraphActivites.length * 180}
                    height={200}
                    data={barGraphActivites}
                    barSize={20}
                  >
                    <CartesianGrid strokeDasharray="0 0" />
                    <XAxis dataKey="entValue" fontSize={10} textAnchor="middle" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill={isensTheme.palette.secondary.light} />
                  </BarChart>
                </Grid>
              </Grid>
            )}
            {barGraphSystemAcronym.length > 0 && (
              <Grid>
                <center>
                  <Typography variant="h6" display="block" style={{ marginLeft: '0px' }}>
                    <span>Acronyms</span>
                  </Typography>
                </center>
                <Grid item xs={12} style={{ overflow: 'scroll' }}>
                  <BarChart
                    width={barGraphSystemAcronym.length * 300}
                    height={200}
                    data={barGraphSystemAcronym}
                    barSize={20}
                  >
                    <CartesianGrid strokeDasharray="0 0" />
                    <XAxis dataKey="entValue" fontSize={10} textAnchor="middle" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill={isensTheme.palette.green.light} />
                  </BarChart>
                </Grid>
              </Grid>
            )}
            {barGraphStandards.length > 0 && (
              <Grid>
                <center>
                  <Typography variant="h6" display="block" style={{ marginLeft: '0px' }}>
                    <span>Standards</span>
                  </Typography>
                </center>
                <Grid item xs={12} style={{ overflow: 'scroll' }}>
                  <BarChart
                    width={barGraphStandards.length * 300}
                    height={200}
                    data={barGraphStandards}
                    barSize={20}
                  >
                    <CartesianGrid strokeDasharray="0 0" />
                    <XAxis dataKey="entValue" fontSize={10} textAnchor="middle" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill={isensTheme.palette.primary.light} />
                  </BarChart>
                </Grid>
              </Grid>
            )}
            {barGraphCyber.length > 0 && (
              <Grid>
                <center>
                  <Typography variant="h6" display="block" style={{ marginLeft: '0px' }}>
                    <span>Cyber Security</span>
                  </Typography>
                </center>
                <Grid item xs={12} style={{ overflow: 'scroll' }}>
                  <BarChart
                    width={barGraphCyber.length === 1 ? 1200 : barGraphCyber.length * 280}
                    height={200}
                    data={barGraphCyber}
                    barSize={20}
                  >
                    <CartesianGrid strokeDasharray="0 0" />
                    <XAxis dataKey="entValue" fontSize={10} textAnchor="middle" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill="#002884" />
                  </BarChart>
                </Grid>
              </Grid>
            )}
            {barGraphProject.length > 0 && (
              <Grid>
                <center>
                  <Typography variant="h6" display="block" style={{ marginLeft: '0px' }}>
                    <span>Project Management</span>
                  </Typography>
                </center>
                <Grid item xs={12} style={{ overflow: 'scroll' }}>
                  <BarChart
                    width={barGraphProject.length === 1 ? 1200 : barGraphProject.length * 280}
                    height={200}
                    data={barGraphProject}
                    barSize={20}
                  >
                    <CartesianGrid strokeDasharray="0 0" />
                    <XAxis dataKey="entValue" fontSize={10} textAnchor="middle" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill={isensTheme.palette.primary.light} />
                  </BarChart>
                </Grid>
              </Grid>
            )}

            {barGraphCommercial.length > 0 && (
              <Grid>
                <center>
                  <Typography variant="h6" display="block" style={{ marginLeft: '0px' }}>
                    <span>Commercial</span>
                  </Typography>
                </center>
                <Grid item xs={12} style={{ overflow: 'scroll' }}>
                  <BarChart
                    width={barGraphCommercial.length === 1 ? 1200 : barGraphCommercial.length * 280}
                    height={200}
                    data={barGraphCommercial}
                    barSize={20}
                  >
                    <CartesianGrid strokeDasharray="0 0" />
                    <XAxis dataKey="entValue" fontSize={10} textAnchor="middle" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill={isensTheme.palette.secondary.light} />
                  </BarChart>
                </Grid>
              </Grid>
            )}

            {barGraphFinance.length > 0 && (
              <Grid>
                <center>
                  <Typography variant="h6" display="block" style={{ marginLeft: '0px' }}>
                    <span>Finance</span>
                  </Typography>
                </center>
                <Grid item xs={12} style={{ overflow: 'scroll' }}>
                  <BarChart
                    width={barGraphFinance.length === 1 ? 1200 : barGraphFinance.length * 280}
                    height={200}
                    data={barGraphFinance}
                    barSize={20}
                  >
                    <CartesianGrid strokeDasharray="0 0" />
                    <XAxis dataKey="entValue" fontSize={10} textAnchor="middle" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill={isensTheme.palette.green.light} />
                  </BarChart>
                </Grid>
              </Grid>
            )}

            {barGraphContractual.length > 0 && (
              <Grid>
                <center>
                  <Typography variant="h6" display="block" style={{ marginLeft: '0px' }}>
                    <span>Contractual</span>
                  </Typography>
                </center>
                <Grid item xs={12} style={{ overflow: 'scroll' }}>
                  <BarChart
                    width={
                      barGraphContractual.length === 1 ? 1200 : barGraphContractual.length * 280
                    }
                    height={200}
                    data={barGraphContractual}
                    barSize={20}
                  >
                    <CartesianGrid strokeDasharray="0 0" />
                    <XAxis dataKey="entValue" fontSize={10} textAnchor="middle" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill={isensTheme.palette.primary.light} />
                  </BarChart>
                </Grid>
              </Grid>
            )}

            {barGraphDigital.length > 0 && (
              <Grid>
                <center>
                  <Typography variant="h6" display="block" style={{ marginLeft: '0px' }}>
                    <span>Digital</span>
                  </Typography>
                </center>
                <Grid item xs={12} style={{ overflow: 'scroll' }}>
                  <BarChart
                    width={barGraphDigital.length === 1 ? 1200 : barGraphDigital.length * 280}
                    height={200}
                    data={barGraphDigital}
                    barSize={20}
                  >
                    <CartesianGrid strokeDasharray="0 0" />
                    <XAxis dataKey="entValue" fontSize={10} textAnchor="middle" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill={isensTheme.palette.primary.light} />
                  </BarChart>
                </Grid>
              </Grid>
            )}
          </Paper>
          <div>
            <br />
            <Paper>
              {systemCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>System Entites</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={systemCloudData}
                        shuffle={true}
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                        renderer={customRenderer}
                        // style={{
                        //   // width: 800,
                        //   textAlign: 'center',
                        //   backgroundColor: 'white',
                        //   fontWeight: 'bold',
                        // }}
                      />
                      {/* {console.log('tooltipContent', tooltipContent)}
                      {tooltipContent && (
                        <Tooltip title={tooltipContent}>
                          <span>{tooltipContent}</span>
                        </Tooltip>
                      )} */}
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
            <br />
            <Paper>
              {activityCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Activities</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={activityCloudData}
                        shuffle={true}
                        // onMouseMove={(tag) =>
                        //   setSnack(`'${tag.value}',  count: '${tag.count}'`, 'info')
                        // }
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                        renderer={customRenderer}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
            <br />
            <Paper>
              {acronymCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Acronyms</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={acronymCloudData}
                        shuffle={true}
                        // onMouseMove={(tag) =>
                        //   setSnack(`'${tag.value}',  count: '${tag.count}'`, 'info')
                        // }
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                        renderer={customRenderer}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
            <br />
            <Paper>
              {standardsCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Standards</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={standardsCloudData}
                        shuffle={true}
                        renderer={customRenderer}
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
            <br />
            <Paper>
              {cyberCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Cyber Security</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={cyberCloudData}
                        shuffle={true}
                        renderer={customRenderer}
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
            <br />
            <Paper>
              {projectCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Project Management</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={projectCloudData}
                        shuffle={true}
                        renderer={customRenderer}
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
            <br />
            <Paper>
              {commercialCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Commercial</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={commercialCloudData}
                        shuffle={true}
                        renderer={customRenderer}
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
            <br />
            <Paper>
              {financeCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Finance</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={financeCloudData}
                        shuffle={true}
                        renderer={customRenderer}
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>

            <br />
            <Paper>
              {contractualCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Contractual</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={contractualCloudData}
                        shuffle={true}
                        renderer={customRenderer}
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>

            <Paper>
              {digitalCloudData.length > 0 && (
                <div>
                  <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                    <span>Digital</span>
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={8}>
                      <TagCloud
                        minSize={18}
                        maxSize={45}
                        tags={digitalCloudData}
                        shuffle={true}
                        renderer={customRenderer}
                        style={{
                          // width: 800,
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
          </div>

          {/* <Paper>
            <span>
              <hr />
              <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                <span>System Entites</span>
              </Typography>
              <div style={{ height: 300 }}>
                <ReactWordcloud
                  words={systemCloudData}
                  options={{
                    fontSizes: [12, 60],
                    fontStyle: 'normal',
                    rotations: 0,
                  }}
                />
              </div>
            </span>
            <span>
              <hr />
              <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                <span>Activities</span>
              </Typography>

              <div style={{ height: 300 }}>
                <ReactWordcloud
                  words={activityCloudData}
                  options={{ fontSizes: [12, 60], fontStyle: 'normal', rotations: 0 }}
                />
              </div>
            </span>
            <span>
              <hr />
              <Typography variant="h6" display="block" style={{ marginLeft: '20px' }}>
                <span>System Acronyms</span>
              </Typography>
              <div style={{ height: 300 }}>
                <ReactWordcloud
                  words={acronymCloudData}
                  options={{ fontSizes: [12, 60], fontStyle: 'normal', rotations: 0 }}
                />
              </div>
            </span>
          </Paper> */}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default INer;
