import React, { useMemo } from 'react';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
  AddCircle as AddCircleIcon,
  Send as SendIcon,
  PlaylistAdd as PlaylistAddIcon,
  Merge as MergeIcon,
  CallSplit,
  HorizontalSplit as HorizontalSplitIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  ControlPoint as ControlPointIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  Save as SaveIcon,
  FactCheck as FactCheckIcon,
  ViewColumn as ViewColumnIcon,
  DensityLarge as DensityLargeIcon,
  DensityMedium as DensityMediumIcon,
  DensitySmall as DensitySmallIcon,
  ArrowDownward as ArrowDownwardIcon,
  SwapVert as SwapVertIcon,
  Sort as SortIcon,
} from '@mui/icons-material';

const useIconsForTable = () => {
  const IconsForTable = useMemo(
    () => ({
      ViewColumnIcon: (props) => <ViewColumnIcon color="info" {...props} />,
      DensityLargeIcon: (props) => <DensityLargeIcon color="info" {...props} />,
      DensityMediumIcon: (props) => <DensityMediumIcon color="info" {...props} />,
      DensitySmallIcon: (props) => <DensitySmallIcon color="info" {...props} />,
      ArrowDownwardIcon: (props) => <ArrowDownwardIcon color="info" {...props} />,
      SortIcon: (props) => () => <SortIcon color="secondary" {...props} />,
      SwapVert: (props) => <SwapVertIcon color="secondary" {...props} />,
    }),
    []
  );

  return { IconsForTable };
};

export default useIconsForTable;
