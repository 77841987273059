const extractABS = (pbs: string, groups: []) => {
  const _tmp = groups?.filter((_g: any) => _g.pbs === pbs).map((_g: any) => _g.abs) || [];
  const extractedABS = [...(new Set(_tmp) as any)] || [];
  return extractedABS;
};

const extractOBS = (pbs: string, abs: string, groups: []) => {
  const _tmp =
    groups?.filter((_g: any) => _g.pbs === pbs && _g.abs === abs).map((_g: any) => _g.obs) || [];
  const extractedOBS = [...(new Set(_tmp) as any)] || '';
  return extractedOBS.pop() || '';
};

const extractUsers = (pbs: string, abs: string, obs: string, groups: []): any[] => {
  const groupuser = groups?.find((v: any) => {
    return pbs === v.pbs && (abs || '') === v.abs && obs === v.obs;
  }) as any;

  if (groupuser) return groupuser?.user_ids || [];

  return [];
};

const extractUsersList = (subAllocations: any, abs: string, obs: string, groups: [], users: []) => {
  let receiver = [] as any;

  const receivers = extractUsers(subAllocations.pbs, abs || '', obs, groups).filter(
    (userId) => users?.findIndex((user: any) => user.user_id === userId.id) !== -1
  );

  if (receivers.length) {
    // receiver = [subAllocations.receiver] || receivers || [];
    const receiversList = receivers.map((userId) =>
      users?.find((user: any) => user.user_id === userId.id)
    );

    const receiverIdsList = receiversList.map(
      (user: any) => `${user?.user?.name}_${user?.user?.id}`
    );

    // check if allocation.receiver not is included in list take first one
    if (!receiverIdsList.includes(subAllocations.receiver)) {
      // eslint-disable-next-line prefer-destructuring
      receiver = receiverIdsList;
    } else {
      const r = receivers.map((user: any) => `${user?.name}_${user?.id}`);
      receiver = r;
    }
  }

  return receiver;
};

const extractUserName = (name, key) => {
  const check = {
    name: 0,
    id: 1,
  };
  const temp = name?.split('_')[check[key]] || '';
  return !temp ? '' : temp;
};

const generateUniqueId = () => {
  const timestamp = new Date().getTime().toString(16);
  const randomNumber = Math.random().toString(16).substr(2);
  return `${timestamp}-${randomNumber}`;
};

const splitText = (text: string) => {
  const splitArray = text?.split('__*|*');
  const removedSymbols = splitArray.length > 0 ? splitArray[1] : '';
  return removedSymbols;
};

export default extractUsers;

export { extractABS, extractOBS, extractUsersList, extractUserName, generateUniqueId, splitText };
