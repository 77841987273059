/* eslint-disable import/prefer-default-export */
import axios from './axios';
import { BACKEND_URL } from '../const';

export const download_datasets = (containerName: string, fileLocation: string): string => {
  let querystring = `container_name=${containerName}`;
  querystring = `${querystring}&file_location=${fileLocation}`;
  return `${BACKEND_URL.root}/download/resources/datasets?${querystring}`;
};

export default download_datasets;
