import React, { useMemo } from 'react';

const useRefreshLoading = ({ refreshLoading, loading }) => {
  const loadTest = useMemo(() => {
    if (loading) {
      return true;
    }

    if (refreshLoading) {
      return true;
    }

    return false;
  }, [refreshLoading, loading]);

  return { loadTest };
};

export default useRefreshLoading;
