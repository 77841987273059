import { Table } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import TStyles, { TStylesAloc } from './TStyles';

const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase) as any;
export const TableComponent2 = withStyles(TStylesAloc, { name: 'TableComponent' })(
  TableComponentBase
) as any;
export default TableComponent;
