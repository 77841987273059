import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import React from 'react';
import tableStyles from '../../../app/tableStyles';
import BarStackDiv from '../../../components/Charts/BarStackDiv';
import formatNumber from '../../../tools/formatNumber';
import { colorsRules } from '../ResultContainer';

type Props = {
  rows: Data[];
  displayBar?: boolean;
  stickyRow?: Data;
};

type Data = {
  label: string | number;
  nok: string | number;
  ok: string | number;
  not_applicable: string | number;
  not_tested: string | number;
  sub_rows: any;
};

const localStyles = makeStyles((theme: Theme) =>
  createStyles({
    td: {
      width: 140,
    },
  })
);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'label', numeric: false, disablePadding: false, label: 'Rule' },
  { id: 'ok', numeric: true, disablePadding: false, label: 'OK' },
  { id: 'nok', numeric: true, disablePadding: false, label: 'Not OK' },
  { id: 'not_applicable', numeric: true, disablePadding: false, label: 'Not Applicable' },
  { id: 'not_tested', numeric: true, disablePadding: false, label: 'Not Tested' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  })
);

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {/* <Input type="text" aria-label="filter file" /> */}
    </Toolbar>
  );
};

function Row(props: {
  row: Data;
  colors: any;
  labelId: string;
  displayBar: boolean;
  classes: any;
}) {
  const { row, colors, labelId, displayBar, classes } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow key={`row-${labelId}-${new Date()}`}>
        <TableCell component="th" scope="row" padding="default">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            {row.label}
          </IconButton>{' '}
        </TableCell>
        {displayBar ? (
          <TableCell align="center" colSpan={4}>
            <BarStackDiv
              key={`BarStackDiv-${labelId}`}
              data={[
                { color: colors.ok, value: row.ok },
                { color: colors.nok, value: row.nok },
                { color: colors.notApplicable, value: row.not_applicable },
                { color: colors.notTested, value: row.not_tested },
              ]}
            />
          </TableCell>
        ) : (
          <>
            <TableCell align="center" className={classes.td} style={{ backgroundColor: colors.ok }}>
              {formatNumber(row.ok)}
            </TableCell>
            <TableCell
              align="center"
              className={classes.td}
              style={{ backgroundColor: colors.nok }}
            >
              {formatNumber(row.nok)}
            </TableCell>
            <TableCell
              align="center"
              className={classes.td}
              style={{ backgroundColor: colors.notApplicable }}
            >
              {formatNumber(row.not_applicable)}
            </TableCell>
            <TableCell
              align="center"
              className={classes.td}
              style={{ backgroundColor: colors.notTested, color: 'white' }}
            >
              {formatNumber(row.not_tested)}
            </TableCell>
          </>
        )}
      </TableRow>
      {open &&
        row.sub_rows &&
        row.sub_rows.map((sub: any, index: number) => {
          return (
            <TableRow key={`sub-${labelId}-${index}-${new Date()}`}>
              <TableCell component="th" id={labelId} scope="row">
                <Typography className={classes.subTd}>{sub.label}</Typography>
              </TableCell>
              <TableCell align="center" style={{ backgroundColor: colors.ok }}>
                {formatNumber(sub.ok)}
              </TableCell>
              <TableCell align="center" style={{ backgroundColor: colors.nok }}>
                {formatNumber(sub.nok)}
              </TableCell>
              <TableCell align="center" style={{ backgroundColor: colors.notApplicable }}>
                {formatNumber(sub.not_applicable)}
              </TableCell>
              <TableCell align="center" style={{ backgroundColor: colors.notTested }}>
                {formatNumber(sub.not_tested)}
              </TableCell>
            </TableRow>
          );
        })}
    </>
  );
}

export default function EnhancedTable({ rows, displayBar: _displayBar, stickyRow }: Props) {
  const classes = tableStyles();
  const localClasses = localStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('label');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [displayBar, setDisplayBar] = React.useState<boolean>(_displayBar || false);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string | number) => {};

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="table-artifacts"
            size="small"
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stickyRow && (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, stickyRow.label)}
                  role="checkbox"
                  tabIndex={-1}
                  key={stickyRow.label}
                >
                  <TableCell component="th" id="total" scope="row" padding="default">
                    <Typography className={classes.subTd} style={{ fontWeight: 'bold' }}>
                      {stickyRow.label}
                    </Typography>
                  </TableCell>
                  {displayBar ? (
                    <TableCell align="center" colSpan={3}>
                      <BarStackDiv
                        key={`BarStackDiv-${stickyRow.label}`}
                        data={[
                          { color: colorsRules.ok, value: stickyRow.ok },
                          { color: colorsRules.nok, value: stickyRow.nok },
                          { color: colorsRules.notApplicable, value: stickyRow.not_applicable },
                          { color: colorsRules.notTested, value: stickyRow.not_tested },
                        ]}
                      />
                    </TableCell>
                  ) : (
                    <>
                      <TableCell
                        align="center"
                        className={localClasses.td}
                        style={{ backgroundColor: colorsRules.ok, fontWeight: 'bold' }}
                      >
                        {formatNumber(stickyRow.ok)}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={localClasses.td}
                        style={{ backgroundColor: colorsRules.nok, fontWeight: 'bold' }}
                      >
                        {formatNumber(stickyRow.nok)}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={localClasses.td}
                        style={{ backgroundColor: colorsRules.notApplicable, fontWeight: 'bold' }}
                      >
                        {formatNumber(stickyRow.not_applicable)}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={localClasses.td}
                        style={{
                          backgroundColor: colorsRules.notTested,
                          color: 'white',
                          fontWeight: 'bold',
                        }}
                      >
                        {formatNumber(stickyRow.not_tested)}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              )}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-collapse-${page}-${index}`;
                  return (
                    <Row
                      key={labelId}
                      row={row}
                      colors={colorsRules}
                      labelId={labelId}
                      displayBar={displayBar}
                      classes={classes}
                    />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 5 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
