export type rowType = {
  'Object Number': string;
  _Filter: string;
  'Rule Comment': string;
  'Rule Criticity': string;
  'Rule Type': string;
  'Project Comment': string;
  'Project Applicability': string;
  'Module Profile': string;
  _Filter_PRD: string;
  'Modified since last baseline': string;
  'Script - Rule - ID': string;
  'MARS Rules v1.1': string;
  'Script - Rule - Condition - Local': string;
  'Script - Rule - Expected Result': string;
  'Script - Rule - Condition - Full': string;
  'DXL - Attributes in Rule': string;
};

export type xslxColumn = {
  A: string;
  B: string;
  C: string;
  D: string;
  E: string;
  F: string;
  G: string;
  H: string;
  I: string;
  J: string;
  K: string;
  L: string;
  M: string;
  N: string;
  O: string;
  P: string;
};

// Object Number	_Filter	Rule Comment	Rule Criticity	Rule Type	Project Comment	Project Applicability	Module Profile	_Filter_PRD	Modified since last baseline	Script - Rule - ID	MARS Rules v1.1	Script - Rule - Condition - Local	Script - Rule - Expected Result	Script - Rule - Condition - Full	DXL - Attributes in Rule

export type exportConfig = rowType[];

export default (inputJson: any[]): exportConfig => {
  return inputJson.slice(1).map(
    (row: xslxColumn): rowType => {
      return {
        'Object Number': row.A || '',
        _Filter: row.B || '',
        'Rule Comment': row.C || '',
        'Rule Criticity': row.D || '',
        'Rule Type': row.E || '',
        'Project Comment': row.F || '',
        'Project Applicability': row.G || '',
        'Module Profile': row.H || '',
        _Filter_PRD: row.I || '',
        'Modified since last baseline': row.J || '',
        'Script - Rule - ID': row.K || '',
        'MARS Rules v1.1': row.L || '',
        'Script - Rule - Condition - Local': row.M || '',
        'Script - Rule - Expected Result': row.N || '',
        'Script - Rule - Condition - Full': row.O || '',
        'DXL - Attributes in Rule': row.P || '',
      };
    }
  );
};
