import { Avatar } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React from 'react';
import { Link, Route, RouteProps, useHistory } from 'react-router-dom';
import logoIcon from '../assets/logo-icon.png';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    listViewBottom: {
      width: '100%',
      flexGrow: 1,
      // bottom: 0,
      // position: 'fixed',
      maxWidth: drawerWidth,
      '& .Mui-selected': {
        background: '#DC3223 0% 0% no-repeat padding-box',
        border: '1px solid #002A45',
        color: 'white !important',
        // color: 'white',
        fontWeight: 'bold',
        '& .MuiListItemIcon-root': {
          color: 'white',
        },
        '& .MuiListItemText-root': {
          color: 'white !important',
          fontWeight: 'bold',
        },
      },
      '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
        background: '#DC3223 0% 0% no-repeat padding-box',
      },
      '& .MuiListItem-root': {
        background: '#EEF1F9 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
        borderBottom: '1px solid #C4D4EC',
      },
    },
    ListItemInside: {
      // '& .MuiListItem-button:hover': {
      //   background: 'red',
      // },
      textAlign: 'center',
      fontSize: 16,
      // padding: '0px 108px 0px 13px',
      paddingTop: '13px',
      paddingBottom: '13px',
      '& .MuiListItemIcon-root': {
        color: '#002A45',
        // minWidth: '38px',
      },
      '& .MuiListItemText-root': {
        color: '#002A45 !important',
        fontWeight: 'bold',
      },
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    title: {
      flexGrow: 1,
      color: 'white',
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      background: '#EEF1F9 0% 0% no-repeat padding-box',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  })
);
const AdminLayout = ({ component: Component, ...rest }: RouteProps & any): any => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Route
      {...rest}
      render={(props: any): any => (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              {/* <Link
                to="/"
                style={{
                  textDecoration: 'None',
                }}
              >
                <IconButton
                  color="inherit"
                  aria-label="isens app"
                  edge="start"
                  className={clsx(classes.menuButton)}
                >
                  <Avatar alt="iSenS" src={logoIcon} />
                </IconButton>
              </Link>
              <Typography variant="h6" noWrap>
                Isens Admin
              </Typography> */}
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div
              style={{
                background: '#002A45',
              }}
              className={classes.drawerHeader}
            >
              <Toolbar className={classes.toolbar}>
                <Link to="/">
                  <IconButton
                    size="small"
                    edge="start"
                    aria-label="home"
                    className={classes.menuButton}
                  >
                    <Avatar alt="iSenS" src={logoIcon} />
                  </IconButton>
                </Link>
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  className={classes.title}
                >
                  iSenS Admin
                </Typography>
              </Toolbar>
              <IconButton onClick={handleDrawerClose} style={{ color: '#FFFFFF' }}>
                <MenuIcon />
              </IconButton>
              {/* <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton> */}
            </div>
            <List className={classes.listViewBottom}>
              <ListItem
                button
                key="Users"
                onClick={() => {
                  history.push('/admin');
                  // setModes('mode2');
                  setSelectedIndex(1);
                }}
                selected={selectedIndex === 1}
                className={classes.ListItemInside}
              >
                <ListItemText primary="Users" />
              </ListItem>
              <ListItem
                button
                key="Projects"
                onClick={() => {
                  history.push('/admin/projects');
                  // setModes('mode2');
                  setSelectedIndex(2);
                }}
                selected={selectedIndex === 2}
                className={classes.ListItemInside}
              >
                <ListItemText primary="Projects" />
              </ListItem>
              <ListItem
                button
                key="iVerifier Templates"
                onClick={() => {
                  history.push('/admin/iverifier_templates');
                  // setModes('mode2');
                  setSelectedIndex(3);
                }}
                selected={selectedIndex === 3}
                className={classes.ListItemInside}
              >
                <ListItemText primary="iVerifier_Templates" />
              </ListItem>
              <ListItem
                button
                key="AdminHistory"
                onClick={() => {
                  history.push('/admin/AdminHistory');
                  // setModes('mode2');
                  setSelectedIndex(4);
                }}
                selected={selectedIndex === 4}
                className={classes.ListItemInside}
              >
                <ListItemText primary="History" />
              </ListItem>
              <ListItem
                button
                key="Assessment"
                onClick={() => {
                  history.push('/admin/Assessment');
                  // setModes('mode2');
                  setSelectedIndex(5);
                }}
                selected={selectedIndex === 5}
                className={classes.ListItemInside}
              >
                <ListItemText primary="Assessment" />
              </ListItem>
              <ListItem
                button
                key="AdminKpi"
                onClick={() => {
                  history.push('/admin/AdminKpi');
                  // setModes('mode2');
                  setSelectedIndex(6);
                }}
                selected={selectedIndex === 6}
                className={classes.ListItemInside}
              >
                <ListItemText primary="Project KPI" />
              </ListItem>
              <ListItem
                button
                key="scheduleAnalysis"
                onClick={() => {
                  history.push('/admin/scheduleAnalysis');
                  // setModes('mode2');
                  setSelectedIndex(7);
                }}
                selected={selectedIndex === 7}
                className={classes.ListItemInside}
              >
                <ListItemText primary="Schedule Analysis Configration" />
              </ListItem>
              <ListItem
                button
                key="defaultGlossary"
                onClick={() => {
                  history.push('/admin/defaultGlossary');
                  // setModes('mode2');
                  setSelectedIndex(8);
                }}
                selected={selectedIndex === 8}
                className={classes.ListItemInside}
              >
                <ListItemText primary="Default Glossary" />
              </ListItem>
            </List>
            <Divider />
            {/* <List className={classes.listViewBottom}>
              <ListItem button key="Projects" className={classes.ListItemInside}>
                <Link
                  to="/admin/projects"
                  style={{
                    textDecoration: 'None',
                  }}
                >
                  <ListItemText primary="Projects" />
                </Link>
              </ListItem>
            </List>
            <List className={classes.listViewBottom}>
              <ListItem button key="iverifier_templates" className={classes.ListItemInside}>
                <Link
                  to="/admin/iverifier_templates"
                  style={{
                    textDecoration: 'None',
                  }}
                >
                  <ListItemText primary="iVerifier Templates" />
                </Link>
              </ListItem>
            </List> */}
            <Divider />
            {/* <List>
              <ListItem button key="Assessment">
                <Link
                  to="/admin/Assessment"
                  style={{
                    textDecoration: 'None',
                  }}
                >
                  <ListItemText primary="Assessment" />
                </Link>
              </ListItem>
            </List> */}
          </Drawer>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            <Component {...props} />
          </main>
        </div>
      )}
    />
  );
};

export default AdminLayout;
