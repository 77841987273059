import { Column } from '@devexpress/dx-react-grid';
import { optionsColumnsType } from './allColumns';

export const columnsCapture = (optionColumns: optionsColumnsType): (Column | string)[] => {
  const { targetColumn, displayColumns, isRegExp, levelsHierarchy } = optionColumns;
  const defaultColumns = [
    // 'Menu',
    {
      name: 'id',
      title: 'iSenS_ID',
    },
    'Text',
    'updated_at',
    'PageNo',
    'Hierarchy',
    // 'Text_original',
    'Original_Hierarchy',
    ...levelsHierarchy,
    'SubType',
    'Context',
    'Context_original',
    'Digital',
    'comment',
  ];
  if (isRegExp) {
    const iReq = defaultColumns.findIndex((v: any) => v === 'ID');
    const i = defaultColumns.findIndex((v: any) => v === 'Text');
    // regexp column
    if (i !== -1 && iReq === -1)
      defaultColumns.splice(i, 0, {
        name: 'ID',
        title: 'ReqID',
      });
  }
  if (targetColumn && displayColumns) {
    return [
      'id',
      'updated_at',
      ...Array.from(new Set([targetColumn, ...displayColumns])),
      'comment',
      // 'Artifact_Text_original',
    ];
  }
  return defaultColumns;
};

export default columnsCapture;
