import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { isEmpty } from '@antv/util';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { getScheduleAnalysisConf, postScheduleAnalysisConf } from '../../../api/module1';
import configscheduleanalysis from './scheduleanalysisconfig';

export const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, padding: theme.spacing(2) },
  bloc: {
    minHeight: 250,
    borderRadius: 10,
    // border: 'solid 1px;',
    borderColor: theme.palette.primary.light,
    margin: 10,
  },
  error: {
    color: theme.palette.error.main,
  },
  heading: {
    backgroundColor: '#DC3223',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
  },
  success: {
    background: 'lightgreen',
    border: 'solid 1px green',
    borderRadius: 5,
    padding: 5,
  },
}));

const AdminScheduleAnalysis = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const names = ['sig_platform', 'turnkey_value', 'infra_value'];
  const [selectedNames, setSelectedNames] = useState([]);
  const [payload, setPayload] = useState({});
  const firstFieldRef = useRef(null);
  const [firstField, setFirstField] = useState('');
  const [firstFieldFocused, setFirstFieldFocused] = useState(false);

  function adjustTextareaHeight(refthis) {
    refthis.current.style.height = 'auto'; // eslint-disable-line no-param-reassign
    refthis.current.style.height = `${refthis.current.scrollHeight}px`; // eslint-disable-line no-param-reassign
  }

  const handleFirstFieldChange = (event) => {
    setFirstField(event.target.value);
    if (event.target.value) {
      const obj = {
        dropdown: selectedNames,
        data: JSON.parse(event.target.value),
      };
      setPayload(obj);
    }
    adjustTextareaHeight(firstFieldRef);
  };

  const handleFirstFieldFocus = () => {
    setFirstFieldFocused(true);
  };

  const handleFirstFieldBlur = () => {
    setFirstFieldFocused(false);
  };

  const handleOnChange = async (e) => {
    setSelectedNames(e.target.value);
    const data = await getScheduleAnalysisConf(e.target.value);
    setFirstField(JSON.stringify(data[0]?.signaling_tree_data || []));
  };

  const handleSubmit = async () => {
    await postScheduleAnalysisConf(payload);
  };

  return (
    <div className={classes.root}>
      <Paper>
        <Box className={classes.bloc}>
          <Typography variant="subtitle1" className={classes.heading}>
            Schedule Analysis Configration
          </Typography>
          <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
            <FormControl variant="outlined" style={{ width: 300, margin: 10 }}>
              <Select
                // multiple
                value={selectedNames}
                onChange={(e) => handleOnChange(e)}
                input={<OutlinedInput label="Multiple Select" />}
                // renderValue={(selected) => (
                //   <Stack gap={1} direction="row" flexWrap="wrap">
                //     {selected.map((value) => (
                //       <Chip
                //         key={value}
                //         label={value}
                //         onDelete={() =>
                //           setSelectedNames(selectedNames.filter((item) => item !== value))
                //         }
                //         deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                //       />
                //     ))}
                //   </Stack>
                // )}
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name} style={{ justifyContent: 'space-between' }}>
                    {name}
                    {selectedNames.includes(name) ? <CheckIcon color="info" /> : null}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Typography variant="subtitle1">Please provide your configration here</Typography>
          <textarea
            ref={firstFieldRef}
            value={firstField}
            onChange={handleFirstFieldChange}
            onFocus={handleFirstFieldFocus}
            onBlur={handleFirstFieldBlur}
            style={{
              width: '100%',
              minHeight: '30vh',
              border: firstFieldFocused ? 'none' : 'none',
              outline: firstFieldFocused ? 'none' : 'none',
              background: '#EEF1F9',
              resize: 'none',
              overflow: 'hidden',
              fontSize: 16,
            }}
          />
          <Button
            type="button"
            variant="contained"
            color="primary"
            startIcon={loading && <CircularProgress style={{ color: 'white' }} size={20} />}
            onClick={handleSubmit}
            style={{
              // width: '150px',
              // height: '38px',
              flex: 1,
              marginTop: 6,
              marginBottom: 6,
              float: 'right',
            }}
          >
            SUBMIT
          </Button>
        </Box>
      </Paper>
    </div>
  );
};

export default AdminScheduleAnalysis;
