/* eslint-disable react/destructuring-assignment */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  Table,
  PagingPanel,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  Toolbar,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
} from '@devexpress/dx-react-grid-material-ui';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';

import {
  Button,
  CircularProgress,
  fade,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import TableCell from '@material-ui/core/TableCell';
import Radio from '@material-ui/core/Radio';

import Module1Context from '../Module5Context';
import formatBytes from '../../../tools/formatBytes';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import { fileDetails } from '../../../api/module1';
import { iSafety_doc2dataset } from '../../../api/module5';
import TStyles, { rowComponent, tUseStyles } from '../../../components/Datagrid/TStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  gridStyle: {
    border: '10px solid black ',
  },
}));
// table header
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
  { name: 'userName', title: 'Uploaded by' },
];

type PayloadText2Dataset = {
  Requirement: string;
  'DesignClosure 1': string;
  'ValidationClosure 1': string;
  'FileType 1': string;
  'Score 1': string;
  'DesignClosure 2': string;
  'ValidationClosure 2': string;
  'FileType 2': string;
  'Score 2': string;
  'DesignClosure 3': string;
  'ValidationClosure 3': string;
  'FileType 3': string;
  'Score 3': string;
  'DesignClosure 4': string;
  'ValidationClosure 4': string;
  'FileType 4': string;
  'Score 4': string;
  'DesignClosure 5': string;
  'ValidationClosure 5': string;
  'FileType 5': string;
  'Score 5': string;
};

function IsafetyNew() {
  const classes = useStyles();
  const tableUseStyles = tUseStyles();
  const { project } = useContext(Module1Context);
  const [files, setFiles] = useState<any[]>([]);

  const [selection, setSelection] = useState([]);
  // to control the loader and display data full Grid
  const { setSnack } = useContext(Module1Context);
  const [loading, setLoading] = useState(false);
  const [loadingSecond, setLoadingSecond] = useState(false);
  const [display, setDisplay] = useState(false);
  const [sheetNames, setSheetNames] = useState<any[]>([]);
  const [columnName, setColumnName] = useState<any[]>([]);
  const [sheets, setSheets] = useState<any[]>([]);
  const [selectedSheetName, setSelectedSheetName] = useState<any>();
  const [selectedColumnName, setSelectedColumnName] = useState<any>();
  const [fileType, setFileType] = useState<any>();
  const [displayGrid, setDisplayGrid] = useState<any>();
  const [errorMsg, setErrorMsg] = useState<any>();
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [rows, setRows] = useState<any>([]);
  const [columnsAre, setColumnsAre] = useState<any>([]);
  const [csvDataframe, setCsvDataframe] = useState<any>();
  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };
  const [containerIs, setContainerIs] = useState<any>();
  const [systemSubSytem, setSystemSubSytem] = useState<any>('INFRA');
  const [requirementcolumn, setRequirementcolumn] = useState<any>();
  const [firstLine, setFirstLine] = useState<any>(1);
  const [proposition, setProposition] = useState<any>();
  const [score, setScore] = useState<any>(50);
  const [enableButton, setEnableButton] = useState<any>(true);
  const handleSetRowsColumns = async (myRows: {}[]) => {
    const myCols = Object.keys(myRows[0]).map((key) => ({
      name: key,
      title: key.toUpperCase(),
    })) as Column[];
    setColumnsAre(myCols);
    setRows(myRows);
  };
  const [toggleHeight, setToggleHeight] = useState(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

  useEffect(() => {
    const { folders } = project;
    const allResources = [] as any[];
    if (folders) {
      for (const eachFolder of folders) {
        if (eachFolder.resources && eachFolder.resources.length > 0)
          for (const r of eachFolder.resources) {
            // get file extension(only xls, xlsx, csv) before that convert to lower case
            const fileExtension = r.filename.toLowerCase().split('.').pop();
            if (fileExtension === 'xls' || fileExtension === 'xlsx')
              allResources.push({
                ...r,
                size: formatBytes(Number(r.size), 0),
                id: r.etag,
                folderName: eachFolder.name,
                folderId: eachFolder.id,
              });
          }
      }
    }
    setFiles(allResources.sort((a, b) => new Date(b.date) - new Date(a.date)));
  }, []);

  let fileIs1: any;
  if (selection.length > 0 && selection[0] !== undefined && selection.length < 2) {
    fileIs1 = files[selection[0]].filename || '';
    // console.log(fileIs1);
  }

  function getFileDetails(filename, filenameCopy, container) {
    setLoading(true);
    setDisplay(false);
    setDisplayGrid(false);
    setErrorMsg('');
    setContainerIs(container);
    fileDetails(filename, filenameCopy, container)
      .then((payload: any) => {
        if (payload) {
          // console.log(payload);
          setErrorMsg(payload.error);
          if (payload.error === null) {
            if (payload.output.FileType === 'Excel') {
              setSheetNames(payload.output.Sheet_names);
              setSheets(payload.output.Sheets);
              setFileType('Excel');
            } else {
              setErrorMsg('File type not allowed. Select only Excel');
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setDisplay(true);
      });
  }

  function HandleReg(e: any) {
    if (e.target.name === 'sheetname') {
      setColumnName(['']);
      // console.log(e.currentTarget.value);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < sheets.length; i++) {
        if (sheets[i].SheetName === e.currentTarget.value) {
          setColumnName(sheets[i].ColumNames);
          setSelectedSheetName(e.currentTarget.value);
        }
      }
    }
    // if (e.target.name === 'columnname') {
    //   setSelectedColumnName(e.currentTarget.value);
    // }
    if (e.target.name === 'systemSubSytem') {
      setSystemSubSytem(e.currentTarget.value);
    }
    if (e.target.name === 'requirementcolumn') {
      setRequirementcolumn(e.currentTarget.value);
    }
    if (e.target.name === 'firstLine ') {
      setFirstLine(e.currentTarget.value);
    }
    if (e.target.name === 'proposition') {
      if (
        e.target.value === undefined ||
        e.target.value === '' ||
        e.target.value < 0 ||
        e.target.value > 5
      ) {
        setSnack('1 to 5 are allowed only', 'warning');
        setEnableButton(true);
      } else {
        setProposition(e.currentTarget.value);
        setEnableButton(false);
      }
    }
  }

  const handleThresholdChangeFilter = (event, newValue) => {
    setScore(newValue);
    // eslint-disable-next-line func-names
    setTimeout(function () {
      // apiCallScoreWise(input, count, newValue);
    }, 1000);
  };

  function getMultiText() {
    setDisplayGrid(false);
    setLoadingSecond(true);
    setTimeout(() => {
      if (selectedSheetName === undefined) {
        setLoadingSecond(false);
      } else {
        iSafety_doc2dataset(
          containerIs,
          systemSubSytem,
          fileIs1,
          selectedSheetName,
          requirementcolumn,
          firstLine,
          proposition
        )
          .then((payload: any) => {
            if (payload) {
              // console.log(payload);
              const initRow = JSON.parse(payload.output);
              if (proposition === '5') {
                handleSetRowsColumns(
                  initRow.filter(
                    (initRow: PayloadText2Dataset) =>
                      initRow['Score 1'] >= score &&
                      initRow['Score 2'] >= score &&
                      initRow['Score 3'] >= score &&
                      initRow['Score 4'] >= score &&
                      initRow['Score 5'] >= score
                  )
                );
              } else if (proposition === '4') {
                handleSetRowsColumns(
                  initRow.filter(
                    (initRow: PayloadText2Dataset) =>
                      initRow['Score 1'] >= score &&
                      initRow['Score 2'] >= score &&
                      initRow['Score 3'] >= score &&
                      initRow['Score 4'] >= score
                  )
                );
              } else if (proposition === '3') {
                handleSetRowsColumns(
                  initRow.filter(
                    (initRow: PayloadText2Dataset) =>
                      initRow['Score 1'] >= score &&
                      initRow['Score 2'] >= score &&
                      initRow['Score 3'] >= score
                  )
                );
              } else if (proposition === '2') {
                handleSetRowsColumns(
                  initRow.filter(
                    (initRow: PayloadText2Dataset) =>
                      initRow['Score 1'] >= score && initRow['Score 2'] >= score
                  )
                );
              } else if (proposition === '1') {
                handleSetRowsColumns(
                  initRow.filter((initRow: PayloadText2Dataset) => initRow['Score 1'] >= score)
                );
              } else {
                // console.log('else');
                handleSetRowsColumns(initRow);
              }
              setDisplayGrid(true);
            } else {
              handleSetRowsColumns(JSON.parse('[""]'));
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoadingSecond(false);
          });
      }
    }, 1000);
  }

  const onChangeAttribute = (selection: any) => {
    const newselection: any = [0];
    if (selection[0] === undefined) {
      // eslint-disable-next-line no-param-reassign
      selection[0] = selection.slice(-1).pop();
    }
    // eslint-disable-next-line prefer-destructuring
    newselection[0] = selection[1];
    setSelection(newselection);

    if (selection.length === 1) {
      // console.log('if condition no action required: ', selection);
    } else {
      // console.log('else', selection.slice(-1).pop());
      const fileno = selection.slice(-1).pop();
      // console.log(files[fileno].container);
      getFileDetails(files[fileno].filename, files[fileno].filename, files[fileno].container);
    }

    // console.log(files[selection[0]]);
  };

  return (
    <>
      <div>
        <span>
          <b>Select one file:</b>
        </span>
        <span>
          <br />
          {/* Total rows selected: {selection.length} */}
          <span style={{ marginRight: '10px' }}>
            Selected File Name:<b> {fileIs1 || ''}</b>
          </span>
        </span>
        <br />
      </div>
      <div>
        <Paper className={tableUseStyles.panelMain}>
          <ExpressGrid rows={files} columns={columns}>
            <DataTypeProvider
              for={['filename']}
              formatterComponent={({ value }) => <span title={value}>{value}</span>}
            />
            <SelectionState selection={selection} onSelectionChange={onChangeAttribute} />
            <FilteringState defaultFilters={[{ columnName: 'filename', value: '' }]} />
            <IntegratedFiltering />
            <SortingState />
            <IntegratedSorting />
            <PagingState defaultCurrentPage={0} defaultPageSize={5} />
            <IntegratedPaging />
            <VirtualTable />
            <Table tableComponent={TableComponent} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
            <PagingPanel pageSizes={pageSizes} />
          </ExpressGrid>

          {/* <span style={{ float: 'right', marginRight: '10px' }}>
            Selected File Name: <b>{fileIs1 || 'NA'}</b>
          </span> */}

          <br />
          <span style={{ color: 'red' }}>{errorMsg}</span>

          {loading ? (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            ''
          )}
          {display && errorMsg === null && fileType === 'Excel' && (
            <div className={classes.root} style={{ marginTop: '0px' }}>
              <form className={classes.root} noValidate autoComplete="off">
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">Sheet Name</InputLabel>
                  <Select
                    native
                    label="Sheet Name"
                    name="sheetname"
                    style={{ width: '200px' }}
                    onChange={HandleReg}
                    defaultValue=""
                  >
                    <option aria-label="None" value="" disabled />
                    {sheetNames.length > 0
                      ? sheetNames.map((cap: string, index: number) => (
                          <option key={`capture-type-${index}`} value={cap}>
                            &nbsp;&nbsp;{cap}
                          </option>
                        ))
                      : ''}
                  </Select>
                </FormControl>
                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">Target Column</InputLabel>
                  <Select
                    native
                    label="Column  Name"
                    name="columnname"
                    style={{ width: '200px' }}
                    onChange={HandleReg}
                    defaultValue=""
                  >
                    <option aria-label="None" value="" disabled />
                    {columnName.length > 0
                      ? columnName.map((cap: string, index: number) => (
                          <option key={`capture-type-${index}`} value={cap}>
                            &nbsp;&nbsp;{cap}
                          </option>
                        ))
                      : ''}
                  </Select>
                </FormControl> */}
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">SystemSubSytem</InputLabel>
                  <Select
                    native
                    label="SystemSubSytem"
                    name="systemSubSytem"
                    style={{ width: '100px' }}
                    onChange={HandleReg}
                    // defaultValue=""
                  >
                    <option aria-label="None" value="" disabled />
                    <option value="INFRA">INFRA</option>
                    <option value="SYS">SYS</option>
                    <option value="RSK">RSK</option>
                    {/* <option value="RST">RST</option> */}
                  </Select>
                </FormControl>
                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="outlined-basic"
                    label="systemSubSytem"
                    name="systemSubSytem"
                    variant="outlined"
                    onChange={HandleReg}
                  />
                </FormControl> */}
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="outlined-basic"
                    label="Requirement Column"
                    name="requirementcolumn"
                    variant="outlined"
                    onChange={HandleReg}
                  />
                </FormControl>
                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="outlined-basic"
                    label="First Line"
                    name="firstLine"
                    variant="outlined"
                    onChange={HandleReg}
                    style={{ width: '100px' }}
                  />
                </FormControl> */}
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="outlined-basic"
                    label="Number Of Proposition "
                    name="proposition"
                    variant="outlined"
                    style={{ width: '100px' }}
                    onChange={HandleReg}
                    type="number"
                  />
                </FormControl>
                <Slider
                  name="count-slider"
                  min={50}
                  max={100}
                  defaultValue={50}
                  // getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider-always"
                  step={1}
                  // marks={marks}
                  valueLabelDisplay="on"
                  style={{ width: '12rem', marginTop: 35 }}
                  onChangeCommitted={handleThresholdChangeFilter}
                  aria-label="Number of records"
                />
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={getMultiText}
                  style={{
                    marginTop: '30px',
                    height: '50px',
                    width: '150px',
                    float: 'right',
                    marginRight: '10px',
                  }}
                  disabled={enableButton}
                >
                  Analyse
                </Button>
              </form>
            </div>
          )}
          <br />
        </Paper>
      </div>
      {loadingSecond ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        ''
      )}
      {displayGrid && (
        <div>
          <br />
          <span style={{ color: 'black' }}>
            <b>Document Name: </b> {fileIs1}
          </span>
          <br />

          <Grid style={{ border: '1px solid', borderRadius: 5 }}>
            <div style={{ padding: '0px', border: '1px' }}>
              <ExpressGrid rows={rows} columns={columnsAre}>
                <DataTypeProvider
                  for={[
                    'Requirement',
                    'DesignClosure 1',
                    'ValidationClosure 1',
                    'DesignClosure 2',
                    'ValidationClosure 2',
                    'DesignClosure 3',
                    'ValidationClosure 3',
                    'DesignClosure 4',
                    'ValidationClosure 4',
                    'DesignClosure 5',
                    'ValidationClosure 5',
                  ]}
                  formatterComponent={({ value }) => <span title={value}>{value}</span>}
                />
                <FilteringState defaultFilters={[{ columnName: 'Requirement', value: '' }]} />
                <IntegratedFiltering />
                <SortingState />
                <IntegratedSorting />
                <PagingState defaultCurrentPage={0} defaultPageSize={5} />
                <IntegratedPaging />
                <Table
                  columnExtensions={[
                    { columnName: 'Requirement', width: '200px', wordWrapEnabled: toggleHeight },
                    {
                      columnName: 'DesignClosure 1',
                      width: '200px',
                      wordWrapEnabled: toggleHeight,
                    },
                    {
                      columnName: 'ValidationClosure 1',
                      width: '200px',
                      wordWrapEnabled: toggleHeight,
                    },
                    {
                      columnName: 'DesignClosure 2',
                      width: '200px',
                      wordWrapEnabled: toggleHeight,
                    },
                    {
                      columnName: 'ValidationClosure 2',
                      width: '200px',
                      wordWrapEnabled: toggleHeight,
                    },
                    {
                      columnName: 'DesignClosure 3',
                      width: '200px',
                      wordWrapEnabled: toggleHeight,
                    },
                    {
                      columnName: 'ValidationClosure 3',
                      width: '200px',
                      wordWrapEnabled: toggleHeight,
                    },
                    {
                      columnName: 'DesignClosure 4',
                      width: '200px',
                      wordWrapEnabled: toggleHeight,
                    },
                    {
                      columnName: 'ValidationClosure 4',
                      width: '200px',
                      wordWrapEnabled: toggleHeight,
                    },
                    {
                      columnName: 'DesignClosure 5',
                      width: '200px',
                      wordWrapEnabled: toggleHeight,
                    },
                    {
                      columnName: 'ValidationClosure 5',
                      width: '200px',
                      wordWrapEnabled: toggleHeight,
                    },
                  ]}
                />
                <TableColumnResizing
                  defaultColumnWidths={[
                    { columnName: 'Requirement', width: '200px' },
                    {
                      columnName: 'DesignClosure 1',
                      width: '200px',
                    },
                    {
                      columnName: 'ValidationClosure 1',
                      width: '200px',
                    },
                    {
                      columnName: 'FileType 1',
                      width: '200px',
                    },
                    {
                      columnName: 'Score 1',
                      width: '200px',
                    },
                    {
                      columnName: 'DesignClosure 2',
                      width: '200px',
                    },
                    {
                      columnName: 'ValidationClosure 2',
                      width: '200px',
                    },
                    {
                      columnName: 'FileType 2',
                      width: '200px',
                    },
                    {
                      columnName: 'Score 2',
                      width: '200px',
                    },
                    {
                      columnName: 'DesignClosure 3',
                      width: '200px',
                    },
                    {
                      columnName: 'ValidationClosure 3',
                      width: '200px',
                    },
                    {
                      columnName: 'FileType 3',
                      width: '200px',
                    },
                    {
                      columnName: 'Score 3',
                      width: '200px',
                    },
                    {
                      columnName: 'DesignClosure 4',
                      width: '200px',
                    },
                    {
                      columnName: 'ValidationClosure 4',
                      width: '200px',
                    },
                    {
                      columnName: 'FileType 4',
                      width: '200px',
                    },
                    {
                      columnName: 'Score 4',
                      width: '200px',
                    },
                    {
                      columnName: 'DesignClosure 5',
                      width: '200px',
                    },
                    {
                      columnName: 'ValidationClosure 5',
                      width: '200px',
                    },
                    {
                      columnName: 'FileType 5',
                      width: '200px',
                    },
                    {
                      columnName: 'Score 5',
                      width: '200px',
                    },
                  ]}
                />
                <TableHeaderRow showSortingControls />
                <TableFilterRow rowComponent={rowComponent} />
                <TableColumnVisibility
                  hiddenColumnNames={hiddenColumnNames}
                  onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <Toolbar />
                <ColumnChooser />
                <ToolBarPlugin name="Download" onClick={startExport} />
                <ToolBarPlugin
                  name="Height"
                  title="Wrap Text"
                  onClick={() => {
                    setToggleHeight(!toggleHeight);
                  }}
                />
                <PagingPanel pageSizes={pageSizes} />
              </ExpressGrid>
              <GridExporter ref={exporterRef} rows={rows} columns={columnsAre} onSave={onSave} />
            </div>
          </Grid>
        </div>
      )}
    </>
  );
}

export default IsafetyNew;
