import React from 'react';
import { Grid } from '@material-ui/core';
import UnderIdca from './UnderIdca';

function Idca() {
  return (
    <>
      <Grid style={{ marginLeft: '20px', marginTop: '20px' }}>
        <UnderIdca />
      </Grid>
    </>
  );
}
export default Idca;
