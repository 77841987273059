import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import Paper from '@mui/material/Paper';
import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import {
  getProjectTemplateId,
  getTemplates,
  importTemplateToProject,
  setProjectTemplateId,
} from '../../../api/module3';
import Loading from '../../../components/Core/Loading';
import TableComponent from '../../../components/Datagrid/TableComponent';
import {
  tableContainerComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../components/Datagrid/TStyles';
import { handleColumns } from '../../../tools/datagrid';
import formatDate, { formatLocal } from '../../../tools/formatDate';
import ProjectContext from '../../Project/ProjectContext';
import { renderUser } from '../Admin/IverifierTemplatesAdmin';
import ModalFunctionViewer from '../ModalFunctionViewer';
import ModalRulesViewer from '../ModalRulesViewer';

const columns = handleColumns([
  'id',
  'name',
  'version',
  'rules',
  'functions',
  'created_at',
  'created_by_user',
  'updated_at',
  'updated_by_user',
]);

function IverifierTemplateConfig() {
  const { setSnack } = useContext(ProjectContext);
  const [loading, setLoading] = useState(true);
  const [payloadIs, setPayloadIs] = useState<any>();
  const { projectId } = useParams() as any;
  const [rows, setRows] = useState<any[]>([]);
  const [selection, setSelection] = useState<any>();
  const [selectionPending, setSelectionPending] = useState<any>();
  const [toggleHeight, setToggleHeight] = useState(false);
  const [template, setTemplate] = useState<any>(null);

  // modal state
  const [toggleModalName, setToggleModalName] = useState<
    '' | 'modalConfirm' | 'modalRule' | 'modalFunction'
  >('');

  useEffect(() => {
    const jwtData = JSON.parse(localStorage.getItem('appJWT'));
    let adminIs = false;
    if (jwtData.is_admin === true) {
      adminIs = true;
    }
    getTemplates(adminIs)
      .then((payload: any) => {
        if (payload) {
          setPayloadIs(payload);
          setRows(payload);

          getProjectTemplateId(projectId).then((res) => {
            if (Number(res.iverifier_template_id) > 0) setSelection([res.iverifier_template_id]);
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onConfirmSelection = (newSelection: any) => {
    if (newSelection.length === 0) {
      return;
    }
    let templateSelectedId: number;
    if (newSelection.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      templateSelectedId = newSelection[0];
    } else {
      templateSelectedId = newSelection.slice(-1).pop();
      setSelection(newSelection.slice(-1));
    }
    if (templateSelectedId) {
      importTemplateToProject(projectId, templateSelectedId)
        .then((payload: any) => {
          if (payload) {
            setProjectTemplateId(projectId, templateSelectedId);
          }
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          setSnack(
            `Rules and functions from template ${templateSelectedId} are added to current project`
          );
        });
    }
    // console.log(newSelection, templateSelectedId);
  };

  const handleConfirmOK = () => {
    onConfirmSelection(selectionPending);
    setToggleModalName('');
  };
  const handleConfirmClose = () => {
    setToggleModalName('');
  };

  const onChangeSelection = (newSelection: []) => {
    setSelectionPending(newSelection);
    if (newSelection.length > 0) setToggleModalName('modalConfirm');
  };

  const renderRules = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    if (value)
      return (
        <Button
          onClick={() => {
            setTemplate(row);
            setToggleModalName('modalRule');
          }}
        >
          {value.filter((r) => r.script_rule_id).length}
        </Button>
      );
    return null;
  };
  const renderFunctions = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    if (value)
      return (
        <Button
          onClick={() => {
            setTemplate(row);
            setToggleModalName('modalFunction');
          }}
        >
          {value.length}
        </Button>
      );
    return null;
  };
  // console.log('temp', template);

  if (loading) return <Loading />;

  return (
    <>
      <Paper>
        {/* <ReactJson src={{ selection, payloadIs }} collapsed={true} theme="monokai" /> */}
        <div style={{ marginLeft: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div>Selected Template applied on this project(only one at a time): {projectId}</div>
              <ExpressGrid rows={rows} columns={columns} getRowId={(r) => r.id}>
                <DataTypeProvider for={['rules']} formatterComponent={renderRules} />
                <DataTypeProvider for={['functions']} formatterComponent={renderFunctions} />
                <DataTypeProvider
                  for={['created_at', 'updated_at']}
                  formatterComponent={({ value }) => <>{formatDate(value, formatLocal())}</>}
                />
                <DataTypeProvider
                  for={['created_by_user', 'updated_by_user']}
                  formatterComponent={renderUser}
                />
                {/* selection */}
                <SelectionState selection={selection} onSelectionChange={onChangeSelection} />
                <IntegratedSelection />
                {/* filtering */}
                <FilteringState />
                <SortingState />
                <IntegratedSorting />
                <IntegratedFiltering />
                <PagingState defaultCurrentPage={0} defaultPageSize={10} />
                <IntegratedPaging />
                <Table
                  columnExtensions={columns.map((c) => ({
                    columnName: c.name,
                    wordWrapEnabled: toggleHeight,
                  }))}
                  tableComponent={TableComponent}
                  containerComponent={tableContainerComponent}
                  headComponent={tableHeaderComponent}
                />
                <TableColumnResizing
                  defaultColumnWidths={[
                    ...columns.map((c) => {
                      if (c.name === 'id') return { columnName: c.name, width: 80 };
                      if (c.name === 'version') return { columnName: c.name, width: 120 };
                      if (c.name === 'rules') return { columnName: c.name, width: 80 };
                      if (c.name === 'functions') return { columnName: c.name, width: 80 };
                      if (
                        ['created_at', 'created_by_user', 'updated_at', 'updated_by_user'].includes(
                          c.name
                        )
                      )
                        return { columnName: c.name, width: 150 };
                      return {
                        columnName: c.name,
                        width: Math.ceil(1400 / (columns.length - 7)),
                      };
                    }),
                  ]}
                />
                <TableHeaderRow showSortingControls />
                <TableFilterRow />
                <TableColumnVisibility defaultHiddenColumnNames={[]} />
                <Toolbar rootComponent={toolbarComponent} />
                {/* selection */}
                <TableSelection AllowSelectSiAngleRowOnly="true" />
                <ColumnChooser />
                <PagingPanel pageSizes={[10, 20, 50]} />
              </ExpressGrid>
            </Grid>
          </Grid>
          {toggleModalName === 'modalRule' && (
            <ModalRulesViewer
              rows={template.rules || []}
              template={{
                id: template.id,
                name: template.name,
                version: template.version,
              }}
              onClose={() => {
                setToggleModalName('');
              }}
            />
          )}
          {toggleModalName === 'modalFunction' && (
            <>
              <ModalFunctionViewer
                rows={template.functions || []}
                template={{
                  name: template.name,
                  version: template.version,
                }}
                onClose={() => {
                  setToggleModalName('');
                }}
              />
            </>
          )}
        </div>
        <Dialog
          open={toggleModalName === 'modalConfirm'}
          onClose={handleConfirmClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirm switch template</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to import NEW rules and functions ?
              <br />
              <b>Warning : Existing rules and functions will be deleted</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleConfirmOK} color="primary" autoFocus>
              OK switch to new template
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </>
  );
}
export default IverifierTemplateConfig;
