import React from 'react';
import { Card, CardContent, Grid, Paper, Typography, Box } from '@material-ui/core';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Iframe from 'react-iframe';
import AutoFitImage from 'react-image-autofit-frame';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { download_datasets } from '../../../api/datasets';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  searchbtn: {
    // border: `1px solid ${theme.palette.primary.light}`,
    // borderRadius: 5,
    marginLeft: -40,
    // paddingBottom: 15,
    borderRadius: 35,
    marginTop: 10,
    zIndex: 1,
    backgroundColor: '#FFF',
  },
  searchbtnStyle: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderRadius: '35px !important',
      borderColor: '#002a45',
      backgroundColor: '#FFF',
    },
    '& .MuiInputLabel-outlined': {
      color: '#002a45',
    },
  },
  paper: {
    padding: theme.spacing(2),
    // border: `1px solid ${theme.palette.primary.light}`,
    border: '1px solid',
    borderColor: '#002A45',
    borderRadius: 5,
    // color: theme.palette.text.secondary,
    fontSize: '14px',
    backgroundColor: '',
  },
  paper1: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 5,
    // color: theme.palette.text.secondary,
    fontSize: '14px',
    backgroundColor: '#F1F3F4',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  root2: {
    // minWidth: 275,
    backgroundColor: '#9FDBC2',
    marginBottom: 15,
    // opacity: 0.4,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 15,
  },
  pos: {
    marginBottom: 12,
  },
}));

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
}));

function AskQuestion({ askQuesRes, urlSearch, apiData, searchKey }) {
  const classes = useStyles();
  return (
    <>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        {askQuesRes?.concept && (
          <Box style={{ fontSize: '14px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Box style={{ border: '1px solid #002A45' }}>
              <div>
                {askQuesRes?.noMatchingAnswer === true ? (
                  <Card className={classes.root2}>
                    <CardContent style={{ padding: '10px 150px' }}>
                      <Typography className={classes.title} gutterBottom>
                        <b style={{ color: 'rgba(8, 85, 53, 1' }}>
                          Looks not very great matches for your query, but we have some relevant
                          information which may be useful to you...
                        </b>
                        <br />{' '}
                        <span style={{ color: 'rgba(8, 85, 53, 0.6' }}>
                          If you have some relevant information/documents to contribute to our
                          database expansion, then kindly contact iSenS administrator.
                        </span>
                      </Typography>
                    </CardContent>
                  </Card>
                ) : null}
                {(askQuesRes?.concept?.acronymResults?.display ||
                  askQuesRes?.concept?.definitionResults?.display) && (
                  <Box style={{ fontWeight: 'normal', padding: '0.5rem' }}>
                    {askQuesRes.concept?.displayHeading && (
                      <>
                        <h2 style={{ color: '#002A45', margin: '0.5rem' }}>
                          {askQuesRes.concept?.displayHeading}
                        </h2>
                        <hr />
                      </>
                    )}
                    {askQuesRes?.concept?.acronymResults?.display && (
                      <p style={{ fontSize: '16px' }}>
                        <ul>
                          {askQuesRes?.concept?.acronymResults?.display?.map(
                            (item: { expansion: React.ReactNode; source: React.ReactNode }) => (
                              <p>
                                <li>
                                  <span>{item?.expansion}</span>
                                  <span>
                                    <b style={{ color: '#002A45' }}>&nbsp;Source:&nbsp;</b>{' '}
                                    {item.source}
                                  </span>
                                </li>
                              </p>
                            )
                          )}
                        </ul>
                        {askQuesRes?.concept?.acronymResults?.displayAlternateForms?.length > 0 && (
                          <span
                            style={{
                              color: '#002A45',
                              marginLeft: 25,
                              fontSize: '16px',
                            }}
                          >
                            <b style={{ fontWeight: 'bold' }}>
                              {askQuesRes?.concept?.acronymResults?.displayAlternateForms?.length >
                              0
                                ? 'Also Means'
                                : ''}{' '}
                            </b>
                          </span>
                        )}
                        <ul>
                          {askQuesRes?.concept?.acronymResults?.displayAlternateForms?.map(
                            (item: any) => (
                              <p>
                                <li>
                                  <span>
                                    {item?.hyperlink !== '' && (
                                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                      <a
                                        href="#"
                                        onClick={() => {
                                          urlSearch(String(item.expansion));
                                        }}
                                      >
                                        {item?.expansion}
                                      </a>
                                    )}
                                  </span>
                                  <span>
                                    <b style={{ color: '#002A45' }}>&nbsp;Source:&nbsp;</b>{' '}
                                    {item?.source}
                                  </span>
                                </li>
                              </p>
                            )
                          )}
                        </ul>
                      </p>
                    )}
                    {askQuesRes?.concept?.definitionResults?.display && (
                      <p style={{ fontSize: '16px' }}>
                        {askQuesRes?.concept?.definitionResults?.display && (
                          <p style={{ fontSize: '16px' }}>
                            <ul>
                              {askQuesRes?.concept?.definitionResults?.display?.map(
                                (item: {
                                  definition: React.ReactNode;
                                  source: React.ReactNode;
                                }) => (
                                  <p>
                                    <li>
                                      <span>{item?.definition}</span>
                                    </li>
                                  </p>
                                )
                              )}
                            </ul>
                          </p>
                        )}
                      </p>
                    )}
                  </Box>
                )}
              </div>
            </Box>
            {askQuesRes?.imageResults && (
              <Box
                style={{
                  padding: '0.5rem',
                  backgroundColor: '#EEF1F9',
                  border: '1px solid #002A45',
                }}
              >
                <Carousel
                  swipeable={true}
                  showDots={true}
                  responsive={responsive}
                  infinite={true}
                  arrows={true}
                  autoPlaySpeed={500}
                  keyBoardControl={true}
                  renderArrowsWhenDisabled={true}
                >
                  {askQuesRes.imageResults.map((image) => (
                    <div key={image.ImageTitle}>
                      <center>
                        <AutoFitImage
                          frameWidth="600px"
                          frameHeight="400px"
                          imgSize="contain"
                          imgSrc={download_datasets('qna-images', image.ImageLocation)}
                        />
                      </center>
                      <p className="legend">
                        &nbsp;{' '}
                        <span style={{ color: 'black' }}>
                          <b>
                            Title: {image.ImageTitle} (source: {image.ImageSource})
                          </b>
                        </span>
                      </p>
                    </div>
                  ))}
                </Carousel>
              </Box>
            )}
            {askQuesRes?.answer.length > 0 || askQuesRes?.peopleAlsoAskFor.length > 0 ? (
              <Box style={{ border: '1px solid #002A45' }}>
                <Box style={{ fontWeight: 'normal', padding: '0.5rem' }}>
                  {askQuesRes?.answer.length > 0 ? (
                    <>
                      <h2 style={{ color: '#002A45', margin: '0.5rem' }}>Search results</h2>
                      <hr />
                    </>
                  ) : (
                    ''
                  )}

                  {askQuesRes?.answer.map((ans) => (
                    <div style={{ fontSize: '16px' }}>
                      <p style={{ marginLeft: '20px' }}>
                        {
                          // eslint-disable-next-line react/no-unescaped-entities
                          ans['Answer: '] ? <li>{ans['Answer: ']}</li> : ''
                        }
                      </p>
                      {ans?.full_context && (
                        <>
                          <p>
                            <ul>
                              <li>
                                <span>{ans.full_context.substr(0, ans.highlight_start)}</span>
                                <span>
                                  <b
                                    style={{
                                      fontWeight: 'normal',
                                      backgroundColor: 'yellow',
                                    }}
                                  >
                                    {ans.full_context.substr(
                                      ans.highlight_start,
                                      ans.highlight_end - ans.highlight_start
                                    )}
                                  </b>
                                </span>
                                <span>
                                  {ans.full_context.substr(
                                    ans.highlight_end,
                                    ans.full_context.length
                                  )}
                                </span>
                              </li>
                            </ul>
                          </p>
                        </>
                      )}
                    </div>
                  ))}
                  <div>
                    {
                      // eslint-disable-next-line react/jsx-curly-brace-presence
                      askQuesRes?.peopleAlsoAskFor.length > 0 && (
                        <h2 style={{ color: '#002A45', margin: 0, paddingBottom: '0.5rem' }}>
                          People also ask for
                        </h2>
                      )
                    }

                    <div>
                      {askQuesRes?.peopleAlsoAskFor.map((ans, index) => (
                        <Accordion
                          style={
                            index !== askQuesRes.peopleAlsoAskFor.length - 1
                              ? { borderBottom: '1px solid #002A45' }
                              : {}
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography style={{ fontSize: '16px' }} className={classes.heading}>
                              {
                                // eslint-disable-next-line dot-notation
                                ans['RecommendQues']
                              }
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography style={{ fontSize: '16px', color: '#002A45' }}>
                              {
                                // eslint-disable-next-line dot-notation
                                ans['Answer: ']
                              }
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </div>
                  </div>
                </Box>
              </Box>
            ) : (
              ''
            )}
            {askQuesRes?.concept?.displayOntology ? (
              <Box>
                <Iframe
                  url={apiData}
                  id="myId"
                  className="myClassname"
                  overflow="hidden"
                  scrolling="no"
                  display="initial"
                  position="relative"
                  width="100%"
                  height="700px"
                />
              </Box>
            ) : (
              ''
            )}
          </Box>
        )}
      </Box>
    </>
  );
}

export default AskQuestion;
