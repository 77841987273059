import { FormLabel, Grid, Input, Box } from '@material-ui/core';
import React, { useContext } from 'react';
import RunContext from '../../RunContext';

function StepFinal() {
  const { config, setConfig } = useContext(RunContext);

  const handleChangeSeparator = (e: React.ChangeEvent<any>) => {
    setConfig({ ...config, file_separator: e.currentTarget.value });
  };
  return (
    <>
      <Box style={{ border: '2px solid grey', borderRadius: '5px' }}>
        <Grid
          style={{
            marginTop: '20px',
            marginBottom: '20px',
          }}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <b>Click on &quot;Save&quot; Button to save the config only</b>
          </Grid>
          <Grid item>
            <b>Click on &quot;Save and Run &quot; Button to save and run the config</b>
          </Grid>
        </Grid>
        {/* <Grid
        style={{
          marginTop: '20px',
          marginBottom: '20px',
        }}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <FormLabel>Separator</FormLabel>
        </Grid>
        <Grid item>
          <Input value={config.file_separator} onChange={handleChangeSeparator} />
        </Grid>
        <Grid item>
          <br />
        </Grid>
      </Grid> */}
      </Box>
    </>
  );
}

export default StepFinal;
