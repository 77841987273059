import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ProjectState = Project[];

const initialState: ProjectState = [];

const ProjectSlice = createSlice({
  initialState,
  name: 'projects',
  reducers: {
    create: (state, action: PayloadAction<Project>) => {
      state.push(action.payload);
    },
    remove: (state, action: PayloadAction<Project>) => {
      return state.filter((p: Project) => p.id !== action.payload.id);
    },
    // TODO modify project
    // update: () => { },
  },
});

export const { create, remove } = ProjectSlice.actions;

export default ProjectSlice.reducer;
