import { Column } from '@devexpress/dx-react-grid';
import React from 'react';
import { columnFuncType } from './allColumns';

const commonColumnsAdded = [
  {
    name: 'System Entities',
    title: 'System Entities',
  },
  {
    name: 'Activities',
    title: 'Activities',
  },
  {
    name: 'Acronyms',
    title: 'Acronyms',
  },
  {
    name: 'Cybersecurity',
    title: 'Cyber Security',
  },
  {
    name: 'Standards',
    title: 'Standards',
  },
  {
    name: 'project_management',
    title: 'Project Management',
  },
  {
    name: 'Commercial',
    title: 'Commercial',
  },
  {
    name: 'Finance',
    title: 'Finance',
  },
  {
    name: 'Contractual',
    title: 'Contractual',
  },
  {
    name: 'Context',
    title: 'Context',
  },
  // {
  //   name: 'History',
  //   title: 'History',
  // },
  // {
  //   name: 'kpi_lifecycle',
  //   title: 'Lifecycles',
  // },
  {
    name: 'assigment',
    title: 'Multi assigment',
  },
  {
    name: 'safety',
    title: 'Safety',
  },
  {
    name: 'category',
    title: 'Category',
  },
  {
    name: 'testable',
    title: 'Testable',
  },
  {
    name: 'contribution',
    title: 'Contribution',
  },
  {
    name: 'type',
    title: 'Type',
  },

  // Critical To Customer
  {
    name: 'Critical To Customer',
    title: 'Critical To Customer',
  },
  {
    name: 'Stability',
    title: 'Stability',
  },
  {
    name: 'Validation Method',
    title: 'Validation Method',
  },
  {
    name: 'Source',
    title: 'Source',
  },
  {
    name: 'Rationale',
    title: 'Rationale',
  },
  {
    name: 'Validation Evidence',
    title: 'Validation Evidence',
  },
  {
    name: 'Char_Score',
    title: 'Char Score',
  },
  {
    name: 'comment',
    title: 'Comment',
  },
];

export const columnsAnalyze: columnFuncType = (optionColumns, isExported = false) => {
  const { targetColumn, displayColumns, isRegExp, entity, levelsHierarchy } = optionColumns;
  const commonExportColumns = [
    {
      name: 'pbs',
      title: entity === 'RSC' ? 'SBS' : 'PBS',
    },
    {
      name: 'kpi_lifecycle',
      title: 'Lifecycles',
    },
    {
      name: 'lifecycle',
      title: 'Lifecycle Multi',
    },
    {
      name: 'kpi_compliance',
      title: 'Compliance',
    },
    {
      name: 'compliance',
      title: 'Compliance Multi',
    },
    {
      name: 'nc',
      title: 'NC',
    },
    {
      name: 'nc_rationale',
      title: 'NC rationale',
    },
    {
      name: 'gap',
      title: 'Gap',
    },
    {
      name: 'gap_rationale',
      title: 'Gap rationale',
    },
    {
      name: 'rationale',
      title: 'Rationale',
    },
  ];
  const commonColumns = [
    {
      name: 'History',
      title: <>History</>,
    },
    {
      name: 'kpi_lifecycle',
      title: 'Lifecycles',
    },
    {
      name: 'kpi_compliance',
      title: 'Compliance',
    },
    {
      name: 'analyze',
      title: 'Analyze Multi',
    },
  ];

  const regExpCol = [];
  if (isRegExp) {
    regExpCol.push('ReqID');
  }

  // export into EXCEL
  if (isExported) {
    if (targetColumn) {
      return [
        'id',
        ...Array.from(new Set([targetColumn, ...displayColumns])),
        'Characterization',
        'Original_Hierarchy',
        ...levelsHierarchy,
        ...commonExportColumns,
        ...commonColumnsAdded,
      ];
    }
    return [
      'id',
      ...regExpCol,
      'Text',
      'Characterization',
      'Original_Hierarchy',
      ...levelsHierarchy,
      ...commonExportColumns,
      ...commonColumnsAdded,
    ];
  }

  if (targetColumn) {
    return [
      // 'Menu',
      {
        name: 'id',
        title: 'iSenS_ID',
      },
      ...Array.from(new Set([targetColumn, ...displayColumns])),
      'Characterization',
      'Original_Hierarchy',
      ...levelsHierarchy,
      ...commonColumns,
      ...commonColumnsAdded,
    ];
  }
  // default PDF
  return [
    // 'Menu',
    {
      name: 'id',
      title: 'iSenS_ID',
    },
    ...regExpCol,
    'Text',
    'SubType',
    'Characterization',
    'PageNo',
    'Hierarchy',
    'Original_Hierarchy',
    ...levelsHierarchy,
    ...commonColumns,
    ...commonColumnsAdded,
  ];
};

export const columnsAnalyzeMulti: columnFuncType = (optionColumns): (Column | string)[] => {
  const { entity } = optionColumns;

  return [
    {
      name: 'selectionCustom',
      title: 'Select',
    },
    {
      name: 'pbs',
      title: entity === 'RSC' ? 'SBS' : 'PBS',
    },
    {
      name: 'lifecycle',
      title: 'Lifecycle Multi',
    },

    {
      name: 'compliance',
      title: 'Compliance Multi',
    },
    {
      name: 'nc',
      title: 'NC',
    },
    {
      name: 'gap',
      title: 'Gap',
    },
    {
      name: 'rationale',
      title: 'Rationale',
    },
  ];
};
