import React, { useEffect, useState } from 'react';
import { createIntl, createIntlCache, IntlProvider } from 'react-intl';
import localeEn from './en.json';
import localeFr from './fr.json';

// beware this is an async Func return object not JSON
const loadMessages = (locale: string): Promise<Object> => {
  switch (locale) {
    case 'fr':
      return import('./fr.json');
    default:
      return import('./en.json');
  }
};
/**
 * Locale priority
 * 0/ env var for init
 * 1/ querystring
 * 2/ localstorage
 */
const getLocale = (): string => {
  const locale = process.env.REACT_APP_LOCALE || 'en';

  // querystring
  const searchParams = new URLSearchParams(window.location.search.replace('?', ''));
  const lang = searchParams.get('lang');

  if (['fr', 'en'].includes(lang as string)) {
    window.localStorage.setItem('locale', String(lang));
    return lang as string;
  }

  // localstorage
  if (window.localStorage.getItem('locale') === null) {
    window.localStorage.setItem('locale', String(locale));
  }
  return window.localStorage.getItem('locale') || locale;
};

const locale = getLocale();

const localeMsg = locale === 'en' ? localeEn : localeFr;

const cache = createIntlCache();

export const intl = createIntl(
  {
    locale,
    messages: localeMsg,
    defaultLocale: 'en',
  },
  cache
);

function withIntl<T extends typeof IntlProvider>(Component: any): any {
  return (props: T): React.ReactElement | null => {
    const [messages, setMessages] = useState(undefined);
    useEffect(() => {
      loadMessages(locale).then((payload: any) => {
        setMessages(payload);
      });
    }, []);
    if (messages === undefined) return null;
    return (
      <IntlProvider messages={messages} locale={locale} defaultLocale="en">
        <Component {...props} />
      </IntlProvider>
    );
  };
}

export default withIntl;
