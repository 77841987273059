const allocationKeys = (allocations) => {
  const keyParisValue = {};
  try {
    for (const item of allocations) {
      keyParisValue[item.id] = item;
    }
  } catch (error) {
    console.log('error', error);
  }

  return keyParisValue;
};

const convertToPairs = (responseArr) => {
  const keyParisValue = {};
  try {
    for (const item of responseArr) {
      keyParisValue[item.row_id] = { ...item };
    }
  } catch (error) {
    console.log('error', error);
  }

  return keyParisValue;
};

export { allocationKeys };

export default convertToPairs;
