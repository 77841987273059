import { AllocationsReceiverExtraction } from './allocationExtraction';
import { allocationKeys } from './convertToPairs';
import { generateUniqueId } from './extractdata';

const extractDisplayColumns = (displayColumns, resonpose, textColum) => {
  try {
    if (displayColumns) {
      const newObjectForExtraColumns = {};
      displayColumns
        .filter((data) => data !== textColum)
        .map((item, index) => {
          newObjectForExtraColumns[`Column_${index + 1}`] = resonpose[item];
          return item;
        });

      return newObjectForExtraColumns;
    }
    return {};
  } catch (error) {
    console.log('Error in extractDisplayColumns', error);
    return {};
  }
};

function extractionTextCode(
  response: any[],
  targetColumn: string,
  OriginalLang: string | number,
  transCode: string,
  groups = [],
  users = [],
  service = [],
  displayColumns: any
) {
  let tempHerarchy = '';
  const responseArr = response.map(
    (row: {
      row_capture: { [x: string]: any };
      kpi_lifecycle: any;
      kpi_compliance: any;
      analysis: any;
      allocations: any;
      lifecycles: any;
      updated_at: any;
      id: any;
      Text: string;
    }) => {
      const { i18n } = row?.row_capture || {};
      const textColum = targetColumn ?? 'Text';
      tempHerarchy = row.row_capture?.Original_Hierarchy
        ? row.row_capture?.Original_Hierarchy
        : tempHerarchy;
      if ('i18n' in row.row_capture && Object?.keys(i18n)?.length > 1) {
        // if (row.row_capture[targetColumn]) {
        return {
          ...row.row_capture,
          [textColum]: '',
          extractedText: transCode === '-' ? i18n[OriginalLang] : i18n[transCode],
          // [targetColumn]: transCode === '-' ? i18n[OriginalLang] : i18n[transCode],
          // Coordinates: '',
          // Allocation: '',
          copyHerarchy: tempHerarchy,
          ...extractDisplayColumns(displayColumns, row.row_capture, textColum),
          kpi_lifecycle: row.kpi_lifecycle,
          kpi_compliance: row.kpi_compliance,
          analysis: row?.analysis ?? [],
          allocations: AllocationsReceiverExtraction(row.allocations, groups, users),
          lifecycles: row.lifecycles,
          updated_at: row.updated_at,
          id: row.id,
          row_id: generateUniqueId(),
          // Validation_Method: '',
          // Source: '',
          // Rationale: '',
          // Validation_Evidence: '',
          // comment: '',
        };
        // }
        // return {
        //   ...row.row_capture,
        //   [textColum]: '',
        //   extractedText: transCode === '-' ? i18n[OriginalLang] : i18n[transCode],
        //   // Text: transCode === '-' ? i18n[OriginalLang] : i18n[transCode],
        //   // Coordinates: '',
        //   // Allocation: '',
        //   ...extractDisplayColumns(displayColumns, row.row_capture, textColum),
        //   kpi_lifecycle: row.kpi_lifecycle,
        //   kpi_compliance: row.kpi_compliance,
        //   analysis: row?.analysis ?? [],
        //   allocations: AllocationsReceiverExtraction(row.allocations, groups, users),
        //   lifecycles: row.lifecycles,
        //   updated_at: row.updated_at,
        //   id: row.id,
        //   row_id: generateUniqueId(),
        //   // Validation_Method: '',
        //   // Source: '',
        //   // Rationale: '',
        //   // Validation_Evidence: '',
        //   // comment: '',
        // };
      }
      return {
        ...row.row_capture,
        // Coordinates: '',
        // Allocation: '',
        extractedText: row.row_capture[textColum],
        Text: '',
        ...extractDisplayColumns(displayColumns, row.row_capture, textColum),
        kpi_lifecycle: row.kpi_lifecycle,
        kpi_compliance: row.kpi_compliance,
        analysis: row?.analysis ?? [],
        allocations: AllocationsReceiverExtraction(row.allocations, groups, users),
        lifecycles: row.lifecycles,
        updated_at: row.updated_at,
        id: row.id,
        row_id: generateUniqueId(),
        // Validation_Method: '',
        // Source: '',
        // Rationale: '',
        // Validation_Evidence: '',
        // comment: '',
      };
    }
  );

  return responseArr;
}

export default extractionTextCode;
