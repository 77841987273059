// eslint-disable-next-line import/no-duplicates
import {
  Button,
  CardContent,
  IconButton,
  Paper,
  Box,
  Grid,
  Typography,
  Card,
  Dialog,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PublicIcon from '@mui/icons-material/Public';
// import { Box, Grid, Typography } from '@mui/material';
// import Card from '@mui/material/Card';
// import CardActionArea from '@mui/material/CardActionArea';
// import CardActions from '@mui/material/CardActions';
// import CardMedia from '@mui/material/CardMedia';
// eslint-disable-next-line import/no-duplicates
// import { Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import React, { Props } from 'react';
import aboutImage from '../../../assets/user_info_bg.png';

export const useStyles = makeStyles((theme) => ({
  resultIcon: {
    color: '#002A45',
    transform: 'scale(1.5)',
    opacity: 1,
    fontWeight: 'bold',
    marginRight: 4,
    marginLeft: 4,
  },
  root: {
    // margin: 0,
    padding: 0,
    '&.MuiDialogContent-root': {
      padding: 0,
    },
  },
  rootChildDivider: {
    padding: '0px !important',
    borderTop: 'none !important',
    borderBottom: 'none !important',
  },
  closeButton: {
    // top: 30px;
    // color: #002A45;
    // right: 29px;
    // position: absolute;
    // border: 2px white solid;
    // padding: 0px;
    // background: white;
    position: 'absolute',
    right: 0,
    top: 0,
    color: '#002A45',
    border: '2px white solid',
    padding: 0,
    background: 'white',
    // '&.MuiIconButton-root:hover': {
    //   background: 'none',
    // },
    '&:hover': {
      // backgroundColor: 'red', // Background color on hover
      // color: 'white', // Text color on hover
      position: 'absolute',
      right: 0,
      top: 0,
      color: '#002A45',
      border: '2px white solid',
      padding: 0,
      background: 'white',
    },
  },
  heading: {
    backgroundImage: `url(${aboutImage})`,
    // backgroundSize: '50% auto',
    height: 200,
    border: `1px solid ${theme.palette.primary.main}`,
    // borderRadius: 8,
    // // textAlign: 'center',
    // marginBottom: 10,
    // color: 'white',
    backgroundSize: '25% auto', // Ensure the image covers the entire Card
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
    backgroundPosition: 'right',
  },
  iconButtonInner: {
    color: 'white',
    fontSize: '50px !important',
  },
  circularIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px', // Adjust the width and height as needed to make it circular
    height: '100px', // Make sure width and height are the same for a circle
    borderRadius: '50%', // Sets the border radius to 50% to make it circular
    backgroundColor: '#DC3223', // Change the background color as needed
  },
  typo1: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 20,
  },
  typo2: {
    fontWeight: 'bold',
    color: '#00C5FF',
    fontSize: 17,
  },
  typo3: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 15,
  },
  container: {
    marginTop: 10,
    display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // height: '100vh', // Set the height to the viewport height for centering vertically
  },
  paper: {
    display: 'flex',
    // background: '#DC3223',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: theme.spacing(1),
    // border: '1px solid #ccc', // Border styling
    // boxShadow: '0px 3px 3px white', // Box shadow styling
  },
}));

const UserProfile = ({ open, onConfirm, onCancel, label }: Props) => {
  const classes = useStyles();
  const token = JSON.parse(localStorage.getItem('azJWT') || '{}');
  return (
    <>
      <Dialog
        onClose={onCancel}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth={true}
        className={classes.root}
      >
        <IconButton aria-label="close" className={classes.closeButton} onClick={onCancel}>
          <CancelOutlinedIcon />
        </IconButton>
        {/* <DialogTitle id="customized-dialog-title">
          <Typography variant="h6">.</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </DialogTitle> */}
        <DialogContent dividers className={classes.rootChildDivider}>
          {/* <Typography variant="subtitle1" className={classes.heading}>
            HI
          </Typography> */}
          <Card>
            <div className={classes.heading}>
              <CardContent
                style={{
                  // borderRadius: '11px 0px 135px 0px',
                  // borderTopRightRadius: '50%',
                  background: 'rgba(0, 42, 69, 0.9) 0% 0% no-repeat padding-box',
                  height: 150,
                  // textAlign: 'center',
                }}
              >
                <Grid
                  container
                  spacing={0}
                  style={{ marginTop: 10 }}
                  justifyItems="center"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <div className={classes.circularIcon}>
                      <PersonOutlineOutlinedIcon className={classes.iconButtonInner} />
                    </div>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography className={classes.typo1}>
                      {token?.account?.idTokenClaims.name}
                    </Typography>
                    <Typography className={classes.typo2}>
                      {token?.account?.idTokenClaims.jobTitle}
                    </Typography>
                    <Typography className={classes.typo3}>
                      {token?.account?.idTokenClaims.preferred_username}
                    </Typography>
                    <Typography
                      // className={classes.typo3}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {/* <PublicIcon className={classes.resultIcon} /> */}
                        <Typography
                          variant="h6"
                          style={{
                            color: 'white',
                            opacity: 1,
                            fontWeight: 'bold',
                            fontSize: 15,
                            // font: 'normal normal medium 25px/33px Alstom',
                            // marginTop: 5,
                          }}
                        >
                          Site Code :
                        </Typography>
                      </div>
                      <Typography
                        // variant="h6"
                        style={{
                          color: '#DC3223',
                          opacity: 1,
                          fontWeight: 'bold',
                          marginLeft: 2,
                          fontSize: 17,
                          wordWrap: 'break-word',
                          // font: 'normal normal medium 25px/33px Alstom',
                        }}
                      >
                        {token?.account?.idTokenClaims['site code']}
                      </Typography>
                    </Typography>
                    {/* <Grid container className={classes.container}>
                      <Grid item>
                        <Paper className={classes.paper}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <PublicIcon className={classes.resultIcon} />
                            <Typography
                              variant="h6"
                              style={{
                                color: '#002A45',
                                opacity: 1,
                                fontWeight: 'bold',
                                fontSize: 20,
                                // font: 'normal normal medium 25px/33px Alstom',
                                // marginTop: 5,
                              }}
                            >
                              Site Code :
                            </Typography>
                          </div>
                          <Typography
                            // variant="h6"
                            style={{
                              color: 'white',
                              opacity: 1,
                              fontWeight: 'bold',
                              fontSize: 17,
                              wordWrap: 'break-word',
                              // font: 'normal normal medium 25px/33px Alstom',
                            }}
                          >
                            {token?.account.idTokenClaims['site code']}
                          </Typography>
                        </Paper>
                      </Grid>
                    </Grid> */}
                  </Grid>
                </Grid>
              </CardContent>
              <Grid
                container
                spacing={0}
                // style={{ marginTop: 10 }}
                justifyItems="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  {/* <Typography className={classes.typo1}>
                    {token?.account.idTokenClaims.name}
                  </Typography>
                  <Typography className={classes.typo2}>
                    {token?.account.idTokenClaims.jobTitle}
                  </Typography>
                  <Typography className={classes.typo3}>
                    {token?.account.idTokenClaims.preferred_username}
                  </Typography> */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      // justifyContent: 'center',
                      alignItems: 'center',
                      padding: 10,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <GroupsOutlinedIcon className={classes.resultIcon} />
                      <Typography
                        variant="h6"
                        style={{
                          color: '#002A45',
                          opacity: 1,
                          fontWeight: 'bold',
                          fontSize: 20,
                          marginRight: 5,
                          marginLeft: 5,
                          // font: 'normal normal medium 25px/33px Alstom',
                          // marginTop: 5,
                        }}
                      >
                        Department :
                      </Typography>
                    </div>
                    <Typography
                      // variant="h6"
                      style={{
                        color: '#DC3223',
                        opacity: 1,
                        fontWeight: 'bold',
                        fontSize: 17,
                        wordWrap: 'break-word',
                        // font: 'normal normal medium 25px/33px Alstom',
                      }}
                    >
                      {token?.account?.idTokenClaims.Department}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={onCancel} color="default" variant="contained">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="secondary" variant="contained">
            Confirm
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

export default UserProfile;
