import { DiscussionType, DiscussionTypeUpdate } from './chat';

export type gptEngineType = 'public' | 'private' | 'rex';

export type reducerStateType = {
  errorMsg?: string;
  loadingPrompt?: boolean;
  scrollBottom?: boolean;
  gptEngine?: gptEngineType;
  discussions: DiscussionType[];
  discussionEditId?: number;
  discussionId?: number; // dicussion for dashboard
  discussion?: DiscussionType; // dicussion for dashboard
  discussionHasChanged?: boolean; // dicussion on delete questions
  discussionAutoSave?: boolean; // dicussion on delete questions
  discussionAutoSaveFeedback?: boolean; // dicussion on delete + feedback questions
  discussionFeedback?: any; // dicussion on delete + feedback questions
  abortList?: AbortController[];
  initPrompt?: string;
};
export type reducerActionType =
  | { type: 'INIT'; payload: DiscussionType[] }
  | { type: 'SCROLL'; payload: DiscussionType[] }
  | { type: 'DELETE_DISCUSS_QUESTION'; payload: { discuss_id: number; row_number: number } }
  | {
      type: 'FEEDBACK_DISCUSS_QUESTION';
      payload: {
        discuss_id: number;
        row_number?: number;
        response_id?: string; // from uuid
        feedback: undefined | 'like' | 'dislike';
        callback?: any;
      };
    }
  | {
      type: 'SELECT_DISCUSS';
      payload: { id: number; action: gptEngineType };
    }
  | { type: 'EDIT_DELETE_DISCUSS'; payload: number }
  | { type: 'EDIT_UPDATE'; payload: DiscussionTypeUpdate }
  | { type: 'EDIT_CLICK'; payload: number }
  | { type: 'LOADING_PROMPT'; payload: boolean }
  | { type: 'DISCUSSION_INIT_STATE'; payload: DiscussionType }
  | { type: 'DISCUSSION_START_NEW' }
  | { type: 'PROMPT_START'; payload: string }
  | { type: 'STREAM_REPLY'; payload: string }
  | { type: 'STREAM_REPLY_UUID'; payload: any }
  | { type: 'STREAM_REPLY_WITH_DATA'; payload: any }
  | { type: 'SUBMIT_PROMPT'; payload: DiscussionType }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'ABORTCONTROLLER_ADD'; payload: AbortController }
  | { type: 'ABORTCONTROLLER_ABORT'; payload: undefined }
  | { type: 'SWITCH_ENGINE_GPT'; payload: gptEngineType };

export type dispatchType = (action: reducerActionType) => any;

export const initDisccussionState = {
  discussions: [],
  gptEngine: 'private',
  discussionHasChanged: false,
  loadingPrompt: false,
  abortList: [],
} as reducerStateType;
const reducerDiscussions = (
  state: reducerStateType,
  action: reducerActionType
): reducerStateType => {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        discussions: action.payload,
      };
    case 'SCROLL':
      return {
        ...state,
        discussions: state.discussions.concat(action.payload),
      };
    case 'DELETE_DISCUSS_QUESTION': {
      const { discussion: my_discuss } = state;
      if (!my_discuss) {
        return state;
      }
      const new_cache = my_discuss.cache.filter((d, k) => {
        return k !== action.payload.row_number && k !== action.payload.row_number + 1;
      });

      const new_cache_metadata = my_discuss.cache_metadata?.filter((d) => {
        return (
          d.row_number !== action.payload.row_number &&
          d.row_number !== action.payload.row_number + 1
        );
      });
      // console.log(action, new_discussion_cache);
      const new_cache_metadata_2 = new_cache_metadata?.map((m) => {
        if (m.row_number > action.payload.row_number) {
          // eslint-disable-next-line no-param-reassign
          m.row_number -= 2;
          return m;
        }
        return m;
      });

      return {
        ...state,
        discussionHasChanged: true,
        discussions: state.discussions.map((d) => {
          if (d.id === action.payload.discuss_id)
            return {
              ...d,
              cache: new_cache,
              cache_metadata: new_cache_metadata_2,
            };
          return d;
        }),
        discussion: {
          ...my_discuss,
          cache: new_cache,
          cache_metadata: new_cache_metadata_2,
        },
      };
    }
    case 'FEEDBACK_DISCUSS_QUESTION': {
      const { discussion: my_discuss } = state;
      const { feedback, response_id } = action.payload;
      if (!my_discuss) {
        return state;
      }

      // update cache
      const my_discuss_cache_new = my_discuss.cache.map((d) => {
        if (d.id === response_id) {
          return { ...d, feedback };
        }
        return d;
      });

      return {
        ...state,
        discussionAutoSaveFeedback: true, // auto save ON
        discussionFeedback: action.payload,
        scrollBottom: false,
        discussions: state.discussions.map((d) => {
          if (d.id === action.payload.discuss_id) return { ...d, cache: my_discuss_cache_new };
          return d;
        }),
        discussion: { ...my_discuss, cache: my_discuss_cache_new },
      };
    }
    // case 'FEEDBACK_DISCUSS_QUESTION_v1': {
    //   const { discussion: my_discuss } = state;
    //   const { feedback, row_number } = action.payload;
    //   if (!my_discuss) {
    //     return state;
    //   }

    //   if (!my_discuss.cache_metadata) {
    //     my_discuss.cache_metadata = [];
    //   }

    //   // update cache
    //   const my_discuss_cache_new = my_discuss.cache.map((d, k) => {
    //     if (k === row_number) {
    //       return { ...d, feedback };
    //     }
    //     return d;
    //   });

    //   // find existing metatdata
    //   const my_metadata = my_discuss.cache_metadata.find(
    //     (m) => m.row_number === action.payload.row_number
    //   );

    //   let new_metadata = [] as any[];

    //   if (!my_metadata) {
    //     my_discuss.cache_metadata.push({
    //       row_number,
    //       feedback,
    //     });
    //     new_metadata = my_discuss.cache_metadata;
    //   } else {
    //     new_metadata = my_discuss.cache_metadata.map((m) => {
    //       if (m.row_number === action.payload.row_number) {
    //         return { ...m, feedback };
    //       }
    //       return m;
    //     });
    //   }

    //   return {
    //     ...state,
    //     discussionAutoSaveFeedback: true, // auto save ON
    //     scrollBottom: false,
    //     discussions: state.discussions.map((d) => {
    //       if (d.id === action.payload.discuss_id)
    //         return { ...d, cache: my_discuss_cache_new, cache_metadata: new_metadata };
    //       return d;
    //     }),
    //     discussion: { ...my_discuss, cache: my_discuss_cache_new, cache_metadata: new_metadata },
    //   };
    // }
    case 'SELECT_DISCUSS':
      if (state.discussionId === action.payload.id) return state;
      return {
        ...state,
        loadingPrompt: false,
        scrollBottom: true,
        discussionHasChanged: false,
        discussionId: action.payload.id,
        gptEngine: action.payload.action,
        discussion: state.discussions.find((d) => Number(d.id) === Number(action.payload.id)),
      };
    case 'EDIT_DELETE_DISCUSS':
      return {
        ...state,
        // check if we display a deleted discussion then new prompt
        discussionId: state.discussionId === action.payload ? undefined : state.discussionId,
        discussion: state.discussionId === action.payload ? undefined : state.discussion,
        discussions: state.discussions.filter((d) => Number(d.id) !== Number(action.payload)),
      };

    case 'EDIT_UPDATE':
      return {
        ...state,
        discussionHasChanged: false,
        discussionAutoSave: false,
        discussionAutoSaveFeedback: false,
        // check if we display a deleted discussion then new prompt
        discussions: state.discussions.map((d) => {
          if (d.id === action.payload.id) return { ...d, ...action.payload };
          return d;
        }),
      };
    case 'EDIT_CLICK':
      return {
        ...state,
        // check if we display a deleted discussion then new prompt
        discussionEditId: action.payload,
      };
    case 'LOADING_PROMPT':
      return {
        ...state,
        loadingPrompt: action.payload,
        scrollBottom: false,
      };
    // render User prompt during request
    case 'PROMPT_START': {
      // console.log(action.payload);
      let currentDiscussion = state.discussion;
      if (currentDiscussion) {
        const lastMessage = currentDiscussion.cache[currentDiscussion.cache.length - 1];
        if (lastMessage) {
          currentDiscussion.cache.push(
            {
              role: 'user',
              content: action.payload,
            },
            {
              role: 'assistant',
              content: '',
            }
          );
        }
      } else {
        currentDiscussion = {
          id: undefined,
          cache: [
            {
              role: 'user',
              content: action.payload,
            },
          ],
          cache_metadata: [],
        } as any;
      }
      return {
        ...state,
        discussion: currentDiscussion,
        discussions: state.discussions.map((d) => {
          if (d.id === state.discussion?.id) return currentDiscussion as DiscussionType;
          return d;
        }),
        loadingPrompt: true,
        scrollBottom: true,
      };
    }
    // streaming response gpt3
    case 'STREAM_REPLY': {
      const currentDiscussion = state.discussion;
      if (currentDiscussion) {
        currentDiscussion.cache[currentDiscussion.cache.length - 1] = {
          role: 'assistant',
          content: action.payload,
        };
        currentDiscussion.replied_at = new Date().toISOString();
      }

      return {
        ...state,
        discussion: currentDiscussion,
        discussions: state.discussions.map((d) => {
          if (d.id === state.discussion?.id) return currentDiscussion as DiscussionType;
          return d;
        }),
        // loadingPrompt: true,
        scrollBottom: true,
      };
    }

    case 'STREAM_REPLY_UUID': {
      const currentDiscussion = state.discussion;
      if (currentDiscussion) {
        currentDiscussion.cache[currentDiscussion.cache.length - 1].id = action.payload;
      }

      return {
        ...state,
        discussion: currentDiscussion,
        discussions: state.discussions.map((d) => {
          if (d.id === state.discussion?.id) return currentDiscussion as DiscussionType;
          return d;
        }),
        // loadingPrompt: true,
        scrollBottom: true,
      };
    }
    // stream repsonse gpt4
    case 'STREAM_REPLY_WITH_DATA': {
      const currentDiscussion = state.discussion;
      if (currentDiscussion) {
        // console.log('STREAM_REPLY_WITH_DATA', action.payload.choices[0]);

        // eslint-disable-next-line prefer-destructuring
        currentDiscussion.cache[currentDiscussion.cache.length - 1] = action.payload.choices[0];
        currentDiscussion.replied_at = new Date().toISOString();
      }

      return {
        ...state,
        discussion: currentDiscussion,
        discussions: state.discussions.map((d) => {
          if (d.id === state.discussion?.id) return currentDiscussion as DiscussionType;
          return d;
        }),
        // loadingPrompt: true,
        scrollBottom: true,
      };
    }

    // submit when new prompt No stream
    case 'SUBMIT_PROMPT':
      return {
        ...state,
        discussionId: action.payload.id,
        discussions: state.discussions.map((d) => {
          if (d.id === action.payload.id) return { ...d, ...action.payload };
          return d;
        }),
        discussion: {
          ...state.discussions.find((d) => {
            return d.id === action.payload.id;
          }),
          ...action.payload,
          // cache: action.payload.cache,
        },
        loadingPrompt: false,
        scrollBottom: true,
      };
    case 'DISCUSSION_START_NEW':
      return {
        ...state,
        discussionId: undefined,
        discussion: undefined,
        discussionHasChanged: false,
        discussionAutoSave: false,
        discussionAutoSaveFeedback: false,
        loadingPrompt: false,
        scrollBottom: false,
        initPrompt: '',
      };
    // submit when new prompt with stream after init discussion
    case 'DISCUSSION_INIT_STATE': {
      const currentDiscussion = action.payload;
      if (currentDiscussion) {
        currentDiscussion.cache.push({
          role: 'assistant',
          content: '',
        });
      }
      return {
        ...state,
        discussionId: action.payload.id,
        discussions: [currentDiscussion, ...state.discussions],
        discussion: currentDiscussion,
      };
    }
    case 'SWITCH_ENGINE_GPT':
      state.abortList?.forEach((a) => a.abort());
      return {
        ...state,
        abortList: [],
        gptEngine: action.payload,
        discussionId: undefined,
        discussion: undefined,
        discussionAutoSave: false,
        discussionAutoSaveFeedback: false,
        discussionHasChanged: false,
        loadingPrompt: false,
        scrollBottom: false,
      };
    case 'SET_ERROR':
      return {
        ...state,
        errorMsg: action.payload,
      };
    case 'ABORTCONTROLLER_ADD':
      return {
        ...state,
        abortList: [action.payload, ...(state.abortList || [])],
      };
    case 'ABORTCONTROLLER_ABORT':
      state.abortList?.forEach((a) => a.abort());
      return {
        ...state,
        loadingPrompt: false,
        scrollBottom: false,
        abortList: [],
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducerDiscussions;
