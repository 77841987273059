import { Avatar, Box } from '@material-ui/core';
import React from 'react';
import logoIcon from '../../../../assets/images/chat_icon_mini.png';
import { AvatarType } from './chat';

export const AvatarDiscuss = (displayType: AvatarType, nameIs: string) => {
  if (displayType === 'user')
    return (
      <Box style={{ flexBasis: '10%' }}>
        <Avatar
          style={{
            backgroundColor: '#002a45',
            color: 'white',
            marginLeft: 5,
          }}
        >
          {nameIs}
        </Avatar>
      </Box>
    );

  return (
    <Box style={{ flexBasis: '10%', padding: 5, paddingLeft: 4 }}>
      <Avatar
        style={{
          backgroundColor: '#002a45',
          color: 'white',
          width: '46px',
          height: '46px',
        }}
        src={logoIcon}
        alt="logoIcon"
      />
    </Box>
  );
};

export default AvatarDiscuss;
