import { AppBar, Box, Dialog, IconButton, Slide, Typography } from '@material-ui/core';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import HistoryIcon from '@mui/icons-material/History';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import AssignHistoryContainer from './AssignHistoryContainer';

type Props = {
  project_id: number;
  onClose: any;
  workload_id?: number;
  rows_allocation?: any;
};
export const useStyles = makeStyles((theme) => ({
  boxMain: {
    margin: theme.spacing(2),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function AssignModelHistory({ project_id, workload_id, rows_allocation, onClose }: Props) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth
        open={true}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <Box>
          <AppBar position="static">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Toolbar>
                <IconButton
                  size="small"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  <HistoryIcon />
                </IconButton>
                <Typography variant="h6" component="div">
                  History
                </Typography>
              </Toolbar>
              <Toolbar>
                <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                  Close <CloseIcon />
                </IconButton>
              </Toolbar>
            </div>
          </AppBar>
        </Box>
        <Box className={classes.boxMain}>
          <AssignHistoryContainer
            projectId={project_id}
            workloadId={workload_id}
            allocationId={rows_allocation}
          />
        </Box>
      </Dialog>
    </div>
  );
}

export default AssignModelHistory;
