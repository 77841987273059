import { AlertProps } from '@material-ui/lab';
import { AppJwtType } from 'Auth';
import { any } from 'prop-types';
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { getRBSLabels } from '../../api/ownings';
import { getShortcuts } from '../../api/shortcuts';
import { getUserProfile } from '../../api/module1';
import useAppToken from '../../app/useAppToken';
import AlertSnack from '../../components/Alert/AlertSnack';
import { Project } from '../../react-app-env';

export interface ProjectContextType {
  userJWT?: AppJwtType;
  projects: Project[];
  shortCuts: any;
  setShortCuts: any;
  userSetting: any;
  setUserSetting: any;
  projectBread: any;
  isHiden: boolean;
  setIsHiden: () => {};
  open: boolean;
  setOpen: () => {};
  setProjectBread: () => {};
  setProjects: (d: Project[]) => any;
  getProject: (id: number) => Project;
  setProject: (d: Project) => any;
  setSnackMsg: (msg: string) => any;
  setSnackSeverity: (severity: AlertProps['severity']) => any;
  setSnack: (msg: string, severity?: AlertProps['severity']) => any;
  rbsLabels?: any;
  completedProjects?: boolean;
}

const ProjectContext = React.createContext<ProjectContextType>({
  userJWT: (localStorage.getItem('appJWT') ?? {}) as AppJwtType, // TODO move to top later
  projects: [] as any,
  shortCuts: [] as any,
  projectBread: Number,
  isHiden: Boolean,
  project_lite: [] as any,
  setIsHiden: (): any => {},
  open: Boolean,
  setOpen: (): any => {},
  setProjectBread: (): any => {},
  setShortCuts: (): any => {},
  setUserSetting: (): any => {},
  setProjects: (): any => {},
  setProjects_lite: (): any => {},
  getProject: (): any => {},
  setProject: (): any => {},
  setSnackMsg: (): any => {},
  setSnackSeverity: (): any => {},
  // combi msg + severity
  setSnack: (): any => {},
  rbsLabels: [] as any,
  completedProjects: false,
});

type Props = {
  project_lite: Project[];
  completedProjects?: boolean;
  children: any;
};

const ProjectContextProvider = ({ project_lite, completedProjects, children }: Props): any => {
  const [_projects, _setProjects] = useState(project_lite);
  const [projects, setProjects] = useState([]);
  const [_rbsLabels, _setRbsLabels] = useState([]);
  const [open, setOpen] = useState<boolean>(false);
  const [isHiden, setIsHiden] = React.useState(false);
  const [snackMsg, setSnackMsg] = useState<string>('');
  const [shortCuts, setShortCuts] = useState([]);
  const [userSetting, setUserSetting] = useState([]);
  const [projectBread, setProjectBread] = useState();
  const [floderBread, setFloderBread] = useState();
  const [snackSeverity, setSnackSeverity] = useState<AlertProps['severity']>('success');
  const { appJWT } = useAppToken();

  useEffect(() => {
    const { user_id } = appJWT;
    getShortcuts(user_id).then((data) => {
      if (data.length > 0) {
        const { shortcuts } = data[0];
        setShortCuts(shortcuts);
      }
    });
    getUserProfile(user_id)
      .then((response: any) => {
        if (response.length !== 0) {
          // eslint-disable-next-line array-callback-return
          response.map((data: any) => {
            if (data) {
              setUserSetting(data?.profiles);
            }
          });
        } else {
          setOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    _setProjects(project_lite);
    getRBSLabels().then((data) => {
      if (data) _setRbsLabels(data[0]);
    });
  }, [project_lite]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackMsg('');
  };

  return (
    <ProjectContext.Provider
      value={{
        completedProjects,
        rbsLabels: _rbsLabels,
        userJWT: localStorage.getItem('appJWT') ? JSON.parse(localStorage.getItem('appJWT')) : {},
        projects_lite: _projects,
        projects,
        isHiden,
        setIsHiden,
        open,
        setOpen,
        setProjects_lite: _setProjects,
        setProjects: (_input: Project[], force = false) => {
          if (force) {
            setProjects(_input);
          }
          if (!isEqual(projects, _input)) setProjects(_input);
        },
        setProject: (_project: Project) => {
          const i = _projects.findIndex((p: Project) => p.id === _project.id);
          if (i === -1) return;
          _projects[i] = _project;
          _setProjects([..._projects]);
        },
        getProject: (_id: number): Project => {
          return projects?.find((p: Project) => Number(p.id) === Number(_id)) as Project;
        },
        shortCuts,
        setShortCuts,
        userSetting,
        setUserSetting,
        projectBread,
        setProjectBread,
        floderBread,
        setFloderBread,
        setSnackMsg,
        setSnackSeverity,
        setSnack: (msg: string, severity = 'success' as AlertProps['severity']) => {
          setSnackMsg(msg);
          setSnackSeverity(severity);
        },
      }}
    >
      {snackMsg !== '' && (
        <AlertSnack
          msg={snackMsg}
          severity={snackSeverity}
          handleClose={handleClose}
          duration={3000}
        />
      )}
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContext;

export { ProjectContextProvider };
