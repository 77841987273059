/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Avatar,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  MenuItem,
  Select,
  TableCell,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import MenuOpenIcon from '@material-ui/icons/Menu';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import HistoryIcon from '@mui/icons-material/History';
import ImageIcon from '@mui/icons-material/Image';
import TableChartIcon from '@mui/icons-material/TableChart';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { AllocationData, LifeCycleData, Owning, SelectionAllocation } from 'OwningType';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactJson from 'react-json-view';
import { Link, useParams } from 'react-router-dom';
import { isEmpty } from '@antv/util';
import { createBulkOwning, getOwning } from '../../../../../api/ownings';
import Loading from '../../../../../components/Core/Loading';
import MultiAllocTableComp from '../../../../../components/Datagrid/MultiAllocTableComp';
import TableComponent, { TableComponent2 } from '../../../../../components/Datagrid/TableComponent';
import TableHeaderCellWrap from '../../../../../components/Datagrid/TableHeaderCellWrap';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import { COMPLIANCE_LABELS, IS_DEBUG_MODE, LIFECYCLE_LABELS } from '../../../../../const';
// import { getAllCustomedGroups } from '../../../../api/projects';
import { getProjectGroupUsers } from '../../../../../api/projects';
import NavigationGoto from '../../../../../components/Core/NavigationGoto';
import { LangCode } from '../../../../../components/Datagrid/TranslationList';
import {
  multiTableRowComponent,
  tableAllocatorComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../../../components/Datagrid/TStyles';
import { handleColumns } from '../../../../../tools/datagrid';
import ProjectContext from '../../../../Project/ProjectContext';
import Module1Context from '../../../Module1Context';
import { allColumns } from '../../../tools/allColumns';
import AssignModelHistory from './AssignModelHistory';
import { lifecycleFilter, LifecyleCellKpi, LifecyleCellMulti_2 } from './LifecyleCell';
import ModalClarification from './ModalClarification';
import ModalDecline from './ModalDecline';
import ModalKpi from './ModalKpi';
import ModalRawdata from './ModalRawdata';
import RenderCellExcel from './RenderCellExcel';
import RenderCellText from './RenderCellText';
import Similarizer from './Similarizer';
import { textWidth } from './useStyle';
import IAllocatorStyle from '../../../../../components/Datagrid/TableAllNewStyle/IAllocatorStyle';
import NavigationGotoNew from '../../../../../components/Core/NavigationGotoNew';

const stylingObject = {
  ahref: {
    textDecoration: 'none',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    color: 'blue',
  },
};

/*
Accept = Light Green
Unassigned = ?
Assign = Dark Green
Reject = Pink
Analyze = Yellow
Information = Grey

*/

/**
 * rule: when declined = end lifecycle Front: disable checkbox, DB: clean all analysis
 * lifecycle: 2 types
 * - assign
 * - clarification
 */

export const useStyles = makeStyles((theme) => ({
  root: {},
  customWidth: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '1rem',
  },
  latestAction: {
    backgroundColor: theme.palette.success.dark,
    border: '1px solid white',
  },
  clarifyIcon: {
    cursor: 'pointer',
  },
  customText: {
    color: 'white',
  },
  borderBase: {
    borderRadius: 2,
    border: '2px solid red',
  },
}));
const CustomWidth2Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#eef1f9',
    border: '1px solid black',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    width: 50,
    transition: 'opacity 1s',
    // height: 30,
    '& .MuiTooltip-arrow': {
      color: '#eef1f9',
      '&:before': {
        backgroundColor: '#eef1f9',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid black`,
        fontSize: 16,
      },
    },
  },
});

type Props = {
  filename: string;
  onSearchText?: any;
  rows: any[];
  workloadId: number;
  targetColumn?: any;
  displayColumns?: any[];
  onDownload?: any;
  isRegExp?: boolean;
  entityProject?: string;
  languageOriginCode: LangCode;
  levelsHierarchy: any;
  languages: LangCode[];
  langCheck: any;
  originalText?: any;
  setOriginalText?: any;
  setActionForFile?: any;
  dispatch?: any;
  onChange?: any;
};

function OwningFlow({
  rows,
  workloadId,
  filename,
  onDownload,
  langCheck,
  onSearchText,
  targetColumn,
  originalText,
  setOriginalText,
  setActionForFile,
  displayColumns,
  isRegExp,
  dispatch,
  entityProject,
  onChange,
  languageOriginCode,
  levelsHierarchy,
  languages,
}: Props) {
  const classes = useStyles();
  const classes1 = IAllocatorStyle();
  const { projectId } = useParams() as any;
  const { userJWT, rbsLabels } = useContext(ProjectContext);
  const { project, setSnack, translationCode, setTranslationCode, languagesCode } = useContext(
    Module1Context
  );
  const [projectUsers, setProjectUsers] = useState<any>([]);
  const [rowsEdited, setRowsEdited] = useState<any>(rows);
  // rows
  // .filter((row: any) => {
  //   return row.Characterization === 'Requirement' && row.lifecycles?.length;
  // })
  // .slice(0, 2)
  // modal raw data
  const [imagesList, setImagesList] = useState<any[]>([]);
  const [tablesList, setTablesList] = useState<any[]>([]);
  const [rawType, setRawType] = useState<rawTypeEnum>('images');
  const [toggleRawdata, setToggleRawdata] = useState(false);

  const [modalRow, setModalRow] = useState(undefined);
  const [toggleDecline, setToggleDecline] = useState(false);
  const [toggleRows, setToggleRows] = useState([]);
  const [toggleFavorite, setToggleFavorite] = useState(false);

  // state for selection: multi ,normal
  const [toggleCheckbox, setToggleCheckbox] = useState(false);
  const [selection, setSelection] = useState<SelectionAllocation[]>([]);
  const [selectionRows, setSelectionRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [openAssignHistory, setOpenAssignHistory] = useState(false);
  const [paramHistory, setParamHistory] = useState<any>();

  // state for iSimilarizer
  const [openSim, setOpenSim] = useState(false);
  const [rowsSim, setRowsSim] = useState<any>([]);

  const [projectGroups, setProjectGroups] = useState<any>([]);
  const [toggleHeight, setToggleHeight] = useState(false);

  const [rowsExport, setRowsExport] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [openDashboard, setOpenDashboard] = useState(false);

  const pageSizes = [5, 10, 20, 50];
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const [columns] = useState<any>(
    handleColumns(
      allColumns('own', {
        targetColumn,
        displayColumns,
        levelsHierarchy,
        isRegExp,
        entity: entityProject,
      })
    )
  );

  const [columnsMulti] = useState<any>(
    handleColumns(
      allColumns('own_multi', {
        targetColumn,
        displayColumns: undefined,
        levelsHierarchy,
        isRegExp,
        entity: entityProject,
      })
    )
  );
  const columnsExport = handleColumns(
    allColumns(
      'own',
      {
        targetColumn,
        displayColumns,
        levelsHierarchy,
        isRegExp,
        entity: entityProject,
      },
      true
    )
  );

  const exporterRef = useRef(null);
  const targetPage = `${workloadId}OWNING${projectId}`;

  // download selected columns states

  const [col, setCol] = useState(
    columns.map((data) => {
      return data?.name;
    })
  );
  const [columnSelected, setColumnSelected] = useState([
    'id',
    'System Entities',
    'Characterization',
    'comment',
    'Activities',
    'Acronyms',
    'Cybersecurity',
    'Standards',
    'project_management',
    'Finance',
    'Contractual',
    'Context',
    'safety',
    'category',
    'testable',
    'contribution',
    'type',
    'Critical To Customer',
    'Stability',
    'Validation Method',
    'Source',
    'Rationale',
    'Validation Evidence',
    'Char_Score',
    'History',
    'clarify',
    'Hierarchy',
    'Original_Hierarchy',
    ...levelsHierarchy,
    'SubType',
    'Commercial',
    'PageNo',
    'assigment',
  ]);

  const [displayColumnDown, setDisplayColumnDown] = useState([]);

  function callingOwningAPI() {
    setLoad(true);
    getOwning(
      projectId,
      Number(workloadId),
      filename,
      undefined,
      true, // add allocations
      true, // add lifecycle
      false,
      'owning'
    )
      .then((response) => {
        let rowTab;
        if (response[0].row_capture && 'allocations' in response[0]) {
          dispatch({ type: 'assignment' });
          dispatch({ type: 'characterizer' });
          dispatch({ type: 'capture' });
          setActionForFile('allocator');
        }

        if (response[0]?.row_capture) {
          rowTab = 'assignment';
          const { i18n } = response[0]?.row_capture || {};
          const keyss = Object?.keys(i18n);
          let OriginalLang = '';
          let transCode = '';
          if (langCheck) {
            const orignalLangCode = languagesCode.filter((data: any) => {
              return data.lang === langCheck;
            });
            if (orignalLangCode[0].code === 'en') {
              OriginalLang = 'en';
              transCode = 'en';
              setOriginalText(OriginalLang);
              setTranslationCode(transCode);
            } else {
              OriginalLang = orignalLangCode[0].code;
              setOriginalText(orignalLangCode[0].code);
              setTranslationCode('-');
              transCode = '-';
            }
          } else {
            const originalText = keyss.filter((data) => {
              return !['fr', 'de', 'it', 'es', 'en'].includes(data);
            });
            if (originalText[0] !== undefined) {
              // eslint-disable-next-line prefer-destructuring
              OriginalLang = originalText[0];
              setOriginalText(originalText[0]);
              setTranslationCode('-');
              transCode = '-';
            } else {
              OriginalLang = 'en';
              setOriginalText('en');
              setTranslationCode('en');
              transCode = 'en';
            }
          }
          const responseArr = response.map((row) => {
            const { i18n } = row?.row_capture || {};
            if ('i18n' in row.row_capture && Object?.keys(i18n)?.length > 1) {
              if (row.row_capture[targetColumn]) {
                return {
                  ...row.row_capture,
                  [targetColumn]: transCode === '-' ? i18n[OriginalLang] : i18n[transCode],
                  kpi_lifecycle: row.kpi_lifecycle,
                  kpi_compliance: row.kpi_compliance,
                  allocations: row.allocations,
                  lifecycles: row.lifecycles,
                  updated_at: row.updated_at,
                  id: row.id,
                };
              }
              return {
                ...row.row_capture,
                Text: transCode === '-' ? i18n[OriginalLang] : i18n[transCode],
                kpi_lifecycle: row.kpi_lifecycle,
                kpi_compliance: row.kpi_compliance,
                allocations: row.allocations,
                lifecycles: row.lifecycles,
                updated_at: row.updated_at,
                id: row.id,
              };
            }
            return {
              ...row.row_capture,
              kpi_lifecycle: row.kpi_lifecycle,
              kpi_compliance: row.kpi_compliance,
              allocations: row.allocations,
              lifecycles: row.lifecycles,
              updated_at: row.updated_at,
              id: row.id,
            };
          });
          onChange(responseArr);
          setRowsEdited(responseArr);
          setLoad(false);
          // setShowTable(true);
          // clearInterval(intervalCallApI);
        }
      })
      .catch((e) => {
        setSnack(`${e} `, 'warning');
        console.log(e);
        // callingOwningAPI();
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  useEffect(() => {
    handleRefresh();
    if (sessionStorage.getItem(targetPage) == null) {
      setCurrentPage(0);
    } else {
      const previouspage = sessionStorage.getItem(targetPage, currentPage);
      setCurrentPage(previouspage);
    }
  }, []);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  // effect for export EXCEL
  useEffect(() => {
    const newRows = [] as any[];
    rowsEdited?.forEach((r) => {
      const rowTemp = { ...r };
      delete rowTemp.selectionCustom;
      delete rowTemp.Allocation;
      delete rowTemp.allocations;
      delete rowTemp.lifecycles;
      if (r.Characterization === 'Requirement') {
        if (!r.allocations) {
          // UI fix
          return;
        }
        r.allocations.forEach((allo: AllocationData, index: number) => {
          const lifecycle = allo?.lifecycle
            ? LIFECYCLE_LABELS[allo?.lifecycle]
            : LIFECYCLE_LABELS.characterized;
          newRows.push({
            ...rowTemp,
            kpi_lifecycle: LIFECYCLE_LABELS[rowTemp.kpi_lifecycle],
            lifecycle,
            kpi_compliance: COMPLIANCE_LABELS[rowTemp.kpi_compliance],
            allocations: allo.allocation,
            score: allo.allocation_score,
            pbs: allo.pbs,
            abs: allo.abs,
            obs: allo.obs,
            receivers: allo.receiver
              ? project.users.find((user) => user.user_id === allo.receiver)?.user?.name
              : '',
          });
        });
      } else {
        newRows.push({
          ...rowTemp,
          kpi_lifecycle: LIFECYCLE_LABELS.characterized,
          lifecycle: LIFECYCLE_LABELS.characterized,
        });
      }
    });
    const imageArray = [];
    const tableArray = [];
    for (const row of newRows) {
      if (row.Characterization === 'Image') {
        imageArray.push({ id: row.id, title: row.Text, data: row.RawData });
      }
      if (row.Characterization === 'Table') {
        tableArray.push({ id: row.id, data: row.RawData });
      }
    }
    setImagesList(imageArray);
    setTablesList(tableArray);
  }, [rowsEdited]);

  useEffect(() => {
    if (rowsEdited[0]) {
      const { i18n } = rowsEdited[0];
      if (Object?.keys(i18n)?.length === 1) {
        return;
      }
      if (originalText !== undefined && translationCode === '-') {
        const temp = rows.map((data) => {
          if (data[targetColumn]) {
            return {
              ...data,
              [targetColumn]: data?.i18n[originalText],
            };
          }
          if (data?.i18n !== undefined) {
            const oL = data?.i18n[originalText];
            return { ...data, Text: oL };
          }
          return data;
        });
        // setRowsEdited(temp);
        setRowsEdited(temp);
      } else {
        setRowsEdited((prev) => {
          return prev.map((r) => {
            if (r?.i18n !== undefined && r?.i18 !== null && r?.i18n[translationCode || 'en']) {
              if (r?.Text) {
                return {
                  ...r,
                  Text: r?.i18n[translationCode],
                };
              }
              if (r[targetColumn]) {
                return {
                  ...r,
                  [targetColumn]: r?.i18n[translationCode],
                };
              }
            }
            return r;
          });
        });
      }
    }
  }, [translationCode]);

  useEffect(() => {
    const dynamicColumn = JSON.parse(localStorage.getItem('storedColumnOwning')) || columnSelected;
    const difference = col.filter((x) => !dynamicColumn?.includes(x));
    const temp = [] as any;
    rowsEdited?.map((data) => {
      const t = {};
      const { i18n } = data;
      difference?.map((key) => {
        if (key === 'Text' && 'i18n' in data && Object?.keys(i18n)?.length > 1) {
          if (originalText !== undefined && translationCode === '-' && data?.i18n !== undefined) {
            const oL = data?.i18n[originalText];
            t[key] = oL;
          } else if (
            data?.i18n !== undefined &&
            data?.i18 !== null &&
            data?.i18n[translationCode || 'en']
          ) {
            t[key] = data?.i18n[translationCode];
          }
        } else {
          t[key] = data[key];
        }
        return key;
      });
      if (!isEmpty(data?.allocations)) {
        data?.allocations.forEach((allo: AllocationData) => {
          if (allo && allo?.lifecycle) {
            const lifecycle = allo?.lifecycle
              ? LIFECYCLE_LABELS[allo?.lifecycle]
              : LIFECYCLE_LABELS.characterized;
            temp.push({
              ...data,
              kpi_lifecycle: LIFECYCLE_LABELS[data.kpi_lifecycle],
              lifecycle,
              kpi_compliance: COMPLIANCE_LABELS[data.kpi_compliance],
              allocations: allo.allocation,
              score: allo.allocation_score,
              pbs: allo.pbs,
              abs: allo.abs,
              obs: allo.obs,
              receiver: allo.receiver
                ? project.users.find((user) => user.user_id === allo.receiver)?.user?.name
                : '',
            });
          } else {
            temp.push({
              ...data,
              kpi_lifecycle: LIFECYCLE_LABELS.characterized,
              lifecycle: LIFECYCLE_LABELS.characterized,
            });
          }
        });
      } else {
        temp.push(t);
      }
      return data;
    });
    const disCol = [] as any;
    columns.map((data) => {
      if (difference.includes(data.name) && data?.name !== 'own') {
        disCol.push({
          name: data.name,
          title: data.name,
        });
      }
      return data;
    });
    disCol.push({ name: 'lifecycle', title: 'Lifecycle Multi' });
    disCol.push({ name: 'pbs', title: 'PBS' });
    disCol.push({ name: 'abs', title: 'ABS' });
    disCol.push({ name: 'obs', title: 'OBS' });
    disCol.push({ name: 'receiver', title: 'Receivers' });
    setDisplayColumnDown(disCol);
    setRowsExport(temp);
  }, [columnSelected, rowsEdited]);

  useEffect(() => {
    if (pageSize > rowsEdited.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < rowsEdited.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);

  // Load groups data
  useEffect(() => {
    getProjectGroupUsers(projectId)
      .then((resp) => {
        setProjectGroups(resp);
      })
      .catch((error) => {
        setSnack(JSON.stringify(error.detail), 'error');
      });
  }, []);

  const handlePageNum = () => {
    if (Number.isNaN(tempPage)) {
      return;
    }
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  useEffect(() => {
    if (!project || !project.users) return;
    setLoading(true);
    setProjectUsers(
      project.users
        .filter((_user: any) => _user.user !== null)
        .map((_user: any) => ({ name: _user.user.name, id: _user.user.id }))
    );

    setLoading(false);
  }, [rows, projectId, project]);

  const TableHeaderContentBase = ({ column, children, ...restProps }) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
      setOpen(false);
    };
    const handleSelectAll = () => {
      if (toggleFavorite !== true) {
        setToggleCheckbox(false);
        handleClose();
        return;
      }
      const start = Math.abs(pageSize - pageSize * (currentPage + 1));
      const rowsSel = [] as any[];
      for (let index = start; index < pageSize * (currentPage + 1); index += 1) {
        const { id, lifecycles, allocations } = rowsEdited[index] || {};
        if (allocations === undefined) {
          // eslint-disable-next-line no-continue
          continue;
        }
        // if (['owned', 'analyzed'].includes(String(allocations.lifecycle))) {
        //   console.log(allocations.lifecycle);
        //   // eslint-disable-next-line no-continue
        //   continue;
        // }
        if (allocations.length > 0) {
          for (let i = 0; i < allocations.length; i += 1) {
            if (
              ['analyzed', 'need-clarification', 'recaptured'].includes(
                String(allocations[i].lifecycle)
              )
            ) {
              // eslint-disable-next-line no-continue
              continue;
            }
            const allocation = allocations[i];
            rowsSel.push({
              allocation,
              text: rowsEdited[index].Text ?? rowsEdited[index][targetColumn],
              id,
              lifecycles,
            });
          }
        }
      }

      // for (const row of rowsEdited) {
      //   const { id, lifecycles, allocations } = row as Owning;
      //   if (allocations === undefined) {
      //     // eslint-disable-next-line no-continue
      //     continue;
      //   }
      //   for (const allocation of allocations) {
      //     if (['owned', 'analyzed'].includes(String(allocation.lifecycle))) {
      //       // eslint-disable-next-line no-continue
      //       continue;
      //     }
      //     rowsSel.push({
      //       allocation,
      //       id,
      //       lifecycles,
      //     });
      //   }
      // }
      setToggleCheckbox(!toggleCheckbox);
      setSelection(rowsSel);
      handleClose();
    };

    return (
      <TableHeaderRow.Content column={column} {...restProps}>
        {column.name === 'own' ? (
          <>
            <Checkbox
              style={{
                height: '25px',
              }}
              checked={toggleCheckbox}
              onClick={() => {
                if (toggleCheckbox) {
                  setToggleCheckbox(!toggleCheckbox);
                  setSelection([]);
                  handleClose();
                } else {
                  setOpen(true);
                }
              }}
            />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              className={classes.borderBase}
            >
              {/* <DialogTitle id="draggable-dialog-title">
              </DialogTitle> */}
              <DialogContent
                color="white"
                style={{
                  background: '#DC3223 0% 0% no-repeat padding-box',
                  color: 'white !important',
                }}
              >
                <DialogContentText className={classes.customText}>
                  {toggleFavorite !== true
                    ? 'please select favourite'
                    : 'You are going to select all owning from current page'}
                </DialogContentText>
              </DialogContent>
              <DialogActions
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Button
                  autoFocus
                  onClick={handleClose}
                  style={{ backgroundColor: 'green', color: 'white' }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSelectAll}
                  style={{ backgroundColor: '#002A45', color: 'white' }}
                >
                  {toggleFavorite !== true ? 'Ok' : 'Select all'}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : null}
        {children}
      </TableHeaderRow.Content>
    );
  };

  const handleRefresh = () => {
    setToggleFavorite(false);
    callingOwningAPI();
    // getOwning(
    //   projectId,
    //   Number(workloadId),
    //   filename,
    //   undefined,
    //   true, // add allocations
    //   true, // add lifecycle
    //   false,
    //   'owning'
    // )
    //   .then((payload) => {
    //     const tempRows = payload.map((row) => {
    //       const [lc1] = lifecycleFilter(row.lifecycles || []);

    //       // clean receivers
    //       if (!lc1 || lc1.length === 0) {
    //         return { ...row.row_capture, receivers: undefined };
    //       }
    //       return {
    //         ...row.row_capture,
    //         allocations: row.allocations,
    //         lifecycles: row.lifecycles,
    //         updated_at: row.updated_at,
    //         id: row.id,
    //       };
    //     });
    //     setRowsEdited(tempRows);
    //   })
    //   .catch((reason) => {
    //     console.error(reason);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const [integratedFilteringColumnExtensions] = useState([]);

  const updateStateRows = (rows: any[], response: Partial<Owning>[]) => {
    const rowIds = response.map((r) => r.id);
    setRowsEdited(
      rows.map((row: Owning) => {
        if (rowIds.includes(row.id)) {
          const element = response.find((res: Partial<Owning>) => row.id === res.id);
          const newRow = {
            ...row,
            ...element,
          };
          return newRow;
        }
        return row;
      })
    );
    setSelection([]);
  };

  const handleToggleFavorite = () => {
    if (!toggleFavorite === true) {
      // console.log(rowsEdited);
      // const rowsFavorite = rowsEdited.filter((row) => {
      //   const receivers = row?.allocations.map((a) => Number(a.receiver));

      //   return receivers.includes(Number(userJWT.user_id));
      // });

      // const rowsFavorite2 = rowsFavorite.map((r) => {
      //   const newAllocations = r.allocations.filter((a) => {
      //     return Number(a.receiver) === Number(userJWT.user_id);
      //   });

      //   return { ...r, allocations: newAllocations };
      // });
      const temp = [] as any;
      const filterRow = rowsEdited?.map((row) => {
        const al = row.allocations;
        let boolen = false;
        const t = [];
        for (let i = 0; i < al?.length; i += 1) {
          for (let j = 0; j < projectGroups.length; j += 1) {
            if (['D&IS', 'Services'].includes(project.entity)) {
              if (al[i]?.pbs === projectGroups[j]?.pbs && al[i].abs === projectGroups[j]?.abs) {
                if (projectGroups[j]?.user_ids.includes(userJWT.user_id)) {
                  boolen = true;
                  t?.push(al[i]);
                }
              }
            }
            if (project.entity === 'RSC') {
              if (al[i]?.obs === projectGroups[j]?.obs && al[i]?.pbs === projectGroups[j]?.pbs) {
                if (projectGroups[j]?.user_ids?.includes(userJWT.user_id)) {
                  boolen = true;
                  t?.push(al[i]);
                }
              }
            }
          }
        }
        if (boolen) {
          temp.push({ ...row, allocations: t });
        }
        return row;
      });
      setToggleRows(rowsEdited);
      setRowsEdited(temp);
    } else {
      setRowsEdited(toggleRows);
    }
    setToggleFavorite(!toggleFavorite);
  };
  const handleAccepDecline = (status: string) => {
    if (selection?.length === 0) {
      setSnack(`Select at least one row to ${status}`, 'warning');
      return;
    }

    const newLifecycles = selection.map((sel: SelectionAllocation) => {
      return {
        project_id: Number(projectId),
        owning_id: sel.id,
        user_id: Number(userJWT.user_id),
        content: '',
        action: status,
        workload_id: workloadId,
        filename,
        allocation_id: sel.allocation.id,
        allocation: sel.allocation.allocation,
      };
    });

    createBulkOwning(newLifecycles)
      .then((resp: LifeCycleData[]) => {
        updateStateRows(rowsEdited, resp);
        setSnack(`${newLifecycles.length} requirements are ${status}ed`, 'info');
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setSelection([]);
        setToggleCheckbox(false);
      });
  };

  const handleDecline = () => {
    if (selection?.length === 0) {
      setSnack(`Select at least one row to reject`, 'warning');
      return;
    }
    setToggleDecline(true);
  };

  const openSimilarizer = () => {
    const selectedRows = rowsEdited.filter((row, index) => selectionRows.includes(index));
    // validation text to similarize
    const templistText = selectedRows.map((row) => {
      return row.Text ?? row[targetColumn];
    });
    if (templistText?.length === 0) {
      setSnack('Select at least one row to run iSimialarizer', 'warning');
      setRowsSim([]);
      setOpenSim(false);
      return;
    }
    setRowsSim(templistText);
    setOpenSim(true);
  };
  const showDashboardHandler = () => {
    setOpenDashboard(true);
  };
  const modalImage = (row, rawType = 'images' as rawTypeEnum) => {
    if (rawType === 'images') {
      const imageIndex = imagesList.findIndex((r) => row?.id === r.id);
      const imagesListSorted = [
        ...imagesList.slice(imageIndex),
        ...imagesList.slice(0, imageIndex).reverse(),
      ];
      setImagesList(imagesListSorted);
    }

    if (rawType === 'tables') {
      const itemIndex = tablesList.findIndex((r) => row?.id === r.id);
      const imagesListSorted = [
        ...tablesList.slice(itemIndex),
        ...tablesList.slice(0, itemIndex).reverse(),
      ];
      setTablesList(imagesListSorted);
    }

    setRawType(rawType);
    setToggleRawdata(true);
  };
  const renderSubType = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    if (row.Characterization === 'Image') {
      return (
        <Button title="Image" onClick={() => modalImage(row, 'images')}>
          <ImageIcon />
          {value}
        </Button>
      );
    }
    if (row.Characterization === 'Table') {
      return (
        <Button title="Table" onClick={() => modalImage(row, 'tables')}>
          <TableChartIcon />
          {value}
        </Button>
      );
    }

    return <>{value}</>;
  };
  // table container style
  const tableChildContainer: React.FunctionComponent<Table.CellProps> = (props) => {
    return (
      <Table.Container
        {...props}
        style={{ maxHeight: '70vh', overflowY: 'auto', border: '1px solid black' }}
      />
    );
  };

  const allocationHistory = ({ row }: DataTypeProvider.ValueFormatterProps) => {
    if (row?.Characterization === 'Requirement') {
      return (
        <Button title="Assigned history" component={Link} onClick={() => modalHistory(row)}>
          {' '}
          <HistoryIcon />{' '}
        </Button>
      );
    }
    return '';
  };
  const modalHistory = (row) => {
    setOpenAssignHistory(true);
    setParamHistory(row.id);
  };

  const renderBreakDown = ({
    row: { allocation },
    column,
  }: DataTypeProvider.ValueFormatterProps) => {
    if (allocation[column.name]) {
      if (rbsLabels[allocation[column.name]]) {
        return (
          <>
            {rbsLabels[allocation[column.name]]} ({allocation[column.name]})
          </>
        );
      }
      return <>{allocation[column.name]}</>;
    }
    return null;
  };

  const renderClarify = useCallback(
    ({ row }: DataTypeProvider.ValueFormatterProps) => {
      const lc2 = lifecycleFilter(row.lifecycles || [])[1];
      if (row.Characterization === 'Requirement')
        return (
          <Badge badgeContent={lc2.length || 0} color="primary">
            <LiveHelpIcon
              color="disabled"
              className={classes.clarifyIcon}
              onClick={() => {
                setModalRow(row);
              }}
              titleAccess="Ask for clarification"
            />
          </Badge>
        );
      return <></>;
    },
    [rowsEdited]
  );

  const handleChangeCheckbox_2 = (e, row) => {
    const { allocation, id, lifecycles } = JSON.parse(e.currentTarget.value);

    if (e.currentTarget.checked) {
      setSelection(
        selection.concat({ allocation, text: row.Text ?? row[targetColumn], id, lifecycles })
      );
    } else {
      const index = selection.findIndex(
        (s: SelectionAllocation) => s.allocation.id === allocation.id && s.id === id
      );
      if (index !== -1) {
        // remove uncheck item
        const myselection = [...selection];
        myselection.splice(index, 1);
        setSelection(myselection);
      }
    }
  };

  const CustomCheckboxCellBase_2 = ({ row: { row, allocation } }: TableSelection.CellProps) => {
    const { id, lifecycles, allocations } = row as Owning;

    if (!['assigned', 'owned', 'rejected'].includes(String(allocation.lifecycle))) return null;
    // get receiver

    const groupID =
      project.entity === 'D&IS' || project.entity === 'Services'
        ? projectGroups.filter((data) => {
            return data.pbs === allocation.pbs && data.abs === allocation.abs;
          })
        : projectGroups.filter((data) => {
            return data.obs === allocation.obs && data.pbs === allocation.pbs;
          });
    // const lastReceiver =
    //   allocation?.receiver && groupID[0]?.user_ids.includes(Number(userJWT.user_id))
    //     ? Number(userJWT.user_id)
    //     : undefined;

    // if (!lastReceiver || Number(lastReceiver) !== Number(userJWT.user_id)) {
    //   return null;
    // }

    // disable condition checking existing allocation?.receiver when assignment for all
    const lastReceiver = groupID[0]?.user_ids.includes(Number(userJWT.user_id))
      ? Number(userJWT.user_id)
      : undefined;

    if (!lastReceiver) {
      return null;
    }
    return (
      <>
        <Checkbox
          icon={<AssignmentIndOutlinedIcon />}
          checkedIcon={<AssignmentIndIcon />}
          style={{
            height: '25px',
          }}
          checked={
            selection.findIndex(
              (s: SelectionAllocation) => s.allocation.id === allocation.id && s.id === row.id
            ) !== -1
          }
          value={JSON.stringify({
            allocation,
            text: row.Text ?? row[targetColumn],
            id,
            lifecycles,
          })}
          onChange={(e) => handleChangeCheckbox_2(e, row)}
        />
      </>
    );
  };

  const renderReceivers_2 = ({ row: { allocation } }: DataTypeProvider.ValueFormatterProps) => {
    const user = project.users.find((user) => user.user_id === allocation.receiver);
    if (!user) return null;
    return <>{user.user.name}</>;
  };

  const renderTableMulti = ({ row }: DataTypeProvider.ValueFormatterProps) => {
    if (!row.allocations?.length) return null;
    return (
      <>
        <ExpressGrid
          rows={row.allocations.map((r) => {
            return {
              row,
              allocation: r,
            };
          })}
          //
          columns={columnsMulti}
        >
          <DataTypeProvider for={['lifecycle']} formatterComponent={LifecyleCellMulti_2} />
          <DataTypeProvider for={['pbs', 'abs', 'obs']} formatterComponent={renderBreakDown} />
          <DataTypeProvider for={['receiver']} formatterComponent={renderReceivers_2} />
          <DataTypeProvider
            for={['selectionCustom']}
            formatterComponent={CustomCheckboxCellBase_2}
          />
          <Table tableComponent={TableComponent2} />
          <TableColumnResizing
            defaultColumnWidths={[
              { columnName: 'selectionCustom', width: 80 },
              { columnName: 'lifecycle', width: 200 },
              { columnName: 'History', width: 120 },
              { columnName: 'pbs', width: 200 },
              { columnName: 'abs', width: 200 },
              { columnName: 'obs', width: 200 },
              { columnName: 'receiver', width: 200 },
            ]}
          />
          <TableHeaderRow
            hoverStateEnabled={true}
            cellComponent={TableHeaderCellWrap}
            rowComponent={multiTableRowComponent}
          />
        </ExpressGrid>
      </>
    );
  };
  // if (!project || !rowsEdited?.length) return null;
  if (loading) return <Loading />;

  // for menu dropdown
  const renderId = ({ value, row, column }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            marginRight: 115,
            marginTop: 5,
          }}
        >
          <MenuOpenIcon />
          <Select
            style={{ marginLeft: -25 }}
            defaultValue={value}
            onChange={(e) => handleTest(e, row, column)}
            disableUnderline
          >
            <MenuItem>
              <a
                href="#text-buttons"
                onClick={openSimilarizer}
                style={stylingObject.ahref}
                title="Find Similar Items"
              >
                <FileCopyOutlinedIcon />
                iSimilarizer
              </a>
            </MenuItem>
          </Select>
        </div>
      </>
    );
  };
  const handleTest = (e: any, row: any, column: Column) => {
    console.log(e?.value);
  };
  // table Row Menu
  const SelectionCellComponent = ({ row, selected, onToggle, value, column }) => (
    <TableCell>
      {/* <CheckBoxOutlineBlankIcon onClick={abcd(row)} /> */}
      <div className={classes1.IconStyle}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CustomWidth2Tooltip
            // open={true}
            placement="top-start"
            // disableTouchListener
            arrow
            title={
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <a
                    href="#text-buttons"
                    onClick={handleDeleteRows}
                    style={stylingObject.ahref}
                    title="Delete"
                  >
                    <Avatar className={classes1.avatar}>
                      <DeleteIcon fontSize="small" />
                    </Avatar>
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={handleEditDrop}
                    style={stylingObject.ahref}
                    title="Edit"
                  >
                    <Avatar className={classes1.avatar}>
                      <EditIcon fontSize="small" />
                    </Avatar>
                  </a> */}
                  <a
                    href="#text-buttons"
                    onClick={openSimilarizer}
                    style={stylingObject.ahref}
                    title="Find Similar Items"
                    label="at"
                  >
                    <Avatar className={classes1.avatar}>
                      <FileCopyOutlinedIcon fontSize="small" />
                    </Avatar>
                  </a>
                  {/* <a
                    href="#text-buttons"
                    style={stylingObject.ahref}
                    title="New Capture"
                    onClick={handleOwning}
                  >
                    <Avatar className={classes1.avatar}>
                      <ControlPointIcon fontSize="small" />
                    </Avatar>
                  </a> */}
                  {/* <a
                    href="#text-buttons"
                    onClick={handleSave}
                    style={stylingObject.ahref}
                    title="Save"
                  >
                    <Avatar className={classes1.avatar}>
                      <SaveIcon fontSize="small" />
                    </Avatar>
                  </a> */}
                </div>
              </>
            }
          >
            <DragIndicatorIcon style={{ color: '#002A45', fontWeight: 'bold' }} fontSize="small" />
          </CustomWidth2Tooltip>
          <Checkbox checked={selected} onChange={onToggle} className={classes1.tableCheck} />
        </div>
      </div>
    </TableCell>
  );

  return (
    <>
      {IS_DEBUG_MODE && (
        <ReactJson src={{ columns, rowsExport, rowsEdited }} collapsed={true} theme="monokai" />
      )}
      {load ? (
        <Loading />
      ) : (
        <div id="table-allo" style={{ padding: '0 10px' }}>
          <ExpressGrid rows={rowsEdited} columns={columns}>
            <DataTypeProvider
              for={['Text']}
              formatterComponent={(dataTable) =>
                RenderCellText({
                  filename,
                  dataTable,
                  translationCode,
                  toggleHeight,
                  onSearchText,
                })
              }
            />
            {displayColumns && (
              <DataTypeProvider
                for={displayColumns}
                formatterComponent={(dataTable) =>
                  RenderCellExcel({
                    dataTable,
                    toggleHeight,
                  })
                }
              />
            )}
            {targetColumn && (
              <DataTypeProvider
                for={[targetColumn]}
                formatterComponent={(dataTable) =>
                  RenderCellExcel({
                    dataTable,
                    translationCode,
                    toggleHeight,
                  })
                }
              />
            )}
            <DataTypeProvider for={['SubType']} formatterComponent={renderSubType} />
            <DataTypeProvider for={['kpi_lifecycle']} formatterComponent={LifecyleCellKpi} />
            <DataTypeProvider for={['History']} formatterComponent={allocationHistory} />
            <DataTypeProvider for={['clarify']} formatterComponent={renderClarify} />
            <DataTypeProvider for={['own']} formatterComponent={renderTableMulti} />
            <FilteringState
              columnExtensions={columns.map((c) => ({
                columnName: c.name,
                filteringEnabled: !['History', 'own', 'clarify'].includes(c.name),
              }))}
            />
            <PagingState
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              defaultCurrentPage={0}
              defaultPageSize={5}
              currentPage={currentPage}
              onCurrentPageChange={(p) => {
                sessionStorage.setItem(targetPage, p);
                setCurrentPage(p);
              }}
            />
            <SortingState />
            <IntegratedSorting />
            <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
            {/* selection */}
            <SelectionState selection={selectionRows} onSelectionChange={setSelectionRows} />
            <IntegratedSelection />
            <IntegratedPaging />
            <Table
              columnExtensions={columns.map((c) => ({
                columnName: c.name,
                wordWrapEnabled: toggleHeight,
              }))}
              tableComponent={MultiAllocTableComp}
              containerComponent={tableAllocatorComponent}
              headComponent={tableHeaderComponent}
            />
            <TableColumnResizing
              defaultColumnWidths={[
                ...columns.map((c) => {
                  if (c.name === 'own') return { columnName: 'own', width: 1100 };
                  if (c.name === 'Text') return { columnName: 'Text', width: textWidth };
                  if (c.name === targetColumn)
                    return { columnName: targetColumn, width: textWidth };
                  if (c.name === 'clarify') return { columnName: 'clarify', width: 80 };
                  if (c.name === 'id') return { columnName: 'id', width: 80 };
                  if (c.name === 'Hierarchy') return { columnName: c.name, width: 120 };
                  if (c.name === 'Original_Hierarchy') return { columnName: c.name, width: 200 };
                  if (c.name.includes('Heading_Level')) return { columnName: c.name, width: 200 };
                  return {
                    columnName: c.name,
                    width: Math.ceil(4000 / columns.length),
                  };
                }),
              ]}
            />
            {/* <TableHeaderRow showSortingControls cellComponent={TableHeaderCellWrap} /> */}
            <TableHeaderRow
              showSortingControls
              contentComponent={TableHeaderContentBase}
              // cellComponent={TableHeaderCellWrap}
            />
            <TableFilterRow />
            <TableColumnVisibility
              defaultHiddenColumnNames={
                JSON.parse(localStorage.getItem('storedColumnOwning')) || columnSelected
              }
              onHiddenColumnNamesChange={(arr) => {
                localStorage.setItem('storedColumnOwning', JSON.stringify(arr));
                setColumnSelected(arr);
              }}
            />
            <Toolbar rootComponent={toolbarComponent} />
            {/* selection */}
            <TableSelection showSelectAll cellComponent={SelectionCellComponent} />
            <ColumnChooser />
            {languages && languages.length > 0 && (
              <ToolBarPlugin
                languages={languages}
                languageCode={translationCode}
                languageOriginCode={languageOriginCode}
                name="Translation"
                title="Translation"
                onTranslate={(lang) => {
                  if (lang) setTranslationCode(lang);
                }}
              />
            )}
            <ToolBarPlugin name="Refresh" onClick={handleRefresh} />
            {/* <ToolBarPlugin name="Favorite" title="My allocations" onClick={handleToggleFavorite} /> */}
            <ToolBarPlugin
              name={toggleFavorite === false ? 'Favorite' : 'FavoriteRed'}
              title="My allocations"
              onClick={handleToggleFavorite}
            />
            <ToolBarPlugin name="Accept" onClick={() => handleAccepDecline('accept')} />
            <ToolBarPlugin name="Reject" onClick={handleDecline} />
            <ToolBarPlugin name="Dashboard" onClick={showDashboardHandler} title="KPIs" />
            {/* <ToolBarPlugin name="iSimilarizer" title="Find Similar Items" onClick={openSimilarizer} /> */}
            <ToolBarPlugin
              name="Height"
              title="Wrap Text"
              onClick={() => {
                setToggleHeight(!toggleHeight);
              }}
            />
            <ToolBarPlugin name="Download" onClick={startExport} />
            <PagingPanel
              pageSizes={pageSizes}
              containerComponent={(props) => (
                <>
                  <PagingPanel.Container {...props} className={classes1.pagingPanelContainer} />
                </>
              )}
            />
          </ExpressGrid>
          <GridExporter
            ref={exporterRef}
            rows={rowsExport}
            columns={displayColumnDown}
            onSave={onDownload}
          />
          <NavigationGotoNew
            setPageSize={setPageSize}
            fileLength={rowsEdited.length}
            pageSize={pageSize}
            dis={dis}
            setTemPage={setTemPage}
            handlePageNum={handlePageNum}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}

      {modalRow && (
        <ModalClarification
          requirement={JSON.parse(JSON.stringify(modalRow))}
          users={projectUsers}
          onClose={() => {
            setModalRow(undefined);
          }}
          onSend={createBulkOwning}
          onSendResolve={(newLf) => updateStateRows(rowsEdited, newLf)}
          projectId={projectId}
          workloadId={workloadId}
          filename={filename}
        />
      )}
      {openSim && rowsSim.length && <Similarizer inputSimList={rowsSim} />}
      {toggleDecline && (
        <ModalDecline
          requirements={selection}
          onClose={() => {
            setToggleDecline(false);
            setToggleCheckbox(false);
          }}
          onSend={createBulkOwning}
          onSendResolve={(newLf) => updateStateRows(rowsEdited, newLf)}
          projectId={projectId}
          workloadId={workloadId}
          filename={filename}
        />
      )}
      {toggleRawdata && (
        <ModalRawdata
          imagesList={imagesList}
          tablesList={tablesList}
          rawType={rawType}
          onClose={() => {
            setToggleRawdata(false);
          }}
        />
      )}
      {openAssignHistory && (
        <AssignModelHistory
          project_id={projectId}
          workload_id={workloadId}
          rows_allocation={paramHistory}
          onClose={() => {
            setOpenAssignHistory(false);
          }}
        />
      )}
      {openDashboard && (
        <ModalKpi
          project_id={projectId}
          workload_id={workloadId}
          file_name={filename}
          user_id={userJWT.user_id}
          title={filename}
          onClose={() => {
            setOpenDashboard(false);
          }}
        />
      )}
    </>
  );
}

export default OwningFlow;
