import { any } from 'prop-types';
import React, { useReducer, useState } from 'react';
import {
  excelInitialState,
  filesreducers,
  initialStateFiles,
} from './Reducers/combinerReducersDoc';
import excelFilereducers from './Reducers/excelFilereducer';

export interface DocAnalysisContextType {
  operation: any;
  setOperation: any;
  scopeSetting: any;
  setScopeSetting: any;
  selection: any;
  setSelection: any;
  fileSetting: any;
  setFileSettings: any;
  toggleTab: any;
  setToggleTab: any;
}

const DocAnalysisContext = React.createContext<DocAnalysisContextType>({
  operation: any,
  setOperation: any,
  scopeSetting: any,
  setScopeSetting: any,
  selection: any,
  setSelection: any,
  fileSetting: any,
  setFileSettings: any,
  toggleTab: {} as any,
  setToggleTab: (): any => {},
});

type Props = {
  children: any;
};

const DocAnalysisContextProvider = ({ children }: Props) => {
  const [operation, setOperation] = useState(0);
  const [scopeSetting, setScopeSetting] = useState([]);
  const [toggleTab, setToggleTab] = useState({
    left: '0rem',
  });
  const [selection, setSelection] = useState([]);
  const [fileSetting, setFileSettings] = useState({});
  //   const [containerSerive, setContainerService] = useState({});
  //   const [state, dispatch] = useReducer(excelFilereducers, excelInitialState);

  return (
    <DocAnalysisContext.Provider
      value={{
        operation,
        setOperation,
        scopeSetting,
        setScopeSetting,
        selection,
        setSelection,
        fileSetting,
        setFileSettings,
        toggleTab,
        setToggleTab,
      }}
    >
      {children}
    </DocAnalysisContext.Provider>
  );
};

export default DocAnalysisContext;

export { DocAnalysisContextProvider };
