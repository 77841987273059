/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ExcelDoc from '../../../../../assets/icons8-excel-48.png';
import PdfDoc from '../../../../../assets/pdf_ins.png';
import CsvDoc from '../../../../../assets/csv_icons.png';
import PptDoc from '../../../../../assets/icons8-powerpoint-48.png';
import DocxDoc from '../../../../../assets/icons8-microsoft-word-2019-48.png';
import docIcon from '../../../../../assets/docx_ico.png';
import pdfIcon from '../../../../../assets/pdf_ico.png';
import xlsIcon from '../../../../../assets/xlsx_ico.png';
import NERParamTarget from '../../../../../components/NERParamTarget';

const FileSetting = ({ dataoutput, targetColumnsAre, HandleReg }) => {
  return (
    <>
      <Grid spacing={0} style={{ padding: '1rem' }}>
        <Grid container spacing={3}>
          {dataoutput.map((c, indexIs) => {
            return (
              <>
                {c.FileType === 'Excel' && (
                  <>
                    <Grid item xs={6}>
                      {' '}
                      <img
                        src={xlsIcon}
                        alt="isens"
                        style={{
                          width: 20,
                          height: 25,
                          transform: 'translateY(8px)',
                        }}
                      />
                      <Tooltip title={c?.Blob} placement="top" arrow>
                        <b style={{ color: 'blue', wordWrap: 'break-word' }}>
                          {c?.Blob.substring(0, 25)}
                        </b>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          gap: '20px',
                          marginBottom: '2rem',
                        }}
                      >
                        <FormControl
                          variant="outlined"
                          size="small"
                          style={{ flexBasis: '50%', height: '2.5rem' }}
                        >
                          <InputLabel
                            htmlFor="outlined-age-native-simple"
                            style={{ color: '#002A45', fontWeight: 'bold' }}
                            size="small"
                          >
                            Sheet Column
                          </InputLabel>
                          <Select
                            native
                            label="Sheet  Name"
                            size="small"
                            // name={'sheetname'.concat(indexIs)}
                            name="sheetname"
                            style={{ width: '100%' }}
                            onChange={(e) => HandleReg(e, indexIs)}
                            defaultValue=""
                          >
                            <option aria-label="None" value="" disabled />
                            {c?.Sheet_names !== null
                              ? c?.Sheet_names.map((cap: string, index: number) => (
                                  <option key={`capture-type-${index}`} value={cap}>
                                    &nbsp;&nbsp;{cap}
                                  </option>
                                ))
                              : ''}
                          </Select>
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          size="small"
                          style={{ flexBasis: '50%', height: '2.5rem' }}
                        >
                          <InputLabel
                            htmlFor="outlined-age-native-simple"
                            style={{ color: '#002A45', fontWeight: 'bold' }}
                          >
                            Target Column
                          </InputLabel>
                          <Select
                            native
                            label="Target  Column"
                            name="targetColumn"
                            style={{ width: '100%' }}
                            onChange={(e) => HandleReg(e, indexIs)}
                            defaultValue=""
                          >
                            <option aria-label="None" value="" disabled />
                            <NERParamTarget
                              key={`NERParamTarget-${indexIs}`}
                              data={targetColumnsAre[indexIs]}
                            />
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  </>
                )}
                {c.FileType === 'CSV' && (
                  <>
                    <Grid item xs={6}>
                      <img
                        src={CsvDoc}
                        alt="isens"
                        style={{
                          width: 20,
                          height: 25,
                          transform: 'translateY(8px)',
                        }}
                      />
                      <Tooltip title={c?.Blob} placement="top" arrow>
                        <b style={{ color: 'blue', wordWrap: 'break-word' }}>
                          {c?.Blob.substring(0, 25)}
                        </b>
                      </Tooltip>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        style={{ flexBasis: '100%', height: '2.5rem' }}
                      >
                        <InputLabel
                          htmlFor="outlined-age-native-simple"
                          style={{ color: '#002A45', fontWeight: 'bold' }}
                        >
                          Column Name
                        </InputLabel>
                        <Select
                          native
                          label="Column Name"
                          name="columnname"
                          style={{ width: '100%' }}
                          onChange={(e) => HandleReg(e, indexIs)}
                          defaultValue=""
                        >
                          <option aria-label="None" value="" disabled />
                          {c?.ColumnNames !== null
                            ? c?.ColumnNames.map((cap: string, index: number) => (
                                <option key={`capture-type-${index}`} value={cap}>
                                  &nbsp;&nbsp;{cap}
                                </option>
                              ))
                            : ''}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
                {c.FileType === 'PDF' && (
                  <>
                    <Grid item xs={6}>
                      <img
                        src={PdfDoc}
                        alt="isens"
                        style={{
                          width: 20,
                          height: 25,
                          transform: 'translateY(8px)',
                        }}
                      />
                      <Tooltip title={c?.Blob} placement="top" arrow>
                        <b style={{ color: 'blue', wordWrap: 'break-word' }}>
                          {c?.Blob.substring(0, 25)}
                        </b>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      {''}
                    </Grid>
                  </>
                )}
              </>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default FileSetting;
