/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  CustomPaging,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import dayjs, { Dayjs } from 'dayjs';
import { Box, Button, IconButton, makeStyles, Paper, Typography } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CloseIcon from '@mui/icons-material/Close';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { Link, useParams } from 'react-router-dom';
import { w3cwebsocket } from 'websocket';
import isEqual from 'react-fast-compare';
import { workloadDelete, workloadGetAll, workloadGetbyPageNumber } from '../../api/workloads';
import getW3client from '../../api/wsWorkloads';
import useAppToken from '../../app/useAppToken';
import Loading from '../../components/Core/Loading';
import NavigationGoto from '../../components/Core/NavigationGoto';
import ProjectBreadCrumbs from '../../components/Core/ProjectBreadCrumbs';
// import LifecycleKpiContainer from '../KPI/LifecycleKpiContainer';
import TableComponent from '../../components/Datagrid/TableComponent';
import ToolBarPlugin from '../../components/Datagrid/ToolBarPlugin';
import { IS_DEBUG_MODE } from '../../const';
import { CreatedByUser, Artifact } from '../../react-app-env';
import downloadSourceFile from '../../tools/downloadSourceFile';
import downloadTranslateFile from '../../tools/downloadTranslateFile';
import formatDate, { formatLocal } from '../../tools/formatDate';
import Module1Context from '../Module1/Module1Context';
import NavigationGotoNew from '../../components/Core/NavigationGotoNew';
import MultiAllocTableComp from '../../components/Datagrid/MultiAllocTableComp';
import {
  tableAllocatorComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../components/Datagrid/TStyles';
import ProjectContext from './ProjectContext';

type wsMessageType = {
  id: number;
  project_id: number;
  app: string;
  action: string;
  created_by: CreatedByUser;
  created_at: string;
  file_number: any;
  progress_percent: number;
  updated_at: string;
  status: 'end' | 'start' | 'progress';
  errors: any[];
  resources: any[];
};

type responseWsWorkload = { activities: any[]; listIds: string };
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  tablescrol: {
    maxHeight: '70vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'none',
      borderRadius: '3px',
      backgroundColor: 'white',
    },

    '&::-webkit-scrollbar-thumb': {
      outline: 'none',
      backgroundColor: '#ccc',
    },
  },
  bloc: {
    minHeight: '400px',
    // height: '100%',
    borderRadius: 10,
    position: 'relative',
    border: 'solid 1px;',
    borderColor: theme.palette.primary.light,
    margin: 10,
    backgroundColor: '#EEF1F9',
    margin: 1,
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
  },
  heading2: {
    // backgroundColor: theme.palette.primary.main,
    // border: `1px solid ${theme.palette.primary.main}`,
    // borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'theme.palette.primary.main',
  },
  button: {
    margin: theme.spacing(1),
    fontSize: 10,
  },
  buttons: {
    textTransform: 'none',
  },
  iconSmall: {
    fontSize: 20,
  },
  testLink: {
    marginLeft: -10,
    color: 'blue',
  },
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
}));

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  const { value } = props;
  return (
    <Box
      style={{
        position: 'relative',
        display: 'inline-flex',
      }}
    >
      <CircularProgress variant="determinate" style={{ color: '#002A45' }} {...props} />
      <Box
        style={{
          top: '0',
          left: '0',
          bottom: '0',
          right: '0',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          style={{ fontWeight: 'bold', color: '#002A45' }}
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ProjectActivityContainer = () => {
  const classes = useStyles();
  const { userSetting } = useContext(ProjectContext);
  const { projectId } = useParams() as any;
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any[]>([] as any);
  const [selection, setSelection] = useState<any>([]);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const { appJWT } = useAppToken();
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [wsClient, setWsClient] = useState<w3cwebsocket | undefined>(undefined);
  const [open, setOpen] = React.useState(false);
  const [contextInterval, setContextInterval] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [dowError, setDowError] = useState();
  const [webSocketData, setWebSocketData] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterValues, setFilterValues] = React.useState({
    activityId: '',
    modules: [],
    action: [],
    fromDate: '',
    toDate: '',
  }); // filter values
  const handleFilter = () => {
    setOpenFilter(!openFilter);
  };

  // table scroll container style
  const tableScrollComponent: React.FunctionComponent<Table.CellProps> = (props) => {
    return <Table.Container {...props} className={classes.tablescrol} />;
  };

  // useEffect(() => {
  //   // eslint-disable-next-line @typescript-eslint/no-use-before-define

  // }, [currentPage]);

  const handleClose = () => {
    setOpen(false);
  };
  // const emptyModules: string[] = [];
  // const submodule: string[] = [];

  // if (userSetting) {
  //   userSetting.map((item) => {
  //     item.m1.length === 0 && emptyModules.push('module1');
  //     item.m2.length === 0 && emptyModules.push('module2');
  //     item.m3.length === 0 && emptyModules.push('module3');
  //     item.m4.length === 0 && emptyModules.push('module4');
  //     item.m5.length === 0 && emptyModules.push('module5');
  //     item.m6.length === 0 && emptyModules.push('module6');
  //     if (item.m1.length !== 0) {
  //       item?.m1.map((data) => {
  //         data?.label === 'Smart Requirement' &&
  //           submodule.push(...['capture', 'characterizer', 'allocator']);
  //       });
  //     }
  //     if (item.m2.length !== 0) {
  //       item?.m2.map((data) => {
  //         data?.label === 'Knowledge360' &&
  //           submodule.push(...['DocToDataset', 'DocToDoc', 'docdiffer', 'iner', 'SelfDoc']);
  //         data?.label === 'Translator' && submodule.push('Translator');
  //       });
  //     }
  //     if (item.m3.length !== 0) {
  //       item?.m3.map((data) => {
  //         data?.label === 'iQualimeter' && submodule.push('iQualimeter');
  //         data?.label === 'iVerifier' && submodule.push('iverifier');
  //       });
  //     }
  //     if (item.m6.length !== 0) {
  //       item?.m6.map((data) => {
  //         data?.label === 'Schedule Analysis' && submodule.push('ScheduleAnalysis');
  //       });
  //     }
  //   });
  // }

  const handleMessage = (message: { data: string }, activities: any[]) => {
    // console.log('handleMessage', ' message.data=', message.data, ' activities=', activities);
    const msgData: wsMessageType[] = JSON.parse(String(message.data));
    if (msgData && msgData.length > 0) {
      // console.log(msgData);
      // update rows
      const newRows = activities.map(
        (row: Artifact): Artifact => {
          const rowWebsocket = msgData.find((r) => r.id === row.id) as Artifact;
          if (rowWebsocket) {
            return {
              ...row,
              ...rowWebsocket,
            };
          }
          return row;
        }
      );
      if (!isEqual(activities, newRows)) setWebSocketData(newRows);
    }
  };

  // 1) getWorkLoadAll: get all activities
  // 2) filter activities only not ended
  // 3) return listIds for websocket
  // 4) get message from websocket for no ended activities
  // 5) handleMessage : extract status update rows
  // console.log(loading);
  const getWorkLoadAll = (
    currentPage: number,
    reset = 'none'
  ): Promise<void | responseWsWorkload> => {
    // if (compLoading) setLoading(true);
    setLoading(true);

    if (contextInterval) {
      clearTimeout(contextInterval);
    }
    const payload =
      reset === 'reset'
        ? {
            activityId: '',
            modules: [],
            action: [],
            fromDate: '',
            toDate: '',
          }
        : filterValues;
    return workloadGetbyPageNumber(projectId, currentPage + 1, payload)
      .then((activities) => {
        let listIds = [] as any[];
        const { activity_counts, workload } = activities;
        listIds = workload.filter((act) => act.status !== 'end').map((act) => act.id);
        setTotalCount(activity_counts[0].total_num_activity);
        setRows(workload);
        setSelection([]);
        setLoading(false);
        // console.log('workload', workload);
        // return Promise.resolve({ activities, listIds: '7605' });
        return Promise.resolve({ activities: workload, listIds: listIds.join(',') });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (webSocketData.length > 0 && currentPage === 0) {
      setRows(webSocketData);
    }
  }, [webSocketData]);

  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleRefresh();
    }, 1000);
  }, []);

  useEffect(() => {
    setLoading(true);
    setCurrentPage(0);
    getWorkLoadAll(0).then((response: responseWsWorkload) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      websocketFlow(response);
    });
    // if (emptyModules.length !== 0 || submodule.length !== 0) {
    //   getWorkLoadAll(currentPage).then((response: responseWsWorkload) => {
    //     // console.log('getWorkLoadAll response=', response);
    //     websocketFlow(response);
    //   });
    // }
    return () => {
      // don't forget to close
      wsClient?.close();
      console.log(`Websocket Close. ProjId: ${projectId}`);
    };
  }, [projectId, userSetting]);

  const websocketFlow = useCallback(
    (params: responseWsWorkload) => {
      try {
        const { activities, listIds } = params;
        if (wsClient) wsClient?.close();
        const wsClientNew = getW3client();
        // console.log('wsClientNew', wsClientNew);

        wsClientNew.onmessage = (message) => handleMessage(message, activities);
        setWsClient(wsClientNew);
        wsClientNew.onopen = () => {
          console.log('wsClientNew', 'open');
          wsClientNew.send(listIds);
        };
        wsClientNew.onerror = (error) => {
          console.error(error);
        };
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appJWT?.access_token, projectId]
  );
  useEffect(() => {
    if (pageSize > rows.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < rows.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);

  const handleCurentPage = (value: number) => {
    setLoading(true);
    if (contextInterval) {
      clearTimeout(contextInterval);
    }
    const contextIntervalNew = setTimeout(() => {
      getWorkLoadAll(value);
    }, 500);
    setContextInterval(contextIntervalNew);
    setCurrentPage(value);
  };
  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
    handleCurentPage(rel);
  };

  const handleRefresh = () => {
    getWorkLoadAll(currentPage);
  };

  const handleDeleteRows = () => {
    if (selection.length === 0) return;
    const rowsNotDeleted = rows.filter((row, index) => !selection.includes(index));
    const rowsDeleted = rows.filter((row, index) => selection.includes(index));
    const deleteIds = rowsDeleted.map((row) => row.id);
    workloadDelete(deleteIds)
      .then(() => {
        setRows(rowsNotDeleted);
        setSelection([]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const renderApp = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    return <span>{row.app.toLowerCase().replace(/\b(\w)/g, (s: any) => s.toUpperCase())}</span>;
  };

  const renderAction = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    if (row.action === 'allocator') {
      return <span>iAllocator</span>;
    }
    if (row.action === 'docdiffer') {
      return <span>Compare versions</span>;
    }
    if (row.action === 'iner' || row.action === 'ner') {
      return <span>Extract topics</span>;
    }
    if (row.action === 'DocToDataset') {
      return <span>Similarity analysis</span>;
    }
    if (row.action === 'DocToDoc') {
      return <span>Document similiarity</span>;
    }
    if (row.action === 'SelfDoc') {
      return <span>Find duplicates</span>;
    }
    if (row.action === 'icapture' || row.action === 'capture') {
      return <span>iCapturer</span>;
    }
    if (row.action === 'characterizer') {
      return <span>iCharacterizer</span>;
    }
    return <span>{row.action}</span>;
  };

  const renderStatusIcon = ({ value, row }: { value: any; row: Artifact }) => {
    if (value === 'end' && (row.action === 'Translator' || row.action === 'RexBook')) {
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
          size="small"
          onClick={() => handleDownload(row)}
        >
          <DownloadForOfflineIcon
            style={{ color: '#19AA6E', marginRight: 2 }}
            titleAccess="Download translated file"
          />
        </Button>
      );
    }
    if (value === 'end') {
      return (
        <>
          {row?.errors?.length > 0 ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
              size="small"
              onClick={() => redirectResultPage(row)}
            >
              <ErrorIcon style={{ color: '#DC3223', marginRight: 2 }} titleAccess="all done" />
              Error
            </Button>
          ) : (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              style={{ textDecoration: 'none' }}
              // eslint-disable-next-line max-len
              // to={`/projects/${projectId}/${row.app}/${
              //   row?.app[0] + row?.app[row?.app?.length - 1]
              // }/results/${row.id}?action=${row.action}`}
              // eslint-disable-next-line no-template-curly-in-string, max-len
              to={
                // eslint-disable-next-line no-nested-ternary
                ['DocToDataset', 'DocToDoc', 'docdiffer', 'iner', 'SelfDoc'].includes(row.action)
                  ? `/projects/${projectId}/${row.app}/${
                      row?.app[0] + row?.app[row?.app?.length - 1]
                    }/results/${row.id}?action=${`Knowledge360`}&service=${row.action}`
                  : ['ScheduleAnalysis'].includes(row.action)
                  ? `/projects/${projectId}/${row.app}/${
                      row?.app[0] + row?.app[row?.app?.length - 1]
                    }/results/${row.id}/Schedule_Analysis`
                  : `/projects/${projectId}/${row.app}/${
                      row?.app[0] + row?.app[row?.app?.length - 1]
                    }/results/${row.id}?action=${row.action}`
              }
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                }}
                size="small"
                onClick={() => redirectResultPage(row)}
              >
                <DoneAllIcon style={{ color: '#19AA6E', marginRight: 2 }} titleAccess="all done" />
                view
              </Button>
            </Link>
          )}
        </>
      );
    }

    if (value === 'progress')
      return (
        <div style={{ margin: 10, paddingLeft: 10, paddingRight: 10 }}>
          <CircularProgressWithLabel value={row.progress_percent} />
          {/* <PieProgress progress={row.progress_percent} /> */}
        </div>
      );

    if (value === 'start') {
      return (
        // <Link
        //   // eslint-disable-next-line max-len
        //   to={`/projects/${projectId}/${row.app}/results/${row.id}?action=${row.action}`}
        // >
        <HourglassEmptyIcon style={{ marginLeft: 25, marginRight: 25 }} titleAccess="started" />
        // </Link>
      );
    }
    return <></>;
  };
  /* eslint consistent-return: "error" */

  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle
        style={{
          display: 'flex',
          backgroundColor: '#002A45',
          justifyContent: 'flex-end',
          padding: '0px',
          height: '1.5rem',
        }}
        {...other}
      >
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  const redirectResultPage = (row: string) => {
    /* eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }] */
    // eslint-disable-next-line no-restricted-globals
    if (row?.errors.length > 0) {
      const error = `Error during process: ${row?.errors[0].msg}`;
      setOpen(true);
      setDowError(error);
      // const msg = row?.errors[0].msg;
      // alert(`Error during translation: ${msg}`, 'error');
    }
  };
  const handleDownload = (row: string) => {
    /* eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }] */
    // eslint-disable-next-line no-restricted-globals
    if (row?.errors.length > 0) {
      const error = `Error during translation ${row?.errors[0].msg}`;
      setOpen(true);
      setDowError(error);
      // const msg = row?.errors[0].msg;
      // alert(`Error during translation: ${msg}`, 'error');
      return;
    }
    const querystring = `files=${'translate_file'}`;
    if (row.action === 'Translator')
      downloadTranslateFile(`/download/resources/${row?.id}`, querystring);
    else
      downloadTranslateFile(
        `/download/userresources/${row?.id}/rexbook/${'projects'}`,
        querystring
      );
  };

  const handleDownload_2 = (row: string) => {
    // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    const querystring = `files=${'original_file'}`;
    downloadSourceFile(`/download/resources/${row?.id}`, querystring);
  };
  const renderStatusDetails = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    if (row.app === 'module1' || (row.app === 'module3' && row.status === 'end')) {
      const data = value?.map((res: any) => res.filename).join(' , ');
      const captureNames = value?.map((res: any) => res.settings?.nameOfCapture || '').join(' , ');
      return (
        <>
          <div title={captureNames}>{captureNames}</div>
          <div title={data}>{data}</div>
        </>
      );
    }
    if (row.app === 'module2' && (row.action === 'Translator' || row.action === 'rexbook')) {
      const data = value?.map((res: any) => res.filename).join(' , ');
      const captureNames = value?.map((res: any) => res.settings?.nameOfCapture || '')[0];
      if (row?.status === 'end' && row?.errors?.length === 0) {
        return (
          <>
            <div title="Translated language">{captureNames}</div>
            <Button
              component={Link}
              className={classes.buttons}
              onClick={() => {
                handleDownload_2(row);
              }}
            >
              <div className={classes.testLink} title="Download source file.">
                {data}
              </div>
            </Button>
          </>
        );
      }
      return (
        <>
          <div title="Translated language">{captureNames}</div>
          <div title="Source file.">{data}</div>
        </>
      );
    }
    if (row.app === 'module2') {
      const data = value?.map((res: any) => res.filename).join(' , ');
      const captureNames = value?.map((res: any) => res.settings?.nameOfCapture || '')[0];
      return (
        <>
          <div title={captureNames}>{captureNames}</div>
          <div title={data}>{data}</div>
        </>
      );
    }

    if (row.app === 'module6') {
      const data = value?.map((res: any) => res.filename).join(' , ');
      const captureNames = value?.map((res: any) => res.settings?.nameOfCapture || '')[0];
      return (
        <>
          <div title={captureNames}>{captureNames}</div>
          <div title={data}>{data}</div>
        </>
      );
    }
    if (row.app === 'module3' && row.action === 'iQualimeter') {
      const data = value?.map((res: any) => res.filename).join(' , ');
      const captureNames = value?.map((res: any) => res.settings?.nameOfCapture || '')[0];
      return (
        <>
          <div title={captureNames}>{captureNames}</div>
          <div title={data}>{data}</div>
        </>
      );
    }
    if (row.app === 'module3' && row.action === 'iverifier') {
      if (row.status === 'progress') return <>{row.last_file_processed}</>;
      if (row.status === 'start') return `Nb files ${row.file_number}`;
    }
    return <></>;
  };

  // if (loading) return <Loading />;

  if (!rows) return null;
  return (
    <div className={classes.root}>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} />
        <DialogContent dividers style={{ backgroundColor: '#DC3223' }}>
          <Typography style={{ fontSize: '1em', fontWeight: 'bold', color: 'white' }}>
            {dowError}
          </Typography>
        </DialogContent>
      </BootstrapDialog>
      {/* {IS_DEBUG_MODE && (
        <ReactJson src={{ rows: rows.slice(0, 10) }} collapsed={true} theme="monokai" />
      )} */}
      {/* <ProjectBreadCrumbs projectId={projectId} /> */}

      <Paper style={{ textAlign: 'center', marginTop: '1rem' }}>
        <Box className={classes.bloc}>
          {loading && (
            <Box
              style={{
                position: 'absolute',
                // height: '20%',
                // width: '20%',
                top: '50%',
                left: '50%',
                zIndex: 700,
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Loading />
            </Box>
          )}
          <Typography variant="subtitle1" className={classes.heading}>
            Project Activity
          </Typography>
          {rows.length > 0 ? (
            <>
              {' '}
              <Grid
                rows={rows}
                columns={[
                  { name: 'id', title: 'Id' },
                  { name: 'resources', title: 'Activity Details' },
                  { name: 'status', title: 'Status' },
                  { name: 'app', title: 'Module' },
                  { name: 'action', title: 'Action' },
                  { name: 'created_by', title: 'Username' },
                  { name: 'created_at', title: 'Date Creation' },
                  { name: 'updated_at', title: 'Date Update/End' },
                  // { name: 'dashboard', title: 'KPI' },
                ]}
              >
                <DataTypeProvider
                  for={['statusDetails']}
                  formatterComponent={({ value }) => <span title={value}>{value}</span>}
                />
                <DataTypeProvider
                  for={['created_by']}
                  formatterComponent={({ value }) => <>{value?.name}</>}
                />
                <DataTypeProvider
                  for={['created_at', 'updated_at']}
                  formatterComponent={({ value }) => <>{formatDate(value, formatLocal())}</>}
                />
                <DataTypeProvider for={['status']} formatterComponent={renderStatusIcon} />
                <DataTypeProvider for={['app']} formatterComponent={renderApp} />
                <DataTypeProvider for={['action']} formatterComponent={renderAction} />
                <DataTypeProvider for={['resources']} formatterComponent={renderStatusDetails} />
                <SelectionState selection={selection} onSelectionChange={setSelection} />
                {/* <FilteringState />
                <IntegratedFiltering /> */}
                <IntegratedSelection />
                <SortingState defaultSorting={[{ columnName: 'id', direction: 'desc' }]} />
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={(e) => {
                    handleCurentPage(e);
                  }}
                  pageSize={pageSize}
                />
                <CustomPaging totalCount={totalCount} />
                <Table
                  columnExtensions={[
                    { columnName: 'id', width: '8%' },
                    { columnName: 'action', width: '10%' },
                    { columnName: 'app', width: '10%' },
                    {
                      columnName: 'resources',
                      width: '30%',
                      wordWrapEnabled: true,
                    },
                    { columnName: 'created_at', width: '10%' },
                    { columnName: 'updated_at', width: '10%' },
                  ]}
                  tableComponent={MultiAllocTableComp}
                  // containerComponent={tableScrollComponent}
                  containerComponent={tableAllocatorComponent}
                  headComponent={tableHeaderComponent}
                />
                <TableColumnResizing
                  defaultColumnWidths={[
                    { columnName: 'id', width: '100px' },
                    { columnName: 'action', width: '200px' },
                    { columnName: 'app', width: '200px' },
                    {
                      columnName: 'resources',
                      width: '200px',
                    },
                    {
                      columnName: 'status',
                      width: '200px',
                    },
                    { columnName: 'created_at', width: '200px' },
                    {
                      columnName: 'created_by',
                      width: '200px',
                    },
                    { columnName: 'updated_at', width: '200px' },
                  ]}
                />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility defaultHiddenColumnNames={[]} />
                <TableSelection />
                <Toolbar rootComponent={toolbarComponent} />
                <ToolBarPlugin
                  name="Filter"
                  getWorkLoadAll={getWorkLoadAll}
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                />
                <ColumnChooser />
                {/* <TableFilterRow /> */}
                <ToolBarPlugin name="Delete" onClick={handleDeleteRows} />
                <ToolBarPlugin name="Refresh" onClick={handleRefresh} />
                <PagingPanel
                  // pageSizes={[10]}
                  containerComponent={(props) => (
                    <>
                      <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
                    </>
                  )}
                />
              </Grid>
              <NavigationGotoNew
                setPageSize={setPageSize}
                fileLength={rows.length}
                pageSize={pageSize}
                dis={dis}
                setTemPage={setTemPage}
                handlePageNum={handlePageNum}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />{' '}
            </>
          ) : (
            <>
              {!loading && (
                <>
                  <Grid
                    rows={rows}
                    columns={[
                      { name: 'id', title: 'Id' },
                      { name: 'resources', title: 'Activity Details' },
                      { name: 'status', title: 'Status' },
                      { name: 'app', title: 'Module' },
                      { name: 'action', title: 'Action' },
                      { name: 'created_by', title: 'Username' },
                      { name: 'created_at', title: 'Date Creation' },
                      { name: 'updated_at', title: 'Date Update/End' },
                      // { name: 'dashboard', title: 'KPI' },
                    ]}
                  >
                    <DataTypeProvider
                      for={['statusDetails']}
                      formatterComponent={({ value }) => <span title={value}>{value}</span>}
                    />
                    <DataTypeProvider
                      for={['created_by']}
                      formatterComponent={({ value }) => <>{value?.name}</>}
                    />
                    <DataTypeProvider
                      for={['created_at', 'updated_at']}
                      formatterComponent={({ value }) => <>{formatDate(value, formatLocal())}</>}
                    />
                    <DataTypeProvider for={['status']} formatterComponent={renderStatusIcon} />
                    <DataTypeProvider for={['app']} formatterComponent={renderApp} />
                    <DataTypeProvider for={['action']} formatterComponent={renderAction} />
                    <DataTypeProvider
                      for={['resources']}
                      formatterComponent={renderStatusDetails}
                    />
                    <SelectionState selection={selection} onSelectionChange={setSelection} />
                    {/* <FilteringState />
                <IntegratedFiltering /> */}
                    <IntegratedSelection />
                    <SortingState defaultSorting={[{ columnName: 'id', direction: 'desc' }]} />
                    <PagingState
                      currentPage={currentPage}
                      onCurrentPageChange={(e) => {
                        handleCurentPage(e);
                      }}
                      pageSize={pageSize}
                    />
                    <CustomPaging totalCount={totalCount} />
                    <Table
                      columnExtensions={[
                        { columnName: 'id', width: '8%' },
                        { columnName: 'action', width: '10%' },
                        { columnName: 'app', width: '10%' },
                        {
                          columnName: 'resources',
                          width: '30%',
                          wordWrapEnabled: true,
                        },
                        { columnName: 'created_at', width: '10%' },
                        { columnName: 'updated_at', width: '10%' },
                      ]}
                      tableComponent={MultiAllocTableComp}
                      // containerComponent={tableScrollComponent}
                      containerComponent={tableAllocatorComponent}
                      headComponent={tableHeaderComponent}
                    />
                    <TableColumnResizing
                      defaultColumnWidths={[
                        { columnName: 'id', width: '100px' },
                        { columnName: 'action', width: '200px' },
                        { columnName: 'app', width: '100px' },
                        {
                          columnName: 'resources',
                          width: '100px',
                        },
                        {
                          columnName: 'status',
                          width: '200px',
                        },
                        { columnName: 'created_at', width: '200px' },
                        {
                          columnName: 'created_by',
                          width: '200px',
                        },
                        { columnName: 'updated_at', width: '200px' },
                      ]}
                    />
                    <TableHeaderRow showSortingControls />
                    <TableColumnVisibility defaultHiddenColumnNames={[]} />
                    <TableSelection />
                    <Toolbar rootComponent={toolbarComponent} />
                    <ToolBarPlugin
                      name="Filter"
                      getWorkLoadAll={getWorkLoadAll}
                      filterValues={filterValues}
                      setFilterValues={setFilterValues}
                    />
                    {/* <ColumnChooser /> */}
                    {/* <TableFilterRow /> */}
                    {/* <ToolBarPlugin name="Delete" onClick={handleDeleteRows} /> */}
                    {/* <ToolBarPlugin name="Refresh" onClick={handleRefresh} /> */}
                  </Grid>
                </>
              )}
            </>
          )}
          {/* <ExpressGrid
            rows={rows}
            columns={[
              { name: 'id', title: 'Id' },
              { name: 'resources', title: 'Activity Details' },
              { name: 'status', title: 'Status' },
              { name: 'app', title: 'Module' },
              { name: 'action', title: 'Action' },
              { name: 'created_by', title: 'Username' },
              { name: 'created_at', title: 'Date Creation' },
              { name: 'updated_at', title: 'Date Update/End' },
              // { name: 'dashboard', title: 'KPI' },
            ]}
          >
            <DataTypeProvider
              for={['statusDetails']}
              formatterComponent={({ value }) => <span title={value}>{value}</span>}
            />
            <DataTypeProvider
              for={['created_by']}
              formatterComponent={({ value }) => <>{value?.name}</>}
            />
            <DataTypeProvider
              for={['created_at', 'updated_at']}
              formatterComponent={({ value }) => <>{formatDate(value, formatLocal())}</>}
            />
            <DataTypeProvider for={['status']} formatterComponent={renderStatusIcon} />
            <DataTypeProvider for={['app']} formatterComponent={renderApp} />
            <DataTypeProvider for={['action']} formatterComponent={renderAction} />
            <DataTypeProvider for={['resources']} formatterComponent={renderStatusDetails} />
            <SelectionState selection={selection} onSelectionChange={setSelection} />
            <FilteringState />
            <IntegratedFiltering />
            <IntegratedSelection />
            <SortingState defaultSorting={[{ columnName: 'id', direction: 'desc' }]} />
            <IntegratedSorting />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              // onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <CustomPaging totalCount={totalCount} />
            <Table
              columnExtensions={[
                { columnName: 'id', width: '8%' },
                { columnName: 'action', width: '10%' },
                { columnName: 'app', width: '10%' },
                {
                  columnName: 'resources',
                  width: '30%',
                  wordWrapEnabled: true,
                },
                { columnName: 'created_at', width: '10%' },
                { columnName: 'updated_at', width: '10%' },
              ]}
              tableComponent={MultiAllocTableComp}
              containerComponent={tableScrollComponent}
              headComponent={tableHeaderComponent}
            />
            <TableColumnResizing
              defaultColumnWidths={[
                { columnName: 'id', width: '200px' },
                { columnName: 'action', width: '200px' },
                { columnName: 'app', width: '200px' },
                {
                  columnName: 'resources',
                  width: '200px',
                },
                {
                  columnName: 'status',
                  width: '200px',
                },
                { columnName: 'created_at', width: '200px' },
                {
                  columnName: 'created_by',
                  width: '200px',
                },
                { columnName: 'updated_at', width: '200px' },
              ]}
            />
            <TableHeaderRow showSortingControls />
            <TableColumnVisibility defaultHiddenColumnNames={[]} /> */}
          {/* selection
            <TableSelection />
            <Toolbar rootComponent={toolbarComponent} />
            <ColumnChooser />
            <TableFilterRow />
            <ToolBarPlugin name="Delete" onClick={handleDeleteRows} />
            <ToolBarPlugin name="Refresh" onClick={handleRefresh} />
            <PagingPanel />
            <PagingPanel
              // pageSizes={[10]}
              containerComponent={(props) => (
                <>
                  <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
                </>
              )}
            />
          </ExpressGrid> */}
        </Box>
      </Paper>
    </div>
  );
};

export default ProjectActivityContainer;
