import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TreeItem, TreeView } from '@material-ui/lab';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import logoCurrentModule from '../../assets/m5_blue.png';
import ProjectBreadCrumbs from '../../components/Core/ProjectBreadCrumbs';
import ITracer from './components/ITracer';
import Module4Context from './Module4Context';
import TreeAllStyle, { useStyles } from '../../components/TreeMenu/TreeAllStyle';
import TreeArowIcons from '../../components/TreeMenu/TreeArowIcons';

function Module4() {
  const classes = TreeAllStyle();
  const arowClasses = TreeArowIcons();
  const { projectId } = useParams() as any;
  const { setSnackMsg, setSnackType } = useContext(Module4Context);

  useEffect(() => {
    // setSnackMsg('dddd');
    // setSnackType('success');
  }, []);

  return (
    <div className={classes.root}>
      <Grid container>
        {/* <Grid item xs={12}>
          <ProjectBreadCrumbs
            projectId={projectId}
            nodes={[
              {
                label: 'Performance & Maturity',
                to: `#`,
                icon: (
                  <img
                    src={logoCurrentModule}
                    alt="Performance & Maturity"
                    title="Performance & Maturity"
                  />
                ),
              },
            ]}
          />
        </Grid> */}
        <TreeView
          className={classes.treeRoot}
          defaultCollapseIcon={<ExpandMoreIcon className={arowClasses.ArowdownStyle} />}
          defaultExpandIcon={<KeyboardArrowUpOutlinedIcon className={arowClasses.ArowupStyle} />}
          defaultExpanded={['']}
        >
          <TreeItem
            nodeId="1"
            label={
              <Typography variant="subtitle1" className={classes.treeLabel}>
                <span>
                  <FindInPageOutlinedIcon className={classes.treeIcon} />
                </span>
                iTracer
              </Typography>
            }
            className={classes.treeitem}
            classes={{
              label: classes.label,
              selected: classes.label,
              content: classes.content,
              group: classes.group,
            }}
          >
            <ITracer />
          </TreeItem>
        </TreeView>
      </Grid>
    </div>
  );
}

export default Module4;
