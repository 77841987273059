import { Box } from '@material-ui/core';
import React from 'react';
import ScheduleAnalysis from './Components/ScheduleAnalysis';
import ScheduleMain from './Components/ScheduleMain';

const Module6 = React.memo(() => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: '1.5rem',
        margin: '1rem 1rem 1rem 1rem',
        border: '2px solid #002A45',
      }}
    >
      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box
          style={{
            flexBasis: '15%',
            padding: '0.6rem',
            color: 'white',
            backgroundColor: '#002A45',
            borderRadius: '30px',
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          Schedule Analysis
        </Box>
      </Box>

      <ScheduleMain />
    </Box>
  );
});

export default Module6;
