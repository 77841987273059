import release from './release.json';

export type Release = {
  branch: string;
  content?: string;
  images?: any[];
  date: string;
};
release.splice(0, 0, {
  branch: '62868-UI-M1-v12',
  content: 'Lifecyle multi , KPI',
  date: 'Mon Feb 14 2022',
  version: '0.0.23',
});
release.splice(0, 0, {
  branch: '62868-UI-M1-v12',
  content: 'Check duplication rationale',
  date: 'Tue Feb 15 2022',
  version: '0.0.24',
});
release.splice(0, 0, {
  branch: '62868-UI-M1-v12',
  content: 'M3 separator',
  date: 'Wed Feb 16 2022',
  version: '0.0.25',
});
release.splice(0, 0, {
  branch: '62868-UI-M1-v12',
  content: 'Project user add / delete : fix callback',
  date: 'Thu Feb 17 2022',
  version: '0.0.26',
});
release.splice(0, 0, {
  branch: '72743_MyInboxTab',
  content: 'My Inbox layout creation for Info, Event and Notification',
  date: 'Wed Feb 23 2022',
  version: '0.0.27',
});
release.splice(0, 0, {
  branch: '66833-hide-abs-RSC',
  content: 'Hide ABS for RSC entity project',
  date: 'Thu Feb 24 2022',
  version: '0.0.28',
});
release.splice(0, 0, {
  branch: 'NOWI_tableHeader_UI',
  content: 'TableHeader_UI',
  date: 'Tues March 1 2022',
  version: '0.0.29',
});
release.splice(0, 0, {
  branch: '30022_LeftNavigation_BottomUI',
  content: 'Left Navigation Bottom contact ,inbox ,myinfo_link_UI',
  date: 'Wed March 2 2022',
  version: '0.0.30',
});
release.splice(0, 0, {
  branch: '66833-hide-abs-RSC',
  content: 'Export m1 + Rename appToken to appJWT',
  date: 'Wed Mar 02 2022',
  version: '0.0.31',
});
release.splice(0, 0, {
  branch: 'NOWI_MyInfo_UI',
  content: 'MyInfo_appToken to azJWT',
  date: 'Thurs Mar 03 2022',
  version: '0.0.32',
});
release.splice(0, 0, {
  branch: '78005-duplication-OBS-RBS-ABS',
  content: 'Add validation duplication for OBS/ABS/PBS',
  date: 'Thu 03 Mar 2022',
  version: '0.0.33',
});
release.splice(0, 0, {
  branch: '62868-UI-M1-v13',
  content: 'nc and gap rationale in download',
  date: 'Thu 03 Mar 2022',
  version: '0.0.34',
});
release.splice(0, 0, {
  branch: '76984_contact_UI',
  content: 'contact table data and UI',
  date: 'Tues 08 Mar 2022',
  version: '0.0.35',
});

release.splice(0, 0, {
  branch: '73637_v1.2',
  content: 'char score as level : High, Medium, Low',
  date: 'Tue 08 Mar 2022',
  version: '0.0.36',
});
release.splice(0, 0, {
  branch: '78503_scrollbarWidth',
  content: 'scroll bar and capture timing',
  date: 'wed 09 Mar 2022',
  version: '0.0.37',
});
release.splice(0, 0, {
  branch: '73637_v1.4',
  content: 'Fix multi nc, gap, rationale + Modal on change compliance in modal analysis',
  date: 'wed 09 Mar 2022',
  version: '0.0.38',
});
release.splice(0, 0, {
  branch: '73637_v1.4',
  content: 'Name of Capture validation',
  date: 'Fri 11 Mar 2022',
  version: '0.0.39',
});
release.splice(0, 0, {
  branch: '66550_IcaptureandconnectUI',
  content: 'Capture and Connect UI',
  date: 'Mon 14 Mar 2022',
  version: '0.0.40',
});
release.splice(0, 0, {
  branch: '73637_v1.5',
  content: 'Allo score in level',
  date: 'Mon 14 Mar 2022',
  version: '0.0.41',
});
release.splice(0, 0, {
  branch: '66550_ScopeIcaptureUI',
  content: 'ScopeIcaptureUI',
  date: 'Tues 15 Mar 2022',
  version: '0.0.42',
});
release.splice(0, 0, {
  branch: '81061-pdf-navigation',
  content: 'New pdf navigation',
  date: 'Wed 16 Mar 2022',
  version: '0.0.43',
});
release.splice(0, 0, {
  branch: 'captureScope2.1',
  content: 'PDF Csv and Excel UI',
  date: 'Fri 18 Mar 2022',
  version: '0.0.44',
});
release.splice(0, 0, {
  branch: '81802_M1_Tabs',
  content: 'M1 Tabs Activation',
  date: 'Tues 22 Mar 2022',
  version: '0.0.45',
});
release.splice(0, 0, {
  branch: '33132_v1.0',
  content: 'iSimilarizer: latency issue resolved. Performer M2 access link added.',
  date: 'Wed March 23 2022',
  version: '0.0.46',
});

release.splice(0, 0, {
  branch: 'kpi-characterizer',
  content: 'Default lifecycle = characterized',
  date: 'Wed 23 Mar 2022',
  version: '0.0.47',
});
release.splice(0, 0, {
  branch: '81405-sbs',
  content: 'SBS columns + fix rule DIS Artifact_ID!=&&Artifact_Text!=',
  date: 'Tues 22 Mar 2022',
  version: '0.0.48',
});
release.splice(0, 0, {
  branch: 'DisableRSC_ReplaceTitle',
  content: 'DisableRSC_ReplaceTitle+CSV date',
  date: 'Friday 25 march 2022',
  version: '0.0.49',
});
release.splice(0, 0, {
  branch: 'captureNameConditions',
  content: 'captureNameConditions',
  date: 'Sat 26 march 2022',
  version: '0.0.50',
});
release.splice(0, 0, {
  branch: 'captureNameTwice',
  content: 'captureNameConditions',
  date: 'Sat 26 march 2022',
  version: '0.0.50',
});
release.splice(0, 0, {
  branch: '84078_M1TableTootltipUserRole',
  content: 'M1TableTootltipUserRoleUpdate',
  date: 'Fri 22 April 2022',
  version: '0.0.52',
});
release.splice(0, 0, {
  branch: 'docdifferAsync_v1.7',
  content: 'M2-docDiffer_UI_M2-iNER_UI',
  date: 'Fri 20 May 2022',
  version: '0.0.52',
});
release.splice(0, 0, {
  branch: 'docdifferAsync_v1.8',
  content: 'M2-docDiffer_columnNameAdded',
  date: 'Tue 24 May 2022',
  version: '0.0.53',
});
release.splice(0, 0, {
  branch: 'fix-selectall',
  content: 'fix select for assignment',
  date: 'Tue 30 May 2022',
  version: '0.0.54',
});
release.splice(0, 0, {
  branch: '92460',
  content: 'M2-docDiffer-color code added + M3-iQualimeter-Rules display full in dropdown',
  date: 'Tue 31 May 2022',
  version: '0.0.55',
});
release.splice(0, 0, {
  branch: '936689',
  content: 'M2-iSimilarizer - Text2Text - state save + M2-iSearcher state save',
  date: 'Mon 13 June 2022',
  version: '0.0.55',
});
release.splice(0, 0, {
  branch: '93187',
  content: 'M1-ProjectActivity name changes + M3-Rules API chnages',
  date: 'Tue 14 June 2022',
  version: '0.0.55',
});
release.splice(0, 0, {
  branch: 'm2-docToDatasetAsync_v1.4',
  content:
    'M2-qiSimilarizer docToDataset Async with Cature Name + Viewer Role - iNER & iSimilarizer-docToDataset and docDiffer activated',
  date: 'Tue 12 July 2022',
  version: '0.0.55',
});
release.splice(0, 0, {
  branch: 'm1-INer_Extract_Entities_New',
  content:
    'm1- extract entities document review modified + new columns added + change in m2-iSimilarizer Asynchro action names',
  date: 'Tue 19 July 2022',
  version: '0.0.55',
});
release.splice(0, 0, {
  branch: '105907',
  content:
    'Project configuration- Attributes (safety,category,testable) CRUD + display in m1-characterization having dropdown option',
  date: 'Fri 05 Aug 2022',
  version: '0.0.55',
});

release.splice(0, 0, {
  branch: '96014-UI-multi-allo',
  content: 'Refactor multi allocation UI + how to parse allocations',
  date: 'Fri 29 Jul 2022',
  version: '0.0.56',
});

release.splice(0, 0, {
  branch: '105067-multi-split',
  content: 'In capture add multi split + fix page number on reload data',
  date: 'Fri 05 Aug 2022',
  version: '0.0.57',
});

release.splice(0, 0, {
  branch: '105907_v1.0',
  content: 'Attribute config bug fixes + m1-characterization dropdown display change',
  date: 'Tue 09 Aug 2022',
  version: '0.0.57',
});
release.splice(0, 0, {
  branch: '105907_v1.1',
  content: 'Attribute default column added + m2- text2dataset columns added + m1 columns added',
  date: 'Wed 10 Aug 2022',
  version: '0.0.57',
});

release.splice(0, 0, {
  branch: '105907_v1.2',
  content: 'm1-characterization & owning columns added',
  date: 'Thru 11 Aug 2022',
  version: '0.0.58',
});

release.splice(0, 0, {
  branch: 'm2-iSimilarizerBugResolve',
  content: 'm2-iSimilarizer-DocToDoc & selfDoc Sheet name bug resolve for multisheet excel',
  date: 'Tue 23 Aug 2022',
  version: '0.0.58',
});
release.splice(0, 0, {
  branch: 'm1-AssignMultiAssignSelectAllBugFix',
  content:
    'm1- Assign MultiAssignment SelectAll pop up bug fix + iCapture SubType & Context editable',
  date: 'Thu 25 Aug 2022',
  version: '0.0.58',
});
release.splice(0, 0, {
  branch: 'fix-project-groups',
  content: 'fix group bfreakdown',
  date: 'Thu 25 Aug 2022',
  version: '0.0.58',
});
release.splice(0, 0, {
  branch: '105166-m1-display-table',
  content: 'display images & tables in cell of characterizer',
  date: 'Thu 25 Aug 2022',
  version: '0.0.59',
});
release.splice(0, 0, {
  branch: 'm1-newAssign_v1.2',
  content: 'm1-New Assign with Edit created',
  date: 'Wed 14 Sept 2022',
  version: '0.0.59',
});
release.splice(0, 0, {
  branch: 'm1-newAssign_v1.3',
  content: 'M2-iSimilarizer bug fixes + Configuration- attributes alligned in alphabetical order',
  date: 'Wed 14 Sept 2022',
  version: '0.0.60',
});
release.splice(0, 0, {
  branch: 'CreateProjectModalModification_and_VersionUpdate',
  content: 'CreateProjectModalModification_and_VersionUpdatedEverywhere',
  date: 'Thursday 15 Sept 2022',
  version: '0.0.61',
});
release.splice(0, 0, {
  branch: 'ConfigurationPagePrpertiesAdded_craetePageModalFixed',
  content: 'PagePrpertiesAddedConfigurationPage_craetePageModalFixed',
  date: 'Friday 16 Sept 2022',
  version: '0.0.62',
});
release.splice(0, 0, {
  branch: 'Analysis_ConformitynGapTans_NewButtonUiFixed',
  content: 'Analysis_ConformitynGapTabs_NewButtonUiFixed',
  date: 'Friday 16 Sept 2022',
  version: '0.0.63',
});
release.splice(0, 0, {
  branch: 'CaptureSettingUiFixea_ConfigPropertiesFixed',
  content: 'CaptureSettingUiFixea_ConfigPropertiesFixed',
  date: 'Friday 16 Sept 2022',
  version: '0.0.63',
});
release.splice(0, 0, {
  branch: '105067-multi-split-v3',
  content: 'multi split with limit 10 max',
  date: '5th Sept 2022',
  version: '0.0.64',
});

release.splice(0, 0, {
  branch: '110681-capture-new-owning-v3',
  content: 'add new a capture row',
  date: '5th Sept 2022',
  version: '0.0.65',
});
release.splice(0, 0, {
  branch: 'NewButtonUiNC_UnablePropertyTypeEntity',
  content: 'NewButtonUiNC_UnablePropertyTypeEntity_IsmilarizerButtonFixedInAllIallocatoreTabs',
  date: '20th Sept 2022',
  version: '0.0.66',
});
release.splice(0, 0, {
  branch: 'FinalPropertiesUi',
  content: 'FinalPropertiesUi_updated',
  date: 'wed 21th Sept 2022',
  version: '0.0.67',
});
release.splice(0, 0, {
  branch: 'm2_bugfix_v1.2',
  content:
    'm1-Assign iNER bug resolved, M2-DOcDiffer messageError added, m1-Capture csv,excel No Header - csv excel bug resolved',
  date: 'wed 21th Sept 2022',
  version: '0.0.67',
});
release.splice(0, 0, {
  branch: 'LeftNavigation_TopBar_ToolBar_cardViewUI',
  content: 'LeftNavigation_TopBar_ToolBar_cardViewUI_DetailView_tableFilterUI',
  date: 'Fri 30th Sept 2022',
  version: '0.0.68',
});
release.splice(0, 0, {
  branch: 'M1_M2_UxEnhancement',
  content: 'M1_M2_UxEnhancement',
  date: 'Fri 7th oct 2022',
  version: '0.0.69',
});
release.splice(0, 0, {
  branch: '118466',
  content: 'translation in iCapture for PDF + doc2doc dropdown validation added',
  date: 'Fri 14th oct 2022',
  version: '0.0.69',
});
release.splice(0, 0, {
  branch: 'LoaderandLeftNavigationUXUI',
  content: 'LoaderandLeftNavigationUXUImodifications',
  date: 'Thurs 20th oct 2022',
  version: '0.0.70',
});
release.splice(0, 0, {
  branch: 'captureSplitViewandQuerySearchEnhancement',
  content: 'captureSplitViewAdded and QuerySearchEnhancement',
  date: 'wed 2nd Nov 2022',
  version: '0.0.71',
});
release.splice(0, 0, {
  branch: '123695-iverifier-attributes',
  content: 'IVerifier attributes configuration page added',
  date: 'thu 10th Nov 2022',
  version: '0.0.72',
});
release.splice(0, 0, {
  branch: 'M2IsmilarizerTabsUI',
  content: 'M2IsmilarizerTabsUI and ux has bween fixed',
  date: 'tues 11th Nov 2022',
  version: '0.0.73',
});
release.splice(0, 0, {
  branch: 'iQualimeter_Async_task',
  content: 'Async Operation for iQualimeter',
  date: 'Fri 15th Nov 2022',
  version: '0.0.74',
});
release.splice(0, 0, {
  branch: 'IverifierRulemodalandWelcomebg',
  content:
    'IverfierConfigRuleModal added and welcome bg image updated and LeftNavigation auto hide',
  date: 'wed 23th Nov 2022',
  version: '0.0.75',
});
release.splice(0, 0, {
  branch: 'task-139833',
  content: 'Fixed UI issue for module 2',
  date: 'wed 23th Nov 2022',
  version: '0.0.76',
});
release.splice(0, 0, {
  branch: 'CapturedefViewandIverifierAdimRulesandFunctionModal',
  content: 'Fixed madal UI for Iverfier admin Template and set the capture default view',
  date: 'Tues 29th Nov 2022',
  version: '0.0.77',
});
release.splice(0, 0, {
  branch: 'task-142187',
  content:
    'When the capture PDF is english, Tetxt Original and context original columns should not be visible',
  date: 'Fri 02nd Dec 2022',
  version: '0.0.78',
});

release.splice(0, 0, {
  branch: 'iverifier-*',
  content: 'admin + config + module3',
  date: 'Wed 8th feb 2023',
  version: '0.0.79',
});
release.splice(0, 0, {
  branch: 'AdminPageHistoryDeleteandProjectViewLoader',
  content: 'adminHistory Delete btn implementation + Project View Loader fixed',
  date: 'Tues 2nd May 2023',
  version: '0.0.80',
});
release.splice(0, 0, {
  branch: 'enhancementInViewAllAllocations',
  content: 'View All Allocations functionality is working correctly',
  date: 'Wed 31st May 2023',
  version: '0.0.81',
});
release.splice(0, 0, {
  branch: 'addallocation',
  content:
    'added loading on save,search placeholder name changed,notification and validation on add, delete and save.',
  date: 'thu 1st June 2023',
  version: '0.0.82',
});
release.splice(0, 0, {
  branch: 'bugfixforCaptureFlow',
  content: 'Fixed bugs in captureFlow file',
  date: 'thu 1st June 2023',
  version: '0.0.83',
});
release.splice(0, 0, {
  branch: 'addallocation',
  content: 'fixed delete popover message, added modal confirmation in assign tab',
  date: 'Fri 2nd June 2023',
  version: '0.0.84',
});
release.splice(0, 0, {
  branch: 'assignSinglePop',
  content: 'm1-assign tab on icon popover singleAssign details',
  date: 'Fri 2nd June 2023',
  version: '0.0.85',
});
release.splice(0, 0, {
  branch: 'addallocation',
  content:
    'fixed delete popover message, added modal confirmation in chara and capture tab, added tooltip, fixed overlap issue',
  date: 'Tue 6th June 2023',
  version: '0.0.86',
});
release.splice(0, 0, {
  branch: 'hierarchy_levels_feature_add',
  content: 'Add hierarchy level feature',
  date: 'Tue 9th June 2023',
  version: '0.0.87',
});
release.splice(0, 0, {
  branch: 'filter_columnChossert_enhancement',
  content: 'changes in filter for translation and column chosser download enhancement',
  date: 'Tue 13th June 2023',
  version: '0.0.88',
});
release.splice(0, 0, {
  branch: 'M1DefectFix',
  content: 'validation handled',
  date: 'Tue 13th June 2023',
  version: '0.0.89',
});
release.splice(0, 0, {
  branch: 'M1DefectFix',
  content: 'capture report, proper success message',
  date: 'wed 14th June 2023',
  version: '0.0.90',
});
release.splice(0, 0, {
  branch: 'Edit_Table_Row_Pop_enhancement',
  content: 'Edit Row Enhancement',
  date: 'wed 14th June 2023',
  version: '0.0.91',
});
release.splice(0, 0, {
  branch: 'OwningNewUIDesign',
  content: 'OwningNewUIDesign',
  date: 'Thr 15th June 2023',
  version: '0.0.92',
});
release.splice(0, 0, {
  branch: 'M1DefectFix',
  content: 'saving the column checks for capture char and assign',
  date: 'Fri 16th June 2023',
  version: '0.0.93',
});
release.splice(0, 0, {
  branch: 'AnalyzeNewUI',
  content: 'new UI',
  date: 'Wed 21st June 2023',
  version: '0.0.94',
});
release.splice(0, 0, {
  branch: 'confirmation_for_Module1_character_allocator',
  content: 'Modal confirmation added to m1',
  date: 'Wed 21st June 2023',
  version: '0.0.95',
});
release.splice(0, 0, {
  branch: 'enhancement_for_viewAll',
  content: 'enhacement in viewALL allocation',
  date: 'Thu 22st June 2023',
  version: '0.0.96',
});
release.splice(0, 0, {
  branch: ' ViewALLUIEnhancement',
  content: 'view UI enhancement',
  date: 'Thu 27st June 2023',
  version: '0.0.97',
});
release.splice(0, 0, {
  branch: 'analysisFix',
  content: 'Analysis Fix',
  date: 'Thu 13th July 2023',
  version: '0.0.98',
});
release.splice(0, 0, {
  branch: 'fastM1_v1.0',
  content: 'fast m1 for csv,excel and pdf files',
  date: 'Fri 24th Nov 2023',
  version: '0.0.99',
});
release.splice(0, 0, {
  branch: 'knowlege_360_fix',
  content: 'css fix for knowledge360',
  date: 'Tue 5th Dec 2023',
  version: '0.1.0',
});
release.splice(0, 0, {
  branch: 'bugResolution_1.0',
  content: 'm1-Analysis modal - GAP,NC Rationale - mandotary bug resolve',
  date: 'Tue 5th Dec 2023',
  version: '0.1.1',
});
release.splice(0, 0, {
  branch: 'bugResolution_1.1',
  content: 'm3-iVerifier- if no rules message display',
  date: 'Tue 5th Dec 2023',
  version: '0.1.2',
});
release.splice(0, 0, {
  branch: 'M2SimilarityTableIssueandtableUI',
  content: 'M2SimilarityTableIssue and tableUI for Doc and compareVersion',
  date: 'Tue 5th Dec 2023',
  version: '0.1.3',
});
release.splice(0, 0, {
  branch: 'ownedComment',
  content: 'Owned removed',
  date: 'Thr 7th Dec 2023',
  version: '0.1.4',
});
release.splice(0, 0, {
  branch: 'iQualimeter_noReq_added',
  content: 'iQualimeter - noReq - added - extra required objects coming from payload added',
  date: 'Thr 7th Dec 2023',
  version: '0.1.5',
});
release.splice(0, 0, {
  branch: 'iQualimeter_noHeaderFix',
  content: 'iQualimeter - No Header bug fixed',
  date: 'Mon 18th Dec 2023',
  version: '0.1.6',
});
release.splice(0, 0, {
  branch: 'assignTab_selectAll_v1.0',
  content: 'm1-assign Tab : slect all allocation in a single toolbar button click added',
  date: 'Wed 10th Jan 2024',
  version: '0.1.7',
});
release.splice(0, 0, {
  branch: 'projects_API_modification',
  content: 'API optimization for workloads and projects',
  date: 'Wed 10th Jan 2024',
  version: '0.1.8',
});
release.splice(0, 0, {
  branch: 'analyzeTab_selectAll_v1.1',
  content: 'm1-analyze tab having selectall compliant all direct button',
  date: 'Wed 10th Jan 2024',
  version: '0.1.9',
});
release.splice(0, 0, {
  branch: 'analyzeTab_selectAll_v1.3',
  content: 'm1-analyze tab having selectall compliant all direct button',
  date: 'Fri 12th Jan 2024',
  version: '0.1.10',
});
release.splice(0, 0, {
  branch: 'editAssign_1.0',
  content: 'Editing in Assign tab for comments,subType and others done',
  date: 'Thr 18th Jan 2024',
  version: '0.1.11',
});
release.splice(0, 0, {
  branch: 'editAssign_1.1',
  content: 'in m1- isens_id column width increased with commenting all assign at a time code',
  date: 'Fri 19th Jan 2024',
  version: '0.1.12',
});
release.splice(0, 0, {
  branch: 'workspace_for_user',
  content: 'User Workspace for Module two service',
  date: 'Fri 29th Jan 2024',
  version: '0.1.13',
});
release.splice(0, 0, {
  branch: 'project_Loading_optimization',
  content: 'Project API optimization',
  date: 'Fri 31th Jan 2024',
  version: '0.1.14',
});
release.splice(0, 0, {
  branch: 'project_Loading_optimization',
  content: 'Project API optimization',
  date: 'Fri 31th Jan 2024',
  version: '0.1.14',
});
release.splice(0, 0, {
  branch: 'Apsys1.0',
  content: 'm2 module - Apsys : Rex New Component added',
  date: 'Thr 1st Feb 2024',
  version: '0.1.14',
});
release.splice(0, 0, {
  branch: 'AppTopBarHelpButton',
  content: 'App home Page: TopBar - Help button added with provided texts',
  date: 'Thr 1st Feb 2024',
  version: '0.1.14',
});
release.splice(0, 0, {
  branch: 'analyzeNewModal',
  content: 'm1-Smart requirement management : anayze Tab - new modal UI with iSimilarizer in side',
  date: 'Fri 2nd Feb 2024',
  version: '0.1.15',
});
release.splice(0, 0, {
  branch: 'analyzeNewModal_V1.0',
  content: 'm1-Smart requirement management : anayze Tab - new Modal with reject button',
  date: 'Wed 7th Feb 2024',
  version: '0.1.16',
});
release.splice(0, 0, {
  branch: 'analyzeNewModal_V1.1',
  content: 'm1-Smart requirement management : anayze Tab - Popup - Bugs resolution',
  date: 'Thur 8th Feb 2024',
  version: '0.1.17',
});
release.splice(0, 0, {
  branch: 'analyzeNewModal_V1.2',
  content: 'm1-Smart requirement management : anayze Tab - Popup - validation bug resolution',
  date: 'Thur 9th Feb 2024',
  version: '0.1.18',
});
release.splice(0, 0, {
  branch: 'analyzeNewModal_bugFix',
  content: 'm1-Smart requirement management : anayze Tab - Popup - validation bug resolution',
  date: 'Thur 14th Feb 2024',
  version: '0.1.22',
});
release.splice(0, 0, {
  branch: 'projectFolderDelaccess_1.0',
  content: 'access to user to delete folder , delete file  edit()rename folder',
  date: 'Thur 29th Feb 2024',
  version: '0.1.23',
});
release.splice(0, 0, {
  branch: 'versionupdateandExtractEntDis',
  content: 'version updated and Extract Entity disabled',
  date: 'Tues 25th june 2024',
  version: '0.1.24',
});

release.splice(0, 0, {
  branch: 'OBS-DACH',
  content: 'Code changes for obs DACH',
  date: 'Tues 26th july 2024',
  version: '0.1.25',
});

release.splice(0, 0, {
  branch: 'bug_resol_346399_346197',
  content: 'Bug resolution 346399 & 346197',
  date: 'Fri 9th Aug 2024',
  version: '0.1.26',
});

release.splice(0, 0, {
  branch: 'bug_resol_defect_336691',
  content: 'Bug resolution defect_336691',
  date: 'Mon 12th Aug 2024',
  version: '0.1.27',
});

release.splice(0, 0, {
  branch: 'Task_347143_rexbook_filterAdded_1.0',
  content: 'Filter added in m2- rexbook Part A',
  date: 'Thr 22nd Aug 2024',
  version: '0.1.28',
});

release.splice(0, 0, {
  branch: 'Task_347143_rexbook_filterAdded_1.1',
  content: 'Filter added in m2- rexbook Part B - user profile',
  date: 'Mon 26th Aug 2024',
  version: '0.1.29',
});

release.splice(0, 0, {
  branch: 'performernotallow',
  content: 'Defect 361436: iVerifier: Performer is allowed to edit the specific rules',
  date: 'Mon 17th Sept 2024',
  version: '0.1.30',
});

release.splice(0, 0, {
  branch: 'rexchatrk1.3',
  content: 'user profile six layer filter pass to rexchat',
  date: 'Mon 17th Sept 2024',
  version: '0.1.31',
});

export default release as Release[];
