import { InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import withPlugin, { WithPluginProps } from './withPlugin';

const SelectPlugin = ({ onChange, items }: WithPluginProps) => {
  const handleChange = (event) => {
    if (onChange) onChange(event);
  };
  return (
    <>
      <InputLabel id="similarizer-select-label" style={{ fontWeight: 700, fontSize: 16 }}>
        Input Text:&nbsp;&nbsp;
      </InputLabel>
      <Select
        labelId="similarizer-select-label"
        id="similarizer-input-select"
        onChange={handleChange}
        defaultValue="0"
        label="Input"
        style={{ width: '400px' }}
      >
        {items?.map((item, index) => (
          <MenuItem title={item} value={index} key={`item-${index}`}>
            {item.length > 200 ? `${item.substring(0, 200)}...` : item}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const SelectPluginHoc = withPlugin(SelectPlugin);

export default SelectPluginHoc;
