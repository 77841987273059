/* eslint-disable import/prefer-default-export */
import axios from './axios';
import { BACKEND_URL } from '../const';

const getM6FilterConditions = async (): Promise<any> => {
  try {
    // const response = await axios.get(
    //   `http://isens-pm-01.westeurope.cloudapp.azure.com:8000/filter_conditions`
    // );
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m6-schedule-analysis`, {});
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export { getM6FilterConditions };
