/**
 * This component renders a Apsys Page
 *  Get all Data related to Apsys in the Graphical format by using Regression Graph
 *  Css also fixed here
 * @param {string} component No params.
 * @returns {ReactNode} A React element that renders Apsys Page.
 *  Copyright @ Alstom 2023
 *  auther @Ranjan
 */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
// import Tooltip as NewTooltips, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useHistory, useParams } from 'react-router-dom';
import { Theme, useTheme } from '@material-ui/core/styles';
import { makeStyles, withStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import {
  Avatar,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  HeatMapComponent,
  Inject,
  Legend as LegendHeat,
  Tooltip as TooltipHeat,
} from '@syncfusion/ej2-react-heatmap';
import { Workload3Create } from 'WorkloadType';
import MultiSelect from 'react-multi-select-component';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import FontDownloadOutlinedIcon from '@material-ui/icons/FontDownloadOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ReactJson from 'react-json-view';
import ApsysGraph from './ApsysGraph';
import { getAllWaveData, getApsysFilterData } from '../../../api/module2';
import NoResultsFound from '../../../components/Core/NoResultsFound';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  contain: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
    padding: '5px',
  },
  formControlMultiTextlebel: {
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  formControlMulti: {
    // margin: theme.spacing(1),
    minWidth: '100%',
    maxWidth: '50%',
  },
  formControlMultiFile: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
  },
  formControlMulti1: {
    margin: theme.spacing(1),
    minWidth: 220,
    maxWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tabstyle: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '33px',
    background: '#DC3223 0% 0% no-repeat padding-box',
    color: '#fff !important',
    fontSize: 14,
    fontWeight: 700,
  },
  MultiSelect: {
    // border: '2px solid red',
    // opacity: 1,
    '& .dropdown-container': {
      border: '1px solid #002A45',
      opacity: 1,
      // position: 'fixed',
    },
    '& .gray': {
      color: '#002A45 !important',
    },
    '& .dropdown-container[aria-disabled="true"]': {
      // overFlow: 'visible',
      // position: 'fixed',
      // backgroundColor: '#C4D4EC',
    },
    // '& .dropdown-content': {
    //   // overflowY: 'auto',
    //   backgroundColor: 'red',
    //   position: 'initial',
    // },
    '& .select-menu-outer': {
      position: 'fixed',
    },
    // '& .rmsc': {
    //   borderColor: 'white',
    // },
    // '& .dropdown-heading': {
    //   color: 'red',
    // },
  },
  customScrollbar: {
    '&::-webkit-scrollbar': {
      width: '8px', // Set the width of the scrollbar
    },
    // '&::-webkit-scrollbar-thumb': {
    //   background: '#888', // Color of the thumb
    //   borderRadius: '5px', // Rounded corners for the thumb
    // },
    // '&::-webkit-scrollbar-thumb:hover': {
    //   background: '#555', // Color of the thumb on hover
    // },
  },
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
}));

function RexApsys() {
  const classes = useStyles();
  const theme = useTheme();
  // const [pageSizes] = useState([5, 10, 20, 50, 100]);

  const [selection, setSelection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFilterData, setLoadingFilterData] = useState(true); // for filter selection
  const [display, setDisplay] = useState(false);

  const [filterDocumentProfiles, setFilterDocumentProfiles] = useState<any[]>([]);
  const [filterProductLine, setFilterProductLine] = useState<any[]>([]);
  const [filterSite, setFilterSite] = useState<any[]>([]);
  const [filterWave, setFilterWave] = useState<any[]>([]);
  const [filterDomain, setFilterDomain] = useState<any[]>([]);
  const [filterXaxis, setFilterXaxis] = useState<any[]>([]);
  const [filterYaxis, setFilterYaxis] = useState<any[]>([
    { label: 'Performance', value: 'Performance' },
  ]);

  const [filterDocumentProfilesDefault, setFilterDocumentProfilesDefault] = useState<any>();
  const [productEntitiesDefault, setProductEntitiesDefault] = useState<any>();
  const [siteEntitiesDefault, setSiteEntitiesDefault] = useState<any>();
  const [waveEntitiesDefault, setWaveEntitiesDefault] = useState<any>();
  const [domainEntitiesDefault, setDomainEntitiesDefault] = useState<any>();
  const [xaxisEntitiesDefault, setXaxisEntitiesDefault] = useState<any>();
  const [yaxisEntitiesDefault, setyaxisEntitiesDefault] = useState<any>();

  const [selectedProductEntities, setSelectedProductEntities] = useState([]);
  const [selectedSiteEntites, setSelectedSiteEntites] = useState([]);
  const [selectedWaveEntities, setSelectedWaveEntities] = useState([]);
  const [selectedDomainEntites, setSelectedDomainEntites] = useState([]);
  const [selectedXaxisEntities, setSelectedXaxisEntities] = useState([]);
  const [selectedYaxisEntities, setSelectedYaxisEntities] = useState([]);

  const [finalPayloadHere, setFinalPayloadHere] = useState([]);
  const [payloadData, setPayloadData] = useState<any>();

  const [selectedDocumentProfiles, setSelectedDocumentProfiles] = useState([]);

  const [datasetResultScore, setDatasetResultScore] = useState<any>();

  let dataSetRawData: any;
  useEffect(() => {
    // to take the icose Dataset project dropdown
    getApsysFilterData(['*'], ['*'], ['*'], ['*'], ['*'], 'Performance')
      .then((payload: any) => {
        // console.log('payload', payload);
        setLoadingFilterData(false);
        if (payload) {
          // console.log(payload);
          const prod: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.lines) {
            const item = payload.filters.lines[i];
            prod.push({
              label: item,
              value: item,
            });
          }
          setFilterProductLine(prod);
          setProductEntitiesDefault(prod);
          const site: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.sites) {
            const item = payload.filters.sites[i];
            site.push({
              label: item,
              value: item,
            });
          }
          setFilterSite(site);
          setSiteEntitiesDefault(site);
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.waves) {
            const item = payload.filters.waves[i];
            ent.push({
              label: item,
              value: item,
            });
          }
          setFilterWave(ent);
          setWaveEntitiesDefault(ent);
          // another one for platform
          const plat: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.domain) {
            const item = payload.filters.domain[i];
            plat.push({
              label: item,
              value: item,
            });
          }
          // console.log(employees);
          setFilterDomain(plat);
          setDomainEntitiesDefault(plat);
          // another one for projects
          const pro: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.xAxis) {
            const item = payload.filters.xAxis[i];
            pro.push({
              label: item,
              value: item,
            });
          }
          // console.log(employees);
          setFilterXaxis(pro);
          setXaxisEntitiesDefault(pro);
          // another one for projects
          const docpro: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.yAxis) {
            const item = payload.filters.yAxis[i];
            docpro.push({
              label: item,
              value: item,
            });
          }
          // console.log(employees);
          setFilterYaxis([{ label: 'Performance', value: 'Performance' }]);
          setyaxisEntitiesDefault([{ label: 'Performance', value: 'Performance' }]);
          // seperate code
          // setPayloadHere(payload);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);
  useEffect(() => {
    if (selectedWaveEntities.length > 1) {
      if (selectedDomainEntites.length > 1) {
        setSelectedDomainEntites([selectedDomainEntites[selectedDomainEntites.length - 1]]);
      }
    }
  }, [selectedDomainEntites]);
  // useEffect(() => {
  //   if (selectedWaveEntities.length > 2) {
  //     setSelectedWaveEntities([selectedWaveEntities[selectedWaveEntities.length - 1]]);
  //   }
  // }, [selectedWaveEntities]);
  // useEffect(() => {
  //   if (selectedDomainEntites.length > 1) {
  //     setSelectedDomainEntites([selectedDomainEntites[selectedDomainEntites.length - 1]]);
  //   }
  // }, [selectedDomainEntites]);
  useEffect(() => {
    if (selectedXaxisEntities.length > 1) {
      setSelectedXaxisEntities([selectedXaxisEntities[selectedXaxisEntities.length - 1]]);
    }
  }, [selectedXaxisEntities]);

  function onChangeProductLineEntity(option: any) {
    setSelectedProductEntities(option);
    const extra = 'noChange_prodEntity';
    // console.log('option', option);
    // if (option.length === 1) {
    //   setFilterDomain([]);
    //   setFilterXaxis([]);
    //   setFilterYaxis([]);
    // }
    // console.log('option', option);
    if (option.length > 0) {
      console.log('hi');
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      multifilterCall(
        option,
        selectedSiteEntites,
        selectedWaveEntities,
        selectedDomainEntites,
        selectedXaxisEntities,
        selectedYaxisEntities,
        extra
      );
    }
  }
  function onChangeSiteEntity(option: any) {
    setSelectedSiteEntites(option);
    const extra = 'noChange_siteEntity';
    // // console.log('option', option);
    // if (option.length === 1) {
    //   setFilterDomain([]);
    //   setFilterXaxis([]);
    //   setFilterYaxis([]);
    // }
    // if (option.length > 1) {
    //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
    //   multifilterCall(
    //     option,
    //     selectedDomainEntites,
    //     selectedXaxisEntities,
    //     selectedYaxisEntities,
    //     extra
    //   );
    // }
    if (selectedProductEntities.length > 0 || option.length > 0) {
      multifilterCall(
        selectedProductEntities,
        option,
        selectedWaveEntities,
        selectedDomainEntites,
        selectedXaxisEntities,
        selectedYaxisEntities,
        extra
      );
    }
  }

  function onChangeWaveEntity(option: any) {
    setSelectedWaveEntities(option);
    const extra = 'noChange_waveEntity';
    // console.log('option', option);
    // if (option.length === 1) {
    //   setFilterDomain([]);
    //   setFilterXaxis([]);
    //   setFilterYaxis([]);
    // }
    // if (option.length > 1) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // multifilterWave(option);
    // }
    if (selectedProductEntities.length > 0 || selectedSiteEntites.length > 0 || option.length > 0) {
      multifilterCall(
        selectedProductEntities,
        selectedSiteEntites,
        option,
        selectedDomainEntites,
        selectedXaxisEntities,
        selectedYaxisEntities,
        extra
      );
    }
  }
  // console.log('selectedWaveEntities', selectedWaveEntities);

  function onChangeDomainEntity(option: any) {
    setSelectedDomainEntites(option);
    const extra = 'noChange_domain';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // if (selectedWaveEntities.length > 1) {
    //   multifilterCall(
    //     selectedWaveEntities,
    //     option,
    //     selectedXaxisEntities,
    //     selectedYaxisEntities,
    //     extra
    //   );
    // } else {
    //   console.log('domain shuld be one');
    // }
    if (
      selectedProductEntities.length > 0 ||
      // selectedSiteEntites.length > 0 &&
      selectedWaveEntities.length > 0
    ) {
      multifilterCall(
        selectedProductEntities,
        selectedSiteEntites,
        selectedWaveEntities,
        option,
        selectedXaxisEntities,
        selectedYaxisEntities,
        extra
      );
    }
  }

  function onChangeXaxisEntity(option: any) {
    setSelectedXaxisEntities(option);
    const extra = 'noChange_xAxis';

    // if (selectedWaveEntities.length > 1 && selectedDomainEntites.length === 1) {
    //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
    //   multifilterCall(
    //     selectedWaveEntities,
    //     selectedDomainEntites,
    //     option,
    //     selectedYaxisEntities,
    //     extra
    //   );
    // }
    if (
      selectedProductEntities.length > 0 ||
      // selectedSiteEntites.length > 0 &&
      (selectedWaveEntities.length > 0 && selectedDomainEntites.length > 0)
    ) {
      multifilterCall(
        selectedProductEntities,
        selectedSiteEntites,
        selectedWaveEntities,
        selectedDomainEntites,
        option,
        selectedYaxisEntities,
        extra
      );
    }
  }

  function onChangeYaxisEntity(option: any) {
    setSelectedYaxisEntities(option);
    const extra = 'noChange_yAxis';
    // if (
    //   selectedWaveEntities.length > 1 &&
    //   selectedDomainEntites.length === 1 &&
    //   selectedXaxisEntities.length === 1
    // ) {
    //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
    //   multifilterCall(
    //     selectedWaveEntities,
    //     selectedDomainEntites,
    //     selectedXaxisEntities,
    //     option,
    //     extra
    //   );
    // }
    if (
      selectedProductEntities.length > 0 ||
      // selectedSiteEntites.length > 0 &&
      (selectedWaveEntities.length > 0 &&
        selectedDomainEntites.length > 0 &&
        selectedXaxisEntities.length > 0)
    ) {
      multifilterCall(
        selectedProductEntities,
        selectedSiteEntites,
        selectedWaveEntities,
        selectedDomainEntites,
        selectedXaxisEntities,
        option,
        extra
      );
    }
  }
  // mutisdelection call
  function multifilterCall(
    prod: any,
    site: any,
    wave: any,
    dom: any,
    xAxis: any,
    yAxis: any,
    extra: any
  ) {
    console.log('wave after mutli', wave);
    const prodVal: any = [];
    const siteVal: any = [];
    const waveVal: any = [];
    const domainVal: any = [];
    const xAxisVal: any = [];
    const yAxisVal: any = [];
    prod.forEach((o: any) => {
      prodVal.push(o.value);
    });
    site.forEach((o: any) => {
      siteVal.push(o.value);
    });
    wave.forEach((o: any) => {
      waveVal.push(o.value);
    });
    dom.forEach((o: any) => {
      domainVal.push(o.value);
    });
    xAxis.forEach((o: any) => {
      xAxisVal.push(o.value);
    });
    yAxis.forEach((o: any) => {
      yAxisVal.push(o.value);
    });
    // console.log(waveVal, domainVal, xAxisVal, yAxisVal);
    if (waveVal.length === 0 || domainVal.length === 0 || xAxisVal.length === 0) {
      domainVal.push('*');
    }
    if (xAxisVal.length === 0) {
      xAxisVal.push('*');
    }
    if (waveVal.length === 0) {
      waveVal.push('*');
    }
    if (prodVal.length === 0) {
      prodVal.push('*');
    }
    if (siteVal.length === 0) {
      siteVal.push('*');
    }
    // console.log(waveVal, domainVal, siteVal, prodVal, xAxisVal, 'Performance');
    getApsysFilterData(waveVal, domainVal, siteVal, prodVal, xAxisVal, 'Performance')
      .then((payload: any) => {
        if (payload) {
          // console.log(payload);
          const entityProdAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.lines) {
            const item = payload.filters.lines[i];
            entityProdAfterFilter.push({
              label: item,
              value: item,
            });
          }
          if (extra !== 'noChange_prodEntity') {
            setFilterProductLine(entityProdAfterFilter);
          }
          const entitySiteAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.sites) {
            const item = payload.filters.sites[i];
            entitySiteAfterFilter.push({
              label: item,
              value: item,
            });
          }
          if (extra !== 'noChange_siteEntity') {
            setFilterSite(entitySiteAfterFilter);
          }
          const entityWaveAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.waves) {
            const item = payload.filters.waves[i];
            entityWaveAfterFilter.push({
              label: item,
              value: item,
            });
          }
          if (extra !== 'noChange_waveEntity') {
            setFilterWave(entityWaveAfterFilter);
          }
          const domainAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.domain) {
            const item = payload.filters.domain[i];
            domainAfterFilter.push({
              label: item,
              value: item,
            });
          }
          if (extra !== 'noChange_domain') {
            setFilterDomain(domainAfterFilter);
          }
          const xAxisAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.xAxis) {
            const item = payload.filters.xAxis[i];
            xAxisAfterFilter.push({
              label: item,
              value: item,
            });
          }
          if (extra !== 'noChange_xAxis') {
            setFilterXaxis(xAxisAfterFilter);
          }
          const documentProfileAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          // for (const i in payload.filters.yAxis) {
          //   const item = payload.filters.yAxis.split(',')[i];
          //   documentProfileAfterFilter.push({
          //     label: item,
          //     value: item,
          //   });
          // }
          if (extra !== 'noChange_yAxis') {
            setFilterYaxis([{ label: 'Performance', value: 'Performance' }]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }
  function multifilterWave(wave: any) {
    // console.log('wave after mutli', wave);
    const waveVal: any = [];

    wave.forEach((o: any) => {
      waveVal.push(o.value);
    });

    if (waveVal.length === 0) {
      waveVal.push('*');
    }
    // console.log(waveVal, domainVal, xAxisVal, yAxisVal);
    getApsysFilterData(waveVal, ['*'], ['*'], ['*'], ['*'], 'Performance')
      .then((payload: any) => {
        if (payload) {
          // console.log(payload);
          const entityWaveAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.domain) {
            const item = payload.filters.domain[i];
            entityWaveAfterFilter.push({
              label: item,
              value: item,
            });
          }
          setFilterDomain(entityWaveAfterFilter);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }
  // eslint-disable-next-line consistent-return
  const renderWave = () => {
    if (!selectedWaveEntities.length) {
      return <b style={{ color: '#002A45' }}>Waves</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const renderProduct = () => {
    if (!selectedProductEntities.length) {
      return <b style={{ color: '#002A45' }}>Product Line</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const renderSite = () => {
    if (!selectedSiteEntites.length) {
      return <b style={{ color: '#002A45' }}>Site</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const renderDomain = () => {
    if (!selectedDomainEntites.length) {
      return <b style={{ color: '#002A45' }}>Domain</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const renderXaxis = () => {
    if (!selectedXaxisEntities.length) {
      return <b style={{ color: '#002A45' }}>XAxis</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const renderYaxis = () => {
    if (!selectedYaxisEntities.length) {
      return <b style={{ color: '#002A45' }}>YAxis</b>;
    }
  };
  function datasetProcess() {
    // setDisplayDatasetResult(false);
    // setGraphDataDisplay1(false);
    setLoading(true);
    const prod: any = [];
    const site: any = [];
    const wave: any = [];
    const domain: any = [];
    const xAxis: any = [];
    const documentTitle: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < selectedProductEntities.length; index++) {
      prod.push(selectedProductEntities[index].value);
    }
    if (prod.length === 0) {
      prod.push('*');
    }
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < selectedSiteEntites.length; index++) {
      site.push(selectedSiteEntites[index].value);
    }
    if (site.length === 0) {
      site.push('*');
    }
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < selectedWaveEntities.length; index++) {
      wave.push(selectedWaveEntities[index].value);
    }
    if (wave.length === 0) {
      wave.push('*');
    }
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < selectedDomainEntites.length; index++) {
      domain.push(selectedDomainEntites[index].value);
    }
    if (domain.length === 0) {
      domain.push('*');
    }
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < selectedXaxisEntities.length; index++) {
      xAxis.push(selectedXaxisEntities[index].value);
    }
    if (xAxis.length === 0) {
      xAxis.push('*');
    }
    getAllWaveData(prod, site, wave, domain, xAxis[0], 'Performance')
      .then((payload: any) => {
        if (payload) {
          const myArray = [];
          myArray.push(payload);
          setFinalPayloadHere(payload);
          // console.log(finalPayloadHere, 'final');
          // setDisplayDatasetResult(true);
          // JSON.parse(payload.output.dataframe)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    // }
  }

  // if (loadingFilterData) {
  //   return <Loading />;
  // }

  return (
    <>
      <Grid
        container
        style={{ backgroundColor: '#002A45', padding: 10, color: 'white', fontSize: 20 }}
      >
        <Grid item xs={12}>
          {' '}
          Filter
        </Grid>
      </Grid>
      <Paper
        style={{
          backgroundColor: '#EEF1F9',
          // margin: 15,
          borderRadius: 0,
          border: '1px solid #002A45',
          padding: '18px 9px 20px 8px',
        }}
      >
        <div>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              {/* {console.log('selectedWaveEntities', selectedWaveEntities)} */}
              <FormControl variant="outlined" className={classes.formControlMulti}>
                <MultiSelect
                  options={filterProductLine}
                  value={selectedProductEntities}
                  // hasSelectAll={false}
                  onChange={onChangeProductLineEntity}
                  labelledBy="Product Line"
                  hasSelectAll={false}
                  valueRenderer={renderProduct}
                  overrideStrings={{
                    allItemsAreSelected: selectedProductEntities
                      .map((item) => {
                        return item?.value;
                      })
                      .join(','),
                  }}
                  className={classes.MultiSelect}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="outlined" className={classes.formControlMulti}>
                <MultiSelect
                  options={filterSite}
                  value={selectedSiteEntites}
                  // hasSelectAll={false}
                  onChange={onChangeSiteEntity}
                  labelledBy="Site"
                  hasSelectAll={false}
                  valueRenderer={renderSite}
                  overrideStrings={{
                    allItemsAreSelected: selectedSiteEntites
                      .map((item) => {
                        return item?.value;
                      })
                      .join(','),
                  }}
                  className={classes.MultiSelect}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="outlined" className={classes.formControlMulti}>
                <MultiSelect
                  options={filterWave}
                  value={selectedWaveEntities}
                  // hasSelectAll={false}
                  onChange={onChangeWaveEntity}
                  labelledBy="Wave"
                  hasSelectAll={false}
                  valueRenderer={renderWave}
                  overrideStrings={{
                    allItemsAreSelected: selectedWaveEntities
                      .map((item) => {
                        return item?.value;
                      })
                      .join(','),
                  }}
                  className={classes.MultiSelect}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              {/* {console.log('filterYaxis', filterYaxis)} */}
              <FormControl variant="outlined" className={classes.formControlMulti}>
                <MultiSelect
                  options={filterDomain}
                  value={selectedDomainEntites}
                  onChange={onChangeDomainEntity}
                  labelledBy="Domain"
                  hasSelectAll={false}
                  valueRenderer={renderDomain}
                  overrideStrings={{
                    allItemsAreSelected: selectedDomainEntites
                      .map((item) => {
                        return item?.value;
                      })
                      .join(','),
                  }}
                  className={classes.MultiSelect}
                />
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <FormControl variant="outlined" className={classes.formControlMulti}>
                <MultiSelect
                  options={filterXaxis}
                  value={selectedXaxisEntities}
                  onChange={onChangeXaxisEntity}
                  labelledBy="X-axis"
                  hasSelectAll={false}
                  valueRenderer={renderXaxis}
                  overrideStrings={{
                    allItemsAreSelected: selectedXaxisEntities
                      .map((item) => {
                        return item?.value;
                      })
                      .join(','),
                  }}
                  className={classes.MultiSelect}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="outlined" className={classes.formControlMulti}>
                <MultiSelect
                  options={filterYaxis}
                  value={selectedYaxisEntities}
                  onChange={onChangeYaxisEntity}
                  hasSelectAll={false}
                  labelledBy="Y-axis"
                  valueRenderer={renderYaxis}
                  overrideStrings={{
                    allItemsAreSelected: selectedYaxisEntities
                      .map((item) => {
                        return item?.value;
                      })
                      .join(','),
                  }}
                  className={classes.MultiSelect}
                />
              </FormControl>
            </Grid>
            <Grid xs={6} spacing={0}>
              <div style={{ float: 'right' }}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    spacing={1}
                    container
                    alignItems="center"
                    // style={{ float: 'left' }}
                    justify="flex-end"
                    // style={{ display: 'flex', justifyContent: 'end' }}
                  >
                    <Grid item xs={6}>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() => {
                          setFilterProductLine(productEntitiesDefault);
                          setSelectedProductEntities([]);
                          setFilterSite(siteEntitiesDefault);
                          setSelectedSiteEntites([]);
                          setFilterWave(waveEntitiesDefault);
                          setSelectedWaveEntities([]);
                          setFilterDomain(domainEntitiesDefault);
                          setSelectedDomainEntites([]);
                          setFilterXaxis(xaxisEntitiesDefault);
                          setSelectedXaxisEntities([]);
                          setFilterYaxis(yaxisEntitiesDefault);
                          setSelectedYaxisEntities([]);
                          setFinalPayloadHere([]);
                        }}
                        style={{
                          height: '50px',
                          width: '150px',
                          // marginTop: -5,
                          // float: 'right',
                        }}
                        color="primary"
                      >
                        <FilterAltOffIcon />
                        Clear Filter
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        type="button"
                        variant="contained"
                        disabled={
                          !(
                            selectedWaveEntities.length > 0 &&
                            selectedXaxisEntities.length > 0 &&
                            // selectedDomainEntites.length > 0 &&
                            selectedYaxisEntities.length > 0
                          )
                        }
                        color="primary"
                        onClick={datasetProcess}
                        style={{
                          height: '50px',
                          width: '150px',
                          // marginTop: -5,
                          // float: 'right',
                        }}
                      >
                        Analyze
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          {/* <div style={{ float: 'right' }}></div> */}
        </div>
      </Paper>
      <br />
      {/* {console.log('final', Object.keys(finalPayloadHere).length)} */}
      {Object.keys(finalPayloadHere).length > 0 && (
        <>
          {finalPayloadHere?.status === 'Error' ? (
            <NoResultsFound />
          ) : (
            <ApsysGraph dataApsy={finalPayloadHere} />
          )}
        </>
      )}
    </>
  );
}

export default RexApsys;
