/* eslint-disable no-case-declarations */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import {
  AppBar,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  ThemeProvider,
  Typography,
  withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps, useParams } from 'react-router-dom';
import MuiListItem from '@material-ui/core/ListItem';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styled from 'styled-components';
import { isEmpty } from '@antv/util';
import { isensTheme } from '../../app/isensTheme';
import m1 from '../../assets/m1_blue.png';
import m2 from '../../assets/m2_blue.png';
import m3 from '../../assets/m3_blue.png';
import m4 from '../../assets/m4_blue.png';
import m5 from '../../assets/m5_blue.png';
import { ENV } from '../../const';
import ProjectContext from '../../containers/Project/ProjectContext';

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

const useStylesModuleBar = makeStyles((theme) => ({
  root: {
    scrollBehavior: 'smooth',
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    // gap: '1rem',
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: '0.4rem',
    gap: '0.2rem',
    backgroundColor: '#EEF1F9',
    '&::-webkit-scrollbar': {
      width: '0px',
      height: '0px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'none',
      borderRadius: '3px',
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-thumb': {
      outline: 'none',
    },
    '& .Mui-selected': {
      backgroundColor: '#EEF1F9',
      border: '1px solid #002A45',
      borderRadius: 5,
      // color: 'white',
      // fontWeight: 'bold',
    },
    '& .Mui-selected:hover': {
      backgroundColor: '#EEF1F9',
    },
    '&:hover': {
      backgroundColor: '#EEF1F9',
    },
  },
}));

const ModulesBar = (props: any): JSX.Element => {
  const { userSetting } = useContext(ProjectContext);
  const classes = useStylesModuleBar();
  const [selected, setSelected] = React.useState(1);
  const { projectId, moduleroute } = useParams() as any;
  // const { isHiden, setIsHiden } = useContext(ProjectContext);
  const moveRef = useRef();
  const [module1, setModule1] = useState([]);
  const [module2, setModule2] = useState([]);
  const [module3, setModule3] = useState([]);
  const [module4, setModule4] = useState([]);
  const [module5, setModule5] = useState([]);
  const [module6, setModule6] = useState([]);

  useEffect(() => {
    switch (moduleroute) {
      case 'm1':
        setSelected(1);
        break;
      case 'm2':
        setSelected(2);
        break;
      case 'm3':
        setSelected(3);
        break;
      case 'm4':
        setSelected(6);
        break;
      case 'm5':
        setSelected(5);
        break;
      case 'm6':
        setSelected(4);
        break;
      case undefined:
        setSelected(0);
        break;
      default:
        break;
    }
  }, [moduleroute]);
  useEffect(() => {
    userSetting.map((data) => {
      setModule1(data?.m1);
      setModule2(data?.m2);
      setModule3(data?.m3);
      setModule4(data?.m4);
      setModule5(data?.m5);
      setModule6(data?.m6);
    });
  }, [userSetting]);

  const scroll = (scrollOffset: number) => {
    const childElement = moveRef?.current?.children[0];
    const childElementWidth = childElement.offsetWidth;
    const actualSize = scrollOffset * (childElementWidth + 5);
    moveRef.current.scrollLeft += actualSize;
  };

  return (
    <ThemeProvider theme={isensTheme}>
      <div
        style={{
          position: 'sticky',
          // top: isHiden ? -64 : 64,
          // zIndex: isHiden ? 0 : 1099,
          top: 64,
          zIndex: 1099,
          display: 'flex',
          gap: '1rem',
          justifyContent: 'space-between',
          width: '100%',
          backgroundColor: '#EEF1F9',
          boxShadow: '0px 1px 6px #00000029',
          transition: 'top 0.3s',
        }}
      >
        {moveRef?.current?.children?.length > 4 && (
          <IconButton style={{ flexBasis: '5%' }} onClick={() => scroll(-1)}>
            <ArrowBackIosIcon />
          </IconButton>
        )}

        <List className={classes.root} style={{ flexBasis: '90%' }} ref={moveRef}>
          {!isEmpty(module1) && (
            <ListItem
              button
              component={Link}
              to={`/projects/${projectId}/module1/${'m1'}/new`}
              style={{
                flexBasis: '24%',
                flexGrow: 0,
                flexShrink: 0,
                // flex: 1,
                cursor: 'pointer',
                maxWidth: '24%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                padding: '0.4rem',
              }}
              selected={selected === 1}
              onClick={() => setSelected(1)}
            >
              <ListItemIcon>
                <Avatar alt="Module 1" src={m1} />
              </ListItemIcon>
              <Typography style={{ color: '#002a45' }}>Smart Requirement Management</Typography>
            </ListItem>
          )}
          {!isEmpty(module2) && (
            <ListItem
              component={Link}
              to={`/projects/${projectId}/module2/${'m2'}/run`}
              button
              style={{
                flexBasis: '24%',
                flexGrow: 0,
                flexShrink: 0,
                cursor: 'pointer',
                maxWidth: '24%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                padding: '0.4rem',
              }}
              selected={selected === 2}
              onClick={() => setSelected(2)}
            >
              <ListItemIcon>
                <Avatar alt="Module 2" src={m2} />
              </ListItemIcon>
              <br />
              <Typography style={{ color: '#002a45' }}>Knowledge & Efficiency</Typography>
            </ListItem>
          )}
          {!isEmpty(module3) && (
            <ListItem
              button
              component={Link}
              to={`/projects/${projectId}/module3/${'m3'}`}
              style={{
                flexBasis: '24%',
                flexGrow: 0,
                flexShrink: 0,
                // flex: 1,
                cursor: 'pointer',
                maxWidth: '24%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                padding: '0.4rem',
              }}
              selected={selected === 3}
              onClick={() => setSelected(3)}
            >
              <ListItemIcon>
                <Avatar alt="Module 3" src={m3} />
              </ListItemIcon>
              <Typography style={{ color: '#002a45' }}>Verification & Quality</Typography>
            </ListItem>
          )}
          {!isEmpty(module6) && (
            <ListItem
              button
              component={Link}
              to={`/projects/${projectId}/module6/${'m6'}`}
              style={{
                flexBasis: '24%',
                flexGrow: 0,
                flexShrink: 0,
                // flex: 1,
                cursor: 'pointer',
                maxWidth: '24%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                padding: '0.4rem',
              }}
              selected={selected === 4}
              onClick={() => setSelected(4)}
            >
              <ListItemIcon>
                <Avatar alt="Module 5" src={m5} />
              </ListItemIcon>
              <Typography style={{ color: '#002a45' }}>Project Management</Typography>
            </ListItem>
          )}

          {ENV !== 'production' && ENV !== 'test' && (
            <>
              {!isEmpty(module4) && (
                <ListItem
                  button
                  component={Link}
                  to={`/projects/${projectId}/module4/${'m4'}`}
                  style={{
                    flexBasis: '24%',
                    flexGrow: 0,
                    flexShrink: 0,
                    // flex: 1,
                    cursor: 'pointer',
                    maxWidth: '24%',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0.4rem',
                  }}
                  selected={selected === 6}
                  onClick={() => setSelected(6)}
                >
                  <ListItemIcon>
                    <Avatar alt="Module 4" src={m4} />
                  </ListItemIcon>
                  <Typography style={{ color: '#002a45' }}>Performance & Maturity</Typography>
                </ListItem>
              )}
              {!isEmpty(module5) && (
                <ListItem
                  button
                  component={Link}
                  to={`/projects/${projectId}/module5/${'m5'}`}
                  style={{
                    flexBasis: '24%',
                    flexGrow: 0,
                    flexShrink: 0,
                    // flex: 1,
                    cursor: 'pointer',
                    maxWidth: '24%',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0.4rem',
                  }}
                  selected={selected === 5}
                  onClick={() => setSelected(5)}
                >
                  <ListItemIcon>
                    <Avatar alt="Module 5" src={m5} />
                  </ListItemIcon>
                  <Typography style={{ color: '#002a45' }}>Safety & CyberSecurity</Typography>
                </ListItem>
              )}
            </>
          )}
        </List>
        {moveRef?.current?.children?.length > 4 && (
          <IconButton style={{ flexBasis: '5%' }} onClick={() => scroll(1)}>
            <ArrowForwardIosIcon />
          </IconButton>
        )}
      </div>
    </ThemeProvider>
  );
};

export default ModulesBar;
