import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Iframe from 'react-iframe';
import Axios from '../../../api/axios';
import { BACKEND_URL } from '../../../const';

function IConcept() {
  const [url, seturl] = useState('');
  const { projectId } = useParams() as any;
  useEffect(() => {
    Axios.post(`${BACKEND_URL.root}/modules/pipe/m2-onto-iframe/${projectId}`, {}).then(
      (response) => {
        seturl(response.data);
      }
    );
  }, []);

  if (url === '') return null;
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Iframe
        url={`${url}/`}
        width="100%"
        height="1000px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"
      />
    </Box>
  );
}

export default IConcept;
