/* eslint-disable react/destructuring-assignment */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import MultiSelect from 'react-multi-select-component';
import { makeStyles, withStyles } from '@material-ui/styles';
import { useHistory, useParams } from 'react-router-dom';
import { Workload3Create } from 'WorkloadType';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import {
  userWorkloadGetById,
  workloadCreate,
  workloadGetById,
  workloadRunPost,
} from '../../../../../../api/workloads';
import { fileDetails, iCaptureCsv, iCaptureExcel } from '../../../../../../api/module1';
import ExcelDoc from '../../../../../../assets/icons8-excel-48.png';
import PdfDoc from '../../../../../../assets/pdf_ins.png';
import PptDoc from '../../../../../../assets/icons8-powerpoint-48.png';
import DocxDoc from '../../../../../../assets/icons8-microsoft-word-2019-48.png';
import CsvDoc from '../../../../../../assets/csv_icons.png';
import docIcon from '../../../../../../assets/docx_ico.png';
import pdfIcon from '../../../../../../assets/pdf_ico.png';
import xlsIcon from '../../../../../../assets/xlsx_ico.png';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  tableHeaderComponent,
  toolbarComponent,
  tUseStyles,
} from '../../../../../../components/Datagrid/TStyles';
import ToolBarPlugin from '../../../../../../components/Datagrid/ToolBarPlugin';
import formatBytes from '../../../../../../tools/formatBytes';
import NavigationGoto from '../../../../../../components/Core/NavigationGoto';
import { isensTheme } from '../../../../../../app/isensTheme';
import { getCaptureName } from '../../../../../../api/breakdowns';
import Loading from '../../../../../../components/Core/Loading';
import MultiAllocTableComp from '../../../../../../components/Datagrid/MultiAllocTableComp';
import NavigationGotoNew from '../../../../../../components/Core/NavigationGotoNew';
import NERParamTarget from '../../../../../../components/NERParamTarget';
import WorkSpaceContext from '../../../../WorkspceContext';

// table header
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);
const useStyles = makeStyles((theme) => ({
  root: {
    // '& .MuiPaper-root': {
    //   backgroundColor: theme.palette.background.paper,
    // },
    // backgroundColor: 'rgb(238, 241, 249)',
  },
  paper: {
    '& .MuiPaper-rounded': {
      background: 'red !important',
    },
  },
  captureHandle: {
    '& .MuiInputLabel-outlined': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
  },
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
}));

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
];

type Props = {
  id?: number;
  files?: any;
  setFiles?: any;
};

function DocSimilarity({ id: workloadId, files, setFiles }: Props) {
  const { setSnack } = useContext(WorkSpaceContext);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [captureNames, setCaptureNames] = useState<any[]>([]);
  const [currentCaptureName, setCurrentCaptureName] = useState<any>('');
  const [disOP, setDisOP] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [dis, setDis] = useState(false);
  const [tempPage, setTemPage] = useState(0);

  const [currentPage1, setCurrentPage1] = useState(0);
  const [tempPage1, setTemPage1] = useState(0);
  const [dis1, setDis1] = useState(true);
  const [pageSize1, setPageSize1] = useState(5);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const handlePageNum1 = () => {
    const rel = tempPage1 - 1;
    setCurrentPage1(rel);
  };

  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${currentCaptureName}.xlsx`
      );
    });
  };
  // const { project } = useContext(Module1Context);
  // const [files, setFiles] = useState<any[]>([]);
  const [selection, setSelection] = useState([]);

  // to control the loader and display data full Grid
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);

  const [loadingResult, setLoadingResult] = useState(false);

  // const { setSnack } = useContext(Module1Context);
  const [messageToDisplay, setMessageToDisplay] = useState<any>();

  const [dataoutput, setDataoutput] = useState<any>([]);
  const [columnWidth, setColumnWidth] = useState<any>([]);

  // required when we dont have fixed named columns
  const [rows, setRows] = useState<any>([]);
  const [columnsAre, setColumnsAre] = useState<any>([]);
  const [columnNamesAre, setColumnNamesAre] = useState<any>([]);
  const [newLine, setNewLine] = useState<any>('');
  const [filter, setFilter] = useState<any>([]);
  const [filterEntity, setFilterEntity] = useState<any>([]);

  const [resourcesAre, setResourcesAre] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');
  const inputHandler = (event: any) => setInputValue(event.target.value);

  const [fileName1, setFileName1] = useState<any>();
  const [fileName2, setFileName2] = useState<any>();

  const [sheetName0, setSheetName0] = useState<any>();
  const [targetName0, setTargetName0] = useState<any>();
  const [columnName0, setColumnName0] = useState<any>();

  const [sheetName1, setSheetName1] = useState<any>();
  const [targetName1, setTargetName1] = useState<any>();
  const [columnName1, setColumnName1] = useState<any>();
  const [errorMessage, setErrorMessage] = useState('');
  const [similarityScore, setSimilarityScore] = useState<any>();

  const [targetColumnsAre, setTargetColumnsAre] = useState<any>([]);

  const handleSetRowsColumns = async (myRows: {}[]) => {
    const araryCol = Object.keys(myRows[0]);
    const myCols: any = [];
    araryCol.map((key) => {
      // const keyTemp = key.replace(' ', '_');
      if (key !== 'RawData') {
        if (key !== 'Coordinates') {
          if (key !== 'i18n') {
            myCols.push({
              name: key,
              title: key.toUpperCase(),
            });
          }
        }
      }
      return key;
    });
    setColumnsAre(myCols);
    const tempArr: any = [];
    // eslint-disable-next-line array-callback-return
    myCols.map((c: any) => {
      tempArr.push(c.name);
    });
    setColumnNamesAre(tempArr);
    setRows(myRows);
  };
  // useEffect(() => {
  //   getCaptureName(projectId).then((resp) => {
  //     if (resp) {
  //       setCaptureNames(resp);
  //     }
  //   });
  // }, []);
  /**
   * Get all files from project
   */

  /**
   * if projectId && workloadId present -> render results
   * wait for allfiles are loaded
   */
  useEffect(() => {
    if (workloadId === undefined) {
      return;
    }
    setLoading(true);
    userWorkloadGetById(workloadId)
      .then((payload) => {
        // console.log(payload);
        // console.log(payload.resources);
        setLoading(false);
        setDisplay(true);
        // console.log(JSON.parse(payload.results_data[0].data.output.DocToDoc_Result));
        setResourcesAre(payload?.resources[0]?.scopeSettingOP);
        handleSetRowsColumns(JSON.parse(payload.results_data[0].data.output.DocToDoc_Result));
        setCurrentCaptureName(payload?.resources[0]?.output?.NameOfCapture);
        setSimilarityScore(
          payload.results_data[0].data.output.Overall_Document_Similarity.Similarity
        );
      })
      .catch(() => {
        setLoading(false);
        setDisplay(false);
        setSnack(messageToDisplay, 'error');
      });
  }, [workloadId]);

  useEffect(() => {
    if (pageSize1 > rows.length) {
      if (dis1) {
        setDis1((prev) => {
          return !prev;
        });
      }
    } else if (pageSize1 < rows.length) {
      setDis1((prev) => {
        return !prev;
      });
    }
  }, [pageSize1]);

  return (
    <>
      {loading && <Loading />}

      {/* {loadingResult ? <Loading /> : ''} */}
      {display && (
        <>
          <Paper style={{ borderColor: 'black' }}>
            <Box style={{ backgroundColor: '#002A45', color: 'white', padding: '0.5rem' }}>
              <Typography variant="h5">Resource details:</Typography>
            </Box>
            <Box style={{ padding: '1rem' }}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                {resourcesAre?.map((data: any) => {
                  return (
                    <>
                      {data?.FileName && (
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#EEF1F9',
                            height: '2rem',
                            paddingLeft: '1rem',
                          }}
                        >
                          <Box style={{ flexBasis: '25%' }}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              File Name: {data?.FileName}
                            </Typography>
                          </Box>
                          <Box style={{ flexBasis: '25%' }}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              File Type: {data?.FileType}
                            </Typography>
                          </Box>
                          <Box style={{ flexBasis: '25%' }}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              Sheet Name: {data?.SheetName != null ? data?.SheetName : 'NA'}
                            </Typography>
                          </Box>
                          <Box style={{ flexBasis: '25%' }}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              Target Column: {data?.TargetColumn}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </>
                  );
                })}
              </Box>
            </Box>
          </Paper>
          <Paper style={{ borderColor: 'black' }}>
            <div style={{ marginLeft: '5px' }}>
              <h3>Document Similarity: {similarityScore}% </h3>
            </div>
          </Paper>
          <Box style={{ marginTop: 5, backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
            <ExpressGrid rows={rows} columns={columnsAre}>
              <DataTypeProvider
                for={columnNamesAre}
                formatterComponent={({ value }) => (
                  <span title={value} style={{ whiteSpace: `${newLine}` }}>
                    {value}
                  </span>
                )}
              />
              <SortingState />
              <IntegratedSorting />
              <PagingState
                currentPage={currentPage1}
                onCurrentPageChange={setCurrentPage1}
                pageSize={pageSize1}
                onPageSizeChange={setPageSize1}
              />
              <IntegratedPaging />
              <Table
                tableComponent={MultiAllocTableComp}
                containerComponent={tableAllocatorComponent}
                headComponent={tableHeaderComponent}
                columnExtensions={columnsAre.map((c) => ({
                  columnName: c.name,
                  wordWrapEnabled: toggleHeight,
                }))}
              />
              <TableHeaderRow showSortingControls />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={setHiddenColumnNames}
              />
              <Toolbar rootComponent={toolbarComponent} />
              <ColumnChooser />
              <ToolBarPlugin name="Download" onClick={startExport} />
              <ToolBarPlugin
                name="Height"
                title="Wrap Text"
                onClick={() => {
                  setToggleHeight(!toggleHeight);
                }}
              />
              <ToolBarPlugin
                name="NewLine"
                onClick={() => (newLine !== 'pre-line' ? setNewLine('pre-line') : setNewLine(''))}
              />
              <PagingPanel pageSizes={pageSizes} />
            </ExpressGrid>
            <GridExporter ref={exporterRef} rows={rows} columns={columnsAre} onSave={onSave} />
            {/* <NavigationGotoNew
              setPageSize={setPageSize1}
              fileLength={rows.length}
              pageSize={pageSize1}
              dis={dis1}
              setTemPage={setTemPage1}
              handlePageNum={handlePageNum1}
              currentPage={currentPage1}
              setCurrentPage={setCurrentPage1}
            /> */}
          </Box>
        </>
      )}
    </>
  );
}

export default DocSimilarity;
