import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React from 'react';
import AvatarDiscuss from './AvatarDiscuss';
import Dots from './Dots';
import MarkdownContent from './MarkdownContent';
import { AvatarType } from './chat';

interface MessageProps {
  message: {
    id?: string;
    role: AvatarType;
    content: string;
    feedback?: 'like' | 'dislike';
  };
  metadata?: {
    row_number: number;
    feedback?: 'like' | 'dislike';
  };
  rowNumber?: number;
  messageId?: string;
  discussionId: number;
  dispatch: React.Dispatch<any>;
  isStreaming: boolean;
  initialname: string;
  classes: {
    rootMessage?: string;
    userMessage?: string;
    boxMessage?: string;
    imgMessage?: string;
    contentMessage?: string;
    btnThumb?: string;
    btnThumbIcon?: string;
    buttonCitations?: string;
  };
}

const DiscussBoardItemAssistant: React.FC<MessageProps> = ({
  message,
  rowNumber,
  messageId,
  discussionId,
  initialname,
  classes,
  dispatch,
  metadata,
  isStreaming,
}) => {
  const CompUp = message?.feedback === 'like' ? ThumbUpIcon : ThumbUpOutlinedIcon;
  const CompDown = message?.feedback === 'dislike' ? ThumbDownIcon : ThumbDownOutlinedIcon;

  return (
    <div style={{ width: '100%' }} className={classes.rootMessage}>
      <Box display="flex" flexDirection="row" className={classes.boxMessage}>
        <Box className={classes.imgMessage} style={{ paddingLeft: 10 }}>
          {AvatarDiscuss(message.role, initialname)}
        </Box>
        <Box className={classes.contentMessage}>
          <MarkdownContent content={message.content} />
          {isStreaming && <Dots />}
        </Box>
      </Box>
      {!isStreaming && (
        <Box className={classes.btnThumb}>
          <ContentCopyIcon
            titleAccess="Copy this response"
            className={classes.btnThumbIcon}
            onClick={() => navigator.clipboard.writeText(message.content)}
            style={{ marginRight: '10px' }}
          />
          <CompUp
            className={classes.btnThumbIcon}
            onClick={() => {
              dispatch({
                type: 'FEEDBACK_DISCUSS_QUESTION',
                payload: {
                  discuss_id: discussionId,
                  response_id: messageId,
                  feedback: message?.feedback === 'like' ? undefined : 'like',
                },
              });
            }}
            style={{ marginRight: '10px' }}
          />
          <CompDown
            className={classes.btnThumbIcon}
            onClick={() => {
              dispatch({
                type: 'FEEDBACK_DISCUSS_QUESTION',
                payload: {
                  discuss_id: discussionId,
                  response_id: messageId,
                  feedback: message?.feedback === 'dislike' ? undefined : 'dislike',
                },
              });
            }}
          />
        </Box>
      )}
    </div>
  );
};

export default DiscussBoardItemAssistant;
// dont use memo here
// export default memo(ItemAssistant);
