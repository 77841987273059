import React from 'react';
import msalInstance, { scopes } from '../../api/auth';
import useAzureToken from '../../app/useAzureToken';

// msalInstance
//   .handleRedirectPromise()
//   .then((tokenResponse) => {})
//   .catch((err) => {
//     console.error(err);
//   });

function Connect() {
  const { jwt, setJWT } = useAzureToken();
  const request = {
    scopes,
  };

  msalInstance
    .acquireTokenSilent(request)
    .then((tokenResponse) => {
      // Do something with the tokenResponse
      console.info(tokenResponse);
      setJWT(JSON.stringify(tokenResponse));
    })
    .catch((err) => {
      console.error(err);
      // if (error instanceof InteractionRequiredAuthError) {
      //   // fallback to interaction when silent call fails
      //   return msalInstance.acquireTokenRedirect(request);
      // }
      return msalInstance.acquireTokenRedirect(request);
    });

  // msalInstance
  //   .handleRedirectPromise()
  //   .then((tokenResponse) => {
  //     if (!tokenResponse) throw new Error('tokenResponse');

  //     // Check if the tokenResponse is null
  //     // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
  //     // If the tokenResponse === null, you are not coming back from an auth redirect.
  //     console.info(tokenResponse);
  //     setToken(JSON.stringify(tokenResponse));
  //   })
  //   .catch((err) => {
  //     // handle error, either in the library or coming back from the server
  //     console.error(err);
  //   });
  return <div>todo</div>;
}

export default Connect;
