import { Grid, Typography } from '@material-ui/core';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TreeItem, TreeView } from '@material-ui/lab';
import React from 'react';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';

// import IConcept from './components/IConcept';
// import ISimilarizerTab from './components/IsimilarizerTab';

import TreeAllStyle, { useStyles } from '../../components/TreeMenu/TreeAllStyle';
import TreeArowIcons from '../../components/TreeMenu/TreeArowIcons';

function Module2Viewer() {
  const classes = TreeAllStyle();
  const arowClasses = TreeArowIcons();

  return (
    <div className={classes.root}>
      <Grid container>
        <TreeView
          className={classes.treeRoot}
          defaultCollapseIcon={<ExpandMoreIcon className={arowClasses.ArowdownStyle} />}
          defaultExpandIcon={<KeyboardArrowUpOutlinedIcon className={arowClasses.ArowupStyle} />}
          defaultExpanded={['']}
        >
          <TreeItem
            nodeId="0"
            label={
              <Typography variant="subtitle1" className={classes.treeLabel}>
                <span>
                  <FileCopyOutlinedIcon className={classes.treeIcon} />
                </span>
                iSimilarizer
              </Typography>
            }
            className={classes.treeitem}
            classes={{
              label: classes.label,
              selected: classes.label,
              content: classes.content,
              group: classes.group,
            }}
          >
            {/* <ISimilarizerTab /> */}
          </TreeItem>

          <TreeItem
            nodeId="4"
            label={
              <Typography variant="subtitle1" className={classes.treeLabel}>
                <span>
                  <AssessmentOutlinedIcon className={classes.treeIcon} />
                </span>
                iOntology
              </Typography>
            }
            className={classes.treeitem}
            classes={{
              label: classes.label,
              selected: classes.label,
              content: classes.content,
              group: classes.group,
            }}
          >
            <Grid item xs={12} style={{ marginBottom: '10px' }}>
              {' '}
            </Grid>
            <Grid item xs={12} style={{ minHeight: '1000px' }}>
              {/* <IConcept /> */}
            </Grid>
          </TreeItem>
        </TreeView>
      </Grid>
    </div>
  );
}
export default Module2Viewer;
