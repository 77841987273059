/* eslint-disable react/destructuring-assignment */
import { Button, FormControl, Grid, Paper, Typography, Breadcrumbs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import MultiSelect from 'react-multi-select-component';
import { Link, useHistory } from 'react-router-dom';
import {
  getClusterName,
  getRadinessProjectName,
  getRmName,
  getSubRegionName,
} from '../../../api/kpi';
import Loading from '../../../components/Core/Loading';
import ChangebleReadiness from './ChangebleReadiness';
import NonChangebleReadiness from './NonChangebleReadiness';

const useStyles2 = makeStyles((theme) => ({
  root: {
    display: 'inline',
    float: 'left',
    margin: 10,
    color: theme.palette.primary.main,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    backgroundColor: theme.palette.action.hover,
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
  textField: {
    margin: '15px 5px',
    paddingRight: 10,
  },
  radio: {
    textAlign: 'center',
  },
  label: {
    marginRight: '30px',
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  contain: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
    padding: '5px',
  },
  formControlMultiTextlebel: {
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  formControlMulti: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
  },
  formControlMultiFile: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
  },
  formControlMulti1: {
    margin: theme.spacing(1),
    minWidth: 220,
    maxWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tabstyle: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '33px',
    background: '#DC3223 0% 0% no-repeat padding-box',
    color: '#fff !important',
    fontSize: 14,
    fontWeight: 700,
  },
  MultiSelectHide: {
    // border: '2px solid red',
    // opacity: 1,
    '& .dropdown-container': {
      border: '1px solid #002A45',
      opacity: 1,
      // position: 'fixed',
    },
    '& .gray': {
      color: '#002A45 !important',
    },
    '& .dropdown-container[aria-disabled="true"]': {
      // overFlow: 'visible',
      // position: 'fixed',
      backgroundColor: '#C4D4EC',
      opacity: 0.5,
    },
    '& .select-menu-outer': {
      position: 'fixed',
    },
  },
  MultiSelect: {
    // border: '2px solid red',
    // opacity: 1,
    '& .dropdown-container': {
      border: '1px solid #002A45',
      opacity: 1,
      // position: 'fixed',
    },
    '& .gray': {
      color: '#002A45 !important',
    },
    '& .dropdown-container[aria-disabled="true"]': {
      // overFlow: 'visible',
      // position: 'fixed',
      // backgroundColor: '#C4D4EC',
    },
    // '& .dropdown-content': {
    //   // overflowY: 'auto',
    //   backgroundColor: 'red',
    //   position: 'initial',
    // },
    '& .select-menu-outer': {
      position: 'fixed',
    },
    // '& .rmsc': {
    //   borderColor: 'white',
    // },
    // '& .dropdown-heading': {
    //   color: 'red',
    // },
  },
}));

function EnggReadiness() {
  const classes = useStyles();
  const classes2 = useStyles2();
  const [sendRequest, setSendRequest] = useState(false);
  const [loading, setLoading] = useState(false);
  // initial dropdpwn data
  // Cluster State
  const [alstomClusterDefault, setAlstomClusterDefault] = useState<any>([]);
  const history = useHistory();
  const [alstomCluster, setAlstomCluster] = useState<any>([]);
  const [selectedCluster, setSelectedCluster] = useState([]);
  const [alstomSubRegion, setAlstomSubRegion] = useState<any>([]);
  const [selectedSubregion, setSelectedSubregion] = useState([]);
  const [alstomSubRegionDefault, setAlstomSubRegionDefault] = useState<any>([]);
  // RM State
  const [alstomRmName, setAlstomRmName] = useState<any>([]);
  const [selectedRmName, setSelectedRmName] = useState([]);
  const [alstomRmNameDefault, setAlstomRmNameDefault] = useState<any>([]);
  const [selectedNewRm, setSelectedNewRm] = useState([]);
  const [payloadHere, setPayloadHere] = useState<any>();
  // ProgramName State
  const [alstomProgramName, setAlstomProgramName] = useState<any>([]);
  const [selectedProgramName, setSelectedProgramName] = useState([]);
  const [alstomProgramNameDefault, setAlstomProgramNameDefault] = useState<any>([]);
  const [selectedFinalProgramName, setSelectedFinalProgramName] = useState([]);

  // switch the component
  const [displaySingleProject, SetDisplaySingleProject] = useState<any>(false);
  const [displayMultiProject, SetDisplayMultiProject] = useState<any>(false);
  const [param, setParam] = useState<any>('');

  useEffect(() => {
    getClusterName()
      .then((payload: any) => {
        if (payload) {
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload) {
            const item = payload[i].region;
            ent.push({
              label: item,
              value: item,
            });
          }
          setAlstomCluster(ent);
          setAlstomClusterDefault(ent);
          // seperate code
          setPayloadHere(payload);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);
  // Sub region
  useEffect(() => {
    getSubRegionName(undefined)
      .then((payload: any) => {
        if (payload) {
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload) {
            const item = payload[i].sub_region;
            ent.push({
              label: item,
              value: item,
            });
          }
          setAlstomSubRegion(ent);
          setAlstomSubRegionDefault(ent);
          // seperate code
          setPayloadHere(payload);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);
  // get RM Data
  useEffect(() => {
    getRmName()
      .then((payload: any) => {
        if (payload) {
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload) {
            const item = payload[i].rm_name;
            ent.push({
              label: item,
              value: item,
            });
          }
          setAlstomRmName(ent);
          setAlstomRmNameDefault(ent);
          // seperate code
          setPayloadHere(payload);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);
  function onChangeEntity(option: any) {
    setSelectedCluster(option);
    const json = JSON.stringify(option);
    sessionStorage.setItem('cluster', json);
    const extra = 'noChange_entity';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterClusterNameCall(option, selectedCluster);
  }
  function onChangeSubRegion(option: any) {
    setSelectedSubregion(option);
    const json = JSON.stringify(option);
    sessionStorage.setItem('subRegion', json);
    const extra = 'noChange_entity';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterSubRegionCall(option, selectedSubregion);
  }
  function onChangeRmName(option: any) {
    setSelectedRmName(option);
    const json = JSON.stringify(option);
    sessionStorage.setItem('rmName', json);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterRmNameCall(option, selectedRmName);
  }
  function onChangeProgramName(option: any) {
    setSelectedProgramName(option);
    const json = JSON.stringify(option);
    sessionStorage.setItem('prgName', json);
    const extra = 'noChange_entity';
    multifilterProgramCall(option, selectedProgramName);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  }
  function multifilterRmNameCall(en: any, plat: any) {
    const entityVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    const rm_names = entityVal.length ? entityVal.join() : undefined;
    getRadinessProjectName(rm_names, undefined)
      .then((payload: any) => {
        if (payload) {
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload) {
            const item = payload[i].project_program;
            ent.push({
              label: item,
              value: item,
            });
          }
          setAlstomProgramName(ent);
          setAlstomProgramNameDefault(ent);
          // seperate code
          setPayloadHere(payload);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }
  function multifilterClusterNameCall(en: any, plat: any) {
    const entityVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    const region = entityVal.length ? entityVal.join() : undefined;
    getSubRegionName(region)
      .then((payload: any) => {
        if (payload) {
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload) {
            const item = payload[i].sub_region;
            ent.push({
              label: item,
              value: item,
            });
          }
          setAlstomSubRegion(ent);
          setAlstomSubRegionDefault(ent);
          // seperate code
          setPayloadHere(payload);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }

  function multifilterSubRegionCall(en: any, plat: any) {
    const entityVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    const sub_region = entityVal.length ? entityVal.join() : undefined;
    getRadinessProjectName(undefined, sub_region)
      .then((payload: any) => {
        if (payload) {
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload) {
            const item = payload[i].project_program;
            const item2 = payload[i].ct_code;
            ent.push({
              label: item,
              value: item2,
            });
          }
          setAlstomProgramName(ent);
          setAlstomProgramNameDefault(ent);
          // seperate code
          setPayloadHere(payload);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }
  function multifilterProgramCall(en: any, plat: any) {
    const entityVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    const Program_name = entityVal.length ? entityVal.join() : undefined;
    const selectedFinalProgramName = [...entityVal];
    setSelectedFinalProgramName([...selectedFinalProgramName]);
  }
  // eslint-disable-next-line consistent-return
  const customValueRenderer = () => {
    if (!selectedCluster.length) {
      return <b style={{ color: '#002A45' }}>Select the region name</b>;
    }
  };

  // eslint-disable-next-line consistent-return
  const customValueRendererSubRegion = () => {
    if (!selectedSubregion.length) {
      return <b style={{ color: '#002A45' }}>Select the sub region</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customRmValueRenderer = () => {
    if (!selectedRmName.length) {
      return <b style={{ color: '#002A45' }}>RM Name</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customProgramRenderer = () => {
    if (!selectedProgramName.length) {
      return <b style={{ color: '#002A45' }}>Select the Project / Program Name</b>;
    }
  };
  const dataProcess = () => {
    SetDisplaySingleProject(false);
    SetDisplayMultiProject(false);
    setTimeout(() => {
      setParam(encodeURIComponent(selectedFinalProgramName.join()));
      if (selectedFinalProgramName.length > 1) {
        // history.push(`/me/engg?project_program_name=${param}`);
        SetDisplaySingleProject(false);
        SetDisplayMultiProject(true);
      } else {
        // history.push(`/me/enggSingle?project_program_name=${param}`);
        SetDisplaySingleProject(true);
        SetDisplayMultiProject(false);
      }
    }, 1000);
  };

  return (
    <>
      <Breadcrumbs separator=">" aria-label="breadcrumb" className={classes2.root}>
        <Link className={classes2.link} to="/me/enggReadinessHome">
          Dashboard
        </Link>
        <Link className={classes2.link} to="/me/readiness">
          Engineering Readiness
        </Link>
      </Breadcrumbs>
      <div>
        <Paper style={{ margin: 80 }}>
          <Grid style={{ border: '1px solid black' }}>
            <Grid className={classes.heading}>
              Engineering Readiness - Project / Program Selection
            </Grid>
            <Grid style={{ minHeight: '30vh', backgroundColor: '#EEF1F9' }}>
              <div>
                <div style={{ padding: '10px' }}>
                  <Grid container style={{ paddingLeft: 50 }}>
                    <Grid container alignContent="center" justify="space-around">
                      <>
                        <Grid item xs={4}>
                          {selectedRmName.length > 0 ? (
                            <>
                              <FormControl variant="outlined" className={classes.formControlMulti}>
                                <MultiSelect
                                  options={alstomCluster}
                                  value={selectedCluster}
                                  onChange={onChangeEntity}
                                  labelledBy="Region"
                                  hasSelectAll={true}
                                  valueRenderer={customValueRenderer}
                                  className={classes.MultiSelectHide}
                                  disabled
                                />
                              </FormControl>

                              <FormControl variant="outlined" className={classes.formControlMulti}>
                                <MultiSelect
                                  options={alstomSubRegion}
                                  value={selectedSubregion}
                                  onChange={onChangeSubRegion}
                                  labelledBy="Sub Region"
                                  hasSelectAll={true}
                                  valueRenderer={customValueRendererSubRegion}
                                  className={classes.MultiSelectHide}
                                  disabled
                                />
                              </FormControl>
                            </>
                          ) : (
                            <>
                              <FormControl variant="outlined" className={classes.formControlMulti}>
                                <MultiSelect
                                  options={alstomCluster}
                                  value={selectedCluster}
                                  onChange={onChangeEntity}
                                  labelledBy="Region"
                                  hasSelectAll={true}
                                  valueRenderer={customValueRenderer}
                                  className={classes.MultiSelect}
                                />
                              </FormControl>
                              <FormControl variant="outlined" className={classes.formControlMulti}>
                                <MultiSelect
                                  options={alstomSubRegion}
                                  value={selectedSubregion}
                                  onChange={onChangeSubRegion}
                                  labelledBy="Sub Region"
                                  hasSelectAll={true}
                                  valueRenderer={customValueRendererSubRegion}
                                  className={classes.MultiSelectHide}
                                />
                              </FormControl>
                            </>
                          )}
                          <Typography
                            style={{
                              fontWeight: 'bold',
                              width: 300,
                              textAlign: 'center',
                            }}
                          >
                            OR
                          </Typography>
                          {selectedCluster.length > 0 || selectedSubregion.length ? (
                            <>
                              <FormControl variant="outlined" className={classes.formControlMulti}>
                                <MultiSelect
                                  options={alstomRmName}
                                  value={selectedRmName}
                                  onChange={onChangeRmName}
                                  disabled
                                  labelledBy="RM Name"
                                  hasSelectAll={true}
                                  valueRenderer={customRmValueRenderer}
                                  className={classes.MultiSelectHide}
                                />
                              </FormControl>
                            </>
                          ) : (
                            <FormControl variant="outlined" className={classes.formControlMulti}>
                              <MultiSelect
                                options={alstomRmName}
                                value={selectedRmName}
                                onChange={onChangeRmName}
                                labelledBy="RM Name"
                                hasSelectAll={true}
                                valueRenderer={customRmValueRenderer}
                                className={classes.MultiSelect}
                              />
                            </FormControl>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl variant="outlined" className={classes.formControlMulti}>
                            <MultiSelect
                              options={alstomProgramName}
                              value={selectedProgramName}
                              onChange={onChangeProgramName}
                              labelledBy="Entities"
                              hasSelectAll={true}
                              valueRenderer={customProgramRenderer}
                              className={classes.MultiSelect}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid item xs={10} style={{ marginTop: -50, float: 'right' }}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            style={{ float: 'right' }}
                          >
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              //   onClick={datasetProcess}
                              style={{
                                height: '50px',
                                width: '150px',
                                marginTop: -5,
                                float: 'right',
                              }}
                              disabled={selectedFinalProgramName.length < 1}
                              onClick={dataProcess}
                              // onClick={() => setSendRequest(true)}
                            >
                              Submit
                            </Button>
                          </FormControl>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            style={{ float: 'right' }}
                          >
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              //   onClick={datasetProcess}
                              style={{
                                height: '50px',
                                width: '150px',
                                marginTop: -5,
                              }}
                              onClick={() => {
                                setAlstomCluster(alstomClusterDefault);
                                setSelectedCluster([]);
                                setAlstomRmName(alstomRmNameDefault);
                                setSelectedRmName([]);
                                setAlstomProgramName(alstomProgramNameDefault);
                                setSelectedProgramName([]);
                                setAlstomSubRegion(alstomSubRegionDefault);
                                setSelectedSubregion([]);
                                setSelectedFinalProgramName([]);
                                //   setSelectedFinalProgramName();
                                // added for single or multi project display
                                SetDisplaySingleProject(false);
                                SetDisplayMultiProject(false);
                              }}
                              // onClick={() => setSendRequest(true)}
                            >
                              Clear All
                            </Button>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
      {loading ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Loading />
        </div>
      ) : (
        ''
      )}
      {displayMultiProject && (
        <Paper style={{ margin: 80 }}>
          <Grid style={{ border: '1px solid black' }}>
            <NonChangebleReadiness projectName={param} />
          </Grid>
        </Paper>
      )}
      {displaySingleProject && (
        <Paper style={{ margin: 80 }}>
          <Grid style={{ border: '1px solid black' }}>
            {/* <SingleProject />{' '} */}
            <ChangebleReadiness projectName={param} />
          </Grid>
        </Paper>
      )}
    </>
  );
}

export default EnggReadiness;
