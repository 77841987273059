import { Grid, StepConnector } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getProjectConfigs, updateProjectConfig } from '../../api/iverifier';
import { workloadCreate, workloadRunAsync } from '../../api/workloads';
import m3Logo from '../../assets/m3_blue.png';
import Loading from '../../components/Core/Loading';
import ProjectBreadCrumbs from '../../components/Core/ProjectBreadCrumbs';
import { FILES_BY_MODULES } from '../../const';
import StepFiles from './components/RunStepper/StepFiles';
import StepFinal from './components/RunStepper/StepFinal';
import StepFunctions from './components/RunStepper/StepFunctions';
import StepRules from './components/RunStepper/StepRules';
import Module3Context from './Module3Context';
import Run3Context from './RunContext';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#DC3223',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#DC3223',
    },
  },
  line: {
    borderColor: 'gray',
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    buttonContainer: {
      alignItems: 'flex-end',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

function IverifierContainer() {
  const classes = useStyles();
  const history = useHistory();
  const { projectId, configId } = useParams() as any;
  const { project, setSnack } = useContext(Module3Context);
  const runContext = useContext(Run3Context);
  const { config, runId, setConfig } = runContext;
  const [loading, setLoading] = useState(true);

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const steps = ['Files', 'Rules', 'Functions', 'Save or Run'];

  useEffect(() => {
    getProjectConfigs(Number(projectId), Number(configId)).then((response) => {
      delete response.updated_at;
      delete response.updated_by;
      setConfig(response);
      setLoading(false);
    });
  }, []);

  if (loading) return <Loading />;

  const getStepContent = (step: number): React.ReactNode => {
    switch (step) {
      case 0:
        return <StepFiles folders={project.folders} filterFiles={FILES_BY_MODULES.module3} />;
      case 1:
        return <StepRules selected={config.rule_ids ?? []} />;
      case 2:
        return <StepFunctions selected={config.function_ids ?? []} />;
      case 3:
        // save as or new
        return <StepFinal />;
      default:
        return 'Unknown step';
    }
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // first create workload then create config if name not empty
  const handleWorkloadRun = () => {
    const { rule_tree_ids, resources, function_ids, file_separator, name } = runContext.config;
    updateProjectConfig(config).then(() => {
      if (rule_tree_ids?.length === 0) {
        setSnack('No rule is selected', 'error');
        return;
      }
      if (resources.length === 0) {
        setSnack('No file is selected', 'error');
        return;
      }
      workloadCreate({
        project_id: project.id,
        app: 'module3',
        function_ids,
        resources,
        rule_tree_ids,
        file_separator,
      }).then((data) => {
        workloadRunAsync(data.id, config.file_separator);
        history.push(`/projects/${projectId}`);
      });
    });
  };

  const handleSave = () => {
    updateProjectConfig(config)
      .then(() => {
        setSnack(`Config ${config.name}  saved`);
        history.push(`/projects/${projectId}/module3/m3?action=iverifier`);
      })
      .catch((error) => {
        setSnack(`Save failed : ${error.message}`, 'error');
      })
      .finally(() => {});
  };
  return (
    <Grid container>
      {/* <ReactJson src={{ runContext }} collapsed={true} theme="monokai" /> */}
      {/* <Grid item xs={12}>
        <ProjectBreadCrumbs
          projectId={projectId}
          nodes={[
            {
              label: 'Verification & Quality',
              to: `/projects/${project.id}/module3`,
              icon: (
                <img src={m3Logo} alt="Verification & Quality" title="Verification & Quality" />
              ),
            },
            { label: config.name, to: '#' },
          ]}
        />
      </Grid> */}
      <Grid item xs={12}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: React.ReactNode } = {};
            return (
              <Step key={label} {...stepProps} style={{ width: '100%' }}>
                <StepLabel
                  {...labelProps}
                  onClick={() => setActiveStep(index)}
                  style={{ cursor: 'pointer' }}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item xs={12}>
        {/* Footer navigation */}
        {activeStep === steps.length ? (
          <div>
            <Typography variant="h5" gutterBottom>
              Your worlkoad {config.name} is running
            </Typography>
            <Link to={`/projects/${project.id}/module3/runs/${runId}`}>
              <Typography variant="subtitle1">To check worlkoad its status</Typography>
            </Link>
          </div>
        ) : (
          <div>
            {/* stepper content */}
            <div style={{ minWidth: 1100, marginBottom: 30, border: 'none' }}>
              {getStepContent(activeStep)}
            </div>
            {/* stepper buttons */}
            <div
              style={{
                position: 'sticky',
                bottom: 0,
              }}
            >
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="baseline"
                className={classes.buttonContainer}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    style={{ marginRight: 10 }}
                  >
                    {/* Back */}
                    PREVIOUS
                  </Button>
                  {activeStep === steps.length - 1 && (
                    // final buttons to save or run
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        style={{ marginRight: 10 }}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleWorkloadRun}
                        style={{ marginRight: 10 }}
                      >
                        Save and Run
                      </Button>
                    </>
                  )}
                  {activeStep === 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      style={{ marginRight: 10 }}
                      disabled={
                        runContext?.config?.resources === null ||
                        !(runContext?.config?.resources.length > 0)
                      }
                    >
                      {/* Rules */}
                      NEXT
                    </Button>
                  )}

                  {activeStep === 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      style={{ marginRight: 10 }}
                      disabled={
                        runContext?.config?.rule_tree_ids === null ||
                        !(runContext?.config?.rule_tree_ids.length > 0)
                      }
                    >
                      {/* Functions */}
                      NEXT
                    </Button>
                  )}

                  {activeStep === 2 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      style={{ marginRight: 10 }}
                    >
                      Save / Run
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default IverifierContainer;
