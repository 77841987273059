import React from 'react';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const Refresh = ({ refreshDATA }) => {
  return (
    <>
      <IconButton
        color="info"
        onClick={() => {
          refreshDATA(true);
        }}
      >
        <RefreshIcon />
      </IconButton>
    </>
  );
};

export default Refresh;
