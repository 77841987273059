import React, { useEffect, useState } from 'react';
import ExpertSearch from '../TextAnalysisResultContainer/ExpertSearch';
import './transitionCss.css';

const ExpertSearch2 = ({ result }) => {
  // console.log('Expt result', result);
  const [expertDomain, setExpertDomain] = useState<any>([]);
  const [smartData, setSmartData] = useState<any>();
  const [TotalPeopleResult, setTotalPeopleResult] = useState<any>();
  const [payloadMain, setPayloadMain] = useState() as any;
  const [artifactKpi, setArtifactKpi] = useState<any>([]);
  const [rows, setRows] = React.useState([]);
  const [language, setLanguage] = React.useState();
  const [detectLanguage, setDetectLanguage] = React.useState();
  const [timeTaken, setTimeTaken] = useState<any>();
  const [totalResult, setTotalResult] = useState<any>();

  const [alstomEntityWiseDistribution, setAlstomEntityWiseDistribution] = useState<any>([]);
  const [platformWiseDistribution, setPlatformWiseDistribution] = useState<any>([]);
  const [projectWiseDistribution, setProjectWiseDistribution] = useState<any>([]);
  const [documentProfileWiseDistribution, setDocumentProfileWiseDistribution] = useState<any>([]);
  const [artifactWiseDistribution, setArticatWiseDistribution] = useState<any>([]);
  const [solutionWiseDistribution, setSolutionWiseDistribution] = useState<any>([]);
  const [countryWiseDistribution, setCountryWiseDistribution] = useState<any>([]);
  const [productWiseDistribution, setProductWiseDistribution] = useState<any>([]);
  const [searchPayload, setSearchPayload] = useState<any>();
  const [displaySmart, setDisplaySmart] = useState(false);

  useEffect(() => {
    const totalExpertiseResult = result ? result.experts : {};
    const keysExpertDomain = Object.keys(totalExpertiseResult);
    const valuesExpertDomain = Object.values(totalExpertiseResult);
    const expertDomain_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysExpertDomain.length; index++) {
      if (valuesExpertDomain[index] !== 0) {
        expertDomain_array.push({
          name: keysExpertDomain[index],
          'Number Of Artifacts': valuesExpertDomain[index],
        });
      }
    }
    setExpertDomain(expertDomain_array);
    nameClick(Object.keys(result?.experts)[0], true, result);
  }, [result]);

  const nameClick = (getName: string, _e_targetDefault: any = false, payload: any) => {
    const startTime = performance.now();
    const nameValue = getName;
    const payloadMain = payload;
    const specsKpis = payloadMain.specs[nameValue]?.artifactKPIs;
    const keysSpecsKpi = Object.keys(specsKpis);
    const valuesSpecsKpi = Object.values(specsKpis);
    const expertSpecsKpi_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysSpecsKpi.length; index++) {
      if (valuesSpecsKpi[index] !== 0) {
        expertSpecsKpi_array.push({
          name: keysSpecsKpi[index],
          value: valuesSpecsKpi[index],
        });
      }
    }
    // for graph code starts here
    const alstomEntity = payloadMain?.specKPIs[nameValue];
    const keysAlstomEntityWiseDistribution = Object.keys(alstomEntity?.productLineWiseDistribution);
    const valuesAlstomEntityWiseDistribution = Object.values(
      alstomEntity?.productLineWiseDistribution
    );
    const alstomEntityWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysAlstomEntityWiseDistribution.length; index++) {
      if (valuesAlstomEntityWiseDistribution[index] !== 0) {
        alstomEntityWiseDistribution_array.push({
          name: keysAlstomEntityWiseDistribution[index],
          value: valuesAlstomEntityWiseDistribution[index],
        });
      }
    }
    // second graph
    const keysPlatformWiseDistribution = Object.keys(alstomEntity?.platformWiseDistribution);
    const valuesPlatformWiseDistribution = Object.values(alstomEntity?.platformWiseDistribution);
    const platformWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysPlatformWiseDistribution.length; index++) {
      if (valuesPlatformWiseDistribution[index] !== 0) {
        platformWiseDistribution_array.push({
          name: keysPlatformWiseDistribution[index],
          value: valuesPlatformWiseDistribution[index],
        });
      }
    }
    // Third graph
    const keysProjectWiseDistribution = Object.keys(alstomEntity?.projectWiseDistribution);
    const valuesProjectWiseDistribution = Object.values(alstomEntity?.projectWiseDistribution);
    const projectWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysProjectWiseDistribution.length; index++) {
      if (valuesProjectWiseDistribution[index] !== 0) {
        projectWiseDistribution_array.push({
          name: keysProjectWiseDistribution[index],
          value: valuesProjectWiseDistribution[index],
        });
      }
    }
    // Fourth graph documentProfileWiseDistribution
    const keysdocumentProfileWiseDistribution = Object.keys(
      alstomEntity?.documentProfileWiseDistribution
    );
    const valuesdocumentProfileWiseDistribution = Object.values(
      alstomEntity?.documentProfileWiseDistribution
    );
    const documentProfileWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysdocumentProfileWiseDistribution.length; index++) {
      if (valuesdocumentProfileWiseDistribution[index] !== 0) {
        documentProfileWiseDistribution_array.push({
          name: keysdocumentProfileWiseDistribution[index],
          value: valuesdocumentProfileWiseDistribution[index],
        });
      }
    }
    // Fifth Graph
    const keysArtifactWiseDistribution = Object.keys(alstomEntity?.artifactWiseDistribution);
    const valuesArtifactWiseDistribution = Object.values(alstomEntity?.artifactWiseDistribution);
    const artifactWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysArtifactWiseDistribution.length; index++) {
      if (valuesArtifactWiseDistribution[index] !== 0) {
        artifactWiseDistribution_array.push({
          name: keysArtifactWiseDistribution[index],
          value: valuesArtifactWiseDistribution[index],
        });
      }
    }
    // Fiffth graph documentProfileWiseDistribution
    const keysdocumentSolutionWiseDistribution = Object.keys(
      alstomEntity?.solutionWiseDistribution
    );
    const valuesdocumentSolutionWiseDistribution = Object.values(
      alstomEntity?.solutionWiseDistribution
    );
    const solutionWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysdocumentSolutionWiseDistribution.length; index++) {
      if (valuesdocumentSolutionWiseDistribution[index] !== 0) {
        solutionWiseDistribution_array.push({
          name: keysdocumentSolutionWiseDistribution[index],
          value: valuesdocumentSolutionWiseDistribution[index],
        });
      }
    }
    setSolutionWiseDistribution(solutionWiseDistribution_array);
    // sixth graph documentProfileWiseDistribution
    const keysProductWiseDistribution = Object.keys(alstomEntity?.productWiseDistribution);
    const valuesProductWiseDistribution = Object.values(alstomEntity?.productWiseDistribution);
    const productWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysProductWiseDistribution.length; index++) {
      if (
        valuesProductWiseDistribution[index] !== 0 &&
        keysProductWiseDistribution[index] &&
        valuesProductWiseDistribution[index]
      ) {
        productWiseDistribution_array.push({
          name: keysdocumentSolutionWiseDistribution[index],
          value: valuesdocumentSolutionWiseDistribution[index],
        });
      }
    }
    setProductWiseDistribution(productWiseDistribution_array);
    // Fiffth graph documentProfileWiseDistribution
    const keysCountryWiseDistribution = Object.keys(alstomEntity?.countryWiseDistribution);
    const valuesCountryWiseDistribution = Object.values(alstomEntity?.countryWiseDistribution);
    const countryWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysCountryWiseDistribution.length; index++) {
      if (valuesCountryWiseDistribution[index] !== 0) {
        countryWiseDistribution_array.push({
          name: keysCountryWiseDistribution[index],
          value: valuesCountryWiseDistribution[index],
        });
      }
    }
    setCountryWiseDistribution(countryWiseDistribution_array);
    // setLanguage(payloadMain?.translation?.detectedLanguage);
    // setDetectLanguage(payloadMain?.translation?.translatedText);
    setSmartData(payloadMain?.specs[nameValue]?.smartIDCard);
    setArtifactKpi(expertSpecsKpi_array);
    const endTime = performance.now();
    const t = ((endTime - startTime) / 3600).toFixed(2);
    setTimeTaken(t);
    setRows(payloadMain?.specs[nameValue]?.searchResults);
    setTotalPeopleResult(payloadMain?.specs[nameValue]?.numberOfResults);
    setAlstomEntityWiseDistribution(alstomEntityWiseDistribution_array);
    setPlatformWiseDistribution(platformWiseDistribution_array);
    setProjectWiseDistribution(projectWiseDistribution_array);
    setDocumentProfileWiseDistribution(documentProfileWiseDistribution_array);
    setArticatWiseDistribution(artifactWiseDistribution_array);
    setPayloadMain(payload);
    setDisplaySmart(false);
  };
  return (
    <div>
      <ExpertSearch
        rowResult={rows}
        alstomEntityWiseDistribution={alstomEntityWiseDistribution}
        platformWiseDistribution={platformWiseDistribution}
        projectWiseDistribution={projectWiseDistribution}
        documentProfileWiseDistribution={documentProfileWiseDistribution}
        artifactWiseDistribution={artifactWiseDistribution}
        solutionWiseDistribution={solutionWiseDistribution}
        countryWiseDistribution={countryWiseDistribution}
        productWiseDistribution={productWiseDistribution}
        expertDomain={expertDomain}
        nameClick={nameClick}
        smartData={smartData}
        artifactKpi={artifactKpi}
        TotalPeopleResult={TotalPeopleResult}
        timeTaken={timeTaken}
        payloadMain={payloadMain}
      />
    </div>
  );
};

export default ExpertSearch2;
