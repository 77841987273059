// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import { AlertProps } from '@material-ui/lab';
import isEqual from 'react-fast-compare';
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useReducer, useState } from 'react';
import AlertSnack from '../../components/Alert/AlertSnack';
import ProjectContext from '../Project/ProjectContext';
import useAppToken from '../../app/useAppToken';
import {
  getHistoryTranslation,
  iSearcherGetArtifactEntityFilters,
  iSearcherGetFilters,
  RexFilters,
  textToTextInitialFilter,
} from '../../api/module2';
// import { reducers, initialState } from '../Reducers/combineReducer';

export interface Module2ContextType {
  project: Project;
  setProject: (d: Project) => any;
  artifacts: Artifact[];
  setArtifacts: (d: Artifact) => any;
  setSnackMsg: (msg: string) => any;
  setSnackSeverity: (severity: AlertProps['severity']) => any;
  setSnack: (msg: string, severity?: AlertProps['severity']) => any;
  historyTranslation: His;
  setHistoryTranslation: (h: His) => any;
}

const Module2Context = React.createContext<Module2ContextType>({
  project: {} as any,
  setProject: (): any => {},
  artifacts: [] as any,
  historyTranslation: [] as any,
  setArtifacts: (): any => {},
  setSnackMsg: (): any => {},
  setSnackSeverity: (): any => {},
  setHistoryTranslation: (): any => {},
  // combi msg + severity
  setSnack: (): any => {},
});

type Props = {
  project: Project;
  artifacts?: Artifact[];
  children: any;
};

const Module2ContextProvider = ({ project, artifacts, children }: Props): any => {
  const [_project, _setProject] = useState(project);
  const [_artifacts, _setArtifacts] = useState(artifacts);
  const [snackMsg, setSnackMsg] = useState('');
  // const [state, dispatch] = useReducer(reducers, initialState);
  const [snackSeverity, setSnackSeverity] = useState<AlertProps['severity']>('success');
  const [historyTranslation, setHistoryTranslation] = useState([]);
  const { appJWT } = useAppToken();

  // console.log('initial', initialState);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackMsg('');
  };

  return (
    <Module2Context.Provider
      value={{
        project: _project,
        setProject: (_input) => {
          if (!isEqual(project, _input)) _setProject(_input);
        },
        artifacts: _artifacts || ([] as any),
        setArtifacts: (_input) => {
          if (!isEqual(project, _input)) _setArtifacts(_input as any);
        },
        setSnackMsg,
        setSnackSeverity,
        historyTranslation,
        setHistoryTranslation,
        setSnack: (msg: string, severity = 'success' as AlertProps['severity']) => {
          setSnackMsg(msg);
          setSnackSeverity(severity);
        },
      }}
    >
      {snackMsg !== '' && (
        <AlertSnack
          msg={snackMsg}
          severity={snackSeverity}
          handleClose={handleClose}
          duration={3000}
        />
      )}
      {children}
    </Module2Context.Provider>
  );
};

export default Module2Context;

export { Module2ContextProvider };
