import {
  insertCharacterizer,
  insertNewAssigned,
  mergeAssignment,
  mergeRepeatCapture,
  PayloadInsertNewAssigned,
  splitCapture,
} from '../../../../../../api/module1';
import convertToPairs from '../utils/convertToPairs';
import extractionTextCode from '../utils/extractionTextCode';
import extractOriginalLang from '../utils/extractOriginalLang';

const handleTextAlter = (
  rowsSelection: any,
  concatStr: string,
  targetColumn: any,
  groups: any,
  users: any,
  langCheck: any,
  languagesCode: any,
  projectId: any,
  workloadId: any,
  row_types: any,
  i18n: any,
  transCode: any
) => {
  const { OriginalLang } = extractOriginalLang(i18n, langCheck, languagesCode);
  const body = {
    project_id: Number(projectId),
    workload_id: workloadId,
    ids: rowsSelection,
    column_name: targetColumn ?? 'Text',
    language_code: transCode === '-' ? OriginalLang : transCode,
    concat_str: concatStr,
  };
  return mergeAssignment(body, row_types)
    .then((response: any) => {
      const extractedDAta = extractionTextCode(
        response,
        targetColumn,
        OriginalLang,
        transCode,
        groups,
        users
      );
      return convertToPairs(extractedDAta);
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {});
};

const handleSplitSubmit = (
  formBody: any,
  rowsSelection: any,
  targetColumn: any,
  groups: any,
  users: any,
  langCheck: any,
  languagesCode: any,
  projectId: any,
  workloadId: any,
  row_types: any,
  i18n: any,
  transCode: any
) => {
  const { OriginalLang } = extractOriginalLang(i18n, langCheck, languagesCode);
  const body = {
    project_id: Number(projectId),
    workload_id: workloadId,
    id: rowsSelection,
    column_name: targetColumn ?? 'Text',
    language_code: transCode === '-' ? OriginalLang : transCode,
    ...formBody,
  };
  return splitCapture(body, row_types)
    .then((response: any) => {
      const extractedDAta = extractionTextCode(
        response,
        targetColumn,
        OriginalLang,
        transCode,
        groups,
        users
      );
      return convertToPairs(extractedDAta);
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {});
};

const handleRepeatMergeData = (
  rowsSelection: any,
  targetColumn: string | number,
  concatStr: any,
  groups: any,
  users: any,
  langCheck: any,
  languagesCode: any,
  projectId: any,
  workloadId: any,
  row_types: any,
  i18n: any,
  transCode: any
) => {
  const { OriginalLang } = extractOriginalLang(i18n, langCheck, languagesCode);
  const body = {
    project_id: Number(projectId),
    workload_id: workloadId,
    ids: rowsSelection,
    column_name: targetColumn ?? 'Text',
    concat_str: concatStr,
    language_code: transCode === '-' ? OriginalLang : transCode,
  };
  return mergeRepeatCapture(body, row_types)
    .then((response: any) => {
      const extractedDAta = extractionTextCode(
        response,
        targetColumn,
        OriginalLang,
        transCode,
        groups,
        users
      );
      return convertToPairs(extractedDAta);
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {});
};

const handelNewCaptureWithFile = async (
  formBody: {
    context: any;
    comment: any;
    hierarchy: any;
    subtype: any;
    characterization: any;
    Cybersecurity: any;
    Standards: any;
    Commercial: any;
    Finance: any;
    Contractual: any;
  },
  file: { file: string | Blob },
  transCode: string,
  targetColumn: string,
  i18n: {},
  langCheck: any,
  languagesCode: any[],
  id: number,
  groups: any,
  users: any,
  row_types: any
) => {
  const formData = new FormData();
  formData.append('file', file.file);
  const columnName = targetColumn ?? 'Text';

  const { OriginalLang } = extractOriginalLang(i18n, langCheck, languagesCode);

  const body = {
    Context: formBody?.context,
    comment: formBody?.comment,
    Hierarchy: formBody?.hierarchy,
    SubType: formBody.subtype,
    capture: 'Image',
    Type: 'Text',
    i18n: { en: 'Image' },
    Characterization: formBody.characterization,
    Cybersecurity: formBody.Cybersecurity,
    Standards: formBody.Standards,
    Commercial: formBody.Commercial,
    Finance: formBody.Finance,
    Contractual: formBody.Contractual,
    column_name: columnName,
    language_code: transCode === '-' ? OriginalLang : transCode,
  } as PayloadInsertNewAssigned;
  // const id = Number(rowsEdited.find((row, index) => selectionRows.includes(index)).id);

  try {
    const response = await insertCharacterizer(id, body, row_types, formData);
    const extractedDAta = extractionTextCode(
      response,
      targetColumn,
      OriginalLang,
      transCode,
      groups,
      users
    );
    return convertToPairs(extractedDAta);
  } catch (error) {
    console.log(error);
  }
  return {};
};

const handelNewCaptureWithoutFile = async (
  formBody: any,
  file: { file: string | Blob },
  transCode: string,
  targetColumn: string,
  i18n: {},
  langCheck: any,
  languagesCode: any[],
  id: number,
  groups: any,
  users: any,
  row_types: any,
  languages: any
) => {
  const columnName = targetColumn ?? 'Text';
  const { OriginalLang } = extractOriginalLang(i18n, langCheck, languagesCode);

  const tempObj1: any = {};
  if (languages?.length) {
    tempObj1[OriginalLang] = formBody.capture;
    for (const i of languages) {
      tempObj1[i] = formBody.capture;
    }
  }
  const tempObj = {} as any;
  if (Object.keys(tempObj1).length === 0) {
    tempObj[OriginalLang] = formBody.capture;
    tempObj.en = formBody.capture;
  }
  const body = {
    Context: formBody?.context,
    comment: formBody?.comment,
    Hierarchy: formBody?.hierarchy,
    SubType: formBody.subtype,
    capture: formBody.capture,
    Characterization: formBody.characterization,
    Cybersecurity: formBody.Cybersecurity,
    Standards: formBody.Standards,
    Commercial: formBody.Commercial,
    Finance: formBody.Finance,
    Contractual: formBody.Contractual,
    column_name: columnName,
    i18n: Object.keys(tempObj1).length > 0 ? tempObj1 : tempObj,
    language_code: transCode === '-' ? OriginalLang : transCode,
  } as PayloadInsertNewAssigned;

  try {
    const response = await insertNewAssigned(id, body);

    const extractedDAta = extractionTextCode(
      response,
      targetColumn,
      OriginalLang,
      transCode,
      groups,
      users
    );

    return convertToPairs(extractedDAta);
  } catch (error) {
    console.log(error);
  }

  return {};
};

export {
  handleTextAlter,
  handleSplitSubmit,
  handleRepeatMergeData,
  handelNewCaptureWithFile,
  handelNewCaptureWithoutFile,
};
