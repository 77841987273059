import { Box, Button } from '@mui/material';
import React from 'react';
import { ButtonMUI, ToogleContainer, ButtonMUIToggle, floatBtn } from '../Styles/utils';

function Toogle({ onChangeToggle, toggleTable }) {
  return (
    <>
      <ToogleContainer>
        <ButtonMUIToggle
          onClick={() => {
            onChangeToggle(true);
          }}
          variant="contained"
          style={
            toggleTable
              ? { backgroundColor: '#DC3223' }
              : { background: 'transparent', color: '#002a45' }
          }
          // color={toggleTable ? 'secondary' : ''}
        >
          View All
        </ButtonMUIToggle>
        <ButtonMUIToggle
          onClick={() => {
            onChangeToggle(false);
          }}
          variant="contained"
          style={
            toggleTable
              ? { background: 'transparent', color: '#002a45' }
              : { backgroundColor: '#DC3223' }
          }
          // style={{ marginLeft: '0.5rem' }}
          // color={toggleTable ? '' : 'secondary'}
        >
          Requirements
        </ButtonMUIToggle>
      </ToogleContainer>
    </>
  );
}

export default Toogle;
