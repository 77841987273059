import { Checkbox, Input, ListItemText, MenuItem, Select } from '@material-ui/core';
import React, { useEffect } from 'react';

type Props = {
  languageCode: string;
  onChange: any;
};

const style = {
  menuLang: {
    '& .Mui-selected': {
      fontWeight: 'bold',
    },
  },
};

export const translateToLanguages = [
  { label: 'French', value: 'fr' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Spanish', value: 'es' },
  { label: 'Italian', value: 'it' },
];

export const allLanguages = [
  { label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Spanish', value: 'es' },
  { label: 'Italian', value: 'it' },
];

export const getLanguageCode = (langLabel: String) => {
  if (langLabel === '-') return '-';
  return langLabel
    ? allLanguages.find(({ label }) => {
        return label.toLowerCase() === langLabel.toLowerCase();
      })?.value || '-'
    : '';
};

export const getLanguageLabel = (langCode: String) =>
  allLanguages.find(({ value }) => {
    return value === langCode;
  })?.label;

// componemt
function TranslateSelection({ languageCode, onChange }: Props) {
  const [translateTo, setTranslateTo] = React.useState<string[]>(
    languageCode !== 'en' ? ['en'] : []
  );

  useEffect(() => {
    setTranslateTo(languageCode !== 'en' ? ['en'] : []);
  }, [languageCode]);

  // remove default language
  const translateToLanguages2 = translateToLanguages.filter(({ value }) => {
    return value !== languageCode;
  });

  const handleChangeTranslate = (event: ChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    const listTranslate = typeof value === 'string' ? value.split(',') : value;
    setTranslateTo(
      // On autofill we get a stringified value.
      listTranslate
    );
    onChange(event);
  };

  return (
    <Select
      label="Translate"
      name="translate"
      multiple
      // input={<Input />}
      value={translateTo}
      onLoad={handleChangeTranslate}
      onChange={handleChangeTranslate}
      renderValue={(selected: any) => selected.join(', ')}
      className={style.menuLang}
    >
      {/* // add english native language is not english by default */}
      {languageCode !== 'en' && (
        <MenuItem key="translate-en" value="en" disabled>
          <Checkbox checked />
          <ListItemText primary="English" />
        </MenuItem>
      )}
      {translateToLanguages2.map(({ label, value }: any, index: number) => (
        <MenuItem key={`translate-${index}`} value={value}>
          <Checkbox checked={translateTo.indexOf(value) > -1} />
          <ListItemText primary={label} />
        </MenuItem>
      ))}
    </Select>
  );
}

export default TranslateSelection;
