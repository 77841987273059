import React, { useMemo, useState } from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import AllocationExtraction, { AnalysisAllocationExtraction } from '../utils/allocationExtraction';

const useDownloadFile = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    columns,
    row: data,
    filename,
    service,
    targetColumn,
    projectGroups,
    userId,
    groups,
    users,
  } = props;

  const [mergColumn, setMergColumns] = useState([]);
  const [progress, setProgress] = useState(0);

  const exportRows = useMemo(() => {
    if (!data) {
      return [];
    }

    let exportRows = [] as any;

    for (const item of data) {
      if (service === 'analysis') {
        // const AllocationsTemp = [] as any;
        if (item.Characterization === 'Requirement') {
          AnalysisAllocationExtraction(exportRows, item, targetColumn, projectGroups, userId);
        } else {
          exportRows.push({
            Id: item.id,
            ...item,
          });
        }
      } else if (service === 'assignment') {
        if (item.Characterization === 'Requirement') {
          // const AllocationsTemp = [] as any;
          AllocationExtraction(exportRows, item, groups, users, targetColumn);
          // const temp = [...exportRows, AllocationsTemp]
        } else {
          exportRows.push({
            Id: item.id,
            ...item,
          });
        }
      }
      if (['capture', 'characterizer'].includes(service)) {
        exportRows = data;
      }
    }
    return exportRows;
  }, [data, projectGroups]);

  const extractAllocatorColomn = () => {
    const col = columns.map((data) => {
      if (typeof data.header === 'string') {
        if (data.header === 'iSenS_ID') {
          return {
            header: data.header,
            key: 'Id',
            width: 10,
          };
        }
        return {
          header: data.header,
          key: data.accessorKey,
          width: data.accessorKey === 'extractedText' ? 50 : 20,
        };
      }

      return {
        header: data.header[0],
        key: data.accessorKey[0],
        width: 50,
      };
    });

    if (service === 'assignment') {
      const temp = [
        {
          header: 'Lifecycle',
          key: 'lifecycle',
          width: 30,
        },
        {
          header: 'PBS',
          key: 'pbs',
          width: 30,
        },
        {
          header: 'ABS',
          key: 'abs',
          width: 30,
        },
        {
          header: 'OBS',
          key: 'obs',
          width: 30,
        },
        {
          header: 'Receiver',
          key: 'receiverName',
          width: 30,
        },
      ];
      const mCol = ['G', 'H', 'I', 'J', 'K'] as any;
      setMergColumns(mCol);

      return [...col.slice(0, 6), ...temp, ...col.slice(6, col.length)];
    }

    if (service === 'analysis') {
      const temp = [
        {
          header: 'Lifecycle',
          key: 'lifecycle',
          width: 30,
        },
        {
          header: 'PBS',
          key: 'pbs',
          width: 30,
        },
        {
          header: 'Compliance',
          key: 'compliance',
          width: 30,
        },
        {
          header: 'NC',
          key: 'nc',
          width: 30,
        },
        {
          header: 'Gap',
          key: 'gap',
          width: 30,
        },
        {
          header: 'Rationale',
          key: 'rationale',
          width: 30,
        },
      ];
      const mCol = ['G', 'H', 'I', 'J', 'K', 'L'] as any;
      setMergColumns(mCol);

      return [...col.slice(0, 6), ...temp, ...col.slice(6, col.length)];
    }

    return col;
  };

  const extractColumn = () => {
    const col = columns.map((data) => {
      if (data.name === 'Characterization' && service === 'characterizer') {
        return {
          header: 'Characterization',
          key: 'Characterization',
          width: 30,
        };
      }

      if (data.name === 'comment' && service === 'characterizer') {
        return {
          header: 'Comment',
          key: 'comment',
          width: 30,
        };
      }

      if (data.name === 'id') {
        return {
          header: data.title,
          key: data.name,
          width: 10,
        };
      }

      if (data.name === 'Char_Score' && service === 'characterizer') {
        return {
          header: 'Char_Score',
          key: 'Char_Score',
          width: 30,
        };
      }
      return {
        header: data.title,
        key: data.name,
        width: 30,
      };
    });

    return col;
  };

  const exportColumns = useMemo(() => {
    if (['capture', 'characterizer'].includes(service)) {
      return extractColumn();
    }

    return extractAllocatorColomn();
  }, [columns, service]);

  // console.log('exportRows', exportRows);

  const exportAllDataforAllocator = async () => {
    try {
      setLoading(true);
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('My Sheet');
      worksheet.columns = exportColumns;
      worksheet.addRows(exportRows);
      worksheet.getRow(1).font = { bold: true };
      worksheet.getColumn('B').eachCell({ includeEmpty: true }, function (cell: any, rowNumber) {
        const currentCell = cell;
        if (cell) {
          currentCell.alignment = { wrapText: true };
        }
      });
      const col_len = columns.length;
      // let trackAllocation = 0;
      // let let_track_index = 2;

      for (let i = 0; i < exportRows.length; i += 1) {
        const { SubType } = exportRows[i];
        if (SubType === 'Image') {
          const { RawData } = exportRows[i];
          const temp = i + 2;
          const excellCol = String.fromCharCode(64 + 2) + temp.toString();
          const cell = worksheet.getCell(excellCol);
          worksheet.getColumn(2).width = 50;
          worksheet.getRow(temp).height = 75;
          const imageObject = workbook.addImage({
            base64: RawData,
            extension: 'png',
          });

          worksheet.addImage(imageObject, {
            tl: { col: cell.col - 1, row: cell.row - 1 },
            br: { col: cell.col, row: cell.row },
            ext: { width: 50, height: 75 },
          });
        }
      }
      // let prentIndex = 0;/
      // let i = 0;

      // const processBatch = async (batch, startRow) => {
      //   let i = 0;
      //   while (i < batch.length) {
      //     const { Characterization, SubType } = batch[i];

      // trackAllocation = 0;

      // if (Characterization === 'Requirement') {
      //   const { allocations } = batch[i];
      //   const allocationLenth = Object.values(allocations);

      //   let j = 0;
      //   while (j < col_len) {
      //     if (!mergColumn.includes(String.fromCharCode(64 + j + 1))) {
      //       if (j > 25) {
      //         const temp = j - 25;
      //         worksheet.mergeCells(
      //           `A${String.fromCharCode(
      //             64 + temp + 1
      //           )}${let_track_index}:A${String.fromCharCode(64 + temp + 1)}${(
      //             allocationLenth.length +
      //             let_track_index -
      //             1
      //           ).toString()}`
      //         );
      //         j += 1;
      //       } else {
      //         worksheet.mergeCells(
      //           `${String.fromCharCode(64 + j + 1)}${let_track_index}:${String.fromCharCode(
      //             64 + j + 1
      //           )}${(allocationLenth.length + let_track_index - 1).toString()}`
      //         );

      //         j += 1;
      //       }
      //     } else {
      //       j += mergColumn.length;
      //     }
      //   }
      //   let_track_index += allocationLenth.length - 1;

      //   i += allocationLenth.length - 1;
      // }
      //     if (SubType === 'Image') {
      //       const { RawData } = batch[i];
      //       const temp = startRow + i + 2;
      //       const excellCol = String.fromCharCode(64 + 2) + temp.toString();
      //       const cell = worksheet.getCell(excellCol);
      //       worksheet.getColumn(2).width = 50;
      //       worksheet.getRow(temp).height = 75;

      //       const imageObject = workbook.addImage({
      //         base64: RawData,
      //         extension: 'png',
      //       });

      //       worksheet.addImage(imageObject, {
      //         tl: { col: cell.col - 1, row: cell.row - 1 },
      //         br: { col: cell.col, row: cell.row },
      //         ext: { width: 50, height: 75 },
      //       });
      //       let_track_index += 1;
      //       i += 1;
      //     } else {
      //       i += 1;
      //       let_track_index += 1;
      //     }
      //     setProgress(((startRow + i + 1) / exportRows.length) * 100);
      //     await new Promise((resolve) => setTimeout(resolve, 0)); // Yield control to UI
      //   }
      // };

      // const batchSize = 100;
      // for (let i = 0; i < exportRows.length; i += batchSize) {
      //   const batch = exportRows.slice(i, i + batchSize);
      //   await processBatch(batch, i);
      // }

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, filename);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const exportAllData = () => {
    setLoading(true);
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('My Sheet');
      worksheet.columns = exportColumns;
      const temp = [...exportRows];
      worksheet.addRows(temp);
      worksheet.getRow(1).font = { bold: true };
      worksheet.getColumn('B').eachCell({ includeEmpty: true }, function (cell: any, rowNumber) {
        const currentCell = cell;
        if (cell) {
          currentCell.alignment = { wrapText: true };
        }
      });

      for (let i = 0; i < exportRows.length; i += 1) {
        const { SubType } = exportRows[i];
        if (SubType === 'Image') {
          const { RawData } = exportRows[i];
          const temp = i + 2;
          const excellCol = String.fromCharCode(64 + 2) + temp.toString();
          const cell = worksheet.getCell(excellCol);
          worksheet.getColumn(2).width = 50;
          worksheet.getRow(temp).height = 75;
          const imageObject = workbook.addImage({
            base64: RawData,
            extension: 'png',
          });

          worksheet.addImage(imageObject, {
            tl: { col: cell.col - 1, row: cell.row - 1 },
            br: { col: cell.col, row: cell.row },
            ext: { width: 50, height: 75 },
          });
        }
      }

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, filename);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return { exportAllData, exportAllDataforAllocator, loading, progress };
};

export default useDownloadFile;
