import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Grid, Paper } from '@material-ui/core';
import Iframe from 'react-iframe';
import Axios from '../../../api/axios';
import { BACKEND_URL } from '../../../const';

function EnggRedinessHome() {
  const [url, seturl] = useState('');
  const history = useHistory();
  useEffect(() => {
    Axios.get(`${BACKEND_URL.root}/engg_readiness/getenggreadinessurl`).then((response) => {
      seturl(response.data);
    });
  }, []);

  if (url === '') return null;

  return (
    <Paper style={{ padding: 30 }}>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          maxHeight: '75vh',
          overflowY: 'scroll',
        }}
      >
        <Iframe
          url={`${url}`}
          width="100%"
          height="1000px"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
        />
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            //   onClick={datasetProcess}
            style={{
              height: '50px',
              width: '150px',
              marginTop: 10,
              float: 'right',
            }}
            onClick={() => history.push('/me/readiness')}
            // disabled={selectedFinalProgramName.length < 1}
            // onClick={dataProcessAll}
            // onClick={() => setSendRequest(true)}
          >
            Add/Edit
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default EnggRedinessHome;
