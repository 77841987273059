export const excelInitialState = {
  fileType: 'Excel',
  sheetName: '',
  targetColumn: '',
  columns: [],
  filename: '',
};

const excelFilereducers = (state, action) => {
  switch (action.type) {
    case 'change_sheets': {
      return { ...state, sheetName: action.payload };
    }

    case 'change_targetColumn': {
      return { ...state, targetColumn: action.payload };
    }

    case 'change_columns': {
      return { ...state, columns: action.payload.columnName, filename: action.payload.filename };
    }

    case 'clear_sheets': {
      return {
        ...state,
        sercive: '',
        sheetName: '',
        targetColumn: '',
        columns: [],
      };
    }
    default:
      return state;
  }
};

export default excelFilereducers;
