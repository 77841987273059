import { makeStyles } from '@material-ui/core';
import React from 'react';

export const useStyles = makeStyles(() => ({
  typingAnimation: {
    display: 'inline-block',
    fontSize: '44px',
  },

  dot: {
    display: 'inline-block',
    animation: '$bouncing 1s infinite',
  },

  '@keyframes bouncing': {
    '0%': {
      transform: 'translateY(0)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(-10px)',
    },
  },
}));

function Dots() {
  const classes = useStyles();
  return (
    <>
      <span className={classes.typingAnimation}>
        <span className={classes.dot}>.</span>
        <span className={classes.dot} style={{ animationDelay: '0.1s' }}>
          .
        </span>
        <span className={classes.dot} style={{ animationDelay: '0.2s' }}>
          .
        </span>
      </span>
    </>
  );
}

export default Dots;
