/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Box, ClickAwayListener, Typography, Button, IconButton, Tooltip } from '@material-ui/core';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ClearIcon from '@mui/icons-material/Clear';
import NotesIcon from '@mui/icons-material/Notes';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const HistoryNav = ({ toggleTab, dropHistory, setToggleTab, handleDropHistory }: any) => {
  return (
    <>
      <Box
        onClick={handleDropHistory}
        style={dropHistory ? { borderBottom: '1px solid #ccd6f2' } : {}}
        className="textTrans-container-second-half"
      >
        <Box>
          <Button
            onClick={(e) => {
              if (dropHistory) e.stopPropagation();
              // e.stopPropagation();
              setToggleTab((prev) => {
                return {
                  ...prev,
                  left: '0rem',
                };
              });
            }}
            style={{ width: '5rem' }}
          >
            Recent
          </Button>
          <Button
            onClick={(e) => {
              if (dropHistory) e.stopPropagation();
              // e.stopPropagation();
              setToggleTab((prev) => {
                return {
                  ...prev,
                  left: '5rem',
                };
              });
            }}
            style={{ width: '5rem' }}
          >
            Saved
          </Button>
        </Box>
        <IconButton>
          <KeyboardArrowDownIcon
            style={dropHistory ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}
          />
        </IconButton>
        {dropHistory && (
          <div onClick={(e) => e.stopPropagation()} style={toggleTab} className="scrolLine">
            {}
          </div>
        )}
      </Box>
    </>
  );
};

export default HistoryNav;
