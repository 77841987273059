import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  DataTypeProvider,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  ClickAwayListener,
  IconButton,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import PublishIcon from '@mui/icons-material/Publish';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
// import DragAndDrop from '../../../../../components/Core/DragAndDrop';
// import WorkSpaceContext from '../../../WorkspceContext';
import TStyles, {
  rowComponent,
  tUseStyles,
} from '../../../../../../../components/Datagrid/TStyles';
// import formatBytes from '../../../../../../../formatBytes';

import ExcelDoc from '../../../../../../../assets/icons8-excel-48.png';
import PptDoc from '../../../../../../../assets/icons8-powerpoint-48.png';
import DocxDoc from '../../../../../../../assets/icons8-microsoft-word-2019-48.png';
import PdfDoc from '../../../../../../../assets/pdf_ins.png';
import CsvDoc from '../../../../../../../assets/csv_icons.png';
import DocAnalysisContext from '../../../DocAnalysisContext';
import { fileDetails } from '../../../../../../../api/module1';
import WorkSpaceContext from '../../../../../WorkspceContext';

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
  { name: 'userName', title: 'Uploaded by' },
];

const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

const FilesContiner = () => {
  const [currentPage, setCurrentPage] = useState();
  const [pageSize, setPageSize] = useState(5);
  const pageSizes = [5, 10, 20, 50];
  const { docFiles, setDocFiles, setSnack } = useContext(WorkSpaceContext);
  const filesOject = {};
  const {
    operation,
    setOperation,
    scopeSetting,
    setScopeSetting,
    selection,
    setSelection,
    fileSetting,
    setFileSettings,
    setToggleTab,
  } = useContext(DocAnalysisContext);

  const docOperation = [
    'Similarity Analysis',
    'Document Similarity',
    'Compare Versions',
    'Extract Topics',
    // 'Translate',
    'Find Duplicates',
  ];

  const getFileDetails = (filename: any, filenameCopy: any, container: any) => {
    fileDetails(filename, filenameCopy, container).then((data) => {
      setToggleTab((prev) => {
        return {
          ...prev,
          left: '5rem',
        };
      });
      setScopeSetting([data.output]);
    });
  };

  const handleOneFileServise = (selectedKeys: any) => {
    const newselection: any = [0];
    if (selectedKeys[0] === undefined) {
      // eslint-disable-next-line no-param-reassign
      selectedKeys[0] = selectedKeys.slice(-1).pop();
    }
    // eslint-disable-next-line prefer-destructuring
    newselection[0] = selectedKeys[selectedKeys.length - 1];
    setSelection(newselection);
    if (selectedKeys.length !== 0) {
      const fileno = selectedKeys.slice(-1).pop();
      if (fileno !== undefined) {
        setScopeSetting([]);
        getFileDetails(
          docFiles[fileno].filename,
          docFiles[fileno].filename,
          docFiles[fileno].container
        );
      }
    }
  };

  const handleTwoFileServise = (selectedKeys: any) => {
    if (selectedKeys.length > 2) {
      setSnack('More then two files are not allowed', 'error');
      return;
    }
    if (selectedKeys.length === 2) {
      const promises = [];
      for (const eachpayload of selectedKeys) {
        promises.push(() =>
          fileDetails(
            docFiles[eachpayload].filename,
            docFiles[eachpayload].filename,
            docFiles[eachpayload].container
          )
        );
      }

      Promise.all(promises.map((fn) => fn())).then((result) => {
        const output = result.map((data) => {
          return data.output;
        });
        setScopeSetting(output);
        setToggleTab((prev) => {
          return {
            ...prev,
            left: '5rem',
          };
        });
      });
    }
    setSelection(selectedKeys);
  };

  const handleExtractTopics = (selectedKeys) => {
    if (selectedKeys.length > 5) {
      setSnack('More then Five files are not allowed', 'error');
      return;
    }
    const promises = [];
    // if ()
    if (Object.keys(fileSetting).length > 0) {
      setFileSettings({});
    }
    for (const eachpayload of selectedKeys) {
      promises.push(() =>
        fileDetails(
          docFiles[eachpayload].filename,
          docFiles[eachpayload].filename,
          docFiles[eachpayload].container
        )
      );
    }
    Promise.all(promises.map((fn) => fn())).then((result) => {
      const output = result.map((data) => {
        return data.output;
      });
      setScopeSetting(output);
    });
    setSelection(selectedKeys);
  };

  const handleSelectionChange = (selectedKeys: any) => {
    switch (docOperation[operation]) {
      case 'Similarity Analysis':
        handleOneFileServise(selectedKeys);
        break;
      case 'Document Similarity':
        handleTwoFileServise(selectedKeys);
        break;
      case 'Compare Versions':
        handleTwoFileServise(selectedKeys);
        break;
      case 'Extract Topics':
        handleExtractTopics(selectedKeys);
        break;
      case 'Find Duplicates':
        handleOneFileServise(selectedKeys);
        break;
      default:
        break;
    }
  };

  const renderUploadDate = ({ row }) => {
    const dates = row.date.split(' ');
    return (
      <>
        {dates[0]} {dates[1].split('.')[0]}
      </>
    );
  };

  const renderFileName = ({ row }: any) => {
    const ext = row.filename.split('.');
    const extensions = {
      xlsx: ExcelDoc,
      pptx: PptDoc,
      csv: CsvDoc,
      pdf: PdfDoc,
      docx: DocxDoc,
    };
    return (
      <div
        style={{
          position: 'relative',
          padding: '0.3rem',
          width: 'max-content',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <img src={extensions[ext[ext.length - 1]]} height="30" width="30" alt="img" />
          <Typography>{row.filename}</Typography>
        </div>
        {row?.isNew && (
          <div
            style={{
              width: 'max-content',
              padding: '0.2rem',
              borderRadius: '10px',
              backgroundColor: '#19AA6E',
              color: 'white',
              position: 'absolute',
              top: '-12px',
              right: '-35px',
            }}
          >
            <Typography style={{ fontSize: '0.85em' }}>New !</Typography>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Box>
        <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
          <ExpressGrid rows={docFiles} columns={columns}>
            <SelectionState selection={selection} onSelectionChange={handleSelectionChange} />
            <FilteringState />
            <IntegratedFiltering />
            <DataTypeProvider for={['filename']} formatterComponent={renderFileName} />
            <DataTypeProvider for={['date']} formatterComponent={renderUploadDate} />
            <SortingState />
            <IntegratedSorting />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <VirtualTable />
            <Table tableComponent={TableComponent} />
            <TableColumnResizing
              defaultColumnWidths={[
                { columnName: 'filename', width: '300px' },
                { columnName: 'size', width: '200px' },
                { columnName: 'folderName', width: '200px' },
                { columnName: 'date', width: '200px' },
                { columnName: 'userName', width: '200px' },
              ]}
            />
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            <TableSelection />
            <PagingPanel pageSizes={pageSizes} />
          </ExpressGrid>
        </Box>
      </Box>
    </>
  );
};
export default FilesContiner;
