/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Breadcrumbs,
  Typography,
  Box,
} from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import MultiSelect from 'react-multi-select-component';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  getClusterName,
  getNonChangeRedinessProject,
  getRadinessProjectName,
  getRadinessProjects,
  getRmName,
  getSubRegionName,
} from '../../../api/kpi';
import useAppToken from '../../../app/useAppToken';
import NavigationGoto from '../../../components/Core/NavigationGoto';
import TableComponent from '../../../components/Datagrid/TableComponent';
import { rowComponent, toolbarComponent } from '../../../components/Datagrid/TStyles';
import ChangebleReadiness from './ChangebleReadiness';
import Loading from '../../../components/Core/Loading';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';

const useStyles2 = makeStyles((theme) => ({
  root: { display: 'inline', margin: 10, color: theme.palette.primary.main },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    backgroundColor: theme.palette.action.hover,
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
  textField: {
    margin: '15px 5px',
    paddingRight: 10,
  },
  radio: {
    textAlign: 'center',
  },
  label: {
    marginRight: '30px',
  },
  // root: {
  //   backgroundColor: theme.palette.background.paper,
  //   width: '100%',
  // },
  contain: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
    padding: '5px',
  },
  formControlMultiTextlebel: {
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  formControlMulti: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
  },
  formControlMultiFile: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
  },
  formControlMulti1: {
    margin: theme.spacing(1),
    minWidth: 220,
    maxWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tabstyle: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '33px',
    background: '#DC3223 0% 0% no-repeat padding-box',
    color: '#fff !important',
    fontSize: 14,
    fontWeight: 700,
  },
  MultiSelectHide: {
    // border: '2px solid red',
    // opacity: 1,
    '& .dropdown-container': {
      border: '1px solid #002A45',
      opacity: 1,
      // position: 'fixed',
    },
    '& .gray': {
      color: '#002A45 !important',
    },
    '& .dropdown-container[aria-disabled="true"]': {
      // overFlow: 'visible',
      // position: 'fixed',
      backgroundColor: '#C4D4EC',
      opacity: 0.5,
    },
    '& .select-menu-outer': {
      position: 'fixed',
    },
  },
  MultiSelect: {
    // border: '2px solid red',
    // opacity: 1,
    '& .dropdown-container': {
      border: '1px solid #002A45',
      opacity: 1,
      // position: 'fixed',
    },
    '& .gray': {
      color: '#002A45 !important',
    },
    '& .dropdown-container[aria-disabled="true"]': {
      // overFlow: 'visible',
      // position: 'fixed',
      // backgroundColor: '#C4D4EC',
    },
    // '& .dropdown-content': {
    //   // overflowY: 'auto',
    //   backgroundColor: 'red',
    //   position: 'initial',
    // },
    '& .select-menu-outer': {
      position: 'fixed',
    },
    // '& .rmsc': {
    //   borderColor: 'white',
    // },
    // '& .dropdown-heading': {
    //   color: 'red',
    // },
  },
}));

type Props = {
  program_project_name?: any;
};
// const columns = handleColumns(['id', 'function', 'mapping', 'example', 'created_at', 'updated_at']);

export default function NonChangebleReadiness(projectName: any) {
  console.log('NonChangebleReadiness alstomProgramName:', projectName);
  // const queryParams = new URLSearchParams(window.location.search);
  // const actionIs = queryParams.get('project_program_name');
  const { projectName: actionIs } = projectName;

  const classes = useStyles();
  const classes2 = useStyles2();
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const [toggleHeight, setToggleHeight] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [payload, setPayload] = useState([]);
  const { appJWT } = useAppToken() as any;
  const usersArray = [];
  const columnsName = [
    { name: 'rm_name', title: 'RM Name' },
    { name: 'region', title: 'Cluster Name' },
    { name: 'name_contract', title: 'Program Name' },
    { name: 'ct_code', title: 'Program Code' },
    { name: 'total_requirements', title: 'Total nb of requirements' },
    { name: 'dfq_gate', title: 'Select the DFQ Gate' },
    { name: 'date_of_dfq', title: 'Date of DFQ' },
    { name: 'identified', title: 'Identified' },
    { name: 'scope', title: 'Zone' },
    { name: 'defined', title: 'Defined' },
    { name: 'committed_owned', title: 'Committed/Owned' },
    { name: 'covered', title: 'Covered' },
    { name: 'obsolete', title: 'Obsolete' },
    { name: 'rejected', title: 'Rejected' },
    { name: 'empties', title: 'Empty' },
    { name: 'date_record_entered', title: 'Zone' },
    { name: 'comments_remarks', title: 'Comments/Remarks' },
    { name: 'region_destination', title: 'Region_Destination' },
    { name: 'requirement_level', title: 'Requirement_LEVEL' },
  ];
  const [alstomClusterDefault, setAlstomClusterDefault] = useState<any>([]);
  const [alstomCluster, setAlstomCluster] = useState<any>([]);
  const [selectedCluster, setSelectedCluster] = useState([]);
  const [alstomSubRegion, setAlstomSubRegion] = useState<any>([]);
  const [selectedSubregion, setSelectedSubregion] = useState([]);
  const [alstomSubRegionDefault, setAlstomSubRegionDefault] = useState<any>([]);
  // RM State
  const [alstomRmName, setAlstomRmName] = useState<any>([]);
  const [selectedRmName, setSelectedRmName] = useState([]);
  const [alstomRmNameDefault, setAlstomRmNameDefault] = useState<any>([]);
  const [selectedNewRm, setSelectedNewRm] = useState([]);
  const [payloadHere, setPayloadHere] = useState<any>();
  // ProgramName State
  const [alstomProgramName, setAlstomProgramName] = useState<any>([]);
  const [selectedProgramName, setSelectedProgramName] = useState([]);
  const [alstomProgramNameDefault, setAlstomProgramNameDefault] = useState<any>([]);
  const [selectedFinalProgramName, setSelectedFinalProgramName] = useState([]);

  const [hiddenColumnNames, setHiddenColumnNames] = useState([
    'scope',
    'defined',
    'obsolete',
    'rejected',
    'empties',
  ]);
  // ProgramName State
  useEffect(() => {
    setLoading(true);
    const clus = JSON.parse(sessionStorage.getItem('cluster'));
    if (clus) {
      onChangeEntity(clus);
    }
    const subRe = JSON.parse(sessionStorage.getItem('subRegion'));
    if (subRe) {
      onChangeSubRegion(subRe);
    }
    const rName = JSON.parse(sessionStorage.getItem('rmName'));
    if (rName) {
      onChangeRmName(rName);
    }
    const prgName = JSON.parse(sessionStorage.getItem('prgName'));
    if (prgName) {
      onChangeProgramName(prgName);
    }
    // action ius used here (RK)
    console.log('actionIs:', actionIs);
    getRadinessProjects(actionIs)
      .then((payload: any) => {
        if (payload) {
          setTimeout(() => {
            setLoading(false);
            setPayload(payload);
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    if (pageSize > payload.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < payload.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);
  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  useEffect(() => {
    getClusterName()
      .then((payload: any) => {
        if (payload) {
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload) {
            const item = payload[i].region;
            ent.push({
              label: item,
              value: item,
            });
          }
          setAlstomCluster(ent);
          setAlstomClusterDefault(ent);
          // seperate code
          setPayloadHere(payload);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    getSubRegionName('')
      .then((payload: any) => {
        if (payload) {
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload) {
            const item = payload[i].sub_region;
            ent.push({
              label: item,
              value: item,
            });
          }
          setAlstomSubRegion(ent);
          setAlstomSubRegionDefault(ent);
          // seperate code
          setPayloadHere(payload);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    getRmName()
      .then((payload: any) => {
        if (payload) {
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload) {
            const item = payload[i].rm_name;
            ent.push({
              label: item,
              value: item,
            });
          }
          setAlstomRmName(ent);
          setAlstomRmNameDefault(ent);
          // seperate code
          setPayloadHere(payload);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);
  function onChangeEntity(option: any) {
    setSelectedCluster(option);
    const extra = 'noChange_entity';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterClusterNameCall(option, selectedCluster);
  }
  function onChangeSubRegion(option: any) {
    setSelectedSubregion(option);
    const extra = 'noChange_entity';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterSubRegionCall(option, selectedSubregion);
  }
  function onChangeRmName(option: any) {
    setSelectedRmName(option);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterRmNameCall(option, selectedRmName);
  }
  function onChangeProgramName(option: any) {
    setSelectedProgramName(option);
    const extra = 'noChange_entity';
    multifilterProgramCall(option, selectedProgramName);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  }
  function multifilterRmNameCall(en: any, plat: any) {
    const entityVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    const rm_names = entityVal.length ? entityVal.join() : undefined;
    getRadinessProjectName(rm_names, undefined)
      .then((payload: any) => {
        if (payload) {
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload) {
            const item = payload[i].project_program;
            const item2 = payload[i].ct_code;
            ent.push({
              label: item,
              value: item2,
            });
          }
          setAlstomProgramName(ent);
          setAlstomProgramNameDefault(ent);
          // seperate code
          setPayloadHere(payload);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }
  function multifilterClusterNameCall(en: any, plat: any) {
    const entityVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    const region = entityVal.length ? entityVal.join() : undefined;
    getSubRegionName(region)
      .then((payload: any) => {
        if (payload) {
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload) {
            const item = payload[i].sub_region;
            ent.push({
              label: item,
              value: item,
            });
          }
          setAlstomSubRegion(ent);
          setAlstomSubRegionDefault(ent);
          // seperate code
          setPayloadHere(payload);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }

  function multifilterSubRegionCall(en: any, plat: any) {
    const entityVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    const sub_region = entityVal.length ? entityVal.join() : undefined;
    getRadinessProjectName(undefined, sub_region)
      .then((payload: any) => {
        if (payload) {
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload) {
            const item = payload[i].project_program;
            const item2 = payload[i].ct_code;
            ent.push({
              label: item,
              value: item2,
            });
          }
          setAlstomProgramName(ent);
          setAlstomProgramNameDefault(ent);
          // seperate code
          setPayloadHere(payload);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }
  function multifilterProgramCall(en: any, plat: any) {
    const entityVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    const Program_name = entityVal.length ? entityVal.join() : undefined;
    const selectedFinalProgramName = [...entityVal];
    setSelectedFinalProgramName([...selectedFinalProgramName]);
  }
  // eslint-disable-next-line consistent-return
  const customValueRenderer = () => {
    if (!selectedCluster.length) {
      return <b style={{ color: '#002A45' }}>Select the region name</b>;
    }
  };

  // eslint-disable-next-line consistent-return
  const customValueRendererSubRegion = () => {
    if (!selectedSubregion.length) {
      return <b style={{ color: '#002A45' }}>Select the sub region</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customRmValueRenderer = () => {
    if (!selectedRmName.length) {
      return <b style={{ color: '#002A45' }}>RM Name</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customProgramRenderer = () => {
    if (!selectedProgramName.length) {
      return <b style={{ color: '#002A45' }}>Select the Project / Program Name</b>;
    }
  };
  const dataProcess = () => {
    setLoading(true);
    const param = encodeURIComponent(selectedFinalProgramName.join());
    getRadinessProjects(param)
      .then((payload: any) => {
        if (payload) {
          setTimeout(() => {
            setPayload(payload);
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // table Export
  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'readiness.xlsx');
    });
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '20px',
          padding: '0px 40px',
          flexDirection: 'column',
        }}
      >
        {loading ? (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Loading />
          </div>
        ) : (
          <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
            <ExpressGrid rows={payload} columns={columnsName}>
              <FilteringState defaultFilters={[{ columnName: 'rm_name', value: '' }]} />
              <IntegratedFiltering />
              <SortingState />
              <IntegratedSorting />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
              <IntegratedPaging />
              <Table tableComponent={TableComponent} />
              {/* table header bold <TableHeaderRow showSortingControls cellComponent={TableHeaderCellWrap} /> */}
              <TableHeaderRow showSortingControls />
              <TableFilterRow rowComponent={rowComponent} />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={setHiddenColumnNames}
              />
              <Toolbar rootComponent={toolbarComponent} />
              <ColumnChooser />
              <ToolBarPlugin name="Download" onClick={startExport} />
              {/* <ToolBarPlugin name="Download" onClick={startExport} /> */}
              {/* <ToolBarPlugin
                name="Height"
                title="Wrap Text"
                onClick={() => setToggleHeight(!toggleHeight)}
              /> */}
              {/* <PagingPanel pageSizes={pageSizes} /> */}
              <PagingPanel pageSizes={pageSizes} />
            </ExpressGrid>
            <GridExporter ref={exporterRef} rows={payload} columns={columnsName} onSave={onSave} />
            <NavigationGoto
              setPageSize={setPageSize}
              fileLength={payload.length}
              pageSize={pageSize}
              dis={dis}
              setTemPage={setTemPage}
              handlePageNum={handlePageNum}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Box>
        )}
        <br />
        <br />
      </div>
      {/* <ChangebleReadiness changeblePaylod={payload} /> */}
    </>
  );
}
