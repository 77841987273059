import { Card, Chip, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getTimseries } from '../../api/analysis';
import ProjectBreadCrumbs from '../../components/Core/ProjectBreadCrumbs';
import { ISENS_COLORS } from '../../const';

const COLORS = [ISENS_COLORS[1], ISENS_COLORS[3], ISENS_COLORS[5]];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fontSize="16px"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const useStyles = makeStyles((theme) => ({
  root: {},
  bloc: {
    height: 323,
    width: 323,
    borderRadius: '17px',
    border: 'solid 1px;',
    borderColor: theme.palette.primary.light,
  },
  blocPie: {
    width: 363,
    '& > *': { textDecoration: 'none' },
  },
  overflow: {
    height: 275,
    overflowY: 'scroll',
    wordWrap: 'break-word',
  },
  iconMargin: {
    marginRight: '16px',
    color: theme.palette.primary.dark,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.primary.light,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

function KpiContainer() {
  const classes = useStyles();
  const { projectId } = useParams();
  const [pieData, setPieData] = useState([] as any);
  const [activityTime, setActivityTime] = useState([] as any[]);

  useEffect(() => {
    getTimseries(projectId).then(({ output_group_by, output_timeseries }) => {
      setPieData(output_group_by);
      setActivityTime(output_timeseries);
    });
  }, [projectId]);

  return (
    <div>
      <Grid container>
        {/* <ReactJson src={{ runContext }} collapsed={true} theme="monokai" /> */}
        <Grid item xs={12}>
          <ProjectBreadCrumbs
            projectId={projectId}
            nodes={[
              {
                label: 'KPI',
                to: `#`,
                // icon: (
                //   <img src={m3Logo} alt="Verification & Quality" title="Verification & Quality" />
                // ),
              },
            ]}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={1}>
        {pieData && (
          <Grid item>
            <Card className={clsx(classes.bloc)} style={{ width: 340 }}>
              <Typography variant="subtitle1" className={classes.title}>
                My KPIs
              </Typography>
              <div style={{ textAlign: 'center' }}>
                <PieChart width={260} height={220}>
                  <Pie
                    data={pieData}
                    cx={170}
                    cy={100}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {pieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
                {pieData.map((d, index) => (
                  <>
                    <Chip
                      label={`${d.name} (${d.value})`}
                      color="primary"
                      style={{
                        backgroundColor: COLORS[index % COLORS.length],
                        marginRight: '3px',
                      }}
                    />
                  </>
                ))}
              </div>
            </Card>
          </Grid>
        )}
        {activityTime && (
          <Grid item>
            <Card className={clsx(classes.bloc)} style={{ width: 400 }}>
              <Typography variant="subtitle1" className={classes.title}>
                My KPIs
              </Typography>
              <div>
                <LineChart
                  width={350}
                  height={250}
                  data={activityTime}
                  margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="completed" stroke={ISENS_COLORS[1]} />
                  <Line type="monotone" dataKey="not_started" stroke={ISENS_COLORS[3]} />
                  <Line type="monotone" dataKey="started" stroke={ISENS_COLORS[5]} />
                </LineChart>
              </div>
            </Card>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default KpiContainer;
