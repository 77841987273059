import { isEmpty } from '@antv/util';
import React, { useState, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, List, Tooltip, Typography, FormLabel, CircularProgress } from '@material-ui/core';
import PublishIcon from '@mui/icons-material/Publish';
import CircularProgressWithLabel from '@mui/material/CircularProgress';
import ProjectContext from '../../../../Project/ProjectContext';
import axios from '../../../../../api/axios';
import { BACKEND_URL } from '../../../../../const';
import formatBytes from '../../../../../tools/formatBytes';
import CustomDialog from '../../../../../components/Core/CustomDialoge';
import useAppToken from '../../../../../app/useAppToken';
// import ProjectContext from '../../containers/Project/ProjectContext';
// import { BACKEND_URL } from '../../const';
// import axios from '../../api/axios';
// import formatBytes from '../../tools/formatBytes';
// import CustomDialog from './CustomDialoge';

function RFPuploadFile({ files, setFiles, projectId }) {
  const [open, setOpen] = useState(false);
  const inputRef = useRef();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const { setSnack, getProject, setProject } = useContext(ProjectContext);
  const project = getProject(Number(projectId));
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;
  const [iconLoad, setIconload] = useState(false);

  const CheckDuplicateFiles = (myFile: any) => {
    if (project && project.folders) {
      for (const eachFolder of project.folders)
        if (eachFolder.resources && eachFolder.resources.length > 0)
          for (const resource of eachFolder.resources)
            if (resource.filename === myFile.name) return true;
    }
    return false;
  };

  // const handleAddFilesToProject = async (e: React.ChangeEvent<any>) => {
  //   // alert(`file ${JSON.stringify(e.currentTarget.files)}`);
  //   const uploadedFiles = e.currentTarget.files; /* eslint no-continue: "error" */
  //   const formdata = new FormData();
  //   const fileLength = [];
  //   const format = /* eslint-disable no-useless-escape */ /[`!@#$%^&*()+\=\[\]{};'\\:"\\|,.<>\/?~]/;
  //   for (const myFile of uploadedFiles) /* eslint no-continue: "error" */ {
  //     const fileNameWithoutExt = myFile.name.split('.')[0];
  //     if (fileNameWithoutExt.match(format) || myFile.name.length > 80) {
  //       fileLength.push(myFile.name);
  //     } else if (CheckDuplicateFiles(myFile)) {
  //       const result = window.confirm(
  //         `This file already exists: ${myFile.name}. Click OK to add again?`
  //       );
  //       if (result) formdata.append('files', myFile);
  //     } else formdata.append('files', myFile);
  //   }
  //   if (fileLength.length >= 1) {
  //     setSnack(
  //       `File name:(${fileLength.toString()}) should be less than 80 characters and only support special characters(-,_).`,
  //       'error'
  //     );
  //   }
  //   if (formdata.get('files')) {
  //     const uploadUrl = BACKEND_URL.upload
  //       .replace('projectId', projectId)
  //       .replace('folderId', project?.folders[0]?.id);
  //     try {
  //       const uplResponse = await axios.post(uploadUrl, formdata, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //         onUploadProgress: (progressEvent: any) => {
  //           const { loaded, total } = progressEvent;
  //           const percent = Math.floor((loaded * 100) / total);

  //           setUploadPercentage(percent);
  //         },
  //       });
  //       const folderUpdated = (await uplResponse.data) as Folder;
  //       if (folderUpdated && project && project.folders) {
  //         project.folders[0] = folderUpdated;
  //         setProject(project);
  //         setUploadPercentage(0);
  //         //   setTimeout(function () {
  //         //     setUploadPercentage(0);
  //         //   }, 800);
  //       }
  //       const allResources = [] as any[];
  //       if (project?.folders) {
  //         for (const eachFolder of project?.folders) {
  //           if (eachFolder.resources && eachFolder.resources.length > 0)
  //             for (const r of eachFolder.resources) {
  //               // get file extension(only xls, xlsx, csv) before that convert to lower case
  //               const fileExtension = r.filename.toLowerCase().split('.').pop();
  //               if (
  //                 fileExtension === 'csv' ||
  //                 fileExtension === 'xls' ||
  //                 fileExtension === 'xlsx' ||
  //                 fileExtension === 'pdf'
  //               )
  //                 allResources.push({
  //                   ...r,
  //                   size: formatBytes(Number(r.size), 0),
  //                   id: r.etag,
  //                   folderName: eachFolder.name,
  //                   folderId: eachFolder.id,
  //                 });
  //             }
  //         }
  //       }
  //       setFiles(allResources);
  //       setOpen(true);
  //     } catch (error) {
  //       setSnack('Error in File Upload', 'error');
  //       setUploadPercentage(0);
  //     }
  //   }
  // };
  // eslint-disable-next-line consistent-return
  const uploadURLCall = async (uploadUrl: any, formdata: FormData) => {
    try {
      const uplResponse = await axios.post(uploadUrl, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return uplResponse.data;
      // setFiles(allResources);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeFiles = (event: any) => {
    try {
      const len = Object?.keys(event)?.length;
      const arraylist = [];
      for (let i = 0; i < len; i += 1) {
        arraylist.push(event[i]);
      }

      for (const eachFile of arraylist) {
        const ext = eachFile?.name?.split('.');

        if (
          [
            'csv',
            'pdf',
            'docx',
            'pptx',
            'ppt',
            'htm',
            'msg',
            'xlf',
            'html',
            'markdown',
            'mdown',
            'mkdn',
            'md',
            'mkd',
            'mdwn',
            'mdtxt',
            'mdtext',
            'rmd',
            'mthml',
            'mht',
            'doc',
            'odt',
            'odp',
            'ods',
            'rtf',
            'tsv',
            'tab',
            'txt',
          ].includes(ext[ext?.length - 1])
        ) {
          setSnack(`${eachFile.name} not supported`, 'error');
          return;
        }
        if (eachFile.name?.length >= 80) {
          setSnack('file name should be less than 80 characters', 'error');
          return;
        }
      }
      const formdata = new FormData();
      const names = arraylist.map((e: any) => {
        formdata.append('files', e);
        return e.name;
      });
      const format = /* eslint-disable no-useless-escape */ /[`!@#$%^&*()+\=\[\]{};'\\:"\\|,<>\/?~]/;
      const fileLength = [];
      for (const eachFile of names) {
        // const fileNameWithoutExt = eachFile.split('.')[0];
        const fileNameWithoutExt = eachFile.split('.').slice(0, -1).join('.');

        if (fileNameWithoutExt.match(format)) {
          setSnack(`File name ${eachFile} contains special character not allowed`, 'error');
          return;
        }
        // if (names.includes(eachFile.filename)) {
        //   setSnack(`File name ${eachFile.filename} already exists.`, 'error');
        //   return;
        // }
      }
      for (const eachFile of files) {
        if (names.includes(eachFile.filename)) {
          setSnack(`File name ${eachFile.filename} already exists.`, 'error');
          return;
        }
      }

      if (formdata.get('files')) {
        setIconload(true);
        const uploadUrl = BACKEND_URL.uploadRFP
          .replace('userId', user_id)
          .replace('projectId', projectId);

        uploadURLCall(uploadUrl, formdata).then((result) => {
          //   project.folders[0] = result;
          setProject(project);
          const { resources } = result[0] || [];

          const newUploadfile = resources.filter((data: any) => {
            return names.includes(data.filename);
          });
          const NewData = newUploadfile.map((data) => {
            const obj = {
              ...data,
              size: formatBytes(Number(data?.size), 0),
              id: newUploadfile[0]?.etag,
              //   folderName: project?.folders[0]?.name,
              //   folderId: project?.folders[0]?.id,
              isNew: true,
            };
            return obj;
          });
          setFiles((prev: any) => {
            return [...NewData, ...prev];
          });
          setIconload(false);
          setSnack('Successfully Uploaded', 'success');
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const filehandleChangeFiles = (event: any) => {
    const e = event?.target?.files || '';
    handleChangeFiles(e);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {project && project.folders && !isEmpty(project.folders) && (
        <Tooltip title="upload file">
          <FormLabel
            style={{
              backgroundColor: '#EEF1F9',
              boxShadow: '0px 4px 8px #00000029',
              width: '3rem',
              color: '#002A45',
              height: '3rem',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            {iconLoad ? (
              <CircularProgress style={{ color: '#002A45' }} size={20} />
            ) : (
              <PublishIcon />
            )}
            <input type="file" multiple ref={inputRef} hidden onChange={filehandleChangeFiles} />
          </FormLabel>
        </Tooltip>
      )}
      <div>
        <CustomDialog project={project} open={open} setOpen={setOpen} />
      </div>
    </div>
  );
}

export default RFPuploadFile;
