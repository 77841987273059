import axios from './axios';
import { BACKEND_URL } from '../const';

const getAllUserFiles = async (userId: number) => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/users_folder/get/${userId}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getGrossaryFile = async (userId: number) => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/translator_glossary/get/${userId}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { getAllUserFiles, getGrossaryFile };
