import { AppBar, Box, Dialog, IconButton, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import CaptureKpiContainer from '../../../../KPI/CaptureKpiContainer';

type Props = {
  project_id: number;
  onClose: any;
  user_id?: number;
  workload_id?: number;
  file_name?: string;
};
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    alignItems: 'end',
  },
  root: {},
  box: {
    margin: theme.spacing(2),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CaptureModalKpi({ project_id, workload_id, user_id, file_name, onClose }: Props) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        maxWidth="xl"
        fullWidth
        open={true}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            // style={{ textAlign: 'end !important' }}
          >
            Close <CloseIcon />
          </IconButton>
        </AppBar>
        <Box className={classes.box}>
          <CaptureKpiContainer
            project_id={project_id}
            workload_id={workload_id}
            user_id={user_id}
            file_name={file_name}
            title={file_name}
          />
        </Box>
      </Dialog>
    </div>
  );
}
export default CaptureModalKpi;
