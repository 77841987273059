/* eslint-disable no-unused-expressions */
import React, { useCallback, useContext } from 'react';
import { Box, Button, IconButton, Popover, Slider, Typography } from '@material-ui/core';

import MultiSelect from 'react-multi-select-component';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { textToText } from '../../../api/module2';
import './Filters.css';
import DropSelectMui from '../../Core/DropSelectMui';
import M2GlobalContext from '../../../containers/ContextMager/M2GlobalContext';

const SimilarityDocFilter = () => {
  const { state, dispatch } = useContext(M2GlobalContext);
  const { similarityFilter } = state;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const {
    simi_FilterPlatforms,
    simi_AlstomEntities,
    simi_AlstomEntitiesDef,
    simi_FilterPlatformsDef,
    simi_FilterProjects,
    simi_FilterProjectsDef,
    simi_FilterDocumentProfiles,
    simi_FilterDocumentProfilesDef,
    simi_FilterSolution,
    simi_FilterSolutionDef,
    simi_FilterProductDef,
    simi_FilterProduct,
    dropDown1,
    dropDown2,
    dropDown3,
    dropDown4,
    dropDown5,
    dropDown6,
  } = similarityFilter;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function multifilterCall(
    en: any,
    plat: any,
    proj: any,
    docprof: any,
    sol: any,
    prod: any,
    extra: any
  ) {
    const entityVal: any = [];
    const platformVal: any = [];
    const projectVal: any = [];
    const documentProfileVal: any = [];
    const solutionVal: any = [];
    const productVal: any = [];
    en?.forEach((o: any) => {
      Object.is(o, Object(o)) ? entityVal?.push(o.value) : entityVal?.push(o);
      // entityVal?.push(o);
    });
    plat?.forEach((o: any) => {
      Object.is(o, Object(o)) ? platformVal?.push(o.value) : platformVal?.push(o);
    });
    sol?.forEach((o: any) => {
      Object.is(o, Object(o)) ? solutionVal?.push(o.value) : solutionVal?.push(o);
      // solutionVal?.push(o);
    });
    prod?.forEach((o: any) => {
      Object.is(o, Object(o)) ? productVal?.push(o.value) : productVal?.push(o);
      // productVal?.push(o);
    });
    proj?.forEach((o: any) => {
      Object.is(o, Object(o)) ? projectVal?.push(o.value) : projectVal?.push(o);
      // projectVal?.push(o);
    });
    docprof?.forEach((o: any) => {
      Object.is(o, Object(o)) ? documentProfileVal?.push(o.value) : documentProfileVal?.push(o);
      // documentProfileVal?.push(o);
    });

    textToText(entityVal, platformVal, solutionVal, productVal, projectVal, documentProfileVal)
      .then((payload: any) => {
        if (payload) {
          // console.log(payload);
          const entityAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.alstomEntities) {
            const item = payload.filters.alstomEntities[i];
            entityAfterFilter.push({
              label: item,
              value: item,
            });
          }
          // if (extra !== 'noChange_entity') {
          //   setAlstomEntities(entityAfterFilter);
          // }
          const platformAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.platforms) {
            const item = payload.filters.platforms[i];
            platformAfterFilter.push({
              label: item,
              value: item,
            });
          }

          const solutionAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.solutions) {
            const item = payload.filters.solutions[i];
            solutionAfterFilter.push({
              label: item,
              value: item,
            });
          }

          const productAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.products) {
            const item = payload.filters.products[i];
            productAfterFilter.push({
              label: item,
              value: item,
            });
          }

          // if (extra !== 'noChange_platform') {
          //   setFilterPlatforms(platformAfterFilter);
          // }
          const projectAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.projects) {
            const item = payload.filters.projects[i];
            projectAfterFilter.push({
              label: item,
              value: item,
            });
          }
          // if (extra !== 'noChange_project') {
          //   setFilterProjects(projectAfterFilter);
          // }
          const documentProfileAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.documentProfiles) {
            const item = payload.filters.documentProfiles[i];
            documentProfileAfterFilter.push({
              label: item,
              value: item,
            });
          }
          // if (extra !== 'noChange_documentProfile') {
          //   setFilterDocumentProfiles(documentProfileAfterFilter);
          // }

          switch (extra) {
            case 'change_entity': {
              const obj = {
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_entity', payload: obj });
              break;
            }

            case 'change_platform': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_platform', payload: obj });
              break;
            }

            case 'change_solution': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_solution', payload: obj });
              break;
            }

            case 'change_product': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_product', payload: obj });
              break;
            }

            case 'change_project': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_project', payload: obj });
              break;
            }

            case 'change_documentProfile': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_documentProfile', payload: obj });
              break;
            }
            default:
              break;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }

  // function onChangeProductLineValue(option: any) {
  //   // setDropDown1(option);
  //   const mapdata = option.map((data) => {
  //     return {
  //       label: data,
  //       value: data,
  //     };
  //   });
  //   dispatch({ type: 'change_simi_entity', payload: mapdata });
  //   // extra argu implemented but not in use
  //   const extra = 'change_entity';
  //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
  //   multifilterCall(option, dropDown2, dropDown3, dropDown4, dropDown5, dropDown6, extra);
  // }

  const onChangeProductLineValue = (option: any) => {
    const mapdata = option.map((data) => {
      return {
        label: data,
        value: data,
      };
    });
    dispatch({ type: 'change_simi_entity', payload: mapdata });
    // extra argu implemented but not in use
    const extra = 'change_entity';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(option, dropDown2, dropDown3, dropDown4, dropDown5, dropDown6, extra);
  };

  const onChangePlatformValue = (option: any) => {
    // setDropDown1(option);
    const mapdata = option.map((data) => {
      return {
        label: data,
        value: data,
      };
    });
    dispatch({ type: 'change_simi_Platform', payload: mapdata });
    const extra = 'change_platform';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, option, dropDown3, dropDown4, dropDown5, dropDown6, extra);
  };

  const onChangeProjectValue = (option: any) => {
    // setDropDown1(option);
    const mapdata = option.map((data) => {
      return {
        label: data,
        value: data,
      };
    });
    dispatch({ type: 'change_simi_Project', payload: mapdata });
    const extra = 'change_project';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, option, dropDown4, dropDown5, dropDown6, extra);
  };

  const onChangeDocumentProfile = (option: any) => {
    // setDropDown1(option);
    const mapdata = option.map((data) => {
      return {
        label: data,
        value: data,
      };
    });
    dispatch({ type: 'change_simi_DocumentProfile', payload: mapdata });
    const extra = 'change_documentProfile';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, dropDown3, option, dropDown5, dropDown6, extra);
  };

  const onChangeSolution = (option: any) => {
    // setDropDown1(option);
    const mapdata = option.map((data) => {
      return {
        label: data,
        value: data,
      };
    });
    dispatch({ type: 'change_simi_Solution', payload: mapdata });
    const extra = 'change_solution';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, dropDown3, dropDown4, option, dropDown6, extra);
  };

  const onChangeProduct = (option: any) => {
    // setDropDown1(option);
    const mapdata = option.map((data) => {
      return {
        label: data,
        value: data,
      };
    });
    dispatch({ type: 'change_simi_Product', payload: mapdata });
    const extra = 'change_product';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, dropDown3, dropDown4, dropDown5, option, extra);
  };

  function handleFilter2() {
    dispatch({ type: 'clear_simiFilters' });
  }

  return (
    <>
      <IconButton
        style={{
          backgroundColor: '#EEF1F9',
          boxShadow: '0px 4px 8px #00000029',
          width: '3rem',
          color: '#002A45',
          height: '3rem',
        }}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <FilterAltOutlinedIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'top',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            backgroundColor: '#EEF1F9',
            marginTop: '10px',
            boxShadow: 'none',
            overflowX: 'visible',
            overflowY: 'visible',
          },
        }}
      >
        <Box style={{ display: 'flex', gap: '5px', width: '70rem', padding: '0.5rem' }}>
          <Box style={{ flex: 1 }}>
            <DropSelectMui
              options={simi_AlstomEntities ?? []}
              value={dropDown1?.map((item) => item?.value) ?? []}
              onChange={onChangeProductLineValue}
              labelledBy="Product Line"
            />
          </Box>

          <Box style={{ flex: 1 }}>
            <DropSelectMui
              options={simi_FilterPlatforms ?? []}
              labelledBy="Platform"
              value={dropDown2?.map((item) => item?.value) ?? []}
              onChange={onChangePlatformValue}
            />
          </Box>

          <Box style={{ flex: 1 }}>
            <DropSelectMui
              options={simi_FilterSolution ?? []}
              value={dropDown5?.map((item) => item?.value) ?? []}
              onChange={onChangeSolution}
              labelledBy="Solution"
            />
          </Box>

          <Box style={{ flex: 1 }}>
            <DropSelectMui
              options={simi_FilterProduct ?? []}
              labelledBy="Product"
              value={dropDown6?.map((item) => item?.value) ?? []}
              onChange={onChangeProduct}
            />
          </Box>

          <Box style={{ flex: 1 }}>
            <DropSelectMui
              options={simi_FilterProjects ?? []}
              labelledBy="Project"
              value={dropDown3?.map((item) => item?.value) ?? []}
              onChange={onChangeProjectValue}
            />
          </Box>

          <Box style={{ flex: 1 }}>
            <DropSelectMui
              options={simi_FilterDocumentProfiles ?? []}
              labelledBy="Document Profile"
              value={dropDown4?.map((item) => item?.value) ?? []}
              onChange={onChangeDocumentProfile}
            />
          </Box>
          <Box style={{ flex: 1 }}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              style={{ width: '100%' }}
              onClick={() => {
                handleFilter2();
              }}
              startIcon={<FilterAltOffIcon />}
            >
              Clear Filter
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default SimilarityDocFilter;
