import * as React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, makeStyles, Tab, Tabs, Typography, useTheme } from '@material-ui/core';
import FontDownloadOutlinedIcon from '@material-ui/icons/FontDownloadOutlined';
import DocumentScannerIcon from '@mui/icons-material/NoteAlt';
import TranslateIcon from '@mui/icons-material/ArticleOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import { useState } from 'react';
import TabStyle from '../../../../components/Layout/TabStyle';
import DocumentAnalysis from './DocumentAnalysis/DocumentAnalysis';
import TextAnalysisMode from '../../../HOC/TextAnalysisMode';
import './TabContainer.css';
import { DocAnalysisContextProvider } from './DocAnalysisContext';
import WorkSpaceTextAnalysis from './TextAnaylsis/withWorkSpaceTextAnalysis';
// import { DocAnalysisContextProvider } from '../../DocAnalysisContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    marginTop: -20,
  },
  tabstyle: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '33px',
    background: '#DC3223 0% 0% no-repeat padding-box',
    color: '#fff !important',
    fontSize: 14,
    fontWeight: 700,
  },
  appStyle: {
    border: '1px solid #002A45',
    borderRadius: '34px',
    backgroundColor: '#f5f5f5',
  },
}));
const mode = {
  TextAnalysis: { left: '0%' },
  DocumentAnalysis: { left: '50%' },
};

function Knowledge360({ setReset, reset }) {
  const classes = useStyles();
  const [tab, setTab] = useState('TextAnalysis');
  const async_op = ['DocToDataset', 'DocToDoc', 'docdiffer', 'iner', 'SelfDoc'];
  const classesTab = TabStyle();
  const [value, setValue] = React.useState(0);
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('service');
  //   const [operation, asynOperation] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  React.useEffect(() => {
    if (actionIs !== undefined) {
      if (async_op.includes(actionIs)) {
        // asynOperation(actionIs);
        setTab('DocumentAnalysis');
      }
      //   if (actionIs === 'Knowledge360') {
      //     // asynOperation(actionIs);
      //     setAnalysis('Knowledge360');
      //   }
      // else if (actionIs === 'TextAnalysis') {
      //   setAnalysis('TextAnalysis');
      // } else if (actionIs === 'DocumentAnalysis') {
      //   setAnalysis('DocumentAnalysis');
      // }
    }
  }, [actionIs]);

  return (
    <>
      <Box className="home-tab-container">
        <div className="home-tab-container-button-Box">
          <div style={mode[tab]} className="home-tab-container-btn">
            {}
          </div>
          <button
            type="button"
            className="home-tab-container-toggleBtn"
            style={mode[tab]?.left === '0%' ? { color: 'white' } : {}}
            onClick={() => {
              setTab('TextAnalysis');
              if (reset) {
                setReset(false);
              }
            }}
          >
            <DocumentScannerIcon />
            Text Analysis
          </button>
          <button
            type="button"
            className="home-tab-container-toggleBtn"
            style={mode[tab]?.left === '50%' ? { color: 'white' } : {}}
            onClick={() => {
              setTab('DocumentAnalysis');
              if (reset) {
                setReset(false);
              }
            }}
          >
            <TranslateIcon />
            Document Analysis
          </button>
        </div>
        {tab === 'TextAnalysis' && <WorkSpaceTextAnalysis setReset={setReset} />}
        {tab === 'DocumentAnalysis' && (
          <DocAnalysisContextProvider>
            <DocumentAnalysis asynOperations={0} />{' '}
          </DocAnalysisContextProvider>
        )}
      </Box>
    </>
  );
}

export default Knowledge360;
