import { useMaterialReactTable } from 'material-react-table';
import React, { useEffect, useState } from 'react';
import useRefreshLoading from '../../Hooks/useRefreshLoading';
import useAnalysis from './useAnalysis';
import useAnalysisOperations from './useAnalysisOperations';

type Props = {
  rows: any;
  targetColumn: any;
  onChangeToggle: any;
  toggleTable: any;
  isFullScreen: any;
  onChangeFullScreen: any;
  user_id: any;
  onChangeModalData: any;
  projectId: any;
  refreshDATA: any;
  workloadId: any;
  setMetaDAta: any;
  refreshLoading: any;
  filename: any;
  metaData: any;
  project: any;
  setNotify: any;
  displayColumns: any;
  levelsHierarchy: any;
  type: any;
};

const useAnalysisTable = (props: Props) => {
  const {
    rows,
    targetColumn,
    onChangeToggle,
    refreshLoading,
    toggleTable,
    isFullScreen,
    onChangeFullScreen,
    user_id,
    onChangeModalData,
    projectId,
    workloadId,
    setMetaDAta,
    metaData,
    filename,
    project,
    setNotify,
    refreshDATA,
    displayColumns,
    levelsHierarchy,
    type,
  } = props;

  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    setRowSelection({});
  }, [rows]);

  const { loading, handleAllCompliant } = useAnalysisOperations({
    workloadId,
    filename,
    projectId,
    userId: user_id,
    setMetaDAta,
    metaData,
    project,
    setNotify,
  });

  const { loadTest } = useRefreshLoading({ refreshLoading, loading });

  const analysisObject = useAnalysis({
    targetColumn,
    data: rows,
    loading: loadTest,
    onChangeToggle,
    toggleTable,
    isFullScreen,
    onChangeFullScreen,
    user_id,
    onChangeModalData,
    rowSelection,
    setRowSelection,
    handleAllCompliant,
    refreshDATA,
    displayColumns,
    levelsHierarchy,
    type,
  }) as any;

  const table = useMaterialReactTable({
    ...analysisObject,
  });

  return table;
};

export default useAnalysisTable;
