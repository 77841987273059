import { useState } from 'react';
// keep as JS
export default function useAzureToken() {
  const getJWT = () => {
    const tokenString = localStorage.getItem('azJWT');
    return JSON.parse(tokenString);
  };

  const [jwt, setJwt] = useState(getJWT());

  /**
   * stringify token
   * @param {*} jwt
   */
  const saveJWT = (jwt) => {
    localStorage.setItem('azJWT', JSON.stringify(jwt));
    setJwt(jwt);
  };

  return {
    setJWT: saveJWT,
    jwt,
  };
}
