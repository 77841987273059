/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './WorkSpaceMenu.css';
import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ShortcutOutlinedIcon from '@mui/icons-material/ShortcutOutlined';
import expertSearchIcon from '../../../assets/shorcutsIcons/expertSearchIcon.png';
import translatorIcon from '../../../assets/shorcutsIcons/translatorIcon.png';
// import GenaIcon from '../../../assets/shorcutsIcons/GenaIcon.png';
import GenaMenuIcon from '../../../assets/shorcutsIcons/GenaMenuIcon.png';
import rex360Icon from '../../../assets/shorcutsIcons/rex360Icon.png';
import useAppToken from '../../../app/useAppToken';
import ProjectContext from '../../Project/ProjectContext';
// import TranslateIcon from '@mui/icons-material/Translate';

function useDelayUnmount(isMounted: any, delay: any) {
  const [shouldRender, setShouldRender] = React.useState(false);

  React.useEffect(() => {
    let timeoutId: number;

    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delay);
    }

    return () => clearTimeout(timeoutId);
  });

  return shouldRender;
}

export default function WorkSpaceMenu({ profileData }) {
  // const [click, setClick] = React.useState(false);
  const { open } = useContext(ProjectContext);
  const ANIM_DURATION = 500;
  const { appJWT } = useAppToken();
  const [isMounted, toggleIsMounted] = React.useState(open === false ? true : open);
  // const shouldRender = useDelayUnmount(isMounted, 500);
  const history = useHistory();
  const mountedClass = 'tabContainer_mounted';
  const unmountingClass = 'tabContainer_unmounting';
  const tabs = [
    { label: 'GenA', icon: GenaMenuIcon, url: `/workspace/GenA` },
    { label: 'Translator', icon: translatorIcon, url: `/workspace/Translator` },
    {
      label: 'Knowledge360',
      icon: expertSearchIcon,
      url: `/workspace/Knowledge360`,
    },
    {
      label: 'Rex360',
      icon: rex360Icon,
      url: `/workspace/REXAnalysis`,
    },
  ];

  const filteredTab =
    useMemo(() => {
      if (profileData) {
        const { m2 } = profileData[0] || [];
        return tabs.filter((item) => m2?.map((m) => m?.value).includes(item?.label));
      }
      return [];
    }, [profileData]) ?? [];

  function redirectPAge(url: string) {
    history.push(url);
  }
  function toggleMounted() {
    toggleIsMounted((prev) => !prev);
  }

  return (
    <div className="ContainerMenu">
      <div
        style={{ display: 'flex', height: '4em', position: 'relative' }}
        // onMouseLeave={() => {
        //   isMounted ? toggleMounted() : '';
        // }}
      >
        {' '}
        <div
          className="btn-outline"
          // onMouseEnter={() => toggleMounted()}
          // style={{ borderRadius: !isMounted ? "0px 30px 30px 0px" : "0px" }}
          //   onClick={() => toggleMounted()}
          onClick={() => {
            toggleMounted();
          }}
        >
          {' '}
          <div className="short-btn">
            {' '}
            <ShortcutOutlinedIcon style={{ border: '2px solid white', borderRadius: '5px' }} />
          </div>
        </div>
        <div
          className={`tabContainer ${isMounted ? mountedClass : ''}`}
          // style={{
          //   width: `${8 * filteredTab.length}%`,
          // }}
        >
          {filteredTab?.map((data) => {
            return (
              <div className="container-workspcemenu" onClick={() => redirectPAge(data.url)}>
                <div className="outerCircle-workspcemenu">
                  <div className="innerCircle-workspcemenu">
                    <img style={{ width: '100%', height: '100%' }} src={data.icon} alt="img" />
                  </div>
                </div>
                <p className="text-p-workmenu">{data.label}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
