import React from 'react';
import { Box, Button, List, TextField, Typography } from '@material-ui/core';
import HomeIcon from '@mui/icons-material/Home';
import EastIcon from '@mui/icons-material/East';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import WestIcon from '@mui/icons-material/West';
import Fab from '@mui/material/Fab';
import { QuestionStyle } from './AssessmentStyle';

const Questions = ({ setAssessOp }) => {
  const classes = QuestionStyle();
  return (
    <>
      <Box className={classes.subQuestions}>
        <Box className={classes.questionBox}>
          <Typography style={{ flexBasis: '13%' }}>
            <b>Question 1.1.1 - </b>
          </Typography>
          <Typography style={{ flexBasis: '87%' }}>
            Is a Requirement Management Plan (RMP) available for the Project/Program/Product, as a
            standalone document?
          </Typography>
        </Box>

        <Box style={{ width: '100%', display: 'flex', gap: '30px' }}>
          <FormControl>
            <FormLabel
              id="Inspection-or-Assessment-Evaluation-Status"
              style={{ fontSize: '1.2em', color: '#002A45', fontWeight: 'bold' }}
            >
              Inspection or Assessment Evaluation Status :
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="Inspection-or-Assessment-Evaluation-Status"
              name="Inspection-or-Assessment-Evaluation-Status"
            >
              <FormControlLabel value="OK" control={<Radio />} label="OK" />
              <FormControlLabel value="NOK" control={<Radio />} label="NOK" />
              <FormControlLabel value="NOT YET" control={<Radio />} label="NOT YET" />
              <FormControlLabel value="NA" control={<Radio />} label="NA" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box style={{ width: '100%', display: 'flex', gap: '10px', flexDirection: 'column' }}>
          <Typography style={{ fontSize: '1.2em', color: '#002A45', fontWeight: 'bold' }}>
            Comments :
          </Typography>
          <TextField
            id="outlined-basic"
            label="comments"
            variant="outlined"
            multiline
            rows={2}
            style={{ backgroundColor: 'white' }}
            fullWidth
          />
        </Box>
        <Box style={{ width: '100%', display: 'flex' }}>
          <Box
            style={{
              flexBasis: '20%',
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '0.5rem',
              border: '1px solid #002A45',
              borderRight: '0px',
            }}
          >
            <p style={{ color: '#002A45', opacity: '0.6', margin: '0px' }}>Upload document proof</p>
          </Box>
          <Button type="button" variant="contained" color="primary">
            Browse
          </Button>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'flex-end', gap: '20px', width: '100%' }}>
          <Fab
            size="small"
            style={{ backgroundColor: 'red', color: 'white' }}
            onClick={() => {
              setAssessOp(-1);
            }}
          >
            <HomeIcon />
          </Fab>
          <Button type="button" variant="contained" color="primary" startIcon={<WestIcon />}>
            Previous
          </Button>
          <Button type="button" variant="contained" color="primary" endIcon={<EastIcon />}>
            Next
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Questions;
