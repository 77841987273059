import { makeStyles } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TranslateIcon from '@mui/icons-material/Translate';
import React, { useState } from 'react';
import {
  allLanguages,
  getLanguageLabel,
} from '../../containers/Module1/components/RunStepper/TranslateSelection';

// "-" == undefined
export type LangCode = 'fr' | 'de' | 'it' | 'es' | 'en' | '-';

type Props = {
  languages: string[];
  languageCode: LangCode;
  languageOriginCode: LangCode;
  onClick?: any;
};

const useStyles = makeStyles({
  menuLang: {
    '& .Mui-selected': {
      'font-weight': 'bold',
    },
  },
});

export default function TranslationList({
  onClick,
  languages,
  languageCode,
  languageOriginCode,
}: Props) {
  const listLangMenu = allLanguages.filter(({ value }) => {
    return languages?.includes(value);
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const [langSelected, setLangSelected] = useState<LangCode>(languageCode as LangCode);

  const handleClose = (lang: LangCode) => {
    setAnchorEl(null);
    if (['fr', 'de', 'it', 'es', 'en', '-'].includes(lang)) {
      setLangSelected(lang);
      if (onClick) onClick(lang);
    }
  };

  const originalLang =
    languageOriginCode !== '-'
      ? `${getLanguageLabel(languageOriginCode)} (Original Text)`
      : 'Original Text';

  return (
    <>
      <TranslateIcon onClick={handleClick} />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menuLang}
      >
        <MenuItem
          onClick={() => handleClose(languageOriginCode)}
          selected={[languageOriginCode].includes(langSelected)}
        >
          {originalLang}
        </MenuItem>
        {listLangMenu.map(({ label, value }) => (
          <MenuItem
            onClick={() => handleClose(value as LangCode)}
            selected={langSelected === value}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
