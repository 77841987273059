const charScoreColor = {
  Medium: 'rgb(255, 102, 51)',
  High: 'rgb(25, 170, 110)',
  Low: 'rgb(225, 90, 80)',
};

const lifeCycle = {
  characterized: 'rgb(120, 130, 145)',
  assigned: 'rgb(25, 170, 110)',
  owned: 'rgb(0, 42, 69)',
  analyzed: 'rgb(175, 160, 130)',
  recaptured: '#FF6633',
  rejected: 'rgb(225, 90, 80)',
  'need-clarification': '#03568A',
};

export { charScoreColor, lifeCycle };
