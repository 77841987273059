import { Pie } from '@ant-design/charts';
import React from 'react';
import { colorsFiles } from '../ResultContainer';

const PieVerificationFiles: React.FC = ({ notOK, ok, outOfScope }: any) => {
  const data = [
    {
      type: 'Not OK',
      value: notOK,
    },
    {
      type: 'OK',
      value: ok,
    },
    {
      type: 'Out Scope',
      value: outOfScope,
    },
  ];
  const config = {
    autoFit: false,
    // width: 360,
    height: 220,
    appendPadding: 0,
    data,
    angleField: 'value',
    colorField: 'type', // or seriesField in some cases
    color: [colorsFiles.nok, colorsFiles.ok, colorsFiles.outOfScope],
    radius: 0.7,
    label: { type: 'outer' },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
  };
  return <Pie style={{ marginTop: '10px' }} {...config} />;
};

export default PieVerificationFiles;
