import React, { memo, useMemo, useState } from 'react';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  DataTypeProvider,
  CustomPaging,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import LinkIcon from '@mui/icons-material/Link';
import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, IconButton, Typography, makeStyles, Checkbox } from '@material-ui/core';
import { rowComponent } from '../../Datagrid/TStyles';
import ExcelDoc, { CsvDoc, defaultFileIcon, DocxDoc, PdfDoc, PptDoc } from '../const/png';
import TableComponent from '../../Datagrid/TableComponent';

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: '70%',
    height: '1rem',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
  },

  pinGlossaryDiv: {
    height: '10rem',
    width: '100%',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      width: '2px',
      height: '2px',
    },
    // '&::-webkit-scrollbar-track': {
    //   boxShadow: 'none',
    //   borderRadius: '3px',
    //   backgroundColor: 'white',
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   outline: 'none',
    // },
  },

  glossaryList: {
    padding: '0.67rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.67rem',
  },

  glossaryItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.2rem',
  },
}));

const GlossarySelection = ({
  handleClose,
  open,
  classes,
  selectedFile,
  handleRadioSelection,
  pinFiles,
}) => {
  // console.log(selectedFile);
  const selectObj = useMemo(() => {
    return selectedFile[0] ?? { glossaryFileName: '' };
  }, [selectedFile]);

  return (
    <>
      {' '}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ padding: 10 }}>
          <div className={classes.pinGlossaryDiv}>
            <div className={classes.glossaryList}>
              {pinFiles.map((data, index) => {
                const ext = data.filename.split('.');
                const extensions = {
                  xlsx: ExcelDoc,
                  pptx: PptDoc,
                  ppt: PptDoc,
                  csv: CsvDoc,
                  pdf: PdfDoc,
                  docx: DocxDoc,
                  doc: DocxDoc,
                };
                return (
                  <>
                    <div key={index} className={classes.glossaryItem}>
                      <Checkbox
                        value={data.filename}
                        defaultChecked={selectObj.glossaryFileName === data.filename}
                        onChange={(e) => {
                          handleRadioSelection({
                            glossaryFileName: e.target.value,
                            glossaryFileDetails: data,
                            linkedDocument: selectedFile[0],
                            checked: e.target.checked,
                          });
                        }}
                      />
                      <img
                        src={extensions[ext[ext.length - 1]] ?? defaultFileIcon}
                        height="30"
                        width="30"
                        alt="img"
                      />
                      {/* <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                      <Typography style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                        {data.filename}
                      </Typography>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const FileDataTable = ({
  pinFiles = [],
  selection,
  setSelection,
  Filescolumns,
  handleRadioSelection,
  files,
  handleSelectionChange,
  selectedFileData = [],
  open,
  setOpen,
}) => {
  const [pageSizes] = useState([5, 10, 20, 50]);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  //   const { user_id } = appJWT;
  const [pageSize, setPageSize] = useState(5);
  const classes = useTableStyles();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedFile, setSelectedFile] = useState([]);

  const handleClickOpen = (row) => {
    setOpen(true);
    setSelectedFile(row);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // console.log(selectedFileData);

  const renderFileName = ({ row }: any) => {
    const ext = row.filename.split('.');
    const extensions = {
      xlsx: ExcelDoc,
      pptx: PptDoc,
      ppt: PptDoc,
      csv: CsvDoc,
      pdf: PdfDoc,
      docx: DocxDoc,
      doc: DocxDoc,
    };
    // console.log(row);
    return (
      <>
        <div
          style={{
            position: 'relative',
            padding: '0.3rem',
            width: 'max-content',
          }}
        >
          {pinFiles.length > 0 &&
            selection
              .map((data) => {
                return files[data].filename;
              })
              .includes(row.filename) && (
              <IconButton
                style={{ padding: 0, position: 'absolute', left: '50px', bottom: '-10px' }}
                onClick={() => {
                  try {
                    const r = selectedFileData.filter((data) => {
                      return data?.document?.filename === row?.filename;
                    });
                    handleClickOpen(r);
                  } catch (error) {
                    console.log(error);
                  }

                  // console.log(r);
                }}
              >
                <LinkIcon />
              </IconButton>
            )}
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
            <img
              src={extensions[ext[ext.length - 1]] ?? defaultFileIcon}
              height="30"
              width="30"
              alt="img"
            />
            <Typography>{row.filename}</Typography>
          </div>
          {row?.isNew && (
            <div
              style={{
                width: 'max-content',
                padding: '0.2rem',
                borderRadius: '10px',
                backgroundColor: '#19AA6E',
                color: 'white',
                position: 'absolute',
                top: '-12px',
                right: '-35px',
              }}
            >
              <Typography style={{ fontSize: '0.85em' }}>New !</Typography>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderUploadDate = ({ row }) => {
    const dates = row.date.split(' ');
    return (
      <>
        {dates[0]} {dates[1].split('.')[0]}
      </>
    );
  };

  return (
    <>
      {/* <Dialog open={open} onClose={handleClose}>
        <div
          style={{
            height: '30rem',
            position: 'absolute',
            width: '20rem',
            overflow: 'auto',
            zIndex: 12,
          }}
        >
          <ul>
            {pinFiles.map((data, index) => {
              return <li key={index}>{data.filename}</li>;
            })}
          </ul>
        </div>
      </Dialog> */}
      <GlossarySelection
        handleClose={handleClose}
        open={open}
        classes={classes}
        selectedFile={selectedFile}
        handleRadioSelection={handleRadioSelection}
        pinFiles={pinFiles}
      />

      <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
        <Grid rows={files} columns={Filescolumns}>
          <SelectionState selection={selection} onSelectionChange={handleSelectionChange} />
          <FilteringState />
          <IntegratedFiltering />
          <DataTypeProvider for={['filename']} formatterComponent={renderFileName} />
          <DataTypeProvider for={['date']} formatterComponent={renderUploadDate} />
          <SortingState />
          <IntegratedSorting />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <VirtualTable />
          <Table tableComponent={TableComponent} />
          <TableColumnResizing
            defaultColumnWidths={[
              { columnName: 'filename', width: '300px' },
              { columnName: 'fromLang', width: '200px' },
              { columnName: 'toLang', width: '200px' },
              { columnName: 'size', width: '200px' },
              { columnName: 'folderName', width: '200px' },
              { columnName: 'date', width: '200px' },
              { columnName: 'userName', width: '200px' },
            ]}
          />
          <TableHeaderRow showSortingControls />
          <TableFilterRow rowComponent={rowComponent} />
          <TableSelection />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
      </Box>
    </>
  );
};

export default memo(FileDataTable);
