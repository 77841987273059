import { Card, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Image as ImageIcon, TableChart as TableChartIcon } from '@mui/icons-material';
import { Grid as ExpressGrid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { handleColumns } from '../../../../../../tools/datagrid';
import TableComponent from '../../../../../../components/Datagrid/TableComponent';
import TableHeaderCellWrap from '../../../../../../components/Datagrid/TableHeaderCellWrap';
import {
  multiTableRowComponent,
  tableHeaderComponent,
} from '../../../../../../components/Datagrid/TStyles';

const tableChildContainer: React.FunctionComponent<Table.CellProps> = (props) => {
  return (
    <Table.Container
      {...props}
      style={{
        maxHeight: '70vh',
        overflowY: 'auto',
        border: '1px solid black',
      }}
    />
  );
};

const CustomWidthImageTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 700,
    backgroundColor: '#002A45',
    border: '1px solid black',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: 1,
    transition: 'opacity 1s',
    '& .MuiTooltip-arrow': {
      color: '#eef1f9',
      '&:before': {
        backgroundColor: '#002A45',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid black`,
        fontSize: 50,
      },
    },
  },
});

function HoverToolTip({ row, renderedCellValue, children }) {
  const { SubType } = row || '';

  const renderContent = () => {
    switch (SubType) {
      case 'Table':
        return (
          <>
            {' '}
            <ExpressGrid rows={row.RawData.rows} columns={handleColumns(row.RawData.columns)}>
              <Table
                tableComponent={TableComponent}
                containerComponent={tableChildContainer}
                headComponent={tableHeaderComponent}
                columnExtensions={handleColumns(row.RawData.columns).map((c) => ({
                  columnName: c.name,
                  wordWrapEnabled: true,
                }))}
              />
              <TableHeaderRow
                cellComponent={TableHeaderCellWrap}
                rowComponent={multiTableRowComponent}
              />
            </ExpressGrid>
          </>
        );

      case 'Image':
        return (
          <>
            <img
              style={{ objectFit: 'cover' }}
              src={`data:image/png;base64, ${row.RawData}`}
              alt=""
            />
          </>
        );

      case 'Paragraph':
        return <Typography color="info">{renderedCellValue}</Typography>;

      case 'Heading':
        return (
          <Typography color="info" style={{ fontWeight: 'bold' }}>
            {renderedCellValue}
          </Typography>
        );

      default:
        return (
          <>
            {' '}
            <Typography color="info">{renderedCellValue}</Typography>
          </>
        );
    }
  };

  return (
    <>
      {' '}
      <CustomWidthImageTooltip
        placement="right-start"
        arrow
        // open={true}
        title={
          <>
            <Card
              style={{
                background: 'while',
                // color: '#002A45',
                // fontWeight: 'bold',
                margin: 0,
                padding: 5,
                maxHeight: 400,
                overflowY: 'scroll',
                maxWidth: 500,
                overflowX: 'scroll',
              }}
            >
              {renderContent()}
            </Card>
          </>
        }
      >
        {children}
      </CustomWidthImageTooltip>
    </>
  );
}

export default HoverToolTip;
