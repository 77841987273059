/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unused-prop-types */
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useRef } from 'react';
import { pdfSmartHeader } from '../../../../api/module1';
import Module1Context from '../../Module1Context';
import Run1Context, { Resource } from '../../RunContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      flexGrow: 1,
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    buttonContainer: {
      alignItems: 'flex-end',
      '& > *': {
        margin: theme.spacing(3),
      },
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    control: {
      padding: theme.spacing(2),
    },
    paper: {
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '95%',
    },
    formControl1: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    paper1: {
      width: '100%',
      // borderRadius: 5,
      border: '1px solid #002A45',
      color: '#002A45',
      fontWeight: 'bold',
    },
    treeitem: {
      width: '95%',
    },
    radiobtn: {
      textAlign: 'left',
    },
    warningTable: {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
    textField: {
      // color: '#002A45 !important',
      backgroundColor: '#EEF1F9',
      borderRadius: '5px !important',
      '& .MuiInputBase-input': {
        color: '#002A45 !important',
        fontWeight: 'bold',
        border: '1px solid #002A45',
        borderRadius: 4,
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none',
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none',
      },
      '& .MuiFormLabel-root': {
        color: '#002A45 !important',
        fontWeight: 'bold',
      },
      '& .MuiFilledInput-root': {
        borderBottomLeftRadius: '4px !important',
        borderBottomRightRadius: '4px !important',
      },
    },
    textFieldSecond: {
      // color: '#002A45 !important',
      backgroundColor: '#EEF1F9',
      borderRadius: '5px !important',
      '& .MuiInputBase-input': {
        color: '#002A45 !important',
        fontWeight: 'bold',
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none',
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none',
      },
      '& .MuiFormLabel-root': {
        color: '#002A45 !important',
        fontWeight: 'bold',
      },
      '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
        // these are the classes used from material-ui library for the asterisk element
        // '&::after': {
        //   color: 'red', // add your text here
        // },
        color: 'red',
      },
      '& .MuiFilledInput-root': {
        // position: 'relative',
        // backgroundColor: 'rgba(0, 0, 0, 0.09) !important',
        // borderTopLeftRadius: '4px !important',
        // borderTopRightRadius: '4px !important',
        // borderRadius: '4px !important',
      },
      borderBottomLeftRadius: '4px !important',
      width: 150,
    },
    labelAsterisk: {
      color: 'red',
    },
  })
);

type Props = {
  onChange?: any;
  resource: Resource;
};
// set state hide and use it

function FileSettingsDoc(this: any, { onChange, resource }: Props) {
  const { setSnack } = useContext(Module1Context);
  const runContext = useContext(Run1Context);
  const { setToggleCapture } = runContext;
  const { settings, filename, scope } = resource;
  const [starPagetValue, setStarPagetValue] = React.useState(
    settings?.startPage || scope?.StartPage
  );
  const [endPageValue, setEndPageValue] = React.useState(settings.endPage || scope?.EndPage);
  const [loadingHeader, setLoadingHeader] = React.useState(false);
  const [state, setState] = React.useState({
    extractTables: false,
    smartHeaderFooter: false,
    headerMargin: 0,
    footerMargin: 0,
  });
  const classes = useStyles();

  const refStartPage = useRef(null);
  const refEndPage = useRef(null);
  const { project } = useContext(Module1Context);

  const [radioCapture, setRadioCapture] = React.useState(
    // eslint-disable-next-line no-nested-ternary
    settings?.captureAll !== undefined ? (settings?.captureAll ? 'all' : 'custom') : 'all'
  );
  const handleRadioCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioCapture((e.target as HTMLInputElement).value);
    onChange(filename, { [e.target.name]: e.target.value === 'all' });
  };

  function handleReg(e: any) {
    if (e.target.name === 'typesOfCapture' && e.currentTarget.value !== 'Paragraph_Capture') {
      onChange(filename, { mergeBullet: false });
      setState({ ...state, mergeBullet: false });
    }
    onChange(filename, { [e.target.name]: e.currentTarget.value });
    setState({ ...state, [e.target.name]: e.target.checked });
  }

  function handleCheckboxBool(e: any) {
    onChange(filename, { [e.target.name]: e.target.checked });
    setState({ ...state, [e.target.name]: e.target.checked });
  }
  function handleHeader(e: any) {
    // #validation
    const startPage = settings.startPage !== undefined ? settings.startPage : scope?.StartPage;
    const endPage = settings.endPage !== undefined ? settings?.endPage : scope?.EndPage;
    if (startPage > endPage && e.target.value > 0) {
      setSnack('Start page must be lower than end page', 'warning');
      return;
    }
    onChange(filename, { [e.target.name]: e.target.checked });
    setState({ ...state, [e.target.name]: e.target.checked });
    if (e.currentTarget.checked) {
      setLoadingHeader(true);
      setToggleCapture(false);
      pdfSmartHeader({
        startPage: Number(startPage),
        endPage: Number(endPage),
        FilePath: scope?.FilePath,
        BlobName: scope?.Blob,
        Container: scope?.Container,
      })
        .then((payload) => {
          const { smartFooter, smartHeader } = payload.output;
          setState({
            ...state,
            ...{
              [e.target.name]: e.target.checked,
            },
          });
          onChange(filename, {
            ...settings,
            [e.target.name]: e.target.checked,
            footerMargin: smartFooter,
            headerMargin: smartHeader,
          });
        })
        .finally(() => {
          setLoadingHeader(false);
          setToggleCapture(true);
        });
    } else {
      delete settings.smartHeaderFooter;
      delete settings.footerMargin;
      delete settings.headerMargin;
      onChange(filename, settings, true);
    }
  }
  const StartPageHandle = (e: any) => {
    if (e.target.value > scope.EndPage) {
      setStarPagetValue({
        starPagetValue: e.preventDefault(),
      });
      setSnack('Please enter the valid start page Number', 'warning', 4000);
    } else {
      setStarPagetValue(e.target.value);
      setRadioCapture('custom');
      onChange(filename, { captureAll: false });
      onChange(filename, { [e.target.name]: e.currentTarget.value });
    }
  };
  const EndPageHandle = (e: any) => {
    if (e.target.value > scope.EndPage) {
      setEndPageValue({
        endPageValue: e.preventDefault(),
      });
      setSnack('Please enter the valid end page Number', 'warning', 4000);
    } else {
      setEndPageValue(e.target.value);
      setRadioCapture('custom');
      onChange(filename, { captureAll: false });
      onChange(filename, { [e.target.name]: e.currentTarget.value });
    }
  };
  const blockInvalidChar = (e: any) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

  const typesOfCapture = settings.typesOfCapture || scope?.TypesOfCapture[0];

  return (
    <Paper className={classes.paper1}>
      <Grid container>
        <Grid item xs={8}>
          <Grid item xs={12}>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                label="Project Name"
                variant="filled"
                name="projectId"
                defaultValue={project.name}
                className={classes.textField}
                inputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
              {settings.typesOfCapture === 'Requirement_Capture' && (
                <TextField
                  label="Reg ID RegExp"
                  variant="outlined"
                  name="regId"
                  className={classes.textField}
                  defaultValue={settings?.regId || ''}
                  onChange={(e: any) =>
                    onChange(filename, { [e.target.name]: e.currentTarget.value })
                  }
                />
              )}

              <TextField
                label="Language"
                variant="filled"
                name="language"
                defaultValue={scope?.Language}
                className={classes.textField}
                inputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) =>
                  onChange(filename, { [e.target.name]: e.currentTarget.value })
                }
              />
              <TextField
                disabled
                label="File Type"
                variant="filled"
                name="filetype"
                className={classes.textField}
                defaultValue={scope?.FileType}
                onChange={(e: any) =>
                  onChange(filename, { [e.target.name]: e.currentTarget.value })
                }
              />
            </form>
          </Grid>
          <Grid item xs={12}>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                label="Capture Name"
                inputProps={{
                  maxlength: 20,
                }}
                required
                variant="outlined"
                // style={{ width: 125 }}
                name="nameOfCapture"
                // className={classes.textFieldSecond}
                defaultValue=""
                onChange={(e: any) =>
                  onChange(filename, { [e.target.name]: e.currentTarget.value })
                }
              />

              <FormControl variant="outlined" className={classes.formControl1}>
                <InputLabel htmlFor="outlined-age-native-simple">Type of capture</InputLabel>
                <Select
                  native
                  label="Type of capture"
                  name="typesOfCapture"
                  // style={{ width: 150 }}
                  onChange={handleReg}
                  defaultValue={settings.typesOfCapture || scope?.TypesOfCapture[0]}
                >
                  <option aria-label="None" value="" />
                  {scope &&
                    scope.TypesOfCapture.map((cap: string, index: number) => (
                      <option key={`capture-type-${index}`} value={cap}>
                        {cap}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="captureAll"
                  value={radioCapture}
                  name="captureAll"
                  className={classes.radiobtn}
                  onChange={handleRadioCapture}
                >
                  <div style={{ width: '500px' }}>
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="Capture all pages"
                      onChange={(e: any) => {
                        if (e.currentTarget.checked) {
                          onChange(filename, {
                            captureAll: true,
                          });
                        }
                      }}
                    />
                  </div>
                </RadioGroup>{' '}
              </FormControl>
            </form>
          </Grid>
          {/* <Grid item xs={12} style={{ marginLeft: '10px', paddingBottom: '20px' }}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="captureAll"
                value={radioCapture}
                name="captureAll"
                className={classes.radiobtn}
                onChange={handleRadioCapture}
              >
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <div style={{ width: '500px' }}>
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label="Capture all pages"
                        onChange={(e: any) => {
                          if (e.currentTarget.checked) {
                            onChange(filename, {
                              captureAll: true,
                            });
                          }
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ width: '500px' }}>
                      <FormControlLabel
                        value="custom"
                        label=""
                        control={<Radio />}
                        onChange={(e: any) => {
                          if (e.currentTarget.checked) {
                            onChange(filename, {
                              captureAll: false,
                            });
                          }
                        }}
                      />
                      <TextField
                        className={classes.textFieldSecond}
                        ref={refStartPage}
                        type="number"
                        name="startPage"
                        label="Start page"
                        variant="outlined"
                        defaultValue={starPagetValue}
                        disabled={radioCapture === 'all'}
                        // style={{ width: 150 }}
                        inputProps={{ min: 1, max: scope?.EndPage }}
                        onKeyDown={blockInvalidChar}
                        value={starPagetValue}
                        onChange={StartPageHandle}
                      />
                      <span> To </span>
                      <TextField
                        ref={refEndPage}
                        type="number"
                        name="endPage"
                        label="End page"
                        variant="outlined"
                        className={classes.textFieldSecond}
                        defaultValue={endPageValue}
                        disabled={radioCapture === 'all'}
                        value={endPageValue}
                        // style={{ width: 150 }}
                        inputProps={{ min: 1, max: scope?.EndPage }}
                        // TODO
                        // onChange={onChangeEndPage}
                        onKeyDown={blockInvalidChar}
                        onChange={EndPageHandle}
                      />
                    </div>
                  </Grid>
                </Grid>
              </RadioGroup>{' '}
            </FormControl>
          </Grid> */}
        </Grid>
        <Grid
          item
          xs={4}
          style={{ borderLeft: '1px solid #002A45', color: '#002A45', fontWeight: 'bold' }}
        >
          <Grid container direction="column">
            <Grid item>
              <label htmlFor={`smartHeaderFooter-${filename}`}>
                <Checkbox
                  defaultChecked={settings.smartHeaderFooter}
                  onChange={handleCheckboxBool}
                  name="smartHeaderFooter"
                  id={`smartHeaderFooter-${filename}`}
                  color="primary"
                  value={true}
                />
                Remove header & footer from extraction
              </label>
            </Grid>
            {/* <Grid item>
              <label htmlFor={`mergeBullet-${filename}`}>
                <Checkbox
                  defaultChecked={settings.mergeBullet}
                  onChange={handleCheckboxBool}
                  name="mergeBullet"
                  id={`mergeBullet-${filename}`}
                  color="primary"
                  value={true}
                  disabled={typesOfCapture !== 'Paragraph_Capture'}
                />
                Merge bullets
              </label>
            </Grid> */}
            <Grid item>
              <label htmlFor={`smartTableOfContent-${filename}`}>
                <Checkbox
                  defaultChecked={settings.smartTableOfContent}
                  onChange={handleCheckboxBool}
                  name="smartTableOfContent"
                  id={`smartTableOfContent-${filename}`}
                  color="primary"
                  value={true}
                />
                Remove table of contents
              </label>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default FileSettingsDoc;
