import { useState } from 'react';
import jwt_decode from 'jwt-decode';
import { AppJwtType } from 'Auth';
// keep as JS

interface UseAppTokenType {
  appJWT: AppJwtType;
}

export default function useAppToken() {
  const getJWTContent = () => {
    const appJWT = localStorage.getItem('appJWT');
    if (appJWT === null || appJWT === '') return null;
    // always decode JWT
    return jwt_decode(JSON.parse(appJWT).access_token);
  };

  const [appJWT, setJwtContent] = useState(getJWTContent());

  /**
   * stringify token
   * @param {*} jwt
   */
  const saveJWT = (jwt) => {
    localStorage.setItem('appJWT', JSON.stringify(jwt));
    setJwtContent(jwt);
  };

  return {
    setAppJWT: saveJWT,
    appJWT,
  } as UseAppTokenType;
}
