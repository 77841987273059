/* eslint-disable react/jsx-no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SnackbarContentProps } from '@material-ui/core';
import { AlertProps } from '@mui/material';
import { getAllUserFiles, getGrossaryFile } from '../../api/Workspace';
import useAppToken from '../../app/useAppToken';
import formatBytes from '../../tools/formatBytes';
import AlertSnack from '../../components/Alert/AlertSnack';

export interface WorkSpaceContextType {
  files: any;
  setFiles: any;
  docFiles: any;
  setDocFiles: any;
  glossaryFiles: any;
  setGlossaryFiles: any;
  setSnackMsg: (msg: SnackbarContentProps['message']) => any;
  setSnackSeverity: (severity: AlertProps['severity']) => any;
  setSnack: (msg: string, severity?: AlertProps['severity'], duration?: number) => any;
  historyTranslation: any;
  setHistoryTranslation: any;
}

const WorkSpaceContext = React.createContext<WorkSpaceContextType>({
  docFiles: [] as any,
  setDocFiles: (): any => {},
  files: [] as any,
  historyTranslation: [] as any,
  setFiles: (): any => {},
  setSnackMsg: (): any => {},
  setSnackSeverity: (): any => {},
  glossaryFiles: [] as any,
  setGlossaryFiles: (): any => {},
  // combi msg + severity
  setSnack: (): any => {},
  setHistoryTranslation: (): any => {},
});

type Props = {
  children: any;
};

const WorkSpaceContextProvider = ({ children }: Props) => {
  const [_files, _setFiles] = useState([]);
  const [glossaryFiles, setGlossaryFiles] = useState([]);
  const [docFiles, setDocFiles] = useState([]);
  const [snackMsg, setSnackMsg] = useState<SnackbarContentProps['message']>('');
  const [snackSeverity, setSnackSeverity] = useState<AlertProps['severity']>('success');
  const [historyTranslation, setHistoryTranslation] = useState([]);
  const [snackDuration, setSnackDuration] = useState<number>(3000);
  //   const { userId } = useParams();
  const { appJWT } = useAppToken();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackMsg('');
  };

  useEffect(() => {
    try {
      getGrossaryFile(appJWT.user_id).then((result) => {
        const allResources = [] as any;
        if (result.length > 0) {
          const { resources } = result[0];
          for (const r of resources) {
            allResources.push({
              ...r,
              size: formatBytes(Number(r.size), 0),
              id: r.etag,
              folderName: 'user',
              folderId: 0,
              isNew: false,
            });
          }
        }
        setGlossaryFiles(allResources.sort((a, b) => new Date(b.date) - new Date(a.date)));
      });
      getAllUserFiles(appJWT.user_id).then((result) => {
        const allResources = [] as any;
        if (result.length > 0) {
          const { resources } = result[0];
          for (const r of resources) {
            allResources.push({
              ...r,
              size: formatBytes(Number(r.size), 0),
              id: r.etag,
              folderName: 'user',
              folderId: 0,
              isNew: false,
            });
          }
        }
        _setFiles(allResources.sort((a, b) => new Date(b.date) - new Date(a.date)));
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <WorkSpaceContext.Provider
      value={{
        files: _files,
        setFiles: _setFiles,
        docFiles,
        setDocFiles,
        glossaryFiles,
        setGlossaryFiles,
        setSnackMsg,
        setSnackSeverity,
        setSnack: (
          msg: string,
          severity = 'success' as AlertProps['severity'],
          duration = 3000
        ) => {
          setSnackDuration(duration);
          setSnackMsg(msg);
          setSnackSeverity(severity);
        },
        historyTranslation,
        setHistoryTranslation,
      }}
    >
      {snackMsg !== '' && (
        <AlertSnack
          msg={snackMsg}
          severity={snackSeverity}
          handleClose={handleClose}
          duration={3000}
        />
      )}
      {children}
    </WorkSpaceContext.Provider>
  );
};

export default WorkSpaceContext;

export { WorkSpaceContextProvider };
