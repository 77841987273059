import React from 'react';
import { dispatchType, reducerStateType } from './ChatReducer';

export type ChatContextType = {
  state: reducerStateType;
  dispatch: dispatchType;
};

export const ChatContext = React.createContext<ChatContextType>({} as ChatContextType);
type Props = {
  state: reducerStateType;
  dispatch: dispatchType;
  children?: any;
};
const ChatContextProvider = ({ state, dispatch, children }: Props): any => {
  // console.log(state, dispatch);

  return <ChatContext.Provider value={{ state, dispatch }}>{children}</ChatContext.Provider>;
};

export default ChatContextProvider;
