import JSZip from 'jszip';

const zip = new JSZip();

const CreateZip = async (urls: any[], zipFolderName: string) => {
  const addFilesToZip = async (urls: any[], filename: string) => {
    try {
      const response = await fetch(urls);
      const data = await response.blob();
      zip.file(filename, data);
    } catch (error) {
      console.error('Error adding file to zip', error);
    }
  };

  const promises = urls.map((data, index) => {
    const { url, filename } = data;
    return addFilesToZip(url, filename);
  });

  try {
    await Promise.all(promises);
    const content = await zip.generateAsync({ type: 'blob' });
    if (content instanceof Blob) {
      const url = window.URL.createObjectURL(content);
      const a = document.createElement('a');
      a.href = url;
      a.download = zipFolderName.length > 0 ? `${zipFolderName}.zip` : 'downloaded.zip';
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      console.error('Invalid Blob data');
    }
  } catch (error) {
    console.error('Error generating zip archive:', error);
  }
};

export default CreateZip;
