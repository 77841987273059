import { Typography, Button, Card, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { Image as ImageIcon, TableChart as TableChartIcon } from '@mui/icons-material';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
// import TextRowModal from '../Modal/TextRowModal';
import HoverToolTip from '../Modal/HoverToolTip';
import HoverToolTipForText from '../Modal/HoverToolTipForText';

const RenderText = ({ renderedCellValue, row }) => {
  // const [open, setOpen] = useState(false);

  const { SubType } = row || '';

  function renderValues() {
    const key = SubType ?? '';
    switch (key) {
      case 'Paragraph':
        return (
          <HoverToolTipForText renderedCellValue={renderedCellValue}>
            <Typography color="info">{renderedCellValue}</Typography>
          </HoverToolTipForText>
        );

      case 'Heading':
        return (
          <HoverToolTipForText renderedCellValue={renderedCellValue}>
            <Typography color="info" style={{ fontWeight: 'bold' }}>
              {renderedCellValue}
            </Typography>
          </HoverToolTipForText>
        );

      case 'Image':
        return (
          <>
            <HoverToolTip row={row} renderedCellValue={renderedCellValue}>
              {' '}
              <ImageIcon />
            </HoverToolTip>

            <b>{renderedCellValue}</b>
          </>
        );

      case 'Table':
        return (
          <>
            <HoverToolTip row={row} renderedCellValue={renderedCellValue}>
              <TableChartIcon />{' '}
            </HoverToolTip>
            <b>{renderedCellValue}</b>
          </>
        );

      default:
        return (
          <HoverToolTipForText renderedCellValue={renderedCellValue}>
            {' '}
            <Typography color="info">{renderedCellValue}</Typography>
          </HoverToolTipForText>
        );
    }
  }

  return (
    <>
      {renderValues()}

      {/* {open && <TextRowModal open={open} onClose={handleClose} type={SubType} row={row} />} */}
    </>
  );
};

export default RenderText;
