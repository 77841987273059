import { Chip, Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  BarChart,
  Sector,
} from 'recharts';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getAssignRequirementAllocation, getProjectManagement } from '../../api/assign';
import { getTimseries, KpiInput } from '../../api/analysis';
import { getComplianceKPIs, getLifecycleKPIs } from '../../api/kpi';
import {
  COMPLIANCE_COLORS,
  ASSIGN_LIFECYCLE_COLORS,
  COMPLIANCE_LABELS,
  LIFECYCLE_COLORS,
  LIFECYCLE_LABELS,
  ALLOCATION_COLORS,
  ALLOCATION_LABELS,
  ASSIGN_LIFECYCLE_LABELS,
} from '../../const';

type Props = {
  project_id: number;
  user_id?: number;
  title: string;
  onClose?: any;
  workload_id?: number;
  file_name?: string;
};
const COLORS = [
  '#1e3246',
  '#dc3223',
  '#19aa6e',
  '#4b5a69',
  '#788291',
  '#d2d7dc',
  '#eb827d',
  '#fad7d2',
  '#d2f0e1',
  '#73cdaa',
  '#9b875f',
  '#afa082',
  '#ebe6dc',
  '#c3b9a0',
  '#C3DDF6',
  '#468AD1',
  '#F8A600',
  '#2f475f',
  '#95372f',
  '#7a231b',
  '#94a2b8',
  '#5b8f75',
  '#546c60',
  '#36443d',
  '#748c80',
  '#4fa7ff',
  '#174471',
  '#384a5c',
  '#acacad',
  '#bdbdbd',
];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fontSize="16px"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '20px',
    border: `1px solid`,
    borderColor: theme.palette.primary.main,
    opacity: 1,
    backgroundColor: 'white',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  customTooltip: {
    background: 'rgba(0, 0, 0, 0.75)',
    borderRadius: '2px',
    color: 'white',
    padding: '5px',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
    pointerEvents: 'none',
    position: 'relative',
    transform: 'translate(-50%, -100%)',
    whiteSpace: 'nowrap',
  },
  label: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
}));

export default function AnalysisFlowKPI({
  project_id,
  workload_id,
  user_id,
  file_name,
  title,
  onClose,
}: Props) {
  const classes = useStyles();
  const [activeIndex1, setActivate1] = useState(0);
  const [activeIndex2, setActivate2] = useState(0);
  const [pieDataCompliance, setPieDataCompliance] = useState([] as any);
  const [pieDataLifecycle, setPieDataLifecycle] = useState([] as any);
  const [timeseriesCompliance, setTimeseriesCompliance] = useState([] as any[]);
  const [timeseriesLifecycle, setTimeseriesLifecycle] = useState([] as any[]);
  const [obsValue, setObsValue] = React.useState('obs');

  useEffect(() => {
    const kpiInput = { project_id } as KpiInput;
    if (workload_id) kpiInput.workload_id = workload_id;
    if (file_name) kpiInput.file_name = file_name;
    if (user_id) kpiInput.user_id = user_id;
    // timeseries compliance
    getTimseries(kpiInput, 'compliance').then((output) => {
      setTimeseriesCompliance(output.sort((a, b) => (a.name < b.name ? -1 : 1)));
    });
    // timeseries lifecycle
    getTimseries(kpiInput, 'lifecycle').then((output) => {
      setTimeseriesLifecycle(output.sort((a, b) => (a.name < b.name ? -1 : 1)));
    });
    // for lifecycle
    getLifecycleKPIs(kpiInput).then((output) => {
      const outputWithoutOwned = output.filter((user) => user.name !== 'owned');
      setPieDataLifecycle(outputWithoutOwned);
      // setPieDataLifecycle(output);
    });
    // for compliance
    getComplianceKPIs(kpiInput).then((output) => {
      setPieDataCompliance(output);
    });
  }, [project_id, workload_id, file_name, user_id, obsValue]);

  const onPieEnter1 = (_, index) => {
    setActivate1(index);
  };

  const onPieEnter2 = (_, index) => {
    setActivate2(index);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setObsValue(event.target.value);
  };

  const CustomTooltip = (props) => {
    const { payload, cx, cy } = props;
    if (payload && payload.length) {
      return (
        <div className={classes.customTooltip} style={{ top: cy, left: cx }}>
          <p className={classes.label}>{`${payload[0].name}: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`PV ${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        spacing={0}
        className={classes.root}
      >
        <Grid item lg={12} sm={12} md={12}>
          <Typography variant="subtitle1">Allocation lifecycle status for user</Typography>
        </Grid>
        <Grid item lg={6} sm={6} md={6}>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <PieChart width={600} height={400}>
              <Pie
                isAnimationActive={true}
                activeIndex={activeIndex1}
                data={pieDataLifecycle}
                activeShape={renderActiveShape}
                // cy="center"
                cx="50%"
                cy="50%"
                legendType="circle"
                // labelLine={false}
                // label={renderCustomizedLabel}
                // outerRadius={140}
                innerRadius={90}
                outerRadius={120}
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={onPieEnter1}
              >
                {pieDataLifecycle.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={LIFECYCLE_COLORS[entry.name]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </Box>
          <div style={{ textAlign: 'center' }}>
            {pieDataLifecycle.map((d, index) => (
              <Chip
                label={`${LIFECYCLE_LABELS[d.name]} (${d.value})`}
                color="primary"
                style={{
                  backgroundColor: LIFECYCLE_COLORS[d.name],
                  marginRight: '3px',
                  marginTop: '5px',
                }}
                key={`chip-${index}`}
              />
            ))}
          </div>
        </Grid>
        <Grid item lg={6} sm={6} md={6}>
          <ResponsiveContainer>
            <LineChart
              height={500}
              data={timeseriesLifecycle}
              margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="analyzed" stroke={LIFECYCLE_COLORS.analyzed} />
              <Line type="monotone" dataKey="assigned" stroke={LIFECYCLE_COLORS.assigned} />
              <Line
                type="monotone"
                dataKey="characterized"
                stroke={LIFECYCLE_COLORS.characterized}
              />
              <Line
                type="monotone"
                dataKey="clarification"
                stroke={LIFECYCLE_COLORS.clarification}
              />
              <Line type="monotone" dataKey="rejected" stroke={LIFECYCLE_COLORS.rejected} />
              {/* <Line type="monotone" dataKey="owned" stroke={LIFECYCLE_COLORS.owned} /> */}
              <Line type="monotone" dataKey="recaptured" stroke={LIFECYCLE_COLORS.recaptured} />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        spacing={0}
        className={classes.root}
      >
        <Grid item lg={12} sm={12} md={12}>
          <Typography variant="subtitle1">Allocation compliance status for user</Typography>
        </Grid>
        <Grid item lg={6} sm={6} md={6}>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <PieChart width={600} height={400}>
              <Pie
                isAnimationActive={true}
                activeIndex={activeIndex2}
                data={pieDataCompliance}
                activeShape={renderActiveShape}
                // cy="center"
                cx="50%"
                cy="50%"
                legendType="circle"
                // labelLine={false}
                // label={renderCustomizedLabel}
                // outerRadius={140}
                innerRadius={90}
                outerRadius={120}
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={onPieEnter2}
              >
                {pieDataCompliance.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COMPLIANCE_COLORS[entry.name]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </Box>
          <div style={{ textAlign: 'center' }}>
            {pieDataCompliance.map((d: any, index) => (
              <Chip
                label={`${COMPLIANCE_LABELS[d.name]} (${d.value})`}
                color="primary"
                style={{
                  backgroundColor: COMPLIANCE_COLORS[String(d.name)],
                  marginRight: '3px',
                  marginTop: '5px',
                }}
                key={`chip-${index}`}
              />
            ))}
          </div>
        </Grid>
        <Grid item lg={6} sm={6} md={6}>
          <ResponsiveContainer>
            <LineChart
              height={230}
              data={timeseriesCompliance}
              margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              {/* <Line type="monotone" dataKey="not_started" stroke={COLORS_MAP.not_started} /> */}
              <Line type="monotone" dataKey="compliant" stroke={COMPLIANCE_COLORS.compliant} />
              <Line type="monotone" dataKey="nc" stroke={COMPLIANCE_COLORS.nc} />
              <Line type="monotone" dataKey="gap" stroke={COMPLIANCE_COLORS.gap} />
              <Line type="monotone" dataKey="tbd" stroke={COMPLIANCE_COLORS.tbd} />
              <Line
                type="monotone"
                dataKey="need-clarification"
                stroke={COMPLIANCE_COLORS['need-clarification']}
              />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </>
  );
}
