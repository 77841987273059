import React, { useContext } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import useProjectId from '../../app/useProjectId';
import ProjectContext from '../Project/ProjectContext';
import KpiContext, { KpiContextProvider } from './KpiContext';
import KpiContainer from './KpiContainer';
import PageLayout from '../../layouts/PageLayout';

function KpiRouter() {
  const { projectId } = useParams() as any;
  const { projects_lite } = useContext(ProjectContext);

  const { project } = useProjectId(projectId, projects_lite);

  if (project === undefined) return null;

  return (
    <KpiContextProvider project={project}>
      <KpiContext.Consumer>
        {(): any => {
          return (
            <Switch>
              <PageLayout
                path="/projects/:projectId/kpi"
                render={() => {
                  return <KpiContainer />;
                }}
              />
              <PageLayout
                path="*"
                render={() => {
                  return <KpiContainer />;
                }}
              />
            </Switch>
          );
        }}
      </KpiContext.Consumer>
    </KpiContextProvider>
  );
}

export default KpiRouter;
