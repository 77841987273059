/* eslint-disable import/prefer-default-export */
import { AnalysisDataset, AnalysisDatasetNew } from 'AnalysisDataset';
import axios from './axios';
import { BACKEND_URL } from '../const';

// check duplication
const checkDuplicationName = (rows: AnalysisDataset[]) => {
  return rows.findIndex((v, i) => {
    if (v.name === undefined) {
      return false;
    }
    return (
      rows.findIndex((v2, i2) => {
        if (v2.name === undefined) {
          return false;
        }
        return String(v.name).toLowerCase() === String(v2.name).toLowerCase() && i !== i2;
      }) !== -1
    );
  });
};

const checkDuplicationRationale = (rows: AnalysisDataset[]) => {
  return rows.findIndex((v, i) => {
    if (v.rationale === undefined && v.rationale === null) {
      return false;
    }
    return (
      rows.findIndex((v2, i2) => {
        if (v2.rationale === undefined && v.rationale === null) {
          return false;
        }
        return String(v.rationale).toLowerCase() === String(v2.rationale).toLowerCase() && i !== i2;
      }) !== -1
    );
  });
};

const checkRationales = (rows: AnalysisDataset[]) => {
  for (const v of rows) {
    if (!('rationale' in v) || v.rationale === undefined || v.rationale === null) {
      return true;
    }
  }
  return false;
};

// name should be 4 charc alpha  - _
const checkRegName = (rows: AnalysisDataset[], datatype: 'nc' | 'gap' = 'gap') => {
  let reg = /^[a-z0-9_-]{4,}$/gi;
  if (datatype === 'nc') {
    reg = /^[NC_][a-z0-9_-]{4,}$/gi;
  } else {
    reg = /^[GAP_][a-z0-9_-]{4,}$/gi;
  }
  return rows.findIndex((v) => {
    return String(v.name).search(reg) === -1;
  });
};

const getAllImportedAnalysis = async (projectId: number): Promise<AnalysisDataset[]> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/projects/${projectId}/analysis_dataset`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

type RespImport = AnalysisDataset[];

const createAnalysisDataset = async (
  projectId: number,
  formdata: AnalysisDatasetNew[]
): Promise<RespImport> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/projects/${projectId}/analysis_dataset`,
      formdata
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
type editionType = 'excelImport' | 'manualMode';
const writeAnalysisDataset = async (
  projectId: number,
  datatype: 'nc' | 'gap',
  formdata: AnalysisDataset[],
  editionMode: editionType
): Promise<RespImport> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/projects/${projectId}/analysis_dataset/${datatype}?edition_mode=${editionMode}`,
      formdata
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getAllAnalysisDataset = async (projectId: number): Promise<RespImport> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/projects/${projectId}/analysis_dataset`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const updateAnalysisDataset = async (
  projectId: number,
  formdata: AnalysisDataset[]
): Promise<RespImport> => {
  try {
    const response = await axios.put(
      `${BACKEND_URL.root}/projects/${projectId}/analysis_dataset`,
      formdata
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const deleteAnalysisDataset = async (
  projectId: number,
  formdata: number[]
): Promise<RespImport> => {
  try {
    const response = await axios.delete(
      `${BACKEND_URL.root}/projects/${projectId}/analysis_dataset`,
      { data: formdata }
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getUserRole = async (projectId: number) => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/projects/${projectId}/users/roles`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export {
  getAllImportedAnalysis,
  createAnalysisDataset,
  updateAnalysisDataset,
  writeAnalysisDataset,
  deleteAnalysisDataset,
  checkDuplicationName,
  checkDuplicationRationale,
  checkRegName,
  checkRationales,
  getUserRole,
  getAllAnalysisDataset,
};
