import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import CloseIcon from '@mui/icons-material/Close';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import {
  checkDuplicationName,
  checkDuplicationRationale,
  checkRegName,
  createAnalysisDataset,
  getAllAnalysisDataset,
} from '../../../../../../api/analysis_dataset';
import axios from '../../../../../../api/axios';
import useAppToken from '../../../../../../app/useAppToken';
import { BACKEND_URL } from '../../../../../../const';
import ProjectContext from '../../../../../Project/ProjectContext';
import Similarizer from '../Similarizer';
import ModalAnalysisForms from './component/ModalAnalysisForms';
import { splitText } from '../utils/extractdata';

type Props = {
  requirements: any[];
  rows: any[];
  myProjectId: Number;
  workloadId: Number;
  filename: string;
  setToggleAnalysis: any;
  onClose?: any;
  onSendResolve?: any;
  type: string;
};

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  // textAlign: 'center',
  // color: theme.palette.text.secondary,
}));

const ModalAnalysis = ({
  open,
  onClose,
  requirements,
  selectedIds,
  workloadId,
  filename,
  targetColumn,
  project,
  projectId,
  setNotify,
  userId,
  setMetaDAta,
  langCheck,
  languagesCode,
  metaData,
  urlFile,
  type,
}) => {
  // const classes = useStyles();

  // console.log('requirements', requirements);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [gapList, setGapList] = useState([]);
  const [nclist, setNcList] = useState([]);
  const [updateRequirement, setUpdateRequirement] = useState([]);

  useEffect(() => {
    getAllAnalysisDataset(projectId)
      .then((res) => {
        setGapList(res?.filter((a) => a.datatype === 'gap'));
        setNcList(res?.filter((a) => a.datatype === 'nc'));
      })
      .catch(() => {
        console.log('no values for gap & nc');
      });
  }, [project]);
  // compliance: e.target.value,
  // rationale: rationaleExtract.rationale,
  // created_at: rationaleExtract.created_at,
  // analysis_dataset_id: rationaleExtract.id,

  useMemo(() => {
    const dataUpdated = requirements.map((eachRequirement) => {
      const newComment = {
        created_at: '',
        content: '',
        isNew: true,
      };

      if (eachRequirement.isAnalised) {
        const comments =
          eachRequirement?.comments.map((data) => {
            return {
              ...data,
              isNew: false,
            };
          }) ?? [];

        return {
          Coordinates: eachRequirement.Coordinates,
          text: splitText(eachRequirement.Text),
          isAnalised: eachRequirement?.isAnalised,
          compliance: eachRequirement?.compliance ?? 'none',
          rationale: eachRequirement?.rationale[0]?.content ?? '',
          created_at: eachRequirement?.rationale[0]?.created_at ?? '',
          analysis_dataset_id: eachRequirement?.analysis_dataset_id ?? '',
          allocation: eachRequirement?.allocation ?? '',
          compliant: ['gap', 'nc'].includes(eachRequirement?.compliance)
            ? eachRequirement?.analysis_dataset?.name ?? ''
            : '',
          owning_id: eachRequirement.owning_id,
          allocation_id: eachRequirement.allocation_id,
          comments: [...[newComment], ...comments],
          pbs: eachRequirement.pbs,
          user_id: userId,
          workload_id: workloadId,
          project_id: projectId,
          filename,
          reject: false,
          rejectValue: '',
        };
      }

      return {
        Coordinates: eachRequirement.Coordinates,
        text: splitText(eachRequirement.Text),
        isAnalised: eachRequirement?.isAnalised,
        compliance: 'none',
        rationale: '',
        created_at: '',
        compliant: '',
        comments: [newComment],
        analysis_dataset_id: '',
        allocation: eachRequirement?.allocation ?? '',
        owning_id: eachRequirement.owning_id,
        allocation_id: eachRequirement.allocation_id,
        pbs: eachRequirement.pbs,
        user_id: userId,
        workload_id: workloadId,
        project_id: projectId,
        filename,
        reject: false,
      };
    });
    setUpdateRequirement(dataUpdated);
  }, [requirements]);

  const trackCurrentRequirement = updateRequirement[currentIndex];

  const IncrementIndex = () => {
    setCurrentIndex((prev) => prev + 1);
  };

  const decrementIndex = () => {
    setCurrentIndex((prev) => prev - 1);
  };

  const props = {
    onClose,
    trackCurrentRequirement,
    firstIndex: currentIndex === 0,
    lastIndex: currentIndex === updateRequirement.length - 1,
    IncrementIndex,
    decrementIndex,
    totalRequirements: updateRequirement.length,
    currentIndex,
    gapList,
    setGapList,
    nclist,
    setNcList,
    setNotify,
    updateRequirement,
    setUpdateRequirement,
    workloadId,
    filename,
    projectId,
    userId,
    setMetaDAta,
    project,
    langCheck,
    languagesCode,
    selectedIds,
    metaData,
    urlFile,
    type,
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        sx={{ padding: 0, backgroundColor: '#EEF1F9' }}
      >
        <DialogContent
          sx={{ m: 0, position: 'relative', height: '100vh', backgroundColor: '#EEF1F9', p: 0 }}
        >
          <ModalAnalysisForms props={{ ...props }} />
          <IconButton
            color="primary"
            sx={{ position: 'absolute', top: 0, right: 0 }}
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(ModalAnalysis);
