export const hiddenColumns = [
  'product',
  'solution',
  'project',
  'country',
  'rexIdentification',
  'rexSource',
  '8D#',
  'QRQC#',
  'CONQ#',
  'rexSessionDate',
  'abs',
  'pbs',
  'impactedSolution',
  'metierImpacted',
  'picOfSsupport',
  'luPuSuppliersMetadata',
  'qualityImpact',
  'costImpact',
  'deliveryImpact',
  'mitigationAction',
  // 'proposedAction',
  'pic',
  'deadlineForAnalysis',
  // 'analysis',
  'rootCauses',
  'capitalizationByStorage',
  'decidedActionOfCapitalization',
  'metierInvolved',
  'solvingSite',
  'picOfCapitalization',
  'deadlineForImplementation',
  // 'capitalizationEvidence',
  'relevantForRequirement',
  'rejectionReason',
  'comments',
  'submitter',
  'priority',
  'rexCardToBeTransferred',
  'rexCardReused',
  'tenderProjectProgramREXcardReused',
  'idREXCardDuplicated',
  'createdBy',
  'createdOn',
  'specialProcesses',
  'itemType',
  'path',
  // 'id',
];
const RexButtons = [
  'REX Cards',
  'REX Book',
  // 'REX Chat',
  'Requirements',
  'Experts',
  'Tests',
  'Change Requests',
  'Lean Six Sigma',
  'APSYS',
  'CSS Project',
];

// eslint-disable-next-line consistent-return
export const getRexData = (_data: string | any[]) => {
  if (_data.length > 0) {
    const columns = Object.keys(_data[0]);
    let temp = {};
    const modifiedCol = columns.map((data) => {
      if (data === 'deadlineForAnalysis') {
        temp = {
          name: data,
          title: 'Deadline for Analysis',
        };
      } else if (data === 'cashImpactiSenS') {
        temp = {
          name: data,
          title: 'Cash Impact (derived by iSenS)',
        };
      } else if (data === 'capitalizationEvidence') {
        temp = {
          name: data,
          title: 'Capitalization Evidence',
        };
      } else if (data === 'proposedAction') {
        temp = {
          name: data,
          title: 'Proposed Action',
        };
      } else if (data === 'capitalizationByStorage') {
        temp = {
          name: data,
          title: 'Capitalization By Storage',
        };
      } else if (data === 'capitalizationEvidence') {
        temp = {
          name: data,
          title: 'Capitalization Evidence',
        };
      } else if (data === 'costImpact') {
        temp = {
          name: data,
          title: 'Cost Impact',
        };
      } else if (data === 'deadlineForImplementation') {
        temp = {
          name: data,
          title: 'Deadline For Implementation',
        };
      } else if (data === 'decidedActionOfCapitalization') {
        temp = {
          name: data,
          title: 'Decided Action Of Capitalization',
        };
      } else if (data === 'deliveryImpact') {
        temp = {
          name: data,
          title: 'Delivery Impact',
        };
      } else if (data === 'idREXCardDuplicated') {
        temp = {
          name: data,
          title: 'Id Rex card Duplicated',
        };
      } else if (data === 'cashImpact') {
        temp = {
          name: data,
          title: 'Cash Impact',
        };
      } else if (data === 'impactedSolution') {
        temp = {
          name: data,
          title: 'Impacted Solution',
        };
      } else if (data === 'issuerSite') {
        temp = {
          name: data,
          title: 'Issuer Site',
        };
      } else if (data === 'itemType') {
        temp = {
          name: data,
          title: 'Item Type',
        };
      } else if (data === 'luPuSuppliersMetadata') {
        temp = {
          name: data,
          title: 'luPu Suppliers Metadata',
        };
      } else if (data === 'metierImpacted') {
        temp = {
          name: data,
          title: 'Metier Impacted',
        };
      } else if (data === 'metierInvolved') {
        temp = {
          name: data,
          title: 'Metier Involved',
        };
      } else if (data === 'mitigationAction') {
        temp = {
          name: data,
          title: 'Mitigation Action',
        };
      } else if (data === 'picOfCapitalization') {
        temp = {
          name: data,
          title: 'Pic Of Capitalization',
        };
      } else if (data === 'picOfSsupport') {
        temp = {
          name: data,
          title: 'Pic Of support',
        };
      } else if (data === 'proposedAction') {
        temp = {
          name: data,
          title: 'Proposed Action',
        };
      } else if (data === 'qualityImpact') {
        temp = {
          name: data,
          title: 'Quality Impact',
        };
      } else if (data === 'rejectionReason') {
        temp = {
          name: data,
          title: 'Rejection Reason',
        };
      } else if (data === 'relevantForRequirement') {
        temp = {
          name: data,
          title: 'Relevant For Requirement',
        };
      } else if (data === 'rexCardReused') {
        temp = {
          name: data,
          title: 'Rex Card Reused',
        };
      } else if (data === 'rexCardToBeTransferred') {
        temp = {
          name: data,
          title: 'Rex Card to be Transferred',
        };
      } else if (data === 'rexIdentification') {
        temp = {
          name: data,
          title: 'Rex Identification',
        };
      } else if (data === 'rexSessionDate') {
        temp = {
          name: data,
          title: 'Rex Session Date',
        };
      } else if (data === 'rexSource') {
        temp = {
          name: data,
          title: 'Rex Source',
        };
      } else if (data === 'rootCauses') {
        temp = {
          name: data,
          title: 'Root Causes',
        };
      } else if (data === 'solvingSite') {
        temp = {
          name: data,
          title: 'Solving Site',
        };
      } else if (data === 'specialProcesses') {
        temp = {
          name: data,
          title: 'Special Processes',
        };
      } else if (data === 'tenderProjectProgramREXcardReused') {
        temp = {
          name: data,
          title: 'Tender Project Program REX card Reused',
        };
      } else if (data === 'cashImpactiSenSJustification') {
        temp = {
          name: data,
          title: 'Cash Impact iSenS Justification',
        };
      } else {
        const t = data.toLowerCase();
        temp = {
          name: data,
          title: t[0].toUpperCase() + t.slice(1),
        };
      }
      return temp;
    });
    return { column: modifiedCol, row: _data };
  }
  return { column: [], row: [] };
};

export const rexColumnResize = [
  {
    columnName: 'id',
    width: 100,
  },
  {
    columnName: 'issuerSite',
    width: 200,
  },
  {
    columnName: 'organisation',
    width: 200,
  },
  {
    columnName: 'rexIdentification',
    width: 300,
  },
  {
    columnName: 'title',
    width: 300,
  },
  {
    columnName: 'cashImpact',
    width: 300,
  },
  {
    columnName: 'cashImpactiSenS',
    width: 350,
  },
  {
    columnName: 'cashImpactiSenSJustification',
    width: 350,
  },
  {
    columnName: 'status',
    width: 200,
  },
  {
    columnName: 'description',
    width: 400,
  },
  {
    columnName: 'typology',
    width: 100,
  },
  {
    columnName: 'platform',
    width: 100,
  },
  {
    columnName: 'rexSource',
    width: 300,
  },
  {
    columnName: '8D#',
    width: 300,
  },
  {
    columnName: 'QRQC#',
    width: 200,
  },
  {
    columnName: 'CONQ#',
    width: 200,
  },
  {
    columnName: 'rexSessionDate',
    width: 300,
  },
  {
    columnName: 'abs',
    width: 300,
  },
  {
    columnName: 'pbs',
    width: 300,
  },
  {
    columnName: 'impactedSolution',
    width: 300,
  },
  {
    columnName: 'metierImpacted',
    width: 300,
  },
  {
    columnName: 'picOfSsupport',
    width: 300,
  },
  {
    columnName: 'luPuSuppliersMetadata',
    width: 300,
  },
  {
    columnName: 'qualityImpact',
    width: 300,
  },
  {
    columnName: 'costImpact',
    width: 200,
  },
  {
    columnName: 'deliveryImpact',
    width: 200,
  },
  {
    columnName: 'mitigationAction',
    width: 300,
  },
  {
    columnName: 'proposedAction',
    width: 300,
  },
  {
    columnName: 'pic',
    width: 200,
  },

  {
    columnName: 'deadlineForAnalysis',
    width: 200,
  },
  {
    columnName: 'analysis',
    width: 300,
  },
  {
    columnName: 'rootCauses',
    width: 200,
  },
  {
    columnName: 'capitalizationByStorage',
    width: 200,
  },
  {
    columnName: 'decidedActionOfCapitalization',
    width: 300,
  },
  {
    columnName: 'metierInvolved',
    width: 300,
  },
  {
    columnName: 'solvingSite',
    width: 300,
  },
  {
    columnName: 'picOfCapitalization',
    width: 300,
  },
  {
    columnName: 'deadlineForImplementation',
    width: 200,
  },
  {
    columnName: 'capitalizationEvidence',
    width: 200,
  },
  {
    columnName: 'relevantForRequirement',
    width: 200,
  },
  {
    columnName: 'rejectionReason',
    width: 200,
  },
  {
    columnName: 'comments',
    width: 100,
  },
  {
    columnName: 'submitter',
    width: 100,
  },
  {
    columnName: 'priority',
    width: 100,
  },
  {
    columnName: 'rexCardToBeTransferred',
    width: 100,
  },
  {
    columnName: 'rexCardReused',
    width: 100,
  },
  {
    columnName: 'tenderProjectProgramREXcardReused',
    width: 100,
  },
  {
    columnName: 'idREXCardDuplicated',
    width: 100,
  },
  {
    columnName: 'createdBy',
    width: 100,
  },
  {
    columnName: 'createdOn',
    width: 100,
  },
  {
    columnName: 'specialProcesses',
    width: 100,
  },
  {
    columnName: 'itemType',
    width: 100,
  },
  {
    columnName: 'path',
    width: 100,
  },
  { columnName: 'productLine', width: 300 },

  { columnName: 'platform', width: 300 },

  { columnName: 'product', width: 300 },

  { columnName: 'solution', width: 300 },

  { columnName: 'project', width: 300 },

  { columnName: 'country', width: 300 },
  { columnName: 'problem', width: 300 },
  { columnName: 'context', width: 300 },
  { columnName: 'keytakeaway', width: 300 },
];

export const highlightConfig = {
  VALIDATED: {
    backgroundColor: '#8BC34A',
  },
  SUBMITTED: {
    backgroundColor: '#673AB7',
  },
  ANALYZED: {
    backgroundColor: '#4CAF50',
  },
  CAPITALIZED: {
    backgroundColor: '#2196F3',
  },
  CLOSED: {
    backgroundColor: '#9E9E9E',
  },
  'DISCARDED IN METIER': {
    backgroundColor: '#FFC107',
  },
  DRAFT: {
    backgroundColor: '#FF9800',
  },
  DUPLICATED: {
    backgroundColor: '#607D8B',
  },
  Draft: {
    backgroundColor: '#FF9800',
  },
  'Not defined': {
    backgroundColor: '#9E9E9E',
  },
  OBSOLETED: {
    backgroundColor: '#FF5722',
  },
  REJECTED: {
    backgroundColor: '#F44336',
  },
  'TO BE DELETED': {
    backgroundColor: '#E91E63',
  },
  DIS: {
    backgroundColor: '#002a45',
  },
  RSC: {
    backgroundColor: '#3498DB',
  },
  Services: {
    backgroundColor: '#E91E63',
  },
  'Not Defined': {
    backgroundColor: '#95A5A6',
  },
  STORED: {
    backgroundColor: '#FF5722',
  },
};

export default RexButtons;
