import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ColumnDef,
  MRT_ToggleDensePaddingButton as ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton as ToggleFullScreenButton,
  // MRT_ShowHideColumnsButton as ShowHideColumnsButton,
  MRT_GlobalFilterTextField as GlobalFilterTextField,
} from 'material-react-table';
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import GradeIcon from '@mui/icons-material/Grade';
import CheckIcon from '@mui/icons-material/Check';
import GavelIcon from '@mui/icons-material/Gavel';
import EditIcon from '@mui/icons-material/Edit';
import React, { useMemo, useState } from 'react';
import stylesRequiremt from '../../Styles/requirement';
import FullScreenToogle from '../../components/FullScreenToogle';
import { charScoreColor, lifeCycle } from '../../const/allocatorConst';
import Toogle from '../../components/Toggle';
import checkCompliance from '../components/checkCompliance';
import RenderLifeCycleMulti from '../components/RenderLIfeCycleMulti';
import { splitText } from '../../utils/extractdata';
import ComplianceMulti from '../components/ComplianceMutli';
import RenderGapOrNC from '../components/RenderGapOrNc';
import HoverToolTipForText from '../../Modal/HoverToolTipForText';
import { IOSSwitch, FormControl, ButtonMUI } from '../../Styles/utils';
import Refresh from '../../components/Refresh';
import useIconsForTable from '../../Hooks/useIconsForTable';
import ShowHideColumnsButton from '../../core/ShowHideColumnsButton';
import useAnalysisColumn from './useAnalysisColumn';
import hiddenColumns from '../../const/allData';

type Props = {
  targetColumn: any;
  data: any[];
  onChangeToggle: any;
  toggleTable: any;
  isFullScreen: any;
  onChangeFullScreen: any;
  user_id: any;
  onChangeModalData: any;
  rowSelection: any;
  setRowSelection: any;
  handleAllCompliant: any;
  loading: any;
  refreshDATA: any;
  displayColumns: any;
  levelsHierarchy: any;
  type: any;
};

const useAnalysis = (props: Props) => {
  const {
    targetColumn,
    loading: allCompliantLoading,
    data: rows,
    onChangeToggle,
    toggleTable,
    isFullScreen,
    onChangeFullScreen,
    user_id,
    onChangeModalData,
    rowSelection,
    setRowSelection,
    handleAllCompliant,
    refreshDATA,
    displayColumns,
    levelsHierarchy,
    type,
  } = props;

  const [favouriteToggle, setFavouriteToggle] = useState(false);

  const textColum = targetColumn ?? 'Text';

  const { IconsForTable } = useIconsForTable();

  const data = useMemo(() => {
    if (favouriteToggle) {
      return rows.filter((data) => {
        return data.isUsersFavourite;
      });
    }

    return rows;
  }, [rows, favouriteToggle]);

  // console.log('row', rows);

  const { columns } = useAnalysisColumn({
    data,
    user_id,
    targetColumn,
    displayColumns,
    levelsHierarchy,
    type,
  });

  const checkAssginStatus = (table) => {
    const { rows } = table.getSelectedRowModel();

    const values = Object.keys(table.getState().rowSelection).filter((data) => {
      return data.split(':').length === 1;
    });

    return values.length === 0;
  };

  const analysisObject = useMemo(
    () => ({
      columns,
      data,
      autoResetPageIndex: false,
      enableColumnResizing: true,
      enableGrouping: true,
      enableStickyHeader: true,
      enableRowSelection: (row) =>
        !['characterized', 'rejected'].includes(row.original.lifecycle) &&
        row.original.isUsersFavourite,
      editDisplayMode: 'table',
      enableSelectAll: favouriteToggle,
      enableEditing: true,
      enableRowActions: true,
      enableColumnDragging: false,
      enableColumnPinning: true,
      positionToolbarAlertBanner: 'none',
      paginationDisplayMode: 'pages',
      getRowId: (row) => row.rowId,
      ...stylesRequiremt,
      onRowSelectionChange: setRowSelection, // connect internal row selection state to your own
      initialState: {
        density: 'compact',
        showColumnFilters: true,
        columnVisibility: { ...hiddenColumns },
        // showGlobalFilter: true,
        expanded: true, // expand all groups by default
        grouping: ['requirementText'], // an array of columns to group by by default (can be multiple)
        pagination: { pageIndex: 0, pageSize: 20 },
        //   sorting: [{ id: "state", desc: false }], //sort by state by default
      },
      state: { rowSelection, showProgressBars: allCompliantLoading },
      muiToolbarAlertBannerChipProps: { color: 'primary' },
      muiLinearProgressProps: ({ isTopToolbar }) => ({
        color: 'secondary',
        sx: {
          display: isTopToolbar ? 'block' : 'none',
          height: 7,
        },
      }),

      renderTopToolbarCustomActions: ({ table }) => {
        return (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <ButtonMUI
              // color="secondary"
              startIcon={<EditIcon />}
              disabled={checkAssginStatus(table)}
              sx={{ backgroundColor: 'green' }}
              onClick={() => {
                const { rows } = table.getSelectedRowModel();
                // const { original } = rows;
                // console.log(rows);
                const temp = rows.map((Data) => {
                  return {
                    ...Data.original.analysis,
                    Coordinates: Data.original.Coordinates,
                    Text: Data.original.requirementText,
                    owning_id: Data.original.owning_id,
                    pbs: Data.original.pbs,
                    allocation_id: Data.original.id,
                    allocation: Data.original.allocation,
                  };
                });

                // console.log('analysis data', temp, rows);
                const ids = rows.map((Data) => {
                  return {
                    parentId: Data.original.parentRow_id,
                    allocationId: Data.original.id,
                    owning_id: Data.original.owning_id,
                  };
                });

                onChangeModalData((prev) => {
                  return { ...prev, Analysis: { open: true, requirements: temp, keys: ids } };
                });
              }}
              variant="contained"
            >
              Analysis
            </ButtonMUI>

            <ButtonMUI
              // color="secondary"
              startIcon={<GavelIcon />}
              disabled={checkAssginStatus(table) || !favouriteToggle}
              sx={{ backgroundColor: 'green' }}
              onClick={() => {
                const { rows } = table.getSelectedRowModel();
                const ids = rows.map((Data) => {
                  return {
                    parentId: Data.original.parentRow_id,
                    allocationId: Data.original.id,
                    owning_id: Data.original.owning_id,
                    allocation: Data.original.allocation,
                  };
                });

                handleAllCompliant(ids);
              }}
              variant="contained"
            >
              All Compliant
            </ButtonMUI>

            {/* <GlobalFilterTextField table={table} /> */}
          </Box>
        );
      },

      renderToolbarInternalActions: ({ table }) => {
        return (
          <>
            <Box style={{ display: 'flex' }}>
              {/* <GlobalFilterTextField table={table} /> */}
              <Toogle onChangeToggle={onChangeToggle} toggleTable={toggleTable} />
              <Refresh refreshDATA={refreshDATA} />
              <FormControl
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={favouriteToggle}
                    onChange={(event) => {
                      setFavouriteToggle(event.target.checked);
                    }}
                  />
                }
                label="Favourite"
              />
              {/* <IconButton
                onClick={() => {
                  setFavouriteToggle((prev) => !prev);
                }}
              >
                <GradeIcon sx={{ color: favouriteToggle ? 'green' : '' }} />
              </IconButton> */}
              <ShowHideColumnsButton table={table} />
              {/* <ShowHideColumnsButton table={table} /> */}
              <ToggleDensePaddingButton table={table} />
              {/* <ToggleFullScreenButton table={table} /> */}
              <FullScreenToogle
                isFullScreen={isFullScreen}
                onChangeFullScreen={onChangeFullScreen}
              />
            </Box>
          </>
        );
      },
      icons: IconsForTable,
    }),
    [data, columns, rowSelection, rows, favouriteToggle, toggleTable, allCompliantLoading]
  );

  return analysisObject;
};

export default useAnalysis;
