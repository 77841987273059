import { AnalysisData } from 'OwningType';
import axios from './axios';
import { BACKEND_URL } from '../const';

export interface KpiInput {
  project_id: Number;
  workload_id?: Number;
  user_id?: Number;
  file_name?: string;
  file_id?: Number;
}

const createAnalysis = async (body: AnalysisData[]): Promise<any[]> => {
  try {
    const response = await axios.put(`${BACKEND_URL.root}/analysis`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

type getKPIpayload = {
  group_by_user: {
    completed: number;
    not_started: number;
    started: number;
  };
  timeseries_user: {
    name: string;
    started: number;
    not_started: number;
    completed: number;
  }[];
  group_by_all: {
    completed: number;
    not_started: number;
    started: number;
  };
  timeseries_all: {
    name: string;
    started: number;
    not_started: number;
    completed: number;
  }[];
};

// timeseries
const getTimseries = async (
  kpiInput: KpiInput,
  datatype: 'lifecycle' | 'compliance'
): Promise<getKPIpayload> => {
  try {
    let querystring = `project_id=${kpiInput.project_id}`;
    if (kpiInput.workload_id) querystring = `${querystring}&workload_id=${kpiInput.workload_id}`;
    if (kpiInput.file_name) querystring = `${querystring}&filename=${kpiInput.file_name}`;
    if (kpiInput.file_id) querystring = `${querystring}&file_id=${kpiInput.file_id}`;
    if (kpiInput.user_id) querystring = `${querystring}&user_id=${kpiInput.user_id}`;
    const response = await axios.get(
      `${BACKEND_URL.root}/kpi/timeseries/${datatype}?${querystring}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export { createAnalysis, getTimseries };
