import { Box, Dialog, Grid, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import React, { useEffect, useRef, useState } from 'react';
import { LifeCycleData } from 'OwningType';
import useAzureToken from '../../../../../app/useAzureToken';
import formatDate, { formatLocal, fromDate } from '../../../../../tools/formatDate';
import useAppToken from '../../../../../app/useAppToken';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  pdfPanel: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    margin: 5,
    padding: 5,
    minHeight: 100,
  },
  dialogContent: {
    backgroundColor: '#FBFBFB',
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
}));

type Props = {
  requirement: any;
  users: any[];
  onClose: any;
  onSend: any;
  onSendResolve: any;
  projectId: number;
  workloadId: number;
  filename: string;
  targetColumn?: string;
};

export default function ModalClarification({
  projectId,
  workloadId,
  filename,
  requirement,
  users,
  onClose,
  onSend,
  onSendResolve,
  targetColumn,
}: Props) {
  const classes = useStyles();
  const queryRef = useRef(null);
  const [replyLifecycles, setReplyLifecycles] = useState<any[]>([]);
  const [message, setMessage] = useState('');
  const { jwt: azJWT } = useAzureToken();
  const { appJWT } = useAppToken();
  const username = azJWT?.idTokenClaims.name || azJWT?.name;

  useEffect(() => {
    if (requirement.lifecycles?.length > 0) {
      let templifecycles = [...requirement.lifecycles].sort((a, b) => (a.id > b.id ? 1 : -1));
      templifecycles = templifecycles.filter((lc) => lc.action === 'ask' || lc.action === 'reply');
      if (templifecycles.length > 0) setReplyLifecycles(templifecycles);
    }
  }, [requirement]);

  const handleClose = () => {
    onClose();
  };

  const handleSendQuery = () => {
    const tmp = message.trim();
    if (tmp.length === 0) return;
    onSend([
      {
        project_id: projectId,
        workload_id: Number(workloadId),
        filename,
        owning_id: requirement.id,
        user_id: appJWT.user_id, // TODO should be receivers ID
        content: message,
        action: replyLifecycles.length > 0 ? 'reply' : 'ask',
      } as LifeCycleData,
    ]).then((p) => {
      onSendResolve(p);
      onClose();
    });
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          Clarification
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.pdfPanel}>
            <div>{targetColumn ? requirement[targetColumn] : requirement.Text}</div>
          </Box>
          {replyLifecycles.length > 0 && (
            <div style={{ padding: 5, minHeight: 150, fontSize: 13 }}>
              {replyLifecycles.map((lc, index) => (
                <div key={`lc-clarify-${index}`}>
                  <Grid container direction="row" alignItems="center">
                    <AccountCircleOutlinedIcon fontSize="large" />
                    &nbsp;{users.find((user) => user.id === lc.user_id).name} &nbsp;
                    {formatDate(lc.created_at, formatLocal())}, &nbsp;{fromDate(lc.created_at)}{' '}
                    &nbsp;
                    <b>{lc.content}</b>
                  </Grid>
                </div>
              ))}
            </div>
          )}

          {requirement.lifecycle !== 'accept' && requirement.lifecycle !== 'reject' && (
            <div style={{ padding: 5, fontSize: 16 }}>
              <Grid container direction="row" alignItems="center">
                <AccountCircleOutlinedIcon color="error" fontSize="large" />
                &nbsp;
                {username}
              </Grid>
              &nbsp;
              <span>
                <TextField
                  variant="outlined"
                  placeholder="Write your message here"
                  fullWidth
                  ref={queryRef}
                  onChange={(e) => {
                    setMessage(e.currentTarget.value);
                  }}
                />
              </span>
            </div>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogContent}>
          <Button autoFocus onClick={handleClose} color="default" variant="contained">
            Cancel
          </Button>
          {requirement.lifecycle !== 'accept' && requirement.lifecycle !== 'reject' && (
            <Button autoFocus onClick={handleSendQuery} color="primary" variant="contained">
              Send
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
