import { Paper } from '@mui/material';
import styled, { css } from 'styled-components';

// MyObjectives.tsx file css
// Container for the top-level tree structure
const TreeMyObjectives = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;

  & > ul {
    border-top: none; // Ensure there is no top border on the first ul
    padding: 0;
    margin: 0;
  }

  // Target the root's line
  & .r-tree-node {
    &:first-child {
      &::before {
        display: none; // Hide the top line before the root
      }
    }
  }
`;

// Style for each node in the tree
const NodeMyObjectives = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

// Box for displaying objectives
const BoxMyObjectives = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff; // White background
  color: #002a45; // Blue text
  padding: 14px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 270px; // Increased width
  border: 2px solid #002a45; // Blue border
`;

// Style for updated nodes with a left border
const UpdatedMyObjectives = styled(BoxMyObjectives)`
  &::before {
    content: "''"; // Required to make the pseudo-element work
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 8px; // Width of the left border
    background-color: green; // Color of the left border
    border-top-left-radius: 4px; // Match the node's border radius
    border-bottom-left-radius: 4px; // Match the node's border radius
  }
`;

// Styled button for submitting objectives
const SubmitButtonMyObjectives = styled.button`
  background-color: #002a45 !important; // Blue color for the button
  color: #ffffff !important; // White text color
  margin-top: 20px; // Adding some space above the button
  position: relative; // Set positioning context
  left: 50%; // Position the button
  transform: translateX(-50%); // Center the button horizontally
  height: 40px; // Increased height
  width: 250px; // Increased width
  cursor: pointer; // Show pointer on hover
  font-size: 16px; // Increased font size
`;

// Background container for button
const ButtonBackgroundMyObjectives = styled.div`
  margin-top: 20px; // Space between the tree and the button
  width: 100%;
  background-color: #eef1f9;
  height: 80px; // Increased height
`;

// Container for messages
const MessageContainerMyObjectives = styled.div`
  width: max-content;
  text-align: center;
  margin-top: 20px;
  padding: 8px;
  border: 1px solid #002a45;
  border-radius: 4px;
`;

// Employees.tsx file css
const ModalEmployee = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 8px;
  margin: 20px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

// Paper component for the modal
const ModalPaperEmployee = styled(Paper)`
  padding: 20px;
  max-width: 600px;
  max-height: 80vh;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: large;
  overflow-y: auto; // Enable vertical scrolling if content exceeds height
`;

// jobdailog.tsx file css
// Container for the Job Dialog
const ContainerJobDialog = styled.div`
  display: flex;
  align-items: center; // Align icon and text vertically
`;

// Icon styling within Job Dialog
const IconJobDialog = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #002a45; // Icon background color
  margin-right: 10px; // Space between icon and text
`;

// Container for text within the Job Dialog
const TextContainerJobDialog = styled.div`
  display: flex;
  flex-direction: column; // Stack name and job title vertically
`;

// Styling for the name within Job Dialog
const NameJobDialog = styled.span`
  font-weight: bold;
  color: #002a45; // Desired text color for the name
`;

// Styling for the job title within Job Dialog
const JobTitleJobDialog = styled.span`
  color: #666; // Gray color for job title
`;

// Styling for labels within the Job Dialog
const LabelJobDialog = styled.label`
  font-weight: bold;
  color: #002a45; // Desired color for the label
  min-width: 120px; // Minimum width for the label
  flex-shrink: 0; // Prevent label from shrinking
  margin-right: 10px; // Space between label and text field
`;

// Container for aligning label and input within Job Dialog
const AlignLabelAndInputJobDialog = styled.div`
  display: flex;
  align-items: center; // Align label and input vertically
`;

// Objectivestree.tsx file css
// Container for the object tree
const ContainerObjectTree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Box for the object tree
const BoxObjectTree = styled(Paper)`
  width: 300px;
  height: 200px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid #000;
  border-radius: 5px;
  overflow: hidden;
`;

// Header for the object tree
const HeaderObjectTree = styled.div`
  width: 100%;
  padding: 8px;
  color: white; // Text color for header
  text-align: center;
`;

// Content area within the object tree
const ContentObjectTree = styled.div`
  flex-grow: 1; // Ensures content fills remaining space
  background-color: #f5f5f5; // Background color for objectives
`;

// Objectives text in the object tree
const ObjectivesObjectTree = styled.div`
  font-size: 14px;
  white-space: pre-line; // Preserves new lines
  text-align: start;
  cursor: pointer;
`;

// Modal container for the object tree
const ModalObjectTree = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 8px;
  margin: 20px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

// Paper component for the modal
const ModalPaperObjectTree = styled(Paper)`
  padding: 20px;
  max-width: 600px;
  max-height: 80vh;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #002a45;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: large;
  overflow-y: auto; // Enable vertical scrolling if content exceeds height
`;

// Text for objectives in the object tree
const TextObjectiveObjectTree = styled.div`
  background-color: #002a45;
  width: 100%;
  text-align: center;
  color: white;
`;

// Orgawareness.tsx file css
// Container for organizational awareness
const ContainerOrgAwareness = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #002a45;
  border-radius: 8px;
  margin: 8px;
  height: 100%;
  width: 100%;
`;

// Go back button styling
const GoBackButtonOrgAwareness = styled.button`
  margin: 8px;
`;

// Header for organizational awareness
const HeaderOrgAwareness = styled.div`
  color: #ffffff;
  font-weight: bold;
  background-color: #002a45;
  width: 100%;
  border-radius: 8px 8px 0 0;
  text-align: center;
`;

// Custom node styling
const CustomNodeOrgAwareness = styled.div`
  padding: 10px;
  text-align: center;
`;

// Tooltip Row styling
const TooltipRowOrgAwareness = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

// Key styling within tooltip
const KeyOrgAwareness = styled.span`
  font-weight: bold;
  font-size: 18px;
  flex-basis: 30%;
`;

// Value styling within tooltip
const ValueOrgAwareness = styled.span`
  text-align: left;
  color: #658da7;
  font-size: 18px;
  flex-basis: 70%;
`;

// MyOrgChart.tsx file css
// Container for the organizational chart
const ContainerMyOrgChart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #002a45;
  border-radius: 8px;
  margin: 8px;
  height: 100%;
  width: 100%;
`;

// Go back button styling
const GoBackButtonMyOrgChart = styled.button`
  margin: 8px;
`;

// Header for the organizational chart
const HeaderMyOrgChart = styled.div`
  color: #ffffff;
  font-weight: bold;
  background-color: #002a45;
  width: 100%;
  border-radius: 8px 8px 0 0;
  text-align: center;
`;

// Custom node styling
const CustomNodeMyOrgChart = styled.div`
  padding: 10px;
  text-align: center;
`;

// Button styling within the organizational chart
const ButtonMyOrgChart = styled.button`
  margin-top: 8px; // Adds space between the name and the button
`;

// Modal container for the organizational chart
const ModalMyOrgChart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 8px;
  margin: 20px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

// Paper component for the modal
const ModalPaperMyOrgChart = styled.div`
  padding: 20px;
  max-width: 600px;
  max-height: 80vh;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: large;
  overflow-y: auto; // Enable vertical scrolling if content exceeds height
`;

export {
  TreeMyObjectives,
  NodeMyObjectives,
  UpdatedMyObjectives,
  BoxMyObjectives,
  SubmitButtonMyObjectives,
  ButtonBackgroundMyObjectives,
  MessageContainerMyObjectives,
  ModalEmployee,
  ModalPaperEmployee,
  ContainerJobDialog,
  IconJobDialog,
  TextContainerJobDialog,
  NameJobDialog,
  JobTitleJobDialog,
  LabelJobDialog,
  AlignLabelAndInputJobDialog,
  ContainerObjectTree,
  BoxObjectTree,
  HeaderObjectTree,
  ContentObjectTree,
  ObjectivesObjectTree,
  ModalObjectTree,
  ModalPaperObjectTree,
  TextObjectiveObjectTree,
  ContainerOrgAwareness,
  GoBackButtonOrgAwareness,
  HeaderOrgAwareness,
  CustomNodeOrgAwareness,
  TooltipRowOrgAwareness,
  KeyOrgAwareness,
  ValueOrgAwareness,
  ContainerMyOrgChart,
  GoBackButtonMyOrgChart,
  HeaderMyOrgChart,
  CustomNodeMyOrgChart,
  ButtonMyOrgChart,
  ModalMyOrgChart,
  ModalPaperMyOrgChart,
};
