import React, { useMemo, useState } from 'react';
import { Button, Chip, FormControl, MenuItem, Select, Typography } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import HoverToolTipForText from '../../Modal/HoverToolTipForText';
import { extractABS, extractOBS, extractUserName, extractUsersList } from '../../utils/extractdata';
import { charScoreColor, lifeCycle } from '../../const/allocatorConst';
import hiddenColumns from '../../const/allData';

const PBSCustomInput = ({ onBlur, Options, value: currentValue, disabled }) => {
  const [value, setValue] = useState(currentValue);

  const onChange = (event) => {
    setValue(event.target.value);
    onBlur(event);
  };

  return (
    <>
      <FormControl variant="standard" sx={{ m: 0, minWidth: 120 }}>
        {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={value}
          onChange={onChange}
          disabled={disabled}
          // label="Age"
        >
          {Options?.map((data: any, index: any) => {
            return (
              <MenuItem key={index} value={data}>
                {data}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

const CustomInput = ({ onBlur, Options, disabled }) => {
  const obj = useMemo(() => {
    const val = Options.filter((data) => {
      return data.select === true;
    });
    return val[0] ?? { value: '' };
  }, [Options]);

  const changeValue = (event: any) => {
    onBlur(event);
  };

  return (
    <>
      <FormControl variant="standard" sx={{ m: 0, minWidth: 120 }}>
        {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={!obj.value ? '' : obj.value}
          onChange={onBlur}
          disabled={disabled}
          // label="Age"
        >
          {Options?.map((data: any, index: any) => {
            return (
              <MenuItem key={index} value={data.value}>
                {data?.value?.split('_')[0] || ''}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

const useRequirementColumn = (props) => {
  const {
    data,
    targetColumn,
    setEditedUsers,
    favouriteToggle,
    editedUsers,
    List_of_pbs,
    displayColumns,
    levelsHierarchy,
    type,
    onChangeModalData,
    herarchySelection,
    groups,
    users,
  } = props;

  const textColum = targetColumn ?? 'Text';
  const keysForMetaData = [...Object.keys(hiddenColumns), ...levelsHierarchy];

  const extraColumns = useMemo(() => {
    if (data.length === 0) {
      return [];
    }

    const addColumnExcel = displayColumns ?? [];
    // console.log('keysForMetaData', keysForMetaData);

    const filterdcol = [...keysForMetaData.filter((data) => data !== 'id' && data !== 'Hierarchy')];

    // console.log('filterdcol', filterdcol);

    return filterdcol.map((item) => {
      return {
        header: item,
        accessorKey: item,
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Typography color="info" style={{ visibility: 'hidden' }}>
              {renderedCellValue}
            </Typography>
          );
        },

        AggregatedCell: ({ cell, id, row }) => {
          return <Typography color="info">{row.original[item]}</Typography>;
          // return <div>Team Score: {row.original.Char_Score}</div>;
        },
      };
    });
  }, [data, levelsHierarchy]);

  const extractDisplayColumns = useMemo(() => {
    const temp = keysForMetaData.filter((data) => data !== 'Hierarchy');
    if (displayColumns) {
      return displayColumns
        .filter((data) => data !== textColum && !temp.includes(data))
        .map((item) => {
          return {
            header: item,
            accessorKey: item === 'Hierarchy' ? 'treeHierarchy' : item,
            enableGrouping: false,
            enableEditing: false,
            grow: true,
            size: item.length > 16 ? 500 : 100,
            Cell: ({ renderedCellValue, row }) => {
              return (
                <Typography color="info" style={{ visibility: 'hidden' }}>
                  {renderedCellValue}
                </Typography>
              );
            },

            AggregatedCell: ({ cell, id, row }) => {
              return <Typography color="info">{row.original[item]}</Typography>;
              // return <div>Team Score: {row.original.Char_Score}</div>;
            },
          };
        });
    }
    return [
      {
        header: 'Hierarchy',
        accessorKey: 'treeHierarchy',
        enableEditing: false,
        grow: true,
        enableGrouping: false,
        size: 100,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Typography color="info" style={{ visibility: 'hidden' }}>
              {renderedCellValue}
            </Typography>
          );
        },

        AggregatedCell: ({ cell, id, row }) => {
          return <Typography color="info">{row.original.treeHierarchy}</Typography>;
          // return <div>Team Score: {row.original.Char_Score}</div>;
        },
      },
    ];
  }, [displayColumns]);

  const columns = useMemo(
    () => [
      {
        header: textColum,
        accessorKey: 'requirementText',
        enableGrouping: false,
        grow: true,
        size: 550,
        GroupedCell: ({ cell }) => {
          const splitArray = cell?.getValue()?.split('__*|*');
          const text = splitArray.length > 0 ? splitArray[1] : '';
          return (
            <>
              <HoverToolTipForText renderedCellValue={text}>
                <Typography color="info">{text}</Typography>
              </HoverToolTipForText>
            </>
          );
        },
        // filterFn: (row, id, filterValue) => {
        //   console.log('row', row);
        //   console.log('filterValue', filterValue);
        //   return false;
        // },
      },
      ...extractDisplayColumns,
      // {
      //   header: 'Hierarchy',
      //   accessorKey: 'Hierarchy',
      //   enableEditing: false,
      //   grow: true,
      //   enableGrouping: false,
      //   size: 130,
      //   Cell: ({ renderedCellValue, row }) => {
      //     return (
      //       <Typography color="info" style={{ visibility: 'hidden' }}>
      //         {renderedCellValue}
      //       </Typography>
      //     );
      //   },

      //   AggregatedCell: ({ cell, id, row }) => {
      //     return <Typography color="info">{row.original.Hierarchy}</Typography>;
      //     // return <div>Team Score: {row.original.Char_Score}</div>;
      //   },
      // },
      {
        header: 'iSenS_ID',
        accessorKey: 'owning_id',
        enableEditing: false,
        grow: true,
        enableGrouping: false,
        size: 130,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Typography color="info" style={{ visibility: 'hidden' }}>
              {renderedCellValue}
            </Typography>
          );
        },

        AggregatedCell: ({ cell, id, row }) => {
          return <Typography color="info">{row.original.owning_id}</Typography>;
          // return <div>Team Score: {row.original.Char_Score}</div>;
        },
      },

      {
        header: 'Life Cycle',
        accessorKey: 'lifecycle',
        enableEditing: false,
        size: 150,
        enableGrouping: false,
        Cell: ({ renderedCellValue, row }) => (
          <Chip
            sx={{ width: 100, backgroundColor: lifeCycle[renderedCellValue], color: 'white' }}
            label={renderedCellValue}
          />
        ),
        // aggregationFn: 'max', //show the max age in the group (lots of pre-built aggregationFns to choose from)
        // required to render an aggregated cell
      },

      {
        header: 'PBS',
        accessorKey: 'pbs',
        editVariant: 'select',
        grow: true,
        size: 200,
        enableGrouping: false,
        Edit: ({ cell, column, row, table }) => {
          const {
            absOptions,
            receiverOptions,
            pbs,
            obs,
            Text,
            copyHerarchy,
            treeHierarchy: Hierarchy,
          } = row._valuesCache || [];

          const onBlur = (event) => {
            // eslint-disable-next-line no-param-reassign
            const abs = extractABS(event.target.value, groups) || '';

            const statusChange = abs.map((data, index) => {
              return {
                select: index === 0,
                value: data,
              };
            });

            // eslint-disable-next-line no-param-reassign
            row._valuesCache.absOptions = statusChange;

            const obs = extractOBS(event.target.value, abs[0] || '', groups) || '';
            // eslint-disable-next-line no-param-reassign
            row._valuesCache.obs = obs || '';
            // eslint-disable-next-line no-param-reassign

            const tempObj = {
              ...row.original,
              pbs: event.target.value,
            };

            const receiver = extractUsersList(tempObj, abs[0], obs, groups, users) as any;

            const checkReceiver = receiver.map((data, index) => {
              return {
                select: index === 0,
                value: data,
              };
            });

            // eslint-disable-next-line no-param-reassign
            row._valuesCache.receiverOptions = checkReceiver;

            // eslint-disable-next-line no-param-reassign
            row._valuesCache.pbs = event.target.value;
            const herarchy = !Hierarchy ? 'none' : Hierarchy;
            if (favouriteToggle && herarchy !== 'none') {
              herarchySelection(herarchy, {
                pbs: event.target.value,
                abs: abs.length > 0 ? abs[0] : '',
                obs,
                absOptions: statusChange,
                receiver: receiver.length > 0 ? receiver[0] : '',
                receiverOptions: checkReceiver ?? [],
              });
            } else {
              table.setEditingRow(row);
              setEditedUsers({
                ...editedUsers,
                [row.id]: {
                  ...row._valuesCache,
                  pbs: event.target.value,
                  abs: abs.length > 0 ? abs[0] : '',
                  obs,
                  row_id: row.original.row_id,
                  allocationId: row.original.id,
                  absOptions: statusChange,
                  receiver: receiver.length > 0 ? receiver[0] : '',
                  receiverOptions: checkReceiver ?? [],
                },
              });
            }
          };

          if (['analyzed'].includes(row.original.lifecycle)) {
            return (
              <>
                <Typography color="info">{row.original.pbs}</Typography>
              </>
            );
          }

          return (
            <PBSCustomInput
              onBlur={onBlur}
              Options={List_of_pbs ?? []}
              value={row?.original?.pbs ?? ''}
              disabled={['analyzed'].includes(row.original.lifecycle)}
              // data={absOptions[0]}
            />
          );
        },
      },
      {
        header: 'ABS',
        accessorKey: 'absOptions',
        enableEditing: true,
        grow: true,
        enableGrouping: false,
        size: 200,
        editVariant: 'select',
        Edit: ({ cell, column, row, table }) => {
          const { absOptions, receiverOptions, pbs, obs, Text } = row._valuesCache || [];

          const onBlur = (event) => {
            const changeStatus = absOptions.map((data, index) => {
              return {
                select: event.target.value === data.value,
                value: data.value,
              };
            });

            // eslint-disable-next-line no-param-reassign
            row._valuesCache.absOptions = changeStatus;
            if (table.isCreating) {
              table.setCreatingRow(row);
            } else if (table.isEditing) {
              table.setEditingRow(row);
            }
            setEditedUsers({
              ...editedUsers,
              [row.id]: {
                ...row._valuesCache,
                abs: event.target.value,
                absOptions: changeStatus,
                row_id: row.original.row_id,
                allocationId: row.original.id,
              },
            });
          };

          if (['analyzed'].includes(row.original.lifecycle)) {
            return (
              <>
                <Typography color="info">{row.original.abs}</Typography>
              </>
            );
          }

          return (
            <CustomInput
              onBlur={onBlur}
              Options={absOptions ?? []}
              disabled={['analyzed'].includes(row.original.lifecycle)}
              // data={absOptions[0]}
            />
          );
        },
        filterFn: (row, id, filterValue) => {
          const temp = row.getValue(id).filter((data) => {
            return data.select === true;
          });

          // console.log('row', row);
          // console.log('filterValue', filterValue);
          return temp[0]?.value?.toLowerCase()?.includes(filterValue?.toLowerCase()) || false;
        },
      },

      {
        header: 'OBS',
        accessorKey: 'obs',
        enableEditing: false,
        grow: true,
        enableGrouping: false,
        size: 200,
        // aggregationFn: 'mean',
        // required to render an aggregated cell, show the average salary in the group
        // customize normal cell render on normal non-aggregated rows
      },
      {
        header: 'Receiver',
        accessorKey: 'receiverOptions',
        enableEditing: true,
        grow: true,
        enableGrouping: false,
        size: 200,
        Edit: ({ cell, column, row, table }) => {
          const { receiverOptions, pbs, absOptions, obs, Text } = row._valuesCache || [];

          // let currentValforABs = null;
          const onBlur = (event) => {
            const changeStatus = receiverOptions.map((data, index) => {
              return {
                select: event.target.value === data.value,
                value: data.value,
              };
            });

            // eslint-disable-next-line no-param-reassign
            row._valuesCache.receiverOptions = changeStatus;

            // currentValforABs = event.target.value;
            if (table.isCreating) {
              table.setCreatingRow(row);
            } else if (table.isEditing) {
              table.setEditingRow(row);
            }

            const abs = absOptions.filter((data) => {
              return data.select === true;
            });

            setEditedUsers({
              ...editedUsers,
              [row.id]: {
                ...row._valuesCache,
                receiverOptions: changeStatus,
                receiver: event.target.value,
                abs: abs.length > 0 ? abs[0].value : '',
                row_id: row.original.row_id,
                allocationId: row.original.id,
              },
            });
          };

          if (['analyzed'].includes(row.original.lifecycle)) {
            return (
              <>
                <Typography color="info">
                  {extractUserName(row?.original?.receiver, 'name')}
                </Typography>
              </>
            );
          }

          return (
            <CustomInput
              onBlur={onBlur}
              Options={receiverOptions ?? []}
              disabled={['analyzed'].includes(row.original.lifecycle)}
              // data={currentValforABs ?? receiverOptions[0]}
            />
          );
        },
        filterFn: (row, id, filterValue) => {
          const temp = row.getValue(id).filter((data) => {
            return data.select === true;
          });

          return temp[0]?.value?.toLowerCase()?.includes(filterValue.toLowerCase()) || false;
        },
      },
      {
        header: 'SubType',
        accessorKey: 'SubType',
        enableGrouping: false,
        enableEditing: true,
        grow: true,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          return <div style={{ visibility: 'hidden' }}>{renderedCellValue}</div>;
        },

        AggregatedCell: ({ cell, id, row }) => {
          return <Typography color="info">{row.original.SubType}</Typography>;
          // return <div>Team Score: {row.original.Char_Score}</div>;
        },
        // return <div>Team Score: {row.original.Char_Score}</div>;
      },

      {
        header: 'History',
        accessorKey: 'History',
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          return <div style={{ visibility: 'hidden' }}>{renderedCellValue}</div>;
        },

        AggregatedCell: ({ cell, id, row }) => {
          if (['Requirement', 'Technical Req'].includes(row?.original?.Characterization)) {
            return (
              <Button
                title="Assigned history"
                onClick={() => {
                  onChangeModalData((prev) => {
                    return {
                      ...prev,
                      allocationHistory: { open: true, requirements: [], keys: row?.original?.id },
                    };
                  });
                }}
              >
                {' '}
                <HistoryIcon />{' '}
              </Button>
            );
          }
          return '';
        },
      },
      {
        header: 'KPI Lifecycle',
        accessorKey: 'kpi_lifecycle',
        enableEditing: false,
        enableGrouping: false,
        grow: true,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          return <div style={{ visibility: 'hidden' }}>{renderedCellValue}</div>;
        },

        AggregatedCell: ({ cell, id, row }) => {
          return (
            <Chip
              sx={{
                width: 100,
                backgroundColor: lifeCycle[row.original.kpi_lifecycle],
                color: 'white',
              }}
              label={row.original.kpi_lifecycle}
            />
          );
          // return <div>Team Score: {row.original.Char_Score}</div>;
        },
      },

      {
        header: 'Char Score',
        accessorKey: 'Char_Score',
        enableEditing: false,
        enableGrouping: false,
        grow: true,
        size: 200,
        Cell: ({ renderedCellValue, row }) => (
          <Chip
            sx={{
              width: 100,
              backgroundColor: charScoreColor[renderedCellValue],
              color: 'white',
              visibility: 'hidden',
            }}
            label={renderedCellValue}
          />
        ),

        AggregatedCell: ({ cell, id, row }) => {
          return (
            <Chip
              sx={{
                width: 100,
                backgroundColor: charScoreColor[row.original.Char_Score],
                color: 'white',
              }}
              label={row.original.Char_Score}
            />
          );
          // return <div>Team Score: {row.original.Char_Score}</div>;
        },
        // aggregationFn: 'max', //show the max age in the group (lots of pre-built aggregationFns to choose from)
        // required to render an aggregated cell
      },
      {
        header: 'Characterization',
        accessorKey: 'Characterization',
        enableEditing: false,
        grow: true,
        enableGrouping: false,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          return <div style={{ visibility: 'hidden' }}>{renderedCellValue}</div>;
        },

        AggregatedCell: ({ cell, id, row }) => {
          return <div>{row.original.Characterization}</div>;
          // return <div>Team Score: {row.original.Char_Score}</div>;
        },
      },
      {
        header: 'Context',
        accessorKey: 'Context',
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          return <div style={{ visibility: 'hidden' }}>{renderedCellValue}</div>;
        },

        AggregatedCell: ({ cell, id, row }) => {
          return <div>{row.original.Context}</div>;
          // return <div>Team Score: {row.original.Char_Score}</div>;
        },
      },
      {
        header: 'Type',
        accessorKey: 'Type',
        enableGrouping: false,
        grow: true,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          return <div style={{ visibility: 'hidden' }}>{renderedCellValue}</div>;
        },

        AggregatedCell: ({ cell, id, row }) => {
          return <div>{row.original.Type}</div>;
          // return <div>Team Score: {row.original.Char_Score}</div>;
        },
      },
    ],
    [editedUsers, textColum, extractDisplayColumns, favouriteToggle, data]
  );

  // console.log('columns', columns);

  const columnsTableInject = useMemo(() => {
    if (type !== 'pdf') {
      const removedCol = columns.filter((item) => item.accessorKey !== 'SubType');
      return [...removedCol, ...extraColumns];
    }

    return [...columns, ...extraColumns];
  }, [columns, extraColumns]);

  // console.log('columnsTableInject', columnsTableInject);

  return { columns: columnsTableInject };
};

export default useRequirementColumn;
