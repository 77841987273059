import { Box, Button, CssBaseline } from '@mui/material';
import { GroupUser } from 'GroupType';
import { AppJwtType } from 'Auth';
import { Link, useParams } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { ThemeProvider } from '@mui/material/styles';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { getOwning } from '../../../../../../../api/ownings';
import Module1Context from '../../../../../Module1Context';
import extractUsers, {
  extractABS,
  extractOBS,
  extractUserName,
  extractUsersList,
  generateUniqueId,
} from '../../utils/extractdata';
import useAllDataTable from '../../Hooks/useAllDataTable';
import useRequirementTable from '../Hooks/useRequirementTable';
import theme from '../../Theme/AssginTheme';
import AllocationExtraction, {
  AllocationsReceiverExtraction,
} from '../../utils/allocationExtraction';
import extractionTextCode from '../../utils/extractionTextCode';
import extractOriginalLang from '../../utils/extractOriginalLang';
import ModalSplit from '../../ModalSplit';
import useMetaDataOperation from '../../Hooks/useMetaDataOperation';
import ModalConfirm from '../../../../../../Admin/components/ModalConfirm';
import ModalEdit from '../../Modal/ModalEdit';
import convertToPairs from '../../utils/convertToPairs';
import useAppToken from '../../../../../../../app/useAppToken';
import ModalAddAssigmentRow from '../../ModalAddAssigmentRow';
import FindSimilarity from '../../Modal/FindSimilarity';
import useLoadResponse from '../../Hooks/useLoadResponse';
import Loading from '../../components/Loading';
import AssignModalKpi from '../../AssignModalKpi';
import useProjectData from '../../Hooks/useProjectData';
import ProjectContext from '../../../../../../Project/ProjectContext';
import MultiAllocator from '../../Modal/MultiAllocator';
import AllocationHistory from '../../Modal/AllocationHistory';

const AssignFlow = ({
  filename,
  workloadId,
  actionForFile,
  setActionForFile,
  originalText,
  setOriginalText,
  levelsHierarchy,
  targetColumn,
  displayColumns,
  langCheck,
  entity,
  dispatch,
  isRegExp,
  languageOriginCode,
  languages,
  onChange,
  urlFile,
}) => {
  const { setSnack, project, translationCode, setTranslationCode, languagesCode } = useContext(
    Module1Context
  );
  const { appJWT }: { appJWT: AppJwtType } = useAppToken();
  const { userJWT, rbsLabels } = useContext(ProjectContext);

  const [metaData, setMetaDAta] = useState({});
  const [toggleTable, setToogleTable] = useState(true);
  const [isFullScreen, setFullScreen] = useState(false);
  const { projectId } = useParams() as any;
  const [i18n, setI18n] = useState();
  const [modalData, setModalDAta] = useState({
    deletData: { open: false, requirements: [], keys: [] },
    splitData: { open: false, requirements: {}, keys: [] },
    editText: { open: false, requirements: [], keys: [] },
    newCapture: { open: false, requirements: [], keys: [] },
    findSimilarity: { open: false, requirements: [], keys: [] },
    kpis: { open: false, requirements: [], keys: [] },
    multiAllocator: { open: false, requirements: [], keys: [] },
    allocationHistory: { open: false, requirements: [], keys: [] },
  });

  // const [userListName, setUserListName] = useState({});

  // console.log('displayColumn', displayColumns);

  const {
    deletData,
    splitData,
    editText,
    newCapture,
    findSimilarity,
    kpis,
    multiAllocator,
    allocationHistory,
  } = modalData;

  const { List_of_pbs, groups, users } = useProjectData({ project });

  const { dataLoading, percentage, refreshDATA, refreshLoading } = useLoadResponse({
    projectId,
    workloadId,
    filename,
    type: 'assignment',
    langCheck,
    languagesCode,
    translationCode,
    setTranslationCode,
    setMetaDAta,
    setI18n,
    targetColumn,
    dispatch,
    setActionForFile,
    actionForFile,
    groups,
    users,
    onChange,
    displayColumns,
  });

  const rows = useMemo(() => {
    const values = Object.values(metaData);
    const AllocationsTemp = [] as any;

    for (const item of values) {
      AllocationExtraction(AllocationsTemp, item, groups, users, targetColumn);
    }
    return AllocationsTemp;
  }, [metaData]);

  const requirementTable = useRequirementTable({
    rows,
    metaData,
    setMetaDAta,
    List_of_pbs,
    refreshLoading,
    groups,
    users,
    toggleTable,
    onChangeToggle: setToogleTable,
    onChangeFullScreen: setFullScreen,
    displayColumns,
    isFullScreen,
    appJWT,
    projectId,
    workloadId,
    filename,
    setNotify: setSnack,
    onChangeModalData: setModalDAta,
    targetColumn,
    refreshDATA,
    levelsHierarchy,
    type: filename.split('.').reverse()[0] ?? '',
  });

  const allDataTable = useAllDataTable({
    rows: metaData,
    toggleTable,
    onChangeToggle: setToogleTable,
    onChangeFullScreen: setFullScreen,
    refreshLoading,
    isFullScreen,
    targetColumn,
    displayColumns,
    projectGroups: [],
    groups,
    users,
    langCheck,
    languagesCode,
    languages,
    translationCode,
    projectId,
    workloadId,
    setMetaDAta,
    transCode: translationCode,
    setTranslationCode,
    onChangeModalData: setModalDAta,
    languageOriginCode,
    i18n,
    setNotify: setSnack,
    levelsHierarchy,
    filename,
    refreshDATA,
    service: 'assignment',
    user_id: userJWT?.user_id,
    urlFile,
  });

  const {
    loading,
    handleSplit,
    handleDeleteRow,
    handelEditedRow,
    handelNewCapture,
  } = useMetaDataOperation({
    targetColumn,
    groups,
    users,
    langCheck,
    languagesCode,
    projectId,
    workloadId,
    onChangeTableData: setMetaDAta,
    transCode: translationCode,
    i18n,
    languages,
    setNotify: setSnack,
    filename,
  });

  const handleSplitSubmit = (formBody) => {
    const { keys } = splitData;
    handleSplit(formBody, keys, 'assignment').then((data) => {
      setModalDAta((prev) => {
        return {
          ...prev,
          splitData: { open: false, requirements: [], keys: [] },
        };
      });
    });
  };

  const handleModalClose = (newState) => {
    setModalDAta((prev) => {
      return {
        ...prev,
        ...newState,
      };
    });
  };

  const handleDeleteModal = async () => {
    const { keys, requirements } = deletData;
    const rows = Object.values(metaData);
    const filteredrow = await handleDeleteRow(keys, 'assignment', rows, requirements, filename);
    setMetaDAta(filteredrow);
    setModalDAta((prev) => {
      return { ...prev, deletData: { open: false, requirements: [], keys: [] } };
    });
  };

  const handleEditModal = async (newRow: any[]) => {
    const rows = Object.values(metaData);
    const newRows = await handelEditedRow(metaData, newRow, editText.keys[0]);
    if (newRows) {
      setMetaDAta(newRows);
      setModalDAta((prev) => {
        return {
          ...prev,
          editText: { open: false, requirements: [], keys: [] },
        };
      });
      setSnack('Row edited successfully', 'success');
    }
  };

  const handleInsertCapture = async (formBody: any, file: any) => {
    const rows = Object.values(metaData);
    const id = Number(newCapture.keys[0] ?? undefined);
    const newRows = await handelNewCapture(formBody, file, rows, 'assignment', id);
    setMetaDAta(newRows);
    setModalDAta((prev) => {
      return {
        ...prev,
        newCapture: { open: false, requirements: [], keys: [] },
      };
    });
  };

  const handleClsoeMultiAllocator = () => {
    setModalDAta((prev) => {
      return {
        ...prev,
        multiAllocator: { open: false, requirements: [], keys: [] },
      };
    });
  };

  const handleCloseAllocationHistory = () => {
    setModalDAta((prev) => {
      return {
        ...prev,
        allocationHistory: { open: false, requirements: [], keys: [] },
      };
    });
  };

  const renderTable = () => {
    return toggleTable ? (
      <MaterialReactTable table={{ ...allDataTable }} />
    ) : (
      <MaterialReactTable table={{ ...requirementTable }} />
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div
        style={
          isFullScreen
            ? {
                bottom: 0,
                height: '100vh',
                left: 0,
                margin: 0,
                maxHeight: '100vh',
                maxWidth: '100vw',
                padding: 0,
                position: 'fixed',
                right: 0,
                top: 0,
                width: '100vw',
                zIndex: 1299,
                backgroundColor: 'white',
              }
            : {}
        }
      >
        {dataLoading ? (
          <div style={{ padding: '0px 5px', backgroundColor: '#EEF1F9' }}>{renderTable()}</div>
        ) : (
          <Loading percentage={percentage} />
        )}
        {splitData.open && (
          <ModalSplit
            requirement={splitData.requirements}
            onClose={() => {
              handleModalClose({ splitData: { open: false, requirements: {}, keys: [] } });
            }}
            onSubmit={handleSplitSubmit}
            targetColumn={targetColumn}
          />
        )}
        {deletData.open && (
          <ModalConfirm
            open={deletData.open}
            onConfirm={handleDeleteModal}
            onCancel={() => {
              handleModalClose({ deletData: { open: false, requirements: [], keys: [] } });
            }}
            label="isDeleteRow"
          />
        )}
        {editText.open && (
          <ModalEdit
            requirement={editText.requirements}
            open={editText.open}
            onConfirm={handleEditModal}
            handleClose={() => {
              handleModalClose({ editText: { open: false, requirements: [], keys: [] } });
            }}
            targetColumn={targetColumn}
            translationCode={translationCode}
            i18n={i18n}
            langCheck={langCheck}
            languagesCode={languagesCode}
          />
        )}
        {newCapture.open && (
          <ModalAddAssigmentRow
            onClose={() => {
              handleModalClose({ newCapture: { open: false, requirements: [], keys: [] } });
            }}
            onSubmit={handleInsertCapture}
            targetColumn={targetColumn}
          />
        )}
        {findSimilarity.open && (
          <FindSimilarity
            open={findSimilarity.open}
            onClose={() => {
              handleModalClose({ findSimilarity: { open: false, requirements: [], keys: [] } });
            }}
            rowsSim={findSimilarity.requirements}
            targetColumn={targetColumn}
          />
        )}

        {kpis.open && (
          <AssignModalKpi
            project_id={projectId}
            workload_id={workloadId}
            file_name={filename}
            // user_id={userJWT.user_id}
            title={filename}
            onClose={() => {
              handleModalClose({ kpis: { open: false, requirements: [], keys: [] } });
            }}
          />
        )}

        {multiAllocator.open && (
          <MultiAllocator
            open={multiAllocator.open}
            requirements={multiAllocator.requirements}
            keys={multiAllocator.keys}
            metaData={metaData}
            setMetaData={setMetaDAta}
            groups={groups}
            users={users}
            List_of_pbs={List_of_pbs}
            handleClose={handleClsoeMultiAllocator}
            user_id={userJWT?.user_id}
            projectId={projectId}
            workloadId={workloadId}
            filename={filename}
          />
        )}

        {allocationHistory.open && (
          <AllocationHistory
            handleClose={handleCloseAllocationHistory}
            open={allocationHistory.open}
            project_id={projectId}
            workload_id={workloadId}
            rows_allocation={allocationHistory.keys}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

export default AssignFlow;
