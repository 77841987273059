import { Box, CircularProgress, Dialog, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formInput: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginBottom: 5,
  },
  dialogContent: {
    backgroundColor: '#FBFBFB',
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
}));

type Props = {
  onClose?: any;
  onSubmit?: any;
  datatype: 'nc' | 'gap';
};

export default function ModalGapNcRow({ onClose, onSubmit, datatype }: Props) {
  const classes = useStyles();
  // reducer for tab authorization

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({});

  const handleClose = () => {
    reset();
    setLoading(false);
    if (onClose) onClose();
  };

  const handleNewRow = (data) => {
    // console.log(data);
    if (onSubmit) onSubmit(data);
    setLoading(true);
  };

  const title = datatype === 'gap' ? 'Add new GAP' : 'Add new Non conformity';
  const inputLabel = datatype === 'gap' ? 'GAP' : 'Non conformity';

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          {title}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form>
            <Box className={classes.formInput}>
              <TextField
                {...register('name', { required: true })}
                fullWidth
                variant="outlined"
                label={inputLabel}
                placeholder={inputLabel}
              />
            </Box>
            <Box className={classes.formInput}>
              <TextField
                {...register('rationale', { required: true })}
                fullWidth
                variant="outlined"
                label="Rationale"
                placeholder="Rationale"
              />
            </Box>
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogContent}>
          <Button autoFocus onClick={handleClose} color="default" variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(handleNewRow)}
            color="primary"
            variant="contained"
            endIcon={loading && <CircularProgress size={20} color="secondary" />}
          >
            {title}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
