export const csvInitialState = {
  fileType: 'CSV',
  columnName: '',
  filename: '',
  columnNames: '',
};

const csvFileReducer = (state, action) => {
  switch (action.type) {
    case 'change_columnName': {
      return {
        ...state,
        columnName: action.payload.columnName,
        filename: action.payload.filename,
        columnNames: action.payload.columnNames,
      };
    }

    default:
      return state;
  }
};

export default csvFileReducer;
