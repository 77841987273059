import React, { useContext } from 'react';
import Activities from './Components/Activities';
import FileSettingsContainer from './core/components/FileSettingsContainer';
import UploadContainer from './core/components/UplaodContainer';
import TabContainer from './core/TabContainer';
import RexAnalysisContext from './RexAnalysisContext';

const REXBook = () => {
  const { toggleTab, setToggleTab, workLoad } = useContext(RexAnalysisContext);
  const handleToggleComponent = (Rem: any) => {
    switch (Rem) {
      case '0rem':
        return <UploadContainer />;
      case '5rem':
        return <FileSettingsContainer serivceName="RexBook" />;
      case '10rem':
        return <Activities workLoad={workLoad} />;
      default:
        return null;
    }
  };
  return <TabContainer>{handleToggleComponent(toggleTab.left)}</TabContainer>;
};

export default REXBook;
