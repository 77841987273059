const textTanslatorreducer = (state, action) => {
  switch (action.type) {
    case 'text_change': {
      return {
        ...state,
        text: action.payload,
      };
    }

    case 'Change_mode_serive': {
      return {
        ...state,
        mode: action.payload,
      };
    }
    case 'destText_change': {
      return {
        ...state,
        destText: action.payload,
      };
    }
    case 'sourceLang_change': {
      return {
        ...state,
        sourceLanguage: action.payload,
      };
    }

    case 'destinationLang_change': {
      return {
        ...state,
        destinationLanguage: action.payload,
      };
    }
    case 'clear_text': {
      return {
        ...state,
        text: action.payload,
        destText: action.payload,
      };
    }

    case 'swap_data': {
      return {
        ...state,
        text: state.destText,
        destText: state.text,
        sourceLanguage: state.destinationLanguage,
        destinationLanguage: state.sourceLanguage,
      };
    }

    case 'reset_state': {
      return {
        ...state,
        text: action.payload,
        destText: action.payload,
        sourceLanguage: action.payload,
        destinationLanguage: action.payload,
      };
    }

    case 'click_card_update': {
      return {
        ...state,
        text: action.payload.text,
        destText: action.payload.destText,
        sourceLanguage: action.payload.sourceLanguage,
        destinationLanguage: action.payload.destinationLanguage,
      };
    }

    case 'update_recent_history': {
      return {
        ...state,
        textTranslationHistory: Array.isArray(action.payload)
          ? [...action.payload]
          : [...state.textTranslationHistory, action.payload],
      };
    }

    case 'add_recent_history': {
      return {
        ...state,
        textTranslationHistory: Array.isArray(action.payload)
          ? [...state.textTranslationHistory, ...action.payload]
          : [action.payload, ...state.textTranslationHistory],
      };
    }

    case 'remove_recent_history': {
      return {
        ...state,
        textTranslationHistory: state.textTranslationHistory.filter(
          (_data: { id: any }) => _data.id !== action.payload
        ),
      };
    }
    default:
      return state;
  }
};

export default textTanslatorreducer;
