import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { TooltipProps } from '@mui/material/Tooltip';
import Button, { ButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#002A45',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const FormControl = styled((props) => <FormControlLabel {...props} />)(({ theme }) => ({
  color: '#002A45',
  margin: 0,

  '& .MuiFormControlLabel-label': {},
  '& .MuiTypography-root': {
    fontSize: '1rem',
    fontWeight: '1000',
  },
}));

const ButtonMUI = styled((props) => <Button {...props} />)(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  // backgroundColor: '#0063cc',
  // borderColor: '#0063cc',
  // fontFamily: [
  //   '-apple-system',
  //   'BlinkMacSystemFont',
  //   '"Segoe UI"',
  //   'Roboto',
  //   '"Helvetica Neue"',
  //   'Arial',
  //   'sans-serif',
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(','),
  // '&:hover': {
  //   backgroundColor: '#0069d9',
  //   borderColor: '#0062cc',
  //   boxShadow: 'none',
  // },
  // '&:active': {
  //   boxShadow: 'none',
  //   backgroundColor: '#0062cc',
  //   borderColor: '#005cbf',
  // },
  // '&:focus': {
  //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  // },
}));

const ToogleContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  // position: 'relative',
  border: '1px solid #002a45',
  // backgroundColor: '#002a45',
  borderRadius: '30px',
  // boxShadow: '0px 4px 8px #00000029',
}));

const ButtonMUIToggle = styled((props) => <Button {...props} />)(({ theme }) => {
  return {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    borderRadius: '30px',
    padding: '6px 15px',
    cursor: 'pointer',
    width: '160px',
    height: '100%',
    // lineHeight: 1.5,
    // transition: 'color 0.5s',
    border: 0,
    outline: 'none',
    position: 'relative',
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    textAlign: 'center',
  };
});

const floatBtn = styled((props) => <Box {...props} />)(({ theme }) => ({
  left: 0,
  top: 0,
  position: 'absolute',
  width: '100px',
  height: '100%',
  backgroundColor: '#002a45',
  borderRadius: '30px',
  transition: '0.5s',
}));

export const getCommonTooltipProps = (
  placement?: TooltipProps['placement']
): Partial<TooltipProps> => ({
  disableInteractive: true,
  enterDelay: 1000,
  enterNextDelay: 1000,
  placement,
});

// const ButtonMUI = styled(Button)({

// });

export { IOSSwitch, ButtonMUI, FormControl, ToogleContainer, ButtonMUIToggle, floatBtn };
