/* eslint-disable eqeqeq */
import { Button, CssBaseline, Theme } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import InboxIcon from '@mui/icons-material/Inbox';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import WorkIcon from '@mui/icons-material/Work';
import BadgeIcon from '@mui/icons-material/Badge';
import { Breadcrumbs, Tooltip } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Link as LinkTo, useHistory, useParams } from 'react-router-dom';
import { drawerWidth } from '../../../app/isensTheme';
import useAppToken from '../../../app/useAppToken';
import { Project } from '../../../react-app-env';
import ModalFolderCreate from './ModalFolderCreate';
import ModalProjectCreate from './ModalProjectCreate';
import ProjectContext from '../ProjectContext';
import { ENV } from '../../../const';
import { getProjectbyID } from '../../../api/projects';
import ModalProjectCreateNew from './ModalProjectCreateNew';

type Props = {
  projects: Project[];
  enableButtonProject?: boolean;
  tabWork: boolean;
  setTabWork: Function;
  setModes: Function;
  modes: String;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexDirection: 'row-reverse',
    maxWidth: drawerWidth,
    margin: '0',
    font: 'normal normal medium 20px/26px Alstom !important',
    color: theme.palette.primary.contrastText,
    '& > *': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiTreeView-root': {
      [theme.breakpoints.up('lg')]: {
        maxHeight: '62vh',
        overflowY: 'scroll',
        backgroundColor: '#D8E0F5',
        '&::-webkit-scrollbar': {
          width: '9px',
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px #ccc',
          borderRadius: '3px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#ccc',
          outline: '1px solid #708090',
          borderRadius: '3px',
        },
      },
      [theme.breakpoints.down('lg')]: {
        maxHeight: '40vh',
        overflowY: 'scroll',
        backgroundColor: '#D8E0F5',
        '&::-webkit-scrollbar': {
          width: '9px',
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px #ccc',
          borderRadius: '3px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgb(168, 168, 168)',
          opacity: 0.1,
          outline: '1px solid #708090',
          borderRadius: '3px',
        },
      },
    },
  },
  treeview: {
    margin: '0',
    // height: 400,
    flexGrow: 1,
    maxWidth: drawerWidth,
    borderRadius: '0px',
    // overflowY: 'scroll',
    // maxHeight: '40vh',
    // [theme.breakpoints.up('sm')]: {
    //   backgroundColor: 'red',
    // },
    // [theme.breakpoints.up('md')]: {
    //   overflowY: 'scroll',
    //   maxHeight: '40vh',
    // },
    // [theme.breakpoints.up('lg')]: {
    //   overflowY: 'scroll',
    //   maxHeight: '75vh',
    // },
    // // overflowY: 'scroll',
    // // maxHeight: '75vh',
    // // label: {
    // //   paddingLeft: 4000,
    // // },
    '& .MuiTreeItem-label': {
      marginLeft: -7,
    },
    color: '#002A45 !important',
    '& .Mui-selected > .MuiTreeItem-content': {
      backgroundColor: '#002A45 !important',
    },
    '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: '#DC3223 !important',
      color: '#FFFFFF !important',
      opacity: 1,
    },
    // '& .MuiTreeItem-root > .MuiTreeItem-content.Mui-selected.Mui-focused': {
    //   background: 'blue',
    // },
  },
  treeItem: {
    background: '#EEF1F9 0% 0% no-repeat padding-box !important',
    borderBottom: '1px solid #C4D4EC',
    opacity: 1,
    '& .MuiTreeItem-group': {
      marginLeft: 11,
    },
  },
  ListMain: {
    width: '100%',
    maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
    '& .Mui-selected': {
      // backgroundColor: 'pink',
      background: '#DC3223 0% 0% no-repeat padding-box',
      border: '1px solid #002A45',
      color: 'white !important',
      // color: 'white',
      fontWeight: 'bold',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
      '& .MuiListItemText-root': {
        color: 'white !important',
        fontWeight: 'bold',
      },
    },
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      // background: '#DC3223 0% 0% no-repeat padding-box',
      padding: 0,
      paddingLeft: 10,
    },
    '& .MuiListItem-root': {
      background: '#EEF1F9 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      borderBottom: '1px solid #C4D4EC',
      padding: 0,
      paddingLeft: 10,
    },
    // [theme.breakpoints.up('lg')]: {
    //   maxHeight: '62vh',
    //   overflowY: 'scroll',
    //   // backgroundColor: '#D8E0F5',
    //   '&::-webkit-scrollbar': {
    //     width: '9px',
    //   },
    //   '&::-webkit-scrollbar-track': {
    //     boxShadow: 'inset 0 0 6px #ccc',
    //     borderRadius: '3px',
    //   },
    //   '&::-webkit-scrollbar-thumb': {
    //     background: '#ccc',
    //     outline: '1px solid #708090',
    //     borderRadius: '3px',
    //   },
    // },
    // [theme.breakpoints.down('lg')]: {
    //   maxHeight: '40vh',
    //   overflowY: 'scroll',
    //   // backgroundColor: '#D8E0F5',
    //   '&::-webkit-scrollbar': {
    //     width: '9px',
    //   },
    //   '&::-webkit-scrollbar-track': {
    //     boxShadow: 'inset 0 0 6px #ccc',
    //     borderRadius: '3px',
    //   },
    //   '&::-webkit-scrollbar-thumb': {
    //     background: 'rgb(168, 168, 168)',
    //     opacity: 0.1,
    //     outline: '1px solid #708090',
    //     borderRadius: '3px',
    //   },
    // },
    [theme.breakpoints.up('lg')]: {
      maxHeight: '25vh',
      overflowY: 'scroll',
      // backgroundColor: '#D8E0F5',
      '&::-webkit-scrollbar': {
        width: '9px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px #ccc',
        borderRadius: '3px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgb(168, 168, 168)',
        opacity: 0.1,
        outline: '1px solid #708090',
        borderRadius: '3px',
      },
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: '15vh',
      overflowY: 'scroll',
      // backgroundColor: '#D8E0F5',
      '&::-webkit-scrollbar': {
        width: '9px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px #ccc',
        borderRadius: '3px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgb(168, 168, 168)',
        opacity: 0.1,
        outline: '1px solid #708090',
        borderRadius: '3px',
      },
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: '25vh',
      overflowY: 'scroll',
      // backgroundColor: '#D8E0F5',
      '&::-webkit-scrollbar': {
        width: '9px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px #ccc',
        borderRadius: '3px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgb(168, 168, 168)',
        opacity: 0.1,
        outline: '1px solid #708090',
        borderRadius: '3px',
      },
    },
    [theme.breakpoints.up('xl')]: {
      maxHeight: '62vh',
      overflowY: 'scroll',
      // backgroundColor: '#D8E0F5',
      '&::-webkit-scrollbar': {
        width: '9px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px #ccc',
        borderRadius: '3px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgb(168, 168, 168)',
        opacity: 0.1,
        outline: '1px solid #708090',
        borderRadius: '3px',
      },
    },
    // borderBottom: '1px solid #224B65 !important',
    // marginLeft: -15,
    // opacity: 1,
    // '&:hover': {
    //   // background: 'none !important',
    //   // borderRadius: '12px',
    // },
    // '& .MuiListItem-root.Mui-selected,.MuiListItem-root.Mui-selected:': {
    //   backgroundColor: 'blue !important',
    //   color: '#FFFFFF !important',
    //   opacity: 1,
    // },
  },
  ListItemInside: {
    // '& .MuiListItem-button:hover': {
    //   background: 'red',
    // },
    backgroundColor: '#002A45 !important',
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'center',
    fontSize: 16,
    '& .MuiListItemIcon-root': {
      color: '#002A45',
      background: '#DC3223 0% 0% no-repeat padding-box',
      // padding: '10px 0px 9px 0px',
      marginRight: 17,
      minWidth: 0,
    },
    '& .MuiListItemText-root': {
      color: '#002A45 !important',
      fontWeight: 'bold',
      background: '#DC3223 0% 0% no-repeat padding-box',
      // whiteSpace: 'break-spaces',
      // padding: '10px 120px 10px 0px',
      // margin: '-8px 0px',
      // width: '100px',
      // marginLeft: 15,
    },
  },
  ListItemInside2: {
    // '& .MuiListItem-button:hover': {
    //   background: 'red',
    // },
    // backgroundColor: '#002A45 !important',
    fontSize: 16,
    '& .MuiListItemIcon-root': {
      color: '#002A45',
      // minWidth: '38px',
      marginRight: 17,
      minWidth: 0,
    },
    '& .MuiListItemText-root': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
  },
  ListSubMain: {
    // paddingLeft: theme.spacing(4),
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      background: '#031F31 0% 0% no-repeat padding-box',
      padding: '5px 0px 5px 10px',
    },
    '& .MuiListItem-root': {
      background: '#EEF1F9 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      // borderBottom: '1px solid #C4D4EC',
      '&.Mui-selected': {
        // backgroundColor: 'pink',
        background: '#002A45 0% 0% no-repeat padding-box',
        border: '1px solid #224B65',
        color: 'white !important',
        padding: '5px 0px 5px 10px',
        // color: 'white',
        fontWeight: 'bold',
        '& .MuiListItemIcon-root': {
          color: 'white',
        },
        '& .MuiListItemText-root': {
          color: 'white !important',
          fontWeight: 'bold',
        },
      },
    },
  },
  treeNode: {
    // color: '#002A45 !important',
    '&:hover': {
      // backgroundColor: theme.palette.primary.contrastText,
      background: 'none !important',
      // borderRadius: '12px',
    },
    padding: '13px 0 13px 0',
  },
  ListsubItemInside: {
    // '& .MuiListItem-button:hover': {
    //   background: 'red',
    // },
    // paddingLeft: theme.spacing(4),
    fontSize: 16,
    '& .MuiListItemIcon-root': {
      color: '#002A45',
    },
    '& .MuiListItemText-root': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
  },
  treeNodeInside: {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      // backgroundColor: '#031F31',
      // padding: '15px 0 15px -20px',
      // background: 'none !important',
      // borderRadius: '12px',
      '& .Mui-selected .MuiTreeItem-content > .MuiTreeItem-label': {
        backgroundColor: 'blue !important',
        color: '#FFFFFF !important',
        opacity: 1,
      },
    },
    padding: '13px 0 13px 0',
    '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-label': {
      backgroundColor: 'blue !important',
      color: '#FFFFFF !important',
      opacity: 1,
    },
  },
  iconOne: {
    margin: '0 10px 0 0',
    // color: '#002A45',
  },
  iconSecond: {
    margin: '0 10px 0 0',
    color: 'green',
  },
  subicon: {
    marginRight: '10px',
    fontSize: '15px',
  },
  successIcon: {
    backgroundColor: '#DC3223',
  },
  successIconDNs: {
    backgroundColor: 'green',
  },
  treeProjectLabel: {
    fontSize: 16,
    maxWidth: 180,
    flexGrow: 1,
    textOverflow: 'ellipsis',
    /* Nécessaires pour text-overflow */
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  link: {
    display: 'block',
    // alignContent: 'center',
    // justifyContent: 'center',
    textDecoration: 'none',
  },
  tooltips: {
    background: '#002A45 0% 0% no-repeat padding-box !important',
  },
  root2: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  root3: {
    '&$selected': {
      backgroundColor: '#EEF1F9',
      '&:hover': {
        backgroundColor: 'yellow',
      },
    },
  },
  listItemRoot: {
    '& .Mui-selected': {
      backgroundColor: 'red',
    },
  },
  selected: {},
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const CollapseBox = ({
  project,
  openedItemId,
  classes,
  i,
  selected,
  setSelected,
  sam,
  getId,
}: any) => {
  const history = useHistory();
  const [folders, setFolder] = useState([]);
  const { setProjectBread, setFloderBread, setProjects, projects } = useContext(ProjectContext);
  const { name, id } = project;
  const { projectId } = useParams();

  useEffect(() => {
    if (getId[0]) {
      const findId = projects.filter((data) => data.id === getId[0]);
      if (findId.length === 0) {
        getProjectbyID(getId[0]).then((data) => {
          // console.log('data', data);
          const obj = {
            ...project,
            folders: data[0]?.folders,
          };
          const arr = [];
          // console.log('obj', obj);
          arr.push(obj);
          setProjects(arr);
          setFolder(data[0]?.folders);
        });
      } else {
        setFolder(findId[0]?.folders);
      }
    }
  }, [getId]);

  return (
    <>
      <Collapse in={openedItemId == id && sam} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.ListSubMain}>
          <LinkTo to={`/projects/${id}`} title="Activity" className={classes.link}>
            <ListItem
              button
              className={classes.ListsubItemInside}
              selected={selected === i}
              onClick={() => setSelected(i)}
            >
              <ListItemIcon>
                <PlaylistAddCheckOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Activity" />
            </ListItem>
          </LinkTo>
        </List>
      </Collapse>
      <Collapse in={openedItemId == id && sam} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.ListSubMain}>
          <LinkTo to={`/projects/${id}/manage/inbox`} title="Inbox" className={classes.link}>
            <ListItem
              button
              className={classes.ListsubItemInside}
              selected={selected === i}
              onClick={() => setSelected(i)}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Inbox" />
            </ListItem>
          </LinkTo>
        </List>
      </Collapse>
      <Collapse in={openedItemId == id && sam} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.ListSubMain}>
          <LinkTo
            to={`/projects/${id}/manage/config`}
            title="Configuration"
            className={classes.link}
          >
            <ListItem
              button
              className={classes.ListsubItemInside}
              selected={selected === i}
              onClick={() => setSelected(i)}
            >
              <ListItemIcon>
                <SettingsOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Configuration" />
            </ListItem>
          </LinkTo>
        </List>
      </Collapse>
      {ENV !== 'production' && ENV !== 'test' && (
        <Collapse in={openedItemId == id && sam} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.ListSubMain}>
            <LinkTo
              to={`/projects/${id}/manage/Assessment`}
              title="Assessment"
              className={classes.link}
            >
              <ListItem
                button
                className={classes.ListsubItemInside}
                selected={selected === i}
                onClick={() => setSelected(i)}
              >
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Assessment" />
              </ListItem>
            </LinkTo>
          </List>
        </Collapse>
      )}
      <Collapse in={openedItemId == id && sam} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.ListSubMain}>
          <LinkTo
            to={`/projects/${id}/manage/requirements`}
            title="Requirements"
            className={classes.link}
          >
            <ListItem
              button
              className={classes.ListsubItemInside}
              selected={selected === i}
              onClick={() => setSelected(i)}
            >
              <ListItemIcon>
                <FormatListBulletedRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Requirements" />
            </ListItem>
          </LinkTo>
        </List>
      </Collapse>
      <Collapse in={openedItemId == id && sam} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.ListSubMain}>
          <LinkTo
            to={`/projects/${id}/manage/analysis`}
            title="Data Analytics & KPI's"
            className={classes.link}
          >
            <ListItem
              button
              className={classes.ListsubItemInside}
              selected={selected === i}
              onClick={() => setSelected(i)}
            >
              <ListItemIcon>
                <AssessmentOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Data Analytics & KPI" />
            </ListItem>
          </LinkTo>
        </List>
      </Collapse>
      {folders?.map((folder: any, index: number) => (
        <Collapse in={openedItemId == id && sam} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.ListSubMain}>
            <ListItem
              button
              className={classes.ListsubItemInside}
              selected={selected === i}
              onClick={() => {
                // setSelected(i);
                setFloderBread(folder.id);
                history.push(`/projects/${id}/folders/${folder.id}`);
              }}
            >
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText style={{ color: 'black' }} primary={folder.name} />
            </ListItem>
          </List>
        </Collapse>
      ))}
    </>
  );
};

function ProjectTreeView({
  projects,
  enableButtonProject,
  tabWork,
  setTabWork,
  setModes,
  modes,
}: Props) {
  const classes = useStyles();
  const { appJWT } = useAppToken();
  const [project, setProject] = React.useState<Project>();
  const [openProjectCreate, setOpenProjectCreate] = React.useState(false);
  const [openFolderCreate, setOpenFolderCreate] = React.useState(false);
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState(0);
  const [collapse, setCollapse] = React.useState(false);
  const history = useHistory();
  const { setProjectBread, setFloderBread, setSnack } = useContext(ProjectContext);
  const [sam, setSam] = React.useState(false);
  const { projectId, userId } = useParams();

  const queryParams = window.location.pathname;

  const getURLPath = queryParams
    .split('/')
    .slice(0, 3)
    .map((e) => {
      let onlyNumber;
      // eslint-disable-next-line use-isnan
      if (Number(e) != 0 && !Number.isNaN(Number(e))) {
        onlyNumber = Number(e);
      }
      return onlyNumber;
    });
  const getId = getURLPath.filter((e) => e != null);

  useEffect(() => {
    if (modes === 'mode2' && sam) {
      setSam((prev) => !prev);
    } else if (modes === 'mode1' && !sam) {
      setSam((prev) => !prev);
    }
  }, [modes]);

  useEffect(() => {
    const privateProjectName = appJWT.email.replace('@alstomgroup.com', '');
    const privateProject = projects.find((project) => project.name === privateProjectName);
    if (privateProject && !privateProject?.entity) {
      setSnack("Please configure your project's entity", 'warning');
      history.push(`/projects/${privateProject.id}/manage/config?action=project_properties`);
    }
  }, []);

  const handleToggle = (event: React.ChangeEvent<any>, nodeIds: string[]) => {
    const tag = String(event.target.tagName).toLocaleLowerCase();
    if (tag === 'div' || tag === 'h6' || tag === 'p') setExpanded(nodeIds);
  };

  const handleSelect = (event: React.ChangeEvent<any>, nodeIds: string[]) => {
    if (nodeIds.length !== 0) {
      const regexLink = /link/g;
      if (String(nodeIds).search(regexLink) !== -1) {
        return setSelected(nodeIds);
      }
      const regex = /[0-9]+/g;
      const found = String(nodeIds).match(regex) || [];
      // eslint-disable-next-line no-unused-expressions
      found.length >= 2
        ? history.push(`/projects/${found[0]}/folders/${found[1]}`)
        : history.push(`/projects/${found[0]}`);
    }

    return setSelected(nodeIds);
  };

  const handleOpenProjectCreate = () => {
    setOpenProjectCreate(true);
  };
  const handleCloseProject = () => {
    setOpenProjectCreate(false);
  };

  const handleOpenFolderCreate = (p: Project) => {
    setProject(p);
    setOpenFolderCreate(true);
  };
  const handleCloseFolder = () => {
    setOpenFolderCreate(false);
  };
  const [openedItemId, setOpenedItemId] = React.useState(null);
  const handleClick = (event, i) => {
    const clickedItemId = e.currentTarget.id;
    if (openedItemId === clickedItemId) {
      setOpenedItemId('');
    } else {
      setOpenedItemId(clickedItemId);
    }
    // setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <div>
        <CssBaseline />
        {enableButtonProject && (
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={null}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 10,
            }}
          >
            {appJWT.profile === 'viewer' ? (
              'none'
            ) : (
              <Button
                variant="contained"
                aria-label="upload add project"
                color="primary"
                size="large"
                style={{
                  marginTop: 10,
                  backgroundColor: '#002A45',
                  textAlign: 'center',
                  borderRadius: 5,
                  boxShadow: 'none',
                  fontSize: '14px',
                }}
                onClick={handleOpenProjectCreate}
                startIcon={<WorkIcon style={{ color: 'white' }} />}
              >
                Create New Project
              </Button>
            )}
          </Breadcrumbs>
        )}
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          // className={classes.root2}
          className={classes.ListMain}
        >
          {/* {projects.length > 0
            ? projects.map((p: Project, i: number) =>
                p?.is_public === true ? (
                  <>
                    {p.id === getId[0] ? (
                      <>
                        <LinkTo to={`/projects/${p.id}`} className={classes.link}>
                          <ListItem
                            className={classes.ListItemInside}
                            id={p.id}
                            button
                            // eslint-disable-next-line no-restricted-globals
                            onClick={(orgEvent, ind: any) => {
                              setModes('mode1');
                              const index = i;
                              const clickedItemId = orgEvent.currentTarget.id;
                              if (openedItemId === clickedItemId) {
                                setOpenedItemId('');
                                setSelected(i);
                              } else {
                                setOpenedItemId(clickedItemId);
                                setSelected(i);
                              }
                              setProjectBread(p.id);
                            }}
                            selected={p.id === getId[0]}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                background: '#DC3223 0% 0% no-repeat padding-box',
                                justifyContent: 'center',
                                // gap: 2,
                                padding: '5px 0px 5px 0px',
                                // margin: '-8px 0px',
                                width: '100%',
                              }}
                            >
                              <ListItemIcon>
                                <BadgeIcon />
                              </ListItemIcon>
                              <ListItemText primary={p.name} />
                              <ListItemIcon>
                                <Tooltip key="tooltip-create-project" title="Add a Folder">
                                  <CreateNewFolderOutlinedIcon
                                    onClick={() => handleOpenFolderCreate(p)}
                                  />
                                </Tooltip>
                              </ListItemIcon>
                            </div>
                          </ListItem>
                        </LinkTo>
                      </>
                    ) : (
                      <>
                        <LinkTo to={`/projects/${p.id}`} className={classes.link}>
                          <ListItem
                            className={classes.ListItemInside2}
                            id={p.id}
                            button
                            // eslint-disable-next-line no-restricted-globals
                            onClick={(orgEvent, ind: any) => {
                              setModes('mode1');
                              const index = i;
                              const clickedItemId = orgEvent.currentTarget.id;
                              if (openedItemId === clickedItemId) {
                                setOpenedItemId('');
                                setSelected(i);
                              } else {
                                setOpenedItemId(clickedItemId);
                                setSelected(i);
                              }
                              setProjectBread(p.id);
                            }}
                            selected={false}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                // gap: 2,
                                padding: '5px 0px 5px 0px',
                                // margin: '-8px 0px',
                                width: '100%',
                              }}
                            >
                              <ListItemIcon>
                                <BadgeIcon />
                              </ListItemIcon>
                              <ListItemText primary={p.name} />
                              <ListItemIcon>
                                <Tooltip key="tooltip-create-project" title="Add a Folder">
                                  <CreateNewFolderOutlinedIcon
                                    onClick={() => handleOpenFolderCreate(p)}
                                  />
                                </Tooltip>
                              </ListItemIcon>
                            </div>
                          </ListItem>
                        </LinkTo>
                      </>
                    )}

                    <Collapse in={openedItemId == p.id && sam} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding className={classes.ListSubMain}>
                        <LinkTo to={`/projects/${p.id}`} title="Activity" className={classes.link}>
                          <ListItem
                            button
                            className={classes.ListsubItemInside}
                            selected={selected === i}
                            onClick={() => setSelected(i)}
                          >
                            <ListItemIcon>
                              <PlaylistAddCheckOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Activity" />
                          </ListItem>
                        </LinkTo>
                      </List>
                    </Collapse>
                    <Collapse in={openedItemId == p.id && sam} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding className={classes.ListSubMain}>
                        <LinkTo
                          to={`/projects/${p.id}/manage/inbox`}
                          title="Inbox"
                          className={classes.link}
                        >
                          <ListItem
                            button
                            className={classes.ListsubItemInside}
                            selected={selected === i}
                            onClick={() => setSelected(i)}
                          >
                            <ListItemIcon>
                              <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Inbox" />
                          </ListItem>
                        </LinkTo>
                      </List>
                    </Collapse>
                    <Collapse in={openedItemId == p.id && sam} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding className={classes.ListSubMain}>
                        <LinkTo
                          to={`/projects/${p.id}/manage/config`}
                          title="Configuration"
                          className={classes.link}
                        >
                          <ListItem
                            button
                            className={classes.ListsubItemInside}
                            selected={selected === i}
                            onClick={() => setSelected(i)}
                          >
                            <ListItemIcon>
                              <SettingsOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Configuration" />
                          </ListItem>
                        </LinkTo>
                      </List>
                    </Collapse>
                    {ENV !== 'production' && ENV !== 'test' && (
                      <Collapse in={openedItemId == p.id && sam} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.ListSubMain}>
                          <LinkTo
                            to={`/projects/${p.id}/manage/Assessment`}
                            title="Assessment"
                            className={classes.link}
                          >
                            <ListItem
                              button
                              className={classes.ListsubItemInside}
                              selected={selected === i}
                              onClick={() => setSelected(i)}
                            >
                              <ListItemIcon>
                                <AssessmentIcon />
                              </ListItemIcon>
                              <ListItemText primary="Assessment" />
                            </ListItem>
                          </LinkTo>
                        </List>
                      </Collapse>
                    )}
                    <Collapse in={openedItemId == p.id && sam} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding className={classes.ListSubMain}>
                        <LinkTo
                          to={`/projects/${p.id}/manage/requirements`}
                          title="Requirements"
                          className={classes.link}
                        >
                          <ListItem
                            button
                            className={classes.ListsubItemInside}
                            selected={selected === i}
                            onClick={() => setSelected(i)}
                          >
                            <ListItemIcon>
                              <FormatListBulletedRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Requirements" />
                          </ListItem>
                        </LinkTo>
                      </List>
                    </Collapse>
                    <Collapse in={openedItemId == p.id && sam} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding className={classes.ListSubMain}>
                        <LinkTo
                          to={`/projects/${p.id}/manage/analysis`}
                          title="Data Analytics & KPI's"
                          className={classes.link}
                        >
                          <ListItem
                            button
                            className={classes.ListsubItemInside}
                            selected={selected === i}
                            onClick={() => setSelected(i)}
                          >
                            <ListItemIcon>
                              <AssessmentOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Data Analytics & KPI" />
                          </ListItem>
                        </LinkTo>
                      </List>
                    </Collapse>
                    {p.folders?.map((folder: any, index: number) => (
                      <Collapse in={openedItemId == p.id && sam} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.ListSubMain}>
                          <ListItem
                            button
                            className={classes.ListsubItemInside}
                            selected={selected === i}
                            onClick={() => {
                              setSelected(i);
                              setFloderBread(folder.id);
                              history.push(`/projects/${p.id}/folders/${folder.id}`);
                            }}
                          >
                            <ListItemIcon>
                              <FolderIcon />
                            </ListItemIcon>
                            <ListItemText style={{ color: 'black' }} primary={folder.name} />
                          </ListItem>
                        </List>
                      </Collapse>
                    ))}
                  </>
                ) : null
              )
            : null} */}
          {projects.length > 0
            ? projects.map((p: Project, i: number) => {
                return p?.is_public === false ? (
                  <>
                    {p.id === getId[0] ? (
                      <>
                        <LinkTo to={`/projects/${p.id}`} className={classes.link}>
                          <ListItem
                            className={classes.ListItemInside}
                            id={p.id}
                            button
                            // eslint-disable-next-line no-restricted-globals
                            onClick={(orgEvent, ind: any) => {
                              setModes('mode1');
                              const index = i;
                              const clickedItemId = orgEvent.currentTarget.id;
                              setOpenedItemId(clickedItemId);
                              setSelected(i);
                              if (openedItemId === clickedItemId) {
                                setOpenedItemId('');
                                setSelected(i);
                              } else {
                                setOpenedItemId(clickedItemId);
                                setSelected(i);
                              }
                              setProjectBread(p.id);
                            }}
                            selected={p.id === getId[0]}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                background: '#DC3223 0% 0% no-repeat padding-box',
                                justifyContent: 'center',
                                // gap: 2,
                                padding: '5px 0px 5px 0px',
                                // margin: '-8px 0px',
                                width: '100%',
                              }}
                            >
                              <ListItemIcon>
                                <WorkIcon />
                              </ListItemIcon>
                              <Tooltip key="tooltip-create-project" title={p.name}>
                                <ListItemText
                                  primary={`${p.name.slice(0, 22)}${
                                    p.name.length > 21 ? '...' : ''
                                  }`}
                                />
                              </Tooltip>
                              <ListItemIcon>
                                <Tooltip key="tooltip-create-project" title="Add a Folder">
                                  <CreateNewFolderOutlinedIcon
                                    onClick={() => handleOpenFolderCreate(p.id)}
                                  />
                                </Tooltip>
                              </ListItemIcon>
                            </div>
                          </ListItem>
                        </LinkTo>
                      </>
                    ) : (
                      <>
                        <LinkTo to={`/projects/${p.id}`} className={classes.link}>
                          <ListItem
                            className={classes.ListItemInside2}
                            id={p.id}
                            button
                            // eslint-disable-next-line no-restricted-globals
                            onClick={(orgEvent, ind: any) => {
                              setModes('mode1');
                              const index = i;
                              const clickedItemId = orgEvent.currentTarget.id;
                              if (openedItemId === clickedItemId) {
                                setOpenedItemId('');
                                setSelected(i);
                              } else {
                                setOpenedItemId(clickedItemId);
                                setSelected(i);
                              }
                              setProjectBread(p.id);
                            }}
                            selected={false}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                // gap: 2,
                                padding: '5px 0px 5px 0px',
                                // margin: '-8px 0px',
                                width: '100%',
                              }}
                            >
                              <ListItemIcon>
                                <WorkIcon />
                              </ListItemIcon>
                              <Tooltip key="tooltip-create-project" title={p.name}>
                                <ListItemText
                                  primary={`${p.name.slice(0, 22)}${
                                    p.name.length > 21 ? '...' : ''
                                  }`}
                                />
                              </Tooltip>
                              <ListItemIcon>
                                <Tooltip key="tooltip-create-project" title="Add a Folder">
                                  <CreateNewFolderOutlinedIcon
                                    onClick={() => handleOpenFolderCreate(p.id)}
                                  />
                                </Tooltip>
                              </ListItemIcon>
                            </div>
                          </ListItem>
                        </LinkTo>
                      </>
                    )}

                    {(selected === i || p.id === getId[0]) && (
                      <CollapseBox
                        project={p}
                        setOpenedItemId={setOpenedItemId}
                        openedItemId={openedItemId}
                        classes={classes}
                        i={i}
                        setModes={setModes}
                        selected={selected}
                        setSelected={setSelected}
                        sam={sam}
                        setFloderBread={setFloderBread}
                        getId={getId}
                      />
                    )}
                  </>
                ) : null;
              })
            : null}
        </List>
        {enableButtonProject && openProjectCreate && (
          // <ModalProjectCreate open={openProjectCreate} onClose={handleCloseProject} />
          <ModalProjectCreateNew open={openProjectCreate} onClose={handleCloseProject} />
        )}
        {project && (
          <ModalFolderCreate
            project_id={project}
            open={openFolderCreate}
            onClose={handleCloseFolder}
          />
        )}
      </div>
    </div>
  );
}

export default ProjectTreeView;
