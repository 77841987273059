import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      //   width: '100%',
    },
  },
};

// const names = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder',
// ];

export default function DropSelectMui({ options, value, onChange, labelledBy }) {
  //   const [personName, setPersonName] = React.useState<string[]>([]);

  //   const handleChange = (event: SelectChangeEvent<typeof personName>) => {
  //     const {
  //       target: { value },
  //     } = event;
  //     setPersonName(
  //       // On autofill we get a stringified value.
  //       typeof value === 'string' ? value.split(',') : value
  //     );
  //   };

  return (
    <div>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="demo-multiple-checkbox-label">{labelledBy}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          input={<OutlinedInput label={labelledBy} />}
          renderValue={(selected) => selected.join(', ').slice(0, 14)}
          MenuProps={MenuProps}
        >
          {options
            .map((data) => data.value)
            .map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={value.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
