import { Column } from '@devexpress/dx-react-grid';
import React from 'react';
import { columnFuncType, optionsColumnsType } from './allColumns';

export const columnsAssign: columnFuncType = (
  optionColumns: optionsColumnsType,
  isExported = false
): (Column | string)[] => {
  const { targetColumn, displayColumns, isRegExp, entity, levelsHierarchy } = optionColumns;
  const breakdownColumns =
    entity === 'RSC'
      ? [
          {
            name: 'pbs',
            title: 'SBS',
          },

          {
            name: 'obs',
            title: 'OBS',
          },
        ]
      : [
          {
            name: 'pbs',
            title: 'PBS',
          },
          {
            name: 'abs',
            title: 'ABS',
          },
          {
            name: 'obs',
            title: 'OBS',
          },
        ];
  const commonColumns = [
    {
      name: 'Char_Score',
      title: <>Char Score</>,
    },
    {
      name: 'Assign Here',
      title: <>Assign Here</>,
    },
    {
      name: 'System Entities',
      title: <>System Entities</>,
    },
    {
      name: 'Activities',
      title: <>Activities</>,
    },
    {
      name: 'Acronyms',
      title: <>Acronyms</>,
    },
    {
      name: 'Cybersecurity',
      title: <>Cyber Security</>,
    },
    {
      name: 'Standards',
      title: <>Standards</>,
    },
    {
      name: 'project_management',
      title: <>Project Management</>,
    },
    {
      name: 'Commercial',
      title: <>Commercial</>,
    },
    {
      name: 'Finance',
      title: <>Finance</>,
    },
    {
      name: 'Contractual',
      title: <>Contractual</>,
    },
    {
      name: 'Context',
      title: <>Context</>,
    },
    {
      name: 'History',
      title: <>History</>,
    },
    {
      name: 'kpi_lifecycle',
      title: 'Lifecycles',
    },
    {
      name: 'assigment',
      title: <>Multi assigment</>,
    },
    {
      name: 'safety',
      title: <>Safety</>,
    },
    {
      name: 'testable',
      title: <>Testable</>,
    },
    {
      name: 'contribution',
      title: <>Contribution</>,
    },
    {
      name: 'type',
      title: <>Type</>,
    },
    {
      name: 'Critical To Customer',
      title: <>Critical To Customer</>,
    },
    {
      name: 'Stability',
      title: <>Stability</>,
    },
    {
      name: 'Validation Method',
      title: <>Validation Method</>,
    },
    {
      name: 'Source',
      title: <>Source</>,
    },
    {
      name: 'Rationale',
      title: <>Rationale</>,
    },
    {
      name: 'Validation Evidence',
      title: <>Validation Evidence</>,
    },
    {
      name: 'Digital',
      title: <>Digital</>,
    },
    {
      name: 'comment',
      title: <>Comment</>,
    },
  ];
  // eslint-disable-next-line no-unused-expressions
  entity === 'RSC'
    ? commonColumns.unshift(
        {
          name: 'Deeper_Characterization',
          title: <>Category</>,
        },
        {
          name: 'Deeper_Char_Score',
          title: <>Category Score</>,
        }
      )
    : commonColumns.unshift(
        {
          name: 'Category',
          title: <>Category</>,
        },
        {
          name: 'Category_Score',
          title: <>Category Score</>,
        }
      );
  const regExpCol = [];
  if (isRegExp) {
    regExpCol.push('ReqID');
  }

  // export into EXCEL
  if (isExported) {
    const commonExport = [
      'Characterization',
      'System Entities',
      'Activities',
      'Acronyms',
      'Cybersecurity',
      'Standards',
      'allocations',
      'score',
      'project_management',
      'Commercial',
      'Finance',
      'Contractual',
      'safety',
      'Category',
      'Category_Score',
      'testable',
      'contribution',
      'type',
      'Critical To Customer',
      'Stability',
      'Validation Method',
      'Source',
      'Rationale',
      'Validation Evidence',
      'comment',
      'Digital',
      'Char_Score',
      {
        name: 'kpi_lifecycle',
        title: 'Lifecycles',
      },
      {
        name: 'lifecycle',
        title: 'Lifecycle Multi',
      },
      ...breakdownColumns,
      'receiver',
    ];
    if (targetColumn) {
      return [
        'id',
        // 'Original_Hierarchy',
        ...Array.from(new Set([targetColumn, ...displayColumns])),
        ...commonExport,
      ];
    }
    // its for pdf (think so need David help)
    return [
      'id',
      ...regExpCol,
      'Text',
      'PageNo',
      'Hierarchy',
      'Original_Hierarchy',
      ...levelsHierarchy,
      'SubType',
      ...commonExport,
    ];
  }

  if (targetColumn) {
    return [
      // 'Menu',
      {
        name: 'id',
        title: 'iSenS_ID',
      },
      ...Array.from(new Set([targetColumn, ...displayColumns])),
      'Characterization',
      ...commonColumns,
    ];
  }

  // default PDF
  return [
    // 'Menu',
    {
      name: 'id',
      title: 'iSenS_ID',
    },
    ...regExpCol,
    'Text',
    'SubType',
    'Characterization',
    'PageNo',
    'Hierarchy',
    'Original_Hierarchy',
    ...levelsHierarchy,
    ...commonColumns,
  ];
};

export const columnsAssignMulti: columnFuncType = (
  optionColumns: optionsColumnsType
): (Column | string)[] => {
  const { entity } = optionColumns;
  const breakdownColumns =
    entity === 'RSC'
      ? [
          {
            name: 'pbs',
            title: 'SBS',
          },

          {
            name: 'obs',
            title: 'OBS',
          },
        ]
      : [
          {
            name: 'pbs',
            title: 'PBS',
          },
          {
            name: 'abs',
            title: 'ABS',
          },
          {
            name: 'obs',
            title: 'OBS',
          },
        ];
  return [
    {
      name: 'selectionCustom',
      title: 'Select',
    },
    {
      name: 'allocations',
      title: 'Alloc.',
    },
    {
      name: 'score',
      title: 'Score',
    },
    {
      name: 'lifecycle',
      title: 'Lifecycle Multi',
    },
    ...breakdownColumns,
    {
      name: 'receiver', // non plurial for allocation
      title: 'Receivers',
    },
  ];
};
