import {
  AppBar,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { statsRequirements } from '../../api/projects';
import { workloadGetAll } from '../../api/workloads';
import useProjectId from '../../app/useProjectId';
import Loading from '../../components/Core/Loading';
import ProjectBreadCrumbs from '../../components/Core/ProjectBreadCrumbs';
import { searchUsers } from '../../api/users';
import { a11yProps } from '../../components/Datagrid/TStyles';
import TabStyle from '../../components/Layout/TabStyle';
import AnalysisKpiContainer from '../KPI/AnalysisKpiContainer';
import ProjectAnalysisDataset from './components/ProjectAnalysisDataset';
import ProjectContext from './ProjectContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  content: {
    width: '100%',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: '0px 16px 32px #00000029',
    '&[aria-selected="true"][aria-expanded="true"]>div:nth-of-type(1)': {
      backgroundColor: 'red',
    },
  },
  select: {
    '& > *': { fontWeight: '600 !important', fontSize: theme.typography.body1 },
  },
  formControl: {
    margin: theme.spacing(2),
    backgroundColor: '#FFFFFF',
    width: '95%',
  },
}));
type KpiId = {
  workloadId: any;
  filename: any;
  setting?: any;
};

function ProjectAnalysisContainer() {
  const classesTab = TabStyle();
  const classes = useStyles();

  const { projectId } = useParams() as any;
  const { projects_lite } = useContext(ProjectContext);
  const { project } = useProjectId(projectId, projects_lite);
  const [datatype, setDatatype] = useState<'nc' | 'gap'>('nc');
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<any>(0); // 0 = nc
  const [kpiIds, setKpiIds] = useState<KpiId[] | undefined>(undefined);
  const [kpiSelection, setKpiSelection] = useState<KpiId | undefined>(undefined);
  const [kpiUser, setKpiUser] = useState<undefined>(undefined);
  const [projectUsers, setProjectUsers] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    searchUsers({ project_id: projectId }).then((dbusers: any[]) => {
      const myusers = dbusers.map((userdt) => {
        return {
          ...userdt.user,
          role_id: userdt.user_project.role_id,
        };
      });
      setProjectUsers(myusers);
    });
    statsRequirements(projectId)
      .then((resStats) => {
        workloadGetAll(projectId).then((resWorkload) => {
          let kpiList = [] as KpiId[];
          resWorkload
            .filter((r) => {
              return r.app === 'module1';
            })
            .forEach((r) => {
              kpiList = kpiList.concat(
                r.resources.map((resource) => {
                  const resStat = resStats.find(
                    (stats) =>
                      stats.filename === resource.filename &&
                      Number(stats.workload_id) === Number(r.id)
                  );
                  return {
                    workloadId: r.id,
                    filename: resource.filename,
                    settings: resource.settings,
                    count: resStat?.count || 0,
                  };
                })
              );
            });
          setKpiIds(kpiList);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [projectId]);

  const handleTabChange = async (event, newTabValue) => {
    setTab(newTabValue);
    switch (newTabValue) {
      default:
        break;
      case 1:
        setDatatype('nc');
        break;

      case 2:
        setDatatype('gap');
        break;
    }
  };

  const handleKpiDocument = (e) => {
    if (e.target.value === 'all') {
      setKpiSelection(undefined);
      return;
    }
    setKpiSelection(JSON.parse(e.target.value));
  };

  const handleKpiUsers = (e) => {
    if (e.target.value === 'all') {
      setKpiUser(undefined);
      return;
    }
    setKpiUser(e.target.value);
  };

  if (!project) return <Loading />;
  if (loading) return <Loading />;
  return (
    <div className={classes.root}>
      <Grid container>
        {/* <Grid item xs={12}>
          <ProjectBreadCrumbs projectId={projectId} />
        </Grid> */}
        <Grid item xs={12} className={classes.content}>
          <Box className={classesTab.box}>
            <AppBar position="static" className={classesTab.appStyle}>
              <Tabs
                className={classesTab.root}
                value={tab}
                onChange={handleTabChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="Analysis Gap NC"
                TabIndicatorProps={{ style: { display: 'none' } }}
              >
                <Tab
                  label={
                    <>
                      <Typography variant="subtitle2" className={classesTab.tabLebelText}>
                        <span>
                          <AssessmentOutlinedIcon className={classesTab.iconLabelWrapper} />
                        </span>
                        Analysis
                      </Typography>
                    </>
                  }
                  {...a11yProps(0)}
                  className={tab === 0 ? classesTab.tabstyle : ''}
                />
                <Tab
                  label={
                    <>
                      <Typography
                        variant="subtitle2"
                        title="Non conformity"
                        className={classesTab.tabLebelText}
                      >
                        <span>
                          <RemoveDoneIcon className={classesTab.iconLabelWrapper} />
                        </span>
                        Non conformity
                      </Typography>
                    </>
                  }
                  {...a11yProps(1)}
                  className={tab === 1 ? classesTab.tabstyle : ''}
                />{' '}
                <Tab
                  label={
                    <>
                      <Typography
                        variant="subtitle2"
                        title="GAP"
                        className={classesTab.tabLebelText}
                      >
                        <span>
                          <AutoAwesomeMosaicIcon className={classesTab.iconLabelWrapper} />
                        </span>
                        GAP
                      </Typography>
                    </>
                  }
                  {...a11yProps(2)}
                  className={tab === 2 ? classesTab.tabstyle : ''}
                />
              </Tabs>
            </AppBar>
            {/* {tab === 0 && kpiIds !== undefined && kpiSelection === undefined && (
              <>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch"
                  spacing={0}
                  // className={clsx(classes.bloc)}
                >
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="label-select-document">Project Documents</InputLabel>
                    <Select
                      fullWidth
                      label="Documents"
                      name="document"
                      onChange={handleKpiDocument}
                      defaultValue="all"
                      className={classes.select}
                    >
                      <MenuItem key="all-documents" value="all" className={classes.select}>
                        <Typography>all documents</Typography>
                      </MenuItem>
                      {kpiIds &&
                        kpiIds.map((cap: KpiId, index: number) => {
                          return (
                            <MenuItem
                              key={`document-${index}`}
                              value={JSON.stringify(cap)}
                              className={classes.select}
                            >
                              <Typography>
                                {cap.settings?.nameOfCapture || cap.workloadId} / {cap.filename} (
                                {cap.count})
                              </Typography>
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )} */}
            {tab === 0 && kpiIds !== undefined && (
              <>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch"
                  spacing={0}
                  // className={clsx(classes.bloc)}
                >
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="label-select-document">Project Documents</InputLabel>
                      <Select
                        fullWidth
                        label="Documents"
                        name="document"
                        onChange={handleKpiDocument}
                        defaultValue="all"
                        className={classes.select}
                      >
                        <MenuItem key="all-documents" value="all" className={classes.select}>
                          <Typography>all documents</Typography>
                        </MenuItem>
                        {kpiIds &&
                          kpiIds.map((cap: KpiId, index: number) => {
                            return (
                              <MenuItem
                                key={`document-${index}`}
                                value={JSON.stringify(cap)}
                                className={classes.select}
                              >
                                <Typography>
                                  {cap.settings?.nameOfCapture || cap.workloadId} / {cap.filename} (
                                  {cap.count})
                                </Typography>
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="label-select-document">Project Users</InputLabel>
                      <Select
                        label="Users"
                        name="users"
                        onChange={handleKpiUsers}
                        defaultValue="all"
                        className={classes.select}
                      >
                        <MenuItem key="all-documents" value="all" className={classes.select}>
                          <Typography>all Users</Typography>
                        </MenuItem>
                        {projectUsers &&
                          projectUsers.map((cap: KpiId, index: number) => {
                            return (
                              <MenuItem
                                key={`user-${index}`}
                                value={cap?.id}
                                className={classes.select}
                              >
                                <Typography>{cap?.name}</Typography>
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {projectId && (
                  <Grid style={{ margin: '20px' }}>
                    {kpiSelection && (
                      <>
                        <Grid container direction="row" alignItems="center">
                          <AssignmentIcon />
                          <Typography variant="subtitle1">{kpiSelection.filename}</Typography>
                        </Grid>
                        <Typography variant="subtitle1">
                          Number of requirements : {kpiSelection.count}
                        </Typography>
                      </>
                    )}
                    <AnalysisKpiContainer
                      project_id={projectId}
                      workload_id={kpiSelection?.workloadId}
                      file_name={kpiSelection?.filename}
                      user_id={kpiUser}
                      title={kpiSelection?.filename || 'all documents'}
                    />
                  </Grid>
                )}
              </>
            )}
            {[1, 2].includes(tab) && <ProjectAnalysisDataset datatype={datatype} />}
          </Box>
        </Grid>
      </Grid>{' '}
    </div>
  );
}

export default ProjectAnalysisContainer;
