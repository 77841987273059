import { Snackbar } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';
import React, { useState } from 'react';
import isEqual from 'react-fast-compare';

export interface KpiContextType {
  project: Project;
  setProject: (d: Project) => any;
  setSnackMsg: any;
  setSnackType: any;
}

const KpiContext = React.createContext<KpiContextType>({
  project: {} as any,
  setProject: (): any => {},
  setSnackMsg: (): any => {},
  setSnackType: (): any => {},
});

type Props = {
  project: Project;
  children: any;
};

const KpiContextProvider = ({ project, children }: Props): any => {
  const [_project, _setProject] = useState(project);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackType, setSnackType] = useState<AlertProps['severity']>('success');
  return (
    <KpiContext.Provider
      value={{
        project: _project,
        setProject: (_input) => {
          if (!isEqual(project, _input)) _setProject(_input);
        },
        setSnackMsg,
        setSnackType,
      }}
    >
      {snackMsg && (
        <Snackbar
          open={snackMsg !== ''}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          onClose={() => {
            setSnackMsg('');
          }}
        >
          <Alert severity={snackType}>{snackMsg}</Alert>
        </Snackbar>
      )}
      {children}
    </KpiContext.Provider>
  );
};

export default KpiContext;

export { KpiContextProvider };
