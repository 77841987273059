import Axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';

Axios.interceptors.response.use(
  (response: AxiosResponse<any>): AxiosResponse<any> => response,
  (error: AxiosError): any => {
    if (error && error.response) {
      if (error.response.status === 401) {
        return Promise.reject(Error('Error 401 authentification'));
      }

      if (error.response.status === 403) {
        return Promise.reject(Error('Forbidden'));
      }

      if (error.response.status === 404) {
        return Promise.reject(Error('Error 404 ressource not found'));
      }

      if ([408, 509, 599].includes(error.response.status)) {
        return Promise.reject(Error('Request takes too long time'));
      }

      if (error.response.status === 500 || error.response.status !== 200) {
        return Promise.reject(error);
      }
    }
    // connection refused
    if (error && !error.response) {
      return Promise.reject(Error('Connection error'));
    }
    return error;
  }
);

// getToken use only for API
export const getToken = (): string | null => {
  try {
    return JSON.parse(localStorage.getItem('appJWT') || '').access_token;
  } catch (error) {
    return null;
  }
};

// JSON by default
export const getConfig = (headers = {}): AxiosRequestConfig => ({
  timeout: 2000000, // TODO : 40 sec
  headers: { Authorization: `Bearer ${getToken()}`, ...headers },
});

export const getConfigPublic = (headers = {}): AxiosRequestConfig => ({
  timeout: 2000000, // TODO : 40 sec
  headers: { 'Content-Type': 'application/x-www-form-urlencoded', ...headers },
});

// on startup set bearer for all calls
Axios.defaults.headers.common = getConfig()?.headers ?? {};

export default Axios;
