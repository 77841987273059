/* eslint-disable react/button-has-type */
import { makeStyles, Theme } from '@material-ui/core/styles';

const IAllocatorStyle = makeStyles((theme) => ({
  saveButtonStyle: {
    // Add your custom styles for the "Save" button here
    backgroundColor: 'green !important',
    color: 'white !important',
  },

  cancelButtonStyle: {
    // Add your custom styles for the "Cancel" button here
    backgroundColor: 'red',
    color: 'white',
  },
  avatar: {
    backgroundColor: '#FFFFFF',
    // border: `1px solid ${theme.palette.info.main}`,
    border: '1px solid black',
    color: '#002A45',
    height: '2rem',
    width: '2rem',
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: '#DC3223',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },
  },
  avatarBorder: {
    '& .MuiAvatar-root': {
      border: '1px sloid black',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },
  },
  Navigation: {
    marginTop: '-60px !important',
  },
  tooltipStyle: {
    padding: 0,
    backgroundColor: 'unset',
  },
  IconStyle: {
    marginLeft: -20,
  },
  tableCheck: {
    '&.MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#002A45 !important',
      // marginRight: '10px !important',
    },
    '&.MuiCheckbox-root': {
      padding: '0px !important',
    },
  },
  pagingPanelContainerSplitView: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    // marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
  saveButton: {
    '& .EditColumn-button': {
      backgroundColor: 'red !important',
    },
    // Custom styles for the Save button
    backgroundColor: 'green !important',
    color: 'white',
    // Add other custom styles as needed
  },
  pagination: {
    '& .Pagination-activeButton': {
      background: 'red !important',
      color: 'red !important',
    },
  },
  PaginationButton: {
    // Custom styles for Pagination-button class
    '& .MuiButton-textPrimary': {
      // backgroundColor: '#19AA6E !important',
      // color: 'white',
      // padding: 0,
      marginRight: 10,
      // '& .EditColumn-cell': {
      //   padding: '0px 0px 0px 0px !important',
      // },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItem: 'center',
    },
    '& #commit': {
      // marginRight: 10,
      // color: 'red !important',
      '& .MuiButton-label': {
        marginBottom: 2,
        width: '120%',
        color: 'white !important',
        backgroundColor: '#19AA6E !important',
        padding: '0px 7px',
        borderRadius: 5,
        fontSize: '13px',
      },
    },
    '& #cancel': {
      // color: 'red !important',
      // marginRight: 10,
      '& .MuiButton-label': {
        color: 'white !important',
        width: '97%',
        backgroundColor: '#DC3223 !important',
        padding: '0px 3px',
        borderRadius: 5,
        fontSize: '13px',
      },
    },
  },
  PaginationActiveButton: {
    // Custom styles for Pagination-activeButton class
    '&.Pagination-activeButton': {
      backgroundColor: 'red !important',
      padding: '10px !important',
    },
  },
}));

export default IAllocatorStyle;
