import React, { memo, useState } from 'react';

import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AvatarDiscuss from './AvatarDiscuss';
import MarkdownContent from './MarkdownContent';
import { AvatarType } from './chat';

interface MessageProps {
  message: {
    role: AvatarType;
    content: string;
  };
  rowNumber: number;
  discussionId: number;
  dispatch: React.Dispatch<any>;
  isStreaming: boolean;
  classes: {
    rootMessage: string;
    userMessage: string;
    boxMessage: string;
    imgMessage: string;
    contentMessage: string;
    btnDelete: string;
  };
  initialname: string;
}

const DiscussBoardItemAvatar: React.FC<MessageProps> = ({
  message,
  rowNumber,
  discussionId,
  isStreaming,
  dispatch,
  classes,
  initialname,
}) => {
  const [renderDeleteBtn, setRenderDeleteBtn] = useState(false);
  return (
    <div
      style={{ width: '100%' }}
      className={`${classes.rootMessage} ${classes.userMessage}`}
      onMouseOver={() => setRenderDeleteBtn(true)}
      onMouseLeave={() => setRenderDeleteBtn(false)}
      onFocus={() => setRenderDeleteBtn(true)}
    >
      <Box display="flex" flexDirection="row" className={classes.boxMessage}>
        <Box className={classes.imgMessage}>{AvatarDiscuss(message.role, initialname)}</Box>
        <Box className={classes.contentMessage}>
          <MarkdownContent content={message.content} />
        </Box>
      </Box>
      {!isStreaming && renderDeleteBtn && (
        <DeleteIcon
          id={`icon-delete-${rowNumber}`}
          className={classes.btnDelete}
          onClick={() => {
            dispatch({
              type: 'DELETE_DISCUSS_QUESTION',
              payload: {
                discuss_id: discussionId,
                row_number: rowNumber,
              },
            });
          }}
        />
      )}
    </div>
  );
};

export default memo(DiscussBoardItemAvatar);
