export const COMPLIANCE_COLORS = {
  compliant: '#19AA6E',
  nc: '#E15A50',
  gap: '#FF6633',
  tbd: '#AFA082',
  clarification: '#03568A',
  'need-clarification': '#03568A',
};

export const COMPLIANCE_LABELS = {
  compliant: 'compliant',
  nc: 'non compliant',
  gap: 'gap',
  tbd: 'to be done',
  clarification: 'need clarification',
  'need-clarification': 'need clarification',
};
