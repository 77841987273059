import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@mui/icons-material/Edit';
import React, { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { addRule, editRule } from '../../../api/iverifier';
import useAppToken from '../../../app/useAppToken';
import { IS_DEBUG_MODE } from '../../../const';
import ProjectContext from '../../Project/ProjectContext';
import RuleComposer from './RuleComposer';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    backgroundColor: theme.palette.action.hover,
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  textField: {
    margin: '15px 0px',
    color: '#002A45',
    fontWeight: 'bold',
  },
  radio: {
    textAlign: 'center',
  },
  label: {
    marginRight: '30px',
  },
}));

type Props = {
  data?: any;
  onClose?: any;
};

export default function ModalRuleEditor({ data, onClose }: Props) {
  const classes = useStyles();
  const { appJWT } = useAppToken();
  const { projectId } = useParams() as any;

  const { setSnack } = useContext(ProjectContext);

  const [currentRule, setCurrentRule] = useState<any>(data);

  // bottom buttons
  const [saving, setSaving] = useState<boolean>(false);

  // button edit script rule
  const [ruleType, setRuleType] = useState<
    'conditionLocal' | 'result' | 'conditionFull' | undefined
  >();

  // handle main form
  const { register, handleSubmit } = useForm<any>({
    defaultValues: {
      script_rule: 'a==b OR c!=d',
    },
  });

  const handleClose = (response) => {
    onClose(response);
  };
  const handleReset = () => {};

  const handleScriptRule = (data?: string) => {
    switch (ruleType) {
      case 'conditionLocal':
        setCurrentRule({
          ...currentRule,
          script_rule_condition: data,
        });
        break;

      case 'result':
        setCurrentRule({
          ...currentRule,
          script_rule_result: data,
        });
        break;

      default:
        break;
    }
  };

  const handleSaveRule: SubmitHandler<any> = (data) => {
    if (data?.object_number === '') {
      setSnack('please enter all details', 'error');
      return;
    }
    setSaving(true);
    // eslint-disable-next-line eqeqeq
    if (data.id == null || data.id == '') {
      // addition of new rules
      const body = {
        created_by: Number(appJWT.user_id),
        module_profile: data?.module_profit,
        object_number: data?.object_number,
        project_id: projectId,
        rule: data?.rule,
        script_rule_condition: currentRule?.script_rule_condition,
        script_rule_id: data?.script_rule_id,
        script_rule_result: currentRule?.script_rule_result,
        standard: false,
      };
      addRule(body)
        .then((response) => {
          handleClose(response);
          setSnack('new rule added', 'success');
        })
        .catch(() => {
          setSnack('error while adding the new rule', 'error');
        })
        .finally(() => {
          setSaving(false);
        });
      return;
    }
    const ruleId = data?.id;
    const body = {
      ...currentRule,
      id: ruleId,
      module_profile: data?.module_profit,
      object_number: data?.object_number,
      project_id: projectId,
      rule: data?.rule,
      script_rule_condition: currentRule?.script_rule_condition,
      script_rule_id: data?.script_rule_id,
      script_rule_result: currentRule?.script_rule_result,
      standard: false,
      updated_by: Number(appJWT.user_id),
    };
    editRule(ruleId, body)
      .then((response) => {
        handleClose(response);
        setSnack('rule updated', 'success');
      })
      .catch(() => {
        setSnack('error while updating the rule', 'error');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <>
      <Dialog
        onClose={() => handleClose(undefined)}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="lg"
        fullWidth={true}
      >
        {IS_DEBUG_MODE && <ReactJson src={{ currentRule }} collapsed={true} theme="monokai" />}
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          <Typography>{currentRule?.id ? 'Edit Rule' : 'Create Rule'}</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => handleClose(undefined)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} style={{ backgroundColor: 'white' }}>
          <Grid container direction="row" justify="flex-start">
            <Grid item lg={5} sm={5} md={5}>
              <>
                <TextField
                  {...register('id')}
                  variant="outlined"
                  label="id"
                  placeholder="id"
                  fullWidth
                  className={classes.textField}
                  onFocus={() => setRuleType(undefined)}
                  key="id"
                  defaultValue={currentRule?.id}
                  style={{ display: 'none' }}
                />
                <TextField
                  {...register('module_profile')}
                  variant="outlined"
                  label="Module profile"
                  placeholder="Module profile"
                  fullWidth
                  className={classes.textField}
                  onFocus={() => setRuleType(undefined)}
                  key="module_profile"
                  defaultValue={currentRule?.module_profile}
                  size="small"
                />
                <TextField
                  {...register('object_number')}
                  variant="outlined"
                  label="Object number*"
                  placeholder="Object number*"
                  fullWidth
                  className={classes.textField}
                  onFocus={() => setRuleType(undefined)}
                  key="object_number"
                  defaultValue={currentRule?.object_number}
                  size="small"
                  style={{ marginTop: -5 }}
                />
                <TextField
                  {...register('script_rule_id')}
                  variant="outlined"
                  label="Rule ID"
                  placeholder="Rule ID"
                  fullWidth
                  className={classes.textField}
                  onFocus={() => setRuleType(undefined)}
                  key="rule-id"
                  defaultValue={currentRule?.script_rule_id}
                  size="small"
                  style={{ marginTop: -5 }}
                />
                <TextField
                  {...register('rule')}
                  variant="outlined"
                  label="Rule"
                  placeholder="Rule"
                  fullWidth
                  className={classes.textField}
                  onFocus={() => setRuleType(undefined)}
                  key="rule"
                  defaultValue={currentRule?.rule}
                  size="small"
                  style={{ marginTop: -5 }}
                />
                <Box style={{ border: '2px solid grey', borderRadius: '5px' }}>
                  <Box>
                    <div style={{ padding: 10 }}>
                      <b>Rule condition local</b>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        endIcon={<EditIcon />}
                        style={{ float: 'right', marginRight: 10 }}
                        onClick={() => setRuleType('conditionLocal')}
                      >
                        Edit
                      </Button>
                    </div>
                    <hr />
                    <Typography
                      style={{ backgroundColor: 'white', fontWeight: 'bold', color: 'blue' }}
                    >
                      &nbsp;&nbsp;
                      {currentRule?.script_rule_condition &&
                        currentRule?.script_rule_condition.replace(/-/gi, '')}
                    </Typography>
                    <hr />
                  </Box>

                  <Box>
                    <div style={{ padding: 10 }}>
                      <b>Rule result</b>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        endIcon={<EditIcon />}
                        style={{ float: 'right', marginRight: 10 }}
                        onClick={() => setRuleType('result')}
                      >
                        Edit
                      </Button>
                    </div>
                    <hr />
                    <Typography
                      style={{ backgroundColor: 'white', fontWeight: 'bold', color: 'blue' }}
                    >
                      &nbsp;&nbsp;
                      {currentRule?.script_rule_result &&
                        currentRule?.script_rule_result.replace(/-/gi, '')}
                    </Typography>
                  </Box>
                </Box>

                {/* <Box>
                    <Typography>
                      &nbsp;<b>Rule condition full</b>
                    </Typography>
                    <Box>
                      <Typography
                        style={{ backgroundColor: 'white', fontWeight: 'bold', color: 'blue' }}
                      >
                        {currentRule?.script_rule_condition_full}
                      </Typography>
                    </Box>
                  </Box> */}
                <TextField
                  {...register('script_rule_condition_full')}
                  variant="outlined"
                  label="Rule condition full"
                  placeholder="Rule condition full"
                  className={classes.textField}
                  onFocus={() => setRuleType(undefined)}
                  key="Rule condition full"
                  defaultValue={currentRule?.script_rule_condition_full}
                  size="small"
                  fullWidth
                />

                {/* <Box>
                    <Typography>
                      &nbsp;<b>Criticity</b>
                    </Typography>
                    <Box>
                      <Typography>{currentRule?.criticity}</Typography>
                    </Box>
                  </Box> */}

                <TextField
                  {...register('criticity')}
                  variant="outlined"
                  label="criticity"
                  placeholder="criticity"
                  className={classes.textField}
                  onFocus={() => setRuleType(undefined)}
                  key="criticity"
                  defaultValue={currentRule?.criticity}
                  size="small"
                  fullWidth
                  style={{ marginTop: -5 }}
                />
                {/* <Box>
                    <Typography>
                      &nbsp;<b>Comments</b>
                    </Typography>
                    <Box>
                      <Typography>{currentRule?.comments}</Typography>
                    </Box>
                  </Box> */}

                <TextField
                  {...register('comments')}
                  variant="outlined"
                  label="comments"
                  placeholder="comments"
                  className={classes.textField}
                  onFocus={() => setRuleType(undefined)}
                  key="comments"
                  defaultValue={currentRule?.comments}
                  size="small"
                  fullWidth
                  style={{ marginTop: -5 }}
                />

                <div>
                  <Button autoFocus onClick={handleReset} color="secondary" variant="contained">
                    Reset
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={saving}
                    onClick={handleSubmit(handleSaveRule)}
                    style={{ marginleft: 15 }}
                  >
                    {currentRule?.id ? 'Save Rule' : 'Create Rule'}
                  </Button>
                  &nbsp;
                  {saving && (
                    <span>
                      <CircularProgress size={26} color="secondary" />
                    </span>
                  )}
                </div>
              </>
            </Grid>
            <Grid item lg={1} sm={1} md={1} />
            <Grid item lg={6} sm={6} md={6}>
              {/* rule editor: rule creator + rule composer */}

              {ruleType && <RuleComposer ruleType={ruleType} onSave={handleScriptRule} />}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
