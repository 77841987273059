/* eslint-disable import/prefer-default-export */
import axios from './axios';
import { BACKEND_URL } from '../const';

type SimilarizePayloadAny = {
  output: any;
};

// const iSafety = async (input: String, count: any): Promise<SimilarizePayloadAny> => {
//   try {
//     // eslint-disable-next-line max-len
//     const URL = `https://isens-m5.azurewebsites.net/similarize/text?requirement=${input}&count=${count}`;'&requirement=&count=5
//     const response = await axios.get(URL);
//     return response.data as any;
//   } catch (error) {
//     if (error.response) {
//       throw new Error(error.response.data);
//     }
//     throw new Error(error);
//   }
// };

const iSafety = async (inputs: String, counts: any): Promise<SimilarizePayloadAny> => {
  try {
    const body = {
      requirement: `&requirement=${inputs}`,
      count: `&count=${counts}`,
    };
    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/modules/pipe/m5-text-dataset`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const iSafety_doc2dataset = async (
  container: any,
  systemSubSystem: any,
  fileName: any,
  sheetName: any,
  requirementColumn: any,
  firstLine: any,
  numberOfProposition: any
): Promise<SimilarizePayloadAny> => {
  try {
    // eslint-disable-next-line max-len
    const URL = `https://isens-m5.azurewebsites.net/similarize/doc_to_dataset?container=${container}&systemSubSystem=${systemSubSystem}&fileName=${fileName}&sheetName=${sheetName}&requirementColumn=${requirementColumn}&numberOfProposition=${numberOfProposition}`;
    // console.log(URL);
    const response = await axios.get(URL);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    }
    throw new Error(error);
  }
};

export { iSafety, iSafety_doc2dataset };
