import { Box, Tooltip, Typography, FormLabel, Grid, Card, CardContent } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import React, { useState, useContext, useEffect } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps, useParams } from 'react-router-dom';
import PublishIcon from '@mui/icons-material/Publish';
import { isEmpty } from 'lodash';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import FontDownloadOutlinedIcon from '@material-ui/icons/FontDownloadOutlined';
import CompareOutlinedIcon from '@material-ui/icons/CompareOutlined';
import CircularProgressWithLabel from '@mui/material/CircularProgress';
import BrandingWatermarkOutlinedIcon from '@material-ui/icons/BrandingWatermarkOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { List } from '@mui/material';
import { textAnalysis, ListItem } from '../../../../components/Filter/textAnalysis';
// eslint-disable-next-line import/order
import DocSimilarity from './Components/DocSimilarity';
import SimilarityAnalysisNew from './Components/SimilarityAnalysisNew';
import CompareVersions from './Components/CompareVersions';
import FindDuplicates from './Components/FindDuplicates';
import ExtractTopics from './Components/ExtractTopics';
import { BACKEND_URL } from '../../../../const';
import axios from '../../../../api/axios';
import ProjectContext from '../../../Project/ProjectContext';
import formatBytes from '../../../../tools/formatBytes';
import CustomDialog from '../../../../components/Core/CustomDialoge';
import UploadFile from '../../../../components/Core/UploadFile';
import { getAllProjectCreationLanguages } from '../../../../api/projects';

const docOperation = [
  'Similarity Analysis',
  'Document Similarity',
  'Compare Versions',
  'Extract Topics',
  // 'Translate',
  'Find Duplicates',
];

const poopOverContent = [
  'Find similar text in meaning from the database',
  'Compare similar indivation and the overall similarities',
  'Compare the difference between two document versions',
  'Identify domain specific topics',
  // 'Translate pdf, excel or word documents to other languages',
  'Find duplicate indivation by meaning within the document',
];
const ButtonIconAry = [
  <FormatListBulletedOutlinedIcon />,
  <FontDownloadOutlinedIcon />,
  <CompareOutlinedIcon />,
  <BrandingWatermarkOutlinedIcon />,
  // <TranslateIcon />,
  <WysiwygIcon />,
];

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

function DocumentAnalysis({ asynOperations }) {
  const { setSnack, getProject, setProject } = useContext(ProjectContext);
  const [operation, setOperation] = useState(0);
  const [open, setOpen] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('service');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [files, setFiles] = useState<any[]>([]);
  const [filesRow, setFilesRow] = useState([]);
  const classes = textAnalysis();
  const { workloadId, action } = useParams() as any;
  const { projectId } = useParams() as any;
  const project = getProject(Number(projectId));
  const [languageCode, setLanguageCode] = useState([]);
  const realName = ['DocToDataset', 'DocToDoc', 'docdiffer', 'iner', 'SelfDoc'];

  useEffect(() => {
    getAllProjectCreationLanguages().then((dbProjects) => {
      const db = dbProjects.map((item) => {
        return `${item.language}-${item.codes}`;
      });
      setLanguageCode(db);
    });
    const { folders } = project;
    const allResources = [] as any[];
    if (folders) {
      for (const eachFolder of folders) {
        if (eachFolder.resources && eachFolder.resources.length > 0)
          for (const r of eachFolder.resources) {
            const fileExtension = r.filename.toLowerCase().split('.').pop();
            if (
              fileExtension === 'pdf' ||
              fileExtension === 'csv' ||
              fileExtension === 'xls' ||
              fileExtension === 'xlsx' ||
              fileExtension === 'xlsm' ||
              fileExtension === 'xlsb'
              //   fileExtension === 'docx'
            ) {
              allResources.push({
                ...r,
                size: formatBytes(Number(r.size), 0),
                id: r.etag,
                folderName: eachFolder.name,
                folderId: eachFolder.id,
                isNew: false,
              });
            }
          }
      }
    }
    setFiles(allResources.sort((a, b) => new Date(b.date) - new Date(a.date)));
  }, []);

  useEffect(() => {
    if (files.length > 0) {
      if (operation !== 3) {
        const temp = files.filter((data) => {
          const fileExtension = data.filename.toLowerCase().split('.').pop();

          return ['pdf', 'csv', 'xls', 'xlsx', 'xlsm', 'xlsb'].includes(fileExtension);
        });
        setFilesRow(temp.sort((a, b) => new Date(b.date) - new Date(a.date)));
      } else {
        const temp = files.filter((data) => {
          const fileExtension = data.filename.toLowerCase().split('.').pop();

          return ['pdf', 'csv', 'xls', 'xlsx', 'xlsm', 'xlsb'].includes(fileExtension);
        });
        setFilesRow(temp.sort((a, b) => new Date(b.date) - new Date(a.date)));
      }
    }
  }, [files, operation]);
  // console.log(files);

  useEffect(() => {
    switch (actionIs) {
      case 'DocToDataset':
        setOperation(0);
        break;
      case 'DocToDoc':
        setOperation(1);
        break;
      case 'docdiffer':
        setOperation(2);
        break;
      case 'iner':
        setOperation(3);
        break;
      case 'SelfDoc':
        setOperation(4);
        break;
      default:
        break;
    }
  }, [actionIs]);
  return (
    <>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          justifyContent: 'center',
        }}
      >
        <Box
          style={{
            height: '6rem',
            borderBottom: '1px solid #D1D9EF',
            borderLeft: '1px solid #D1D9EF',
            borderRight: '1px solid #D1D9EF',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            width: '100%',
          }}
        >
          <List className={classes.docMainListParent}>
            {docOperation.map((item, i) => {
              return (
                <Tooltip title={`${poopOverContent[i]}`} arrow>
                  <ListItem
                    button
                    component={Link}
                    to={`/projects/${projectId}/module2/${'m2'}/short/?action=${`DocumentAnalysis`}&service=${
                      realName[i]
                    }`}
                    onClick={() => {
                      setOperation(i);
                    }}
                    className={classes.docOperationListItem}
                    selected={operation === i}
                  >
                    {ButtonIconAry[i]}
                    <Typography>{item}</Typography>
                  </ListItem>
                </Tooltip>
              );
            })}
          </List>
          <UploadFile
            files={files}
            setFiles={setFiles}
            projectId={projectId}
            // style={{ float: 'left' }}
          />
        </Box>
        {/* Doc to DataSet -> Similaity Analysis */}
        {operation === 0 && (
          <SimilarityAnalysisNew
            id={workloadId ? Number(workloadId) : undefined}
            files={filesRow}
            setFiles={setFilesRow}
          />
        )}
        {/* Doc To Doc ->  Document Similiarity */}
        {operation === 1 && (
          <DocSimilarity
            id={workloadId ? Number(workloadId) : undefined}
            files={filesRow}
            setFiles={setFilesRow}
          />
        )}
        {/* iDocDiffer ->  Compare Versions */}
        {operation === 2 && (
          <CompareVersions
            id={workloadId ? Number(workloadId) : undefined}
            files={filesRow}
            setFiles={setFilesRow}
          />
        )}
        {/* iNER ->  Extract Topics */}
        {operation === 3 && (
          <ExtractTopics
            id={workloadId ? Number(workloadId) : undefined}
            files={filesRow}
            setFiles={setFilesRow}
          />
        )}
        {/* Document Translator */}
        {/* {operation === 4 && (
          <FileTranslate files={files} setFiles={setFiles} languageCode={languageCode} />
        )} */}
        {/* selfDoc  ->  Find Duplicates */}
        {operation === 4 && (
          <FindDuplicates
            id={workloadId ? Number(workloadId) : undefined}
            files={filesRow}
            setFiles={setFilesRow}
          />
        )}
      </Box>
      <div>
        <CustomDialog project={project} open={open} setOpen={setOpen} />
      </div>
    </>
  );
}

export default DocumentAnalysis;
