/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import axios from './axios';
import { BACKEND_URL } from '../const';
import { LangCode } from '../components/Datagrid/TranslationList';

const docList = async (): Promise<{ output: string[] }> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/modules/module1/doc/list`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const runCharacterize = async (body: any, runId = 0): Promise<{ output: any }> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/workloads/${runId}/run/m1-characterize`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const runAllocator = async (body: any, runId = 0): Promise<{ output: any }> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/workloads/${runId}/run/m1-allocator`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const mergeCapture = async (body: any, row_types: any): Promise<{ output: any }> => {
  try {
    const response = await axios.put(`${BACKEND_URL.root}/ownings/merge/${row_types}`, body);
    return response.data as any;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const mergeRepeatCapture = async (body: any, row_types: any): Promise<{ output: any }> => {
  try {
    const response = await axios.put(`${BACKEND_URL.root}/ownings/merge/repeat/${row_types}`, body);
    return response.data as any;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export type PayloadSplit = {
  id: number;
  project_id: number;
  workload_id: number;
  column_name?: string;
  language_code?: LangCode;
  text_1: string;
  text_2: string;
};

const splitCapture = async (body: PayloadSplit, row_types: any): Promise<any> => {
  try {
    const response = await axios.put(`${BACKEND_URL.root}/ownings/split/${row_types}`, body);
    return response.data as any;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export type PayloadInsertNewAssigned = {
  comment?: string; // lowercase !
  Context: string;
  Hierarchy?: string;
  SubType: string;
  capture: string;
  column_name: string;
  Characterization: string;
  Cybersecurity: string;
  Standards: string;
  Commercial: string;
  Finance: string;
  Contractual: string;
};

export type PayloadInsertNewCapture = {
  comment?: string; // lowercase !
  Context: string;
  Hierarchy?: string;
  SubType: string;
  capture: string;
  column_name: string;
  language_code?: LangCode;
  i18n?: any;
  Original_Hierarchy?: any;
  Type?: any;
};

const insertNewCapture = async (
  id: number,
  body: PayloadInsertNewCapture,
  row_types: any
): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/ownings/${id}/new/${row_types}`, body);
    return response.data as any;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const insertCharacterizer = async (
  id: number,
  value: any,
  row_types: any,
  formData: File
): Promise<any> => {
  try {
    const values = JSON.stringify(value);
    const response = await axios.post(
      `${BACKEND_URL.root}/ownings/${id}/new_char/${row_types}?value=${values}`,
      formData
    );
    return response.data as any;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const insertNewAssigned = async (id: number, body: PayloadInsertNewAssigned): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/ownings/${id}/newassignment`, body);
    return response.data as any;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getAllProjects = async (): Promise<{ output: string[] }> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/app_dashboard/allproject`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export type ScopeOutput = {
  Text: string;
  FileName: string;
  FilePath: string;
  FileType: 'Excel' | 'CSV' | 'PDF';
  Container: string;
  Blob: string;
  Language: string;
  NumberOfPages: number;
  EstimatedTimeToCapture: string;
  StartPage: number;
  EndPage: number;
  HeaderMargin: number;
  FooterMargin: number;
  NameOfCapture: string;
  TypesOfCapture: string[];
  Process: 'Not-Extractable' | 'Extractable';
  MergeBullet: boolean;
  SmartHeaderFooter: boolean;
  SmartTableOfContent: boolean;
} & {
  // CSV
  ColumnNames?: any;
  ColumnNamesNoHeader?: any;
  TextNoHeader?: any;
  NumberOfColumns?: any;
  NumberOfRecords?: any;
  dataframe?: any;
  Header?: any;
  Sheets?: any;
};

type ScopePayload = {
  output: ScopeOutput;
  error?: any;
  message?: string;
};

const fileDetails = async (
  BlobFullName: string,
  FileName: string,
  Container: string
): Promise<ScopePayload> => {
  try {
    const body = {
      BlobFullName,
      FileName,
      Container,
    };

    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m1-getscopesettings`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

type ScopePayloadiCapturePdf = {
  output: any;
  error?: any;
};

const iCapturePdf = async (
  FileName: string,
  FilePath: string,
  FileType: string,
  Container: string,
  BlobName: string,
  Language: string,
  StartPage: any,
  EndPage: any,
  HeaderMargin: any,
  FooterMargin: any,
  NameOfCapture: any,
  TypesOfCapture: string,
  RegId: string,
  AutoHeaderFooterDetection: boolean,
  TableSwitch: boolean,
  MergeBullet: boolean
): Promise<ScopePayloadiCapturePdf> => {
  try {
    const body = {
      FileName,
      FilePath,
      FileType,
      Container,
      BlobName,
      Language,
      StartPage,
      EndPage,
      HeaderMargin,
      FooterMargin,
      NameOfCapture,
      TypesOfCapture,
      RegId,
      AutoHeaderFooterDetection,
      TableSwitch,
      MergeBullet,
    } as any;
    body.TranslateTo = ['en'];

    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m1-extract-pdf`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const csvHeader = async (dataframe: any, Header: any): Promise<{ output: ScopePayload }> => {
  try {
    const body = {
      dataframe,
      Header,
    };
    // console.log('body:', body);

    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m1-change-header`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

type HightLightBody = {
  Container: string;
  BlobFullName: string;
  FilePath: string;
  Object: string;
  Page: number;
};

type HightLightPayload = {
  searchObject: string;
  page: number;
  width: number;
  height: number;
  position: {
    flag: number;
    boundingRect: {
      x1: number;
      y1: number;
      x2: number;
      y2: number;
    };
  };
};

const searchCoordinatesText = async (
  body: HightLightBody
): Promise<{ output: HightLightPayload }> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m1-highlight-pdf`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

type SmartHeaderPayload = {
  smartFooter: number;
  smartHeader: number;
};

const pdfSmartHeader = async (body: any): Promise<{ output: SmartHeaderPayload }> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m1-smartheaderfooter`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

type ScopePayload1 = {
  output: String;
};
const iNer = async (body: any): Promise<{ output: ScopePayload1 }> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m2-onto-ner-entities`,
      body
    );
    // const response = await axios.post(`${BACKEND_URL.onto}/api/ner/df_extract_entities`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const iNerModule1 = async (body: any, workload: any): Promise<{ output: ScopePayload1 }> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/workloads/${workload}/extract_entity`,
      body
    );
    // const response = await axios.post(`${BACKEND_URL.onto}/api/ner/df_extract_entities`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const iCaptureExcel = async (body: any): Promise<{ output: any }> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m1-extract-excel`, body);
    // const response = await axios.post(`https://isens-m1.azurewebsites.net/icapture/excel`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const iCaptureCsv = async (body: any): Promise<{ output: any }> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m1-extract-csv`, body);
    // const response = await axios.post(`https://isens-m1.azurewebsites.net/icapture/csv`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const updateUserProfile = async (body: any, id: any) => {
  try {
    // console.log(`body ${JSON.stringify(body)}`);
    // write api call
    const response = await axios.put(`${BACKEND_URL.root}/user_profile/update_profile/${id}`, body);
    // console.log(`data updated${response.data}`);
    return response.data as any;
  } catch (error) {
    return Promise.reject(error);
  }
};

const createUserProfile = async (body: any, id: any) => {
  try {
    // write api call
    const response = await axios.post(
      `${BACKEND_URL.root}/user_profile/create_profile/${id}`,
      body
    );
    // console.log(`data updated${response.data}`);
    return response.data as any;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getUserProfile = async (id: any) => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/user_profile/get_profile/${id}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const postScheduleAnalysisConf = async (body: any) => {
  try {
    // write api call
    const response = await axios.post(
      `${BACKEND_URL.root}/project_management/project_management`,
      body
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getScheduleAnalysisConf = async (id: any) => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/project_management/get/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadGetRfpListbyPageNumber = async (projectId: number) => {
  try {
    const response = await axios.get(
      `${BACKEND_URL.root}/schedule_analysis_rfp_file/${projectId}/lists`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getDropdownsConfigAll = async () => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/project_management/get`);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getMilestones = async (id, textData: any, dropdown: any, runType) => {
  const body = JSON.stringify({ schedule: { Text: textData }, projectType: dropdown });
  try {
    const response = await axios.post(`${BACKEND_URL.root}/workloads/${id}/run/${runType}`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const mergeAssignment = async (body: any, row_types: any): Promise<{ output: any }> => {
  try {
    const response = await axios.put(
      `${BACKEND_URL.root}/ownings/merge/assignment/${row_types}`,
      body
    );
    return response.data as any;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const createBulkOwning = async (body: any): Promise<{ output: any }> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/owning_lifecycle`, body);
    return response.data as any;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export {
  docList,
  runCharacterize,
  runAllocator,
  fileDetails,
  csvHeader,
  pdfSmartHeader,
  iCapturePdf,
  iNer,
  iNerModule1,
  searchCoordinatesText,
  mergeCapture,
  getAllProjects,
  mergeRepeatCapture,
  splitCapture,
  iCaptureExcel,
  iCaptureCsv,
  insertNewCapture,
  insertNewAssigned,
  insertCharacterizer,
  updateUserProfile,
  getUserProfile,
  createUserProfile,
  mergeAssignment,
  postScheduleAnalysisConf,
  getScheduleAnalysisConf,
  getDropdownsConfigAll,
  getMilestones,
  createBulkOwning,
  workloadGetRfpListbyPageNumber,
};
