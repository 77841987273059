import { GroupUser } from 'GroupType';
import { useMemo } from 'react';
import { extractUserName } from '../utils/extractdata';

type Props = {
  project: any;
};

const useProjectData = (props: Props) => {
  const { project } = props;
  const { groupusers: groupusersToIterate, users: userToIterate } = project || [];

  const List_of_pbs = useMemo(() => {
    const groupusers: Array<GroupUser> = project?.groupusers || [];
    const temp_list_of_pbs = [] as any;
    for (const obj of groupusers) {
      if (!temp_list_of_pbs.includes(obj.pbs)) {
        temp_list_of_pbs.push(obj.pbs);
      }
    }

    return temp_list_of_pbs;
  }, [project]);

  const groups = useMemo(() => {
    const usersDATA = {} as any;

    if (
      userToIterate &&
      groupusersToIterate &&
      userToIterate.length > 0 &&
      groupusersToIterate.length > 0
    ) {
      for (const item of userToIterate) {
        const idValue = item.user.id;
        const nameValue = item.user.name;
        usersDATA[idValue] = `${nameValue}_${idValue}`;
      }

      // setUserListName(usersDATA);

      const result = groupusersToIterate?.map((data) => {
        const obj = {
          ...data,
          user_ids: data.user_ids.map((data) => {
            return { name: extractUserName(usersDATA[data], 'name'), id: data };
          }),
        };
        return obj;
      });

      return result || [];
    }
    return [];
  }, [userToIterate, groupusersToIterate]) as any;

  const users = useMemo(() => {
    const { users } = project || [];
    return users || [];
  }, [project, userToIterate]) as any;

  return { List_of_pbs, groups, users };
};

export default useProjectData;
