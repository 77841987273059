import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  DataTypeProvider,
  CustomPaging,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  ClickAwayListener,
  IconButton,
  Tooltip,
  makeStyles,
  TablePagination,
} from '@material-ui/core';
import { Pagination, Stack } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { withStyles } from '@material-ui/styles';
import React, { useContext, useEffect, useRef, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import PublishIcon from '@mui/icons-material/Publish';
import { isEmpty } from '@antv/util';
// import { styled } from '@mui/material/styles';
import styled, { css, keyframes } from 'styled-components';
import HistoryIcon from '@mui/icons-material/History';
import useAppToken from '../../../app/useAppToken';
import {
  userWorkloadGetbyPageNumber,
  workloadDelete,
  workloadGetAll,
} from '../../../api/workloads';
import downloadSourceFile, { ZipUrls } from '../../../tools/downloadSourceFile';
import downloadTranslateFile from '../../../tools/downloadTranslateFile';
import CreateZip from '../../../tools/CreateZip';
import Loading from '../../Core/Loading';
import {
  tableAllocatorComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../Datagrid/TStyles';
import MultiAllocTableComp from '../../Datagrid/MultiAllocTableComp';
import ToolBarPlugin from '../../Datagrid/ToolBarPlugin';
import ExcelDoc, { CsvDoc, defaultFileIcon, DocxDoc, PdfDoc, PptDoc } from '../const/png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // padding: theme.spacing(2),
    paddingTop: 0,
  },
  tablescrol: {
    maxHeight: '70vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'none',
      borderRadius: '3px',
      backgroundColor: 'white',
    },

    '&::-webkit-scrollbar-thumb': {
      outline: 'none',
      backgroundColor: '#ccc',
    },
  },
  bloc: {
    minHeight: '400px',
    // height: '100%',
    // borderRadius: 10,
    position: 'relative',
    border: 'solid 1px;',
    // borderColor: theme.palette.primary.light,
    margin: 10,
    backgroundColor: '#EEF1F9',
    margin: 1,
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
  },
  heading2: {
    // backgroundColor: theme.palette.primary.main,
    // border: `1px solid ${theme.palette.primary.main}`,
    // borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'theme.palette.primary.main',
  },
  button: {
    margin: theme.spacing(1),
    fontSize: 10,
  },
  buttons: {
    textTransform: 'none',
  },
  iconSmall: {
    fontSize: 20,
  },
  testLink: {
    marginLeft: -10,
    color: 'blue',
  },
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
}));

const LabelText = styled.div`
  padding: 0.3rem;
  width: 4rem;
  background-color: #eef1f9;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: 1px solid #d1d9ef;
`;

const FileTranslateHistory = ({ mode }) => {
  const [currentPage, setCurrentPage] = useState();
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(10);
  // const pageSizes = [5, 10, 20, 50];
  // const { setSnack } = useContext(WorkSpaceContext);
  // const { setSnack } = useContext(Module1Context);
  const [selection, setSelection] = useState([]);
  const [zipLoad, setZipLoad] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteFile, setDeleteFile] = useState(false);
  const [zipFolderName, setZipFolderName] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [historyRows, setHistoryRows] = useState([]);
  const [contextInterval, setContextInterval] = useState(null);
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [historyCol, setHistoryCol] = useState([
    { name: 'activity_detatils', title: 'File Details' },
    { name: 'source_doc', title: 'Source Document' },
    { name: 'status', title: 'Translated Document' },
    { name: 'created_by', title: 'User Name' },
    { name: 'created_at', title: 'Date Creation' },
    // { name: 'updated_at', title: 'Date Update/End' },
  ]);
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;

  // const tableScrollComponent: React.FunctionComponent<Table.CellProps> = (props) => {
  //   return <Table.Container {...props} className={classes.tablescrol} />;
  // };

  const CallHistory = (currentPage) => {
    userWorkloadGetbyPageNumber(user_id, currentPage + 1, 'Translator')
      .then((activities) => {
        const { activity_counts, workload } = activities;
        setTotalCount(activity_counts[0].total_num_activity);
        const temp = workload % 10;
        if (temp > 0) {
          const p = parseInt(workload.length / 10, 10) + 1;
          setPageCount(p);
        } else {
          const p = workload.length / 10;
          setPageCount(p);
        }
        const updatedRows = workload.map((data) => {
          const obj = {
            id: data.id,
            activity_detatils: data?.resources[0]?.filename ?? '',
            resources: data.resources[0] ?? '',
            source_doc: data.resources[0] ?? '',
            status: data.status,
            isNew: data.status !== 'end',
            created_by: data.created_by.name,
            created_at: data.created_at,
            updated_at: data.updated_at,
            progress_percent: data.progress_percent,
            errors: data.errors,
          };
          return obj;
        });
        setHistoryRows(updatedRows);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const CallHistory2 = () => {
    workloadGetAll(mode.id, true)
      .then((activities) => {
        let listIds = [] as any[];
        if (activities?.length > 0) {
          // list of ids not ended
          listIds = activities.filter((eachACT) => {
            return eachACT.action === 'Translator';
          });
        }
        const updatedRows = listIds.map((data) => {
          const obj = {
            id: data.id,
            activity_detatils: data?.resources[0]?.filename ?? '',
            resources: data.resources[0] ?? '',
            source_doc: data.resources[0] ?? '',
            status: data.status,
            isNew: data.status !== 'end',
            created_by: data.created_by.name,
            created_at: data.created_at,
            updated_at: data.updated_at,
            progress_percent: data.progress_percent,
            errors: data.errors,
          };
          return obj;
        });

        setLoading(false);
        setHistoryRows(updatedRows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (mode.key === 'user') {
      CallHistory(0);
    } else {
      CallHistory2();
    }
  }, []);

  const handleCurentPage = (value: number) => {
    setLoading(true);
    if (mode.key === 'user') {
      if (contextInterval) {
        clearTimeout(contextInterval);
      }
      const contextIntervalNew = setTimeout(() => {
        CallHistory(value);
      }, 500);
      setContextInterval(contextIntervalNew);
    } else {
      CallHistory2();
    }

    setCurrentPage(value);
  };

  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
    handleCurentPage(rel);
  };

  const handleDownload_2 = (row: string) => {
    // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals

    if (mode.key === 'user') {
      const querystring = `files=${'original_file'}`;
      downloadSourceFile(`/download/userresources/${row?.id}`, querystring);
    } else if (mode.key === 'project') {
      const querystring = `files=${'translate_file'}`;
      downloadTranslateFile(`/download/resources/${row?.id}`, querystring);
    }
  };

  const handleDownload = (row: string) => {
    /* eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }] */
    // eslint-disable-next-line no-restricted-globals
    if (row?.errors?.length > 0) {
      const error = `Error during translation ${row?.errors[0].msg}`;
      // setSnack(error);
      return;
    }

    if (mode.key === 'user') {
      const querystring = `files=${'translate_file'}`;
      downloadTranslateFile(`/download/userresources/${row?.id}`, querystring);
    } else if (mode.key === 'project') {
      const querystring = `files=${'translate_file'}`;
      downloadTranslateFile(`/download/resources/${row?.id}`, querystring);
    }
  };

  const renderStatusIcon = ({ value, row }: any) => {
    const { activity_detatils } = row;
    if (value === 'end') {
      return (
        <div style={{ display: 'flex', gap: '0.3rem', justifyContent: 'center' }}>
          <Tooltip title={`Download Translated file ${activity_detatils}`}>
            <LabelText onClick={() => handleDownload(row)}>
              <IconButton
                style={{
                  padding: 0,
                }}
              >
                <DownloadForOfflineIcon style={{ color: '#19AA6E' }} />
              </IconButton>
            </LabelText>
          </Tooltip>
        </div>
      );
    }
    return <></>;
  };

  const renderResources = ({ value, row }: any) => {
    const { activity_detatils } = row;
    if (row?.status === 'end' && row?.errors?.length === 0) {
      return (
        <div style={{ display: 'flex', gap: '0.3rem', justifyContent: 'center' }}>
          <Tooltip title={`Download Source file ${activity_detatils}`}>
            <LabelText
              onClick={() => {
                handleDownload_2(row);
              }}
            >
              <IconButton style={{ padding: 0 }}>
                {' '}
                <DownloadForOfflineIcon style={{ color: '#19AA6E' }} />
              </IconButton>
            </LabelText>
          </Tooltip>
        </div>
      );
    }
    return <></>;
  };

  const renderFileName = ({ value, row }: any) => {
    const { activity_detatils, resources } = row;
    const ext = activity_detatils?.split('.');
    const extensions = {
      xlsx: ExcelDoc,
      pptx: PptDoc,
      csv: CsvDoc,
      pdf: PdfDoc,
      docx: DocxDoc,
      doc: DocxDoc,
    };
    return (
      <div style={{ display: 'flex', gap: '0.5rem' }}>
        <img
          src={extensions[ext[ext?.length - 1]] ?? defaultFileIcon}
          height="40"
          width="40"
          alt="img"
        />
        <div>
          <Typography style={{ fontSize: '1em', fontWeight: 'bold' }}>
            Translated Language : {resources?.settings?.nameOfCapture}
          </Typography>
          <Typography>{activity_detatils}</Typography>
        </div>
      </div>
    );
  };

  console.log('sdfdf');

  const renderDateOfCreation = ({ row }: any) => {
    const dates = row.created_at.split(' ');
    // const date = new Date();
    // date.setMonth(parseInt(dates[1], 10) - 1);
    // const mon = date.toLocaleString('en-US', {
    //   month: 'long',
    // });
    // return (
    //   <>
    //     {dates[0]} / {mon.slice(0, 3)} / {dates[2]}
    //   </>
    // );
    return (
      <>
        {dates[0]} {dates[1].split('.')[0]}
      </>
    );
  };

  const handleFileSelection = (selectedFile: any) => {
    setSelection(selectedFile);
  };

  const GenerateZipFolder = () => {
    setZipLoad(true);
    setModalOpen(false);
    const urls = selection?.map((data: any) => {
      const querystring = `files=${'translate_file'}`;
      return ZipUrls(
        `/download/resources/${historyRows[data]?.id}`,
        querystring,
        historyRows[data]?.activity_detatils
      );
    });
    CreateZip(urls, zipFolderName)
      .then((res) => {
        // setSnack('Downloaded Zip Successfully', 'success');
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setZipLoad(false);
      });
  };

  const handleZipFiles = () => {
    GenerateZipFolder();
  };

  const handleDeleteFile = () => {
    if (selection?.length === 0) return;
    const rowsDeleted = selection.map((data: any) => historyRows[data].id);
    const temp = historyRows.filter((data) => !rowsDeleted.includes(data.id));
    workloadDelete(rowsDeleted)
      .then(() => {
        setHistoryRows(temp);
        setSelection([]);
        setDeleteFile(false);
        // setSnack('Successfully Deleted', 'success');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Box
      style={{
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
        position: 'relative',
        minHeight: '15rem',
      }}
    >
      {loading && (
        <Box
          style={{
            position: 'absolute',
            // height: '20%',
            // width: '20%',
            top: '50%',
            left: '50%',
            zIndex: 700,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loading />
        </Box>
      )}
      {historyRows.length > 0 && (
        <Grid rows={historyRows} columns={historyCol}>
          <DataTypeProvider for={['status']} formatterComponent={renderStatusIcon} />
          <DataTypeProvider for={['source_doc']} formatterComponent={renderResources} />
          <DataTypeProvider for={['activity_detatils']} formatterComponent={renderFileName} />
          <DataTypeProvider for={['created_at']} formatterComponent={renderDateOfCreation} />
          {/* <FilteringState /> */}
          <SelectionState selection={selection} onSelectionChange={setSelection} />
          {/* <IntegratedFiltering /> */}
          {/* <SortingState /> */}
          {/* <IntegratedSorting /> */}
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={(e) => {
              handleCurentPage(e);
            }}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          {/* <IntegratedPaging /> */}
          <VirtualTable />
          <CustomPaging totalCount={totalCount} />
          <Table
            tableComponent={MultiAllocTableComp}
            containerComponent={tableAllocatorComponent}
            // containerComponent={tableScrollComponent}
            headComponent={tableHeaderComponent}
          />
          <TableColumnResizing
            defaultColumnWidths={[
              { columnName: 'activity_detatils', width: '300px' },
              { columnName: 'source_doc', width: '200px' },
              { columnName: 'status', width: '200px' },
              { columnName: 'created_by', width: '200px' },
              { columnName: 'created_at', width: '200px' },
              // { columnName: 'filename', width: '300px' },
              // { columnName: 'size', width: '200px' },
              // { columnName: 'folderName', width: '200px' },
              // { columnName: 'date', width: '200px' },
              // { columnName: 'userName', width: '200px' },
            ]}
          />
          <TableHeaderRow />
          <TableSelection />
          <Toolbar rootComponent={toolbarComponent} />
          {/* <TableFilterRow /> */}

          {selection?.length > 0 && (
            <ToolBarPlugin zipLoad={zipLoad} name="Zip & Download" onClick={handleZipFiles} />
          )}
          <ToolBarPlugin name="Delete" onClick={handleDeleteFile} />

          <PagingPanel />
        </Grid>
      )}
    </Box>
  );
};

export default FileTranslateHistory;
