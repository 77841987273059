/* eslint-disable no-param-reassign */
import { Dialog, DialogTitle, IconButton, makeStyles, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { getRules } from '../../api/iverifier';
import Loading from '../../components/Core/Loading';
import { IS_DEBUG_MODE } from '../../const';
import RulesTreeContainer, { getTreeView } from './RulesTree/RulesTreeContainer';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
  label: {
    marginRight: '30px',
  },
}));

type Props = {
  rows?: any[];
  rows_tree?: any[];
  template?: {
    id: number;
    name: string;
    version: string;
  };
  selected?: any[];
  onClose?: any;
};

export default function ModalRulesViewer({ rows, rows_tree, selected, template, onClose }: Props) {
  const classes = useStyles();
  const [treeData, setTreeData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);

  useEffect(() => {
    if (rows_tree === undefined) {
      getRules(undefined, template?.id, 'hierarchy').then((rules) => {
        // console.log('rules.children', rules.children);
        setTreeData(getTreeView(rules.children));
        setLoading(false);
      });
    } else {
      setTreeData(getTreeView(rows_tree.children));
      setLoading(false);
    }
  }, []);

  const handleClose = () => {
    onClose();
  };

  if (loading) return <Loading />;

  return (
    <Paper>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="lg"
        fullWidth={true}
      >
        {IS_DEBUG_MODE && (
          <ReactJson src={{ selected, rows, treeData }} collapsed={true} theme="monokai" />
        )}
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          {template && (
            <>
              Template : {template?.name} version : {template?.version}
            </>
          )}
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {loading ? (
          <Loading />
        ) : (
          <>
            <RulesTreeContainer rows_tree={treeData} rows={rows} selected={selected} />
          </>
        )}
      </Dialog>
    </Paper>
  );
}
