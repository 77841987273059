import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { Paper } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { Theme, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/styles';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { notifByInbox, notifByProjectId } from '../../../api/wsNotifs';
import useAppToken from '../../../app/useAppToken';
import Loading from '../../../components/Core/Loading';
import TStyles, { rowComponent, tUseStyles } from '../../../components/Datagrid/TStyles';
import TabStyle from '../../../components/Layout/TabStyle';
import Module1Context from '../../Module1/Module1Context';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}
type Props = {
  onSearchText?: any;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  formControlMultiTextlebel: {
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  formControlMulti: {
    margin: theme.spacing(1),
    minWidth: 155,
    maxWidth: 155,
  },
  formControlMulti1: {
    margin: theme.spacing(1),
    minWidth: 280,
    maxWidth: 280,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tabstyle: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '33px',
    background: '#DC3223 0% 0% no-repeat padding-box',
    color: '#fff !important',
    fontSize: 14,
    fontWeight: 700,
  },
}));

const Columns = [{ name: 'label', title: 'Description' }];
type PayloadNotif = {
  ownings: any[];
  workloads: any[];
  userManagements: any[];
};

const payloadNotifInit = {
  ownings: [],
  workloads: [],
  userManagements: [],
} as PayloadNotif;

type MessageNotif = {
  ownings: number;
  workloads: number;
  userManagements: number;
};

const messageInit = {
  ownings: 0,
  workloads: 0,
  userManagements: 0,
} as MessageNotif;

function ProjectInbox({ onSearchText }: Props) {
  const classesTab = TabStyle();
  const tableUseStyles = tUseStyles();
  const { projectId } = useParams() as any;
  const { appJWT } = useAppToken();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { setSnack } = useContext(Module1Context);
  const [pageSizes] = useState([5, 10, 20, 50, 100]);
  const [rows, setRows] = useState<any>([]);
  const [secondTablerows, setsecondTableRows] = useState<any>([]);
  const [thirdTablerows, setthirdTableRows] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };

  // table header
  const TableComponentBase = ({ classes, ...restProps }: any) => (
    <Table.Table {...restProps} className={classes.tableStriped} />
  );

  const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setLoading(true);
    setValue(newValue);
  };
  const columns = [
    { name: 'label', title: 'Description' },
    { name: 'Link', title: 'Link' },
  ];
  useEffect(() => {
    notifByInbox().then((payload) => {
      // eslint-disable-next-line func-names
      const ownings = payload.ownings.filter((payload: { ownings: any[] }, index: any) => {
        // eslint-disable-next-line eqeqeq
        return payload.redirect_params.project_id == projectId;
      });
      // eslint-disable-next-line func-names
      const workloads = payload.workloads.filter((payload: { workloads: any[] }, index: any) => {
        // eslint-disable-next-line eqeqeq
        return payload.redirect_params.project_id == projectId;
      });
      // eslint-disable-next-line func-names
      const userManagements = payload.userManagements.filter(
        (payload: { userManagements: any[] }, index: any) => {
          // eslint-disable-next-line eqeqeq
          return payload.redirect_params.project_id == projectId;
        }
      );
      setTimeout(() => {
        setRows(ownings);

        setsecondTableRows(workloads);

        setthirdTableRows(userManagements);
        setLoading(false);
      }, 2000);
    });
    // .catch((err) => {
    //   console.log(err);
    // })
    // .finally(() => {
    //   setLoading(false);
    // });
  }, [loading, projectId]);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 5000);
  //   // .catch((err) => {
  //   //   console.log(err);
  //   // })
  //   // .finally(() => {
  //   //   setLoading(false);
  //   // });
  // }, []);
  console.log('loading Final hain', loading);
  const renderLinkAlert = ({ value, row }: DataTypeProvider.ValueFormatterProps) => (
    <Link
      // eslint-disable-next-line max-len
      to={`/projects/${row?.redirect_params?.project_id}/${row?.redirect_params?.app_name}/results/${row?.redirect_params?.workload_id}`}
    >
      view
    </Link>
  );
  const renderLinkNotification = ({ value, row }: DataTypeProvider.ValueFormatterProps) => (
    <Link
      // /projects/${params.project_id}/${params.app_name}/results/${params.workload_id}?filename=${params.filename}&action=owning
      // eslint-disable-next-line max-len
      to={`/projects/${row?.redirect_params?.project_id}/${row?.redirect_params?.app_name}/results/${row?.redirect_params?.workload_id}?filename=${rows?.filename}&action=owning`}
    >
      view
    </Link>
  );
  // if (loading) return <Loading />;

  return (
    <div className={classesTab.root} style={{ marginTop: 10 }}>
      <AppBar position="static" className={classesTab.appStyle}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          // style={{ backgroundColor: '#EBE6DC' }}
          style={{
            color: '#002A45',
            fontWeight: 'bold',
            opacity: '1 !important',
            fontSize: '-0.125rem',
          }}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          <Tab
            label={
              <>
                <Typography variant="subtitle2" className={classesTab.tabLebelText}>
                  <span>
                    <ErrorOutlineOutlinedIcon className={classesTab.iconLabelWrapper} />
                  </span>
                  Information
                </Typography>
              </>
            }
            {...a11yProps(0)}
            className={value === 0 ? classesTab.tabstyle : ''}
            // style={{ fontSize: '18px', fontWeight: 'bold' }}
          />
          <Tab
            label={
              <>
                <Typography variant="subtitle2" className={classesTab.tabLebelText}>
                  <span>
                    <AccessAlarmOutlinedIcon className={classesTab.iconLabelWrapper} />
                  </span>
                  Alert
                </Typography>
              </>
            }
            {...a11yProps(1)}
            className={value === 1 ? classesTab.tabstyle : ''}
            // style={{ fontSize: '18px', fontWeight: 'bold' }}
          />
          <Tab
            label={
              <>
                <Typography variant="subtitle2" className={classesTab.tabLebelText}>
                  <span>
                    <NotificationsOutlinedIcon className={classesTab.iconLabelWrapper} />
                  </span>
                  Notifications
                </Typography>
              </>
            }
            {...a11yProps(2)}
            className={value === 2 ? classesTab.tabstyle : ''}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        {loading === true ? (
          <Loading />
        ) : (
          <Paper className={tableUseStyles.panelMain}>
            <ExpressGrid rows={thirdTablerows} columns={Columns}>
              <SelectionState />
              <FilteringState defaultFilters={[{ columnName: 'Descriptions', value: '' }]} />
              <IntegratedFiltering />
              <SortingState />
              <IntegratedSorting />
              <PagingState defaultCurrentPage={0} defaultPageSize={5} />
              <IntegratedPaging />
              <VirtualTable />
              <Table tableComponent={TableComponent} />
              <TableHeaderRow showSortingControls />
              <TableFilterRow rowComponent={rowComponent} />
              <PagingPanel pageSizes={pageSizes} />
              <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
            </ExpressGrid>
          </Paper>
        )}
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        {loading === true ? (
          <Loading />
        ) : (
          <Paper className={tableUseStyles.panelMain}>
            <ExpressGrid rows={secondTablerows} columns={columns}>
              <DataTypeProvider for={['Link']} formatterComponent={renderLinkAlert} />
              <SelectionState />
              <FilteringState defaultFilters={[{ columnName: 'Descriptions', value: '' }]} />
              <IntegratedFiltering />
              <SortingState />
              <IntegratedSorting />
              <PagingState defaultCurrentPage={0} defaultPageSize={5} />
              <IntegratedPaging />
              <VirtualTable />
              <Table tableComponent={TableComponent} />
              <TableHeaderRow showSortingControls />
              <TableFilterRow rowComponent={rowComponent} />
              <PagingPanel pageSizes={pageSizes} />
              <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
            </ExpressGrid>
          </Paper>
        )}
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        {console.log('loading final', loading)}
        {loading === true && value === 2 ? (
          <Loading />
        ) : (
          <Paper className={tableUseStyles.panelMain}>
            <ExpressGrid rows={rows} columns={columns}>
              <DataTypeProvider for={['Link']} formatterComponent={renderLinkNotification} />
              <SelectionState />
              <FilteringState defaultFilters={[{ columnName: 'Descriptions', value: '' }]} />
              <IntegratedFiltering />
              <SortingState />
              <IntegratedSorting />
              <PagingState defaultCurrentPage={0} defaultPageSize={5} />
              <IntegratedPaging />
              <VirtualTable />
              <Table tableComponent={TableComponent} />
              <TableHeaderRow showSortingControls />
              <TableFilterRow rowComponent={rowComponent} />
              <PagingPanel pageSizes={pageSizes} />
              <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
            </ExpressGrid>
          </Paper>
        )}
      </TabPanel>
      {/* </SwipeableViews> */}
    </div>
  );
}
export default ProjectInbox;
