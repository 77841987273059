import React from 'react';

function NERParamTarget(data: any) {
  const r = data;
  // console.log(r);
  return (
    <>
      {r?.data?.NumberOfColumns > 0
        ? r.data.ColumNames.map((cap, index) => (
            <option key={`capture-type-${index}`} value={cap}>
              &nbsp;&nbsp;{cap}
            </option>
          ))
        : ''}
    </>
  );
}

export default NERParamTarget;
