import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import MuiListItem from '@material-ui/core/ListItem';
import { styled } from '@mui/styles';

const AssessmentStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2rem 4rem',
      height: '100%',
    },
    parrentDiv: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      height: '100%',
      width: '100%',
      padding: '1rem',
      border: '2px solid #002A45',
      boxShadow: '5px 10px 10px #888888',
    },
    operationListDiv: {
      height: '100%',
      width: '100%',
      border: '1px solid #002A45',
      borderRadius: '10px',
      backgroundColor: '#EEF1F9',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2rem',
      gap: '10px',
      transition: '0.5s',
    },
    parentList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    subOperationListDiv: {
      backgroundColor: '#EEF1F9',
      border: '1px solid #002A45',
      borderRadius: '10px',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      gap: '10px',
    },
    parentBtn: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  })
);

export const ListItem = withStyles({
  root: {
    display: 'flex',
    // width: 'max-content',
    border: '1px solid #002A45',
    borderRadius: '10px',
    fontSize: '1.29em',
    padding: '0.5rem 1rem 0.5rem 1rem',
    '&$selected': {
      backgroundColor: '#EEF1F9',
      color: '#002A45',
      // width: 'max-content',
      // '& .MuiListItemIcon-root': {
      //   color: 'white',
      // },
    },
    '&$selected:hover': {
      backgroundColor: '#EEF1F9',
      color: '#002A45',
      // width: 'max-content',
      // '& .MuiListItemIcon-root': {
      //   color: 'white',
      // },
    },
    '&:hover': {
      backgroundColor: '#EEF1F9',
      color: '#002A45',
      // width: 'max-content',
      // '& .MuiListItemIcon-root': {
      //   color: 'white',
      // },
    },
  },
  selected: {},
})(MuiListItem);

export const QuestionStyle = makeStyles((theme: Theme) =>
  createStyles({
    subQuestions: {
      backgroundColor: '#EEF1F9',
      border: '1px solid #002A45',
      borderRadius: '10px',
      padding: '2rem',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      gap: '20px',
    },
    questionBox: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      gap: '20px',
      fontSize: '1.67em',
      color: '#002A45',
      borderBottom: '2px solid #D1D9EF',
      paddingBottom: '1rem',
      marginBottom: '1rem',
    },
  })
);
export default AssessmentStyle;
