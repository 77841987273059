import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, { useContext, useReducer } from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import docIcon from '../../../../../../../assets/docx_ico.png';
import pdfIcon from '../../../../../../../assets/pdf_ico.png';
import DocAnalysisContext from '../../../DocAnalysisContext';
import pdfFilereducers, { pdfInitialState } from '../../../../../Reducers/pdfFileReducers';

const LeftBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const RightBlock = styled.div`
  flex: 1;
  display: flex;
  //   flex-direction: column;
  justify-content: flex-end;
`;

const MainDiv = styled.div`
  display: flex;
`;

const PdfFileSettings = ({ index, settingsData }) => {
  const {
    setFileSettings,
    // state,
    // dispatch,
  } = useContext(DocAnalysisContext);
  const [state, dispatch] = useReducer(pdfFilereducers, pdfInitialState);

  React.useEffect(() => {
    setFileSettings((prev) => {
      return {
        ...prev,
        [index]: {
          ...state,
        },
      };
    });
  }, [state]);
  return (
    <>
      <MainDiv>
        <LeftBlock>
          {settingsData.map((c, indexIs) => (
            <FormControl variant="outlined">
              <span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                {c?.FileType === 'unsupported' ? <img src={docIcon} alt="isens" height="30" /> : ''}
                &nbsp;
                {c?.FileType === 'PDF' ? <img src={pdfIcon} alt="isens" height="30" /> : ''}
                {c?.FileType !== 'unsupported' ? (
                  <span>
                    <span style={{ color: 'red' }}>{c?.Error}</span>
                  </span>
                ) : (
                  ''
                )}
                <Tooltip title={c?.Blob} placement="top" arrow>
                  <b style={{ color: 'blue', wordWrap: 'break-word' }}>
                    {c?.Blob.substring(0, 25)}
                  </b>
                </Tooltip>
              </span>
            </FormControl>
          ))}
        </LeftBlock>
        <RightBlock>{}</RightBlock>
      </MainDiv>
    </>
  );
};

export default PdfFileSettings;
