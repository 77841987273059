import { any } from 'prop-types';
import React, { createContext, useReducer, useState } from 'react';
import fileTranslateReducer from './fileTranslateReducer';

export interface FileTranslatorContextType {
  languageCode: any;
  setSnack: any;
  files: any;
  setFiles: any;
  glossaryFiles: any;
  setGlossaryFiles: any;
  glossarySelection: any;
  mode: any;
  setGlossarySelection: any;
  fileSelection: any;
  setFileSelection: any;
  state: any;
  dispatch: any;
}

type Props = {
  children: any;
  setSnack: any;
  languageCode: any;
  mode: any;
  files: any;
  setFiles: any;
  glossaryFiles: any;
  setGlossaryFiles: any;
};

const FileTranslatorContext = createContext<FileTranslatorContextType>({
  languageCode: [] as any,
  setSnack: (): any => {},
  files: [] as any,
  setFiles: (): any => {},
  glossaryFiles: [] as any,
  mode: {} as any,
  setGlossaryFiles: (): any => {},
  glossarySelection: [] as any,
  setGlossarySelection: (): any => {},
  fileSelection: [] as any,
  setFileSelection: (): any => {},
  state: {} as any,
  dispatch: {} as any,
});

const intialState = {
  selectedFileData: [],
  selected_glossary: [],
  FromLang: '',
  ToLang: '',
  GlossaryFromLang: '',
  GlossaryToLang: '',
  glossaryToLanuageMatch: '',
};

const FileTranslatorContextProvider = ({
  children,
  setSnack,
  languageCode,
  mode,
  files,
  setFiles,
  glossaryFiles,
  setGlossaryFiles,
}: Props) => {
  //   const [files, setFiles] = useState(filesData);
  //   const [glossaryFiles, setGlossaryFiles] = useState();
  const [glossarySelection, setGlossarySelection] = useState([]);
  const [fileSelection, setFileSelection] = useState([]);
  const [state, dispatch] = useReducer(fileTranslateReducer, intialState);

  return (
    <FileTranslatorContext.Provider
      value={{
        languageCode,
        files,
        setFiles,
        glossaryFiles,
        setGlossaryFiles,
        setSnack,
        mode,
        glossarySelection,
        setGlossarySelection,
        fileSelection,
        setFileSelection,
        state,
        dispatch,
      }}
    >
      {children}
    </FileTranslatorContext.Provider>
  );
};

export default FileTranslatorContext;

export { FileTranslatorContextProvider };
