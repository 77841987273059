import * as React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, makeStyles, Tab, Tabs, Typography, useTheme } from '@material-ui/core';
import FontDownloadOutlinedIcon from '@material-ui/icons/FontDownloadOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import ISafety from './ISafety';
import IsafetyNew from './IsafetyNew';
import TabStyle from '../../../components/Layout/TabStyle';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  tabstyle: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '33px',
    background: '#DC3223 0% 0% no-repeat padding-box',
    color: '#fff !important',
    fontSize: 14,
    fontWeight: 700,
  },
  appStyle: {
    border: '1px solid #002A45',
    borderRadius: '34px',
    backgroundColor: '#f5f5f5',
  },
}));

function ISafetyTab() {
  const classes = useStyles();
  const classesTab = TabStyle();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <br />
      <div className={classes.root}>
        <AppBar position="static" className={classesTab.appStyle}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
            style={{
              color: '#002A45',
              fontWeight: 'bold',
              opacity: '1 !important',
              fontSize: '-0.125rem',
            }}
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            <Tab
              label={
                <>
                  <Typography variant="subtitle2" className={classesTab.tabLebelText}>
                    <span>
                      <AssignmentOutlinedIcon className={classesTab.iconLabelWrapper} />
                    </span>
                    Text To Dataset
                  </Typography>
                </>
              }
              {...a11yProps(0)}
              className={value === 0 ? classesTab.tabstyle : ''}
            />
            <Tab
              label={
                <>
                  <Typography variant="subtitle2" className={classesTab.tabLebelText}>
                    <span>
                      <FormatListBulletedOutlinedIcon className={classesTab.iconLabelWrapper} />
                    </span>
                    Doc To Dataset
                  </Typography>
                </>
              }
              {...a11yProps(1)}
              className={value === 1 ? classesTab.tabstyle : ''}
            />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <ISafety />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <IsafetyNew />
        </TabPanel>
      </div>
    </>
  );
}

export default ISafetyTab;
