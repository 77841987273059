import { isEmpty } from '@antv/util';
import {
  Box,
  Button,
  FormLabel,
  IconButton,
  InputBase,
  List,
  Paper,
  Popover,
  Typography,
  Tooltip,
  Slider,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import AddIcon from '@mui/icons-material/Add';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CachedIcon from '@mui/icons-material/Cached';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import CallSplit from '@mui/icons-material/CallSplit';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteIcon from '@mui/icons-material/Delete';
import DeselectIcon from '@mui/icons-material/Deselect';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import GetAppIcon from '@mui/icons-material/GetApp';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import MergeIcon from '@mui/icons-material/Merge';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import PieChartIcon from '@mui/icons-material/PieChart';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PublishIcon from '@mui/icons-material/Publish';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import RestoreIcon from '@mui/icons-material/Restore';
import SaveIcon from '@mui/icons-material/Save';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SendIcon from '@mui/icons-material/Send';
import GavelIcon from '@material-ui/icons/Gavel';
import WrapTextIcon from '@mui/icons-material/WrapText';
import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { CircularProgress } from '@mui/material';
import React from 'react';
import TranslationList from './TranslationList';
import withPlugin, { WithPluginProps } from './withPlugin';
import WorkloadFilter from './WorkloadFilter';

const ToolBarPlugin = ({
  languages,
  languageCode,
  languageOriginCode,
  onClick,
  onTranslate,
  zipLoad,
  name = 'default',
  showSel,
  title = undefined,
  mulitple = false,
  loading = false,
  data,
  hide = false,
  getWorkLoadAll,
  filterValues,
  setFilterValues,
}: WithPluginProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openFilter = Boolean(anchorEl);
  const id = openFilter ? 'simple-popover' : undefined;

  const handleClickAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (name === 'Zip & Download') {
    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={
          zipLoad ? <CircularProgress style={{ color: 'white' }} size={20} /> : <FolderZipIcon />
        }
        onClick={handleClick}
      >
        Make Zip & Download
      </Button>
    );
  }

  if (name === 'Generate summary') {
    return (
      <div style={{ padding: '0.8rem' }}>
        <Button onClick={handleClick} variant="contained" color="primary">
          Generate summary
        </Button>
      </div>
    );
  }

  if (name === 'ExcelImport') {
    const inputProps = {} as any;
    if (mulitple) inputProps.multiple = true;
    return (
      <Tooltip title={title || name}>
        <FormLabel style={{ marginTop: '3px' }}>
          <PublishIcon />
          {/* value = "" to disable cache */}
          <input {...inputProps} value="" type="file" hidden onChange={onClick} />
        </FormLabel>
      </Tooltip>
    );
  }

  if (name === 'view_All') {
    return (
      <Button onClick={handleClick} variant="contained" color="primary">
        {!isEmpty(showSel) ? 'View Selected' : 'View ALL'}
      </Button>
    );
  }

  if (name === 'freetext') {
    return (
      <Tooltip title={title || name}>
        <Button onClick={handleClick}>{title || name}</Button>
      </Tooltip>
    );
  }

  if (name === 'Filter') {
    return (
      <>
        <IconButton
          aria-describedby={id}
          variant="contained"
          onClick={(e) => {
            handleClickAnchor(e);
          }}
          title={title || name}
        >
          <FilterAltOutlinedIcon />
        </IconButton>
        <Popover
          id={id}
          open={openFilter}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              backgroundColor: '#EEF1F9',
              marginTop: '10px',
              boxShadow: 'none',
              overflowX: 'visible',
              overflowY: 'visible',
            },
          }}
        >
          <WorkloadFilter
            getWorkLoadAll={getWorkLoadAll}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            setAnchorEl={setAnchorEl}
          />
          {/* <Filters callOperation={callOperation} /> */}
        </Popover>
      </>
    );
  }

  return (
    <Tooltip title={title || name} placement={name === 'Translate' ? 'top' : 'bottom'}>
      <IconButton onClick={handleClick} disabled={hide}>
        {name === 'Add' && <AddIcon />}
        {name === 'Accept' && <AddShoppingCartIcon />}
        {name === 'Check' && <CheckBoxIcon />}
        {name === 'Copy' && <ContentCopyIcon />}
        {name === 'Dashboard' && <AssessmentOutlinedIcon />}
        {name === 'Delete' && <DeleteIcon />}
        {name === 'Deselect' && <DeselectIcon />}
        {name === 'DeleteFolder' && <FolderOffIcon />}
        {name === 'Download' && <GetAppIcon />}
        {name === 'Cancel' && <CancelOutlinedIcon />}
        {name === 'Reject' && <RemoveShoppingCartIcon />}
        {name === 'Edit' && <EditIcon />}
        {name === 'All Compliant' && <GavelIcon />}
        {name === 'Select All Allocation' && <HowToRegIcon />}
        {name === 'Select All' && <SelectAllIcon />}
        {name === 'Favorite' && <StarIcon />}
        {name === 'FavoriteRed' && (
          <StarIcon
            style={{
              color: 'red',

              border: 'solid 1px',

              borderRadius: '50%',

              width: 30,

              height: 30,
            }}
          />
        )}
        {name === 'FullWidth' && <OpenInFullIcon />}
        {name === 'Height' && <WrapTextIcon />}
        {name === 'iSimilarizer' && <FileCopyOutlinedIcon />}
        {name === 'NewLine' && <FormatLineSpacingIcon />}
        {name === 'NewCapture' && <ControlPointIcon />}
        {name === 'Concat' && <PlaylistAddIcon />}
        {name === 'Merge' && <MergeIcon />}
        {name === 'MergeRepeat' && <HorizontalSplitIcon />}
        {name === 'PieChart' && <PieChartIcon />}
        {name === 'Restore' && <RestoreIcon />}
        {name === 'Refresh' && <CachedIcon />}
        {name === 'Send for Rephrased Text' && <PublishedWithChangesIcon />}
        {name === 'Send' && <SendIcon />}
        {name === 'Start Analysis' && <BorderColorIcon />}
        {name === 'Save' && <SaveIcon />}
        {name === 'SaveRed' && (
          <SaveIcon
            style={{
              color: 'red',
              border: 'solid 2px',
              borderRadius: '50%',
              padding: '1px',
              width: 30,
              height: 30,
            }}
          />
        )}
        {name === 'Split' && <CallSplit />}
        {name === 'RefreshRed' && (
          <CachedIcon
            style={{
              color: 'red',
              border: 'solid 2px',
              borderRadius: '50%',
              padding: '1px',
              width: 30,
              height: 30,
            }}
          />
        )}
        {name === 'Activate' && <GroupAddOutlinedIcon />}

        {name === 'Translation' && (
          <TranslationList
            languages={languages}
            languageCode={languageCode}
            languageOriginCode={languageOriginCode}
            onClick={onTranslate}
          />
        )}
        {loading && (
          <CircularProgress
            style={{
              padding: '1px',
              width: 15,
              height: 15,
            }}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default withPlugin(ToolBarPlugin);
