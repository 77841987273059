import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import { TreeItem, TreeView } from '@material-ui/lab';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ProjectBreadCrumbs from '../../components/Core/ProjectBreadCrumbs';
import TreeAllStyle from '../../components/TreeMenu/TreeAllStyle';
import TreeArowIcons from '../../components/TreeMenu/TreeArowIcons';
import ProjectRequirements from './components/ProjectRequirements';
import { statsRequirements } from '../../api/projects';
import { workloadGetAll } from '../../api/workloads';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  content: {
    width: '100%',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: '0px 16px 32px #00000029',
    '&[aria-selected="true"][aria-expanded="true"]>div:nth-of-type(1)': {
      backgroundColor: 'red',
    },
  },
  select: {
    '& > *': { fontWeight: '600 !important', fontSize: theme.typography.body1 },
  },
  formControl: {
    margin: theme.spacing(2),
    backgroundColor: '#FFFFFF',
    width: '95%',
  },
}));
type KpiId = {
  workloadId: any;
  filename: any;
  setting?: any;
};
function ProjectReq() {
  const classes = TreeAllStyle();
  const classess = useStyles();
  const arowClasses = TreeArowIcons();
  const { projectId } = useParams() as any;
  const [kpiIds, setKpiIds] = useState<KpiId[] | undefined>(undefined);
  const [kpiSelection, setKpiSelection] = useState<KpiId | undefined>(undefined);
  useEffect(() => {
    statsRequirements(projectId).then((resStats) => {
      workloadGetAll(projectId).then((resWorkload) => {
        let kpiList = [] as KpiId[];
        resWorkload
          .filter((r) => {
            return r.app === 'module1';
          })
          .forEach((r) => {
            kpiList = kpiList.concat(
              r.resources.map((resource) => {
                const resStat = resStats.find(
                  (stats) =>
                    stats.filename === resource.filename &&
                    Number(stats.workload_id) === Number(r.id)
                );
                return {
                  workloadId: r.id,
                  filename: resource.filename,
                  settings: resource.settings,
                  count: resStat?.count || 0,
                };
              })
            );
          });
        setKpiIds(kpiList);
      });
    });
  }, [projectId]);

  const handleKpiDocument = (e) => {
    if (e.target.value === 'all') {
      setKpiSelection(undefined);
      return;
    }
    setKpiSelection(JSON.parse(e.target.value));
  };

  return (
    <div className={classes.root}>
      <Grid container>
        {/* <Grid item xs={12}>
          <ProjectBreadCrumbs
            projectId={projectId}
            nodes={[
              {
                label: 'Requirements',
                to: `#`,
              },
            ]}
          />
        </Grid> */}
        <TreeView
          className={classes.treeRoot}
          defaultCollapseIcon={<ExpandMoreIcon className={arowClasses.ArowdownStyle} />}
          defaultExpandIcon={<KeyboardArrowUpOutlinedIcon className={arowClasses.ArowupStyle} />}
          defaultExpanded={['0']}
        >
          <TreeItem
            nodeId="0"
            label={
              <Typography variant="subtitle1" className={classes.treeLabel}>
                <span>
                  <FormatListBulletedRoundedIcon className={classes.treeIcon} />
                </span>
                Requirements
              </Typography>
            }
            className={classes.treeitem}
            classes={{
              label: classes.label,
              selected: classes.label,
              content: classes.content,
              group: classes.group,
            }}
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="stretch"
              spacing={0}
              // className={clsx(classes.bloc)}
            >
              <FormControl variant="outlined" className={classess.formControl}>
                <InputLabel id="label-select-document">Project Documents</InputLabel>
                <Select
                  fullWidth
                  label="Documents"
                  name="document"
                  onChange={handleKpiDocument}
                  defaultValue="all"
                  className={classess.select}
                >
                  <MenuItem key="all-documents" value="all" className={classess.select}>
                    <Typography>all documents</Typography>
                  </MenuItem>
                  {kpiIds &&
                    kpiIds.map((cap: KpiId, index: number) => {
                      return (
                        <MenuItem
                          key={`document-${index}`}
                          value={JSON.stringify(cap)}
                          className={classess.select}
                        >
                          <Typography>
                            {cap.settings?.nameOfCapture || cap.workloadId} / {cap.filename}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <ProjectRequirements
              file_name={kpiSelection?.filename}
              workload_id={kpiSelection?.workloadId}
            />
          </TreeItem>
        </TreeView>
      </Grid>
    </div>
  );
}

export default ProjectReq;
