import { Table, TableHeaderRow, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

export function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const TStyles = (Theme: any) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd):hover': {
      backgroundColor: '#F4F5F9',
    },
    '& .MuiPagination-root': {
      backgroundColor: 'red',
    },
    '& .RootBase-root': {
      backgroundColor: 'red',
    },
    '& tbody tr:nth-of-type(even):hover': {
      backgroundColor: '#F4F5F9',
    },
    '& thead tr:nth-of-type(odd)': {
      backgroundColor: Theme.palette.primary.main,
      '& :hover': {
        color: 'white !important',
        opacity: 0.8,
      },
      '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
        color: 'White !important',
      },
      '& .MuiTableSortLabel-icon': {
        color: 'white !important',
        opacity: 1,
      },
      '& .MuiCheckbox-root': {
        color: 'white !important',
        opacity: 1,
      },
    },
    '& tr': {
      backgroundColor: 'white',
    },
    '& th': {
      color: 'white',
      fontWeight: 'bold',
    },
    // '& thead th:nth-of-type(1)': {
    //   color: 'white',
    //   backgroundColor: Theme.palette.secondary.main,
    // },
  },
});

export const TStylesAloc = (Theme: any) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd):hover': {
      backgroundColor: '#F4F5F9',
    },
    '& .MuiPagination-root': {
      backgroundColor: 'red',
    },
    '& .RootBase-root': {
      backgroundColor: 'red',
    },
    '& tbody tr:nth-of-type(even):hover': {
      backgroundColor: '#F4F5F9',
    },
    '& thead tr:nth-of-type(odd)': {
      backgroundColor: Theme.palette.primary.main,
      '& :hover': {
        color: 'white !important',
        opacity: 0.8,
      },
      '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
        color: 'White !important',
      },
      '& .MuiTableSortLabel-icon': {
        color: 'white !important',
        opacity: 1,
      },
      '& .MuiCheckbox-root': {
        color: 'white !important',
        opacity: 1,
      },
    },
    '& tr': {
      backgroundColor: 'white',
    },
    '& th': {
      color: 'white',
      fontWeight: 'bold',
    },
    '& thead th:nth-of-type(1)': {
      color: 'white',
      backgroundColor: Theme.palette.secondary.main,
    },
  },
});

export const tUseStyles = makeStyles((theme: Theme) => ({
  resultPanel: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: '2px !important',
  },
  panelMain: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
    padding: '0px !important',
  },
  tableRadius: {
    borderRadius: '10px',
  },
  tollbarStyle: {
    minHeight: 0,
    backgroundColor: '#EEF1F9',
    borderRadius: '20px',
    // border: '1px solid black',
    // borderRight: '1px solid black',
    '& .MuiIconButton-label': {
      color: '#002A45 !important',
      border: '1px solid white',
      borderRadius: '100%',
      padding: 5,
      background: 'white',
    },
    '& .MuiIconButton-root': {
      padding: 5,
    },
  },
  tollbarSchStyle: {
    minHeight: 0,
    backgroundColor: '#EEF1F9',
    borderRadius: '20px',
    // border: '1px solid black',
    // borderRight: '1px solid black',
    '& .MuiIconButton-label': {
      color: '#002A45 !important',
      border: '1px solid white',
      borderRadius: '100%',
      padding: 5,
      background: 'white',
    },
    '& .MuiIconButton-root': {
      padding: 5,
    },
    '&.MuiToolbar-root': {
      justifyContent: 'flex-end !important',
      // color: 'black',
    },
  },
}));
// Background for Filter
export const rowComponent = ({ ...restProps }) => {
  return <Table.Row {...restProps} style={{ backgroundColor: '#EEF1F9' }} />;
};
export const multiTableRowComponent: React.FunctionComponent<Table.RowProps> = (props) => {
  return <TableHeaderRow.Row {...props} style={{ backgroundColor: 'rgba(2,60,97,0.9)' }} />;
};
// table header style
export const tableHeaderComponent: React.FunctionComponent<Table.RowProps> = (props) => {
  return (
    <Table.TableHead
      {...props}
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 400,
      }}
    />
  );
};
// table container style
export const tableContainerComponent: React.FunctionComponent<Table.CellProps> = (props) => {
  return <Table.Container {...props} style={{ maxHeight: '70vh', overflowY: 'auto' }} />;
};
// table split view capture
export const tableContainerCaptureSplitComponent: React.FunctionComponent<Table.CellProps> = (
  props
) => {
  return <Table.Container {...props} style={{ maxHeight: '50vh', overflowY: 'auto' }} />;
};
// table Allocator container style
export const tableAllocatorComponent: React.FunctionComponent<Table.CellProps> = (props) => {
  return <Table.Container {...props} style={{ maxHeight: '80vh', overflowY: 'auto' }} />;
};
// table toobar style
export const toolbarComponent: React.FunctionComponent<Toolbar.RootProps> = (props) => {
  const classes = tUseStyles();
  return <Toolbar.Root {...props} className={classes.tollbarStyle} />;
};
export const toolbarSceduleComponent: React.FunctionComponent<Toolbar.RootProps> = (props) => {
  const classes = tUseStyles();
  return <Toolbar.Root {...props} className={classes.tollbarSchStyle} />;
};
export default TStyles;
