function extractOriginalLang(i18n: {}, langCheck: any, languagesCode: any[]) {
  // const { i18n } = response[0]?.row_capture || {};
  const keyss = Object?.keys(i18n);
  const obj = {
    OriginalLang: '',
    transCode: '',
  } as any;

  if (keyss.length === 0) {
    return obj;
  }

  if (langCheck) {
    const orignalLangCode = languagesCode.filter((data: any) => {
      return data.lang === langCheck;
    });
    if (orignalLangCode[0]?.code === 'en') {
      obj.OriginalLang = 'en';
      obj.transCode = 'en';
    } else {
      obj.OriginalLang = orignalLangCode[0]?.code;
      obj.transCode = '-';
    }
  } else {
    const originalText = keyss.filter((data) => {
      return !['fr', 'de', 'it', 'es', 'en'].includes(data);
    });
    if (originalText[0] !== undefined) {
      // eslint-disable-next-line prefer-destructuring
      obj.OriginalLang = originalText[0];
      obj.transCode = '-';
    } else {
      obj.OriginalLang = 'en';
      obj.transCode = 'en';
    }
  }

  return obj;
}

export default extractOriginalLang;
