import { SnackbarContentProps } from '@material-ui/core';
import { AlertProps } from '@material-ui/lab';
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { getAllImportedBreakdown } from '../../api/breakdowns';
import {
  getAllProjectCreationLanguages,
  getProjectAnalysisDataSet,
  getProjectGroupsUsers,
} from '../../api/projects';
import { getUserByProjectId, searchUsers } from '../../api/users';
import AlertSnack from '../../components/Alert/AlertSnack';
import { Artifact, Project } from '../../react-app-env';

export interface Module1ContextType {
  project: Project;
  setProject: (d: Project) => any;
  artifacts: Artifact[];
  setArtifacts: (d: Artifact) => any;
  setSnackMsg: (msg: SnackbarContentProps['message']) => any;
  setSnackSeverity: (severity: AlertProps['severity']) => any;
  setSnack: (msg: string, severity?: AlertProps['severity'], duration?: number) => any;
  translationCode?: string;
  setTranslationCode?: any;
  languagesCode?: any;
}

const Module1Context = React.createContext<Module1ContextType>({
  project: {} as any,
  setProject: (): any => {},
  artifacts: [] as any,
  setArtifacts: (): any => {},
  setSnackMsg: (): any => {},
  setSnackSeverity: (): any => {},
  // combi msg + severity
  setSnack: (): any => {},
  translationCode: undefined,
  setTranslationCode: (): any => {},
});

type Props = {
  project: Project;
  artifacts?: Artifact[];
  children: any;
};

const Module1ContextProvider = ({ project, artifacts, children }: Props): any => {
  const [translationCode, setTranslationCode] = useState('');
  const [_project, _setProject] = useState(project);
  const [_artifacts, _setArtifacts] = useState(artifacts);
  const [snackMsg, setSnackMsg] = useState<SnackbarContentProps['message']>('');
  const [snackDuration, setSnackDuration] = useState<number>(3000);
  const [snackSeverity, setSnackSeverity] = useState<AlertProps['severity']>('success');
  const [languagesCode, setLanguagesCode] = useState([]);

  useEffect(() => {
    const dis_nonpm = project?.modelpm === 'dis_nonpm2020' ? 'dis_nonpm2020' : undefined;
    const obj = { analysis_dataset: [], groupusers: [], groups: [], groupsImport: {}, users: [] };
    Promise.all([
      getProjectAnalysisDataSet(Number(project?.id)),
      getUserByProjectId(Number(project?.id)),
      getProjectGroupsUsers(Number(project?.id)),
      getAllImportedBreakdown(Number(project?.id), dis_nonpm),
    ])
      .then((paylaod) => {
        const result = paylaod.map((data, index) => {
          if (data[0]?.analysis_dataset) {
            obj.analysis_dataset = data[0].analysis_dataset ?? [];
          }
          if (data[0]?.groupusers) {
            obj.groupusers = data[0]?.groupusers ?? [];
          }
          if (data[0]?.groups) {
            obj.groups = data[0]?.groups ?? [];
          }
          if (data[0]?.users) {
            obj.users = data[0]?.users ?? [];
          }
          return data;
        });
        obj.groupsImport = paylaod[paylaod.length - 1] ?? [];
        const temp = { ...project, ...obj };
        _setProject(temp);
      })
      .catch((error) => {
        const temp = { ...project, ...obj };
        _setProject(temp);
      });
    getAllProjectCreationLanguages().then((dbProjects) => {
      const db = dbProjects.map((item) => {
        return { lang: item.language, code: item.codes.toLowerCase() };
      });

      // console.log(db);
      setLanguagesCode(db);
    });
  }, []);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackMsg('');
  };
  return (
    <Module1Context.Provider
      value={{
        project: _project,
        setProject: (_input) => {
          if (!isEqual(project, _input)) _setProject(_input);
        },
        artifacts: _artifacts || ([] as any),
        setArtifacts: (_input) => {
          if (!isEqual(project, _input)) _setArtifacts(_input as any);
        },
        setSnackMsg,
        setSnackSeverity,
        setSnack: (
          msg: string,
          severity = 'success' as AlertProps['severity'],
          duration = 3000
        ) => {
          setSnackDuration(duration);
          setSnackMsg(msg);
          setSnackSeverity(severity);
        },
        translationCode,
        setTranslationCode,
        languagesCode,
      }}
    >
      {snackMsg !== '' && (
        <AlertSnack
          msg={snackMsg}
          severity={snackSeverity}
          handleClose={handleClose}
          duration={3000}
        />
      )}
      {children}
    </Module1Context.Provider>
  );
};

export default Module1Context;

export { Module1ContextProvider };
