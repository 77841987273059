/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import { Box, Grid, List, ListItem, ListItemText, Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import clsx from 'clsx';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccountCircle, Description, Language, Timer, CenterFocusStrong } from '@material-ui/icons';
import { TreeItem, TreeView } from '@material-ui/lab';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Loading from '../../../../components/Core/Loading';
import ProjectContext from '../../../Project/ProjectContext';
import Module1Context from '../../Module1Context';
import Run1Context from '../../RunContext';
import DocumentReview from './components/DocumentReview';
import TreeArowIcons from '../../../../components/TreeMenu/TreeArowIcons';
import Capturestatus from './Capturestatus';
import formatDate from '../../../../tools/formatDate';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    '& > *': {
      width: '25ch',
    },
    '& .MuiTreeItem-iconContainer svg': {
      fontSize: '18px',
      position: 'relative',
      left: '-20px',
    },
    // border: '2px solid #002A45',
    borderRadius: '5px',
    // padding: '43px 40px 20px 51px',
    // boxShadow: '0px 16px 32px #00000029',
    // '&[aria-selected="true"][aria-expanded="true"]>div:nth-of-type(1)': {
    //   backgroundColor: 'red',
    // },
    '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      background: 'none !important',
      // backGroundColor: '#fafafa',
    },
  },
  blockPdf: {
    height: '100%',
    // borderRadius: 10,
    // border: 'solid 1px;',
    borderColor: theme.palette.primary.light,
    // margin: 10,
    backgroundColor: '#EEF1F9',
    color: '#002A45',
  },
  bloc: {
    height: '100%',
    // borderRadius: 10,
    // border: 'solid 1px;',
    borderColor: theme.palette.primary.light,
  },
  overflow: {
    height: 250,
    overflowY: 'scroll',
    wordWrap: 'break-word',
  },
  iconMargin: {
    marginRight: '16px',
    color: theme.palette.primary.dark,
  },
  title: {
    textAlign: 'center',
  },
  error: {
    color: theme.palette.error.main,
  },
  tabs: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  tabstyle: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px 5px 0 0',
    // backgroundColor: theme.palette.primary.main,
    color: '#fff !important',
    fontSize: 14,
    fontWeight: 700,
  },
  pdfPanel: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    margin: 10,
    padding: '0px !important',
  },
  captureTypography: {
    backgroundColor: '#002A45',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    color: 'white',
  },
  treeitem: {
    width: '100%',
  },
  // treeLabel: {
  //   borderRadius: '13px',
  //   paddingLeft: 10,
  //   backgroundColor: theme.palette.primary.light,
  //   color: 'white',
  // },
  treeLabel: {
    borderBottom: '1px solid #002A45',
    paddingLeft: 10,
    color: '#002A45',
    font: 'normal normal bold 23px/23px Alstom',
    letterSpacing: '0px',
    padding: '0 0 10px 0',
    '&:hover': {
      background: 'none',
    },
    '&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: '#F4F5F9',
    },
  },
  label: {
    '&hover': {
      backgroundColor: 'none',
    },
    '&.MuiTreeItem-label': {
      width: '100%',
      position: 'relative',
      paddingLeft: '0px',
    },
  },
  group: {
    margin: 0,
    padding: 0,
  },
  content: {
    flexDirection: 'row-reverse',
  },
  ArowupStyle: {
    height: '24px',
    width: '24px',
    marginRight: '15px',
  },
  ArowdownStyle: {
    height: '24px',
    width: '24px',
    marginRight: '15px',
  },
  treeIcon: {
    width: '40px',
    height: '40px',
    transform: 'translateY(8px)',
    marginRight: '10px',
  },
  containerBox: {
    borderRadius: '5px',
    width: '600px',
    // height: '300px',
    display: 'flex',
    flexBasis: '50%',
    flexDirection: 'column',
    border: '1px solid #002A45',
  },
  childBox0: {
    display: 'flex',
    backgroundColor: '#002a45',
    alignItems: 'center',
    color: 'white',
    font: 'normal normal normal 15px/15px Alstom',
  },
  childBox1: {
    display: 'flex',
    backgroundColor: '#DAE2F8',
    alignItems: 'center',
    color: '#002A45',
    font: 'normal normal normal 15px/15px Alstom',
    flexDirection: 'row',
  },
  childBox10: {
    display: 'flex',
    // backgroundColor: 'green',
    // alignItems: 'center',
    // color: '#002A45',
    flexBasis: '50%',
    font: 'normal normal normal 15px/15px Alstom',
    padding: '0.5rem 0.5rem',
    // marginLeft: '-3rem',
  },
  childBox11: {
    display: 'flex',
    // backgroundColor: 'red',
    // alignItems: 'center',
    // color: '#002A45',
    flexBasis: '50%',
    font: 'normal normal normal 15px/15px Alstom',
    padding: '0.5rem 0.5rem',
    // paddingRight: '7rem',
  },
  childBox2: {
    display: 'flex',
    backgroundColor: '#EEF1F9',
    // alignItems: 'center',
    color: '#002A45',
    font: 'normal normal normal 15px/15px Alstom',
    flexDirection: 'row',
  },
  childBox21: {
    display: 'flex',
    // backgroundColor: 'green',
    // alignItems: 'center',
    // color: '#002A45',
    flexBasis: '50%',
    font: 'normal normal normal 15px/15px Alstom',
    // padding: '0.5rem 0.5rem',
  },
  childBox22: {
    display: 'flex',
    // backgroundColor: 'red',
    // alignItems: 'center',
    // color: '#002A45',
    flexBasis: '50%',
    font: 'normal normal normal 15px/15px Alstom',
    // padding: '0.5rem 0.5rem',
  },
}));

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    // maxWidth: 500,
    background: 'none !important',
    border: '1px solid black',
    // boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: 0,
    transition: 'opacity 1s',
    '& .MuiTooltip-arrow': {
      color: '#eef1f9',
      '&:before': {
        backgroundColor: '#002A45',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid black`,
        fontSize: 50,
      },
    },
  },
});

function StepReview() {
  const classes = useStyles();
  const { projectId } = useParams() as any;
  const [loading, setLoading] = useState(true);
  const { resources, workload } = useContext(Run1Context);
  const { userJWT } = useContext(ProjectContext);
  const { project } = useContext(Module1Context);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [captureTime, setCaptureTime] = React.useState('');
  useEffect(() => {
    const temp = resources[0]?.results?.CapturedIn?.split('.');
    if (temp !== undefined) {
      setCaptureTime(temp[0]);
    } else {
      setCaptureTime('00:00:00');
    }
  }, []);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    console.log(event);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (resources) {
      setLoading(false);
    }
  }, [resources]);

  if (!resources || loading) return <Loading />;
  if (resources.length === 0) return <></>;

  // const ms = 0;
  // const pms = (
  //   (window.performance.timing.domContentLoadedEventEnd -
  //     window.performance.timing.navigationStart) /
  //   360
  // ).toFixed(0); // your input string
  // const convertMinsToHrsMins = (value) => {
  //   const sec = parseInt(value, 10); // convert value to number if it's string
  //   let hours = Math.floor(sec / 3600); // get hours
  //   let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  //   let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  //   // add 0 if value < 10; Example: 2 => 02
  //   if (hours < 10) {
  //     hours = `0${hours}`;
  //   }
  //   if (minutes < 10) {
  //     minutes = `0${minutes}`;
  //   }
  //   if (seconds < 10) {
  //     seconds = `0${seconds}`;
  //   }
  //   return `${hours}:${minutes}:${seconds}`;
  // };
  // const MinsToHrsMins = convertMinsToHrsMins(pms);
  // const MinsToHrsMinsPdf = convertMinsToHrsMins(pms);

  return (
    <div>
      <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={3}>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon className={classes.ArowdownStyle} />}
          defaultExpandIcon={<KeyboardArrowUpOutlinedIcon className={classes.ArowupStyle} />}
          defaultExpanded={['resource-key-0']}
        >
          {resources
            ?.filter((resource) => {
              return resource.results;
            })
            .map((resource, index) => {
              const querystring = `filename=${encodeURI(
                resource.filename
              )}&project_id=${projectId}&ooo=ooo&folder_id=${resource.folderId}&---token=${
                userJWT?.access_token
              }`;

              const fileNameExt = new URL(
                String(`${process.env.REACT_APP_BACKEND_URL}/download/resources?${querystring}`)
              ).searchParams
                .get('filename')
                ?.split('.')
                .pop()
                ?.toLowerCase();

              return (
                <TreeItem
                  nodeId={`resource-key-${index}`}
                  key={`resource-key-${index}`}
                  label={
                    <Typography variant="subtitle2" className={classes.treeLabel}>
                      {resource.scope.FileType === 'PDF' ? (
                        <span>
                          <PictureAsPdfIcon className={classes.treeIcon} />
                        </span>
                      ) : (
                        <span>
                          <FileCopyOutlinedIcon className={classes.treeIcon} />
                        </span>
                      )}
                      {resource.filename}
                      <CustomWidthTooltip
                        placement="bottom-start"
                        arrow
                        // open={true}
                        title={
                          <>
                            <Box className={classes.containerBox}>
                              <Box className={classes.childBox0}>
                                <span style={{ paddingLeft: '0.5rem' }}>
                                  <InfoIcon />
                                </span>{' '}
                                <h3 style={{ paddingLeft: '0.5rem' }}>Status</h3>
                              </Box>
                              <Box className={classes.childBox1}>
                                <Box className={classes.childBox10}>
                                  <Typography variant="h6" className={classes.title}>
                                    Capture Report
                                  </Typography>
                                </Box>
                                <Box className={classes.childBox11}>
                                  <Typography variant="h6" className={classes.title}>
                                    Capture Setting
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className={classes.childBox2}>
                                <Box className={classes.childBox21}>
                                  <List>
                                    <ListItem key="details-username">
                                      <AccountCircle className={classes.iconMargin} />
                                      <ListItemText>
                                        <b>{workload?.created_by?.name}</b> <br />
                                        <small>{formatDate(new Date().toString())}</small>
                                      </ListItemText>
                                    </ListItem>
                                    {fileNameExt === 'xlsx' || fileNameExt === 'xls' ? (
                                      <>
                                        <ListItem key="details-pages">
                                          <Description className={classes.iconMargin} />
                                          <ListItemText>
                                            <b>Sheet Name: </b>{' '}
                                            {resource.scope?.Sheets[0].SheetName}{' '}
                                          </ListItemText>
                                        </ListItem>
                                        <ListItem key="details-time">
                                          <Timer className={classes.iconMargin} />
                                          <ListItemText>Captured in {captureTime}</ListItemText>
                                        </ListItem>
                                        <ListItem key="File-ext">
                                          <PictureAsPdfIcon className={classes.iconMargin} />
                                          <ListItemText>
                                            File Type: <b>{fileNameExt}</b>{' '}
                                          </ListItemText>
                                        </ListItem>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                    {fileNameExt === 'csv' ? (
                                      <>
                                        <ListItem key="details-time">
                                          <Timer className={classes.iconMargin} />
                                          <ListItemText>Captured in {captureTime}</ListItemText>
                                        </ListItem>
                                        <ListItem key="File-ext">
                                          <PictureAsPdfIcon className={classes.iconMargin} />
                                          <ListItemText>
                                            File Type: <b>{fileNameExt}</b>{' '}
                                          </ListItemText>
                                        </ListItem>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                    {fileNameExt === 'pdf' ? (
                                      <>
                                        <ListItem key="details-pages">
                                          <Description className={classes.iconMargin} />
                                          <ListItemText>
                                            {resource.scope?.NumberOfPages} Pages
                                          </ListItemText>
                                        </ListItem>
                                        <ListItem key="details-time">
                                          <Timer className={classes.iconMargin} />
                                          <ListItemText>Captured in {captureTime}</ListItemText>
                                        </ListItem>
                                        <ListItem key="File-ext">
                                          <PictureAsPdfIcon className={classes.iconMargin} />
                                          <ListItemText>
                                            File Type <b>{fileNameExt}</b>{' '}
                                          </ListItemText>
                                        </ListItem>
                                        <ListItem key="Capture-name">
                                          <CenterFocusStrong className={classes.iconMargin} />
                                          <ListItemText>
                                            Capture Name <b>{resource.results.NameOfCapture}</b>{' '}
                                          </ListItemText>
                                        </ListItem>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                    {fileNameExt === 'docx' ? (
                                      <>
                                        <ListItem key="details-time">
                                          <Timer className={classes.iconMargin} />
                                          <ListItemText>Captured in {captureTime}</ListItemText>
                                        </ListItem>
                                        {/* <ListItem key="Capture-name">
                                        {/* <CenterFocusStrongI className={classes.iconMargin} /> */}
                                        {/* <ListItemText>
                                          Capture Name <b>{resource.results.NameOfCapture}</b>{' '}
                                        </ListItemText>
                                      </ListItem> */}
                                      </>
                                    ) : (
                                      ''
                                    )}
                                    <ListItem key="details-lang">
                                      <Language className={classes.iconMargin} />
                                      <ListItemText>
                                        {resource.scope?.Language || resource?.settings?.language}
                                      </ListItemText>
                                    </ListItem>
                                  </List>
                                </Box>
                                <Box className={classes.childBox22}>
                                  <List>
                                    <ListItem key="project-name">
                                      <ListItemText>
                                        Project Name: <b>{project.name}</b>{' '}
                                      </ListItemText>
                                    </ListItem>
                                    {(fileNameExt === 'csv' ||
                                      fileNameExt === 'xlsx' ||
                                      fileNameExt === 'xls') && (
                                      <>
                                        <ListItem key="Capture-name">
                                          {/* <CenterFocusStrongi className={classes.iconMargin} /> */}
                                          <ListItemText>
                                            Target Capture: <b>{resource.results.TargetColumn}</b>{' '}
                                          </ListItemText>
                                        </ListItem>
                                        {fileNameExt === 'csv' && (
                                          <ListItem key="records">
                                            <ListItemText>
                                              No Of Records:{' '}
                                              <b>{resource.scope?.NumberOfRecords || 0}</b>
                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; No Of Columns:{' '}
                                              <b>{resource.scope?.NumberOfColumns || 0}</b>
                                            </ListItemText>
                                          </ListItem>
                                        )}
                                        {(fileNameExt === 'xlsx' || fileNameExt === 'xls') && (
                                          <ListItem key="records">
                                            <ListItemText>
                                              No Of Records:{' '}
                                              <b>
                                                {resource.scope?.Sheets[0].NumberOfRecords || 0}
                                              </b>
                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; No Of Columns:{' '}
                                              <b>
                                                {resource.scope?.Sheets[0].NumberOfColumns || 0}
                                              </b>
                                            </ListItemText>
                                          </ListItem>
                                        )}
                                      </>
                                    )}
                                    {fileNameExt === 'pdf' && (
                                      <>
                                        {resource.settings?.typesOfCapture ===
                                          'Requirement_Capture' &&
                                          String(resource.settings.regId || '').trim().length && (
                                            <ListItem key="regId">
                                              <ListItemText>
                                                Reg ID Reg Exp: <b>{resource.settings.regId}</b>
                                              </ListItemText>
                                            </ListItem>
                                          )}

                                        <ListItem key="page">
                                          <ListItemText>
                                            From page: <b>{resource.results.StartPage}</b> to{' '}
                                            <b>{resource.results.EndPage}</b>
                                          </ListItemText>
                                        </ListItem>
                                        <ListItem key="typeOfCapture">
                                          <ListItemText>
                                            Type Of Capture: <b>{resource.results.TypeOfCapture}</b>
                                          </ListItemText>
                                        </ListItem>
                                        <ListItem key="smartTableOfContent">
                                          <ListItemText>
                                            Remove header footer from extraction:{' '}
                                            <b>
                                              {resource.settings?.smartTableOfContent === 'true' ? (
                                                <b>Yes</b>
                                              ) : (
                                                <b>No</b>
                                              )}
                                            </b>
                                          </ListItemText>
                                        </ListItem>
                                        <ListItem key="smartHeaderFooter">
                                          <ListItemText>
                                            Remove table of contents:{' '}
                                            <b>
                                              {resource.settings?.smartHeaderFooter === 'true' ? (
                                                <b>Yes</b>
                                              ) : (
                                                <b>No</b>
                                              )}
                                            </b>
                                          </ListItemText>
                                        </ListItem>
                                        <ListItem key="mergeBullet">
                                          <ListItemText>
                                            Merge bullets:{' '}
                                            <b>
                                              {resource.settings?.mergeBullet === true ? (
                                                <b>Yes</b>
                                              ) : (
                                                <b>No</b>
                                              )}
                                            </b>
                                          </ListItemText>
                                        </ListItem>
                                      </>
                                    )}
                                    {resource.results.Warning && resource.results.Warning !== '' && (
                                      <>
                                        <ListItem key="Warning">
                                          <ListItemText>
                                            <b>{resource.results.Warning}</b>
                                          </ListItemText>
                                        </ListItem>
                                      </>
                                    )}
                                    {fileNameExt === 'docx' && (
                                      <>
                                        <ListItem key="filetype">
                                          <ListItemText>
                                            File Type: <b>{fileNameExt}</b>
                                          </ListItemText>
                                        </ListItem>
                                        <ListItem key="NameOfCapture">
                                          <ListItemText>
                                            Capture Name: <b>{resource.results.NameOfCapture}</b>
                                          </ListItemText>
                                        </ListItem>
                                        <ListItem key="smartTableOfContent">
                                          <ListItemText>
                                            Remove header footer from extraction:{' '}
                                            <b>
                                              {resource.settings?.smartTableOfContent === true ? (
                                                <b>Yes</b>
                                              ) : (
                                                <b>No</b>
                                              )}
                                            </b>
                                          </ListItemText>
                                        </ListItem>
                                        <ListItem key="smartHeaderFooter">
                                          <ListItemText>
                                            Remove table of contents:{' '}
                                            <b>
                                              {resource.settings?.smartHeaderFooter === true ? (
                                                <b>Yes</b>
                                              ) : (
                                                <b>No</b>
                                              )}
                                            </b>
                                          </ListItemText>
                                        </ListItem>
                                        <ListItem key="TargetColumn">
                                          <ListItemText>
                                            Target Capture: <b>{resource.results.TargetColumn}</b>{' '}
                                          </ListItemText>
                                        </ListItem>
                                      </>
                                    )}
                                  </List>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        }
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </CustomWidthTooltip>
                    </Typography>
                  }
                  className={classes.treeitem}
                  classes={{
                    label: classes.label,
                    selected: classes.label,
                    content: classes.content,
                    group: classes.group,
                  }}
                >
                  {workload && querystring && (
                    <DocumentReview
                      urlFile={`${process.env.REACT_APP_BACKEND_URL}/download/resources?${querystring}`}
                      results={resource.results}
                      scope={resource.scope}
                      settings={resource.settings}
                      projectname={project.name}
                      workload={workload}
                      onClose={undefined}
                    />
                  )}
                </TreeItem>
              );
            })}
        </TreeView>
      </Grid>
    </div>
  );
}

export default StepReview;
