import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Box, Paper, Snackbar, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  adminHistoryDelete,
  getAllAdminHistory,
  getAllAdminProjects,
  projectsDelete,
} from '../../../api/projects';
import Loading from '../../../components/Core/Loading';
import NavigationGoto from '../../../components/Core/NavigationGoto';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import TStyles, { rowComponent, toolbarComponent } from '../../../components/Datagrid/TStyles';
import formatDate, { formatLocal } from '../../../tools/formatDate';
import Module1Context from '../../Module1/Module1Context';
import AdminContext from '../AdminContext';
import ModalConfirm from './ModalConfirm';

export const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, padding: theme.spacing(2) },
  bloc: {
    minHeight: 250,
    borderRadius: 10,
    // border: 'solid 1px;',
    borderColor: theme.palette.primary.light,
    margin: 10,
  },
  error: {
    color: theme.palette.error.main,
  },
  heading: {
    backgroundColor: '#DC3223',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
  },
  success: {
    background: 'lightgreen',
    border: 'solid 1px green',
    borderRadius: 5,
    padding: 5,
  },
}));

const AdminHistory = () => {
  const classes = useStyles();
  const [myProjects, setMyProjects] = useState<any[]>([]);
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const { setSnack } = useContext(AdminContext);
  const [tempPage, setTemPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [dis, setDis] = useState(true);
  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };
  const exporterRef = useRef(null);
  const [selection, setSelection] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [myMsg, setMyMsg] = useState('');
  const [pageSizes] = useState([5, 10, 20, 50]);
  const columns2 = [
    { name: 'id', title: 'Id' },
    { name: 'project_id', title: 'Project Id' },
    { name: 'project_name', title: 'Project Name' },
    { name: 'user_id', title: 'User Id' },
    { name: 'module', title: 'Module' },
    { name: 'action', title: 'Action' },
    { name: 'user_name', title: 'User Name' },
    { name: 'created_at', title: 'Created Time' },
    { name: 'error', title: 'Error' },
  ];

  const getAllProjectsList = () => {
    getAllAdminHistory().then((dbProjects: any[]) => {
      //   const tempProjects = dbProjects.map((project) => {
      //     return {
      //       ...project,
      //       createdBy: project.owner.name,
      //     };
      //   });
      setMyProjects(dbProjects);
      setSelection([]);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getAllProjectsList();
  }, []);
  useEffect(() => {
    if (pageSize > myProjects.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < myProjects.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);

  const deleteProjects = () => {
    if (selection.length === 0) return;
    const rowsNotDeleted = myProjects.filter((row, index) => !selection.includes(index));
    const rowsDeleted = myProjects.filter((row, index) => selection.includes(index));
    const deleteIds = rowsDeleted.map((row) => row.id);
    adminHistoryDelete(deleteIds)
      .then(() => {
        setMyProjects(rowsNotDeleted);
        setSelection([]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setSnack('Selected History has been deleted', 'success');
      });
  };

  const handleDeleteConfirm = () => {
    const selectedRows = myProjects
      .filter((row, index) => selection.includes(index))
      .map((row) => {
        return row.id;
      });
    projectsDelete(selectedRows).then((delresp: any) => {
      setMyMsg('Successfully deleted projects');
      getAllProjectsList();
    });
    setModalOpen(false);
  };
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onDownload = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };
  const TableComponentBase = ({ classes, ...restProps }: any) => (
    <Table.Table {...restProps} className={classes.tableStriped} />
  );

  const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

  return (
    <div className={classes.root}>
      <Paper>
        <Snackbar
          open={myMsg !== ''}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          onClose={() => {
            setMyMsg('');
          }}
        >
          <Alert severity="success">{myMsg}</Alert>
        </Snackbar>
        <Box className={classes.bloc}>
          <Typography variant="subtitle1" className={classes.heading}>
            History
          </Typography>
          {showMsg && <span className={classes.success}>Successfully activated new users</span>}
          {loading ? (
            <Loading />
          ) : (
            <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
              <ExpressGrid
                rows={myProjects}
                columns={[
                  { name: 'id', title: 'Id' },
                  { name: 'project_id', title: 'Project Id' },
                  { name: 'project_name', title: 'Project Name' },
                  { name: 'user_id', title: 'User Id' },
                  { name: 'module', title: 'Module' },
                  { name: 'action', title: 'Action' },
                  { name: 'user_name', title: 'User Name' },
                  { name: 'created_at', title: 'Created Time' },
                  { name: 'error', title: 'Error' },
                ]}
              >
                <DataTypeProvider
                  for={['created_at']}
                  formatterComponent={({ value }) => (
                    <span>{formatDate(value, formatLocal('en'))}</span>
                  )}
                />
                <FilteringState />
                <IntegratedFiltering />
                <SelectionState selection={selection} onSelectionChange={setSelection} />
                <IntegratedSelection />
                <SortingState defaultSorting={[{ columnName: 'name', direction: 'asc' }]} />
                <IntegratedSorting />
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />
                <Table
                  tableComponent={TableComponent}
                  // columnExtensions={[
                  //   { columnName: 'id', width: '50px' },
                  //   { columnName: 'project_id', width: '50px' },
                  //   { columnName: 'project_name', width: '200px' },
                  //   { columnName: 'user_id', width: '200px' },
                  //   { columnName: 'module', width: '200px' },
                  //   { columnName: 'action', width: '200px' },
                  //   { columnName: 'user_name', width: '200px' },
                  //   { columnName: 'created_at', width: '200px' },
                  //   {
                  //     columnName: 'error',
                  //     width: '200px',
                  //   },
                  // ]}
                />
                <TableColumnResizing
                  defaultColumnWidths={[
                    { columnName: 'id', width: '100px' },
                    { columnName: 'project_id', width: '100px' },
                    { columnName: 'project_name', width: '100px' },
                    { columnName: 'user_id', width: '70px' },
                    { columnName: 'module', width: '100px' },
                    { columnName: 'action', width: '100px' },
                    { columnName: 'user_name', width: '100px' },
                    { columnName: 'created_at', width: '100px' },
                    {
                      columnName: 'error',
                      width: '50px',
                    },
                  ]}
                />
                <TableHeaderRow showSortingControls />
                <TableFilterRow rowComponent={rowComponent} />
                <Toolbar rootComponent={toolbarComponent} />
                <PagingPanel pageSizes={pageSizes} />
                <ToolBarPlugin name="Delete" onClick={deleteProjects} />
                <ToolBarPlugin name="Download" onClick={startExport} />
                <TableSelection
                  showSelectAll
                  selectByRowClick
                  highlightRow
                  showSelectionColumn={true}
                />
              </ExpressGrid>
              <GridExporter
                ref={exporterRef}
                rows={myProjects}
                columns={columns2}
                onSave={onDownload}
              />
              <NavigationGoto
                setPageSize={setPageSize}
                fileLength={myProjects?.length}
                pageSize={pageSize}
                dis={dis}
                setTemPage={setTemPage}
                handlePageNum={handlePageNum}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Box>
          )}
          {modalOpen && (
            <ModalConfirm
              open={modalOpen}
              onConfirm={handleDeleteConfirm}
              onCancel={() => {
                setModalOpen(false);
              }}
              label="ProjectDelete"
            />
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default AdminHistory;
