import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

function PageLayout({ component: Component, render, ...rest }: RouteProps & any) {
  const classes = useStyles();

  return (
    <Route
      {...rest}
      render={(props: any): any => (
        <div className={classes.root}>
          {Component && <Component {...props} />}
          {render && render()}
        </div>
      )}
    />
  );
}

export default PageLayout;
