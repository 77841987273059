import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, { useContext, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import docIcon from '../../../../../../../assets/docx_ico.png';
import xlsIcon from '../../../../../../../assets/xlsx_ico.png';
import DocAnalysisContext from '../../../DocAnalysisContext';
import excelFilereducers, { excelInitialState } from '../../../../../Reducers/excelFilereducer';
// import excelFilereducers, { excelInitialState } from '../../../Reducers/excelFilereducer';

const LeftBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const RightBlock = styled.div`
  flex: 1;
  display: flex;
  //   flex-direction: column;
  justify-content: flex-end;
`;

const MainDiv = styled.div`
  display: flex;
`;

const ExcelFileSettings = ({ index, settingsData }) => {
  const {
    operation,
    setOperation,
    scopeSetting,
    selection,
    setSelection,
    setScopeSetting,
    fileSetting,
    setFileSettings,
    // state,
    // dispatch,
  } = useContext(DocAnalysisContext);
  const [state, dispatch] = useReducer(excelFilereducers, excelInitialState);

  useEffect(() => {
    setFileSettings((prev) => {
      return {
        ...prev,
        [index]: {
          ...state,
        },
      };
    });
  }, [state]);

  const HandleReg = (e, indexIs) => {
    dispatch({ type: 'change_sheets', payload: e.target.value });

    const { Sheets } = settingsData[0];
    const filterSheetDetails = Sheets.filter((data) => {
      return data.SheetName === e.target.value;
    });
    const obj = {
      columnName: filterSheetDetails[0].ColumNames,
      filename: settingsData[0].FileName,
    };
    dispatch({ type: 'change_columns', payload: obj });
  };

  const HandleTargetColumn = (e, indexIs) => {
    dispatch({ type: 'change_targetColumn', payload: e.target.value });
  };

  return (
    <>
      <MainDiv>
        <LeftBlock>
          {settingsData?.map((c, indexIs) => (
            <FormControl variant="outlined">
              <span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                {c?.FileType === 'unsupported' ? <img src={docIcon} alt="isens" height="30" /> : ''}
                &nbsp;
                {c?.FileType === 'Excel' ? <img src={xlsIcon} alt="isens" height="30" /> : ''}
                {c?.FileType !== 'unsupported' ? (
                  <span>
                    <span style={{ color: 'red' }}>{c?.Error}</span>
                  </span>
                ) : (
                  ''
                )}
                <Tooltip title={c?.Blob} placement="top" arrow>
                  <b style={{ color: 'blue', wordWrap: 'break-word' }}>
                    {c?.Blob.substring(0, 25)}
                  </b>
                </Tooltip>
              </span>
            </FormControl>
          ))}
        </LeftBlock>
        <RightBlock>
          {settingsData.map((c, indexIs) => {
            return (
              <>
                {' '}
                {c.FileType === 'Excel' && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      gap: '20px',
                      width: '80%',
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      size="small"
                      style={{ flexBasis: '50%', height: '2.5rem' }}
                    >
                      <InputLabel
                        htmlFor="outlined-age-native-simple"
                        style={{ color: '#002A45', fontWeight: 'bold' }}
                        size="small"
                      >
                        Sheet Column
                      </InputLabel>
                      <Select
                        native
                        label="Sheet  Name"
                        size="small"
                        name={'sheetname'.concat(indexIs)}
                        // name="sheetname"
                        style={{ width: '100%' }}
                        onChange={(e) => HandleReg(e, indexIs)}
                        defaultValue=""
                      >
                        <option aria-label="None" value="" disabled />
                        {c?.Sheet_names !== null
                          ? c?.Sheet_names.map((cap: string, index: number) => (
                              <option key={`capture-type-${index}`} value={cap}>
                                &nbsp;&nbsp;{cap}
                              </option>
                            ))
                          : ''}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      size="small"
                      style={{ flexBasis: '50%', height: '2.5rem' }}
                    >
                      <InputLabel
                        htmlFor="outlined-age-native-simple"
                        style={{ color: '#002A45', fontWeight: 'bold' }}
                      >
                        Target Column
                      </InputLabel>
                      <Select
                        native
                        label="Target  Column"
                        name="targetColumn"
                        style={{ width: '100%' }}
                        onChange={(e) => HandleTargetColumn(e, indexIs)}
                        defaultValue=""
                      >
                        <option aria-label="None" value="" disabled />
                        {state?.columns !== null
                          ? state?.columns.map((cap: string, index: number) => (
                              <option key={`capture-type-${index}`} value={cap}>
                                &nbsp;&nbsp;{cap}
                              </option>
                            ))
                          : ''}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </>
            );
          })}
        </RightBlock>
      </MainDiv>
    </>
  );
};

export default ExcelFileSettings;
