import {
  Box,
  CircularProgress,
  Dialog,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useRef, useContext } from 'react';
import { useForm } from 'react-hook-form';
import Module1Context from '../../../Module1Context';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formInput: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginBottom: 5,
  },
  dialogContent: {
    backgroundColor: '#FBFBFB',
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
}));

type Props = {
  onClose?: any;
  onSubmit?: any;
  targetColumn?: any;
  rows?: any;
  selection?: any;
  setFiles?: any;
};

export default function ModalAddCharacterizerRow({
  onClose,
  onSubmit,
  targetColumn,
  rows,
  selection,
  setFiles,
}: Props) {
  const classes = useStyles();
  const [subtype, setSubtype] = useState('');
  const refFile = useRef();
  const [characterization, setCharacterization] = useState('');
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState(false);
  const { setSnack } = useContext(Module1Context);
  const [file, setFile] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({});

  const handleSubtype = (e: any) => {
    setImages(false);
    if (e.target.value === 'Image') {
      setImages(true);
    }
    setSubtype(e.target.value);
  };
  const handleCharacterization = (e: any) => {
    setCharacterization(e.target.value);
  };
  const handleClose = () => {
    reset();
    setLoading(false);
    if (onClose) onClose();
  };

  const handleNewRow = (data) => {
    if (data.subtype === 'Image' && file.file === undefined) {
      setSnack('Please upload the image(PNG,JPEG,JPG).', 'error');
      return;
    }
    if (file.file !== undefined) {
      const fileExtension = file.file.name.toLowerCase().split('.').pop();
      if (data.subtype !== 'Image') {
        setSnack('Please Select Subtype Image.', 'error');
        return;
      }
      if (!['jpeg', 'png', 'jpg'].includes(fileExtension)) {
        setSnack('Please Select images(PNG,JPEG,JPG)', 'error');
        return;
      }
    }
    if (onSubmit) {
      onSubmit(data, file);
    }
    setLoading(true);
  };

  const uploadJPGImage = () => {
    // console.log(refFile);
    refFile?.current?.click();
  };

  const handleFileChange = (e) => {
    setFile({ file: e.target.files[0] });
    setSnack('Image Attached', 'success');
    // console.log(rows[selection]);
  };

  return (
    <>
      <input type="file" hidden={true} ref={refFile} onChange={handleFileChange} />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          Add new line
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form>
            {!images && (
              <>
                <Box className={classes.formInput}>
                  <TextField
                    {...register('capture', { required: true })}
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    label="Enter text"
                    placeholder="Enter text"
                    required
                    error={errors.capture}
                    helperText="Text is required"
                  />
                </Box>
              </>
            )}
            {!targetColumn && (
              <>
                <Box className={classes.formInput}>
                  <InputLabel error={errors.subtype}>Subtype *</InputLabel>
                  <Select
                    {...register('subtype', { required: true })}
                    required
                    fullWidth
                    label="Subtype"
                    value={subtype}
                    onChange={handleSubtype}
                  >
                    <MenuItem value="" />
                    {['Heading', 'Paragraph', 'Bullet', 'ParaBullet', 'Image'].map((sub) => (
                      <MenuItem value={sub}>{sub}</MenuItem>
                    ))}
                  </Select>
                </Box>
              </>
            )}
            {!images && (
              <>
                <Box className={classes.formInput}>
                  <InputLabel error={errors.characterization}>Characterization *</InputLabel>
                  <Select
                    {...register('characterization', { required: true })}
                    required
                    fullWidth
                    label="Characterization"
                    value={characterization}
                    onChange={handleCharacterization}
                  >
                    <MenuItem value="" />
                    {['Requirement', 'Information'].map((sub) => (
                      <MenuItem value={sub}>{sub}</MenuItem>
                    ))}
                  </Select>
                </Box>
              </>
            )}
            {images && (
              <>
                <Box className={classes.formInput}>
                  <InputLabel error={errors.characterization}>Characterization *</InputLabel>
                  <Select
                    {...register('characterization', { required: true })}
                    required
                    fullWidth
                    label="Characterization"
                    value={characterization}
                    onChange={handleCharacterization}
                  >
                    <MenuItem value="" />
                    {['Image'].map((sub) => (
                      <MenuItem value={sub}>{sub}</MenuItem>
                    ))}
                  </Select>
                </Box>
              </>
            )}
            {!targetColumn && (
              <>
                <Box className={classes.formInput}>
                  <TextField
                    {...register('hierarchy')}
                    fullWidth
                    variant="standard"
                    label="Hierarchy"
                    placeholder="Hierarchy"
                  />
                </Box>
                <Box className={classes.formInput}>
                  <TextField
                    {...register('context')}
                    fullWidth
                    variant="standard"
                    label="Context"
                    placeholder="Context"
                    error={errors.context}
                  />
                </Box>
              </>
            )}
            <Box className={classes.formInput}>
              <TextField
                {...register('comment')}
                multiline
                rows={3}
                fullWidth
                variant="outlined"
                label="Comment"
                placeholder="Comment"
              />
            </Box>
            <Box className={classes.formInput}>
              <TextField
                {...register('Cybersecurity')}
                multiline
                rows={3}
                fullWidth
                variant="outlined"
                label="Cybersecurity"
                placeholder="Cybersecurity"
              />
            </Box>
            <Box className={classes.formInput}>
              <TextField
                {...register('Standards')}
                multiline
                rows={3}
                fullWidth
                variant="outlined"
                label="Standards"
                placeholder="Standards"
              />
            </Box>
            <Box className={classes.formInput}>
              <TextField
                {...register('Commercial')}
                multiline
                rows={3}
                fullWidth
                variant="outlined"
                label="Commercial"
                placeholder="Commercial"
              />
            </Box>
            <Box className={classes.formInput}>
              <TextField
                {...register('Finance')}
                multiline
                rows={3}
                fullWidth
                variant="outlined"
                label="Finance"
                placeholder="Finance"
              />
            </Box>
            <Box className={classes.formInput}>
              <TextField
                {...register('Contractual')}
                multiline
                rows={3}
                fullWidth
                variant="outlined"
                label="Contractual"
                placeholder="Contractual"
              />
            </Box>
          </form>
        </DialogContent>
        <div style={{ display: 'flex' }}>
          {images && (
            <>
              <div style={{ flexBasis: '50%', display: 'flex', justifyContent: 'flex-start' }}>
                <DialogActions className={classes.dialogContent}>
                  <Button color="primary" variant="contained" onClick={uploadJPGImage}>
                    Upload Image
                  </Button>
                </DialogActions>
              </div>
            </>
          )}
          <div
            style={{
              flexBasis: images ? '50%' : '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <DialogActions className={classes.dialogContent}>
              <Button autoFocus onClick={handleClose} color="default" variant="contained">
                Cancel
              </Button>
              <Button
                onClick={handleSubmit(handleNewRow)}
                color="primary"
                variant="contained"
                endIcon={loading && <CircularProgress size={20} color="secondary" />}
              >
                Add new line
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
    </>
  );
}
