import { Column } from '@devexpress/dx-react-grid';
import columnsCapture from './colmunsCapture';
import { columnsAnalyze, columnsAnalyzeMulti } from './columnsAnalyze';
import { columnsAssign, columnsAssignMulti } from './columnsAssign';
import columnsCharacterize from './columnsCharacterize';
import { columnsOwn, columnsOwnMulti } from './columnsOwning';

export type optionsColumnsType = {
  targetColumn?: string | undefined;
  displayColumns?: any;
  levelsHierarchy?: any;
  isRegExp?: boolean;
  entity?: string;
};

export type columnFuncType = (
  optionColumns: optionsColumnsType,
  isExported?: boolean
) => (Column | string)[] | string[] | Column[];

export const allColumns = (
  page:
    | 'capture'
    | 'characterize'
    | 'assign'
    | 'assign_multi'
    | 'own'
    | 'own_multi'
    | 'analyze'
    | 'analyze_multi',
  optionColumns: optionsColumnsType,
  isExported = false
) => {
  switch (page) {
    case 'capture':
      return columnsCapture(optionColumns);
    case 'characterize':
      return columnsCharacterize(optionColumns, isExported);
    case 'assign':
      return columnsAssign(optionColumns, isExported);
    case 'assign_multi':
      return columnsAssignMulti(optionColumns);
    case 'own':
      return columnsOwn(optionColumns, isExported);
    case 'own_multi':
      return columnsOwnMulti(optionColumns, isExported);
    case 'analyze':
      return columnsAnalyze(optionColumns, isExported);
    case 'analyze_multi':
      return columnsAnalyzeMulti(optionColumns, isExported);
    default:
      return [];
  }
};
