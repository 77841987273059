import { makeStyles, Table } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

const tableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    container: {
      maxHeight: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    subTd: {
      marginLeft: 26,
    },
  })
);

export const TableStyled = withStyles((theme) => {})(Table);
export const DatagridStyled = withStyles((theme) => {})(DataGrid);

export default tableStyles;
