import ExcelDoc from '../../../assets/icons8-excel-48.png';
import PptDoc from '../../../assets/icons8-powerpoint-48.png';
import DocxDoc from '../../../assets/icons8-microsoft-word-2019-48.png';
import PdfDoc from '../../../assets/pdf_ins.png';
import CsvDoc from '../../../assets/csv_icons.png';
import defaultFileIcon from '../../../assets/default_file_icon.png';

export default ExcelDoc;

export { PptDoc, DocxDoc, PdfDoc, CsvDoc, defaultFileIcon };
