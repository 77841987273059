/* eslint-disable import/prefer-default-export */
import { AxiosError } from 'axios';
import { IverifierConfigWrite } from 'WorkloadType';
import { BACKEND_URL } from '../const';
import axios from './axios';

export const getTemplates = async (isPublished = null): Promise<any> => {
  try {
    let querystring = '';
    if (isPublished !== null) {
      querystring = `?is_published=${isPublished}`;
    }
    const response = await axios.get(`${BACKEND_URL.root}/iverifier/templates${querystring}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const updateTemplate = async (body: any): Promise<any> => {
  try {
    const response = await axios.put(`${BACKEND_URL.root}/iverifier/templates/${body.id}`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const saveTemplate = async (body?: any): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/iverifier/templates`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const deleteTemplatesBulk = async (body?: any[]): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/iverifier/templates/bulk/delete`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const getRules = async (
  projectId?: number,
  templateId?: number,
  view?: 'flat' | 'hierarchy'
): Promise<any> => {
  try {
    let querystring = `?`;
    if (projectId) querystring += `&project_id=${projectId}`;
    if (templateId) querystring += `&template_id=${templateId}`;
    if (view) querystring += `&view=${view}`;
    const response = await axios.get(`${BACKEND_URL.root}/iverifier/rules${querystring}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const getFunctions = async (projectId?: number, templateId?: number): Promise<any> => {
  try {
    let querystring = `?`;
    if (projectId) querystring += `&project_id=${projectId}`;
    if (templateId) querystring += `&template_id=${templateId}`;
    const response = await axios.get(`${BACKEND_URL.root}/iverifier/functions${querystring}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// RULES

export const editRule = async (ruleId: any, body?: any): Promise<any> => {
  try {
    const response = await axios.put(`${BACKEND_URL.root}/iverifier/rules/${ruleId}`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const addRule = async (body?: any): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/iverifier/rules`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const saveRulesBulk = async (templateId: string, body?: any[]): Promise<any> => {
  try {
    const querystring = `?template_id=${templateId}`;
    const response = await axios.post(
      `${BACKEND_URL.root}/iverifier/rules/bulk/save${querystring}`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const deleteRulesBulk = async (body?: any[]): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/iverifier/rules/bulk/delete`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const duplicateRulesBulk = async (body?: any[]): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/iverifier/rules/bulk/duplicate`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// FUNCTIONS

export const saveFunctionsBulk = async (templateId: string, body?: any[]): Promise<any> => {
  try {
    const querystring = `?template_id=${templateId}`;
    const response = await axios.post(
      `${BACKEND_URL.root}/iverifier/functions/bulk/save${querystring}`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const deleteFuncBulk = async (body?: any[]): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/iverifier/functions/bulk/delete`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const duplicateFuncBulk = async (body?: any[]): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/iverifier/functions/bulk/duplicate`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const editFunc = async (id: any, body?: any): Promise<any> => {
  try {
    const response = await axios.put(`${BACKEND_URL.root}/iverifier/functions/${id}`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const addFunc = async (body?: any): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/iverifier/functions`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// PROJECT CONFIG

export const getProjectConfigs = async (project_id?: number, config_id?: number): Promise<any> => {
  try {
    let url = `${BACKEND_URL.root}/iverifier/project_configs`;
    let querystring = '';
    if (project_id) {
      querystring = `?project_id=${project_id}`;
    }
    if (config_id) {
      if (!project_id) {
        return Promise.reject(new Error('missing project_id'));
      }
      url += `/${config_id}`;
    }
    const response = await axios.get(`${url}${querystring}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const createProjectConfig = async (
  body?: IverifierConfigWrite,
  importFunction = true
): Promise<any> => {
  try {
    let querystring = '';
    if (importFunction) {
      querystring = `?import_functions=true`;
    }
    const response = await axios.post(
      `${BACKEND_URL.root}/iverifier/project_configs${querystring}`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const updateProjectConfig = async (body?: IverifierConfigWrite): Promise<any> => {
  try {
    const response = await axios.put(
      `${BACKEND_URL.root}/iverifier/project_configs/${body?.id}?project_id=${body?.project_id}`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const deleteConfigsBulk = async (body?: any[]): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/iverifier/project_configs/bulk/delete`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const duplicateConfigsBulk = async (body?: any[]): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/iverifier/project_configs/bulk/duplicate`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
