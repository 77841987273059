/* eslint-disable consistent-return */
import axios from '../../../api/axios';
import { BACKEND_URL } from '../../../const';
import formatBytes from '../../../tools/formatBytes';
import supportedfiles from '../const/supportedfiles';

// eslint-disable-next-line consistent-return
const uploadURLCall = async (uploadUrl: any, formdata: FormData) => {
  try {
    const uplResponse = await axios.post(uploadUrl, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return uplResponse.data;
    // setFiles(allResources);
  } catch (error) {
    console.log(error);
  }
};

const UserUploadFile = (event, files, user_id) => {
  const obj = {
    message: '',
    status: '',
    data: [],
    iconLoad: false,
  };
  try {
    const len = Object?.keys(event)?.length;
    const arraylist: any[] = [];
    for (let i = 0; i < len; i += 1) {
      arraylist.push(event[i]);
    }
    for (const eachFile of arraylist) {
      const ext = eachFile?.name?.split('.');
      if (!supportedfiles.includes(ext[ext?.length - 1])) {
        obj.message = `${eachFile.name} not supported`;
        obj.status = 'error';
        // setSnack(`${eachFile.name} not supported`, 'error');
        return obj;
      }
      if (eachFile.name?.length >= 80) {
        obj.message = 'file name should be less than 80 characters';
        obj.status = 'error';
        // setSnack('file name should be less than 80 characters', 'error');
        return obj;
      }
    }

    const formdata = new FormData();

    const names = arraylist.map((e: any) => {
      formdata.append('files', e);
      return e.name;
    });

    for (const eachFile of files) {
      if (names.includes(eachFile.filename)) {
        obj.message = `File name ${eachFile.filename} already exists.`;
        obj.status = 'error';
        // setSnack(`File name ${eachFile.filename} already exists.`, 'error');
        return obj;
      }
    }
    return new Promise((resolve, reject) => {
      if (formdata.get('files')) {
        // setIconload(true);
        const uploadUrl = BACKEND_URL.uploadUserDoc.replace('userId', user_id);

        uploadURLCall(uploadUrl, formdata).then((result) => {
          if (result) {
            const { resources } = result[0];
            const newUploadfile = resources.filter((data: any) => {
              return names.includes(data.filename);
            });
            const NewData = newUploadfile.map((data) => {
              const obj = {
                ...data,
                size: formatBytes(Number(data.size), 0),
                id: data.etag,
                folderName: 'user',
                folderId: 0,
                isNew: true,
              };
              return obj;
            });
            obj.data = [...NewData];
            obj.iconLoad = false;
            obj.message = 'Successfully Uploaded';
            obj.status = 'success';
            resolve(obj);
            //   setFiles((prev: any) => {
            //     return [...NewData, ...prev];
            //   });
            //   setIconload(false);
            //   setSnack('Successfully Uploaded', 'success');
          }
        });
      } else {
        obj.message = 'Unsuccessfully Uploaded';
        obj.status = 'error';
        reject(obj);
      }
    });
    return obj;
  } catch (error) {
    console.log(error);
  }
};

const UserUploadGlossaryFile = (event, files, user_id, fromLang, toLang) => {
  const obj = {
    message: '',
    status: '',
    data: [],
    iconLoad: false,
  };

  // console.log(event);
  try {
    // if (!fromLang) {
    //   obj.message = `please select form Language`;
    //   obj.status = 'error';
    //   // setSnack(`${eachFile.name} not supported`, 'error');
    //   return obj;
    // }

    // if (!toLang) {
    //   obj.message = `please select To Language`;
    //   obj.status = 'error';
    //   // setSnack(`${eachFile.name} not supported`, 'error');
    //   return obj;
    // }

    const len = Object?.keys(event)?.length;
    const arraylist: any[] = [];
    for (let i = 0; i < len; i += 1) {
      arraylist.push(event[i]);
    }
    for (const eachFile of arraylist) {
      const ext = eachFile?.name?.split('.');
      if (!['csv'].includes(ext[ext?.length - 1])) {
        obj.message = `Please Upload CSV. ${eachFile.name} is not supported.`;
        obj.status = 'error';
        // setSnack(`${eachFile.name} not supported`, 'error');
        return obj;
      }
      if (eachFile.name?.length >= 80) {
        obj.message = 'file name should be less than 80 characters';
        obj.status = 'error';
        // setSnack('file name should be less than 80 characters', 'error');
        return obj;
      }
    }

    const formdata = new FormData();
    const names = arraylist.map((e: any) => {
      formdata.append('files', e);
      return e.name;
    });
    for (const eachFile of files) {
      if (names.includes(eachFile.filename)) {
        obj.message = `File name ${eachFile.filename} already exists.`;
        obj.status = 'error';
        // setSnack(`File name ${eachFile.filename} already exists.`, 'error');
        return obj;
      }
    }

    return new Promise((resolve, reject) => {
      if (formdata.get('files')) {
        // setIconload(true);
        const uploadUrl = `${BACKEND_URL.uploadUserGlossaryDoc.replace(
          'userId',
          user_id
        )}?from=${fromLang}&source=${toLang}`;

        uploadURLCall(uploadUrl, formdata).then((result) => {
          if (result) {
            const { resources } = result[0];
            const newUploadfile = resources.filter((data: any) => {
              return names.includes(data.filename);
            });
            const NewData = newUploadfile.map((data) => {
              const obj = {
                ...data,
                size: formatBytes(Number(data.size), 0),
                id: data.etag,
                folderName: 'user',
                folderId: 0,
                isNew: true,
              };
              return obj;
            });
            obj.data = [...NewData, ...files];
            obj.iconLoad = false;
            obj.message = 'Successfully Uploaded';
            obj.status = 'success';
            resolve(obj);
            //   setFiles((prev: any) => {
            //     return [...NewData, ...prev];
            //   });
            //   setIconload(false);
            //   setSnack('Successfully Uploaded', 'success');
          }
        });
      } else {
        obj.message = 'Unsuccessfully Uploaded';
        obj.status = 'error';
        reject(obj);
      }
    });
  } catch (e) {
    console.log(e);
  }
  return obj;
};

export { UserUploadGlossaryFile };
export default UserUploadFile;
