import { CircularProgress, Dialog, TextareaAutosize } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React, { useReducer, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RestoreIcon from '@mui/icons-material/Restore';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  pdfPanel: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    margin: 5,
    padding: 5,
    minHeight: 100,
    width: '100%',
  },
  dialogContent: {
    backgroundColor: '#FBFBFB',
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
}));

type Props = {
  requirement?: any;
  onClose?: any;
  onSubmit?: any;
  targetColumn?: string;
};

const MAX_SPLIT = 10;

export default function ModalSplit({ requirement, onClose, onSubmit, targetColumn }: Props) {
  const initReducer = {
    multi_text: new Array(MAX_SPLIT)
      .fill(targetColumn ? requirement[targetColumn] : requirement.Text, 0, 1)
      .fill('', 1, MAX_SPLIT),
  };
  const reducer = (
    state: {
      multi_text: string[];
    },
    action: {
      type: any;
      payload?: string;
    }
  ) => {
    if (action.type === 'init') {
      return initReducer;
    }
    if (action.type >= 0) {
      const { multi_text } = state;
      multi_text[action.type] = String(action.payload || '');
      return { ...state, multi_text };
    }
    return { ...state, [action.type]: action.payload };
    // return { ...state, [action.type]: action.payload };
  };

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [splitList, setSplitList] = useState([{ text: '' }]);
  // reducer for tab authorization

  const [formControl, dispatch] = useReducer(reducer, initReducer);

  const handleClose = () => {
    setLoading(false);
    if (onClose) onClose();
  };

  const handleSplit = () => {
    const body = formControl.multi_text.filter((t) => t.length);
    if (body.length <= 1) return;
    setLoading(true);
    onSubmit({ ...formControl, multi_text: body });
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          Split text{' '}
          <RestoreIcon
            onClick={() => {
              dispatch({ type: 'init' });
            }}
          />
          <RemoveIcon
            onClick={() => {
              if (splitList.length < 2) return;
              setSplitList([...splitList.slice(0, -1)]);
              dispatch({ type: splitList.length - 1, payload: '' });
              // console.log(splitList);
            }}
          />
          <AddIcon
            onClick={() => {
              if (splitList.length > MAX_SPLIT - 1) return;
              setSplitList([...splitList, { text: '' }]);
              // console.log(splitList);
            }}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div>
            {splitList.map((sp, index) => {
              return (
                <>
                  <TextareaAutosize
                    key={`text-input-${index}`}
                    className={classes.pdfPanel}
                    aria-label={`Text ${index + 1}`}
                    placeholder={`Text ${index + 1}`}
                    value={formControl.multi_text[index]}
                    onChange={(e) => {
                      dispatch({ type: index, payload: e.currentTarget.value.trim() });
                    }}
                  />
                </>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogContent}>
          <Button autoFocus onClick={handleClose} color="default" variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleSplit}
            color="primary"
            variant="contained"
            endIcon={loading && <CircularProgress size={20} color="secondary" />}
          >
            Split
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
