import { Breadcrumbs, makeStyles } from '@material-ui/core';
import { Folder } from '@material-ui/icons';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useProjectId from '../../app/useProjectId';
import { Allprojects } from '../../assets/icons';
import logoIcon from '../../assets/logo-icon.png';
import ProjectContext from '../../containers/Project/ProjectContext';

const useStyles = makeStyles((theme) => ({
  // root: { display: 'inline', float: 'left', margin: 10, color: theme.palette.primary.main },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}));

type Props = {
  folderId?: string | number | any;
  nodes?: nodeLink[];
};

const ProjectBreadCrumbs = memo(({ folderId, nodes }: Props) => {
  const classes = useStyles();
  const { projectId } = useParams() as any;
  const contextValue = useContext(ProjectContext);
  const memomizedContextValue = useMemo(() => contextValue, [contextValue]);
  const { projects_lite } = memomizedContextValue;
  // const optimsedPro = useMemo(() => projects, [projects]);
  const optimsedNode = useMemo(() => nodes, [nodes]);
  const { project } = useProjectId(projectId, projects_lite);
  const [folderName, setFolderName] = useState('');
  // console.log('hello render projectBreadCrumbs');
  useEffect(() => {
    // console.log('gelo');
    if (project && project.folders) {
      const myFolder = project.folders.find((fldr: any) => Number(fldr.id) === Number(folderId));
      if (myFolder) setFolderName(myFolder.name);
    }
  }, [folderId, project]);

  const iconStyle = {
    marginRight: 5,
    width: 20,
    height: 20,
    verticalAlign: 'text-bottom',
  };

  return (
    <Breadcrumbs separator=">" aria-label="breadcrumb">
      <Link className={classes.link} to="/projects">
        <img style={iconStyle} alt="iSenS" src={logoIcon} />
        Projects
      </Link>
      <Link className={classes.link} to={`/projects/${projectId}`}>
        <Allprojects style={iconStyle} />
        {project?.name || ''}
      </Link>
      {folderId ? (
        <Link className={classes.link} to={`/projects/${projectId}/folders/${folderId}`}>
          <Folder style={iconStyle} />
          {folderName}
        </Link>
      ) : null}
      {optimsedNode &&
        optimsedNode.map((node: nodeLink, index: number) => {
          if (!node.to || !node.label || node.label.length === 0) return null;
          return (
            <Link className={classes.link} to={node.to} key={`bread-${index}-${node.label}`}>
              {node.icon && React.cloneElement(node.icon, { style: iconStyle })}
              {node.label}
            </Link>
          );
        })}
    </Breadcrumbs>
  );
});

export default ProjectBreadCrumbs;
