import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@material-ui/core';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia } from '@mui/material';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import AutoFitImage from 'react-image-autofit-frame';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    // position: 'absolute',
    // left: theme.spacing(110),
    // color: theme.palette.grey[100],
    position: 'absolute',
    right: theme.spacing(1),
    // paddingBottom: 5,
    top: -8,
    color: theme.palette.grey[500],
  },
  imagePanel: {
    // border: `1px solid ${theme.palette.primary.main}`,
    // borderRadius: 10,
    margin: 25,
    paddingTop: 2.5,
    paddingBottom: 1,
    // minHeight: 200,
    // display: 'flex',
    // justifyContent: 'center',
    // alignContent: 'center',
  },
  mainBox1: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    margin: 35,
    paddingTop: 2.5,
    paddingBottom: 1,
    // width: 1200,
  },
  mainCard1: {
    // width: '100%',
    objectFit: 'none',
    display: 'flex',
    justifyItems: 'center',
    alignContent: 'center',
    height: 500,
    // marginTop: 5,
  },
  mainCardMedia1: {
    width: 1000,
    height: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'contain',
  },
  dialogContent: {
    backgroundColor: '#FBFBFB',
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 1,
  },
  textColor: {
    color: theme.palette.primary.main,
  },
}));
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function GenerateImages({ images, input }) {
  const classes = useStyles();
  const [imageTitle, setImageTitle] = useState('');
  const [maindata, setMaindata] = useState([{ name: 'one' }, { name: 'two' }]);
  useEffect(() => {
    setImageTitle(input);
  }, []);
  return (
    <>
      <h1>{imageTitle}</h1>
      <Grid container>
        <Grid item xs={12}>
          <Carousel
            swipeable={true}
            showDots={true}
            responsive={responsive}
            infinite={true}
            arrows={true}
            keyBoardControl={true}
            renderArrowsWhenDisabled={true}
            customTransition="all .5"
            transitionDuration={500}
          >
            <Box className={classes.mainBox1}>
              <>
                <Card className={classes.mainCard1}>
                  <TransformWrapper>
                    {({ zoomIn, zoomOut, ...rest }) => (
                      <>
                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                          <IconButton
                            onClick={zoomIn}
                            style={{
                              backgroundColor: '#002A45',
                              height: 35,
                              borderRadius: 5,
                            }}
                          >
                            <ZoomInOutlinedIcon style={{ color: 'white' }} />
                          </IconButton>
                          <IconButton
                            onClick={zoomOut}
                            style={{
                              backgroundColor: '#002A45',
                              height: 35,
                              borderRadius: 5,
                              marginLeft: 5,
                            }}
                          >
                            <ZoomOutOutlinedIcon style={{ color: 'white' }} />
                          </IconButton>
                        </div>
                        {/* <a
                                  href={`data:image/png;base64, ${image.data}`}
                                  download
                                  onClick={(e) => download(e)}
                                >
                                  <FileDownloadIcon
                                    style={{ color: '#002A45' }}
                                    titleAccess="download"
                                  />
                                </a> */}
                        {/* <Button onClick={downloadFile(image.data)} /> */}
                        <TransformComponent>
                          <CardMedia
                            onClick={zoomOut}
                            className={classes.mainCardMedia1}
                            // height="500"
                            component="img"
                            src={images.Link1}
                            // sx={{ padding: '1em 1em 0 1em', objectFit: 'contain' }}
                            // style={{ width: 300, marginLeft: 'auto', marginRight: 'auto' }}
                          />
                        </TransformComponent>
                      </>
                    )}
                  </TransformWrapper>
                </Card>
              </>
            </Box>
            <Box className={classes.mainBox1}>
              <>
                <Card className={classes.mainCard1}>
                  <TransformWrapper>
                    {({ zoomIn, zoomOut, ...rest }) => (
                      <>
                        <div style={{ position: 'absolute', top: 0, left: 10 }}>
                          <IconButton
                            onClick={zoomIn}
                            style={{
                              backgroundColor: '#002A45',
                              height: 35,
                              borderRadius: 5,
                            }}
                          >
                            <ZoomInOutlinedIcon style={{ color: 'white' }} />
                          </IconButton>
                          <IconButton
                            onClick={zoomOut}
                            style={{
                              backgroundColor: '#002A45',
                              height: 35,
                              borderRadius: 5,
                              marginLeft: 5,
                            }}
                          >
                            <ZoomOutOutlinedIcon style={{ color: 'white' }} />
                          </IconButton>
                        </div>
                        {/* <a
                                  href={`data:image/png;base64, ${image.data}`}
                                  download
                                  onClick={(e) => download(e)}
                                >
                                  <FileDownloadIcon
                                    style={{ color: '#002A45' }}
                                    titleAccess="download"
                                  />
                                </a> */}
                        {/* <Button onClick={downloadFile(image.data)} /> */}
                        <TransformComponent>
                          <CardMedia
                            onClick={zoomOut}
                            className={classes.mainCardMedia1}
                            // height="500"
                            component="img"
                            src={images.Link2}
                            // style={{ width: 300, marginLeft: 'auto', marginRight: 'auto' }}
                          />
                        </TransformComponent>
                      </>
                    )}
                  </TransformWrapper>
                </Card>
              </>
            </Box>
            <Box className={classes.mainBox1}>
              <>
                <Card className={classes.mainCard1}>
                  <TransformWrapper>
                    {({ zoomIn, zoomOut, ...rest }) => (
                      <>
                        <div style={{ position: 'absolute', top: 0, left: 10 }}>
                          <IconButton
                            onClick={zoomIn}
                            style={{
                              backgroundColor: '#002A45',
                              height: 35,
                              borderRadius: 5,
                            }}
                          >
                            <ZoomInOutlinedIcon style={{ color: 'white' }} />
                          </IconButton>
                          <IconButton
                            onClick={zoomOut}
                            style={{
                              backgroundColor: '#002A45',
                              height: 35,
                              borderRadius: 5,
                              marginLeft: 5,
                            }}
                          >
                            <ZoomOutOutlinedIcon style={{ color: 'white' }} />
                          </IconButton>
                        </div>
                        {/* <a
                                  href={`data:image/png;base64, ${image.data}`}
                                  download
                                  onClick={(e) => download(e)}
                                >
                                  <FileDownloadIcon
                                    style={{ color: '#002A45' }}
                                    titleAccess="download"
                                  />
                                </a> */}
                        {/* <Button onClick={downloadFile(image.data)} /> */}
                        <TransformComponent>
                          <CardMedia
                            onClick={zoomOut}
                            className={classes.mainCardMedia1}
                            // height="500"
                            component="img"
                            src={images.Link3}
                            // style={{ width: 300, marginLeft: 'auto', marginRight: 'auto' }}
                          />
                        </TransformComponent>
                      </>
                    )}
                  </TransformWrapper>
                </Card>
              </>
            </Box>
          </Carousel>
        </Grid>
      </Grid>
    </>
  );
}

export default GenerateImages;
