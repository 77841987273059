import { AuthenticationResult } from '@azure/msal-browser';
import {
  Button,
  Grid,
  Paper,
  ThemeProvider,
  Typography,
  Tooltip,
  TextField,
} from '@material-ui/core';
import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { AuthenticationAlstom } from 'Auth';
import React, { useEffect, useState } from 'react';
import msalInstance, { scopes } from '../../api/auth';
import { registerUserWithAzToken } from '../../api/users';
import { isensTheme, useStyles } from '../../app/isensTheme';
import useAppToken from '../../app/useAppToken';
import useAzureToken from '../../app/useAzureToken';
import Alstom_logo from '../../assets/images/Alstom_logo.jpg';
import image3_background from '../../assets/images/welcome.png';
import login_icon from '../../assets/images/login_icon.jpg';
import AppTopBarBlank from '../../components/Layout/AppTopBarBlank';
import { getVersion } from '../../api/public';

type UserStateType = 'newuser' | 'errorconnection' | 'user' | 'dberror';

const AlstomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: 12,
    border: '1px solid #dadde9',
  },
}));

export default function LoginTestPage() {
  const { setJWT } = useAzureToken();
  const { setAppJWT } = useAppToken();
  const classes = useStyles();
  const [userState, setUserState] = useState<UserStateType>('user');
  const [errorForUser, setErrorForUser] = useState<any>('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');

  // eslint-disable-next-line no-console
  console.info('Isens App version =', process.env.REACT_APP_VERSION);

  useEffect(() => {
    getVersion().catch((err) => {
      if (err.message === 'Error: Connection error') {
        localStorage.clear();
        sessionStorage.clear();
        setUserState('errorconnection');
      }
    });
  }, []);

  const handleLogin = async () => {
    // clear all storage
    localStorage.clear();
    sessionStorage.clear();

    const request = {
      scopes,
    };

    try {
      // const loginResponse: AuthenticationResult &
      //   AuthenticationAlstom = (await msalInstance.loginPopup(request)) as AuthenticationResult &
      //   AuthenticationAlstom;
      // console.log(loginResponse);
      if (true) {
        const resp = await registerUserWithAzToken({
          name: `${lastName} ${firstName}`,
          // loginResponse.account?.name,
          email,
          // loginResponse.account?.username,
          details: {
            // site_code: loginResponse.idTokenClaims['site code'] as string,
            // job_function: loginResponse.idTokenClaims.jobTitle,
            // department: loginResponse.idTokenClaims.Department,
            site_code: 'BLR1',
            job_function: 'Software Designer',
            department: 'ADM Technology Centre - Software',
          },
        });
        // user need to be activated
        if (resp.is_new_user === true) {
          setUserState('newuser');
        } else {
          // create "token" put all data from SSO
          setJWT(resp);
          // brearer token for axios
          setAppJWT(resp);

          window.location = '/' as any;
        }
      }
    } catch (err) {
      localStorage.clear();
      sessionStorage.clear();
      setUserState('dberror');
      setErrorForUser(String(err).replace('\n', ''));
    }
  };

  const handleLastnameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleFirstnameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <ThemeProvider theme={isensTheme}>
      <div
        className={classes.root}
        style={{
          height: 'auto',
        }}
      >
        {/* <AppTopBarBlank /> */}
        <div
          style={{
            height: '100vh',
            width: '100%',
            background: `url(${image3_background})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div style={{ marginTop: '200px' }}>
            <center>
              <Paper
                style={{
                  // margin: '15rem 33rem 15rem 33rem',
                  width: '65%',
                  boxShadow:
                    '0px 3px 1px -2px #00000070,0px 2px 2px 0px rgba(100,0,0,0.9),0px 1px 5px 0px rgba(30,50,70,0.12)',
                  height: '250px',
                  borderRadius: '20px',
                }}
              >
                <Grid container justify="center">
                  <Grid
                    item
                    style={{
                      textAlign: 'center',
                      padding: 10,
                      minWidth: '200px',
                      borderRadius: '20px',
                      marginTop: '15px',
                    }}
                  >
                    {/* <img src={Alstom_logo} alt="alstomLogo" style={{ width: '70%' }} /> */}
                    {/* <hr /> */}
                    <Grid container spacing={10}>
                      {userState === 'newuser' && (
                        <Grid item xs={12} style={{ marginLeft: '20px' }} alignContent="center">
                          <h3>Please contact iSenS support team to activate your account.</h3>
                        </Grid>
                      )}
                      {userState === 'dberror' && (
                        <>
                          <Grid item xs={12} style={{ marginLeft: '20px' }} alignContent="center">
                            <h3>
                              Please contact iSenS support team.
                              <AlstomTooltip title={errorForUser}>
                                <ErrorIcon style={{ marginLeft: '200px', marginTop: '-20px' }} />
                              </AlstomTooltip>
                            </h3>
                          </Grid>
                        </>
                      )}
                      {userState === 'errorconnection' && (
                        <Grid item xs={12} style={{ marginLeft: '20px' }} alignContent="center">
                          <h3>Connection error</h3>
                        </Grid>
                      )}

                      {userState === 'user' && (
                        <>
                          <Grid container spacing={18} justify="center">
                            {/* <Grid item xs={1} style={{ marginLeft: '20px', marginTop: '30px' }}> */}
                            {/* <img
                                src={login_icon}
                                alt="loginicon"
                                style={{ width: '50px', marginTop: '5px' }}
                              /> */}
                            <TextField
                              label="Firstname"
                              fullWidth
                              margin="normal"
                              value={firstName}
                              onChange={handleFirstnameChange}
                            />
                            <br />
                            <br />
                            <TextField
                              label="Lastname"
                              fullWidth
                              margin="normal"
                              value={lastName}
                              onChange={handleLastnameChange}
                            />
                            <br />
                            <br />
                            <TextField
                              label="Email"
                              fullWidth
                              margin="normal"
                              value={email}
                              onChange={handleEmailChange}
                            />
                            {/* </Grid>
                            <Grid item xs={9} style={{ marginTop: '25px', width: '100%' }}> */}
                            <Button
                              onClick={handleLogin}
                              style={{
                                backgroundColor: 'transparent',
                                floodColor: 'transparent',
                                color: 'transparent',
                              }}
                              disabled={
                                firstName.length === 0 ||
                                lastName.length === 0 ||
                                email.length === 0
                              }
                            >
                              <Typography variant="h5">
                                <span style={{ color: 'black' }}>LOGIN WITH YOUR</span>{' '}
                                <span style={{ color: 'red' }}>ALSTOM</span>{' '}
                                <span style={{ color: 'black' }}>ACCOUNT</span>
                              </Typography>
                            </Button>{' '}
                            {/* </Grid> */}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </center>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
