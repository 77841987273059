import React from 'react';
import './CircularProgressBarCss.css';

const CircularPogressBar = ({ percentage }) => {
  const normalizedPercentage = Math.min(100, Math.max(0, percentage)) as number;
  const circumference = 2 * Math.PI * 40; // Circumference of the circle with r=40

  return (
    <div className="circle-progress-container">
      <svg className="suvgEL" viewBox="0 0 100 100">
        <circle id="circleEL" cx="50" cy="50" r="40">
          {}
        </circle>
        <circle
          //   id="circleEL"
          className="progress-circle"
          cx="50"
          cy="50"
          r="40"
          style={{
            '--percentage': normalizedPercentage,
            '--circumference': circumference,
          }}
        >
          {}
        </circle>
      </svg>
      <div className="circleLabel">
        <p>{percentage} %</p>
      </div>
    </div>
  );
};

export default CircularPogressBar;
