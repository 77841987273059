import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import React from 'react';
import tableStyles from '../../../app/tableStyles';
import BarStackDiv from '../../../components/Charts/BarStackDiv';
import formatNumber from '../../../tools/formatNumber';
import { colorsFiles } from '../ResultContainer';

type Props = {
  rows: Data[];
  displayBar?: boolean;
  stickyRow?: Data;
};

type Data = {
  label: string;
  nok: number;
  ok: number;
  out_of_scope: number;
};

const localStyles = makeStyles(() =>
  createStyles({
    td: {
      width: 140,
    },
  })
);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'label', numeric: false, disablePadding: false, label: 'File' },
  { id: 'ok', numeric: true, disablePadding: false, label: 'OK' },
  { id: 'nok', numeric: true, disablePadding: false, label: 'Not OK' },
  { id: 'out_of_scope', numeric: true, disablePadding: false, label: 'Out Of Scope' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({ rows, displayBar, stickyRow }: Props) {
  const classes = tableStyles();
  const localClasses = localStyles();

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('label');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {};

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="table-artifacts"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stickyRow && (
                <TableRow
                  hover
                  // onClick={(event) => handleClick(event, stickyRow.label)}
                  role="checkbox"
                  tabIndex={-1}
                  key={stickyRow.label}
                >
                  <TableCell component="th" id="total" scope="row" padding="default">
                    <Typography className={classes.subTd} style={{ fontWeight: 'bold' }}>
                      {stickyRow.label}
                    </Typography>
                  </TableCell>
                  {displayBar ? (
                    <TableCell align="center" colSpan={3}>
                      <BarStackDiv
                        key={`BarStackDiv-${stickyRow.label}`}
                        data={[
                          { color: colorsFiles.ok, value: stickyRow.ok },
                          { color: colorsFiles.nok, value: stickyRow.nok },
                          { color: colorsFiles.outOfScope, value: stickyRow.out_of_scope },
                        ]}
                      />
                    </TableCell>
                  ) : (
                    <>
                      <TableCell
                        align="center"
                        className={localClasses.td}
                        style={{ backgroundColor: colorsFiles.ok, fontWeight: 'bold' }}
                      >
                        {formatNumber(stickyRow.ok)}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={localClasses.td}
                        style={{ backgroundColor: colorsFiles.nok, fontWeight: 'bold' }}
                      >
                        {formatNumber(stickyRow.nok)}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={localClasses.td}
                        style={{ backgroundColor: colorsFiles.outOfScope, fontWeight: 'bold' }}
                      >
                        {formatNumber(stickyRow.out_of_scope)}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              )}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.label)}
                      role="checkbox"
                      tabIndex={-1}
                      key={`row-artifact-results-${row.label}-${index}`}
                    >
                      <TableCell component="th" scope="row" padding="default">
                        <Typography className={classes.subTd}>{row.label}</Typography>
                      </TableCell>
                      {displayBar ? (
                        <TableCell align="center" colSpan={3}>
                          <BarStackDiv
                            key={`row-artifact-results-barstick-${row.label}-${index}`}
                            data={[
                              { color: colorsFiles.ok, value: row.ok },
                              { color: colorsFiles.nok, value: row.nok },
                              { color: colorsFiles.outOfScope, value: row.out_of_scope },
                            ]}
                          />
                        </TableCell>
                      ) : (
                        <>
                          <TableCell
                            align="center"
                            className={localClasses.td}
                            style={{ backgroundColor: colorsFiles.ok }}
                          >
                            {formatNumber(row.ok)}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={localClasses.td}
                            style={{ backgroundColor: colorsFiles.nok }}
                          >
                            {formatNumber(row.nok)}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={localClasses.td}
                            style={{ backgroundColor: colorsFiles.outOfScope }}
                          >
                            {formatNumber(row.out_of_scope)}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 5 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
