import React, { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { Avatar, Button, Tooltip, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { extractUserName } from '../../utils/extractdata';
import { lifeCycle } from '../../const/allocatorConst';
import ComplianceMulti from './ComplianceMutli';
import RenderLifeCycleMulti from './RenderLIfeCycleMulti';
import RenderGapOrNC from './RenderGapOrNc';
import HoverToolTipForText from '../../Modal/HoverToolTipForText';

const AvatarComponent = ({ name }) => {
  return (
    <Avatar alt={name} sx={{ width: 24, height: 24, bgcolor: '#002A45' }}>
      {name[0] ?? ''}
    </Avatar>
  );
};

const childRowStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
};

const Analyis = ({ data, user_id }) => {
  const analysisData = useMemo(() => {
    const { allocations, analysis, lifecycles } = data;
    const valuesForAllocation = Object.values(allocations);

    const header = [
      {
        lifecycle: 'Lifecycle',
        pbs: 'PBS',
        compliance: 'compliance',
        nc: 'NC',
        gap: 'GAP',
        rationale: 'Rationale',
      },
    ];

    if (analysis.length === 0) {
      const temp = [] as any;
      for (const item of valuesForAllocation) {
        const obj = {
          lifecycle: item.lifecycle,
          pbs: item.pbs,
          compliance: 'tbd',
          nc: '',
          gap: '',
          rationale: '',
          receiver: item.receiver,
          lifecycles: [],
          id: item.id,
        };
        temp.push(obj);
      }
      return [...header, ...temp];
    }
    const findAnalysis = () => {
      const temp = analysis?.sort((a: any, b: any) => {
        return b?.id - a?.id;
      });

      const analysisDAta = [] as any;
      for (const item of valuesForAllocation) {
        const analysisLast = temp?.find((eachAnalysis) => {
          if (eachAnalysis?.allocation_id === item.id) {
            return true;
          }
          return false;
        });

        if (
          analysisLast &&
          Object.values(analysisLast).length > 0 &&
          !['assigned', 'characterized'].includes(item.lifecycle)
        ) {
          const obj = {
            lifecycle: item.lifecycle,
            pbs: item.pbs,
            compliance: analysisLast.compliance,
            nc: analysisLast.compliance === 'nc' ? 'nc' : '',
            gap: analysisLast.compliance === 'gap' ? 'gap' : '',
            rationale:
              analysisLast.rationale.length > 0
                ? analysisLast.rationale
                    .map((data) => {
                      return data.content;
                    })
                    .join(',')
                : '',
            lifecycles,
            id: item.id,
          };
          analysisDAta.push(obj);
        } else {
          const obj = {
            lifecycle: item.lifecycle,
            pbs: item.pbs,
            compliance: 'tbd',
            nc: '',
            gap: '',
            rationale: '',
            receiver: item.receiver,
            lifecycles: [],
            id: item.id,
          };
          analysisDAta.push(obj);
        }
      }
      return analysisDAta;
    };

    const analysisFound = findAnalysis();

    return [...header, ...analysisFound];
  }, [data]);

  //   console.log(analysisData);

  return (
    <>
      {analysisData.length > 1 && (
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', gap: '5px' }}>
          {analysisData.map((item, index) => {
            return (
              <div style={{ flex: 1, display: 'flex', fontSize: '1.1rem', marginLeft: '5rem' }}>
                <div style={{ ...childRowStyle, marginBottom: index === 0 ? '10px' : 'none' }}>
                  {index === 0 ? (
                    <b>{item.lifecycle}</b>
                  ) : (
                    <RenderLifeCycleMulti
                      lifecycleLabel={item.lifecycle}
                      receiver={item.receiver}
                      user_id={user_id}
                      lifecyclesIs={item.lifecycles}
                      parentId={item.id}
                    />
                  )}
                </div>
                <div style={{ ...childRowStyle, marginBottom: index === 0 ? '10px' : 'none' }}>
                  {index === 0 ? (
                    <b>{item.pbs}</b>
                  ) : (
                    <Typography color="info">{item.pbs}</Typography> || 'tbd'
                  )}
                </div>
                <div style={{ ...childRowStyle, marginBottom: index === 0 ? '10px' : 'none' }}>
                  {index === 0 ? (
                    <b>{item.compliance}</b>
                  ) : (
                    <>
                      <ComplianceMulti
                        renderedCellValue={item.compliance}
                        receiver={item.receiver}
                        user_id={user_id}
                      />
                    </>
                  )}
                </div>
                <div style={{ ...childRowStyle, marginBottom: index === 0 ? '10px' : 'none' }}>
                  {index === 0 ? <b>{item.nc}</b> : <RenderGapOrNC compliance={item.nc} /> || 'tbd'}
                </div>
                <div style={{ ...childRowStyle, marginBottom: index === 0 ? '10px' : 'none' }}>
                  {index === 0 ? (
                    <b>{item.gap}</b>
                  ) : (
                    <RenderGapOrNC compliance={item.gap} /> || 'tbd'
                  )}
                </div>
                <div style={{ ...childRowStyle, marginBottom: index === 0 ? '10px' : 'none' }}>
                  {index === 0 ? (
                    <b>{item.rationale}</b>
                  ) : (
                    <HoverToolTipForText renderedCellValue={item.rationale}>
                      <Typography color="info">{item.rationale.slice(0, 10)}</Typography>
                    </HoverToolTipForText>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Analyis;
