/**
import { queryString } from 'querystring';
 * @param urlHttp
 */

function downloadSourceFile(urlHttp: string, queryString: string): any {
  const URL = process.env.REACT_APP_BACKEND_URL;
  // console.log('url', urlHttp);
  const link = document.createElement('a');
  link.href = `${URL}${urlHttp}?${queryString}`;
  document.body.appendChild(link);
  link.click();
}

export const ZipUrls = (urlHttp: string, queryString: string, _filename: string) => {
  const URL = process.env.REACT_APP_BACKEND_URL;
  const obj = {
    url: `${URL}${urlHttp}?${queryString}`,
    filename: _filename,
  };
  return obj;
};

export default downloadSourceFile;
