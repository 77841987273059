import React, { useMemo, useState } from 'react';
import { Button, Chip, FormControl, MenuItem, Select, Typography } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import HoverToolTipForText from '../../Modal/HoverToolTipForText';
import {
  extractABS,
  extractOBS,
  extractUserName,
  extractUsersList,
  splitText,
} from '../../utils/extractdata';
import { charScoreColor, lifeCycle } from '../../const/allocatorConst';
import hiddenColumns from '../../const/allData';
import RenderGapOrNC from '../components/RenderGapOrNc';
import ComplianceMulti from '../components/ComplianceMutli';
import RenderLifeCycleMulti from '../components/RenderLIfeCycleMulti';

const useAnalysisColumn = (props) => {
  const {
    data,
    user_id,
    targetColumn,
    displayColumns,
    levelsHierarchy,
    type,
    onChangeModalData,
  } = props;

  const textColum = targetColumn ?? 'Text';

  const keysForMetaData = [...Object.keys(hiddenColumns), ...levelsHierarchy];

  const extraColumns = useMemo(() => {
    // if (data.length === 0) {
    //   return [];
    // }

    const addColumnExcel = displayColumns ?? [];

    const filterdcol = [...keysForMetaData.filter((data) => data !== 'id')];

    return filterdcol.map((item) => {
      return {
        header: item,
        accessorKey: item,
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Typography color="info" style={{ visibility: 'hidden' }}>
              {renderedCellValue}
            </Typography>
          );
        },

        AggregatedCell: ({ cell, id, row }) => {
          return <Typography color="info">{row.original[item]}</Typography>;
          // return <div>Team Score: {row.original.Char_Score}</div>;
        },
      };
    });
  }, [data, levelsHierarchy]);

  const extractDisplayColumns = useMemo(() => {
    if (displayColumns) {
      return displayColumns
        .filter((data) => data !== textColum && !keysForMetaData.includes(data))
        .map((item) => {
          return {
            header: item,
            accessorKey: item,
            enableGrouping: false,
            enableEditing: false,
            grow: true,
            size: item.length > 16 ? 500 : 200,
            Cell: ({ renderedCellValue, row }) => {
              return (
                <Typography color="info" style={{ visibility: 'hidden' }}>
                  {renderedCellValue}
                </Typography>
              );
            },

            AggregatedCell: ({ cell, id, row }) => {
              return <Typography color="info">{row.original[item]}</Typography>;
              // return <div>Team Score: {row.original.Char_Score}</div>;
            },
          };
        });
    }
    return [];
  }, [displayColumns]);

  const analysisColumns = useMemo(
    () => [
      {
        header: [textColum],
        accessorKey: 'requirementText',
        enableGrouping: false,
        grow: true,
        size: 550,
        GroupedCell: ({ cell }) => {
          const text = splitText(cell?.getValue());

          return (
            <>
              <HoverToolTipForText renderedCellValue={text}>
                <Typography color="info">{text}</Typography>
              </HoverToolTipForText>
            </>
          );
        },
      },
      {
        header: 'iSenS_ID',
        accessorKey: 'owning_id',
        enableEditing: false,
        grow: true,
        enableGrouping: false,
        size: 130,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Typography color="info" style={{ visibility: 'hidden' }}>
              {renderedCellValue}
            </Typography>
          );
        },

        AggregatedCell: ({ cell, id, row }) => {
          return <Typography color="info">{row.original.owning_id}</Typography>;
        },
      },

      {
        header: 'Lifecycle Multi',
        accessorKey: 'lifecycle',
        enableEditing: false,
        size: 170,
        enableGrouping: false,
        Cell: ({ renderedCellValue, row }) => {
          const { original } = row;

          const { lifecycle, receiver, lifecyclesIs, allocation_id } = original;

          return (
            <RenderLifeCycleMulti
              lifecycleLabel={lifecycle}
              receiver={receiver}
              user_id={user_id}
              lifecyclesIs={lifecyclesIs}
              parentId={allocation_id}
            />
          );
        },
      },

      {
        header: 'PBS',
        accessorKey: 'pbs',
        grow: true,
        size: 250,
        enableGrouping: false,
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => {
          return <Typography color="info">{renderedCellValue}</Typography>;
        },
      },
      {
        header: 'Compliance Multi',
        accessorKey: 'compliance',
        grow: true,
        size: 150,
        enableGrouping: false,
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => {
          const { original } = row;

          const { receiver, compliance } = original;

          return (
            <>
              <ComplianceMulti
                renderedCellValue={compliance}
                receiver={receiver}
                user_id={user_id}
              />
            </>
          );
        },
        filterFn: (row, id, filterValue) => {
          const r = row.getValue(id);
          // console.log(r.includes(filterValue));

          return r?.toLowerCase()?.includes(filterValue?.toLowerCase()) || false;
        },
      },
      {
        header: 'NC',
        accessorKey: 'nc',
        grow: true,
        size: 130,
        enableGrouping: false,
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <>
              <RenderGapOrNC compliance={renderedCellValue} />
            </>
          );
        },
      },
      {
        header: 'Gap',
        accessorKey: 'gap',
        grow: true,
        size: 130,
        enableGrouping: false,
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <>
              {' '}
              <RenderGapOrNC compliance={renderedCellValue} />
            </>
          );
        },
      },
      {
        header: 'Rationale',
        accessorKey: 'rationale',
        grow: true,
        size: 130,
        enableGrouping: false,
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <HoverToolTipForText renderedCellValue={row.original.rationale}>
              <Typography color="info">{row.original.rationale?.slice(0, 10)}</Typography>
            </HoverToolTipForText>
          );
        },
        filterFn: (row, id, filterValue) => {
          const r = row.getValue(id);
          // console.log(r.includes(filterValue));

          return r?.toLowerCase()?.includes(filterValue?.toLowerCase()) || false;
        },
      },
      {
        header: 'KPI Lifecycle',
        accessorKey: 'kpi_lifecycle',
        enableEditing: false,
        enableGrouping: false,
        grow: true,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          return <div style={{ visibility: 'hidden' }}>{renderedCellValue}</div>;
        },

        AggregatedCell: ({ cell, id, row }) => {
          return (
            <Chip
              sx={{
                width: 100,
                backgroundColor: lifeCycle[row.original.kpi_lifecycle],
                color: 'white',
              }}
              label={row.original.kpi_lifecycle}
            />
          );
          // return <div>Team Score: {row.original.Char_Score}</div>;
        },
      },

      {
        header: 'Char Score',
        accessorKey: 'Char_Score',
        enableEditing: false,
        enableGrouping: false,
        grow: true,
        size: 200,
        Cell: ({ renderedCellValue, row }) => (
          <Chip
            sx={{
              width: 100,
              backgroundColor: charScoreColor[renderedCellValue],
              color: 'white',
              visibility: 'hidden',
            }}
            label={renderedCellValue}
          />
        ),

        AggregatedCell: ({ cell, id, row }) => {
          return (
            <Chip
              sx={{
                width: 100,
                backgroundColor: charScoreColor[row.original.Char_Score],
                color: 'white',
              }}
              label={row.original.Char_Score}
            />
          );
          // return <div>Team Score: {row.original.Char_Score}</div>;
        },
        // aggregationFn: 'max', //show the max age in the group (lots of pre-built aggregationFns to choose from)
        // required to render an aggregated cell
      },

      ...extractDisplayColumns,
      {
        header: 'Characterization',
        accessorKey: 'Characterization',
        enableEditing: false,
        grow: true,
        enableGrouping: false,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          return <div style={{ visibility: 'hidden' }}>{renderedCellValue}</div>;
        },

        AggregatedCell: ({ cell, id, row }) => {
          return <div>{row.original.Characterization}</div>;
          // return <div>Team Score: {row.original.Char_Score}</div>;
        },
      },
    ],
    [textColum, extractDisplayColumns]
  );

  const columnsTableInject = useMemo(() => {
    if (type !== 'pdf') {
      const removedCol = analysisColumns.filter((item) => item.accessorKey !== 'SubType');
      return [...removedCol, ...extraColumns];
    }

    return [...analysisColumns, ...extraColumns];
  }, [analysisColumns, extraColumns]);

  return { columns: columnsTableInject };
};

export default useAnalysisColumn;
