/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
import { FormLabel, IconButton } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import XLSX from 'xlsx';
import ReactJson from 'react-json-view';
import Module3Context from '../Module3Context';
import parseFunctions from '../utils/parseExcelFunctions';
import parseRules from '../utils/parseExcelRules';
import parseRulesConfig from '../utils/parseExcelRulesConfig';
import parseRulesMarseille from '../utils/parseExcelRulesMarseille';

type Props = {
  project: Project;
};

const ProjectMenuSettings: React.FC = () => {
  const history = useHistory();
  const { project, setProject } = useContext(Module3Context);

  const [rulesMarseilleJson, setRulesMarseilleJson] = useState<any>(undefined);
  const [rulesJson, setRulesJson] = useState<any>(undefined);
  const [rulesConfigJson, setRulesConfigJson] = useState<any>(undefined);
  const [functionsJson, setFunctionsJson] = useState<any>(undefined);
  const [result, setResult] = useState<any>({});

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    if (rulesMarseilleJson) {
      const dataImport = parseRulesMarseille(rulesMarseilleJson);
      // setProject({ ...project, rules: dataImport } as Project);
      setResult({ ...result, rulesMarseilles: dataImport });
    }
  }, [rulesMarseilleJson, setProject]);

  useEffect(() => {
    if (rulesConfigJson) {
      const dataImport = parseRulesConfig(rulesConfigJson);
      // setProject({ ...project, rules: dataImport } as Project);
      setResult({ ...result, rulesConfig: dataImport });
    }
  }, [rulesConfigJson, setProject]);

  useEffect(() => {
    if (rulesJson) {
      const dataImport = parseRules(rulesJson);
      // setProject({ ...project, rules: dataImport } as Project);
      setResult({ ...result, rules: dataImport });
    }
  }, [rulesJson, setProject]);

  useEffect(() => {
    if (functionsJson) {
      const dataImport = parseFunctions(functionsJson);
      setResult({ ...result, functions: dataImport });
      // setProject({ ...project, functions: dataImport } as Project);
    }
  }, [functionsJson, setProject]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLoadConfig = async (e: React.ChangeEvent<any>) => {
    // fix undefined files
    if (!e.currentTarget.files || !e.currentTarget.files[0]) return;
    const myFile = e.currentTarget.files[0];
    const reader = new FileReader();

    reader.readAsArrayBuffer(myFile);
    reader.onload = () => {
      const data = new Uint8Array(reader.result as any);
      const wb = XLSX.read(data, { type: 'array' });

      // setRulesJson(
      //   XLSX.utils.sheet_to_json(wb.Sheets.Rules, {
      //     header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],
      //   })
      // );
      setRulesJson(
        XLSX.utils.sheet_to_json(wb.Sheets.Rules, {
          header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'],
        })
      );
      setFunctionsJson(
        XLSX.utils.sheet_to_json(wb.Sheets.Functions, {
          header: ['A', 'B', 'C', 'D'],
        })
      );

      // console.log(configJson);
      // console.log(rulesJson);
      // console.log(rulesFuturesJson);
      handleClose();

      // history.push(`/projects/${project?.id}/module3/settings`);
    };
  };

  const handleLoadRuleConfig = async (e: React.ChangeEvent<any>) => {
    // fix undefined files
    if (!e.currentTarget.files || !e.currentTarget.files[0]) return;
    const myFile = e.currentTarget.files[0];
    const reader = new FileReader();

    reader.readAsArrayBuffer(myFile);
    reader.onload = () => {
      const data = new Uint8Array(reader.result as any);
      const wb = XLSX.read(data, { type: 'array' });

      setRulesConfigJson(
        XLSX.utils.sheet_to_json(wb.Sheets.config, {
          header: [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
            'AA',
            'AB',
            'AC',
          ],
        })
      );
      handleClose();
    };
  };

  const handleLoadRulesMarseille = async (e: React.ChangeEvent<any>) => {
    // fix undefined files
    if (!e.currentTarget.files || !e.currentTarget.files[0]) return;
    const myFile = e.currentTarget.files[0];
    const reader = new FileReader();

    reader.readAsArrayBuffer(myFile);
    reader.onload = () => {
      const data = new Uint8Array(reader.result as any);
      const wb = XLSX.read(data, { type: 'array' });

      setRulesMarseilleJson(
        XLSX.utils.sheet_to_json(wb.Sheets.Rules, {
          header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'],
        })
      );
      handleClose();
    };
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenSettings = () => {
    history.push(`/projects/${project?.id}/module3/m3/settings`);
    setAnchorEl(null);
  };

  const handleRun = () => {
    history.push(`/projects/${project?.id}/module3/m3/runs/new`);
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <SettingsIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <FormLabel>
            Import Excel File Rules & Functions
            <input value="" type="file" hidden onChange={handleLoadConfig} />
          </FormLabel>
        </MenuItem>
        <MenuItem>
          <FormLabel>
            Import Excel Rules Config
            <input value="" type="file" hidden onChange={handleLoadRuleConfig} />
          </FormLabel>
        </MenuItem>
        <MenuItem>
          <FormLabel>
            Import Excel File Rules & Functions AMD/Marseille
            <input value="" type="file" hidden onChange={handleLoadRulesMarseille} />
          </FormLabel>
        </MenuItem>
        <MenuItem onClick={handleOpenSettings}>Open/edit config file</MenuItem>
        <MenuItem onClick={handleRun}>Run</MenuItem>
      </Menu>
      {/* <ReactJson src={{ configJson }} collapsed={true} theme="monokai" /> 
      <ReactJson src={{ rulesJson }} collapsed={false} theme="monokai" />
      <ReactJson src={{ functionsJson }} collapsed={false} theme="monokai" /> */}
      <br />
      {/* <ReactJson src={{ result }} collapsed={false} theme="monokai" /> */}
    </div>
  );
};

export default ProjectMenuSettings;
