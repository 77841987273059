import { Action, configureStore, getDefaultMiddleware, ThunkAction } from '@reduxjs/toolkit';
import { ENV } from '../const';
import rootReducer, { rootState } from '../features/root.reducer';

/**
 * create store
 */
const middleware = [...getDefaultMiddleware()];

const store = configureStore({
  devTools: ENV !== 'production',
  reducer: rootReducer,
  middleware,
});

export type StoreType = typeof store;
export type AppThunk = ThunkAction<void, rootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;

export default store;
