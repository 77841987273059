import {
  Box,
  CircularProgress,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formInput: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginBottom: 5,
  },
  dialogContent: {
    backgroundColor: '#FBFBFB',
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
}));

type Props = {
  onClose?: any;
  onSubmit?: any;
  targetColumn?: any;
};

export default function ModalAssesmentEditor({ onClose, onSubmit, targetColumn }: Props) {
  const classes = useStyles();
  // reducer for tab authorization

  const [subtype, setSubtype] = useState('');
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({});

  const handleSubtype = (e: any) => {
    setSubtype(e.target.value);
  };
  const handleClose = () => {
    reset();
    setLoading(false);
    if (onClose) onClose();
  };

  const handleNewRow = (data) => {
    // console.log(data);
    if (onSubmit) onSubmit(data);
    setLoading(true);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <QuestionAnswerIcon style={{ color: '#DC3223' }} fontSize="large" />
            <div>Add Questions</div>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form>
            <Box className={classes.formInput}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    {...register('Process_number', { required: true })}
                    // multiline
                    // rows={3}
                    type="number"
                    fullWidth
                    variant="outlined"
                    label="Process Number"
                    placeholder="Process Number"
                    required
                    error={errors.Process_number}
                    helperText="Process Number is required"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('process_name', { required: true })}
                    fullWidth
                    variant="outlined"
                    label="Process Name"
                    placeholder="Process Name(Requirements Management Process)"
                    required
                    error={errors.process_name}
                    helperText="Process Name is required"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  {' '}
                  <TextField
                    {...register('plan_number', { required: true })}
                    type="number"
                    fullWidth
                    variant="outlined"
                    label="Plan Number"
                    placeholder="Plan Number"
                    required
                    error={errors.plan_number}
                    helperText="Plan Number is required"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('Plan_Name', { required: true })}
                    rows={3}
                    fullWidth
                    variant="outlined"
                    label="Plan Name"
                    placeholder="Plan Name(Requirement Management Plan)"
                    required
                    error={errors.Plan_Name}
                    helperText="Plan is required"
                  />
                </Grid>
              </Grid>

              <TextField
                {...register('question_number', { required: true })}
                type="number"
                rows={3}
                fullWidth
                variant="outlined"
                label="Question Number"
                placeholder="Question Number"
                required
                error={errors.question_number}
                helperText="Question Number is required"
              />
              <TextField
                {...register('question', { required: true })}
                multiline
                rows={3}
                fullWidth
                variant="outlined"
                label="Question"
                placeholder="Question"
                required
                error={errors.question}
                helperText="Question is required"
              />
            </Box>
            {/* {!targetColumn && (
              <>
                <Box className={classes.formInput}>
                  <TextField
                    {...register('hierarchy')}
                    fullWidth
                    variant="standard"
                    label="Hierarchy"
                    placeholder="Hierarchy"
                  />
                </Box>
                <Box className={classes.formInput}>
                  <InputLabel error={errors.subtype}>Subtype *</InputLabel>
                  <Select
                    {...register('subtype', { required: true })}
                    required
                    fullWidth
                    label="Subtype"
                    value={subtype}
                    onChange={handleSubtype}
                  >
                    <MenuItem value="" />
                    {['Heading', 'Paragraph', 'Bullet', 'ParaBullet', 'Image', 'Table'].map(
                      (sub) => (
                        <MenuItem value={sub}>{sub}</MenuItem>
                      )
                    )}
                  </Select>
                </Box>
                <Box className={classes.formInput}>
                  <TextField
                    {...register('context')}
                    fullWidth
                    variant="standard"
                    label="Context"
                    placeholder="Context"
                    error={errors.context}
                  />
                </Box>
              </>
            )} */}
            {/* <Box className={classes.formInput}>
              <TextField
                {...register('comment')}
                multiline
                rows={3}
                fullWidth
                variant="outlined"
                label="Comment"
                placeholder="Comment"
              />
            </Box> */}
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogContent}>
          <Button autoFocus onClick={handleClose} color="default" variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(handleNewRow)}
            color="primary"
            variant="contained"
            endIcon={loading && <CircularProgress size={20} color="secondary" />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
