import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const { Cell } = TableHeaderRow;

const styles = {
  customHeaderCell: {
    '& div': {
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      fontWeight: 'bold',
      fontSize: '16px',
    },
  },
};

// function TableHeaderCellWrap({ classes, ...restProps }: any) {
//   return <Cell {...restProps} className={classes.customHeaderCell} />;
// }

export const TableHeaderCellWrap = withStyles(styles)(
  ({ column, children, classes, ...restProps }) => (
    <Cell {...restProps} column={column} className={classes.customHeaderCell}>
      <Tooltip title={`Sort by ${column.title}`} enterDelay={300}>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
      </Tooltip>
    </Cell>
  )
);
export default withStyles(styles)(TableHeaderCellWrap);
