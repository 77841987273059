import React, { useState, useRef, useEffect } from 'react';
import { TreeView, TreeItem } from '@material-ui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  MenuItem,
  Select,
  AppBar,
  Toolbar,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  GetApp as GetAppIcon,
  Search as SearchIcon,
  AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon,
} from '@material-ui/icons';

import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Editor from '@monaco-editor/react';
// import { editor } from 'monaco-editor';
import DiscussList from '../../../Module2/NewModule/GPT2/DiscussList';
import GeneratorLeftNavigation from '../Layout/GeneratorLeftNavigation';

import { iTestGenerator } from '../../../../api/module3';
import Loading from '../../../../components/Core/Loading';
import AppTopBarBlank from '../../../../components/Layout/AppTopBarBlank';
import useAppToken from '../../../../app/useAppToken';
import TestGenModal from './TestGenModal';
import TestGensub from './TestSub/TestGensub';
import { getAllTestGenFolder } from '../../../../api/folders';
import TestGenDefault from './TestSub/TestGenDefault';
import './generatorStyle.css';
// import { editor } from 'monaco-editor';

const useStyles = makeStyles(() => ({
  iconContainer: {
    marginTop: 5,
  },
  root: {
    '& .MuiTreeItem-label': {
      background: '#002a45',
      color: 'white',
      paddingTop: 10,
      paddingBottom: 10,
    },
    '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      background: '#002a45',
      color: 'white',
      paddingTop: 10,
      paddingBottom: 10,
      marginRight: 4,
    },
    '& .MuiTreeItem-content': {
      flexDirection: 'row-reverse',
      backgroundColor: '#002a45',
    },
    '& .MuiTreeItem-group': {
      marginLeft: 0,
    },
    '& .MuiBox-root': {
      justifyContent: 'flex-end',
    },
    '& .MuiTreeItem-iconContainer': {
      color: 'white',

      '& svg': {
        fontSize: 27,
        paddingRight: 5,
      },
    },
  },
  selectbox: {
    '&.MuiInputBase-root': {
      color: '#002a45',
      backgroundColor: 'white',
      padding: 5,
      borderRadius: 5,
      borderBottom: 'none',
    },
    '& .MuiSelect-icon': {
      color: '#002a45',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      // paddingRight: 34,
      borderBottom: 'none',
    },
    '& .MuiInputBase-input': {
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      // paddingRight: '10px !important',
    },
  },
  editorSt: {
    // '& .editorWrapper.monaco-editor': {
    //   fontSize: '30px !Important',
    // },
  },
  textField: {
    width: '150px', // Adjust the width
    height: '30px', // Adjust the height
    backgroundColor: 'white', // Set the background color to white
    // padding: 2,
    paddingLeft: '8px !important', // Adjust the left padding for the placeholder text
  },
  inputLabel: {
    // paddingLeft: '8px !important', // Adjust the left padding for the placeholder text
  },
}));

const TestGenerator = () => {
  const classes = useStyles();
  const [createdChildParent, setCreatedChildParent] = useState<any>([]);
  const [open, setOpen] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;
  const [allFolder, setAllFolder] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredArray, setFilteredArray] = useState([]);
  const [collapseData, setCollapseData] = useState(null);
  const [selectedList, setSelectedList] = useState(0);
  // update folder fater delete
  const [folderId, setFolderId] = useState(null);

  const [getIDChild, setGetIDChild] = useState(null);
  const [folderName, setFolderName] = useState('');
  const [folderItemId, setFolderItemId] = useState(null);
  const [deletedId, setDeletedId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [folderCreatedId, setFolderCreatedId] = useState(null);
  const [expendedId, setExpendedId] = useState([]);
  const [thirdNewLine, setThirdNewLine] = useState(0);
  const textRef = useRef(null);
  const [expanded1, setExpanded1] = useState([]);
  const [getIDChildnew, setGetIDChildnew] = useState(null);
  const [renameChild, setRenameChild] = useState('');
  const [mainFolder, setMainFolder] = useState('');

  const hanldleDeletedId = (id) => {
    setLoading(true);
    setTimeout(() => {
      setFolderId(id);
      setDeletedId(id);
      setLoading(false);
    }, 1000);
  };
  const getfolderName = (id) => {
    setTimeout(() => {
      setFolderName(id);
    }, 1000);
  };
  const hanldleFolderCreatedId = (data) => {
    setLoading(true);
    // setFolderId(data);
    setFolderCreatedId(data);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  const hanldleGeneratedDefaultCaseFolder = (id) => {
    setLoading(true);
    setTimeout(() => {
      setFolderId(id);
      setLoading(false);
    }, 500);
  };
  const handleselected = (data) => {
    setSelectedList(data);
  };
  const handleExp = (data) => {
    // textRef.current.scrollTo({ top: textRef.current.scrollHeight, behavior: 'smooth' });
    // textRef.current.scrollTop = textRef.current.scrollHeight;
    if (textRef.current) {
      textRef.current.scrollTop = textRef.current.scrollHeight;
    }
    setExpendedId(data);
  };
  const handleLineLen = (data) => {
    setThirdNewLine(data);
  };
  const handleChildnew = (data) => {
    setGetIDChildnew(data);
  };
  const hanldleGeneratedCaseFolder = (data) => {
    // setLoading(true);
    setTimeout(() => {
      setFolderItemId(data.id);
      setGetIDChild(data?.folder_id);

      // setFolderId(data.folder_id);
      // setLoading(false);
    }, 1000);
  };
  const handleRenameData = (data) => {
    setRenameChild(data);
  };

  useEffect(() => {
    setLoading(true);
    getAllTestGenFolder(user_id)
      .then((fol) => {
        setAllFolder(fol);

        setFilteredArray(fol);
      })

      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
        // setThirdNewLine([]);
        // setFolderItemId(null);
      });
  }, [user_id, folderId, folderItemId, folderCreatedId, getIDChild, mainFolder]);

  // left navigation hide and show
  const hideShow = () => {
    // localStorage.setItem(
    //   'sessionTestGenLeft',
    //   JSON.stringify({ ...userSession, leftBarStatus: true })
    // );
    setOpen(!open);
  };
  const handleOpenFolderCreate = () => {
    setModalOpen(true);
  };
  const handleCloseFolder = () => {
    setModalOpen(false);
  };
  // handle get folder
  const handleGetFolder = (data) => {
    setCreatedChildParent(data);
  };
  const uniqueFolders = {};

  allFolder.forEach((item) => {
    if (!uniqueFolders[item.folder_name]) {
      uniqueFolders[item.folder_name] = [item.id];
    } else {
      uniqueFolders[item.folder_name].push(item.id);
    }
  });

  const oldChild = Object.keys(uniqueFolders).map((folderName) => {
    return {
      folder_name: folderName,
      id: uniqueFolders[folderName][0],
    };
  });
  // search function
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filteredResults = oldChild.filter((obj) =>
      obj.folder_name.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredArray(filteredResults);
  };

  const handleMainFolderName = (data) => {
    setMainFolder(data);
  };

  const handleColListItemData = (data) => {
    setLoading(true);

    setCollapseData(data);
    setTimeout(() => {
      if (textRef && textRef.current) {
        textRef.current.scrollTop = textRef.current.scrollHeight / 8 + thirdNewLine * 2;
        // textRef.current.scrollTop - 400;
      }
      setLoading(false);
    }, 1000);
  };

  const handleNodeToggle1 = (event, nodeIds) => {
    setExpanded1([]);
    // setFirstExpanded(nodeIds.includes('2'));
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography style={{ fontSize: 20, fontWeight: 'bold', color: '#002a45', marginLeft: 0 }}>
            iTestGenerator: Automatic Test Case Generator
          </Typography>
        </Grid>
      </Grid>
      <div style={{ width: '100%', background: '#EEF1F9 0% 0% no-repeat padding-box' }}>
        <Box display="flex" p={1} justifyContent="flex-start" flexGrow={2} margin={0} padding={0}>
          <Box flexShrink={0}>
            <div>
              <TreeView
                sx={{
                  height: 240,
                  maxwidth: open ? '30%' : '100%',
                  flexGrow: 1,
                  overflowY: 'auto',
                }}
                className={classes.root}
                expanded={expanded1}
                onNodeToggle={handleNodeToggle1}
              >
                <TreeItem
                  nodeId="1"
                  label={
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          // size="small"
                          style={{
                            color: 'white',
                            border: '1px solid white',
                            padding: '1px 4px',
                            minWidth: 0,
                            borderRadius: 2,
                            justifyContent: 'flex-end',
                            marginLeft: open ? 0 : 13,
                            // width: '5px',
                            // height: '5px',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            hideShow();
                          }}
                        >
                          <MenuOutlinedIcon />
                        </Button>
                      </div>
                    </Box>
                  }
                >
                  .
                </TreeItem>
              </TreeView>
              <div
                style={{
                  width: open ? '100%' : '0%',
                  height: open ? '65vh' : '0vh',
                  transition: 'width 0.5s, height 0.11s',
                  background: '#EEF1F9 0% 0% no-repeat padding-box',
                }}
              >
                {open && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        padding: '5px 0px',
                        background: '#EEF1F9 0% 0% no-repeat padding-box',
                        border: '1px solid #C4D4EC',
                      }}
                    >
                      <div>
                        <CreateNewFolderOutlinedIcon onClick={handleOpenFolderCreate} />
                      </div>
                      <div>
                        <TextField
                          value={searchQuery}
                          onChange={handleSearch}
                          placeholder="Search"
                          // size="small"
                          style={{ border: '1px solid black', borderRadius: 2 }}
                          className={classes.textField}
                          InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{ maxHeight: '58vh', overflowY: open === true ? 'scroll' : 'unset' }}
                    >
                      <GeneratorLeftNavigation
                        // key={folderId}
                        child={filteredArray}
                        mainData={allFolder}
                        getSelected={handleselected}
                        collapseListChildDta={handleColListItemData}
                        folderIDCreated={folderCreatedId}
                        folderDeletedId={hanldleDeletedId}
                        resultfolderName={getfolderName}
                        slectedFolderList={selectedList}
                        parentId={getIDChild}
                        childIdGen={folderItemId}
                        getFinalChild={handleChildnew}
                        finalId={getIDChildnew}
                        getRename={handleRenameData}
                        getMainFname={handleMainFolderName}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </Box>

          <Box
            ref={textRef}
            style={{
              backgroundColor: 'white',
              // overflowY: 'scroll',

              // overflowY: 'scroll',
              minHeight: open === true ? '30vh' : 'unset',
              maxHeight: open === true ? '72vh' : 'unset',
              overflowY: open === true ? 'scroll' : 'unset',
            }}
            width="100%"
          >
            {loading === true ? (
              <>
                <Loading />
              </>
            ) : (
              <>
                {allFolder.length > 0 && collapseData && (
                  <TestGensub
                    listChildData={collapseData}
                    key={collapseData?.id}
                    generatedTestId={hanldleGeneratedCaseFolder}
                    folderNameChild={folderName}
                    getExpended={handleExp}
                    thirdExpId={expendedId}
                    getLineLength={handleLineLen}
                    lineLen={thirdNewLine}
                  />
                )}
              </>
            )}
            <>
              {allFolder.length === 0 && (
                <>
                  {loading === true ? (
                    <>
                      <Loading />
                    </>
                  ) : (
                    <TestGenDefault generatedTestId={hanldleGeneratedDefaultCaseFolder} />
                  )}
                </>
              )}
            </>
          </Box>
        </Box>
        {modalOpen && (
          <TestGenModal
            userId={user_id}
            open={modalOpen}
            onClose={handleCloseFolder}
            onGetFolderData={handleGetFolder}
            folderCreatedID={hanldleFolderCreatedId}
          />
        )}
      </div>
    </>
  );
};

export default TestGenerator;
