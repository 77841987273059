/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unused-prop-types */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { SelectChangeEvent, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef } from 'react';
import Switch from '@mui/material/Switch';
import ReactJson from 'react-json-view';
import { pdfSmartHeader } from '../../../../api/module1';
import Module1Context from '../../Module1Context';
import Run1Context, { Resource } from '../../RunContext';
import TranslateSelection, { getLanguageCode } from './TranslateSelection';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    buttonContainer: {
      alignItems: 'flex-end',
      '& > *': {
        margin: theme.spacing(3),
      },
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    control: {
      padding: theme.spacing(2),
    },
    paper: {
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '95%',
    },
    formControl1: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    paper1: {
      width: '100%',
      // borderRadius: 5,
      border: '1px solid #002A45',
      color: '#002A45',
      fontWeight: 'bold',
    },
    treeitem: {
      width: '100%',
    },
    radiobtn: {
      textAlign: 'left',
    },
    warningTable: {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
    textField: {
      // color: '#002A45 !important',
      backgroundColor: '#EEF1F9',
      borderRadius: '5px !important',
      '& .MuiInputBase-input': {
        color: '#002A45 !important',
        fontWeight: 'bold',
        border: '1px solid #002A45',
        borderRadius: 4,
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none',
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none',
      },
      '& .MuiFormLabel-root': {
        color: '#002A45 !important',
        fontWeight: 'bold',
      },
      '& .MuiFilledInput-root': {
        borderBottomLeftRadius: '4px !important',
        borderBottomRightRadius: '4px !important',
      },
    },
    textFieldSecond: {
      // color: '#002A45 !important',
      backgroundColor: '#EEF1F9',
      borderRadius: '5px !important',
      '& .MuiInputBase-input': {
        color: '#002A45 !important',
        fontWeight: 'bold',
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none',
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none',
      },
      '& .MuiFormLabel-root': {
        color: '#002A45 !important',
        fontWeight: 'bold',
      },
      '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
        // these are the classes used from material-ui library for the asterisk element
        // '&::after': {
        //   color: 'red', // add your text here
        // },
        color: 'red',
      },
      '& .MuiFilledInput-root': {
        // position: 'relative',
        // backgroundColor: 'rgba(0, 0, 0, 0.09) !important',
        // borderTopLeftRadius: '4px !important',
        // borderTopRightRadius: '4px !important',
        // borderRadius: '4px !important',
      },
      borderBottomLeftRadius: '4px !important',
      width: 150,
    },
    labelAsterisk: {
      color: 'red',
    },
  })
);

type Props = {
  onChange?: any;
  resource: Resource;
};
// set state hide and use it

function FileSettings(this: any, { onChange, resource }: Props) {
  const { setSnack } = useContext(Module1Context);
  const runContext = useContext(Run1Context);
  const { setToggleCapture } = runContext;
  const { settings, filename, scope } = resource;
  // console.log('settings: ', settings);
  const [starPagetValue, setStarPagetValue] = React.useState(
    settings?.startPage || scope?.StartPage
  );
  const [endPageValue, setEndPageValue] = React.useState(settings.endPage || scope?.EndPage);
  const [loadingHeader, setLoadingHeader] = React.useState(false);

  const defaultLang = getLanguageCode(scope?.Language);
  const [state, setState] = React.useState({
    extractTables: false,
    smartHeaderFooter: false,
    headerMargin: 0,
    footerMargin: 0,
    translateTo: defaultLang !== 'en' ? ['en'] : undefined,
    fastm1: 'yes',
  });
  const classes = useStyles();

  const refStartPage = useRef(null);
  const refEndPage = useRef(null);
  const { project } = useContext(Module1Context);
  const [advanceDisplayShow, setAdvanceDisplayShow] = React.useState<boolean>(false);
  // const [fastM1Type, setFastM1Type] = React.useState<boolean>(true);
  const [buttonColorChange, setButtonColorChange] = React.useState({
    commonM1: 'default',
    quickM1: 'primary',
  });
  const handleChangeFast = (event: any) => {
    console.log('btn: ', event);
    if (event === true) {
      onChange(filename, { fastm1: 'yes' });
      setState({ ...state, fastm1: 'yes' });
    } else {
      onChange(filename, { fastm1: 'no' });
      setState({ ...state, fastm1: 'no' });
    }
  };

  const [radioCapture, setRadioCapture] = React.useState(
    // eslint-disable-next-line no-nested-ternary
    settings?.captureAll !== undefined ? (settings?.captureAll ? 'all' : 'custom') : 'all'
  );
  const handleRadioCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioCapture((e.target as HTMLInputElement).value);
    onChange(filename, { [e.target.name]: e.target.value === 'all' });
  };

  function handleReg(e: any) {
    if (e.target.name === 'typesOfCapture' && e.currentTarget.value !== 'Paragraph_Capture') {
      onChange(filename, { mergeBullet: false });
      setState({ ...state, mergeBullet: false });
    }
    onChange(filename, { [e.target.name]: e.currentTarget.value });
    setState({ ...state, [e.target.name]: e.target.checked });
  }

  function handleCheckboxBool(e: any) {
    onChange(filename, { [e.target.name]: e.target.checked });
    setState({ ...state, [e.target.name]: e.target.checked });
  }

  const StartPageHandle = (e: any) => {
    if (e.target.value > scope.EndPage) {
      setStarPagetValue({
        starPagetValue: e.preventDefault(),
      });
      setSnack('Please enter the valid start page Number', 'warning', 4000);
    } else {
      setStarPagetValue(e.target.value);
      setRadioCapture('custom');
      onChange(filename, { captureAll: false });
      onChange(filename, { [e.target.name]: e.currentTarget.value });
    }
  };
  const EndPageHandle = (e: any) => {
    if (e.target.value > scope.EndPage) {
      setEndPageValue({
        endPageValue: e.preventDefault(),
      });
      setSnack('Please enter the valid end page Number', 'warning', 4000);
    } else {
      setEndPageValue(e.target.value);
      setRadioCapture('custom');
      onChange(filename, { captureAll: false });
      onChange(filename, { [e.target.name]: e.currentTarget.value });
    }
  };
  const blockInvalidChar = (e: any) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

  const typesOfCapture = settings.typesOfCapture || scope?.TypesOfCapture[0];

  // Translate
  useEffect(() => {
    // init settings with translation
    if (defaultLang !== 'en') {
      onChange(filename, { translateTo: ['en'] });
    }
  }, []);
  const handleChangeTranslate = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    const listTranslate = typeof value === 'string' ? value.split(',') : value;
    onChange(filename, { translateTo: listTranslate });
    setState({ ...state, translateTo: listTranslate });
  };

  function handleAdvance() {
    setAdvanceDisplayShow(!advanceDisplayShow);
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        marginTop: '2rem',
      }}
    >
      {/* <ReactJson
        src={{
          state,
        }}
        collapsed={true}
        theme="monokai"
      /> */}

      <Box
        style={{
          display: 'flex',
          border: '1px solid #002A45',
          borderRadius: '5px',
        }}
      >
        <Box
          style={{
            flexBasis: '70%',
            justifyContent: 'center',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            borderRight: advanceDisplayShow ? '1px solid #002A45' : 'none',
          }}
        >
          {advanceDisplayShow && (
            <Box>
              <form noValidate autoComplete="off" style={{ display: 'flex', gap: '20px' }}>
                <TextField
                  label="Project Name"
                  variant="filled"
                  name="projectId"
                  defaultValue={project.name}
                  style={{ flexBasis: '33.33%' }}
                  className={classes.textField}
                  inputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
                {settings?.typesOfCapture === 'Requirement_Capture' && (
                  <TextField
                    label="Reg ID RegExp"
                    variant="outlined"
                    name="regId"
                    className={classes.textField}
                    defaultValue={settings?.regId || ''}
                    onChange={(e: any) =>
                      onChange(filename, {
                        [e.target.name]: e.currentTarget.value,
                      })
                    }
                  />
                )}

                <TextField
                  label="Language"
                  variant="filled"
                  style={{ flexBasis: '33.33%' }}
                  name="language"
                  defaultValue={scope?.Language}
                  className={classes.textField}
                  inputProps={{
                    readOnly: true,
                  }}
                  onChange={(e: any) =>
                    onChange(filename, {
                      [e.target.name]: e.currentTarget.value,
                    })
                  }
                />
                <TextField
                  disabled
                  label="File Type"
                  style={{ flexBasis: '33.33%' }}
                  variant="filled"
                  name="filetype"
                  className={classes.textField}
                  defaultValue={scope?.FileType}
                  onChange={(e: any) =>
                    onChange(filename, {
                      [e.target.name]: e.currentTarget.value,
                    })
                  }
                />
              </form>
            </Box>
          )}
          <Box>
            <form noValidate autoComplete="off" style={{ display: 'flex', gap: '20px' }}>
              <TextField
                label="Capture Name"
                inputProps={{
                  maxlength: 20,
                }}
                variant="outlined"
                style={{ flexBasis: '33.33%' }}
                name="nameOfCapture"
                className={classes.textFieldSecond}
                defaultValue=""
                onChange={(e: any) =>
                  onChange(filename, { [e.target.name]: e.currentTarget.value })
                }
                required={advanceDisplayShow}
              />

              <FormControl variant="outlined" style={{ flexBasis: '33.33%' }}>
                <InputLabel htmlFor="outlined-age-native-simple">Type of capture</InputLabel>
                <Select
                  native
                  label="Type of capture"
                  name="typesOfCapture"
                  // style={{ width: 150 }}
                  onChange={handleReg}
                  defaultValue={settings.typesOfCapture || scope?.TypesOfCapture[0]}
                >
                  <option aria-label="None" value="" />
                  {scope &&
                    scope.TypesOfCapture.map((cap: string, index: number) => (
                      <option key={`capture-type-${index}`} value={cap}>
                        {cap}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" style={{ flexBasis: '33.33%' }}>
                <InputLabel htmlFor="outlined-age-native-simple">Translate</InputLabel>
                <TranslateSelection languageCode={defaultLang} onChange={handleChangeTranslate} />
              </FormControl>
            </form>
          </Box>
          {advanceDisplayShow && (
            <Box>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <RadioGroup
                  aria-label="captureAll"
                  value={radioCapture}
                  name="captureAll"
                  className={classes.radiobtn}
                  onChange={handleRadioCapture}
                >
                  <Grid container style={{ width: '100%' }}>
                    <Grid item xs={4}>
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label="Capture all pages"
                        onChange={(e: any) => {
                          if (e.currentTarget.checked) {
                            onChange(filename, {
                              captureAll: true,
                            });
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Box style={{ display: 'flex', gap: '10px' }}>
                        <FormControlLabel
                          style={{
                            flexBasis: '16%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                          value="custom"
                          label=""
                          control={<Radio />}
                          onChange={(e: any) => {
                            if (e.currentTarget.checked) {
                              onChange(filename, {
                                captureAll: false,
                              });
                            }
                          }}
                        />
                        <TextField
                          className={classes.textFieldSecond}
                          ref={refStartPage}
                          style={{ flexBasis: '40%' }}
                          type="number"
                          name="startPage"
                          label="Start page"
                          variant="outlined"
                          defaultValue={starPagetValue}
                          disabled={radioCapture === 'all'}
                          // style={{ width: 150 }}
                          inputProps={{ min: 1, max: scope?.EndPage }}
                          onKeyDown={blockInvalidChar}
                          value={starPagetValue}
                          onChange={StartPageHandle}
                        />
                        <span
                          style={{
                            flexBasis: '4%',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          To{' '}
                        </span>
                        <TextField
                          ref={refEndPage}
                          style={{ flexBasis: '40%' }}
                          type="number"
                          name="endPage"
                          label="End page"
                          variant="outlined"
                          className={classes.textFieldSecond}
                          defaultValue={endPageValue}
                          disabled={radioCapture === 'all'}
                          value={endPageValue}
                          // style={{ width: 150 }}
                          inputProps={{ min: 1, max: scope?.EndPage }}
                          // TODO
                          // onChange={onChangeEndPage}
                          onKeyDown={blockInvalidChar}
                          onChange={EndPageHandle}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </RadioGroup>{' '}
              </FormControl>
            </Box>
          )}
        </Box>
        {advanceDisplayShow && (
          <Box
            style={{
              flexBasis: '30%',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <label htmlFor={`smartHeaderFooter-${filename}`}>
                  <Checkbox
                    defaultChecked={settings.smartHeaderFooter}
                    onChange={handleCheckboxBool}
                    name="smartHeaderFooter"
                    id={`smartHeaderFooter-${filename}`}
                    color="primary"
                    value={true}
                  />
                  Remove header & footer from extraction
                </label>
              </Grid>
              <Grid item>
                <label htmlFor={`mergeBullet-${filename}`}>
                  <Checkbox
                    defaultChecked={settings.mergeBullet}
                    onChange={handleCheckboxBool}
                    name="mergeBullet"
                    id={`mergeBullet-${filename}`}
                    color="primary"
                    value={true}
                    disabled={typesOfCapture !== 'Paragraph_Capture'}
                  />
                  Merge bullets
                </label>
              </Grid>
              <Grid item>
                <label htmlFor={`smartTableOfContent-${filename}`}>
                  <Checkbox
                    defaultChecked={settings.smartTableOfContent}
                    onChange={handleCheckboxBool}
                    name="smartTableOfContent"
                    id={`smartTableOfContent-${filename}`}
                    color="primary"
                    value={true}
                  />
                  Remove table of contents
                </label>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <div style={{ overflow: 'auto' }}>
        <div style={{ float: 'right' }}>
          {/* <span>
            <span>
              <b>Normal M1</b>
            </span>
            <Switch
              checked={checked}
              onChange={handleChangeFast}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <span>
              <b>Fast M1</b>
            </span>
            borderRadius: '4px !important',
                mx: 1,
                border: '1px solid lightgrey !important',
          </span> */}
          <ToggleButtonGroup size="small">
            <Button
              value="no"
              variant="contained"
              color={buttonColorChange.commonM1}
              onClick={() => {
                handleChangeFast(false);
                setButtonColorChange({
                  commonM1: 'primary',
                  quickM1: 'default',
                });
              }}
            >
              Classic M1
            </Button>
            <Button
              value="yes"
              variant="contained"
              color={buttonColorChange.quickM1}
              onClick={() => {
                handleChangeFast(true);
                setButtonColorChange({
                  commonM1: 'default',
                  quickM1: 'primary',
                });
              }}
            >
              Quick M1
            </Button>
          </ToggleButtonGroup>
          &emsp;&emsp;&emsp;
          <Button variant="contained" color="primary" onClick={handleAdvance}>
            Advanced Setting
          </Button>
        </div>
      </div>
    </Box>
  );
}

export default FileSettings;
