import React, { useState, useRef, useEffect } from 'react';
import { TreeView, TreeItem } from '@material-ui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  MenuItem,
  Select,
  AppBar,
  Toolbar,
  TextField,
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  GetApp as GetAppIcon,
  AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon,
} from '@material-ui/icons';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Editor from '@monaco-editor/react';
// import { editor } from 'monaco-editor';
import DiscussList from '../../../../Module2/NewModule/GPT2/DiscussList';
import GeneratorLeftNavigation from '../../Layout/GeneratorLeftNavigation';

import { iTestGenerator } from '../../../../../api/module3';
import Loading from '../../../../../components/Core/Loading';
import '../generatorStyle.css';
import AppTopBarBlank from '../../../../../components/Layout/AppTopBarBlank';
import useAppToken from '../../../../../app/useAppToken';
import TestGenModal from '../TestGenModal';
import { TestGenCreate, TestGenfolderCreate } from '../../../../../api/folders';
// import { editor } from 'monaco-editor';

const useStyles = makeStyles(() => ({
  iconContainer: {
    marginTop: 5,
  },
  root: {
    '& .MuiTreeItem-label': {
      background: '#002a45',
      color: 'white',
      paddingTop: 10,
      paddingBottom: 10,
    },
    '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      background: '#002a45',
      color: 'white',
      paddingTop: 10,
      paddingBottom: 10,
      marginRight: 4,
    },
    '& .MuiTreeItem-content': {
      flexDirection: 'row-reverse',
      backgroundColor: '#002a45',
    },
    '& .MuiTreeItem-group': {
      marginLeft: 0,
    },
    '& .MuiTreeItem-iconContainer': {
      color: 'white',

      '& svg': {
        fontSize: 27,
        paddingRight: 5,
      },
    },
  },
  selectbox: {
    '&.MuiInputBase-root': {
      color: '#002a45',
      backgroundColor: 'white',
      padding: 5,
      borderRadius: 5,
      borderBottom: 'none',
    },
    '& .MuiSelect-icon': {
      color: '#002a45',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      // paddingRight: 34,
      borderBottom: 'none',
    },
    '& .MuiInputBase-input': {
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      // paddingRight: '10px !important',
    },
  },
  editorSt: {
    // '& .editorWrapper.monaco-editor': {
    //   fontSize: '30px !Important',
    // },
  },
}));
function calculateEditorHeight(code) {
  // Split the code by newlines
  const lines = code.split('\n');
  // Calculate the line height in pixels (adjust it according to your needs)
  const lineHeight = 20;
  // Calculate the height based on the line count
  const height = lines.length * lineHeight;
  // Return the calculated height
  return height;
}

const TestGensub = ({
  listChildData,
  generatedTestId,
  folderNameChild,
  getExpended,
  thirdExpId,
  getLineLength,
  lineLen,
}) => {
  const classes = useStyles();
  const firstFieldRef = useRef(null);
  const secondFieldRef = useRef(null);
  const thirdFieldRef = useRef(null);
  const [firstFieldLanguage, setFirstFieldLanguage] = useState('java');
  const [secondFieldLanguage, setSecondFieldLanguage] = useState('java');
  const [firstField, setFirstField] = useState(listChildData?.testcases);
  const [secondField, setSecondField] = useState<any>(
    listChildData?.testcases_opt === null ? '' : listChildData?.testcases_opt
  );
  const [thirdField, setThirdField] = useState(listChildData?.testcases_result?.output);
  const [firstFieldFocused, setFirstFieldFocused] = useState(false);
  const [secondFieldFocused, setSecondFieldFocused] = useState(false);
  const [thirdFieldFocused, setThirdFieldFocused] = useState(false);
  const [expanded, setExpanded] = useState(thirdExpId);
  const [expanded2, setExpanded2] = useState([]);
  const [expanded1, setExpanded1] = useState(['1']);
  const [secondExpanded, setSecondExpanded] = useState(false);
  const [firstExpanded, setFirstExpanded] = useState(false);
  const [thirdExpanded, setThirdExpanded] = useState(false);
  const [thirdNewLine, setThirdNewLine] = useState(lineLen);
  const [loading, setLoading] = useState(false);
  const [firstFieldError, setFirstFieldError] = useState('');
  const [createdChildParent, setCreatedChildParent] = useState<any>([]);
  const [editorScrollRemoved, setEditorScrollRemoved] = useState(false);
  const [editorScroll2Removed, setEditorScroll2Removed] = useState(false);
  const [open, setOpen] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;
  const textRef = useRef(null);
  // const [loadings, setLoadings] = useState(true); // page

  useEffect(() => {
    if (firstFieldLanguage === 'java') {
      // Code to set the language for the editor to Java
      // You can call the required function here
      // editor.setModelLanguage(editor.getModel(), 'java');
    } else if (firstFieldLanguage === 'python') {
      // Code to set the language for the editor to Python
      // You can call the required function here
    } else if (firstFieldLanguage === 'cpp') {
      // Code to set the language for the editor to C++
      // You can call the required function here
    } else if (firstFieldLanguage === 'c') {
      // Code to set the language for the editor to C
      // You can call the required function here
    } else if (firstFieldLanguage === 'javascript') {
      // Code to set the language for the editor to C
      // You can call the required function here
      // editor.setModelLanguage(editor.getModel(), 'javascript');
    }
  }, [firstFieldLanguage]);
  useEffect(() => {
    if (secondFieldLanguage === 'java') {
      // Code to set the language for the editor to Java
      // You can call the required function here
      // editor.setModelLanguage(editor.getModel(), 'java');
    } else if (secondFieldLanguage === 'python') {
      // Code to set the language for the editor to Python
      // You can call the required function here
    } else if (secondFieldLanguage === 'cpp') {
      // Code to set the language for the editor to C++
      // You can call the required function here
    } else if (firstFieldLanguage === 'c') {
      // Code to set the language for the editor to C
      // You can call the required function here
    } else if (secondFieldLanguage === 'javascript') {
      // Code to set the language for the editor to C
      // You can call the required function here
      // editor.setModelLanguage(editor.getModel(), 'javascript');
    }
  }, [secondFieldLanguage]);

  const handleFirstFieldLanguageChange = (event) => {
    setFirstFieldLanguage(event.target.value);
  };

  const handleFirstFieldChange = (value, event) => {
    setFirstField(value);
    setFirstFieldError('');
    // adjustTextareaHeight(firstFieldRef);
  };

  const handleSecondFieldChange = (value, event) => {
    setSecondField(value);
    // adjustTextareaHeight(secondFieldRef);
  };

  const handleThirdFieldChange = (event) => {
    setThirdField(event.target.value);
    adjustTextareaHeight(thirdFieldRef);
  };

  const handleClearFirstField = () => {
    setFirstField('');
    setFirstFieldError('');
    // adjustTextareaHeight(firstFieldRef);
  };

  const handleClearSecondField = () => {
    setSecondField('');
    // adjustTextareaHeight(secondFieldRef);
  };

  const handleClearThirdField = () => {
    setThirdField('');
    setThirdNewLine(0);
    adjustTextareaHeight(thirdFieldRef);
  };

  const handleNodeToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
    // setSecondExpanded(nodeIds.includes('2'));
    setThirdExpanded(nodeIds.includes('3'));
  };
  const handleNodeToggle2 = (event, nodeIds) => {
    setExpanded2(nodeIds);
    setSecondExpanded(nodeIds.includes('2'));
  };
  const handleNodeToggle1 = (event, nodeIds) => {
    setExpanded1(nodeIds);
    // setFirstExpanded(nodeIds.includes('2'));
  };

  const handleFirstFieldFocus = () => {
    setFirstFieldFocused(true);
  };

  const handleFirstFieldBlur = () => {
    setFirstFieldFocused(false);
  };

  const handleSecondFieldFocus = () => {
    setSecondFieldFocused(true);
  };

  const handleSecondFieldBlur = () => {
    setSecondFieldFocused(false);
  };

  const handleThirdFieldFocus = () => {
    setThirdFieldFocused(true);
  };

  const handleThirdFieldBlur = () => {
    setThirdFieldFocused(false);
  };

  function adjustTextareaHeight(refthis) {
    refthis.current.style.height = 'auto'; // eslint-disable-line no-param-reassign
    refthis.current.style.height = `${refthis.current.scrollHeight}px`; // eslint-disable-line no-param-reassign
  }
  const editorHeight = calculateEditorHeight(firstField);
  const editorHeight2 = calculateEditorHeight(secondField);
  function generateRandomName() {
    let name = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 8;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      name += characters.charAt(randomIndex);
    }

    return name;
  }

  const handleGenerateTestCase = async () => {
    setLoading(true);

    const body = {
      query: firstField,
      exampleFuncationTestCases: secondField,
    };

    if (firstField === '') {
      setFirstFieldError('Please enter the text for the above field');
      setLoading(false);
      return;
    }
    setExpanded1([]);
    try {
      await GenerateTestCase(
        user_id,
        listChildData?.folder_id,
        listChildData?.testcase_name === 'Item-1'
          ? `Item${
              parseInt(
                listChildData?.testcase_name.substring(
                  listChildData?.testcase_name.indexOf('-'),
                  10
                ) + 1,
                10
              ) + 1
            }`
          : `${generateRandomName()}`,
        body
      );
    } catch (error) {
      console.error(error);
    }

    // setLoading(false);
  };

  const GenerateTestCase = (user_id, folderId, testCaseName, body) => {
    return new Promise<void>((resolve, reject) => {
      TestGenCreate(user_id, folderId, testCaseName, body)
        .then((f: any) => {
          GenerateFolder();
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const GenerateFolder = () => {
    iTestGenerator(firstField, secondField)
      .then((payload: any) => {
        if (payload) {
          const newlinesCount = countNewlines(payload.output);
          setThirdField(payload?.output);
          setThirdNewLine(newlinesCount);
          getLineLength(newlinesCount);
          // setExpanded1([]);
          generatedTestId(listChildData);
          getExpended(['3']);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const countNewlines = (str) => {
    const newlineCount = str.split('\n').length - 1;
    return newlineCount;
  };

  const handleCopyText = () => {
    const textarea = thirdFieldRef.current;
    textarea.select();
    document.execCommand('copy');
  };

  const handleDownloadText = () => {
    const data = {
      output: thirdField,
    };
    const jsonData = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });

    saveAs(blob, 'textfile.json');
  };
  const editorRef = useRef(null);

  function handleEditorDidMount(editor, monaco) {
    // here is the editor instance
    // you can store it in `useRef` for further usage
    editorRef.current = editor;
  }
  const handleToggleEditorScroll = () => {
    setEditorScrollRemoved(!editorScrollRemoved);
  };
  const handleToggleEditor2Scroll = () => {
    setEditorScroll2Removed(!editorScroll2Removed);
  };
  return (
    <>
      <div>
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
              className={classes.root}
              expanded={expanded1}
              onNodeToggle={handleNodeToggle1}
            >
              <TreeItem
                nodeId="1"
                label={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography>
                      Please provide your code here*:
                      {/* <span style={{ color: 'red', marginLeft: 10 }}>{folderNameChild}</span>-
                      <span style={{ color: 'gold' }}>{listChildData.testcase_name}</span> */}
                    </Typography>
                    <div
                      style={{
                        display: expanded1.length > 0 ? 'unset' : 'none',
                        marginRight: 5,
                      }}
                    >
                      <Select
                        value={firstFieldLanguage}
                        // onChange={handleFirstFieldLanguageChange}
                        style={{ marginRight: 15 }}
                        className={classes.selectbox}
                        onChange={(event) => {
                          event.stopPropagation();
                          setFirstFieldLanguage(event.target.value);
                          // handleFirstFieldLanguageChange();
                        }}
                      >
                        <MenuItem value="java">Java</MenuItem>
                        <MenuItem value="javascript">javascript</MenuItem>
                        <MenuItem value="python">Python</MenuItem>
                        <MenuItem value="cpp">C++</MenuItem>
                        <MenuItem value="c">C</MenuItem>
                      </Select>
                      {/* <IconButton
                        title="clear"
                        color="default"
                        style={{
                          padding: '2px 2px 2px 2px',
                          color: 'black',
                          background: 'white',
                          borderRadius: 5,
                          fontSize: 19,
                        }}
                        
                      >
                        Clear
                      </IconButton> */}
                      <Button
                        // disabled
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleClearFirstField();
                        }}
                        // onClick={handleSubmit}
                        // endIcon={<Loading size={20} displayIcon={true} />}
                        style={{
                          color: 'white',
                          border: '1px solid white',
                          // backgroundColor: '#DC3223',
                          padding: 1,
                          width: '50px',
                          marginRight: 5,
                        }}
                      >
                        Clear
                      </Button>
                    </div>
                  </Box>
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Editor
                      height={editorScrollRemoved === false ? '30vh' : `${editorHeight}px`}
                      className={classes.editorSt}
                      language={firstFieldLanguage}
                      value={firstField}
                      onChange={handleFirstFieldChange}
                    />
                    <IconButton
                      title={editorScrollRemoved === false ? 'Expand' : 'Expandless'}
                      color="default"
                      style={{
                        padding: '0px 5px 0px 0px',
                        color: 'black',
                        float: 'inline-end',
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleToggleEditorScroll();
                      }}
                    >
                      {/* Add the plus icon */}
                      {editorScrollRemoved === false ? (
                        <AddCircleOutlineOutlinedIcon />
                      ) : (
                        <RemoveCircleOutlineOutlinedIcon />
                      )}
                    </IconButton>
                    <p style={{ color: 'red' }}>{firstFieldError}</p>
                  </Grid>
                </Grid>
              </TreeItem>
            </TreeView>
          </Grid>
          <Grid item xs={12}>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
              className={classes.root}
              expanded={expanded2}
              onNodeToggle={handleNodeToggle2}
            >
              <TreeItem
                nodeId="2"
                // label="Example test cases (Optional)"
                label={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography>Example test cases (Optional):</Typography>
                    <div
                      style={{
                        display: expanded2.length > 0 ? 'unset' : 'none',
                        marginRight: 5,
                      }}
                    >
                      <Select
                        value={secondFieldLanguage}
                        // onChange={handleFirstFieldLanguageChange}
                        style={{ marginRight: 15 }}
                        className={classes.selectbox}
                        onChange={(event) => {
                          event.stopPropagation();
                          setSecondFieldLanguage(event.target.value);
                          // handleFirstFieldLanguageChange();
                        }}
                      >
                        <MenuItem value="java">Java</MenuItem>
                        <MenuItem value="javascript">javascript</MenuItem>
                        <MenuItem value="python">Python</MenuItem>
                        <MenuItem value="cpp">C++</MenuItem>
                        <MenuItem value="c">C</MenuItem>
                      </Select>
                      <Button
                        // disabled
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleClearSecondField();
                        }}
                        // onClick={handleSubmit}
                        // endIcon={<Loading size={20} displayIcon={true} />}
                        style={{
                          color: 'white',
                          border: '1px solid white',
                          // backgroundColor: '#DC3223',
                          padding: 1,
                          width: '50px',
                          marginRight: 5,
                        }}
                      >
                        Clear
                      </Button>
                      {/* <IconButton
                        title="clear"
                        color="default"
                        style={{ padding: '0px 5px 0px 0px', color: 'white' }}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleClearSecondField();
                        }}
                      >
                        <CancelOutlinedIcon />
                      </IconButton> */}
                    </div>
                  </Box>
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Editor
                      height={editorScroll2Removed === false ? '30vh' : `${editorHeight2}px`}
                      // defaultLanguage="python"
                      className={classes.editorSt}
                      language={secondFieldLanguage}
                      value={secondField}
                      onChange={handleSecondFieldChange}
                    />
                    <IconButton
                      title={editorScroll2Removed === false ? 'Expand' : 'Expandless'}
                      color="default"
                      style={{
                        padding: '0px 5px 0px 0px',
                        color: 'black',
                        float: 'inline-end',
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleToggleEditor2Scroll();
                      }}
                    >
                      {/* Add the plus icon */}
                      {editorScroll2Removed === false ? (
                        <AddCircleOutlineOutlinedIcon />
                      ) : (
                        <RemoveCircleOutlineOutlinedIcon />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
                <hr />
              </TreeItem>
            </TreeView>
          </Grid>
          <Grid ref={textRef} item xs={12}>
            {loading && (
              <Box
                style={{
                  position: 'relative',
                  top: '50%',
                  left: '50%',
                  zIndex: 700,
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Loading />
              </Box>
            )}
            <Button
              variant="contained"
              style={{ float: 'inline-end' }}
              color="primary"
              onClick={handleGenerateTestCase}
            >
              Generate Test Case
            </Button>
          </Grid>
          {loading === false && (
            <>
              <Grid item xs={12}>
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                  className={classes.root}
                  expanded={expanded}
                  onNodeToggle={handleNodeToggle}
                >
                  <TreeItem
                    nodeId="3"
                    label={
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography>Generated Test Cases:</Typography>
                        <div style={{ display: expanded.length > 0 ? 'unset' : 'none', gap: 10 }}>
                          <IconButton
                            color="default"
                            title="copy"
                            style={{ padding: 0, color: 'white', marginRight: 5 }}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleCopyText();
                            }}
                          >
                            <ContentCopyOutlinedIcon />
                          </IconButton>
                          <IconButton
                            color="default"
                            title="download"
                            style={{ padding: '0px 5px 0px 0px', color: 'white' }}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleDownloadText();
                            }}
                          >
                            <GetAppIcon />
                          </IconButton>
                        </div>
                      </Box>
                    }
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <textarea
                          ref={thirdFieldRef}
                          value={thirdField}
                          onChange={handleThirdFieldChange}
                          onFocus={handleThirdFieldFocus}
                          onBlur={handleThirdFieldBlur}
                          style={{
                            width: '100%',
                            minHeight: thirdNewLine > 0 ? `${thirdNewLine * 5 + 10}vh` : '30vh',
                            height: 'auto',
                            border: thirdFieldFocused ? 'none' : 'none',
                            outline: thirdFieldFocused ? 'none' : 'none',
                            background: '#EEF1F9',
                            resize: 'none',
                            overflow: 'hidden',
                            fontSize: 16,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </TreeItem>
                </TreeView>
              </Grid>
            </>
          )}
        </Grid>
        {/* <div ref={textRef}>hi</div> */}
      </div>
    </>
  );
};

export default TestGensub;
