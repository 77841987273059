import React from 'react';
import { Route, Switch } from 'react-router-dom';
import IverifierTemplatesAdmin from '../Iverifier/Admin/IverifierTemplatesAdmin';
import AdminContext, { AdminContextProvider } from './AdminContext';
import AdminProjects from './components/AdminProjects';
import AdminUsers from './components/AdminUsers';
import AdminAssessment from './components/AdminAssessment';
import AdminHistory from './components/AdminHistory';
import AdminKpi from './components/AdminKpi';
import AdminScheduleAnalysis from './components/AdminScheduleAnalysis';
import FileTranslator from './components/AdminDefaultGlossary';

const AdminRouter = () => {
  return (
    <AdminContextProvider>
      <AdminContext.Consumer>
        {(): any => {
          return (
            <Switch>
              <Route exact path="/admin/projects" component={AdminProjects} />
              <Route exact path="/admin/scheduleAnalysis" component={AdminScheduleAnalysis} />
              <Route exact path="/admin/defaultGlossary" component={FileTranslator} />
              {/* <Route exact path="/admin/iverifier_rules" component={IverifierRulesAdmin} /> */}
              <Route exact path="/admin/iverifier_templates" component={IverifierTemplatesAdmin} />
              {/* <Route exact path="/admin/iverifier_project_configs" component={IverifierProjectConfigs} /> */}
              <Route exact path="/admin/AdminHistory" component={AdminHistory} />
              <Route exact path="/admin/Assessment" component={AdminAssessment} />
              <Route exact path="/admin/AdminKpi" component={AdminKpi} />
              <Route path="/admin" component={AdminUsers} />
            </Switch>
          );
        }}
      </AdminContext.Consumer>
    </AdminContextProvider>
  );
};

export default AdminRouter;
