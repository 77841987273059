/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useState, useRef, useContext, useMemo } from 'react';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { useParams } from 'react-router-dom';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Skeleton } from '@mui/material';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableHeaderRow,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
  TableColumnResizing,
  TableSelection,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import Highlighter from 'react-highlight-words';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Paper,
  CardHeader,
  IconButton,
  Button,
  Tooltip,
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  toolbarComponent,
  tableHeaderComponent,
} from '../../../components/Datagrid/TStyles';
import NavigationGotoNew from '../../../components/Core/NavigationGotoNew';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import './transitionCss.css';
import Kpis from '../../../components/Filter/Kpis';
import { generateRexSummary, SixSigmaRexSummary } from '../../../api/module2';
import money from '../../../assets/icons8-money-50.png';
import IsearcherStyle from '../../../components/Layout/IsearcherStyle';
import MultiAllocTableComp from '../../../components/Datagrid/MultiAllocTableComp';
import SixSigmaColumnReSize, {
  hiddenSixCol,
} from '../TextAnalysisResultContainer/const/sixsigmaconst';
import Module1Context from '../../Module1/Module1Context';
import { SummaryCard } from './RexChild';

const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 900,
    fontSize: 16,
    border: '1px solid #dadde9',
  },
}));

// export const SummaryCard = ({
//   name,
//   title,
//   text,
//   action,
//   context,
//   problem,
//   showCashIcon,
//   last,
// }: any) => {
//   const classes = useStyles();

//   return (
//     <Card
//       style={{ flex: '1', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', backgroundColor: '#EEF1F9' }}
//     >
//       <CardContent style={{ padding: 0 }}>
//         <div
//           style={{
//             borderRadius: '5px',
//             backgroundColor: !showCashIcon ? '#002A45 ' : '#e8b923',
//             boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
//           }}
//         >
//           <div
//             style={{
//               display: 'flex',
//               alignItems: 'center',
//               paddingTop: 10,
//               paddingBottom: 10,
//               paddingLeft: 10,
//               marginBottom: 16,
//               gap: 5,
//             }}
//           >
//             <Tooltip title={title} arrow classes={{ tooltip: classes.tooltip }}>
//               <div
//                 style={{
//                   flexBasis: !showCashIcon ? '100%' : '90%',
//                   display: 'flex',
//                   alignItems: 'center',
//                 }}
//               >
//                 <SummarizeIcon style={{ color: !showCashIcon ? '#FFFFFF' : '#002A45' }} />
//                 <Typography
//                   variant="h5"
//                   component="div"
//                   style={{ color: !showCashIcon ? '#FFFFFF' : '#002A45' }}
//                 >
//                   {title.length > last ? `${title.slice(0, last)}...` : title} {name}
//                 </Typography>
//               </div>
//             </Tooltip>
//             {showCashIcon && (
//               <div
//                 style={{
//                   flexBasis: '10%',
//                   display: 'flex',
//                   justifyContent: 'flex-end',
//                   paddingRight: '5px',
//                 }}
//               >
//                 {' '}
//                 <img src={money} height="30" width="30" alt="money" />{' '}
//               </div>
//             )}
//           </div>
//         </div>
//         <Box style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', color: '#002A45' }}>
//           <>
//             {problem && (
//               <>
//                 <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>Problem :</Typography>
//                 <Typography style={{ textAlign: 'justify', fontSize: 15 }}>{problem}</Typography>
//               </>
//             )}

//             {context && (
//               <>
//                 <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>Context :</Typography>
//                 <Typography style={{ textAlign: 'justify', fontSize: 15 }}>{context}</Typography>
//               </>
//             )}

//             {context || problem ? (
//               <>
//                 <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
//                   Key Takeaways :
//                 </Typography>
//                 <Typography style={{ textAlign: 'justify', fontSize: 15 }}>{action}</Typography>
//               </>
//             ) : (
//               <Typography style={{ fontSize: 15, fontWeight: 'normal', textAlign: 'justify' }}>
//                 {text}
//               </Typography>
//               //   <ol style={{ fontSize: 15, fontWeight: 'bolds', margin: 0 }}>
//               //     {text?.split('\n').map((data, index) => {
//               //       const t = data?.split(' ')?.slice(1, data.split(' ')?.length).join(' ');
//               //       return (
//               //         <li key={index}>
//               //           <Typography style={{ fontSize: 15, fontWeight: 'normal' }}>{t}</Typography>
//               //         </li>
//               //       );
//               //     })}
//               //   </ol>
//             )}
//           </>
//         </Box>
//       </CardContent>
//     </Card>
//   );
// };

const SixSigma = ({ rows, columns, data1, data2, data3, data4 }) => {
  // const [rows, setRows] = useState(row);
  // const [columns, setColumns] = useState(column);
  const [loading, setLoading] = useState(false);
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const classes = IsearcherStyle();
  const pageSizes = [5];
  const [currentPage, setCurrentPage] = useState(0);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [summaryArray, setSummaryArray] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([...hiddenSixCol]);
  const exporterRef = useRef(null);
  const { setSnack } = useContext(Module1Context);
  const [summaryInterval, setSummaryInterval] = useState();

  const tableScrollComponent: React.FunctionComponent<Table.CellProps> = (props) => {
    return <Table.Container {...props} className={classes.tablescrol} />;
  };

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  const fetchData = () => {
    const start = Math.abs(pageSize - pageSize * (currentPage + 1));
    const last = start + 5;
    const n = rows.length;
    if (start < n && last <= n) {
      const temp = rows.slice(start, last);
      const IdsPromisses = temp.map((data) => {
        return () => SixSigmaRexSummary(data.id);
      });
      Promise.all(IdsPromisses.map((fn) => fn()))
        .then((result) => {
          setSummaryArray(result);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (start < n && Math.abs(n - start) <= n) {
      const temp = rows.slice(start, Math.abs(n - start) + start);
      const IdsPromisses = temp.map((data) => {
        return () => SixSigmaRexSummary(data.id);
      });
      Promise.all(IdsPromisses.map((fn) => fn()))
        .then((result) => {
          setSummaryArray(result);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    if (summaryInterval) {
      clearTimeout(summaryInterval);
    }
    const newSearchTimeout = setTimeout(() => {
      fetchData();
    }, 1000);
    setSummaryInterval(newSearchTimeout);
  }, [currentPage, rows]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Six_Sigma_report.xlsx');
    });
    // setRemind(true);
    // setRemidText('Downloaded');
  };

  const renderText = ({ value, row }: DataTypeProvider.ValueFormatterProps) => (
    <a
      id={`search-${row.id}`}
      // href="https://myworkplace.alstom.hub/Citrix/Alstom_PROD_StoreWeb/"
      href={row.url}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        // urlSearch(String(row.url));
      }}
      style={{ textDecoration: 'none' }}
      title={value}
    >
      {value}
    </a>
  );

  const renderTextHighlighter = ({ row, value }) => {
    return (
      <div
        style={{
          fontWeight: 'bold',
          color: 'white',
          width: '8rem',
          textAlign: 'center',
          padding: '0.5rem',
          margin: '0.1rem',
          borderRadius: '5px',
          backgroundColor: `${value}`,
        }}
      >
        {value}
      </div>
    );
  };

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          width: '100%',
        }}
      >
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          <span>
            <b>{rows.length}</b> results found
          </span>
        </Typography>
        <Box
          style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45', borderRadius: '20px' }}
        >
          <ExpressGrid rows={rows} columns={columns}>
            <DataTypeProvider for={['url']} formatterComponent={renderText} />
            <DataTypeProvider for={['level']} formatterComponent={renderTextHighlighter} />
            <FilteringState />
            <IntegratedFiltering />
            <SortingState />
            <IntegratedSorting />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <Table
              tableComponent={MultiAllocTableComp}
              containerComponent={tableScrollComponent}
              headComponent={tableHeaderComponent}
              columnExtensions={[
                { columnName: 'projectTitle', wordWrapEnabled: toggleHeight },
                { columnName: 'projectDescription', wordWrapEnabled: toggleHeight },
                { columnName: 'beltName', wordWrapEnabled: toggleHeight },
                { columnName: 'level', wordWrapEnabled: toggleHeight },
                { columnName: 'progress', wordWrapEnabled: toggleHeight },
              ]}
            />
            <TableColumnResizing defaultColumnWidths={[...SixSigmaColumnReSize]} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={setHiddenColumnNames}
            />
            <Toolbar rootComponent={toolbarComponent} />
            <ColumnChooser />
            <ToolBarPlugin name="Download" onClick={startExport} />
            <ToolBarPlugin
              name="Height"
              title="Wrap Text"
              onClick={() => setToggleHeight(!toggleHeight)}
            />
            <PagingPanel
              containerComponent={(props) => (
                <>
                  <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
                </>
              )}
            />
          </ExpressGrid>
          <GridExporter
            ref={exporterRef}
            rows={rows}
            columns={columns}
            onSave={onSave}
            // customizeCell={customizeCell}
            // customizeHeader={customizeHeader}
          />
          <NavigationGotoNew
            setPageSize={setPageSize}
            fileLength={rows.length}
            pageSize={pageSize}
            dis={dis}
            setTemPage={setTemPage}
            handlePageNum={handlePageNum}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Box>
        {loading ? (
          [1, 2, 3, 4, 5].map((data) => {
            return (
              <Box style={{ marginBottom: '2rem' }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Box>
            );
          })
        ) : (
          <>
            <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
              {summaryArray?.slice(0, 2)?.map((results) => {
                //   const { action, context, problem } = results?.summary;
                if (results?.summary === undefined) {
                  return null;
                }
                const { projectTitle } = results.searchResult[0];
                //   const { showCashIcon } = results;
                return (
                  <SummaryCard
                    name=""
                    title={projectTitle}
                    text={results?.summary}
                    showCashIcon={false}
                    action=""
                    context=""
                    problem=""
                    last={45}
                  />
                );
              })}
            </Box>
            <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
              {summaryArray?.slice(2, 4)?.map((results) => {
                //   const { action, context, problem } = results?.summary;
                if (results?.summary === undefined) {
                  return null;
                }
                const { projectTitle } = results.searchResult[0];
                //   const { showCashIcon } = results;
                return (
                  <SummaryCard
                    name=""
                    title={projectTitle}
                    text={results?.summary}
                    showCashIcon={false}
                    action=""
                    context=""
                    problem=""
                    last={45}
                  />
                );
              })}
            </Box>
            {summaryArray?.slice(4, summaryArray.length)?.map((results) => {
              //   const { action, context, problem } = results?.summary;
              if (results?.summary === undefined) {
                return null;
              }
              const { projectTitle } = results.searchResult[0];
              //   const { showCashIcon } = results;
              return (
                <SummaryCard
                  name=""
                  title={projectTitle}
                  text={results?.summary}
                  showCashIcon={false}
                  action=""
                  context=""
                  problem=""
                  last={100}
                />
              );
            })}
          </>
        )}
        {/* <Kpis data1={data1} data2={data2} data3={data3} data4={data4} /> */}
        {/* <SummaryCard name="Global REX Summary" text={result?.rexSummary} /> */}
      </Box>
    </>
  );
};

export default SixSigma;
