import { ComplianceEnum, LifecycleEnum } from 'OwningType';
import { BACKEND_URL } from '../const';
import axios from './axios';

export type PayloadAddMultiAllocationType = {
  allocaArray: [];
};
export type PayloadAddAllocationType = {
  allocation: string;
  owning_id: number;
  allocation_score: string;
  created_by: number;
  lifecycle: LifecycleEnum;
  compliance: ComplianceEnum;
};

export const addAllocation = async (
  owningId: any,
  body: PayloadAddAllocationType
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/allocations/?owning_id=${owningId}`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const removeAllocation = async (owningId: any, allocation: any): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BACKEND_URL.root}/allocations/${
        allocation.id
      }?owning_id=${owningId}&allocation_label=${encodeURIComponent(allocation.allocation)}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const addMultiAllocation = async (
  allocaArray: PayloadAddMultiAllocationType
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/allocations/new_allocation`,
      allocaArray
    );

    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const deleteMultiAllocation = async (
  delleteAlloc: PayloadAddMultiAllocationType
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/allocations/multi_del_allocation`,
      delleteAlloc
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export default addAllocation;
