import styled from 'styled-components';

const TabContainerDiv = styled.div`
  display: flex;
  color: #002a45;
  flex-direction: column;
`;

const TabHeader = styled.div`
  display: flex;
  padding: 0.5rem 1rem 0.5rem 1rem;
  color: #002a45;
  gap: 12px;
  border-bottom: 1px solid #ccd6f2;
`;

const TabBtnContainer = styled.div`
  flex: 9;
  color: #002a45;
  font-weight: bold;
  display: flex;
  position: relative;
`;

const DropDownContainer = styled.div`
  flex: 1;
  display: flex;
  height: 2rem;
  justify-content: flex-end;
  gap: 20px;
`;

const Line = styled.div`
  position: absolute;
  background: #002a45;
  height: 0.2rem;
  width: 6rem;
  bottom: -0.5rem;
  transition: all 0.5s;
`;
const Button = styled.button`
  background: transparent;
  border: none;
  color: #002a45;
  position: relative;
  padding: 0px;
  width: 6rem;
  font-size: 1rem;
  cursor: pointer;
`;

const GlossaryButton = styled.button`
  background-image: linear-gradient(to right, #0070c0, #002a45);
  border: none;
  color: #002a45;
  position: relative;
  padding: 0px;
  width: 6rem;
  font-size: 1rem;
  height: 100%;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #002a45;
  }
`;

const TabMain = styled.div`
  padding: 0.5rem;
`;

export default TabContainerDiv;

export { TabBtnContainer, Button, TabHeader, DropDownContainer, Line, TabMain, GlossaryButton };
