import React from 'react';
import TextAnalysisMode from '../../../../HOC/TextAnalysisMode';

const withWorkSpaceRexAnalyis = (WrappedComponent) => {
  // TextAnalsis operation states
  return (props) => {
    return <WrappedComponent {...props} />;
  };
};

const WorkSpaceTextAnalysis = withWorkSpaceRexAnalyis(TextAnalysisMode);

export default WorkSpaceTextAnalysis;
