import styled from 'styled-components';

const Select = styled.button`
  display: flex;
  position: relative;
  align-items: center;
  gap: 5px;
  height: 100%;
  width: 9rem;
  padding-left: 0.8rem;
  cursor: pointer;
  background: #dae2f8;
  border-radius: 5px;
  border: 1px solid #ccd6f2;
`;

const SelectContainer = styled.div`
  position: relative;
  height: 100%;
`;

const OptionDiv = styled.div`
  position: absolute;
  /* visibility: hidden; */
  margin-top: 0.3rem;
  background: #eef1f9;
  border: 1px solid #ccd6f2;
  border-radius: 5px;
`;

const InputDiv = styled.div`
  display: flex;
  background: #eef1f9;
  border-radius: 5px;
  height: 2.5rem;
  padding-left: 0.5rem;
  border-bottom: 1px solid #ccd6f2;
`;
const Input = styled.input`
  flex: 9;
  outline: none;
  border: none;
  background: transparent;
`;

const Cross = styled.div`
  flex: 1;
  padding: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SubMenuListDiv = styled.div`
  display: flex;
  gap: 10px;
  max-height: 12rem;
  overflow: auto;
  padding: 0.3rem;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 3px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    outline: none;
    background-color: #002a45;
  }
`;

const SubMenuListColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Button = styled.div`
  background: transparent;
  border: none;
  color: #002a45;
  padding: 0.4rem 0.7rem;
  width: 6rem;
  font-size: 0.8rem;
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    background: #dae2f8;
    border: 1px solid #ccd6f2;
    border-radius: 5px;
  }
`;

export default Select;

export {
  SelectContainer,
  OptionDiv,
  InputDiv,
  Input,
  SubMenuListDiv,
  SubMenuListColumn,
  Button,
  Cross,
};
