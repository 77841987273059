import { makeStyles, withStyles } from '@material-ui/core/styles';
import { spacing } from '@mui/system';

const IsearcherStyle = makeStyles((theme) => ({
  snackbar: {
    width: '100%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: '5px 0',
  },
  listItemStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
  },
  ListItemAvatars: {
    minWidth: 30,
    color: '#002A45',
  },
  ListItemTexts: {
    color: '#002A45',
    fontWeight: 'bold',
  },
  root: {
    // flexGrow: 1,
    // padding: '36px 48px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #002A45',
    borderRadius: '10px',
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
  heading: {
    backgroundColor: theme.palette.primary.light,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
    borderRadius: 12,
  },
  peopleTable: {
    marginTop: -30,
  },
  resultPanel: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: '2px !important',
  },
  searchbtn: {
    marginLeft: -40,
    // paddingBottom: 15,
    borderRadius: 35,
    marginTop: 10,
    zIndex: 1,
  },
  searchbtnStyle: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderRadius: '35px !important',
      backGround: '#FFFFFF 0% 0% no-repeat padding-box',
      borderColor: '#002a45',
    },
    // '& .MuiInputLabel-outlined': {
    // },
  },
  treeRoot: {
    width: '100%',
    flexGrow: 1,
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  treeitem: {
    width: '95%',
    backgroundColor: 'white',
  },
  treeLabel: {
    borderRadius: '13px',
    paddingLeft: 10,
    backgroundColor: theme.palette.primary.light,
    color: 'white',
  },
  label: {
    backgroundColor: 'white',
    borderRadius: '13px',
  },
  // formControlMultiTextlebel: {
  //   marginTop: 10,
  // },
  //   formControlMulti: {
  //     margin: theme.spacing(1),
  //     marginTop: -2,
  //     width: '100%',
  //   },
  formControlReqiurement: {
    formControl: {
      margin: theme.spacing(1),
      width: '100%',
      padding: '20px 0',
    },
  },
  translate: {
    width: '90%',
    fontWeight: 'bold',
    color: '#002A45',
    background: '#EEF1F9 0% 0% no-repeat padding-box',
    // '& .MuiInputBase-root': {
    //   borderColor: 'red',
    // },
  },
  translateRoot: {
    borderColor: 'red',
  },
  MultiSelect: {
    // border: '2px solid red',
    // opacity: 1,
    '& .dropdown-container': {
      border: '1px solid #002A45',
      opacity: 1,
      // position: 'fixed',
    },
    '& .gray': {
      color: '#002A45 !important',
    },
    '& .dropdown-container[aria-disabled="true"]': {
      // overFlow: 'visible',
      // position: 'fixed',
      // backgroundColor: '#C4D4EC',
    },
    // '& .dropdown-content': {
    //   // overflowY: 'auto',
    //   backgroundColor: 'red',
    //   position: 'initial',
    // },
    '& .select-menu-outer': {
      position: 'fixed',
    },
    // '& .rmsc': {
    //   borderColor: 'white',
    // },
    // '& .dropdown-heading': {
    //   color: 'red',
    // },
  },
  toggle: {
    '& .MuiToggleButton-root': {
      background: 'white 0% 0% no-repeat padding-box',
      color: '#002A45 !important',
      border: '1px solid #001F3C',
    },
    // '& MuiToggleButton-root .Mui-selected': {
    //   // background: '#001F3C 0% 0% no-repeat padding-box',
    //   backgroundColor: 'red !important',
    // },
    '& .Mui-selected': {
      color: 'white !important',
      backgroundColor: '#002A45',
    },
    '& .Mui-selected:hover': {
      backgroundColor: 'none',
      opacity: 1,
    },
  },
  MultiSelectHide: {
    // border: '2px solid red',
    // opacity: 1,
    '& .dropdown-container': {
      border: '1px solid #002A45',
      opacity: 1,
      // position: 'fixed',
    },
    '& .gray': {
      color: '#002A45 !important',
    },
    '& .dropdown-container[aria-disabled="true"]': {
      // overFlow: 'visible',
      // position: 'fixed',
      backgroundColor: '#C4D4EC',
      opacity: 0.5,
    },
    // '& .dropdown-content': {
    //   // overflowY: 'auto',
    //   backgroundColor: 'red',
    //   position: 'initial',
    // },
    '& .select-menu-outer': {
      position: 'fixed',
    },
    // '& .rmsc': {
    //   borderColor: 'white',
    // },
    // '& .dropdown-heading': {
    //   color: 'red',
    // },
  },
  toggleButton: {
    '& .MuiToggleButton-root .Mui-selected': {
      background: 'none',
      backgroundColor: 'red !important',
    },
  },
  overlay: {
    position: 'relative',
    top: '-45px',
    float: 'right',
    right: '50px',
  },
  selectDemo: {
    width: '105px',
  },
  iconButtonUpper: {
    marginLeft: 10,
    marginRight: 5,
    backgroundColor: '#DC3223',
    color: '#FFFFFF',
  },
  iconButtonInner: {
    color: 'white',
    fontSize: '1.5rem',
  },
  personIcon: {
    padding: '8px',
    marginLeft: 10,
    marginRight: 5,
    backgroundColor: '#DC3223',
    color: '#FFFFFF',
  },
  cardBody: {
    width: '100%',
    textAlign: 'left',
    border: '1px solid #003E5C',
    background: '#EEF1F9 0% 0% no-repeat padding-box',
  },
  cardBody2: {
    width: '100%',
    height: '40vh',
    textAlign: 'left',
    border: '1px solid #003E5C',
    background: '#EEF1F9 0% 0% no-repeat padding-box',
  },
  ExpCardBody1: {
    width: '100%',
    textAlign: 'left',
    border: '1px solid #003E5C',
    background: '##00000029 0% 0% no-repeat padding-box',
  },
  cardContentDiv1: {
    background: '#E2E8F1 0% 0% no-repeat padding-box',
    borderRadius: 5,
  },
  cardContentDiv2: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 10px 0 10px',
  },
  cardContent: {
    background: '#EEF1F9 0% 0% no-repeat padding-box',
  },
  cardIcon: {
    color: '#002A45',
    transform: 'scale(0.8)',
    paddingBottom: 5,
  },
  root2: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  formControlMultiTextlebel: {
    marginTop: 10,
  },
  //   formControl: {
  //     margin: theme.spacing(1),
  //     minWidth: 160,
  //   },
  //   formControlMulti: {
  //     margin: theme.spacing(1),
  //     minWidth: 155,
  //     maxWidth: 155,
  //   },
  formControlMulti: {
    margin: theme.spacing(1),
    marginTop: -2,
    width: '100%',
  },
  formControlMulti1: {
    margin: theme.spacing(1),
    minWidth: 280,
    maxWidth: 280,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tabstyle: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '33px',
    background: '#DC3223 0% 0% no-repeat padding-box',
    color: '#fff !important',
    fontSize: 14,
    fontWeight: 700,
  },
  root3: {
    border: '1px solid #002A45',
    backgroundColor: '#EEF1F9',
  },
  bullet3: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title3: {
    fontSize: 20,
    color: '#002A45',
  },
  pos3: {
    marginBottom: 12,
  },
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
  tablescrol: {
    maxHeight: '70vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'none',
      borderRadius: '3px',
      backgroundColor: 'white',
    },

    '&::-webkit-scrollbar-thumb': {
      outline: 'none',
      backgroundColor: '#ccc',
    },
  },
}));
export default IsearcherStyle;
