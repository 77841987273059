/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import {
  DataTypeProvider,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
  TableEditRow,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  TableInlineCellEditing,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Select,
  TextField,
  Breadcrumbs,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MultiSelect from 'react-multi-select-component';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import dayjs from 'dayjs';
import {
  getClusterName,
  getRadinessProjectName,
  getRadinessProjects,
  getRadinessSingleRowProjects,
  getRmName,
  saveEnggRediness,
} from '../../../api/kpi';
import useAppToken from '../../../app/useAppToken';
import NavigationGoto from '../../../components/Core/NavigationGoto';
import TableComponent from '../../../components/Datagrid/TableComponent';
import { rowComponent, toolbarComponent } from '../../../components/Datagrid/TStyles';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import ProjectContext from '../../Project/ProjectContext';
import Loading from '../../../components/Core/Loading';

const useStyles2 = makeStyles((theme) => ({
  root: { display: 'inline', margin: 10, color: theme.palette.primary.main },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    backgroundColor: theme.palette.action.hover,
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
  textField: {
    margin: '15px 5px',
    paddingRight: 10,
  },
  radio: {
    textAlign: 'center',
  },
  label: {
    marginRight: '30px',
  },
  // root: {
  //   backgroundColor: theme.palette.background.paper,
  //   width: '100%',
  // },
  contain: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
    padding: '5px',
  },
  formControlMultiTextlebel: {
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  formControlMulti: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
  },
  formControlMultiFile: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
  },
  formControlMulti1: {
    margin: theme.spacing(1),
    minWidth: 220,
    maxWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tabstyle: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '33px',
    background: '#DC3223 0% 0% no-repeat padding-box',
    color: '#fff !important',
    fontSize: 14,
    fontWeight: 700,
  },
  MultiSelectHide: {
    // border: '2px solid red',
    // opacity: 1,
    '& .dropdown-container': {
      border: '1px solid #002A45',
      opacity: 1,
      // position: 'fixed',
    },
    '& .gray': {
      color: '#002A45 !important',
    },
    '& .dropdown-container[aria-disabled="true"]': {
      // overFlow: 'visible',
      // position: 'fixed',
      backgroundColor: '#C4D4EC',
      opacity: 0.5,
    },
    '& .select-menu-outer': {
      position: 'fixed',
    },
  },
  MultiSelect: {
    // border: '2px solid red',
    // opacity: 1,
    '& .dropdown-container': {
      border: '1px solid #002A45',
      opacity: 1,
      // position: 'fixed',
    },
    '& .gray': {
      color: '#002A45 !important',
    },
    '& .dropdown-container[aria-disabled="true"]': {
      // overFlow: 'visible',
      // position: 'fixed',
      // backgroundColor: '#C4D4EC',
    },
    // '& .dropdown-content': {
    //   // overflowY: 'auto',
    //   backgroundColor: 'red',
    //   position: 'initial',
    // },
    '& .select-menu-outer': {
      position: 'fixed',
    },
    // '& .rmsc': {
    //   borderColor: 'white',
    // },
    // '& .dropdown-heading': {
    //   color: 'red',
    // },
  },
  ListSubMain: {
    // paddingLeft: theme.spacing(4),
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      background: '#031F31 0% 0% no-repeat padding-box',
    },
    '& .MuiListItem-root': {
      background: '#002A45 0% 0% no-repeat padding-box',
      border: '1px solid #224B65',
      boxShadow: '0px 3px 6px #00000029',
      color: 'white !important',
      // borderBottom: '1px solid #C4D4EC',
      '&.Mui-selected': {
        // backgroundColor: 'pink',
        background: '#002A45 0% 0% no-repeat padding-box',
        border: '1px solid #224B65',
        color: 'white !important',
        // color: 'white',
        fontWeight: 'bold',
        '& .MuiListItemIcon-root': {
          color: 'white',
        },
        '& .MuiListItemText-root': {
          color: 'white !important',
          fontWeight: 'bold',
        },
      },
    },
  },
  ListsubItemInside: {
    // '& .MuiListItem-button:hover': {
    //   background: 'red',
    // },
    // paddingLeft: theme.spacing(4),
    color: 'white !important',
    fontSize: 16,
    '& .MuiListItemIcon-root': {
      color: 'white',
    },
    '& .MuiListItemText-root': {
      color: 'white !important',
      fontWeight: 'bold',
    },
    paddingTop: 10,
    paddingBottom: 10,
  },
  ListsubItemInsideHide2: {
    // '& .MuiListItem-button:hover': {
    //   background: 'red',
    // },
    // paddingLeft: theme.spacing(4),
    color: 'white !important',
    backgroundColor: '#EEF1F9 !important',
    fontSize: 12,
    border: '1px solid #EEF1F9 !important',
    '& .MuiListItemIcon-root': {
      color: 'white',
    },
    '& .MuiListItemText-root': {
      color: '#EEF1F9 !important',
      fontWeight: 'bold',
    },
    paddingTop: 10,
    paddingBottom: 10,
  },
  ListsubItemInsideHide: {
    // '& .MuiListItem-button:hover': {
    //   background: 'red',
    // },
    // paddingLeft: theme.spacing(4),
    color: 'white !important',
    fontSize: 22,
    '& .MuiListItemIcon-root': {
      color: '#002A45',
    },
    '& .MuiListItemText-root': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
  },
  link: {
    display: 'block',
    // alignContent: 'center',
    // justifyContent: 'center',
    textDecoration: 'none',
  },
  textField2: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 100,
  },
}));

type Props = {
  changeblePaylod?: any;
};
// const columns = handleColumns(['id', 'function', 'mapping', 'example', 'created_at', 'updated_at']);

export default function ChangebleReadiness(projectName: any) {
  // console.log('alstomProgramName1:', projectName);
  // const queryParams = new URLSearchParams(window.location.search);
  // const changeblePaylod = queryParams.get('project_program_name');
  const { projectName: proname } = projectName;
  const changeblePayload = proname;
  const classes2 = useStyles2();
  const { setSnack } = useContext(ProjectContext);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const classes = useStyles();
  const [payload, setPayload] = useState([]);
  const [displayOutput, setDisplayOutput] = useState([]);
  const [textValueToText, setTextValueToText] = React.useState(undefined);
  const [segment, setSegment] = useState([]);
  const [input, setInput] = useState([]);
  const [temp, setTemp] = useState([]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [validationStatus, setValidationStatus] = useState({});
  const [idsChanged, setIdsChanged] = useState<any>([]);
  const [changedMyRows, setChangedMyRows] = useState<any>();
  const [objectsHere, setObjectsHere] = useState<any>([]);
  const [colHere, setColHere] = useState<any>([]);
  const [rowHere, setRowHere] = useState<any>([]);
  const [dataForSave, setDataForSave] = useState<any>([]);

  const usersArray = [];
  const columnsName = [
    { name: 'date_of_dfq', title: 'Date of Dfq' },
    { name: 'Rejected', title: 'Rejected' },
    { name: 'Identified', title: 'Identified' },
    { name: 'Defined', title: 'Defined' },
    { name: 'Committed_Owned', title: 'Committed Owned' },
    { name: 'Covered', title: 'Convered' },
    { name: 'Demonstrated', title: 'Demonstrated' },
    { name: 'Obsolete', title: 'Obsolete' },
  ];
  // API get
  useEffect(() => {
    setLoading(true);
    setDisplay(false);
    getRadinessSingleRowProjects(encodeURIComponent(changeblePaylod), textValueToText)
      .then((payload: any) => {
        if (payload) {
          setTimeout(() => {
            setLoading(false);
            setDisplay(true);
            setPayload(payload);
            setDisplayOutput(payload?.output);
            setSegment(payload?.segment);
            console.log('payload?.output:', payload?.output);
          }, 1000);
          setTimeout(() => {
            if (payload?.output?.length > 0) {
              // console.log('payload?.output OBJ:', Object?.keys(payload?.output[0]));
              setObjectsHere(Object.keys(payload?.output[0]));
              getColumnsName(payload?.output);
            }
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, [textValueToText]);

  // to get columns name and row data
  function getColumnsName(a) {
    const columnsName1: any = [];
    // eslint-disable-next-line array-callback-return
    a?.map((list, i) => {
      columnsName1.push({
        name: `${list.quality_gate}`,
        title: `${list.quality_gate}`,
      });
    });
    setColHere(columnsName1);
    // console.log('columnsName1:', columnsName1);
    const rowData: any = [];
    const rowDataExtra: any = [];
    const obj = {};
    const obj_quality_gate = {};
    const obj_date_of_dfq = {};
    const obj_Identified = {};
    const obj_Defined = {};
    const obj_Committed_Owned = {};
    const obj_Covered = {};
    const obj_Demonstrated = {};
    const obj_Obsolete = {};
    const obj_Rejected = {};
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < columnsName1.length; index++) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < a.length; i++) {
        if (a[i].quality_gate === columnsName1[index].name) {
          // console.log('matched');
          // in same row but others in different columns
          obj[columnsName1[index].name] = a[i]?.id;
          // for others row in different columns
          obj_quality_gate[columnsName1[index].name] = a[i]?.quality_gate;
          rowDataExtra.push(obj_quality_gate);
          obj_date_of_dfq[columnsName1[index].name] = a[i]?.date_of_dfq;
          rowDataExtra.push(obj_date_of_dfq);
          obj_Identified[columnsName1[index].name] = a[i]?.Identified;
          rowDataExtra.push(obj_Identified);
          obj_Defined[columnsName1[index].name] = a[i]?.Defined;
          rowDataExtra.push(obj_Defined);
          obj_Committed_Owned[columnsName1[index].name] = a[i]?.Committed_Owned;
          rowDataExtra.push(obj_Committed_Owned);
          obj_Covered[columnsName1[index].name] = a[i]?.Covered;
          rowDataExtra.push(obj_Covered);
          obj_Demonstrated[columnsName1[index].name] = a[i]?.Demonstrated;
          rowDataExtra.push(obj_Demonstrated);
          obj_Obsolete[columnsName1[index].name] = a[i]?.Obsolete;
          rowDataExtra.push(obj_Obsolete);
          obj_Rejected[columnsName1[index].name] = a[i]?.Rejected;
          rowDataExtra.push(obj_Rejected);
        }
      }
    }
    // console.log('rowDataExtra', rowDataExtra);
    rowData.push(obj);
    const result = rowData.concat(
      rowDataExtra[0],
      rowDataExtra[1],
      rowDataExtra[2],
      rowDataExtra[3],
      rowDataExtra[4],
      rowDataExtra[5],
      rowDataExtra[6],
      rowDataExtra[7],
      rowDataExtra[8]
      // rowDataExtra[9]
    );
    setRowHere(result);
    // console.log('rowData Final:', result);
  }

  // tesxt Filed segment
  const handleTextChangeToLanguage = (e: any) => {
    setTextValueToText(e.target.value);
    setTimeout(() => {
      getColumnsName(displayOutput);
    }, 2000);
  };
  const handleEdit = ({ changed }) => {
    if (changed[Object.keys(changed)[0]] !== undefined) {
      if (objectsHere[Object.keys(changed)[0]] === 'id') {
        setSnack('id chaging not allowed', 'error');
        return;
      }
      if (objectsHere[Object.keys(changed)[0]] === 'quality_gate') {
        setSnack('Quality Gate chaging not allowed', 'error');
        return;
      }
      const changedColumnName = Object.keys(changed[Object.keys(changed)[0]]);
      const changedColumnNameValue = Object.values(changed[Object.keys(changed)[0]]);
      // console.log('changedColumnName:', changedColumnName[0]);
      // console.log('changedColumnValye:', changedColumnNameValue[0]);
      // console.log('changedRowposition:', Object.keys(changed)[0]);

      const changedRowpositionName = objectsHere[Object.keys(changed)[0]];
      // console.log('changedRowpositionName:', changedRowpositionName);

      // updating the data of for loop displayOutput
      const originalData = displayOutput;
      // console.log('originalData: ', originalData);
      // eslint-disable-next-line array-callback-return
      originalData?.map((a, i) => {
        // eslint-disable-next-line dot-notation
        if (a['quality_gate'] === changedColumnName[0]) {
          // eslint-disable-next-line prefer-destructuring
          a[changedRowpositionName] = changedColumnNameValue[0];
        }
      });
      getColumnsName(originalData);
      setDataForSave(originalData);
    }
  };
  const Cell = React.useCallback(
    (props) => {
      const {
        tableRow: { rowId },
        column: { name: columnName },
      } = props;
      const columnStatus = validationStatus[rowId]?.[columnName];
      const valid = !columnStatus || columnStatus.isValid;
      const style = {
        ...(!valid ? { border: '1px solid red' } : null),
      };
      const title = valid ? '' : validationStatus[rowId][columnName].error;

      return <Table.Cell {...props} style={style} title={title} />;
    },
    [validationStatus]
  );

  const handleSave = () => {
    if (dataForSave.length > 0) {
      saveEnggRediness(dataForSave)
        .then(() => {
          setSnack('save ok', 'success');
        })
        .catch((reason) => {
          console.error(reason);
        })
        .finally(() => {});
    }
  };

  // const handleSave = () => {
  //   const rowsEditedFiltered = displayOutput.filter((row) => idsChanged.includes(row.id));
  //   rowsEditedFiltered.forEach((obj) => {
  //     if (
  //       // eslint-disable-next-line
  //       // typeof obj.date_of_dfq !== 'number' ||
  //       typeof obj.date_of_dfq !== undefined
  //     ) {
  //       // console.log('obj.Date_of_Dfq', obj);
  //       if (obj.date_of_dfq !== null) {
  //         // ( "", null, undefined)
  //         // eslint-disable-next-line no-useless-escape
  //         const pattern = /^(\d{4})\-(\d{2})\-(\d{2})$/;
  //         const dateArray = obj.date_of_dfq.match(pattern);
  //         // eslint-disable-next-line radix
  //         const year = parseInt(dateArray[1]);
  //         // eslint-disable-next-line radix
  //         const month = parseInt(dateArray[2]);
  //         // eslint-disable-next-line radix
  //         const day = parseInt(dateArray[3]);
  //         const date = new Date(year, month - 1, day);
  //         const boolvalue =
  //           date.getFullYear() === year && date.getMonth() + 1 === month && date.getDate();
  //         if (!boolvalue) {
  //           setSnack('Date of DFQ Should be YYYY-MM-DD Like 2022-12-29', 'error');
  //           return;
  //         }
  //       }
  //     }
  //     /* eslint no-useless-return: "error" */
  //     if (
  //       // eslint-disable-next-line
  //       // typeof obj.date_of_dfq !== 'number' ||
  //       (typeof obj.Rejected !== 'number' &&
  //         // eslint-disable-next-line no-restricted-globals
  //         isNaN(obj.Rejected)) ||
  //       (typeof obj.Identified !== 'number' &&
  //         // eslint-disable-next-line no-restricted-globals
  //         isNaN(obj.Identified)) ||
  //       (typeof obj.Defined !== 'number' &&
  //         // eslint-disable-next-line no-restricted-globals
  //         isNaN(obj.Defined)) ||
  //       (typeof obj.Committed_Owned !== 'number' &&
  //         // eslint-disable-next-line no-restricted-globals
  //         isNaN(obj.Committed_Owned)) ||
  //       (typeof obj.Covered !== 'number' &&
  //         // eslint-disable-next-line no-restricted-globals
  //         isNaN(obj.Covered)) ||
  //       (typeof obj.Demonstrated !== 'number' &&
  //         // eslint-disable-next-line no-restricted-globals
  //         isNaN(obj.Demonstrated)) ||
  //       (typeof obj.Obsolete !== 'number' &&
  //         // eslint-disable-next-line no-restricted-globals
  //         isNaN(obj.Obsolete))
  //     ) {
  //       setSnack('Except Date of DFQ, All columns should be Number', 'error');
  //       // eslint-disable-next-line no-useless-return
  //       return;
  //     }
  //   });

  //   // if (rowsEditedFiltered.length > 0) {
  //   //   saveEnggRediness(rowsEditedFiltered)
  //   //     .then(() => {
  //   //       setSnack('save ok', 'success');
  //   //     })
  //   //     .catch((reason) => {
  //   //       console.error(reason);
  //   //     })
  //   //     .finally(() => {});
  //   // }
  // };

  return (
    <>
      {loading ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Loading />
        </div>
      ) : (
        ''
      )}
      {display && (
        <>
          <Paper style={{ margin: 30 }}>
            <Card style={{ border: '1px solid black' }}>
              <CardContent className={classes.heading}>
                Engineering Readiness - Project / Program Selection
              </CardContent>
              <Paper
                style={{
                  padding: 20,
                  //   marginBottom: 10,
                  background: '#EEF1F9',
                  fontSize: 14,
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: 'flex',
                      // justifyContent: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <span style={{ fontWeight: 'bold', color: '#002A45' }}>Program Name :</span>
                    <span
                      style={{
                        color: '#707070',
                        fontWeight: 'bold',
                        marginLeft: 5,
                      }}
                    >
                      {payload.project_program}
                    </span>
                  </Grid>
                  <Grid item xs={4}>
                    <span
                      style={{
                        fontWeight: 'bold',
                        color: '#002A45',
                        marginRight: 5,
                      }}
                    >
                      Segment :
                    </span>
                    <FormControl variant="outlined" size="small">
                      {/* <InputLabel htmlFor="outlined-age-native-simple" style={{ width: 500 }}>
                  Translated Language
                </InputLabel> */}
                      <Select
                        native
                        // label="Translated Language"
                        // name={'sheetname'.concat(indexIs)}
                        name="segment"
                        style={{ width: 170, marginTop: -8 }}
                        onChange={handleTextChangeToLanguage}
                        value={textValueToText}
                      >
                        <option aria-label="None" value="" disabled />
                        {segment?.length > 0 &&
                          segment.map((cap: string, index: number) => (
                            <option key={`capture-type-${index}`} value={cap}>
                              {cap}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: 'bold', color: '#002A45' }}>CT Code :</span>
                    <span
                      style={{
                        color: '#707070',
                        fontWeight: 'bold',
                        marginLeft: 5,
                      }}
                    >
                      {payload.ct_code}
                    </span>
                  </Grid>
                </Grid>
                <br />
                <Grid container>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: 'bold', color: '#002A45' }}>Region :</span>
                    <span
                      style={{
                        color: '#707070',
                        fontWeight: 'bold',
                        marginLeft: 5,
                      }}
                    >
                      {payload.region}
                    </span>
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: 'bold', color: '#002A45' }}>RM Name :</span>
                    <span
                      style={{
                        color: '#707070',
                        fontWeight: 'bold',
                        marginLeft: 5,
                      }}
                    >
                      {payload.rm_name}
                    </span>
                  </Grid>
                </Grid>
              </Paper>

              <Grid container>
                <Grid item xs={2} style={{ marginTop: 20 }}>
                  <List component="div" disablePadding className={classes.ListSubMain}>
                    <ListItem
                      button
                      className={classes.ListsubItemInsideHide2}
                      //   selected={selected === i}
                      //   onClick={() => setSelected(i)}
                    >
                      <ListItemIcon>{/* <SettingsOutlinedIcon /> */}</ListItemIcon>
                      <ListItemText primary="hi" style={{ color: 'black' }} />
                    </ListItem>
                    <ListItem
                      button
                      className={classes.ListsubItemInsideHide}
                      //   selected={selected === i}
                      //   onClick={() => setSelected(i)}
                    >
                      <ListItemIcon>{/* <SettingsOutlinedIcon /> */}</ListItemIcon>
                      <ListItemText primary="hi" style={{ color: 'black' }} />
                    </ListItem>
                    {displayOutput?.length > 0 &&
                      objectsHere?.map((list, i) => {
                        return (
                          <ListItem
                            button
                            className={classes.ListsubItemInside}
                            //   selected={selected === i}
                            //   onClick={() => setSelected(i)}
                          >
                            <ListItemIcon>{/* <SettingsOutlinedIcon /> */}</ListItemIcon>
                            <ListItemText primary={objectsHere[i]} />
                          </ListItem>
                        );
                      })}
                  </List>
                </Grid>
                <Grid item xs={10}>
                  <br />
                  {displayOutput?.length > 0 ? (
                    // <ExpressGrid rows={displayOutput} columns={colHere}>
                    <ExpressGrid rows={rowHere} columns={colHere}>
                      {/* <DataTypeProvider for={['Rejected']} formatterComponent={dateField} /> */}
                      <EditingState
                        editingRowIds={editingRowIds}
                        onEditingRowIdsChange={setEditingRowIds}
                        rowChanges={rowChanges}
                        onRowChangesChange={setRowChanges}
                        onCommitChanges={handleEdit}
                      />
                      <Table cellComponent={Cell} tableComponent={TableComponent} />
                      <TableHeaderRow />
                      <Toolbar rootComponent={toolbarComponent} />
                      <ToolBarPlugin name="Save" title="save" onClick={handleSave} />
                      <TableEditRow />
                      <TableInlineCellEditing />
                    </ExpressGrid>
                  ) : (
                    <ExpressGrid rows={[]} columns={columnsName}>
                      {/* <DataTypeProvider for={['Rejected']} formatterComponent={dateField} /> */}
                      <EditingState
                        editingRowIds={editingRowIds}
                        onEditingRowIdsChange={setEditingRowIds}
                        rowChanges={rowChanges}
                        onRowChangesChange={setRowChanges}
                        onCommitChanges={handleEdit}
                      />
                      <Table cellComponent={Cell} tableComponent={TableComponent} />
                      <TableHeaderRow />
                      <Toolbar rootComponent={toolbarComponent} />
                      <ToolBarPlugin name="Save" title="save" onClick={handleSave} />
                      <TableEditRow />
                      <TableInlineCellEditing />
                    </ExpressGrid>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Paper>
        </>
      )}
    </>
  );
}
