import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import logo from '../../../assets/welcome-large.png';
import './App.css';
import AppTopBar from '../../../components/Layout/AppTopBar';

const Welcome = () => {
  return (
    <Paper>
      <AppTopBar isActivated={false} />
      <div>
        <Typography align="center" variant="subtitle1">
          Please contact iSenS support team to activate your account.
        </Typography>
      </div>
      <img
        src={logo}
        alt="logo"
        style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '50%' }}
      />
    </Paper>
  );
};

export default Welcome;
