import { Column } from '@devexpress/dx-react-grid';

/* eslint-disable import/prefer-default-export */
const handleColumns = (cols: (string | Column)[]): Column[] => {
  const myCols = cols.map((key: any) => {
    if (typeof key === 'string')
      return {
        name: key,
        title: key[0]?.toUpperCase() + key.slice(1).replaceAll(/_/g, ' '),
      };
    return key;
  }) as Column[];
  return myCols;
};

export { handleColumns };
