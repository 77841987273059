/* eslint-disable jsx-a11y/label-has-associated-control */
import { IntegratedSelection, SelectionState } from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  Table,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Box, InputLabel, Select, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { encode } from 'html-entities';
import parserHtml from 'html-react-parser';
import React, { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { getFunctionProject, getIverifierConfig } from '../../../api/module3';
import Loading from '../../../components/Core/Loading';
import TableComponent from '../../../components/Datagrid/TableComponent';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import {
  tableContainerComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../components/Datagrid/TStyles';
import { IS_DEBUG_MODE } from '../../../const';
import { handleColumns } from '../../../tools/datagrid';
import ProjectContext from '../../Project/ProjectContext';

const useStyles = makeStyles(() => ({
  customSelect: {
    position: 'relative',
  },
}));

type Props = {
  ruleScript?: string;
  ruleType: 'conditionLocal' | 'result' | 'conditionFull';
  onSave?: any;
};

function RuleComposer({ ruleType, ruleScript, onSave }: Props) {
  const classes = useStyles();
  const { projectId } = useParams() as any;
  const { setSnack } = useContext(ProjectContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [buttonAddEnable, setButtonAddEnable] = useState<boolean>(true);
  // datalist
  const [attributes, setAttributes] = useState([]);
  const [operators, setOperators] = useState([]);
  // table rules composer
  const [selectionRulesComposer, setSelectionRulesComposer] = useState<any[]>([]);
  const [rulesComposition, setRulesComposition] = useState<any[]>([]);
  const columns = handleColumns(['rule']);

  // message
  const [messageSave, setMessageSave] = useState('');
  const [functionData, setFunctionData] = useState<any>();

  // handle composer form
  const { register: registerEditor, handleSubmit: handleSubmitEditor } = useForm<any>();

  const operatorsStatic = [
    {
      value: '$',
      label: 'contains ($)',
    },
    {
      value: '!$',
      label: 'not contains (!$)',
    },
    {
      value: '==',
      label: 'is equal to (==)',
    },
    {
      value: '!=',
      label: 'is not equal to (!=)',
    },
    // {
    //   value: '>',
    //   label: 'is greater than (>)',
    // },
    // {
    //   value: '>=',
    //   label: 'is greater than or equal to (>=)',
    // },
    // {
    //   value: '<',
    //   label: 'is less than (<)',
    // },
    // {
    //   value: '<=',
    //   label: 'is less than or equal to (<=)',
    // },
    {
      value: '==""',
      label: 'is empty (=="")',
    },
    {
      value: '!=""',
      label: 'is not empty (!="")',
    },
    {
      value: '%',
      label: 'includes (%)',
    },
    {
      value: '!%',
      label: 'not includes(!%)',
    },
    {
      value: '&&',
      label: 'and (&&)',
    },
    {
      value: '||',
      label: 'or (||)',
    },
    // {
    //   value: '!',
    //   label: 'not (!)',
    // },
  ];
  useEffect(() => {
    switch (ruleType) {
      case 'conditionFull':
        setMessageSave('Save your condition full');
        break;
      case 'conditionLocal':
        setMessageSave('Save your local condition');
        break;
      case 'result':
        setMessageSave('Save your expected result');
        break;
      default:
        setMessageSave('');
    }
    if (ruleScript !== '' && ruleScript) {
      setRulesComposition([{ rule: ruleScript }]);
    } else {
      setRulesComposition([]);
    }
    setSelectionRulesComposer([]);
  }, [ruleScript, ruleType]);

  useEffect(() => {
    // getAttributesByProject(projectId).then((response) => {
    //   setAttributes(response);
    // });
    // getOperatorsByProject(projectId).then((response) => {
    //   setOperators(response);
    // });
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    refreshPage(projectId);
    Promise.all([
      getIverifierConfig(projectId, 'Attributes'),
      getIverifierConfig(projectId, 'Operators'),
    ])
      .then((response: any) => {
        // console.log(response);
        if (response) {
          if (response[0].length > 0) {
            const array = response[0];
            const r = array[0]?.values.map((a: any) => {
              return { id: a.index, label: a.value };
            });
            setAttributes(r);
          }
          if (response[1].length > 0) {
            const array = response[1];
            const r = array[0]?.values.map((a: any) => {
              return { id: a.index, label: a.value };
            });
            setOperators(r);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function refreshPage(id: any) {
    getFunctionProject(id).then((response) => {
      setFunctionData(response);
    });
  }

  const handleEditorAddRule: SubmitHandler<any> = (data) => {
    // setButtonAddEnable(false);
    // eslint-disable-next-line eqeqeq
    if (data.rule_attribute == '') {
      setSnack('Empty rule_attribute selection', 'error');
      return;
    }
    // console.log('typeof data?.rule_value', typeof data?.rule_value);
    // console.log('data?.rule_value', data?.rule_value);
    let ruleValue: any;
    // data?.rule_value coming as String on keeping condition on null not working so need to check only value no typeof
    // eslint-disable-next-line eqeqeq
    if (data?.rule_operator == '') {
      ruleValue = `${data?.rule_value}`;
    } else {
      ruleValue = `'${data?.rule_value}'`;
    }

    if (data?.rule_operator !== undefined && data?.rule_operator !== '') {
      setRulesComposition([
        {
          rule: `${data?.rule_attribute}{{{${data?.rule_operator}}}}${ruleValue}`,
        },
        ...rulesComposition,
      ]);
    } else {
      setRulesComposition([
        {
          rule: `${data?.rule_attribute}${ruleValue}`,
        },
        ...rulesComposition,
      ]);
    }

    setTimeout(
      () => setSelectionRulesComposer([0, ...selectionRulesComposer.map((e) => Number(e) + 1)]),
      500
    );

    setTimeout(() => setButtonAddEnable(true), 700);

    // console.log('handleAddRule', data);
  };

  const handleRunCompose = (opLogical: string) => {
    try {
      setButtonAddEnable(false);
      if (selectionRulesComposer.length === 0) {
        setSnack('Empty selection', 'error');
        return;
      }

      let ruleComposed = '';

      if (opLogical === '!') {
        if (selectionRulesComposer.length > 1) {
          setSnack('Only one selection', 'error');
          return;
        }
        let rightExp = rulesComposition[selectionRulesComposer[0]].rule;
        if (rightExp.search(/\|\||&&|!/g) !== -1) {
          rightExp = `(${rightExp})`;
        }
        ruleComposed = `***${opLogical}***${rightExp}`;
      } else if (selectionRulesComposer.length <= 1) {
        setSnack('Select more than one', 'error');
        return;
      } else {
        ruleComposed = selectionRulesComposer.reduce((acc: string, e) => {
          // check if rule is present
          if (rulesComposition[e]?.rule === undefined) return acc;
          if (acc === '') return rulesComposition[e].rule;
          let leftExp = acc;
          let rightExp = rulesComposition[e].rule;
          if (acc.search(/\|\||&&|!/g) !== -1) {
            leftExp = `(${acc})`;
          }
          if (rulesComposition[e].rule.search(/\|\||&&|!/g) !== -1) {
            rightExp = `(${rulesComposition[e].rule})`;
          }
          return `${leftExp}***${opLogical}***${rightExp}`;
        }, '');
      }

      // const rulesFiltered = rulesComposition.filter((e, i) => {
      //   return !selectionRulesComposer.includes(i);
      // });

      setRulesComposition([{ rule: ruleComposed }, ...rulesComposition]);
      setTimeout(() => setSelectionRulesComposer([0]), 500);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => setButtonAddEnable(true), 700);
    }
  };

  const handleSaveComposition = () => {
    if (selectionRulesComposer.length !== 1) {
      setSnack('Only one selection', 'error');
      return;
    }
    onSave(String(rulesComposition[selectionRulesComposer[0]].rule).replace(/{{{|}}}|\*\*\*/g, ''));
  };

  const parseCell = (value: string) => {
    let valueUI = value;
    valueUI = valueUI.replace(/\*\*\*!\*\*\*/gi, ` <span style="color:red">!</span> `);
    valueUI = valueUI.replace(/\*\*\*\|\|\*\*\*/gi, ` <span style="color:red">||</span> `);
    valueUI = valueUI.replace(/\*\*\*&&\*\*\*/gi, ` <span style="color:red">&&</span> `);

    attributes
      .map((f) => f.label)
      .forEach((v) => {
        if (typeof v === 'string') {
          // Check if v is a string
          const valHtml = v
            .split('')
            .map((charOperator) => encode(charOperator))
            .join('');
          valueUI = valueUI.replace(
            new RegExp(
              v
                .split('')
                .map((charOperator) => `\\${charOperator}`)
                .join(''),
              'g'
            ),
            ` <span style="color:blue">${valHtml}</span> `
          );
        }
      });

    // functionData
    //   .map((f) => f.mapping)
    //   .forEach((v) => {
    //     valueUI = valueUI.replace(
    //       new RegExp(`{{{${v}}}}`, 'g'),
    //       ` <span style="color:blue">${v}</span> `
    //     );
    //   });

    [...operatorsStatic.map((f) => f.value)].forEach((v) => {
      if (typeof v === 'string') {
        // Ensure v is a string
        const valReg = v
          .split('')
          .map((charOperator) => `\\${charOperator}`)
          .join('');
        const valHtml = v
          .split('')
          .map((charOperator) => encode(charOperator))
          .join('');
        // console.log(valHtml);

        valueUI = valueUI.replace(
          new RegExp(`{{{${valReg}}}}`, 'g'),
          ` <span style="color:green">${valHtml}</span> `
        );
      }
    });

    // console.log(valueUI);
    return parserHtml(valueUI);
  };
  const HighlightedCell = ({ value, style, ...restProps }) => (
    <Table.Cell
      {...restProps}
      style={{
        fontWeight: 'bold',
        ...style,
      }}
    >
      {parseCell(value)}
    </Table.Cell>
  );

  const Cell = (props: any) => {
    const { columns } = props;
    return <HighlightedCell {...props} />;
  };

  if (loading) return <Loading />;

  return (
    <>
      {IS_DEBUG_MODE && (
        <ReactJson
          src={{
            ruleType,
            selectionRulesComposer,
            rulesComposition,
            ruleScript,
          }}
          collapsed={true}
          theme="monokai"
        />
      )}
      <h3>
        <center>Compose your rule</center>
      </h3>
      <Box style={{ border: '2px solid grey', borderRadius: '5px', padding: '10px' }}>
        <div className="customSelect">
          <InputLabel id="rule-attribute-label">
            <b>Attributes list OR Function</b>
          </InputLabel>
          <Select
            {...registerEditor('rule_attribute')}
            native
            labelId="rule-attribute-label"
            id="rule-attribute"
            label="Attributes list"
            fullWidth
            style={{ backgroundColor: '#D3D3D3' }}
          >
            <option value="">-- select an option --</option>
            {attributes.map((e: any) => {
              return <option value={e?.label}>&nbsp;{e?.label}</option>;
            })}

            {functionData?.map((ee: any) => {
              return <option value={ee.mapping}>&nbsp;{ee.function}</option>;
            })}
          </Select>
        </div>
        <div className="customSelect" style={{ marginTop: 5 }}>
          <InputLabel id="rule-operator-label">
            <b>Operators list</b>
          </InputLabel>
          <Select
            {...registerEditor('rule_operator')}
            native
            labelId="rule-operator-label"
            id="rule-operator"
            // value={age}
            label="Operators list"
            // onChange={handleChange}
            fullWidth
            style={{ backgroundColor: '#D3D3D3' }}
          >
            <option value="">-- select an option --</option>
            {operatorsStatic.map((p) => (
              <option value={p.value}>&nbsp;{p.label}</option>
            ))}

            {operators.map((e: any) => {
              return <option value={e?.label}>&nbsp;{e?.label}</option>;
            })}
          </Select>
        </div>

        <TextField
          {...registerEditor('rule_value')}
          variant="outlined"
          label="Rule value"
          placeholder="Rule value"
          size="small"
          // className={classes.textField}
          // onBlur={handleCommentsChange}
          key="rule-value"
          defaultValue=""
          style={{ marginTop: '10px' }}
          fullWidth
        />
        <br />
        <Button
          variant="contained"
          color="primary"
          disabled={!buttonAddEnable}
          onClick={handleSubmitEditor(handleEditorAddRule)}
          style={{ marginTop: '10px' }}
        >
          Add
        </Button>
      </Box>
      <Box style={{ border: '2px solid grey', borderRadius: '5px', padding: '5px', marginTop: 5 }}>
        <ExpressGrid rows={rulesComposition} columns={columns}>
          <SelectionState
            selection={selectionRulesComposer}
            onSelectionChange={setSelectionRulesComposer}
          />
          {rulesComposition.length >= 1 && <IntegratedSelection />}

          <Table
            columnExtensions={columns.map((c) => ({
              columnName: c.name,
              wordWrapEnabled: true,
            }))}
            tableComponent={TableComponent}
            containerComponent={tableContainerComponent}
            headComponent={tableHeaderComponent}
            cellComponent={Cell}
          />
          <TableHeaderRow />
          <Toolbar rootComponent={toolbarComponent} />
          {/* selection */}
          {rulesComposition.length >= 1 && <TableSelection showSelectAll />}
          {/* <ToolBarPlugin name="MergeRepeat" title="Flat rule" onClick={handleFlatRule} /> */}
          <ToolBarPlugin name="freetext" title="AND" onClick={() => handleRunCompose('&&')} />
          <ToolBarPlugin name="freetext" title="OR" onClick={() => handleRunCompose('||')} />
          <ToolBarPlugin name="freetext" title="NOT" onClick={() => handleRunCompose('!')} />
          {/* <ToolBarPlugin name="SaveRed" title="Save composition" onClick={handleSaveComposition} /> */}
          {/* <ToolBarPlugin name="Delete" onClick={null} /> */}
        </ExpressGrid>
      </Box>
      <Box style={{ marginTop: 5 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={!buttonAddEnable}
          onClick={handleSaveComposition}
        >
          {messageSave}
        </Button>
      </Box>
    </>
  );
}

export default RuleComposer;
