/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  DataTypeProvider,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { Box, Button, CircularProgress, Typography, ClickAwayListener } from '@material-ui/core';
import Popover from '@mui/material/Popover';
import Typographys from '@mui/material/Typography';
import CustomTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import { withStyles } from '@material-ui/styles';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import axios from 'axios';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import PublishIcon from '@mui/icons-material/Publish';
import { isEmpty } from '@antv/util';
import styled, { css, keyframes } from 'styled-components';
import { Paper } from '@mui/material';
import TStyles, { rowComponent, toolbarComponent } from '../../../components/Datagrid/TStyles';
import AdminDefaultFileDownload from '../../../tools/AdminDefaultFileDownload';
import './FileTranslator.css';
import DragAndDrop from '../../../components/Core/DragAndDrop';
import { CsvDoc } from '../../../components/DocumentTranslator/const/png';
import { getAllProjectCreationLanguages } from '../../../api/projects';
import { BACKEND_URL } from '../../../const';
import { getAdminDefaultGlossary, adminFolderDelete } from '../../../api/folders';
import AdminContext from '../AdminContext';
import Loading from '../../../components/Core/Loading';

const ProgressBarContainer = styled.div`
  border-radius: 10px;
  width: 100%;
  background-color: #d1d9ef;
`;

const LabelText = styled.div`
  padding: 0.3rem;
  width: 4rem;
  background-color: #eef1f9;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: 1px solid #d1d9ef;
`;

const PercentageLine = styled.div`
  border-radius: 10px;
  padding: 2px;
  background-color: #19aa6e;
  color: white;
  display: flex;
  justify-content: flex-end;
  ${({ percentage }) => css`
    width: ${percentage < 10 ? 10 : percentage}%;
  `};
  transition: width 500ms;
`;

const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'fromLang', title: 'From' },
  { name: 'toLang', title: 'To' },
  // { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
  { name: 'userName', title: 'Uploaded by' },
];

const SupportedFiles = ['csv'];

export const DropDownLanguages = React.memo(
  ({ languageCode, setValueLang, valueLang, palceHolder }: any) => {
    const [pin, setPin] = useState(false);
    const [text, setText] = useState('');
    const [filterLang, setFilterLang] = useState(languageCode);

    useEffect(() => {
      setText('');
      setFilterLang(languageCode);
    }, [languageCode, pin]);

    const handleInput = (e: { target: { value: string } }) => {
      const filteredData = languageCode.filter((data: any) => {
        const t = data.toLowerCase();
        const r = e.target.value.toLowerCase();
        return t.includes(r);
      });
      if (filteredData.length > 0) setFilterLang(filteredData);
      else setFilterLang(languageCode);
      setText(e.target.value);
    };

    const handleLangugeValue = (data: string) => {
      setPin(false);
      setValueLang(data);
    };

    return (
      <>
        <div className="main-menu-wrapper-doc">
          <div className="main-menu-btn">
            <button
              style={!valueLang ? { justifyContent: 'space-between' } : {}}
              className="btn"
              onClick={() => {
                setPin(true);
              }}
            >
              {!valueLang && (
                <Typography style={{ fontSize: '0.85em', fontWeight: 'bold' }}>
                  {palceHolder}
                </Typography>
              )}
              {valueLang?.split('-')?.slice(0, 1)}
              <KeyboardArrowDownIcon />
            </button>
          </div>
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => pin && setPin(false)}
          >
            <div
              style={
                pin ? { display: 'block', opacity: '1', zIndex: '998', pointerEvents: 'auto' } : {}
              }
              className="sub-menu-wrapper d-none"
            >
              <div
                style={pin ? { opacity: '1', animation: 'fadeInUp 0.8s both' } : {}}
                className="sub-menu-inner-wrapper-doc"
              >
                {/* <div className="arrow">{}</div> */}
                <div className="container">
                  <div className="container-header">
                    <input
                      value={text}
                      placeholder={palceHolder}
                      className="input"
                      onChange={handleInput}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0.2rem' }}>
                      <button
                        className="close-btn"
                        onClick={() => {
                          setPin(false);
                        }}
                        label="btn"
                      >
                        <CloseIcon fontSize="small" />
                      </button>
                    </div>
                  </div>
                  <div className="sub-menu-list-wrapper">
                    <div className="sub-menu-list">
                      {filterLang?.slice(0, 18).map((data: string) => {
                        const text = data.split('-');
                        return (
                          <div key={data} className="sub-menu-item">
                            <button
                              className={`sub-menu-item-btn${
                                valueLang === data ? '-selected' : ''
                              }`}
                              // className="sub-menu-item-btn"
                              onClick={() => {
                                handleLangugeValue(data);
                              }}
                            >
                              {text[0]}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    <div className="sub-menu-list">
                      {filterLang?.slice(18, 36).map((data: string) => {
                        const text = data.split('-');
                        return (
                          <div key={data} className="sub-menu-item">
                            <button
                              className={`sub-menu-item-btn${
                                valueLang === data ? '-selected' : ''
                              }`}
                              // className="sub-menu-item-btn"
                              onClick={() => {
                                handleLangugeValue(data);
                              }}
                            >
                              {text[0]}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    <div className="sub-menu-list">
                      {filterLang?.slice(36, 54).map((data: string) => {
                        const text = data.split('-');
                        return (
                          <div key={data} className="sub-menu-item">
                            <button
                              className={`sub-menu-item-btn${
                                valueLang === data ? '-selected' : ''
                              }`}
                              // className="sub-menu-item-btn"
                              onClick={() => {
                                handleLangugeValue(data);
                              }}
                            >
                              {text[0]}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    <div className="sub-menu-list">
                      {filterLang?.slice(54, 70).map((data: string) => {
                        const text = data.split('-');
                        return (
                          <div key={data} className="sub-menu-item">
                            <button
                              className={`sub-menu-item-btn${
                                valueLang === data ? '-selected' : ''
                              }`}
                              // className="sub-menu-item-btn"
                              onClick={() => {
                                handleLangugeValue(data);
                              }}
                            >
                              {text[0]}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </>
    );
  }
);

function FileTranslator({ home = '' }: Props) {
  const { projectId } = useParams() as any;
  const { setSnack } = useContext(AdminContext);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [selection, setSelection] = useState([]);
  const [languageCode, setLanguageCode] = useState();

  const [filter, setFilter] = useState<any>([]);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [files, setFiles] = useState<any>([]);
  const [openDeleteFiles, setOpenDeleteFiles] = useState(false);
  // New Document Translator states
  const [selectionRows, setSelectionRows] = useState<any[]>([]);
  const [fromLang, setFromLang] = useState('');
  const [toLang, setToLang] = useState('');
  const [loading, setLoading] = useState(false);

  const inputRef = useRef();
  const [iconLoad, setIconload] = useState(false);
  const [toggleTab, setToggleTab] = useState({
    left: '0rem',
  });

  const responseData = (res, upload = 'mount') => {
    const resources = res.map((data) => {
      return { ...data.resources, id: data.id };
    });
    setLoading(true);
    setTimeout(() => {
      setFiles(resources);
      setLoading(false);
    }, 2000);

    setIconload(false);
    if (upload === 'upload') {
      setSnack(`File uploaded successfully`, 'success');
    }
  };

  useEffect(() => {
    getAllProjectCreationLanguages().then((dbProjects) => {
      const db = dbProjects.map((item) => {
        return `${item.language}-${item.codes}`;
      });
      setLanguageCode(db);
    });

    getAdminDefaultGlossary().then((res) => {
      responseData(res);
    });
  }, []);

  const handleSelectionChange = (selectedKeys: any) => {
    setFilter([]);
    const ArrayFiles = selectedKeys;
    if (ArrayFiles.length > 1) {
      setSnack('more than one files are not allowed!', 'error');
    } else {
      const data = selectedKeys.map((data) => {
        return files[data];
      });
      setSelectionRows(selectedKeys);
    }
  };

  const UploadFile = () => {
    const FromCheck = files.map((item) => {
      return item.fromLang;
    });

    const ToCheck = files.map((item) => {
      return item.toLang;
    });

    if (!fromLang) {
      setSnack(`Please select From language`, 'error');
      return;
    }

    if (!toLang) {
      setSnack(`Please select To language`, 'error');
      return;
    }

    if (FromCheck.includes(fromLang) && ToCheck.includes(toLang)) {
      setSnack('You allready uploaded form and To language file', 'error');
      return;
    }

    inputRef.current.click();
  };

  // eslint-disable-next-line consistent-return
  const uploadURLCall = async (uploadUrl: any, formdata: FormData) => {
    try {
      const uplResponse = await axios.post(uploadUrl, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return uplResponse.data;
    } catch (error) {
      console.log(error);
    }
  };
  // tooltip
  const ToolTipComponent = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const rows = [
      { col1: 'Fluid', col2: 'Fluide' },
      { col1: 'Fuel', col2: 'Combustible' },
      { col1: 'Fuse', col2: 'Fusible' },
      { col1: 'Pneumatic', col2: 'Pneumatique' },
    ];

    return (
      <>
        <div
          style={{
            position: 'absolute',
            top: '10px',
            right: '3px',
            height: '15px',
            width: '15px',
            borderRadius: '50%',
            backgroundColor: 'red',
            color: 'white',
            padding: '2px',
            fontSize: '9px',
          }}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          i
        </div>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          PaperProps={{
            style: {
              width: '25rem',
              backgroundColor: '#002A45',
              color: 'white',
              borderRadius: '19px',
              textAlign: 'justify',
            },
          }}
          // style={{
          //   width: '40rem',
          //   backgroundColor: '#002A45',
          //   // color: 'white',
          //   // borderRadius: '19px',
          // }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typographys sx={{ p: 1 }} style={{ fontSize: '0.98em' }}>
            {' '}
            A glossary is a list of terms with definitions that you create for the Document
            Translation service to use during the translation process.
          </Typographys>
          <Typographys sx={{ p: 1 }} style={{ fontSize: '0.98em' }}>
            {' '}
            The following shall be the format of the glossary containing two columns: (
            <b> note :- only CSV file is supported </b>)
          </Typographys>
          <Paper sx={{ overflow: 'hidden', margin: '20px' }}>
            <TableContainer sx={{ maxHeight: 300 }}>
              <CustomTable size="small" aria-label="caption table">
                {/* <caption>A basic table example with a caption</caption> */}

                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.col1}>
                      <TableCell scope="row">{row.col1}</TableCell>
                      <TableCell align="left">{row.col2}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </CustomTable>
            </TableContainer>
          </Paper>
        </Popover>
      </>
    );
  };

  const handleDownload = () => {
    if (selectionRows.length === 0) return;
    const selectedRows = files.filter((row, index) => selectionRows.includes(index));
    const downloadItems = selectedRows[0].id;
    const querystring = `filename=${encodeURI(
      selectedRows[0].filename
    )}&folder_id=${downloadItems}`;
    setSelectionRows([]);
    AdminDefaultFileDownload(`/admin_default_glossary/download/resources`, querystring);
  };

  const handleDeleteFiles = () => {
    if (selectionRows.length === 0) return;
    const selectedRows = files.filter((row, index) => selectionRows.includes(index));
    const keptRows = files.filter((row, index) => !selectionRows.includes(index));
    const deleteItems = selectedRows[0].id;
    adminFolderDelete(deleteItems)
      .then(() => {
        setFiles(keptRows);
        setSnack(`${selectedRows.length} files delet-ed`, 'success');
      })
      .catch((e) => {
        if (e.detail) setSnack(e.detail, 'error');
        else setSnack('Something went wrong', 'error');
      })
      .finally(() => {
        setSelectionRows([]);
        setOpenDeleteFiles(false);
      });
  };

  const handleChangeFiles = (event: any) => {
    try {
      const len = Object?.keys(event)?.length;
      const arraylist = [];
      for (let i = 0; i < len; i += 1) {
        arraylist.push(event[i]);
      }
      for (const eachFile of arraylist) {
        const ext = eachFile?.name?.split('.');

        if (['xlsx', 'pdf', 'docx'].includes(ext[1])) {
          setSnack(`${eachFile.name} not supported`, 'error');
          return;
        }
        if (!SupportedFiles.includes(ext[ext?.length - 1])) {
          // setSnack(`${eachFile.name} not supported`, 'error');
          return;
        }
        if (eachFile.name?.length >= 80) {
          // setSnack('file name should be less than 80 characters', 'error');
          return;
        }
      }

      const formdata = new FormData();
      const names = arraylist.map((e: any) => {
        formdata.append('files', e);
        return e.name;
      });
      for (const eachFile of files) {
        if (names.includes(eachFile.filename)) {
          // setSnack(`File name ${eachFile.filename} already exists.`, 'error');
          return;
        }
      }
      if (formdata.get('files')) {
        setIconload(true);
        const uploadUrl = `${BACKEND_URL.adminUploadGlossaryDoc}?from=${fromLang}&source=${toLang}`;
        uploadURLCall(uploadUrl, formdata).then((result) => {
          responseData(result, 'upload');
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const filehandleChangeFiles = (event: any) => {
    const e = event?.target?.files || '';
    handleChangeFiles(e);
  };

  const draghandleChangeFiles = (event: any) => {
    const arr = [];
    arr.push(event);
    handleChangeFiles(arr);
  };

  const renderFileName = ({ row }: any) => {
    const ext = row.filename.split('.');
    const extensions = {
      csv: CsvDoc,
    };
    return (
      <div
        style={{
          position: 'relative',
          padding: '0.3rem',
          width: 'max-content',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <img src={extensions[ext[ext.length - 1]]} height="30" width="30" alt="img" />
          <Typography>{row.filename}</Typography>
        </div>
        {row?.isNew && (
          <div
            style={{
              width: 'max-content',
              padding: '0.2rem',
              borderRadius: '10px',
              backgroundColor: '#19AA6E',
              color: 'white',
              position: 'absolute',
              top: '-12px',
              right: '-35px',
            }}
          >
            <Typography style={{ fontSize: '0.85em' }}>New !</Typography>
          </div>
        )}
      </div>
    );
  };

  const renderUploadDate = ({ row }) => {
    const dates = row.date.split(' ');
    return (
      <>
        {dates[0]} {dates[1].split('.')[0]}
      </>
    );
  };

  return (
    <>
      <Box
        style={home === 'Home' ? { borderRadius: 'none' } : { borderRadius: '10px' }}
        className="fileTrans-container"
      >
        <Box
          style={
            home === 'Home'
              ? { borderRadius: 'none' }
              : { borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }
          }
          className="fileTrans-container-rowone"
        >
          <Box className="fileTrans-container-rowone-menu">
            <DropDownLanguages
              languageCode={languageCode}
              valueLang={fromLang}
              setValueLang={setFromLang}
              palceHolder="Enter From Language"
            />

            <DropDownLanguages
              languageCode={languageCode}
              valueLang={toLang}
              setValueLang={setToLang}
              palceHolder="Enter To Language"
            />

            <Button
              variant="contained"
              color="primary"
              onClick={UploadFile}
              startIcon={
                iconLoad ? (
                  <CircularProgress style={{ color: 'white' }} size={20} />
                ) : (
                  <PublishIcon />
                )
              }
            >
              Upload
            </Button>
            {/* <Button variant="contained" color="primary" onClick={TranslateFile}>
              Translate
            </Button> */}
          </Box>
          <Box style={{ display: 'flex' }}>
            <input type="file" multiple ref={inputRef} hidden onChange={filehandleChangeFiles} />
            <Box>
              <input type="file" multiple ref={inputRef} hidden onChange={filehandleChangeFiles} />
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleDownload()}
                style={{ marginRight: '5px' }}
                startIcon={<GetAppIcon />}
              >
                Download
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDeleteFiles()}
              style={{ marginRight: '5px' }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </Box>
        </Box>
        <Box className="fileTrans-container-rowsecond">
          <Box className="fileTrans-container-rowsecond-top-bar">
            <Button
              style={{ width: '6rem' }}
              onClick={(e) => {
                e.stopPropagation();
                // setShowProcessFile(false);
                setToggleTab((prev) => {
                  return {
                    ...prev,
                    left: '0rem',
                  };
                });
              }}
            >
              <div style={{ marginRight: 20 }}>Glossary</div>
              <div>
                <ToolTipComponent />
              </div>
            </Button>

            <div
              onClick={(e) => e.stopPropagation()}
              style={toggleTab}
              className="top-bar-file-line"
            >
              {}
            </div>
          </Box>
          {toggleTab.left === '0rem' && loading === true ? (
            <Loading />
          ) : (
            <DragAndDrop onChange={draghandleChangeFiles}>
              <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
                <ExpressGrid rows={files} columns={columns}>
                  <SelectionState
                    selection={selectionRows}
                    onSelectionChange={handleSelectionChange}
                  />
                  <FilteringState />
                  <IntegratedFiltering />
                  <DataTypeProvider for={['filename']} formatterComponent={renderFileName} />
                  <DataTypeProvider for={['date']} formatterComponent={renderUploadDate} />
                  <SortingState />
                  <IntegratedSorting />
                  <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                  <IntegratedPaging />
                  <VirtualTable />
                  <Table tableComponent={TableComponent} />
                  <TableColumnResizing
                    defaultColumnWidths={[
                      { columnName: 'filename', width: '300px' },
                      { columnName: 'size', width: '150px' },
                      { columnName: 'folderName', width: '100px' },
                      { columnName: 'fromLang', width: '100px' },
                      { columnName: 'toLang', width: '100px' },
                      { columnName: 'date', width: '200px' },
                      { columnName: 'userName', width: '200px' },
                    ]}
                  />
                  <TableHeaderRow showSortingControls />
                  <TableFilterRow rowComponent={rowComponent} />
                  <TableSelection />
                  <PagingPanel pageSizes={pageSizes} />
                </ExpressGrid>
              </Box>
            </DragAndDrop>
          )}
        </Box>
      </Box>
    </>
  );
}
export default FileTranslator;
