/* eslint-disable react/destructuring-assignment */
import {
  FilteringState,
  IntegratedPaging,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  Table,
  PagingPanel,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import {
  CircularProgress,
  fade,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
} from '@material-ui/core';
import { iCapturePdf, fileDetails } from '../../../api/module1';
import { idcaApi } from '../../../api/module2';
import Module3Context from '../Module3Context';
import formatBytes from '../../../tools/formatBytes';
import BDCidca from './BDCidca';
import DSCidca from './DSCidca';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  tableHeaderComponent,
  tUseStyles,
} from '../../../components/Datagrid/TStyles';
import MultiAllocTableComp from '../../../components/Datagrid/MultiAllocTableComp';
import NavigationGotoNew from '../../../components/Core/NavigationGotoNew';

const useStylesForm = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const styles = (theme: any) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.15),
    },
    '& thead tr:nth-of-type(odd)': {
      backgroundColor: theme.palette.primary.light,
    },
    '& th': {
      color: '#7B909C',
      fontWeight: 'bold',
    },
  },
});
// table header
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);
const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
  { name: 'userName', title: 'Uploaded by' },
];

const columnsOP: any = [
  { name: 'PageNo', title: 'PAGENO' },
  { name: 'Hierarchy', title: 'HIERARCHY' },
  { name: 'Text', title: 'TEXT' },
  { name: 'Sim Page No', title: 'PAGENO' },
  { name: 'Sim Hierarchy', title: 'HIERARCHY' },
  { name: 'Similar Requirement', title: 'TEXT' },
  { name: 'Tag', title: 'TAG' },
  { name: 'Similarity', title: 'SIMILARITY' },
];

// const COLORS = ['green', 'red', '#FFBB28', '#FF8042', '#FFFF99'];
const COLORS = ['#FF8042', 'blue', 'red', '#FFFF99', '#FFBB28', 'green'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function TabContainer(props: any) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  bloc: {
    borderRadius: 10,
    border: 'solid 1px;',
    borderColor: theme.palette.primary.light,
    margin: 10,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.primary.light,
  },
  resultPanel: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: '0px !important',
  },
  panelMain: {
    border: '1px solid #002A45',
    borderRadius: 0,
    padding: '0px !important',
  },
  tableRadius: {
    borderRadius: '10px',
  },
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
}));

function UnderIdca() {
  const classesForm = useStylesForm();
  const [drop, setDrop] = React.useState('');
  const [arr, setArr] = React.useState<any>([]);
  const [arr2, setArr2] = React.useState<any>([]);
  const [arr3, setArr3] = React.useState<any>([]);
  const [arr4, setArr4] = React.useState<any>([]);
  const [arr5, setArr5] = React.useState<any>([]);
  const [colorButton, setColorButton] = React.useState('');
  const [col, setCol] = useState({});
  const [tempPage, setTemPage] = useState(0);
  // const [selectedItem, setSelectedItem] = React.useState('');
  const handleChangeDrop = (event) => {
    setDrop(event.target.value);
    // setSelectedItem(event.target.value);
    // alert(event.target.value);
    if (event.target.value === '1') bdcCall();
    else if (event.target.value === '2') dscCall();
    else {
      setLoading(false);
      setDisplay(false);
    }
    // console.log(idcaJson.templateStructure.matchingScore);
    // getFileDetails();
    // alert('selection changed');
  };
  function bdcCall() {
    // console.log('bdc called');
    idcaApICallFun(container1, fileIs1, fileIs2);
  }
  function dscCall() {
    // console.log('dsc called');
    idcaApICallFun(container1, fileIs1, fileIs2);
  }

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [pageSizes] = useState([5, 10, 20, 50]);
  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };
  const { project } = useContext(Module3Context);
  const [files, setFiles] = useState<any[]>([]);
  const [selection, setSelection] = useState([]);
  // for table data
  const [filesOP1, setFilesOP1] = useState<any[]>([]);
  const [filesOP2, setFilesOP2] = useState<any[]>([]);
  // for graph
  const [graphOP1, setGraphOP1] = useState<any>([]);
  const [graphOP2, setGraphOP2] = useState<any>([]);
  // to control the loader and display data full Grid
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  // to get filname
  const [fileName1, setFileName1] = useState<any>('');
  const [fileName2, setFileName2] = useState<any>();
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [dis, setDis] = useState(true);
  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  useEffect(() => {
    const { folders } = project;
    const allResources = [] as any[];
    if (folders) {
      for (const eachFolder of folders) {
        if (eachFolder.resources && eachFolder.resources.length > 0)
          for (const r of eachFolder.resources) {
            // console.log(r);
            allResources.push({
              ...r,
              size: formatBytes(Number(r.size), 0),
              id: r.etag,
              folderName: eachFolder.name,
              folderId: eachFolder.id,
              // container: r.container,
            });
          }
      }
    }
    setFiles(allResources.sort((a, b) => new Date(b.date) - new Date(a.date)));
  }, []);
  useEffect(() => {
    if (pageSize > files.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < files.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);

  function getFileDetails() {
    setLoading(true);
    setDisplay(false);
    // promise all start
    Promise.all([
      fileDetails(
        files[selection[0]].filename,
        files[selection[0]].filename,
        files[selection[0]].container
      ),
      fileDetails(
        files[selection[1]].filename,
        files[selection[1]].filename,
        files[selection[1]].container
      ),
    ])
      .then((responses) => {
        // Get a JSON object from each of the responses
        return Promise.all(
          responses.map((response) => {
            return response;
          })
        );
      })
      .then((data) => {
        // Log the data to the console
        // You would do something with both sets of data here
        // console.log(data);
        setFileName1(data[0].output.FileName);
        setFileName2(data[1].output.FileName);
        // promise all 2nd start
        Promise.all([
          iCapturePdf(
            data[0].output.FileName,
            data[0].output.FilePath,
            data[0].output.FileType,
            data[0].output.Container,
            data[0].output.Blob,
            data[0].output.Language,
            data[0].output.StartPage,
            data[0].output.EndPage,
            data[0].output.HeaderMargin,
            data[0].output.FooterMargin,
            data[0].output.NameOfCapture,
            data[0].output.TypesOfCapture[0],
            '',
            false,
            false,
            false
          ),
          iCapturePdf(
            data[1].output.FileName,
            data[1].output.FilePath,
            data[1].output.FileType,
            data[1].output.Container,
            data[1].output.Blob,
            data[1].output.Language,
            data[1].output.StartPage,
            data[1].output.EndPage,
            data[1].output.HeaderMargin,
            data[1].output.FooterMargin,
            data[1].output.NameOfCapture,
            data[1].output.TypesOfCapture[0],
            '',
            false,
            false,
            false
          ),
        ])
          .then((responses) => {
            return Promise.all(
              responses.map((response) => {
                return response;
              })
            );
          })
          .then((data) => {
            // console.log(data);
            docDifferApICallFun(data[0].output, data[1].output);
          })
          .catch((error) => {
            console.log(error);
          });
        // promise all 2nd end
      })
      .catch((error) => {
        // if there's an error, log it
        console.log(error);
      });
    // promise all end
  }

  function idcaApICallFun(a: any, b: any, c: any) {
    // iDcaApi API start
    setLoading(true);
    idcaApi({ container: a, templateFileBlobName: b, projectFile: c })
      .then((payload) => {
        // console.log('a=', a, 'b=', b, 'c=', c);
        resArray1 = payload.basic_info.map((item, index) => {
          // return item.name;
          return item;
        });
        setArr(resArray1);
        // console.log('arr1', arr);
        resArray2 = payload.template_structure.templateTree.map((item2, index) => {
          // return item.name;
          return item2;
        });
        setArr2(resArray2);
        // console.log('arr2', arr2);

        resArray3 = payload.template_structure.docTree.map((item3, index) => {
          return item3;
        });
        setArr3(resArray3);
        resArray4 = payload.template_structure.proposedTree.map((item4, index) => {
          // return item.name;
          return item4;
        });
        setArr4(resArray4);
        resArray5 = payload.template_structure.matchingScore;
        setArr5(resArray5);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setDisplay(true);
      });
  }

  let resArray1: [];
  let resArray2: [];
  let resArray3: [];
  let resArray4: [];
  let resArray5;
  let fileIs1;
  let fileIs2;
  let container1;
  // let container2;
  if (selection.length > 0 && selection[0] !== undefined) {
    fileIs1 = files[selection[0]].filename || '';
    fileIs2 = selection[1] !== undefined ? files[selection[1]].filename : '';
    container1 = files[selection[0]].container;
    // container2 = files[selection[1]].container;
    // console.log(container1);
  }
  return (
    <>
      <div>
        <span style={{ fontSize: '14px' }}>
          <b>Select Template File:</b> {fileIs1} <br />
          <b> Select Project File:</b> {fileIs2} <br />
        </span>
      </div>
      <div>
        <Paper className={classes.panelMain}>
          <ExpressGrid rows={files} columns={columns}>
            <SelectionState selection={selection} onSelectionChange={setSelection} />
            <FilteringState defaultFilters={[{ columnName: 'filename', value: 'docx' }]} />
            <IntegratedFiltering />
            <SortingState />
            <IntegratedSorting />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <VirtualTable />
            <Table
              tableComponent={MultiAllocTableComp}
              containerComponent={tableAllocatorComponent}
              headComponent={tableHeaderComponent}
            />
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            <TableSelection />
            {/* <PagingPanel pageSizes={pageSizes} /> */}
            <PagingPanel
              pageSizes={pageSizes}
              containerComponent={(props) => (
                <>
                  <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
                </>
              )}
            />
          </ExpressGrid>
          <NavigationGotoNew
            setPageSize={setPageSize}
            fileLength={files?.length}
            pageSize={pageSize}
            dis={dis}
            setTemPage={setTemPage}
            handlePageNum={handlePageNum}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Paper>
      </div>
      {/* <span>Total rows selected: {selection.length}</span> */}
      <br />
      <span>
        <div>
          <FormControl variant="outlined" className={classesForm.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Select Comparison Level</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={drop}
              disabled={selection.length < 2 || selection.length > 2}
              onChange={handleChangeDrop}
              label="Select Comparison Level"
            >
              <MenuItem value="" disabled>
                {' '}
              </MenuItem>
              <MenuItem value="1">Basic Document Comparison</MenuItem>
              <MenuItem value="2">Document Skeleton Comparison</MenuItem>
            </Select>
          </FormControl>
        </div>
      </span>
      <br />
      {loading ? (
        <center>
          <CircularProgress color="secondary" />
        </center>
      ) : (
        <>
          {drop === '1' ? (
            <div className={classes.root}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  id="discrete-slider-always"
                  gutterBottom
                  style={{ marginTop: 20 }}
                >
                  Basic Document Comparison
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CssBaseline />
                <Container fixed>
                  <Typography component="div" style={{ backgroundColor: '#cfe8fc' }}>
                    <BDCidca arr={arr} />
                  </Typography>
                </Container>
              </Grid>
            </div>
          ) : (
            ''
          )}
          {drop === '2' ? (
            <div className={classes.root}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  id="discrete-slider-always"
                  gutterBottom
                  style={{ marginTop: 0 }}
                >
                  Document Skeleton Comparison
                </Typography>
                <Typography
                  variant="subtitle1"
                  align="left"
                  id="discrete-slider-always"
                  gutterBottom
                  style={{ marginTop: -10 }}
                >
                  Total Matching Score: {arr5}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Container fixed>
                  <table>
                    <tr>
                      <td>
                        <svg width="15" height="15">
                          <rect
                            style={{
                              width: '20px',
                              height: '20px',
                              fill: 'green',
                            }}
                          />
                        </svg>
                        &nbsp; Identical Heading &nbsp;&nbsp;&nbsp;
                      </td>

                      <td>
                        <svg width="15" height="15">
                          <rect
                            style={{
                              width: '20px',
                              height: '20px',
                              fill: 'blue',
                            }}
                          />
                        </svg>
                        &nbsp; New Heading &nbsp;&nbsp;&nbsp;
                      </td>

                      <td>
                        <svg width="15" height="15">
                          <rect
                            style={{
                              width: '20px',
                              height: '20px',
                              fill: 'red',
                            }}
                          />
                        </svg>
                        &nbsp; Removed Heading &nbsp;&nbsp;&nbsp;
                      </td>

                      <td>
                        <svg width="15" height="15">
                          <rect
                            style={{
                              width: '20px',
                              height: '20px',
                              fill: 'black',
                            }}
                          />
                        </svg>
                        &nbsp; Moved Heading &nbsp;&nbsp;&nbsp;
                      </td>
                    </tr>
                  </table>
                </Container>
              </Grid>
              <br />
              <DSCidca arr2={arr2} arr3={arr3} arr4={arr4} />
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
}

export default UnderIdca;
