/* eslint-disable @typescript-eslint/no-inferrable-types */
export const parseFromValuesOrFunc = <T, U>(
  fn: ((arg: U) => T) | T | undefined,
  arg: U
): T | undefined => (fn instanceof Function ? fn(arg) : fn);

export const getValueAndLabel = (option): { label: string; value: string } => {
  let label: string = '';
  let value: string = '';
  if (option) {
    if (typeof option !== 'object') {
      label = option;
      value = option;
    } else {
      label = option.label ?? option.text ?? option.value;
      value = option.value ?? label;
    }
  }
  return { label, value };
};

export const reorderColumn = (draggedColumn, targetColumn, columnOrder) => {
  if (draggedColumn.getCanPin()) {
    draggedColumn.pin(targetColumn.getIsPinned());
  }
  const newColumnOrder = [...columnOrder];
  newColumnOrder.splice(
    newColumnOrder.indexOf(targetColumn.id),
    0,
    newColumnOrder.splice(newColumnOrder.indexOf(draggedColumn.id), 1)[0]
  );

  // localStorage.setItem('Module_1_column_state', JSON.stringify(newColumnOrder));
  return newColumnOrder;
};

export const showRowPinningColumn = (tableOptions): boolean => {
  const { enableRowPinning, rowPinningDisplayMode } = tableOptions;
  return !!(enableRowPinning && !rowPinningDisplayMode?.startsWith('select'));
};

export const showRowDragColumn = (tableOptions): boolean => {
  const { enableRowDragging, enableRowOrdering } = tableOptions;
  return !!(enableRowDragging || enableRowOrdering);
};

export const showRowExpandColumn = (tableOptions): boolean => {
  const {
    enableExpanding,
    enableGrouping,
    renderDetailPanel,
    state: { grouping },
  } = tableOptions;
  return !!(enableExpanding || (enableGrouping && grouping?.length) || renderDetailPanel);
};

export const showRowActionsColumn = (tableOptions): boolean => {
  const {
    createDisplayMode,
    editDisplayMode,
    enableEditing,
    enableRowActions,
    state: { creatingRow },
  } = tableOptions;
  return !!(
    enableRowActions ||
    (creatingRow && createDisplayMode === 'row') ||
    (enableEditing && ['modal', 'row'].includes(editDisplayMode ?? ''))
  );
};

export const showRowSelectionColumn = (tableOptions): boolean => !!tableOptions.enableRowSelection;

export const showRowNumbersColumn = (tableOptions): boolean => !!tableOptions.enableRowNumbers;

export const showRowSpacerColumn = (tableOptions): boolean =>
  tableOptions.layoutMode === 'grid-no-grow';

export const getLeadingDisplayColumnIds = (tableOptions) =>
  [
    showRowPinningColumn(tableOptions) && 'mrt-row-pin',
    showRowDragColumn(tableOptions) && 'mrt-row-drag',
    tableOptions.positionActionsColumn === 'first' &&
      showRowActionsColumn(tableOptions) &&
      'mrt-row-actions',
    tableOptions.positionExpandColumn === 'first' &&
      showRowExpandColumn(tableOptions) &&
      'mrt-row-expand',
    showRowSelectionColumn(tableOptions) && 'mrt-row-select',
    showRowNumbersColumn(tableOptions) && 'mrt-row-numbers',
  ].filter(Boolean) as [];

export const getTrailingDisplayColumnIds = (tableOptions) =>
  [
    tableOptions.positionActionsColumn === 'last' &&
      showRowActionsColumn(tableOptions) &&
      'mrt-row-actions',
    tableOptions.positionExpandColumn === 'last' &&
      showRowExpandColumn(tableOptions) &&
      'mrt-row-expand',
    showRowSpacerColumn(tableOptions) && 'mrt-row-spacer',
  ].filter(Boolean) as [];

export const getAllLeafColumnDefs = (columns) => {
  const allLeafColumnDefs = [];
  const getLeafColumns = (cols) => {
    cols.forEach((col) => {
      if (col.columns) {
        getLeafColumns(col.columns);
      } else {
        allLeafColumnDefs.push(col);
      }
    });
  };
  getLeafColumns(columns);
  return allLeafColumnDefs;
};

export const getColumnId = (columnDef): string =>
  columnDef.id ?? columnDef.accessorKey?.toString?.() ?? columnDef.header;

export const getDefaultColumnOrderIds = (tableOptions, reset = false) => {
  const {
    state: { columnOrder: currentColumnOrderIds = [] },
  } = tableOptions;

  const leadingDisplayColIds: string[] = getLeadingDisplayColumnIds(tableOptions);
  const trailingDisplayColIds: string[] = getTrailingDisplayColumnIds(tableOptions);

  const defaultColumnDefIds = getAllLeafColumnDefs(tableOptions.columns).map((columnDef) =>
    getColumnId(columnDef)
  );

  let allLeafColumnDefIds = reset
    ? defaultColumnDefIds
    : Array.from(new Set([...currentColumnOrderIds, ...defaultColumnDefIds]));

  allLeafColumnDefIds = allLeafColumnDefIds.filter(
    (colId) => !leadingDisplayColIds.includes(colId) && !trailingDisplayColIds.includes(colId)
  );

  return [...leadingDisplayColIds, ...allLeafColumnDefIds, ...trailingDisplayColIds];
};
