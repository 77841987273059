import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactJson from 'react-json-view';
import { createProjectConfig } from '../../api/iverifier';
import useAppToken from '../../app/useAppToken';
import { IS_DEBUG_MODE } from '../../const';
import { configInit } from '../Module3/RunContext';
import ProjectContext from '../Project/ProjectContext';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    backgroundColor: theme.palette.action.hover,
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
  textField: {
    margin: '15px 5px',
    paddingRight: 10,
  },
  radio: {
    textAlign: 'center',
  },
  label: {
    marginRight: '30px',
  },
}));

type Props = {
  projectId?: any;
  onClose?: any;
};

export default function ModalProjectConfig({ projectId, onClose }: Props) {
  const classes = useStyles();
  const { appJWT } = useAppToken();
  const { setSnack } = useContext(ProjectContext);
  const [myProjects, setMyProjects] = useState<any[]>([]);
  // bottom buttons
  const [saving, setSaving] = useState<boolean>(false);

  // button edit script rule
  const myUserId = appJWT.user_id;

  // handle main form
  const { register, handleSubmit } = useForm<any>({
    defaultValues: {
      importFunctions: true,
    },
  });

  const handleClose = () => {
    onClose();
  };

  const handleCreateConfig = (data) => {
    if (!data.project_id && !projectId) return;
    setSaving(true);
    createProjectConfig(
      {
        ...configInit,
        project_id: data.project_id || projectId,
        name: data.config_name,
        created_by: myUserId,
      },
      data.import_functions
    )
      .then((response) => {
        onClose(response);
        setSnack(`Config ${data.config_name} is created`);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="sm"
        fullWidth={true}
      >
        {IS_DEBUG_MODE && <ReactJson src={{}} collapsed={true} theme="monokai" />}
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          Create a config
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={3}
            style={{ margin: 0 }}
          >
            <Grid item lg={12} sm={12} md={12}>
              <>
                <Box>
                  <TextField
                    {...register('config_name')}
                    variant="outlined"
                    label="Config name"
                    placeholder="Config name"
                    fullWidth
                    className={classes.textField}
                    key="config_name"
                    defaultValue=""
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register('import_functions')}
                        name="import_functions"
                        defaultChecked
                      />
                    }
                    label="Import all functions"
                  />
                </Box>
              </>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogContent}>
          {saving && (
            <span>
              <CircularProgress size={26} color="secondary" />
            </span>
          )}
          <Button
            variant="contained"
            color="secondary"
            disabled={saving}
            onClick={handleSubmit(handleCreateConfig)}
          >
            Create config
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
