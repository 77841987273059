/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import {
  Column,
  DataTypeProvider,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { Box, Dialog, MenuItem, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { LifeCycleData } from 'OwningType';
import React, { useEffect, useState } from 'react';
import useAppToken from '../../../../../app/useAppToken';
import TableComponent from '../../../../../components/Datagrid/TableComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  pdfPanel: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    margin: 5,
    padding: 5,
    minHeight: 100,
  },
  dialogContent: {
    backgroundColor: '#FBFBFB',
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
}));

type Props = {
  requirements: any[];
  onClose: any;
  onSend: any;
  onSendResolve: any;
  projectId: number;
  workloadId: number;
  filename: string;
};

export default function ModalDecline({
  projectId,
  workloadId,
  filename,
  requirements,
  onClose,
  onSend,
  onSendResolve,
}: Props) {
  const classes = useStyles();
  const [declineReqs, setDeclineReqs] = useState<any[]>([]);
  const { appJWT } = useAppToken();
  const [columns] = useState<any>([
    { name: 'id', title: 'ID' },
    { name: 'text', title: 'Requirement Text' },
    { name: 'reason', title: 'Reason' },
  ]);

  useEffect(() => {
    if (requirements?.length > 0) {
      const tempreqs = requirements.map((req) => ({ ...req, reason: 'Out of scope' }));
      setDeclineReqs(tempreqs);
    }
  }, [requirements]);

  const handleClose = () => {
    onClose();
  };

  const handleDecline = () => {
    const declineLifeCycles = declineReqs.map(
      (req) =>
        ({
          project_id: projectId,
          owning_id: req.id,
          user_id: appJWT.user_id,
          workload_id: Number(workloadId),
          filename,
          allocation_id: req.allocation.id,
          // allocation: req.allocation.allocation,
          content: req.reason,
          action: 'reject',
        } as LifeCycleData)
    );
    onSend(declineLifeCycles).then((p) => {
      onSendResolve(p);
      onClose();
    });
  };

  const handleReasonChange = (e, row: any, column: Column) => {
    const tempreqs = declineReqs.map((req) => {
      if (req.id === row.id)
        return {
          ...req,
          [column.name]: e.target.value,
        };
      return req;
    });
    setDeclineReqs(tempreqs);
  };

  const renderReason = ({ value, row, column }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <Select value={value} onChange={(e) => handleReasonChange(e, row, column)}>
        <MenuItem key="out-of-scope" value="Out of scope">
          Out of scope
        </MenuItem>
        <MenuItem key="not-only-scope" value="Not only in my scope">
          Not only in my scope
        </MenuItem>
        <MenuItem key="unclear" value="Unclear">
          Unclear
        </MenuItem>
        <MenuItem key="notreq" value="Not a Requirement">
          Not a Requirement
        </MenuItem>
      </Select>
    );
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          Reject Confirmation
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.pdfPanel}>
            <ExpressGrid rows={declineReqs} columns={columns}>
              <DataTypeProvider
                for={['text']}
                formatterComponent={({ value }) => <span title={value}>{value}</span>}
              />
              <DataTypeProvider for={['reason']} formatterComponent={renderReason} />
              <SortingState />
              <IntegratedSorting />
              <PagingState defaultCurrentPage={0} defaultPageSize={10} />
              <IntegratedPaging />
              <Table
                columnExtensions={[{ columnName: 'Text', width: '40%' }]}
                tableComponent={TableComponent}
              />
              <TableHeaderRow showSortingControls />
              <PagingPanel pageSizes={[5, 10, 20, 50]} />
            </ExpressGrid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogContent}>
          <Button autoFocus onClick={handleClose} color="default" variant="contained">
            Cancel
          </Button>
          <Button autoFocus onClick={handleDecline} color="secondary" variant="contained">
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
