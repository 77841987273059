import React from 'react';
import styled from 'styled-components';

type LocalData = {
  color: string;
  value: any;
  percent?: number | string;
};

type Props = {
  data: LocalData[];
};
const Styled = styled.div`
  .BarChart {
    background-color: var(--color-primary);
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    height: inherit;
    height: 1.8em;
    overflow: hidden;
  }

  .BarData {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PercentText {
    color: var(--color-primary-offWhite);
    font: 1em;
    font-weight: bold;
    text-align: center;
  }
`;

function BarStackDiv({ data }: Props) {
  if (!data) return null;
  const total = data.reduce((acc, d) => {
    return Number(d.value) + acc;
  }, 0);
  if (!total) return null;
  return (
    <Styled>
      <div className="BarChart">
        {data &&
          data.map((d, index) => {
            if (!d.percent) {
              // eslint-disable-next-line no-param-reassign
              d.percent = (100 * Number(d.value)) / total;
            }
            return (
              <div
                className="BarData"
                style={{ background: `${d.color}`, width: `${d.percent}%` }}
                key={`barstackdiv-${index}`}
              >
                <p className="PercentText">{`${d.value}`}</p>
              </div>
            );
          })}
      </div>
    </Styled>
  );
}

export default BarStackDiv;
