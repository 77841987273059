export const hiddenSixCol = [
  'id',
  'lssCoach',
  'alstomMetier',
  'projectBenefits',
  'certificationDate',
  'initiative',
];

// eslint-disable-next-line consistent-return
export const getSixSigmaData = (_data: any[]) => {
  if (_data.length > 0) {
    const columns = Object.keys(_data[0]);
    let temp = {};
    const modifiedCol = columns.map((data) => {
      switch (data) {
        case 'id':
          temp = {
            name: data,
            title: 'Id',
          };
          break;
        case 'projectTitle':
          temp = {
            name: data,
            title: 'Project Title',
          };
          break;
        case 'projectDescription':
          temp = {
            name: data,
            title: 'Project Description',
          };
          break;
        case 'beltName':
          temp = {
            name: data,
            title: 'Belt Name',
          };
          break;
        case 'level':
          temp = {
            name: data,
            title: 'Level',
          };
          break;
        case 'region':
          temp = {
            name: data,
            title: 'Region',
          };
          break;
        case 'progress':
          temp = {
            name: data,
            title: 'Progress',
          };
          break;
        case 'lssCoach':
          temp = {
            name: data,
            title: 'Iss Coach',
          };
          break;
        case 'alstomMetier':
          temp = {
            name: data,
            title: 'Alstom Metier',
          };
          break;
        case 'projectBenefits':
          temp = {
            name: data,
            title: 'Project Benefits',
          };
          break;
        case 'certificationDate':
          temp = {
            name: data,
            title: 'Certification Date',
          };
          break;
        case 'initiative':
          temp = {
            name: data,
            title: 'Initiative',
          };
          break;
        case 'itemType':
          temp = {
            name: data,
            title: 'Item Type',
          };
          break;
        case 'url':
          temp = {
            name: data,
            title: 'URL',
          };
          break;
        default:
          break;
      }
      return temp;
    });
    return { column: modifiedCol, row: _data };
  }
  return { column: [], row: [] };
};

const SixSigmaColumnReSize = [
  {
    columnName: 'id',
    width: 100,
  },
  {
    columnName: 'projectTitle',
    width: 300,
  },
  {
    columnName: 'projectDescription',
    width: 300,
  },
  {
    columnName: 'beltName',
    width: 200,
  },
  {
    columnName: 'level',
    width: 200,
  },
  {
    columnName: 'region',
    width: 200,
  },
  {
    columnName: 'progress',
    width: 200,
  },
  {
    columnName: 'lssCoach',
    width: 200,
  },
  {
    columnName: 'alstomMetier',
    width: 200,
  },
  {
    columnName: 'projectBenefits',
    width: 200,
  },
  {
    columnName: 'certificationDate',
    width: 200,
  },
  {
    columnName: 'initiative',
    width: 200,
  },
  {
    columnName: 'itemType',
    width: 200,
  },
  {
    columnName: 'url',
    width: 400,
  },
];
export default SixSigmaColumnReSize;
