import React from 'react';
import './loadingGradient.css';

const Loading = () => {
  return (
    <>
      <div className="divLine">
        <div className="childDivLine">{}</div>
      </div>
    </>
  );
};

export default Loading;
