/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Avatar,
  Tooltip,
  Popover,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import CloseIcon from '@mui/icons-material/Close';
import {
  AlignLabelAndInputJobDialog,
  ContainerJobDialog,
  IconJobDialog,
  JobTitleJobDialog,
  LabelJobDialog,
  NameJobDialog,
  TextContainerJobDialog,
} from '../styles';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: FormData) => void;
  data: any;
  firstName: string;
  dbData: any; // Add this prop for database data
}

const JobDialog: React.FC<DialogProps> = ({ open, onClose, onSubmit, data, firstName, dbData }) => {
  const [formData, setFormData] = useState({
    jobTitle: '',
    jobDescription: '',
    objectives: '',
    projectTitle: '',
    projectDescription: '',
  });

  // Pre-fill form data when the dialog opens and dbData changes
  useEffect(() => {
    if (open && dbData) {
      setFormData({
        jobTitle: dbData[0]?.job_title || '',
        jobDescription: dbData[0]?.job_description || '',
        objectives: dbData[0]?.objectives || '',
        projectTitle: dbData[0]?.project_title || '',
        projectDescription: dbData[0]?.project_details || '',
      });
    }
  }, [open, dbData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    onSubmit(formData);
    onClose(); // Close the dialog after submitting
  };

  const handleQuillChange = (content: string) => {
    setFormData({
      ...formData,
      objectives: content,
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ padding: '8px 8px' }}>
        <ContainerJobDialog>
          <Avatar
            style={{
              backgroundColor: '#002a45',
              marginRight: '10px',
              height: '55px',
              width: '55px',
            }}
          >
            {data?.First_Name.charAt(0)}
            {data?.Last_Name.charAt(0)} {/* You can customize the avatar */}
          </Avatar>
          <TextContainerJobDialog>
            <NameJobDialog>
              <strong>
                {data?.First_Name} {data?.Last_Name}
              </strong>
            </NameJobDialog>
            {data?.Job_Title && (
              <JobTitleJobDialog>
                <small>({data?.Job_Title})</small>
              </JobTitleJobDialog>
            )}
          </TextContainerJobDialog>
        </ContainerJobDialog>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ width: '600px', maxWidth: '800px', padding: '0px 14px' }}>
        <AlignLabelAndInputJobDialog>
          <LabelJobDialog>Job Title</LabelJobDialog>
          <TextField
            name="jobTitle"
            label="Job Title"
            value={formData.jobTitle}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
        </AlignLabelAndInputJobDialog>
        <AlignLabelAndInputJobDialog>
          <LabelJobDialog>Job Description</LabelJobDialog>
          <TextField
            name="jobDescription"
            label="Job Description"
            value={formData.jobDescription}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="dense"
            multiline
            rows={3}
          />
        </AlignLabelAndInputJobDialog>
        {data?.First_Name === firstName && (
          <>
            <AlignLabelAndInputJobDialog>
              <LabelJobDialog>Objectives</LabelJobDialog>
              <ReactQuill
                value={formData.objectives}
                onChange={handleQuillChange}
                modules={JobDialog.modules}
                formats={JobDialog.formats}
                style={{ height: '200px', marginBottom: '65px' }} // Set height as needed
              />
            </AlignLabelAndInputJobDialog>
            <AlignLabelAndInputJobDialog>
              <LabelJobDialog>Project Title</LabelJobDialog>
              <TextField
                name="projectTitle"
                label="Project Title"
                value={formData.projectTitle}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="dense"
              />
            </AlignLabelAndInputJobDialog>
            <AlignLabelAndInputJobDialog>
              <LabelJobDialog>Project Description</LabelJobDialog>
              <TextField
                name="projectDescription"
                label="Project Description"
                value={formData.projectDescription}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="dense"
                multiline
                rows={2}
              />
            </AlignLabelAndInputJobDialog>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          style={{ backgroundColor: '#002a45', borderRadius: '5px' }}
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Optional: Customize the modules and formats for React Quill
JobDialog.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['bold', 'italic', 'underline', 'strike'],
    ['link', 'image'],
    ['clean'], // Remove formatting button
  ],
};

JobDialog.formats = [
  'header',
  'font',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'link',
  'image',
];

export default JobDialog;
