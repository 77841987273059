import { allocationKeys } from './convertToPairs';
import {
  extractABS,
  extractOBS,
  extractUserName,
  extractUsersList,
  generateUniqueId,
} from './extractdata';

const generateUniqueText = (targetColumn, subAllocations, item) => {
  /*
  title :- This function generates unique Text

  purpose :- 
      to identify Text , cause we are grouping the column based on text so, 
      for some different owning id there is posibility of common text, 
      so better to concanate owning id and text
      
  utiles :- 
  no utiles

  return's :- string
  
  */
  try {
    return targetColumn
      ? `${subAllocations.owning_id}__*|*${item[targetColumn]}`
      : `${subAllocations.owning_id}__*|*${item.Text}`;
  } catch (error) {
    console.log('error in generateUniqueText', error);
    return '';
  }
};

function AllocationExtraction(arr, item, groups, users, targetColumn) {
  /*
   title :- This function extract allocation data from meta data and add it in array

  purpose :- 
     it has purpose to filter only allocation data from meta data and show in requirement tab
      
  utiles :- 
    extractABS :- this function extract ABS for respective PBS
    extractOBS :-  this function extract OBS for respective PBS and ABS
    generateUniqueId :- it generates unique id for table row , it helps to identify row
    extractUsersList :- this function extract user List for respective PBS , ABS and OBS
    generateUniqueText :-  This function generates unique Text


  Note :- 
   1. if we have only PBS from Backend data.
    ABS, OBS and receiver are missing then we extracting data from group user 


   2. if allocations already have pbs , abs, obs and recpective receiver 
      then we are assigning saved data


  return's :- Array

  */

  try {
    const values = Object.values(item.allocations) ?? [];
    const key = 'extractedText';
    for (const [index, subAllocations] of values.entries()) {
      const extractedABS = extractABS(subAllocations.pbs, groups) || [];
      const abs = subAllocations.abs || extractedABS[0] || '';
      const obs = subAllocations.obs || extractOBS(subAllocations.pbs, abs || '', groups) || '';

      const extractReceiver = extractUsersList(subAllocations, abs, obs, groups, users) as any;

      const receiver = subAllocations.receiver || extractUserName(extractReceiver[0], 'id') || '';

      // console.log(key);

      arr.push({
        Id: item.id,
        rowId: generateUniqueId(),
        ...item,
        ...subAllocations,
        treeHierarchy: item.Hierarchy,
        owning_id: subAllocations.owning_id,
        parentRow_id: item.row_id,
        allocation_id: subAllocations.id,
        requirementText: generateUniqueText(key, subAllocations, item),
        kpi_lifecycle: item.kpi_lifecycle,
        abs,
        obs,
        enableGrouping: true,
        absOptions: extractedABS.map((data, index) => {
          return {
            select: abs === data,
            value: data,
          };
        }),
        receiverOptions: extractReceiver.map((data, index) => {
          return {
            select: data === receiver,
            value: data,
          };
        }),
        receiver,
        receiverName: extractUserName(subAllocations.receiver, 'name'),
        addMoreAllocation: index === 0,
        lenOfAllocations: values.length,
      });
    }

    return arr;
  } catch (error) {
    console.log('error in AllocationExtraction', error);
    return [];
  }
}

function AllocationsReceiverExtraction(allocations, groups, users) {
  /*
   title :- This function extract allocation data from Response

  purpose :- 
          This function extract allocation data from Response and add it in array and evntualy it will 
          return object of key pair value.

          the purpose of key pair value is to access allocation data in constant time instead of n * m

      
  utiles :- 
    extractABS :- this function extract ABS for respective PBS
    extractOBS :-  this function extract OBS for respective PBS and ABS
    extractUsersList :- this function extract user List for respective PBS , ABS and OBS
    extractUserName :-  This function extract user name based on id or name
    allocationKeys :- this function generate keys  pair values

  Note :- 
   1. if we have only PBS from Backend data.
    ABS, OBS and receiver are missing then we extracting data from group user 


   2. if allocations already have pbs , abs, obs and recpective receiver 
      then we are assigning saved data


  return's :- object

  */

  const temp = [];
  try {
    for (const [index, subAllocations] of allocations.entries()) {
      const extractedABS = extractABS(subAllocations.pbs, groups) || [];
      const abs = subAllocations.abs || extractedABS[0] || '';
      const obs = subAllocations.obs || extractOBS(subAllocations.pbs, abs || '', groups) || '';

      const extractReceiver = extractUsersList(subAllocations, abs, obs, groups, users) as any;

      const receiverName =
        extractReceiver.filter((data, index) => {
          return Number(extractUserName(data, 'id')) === Number(subAllocations.receiver);
        })[0] ?? undefined;

      const receiver = receiverName || extractReceiver[0] || '';

      temp.push({
        ...subAllocations,
        abs,
        obs,
        receiver,
      });
    }

    const withKeys = allocationKeys(temp);

    return withKeys;
  } catch (error) {
    console.log('error in AllocationsReceiverExtraction', error);
  }
  return {};
}

const MutliAllocationExtraction = (pbs, groups, users) => {
  try {
    const extractedABS = extractABS(pbs, groups) || [];
    const abs = extractedABS[0] || '';
    const obs = extractOBS(pbs, abs || '', groups) || '';

    const extractReceiver = extractUsersList({ pbs, receiver: '' }, abs, obs, groups, users) as any;

    const receiver = extractReceiver[0] || '';
    return { abs, obs, receiver };
  } catch (error) {
    console.log('error in MutliAllocationExtraction', error);
  }
  return {};
};

const generatKeysForGroup = (item) => {
  /*
  title :- This function generates keys

  purpose :- 
      to identify user present in group of or not,
      
  utiles :- 
    removeSpace :- it has removeSpace function, it remove's unwanted space from string
    key :- its has function it concanate the values of pbs +  abs +  obs

  return's :- String
  
  */
  try {
    const removeSpace = (value: string) => {
      return value?.trim().split(' ').join('_');
    };

    const key = (item: { pbs: string; abs: string; obs: string }) => {
      return `${removeSpace(item?.pbs)}_${removeSpace(item?.abs)}_${removeSpace(item?.obs)}`;
    };

    return key(item);
  } catch (error) {
    console.log('error in generatKeysForGroup', error);
  }
  return '';
};

const checkIsItFavourite = (allocation, projectGroups, userId) => {
  /*
   title :- This function check is user present in group or not

  purpose :- 
     it helps to filter the favourite allocation from data, it will add the boolean value in analysis row
      
  utiles :- 
    generatKeysForGroup :-  This function generates keys


  return's :- bollean
  
  */
  try {
    const keyFound = generatKeysForGroup(allocation);

    if (projectGroups[keyFound]) {
      const { user_ids } = projectGroups[keyFound];

      return user_ids.includes(userId);
    }
  } catch (error) {
    console.log('error in checkIsItFavourite', error);
  }

  return false;
};

const AnalysisAllocationExtraction = (arr, item, targetColumn, projectGroups, userId) => {
  /*
   title :- This function extract analysis data from meta data and add it in array

  purpose :- it has purpose to filter only analysis data from meta data and show in requirement tab
      
  utiles :- 
    findAnalysis :-  This function findes anlysis from meta data
    generateUniqueId :- it generates unique id for table row , it helps to identify row
    checkIsItFavourite :- This function check is user present in group or not
    generateUniqueText :-  This function generates unique Text


  return's :- Array
  
  */

  try {
    const values = Object.values(item.allocations) ?? [];
    const key = 'extractedText';
    for (const [index, subAllocations] of values.entries()) {
      // const key = targetColumn ?? 'requirementText';

      const findAnalysis = () => {
        if (['assigned', 'characterized'].includes(subAllocations.lifecycle)) {
          return null;
        }
        const temp = item?.analysis?.sort((a: any, b: any) => {
          return b?.id - a?.id;
        });

        const analysisLast = temp?.find((data) => {
          if (data?.allocation_id === subAllocations.id) {
            return true;
          }
          return false;
        });

        return analysisLast ?? undefined;
      };

      const analysisFound = findAnalysis();

      // if (subAllocations.owning_id === 2877644) {
      //   console.log('analysisFound', analysisFound);
      // console.log('item', item);
      // }

      // console.log('analysisFound', analysisFound);

      arr.push({
        ...item,
        Id: item.id,
        allocation_id: subAllocations.id,
        rowId: generateUniqueId(),

        parentRow_id: item.row_id,
        ...subAllocations,
        requirementText: generateUniqueText(key, subAllocations, item),
        kpi_lifecycle: item.kpi_lifecycle,
        enableGrouping: true,
        pbs: subAllocations.pbs,
        analysis: analysisFound ? { ...analysisFound, isAnalised: true } : { isAnalised: false },
        rationale: analysisFound
          ? analysisFound?.rationale
              .map((data) => {
                return data.content;
              })
              .join(',')
          : '',
        addMoreAllocation: index === 0,
        lifecyclesIs: item?.lifecycles,
        lenOfAllocations: values.length,
        lifecycle: subAllocations.lifecycle,
        Characterization: item.Characterization,
        Char_Score: item.Char_Score,
        owning_id: subAllocations.owning_id,
        gap: subAllocations?.compliance === 'gap' ? 'gap' : '',
        nc: subAllocations?.compliance === 'nc' ? 'nc' : '',
        isUsersFavourite:
          subAllocations.lifecycle !== 'characterized'
            ? checkIsItFavourite(subAllocations, projectGroups, userId)
            : false,
      });
    }

    return arr;
  } catch (error) {
    console.log('error in AnalysisAllocationExtraction', error);
  }

  return arr;
};

export default AllocationExtraction;

export {
  AllocationsReceiverExtraction,
  AnalysisAllocationExtraction,
  generatKeysForGroup,
  MutliAllocationExtraction,
};
