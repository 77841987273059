import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { memo, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { userWorkloadGetbyPageNumber, workloadGetAll } from '../../../api/workloads';
import useAppToken from '../../../app/useAppToken';
import ExcelDoc, { CsvDoc, defaultFileIcon, DocxDoc, PdfDoc, PptDoc } from '../const/png';

const useStyleChat = makeStyles((theme) => ({
  fileTransProcessCard: {
    display: 'flex',
    flexBasis: '25%',
    gap: ' 0.5rem',
    flexDirection: 'column',
    padding: '0.5rem',
  },

  processFileName: {
    padding: '1rem',
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    borderRadius: '5px',
    backgroundColor: '#d1d9ef',
  },

  pocessFileNameFileSize: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.3rem',
  },

  processFileLoading: {
    display: 'flex',
    gap: '0.5rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ProgressBarContainer = styled.div`
  border-radius: 10px;
  width: 100%;
  background-color: #d1d9ef;
`;

const PercentageLine = styled.div`
  border-radius: 10px;
  padding: 2px;
  background-color: #19aa6e;
  color: white;
  display: flex;
  justify-content: flex-end;
  ${({ percentage }) => css`
    width: ${percentage < 10 ? 10 : percentage}%;
  `};
  transition: width 500ms;
`;

const ProcessCard = ({ details, historyRows, workloads }: any) => {
  const { filename, size, activity_detatils, progress_percent } = details;
  const ext = activity_detatils?.split('.') ?? [];
  const extensions = {
    xlsx: ExcelDoc,
    pptx: PptDoc,
    csv: CsvDoc,
    pdf: PdfDoc,
    docx: DocxDoc,
    doc: DocxDoc,
  };
  const classes = useStyleChat();

  const percentage = historyRows.filter((data: any) => {
    const { activity_detatils, id } = data;
    return activity_detatils === filename && workloads.includes(id);
  });

  const per = progress_percent > 0 ? progress_percent : 0;
  const digit = per < 9 ? `0${per?.toString()}` : per?.toString();

  return (
    <Box className={classes.fileTransProcessCard}>
      <Box className={classes.processFileName}>
        <img
          src={extensions[ext[ext.length - 1]] ?? defaultFileIcon}
          height="40"
          width="40"
          alt="img"
        />
        <Box className={classes.pocessFileNameFileSize}>
          <Typography style={{ fontSize: '0.79em', fontWeight: 'bold' }}>
            {activity_detatils}
          </Typography>
          <Typography style={{ fontSize: '0.79em', fontWeight: 'bold' }}>{size}</Typography>
        </Box>
      </Box>
      <Box className={classes.processFileLoading}>
        <Typography style={{ fontSize: '0.79em', fontWeight: 'bold' }}>
          {parseInt(digit, 10) === 99 ? 'Translated successfully' : 'Translating...'}
        </Typography>
        {parseInt(digit, 10) < 99 && (
          <ProgressBarContainer>
            <PercentageLine percentage={parseInt(digit, 10)}>
              <Typography style={{ fontSize: '0.79em' }}>{digit} %</Typography>
            </PercentageLine>
          </ProgressBarContainer>
        )}
      </Box>
    </Box>
  );
};

const FileStatus = ({ workLoads, setTransformLine, mode }) => {
  let intervalCheck: any;
  const [runload, setRunLoad] = useState(false);
  // const [workloads, setWorkloads] = useState([]);
  const [historyRows, setHistoryRows] = useState([]);
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;

  const CallStatus = () => {
    userWorkloadGetbyPageNumber(user_id, 1, 'Translator')
      .then((activities) => {
        // console.log('activities', activities);
        const { activity_counts, workload } = activities;
        // console.log('workload', workload);

        const filterdWorkloads = workload.filter((data) => {
          return workLoads.includes(data.id) && data.status !== null;
        });
        const updatedRows = filterdWorkloads.map((data) => {
          const obj = {
            id: data.id,
            activity_detatils: data.resources[0].filename ?? '',
            size: data.resources[0].size,
            resources: data.resources[0] ?? {},
            // source_doc: data.resources[0],
            status: data.status,
            isNew: data.status !== 'end',
            created_by: data.created_by.name,
            created_at: data.created_at,
            updated_at: data.updated_at,
            progress_percent: data.progress_percent,
            errors: data.errors,
          };
          return obj;
        });
        // console.log('updatedRows', updatedRows);

        let check = false;
        for (let i = 0; i < updatedRows.length; i += 1) {
          const { status } = updatedRows[i];
          if (status !== 'end') {
            check = true;
            break;
          }
          if (status === 'end') {
            check = false;
          }
        }
        if (!check) {
          // setShowProcessFile(false);
          // setToggleTab((prev) => {
          //   return {
          //     ...prev,
          //     left: '10rem',
          //   };
          // });
          setTransformLine('12rem');
          clearInterval(intervalCheck);
          // setRunLoad(false);

          setHistoryRows(updatedRows);
        } else {
          setHistoryRows(updatedRows);
        }

        // const filteredState = updatedRows.filter((data) => {
        //   return data.status !== null;
        // });

        // console.log('filteredState', filteredState);

        // if (true) {
        //   let check = false;
        //   for (let i = 0; i < filteredState.length; i += 1) {
        //     const { status } = filteredState[i];
        //     if (status !== 'end') {
        //       check = true;
        //       break;
        //     }
        //     if (status === 'end') {
        //       check = false;
        //     }
        //   }
        //   if (!check) {
        //     // setShowProcessFile(false);
        //     // setToggleTab((prev) => {
        //     //   return {
        //     //     ...prev,
        //     //     left: '10rem',
        //     //   };
        //     // });
        //     clearInterval(intervalCheck);
        //     // setRunLoad(false);

        //     setHistoryRows(updatedRows);
        //   } else {
        //     setHistoryRows(updatedRows);
        //   }
        // }
        // else {
        //   const updatedRows = filteredState.map((data) => {
        //     const obj = {
        //       id: data.id,
        //       activity_detatils: data.resources[0].filename,
        //       resources: data.resources[0],
        //       source_doc: data.resources[0],
        //       status: data.status,
        //       isNew: data.status !== 'end',
        //       created_by: data.created_by.name,
        //       created_at: data.created_at,
        //       updated_at: data.updated_at,
        //       progress_percent: data.progress_percent,
        //       errors: data.errors,
        //     };
        //     return obj;
        //   });
        //   setHistoryRows(updatedRows);
        // }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const CallStatus2 = () => {
    workloadGetAll(mode.id, true)
      .then((activities) => {
        let listIds = [] as any[];
        if (activities?.length > 0) {
          // list of ids not ended
          listIds = activities.filter((eachACT) => {
            return eachACT.action === 'Translator';
          });
        }
        const filterdWorkloads = listIds.filter((data) => {
          return workLoads.includes(data.id) && data.status !== null;
        });
        const updatedRows = filterdWorkloads.map((data) => {
          const obj = {
            id: data.id,
            activity_detatils: data.resources[0].filename ?? '',
            size: data.resources[0].size,
            resources: data.resources[0] ?? {},
            // source_doc: data.resources[0],
            status: data.status,
            isNew: data.status !== 'end',
            created_by: data.created_by.name,
            created_at: data.created_at,
            updated_at: data.updated_at,
            progress_percent: data.progress_percent,
            errors: data.errors,
          };
          return obj;
        });

        let check = false;
        // console.log('listIds', listIds);
        for (let i = 0; i < updatedRows.length; i += 1) {
          const { status } = listIds[i];
          if (status !== 'end') {
            check = true;
            break;
          }
          if (status === 'end') {
            check = false;
          }
        }

        if (!check) {
          // setShowProcessFile(false);
          // setToggleTab((prev) => {
          //   return {
          //     ...prev,
          //     left: '10rem',
          //   };
          // });
          setTransformLine('12rem');
          clearInterval(intervalCheck);
          // setRunLoad(false);
        }

        setHistoryRows(updatedRows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // console.log(mode);
  useEffect(() => {
    if (mode.key === 'user') {
      intervalCheck = setInterval(CallStatus, 2000);
    } else if (mode.key === 'project') {
      intervalCheck = setInterval(CallStatus2, 2000);
    }

    // console.log('firstMount');

    return () => {
      clearInterval(intervalCheck);
    };
  }, []);

  const processCards = [];

  return (
    <>
      {' '}
      <Box
        style={{
          display: 'flex',
          gap: '10px',
          padding: '50px 100px',
          justifyContent: 'ceter',
          alginItems: 'center',
        }}
      >
        {historyRows.length > 0 ? (
          historyRows.map((data, index) => {
            return (
              <ProcessCard
                details={data}
                key={index}
                historyRows={historyRows}
                workloads={workLoads}
              />
            );
          })
        ) : (
          <>
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '22px',
              }}
            >
              <Typography style={{ fontSize: '1.2rem', color: '#002a45' }}>
                {workLoads.length > 0 ? 'processing...' : 'No status'}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
export default memo(FileStatus);
