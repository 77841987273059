export type configItem = {
  function: string;
  example: string;
  mapping: string;
};

export type xslxColumn = { A: string; B: string; C: string; D: string };

export type exportConfig = configItem[];

// Seperator
// Intepret seperator

// Rule sheet name
// Column Object number
// Column Module Profile
// Column Rule ID
// Column Condition local
// Column Expected results
// Column Condition full
// Function sheet name
// Output folder
// Output file in One file

// Input folder

export default (configJson: any[]): exportConfig => {
  return configJson
    .slice(1) // skip 1st row
    .map(
      (row: xslxColumn): configItem => {
        return {
          function: row.A || '',
          example: row.B || '',
          mapping: row.C || '',
        };
      }
    )
    .filter((row: configItem): boolean => row.function !== '');
};
