export type rowType = any;

export type xslxColumn = {
  A: string;
  B: string;
  C: string;
  D: string;
  E: string;
  F: string;
  G: string;
  H: string;
  I: string;
  J: string;
  K: string;
  L: string;
  M: string;
  N: string;
  O: string;
  P: string;
  Q: string;
  R: string;
  S: string;
  T: string;
  U: string;
  V: string;
  W: string;
  X: string;
  Y: string;
  Z: string;
  AA: string;
  AB: string;
  AC: string;
};

export type exportConfig = rowType[];

export default (inputJson: any[]): exportConfig => {
  return inputJson.slice(1).map(
    (row: xslxColumn): rowType => {
      return {
        'Absolute Number': row.A || '',
        'Object Number': row.B || '',
        'Module Profile': row.C || '',
        Item: row.D || '',
        'RTM Type': row.E || '',
        'Module Object Type': row.F || '',
        'Script - Attributes': row.G || '',
        'Script - Baseline Path': row.H || '',
        'Script - Baselineset': row.I || '',

        'Script - Enabled': row.J || '',
        'Script - Filter - Derived': row.K || '',
        'Script - Filter - Link': row.L || '',
        'Script - Filter - Main': row.M || '',
        'Script - Filter - Module': row.N || '',
        'Script - Matrix - Direct_Link': row.O || '',

        'Script - Matrix - Direct_Mod': row.P || '',
        'Script - Matrix - Downstream_Mod': row.Q || '',
        'Script - Matrix - LVL Attribute': row.R || '',
        'Script - Matrix - LVL Column': row.S || '',
        'Script - Matrix - LVL Module': row.T || '',
        'Script - Matrix - Upstream_Link': row.U || '',

        'Script - Matrix - Upstream_Mod': row.V || '',
        'Script - Module Authorization': row.W || '',
        'Script - Module Links': row.X || '',
        'Script - Module Path': row.Y || '',
        'Script - Recursion Depth': row.Z || '',
        'Script - Source': row.AA || '',
        'Script - Source Module View': row.AB || '',
        '#isDeleted': row.AC || '',
      };
    }
  );
};
