const preValidation = (currentCompliance, currentNcorGap, respectiveComplianeRationale) => {
  let message = '';
  let check = true;

  switch (currentCompliance) {
    case 'gap':
      if (!currentNcorGap) {
        message = 'please select gap';
        check = false;
        break;
      }

      if (!respectiveComplianeRationale) {
        message = 'please select rationale';
        check = false;
        break;
      }
      break;

    case 'nc':
      if (!currentNcorGap) {
        message = 'please select nc';
        check = false;
        break;
      }

      if (!respectiveComplianeRationale) {
        message = 'please select rationale';
        check = false;
        break;
      }
      break;

    case 'need-clarification':
      if (!respectiveComplianeRationale) {
        message = 'please select rationale';
        check = false;
        break;
      }
      break;

    case 'tbd':
      if (!respectiveComplianeRationale) {
        message = 'please select rationale';
        check = false;
        break;
      }
      break;

    case 'none':
      message = 'please select compliance';
      check = false;
      break;

    default:
      break;
  }

  return { message, check };
};

const getPayloadForAnalysis = (currentCompliance, data, copy_Payload = false, statickey = {}) => {
  console.log('data', data);
  if (data.reject === true) return null;
  const payload = {
    owning_id: data.owning_id,
    project_id: data.project_id,
    workload_id: data.workload_id,
    user_id: data.user_id,
    filname: data.filename,
    allocation_id: data.allocation_id,
    allocation: data.allocation,
    compliance: copy_Payload ? statickey.compliance : data.compliance,
    rationale: [
      {
        content: copy_Payload ? statickey.rationale : data.rationale,
        created_at: copy_Payload ? statickey.created_at : data.created_at,
      },
    ],
    comments: copy_Payload ? statickey.comments : data.comments,
  } as any;

  switch (currentCompliance) {
    case 'gap':
      payload.analysis_dataset_id = copy_Payload
        ? statickey.analysis_dataset_id
        : data.analysis_dataset_id;
      break;

    case 'nc':
      payload.analysis_dataset_id = copy_Payload
        ? statickey.analysis_dataset_id
        : data.analysis_dataset_id;
      break;

    default:
      break;
  }

  return payload;
};

export { getPayloadForAnalysis };

export default preValidation;
