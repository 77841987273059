import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import CSVFileSettings from './CSVFileSettings';
import ExcelFileSettings from './ExcelFileSettings';
import CaptureNameSettings from './CaptureNameSettings';
import PdfFileSettings from './PdfFileSettings';
import DocAnalysisContext from '../../../DocAnalysisContext';
import SimilarityDocFilter from '../../../../../../../components/Filter/Components/SimilarityDocFilter';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
`;

const LeftBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const RightBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const MainDiv = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  flex-direction: column;
  gap: 20px;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
`;

const FileSettingsContainer = ({ serivceName }) => {
  const {
    scopeSetting,
    selection,
    setSelection,
    setScopeSetting,
    fileSetting,
    setFileSettings,
  } = useContext(DocAnalysisContext);

  useEffect(() => {
    setFileSettings((prev) => {
      return {
        ...prev,
        serivce: serivceName,
      };
    });
  }, []);

  return (
    <>
      <Container>
        <Header>
          <SettingsApplicationsOutlinedIcon style={{ width: 40, height: 40 }} />
          <h2>Scope Settings</h2>
          {serivceName === 'Similarity Analysis' && (
            <div style={{ position: 'absolute', right: '1rem' }}>
              <SimilarityDocFilter />
            </div>
          )}
        </Header>
        <MainDiv>
          {scopeSetting.map((data: { FileType: string }, index) => {
            if (data.FileType === 'Excel')
              return <ExcelFileSettings index={index} settingsData={[data]} />;
            if (data.FileType === 'CSV')
              return <CSVFileSettings index={index} settingsData={[data]} />;
            if (data.FileType === 'PDF')
              return <PdfFileSettings index={index} settingsData={[data]} />;
            return null;
          })}
        </MainDiv>
        <CaptureNameSettings />
      </Container>
    </>
  );
};

export default FileSettingsContainer;
