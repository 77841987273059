/* eslint-disable no-param-reassign */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Dialog, DialogTitle, Grid, IconButton, makeStyles, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import '../../assets/treeview.css';
import Loading from '../../components/Core/Loading';
import TableComponent from '../../components/Datagrid/TableComponent';
import ToolBarPlugin from '../../components/Datagrid/ToolBarPlugin';
import {
  tableContainerComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../components/Datagrid/TStyles';
import { handleColumns } from '../../tools/datagrid';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    backgroundColor: theme.palette.action.hover,
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
  textField: {
    margin: '15px 5px',
    paddingRight: 10,
  },
  radio: {
    textAlign: 'center',
  },
  label: {
    marginRight: '30px',
  },
}));

type Props = {
  rows: any[];
  template?: {
    name: string;
    version: string;
  };
  onClose?: any;
};
const columns = handleColumns(['id', 'function', 'mapping', 'example', 'created_at', 'updated_at']);

export default function ModalFunctionViewer({ rows, template, onClose }: Props) {
  const classes = useStyles();

  const [loading, setLoading] = useState<any>(false);
  const [toggleHeight, setToggleHeight] = useState(false);

  const renderFunction = ({ value }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <>
        <b>{value}</b>
      </>
    );
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Paper>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          Template : {template?.name} version : {template?.version}
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {loading ? (
          <Loading />
        ) : (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={0}
            style={{ margin: 0 }}
          >
            <Grid item lg={12} sm={12} md={12}>
              <ExpressGrid rows={rows} columns={columns} getRowId={(r) => r.id}>
                <DataTypeProvider for={['function']} formatterComponent={renderFunction} />

                {/* filtering */}
                <FilteringState />
                <SortingState />
                <IntegratedSorting />
                <IntegratedFiltering />
                <PagingState defaultCurrentPage={0} defaultPageSize={10} />
                <IntegratedPaging />
                <Table
                  columnExtensions={columns.map((c) => ({
                    columnName: c.name,
                    wordWrapEnabled: true,
                  }))}
                  tableComponent={TableComponent}
                  containerComponent={tableContainerComponent}
                  headComponent={tableHeaderComponent}
                />
                <TableColumnResizing
                  defaultColumnWidths={[
                    ...columns.map((c) => {
                      if (c.name === 'id') return { columnName: c.name, width: 80 };
                      if (c.name === 'mapping') return { columnName: c.name, width: 400 };
                      return {
                        columnName: c.name,
                        width: Math.ceil(1200 / (columns.length - 2)),
                      };
                    }),
                  ]}
                />
                <TableHeaderRow showSortingControls />
                <TableFilterRow />
                <TableColumnVisibility defaultHiddenColumnNames={[]} />
                <Toolbar rootComponent={toolbarComponent} />
                <ColumnChooser />
                <ToolBarPlugin
                  name="Height"
                  title="Wrap Text"
                  onClick={() => {
                    setToggleHeight(!toggleHeight);
                  }}
                />
                <PagingPanel pageSizes={[10, 20, 50]} />
              </ExpressGrid>
            </Grid>
          </Grid>
        )}
      </Dialog>
    </Paper>
  );
}
