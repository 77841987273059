import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  listItem: {
    // height: '25px',
    // verticalAlign: 'top',
  },
}));

export const textWidth = 1000;

export default useStyles;
