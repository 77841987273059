import React from 'react';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MuiGrid from '@mui/material/Grid';
import { Button } from '@material-ui/core';
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import ReactJson from 'react-json-view';
import { Link, useParams } from 'react-router-dom';

export const Popup = ({
  row,
  onChange,
  onApplyChanges,
  onCancelChanges,
  open,
  targetColumn,
  action,
}) => (
  <Dialog
    open={open}
    onClose={onCancelChanges}
    aria-labelledby="form-dialog-title"
    sx={{
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '900px', // Set your width here
        },
      },
    }}
  >
    {/* <ReactJson
      src={{
        row,
        onChange,
        onApplyChanges,
        onCancelChanges,
        open,
        targetColumn,
        action,
      }}
      collapsed={true}
      theme="monokai"
    /> */}
    <DialogTitle
      id="form-dialog-title"
      variant="h6"
      style={{ fontWeight: 'bold', color: ' #002A45' }}
    >
      {/* {targetColumn !== undefined ? targetColumn : 'Text'} */}Edit:
    </DialogTitle>
    <DialogContent>
      <MuiGrid item xs={12}>
        <FormGroup>
          {targetColumn !== undefined ? (
            <TextField
              multiline
              rows={4}
              margin="normal"
              style={{ textAlign: 'justify' }}
              name={targetColumn}
              label={targetColumn}
              value={row[targetColumn] || ''}
              onChange={onChange}
            />
          ) : (
            <TextField
              multiline
              rows={4}
              margin="normal"
              style={{ textAlign: 'justify' }}
              name="Text"
              label="Text"
              value={row.Text || ''}
              onChange={onChange}
            />
          )}
          {action === 'allocator' && (
            <>
              <TextField
                multiline
                rows={4}
                margin="normal"
                style={{ textAlign: 'justify' }}
                name="comment"
                label="Comment"
                value={row.comment || ''}
                onChange={onChange}
              />
              <TextField
                multiline
                rows={4}
                margin="normal"
                style={{ textAlign: 'justify' }}
                name="Source"
                label="Source"
                value={row.Source || ''}
                onChange={onChange}
              />
              <TextField
                multiline
                rows={4}
                margin="normal"
                style={{ textAlign: 'justify' }}
                name="Rationale"
                label="Rationale"
                value={row.Rationale || ''}
                onChange={onChange}
              />
              <TextField
                multiline
                rows={4}
                margin="normal"
                style={{ textAlign: 'justify' }}
                name="Validation Evidence"
                label="Validation Evidence"
                value={row['Validation Evidence'] || ''}
                onChange={onChange}
              />
            </>
          )}
        </FormGroup>
      </MuiGrid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancelChanges} type="button" variant="contained" color="primary">
        Cancel
      </Button>
      <Button onClick={onApplyChanges} type="button" variant="contained" color="primary">
        Save
      </Button>
    </DialogActions>
  </Dialog>
);

const PopupEditing = React.memo(({ popupComponent: Popup, targetColumn, action }) => (
  <Plugin>
    <Template name="popupEditing">
      <TemplateConnector>
        {(
          { rows, getRowId, addedRows, editingRowIds, createRowChange, rowChanges },
          {
            changeRow,
            changeAddedRow,
            commitChangedRows,
            commitAddedRows,
            stopEditRows,
            cancelAddedRows,
            cancelChangedRows,
          }
        ) => {
          const isNew = addedRows.length > 0;
          let editedRow;
          let rowId;
          if (isNew) {
            rowId = 0;
            editedRow = addedRows[rowId];
          } else {
            [rowId] = editingRowIds;
            const targetRow = rows.filter((row) => getRowId(row) === rowId)[0];
            editedRow = { ...targetRow, ...rowChanges[rowId] };
          }

          const processValueChange = ({ target: { name, value } }) => {
            const changeArgs = {
              rowId,
              change: createRowChange(editedRow, value, name),
            };
            if (isNew) {
              changeAddedRow(changeArgs);
            } else {
              changeRow(changeArgs);
            }
          };
          const rowIds = isNew ? [0] : editingRowIds;
          const applyChanges = () => {
            if (isNew) {
              commitAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              commitChangedRows({ rowIds });
            }
          };
          const cancelChanges = () => {
            if (isNew) {
              cancelAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              cancelChangedRows({ rowIds });
            }
          };

          const open = editingRowIds.length > 0 || isNew;
          return (
            <Popup
              open={open}
              row={editedRow}
              targetColumn={targetColumn}
              onChange={processValueChange}
              onApplyChanges={applyChanges}
              onCancelChanges={cancelChanges}
              action={action}
            />
          );
        }}
      </TemplateConnector>
    </Template>
    <Template name="root">
      <TemplatePlaceholder />
      <TemplatePlaceholder name="popupEditing" />
    </Template>
  </Plugin>
));

export default PopupEditing;
