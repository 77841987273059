import React, { useContext } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import useProjectId from '../../app/useProjectId';
import ProjectContext from '../Project/ProjectContext';
import Module5 from './Module5';
import Module5Context, { Module5ContextProvider } from './Module5Context';

function Module5Router() {
  const { projectId } = useParams() as any;
  const { projects } = useContext(ProjectContext);

  const { project } = useProjectId(projectId, projects);

  if (project === undefined) return null;

  return (
    <Module5ContextProvider project={project}>
      <Module5Context.Consumer>
        {(): any => {
          return (
            <Switch>
              <Route
                path="/projects/:projectId/module5/:moduleroute/isafety"
                render={() => {
                  return <Module5 />;
                }}
              />
              <Route
                path="/projects/:projectId/module5/:moduleroute"
                render={() => {
                  return <Module5 />;
                }}
              />
            </Switch>
          );
        }}
      </Module5Context.Consumer>
    </Module5ContextProvider>
  );
}

export default Module5Router;
