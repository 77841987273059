/* eslint-disable import/prefer-default-export */
import axios from './axios';
import { BACKEND_URL } from '../const';

const getModule3Templates = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/modules/module3/templates`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// Product_Line : entity
// DOORS_Platform : Platform
// ,'Solution' ==> new
// 'Product'  --> new
// DOORS_Project : project
// Document_Profile :documents

const getiQualimeterFilterData = async (
  en: any, // productLines
  plat: any, // platforms
  proj: any, // projects
  docprof: any, // documentProfiles
  sol: any, // solutions new
  prod: any, // products new
  countr: any // countries new
): Promise<any> => {
  try {
    const enAll: any = [];
    const platAll: any = [];
    const projAll: any = [];
    const docprofAll: any = [];
    const solAll: any = [];
    const prodAll: any = [];
    const countrAll: any = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < en.length; i++) {
      enAll.push(`&productLines=${encodeURIComponent(en[i])}`);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < plat.length; i++) {
      platAll.push(`&platforms=${encodeURIComponent(plat[i])}`);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < proj.length; i++) {
      projAll.push(`&projects=${encodeURIComponent(proj[i])}`);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < docprof.length; i++) {
      docprofAll.push(`&documentProfiles=${encodeURIComponent(docprof[i])}`);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < sol.length; i++) {
      solAll.push(`&solutions=${encodeURIComponent(sol[i])}`);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < prod.length; i++) {
      prodAll.push(`&products=${encodeURIComponent(prod[i])}`);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < countr.length; i++) {
      countrAll.push(`&countries=${encodeURIComponent(countr[i])}`);
    }

    if (enAll.length === 0) {
      enAll.push(`productLines=*`);
    }
    if (platAll.length === 0) {
      platAll.push(`&platforms=*`);
    }
    if (projAll.length === 0) {
      projAll.push(`&projects=*`);
    }
    if (docprofAll.length === 0) {
      docprofAll.push(`&documentProfiles=*`);
    }
    if (solAll.length === 0) {
      solAll.push(`&solutions=*`);
    }
    if (prodAll.length === 0) {
      prodAll.push(`&products=*`);
    }
    if (countrAll.length === 0) {
      countrAll.push(`&countries=*`);
    }
    // console.log(platAll.join(''));
    // console.log(docprofAll.join(''));

    // console.log(
    //   `https://isens-m3-py.azurewebsites.net/dataset_details_multi_filter?${enAll.join(
    //     ''
    //   )}${platAll.join('')}${projAll.join('')}${docprofAll.join('')}`
    // );
    const body = {
      productLines: enAll.join(''),
      platforms: platAll.join(''),
      projects: projAll.join(''),
      documentProfiles: docprofAll.join(''),
      solutions: solAll.join(''),
      products: prodAll.join(''),
      countries: countrAll.join(''),
    };
    // const response = await axios.get(
    //   // eslint-disable-next-line max-len
    //   `https://isens-m3-iqualimeter.azurewebsites.net/dataset_details_multi_filter?${enAll.join(
    //     ''
    //   )}${platAll.join('')}${projAll.join('')}${docprofAll.join('')}`
    // );
    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/modules/pipe/m3-dataset_details_multi_filter`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const inQualimeterDataset = async (
  entitiesIs: any,
  platformsIs: any,
  projectsIs: any,
  documentTitlesIs: any,
  projectId: any,
  solutionIs: any,
  productIs: any
): Promise<any> => {
  try {
    const body = {
      productLines: entitiesIs,
      platforms: platformsIs,
      projects: projectsIs,
      documentProfiles: documentTitlesIs,
      solutions: solutionIs,
      products: productIs,
      countries: ['*'],
    };
    // console.log(body);
    // const response = await axios.post(
    //   `https://isens-m3-py.azurewebsites.net/incose_dataset_multi_filter`,
    //   body
    // );
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m3-iqualimeter-incose/${projectId}`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getiQualimeterRules = async (): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m3-rule_names_user_selection`,
      {}
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getIverifierConfig = async (id: any, attributeName: any): Promise<any> => {
  try {
    const response = await axios.get(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/projects/${id}/iverifier_attributes/${attributeName}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const createIverifierConfig = async (projectId: any, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/projects/${projectId}/iverifier_attributes`,
      data
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const editIverifierConfig = async (projectId: any, attributeId: any, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/projects/${projectId}/iverifier_attributes/${attributeId}`,
      data
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// getting the templates api
const getProjectTemplateId = async (projectId: number): Promise<any> => {
  try {
    const response = await axios.get(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/projects/${projectId}/iverifier_template`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const setProjectTemplateId = async (projectId: number, templateId: number): Promise<any> => {
  try {
    const response = await axios.put(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/projects/${projectId}/iverifier_template?template_id=${templateId}`,
      {}
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getTemplates = async (isPublished: boolean): Promise<any> => {
  try {
    const response = await axios.get(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/iverifier/templates?is_published=${isPublished}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getRules = async (project_id: any, template_id: any): Promise<any> => {
  try {
    const response = await axios.get(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/iverifier/rules?project_id=${project_id}&template_id=${template_id}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getRulesProject = async (project_id?: any): Promise<any> => {
  try {
    const response = await axios.get(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/iverifier/rules?project_id=${project_id}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getFunctionProject = async (project_id?: any): Promise<any> => {
  try {
    const response = await axios.get(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/iverifier/functions?project_id=${project_id}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const importTemplateToProject = async (to_project_id: any, from_template_id: any): Promise<any> => {
  try {
    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/iverifier/templates/${from_template_id}/import_to_project/${to_project_id}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const iTestGenerator = async (query: any, optional: any): Promise<any> => {
  try {
    // const body = {
    //   waves: en.split(','), // ['*', 'a']
    //   domain: plat.split(','),
    //   xAxis: proj.split(','),
    //   yAxis: 'Performance',
    // };
    // Apsys new changes
    const body = {
      query,
      exampleFuncationTestCases: optional,
    };
    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/modules/pipe/m3-test-generator`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export {
  getProjectTemplateId,
  setProjectTemplateId,
  getModule3Templates,
  getiQualimeterFilterData,
  inQualimeterDataset,
  getiQualimeterRules,
  getIverifierConfig,
  createIverifierConfig,
  editIverifierConfig,
  getTemplates,
  getRules,
  getRulesProject,
  getFunctionProject,
  importTemplateToProject,
  iTestGenerator,
};
