import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Breadcrumbs,
  Typography,
  Box,
  Select,
} from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import MultiSelect from 'react-multi-select-component';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  getClusterName,
  getNonChangeRedinessProject,
  getRadinessProjectName,
  getRadinessProjects,
  getRmName,
  getSubRegionName,
} from '../../../api/kpi';
import useAppToken from '../../../app/useAppToken';
import NavigationGoto from '../../../components/Core/NavigationGoto';
import TableComponent from '../../../components/Datagrid/TableComponent';
import Loading from '../../../components/Core/Loading';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import { rowComponent, toolbarComponent } from '../../../components/Datagrid/TStyles';
import ModalAssesmentEditor from './AdminModal/ModalAssesmentEditor';

const AdminAssessment = () => {
  const [version, setVersion] = useState('');
  const [question, setQuestion] = useState('');
  const [arrayQns, setArrayQns] = useState([]);
  const [errorStar, setErroStar] = useState(false);
  const [payload, setPayload] = useState([]);
  // modal state
  const [toggleModalName, setToggleModalName] = useState<'' | 'modalAssesmentEditor'>('');
  // table pagging state
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    if (pageSize > payload.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < payload.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);
  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };
  // const handleChange = (e) => {
  //   setVersion(e.target.value);
  // };

  const AddQuesitons = () => {
    setToggleModalName('modalAssesmentEditor');
  };
  // column Name
  const columnsName = [
    { name: 'id', title: 'Id' },
    { name: 'question_serial_number', title: 'Question Serial Number' },
    { name: 'sub_serial_number', title: 'Sub Serial Number' },
    { name: 'question', title: 'Question' },
    { name: 'User_Id', title: 'User Id' },
    { name: 'created_date', title: 'Created Date' },
  ];
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <span
            style={{
              fontWeight: 'bold',
              color: '#002A45',
              marginRight: 5,
            }}
          >
            Process Number :
          </span>
          <FormControl variant="outlined" size="small">
            {/* <InputLabel htmlFor="outlined-age-native-simple" style={{ width: 500 }}>
                  Translated Language
                </InputLabel> */}
            <Select
              native
              // label="Translated Language"
              // name={'sheetname'.concat(indexIs)}
              name="Process Number"
              style={{ width: 170, marginTop: -8 }}
              //   onChange={handleTextChangeToLanguage}
              //   value={textValueToText}
            >
              <option aria-label="None" value="" disabled />
              {/* {segment?.length > 0 &&
                    segment.map((cap: string, index: number) => (
                      <option key={`capture-type-${index}`} value={cap}>
                        {cap}
                      </option>
                    ))} */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <span
            style={{
              fontWeight: 'bold',
              color: '#002A45',
              marginRight: 5,
            }}
          >
            Plan Number :
          </span>
          <FormControl variant="outlined" size="small">
            {/* <InputLabel htmlFor="outlined-age-native-simple" style={{ width: 500 }}>
                  Translated Language
                </InputLabel> */}
            <Select
              native
              // label="Translated Language"
              // name={'sheetname'.concat(indexIs)}
              name="Plan Number"
              style={{ width: 170, marginTop: -8 }}
              //   onChange={handleTextChangeToLanguage}
              //   value={textValueToText}
            >
              <option aria-label="None" value="" disabled />
              {/* {segment?.length > 0 &&
                    segment.map((cap: string, index: number) => (
                      <option key={`capture-type-${index}`} value={cap}>
                        {cap}
                      </option>
                    ))} */}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Button
            style={{ width: '8rem', height: '3rem', float: 'right' }}
            type="button"
            variant="contained"
            color="primary"
            onClick={AddQuesitons}
          >
            ADD
          </Button>
        </Grid>
      </Grid>
      <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
        <ExpressGrid rows={payload} columns={columnsName}>
          <FilteringState defaultFilters={[{ columnName: 'rm_name', value: '' }]} />
          <IntegratedFiltering />
          <SortingState />
          <IntegratedSorting />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <Table tableComponent={TableComponent} />
          {/* table header bold <TableHeaderRow showSortingControls cellComponent={TableHeaderCellWrap} /> */}
          <TableHeaderRow showSortingControls />
          <TableFilterRow rowComponent={rowComponent} />
          {/* <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={setHiddenColumnNames}
          /> */}
          <Toolbar rootComponent={toolbarComponent} />
          {/* <ColumnChooser /> */}
          {/* <ToolBarPlugin name="Download" onClick={startExport} /> */}
          {/* <ToolBarPlugin name="Download" onClick={startExport} /> */}
          {/* <ToolBarPlugin
                name="Height"
                title="Wrap Text"
                onClick={() => setToggleHeight(!toggleHeight)}
              /> */}
          {/* <PagingPanel pageSizes={pageSizes} /> */}
          <PagingPanel pageSizes={pageSizes} />
        </ExpressGrid>
        {/* <GridExporter ref={exporterRef} rows={payload} columns={columnsName} onSave={onSave} /> */}
        <NavigationGoto
          setPageSize={setPageSize}
          fileLength={payload.length}
          pageSize={pageSize}
          dis={dis}
          setTemPage={setTemPage}
          handlePageNum={handlePageNum}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Box>
      {toggleModalName === 'modalAssesmentEditor' && (
        <ModalAssesmentEditor
          // data={ruleSelected}
          onClose={(response) => {
            setToggleModalName('');
            // setRuleSelected(undefined);
            if (response) {
              // setRows([rows, ...response]);
              // refreshPage();
            }
          }}
        />
      )}
    </>
  );
};

export default AdminAssessment;
