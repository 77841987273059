/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Avatar, IconButton, makeStyles, Paper, TableContainer } from '@material-ui/core';
import GetAppIcon from '@mui/icons-material/GetApp';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../../api/axios';
import { folderDelete, folderRename, resourcesDelete } from '../../../api/folders';
import csv_ico from '../../../assets/csv_icons.png';
import docs_ico from '../../../assets/docx_icons.png';
import pdf_ico from '../../../assets/pdf_ins.png';
import xlsx_ico from '../../../assets/xlsx_icons.png';
import LinearProgressWithLabel from '../../../components/Charts/LineProgress';
import ProjectBreadCrumbs from '../../../components/Core/ProjectBreadCrumbs';
import TableComponent from '../../../components/Datagrid/TableComponent';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import { BACKEND_URL, IS_DEBUG_MODE } from '../../../const';
import { BlobFile, Project } from '../../../react-app-env';
import downloadFile from '../../../tools/downloadFile';
import formatBytes from '../../../tools/formatBytes';
import formatDate, { formatLocal } from '../../../tools/formatDate';
import isUserAction from '../../../tools/userRole';
import ProjectContext from '../ProjectContext';
import { searchUsers } from '../../../api/users';
import useAppToken from '../../../app/useAppToken';
import supportedfiles from '../../../components/DocumentTranslator/const/supportedfiles';

type PropsDialogFolder = {
  folderName: string;
  open: any;
  handleClose: any;
  handleConfirm: any;
};

type PropsDialogFile = {
  files: BlobFile[];
  open: any;
  handleClose: any;
  handleConfirm: any;
};

const DialogConfirmDeleteFolder = ({
  folderName,
  open,
  handleClose,
  handleConfirm,
}: PropsDialogFolder) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Do you want to delete the folder : {folderName} ?
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleConfirm} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogRename = ({ folderName, open, handleClose, handleConfirm }: PropsDialogFolder) => {
  const [val, setVal] = useState(folderName);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">Enter new name</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Folder name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={val}
            onChange={(e) => setVal(e.currentTarget.value)}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={() => handleConfirm(val)} autoFocus>
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogConfirmDeleteFiles = ({ files, open, handleClose, handleConfirm }: PropsDialogFile) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Do you want to delete the files :
        <ul>
          {files.map((f) => (
            <li key={`li-${f.etag}`}>{f.filename}</li>
          ))}
        </ul>
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleConfirm} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  bar1Determinate: {
    background: 'linear-gradient(270deg, #1c3145, #dd2f22)',
  },
  barRoot: {
    height: 10,
    borderRadius: 5,
  },
  menuButton: {
    // height: 30,
    width: 30,
  },
  visibilityButton: {
    // height: 30,
    // width: 30,
    // fontSize: 35,
    width: '96vw',
    height: '20vh',
  },
  closeIcon: {
    '&.css-i4bv87-MuiSvgIcon-root': {
      // position: 'absolute',
      // top: 30,
      // right: 0,
    },
    position: 'absolute',
    top: 50,
    right: 0,
  },
}));

function ProjectFolder() {
  const classes = useStyles();
  const history = useHistory();
  const { projects, userJWT, setSnack, getProject, setProject } = useContext(ProjectContext);
  const { projectId, folderId } = useParams() as any;
  const project = getProject(Number(projectId));

  const [rows, setRows] = useState<any[]>([]);
  const pageSizes = [5, 10, 20, 50];
  const [fldrName, setFldrName] = useState('');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [selectionRows, setSelectionRows] = useState<any[]>([]);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [openDeleteFolder, setOpenDeleteFolder] = useState(false);
  const [openRename, setOpenRename] = useState(false);
  const [projectUsers, setProjectUsers] = useState<any[]>([]);
  const { appJWT } = useAppToken() as any;
  const token: any = appJWT.user_id;

  const [openDeleteFiles, setOpenDeleteFiles] = useState(false);

  useEffect(() => {
    if (project && project.folders) {
      const myFolder = project.folders.find((fldr: any) => Number(fldr.id) === Number(folderId));
      if (myFolder?.resources) {
        setRows(myFolder.resources as any[]);
      } else setRows([]);
      if (myFolder) setFldrName(myFolder.name);
    }
  }, [folderId, project]);
  // get all project
  useEffect(() => {
    searchUsers({ project_id: projectId }).then((dbusers: any[]) => {
      const myusers = dbusers
        .map((userdt) => {
          return {
            ...userdt.user,
            role_id: userdt.user_project.role_id,
          };
        })
        .filter((users: any) => {
          // eslint-disable-next-line eqeqeq
          if (users.id == token) {
            return users;
          }
          return null;
        });
      setProjectUsers(myusers);
    });
  }, []);

  const handleAddFilesToProject = async (e: React.ChangeEvent<any>) => {
    const uploadedFiles = e.currentTarget.files; /* eslint no-continue: "error" */
    const formdata = new FormData();
    const fileLength = [];
    const format = /* eslint-disable no-useless-escape */ /[`!@#$%^&*()+\=\[\]{};'\\:"\\|,<>\/?~]/;
    /* eslint no-continue: "error" */
    for (const myFile of uploadedFiles as File[]) {
      // const fileNameWithoutExt = myFile.name.split('.')[0];
      const ext = myFile.name.split('.');
      /* eslint no-continue: "error" */
      if (!supportedfiles.includes(ext[ext?.length - 1])) {
        setSnack(`File (${myFile.name}) type not supported`, 'error');
        /* eslint no-continue: "error" */
      } else {
        const fileNameWithoutExt = myFile.name.split('.').slice(0, -1).join('.');
        if (fileNameWithoutExt.match(format) || myFile.name.length > 80) {
          fileLength.push(myFile.name);
        } else if (CheckDuplicateFiles(myFile)) {
          const result = window.confirm(
            `This file already exists: ${myFile.name}. Click OK to add again?`
          );
          if (result) formdata.append('files', myFile);
        } else formdata.append('files', myFile);
      }
    }
    if (fileLength.length >= 1) {
      setSnack(
        `File name:(${fileLength.toString()}) should be less than 80 characters and only support special characters(-,_).`,
        'error'
      );
    }
    if (formdata.get('files')) {
      const uploadUrl = BACKEND_URL.upload
        .replace('projectId', projectId)
        .replace('folderId', folderId);
      try {
        const uplResponse = await axios.post(uploadUrl, formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent: any) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);

            setUploadPercentage(percent);
          },
        });
        const folderUpdated = (await uplResponse.data) as Folder;
        if (folderUpdated && project && project.folders) {
          const index = project.folders?.findIndex((f) => Number(f.id) === Number(folderId));
          if (index >= 0) {
            project.folders[index] = folderUpdated;
            setProject(project);
            setRows(folderUpdated.resources || []);
            setTimeout(function () {
              setUploadPercentage(0);
            }, 500);
            setSnack('Successfully Uploaded', 'success');
          }
        }
      } catch (error) {
        setSnack('Error in File Upload', 'error');
      }
    }
  };

  const CheckDuplicateFiles = (myFile: any) => {
    if (project && project.folders) {
      for (const eachFolder of project.folders)
        if (eachFolder.resources && eachFolder.resources.length > 0)
          for (const resource of eachFolder.resources)
            if (resource.filename === myFile.name) return true;
    }
    return false;
  };

  const handleDownload = (filename: string) => {
    const querystring = `filename=${encodeURI(
      filename
    )}&project_id=${projectId}&folder_id=${folderId}`;
    downloadFile(`/download/resources`, querystring);
  };

  const handleDeleteFolder = () => {
    folderDelete(projectId, folderId)
      .then(() => {
        const projectFoldersUpdated = project?.folders?.filter(
          (f) => Number(f.id) !== Number(folderId)
        );
        setProject({ ...project, folders: projectFoldersUpdated });
        history.push('/projects');
        setSnack('Folder deleted', 'success');
      })
      .catch((e) => {
        // console.log(e);
        if (e.detail) setSnack(e.detail, 'error');
        else setSnack('Something went wrong', 'error');
      });
  };

  const handleRenameFolder = (folderNameNew: string) => {
    folderRename(projectId, folderId, folderNameNew)
      .then(() => {
        const projectFolders =
          project?.folders?.map((f) => {
            if (Number(f.id) === Number(folderId)) {
              // eslint-disable-next-line no-param-reassign
              f.name = folderNameNew;
            }
            return f;
          }) || [];
        setProject({ ...project, folders: projectFolders });
        setOpenRename(false);
        setSnack('Folder renamed', 'success');
      })
      .catch((e) => {
        // console.log(e);
        if (e.detail) setSnack(e.detail, 'error');
        else setSnack('Something went wrong', 'error');
      });
  };

  const handleDeleteFiles = () => {
    if (selectionRows.length === 0) return;
    const selectedRows = rows.filter((row, index) => selectionRows.includes(index));
    const keptRows = rows.filter((row, index) => !selectionRows.includes(index));

    resourcesDelete(projectId, folderId, selectedRows)
      .then(() => {
        setRows(keptRows);
        const projectFolders =
          project?.folders?.map((f) => {
            if (Number(f.id) === Number(folderId)) {
              // eslint-disable-next-line no-param-reassign
              f.resources = keptRows;
            }
            return f;
          }) || [];
        setProject({ ...project, folders: projectFolders });
        setSnack(`${selectedRows.length} files deleted`, 'success');
      })
      .catch((e) => {
        // console.log(e);
        if (e.detail) setSnack(e.detail, 'error');
        else setSnack('Something went wrong', 'error');
      })
      .finally(() => {
        setSelectionRows([]);
        setOpenDeleteFiles(false);
      });
  };
  const renderFile = ({ value }: DataTypeProvider.ValueFormatterProps) => {
    if (value.toLowerCase().split('.').pop() === 'csv') {
      return (
        <>
          <IconButton title="csv" size="small" edge="start" aria-label="home">
            <Avatar alt="iSenS" variant="square" src={csv_ico} className={classes.menuButton} />
          </IconButton>
          {value}
        </>
      );
    }
    if (value.toLowerCase().split('.').pop() === 'xls') {
      return (
        <>
          <IconButton title="xls" size="small" edge="start" aria-label="home">
            <Avatar alt="iSenS" variant="square" src={xlsx_ico} className={classes.menuButton} />
          </IconButton>
          {value}
        </>
      );
    }
    if (value.toLowerCase().split('.').pop() === 'xlsx') {
      return (
        <>
          <IconButton title="xlsx" size="small" edge="start" aria-label="home">
            <Avatar alt="iSenS" variant="square" src={xlsx_ico} className={classes.menuButton} />
          </IconButton>
          {value}
        </>
      );
    }
    if (value.toLowerCase().split('.').pop() === 'xlsm') {
      return (
        <>
          <IconButton title="xlsm" size="small" edge="start" aria-label="home">
            <Avatar alt="iSenS" variant="square" src={xlsx_ico} className={classes.menuButton} />
          </IconButton>
          {value}
        </>
      );
    }
    if (value.toLowerCase().split('.').pop() === 'xlsb') {
      return (
        <>
          <IconButton title="xlsb" size="small" edge="start" aria-label="home">
            <Avatar alt="iSenS" variant="square" src={xlsx_ico} className={classes.menuButton} />
          </IconButton>
          {value}
        </>
      );
    }
    if (value.toLowerCase().split('.').pop() === 'pdf') {
      return (
        <>
          <IconButton title="pdf" size="small" edge="start" aria-label="home">
            <Avatar alt="iSenS" variant="square" src={pdf_ico} className={classes.menuButton} />
          </IconButton>
          {value}
        </>
      );
    }
    return (
      <>
        <IconButton title="docx" size="small" edge="start" aria-label="home">
          <Avatar alt="iSenS" variant="square" src={docs_ico} className={classes.menuButton} />
        </IconButton>
        {value}
      </>
    );
  };

  const columns = [
    { name: 'filename', title: 'File Name' },
    { name: 'download', title: ' ' },
    { name: 'extension', title: 'Ext.' },
    { name: 'date', title: 'Date upload' },
    { name: 'size', title: 'Size' },
    { name: 'userName', title: 'Uploaded by' },
  ];

  return (
    <div className={classes.root}>
      {IS_DEBUG_MODE && (
        <ReactJson src={{ rows, project, projectUsers }} collapsed={true} theme="google" />
      )}
      {/* <ProjectBreadCrumbs projectId={projectId} folderId={folderId} /> */}
      <TableContainer component={Paper}>
        {uploadPercentage > 0 && (
          <div style={{ margin: 10 }}>
            <LinearProgressWithLabel
              classes={{
                bar1Determinate: classes.bar1Determinate,
                root: classes.barRoot,
              }}
              value={uploadPercentage}
            />
          </div>
        )}
        <ExpressGrid
          // rows={rows}
          rows={rows
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map((r) => ({
              ...r,
              extension: String(r.filename).split('.').pop(),
            }))}
          columns={columns}
        >
          <DataTypeProvider
            for={['download']}
            formatterComponent={({ row }) => (
              <>
                <a
                  href="#"
                  onClick={() => {
                    handleDownload(row.filename);
                  }}
                  style={{ marginRight: 5 }}
                  label="anchor"
                >
                  <GetAppIcon />
                </a>
                {/* <VisibilityIcon
                    className={classes.visibilityButton}
                    onClick={() => setChecked(!checked)}
                  /> */}
              </>
            )}
          />
          <DataTypeProvider for={['filename']} formatterComponent={renderFile} />
          <DataTypeProvider
            for={['size']}
            formatterComponent={({ value }) => <>{formatBytes(Number(value), 0)}</>}
          />
          <DataTypeProvider
            for={['date']}
            formatterComponent={({ value }) => <>{formatDate(value, formatLocal())}</>}
          />
          <SelectionState selection={selectionRows} onSelectionChange={setSelectionRows} />
          {/* filtering */}
          <FilteringState />
          <IntegratedFiltering />
          <PagingState defaultCurrentPage={0} defaultPageSize={10} />
          <IntegratedPaging />
          <SortingState />
          <IntegratedSorting />
          <IntegratedSelection />

          <Table
            columnExtensions={[
              {
                columnName: 'download',
                width: 50,
                wordWrapEnabled: toggleHeight,
              },
              {
                columnName: 'filename',
                width: 600,
                wordWrapEnabled: toggleHeight,
              },
              { columnName: 'date', width: 200, wordWrapEnabled: toggleHeight },
              {
                columnName: 'extension',
                width: 80,
                wordWrapEnabled: toggleHeight,
              },
              { columnName: 'size', width: 100, wordWrapEnabled: toggleHeight },
              {
                columnName: 'userName',
                width: 'auto',
                wordWrapEnabled: toggleHeight,
              },
            ]}
            tableComponent={TableComponent}
          />
          <TableHeaderRow showSortingControls />
          {/* filtering */}
          <TableFilterRow />
          <Toolbar />
          {/* selection */}
          <TableSelection showSelectAll />
          {/* <ColumnChooser /> */}
          {isUserAction(project as Project, userJWT, 'project-file-delete', projectUsers) && (
            <ToolBarPlugin
              name="Delete"
              title="Delete selected files"
              onClick={() => {
                if (selectionRows.length) setOpenDeleteFiles(true);
              }}
            />
          )}
          {projectUsers.length > 0 && projectUsers[0].is_admin === true ? (
            <>
              {isUserAction(project as Project, userJWT, 'project-folder-delete', projectUsers) && (
                <ToolBarPlugin
                  name="DeleteFolder"
                  title="Delete current folder"
                  onClick={() => setOpenDeleteFolder(true)}
                />
              )}
            </>
          ) : null}
          {isUserAction(project as Project, userJWT, 'project-folder-rename', projectUsers) && (
            <ToolBarPlugin
              name="Edit"
              title="Rename current folder"
              onClick={() => setOpenRename(true)}
            />
          )}
          <ToolBarPlugin
            name="ExcelImport"
            title="Import files"
            onClick={handleAddFilesToProject}
            mulitple={true}
          />
          <ToolBarPlugin
            name="Height"
            title="Wrap Text"
            onClick={() => setToggleHeight(!toggleHeight)}
          />

          <PagingPanel pageSizes={pageSizes} />
        </ExpressGrid>
      </TableContainer>
      {openDeleteFolder && (
        <DialogConfirmDeleteFolder
          folderName={fldrName}
          open={openDeleteFolder}
          handleClose={() => setOpenDeleteFolder(false)}
          handleConfirm={handleDeleteFolder}
        />
      )}
      {openRename && (
        <DialogRename
          folderName={fldrName}
          open={openRename}
          handleClose={() => setOpenRename(false)}
          handleConfirm={handleRenameFolder}
        />
      )}
      {openDeleteFiles && (
        <DialogConfirmDeleteFiles
          files={rows.filter((row, index) => selectionRows.includes(index))}
          open={openDeleteFiles}
          handleClose={() => setOpenDeleteFiles(false)}
          handleConfirm={handleDeleteFiles}
        />
      )}
    </div>
  );
}

export default ProjectFolder;
