import { Chip } from '@mui/material';
import React from 'react';
import { COMPLIANCE_COLORS, COMPLIANCE_LABELS } from '../../const/analysis';

const checkCompliance = (compliance) => {
  let color = COMPLIANCE_COLORS.tbd;
  let label = COMPLIANCE_LABELS.tbd;

  switch (compliance) {
    case 'compliant':
      color = COMPLIANCE_COLORS.compliant;
      label = COMPLIANCE_LABELS.compliant;
      break;

    case 'nc':
      color = COMPLIANCE_COLORS.nc;
      label = COMPLIANCE_LABELS.nc;
      break;

    case 'gap':
      color = COMPLIANCE_COLORS.gap;
      label = COMPLIANCE_LABELS.gap;
      break;

    case 'tbd':
      color = COMPLIANCE_COLORS.tbd;
      label = COMPLIANCE_LABELS.tbd;
      break;

    case 'need-clarification':
      color = COMPLIANCE_COLORS.clarification;
      label = COMPLIANCE_LABELS.clarification;
      break;

    default:
      return null;
  }

  return { label, color };
};

export default checkCompliance;
