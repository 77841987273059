export type rowType = {
  'Object Number': string;
  'Module Profile': string;
  'Script - Rule - ID': string;
  Rule: string;
  'Script - Rule - Condition - Local': string;
  'Script - Rule - Expected Result': string;
  'Script - Rule - Condition - Full': string;
  'Deletion from Profile': string;
};

export type xslxColumn = {
  A: string;
  B: string;
  C: string;
  D: string;
  E: string;
  F: string;
  G: string;
  H: string;
};

export type exportConfig = rowType[];

export default (inputJson: any[]): exportConfig => {
  return inputJson.slice(1).map(
    (row: xslxColumn): rowType => {
      return {
        'Object Number': row.A || '',
        'Module Profile': row.B || '',
        'Script - Rule - ID': row.C || '',
        Rule: row.D || '',
        'Script - Rule - Condition - Local': row.E || '',
        'Script - Rule - Expected Result': row.F || '',
        'Script - Rule - Condition - Full': row.G || '',
        'Deletion from Profile': row.H || '',
      };
    }
  );
};
