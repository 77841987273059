import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

const TreeArowIcons = makeStyles((theme: Theme) => ({
  ArowupStyle: {
    height: '24px',
    width: '24px',
    marginRight: '15px',
  },
  ArowdownStyle: {
    height: '24px',
    width: '24px',
    marginRight: '15px',
  },
}));
export default TreeArowIcons;
