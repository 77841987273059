import { AlertProps } from '@material-ui/lab';
import isEqual from 'react-fast-compare';
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import AlertSnack from '../../components/Alert/AlertSnack';
import { Project } from '../../react-app-env';

export interface Module3ContextType {
  project: Project;
  setProject: (d: Project) => any;
  artifacts: Artifact[];
  setArtifacts: (d: Artifact) => any;
  setSnackMsg: (msg: string) => any;
  setSnackSeverity: (severity: AlertProps['severity']) => any;
  setSnack: (msg: string, severity?: AlertProps['severity']) => any;
}

const Module3Context = React.createContext<Module3ContextType>({
  project: {} as any,
  setProject: (): any => {},
  artifacts: [] as any,
  setArtifacts: (): any => {},
  setSnackMsg: (): any => {},
  setSnackSeverity: (): any => {},
  // combi msg + severity
  setSnack: (): any => {},
});

type Props = {
  project: Project;
  artifacts?: Artifact[];
  children: any;
};

const Module3ContextProvider = ({ project, artifacts, children }: Props): any => {
  const [_project, _setProject] = useState(project);
  const [_artifacts, _setArtifacts] = useState(artifacts);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackSeverity, setSnackSeverity] = useState<AlertProps['severity']>('success');

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackMsg('');
  };

  return (
    <Module3Context.Provider
      value={{
        project: _project,
        setProject: (_input) => {
          if (!isEqual(project, _input)) _setProject(_input);
        },
        artifacts: _artifacts || ([] as any),
        setArtifacts: (_input) => {
          if (!isEqual(project, _input)) _setArtifacts(_input as any);
        },
        setSnackMsg,
        setSnackSeverity,
        setSnack: (msg: string, severity = 'success' as AlertProps['severity']) => {
          setSnackMsg(msg);
          setSnackSeverity(severity);
        },
      }}
    >
      {snackMsg !== '' && (
        <AlertSnack
          msg={snackMsg}
          severity={snackSeverity}
          handleClose={handleClose}
          duration={3000}
        />
      )}
      {children}
    </Module3Context.Provider>
  );
};

export default Module3Context;

export { Module3ContextProvider };
