import { AppJwtType } from 'Auth';
import { Project } from '../react-app-env';

export type userRoleProject =
  | 'project-create'
  | 'project-delete'
  | 'project-user-add'
  | 'project-user-remove'
  | 'project-user-role'
  | 'project-folder-create'
  | 'project-folder-delete'
  | 'project-folder-rename'
  | 'project-file-upload'
  | 'project-file-delete'
  | 'project-nc-create'
  | 'project-nc-upload'
  | 'project-gap-create'
  | 'project-gap-upload'
  | 'project-groups-write'
  | 'project-kpi-read';

export type userRoleM1 =
  | 'm1-capture-run'
  | 'm1-req-assign'
  | 'm1-req-own'
  | 'm1-capture-run'
  | 'm1-reports-download';

export type userRoleM3Verifier = 'm3-verif-rules-app' | 'm3-verif-rules-project';

const isUserAction = (
  project: Project,
  userJWT: AppJwtType,
  roleType: userRoleProject | userRoleM1 | userRoleM3Verifier,
  projectUsers: any[]
): boolean => {
  const isAuth = false;
  if (!project || !userJWT) {
    return false;
  }
  // old code commented by @RahulKr
  // noticed issue: role_id object is not available in projectUser so passed the projectUsers from parent nad then utilized it in new code
  // const { users } = project;
  // if (!users) {
  //   return false;
  // }
  // const projectUser = users.find((u) => Number(u.user_id) === Number(userJWT.user_id));
  //  const {role_id} = projectUser;

  // new code @RahulKr
  const { user_id } = project;
  if (!user_id) {
    return false;
  }
  const projectUser = Number(user_id) === Number(userJWT.user_id);
  if (!projectUser) {
    return false;
  }
  switch (projectUsers[0]?.role_id) {
    case 1:
      if (
        [
          'project-create',
          'project-delete',
          'project-folder-create',
          'project-folder-delete',
          'project-folder-rename',
          'project-file-delete',
        ].includes(roleType)
      ) {
        return true;
      }
      break;
    case 2:
      if (
        [
          'project-create',
          'project-delete',
          'project-folder-create',
          'project-folder-delete',
          'project-folder-rename',
        ].includes(roleType)
      ) {
        return true;
      }
      break;
    case 3:
      if (['project-folder-create'].includes(roleType)) {
        return true;
      }
      break;
    case 4:
      break;
    default:
      break;
  }

  return isAuth;
};

export default isUserAction;
