import { Chip, Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { getCaptureKPI } from '../../api/assign';

const COLORS = [
  '#1e3246',
  '#dc3223',
  '#19aa6e',
  '#4b5a69',
  '#788291',
  '#d2d7dc',
  '#eb827d',
  '#fad7d2',
  '#d2f0e1',
  '#73cdaa',
  '#9b875f',
  '#afa082',
  '#ebe6dc',
  '#c3b9a0',
  '#C3DDF6',
  '#468AD1',
  '#F8A600',
  '#2f475f',
  '#95372f',
  '#7a231b',
  '#94a2b8',
  '#5b8f75',
  '#546c60',
  '#36443d',
  '#748c80',
  '#4fa7ff',
  '#174471',
  '#384a5c',
  '#acacad',
  '#bdbdbd',
];

type Props = {
  project_id: number;
  user_id?: number;
  title: string;
  onClose?: any;
  workload_id?: number;
  file_name?: string;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (
  { cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }: any,
  full = false
) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const displayLabel = () => {
    if (full) return <>{percent !== 0 ? `${`${name} - ${(percent * 100).toFixed(0)}%`}` : ''}</>;
    return <>{percent !== 0 ? `${`${(percent * 100).toFixed(3)}%`}` : ''}</>;
  };
  return (
    <text
      transform={`translate(${x},${y}) rotate(320)`} // 330
      fill="black"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize="9px"
      fontStretch="collapsed"
      fontFamily="sans-serif, Helvetica Neue, Lucida Grande, Arial"
    >
      {/* {`${(percent * 100).toFixed(0)}%`} */}
      {/* to remove 0% from graph display */}
      {percent !== 0 ? `${`${name.substring(0, 5)} ${(percent * 100).toFixed(3)}%`}` : ''}
      {/* {percent !== 0 ? `${`${(percent * 100).toFixed(3)}%`}` : ''} */}
      {/* by David {displayLabel()} */}
    </text>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '20px',
    border: `1px solid`,
    borderColor: theme.palette.primary.main,
    opacity: 1,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

export default function CaptureKpiContainer({
  project_id,
  workload_id,
  user_id,
  file_name,
  title,
  onClose,
}: Props) {
  const classes = useStyles();
  const [captureKPI, setcaptureKPI] = useState([] as any);

  useEffect(() => {
    getCaptureKPI(project_id, workload_id, file_name).then((AssignData) => {
      setcaptureKPI(AssignData);
    });
  }, [project_id, workload_id]);

  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        spacing={0}
        className={classes.root}
      >
        <Grid item lg={12} sm={12} md={12}>
          <Typography variant="subtitle1">Capture status</Typography>
          <Grid container>
            <Grid item xs={10}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PieChart width={400} height={400}>
                  <Pie
                    isAnimationActive={true}
                    data={captureKPI}
                    // cx={510}
                    cy="center"
                    outerRadius={150}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    fill="#8884d8"
                    dataKey="value"
                    legendType="circle"
                    paddingAngle={1}
                    minAngle={5}
                  >
                    {captureKPI.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </Box>
            </Grid>
            <Grid xs={2}>
              <div style={{ textAlign: 'center', marginTop: '100px', marginLeft: '-200px' }}>
                {captureKPI.map((d, index) => (
                  <Chip
                    label={`${d.name} (${d.value})`}
                    color="primary"
                    style={{
                      backgroundColor: d.name,
                      marginRight: '3px',
                      marginTop: '5px',
                    }}
                    key={`chip-${index}`}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
