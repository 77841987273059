import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

type Props = {
  fileLength: Number;
  pageSize: Number;
  dis: Boolean;
  setTemPage: Function;
  handlePageNum: Function;
  setPageSize: Function;
  currentPage: Number;
  setCurrentPage: Function;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputPageNo: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '1rem',
      height: '4rem',
      marginLeft: '30.5rem',
      marginRight: '1rem',
      padding: '0.5rem',
      marginTop: '-60px !important',
      [theme.breakpoints.up('xl')]: {
        marginLeft: '50.5rem',
      },
      [theme.breakpoints.up('sm')]: {
        marginLeft: '10.5rem',
      },
    },
    inputTag: {
      width: '4rem',
      height: '1.8rem',
      border: '1px solid #002A45',
      '&:focus': {
        outline: 'none',
      },
      padding: '3px',
    },
    errorM: {
      float: 'right',
      marginRight: '2rem',
      color: 'red',
      marginTop: '0',
      fontSize: '0.9rem',
    },
    ul: {
      '& .MuiPaginationItem-root': {
        background: '#fff',
        color: '#002A45',
        margin: '0.4rem',
        '&.Mui-selected': {
          background: '#002A45',
          color: 'white',
        },
        '&:hover': {
          background: '#002A45',
          color: 'white',
        },
      },
    },
    gotToBtn: {
      width: '4rem',
      height: '1.8rem',
    },
  })
);

function NavigationGotoNew({
  fileLength,
  pageSize,
  dis,
  setTemPage,
  handlePageNum,
  setPageSize,
  currentPage,
  setCurrentPage,
}: Props): any {
  const classes = useStyles();
  const [val, setVal] = React.useState(5);
  const [pageCount, setPageCount] = React.useState();

  // useEffect(() => {
  //   const temp = fileLength % val;
  //   setPageSize(val);
  //   if (temp > 0) {
  //     const p = parseInt(fileLength / val, 10) + 1;
  //     setPageCount(p);
  //   } else {
  //     const p = fileLength / val;
  //     setPageCount(p);
  //   }
  // }, [val, fileLength]);

  const handleChange = (event) => {
    setVal(event.target.value);
  };
  return (
    <>
      <Box className={classes.inputPageNo}>
        {/* {/* <Typography>Page Size:</Typography>
        <Box>
          <select value={val} onChange={handleChange} className={classes.inputTag}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </Box> */}
        <Typography>Page no:</Typography>
        <Box>
          <input
            type="number"
            min="1"
            max="400"
            onChange={(e) => {
              const v = parseInt(e.target.value, 10);
              setTemPage(v);
            }}
            className={classes.inputTag}
            // disabled={dis || pageSize < fileLength ? '' : 'disabled'}
          />
        </Box>
        <Button
          className={classes.gotToBtn}
          variant="contained"
          color="primary"
          size="small"
          onClick={handlePageNum}
        >
          Go to
        </Button>
        {/* <Stack spacing={2}>
          <Pagination
            classes={{ ul: classes.ul }}
            page={currentPage + 1}
            count={pageCount}
            showFirstButton
            showLastButton
            size="small"
            onChange={(e, p) => {
              const re = p - 1;
              setCurrentPage(re);
            }}
          />
        </Stack> */}
      </Box>
    </>
  );
}

export default NavigationGotoNew;
