import { Getter } from '@devexpress/dx-react-core';
import {
  EditingState,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  Grid as ExpressGrid,
  Table,
  TableEditColumn,
  TableHeaderRow,
  TableInlineCellEditing,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Grid, TextField } from '@material-ui/core';
import { Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import XLSX from 'xlsx';
import {
  createIverifierConfig,
  editIverifierConfig,
  getIverifierConfig,
} from '../../../api/module3';
import Loading from '../../../components/Core/Loading';
import TableHeaderCellWrap from '../../../components/Datagrid/TableHeaderCellWrap';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import ProjectContext from '../../Project/ProjectContext';

const getRowId = (row) => row.index;

const FocusableCell = ({ onClick, ...restProps }) => (
  <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
);

type Props = {
  name: string;
  projectId: any;
};

function AttributeTableIV({ name, projectId }: Props) {
  const { setSnack } = useContext(ProjectContext);
  const initChecker = {
    projectId,
    name,
    description: '',
    values: '',
  };
  const [loading, setLoading] = useState(true);
  const [attributesData, setAttributesData] = useState<any>(initChecker);
  const [rows, setRows] = useState([]);
  //   [
  //   { index: 6, value: 'abc' },
  //   { index: 7, value: 'def' },
  // ]
  const [init, setInit] = useState<any>({
    description: '',
    values: '',
  });
  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onDownload = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };
  const [pageSizes] = useState([5, 10]);
  useEffect(() => {
    setLoading(true);
    getIverifierConfig(projectId, name)
      .then((response: any) => {
        if (response) {
          // console.log(response);
          if (response.length > 0) {
            setAttributesData(response[0]);
            setRows(response[0]?.values);
            // console.log('checker', response);
            setInit({
              description: response[0]?.description,
              values: response[0].values,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [name, projectId]);

  const [columns] = useState([
    { name: 'index', title: 'INDEX' },
    { name: 'value', title: 'VALUE' },
  ]);

  const [editingCells, setEditingCells] = useState([
    { columnName: 'index', editingEnabled: false },
    { columnName: 'value', editingEnabled: true },
  ]);

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId =
        rows.length > 0 ? Math.max(rows[rows.length - 1].index, rows[0].index) + 1 : 0;
      changedRows = [
        ...added.map((row, ind) => ({
          // detect first row
          index: startingAddedId + ind === 0 ? startingAddedId + ind + 1 : startingAddedId + ind,
          ...row,
        })),
        ...rows,
      ];
      setEditingCells([{ rowId: startingAddedId, columnName: columns[0].name }]);
    }
    if (changed) {
      // console.log(changed);
      changedRows = rows.map((row) =>
        // changed[row.index] changed to changed[row.index]?.value  to get the value changes only not index changes
        changed[row.index]?.value ? { ...row, ...changed[row.index] } : row
      );
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter((row) => !deletedSet.has(row.index));
    }

    setRows(changedRows);
    setAttributesData({
      ...attributesData,
      values: changedRows,
    });
  };

  const addEmptyRow = () => commitChanges({ added: [{}] });

  const saveData = () => {
    // to check passed data should not have blank value & Duplicate value
    let countVal = 0;
    const allVal: any = [];
    // eslint-disable-next-line array-callback-return
    attributesData?.values.map((e: any) => {
      allVal.push(e?.value);
      if (e?.value === undefined || e?.value === null) {
        countVal += 1;
        setSnack('Blank value not allowed', 'error');
      }
    });
    // console.log(countVal);
    // console.log(allVal);
    if (countVal > 0) {
      return;
    }
    function checkForDuplicates(array: any) {
      return new Set(array).size !== array.length;
    }
    const check = checkForDuplicates(allVal);
    if (check) {
      setSnack('Duplicate value not allowed', 'error');
      return;
    }
    // END (to check passed data should not have blank value & Duplicate value)

    if (attributesData?.id === undefined) {
      const body: any = attributesData;
      // console.log(body);
      createIverifierConfig(projectId, body)
        .then((payload: any) => {
          if (payload) {
            setSnack('Data saved', 'success');
            // console.log(payload);
          }
        })
        .catch((err) => {
          console.log(err);
          setSnack(err, 'error');
        })
        .finally(() => {});
    } else {
      const body: any = attributesData;
      // console.log(body);
      editIverifierConfig(projectId, attributesData?.id, body)
        .then((payload: any) => {
          if (payload) {
            setSnack('Data saved', 'success');
            // console.log(payload);
          }
        })
        .catch((err) => {
          console.log(err);
          setSnack(err, 'error');
        })
        .finally(() => {});
    }
  };

  const handleChange = (event) => {
    setAttributesData({
      ...attributesData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeName = (event) => {
    // setSnack('Name is not editable!', 'warning');
  };

  const handleCancelClick = () => {
    setRows(init.values);
    setAttributesData({ ...attributesData, description: init.description });
    setSnack('cancel', 'warning');
  };
  const handleAddClick = () => {
    addEmptyRow();
    // setSnack('cancel', 'warning');
  };

  const handleImport = async (e: React.ChangeEvent<any>) => {
    // fix undefined files
    if (!e.currentTarget.files || !e.currentTarget.files[0]) return;
    const myFile = e.currentTarget.files[0];
    const reader = new FileReader();

    reader.readAsArrayBuffer(myFile);
    reader.onload = () => {
      const data = new Uint8Array(reader.result as any);
      const wb = XLSX.read(data, { type: 'array' });
      const rowsImport = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
      // console.log(rowsImport);

      const startingAddedId =
        rows.length > 0 ? Math.max(rows[rows.length - 1].index, rows[0].index) + 1 : 0;
      const body = rowsImport
        .filter((r) => r.INDEX === undefined)
        .map((r: any, i: any) => {
          return {
            index: startingAddedId + i,
            value: r.VALUE,
          };
        });
      // console.log({ ...rows });
      // console.log({ ...body });

      setRows([...rows, ...body]);
      setAttributesData({
        ...attributesData,
        values: [...rows, ...body],
      });
    };
    e.currentTarget.value = '';
  };

  if (loading) return <Loading />;
  return (
    <>
      <Grid container direction="column" justify="center">
        {/* <ReactJson src={{ rows }} collapsed={true} theme="monokai" /> */}
        <Paper style={{ display: 'none' }}>
          <Stack m={2} spacing={3}>
            <TextField
              label="Name *"
              variant="outlined"
              // eslint-disable-next-line react/destructuring-assignment
              value={attributesData?.name === '' ? props.name : attributesData?.name}
              onChange={handleChangeName}
              name="name"
              disabled
              style={{ display: 'none' }}
            />
            <TextField
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={2}
              variant="outlined"
              onChange={handleChange}
              name="description"
              value={attributesData?.description}
              style={{ display: 'none' }}
            />
          </Stack>
        </Paper>
        <br />
        <Paper>
          <ExpressGrid rows={rows} columns={columns} getRowId={getRowId}>
            <EditingState
              onCommitChanges={commitChanges}
              editingCells={editingCells}
              onEditingCellsChange={setEditingCells}
              addedRows={[]}
              onAddedRowsChange={addEmptyRow}
            />

            <SortingState defaultSorting={[{ columnName: 'index', direction: 'asc' }]} />
            <IntegratedSorting />
            <Table cellComponent={FocusableCell} />
            <PagingState defaultCurrentPage={0} defaultPageSize={10} />
            <TableHeaderRow cellComponent={TableHeaderCellWrap} />
            <Toolbar />
            <ToolBarPlugin name="ExcelImport" onClick={handleImport} />
            <ToolBarPlugin name="Save" onClick={saveData} />
            <ToolBarPlugin name="Add" onClick={handleAddClick} />
            <ToolBarPlugin name="Cancel" onClick={handleCancelClick} />
            <TableInlineCellEditing selectTextOnEditStart />
            <TableEditColumn showDeleteCommand />
            <Getter
              name="tableColumns"
              computed={({ tableColumns }) => {
                const result = [
                  ...tableColumns.filter((c) => c.type !== TableEditColumn.COLUMN_TYPE),
                  {
                    key: 'editCommand',
                    type: TableEditColumn.COLUMN_TYPE,
                    width: 100,
                  },
                ];
                return result;
              }}
            />

            <ToolBarPlugin name="Download" onClick={startExport} />
            {/* <PagingPanel pageSizes={pageSizes} /> */}
          </ExpressGrid>
          <GridExporter ref={exporterRef} rows={rows} columns={columns} onSave={onDownload} />
        </Paper>
      </Grid>
    </>
  );
}
export default AttributeTableIV;
