import { Box, Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import styled from 'styled-components';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { iCaptureCsv, iCaptureExcel, iCapturePdf } from '../../../../../../api/module1';
import { UserWorkloadCreate, userWorkloadRunPost } from '../../../../../../api/workloads';
import M2GlobalContext from '../../../../../ContextMager/M2GlobalContext';
import WorkSpaceContext from '../../../../WorkspceContext';
import RexAnalysisContext from '../../RexAnalysisContext';
import userRexBookCall from '../API/userRexBook';

const Container = styled.div`
  background-color: #eef1f9;
  padding: 1rem;
  border-top: 1px solid #002a45;
`;

const CaptureNameSettings = () => {
  const {
    scopeSetting,
    operation,
    selection,
    setSelection,
    setScopeSetting,
    setToggleTab,
    fileSetting,
    setWorkLoad,
    setFileSettings,
  } = useContext(RexAnalysisContext);

  const { state, dispatch } = useContext(M2GlobalContext);
  const { rexFilter } = state;
  console.log(rexFilter);

  const { setSnack } = useContext(WorkSpaceContext);
  const [captureName, setCaptureName] = React.useState('');
  const [loadingResult, setLoadingResult] = React.useState(false);
  const [checkBoxValue, setCheckBoxValue] = React.useState('');
  // const { state, dispatch } = useContext(M2GlobalContext);

  const HandleValidation = () => {
    const FileterkeysSettings = Object.keys(fileSetting);
    if (!scopeSetting.length) {
      setSnack('Please Select a File', 'error');
      return false;
    }
    if (!checkBoxValue) {
      setSnack('Please Choose between tender or project', 'error');
      return false;
    }
    if (!captureName) {
      setSnack(`Please Enter ${checkBoxValue} Name`, 'error');
      return false;
    }
    for (let i = 0; i < FileterkeysSettings.length - 1; i += 1) {
      const element = FileterkeysSettings[i];
      if (fileSetting[element]?.fileType === 'Excel') {
        if (!fileSetting[element]?.sheetName) {
          setSnack('Please Enter Excel Sheet Column', 'error');
          return false;
        }
        if (!fileSetting[element]?.targetColumn) {
          setSnack('Please Enter Excel Target Column', 'error');
          return false;
        }
      } else if (fileSetting[element].fileType === 'CSV') {
        if (!fileSetting[element]?.columnName) {
          setSnack('Please Enter CSV Column Name', 'error');
          return false;
        }
      }
    }
    // if (!captureName) {
    //   setSnack('Please Enter Activity Name', 'error');
    //   return false;
    // }
    return true;
  };

  function delay(time: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve('update');
      }, time);
    });
  }

  const handleAnalyze = async () => {
    if (HandleValidation()) {
      setLoadingResult(true);
      const obj = userRexBookCall(scopeSetting, fileSetting, captureName, checkBoxValue, rexFilter);
      // console.log(obj);
      const delayUpdate = await delay(2000);
      if (obj.error !== '') {
        setSnack(obj.error);
        setLoadingResult(obj.loading);
      } else {
        setToggleTab(obj.toogleTab);
        setWorkLoad(obj.workload);
        setLoadingResult(obj.loading);
      }
    }
  };

  const InputHandler = (e) => {
    setCaptureName(e.target.value);
  };
  return (
    <>
      <Container>
        <div>
          {/* {errorMessage !== '' && (
            <Typography style={{ color: '#DC3223' }}>Error : {errorMessage}</Typography>
          )} */}
        </div>
        <div>
          <Box style={{ display: 'flex' }}>
            <Box style={{ flexBasis: '30%' }}>{}</Box>
            <Box
              style={{
                flexBasis: '78%',
                display: 'flex',
                height: '2.5rem',
                gap: '20px',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <FormControl>
                {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => {
                    setCheckBoxValue(e.target.value);
                  }}
                >
                  <FormControlLabel value="Tender" control={<Radio />} label="Tender" />
                  <FormControlLabel value="Project" control={<Radio />} label="Project" />
                </RadioGroup>
              </FormControl>
              <TextField
                id="outlined-basic"
                label={`Enter ${checkBoxValue} Name`}
                variant="outlined"
                name="captureName"
                onChange={InputHandler}
                size="small"
                style={{
                  width: '170px',
                  height: '38px',
                  marginTop: 6,
                  marginBottom: 6,
                  backgroundColor: 'white',
                }}
              />
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleAnalyze}
                startIcon={
                  loadingResult && <CircularProgress style={{ color: 'white' }} size={20} />
                }
                style={{
                  width: '150px',
                  height: '38px',
                  marginTop: 6,
                  marginBottom: 6,
                }}
              >
                Analyze
              </Button>
            </Box>
          </Box>
        </div>
      </Container>
    </>
  );
};

export default CaptureNameSettings;
