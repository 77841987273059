import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Input,
  Button,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link as LinkTo, useHistory, useParams } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import TestGensub from '../Component/TestSub/TestGensub';
import useAppToken from '../../../../app/useAppToken';
import {
  TestGenfolderChildDelete,
  TestGenfolderChildRename,
  TestGenfolderDelete,
  TestGenfolderRename,
} from '../../../../api/folders';
import ProjectContext from '../../../Project/ProjectContext';
import ModalConfirm from '../../../Admin/components/ModalConfirm';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: 200,
    borderLeft: '1px solid #C4D4EC',
    borderRight: '1px solid #C4D4EC',
    // marginTop: 10,
  },
  leftNavigation: {
    backgroundColor: '#EEF1F9',
    // borderLeft: '2px solid black',
    // borderRight: '2px solid black',
  },
  listItem: {
    textAlign: 'center',
    // borderTop: '2px solid black',
    borderBottom: '2px solid #D3E3EE',
    backgroundColor: '#EEF1F9',
    '&:hover': {
      // border: '1px solid #6c757d',
      color: 'white',
      background: '#DC3223 0% 0% no-repeat padding-box',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
    '&:focus': {
      background: '#6c757d',
      color: 'black',
    },
  },
  ListSubMain: {
    // paddingLeft: theme.spacing(4),
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      background: '#031F31 0% 0% no-repeat padding-box',
      padding: '5px 0px 5px 10px',
    },
    '& .MuiListItem-root': {
      background: '#EEF1F9 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      // borderBottom: '1px solid #C4D4EC',
      '&.Mui-selected': {
        // backgroundColor: 'pink',
        background: '#002A45 0% 0% no-repeat padding-box',
        border: '1px solid #224B65',
        color: 'white !important',
        padding: '5px 0px 5px 10px',
        // color: 'white',
        fontWeight: 'bold',
        '& .MuiListItemIcon-root': {
          color: 'white',
        },
        '& .MuiListItemText-root': {
          color: 'white !important',
          fontWeight: 'bold',
        },
      },
    },
  },
  ListMain: {
    '& .MuiListItem-root': {
      background: '#EEF1F9 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      borderBottom: '1px solid #C4D4EC',
      // padding: 0,
      // paddingLeft: 10,
    },
    color: '#002A45',
  },
  ListsubItemInside: {
    backgroundColor: '#002A45 !important',
    borderBottom: '1px solid #C4D4EC',
    // '& .MuiListItem-button:hover': {
    //   background: 'red',
    // },
    // paddingLeft: theme.spacing(4),
    fontSize: 14,
    '& .MuiListItemIcon-root': {
      color: 'white',
    },
    '& .MuiListItemText-root': {
      color: 'white !important',
      fontWeight: 'white',
    },
    '&:focus': {
      background: 'red',
      color: 'white',
      // '& .MuiListItemIcon-root': {
      //   color: 'white',
      //   // minWidth: '38px',
      //   // marginRight: 17,
      //   // minWidth: 0,
      // },
    },
    '&.selected': {
      background: '#031F31 0% 0% no-repeat padding-box !important',
      borderLeft: '4px solid #1976d2',
      // color: 'white',
      '& .MuiListItemText-root': {
        color: 'white !important',
        fontWeight: 'bold',
      },
      fontWeight: 'bold',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
  },
  ListItemInside2: {
    // '& .MuiListItem-button:hover': {
    //   background: 'red',
    // },
    // backgroundColor: '#002A45 !important',
    fontSize: 16,
    // '&:hover': {
    //   color: '#002A45',
    //   // background: '#DC3223 0% 0% no-repeat padding-box',
    //   '& .MuiListItemIcon-root': {
    //     color: 'white',
    //   },
    // },
    '& .MuiListItemIcon-root': {
      color: '#002A45',
      // minWidth: '38px',
      // marginRight: 17,
      // minWidth: 0,
    },
    '& .MuiListItemText-root': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
    '&:focus': {
      background: 'red',
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white',
        // minWidth: '38px',
        // marginRight: 17,
        // minWidth: 0,
      },
    },
    '&.selected': {
      // color: 'red',
    },
  },
  listItem45: {
    textAlign: 'center',
    borderBottom: '2px solid #D3E3EE',
    backgroundColor: '#EEF1F9',
    fontSize: 16,
    '& .MuiListItemIcon-root': {
      color: '#002A45',
      // minWidth: '38px',
      // marginRight: 17,
      // minWidth: 0,
    },
    '& .MuiListItemText-root': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
    // '&:hover': {
    //   color: 'white',
    //   background: '#DC3223 0% 0% no-repeat padding-box',
    //   '& .MuiListItemIcon-root': {
    //     color: 'white',
    //   },
    // },
    '&:focus': {
      background: 'red',
      color: 'white',
      // '& .MuiListItemIcon-root': {
      //   color: 'white',
      //   // minWidth: '38px',
      //   // marginRight: 17,
      //   // minWidth: 0,
      // },
    },
    '&.selected': {
      background: 'red 0% 0% no-repeat padding-box',
      // color: 'white',
      '& .MuiListItemText-root': {
        color: 'white !important',
        fontWeight: 'bold',
      },
      fontWeight: 'bold',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
  },
}));
type Props = {
  child: any;
  mainData: boolean;
  collapseListChildDta: any;
};

const GeneratorLeftNavigation = ({
  child,
  mainData,
  collapseListChildDta,
  folderDeletedId,
  folderIDCreated,
  resultfolderName,
  getSelected,
  slectedFolderList,
  parentId,
  childIdGen,
  getFinalChild,
  finalId,
  getRename,
  getMainFname,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(slectedFolderList);
  const [selectedChild, setSelectedChild] = useState(finalId);
  const [openedItemId, setOpenedItemId] = useState(null);
  const [editingChildIndex, setEditingChildIndex] = useState(-1);
  const [editedFolderName, setEditedFolderName] = useState('');
  const newArray = [...mainData];

  const filteredArrays = newArray.filter((item) => {
    if (item.id === parentId) {
      return item;
    }
    return null;
  });

  const sortedArray = filteredArrays.sort((obj1, obj2) => {
    const date1 = new Date(obj1.TestcasesModel.created_at);
    const date2 = new Date(obj2.TestcasesModel.created_at);
    return date2 - date1;
  });

  // Replace the sorted items back into the original array
  newArray.forEach((item, index) => {
    if (item.id === parentId) {
      newArray[index] = sortedArray.shift();
    }
  });
  useEffect(() => {
    if (newArray[finalId]?.id === parentId && newArray.length > 0) {
      // Create a new array to avoid modifying the original array
      // setSelectedChild(newArray[]);
      const selectedItem = newArray.find((item) => item.id === newArray[finalId].id);
      handleList(selectedItem?.TestcasesModel, finalId, selectedItem?.folder_name);
    }
  }, [mainData]);

  useEffect(() => {
    if (child[0]?.id !== undefined && child.length > 0) {
      if (selected === 0) {
        setOpenedItemId(child[0]?.id);
        const selectedItem = newArray.find((item) => item.id === child[0]?.id);

        handleList(selectedItem?.TestcasesModel, 0, selectedItem?.folder_name);
      }
    }
  }, [child]);

  const [editingIndex, setEditingIndex] = useState(-1);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalFolderOpen, setModalFolderOpen] = useState(false);

  const [deletedChildId, setDeletedChildId] = useState(null);
  const [deletedFolderId, setDeletedFolderId] = useState(null);
  const [getData, setGetData] = useState(null);
  const [editedFolderChildName, setEditedChildFolderName] = useState('');
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;
  const { setSnack } = useContext(ProjectContext);
  const uniqueFolders = {};

  const handleClick = (index, clickedItemId) => {
    setSelected(index);
    getSelected(index);
    setOpenedItemId(openedItemId === clickedItemId ? null : clickedItemId);
    if (selected !== index) {
      const selectedItem = newArray.find((item) => item.id === clickedItemId);
      handleList(selectedItem?.TestcasesModel, index, selectedItem?.folder_name);
    }
  };
  const handleClickChild = (index) => {
    setSelectedChild(index);
    getFinalChild(index);
  };

  const handleList = (item, i, fName) => {
    collapseListChildDta(item);
    resultfolderName(fName);
  };

  const handleFolderNameClick = (index) => {
    setEditingIndex(index);
    setEditedFolderName(child[index].folder_name);
  };

  const handleFolderNameBlur = (index, folderId) => {
    const updatedChild = [...child];
    updatedChild[index].folder_name = editedFolderName;
    // console.log('index', index);
    setEditingIndex(-1);
    // Optionally, you can update your data with the updatedChild value here
    handleFolderReName(user_id, folderId, editedFolderName);
    // setSnack('Folder deleted successfully', 'success');
  };
  // Rename Folder Api call here
  const handleFolderReName = (userid, folderId, folderName) => {
    TestGenfolderRename(userid, folderId, folderName)
      .then((f: any) => {
        getMainFname(folderName);
        setSnack('Folder Renamed successfully', 'success');
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleFolderChildNameClick = (index) => {
    setEditingChildIndex(index);
    setEditedChildFolderName(child[index].TestcasesModel.testcase_name);
  };
  const handleFolderChildNameBlur = (index, testCaseId) => {
    const updatedChild = [...child];
    updatedChild[index].TestcasesModel.testcase_name = editedFolderChildName;
    setEditingChildIndex(-1);
    // Optionally, you can update your data with the updatedChild value here
    handleFolderChildReName(user_id, testCaseId, editedFolderChildName);
    // setSnack('Folder deleted successfully', 'success');
  };
  // Rename Folder Api call here
  const handleFolderChildReName = (userid, testCaseId, testCaseName) => {
    TestGenfolderChildRename(userid, testCaseId, testCaseName)
      .then((f: any) => {
        getRename(testCaseName);
        setSnack('Folder child Renamed successfully', 'success');
      })
      .catch((err) => {
        console.error(err);
      });
  };
  // delete Folder Api call here
  const handleFolderNameDelete = (userid, folderId) => {
    setModalFolderOpen(true);
    setDeletedFolderId(folderId);
  };

  const handleFolderChildNameDelete = (userid, folderChildId) => {
    const foundItem = newArray.find((item) => item.TestcasesModel?.id === folderChildId);
    const parentID = foundItem?.id;
    const duplicateLength = newArray.filter((obj) => obj.id === parentID).length;
    if (duplicateLength === 1) {
      // setModalOpen(false);
      setSnack('you can not delete this Child item, folder having only one child', 'success');
    } else {
      setModalOpen(true);
      setDeletedChildId(folderChildId);
    }
  };
  const handleFolderChildNameDownload = (maindata) => {
    const data = {
      function: maindata?.testcases,
      testCases: maindata?.testcases_result?.output,
    };
    const jsonData = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });

    saveAs(blob, `${maindata?.testcase_name}.json`);
  };

  const oldChild = child
    .filter((item, index, self) => self.findIndex((el) => el.id === item.id) === index)
    .map((item) => ({ id: item.id, folder_name: item.folder_name }));
  // New function for downloading the parent folder and its children as a zip file
  const handleFolderNameDownload = async (folderId, fName) => {
    const folderData = newArray
      .filter((item) => item.id === folderId)
      .map((item) => item.TestcasesModel);

    // console.log('folderData', folderData);
    // Create a new instance of JSZip
    const zip = new JSZip();

    // Add JSON data with only the required properties to the zip
    folderData.forEach((testcase, index) => {
      if (testcase) {
        // Append the index to the filename to make it unique
        const fileName = `${testcase.testcase_name}_${index}.json`;
        const fileContent = {
          function: testcase.testcases,
          testcases: testcase.testcases_result,
        };
        const jsonData = JSON.stringify(fileContent, null, 2);
        zip.file(fileName, jsonData);
      }
    });

    // Generate the zip file and trigger download
    zip.generateAsync({ type: 'blob' }).then((blob) => {
      saveAs(blob, `folder_${fName}.zip`);
    });
  };
  const cancelEditingChild = () => {
    setEditingChildIndex(-1);
  };
  const cancelFolderEditingChild = () => {
    setEditingIndex(-1);
  };

  return (
    <div>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.leftNavigation}>
          <List component="nav" className={classes.ListMain}>
            {oldChild.map((item, index) => (
              <React.Fragment key={item.id}>
                <ListItem
                  id={item.id}
                  button
                  onClick={() => handleClick(index, item.id)}
                  // className={`${classes.listItem45} ${selected === index ? 'selected' : ''}`}
                  className={`${classes.listItem45} ${selected === index ? 'selected' : ''}`}
                  // selected={folderIDCreated === item.id}
                >
                  {editingIndex === index ? (
                    // <Input
                    //   // value={child[index].folder_name}
                    //   style={{ color: 'white' }}
                    //   value={editedFolderName}
                    //   onChange={(event) => setEditedFolderName(event.target.value)}
                    //   onBlur={() => handleFolderNameBlur(index, item.id)}
                    //   autoFocus
                    // />
                    <>
                      <Input
                        value={editedFolderName}
                        onChange={(event) => {
                          setEditedFolderName(event.target.value);
                          event.stopPropagation();
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        autoFocus
                        style={{ color: 'white' }}
                      />
                      <div style={{ display: 'flex', marginRight: -37 }}>
                        <Button
                          // size="small"
                          onClick={(event) => {
                            handleFolderNameBlur(index, item.id);
                            event.stopPropagation();
                          }}
                          style={{
                            color: 'white',
                            backgroundColor: '#52d318',
                            padding: 2,
                            // marginLeft: 5,
                            marginRight: 5,
                            minWidth: '48px',
                            height: '24px',
                            fontSize: 'revert',
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          style={{
                            color: 'white',
                            backgroundColor: '#002A45',
                            padding: 5,
                            minWidth: '48px',
                            height: '24px',
                            fontSize: 'revert',
                          }}
                          onClick={(event) => {
                            cancelFolderEditingChild();
                            event.stopPropagation();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <ListItemText
                        title={item.folder_name}
                        primary={`${item.folder_name.substring(0, 7)}...`}
                        // onClick={() => handleFolderNameClick(index)}
                      />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          width: '100%',
                        }}
                      >
                        <ListItemIcon style={{ minWidth: 0 }}>
                          <EditOutlinedIcon onClick={() => handleFolderNameClick(index)} />
                        </ListItemIcon>
                        <ListItemIcon style={{ minWidth: 0 }}>
                          <DeleteForeverIcon
                            onClick={() => handleFolderNameDelete(user_id, item.id)}
                          />
                          {/* <DownloadOutlinedIcon
                            title="Download Folder"
                            onClick={() => handleFolderNameDownload(item.id, item.folder_name)}
                          /> */}
                        </ListItemIcon>

                        <ListItemIcon style={{ minWidth: 0 }} title="Download Folder">
                          <DownloadOutlinedIcon
                            onClick={() => handleFolderNameDownload(item.id, item.folder_name)}
                          />
                        </ListItemIcon>
                      </div>
                    </>
                  )}
                  {openedItemId === item.id ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <>
                  <Collapse
                    in={openedItemId === item.id && openedItemId !== null}
                    timeout="auto"
                    unmountOnExit
                  >
                    {newArray.map((childf, i) => {
                      if (childf.id === item.id && childf.TestcasesModel !== null) {
                        return (
                          <List component="div" disablePadding className={classes.ListSubMain}>
                            <ListItem
                              button
                              key={childf.id}
                              // className={classes.ListsubItemInside}
                              className={`${classes.ListsubItemInside} ${
                                selectedChild === i ? 'selected' : ''
                              }`}
                              onClick={() => {
                                handleList(childf?.TestcasesModel, i, childf?.folder_name);
                                handleClickChild(i);
                              }}
                            >
                              {editingChildIndex === i ? (
                                // <Input
                                //   // value={child[index].folder_name}
                                //   value={editedFolderChildName}
                                //   onChange={(event) => {
                                //     setEditedChildFolderName(event.target.value);
                                //     event.stopPropagation();
                                //   }}
                                //   onBlur={() =>
                                //     handleFolderChildNameBlur(i, childf.TestcasesModel.id)
                                //   }
                                //   onKeyDown={(event) => {
                                //     if (event.key === 'Enter') {
                                //       handleFolderChildNameBlur(i, childf.TestcasesModel.id);
                                //     }
                                //   }}
                                //   autoFocus
                                //   style={{ color: 'white' }}
                                // />
                                <>
                                  <Input
                                    value={editedFolderChildName}
                                    onChange={(event) => {
                                      setEditedChildFolderName(event.target.value);
                                      event.stopPropagation();
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                    }}
                                    autoFocus
                                    style={{ color: 'white' }}
                                  />
                                  <div style={{ display: 'flex', marginRight: -13 }}>
                                    <Button
                                      // size="small"
                                      onClick={(event) => {
                                        handleFolderChildNameBlur(i, childf.TestcasesModel.id);
                                        event.stopPropagation();
                                      }}
                                      style={{
                                        color: 'white',
                                        backgroundColor: '#52d318',
                                        padding: 2,
                                        // marginLeft: 5,
                                        marginRight: 5,
                                        minWidth: '48px',
                                        height: '24px',
                                        fontSize: 'revert',
                                      }}
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      style={{
                                        color: 'white',
                                        backgroundColor: '#d62121',
                                        padding: 5,
                                        minWidth: '48px',
                                        height: '24px',
                                        fontSize: 'revert',
                                      }}
                                      onClick={(event) => {
                                        cancelEditingChild();
                                        event.stopPropagation();
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      width: '100%',
                                    }}
                                  >
                                    <div>
                                      <ListItemText
                                        // name="itsChild"
                                        // primary={childf?.TestcasesModel?.testcase_name}
                                        title={childf?.TestcasesModel?.testcase_name}
                                        primary={`${childf?.TestcasesModel?.testcase_name.substring(
                                          0,
                                          7
                                        )}...`}
                                      />
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                      <ListItemIcon style={{ minWidth: 0 }}>
                                        <EditOutlinedIcon
                                          onClick={(event) => {
                                            handleFolderChildNameClick(i);
                                            event.stopPropagation();
                                          }}
                                        />
                                      </ListItemIcon>
                                      <ListItemIcon style={{ minWidth: 0 }}>
                                        <DeleteForeverIcon
                                          onClick={() =>
                                            handleFolderChildNameDelete(
                                              user_id,
                                              childf.TestcasesModel.id
                                            )
                                          }
                                        />
                                      </ListItemIcon>
                                      <ListItemIcon style={{ minWidth: 0 }}>
                                        <DownloadOutlinedIcon
                                          onClick={() =>
                                            handleFolderChildNameDownload(childf?.TestcasesModel)
                                          }
                                        />
                                      </ListItemIcon>
                                    </div>
                                  </div>
                                </>
                              )}
                            </ListItem>
                          </List>
                        );
                      }

                      return null;
                    })}
                  </Collapse>
                </>
              </React.Fragment>
            ))}
          </List>
        </Grid>
      </Grid>

      {modalOpen && (
        <ModalConfirm
          open={modalOpen}
          onConfirm={() => {
            TestGenfolderChildDelete(user_id, deletedChildId)
              .then((f: any) => {
                folderDeletedId(deletedChildId);
              })
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {
                setModalOpen(false);
                setSnack('Folder child deleted successfully', 'success');
              });
          }}
          onCancel={() => {
            setModalOpen(false);
          }}
          label="isDeleteChildTitle"
        />
      )}
      {modalFolderOpen && (
        <ModalConfirm
          open={modalFolderOpen}
          onConfirm={() => {
            TestGenfolderDelete(user_id, deletedFolderId)
              .then((f: any) => {
                folderDeletedId(deletedFolderId);
              })
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {
                setModalFolderOpen(false);
                setSnack('Folder deleted successfully', 'success');
              });
          }}
          onCancel={() => {
            setModalFolderOpen(false);
          }}
          label="isDeleteFolderTitle"
        />
      )}
    </div>
  );
};

export default GeneratorLeftNavigation;
