import { DataTypeProvider } from '@devexpress/dx-react-grid';
import React from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Card } from '@material-ui/core';
import { LangCode } from '../../../../../components/Datagrid/TranslationList';

type Props = {
  dataTable: DataTypeProvider.ValueFormatterProps;
  translationCode?: LangCode;
  toggleHeight?: boolean;
};
/**
 *
 * @param translationCode optional
 * @returns
 */
const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    // maxWidth: 1500,
    backgroundColor: '#002A45',
    border: '1px solid black',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: 1,
    transition: 'opacity 1s',
    '& .MuiTooltip-arrow': {
      color: '#eef1f9',
      '&:before': {
        backgroundColor: '#002A45',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid black`,
        fontSize: 50,
      },
    },
  },
});
const RenderCellExcel = ({ dataTable, translationCode, toggleHeight }: Props) => {
  const { value, row } = dataTable;

  let text2display = value;

  // only for column with i18n
  if (translationCode) {
    try {
      const { i18n } = row;
      text2display = i18n[translationCode];
    } catch (error) {
      text2display = value;
    }
  }

  return (
    <CustomWidthTooltip
      placement="bottom-start"
      arrow
      // open={true}
      title={
        <>
          <Card
            style={{
              background: 'while',
              color: '#002A45',
              fontWeight: 'bold',
              margin: 0,
              padding: 5,
            }}
          >
            {value}
          </Card>
        </>
      }
    >
      <span
        style={{
          textDecoration: 'none',
          whiteSpace: toggleHeight ? 'pre-line' : 'inherit',
        }}
      >
        {value}
      </span>
    </CustomWidthTooltip>
  );
};

export default RenderCellExcel;
