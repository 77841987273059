import React from 'react';
import convertToPairs from '../utils/convertToPairs';
import extractOriginalLang from '../utils/extractOriginalLang';

function useTranslationCodeChange(props) {
  const { rowObj, onChangeTableData, langCheck, languagesCode, targetColumn } = props;

  const rows = Object.values(rowObj);

  const updateRowLang = (code: string) => {
    try {
      if (rows.length > 0) {
        const { i18n } = rows[0] || {};
        if (Object?.keys(i18n)?.length === 1) {
          return;
        }
        const { OriginalLang } = extractOriginalLang(i18n, langCheck, languagesCode);
        if (OriginalLang !== undefined && code === '-') {
          const temp = rows.map((data: any) => {
            return {
              ...data,
              extractedText: data?.i18n[OriginalLang] ?? data.extractedText,
            };
          });

          const objDAta = convertToPairs(temp);
          // setRowsEdited(temp);
          onChangeTableData(objDAta);
        } else {
          const temp = rows.map((r) => {
            if (r?.i18n !== undefined && r?.i18 !== null && r?.i18n[code || 'en']) {
              return {
                ...r,
                extractedText: r?.i18n[code] ?? data.extractedText,
              };
            }
            return r;
          });
          const objDAta = convertToPairs(temp);
          // setRowsEdited(temp);
          onChangeTableData(objDAta);
        }
      }
    } catch (error) {
      console.log('Error in updateRowLang', error);
    }
  };

  return { updateRowLang };
}

export default useTranslationCodeChange;
