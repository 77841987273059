import { AppBar, Box, Grid, List, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline, ListItemButton, ListItemText, Paper } from '@mui/material';
import React, { useState } from 'react';
import AttributeTable from './AttributeTable';
import IQualimeterAcronymTable from './IQualimeterAcronymTable';
import IQualimeterWordGroupTable from './IQualimeterWordGroupTable';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  table: {
    minWidth: 650,
  },

  snackbar: {
    bottom: '104px',
  },
  active: {
    backgroundColor: 'red',
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: 'white',
    },
  },
  // selected: {
  //   '&.Mui-selected': {
  //     backgroundColor: 'red',
  //   },
  // },
  bloc: {
    minHeight: 250,
    borderRadius: 10,
    border: 'solid 1px;',
    borderColor: theme.palette.primary.light,
    margin: 10,
  },
  error: {
    color: theme.palette.error.main,
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
  },
  treeitem: {
    width: '95%',
    marginTop: '2rem',
  },
  treeLabel: {
    borderRadius: '13px',
    paddingLeft: 10,
    backgroundColor: theme.palette.primary.light,
    color: 'white',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  paper: {
    color: theme.palette.primary.main,
    position: 'absolute',
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 4, 3),
    textAlign: 'center',
    borderRadius: '10px',
    opacity: 1,
    marginTop: 60,
  },
  input: {
    marginBottom: '15px',
  },
  error: {
    color: theme.palette.error.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
  paper2: {
    color: theme.palette.primary.main,
    position: 'absolute',
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 4, 3),
    textAlign: 'center',
    borderRadius: '10px',
    opacity: 1,
    marginTop: 60,
    paddingBottom: 10,
  },
  input2: {
    marginBottom: '15px',
    background: '#0000000B 0% 0% no-repeat padding-box',
    borderRadius: '4px 4px 0px 0px',
  },
  error2: {
    color: theme.palette.error.main,
  },
  button2: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
  projectName: {
    display: 'flex',
    justifyContent: 'center',
  },
  entity: {
    textAlign: 'left',
    border: '1px solid #707070',
    borderRadius: '10px',
    padding: 10,
    color: '#002A45',
  },
  type: {
    textAlign: 'left',
    border: '1px solid #707070',
    borderRadius: '10px',
    padding: 10,
  },
  input6: {
    // '&:invalid': {
    //   border: 'red solid 2px',
    // },
  },
  root6: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),

    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '300px',
    },
    '& .MuiButtonBase-root': {
      margin: theme.spacing(2),
    },
  },
  noBorder: {
    border: 'none',
  },
  ListItemBg: {
    '& .css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root.Mui-selected': {
      backgroundColor: '#DC3223 !important',
    },
    // '& .MuiList-padding': {
    //   paddingTop: '0px !important',
    // },
  },
  iconButtonNoBorder: {
    padding: '8px',
  },
  iconButtonInner: {
    color: 'white',
    fontSize: '1.5rem',
  },
}));

const IQualimeterAttributeContainer = ({ projectId }) => {
  const classes = useStyles();
  const [attributes, setAttributes] = useState<any>('Acronyms');
  // selectedIndex in List for Navigation
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [cancelValue, setCancelValue] = React.useState(true);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setAttributes(event?.target?.textContent);
    setSelectedIndex(index);
    setCancelValue(true);
  };

  return (
    <>
      <Paper style={{ marginTop: 10 }}>
        <Grid container spacing={2} style={{ minHeight: '50vh' }}>
          <Grid item xs={3}>
            <div
              style={{
                minHeight: '80vh',
                border: '1px solid #002A45',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: '5px 5px 0px 5px',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="relative" style={{ paddingTop: 5, paddingBottom: 5 }}>
                  <center>
                    <Typography variant="h6" noWrap component="div">
                      Attributes
                    </Typography>
                  </center>
                </AppBar>
                <List
                  component="nav"
                  aria-label="main mailbox folders"
                  disablePadding
                  className={classes.ListItemBg}
                >
                  <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={(event) => handleListItemClick(event, 0)}
                  >
                    <ListItemText primary="Acronyms" style={{ textAlign: 'center' }} />
                  </ListItemButton>
                  <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={(event) => handleListItemClick(event, 1)}
                  >
                    <ListItemText primary="Word Groups" style={{ textAlign: 'center' }} />
                  </ListItemButton>
                </List>
              </Box>
            </div>
          </Grid>
          {cancelValue === true ? (
            <>
              <Grid item xs={8}>
                {attributes === 'Acronyms' && (
                  <IQualimeterAcronymTable projectId={projectId} name="Acronyms" />
                )}
                {attributes === 'Word Groups' && (
                  <IQualimeterWordGroupTable projectId={projectId} name="Word Groups" />
                )}
              </Grid>
            </>
          ) : null}
        </Grid>
      </Paper>
    </>
  );
};

export default IQualimeterAttributeContainer;
