import React, { memo, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Button, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const RejectCompliance = (props) => {
  const { disableReject, currentRejectValue, setCurrentRejectValue, handleReject, loading } = props;

  const handleRejectValueChange = (event) => {
    setCurrentRejectValue(event.target.value);
  };

  return (
    <>
      <Grid container xs={12} sm direction="column">
        <Grid item xs={6}>
          <FormControl color="info" variant="standard" size="small" sx={{ m: 1, width: '80%' }}>
            <InputLabel id="demo-simple-select-standard-label">Reject Reason:</InputLabel>
            <Select
              disabled={disableReject}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={currentRejectValue}
              onChange={handleRejectValueChange}
              label="Reject Reasons"
              size="small"
            >
              <MenuItem value="" selected disabled>
                -select one reason-
              </MenuItem>
              <MenuItem value="Out of scope">Out of scope</MenuItem>
              <MenuItem value="Not only in my scope">Not only in my scope</MenuItem>
              <MenuItem value="Unclear">Unclear</MenuItem>
              <MenuItem value="Not a Requirement">Not a Requirement</MenuItem>
            </Select>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              // startIcon={loading ? <CircularProgress size={16} sx={{ color: 'white' }} /> : ''}
              disabled={disableReject}
              onClick={() => handleReject()}
              style={{ float: 'left', marginTop: '5px', width: '50%' }}
            >
              Reject
            </Button>
          </FormControl>
        </Grid>

        {/* <Grid item xs={6}>
          
        </Grid> */}
      </Grid>
    </>
  );
};

export default memo(RejectCompliance);
