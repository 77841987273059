import React, { useContext, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  ClickAwayListener,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import PublishIcon from '@mui/icons-material/Publish';
import axios from 'axios';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import useAppToken from '../../../../../../app/useAppToken';
// import WorkSpaceContext from '../../../../WorkspceContext';
import formatBytes from '../../../../../../tools/formatBytes';
import { BACKEND_URL } from '../../../../../../const';
import WorkSpaceContext from '../../../../WorkspceContext';

const UploadButton = () => {
  const inputRef = useRef();
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;
  const { setSnack, files, setFiles } = useContext(WorkSpaceContext);
  const [iconLoad, setIconload] = useState(false);

  // eslint-disable-next-line consistent-return
  const uploadURLCall = async (uploadUrl: any, formdata: FormData) => {
    try {
      const uplResponse = await axios.post(uploadUrl, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return uplResponse.data;
      // setFiles(allResources);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeFiles = (event: any) => {
    try {
      const len = Object?.keys(event)?.length;
      const arraylist = [];
      for (let i = 0; i < len; i += 1) {
        arraylist.push(event[i]);
      }
      for (const eachFile of arraylist) {
        const ext = eachFile?.name?.split('.');
        if (!['xlsx', 'csv', 'pdf', 'docx'].includes(ext[ext?.length - 1])) {
          setSnack(`${eachFile.name} not supported`, 'error');
          return;
        }
        if (eachFile.name?.length >= 80) {
          setSnack('file name should be less than 80 characters', 'error');
          return;
        }
      }
      const formdata = new FormData();
      const names = arraylist.map((e: any) => {
        formdata.append('files', e);
        return e.name;
      });
      for (const eachFile of files) {
        if (names.includes(eachFile.filename)) {
          setSnack(`File name ${eachFile.filename} already exists.`, 'error');
          return;
        }
      }
      if (formdata.get('files')) {
        setIconload(true);
        const uploadUrl = BACKEND_URL.uploadUserDoc.replace('userId', user_id);

        uploadURLCall(uploadUrl, formdata).then((result) => {
          if (result) {
            const { resources } = result[0];
            const newUploadfile = resources.filter((data: any) => {
              return names.includes(data.filename);
            });
            const NewData = newUploadfile.map((data) => {
              const obj = {
                ...data,
                size: formatBytes(Number(data.size), 0),
                id: data.etag,
                folderName: 'user',
                folderId: 0,
                isNew: true,
              };
              return obj;
            });
            setFiles((prev: any) => {
              return [...NewData, ...prev];
            });
            setIconload(false);
            setSnack('Successfully Uploaded', 'success');
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const filehandleChangeFiles = (event: any) => {
    const e = event?.target?.files || '';
    handleChangeFiles(e);
  };

  const UploadFile = () => {
    inputRef.current.click();
  };

  return (
    <>
      <Box style={{ position: 'absolute', right: '1rem', top: '-0.5rem' }}>
        <input type="file" multiple ref={inputRef} hidden onChange={filehandleChangeFiles} />
        <Button
          variant="contained"
          color="primary"
          onClick={UploadFile}
          startIcon={
            iconLoad ? <CircularProgress style={{ color: 'white' }} size={20} /> : <PublishIcon />
          }
          // style={{ position: 'absolute', right: '14px', top: '-90px' }}
        >
          Upload
        </Button>
      </Box>
    </>
  );
};

export default UploadButton;
