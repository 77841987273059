import React from 'react';

import StarIcon from '@mui/icons-material/Star';
import { Box, Chip, Grid, Tooltip } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import formatDate, { formatLocal } from '../../../../../../../tools/formatDate';
import { lifeCycle } from '../../const/allocatorConst';

export const statusLifecyle = {
  assign: 'assigned',
  reject: 'rejected',
  accept: 'owned',
  analyze: 'analyzed',
  capture: 'captured',
} as any;

const RenderLifeCycleMulti = ({ lifecycleLabel, receiver, user_id, lifecyclesIs, parentId }) => {
  let lifecycleStar = null;

  if (Number(receiver) === Number(user_id)) {
    lifecycleStar = (
      <Grid container direction="row" alignItems="center" justify="center">
        <div style={{ display: 'flex' }}>
          {lifecycleLabel}
          <StarIcon fontSize="small" />
        </div>
      </Grid>
    );
  }

  return (
    <Box style={{ display: 'flex', gap: '5px' }}>
      <Chip
        label={lifecycleStar ?? lifecycleLabel}
        title={lifecycleLabel}
        // className={classes.kpiChip}
        style={{ backgroundColor: lifeCycle[lifecycleLabel], color: 'white', width: '115px' }}
      />
      {/* {allocation?.id} */}
      <Tooltip
        color="info"
        title={lifecyclesIs?.map((val: any, index: number) =>
          parentId === val?.allocation_id ? (
            <p key={`lc-hist-${index}`}>
              {/* Id: {val?.allocation_id}<br />  */}
              {formatDate(val.created_at, formatLocal())} :{' '}
              <b>{statusLifecyle[val.action]?.toUpperCase()}</b>{' '}
              {val.action === 'reject' && (
                <>
                  <br />
                  {val.content}
                </>
              )}
            </p>
          ) : (
            ''
          )
        )}
        // classes={{ tooltip: classes.customWidth }}
      >
        <TimelineIcon />
      </Tooltip>
    </Box>
  );
};

export default RenderLifeCycleMulti;
