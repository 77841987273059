import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoginPage from '../containers/Login/LoginPage';
import LoginTestPage from '../containers/Login/LoginTestPage';
import './App.css';
import { isensTheme } from './isensTheme';

const AppLogin = () => (
  <ThemeProvider theme={isensTheme}>
    <CssBaseline />
    <Router>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/test" component={LoginTestPage} />
        <Route exact path="*" component={LoginPage} />
      </Switch>
    </Router>
  </ThemeProvider>
);

export default AppLogin;
