import { Checkbox, Dialog, IconButton, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import React, { useContext, useEffect } from 'react';
import { addUsersToProject, searchUsers } from '../../../api/users';
import Loading from '../../../components/Core/Loading';
import { OptionItem, Project } from '../../../react-app-env';
import ProjectContext from '../ProjectContext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  pdfPanel: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    margin: 10,
    padding: '0px !important',
    minHeight: 100,
  },
  heading: {
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
  },
}));

type Props = {
  projectId: Number;
  open: boolean;
  onClose?: any;
  onAddUsers?: any;
};

export default function ModalAddUsers({ projectId, open, onClose, onAddUsers }: Props) {
  const classes = useStyles();
  const { setSnack } = useContext(ProjectContext);
  const [selectedUsers, setSelectedUsers] = React.useState<OptionItem[]>([]);
  const [nonProjectUsers, setNonProjectUsers] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setSelectedUsers([]);
    setLoading(true);
    searchUsers({ project_id_out: projectId }).then((dbusers: any[]) => {
      setNonProjectUsers(dbusers);
      setLoading(false);
    });
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleSelectUsers = (optionsSelected: OptionItem[]) => {
    // console.log(optionsSelected);
    setSelectedUsers(optionsSelected);
  };

  const handleAddToProject = () => {
    const selectedUserIds = selectedUsers.map((selectedName: OptionItem) => {
      return Number(selectedName.value);
    });
    addUsersToProject(projectId, selectedUserIds).then((resp: ProjectUser[]) => {
      onAddUsers(resp);
      setSnack('Users added');
      handleClose();
    });
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="h6">Add Users to Project</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Autocomplete
                multiple
                id="auto-complete-users"
                options={nonProjectUsers.map((user) => ({
                  label: user.name,
                  value: user.id,
                }))}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </>
                )}
                getOptionSelected={(option: OptionItem, value: OptionItem) => {
                  return option.value === value.value;
                }}
                value={selectedUsers}
                style={{ width: 500, minHeight: 100 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Search by user name"
                    placeholder="Search by user name"
                  />
                )}
                onChange={(event, newValue) => {
                  handleSelectUsers(newValue);
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleAddToProject} color="primary" variant="contained">
            Add to Project
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
