// eslint-disable-next-line import/no-duplicates
import { Button, CardContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PublicIcon from '@mui/icons-material/Public';
import { Box, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
// eslint-disable-next-line import/no-duplicates
import { Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import React, { Props } from 'react';
import { Link } from 'react-router-dom';
import aboutImage from '../../assets/user_info_bg.png';
import { APP_VERSION, ENV } from '../../const';

export const useStyles = makeStyles((theme) => ({
  resultIcon: {
    color: '#002A45',
    transform: 'scale(1.5)',
    opacity: 1,
    marginRight: 4,
  },
  root: {
    // margin: 0,
    padding: 0,
    '&.MuiDialogContent-root': {
      padding: 0,
    },
    '&.MuiDialog-paperFullWidth': {
      width: '50%',
    },
  },
  dialogPaperFullWidth: {
    width: '40%',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
  },
  rootChildDivider: {
    padding: '0px !important',
    borderTop: 'none !important',
    borderBottom: 'none !important',
  },
  closeButton: {
    // top: 30px;
    // color: #002A45;
    // right: 29px;
    // position: absolute;
    // border: 2px white solid;
    // padding: 0px;
    // background: white;
    position: 'absolute',
    right: 0,
    top: 0,
    color: '#002A45',
    border: '2px white solid',
    padding: 0,
    background: 'white',
    // '&.MuiIconButton-root:hover': {
    //   background: 'none',
    // },
    '&:hover': {
      // backgroundColor: 'red', // Background color on hover
      // color: 'white', // Text color on hover
      position: 'absolute',
      right: 0,
      top: 0,
      color: '#002A45',
      border: '2px white solid',
      padding: 0,
      background: 'white',
    },
  },
  heading: {
    backgroundImage: `url(${aboutImage})`,
    // backgroundSize: '50% auto',
    height: 200,
    // width: 100,
    // borderRadius: 8,
    // // textAlign: 'center',
    // marginBottom: 10,
    // color: 'white',
    backgroundSize: '35% auto', // Ensure the image covers the entire Card
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
    backgroundPosition: 'left',
  },
  iconButtonInner: {
    color: 'white',
    fontSize: '50px !important',
  },
  circularIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px', // Adjust the width and height as needed to make it circular
    height: '100px', // Make sure width and height are the same for a circle
    borderRadius: '50%', // Sets the border radius to 50% to make it circular
    backgroundColor: '#DC3223', // Change the background color as needed
  },
  typo1: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 20,
  },
  typo2: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 17,
  },
  typo3: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 15,
  },
}));

const AboutIsens = ({ open, onCancel }: Props) => {
  const classes = useStyles();
  const token = JSON.parse(localStorage.getItem('azJWT') || '{}');
  return (
    <>
      <Dialog
        onClose={onCancel}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth={true}
        className={classes.root}
        classes={{
          paperFullWidth: classes.dialogPaperFullWidth,
        }}
      >
        <IconButton aria-label="close" className={classes.closeButton} onClick={onCancel}>
          <CancelOutlinedIcon />
        </IconButton>
        {/* <DialogTitle id="customized-dialog-title">
          <Typography variant="h6">.</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </DialogTitle> */}
        <DialogContent dividers className={classes.rootChildDivider}>
          {/* <Typography variant="subtitle1" className={classes.heading}>
            HI
          </Typography> */}
          <Card>
            <div className={classes.heading}>
              <CardContent
                style={{
                  // borderRadius: '11px 0px 135px 0px',
                  // borderTopRightRadius: '50%',
                  background:
                    'transparent linear-gradient(138deg, rgba(58, 106, 137, 0.7) 0%, rgba(17, 58, 85, 1) 100%) 0% 0% no-repeat padding-box',
                  height: 200,
                  // textAlign: 'center',
                }}
              >
                <Grid
                  container
                  spacing={0}
                  style={{ marginTop: 0, textAlign: 'center' }}
                  justifyItems="center"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    {/* <div className={classes.circularIcon}>
                      <PersonOutlineOutlinedIcon className={classes.iconButtonInner} />
                    </div> */}
                  </Grid>
                  <Grid item xs={9}>
                    <table>
                      {/* <tr>
                        <th>Company</th>
                        <th>Contact</th>
                        <th>Country</th>
                      </tr> */}
                      <tr>
                        <td>
                          <Typography className={classes.typo1}>iSenS Version</Typography>
                        </td>
                        <td>
                          <Typography className={classes.typo1}>: &nbsp;4.0.0</Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography className={classes.typo1}>UI Build</Typography>
                        </td>
                        <td>
                          <Typography className={classes.typo1}>
                            : &nbsp;
                            {ENV === 'production' ? (
                              <>{APP_VERSION}</>
                            ) : (
                              <Link
                                to="/version"
                                style={{ color: 'white', textDecorationColor: 'blue' }}
                              >
                                {APP_VERSION}
                              </Link>
                            )}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography className={classes.typo1}>
                            Smart Requirement Management
                          </Typography>
                        </td>
                        <td>
                          <Typography className={classes.typo1}>: &nbsp;V24</Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography className={classes.typo1}>
                            knowledge and efficiency
                          </Typography>
                        </td>
                        <td>
                          <Typography className={classes.typo1}>: &nbsp;V24</Typography>
                        </td>
                      </tr>
                      {/* <tr>
                        <td>
                          <Typography className={classes.typo1}>iSearcher</Typography>
                        </td>
                        <td>
                          <Typography className={classes.typo1}>: &nbsp;V24</Typography>
                        </td>
                      </tr> */}
                      <tr>
                        <td>
                          <Typography className={classes.typo1}>Rex</Typography>
                        </td>
                        <td>
                          <Typography className={classes.typo1}>
                            &nbsp;&nbsp;&nbsp;: &nbsp;V3.0.0
                          </Typography>
                        </td>
                      </tr>
                    </table>
                    {/* <br /> */}
                    {/* <Typography className={classes.typo3}>
                      {token?.account.idTokenClaims.preferred_username}
                    </Typography> */}
                  </Grid>
                </Grid>
              </CardContent>
            </div>
            <Grid
              container
              spacing={0}
              // style={{ marginTop: 10 }}
              justifyItems="center"
              alignItems="center"
              style={{ background: 'rgba(49, 96, 128, 1) 0% 0% no-repeat padding-box' }}
            >
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    // justifyContent: 'space-around',
                    alignItems: 'center',
                    padding: 10,
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      color: 'white',
                      opacity: 1,
                      fontWeight: 'bold',
                      fontSize: 16,
                      // font: 'normal normal medium 25px/33px Alstom',
                      // marginTop: 5,
                    }}
                  >
                    Copyright © Alstom SA 2024.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Card>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={onCancel} color="default" variant="contained">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="secondary" variant="contained">
            Confirm
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

export default AboutIsens;
