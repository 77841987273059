import { DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import MarkdownContent from './MarkdownContent';
import { CitationType } from './chat';

const useStyles = makeStyles((theme) => ({
  topTitle: {
    background: theme.palette.primary.main,
    color: 'white  !important',
    '&.MuiDialogTitle-root': {
      padding: '6px 12px',
    },
  },
  contentMessage: {
    wordBreak: 'break-word',
  },
}));

type Props = {
  doc: CitationType;
  onCloseCitation: () => void;
};

function Citation({ doc, onCloseCitation }: Props) {
  const classes = useStyles();
  const title = doc?.filepath?.split('.').slice(0, -1).join('') || 'Citation';
  return (
    <div
      style={{
        maxHeight: '100%',
        minHeight: '100%',
        overflowY: 'auto',
      }}
    >
      <DialogTitle className={classes.topTitle}>
        <IconButton aria-label="close" onClick={onCloseCitation}>
          <CloseIcon style={{ color: 'white' }} />
        </IconButton>
        {title}
      </DialogTitle>
      <DialogContent className={classes.contentMessage}>
        <MarkdownContent content={doc.content} />
      </DialogContent>
    </div>
  );
}

export default Citation;
