import axios from './axios';
import { BACKEND_URL } from '../const';

export const getVersion = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/version`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export default getVersion;
