// /version/get
import { BACKEND_URL } from '../const';
import axios from './axios';

const getFrontendVersion = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/version/get`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }

  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       resolve('1.1.8');
  //     }, 2000);
  //   });
};

export default getFrontendVersion;
