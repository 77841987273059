import React from 'react';
import CheckIcon from '@mui/icons-material/Check';

const RenderGapOrNC = ({ compliance }) => {
  const renderCompliance = () => {
    switch (compliance) {
      case 'nc':
        return (
          <>
            <CheckIcon />
          </>
        );

      case 'gap':
        return (
          <>
            <CheckIcon />
          </>
        );

      default:
        return null;
    }
  };

  return <>{renderCompliance()}</>;
};

export default RenderGapOrNC;
