import React, { useEffect, useState } from 'react';
import { getOwning } from '../../../../../../api/ownings';
import { workloadProjectStatus } from '../../../../../../api/workloads';
import convertToPairs from '../utils/convertToPairs';
import extractionTextCode from '../utils/extractionTextCode';
import extractOriginalLang from '../utils/extractOriginalLang';
import useProjectData from './useProjectData';

type Props = {
  projectId: any;
  workloadId: any;
  filename: any;
  type: any;
  langCheck: any;
  languagesCode: any;
  translationCode: any;
  setTranslationCode: any;
  setMetaDAta: any;
  setI18n: any;
  targetColumn: any;
  groups?: any;
  users?: any;
  dispatch: any;
  setActionForFile: any;
  actionForFile: any;
  onChange: any;
  displayColumns: any;
};

function useLoadResponse(props: Props) {
  const {
    projectId,
    workloadId,
    filename,
    type,
    langCheck,
    languagesCode,
    translationCode,
    setTranslationCode,
    setMetaDAta,
    setI18n,
    targetColumn,
    dispatch,
    setActionForFile,
    actionForFile,
    groups,
    users,
    onChange,
    displayColumns,
  } = props;

  const [dataLoading, setDataLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const isAnalysis = ['analysis'].includes(type);
  const service = ['analysis', 'assignment'].includes(type) ? 'assignment' : type;

  let intervalStatus: number;
  let intervalCallApI: number;

  const refreshDATA = (refresh = false) => {
    if (refresh) setRefreshLoading(true);
    getOwning(
      projectId,
      Number(workloadId),
      filename,
      undefined,
      true, // add allocations
      true, // add lifecycle
      isAnalysis,
      service
    ).then((response: any) => {
      const IdRows = {};

      if (response[0].row_capture && 'allocations' in response[0]) {
        dispatch({ type: 'assignment' });
        dispatch({ type: 'characterizer' });
        dispatch({ type: 'capture' });
        setActionForFile('allocator');
      }

      if (response[0]?.row_capture) {
        const { i18n } = response[0]?.row_capture || {};
        setI18n(i18n);
        const { OriginalLang, transCode } = extractOriginalLang(i18n, langCheck, languagesCode);
        const tempLang = !translationCode ? transCode : translationCode;
        const responseArr = extractionTextCode(
          response,
          targetColumn,
          OriginalLang,
          tempLang,
          groups,
          users,
          isAnalysis,
          displayColumns
        );
        onChange(responseArr);
        const keyPairValues = convertToPairs(responseArr);
        setTranslationCode(tempLang);

        if (refresh) setRefreshLoading(false);
        else setDataLoading(true);
        setMetaDAta(keyPairValues);
        clearInterval(intervalCallApI);
      }
    });
  };

  const ProjectStatus = () => {
    workloadProjectStatus(projectId, workloadId).then((data) => {
      setPercentage(data[0]?.progress_percent);
      if (data[0]?.action === 'allocator' && data[0]?.progress_percent >= 100) {
        clearInterval(intervalStatus);
        refreshDATA();
      }
    });
  };

  useEffect(() => {
    if (['allocator'].includes(actionForFile)) {
      refreshDATA();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    intervalStatus = setInterval(ProjectStatus, 2000);
    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(intervalStatus);
    };
  }, []);

  return { dataLoading, percentage, refreshDATA, refreshLoading };
}

export default useLoadResponse;
