/* eslint-disable react/destructuring-assignment */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import MultiSelect from 'react-multi-select-component';
import { useHistory, useParams } from 'react-router-dom';
import { Workload3Create } from 'WorkloadType';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import { fileDetails, iCaptureCsv, iCaptureExcel } from '../../../../../api/module1';
import {
  workloadCreate,
  workloadGetById,
  workloadRunPost,
  workloadRunPostPDF,
} from '../../../../../api/workloads';
import ExcelDoc from '../../../../../assets/icons8-excel-48.png';
import PptDoc from '../../../../../assets/icons8-powerpoint-48.png';
import DocxDoc from '../../../../../assets/icons8-microsoft-word-2019-48.png';
import PdfDoc from '../../../../../assets/pdf_ins.png';
import CsvDoc from '../../../../../assets/csv_icons.png';
import docIcon from '../../../../../assets/docx_ico.png';
import pdfIcon from '../../../../../assets/pdf_ico.png';
import xlsIcon from '../../../../../assets/xlsx_ico.png';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import TStyles, { rowComponent, tUseStyles } from '../../../../../components/Datagrid/TStyles';
import formatBytes from '../../../../../tools/formatBytes';
import Module1Context from '../../../Module2Context';
import NERParamTarget from '../../../../../components/NERParamTarget';
import { getCaptureName } from '../../../../../api/breakdowns';
import Loading from '../../../../../components/Core/Loading';
import NavigationGoto from '../../../../../components/Core/NavigationGoto';

// table header
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
];

type Props = {
  id?: number;
  files?: any;
  setFiles?: any;
};
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.background.paper,
    },
    backgroundColor: 'rgb(238, 241, 249)',
  },
  paper: {
    '& .MuiPaper-rounded': {
      background: 'red !important',
    },
  },
  captureHandle: {
    '& .MuiInputLabel-outlined': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
  },
}));

function FindDuplicates({ id: workloadId, files, setFiles }: Props) {
  const history = useHistory();
  const classes = useStyles();
  const tableUseStyles = tUseStyles();
  const { projectId } = useParams() as any;
  const [toggleHeight, setToggleHeight] = useState(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const exporterRef = useRef(null);
  const [captureNames, setCaptureNames] = useState<any[]>([]);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };
  const { project } = useContext(Module1Context);
  // const [files, setFiles] = useState<any[]>([]);
  const [selection, setSelection] = useState([]);

  // to control the loader and display data full Grid
  const [display, setDisplay] = useState(false);

  const [loadingResult, setLoadingResult] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setSnack } = useContext(Module1Context);
  const [messageToDisplay, setMessageToDisplay] = useState<any>();

  const [dataoutput, setDataoutput] = useState<any>([]);
  const [columnWidth, setColumnWidth] = useState<any>([]);

  // required when we dont have fixed named columns
  const [rows, setRows] = useState<any>([]);
  const [columnsAre, setColumnsAre] = useState<any>([]);
  const [columnNamesAre, setColumnNamesAre] = useState<any>([]);
  const [newLine, setNewLine] = useState<any>('');
  const [filter, setFilter] = useState<any>([]);
  const [filterEntity, setFilterEntity] = useState<any>([]);

  const [resourcesAre, setResourcesAre] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');
  const inputHandler = (event: any) => setInputValue(event.target.value);

  const [fileName1, setFileName1] = useState<any>();
  const [fileName2, setFileName2] = useState<any>();

  const [sheetName0, setSheetName0] = useState<any>();
  const [targetName0, setTargetName0] = useState<any>();
  const [columnName0, setColumnName0] = useState<any>();

  const [sheetName1, setSheetName1] = useState<any>();
  const [targetName1, setTargetName1] = useState<any>();
  const [columnName1, setColumnName1] = useState<any>();
  const [columnWidths, setColumnWidths] = useState([] as any);
  const [errorMessage, setErrorMessage] = useState('');
  const [load, setLoad] = useState(false);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [currentPage1, setCurrentPage1] = useState(0);
  const [tempPage1, setTemPage1] = useState(0);
  const [dis1, setDis1] = useState(true);
  const [pageSize1, setPageSize1] = useState(5);
  const handlePageNum1 = () => {
    const rel = tempPage1 - 1;
    setCurrentPage1(rel);
  };
  const handleSetRowsColumns = async (myRows: {}[]) => {
    const araryCol = Object.keys(myRows[0]);
    const myCols: any = [];
    araryCol.map((key) => {
      // const keyTemp = key.replace(' ', '_');
      if (key !== 'RawData') {
        if (key !== 'Coordinates') {
          if (key !== 'i18n') {
            myCols.push({
              name: key,
              title: key.toUpperCase(),
            });
          }
        }
      }
      return key;
    });
    const resizeColumns = myCols.map((c) => {
      return {
        columnName: c.name,
        width: Math.ceil(10000 / myCols.length),
      };
    });
    setColumnWidths(resizeColumns);
    setColumnsAre(myCols);
    const tempArr: any = [];
    // eslint-disable-next-line array-callback-return
    myCols.map((c: any) => {
      tempArr.push(c.name);
    });
    setColumnNamesAre(tempArr);
    setRows(myRows);
  };
  useEffect(() => {
    getCaptureName(projectId).then((resp) => {
      if (resp) {
        setCaptureNames(resp);
      }
    });
  }, []);
  /**
   * Get all files from project
   */
  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  useEffect(() => {
    if (pageSize > files.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < files.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);

  useEffect(() => {
    const temp = files.map((data) => {
      return {
        ...data,
        isNew: false,
      };
    });
    setFiles(temp);
  }, []);

  useEffect(() => {
    if (files.length === 0 || projectId === undefined || workloadId === undefined) {
      return;
    }
    setLoadingResult(true);
    workloadGetById(workloadId)
      .then((payload) => {
        setLoadingResult(false);
        const resourcesForPdf = payload?.resources[0]?.scopeSettingOP ?? [
          payload?.resources[0]?.settingsModule,
        ];
        setResourcesAre(resourcesForPdf);
        handleSetRowsColumns(JSON.parse(payload?.results_data[0]?.data?.output));
        setDisplay(true);
      })
      .catch(() => {
        setDisplay(false);
        setSnack(messageToDisplay, 'error');
      });
  }, [files, messageToDisplay, projectId, setSnack, workloadId]);

  const getFileDetails = (inputFiles: any[]) => {
    setLoad(true);
    setDisplay(false);
    const falseArray: any = [];
    const arrayIs: any = [{ columnName: 'Entity', width: 300 }];
    for (const myFile of inputFiles) {
      falseArray.push(
        fileDetails(files[myFile].filename, files[myFile].filename, files[myFile].container)
      );
      arrayIs.push({
        columnName: files[myFile].filename.split('.').slice(0, -1).join('.'),
        width: 300,
      });
    }

    setColumnWidth(arrayIs);
    Promise.all(falseArray)
      .then((responses) => {
        // Get a JSON object from each of the responses
        return Promise.all(
          responses.map((response) => {
            return response;
          })
        );
      })
      .then((data) => {
        const ar: any = [];
        // console.log(data);
        data.map((c: any) => ar.push(c.output));
        setDataoutput(ar);
        // console.log(ar);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });

    // promise all end
  };

  const handleSelectionChange = (selectedKeys: any) => {
    // console.log(files);
    setErrorMessage('');
    setFilter([]);
    setFilterEntity([]);
    const ArrayFiles = selectedKeys;
    // console.log(selectedKeys);
    setSelection(selectedKeys);
    if (ArrayFiles.length > 1) {
      setSnack('more than 1 files not allowed!', 'error');
    } else {
      getFileDetails(ArrayFiles);
    }
  };
  const [targetColumnsAre, setTargetColumnsAre] = useState<any>([]);

  function HandleReg(e: any, indexIs: any) {
    // console.log('dataoutput is: ', dataoutput);
    if (e.target.name === 'sheetname') {
      const state = [...targetColumnsAre];
      state[indexIs] =
        dataoutput[indexIs].Sheets[dataoutput[indexIs].Sheet_names.indexOf(e.target.value)];
      setTargetColumnsAre(state);
      Object.assign(dataoutput[indexIs], { SheetName: e.target.value });
      // console.log('F:', dataoutput);
      if (indexIs === 0) {
        setSheetName0(e.target.value);
      } else {
        setSheetName1(e.target.value);
      }
    }
    if (e.target.name === 'targetColumn') {
      Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
      if (indexIs === 0) {
        setTargetName0(e.target.value);
      } else {
        setTargetName1(e.target.value);
      }
    }
    if (e.target.name === 'columnname') {
      Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
      if (indexIs === 0) {
        setColumnName0(e.target.value);
      } else {
        setColumnName1(e.target.value);
      }
    }
  }

  function handleAnalyze() {
    setErrorMessage('');

    if (dataoutput[0].FileType === 'Excel') {
      if (!sheetName0 || !targetName0) {
        setSnack('Please select both dropdown options', 'error');
        return;
      }
    } else if (dataoutput[0].FileType === 'CSV') {
      if (!columnName0) {
        setSnack('Please select dropdown options', 'error');
        return;
      }
    }
    if (captureNames.includes(inputValue.trim())) {
      setSnack('That activity name is taken.Please try another', 'error');
      return;
    }
    if (inputValue.trim().length === 0) {
      setSnack('Please enter activity name', 'error');
      return;
    }
    setLoading(true);
    setDisplay(false);
    const containerIs = dataoutput[0].Container;
    let tempdataoutput: any = [];
    tempdataoutput = JSON.parse(JSON.stringify(dataoutput));
    if (dataoutput[0].FileType === 'PDF') {
      const r = [
        {
          output: { NameOfCapture: inputValue, FileName: dataoutput[0].Blob },
          TextLength: 100000,
          settingsModule: {
            FileName: dataoutput[0].Blob,
            FilePath: dataoutput[0].FilePath,
            Container: dataoutput[0].Container,
            BlobName: dataoutput[0].Blob,
            id: dataoutput[0].Blob,
            FileType: 'pdf',
            Language: dataoutput[0].Language,
            StartPage: 0,
            EndPage: 0,
            HeaderMargin: 0,
            FooterMargin: 0,
            TypesOfCapture: 'Paragraph_Capture',
            RegId: '',
            TableSwitch: true,
            MergeBullet: false,
            SmartHeaderFooter: true,
            SmartTableOfContent: true,
            TranslateTo: ['en'],
            NameOfCapture: inputValue,
            modeService: 'SelfDoc',
          },
        },
      ];
      workloadCreate({
        project_id: projectId,
        app: 'module2',
        action: 'SelfDoc',
        resources: r,
      })
        .then((workloadNew: Workload3Create) => {
          workloadRunPostPDF(Number(workloadNew.id), r, 'm2-selfdoc')
            .then(() => {
              window.history.pushState('', '', `/projects/${projectId}`);
              history.push(`/projects/${projectId}`);
            })
            .catch((e) => {
              setErrorMessage(e.detail);
              setSnack('Error while workload post.', 'error');
              setDisplay(false);
              setLoading(false);
            })
            .finally(() => {});
        })
        .catch(() => {
          setSnack('Error while workload create.', 'error');
          setDisplay(false);
          setLoading(false);
        })
        .finally(() => {});
    } else {
      // console.log(tempdataoutput);
      Promise.all([
        fileDetails(
          files[selection[0]].filename,
          files[selection[0]].filename,
          files[selection[0]].container
        ),
        // fileDetails(
        //   files[selection[1]].filename,
        //   files[selection[1]].filename,
        //   files[selection[1]].container
        // ),
      ])
        .then(async (responseAll) => {
          // for data 0 process to do iCapture
          // console.log(responseAll);
          const arrPromises: any = [];
          function captureAll(): Promise[] {
            for (const [i, itemData] of responseAll.entries()) {
              if (itemData.output.FileType === 'Excel') {
                let columnPosition: any;
                // console.log(`${sheetName0},${sheetName1}`);
                if (sheetName0 !== undefined) {
                  if (sheetName0 !== null) {
                    columnPosition = (responseAll[0]?.output?.Sheet_names).indexOf(sheetName0);
                  }
                }
                if (sheetName1 !== undefined) {
                  if (sheetName1 !== null) {
                    columnPosition = (responseAll[1]?.output?.Sheet_names).indexOf(sheetName1);
                  }
                }
                const sheetNameFind = i === 0 ? sheetName0 : sheetName1;
                const body = {
                  FilePath: itemData.output.FilePath,
                  Container: itemData.output.Container,
                  BlobName: itemData.output.Blob,
                  NameOfCapture: inputValue,
                  Sheet: sheetNameFind,
                  TargetColumn: i === 0 ? targetName0 : targetName1,
                  ColumnNames: itemData?.output?.Sheets[columnPosition]?.ColumNames,
                  Header: true,
                };
                arrPromises.push(iCaptureExcel(body));
                // console.log(tempdataoutput);
                // to arrange sheet number in first position because of middleware 388-405
                // eslint-disable-next-line no-plusplus
                for (let index = 0; index < tempdataoutput.length; index++) {
                  if (tempdataoutput[index].FileName === itemData.output.Blob) {
                    // eslint-disable-next-line no-plusplus
                    for (let jindex = 0; jindex < tempdataoutput[index].Sheets.length; jindex++) {
                      // eslint-disable-next-line eqeqeq
                      if (tempdataoutput[index].Sheets[jindex].SheetName == sheetNameFind) {
                        tempdataoutput[index].Sheet = tempdataoutput[index].Sheets[jindex];
                      }
                    }
                  }
                }
              } else if (itemData.output.FileType === 'CSV') {
                const body = {
                  FilePath: itemData.output.FilePath,
                  Container: itemData.output.Container,
                  BlobName: itemData.output.Blob,
                  NameOfCapture: inputValue,
                  TargetColumn: i === 0 ? columnName0 : columnName1,
                  ColumnNames: itemData?.output?.ColumnNames,
                  Header: true,
                };
                arrPromises.push(iCaptureCsv(body));
              }
            }
            return arrPromises;
          }

          const responseCharacters = await Promise.all(captureAll());
          const len =
            JSON.parse(responseCharacters[0]?.output?.Text)?.length > 0
              ? JSON.parse(responseCharacters[0]?.output?.Text)?.length
              : 0;
          if (len === 0) {
            setSnack('Invalid file format', 'error');
            setLoading(false);
            setDisplay(false);
            return;
          }
          const r = responseCharacters.map((d, index) => ({
            ...d,
            FileName: responseAll[index].output.Blob,
            NameOfCapture: inputValue,
            scopeSettingOP: tempdataoutput,
            TextLength: len,
          }));
          // const r = responseCharacters;
          // console.log(r);
          // console.log(r);
          // workload here
          workloadCreate({
            project_id: projectId,
            app: 'module2',
            action: 'SelfDoc',
            resources: r,
          })
            .then((workloadNew: Workload3Create) => {
              workloadRunPost(Number(workloadNew.id), r, 'm2-selfdoc')
                .then(() => {
                  window.history.pushState('', '', `/projects/${projectId}`);
                  history.push(`/projects/${projectId}`);
                })
                .catch((e) => {
                  setErrorMessage(e.detail);
                  setSnack('Error while workload post.', 'error');
                  setDisplay(false);
                  setLoading(false);
                })
                .finally(() => {});
            })
            .catch(() => {
              setSnack('Error while workload create.', 'error');
              setDisplay(false);
              setLoading(false);
            })
            .finally(() => {});
        })
        .catch((error) => {
          console.log(error);
          setSnack('Error while processing the scopeSetting for both files.', 'error');
          setDisplay(false);
          setLoading(false);
        });
    }
  }

  const renderFileName = ({ row }: any) => {
    const ext = row.filename.split('.');
    const extensions = {
      xlsx: ExcelDoc,
      csv: CsvDoc,
      pdf: PdfDoc,
      pptx: PptDoc,
      docx: DocxDoc,
    };
    return (
      <div
        style={{
          position: 'relative',
          padding: '0.3rem',
          width: 'max-content',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <img src={extensions[ext[ext.length - 1]]} height="30" width="30" alt="img" />
          <Typography>{row.filename}</Typography>
        </div>
        {row?.isNew && (
          <div
            style={{
              width: 'max-content',
              padding: '0.2rem',
              borderRadius: '10px',
              backgroundColor: '#19AA6E',
              color: 'white',
              position: 'absolute',
              top: '-12px',
              right: '-35px',
            }}
          >
            <Typography style={{ fontSize: '0.85em' }}>New !</Typography>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
        <ExpressGrid rows={files} columns={columns}>
          <DataTypeProvider for={['filename']} formatterComponent={renderFileName} />
          <SelectionState selection={selection} onSelectionChange={handleSelectionChange} />
          <FilteringState />
          <IntegratedFiltering />
          <SortingState />
          <IntegratedSorting />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <VirtualTable />
          <Table tableComponent={TableComponent} />
          <TableHeaderRow showSortingControls />
          <TableFilterRow rowComponent={rowComponent} />
          <TableSelection />
          <PagingPanel pageSizes={pageSizes} />
        </ExpressGrid>
        <NavigationGoto
          setPageSize={setPageSize}
          fileLength={files.length}
          pageSize={pageSize}
          dis={dis}
          setTemPage={setTemPage}
          handlePageNum={handlePageNum}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Box>
      {load && <Loading />}
      <Paper style={{ marginTop: '5px' }}>
        {/* <div style={{ backgroundColor: 'rgb(238, 241, 249)' }}> */}
        {dataoutput.length > 0 && (
          <>
            <div style={{ border: 'solid black', borderWidth: '0.1px' }}>
              <Grid container style={{ marginLeft: 10 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div>
                    <SettingsApplicationsOutlinedIcon style={{ width: 40, height: 40 }} />
                  </div>
                  <div>
                    <h2>Scope Settings</h2>
                  </div>
                </div>
              </Grid>
              <Grid spacing={0} style={{ padding: '1rem' }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        gap: '40px',
                      }}
                    >
                      {dataoutput.map((c, indexIs) => (
                        <FormControl variant="outlined">
                          <span>
                            {c?.FileType === 'unsupported' ? (
                              <img
                                src={docIcon}
                                alt="isens"
                                style={{
                                  width: 20,
                                  height: 25,
                                  transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              ''
                            )}
                            {c?.FileType === 'Excel' || c?.FileType === 'CSV' ? (
                              <img
                                src={xlsIcon}
                                alt="isens"
                                style={{
                                  width: 20,
                                  height: 25,
                                  transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              ''
                            )}
                            {c?.FileType === 'PDF' ? (
                              <img
                                src={pdfIcon}
                                alt="isens"
                                style={{
                                  width: 20,
                                  height: 25,
                                  transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              ''
                            )}
                            &nbsp;
                            <Tooltip title={c?.Blob} placement="top" arrow>
                              <b style={{ color: 'blue', wordWrap: 'break-word' }}>
                                {c?.Blob.substring(0, 25)}
                              </b>
                            </Tooltip>
                          </span>
                          {c?.FileType !== 'unsupported' ? (
                            <span>
                              <span style={{ color: 'red' }}>{c?.Error}</span>
                            </span>
                          ) : (
                            ''
                          )}
                        </FormControl>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {dataoutput.map((c, indexIs) => (
                        <Grid container xs={12}>
                          <Grid item xs={4}>
                            {}
                          </Grid>
                          {c.FileType === 'Excel' && (
                            <Grid item xs={8}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  gap: '20px',
                                  marginBottom: '2rem',
                                }}
                              >
                                <FormControl
                                  variant="outlined"
                                  size="small"
                                  style={{ flexBasis: '50%', height: '2.5rem' }}
                                >
                                  <InputLabel
                                    htmlFor="outlined-age-native-simple"
                                    style={{ color: '#002A45', fontWeight: 'bold' }}
                                    size="small"
                                  >
                                    Sheet Column
                                  </InputLabel>
                                  <Select
                                    native
                                    label="Sheet  Name"
                                    size="small"
                                    // name={'sheetname'.concat(indexIs)}
                                    name="sheetname"
                                    style={{ width: '100%' }}
                                    onChange={(e) => HandleReg(e, indexIs)}
                                    defaultValue=""
                                  >
                                    <option aria-label="None" value="" disabled />
                                    {c?.Sheet_names !== null
                                      ? c?.Sheet_names.map((cap: string, index: number) => (
                                          <option key={`capture-type-${index}`} value={cap}>
                                            &nbsp;&nbsp;{cap}
                                          </option>
                                        ))
                                      : ''}
                                  </Select>
                                </FormControl>
                                <FormControl
                                  variant="outlined"
                                  size="small"
                                  style={{ flexBasis: '50%', height: '2.5rem' }}
                                >
                                  <InputLabel
                                    htmlFor="outlined-age-native-simple"
                                    style={{ color: '#002A45', fontWeight: 'bold' }}
                                  >
                                    Target Column
                                  </InputLabel>
                                  <Select
                                    native
                                    label="Target  Column"
                                    name="targetColumn"
                                    style={{ width: '100%' }}
                                    onChange={(e) => HandleReg(e, indexIs)}
                                    defaultValue=""
                                  >
                                    <option aria-label="None" value="" disabled />
                                    <NERParamTarget
                                      key={`NERParamTarget-${indexIs}`}
                                      data={targetColumnsAre[indexIs]}
                                    />
                                  </Select>
                                </FormControl>
                              </div>
                            </Grid>
                          )}
                          {c.FileType === 'CSV' && (
                            <Grid item xs={8}>
                              <FormControl
                                variant="outlined"
                                size="small"
                                style={{ flexBasis: '100%', width: '100%', height: '2.5rem' }}
                              >
                                <InputLabel
                                  htmlFor="outlined-age-native-simple"
                                  style={{ color: '#002A45', fontWeight: 'bold' }}
                                >
                                  Column Name
                                </InputLabel>
                                <Select
                                  native
                                  label="Column Name"
                                  name="columnname"
                                  style={{ width: '100%' }}
                                  onChange={(e) => HandleReg(e, indexIs)}
                                  defaultValue=""
                                >
                                  <option aria-label="None" value="" disabled />
                                  {c?.ColumnNames !== null
                                    ? c?.ColumnNames.map((cap: string, index: number) => (
                                        <option key={`capture-type-${index}`} value={cap}>
                                          &nbsp;&nbsp;{cap}
                                        </option>
                                      ))
                                    : ''}
                                </Select>
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                style={{
                  backgroundColor: '#EEF1F9',
                  padding: '1rem',
                  borderTop: '1px solid #002A45',
                }}
              >
                <Grid item xs={6} alignItems="center" justify="center">
                  {errorMessage !== '' && (
                    <Typography style={{ color: '#DC3223' }}>Error : {errorMessage}</Typography>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Box style={{ display: 'flex' }}>
                    <Box style={{ flexBasis: '30%' }}>{}</Box>
                    <Box
                      style={{
                        flexBasis: '78%',
                        display: 'flex',
                        height: '2.5rem',
                        gap: '20px',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        label="Enter Activity Name"
                        variant="outlined"
                        name="captureName"
                        onChange={inputHandler}
                        size="small"
                        style={{
                          width: '170px',
                          height: '38px',
                          marginTop: 6,
                          marginBottom: 6,
                          backgroundColor: 'white',
                        }}
                      />
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        startIcon={
                          loading && <CircularProgress style={{ color: 'white' }} size={20} />
                        }
                        onClick={handleAnalyze}
                        style={{
                          width: '150px',
                          height: '38px',
                          marginTop: 6,
                          marginBottom: 6,
                        }}
                      >
                        Analyze
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </Paper>
      <br />
      {/* <ReactJson src={{ rows }} collapsed={true} theme="monokai" /> */}
      {loadingResult ? <Loading /> : ''}
      {display && (
        <>
          <Paper
            style={{
              borderTop: 'solid rgba(0, 0, 0, 0.87)',
              borderWidth: '0.6px',
              backgroundColor: 'rgb(238, 241, 249)',
            }}
          >
            <div style={{ marginLeft: '5px' }}>
              <h3>Resource details:</h3>
              <Grid container spacing={0}>
                {resourcesAre?.map((data: any) => {
                  return (
                    <>
                      {data?.FileName && (
                        <>
                          <Grid item xs={3}>
                            File Name: {data?.FileName}
                          </Grid>
                          <Grid item xs={3}>
                            File Type: {data?.FileType}
                          </Grid>
                          <Grid item xs={3}>
                            Sheet Name: {data?.SheetName != null ? data?.SheetName : 'NA'}
                          </Grid>
                          <Grid item xs={3}>
                            Target Column: {data?.TargetColumn}
                          </Grid>
                        </>
                      )}
                    </>
                  );
                })}
              </Grid>
            </div>
          </Paper>
          <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
            <ExpressGrid rows={rows} columns={columnsAre}>
              <DataTypeProvider
                for={columnNamesAre}
                formatterComponent={({ value }) => (
                  <span title={value} style={{ whiteSpace: `${newLine}` }}>
                    {value}
                  </span>
                )}
              />
              <SortingState />
              <IntegratedSorting />
              <PagingState
                currentPage={currentPage1}
                onCurrentPageChange={setCurrentPage1}
                pageSize={pageSize1}
                onPageSizeChange={setPageSize1}
              />
              <IntegratedPaging />
              <Table
                tableComponent={TableComponent}
                columnExtensions={[
                  {
                    columnName: 'Text1',
                    width: '300px',
                    wordWrapEnabled: toggleHeight,
                  },
                  {
                    columnName: 'Text2',
                    width: '300px',
                    wordWrapEnabled: toggleHeight,
                  },
                  {
                    columnName: 'Text2.1',
                    width: '300px',
                    wordWrapEnabled: toggleHeight,
                  },
                  {
                    columnName: 'Text3',
                    width: '300px',
                    wordWrapEnabled: toggleHeight,
                  },
                  {
                    columnName: 'Text4',
                    width: '300px',
                    wordWrapEnabled: toggleHeight,
                  },
                  // Similarity Match
                  {
                    columnName: 'Similarity Match',
                    width: '200px',
                  },
                ]}
              />
              <TableColumnResizing
                onColumnWidthsChange={setColumnWidths}
                columnWidths={columnWidths}
                resizingMode="nextColumn"
              />
              <TableHeaderRow showSortingControls />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={setHiddenColumnNames}
              />
              <Toolbar />
              <ColumnChooser />
              <ToolBarPlugin name="Download" onClick={startExport} />
              <ToolBarPlugin
                name="Height"
                title="Wrap Text"
                onClick={() => {
                  setToggleHeight(!toggleHeight);
                }}
              />
              <ToolBarPlugin
                name="NewLine"
                onClick={() => (newLine !== 'pre-line' ? setNewLine('pre-line') : setNewLine(''))}
              />
              <PagingPanel pageSizes={pageSizes} />
            </ExpressGrid>
            <NavigationGoto
              setPageSize={setPageSize1}
              fileLength={rows.length}
              pageSize={pageSize1}
              dis={dis1}
              setTemPage={setTemPage1}
              handlePageNum={handlePageNum1}
              currentPage={currentPage1}
              setCurrentPage={setCurrentPage1}
            />
            <GridExporter ref={exporterRef} rows={rows} columns={columnsAre} onSave={onSave} />
          </Box>
        </>
      )}
    </>
  );
}

export default FindDuplicates;
