import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/EditOff';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import StreetviewIcon from '@mui/icons-material/Streetview';
import Button from '@mui/material/Button';
import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { deleteRulesBulk, duplicateRulesBulk, getRules } from '../../../api/iverifier';
import { getRulesProject } from '../../../api/module3';
import Loading from '../../../components/Core/Loading';
import TableComponent from '../../../components/Datagrid/TableComponent';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import {
  tableContainerComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../components/Datagrid/TStyles';
import { IS_DEBUG_MODE } from '../../../const';
import { handleColumns } from '../../../tools/datagrid';
import formatDate, { formatLocal } from '../../../tools/formatDate';
import ProjectContext from '../../Project/ProjectContext';
import { renderUser } from '../Admin/IverifierTemplatesAdmin';
import RuleDetails from '../RulesTree/RuleDetails';
import RulesTreeContainer, { getTreeView } from '../RulesTree/RulesTreeContainer';
import ModalRuleEditor from './ModalRuleEditor';
import { searchUsers } from '../../../api/users';

const columns = handleColumns([
  'id',
  'object_number',
  'standard',
  'rule',
  {
    name: 'script_rule_id',
    title: 'Rule ID',
  },
  {
    name: 'script_rule',
    title: 'Rule Script',
  },
  {
    name: 'script_rule_condition',
    title: 'Local Condition',
  },
  {
    name: 'script_rule_result',
    title: 'Result',
  },

  'created_at',
  'created_by_user',
  'updated_at',
  'updated_by_user',
  'module_profile',
  'comments',
  'criticity',
]);
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    backgroundColor: theme.palette.action.hover,
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
    height: 60,
  },
  textField: {
    margin: '15px 5px',
    paddingRight: 10,
  },
  radio: {
    textAlign: 'center',
  },
  label: {
    marginRight: '30px',
  },
  IconButton: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'none',
    },
  },
  IconDesignMain: {
    display: 'flex',
    border: '1px solid #002A45',
    borderRadius: 70,
  },
  IconDesign: {
    padding: '6px 20px',
    backgroundColor: '#002A45',
    borderRadius: 70,
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    marginTop: -1,
  },
  IconDesign2: {
    padding: '6px 5px',
    width: 30,
    // backgroundColor: 'white',
    borderRadius: 70,
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    color: '#002A45',
  },
}));

function IverifierRules() {
  const { projectId } = useParams() as any;
  const classes = useStyles();
  const { setSnack } = useContext(ProjectContext);
  const [rows, setRows] = useState<any[]>([]);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [view, setView] = React.useState(true);
  const [selection, setSelection] = useState<any>([]);
  const [close, setClose] = useState(true);
  const [ruleSelected, setRuleSelected] = useState<any>(undefined);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [userProfile, setUserProfile] = useState(0);

  // modal state
  const [toggleModalName, setToggleModalName] = useState<
    '' | 'modalRuleDetails' | 'modalRuleEditor'
  >('');
  const [toggleConfirm, setToggleConfirm] = useState(false);
  const [idFilter, setIdFilter] = useState<any>([]);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    refreshPage();
  }, []);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('appJWT') || '{}');
    searchUsers({ project_id: projectId })
      .then((dbusers: any[]) => {
        // eslint-disable-next-line array-callback-return
        dbusers?.map((userdt) => {
          if (userdt.user.id === token?.user_id) {
            setUserProfile(userdt.user_project.role_id);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // console.log(userProfile);
      });
  }, []);

  function refreshPage() {
    Promise.all([getRulesProject(projectId), getRules(projectId, undefined, 'hierarchy')]).then(
      ([rules, rulesTree]) => {
        setRows(rules);
        setTreeData(getTreeView(rulesTree.children));
        setLoading(false);
      }
    );
  }

  const handleNewRule = () => {
    setRuleSelected(undefined);
    setToggleModalName('modalRuleEditor');
  };

  const handleDelete = () => {
    if (selection.length === 0) return;
    if (selection.length > 0) setToggleConfirm(true);
    const standardId: any[] = [];
    // eslint-disable-next-line array-callback-return
    selection.map((a: any, i: any) => {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < rows.length; index++) {
        // eslint-disable-next-line eqeqeq
        if (rows[index]?.id == selection[i]) {
          if (rows[index]?.standard === true) {
            standardId.push(selection[i]);
          }
        }
      }
    });
    setIdFilter(standardId);
  };

  const handleConfirmOK = () => {
    setToggleConfirm(false);

    const standardId: any[] = [];
    // eslint-disable-next-line array-callback-return
    selection.map((a: any, i: any) => {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < rows.length; index++) {
        // eslint-disable-next-line eqeqeq
        if (rows[index]?.id == selection[i]) {
          if (rows[index]?.standard === true) {
            standardId.push(selection[i]);
          }
        }
      }
    });

    const diff1 = selection.filter((x) => !standardId.includes(x));
    // console.log(diff1);
    deleteRulesBulk(diff1).then((res) => {
      setSelection([]);
      setSnack('Selected rules are deleted', 'success');
      if (standardId.length > 0) {
        setSnack(`rule ids: ${standardId}  => standard rules so can't deleted.`, 'error');
      }
      refreshPage();
    });
  };

  const handleConfirmClose = () => {
    setToggleConfirm(false);
  };

  const handleCopy = () => {
    if (selection.length === 0) return;
    duplicateRulesBulk(selection).then((res) => {
      setRows([...res, ...rows]);
      setSelection([]);
      setSnack('Duplicate rule created', 'success');
    });
  };

  const renderStandard = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    if (row.standard) {
      return <CheckCircleIcon />;
    }
    return (
      <EditIcon
        onClick={() => {
          setRuleSelected(row);
          setToggleModalName('modalRuleEditor');
        }}
      />
    );
  };
  const renderObjectNum = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    if (value)
      return (
        <Button
          onClick={() => {
            setRuleSelected(row);
            setToggleModalName('modalRuleDetails');
            setClose(true);
          }}
        >
          {value}
        </Button>
      );
    return null;
  };

  const getRowId = (row) => row.id;

  if (loading) return <Loading />;
  return (
    <div>
      {IS_DEBUG_MODE && <ReactJson src={{ selection, rows }} collapsed={true} theme="monokai" />}
      <Grid container justify="flex-end" alignItems="center">
        <Grid item style={{ marginRight: 90 }}>
          <IconButton
            sx={{
              ml: 1,
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
            }}
            onClick={() => setView(!view)}
          >
            {view ? (
              <Grid className={classes.IconDesignMain}>
                <div className={classes.IconDesign}>
                  <FormatListBulletedOutlinedIcon />
                  <span style={{ fontSize: 16 }}>Detail View</span>
                </div>
                <div className={classes.IconDesign2}>
                  <StreetviewIcon />
                </div>
              </Grid>
            ) : (
              <Grid className={classes.IconDesignMain}>
                <div className={classes.IconDesign2}>
                  <FormatListBulletedOutlinedIcon />
                </div>
                <div className={classes.IconDesign}>
                  <StreetviewIcon />
                  <span style={{ fontSize: 16 }}>Tree View</span>
                </div>
              </Grid>
            )}
          </IconButton>
        </Grid>
      </Grid>
      <Dialog
        open={toggleConfirm}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm deleting the rules</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete the selected rules ?
            <br />
            <span style={{ color: 'red' }}>
              {idFilter.length !== 0 ? `ids: ${idFilter}  standard rules so not deletable` : ''}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmOK} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {view === true ? (
        <ExpressGrid rows={rows} columns={columns} getRowId={getRowId}>
          {/* <DataTypeProvider for={['id']} formatterComponent={renderId} /> */}
          {userProfile === 3 ? null : (
            <DataTypeProvider for={['standard']} formatterComponent={renderStandard} />
          )}

          {/* <DataTypeProvider for={['standard']} formatterComponent={renderStandard} /> */}
          <DataTypeProvider for={['object_number']} formatterComponent={renderObjectNum} />
          <DataTypeProvider
            for={['created_at', 'updated_at']}
            formatterComponent={({ value }) => <>{formatDate(value, formatLocal())}</>}
          />
          <DataTypeProvider
            for={['created_by_user', 'updated_by_user']}
            formatterComponent={renderUser}
          />
          {/* selection */}
          <SelectionState selection={selection} onSelectionChange={setSelection} />
          <IntegratedSelection />
          {/* filtering */}
          <FilteringState />
          <SortingState />
          <IntegratedSorting />
          <IntegratedFiltering />
          <PagingState defaultCurrentPage={0} defaultPageSize={10} />
          <IntegratedPaging />
          {/* <EditingState onCommitChanges={commitChanges} /> */}
          <Table
            columnExtensions={columns.map((c) => ({
              columnName: c.name,
              wordWrapEnabled: toggleHeight,
            }))}
            tableComponent={TableComponent}
            containerComponent={tableContainerComponent}
            headComponent={tableHeaderComponent}
          />
          <TableColumnResizing
            defaultColumnWidths={[
              ...columns.map((c) => {
                if (c.name === 'id') return { columnName: c.name, width: 80 };
                if (c.name === 'standard') return { columnName: c.name, width: 80 };
                if (c.name === 'object_number') return { columnName: c.name, width: 100 };
                if (
                  ['created_at', 'created_by_user', 'updated_at', 'updated_by_user'].includes(
                    c.name
                  )
                )
                  return { columnName: c.name, width: 150 };
                return {
                  columnName: c.name,
                  width: Math.ceil(2000 / (columns.length - 7)),
                };
              }),
            ]}
          />
          <TableHeaderRow showSortingControls />
          {/* <TableEditColumn showEditCommand /> */}
          <TableFilterRow />
          <TableColumnVisibility defaultHiddenColumnNames={[]} />
          <Toolbar rootComponent={toolbarComponent} />
          {/* selection */}
          <TableSelection showSelectAll />
          <ColumnChooser />
          {userProfile === 3 ? null : (
            <ToolBarPlugin name="NewCapture" onClick={handleNewRule} title="Create new rule" />
          )}
          {userProfile === 3 ? null : <ToolBarPlugin name="Delete" onClick={handleDelete} />}
          {userProfile === 3 ? null : <ToolBarPlugin name="Copy" onClick={handleCopy} />}

          {/* <ToolBarPlugin name="NewCapture" onClick={handleNewRule} title="Create new ruleaa" />
          <ToolBarPlugin name="Delete" onClick={handleDelete} />
          <ToolBarPlugin name="Copy" onClick={handleCopy} /> */}
          <ToolBarPlugin name="Refresh" onClick={refreshPage} />
          <ToolBarPlugin
            name="Height"
            title="Wrap Text"
            onClick={() => {
              setToggleHeight(!toggleHeight);
            }}
          />
          <ToolBarPlugin name="Download" onClick={null} />
          <PagingPanel pageSizes={[10, 20, 50]} />
        </ExpressGrid>
      ) : (
        <RulesTreeContainer rows={rows} rows_tree={treeData} />
      )}
      {close ? (
        <>
          {toggleModalName === 'modalRuleDetails' && (
            <Dialog
              onClose={() => setClose(!close)}
              aria-labelledby="customized-dialog-title"
              open={close}
              maxWidth="md"
              fullWidth={true}
            >
              <DialogTitle id="customized-dialog-title" className={classes.heading}>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={() => setClose(!close)}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              {loading ? <Loading /> : <RuleDetails data={ruleSelected} />}
            </Dialog>
          )}
        </>
      ) : null}
      {toggleModalName === 'modalRuleEditor' && (
        <ModalRuleEditor
          data={ruleSelected}
          onClose={(response) => {
            setToggleModalName('');
            setRuleSelected(undefined);
            if (response) {
              // setRows([rows, ...response]);
              refreshPage();
            }
          }}
        />
      )}
    </div>
  );
}

export default IverifierRules;
