import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Homepage from './components/Homepage';

const HomeContainer = () => {
  return (
    <Switch>
      <Route
        exact
        path="/home"
        render={() => {
          return <Homepage />;
        }}
      />
      <Route
        exact
        path="/"
        render={() => {
          return <Homepage />;
        }}
      />
    </Switch>
  );
};

export default HomeContainer;
