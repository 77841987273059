/**
import { queryString } from 'querystring';
 * @param urlHttp
 */
function AdminDefaultFileDownload(urlHttp: string, queryString: string): any {
  const URL = process.env.REACT_APP_BACKEND_URL;
  const link = document.createElement('a');
  link.href = `${URL}${urlHttp}?${queryString}`;

  document.body.appendChild(link);
  link.click();
}

export default AdminDefaultFileDownload;
