/* eslint-disable react/destructuring-assignment */
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import Button from '@material-ui/core/Button';
import { Box, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Tooltip } from '@mui/material';
import TableComponent from '../../../../components/Datagrid/TableComponent';
import {
  rowComponent,
  tableAllocatorComponent,
  tableHeaderComponent,
} from '../../../../components/Datagrid/TStyles';
import Run1Context from '../../RunContext';
import NavigationGoto from '../../../../components/Core/NavigationGoto';
import NavigationGotoNew from '../../../../components/Core/NavigationGotoNew';
import MultiAllocTableComp from '../../../../components/Datagrid/MultiAllocTableComp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputPageNo: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '1rem',
      height: '3rem',
      marginLeft: '49.5rem',
      marginRight: '1rem',
      padding: '0.5rem',
      [theme.breakpoints.up('xl')]: {
        marginLeft: '73.5rem',
      },
    },
    pagingPanelContainer: {
      // display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'center',
      // marginTop: '10px',
      // width: '50% !important',
      padding: '0px !important',
      marginRight: '244px !important',
      '& .Pager-pager': {
        padding: 0,
      },
      // Apply other custom styles as needed
      '& .MuiIconButton-label': {
        color: '#001F3C',
        padding: 5,
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        boxShadow: '#00000029',
      },
      // '& .MuiButton-label': {
      //   color: '#001F3C',
      //   padding: 2,
      //   backgroundColor: '#FFFFFF',
      //   borderRadius: '50%',
      //   boxShadow: '#00000029',
      // },
      '& .MuiButton-root': {
        '&.MuiButton-textPrimary .Pagination-activeButton': {
          backgroundColor: 'red !important',
          color: 'white !important',
          // Add other custom styles as needed
        },
        color: '#001F3C',
        padding: '0px 5px',
        backgroundColor: 'white',
        // borderRadius: '50%',
        boxShadow: '#00000029',
        // gap: 10,
        margin: 9,
        // '&.Pagination-activeButton': {
        //   background: 'red !important',
        // },
        // '& .Pagination-button': {
        //   '& .Pagination-activeButton': {
        //     background: 'red !important',
        //   },
        // },
      },
    },
    inputTag: {
      width: '4rem',
      height: '1.8rem',
      border: '1px solid #002A45',
      '&:focus': {
        outline: 'none',
      },
      padding: '3px',
    },
    errorM: {
      float: 'right',
      marginRight: '2rem',
      color: 'red',
      marginTop: '0',
      fontSize: '0.9rem',
    },
  })
);
const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
  { name: 'userName', title: 'Uploaded by' },
];

type Props = {
  files: any[];
  selected?: number[];
  filterFiles?: string[];
};

function StepInputFiles({ files: filesDefault, selected, filterFiles }: Props) {
  const classes = useStyles();
  let files: any[];
  if (filterFiles) {
    files = filesDefault.filter((row: any) => {
      const fileExtension = row.filename.split('.').pop();
      return filterFiles.includes(fileExtension);
    });
  } else {
    files = filesDefault;
  }
  const [error, setError] = useState(false);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [pageSize, setPageSize] = useState(5);
  const [tempPage, setTemPage] = useState(0);
  // const [maxVal, setMaxVal] = useState();
  const [dis, setDis] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [selection, setSelection] = useState(selected || []);
  const runContext = useContext(Run1Context);
  const { resources, setResources } = runContext;

  useEffect(() => {
    setSelection(selected || []);
  }, []);

  useEffect(() => {
    if (pageSize > files.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < files.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);

  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  const handleSelectionChange = (selectedKeys: any) => {
    // TODO : disable multiple files
    if (selectedKeys.length > 1) {
      alert('You can select only one file');
      return;
    }
    const added = selection.length < selectedKeys.length;

    // console.log('init=', selection, selectedKeys);

    if (added) {
      // action = add files
      const addedKeys = selectedKeys.filter(
        (selectedKey: number) => !selection.includes(selectedKey)
      );
      setResources([
        ...resources,
        ...files.filter((f, index: number) => addedKeys.includes(index)),
      ]);
    } else {
      // action = remove files
      const fileIds = files
        .filter((f, index: number) => selectedKeys.includes(index))
        .map((f) => f.id);
      // console.log('fileIds=', fileIds);
      setResources(resources.filter((f) => fileIds.includes(f.id)));
    }
    // onSelect(files.filter((f, index: number) => selectedKeys.includes(index)));
    setSelection(selectedKeys);
  };
  // table scroll container style
  const tableScrollComponent: React.FunctionComponent<Table.CellProps> = (props) => {
    return <Table.Container {...props} style={{ maxHeight: '55vh', overflowY: 'auto' }} />;
  };
  // Custom messages for sorting state
  const sortingMessages = {
    sortingHint: '', // This will remove the default sorting hint
  };
  const CustomHeaderCell = ({ column, children, ...restProps }) => (
    <TableHeaderRow.Cell {...restProps} column={column}>
      <Tooltip title={`Sort by ${column.title}`} enterDelay={300}>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
      </Tooltip>
    </TableHeaderRow.Cell>
  );
  return (
    <Box>
      {/* <ReactJson src={{ selection }} collapsed={true} theme="monokai" /> */}
      <Box style={{ backgroundColor: '#EEF1F9' }}>
        {/* {console.log('Files', files)} */}
        <Grid rows={files.sort((a, b) => new Date(b.date) - new Date(a.date))} columns={columns}>
          <FilteringState
          // defaultFilters={[{ columnName: 'filename', value: 'pdf' }]}
          />
          <IntegratedFiltering />
          <SelectionState selection={selection} onSelectionChange={handleSelectionChange} />
          <IntegratedSelection />
          <SortingState messages={sortingMessages} />
          <IntegratedSorting />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <VirtualTable />
          <Table
            tableComponent={MultiAllocTableComp}
            containerComponent={tableScrollComponent}
            headComponent={tableHeaderComponent}
          />
          <TableColumnResizing
            defaultColumnWidths={[
              { columnName: 'filename', width: 300 },
              { columnName: 'size', width: 50 },
              { columnName: 'folderName', width: 100 },
              { columnName: 'date', width: 200 },
              { columnName: 'userName', width: 300 },
            ]}
          />
          <TableHeaderRow cellComponent={CustomHeaderCell} showSortingControls />

          <TableFilterRow rowComponent={rowComponent} />
          {/* <PagingPanel pageSizes={pageSizes} /> */}
          <PagingPanel
            pageSizes={pageSizes}
            containerComponent={(props) => (
              <>
                <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
              </>
            )}
          />
          <TableSelection showSelectAll />
        </Grid>

        <NavigationGotoNew
          setPageSize={setPageSize}
          fileLength={files.length}
          pageSize={pageSize}
          dis={dis}
          setTemPage={setTemPage}
          handlePageNum={handlePageNum}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Box>
      {/* <Box className={classes.inputPageNo}>
        <Typography>Page no:</Typography>
        <div>
          <input
            type="number"
            min="1"
            max="400"
            onChange={(e) => {
              const v = parseInt(e.target.value, 10);
              setTemPage(v);
            }}
            className={classes.inputTag}
            disabled={dis || pageSize < files.length ? '' : 'disabled'}
          />
        </div>
        <Button variant="contained" color="primary" size="small" onClick={handlePageNum}>
          Go to
        </Button>
      </Box> */}
      {error && (
        <Typography className={classes.errorM} variant="caption">
          {errorMsg}
        </Typography>
      )}
    </Box>
  );
}

export default StepInputFiles;
