import React, { useEffect, useState } from 'react';
import { Box, InputBase, Button, Typography, IconButton } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import { isEmpty } from '@antv/util';
import { getQnAGenerator } from '../../../api/module2';
import './styles/highlight.css';
import './styles/toggleStyle.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const QnAPairsCard = ({ questions, highLightWord }) => {
  //   const { ans, score } = questionObj;
  const { question, ans, end, score, start } = questions;
  // const [highLightAns, setHighLightAns] = useState();

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#EEF1F9',
          boxShadow: '0px 4px 8px #00000029',
          padding: '0.5rem',
          gap: '10px',
        }}
      >
        <Typography style={{ fontSize: '1em', fontWeight: 'bold', color: '#002A45' }}>
          Q. {question}
        </Typography>
        <Typography style={{ fontSize: '1em', color: '#002A45' }}>
          <b>A.</b> {ans}
        </Typography>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Box style={{ flexBasis: '50%', display: 'flex', justifyContent: 'flex-start' }}>
            <Box
              style={{
                width: '4rem',
                height: '2rem',
                backgroundColor: '#D3D3D3',
                borderRadius: '5px',
                boxShadow: '0px 4px 8px #00000029',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                highLightWord(ans);
              }}
            >
              <Typography style={{ fontSize: '1em', fontWeight: 'bold', color: '#002A45' }}>
                Show
              </Typography>
            </Box>
          </Box>
          <Box style={{ flexBasis: '50%', display: 'flex', justifyContent: 'flex-end' }}>
            {score === 'High' && (
              <div
                style={{
                  width: '4rem',
                  height: '2rem',
                  backgroundColor: '#19AA6E',
                  borderRadius: '5px',
                  boxShadow: '0px 4px 8px #00000029',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography style={{ fontSize: '1em', color: 'white' }}>{score}</Typography>
              </div>
            )}
            {score === 'Medium' && (
              <div
                style={{
                  width: '4rem',
                  height: '2rem',
                  backgroundColor: '#FF6633',
                  borderRadius: '5px',
                  boxShadow: '0px 4px 8px #00000029',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography style={{ fontSize: '1em', color: 'white' }}>{score}</Typography>
              </div>
            )}
            {score === 'Low' && (
              <div
                style={{
                  width: '4rem',
                  height: '2rem',
                  backgroundColor: '#E15A50',
                  borderRadius: '5px',
                  boxShadow: '0px 4px 8px #00000029',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography style={{ fontSize: '1em', color: 'white' }}>{score}</Typography>
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const Highlighter = ({ children, highlight }) => {
  if (!highlight) return children;
  const regexp = new RegExp(highlight, 'g');
  const matches = children.match(regexp);
  const parts = children.split(new RegExp(`${highlight.replace()}`, 'g'));

  for (let i = 0; i < parts.length; i += 1) {
    if (i !== parts.length - 1) {
      let match = matches[i];
      // While the next part is an empty string, merge the corresponding match with the current
      // match into a single <span/> to avoid consequent spans with nothing between them.
      while (parts[i + 1] === '') {
        match += matches[(i += 1)];
      }

      parts[i] = (
        <React.Fragment key={i}>
          {parts[i]}
          <span className="highlighted">{match}</span>
        </React.Fragment>
      );
    }
  }
  return <div className="highlighter">{parts}</div>;
};

const QnAGenerator = () => {
  const [text, setText] = useState('');
  const [question, setQuestion] = useState([]);
  const [words, setWords] = useState(0);
  // const [questionObj, setQuestionObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const [analysis, setAnalysis] = React.useState(true);
  const [summary, setSummary] = React.useState('');
  const [highlight, setHighlight] = React.useState('');
  const [edit, setEdit] = React.useState(true);
  const [summaryLength, setSummaryLength] = useState(0);
  const [payload, setPayload] = useState();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  function highLightWord(highLightAns) {
    setHighlight(highLightAns);
  }

  const callGenerator = () => {
    if (!text) {
      window.alert('Please Enter Text');
    } else {
      setDisplay(false);
      setLoading(true);
      const body = text.replaceAll('"', '');
      // const t = text.replace('"', '\\"');
      getQnAGenerator(body)
        .then((data) => {
          if (data) {
            setPayload(data);
            setQuestion(data?.autoqa);
            const SumLen = data?.summary.split(' ').length;
            setSummaryLength(SumLen);
            setSummary(data?.summary);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setDisplay(true);
          setLoading(false);
          setEdit(false);
        });
    }
  };

  const [downloadLink, setDownloadLink] = useState(null);

  useEffect(() => {
    let str = '';
    payload?.autoqa?.map((data) => {
      str += `${data.question} ${data.ans}. `;
      return str;
    });
    const dataString = JSON.stringify(str);
    const convertedData = convertDataToWordFormat(dataString);
    const blob = new Blob([convertedData], { type: 'application/msword' });
    const url = URL.createObjectURL(blob);
    setDownloadLink(url);

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [payload]);

  const convertDataToWordFormat = (dataString) => {
    // Convert JSON data to HTML or plain text here
    return dataString;
  };

  useEffect(() => {
    if (text) {
      const lenarry = text.split(' ');
      const filteredArray = lenarry.filter((data) => {
        return !isEmpty(data);
      });
      setWords(filteredArray.length);
    }
  }, [text]);

  return (
    <>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogContent dividers style={{ backgroundColor: 'green' }}>
          <Typography style={{ fontSize: '1em', color: 'white' }}>Copied</Typography>
        </DialogContent>
      </BootstrapDialog>
      <Box
        style={{
          width: '100%',
          padding: '5px',
          backgroundColor: '#EEF1F9',
          borderRadius: '5px',
          boxShadow: '0px 4px 8px #00000029',
        }}
      >
        <Box
          style={{
            display: 'flex',
            width: '100%',
            gap: '5px',
          }}
        >
          <Box
            style={{
              flexBasis: '50%',
              border: '1px solid #D1D9EF',
              display: 'flex',
              flexDirection: 'column',
              padding: '5px',
              minHeight: '40rem',
            }}
          >
            <Box style={{ backgroundColor: 'white', height: '100%', padding: '0.5rem' }}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  borderBottom: '1px solid #D1D9EF',
                  paddingBottom: '0.3rem',
                }}
              >
                <button
                  type="button"
                  className="toggleBtn2"
                  style={{
                    color: 'white',
                    backgroundColor: '#002A45',
                    borderRadius: '30px',
                    boxShadow: '0px 4px 8px #00000029',
                  }}
                >
                  Text
                </button>
              </Box>
              {!edit && (
                <div
                  style={{
                    width: '100%',
                    color: '#002A45',
                    fontSize: '1.2em',
                    fontFamily: 'Montserrat, sans-serif',
                  }}
                >
                  <Highlighter highlight={highlight}>{text}</Highlighter>
                </div>
              )}
              {edit && (
                <InputBase
                  value={text}
                  multiline
                  inputProps={{ maxLength: 6674 }}
                  placeholder="Please enter text here........"
                  style={{
                    width: '100%',
                    color: '#002A45',
                    fontSize: '1.2em',
                    letterSpacing: '0.0691em',
                    fontFamily: 'Montserrat, sans-serif',
                  }}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
              )}
            </Box>
            <Box style={{ display: 'flex' }}>
              <div style={{ flexBasis: '50%', backgroundColor: 'white', padding: '5px' }}>
                <div
                  style={{
                    display: 'flex',
                    padding: '0.5rem',
                    gap: '0.5rem',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    width: '8rem',
                    height: '100%',
                    fontSize: '1.2em',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                >
                  {text && (
                    <Typography>
                      {words} Word{words > 1 && 's'}
                    </Typography>
                  )}
                  {!text && (
                    <>
                      {' '}
                      <BackupOutlinedIcon />
                      <Typography>Upload Doc</Typography>{' '}
                    </>
                  )}
                </div>
              </div>
              <div
                style={{
                  flexBasis: '50%',
                  display: 'flex',
                  gap: '50px',
                  justifyContent: 'flex-end',
                  backgroundColor: 'white',
                  padding: '5px',
                }}
              >
                {display && !edit && (
                  <IconButton
                    aria-label="delete"
                    size="large"
                    onClick={() => {
                      setEdit(true);
                    }}
                  >
                    <EditIcon style={{ color: '#002A45' }} fontSize="inherit" />
                  </IconButton>
                )}

                {display && edit && (
                  <IconButton
                    aria-label="delete"
                    size="large"
                    onClick={() => {
                      setEdit(false);
                    }}
                  >
                    <EditOffIcon style={{ color: '#002A45' }} fontSize="inherit" />
                  </IconButton>
                )}

                <Button
                  type="button"
                  variant="contained"
                  // startIcon={loading && <CircularProgress color="success" size={20} />}
                  color="primary"
                  onClick={() => {
                    callGenerator();
                  }}
                >
                  {loading ? <CircularProgress color="success" size={20} /> : 'Analyze'}
                </Button>
              </div>
            </Box>
          </Box>
          <Box
            style={{
              flexBasis: '50%',
              border: '1px solid #D1D9EF',
              padding: '5px',
              minHeight: '40rem',
            }}
          >
            <Box style={{ backgroundColor: 'white', height: '100%', padding: '0.5rem' }}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  borderBottom: '1px solid #D1D9EF',
                  marginBottom: '10px',
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
                  <div className="buttonBox2">
                    <div style={analysis ? { left: '0px' } : { left: '100px' }} className="btn2">
                      {}
                    </div>
                    <button
                      type="button"
                      className="toggleBtn2"
                      style={analysis ? { color: 'white' } : {}}
                      onClick={() => {
                        if (!analysis) setAnalysis((prev) => !prev);
                      }}
                    >
                      Summary
                    </button>
                    <button
                      type="button"
                      className="toggleBtn2"
                      style={analysis ? {} : { color: 'white' }}
                      onClick={() => {
                        if (analysis) setAnalysis((prev) => !prev);
                      }}
                    >
                      QnA
                    </button>
                  </div>
                </div>
              </Box>
              <Box
                style={
                  question.length > 10
                    ? {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        maxHeight: '100%',
                        overflowY: 'auto',
                        backgroundColor: 'white',
                      }
                    : {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        height: '42rem',
                        overflowY: 'auto',
                        backgroundColor: 'white',
                      }
                }
              >
                {!display && (
                  <>
                    {[1, 2, 3, 4].map((data) => {
                      return (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                        </div>
                      );
                    })}
                  </>
                )}
                {display && (
                  <>
                    {analysis === true ? (
                      <Box
                        style={{
                          fontSize: '1.2em',
                          fontFamily: 'Montserrat, sans-serif',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          height: '42rem',
                          overflowY: 'auto',
                          backgroundColor: 'white',
                        }}
                      >
                        <Typography>{summary}</Typography>
                      </Box>
                    ) : (
                      question?.map((data, index) => {
                        return (
                          <QnAPairsCard
                            questions={data}
                            text={text}
                            highLightWord={highLightWord}
                          />
                        );
                      })
                    )}
                  </>
                )}
              </Box>
              {display && (
                <Box
                  style={{
                    display: 'flex',
                    padding: '5px',
                    gap: '15px',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    style={{
                      flexBasis: '50%',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      fontSize: '1.2em',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                    }}
                  >
                    {analysis === true && (
                      <Typography>
                        {summaryLength} Word{summaryLength > 1 && 's'}
                      </Typography>
                    )}
                  </Box>
                  <Box style={{ flexBasis: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton href={downloadLink}>
                      <GetAppOutlinedIcon style={{ color: '#002A45' }} />
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(summary);
                        setOpen(true);
                      }}
                    >
                      <ContentCopyOutlinedIcon style={{ color: '#002A45' }} />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default QnAGenerator;
