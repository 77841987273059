import React from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#002A45',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStylesForTable = makeStyles({
  tableNew: {
    minWidth: 700,
  },
});

export default function BDCidca({ arr }) {
  const classesForTable = useStylesForTable();
  return (
    <TableContainer component={Paper}>
      <Table className={classesForTable.tableNew} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Attribute</StyledTableCell>
            <StyledTableCell align="left">Comparison Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {arr.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell
                style={{
                  borderRight: '3px solid #002A45',
                }}
                align="left"
              >
                {row.name}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={
                  // eslint-disable-next-line no-nested-ternary
                  row.status === 'okay'
                    ? { color: 'green' }
                    : row.status === 'warning'
                    ? { color: 'black' }
                    : { color: 'red' }
                }
              >
                {row.message}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
