import React, { useEffect, useState } from 'react';
import ContentSearchResult from '../TextAnalysisResultContainer/ContentSearchResult';
import './transitionCss.css';

const Test = ({ input, result }) => {
  // console.log('row rest test', input, result);
  const [expertDomain, setExpertDomain] = useState<any>([]);
  const [smartData, setSmartData] = useState<any>();
  const [TotalPeopleResult, setTotalPeopleResult] = useState<any>();
  const [resultMain, setPayloadMain] = useState() as any;
  const [artifactKpi, setArtifactKpi] = useState<any>([]);
  const [rows, setRows] = React.useState([]);
  const [language, setLanguage] = React.useState();
  const [detectLanguage, setDetectLanguage] = React.useState();
  const [timeTaken, setTimeTaken] = useState<any>();
  const [totalResult, setTotalResult] = useState<any>();

  const [alstomEntityWiseDistribution, setAlstomEntityWiseDistribution] = useState<any>([]);
  const [platformWiseDistribution, setPlatformWiseDistribution] = useState<any>([]);
  const [projectWiseDistribution, setProjectWiseDistribution] = useState<any>([]);
  const [documentProfileWiseDistribution, setDocumentProfileWiseDistribution] = useState<any>([]);
  const [solutionWiseDistribution, setSolutionWiseDistribution] = useState<any>([]);
  const [countryWiseDistribution, setCountryWiseDistribution] = useState<any>([]);
  const [productWiseDistribution, setProductWiseDistribution] = useState<any>([]);
  const [artifactWiseDistribution, setArticatWiseDistribution] = useState<any>([]);
  const [searchPayload, setSearchPayload] = useState<any>();

  useEffect(() => {
    // setRows(result?.searchResults);
    setLanguage(result?.translation?.detectedLanguage);
    setDetectLanguage(result?.translation?.translatedText);
    const searchValue = 'Query Search';
    const r: any = result.searchResults;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < r.length; i++) {
      // eslint-disable-next-line dot-notation
      r[i]['id'] = i;
    }
    // setINerUsableData(JSON.stringify(r));
    setSearchPayload(JSON.stringify(r));
    const startTime = performance.now();
    const endTime = performance.now();
    const t = ((endTime - startTime) / 3600).toFixed(2);
    setTimeTaken(t);
    const count1 =
      result?.visualizationData?.productLineWiseDistribution?.['Digital & Integrated Systems'] || 0;
    const count2 =
      result?.visualizationData?.productLineWiseDistribution?.['Rolling Stock & Components'] || 0;
    const count = count1 + count2;
    setTotalResult(result?.numberOfResultsFound);
    // for graph code starts here
    const keysAlstomEntityWiseDistribution = Object.keys(
      searchValue === 'Query Search'
        ? result.visualizationData?.productLineWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.productLineWiseDistribution
    );
    const valuesAlstomEntityWiseDistribution = Object.values(
      searchValue === 'Query Search'
        ? result.visualizationData.productLineWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.productLineWiseDistribution
    );
    const alstomEntityWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysAlstomEntityWiseDistribution.length; index++) {
      if (valuesAlstomEntityWiseDistribution[index] !== 0) {
        alstomEntityWiseDistribution_array.push({
          name: keysAlstomEntityWiseDistribution[index],
          value: valuesAlstomEntityWiseDistribution[index],
        });
      }
    }
    setAlstomEntityWiseDistribution(alstomEntityWiseDistribution_array);
    // second graph
    const keysPlatformWiseDistribution = Object.keys(
      searchValue === 'Query Search'
        ? result.visualizationData.platformWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.platformWiseDistribution
    );
    const valuesPlatformWiseDistribution = Object.values(
      searchValue === 'Query Search'
        ? result.visualizationData.platformWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.platformWiseDistribution
    );
    const platformWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysPlatformWiseDistribution.length; index++) {
      if (valuesPlatformWiseDistribution[index] !== 0) {
        platformWiseDistribution_array.push({
          name: keysPlatformWiseDistribution[index],
          value: valuesPlatformWiseDistribution[index],
        });
      }
    }
    setPlatformWiseDistribution(platformWiseDistribution_array);
    const keysProjectWiseDistribution = Object.keys(
      searchValue === 'Query Search'
        ? result.visualizationData.projectWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.projectWiseDistribution
    );
    const valuesProjectWiseDistribution = Object.values(
      searchValue === 'Query Search'
        ? result.visualizationData.projectWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.projectWiseDistribution
    );
    const projectWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysProjectWiseDistribution.length; index++) {
      if (valuesProjectWiseDistribution[index] !== 0) {
        projectWiseDistribution_array.push({
          name: keysProjectWiseDistribution[index],
          value: valuesProjectWiseDistribution[index],
        });
      }
    }
    setProjectWiseDistribution(projectWiseDistribution_array);
    // third graph documentProfileWiseDistribution
    const keysdocumentProfileWiseDistribution = Object.keys(
      searchValue === 'Query Search'
        ? result.visualizationData.documentProfileWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.documentProfileWiseDistribution
    );
    const valuesdocumentProfileWiseDistribution = Object.values(
      searchValue === 'Query Search'
        ? result.visualizationData.documentProfileWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.documentProfileWiseDistribution
    );
    const documentProfileWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysdocumentProfileWiseDistribution.length; index++) {
      if (valuesdocumentProfileWiseDistribution[index] !== 0) {
        documentProfileWiseDistribution_array.push({
          name: keysdocumentProfileWiseDistribution[index],
          value: valuesdocumentProfileWiseDistribution[index],
        });
      }
    }
    setDocumentProfileWiseDistribution(documentProfileWiseDistribution_array);
    // Fourth Graph
    const keysArtifactWiseDistribution = Object.keys(
      searchValue === 'Query Search'
        ? result.visualizationData.artifactWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.artifactWiseDistribution
    );
    const valuesArtifactWiseDistribution = Object.values(
      searchValue === 'Query Search'
        ? result.visualizationData.artifactWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.artifactWiseDistribution
    );
    const artifactWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysArtifactWiseDistribution.length; index++) {
      if (valuesArtifactWiseDistribution[index] !== 0) {
        artifactWiseDistribution_array.push({
          name: keysArtifactWiseDistribution[index],
          value: valuesArtifactWiseDistribution[index],
        });
      }
    }
    setArticatWiseDistribution(artifactWiseDistribution_array);
    // Fiffth graph documentProfileWiseDistribution
    const keysdocumentSolutionWiseDistribution = Object.keys(
      searchValue === 'Query Search'
        ? result.visualizationData.solutionWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.solutionWiseDistribution
    );
    const valuesdocumentSolutionWiseDistribution = Object.values(
      searchValue === 'Query Search'
        ? result.visualizationData.solutionWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.solutionWiseDistribution
    );
    const solutionWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysdocumentSolutionWiseDistribution.length; index++) {
      if (valuesdocumentSolutionWiseDistribution[index] !== 0) {
        solutionWiseDistribution_array.push({
          name: keysdocumentSolutionWiseDistribution[index],
          value: valuesdocumentSolutionWiseDistribution[index],
        });
      }
    }
    setSolutionWiseDistribution(solutionWiseDistribution_array);
    // sixth graph documentProfileWiseDistribution
    const keysProductWiseDistribution = Object.keys(
      searchValue === 'Query Search'
        ? result.visualizationData.productWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.productWiseDistribution
    );
    const valuesProductWiseDistribution = Object.values(
      searchValue === 'Query Search'
        ? result.visualizationData.productWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.productWiseDistribution
    );
    const productWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysProductWiseDistribution.length; index++) {
      if (valuesProductWiseDistribution[index] !== 0) {
        productWiseDistribution_array.push({
          name: keysdocumentSolutionWiseDistribution[index],
          value: valuesdocumentSolutionWiseDistribution[index],
        });
      }
    }
    setProductWiseDistribution(productWiseDistribution_array);
    // Fiffth graph documentProfileWiseDistribution
    const keysCountryWiseDistribution = Object.keys(
      searchValue === 'Query Search'
        ? result.visualizationData.countryWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.countryWiseDistribution
    );
    const valuesCountryWiseDistribution = Object.values(
      searchValue === 'Query Search'
        ? result.visualizationData.countryWiseDistribution
        : result.individualResults[
            input.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          ]?.visualizationData?.countryWiseDistribution
    );
    const countryWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysCountryWiseDistribution.length; index++) {
      if (valuesCountryWiseDistribution[index] !== 0) {
        countryWiseDistribution_array.push({
          name: keysCountryWiseDistribution[index],
          value: valuesCountryWiseDistribution[index],
        });
      }
    }
    setCountryWiseDistribution(countryWiseDistribution_array);
  }, [result]);

  return (
    <div>
      <ContentSearchResult
        label="rex360"
        rowResult={result?.searchResults}
        contentSearchPayload={result}
        input={input}
        alstomEntityWiseDistribution={alstomEntityWiseDistribution}
        platformWiseDistribution={platformWiseDistribution}
        projectWiseDistribution={projectWiseDistribution}
        documentProfileWiseDistribution={documentProfileWiseDistribution}
        solutionWiseDistribution={solutionWiseDistribution}
        countryWiseDistribution={countryWiseDistribution}
        productWiseDistribution={productWiseDistribution}
        timeTaken={timeTaken}
        totalResult={totalResult}
      />
    </div>
  );
};

export default Test;
