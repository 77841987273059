import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import ExcelDoc from '../../../../../assets/icons8-excel-48.png';
import PdfDoc from '../../../../../assets/pdf_ins.png';
import PptDoc from '../../../../../assets/icons8-powerpoint-48.png';
import DocxDoc from '../../../../../assets/icons8-microsoft-word-2019-48.png';
import CsvDoc from '../../../../../assets/csv_icons.png';
import docIcon from '../../../../../assets/docx_ico.png';
import pdfIcon from '../../../../../assets/pdf_ico.png';
import xlsIcon from '../../../../../assets/xlsx_ico.png';
import CSVFileSettings from './CSVFileSettings';
import ExcelFileSettings from './ExcelFileSettings';
import CaptureNameSettings from './CaptureNameSettings';
import PdfFileSettings from './PdfFileSettings';
import RexAnalysisContext from '../../RexAnalysisContext';
// import DocAnalysisContext from '../../../Knowledge360/DocAnalysisContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
`;

const LeftBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const RightBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const MainDiv = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  flex-direction: column;
  gap: 20px;
`;

const Header = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
`;

const FileSettingsContainer = ({ serivceName }) => {
  const {
    scopeSetting,
    selection,
    setSelection,
    setScopeSetting,
    fileSetting,
    setFileSettings,
  } = useContext(RexAnalysisContext);

  useEffect(() => {
    setFileSettings((prev) => {
      return {
        ...prev,
        serivce: serivceName,
      };
    });
  }, []);

  return (
    <>
      <Container>
        <Header>
          <SettingsApplicationsOutlinedIcon style={{ width: 40, height: 40 }} />
          <h2>Scope Settings</h2>
        </Header>
        <MainDiv>
          {scopeSetting.map((data: { FileType: string }, index) => {
            // console.log(data.FileType);
            if (data.FileType === 'Excel')
              return <ExcelFileSettings index={index} settingsData={[data]} />;
            if (data.FileType === 'CSV')
              return <CSVFileSettings index={index} settingsData={[data]} />;
            if (data.FileType === 'PDF')
              return <PdfFileSettings index={index} settingsData={[data]} />;
            return null;
          })}
        </MainDiv>
        <CaptureNameSettings />
      </Container>
    </>
  );
};

export default FileSettingsContainer;
