/* eslint-disable react/destructuring-assignment */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  Table,
  PagingPanel,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import {
  Button,
  CircularProgress,
  fade,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import { GridExporter } from '@devexpress/dx-react-grid-export';

import Iframe from 'react-iframe';
import { makeStyles, withStyles } from '@material-ui/styles';
import Module1Context from '../Module4Context';
import formatBytes from '../../../tools/formatBytes';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import { fileDetails } from '../../../api/module1';
import TStyles, { rowComponent, tUseStyles } from '../../../components/Datagrid/TStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
// table header
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

// table Filter style

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
  { name: 'userName', title: 'Uploaded by' },
];

function ITracer() {
  const classes = useStyles();
  const tableUseStyles = tUseStyles();
  const { project } = useContext(Module1Context);
  const [files, setFiles] = useState<any[]>([]);
  const [selection, setSelection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const [apiData, setApiData] = useState<any>({});
  const [pageSizes] = useState([5, 10, 20, 50]);

  useEffect(() => {
    const { folders } = project;
    const allResources = [] as any[];
    if (folders) {
      for (const eachFolder of folders) {
        if (eachFolder.resources && eachFolder.resources.length > 0)
          for (const r of eachFolder.resources) {
            // get file extension(only xls, xlsx, csv) before that convert to lower case
            const fileExtension = r.filename.toLowerCase().split('.').pop();
            if (fileExtension === 'zip')
              allResources.push({
                ...r,
                size: formatBytes(Number(r.size), 0),
                id: r.etag,
                folderName: eachFolder.name,
                folderId: eachFolder.id,
              });
          }
      }
    }
    setFiles(allResources);
  }, []);

  let fileIs1: any;
  let fileContainer1: any;
  if (selection.length > 0 && selection[0] !== undefined && selection.length < 2) {
    fileIs1 = files[selection[0]].filename || '';
    fileContainer1 = files[selection[0]].container || '';
    // console.log(fileIs1);
  }

  function callIframeAPI() {
    // console.log(fileIs1);
    setDisplay(false);
    setTimeout(() => {
      setDisplay(true);
      setApiData(
        // eslint-disable-next-line max-len
        `https://isens-m4.azurewebsites.net/explorer/banner=true&card=true/container=${fileContainer1}&blob_name=${fileIs1}`
      );
    }, 1000);
  }

  const onChangeAttribute = (selection: any) => {
    const newselection: any = [0];
    if (selection[0] === undefined) {
      // eslint-disable-next-line no-param-reassign
      selection[0] = selection.slice(-1).pop();
    }
    // eslint-disable-next-line prefer-destructuring
    newselection[0] = selection[1];
    setSelection(newselection);
    if (selection.length === 1) {
      // console.log('if condition no action required: ', selection);
    } else {
      // console.log('else', selection.slice(-1).pop());
      const fileno = selection.slice(-1).pop();
      // getFileDetails(files[fileno].filename);
    }
    // console.log(files[selection[0]]);
  };

  return (
    <>
      <div>
        <span>
          <b>Select one zip file:</b>
        </span>
        <span>
          <br />
          {/* Total rows selected: {selection.length} */}
          <span style={{ marginRight: '10px' }}>
            Selected File Name:<b>{fileIs1 || ''}</b>
          </span>
        </span>
        <br />
      </div>
      <div>
        <Paper className={tableUseStyles.panelMain}>
          <ExpressGrid rows={files} columns={columns}>
            <DataTypeProvider
              for={['filename']}
              formatterComponent={({ value }) => <span title={value}>{value}</span>}
            />
            <SelectionState selection={selection} onSelectionChange={onChangeAttribute} />
            <FilteringState defaultFilters={[{ columnName: 'filename', value: '' }]} />
            <IntegratedFiltering />
            <SortingState />
            <IntegratedSorting />
            <PagingState defaultCurrentPage={0} defaultPageSize={5} />
            <IntegratedPaging />
            <VirtualTable />
            <Table tableComponent={TableComponent} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
            <PagingPanel pageSizes={pageSizes} />
          </ExpressGrid>

          {/* <span style={{ float: 'right', marginRight: '10px' }}>
            Selected File Name: <b>{fileIs1 || 'NA'}</b>
          </span> */}
          <br />
          {/* {loading ? (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            ''
          )} */}
          <Button
            type="button"
            disabled={fileIs1 === 'NA' || fileIs1 === undefined}
            variant="contained"
            color="primary"
            onClick={callIframeAPI}
            style={{
              marginTop: '10px',
              height: '50px',
              width: '150px',
              float: 'right',
              marginRight: '10px',
              marginBottom: '10px',
            }}
          >
            Analyse
          </Button>
        </Paper>
        <br />
        {display && (
          <Grid>
            <Iframe
              url={apiData}
              width="99.99%"
              height="800px"
              id="myId"
              className="myClassname"
              // display="initial"
              position="relative"
              overflow="hidden"
              scrolling="no"
            />
          </Grid>
        )}
      </div>
    </>
  );
}

export default ITracer;
