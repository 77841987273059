import React from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#002A45',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      verticalAlign: 'top',
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStylesForTable = makeStyles({
  tableNew: {
    minWidth: 700,
  },
});

export default function DSCidca({ arr2, arr3, arr4 }) {
  const classesForTable = useStylesForTable();

  function printDetails(arrayInput) {
    const ret = arrayInput.map((item) => {
      return (
        <>
          <ul>
            <li
              style={
                // eslint-disable-next-line no-nested-ternary
                item.status === 'identical_hading'
                  ? { color: 'green' }
                  : // eslint-disable-next-line no-nested-ternary
                  item.status === 'moved_heading'
                  ? { color: 'black' }
                  : item.status === 'removed_heading'
                  ? { color: 'red' }
                  : { color: 'blue' }
              }
            >
              {item.headingName}
              {
                // eslint-disable-next-line react/no-unescaped-entities
                item?.children.length > 0
                  ? item.children.map((i: any) => (
                      <ul>
                        <li
                          style={
                            // eslint-disable-next-line no-nested-ternary
                            i.status === 'identical_hading'
                              ? { color: 'green' }
                              : // eslint-disable-next-line no-nested-ternary
                              i.status === 'moved_heading'
                              ? { color: 'black' }
                              : i.status === 'removed_heading'
                              ? { color: 'red' }
                              : { color: 'blue' }
                          }
                        >
                          {i.headingName}
                          {
                            // eslint-disable-next-line react/no-unescaped-entities
                            i?.children.length > 0
                              ? i.children.map((i2: any) => (
                                  <ul>
                                    <li
                                      style={
                                        // eslint-disable-next-line no-nested-ternary
                                        i2.status === 'identical_hading'
                                          ? { color: 'green' }
                                          : // eslint-disable-next-line no-nested-ternary
                                          i2.status === 'moved_heading'
                                          ? { color: 'black' }
                                          : i2.status === 'removed_heading'
                                          ? { color: 'red' }
                                          : { color: 'blue' }
                                      }
                                    >
                                      {i2.headingName}
                                      {
                                        // eslint-disable-next-line react/no-unescaped-entities
                                        i2?.children.length > 0
                                          ? i2.children.map((i3: any) => (
                                              <ul>
                                                <li
                                                  style={
                                                    // eslint-disable-next-line no-nested-ternary
                                                    i3.status === 'identical_hading'
                                                      ? { color: 'green' }
                                                      : // eslint-disable-next-line no-nested-ternary
                                                      i3.status === 'moved_heading'
                                                      ? { color: 'black' }
                                                      : i3.status === 'removed_heading'
                                                      ? { color: 'red' }
                                                      : { color: 'blue' }
                                                  }
                                                >
                                                  {i3.headingName}
                                                </li>
                                              </ul>
                                            ))
                                          : ''
                                      }
                                    </li>
                                  </ul>
                                ))
                              : ''
                          }
                        </li>
                      </ul>
                    ))
                  : ''
              }
            </li>
          </ul>
        </>
      );
    });
    return ret;
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classesForTable.tableNew} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Template Document</StyledTableCell>
            <StyledTableCell align="left">Project Document</StyledTableCell>
            <StyledTableCell align="left">Corrected Document Proposal</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell align="left">{printDetails(arr2)}</StyledTableCell>
            <StyledTableCell
              style={{
                borderLeft: '3px solid #002A45',
                borderRight: '3px solid #002A45',
              }}
              align="left"
            >
              {printDetails(arr3)}
            </StyledTableCell>
            <StyledTableCell align="left">{printDetails(arr4)}</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
