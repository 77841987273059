import { Column } from '@devexpress/dx-react-grid';
import React from 'react';
import { columnFuncType, optionsColumnsType } from './allColumns';

const commonColumns = [
  {
    name: 'System Entities',
    title: 'System Entities',
  },
  {
    name: 'Activities',
    title: 'Activities',
  },
  {
    name: 'Acronyms',
    title: 'Acronyms',
  },
  {
    name: 'Cybersecurity',
    title: 'Cyber Security',
  },
  {
    name: 'Standards',
    title: 'Standards',
  },
  {
    name: 'project_management',
    title: 'Project Management',
  },
  {
    name: 'Commercial',
    title: 'Commercial',
  },
  {
    name: 'Finance',
    title: 'Finance',
  },
  {
    name: 'Contractual',
    title: 'Contractual',
  },
  {
    name: 'Context',
    title: 'Context',
  },
  // {
  //   name: 'History',
  //   title: 'History',
  // },
  // {
  //   name: 'kpi_lifecycle',
  //   title: 'Lifecycles',
  // },
  {
    name: 'assigment',
    title: 'Multi assigment',
  },
  {
    name: 'safety',
    title: 'Safety',
  },
  {
    name: 'category',
    title: 'Category',
  },
  {
    name: 'testable',
    title: 'Testable',
  },
  {
    name: 'contribution',
    title: 'Contribution',
  },
  {
    name: 'type',
    title: 'Type',
  },
  {
    name: 'Critical To Customer',
    title: 'Critical To Customer',
  },
  {
    name: 'Stability',
    title: 'Stability',
  },
  {
    name: 'Validation Method',
    title: 'Validation Method',
  },
  {
    name: 'Source',
    title: 'Source',
  },
  {
    name: 'Rationale',
    title: 'Rationale',
  },
  {
    name: 'Validation Evidence',
    title: 'Validation Evidence',
  },
  {
    name: 'Char_Score',
    title: 'Char Score',
  },
  {
    name: 'comment',
    title: 'Comment',
  },
];

export const columnsOwn: columnFuncType = (
  optionColumns: optionsColumnsType,
  isExported = false
): (Column | string)[] => {
  const { targetColumn, displayColumns, isRegExp, entity, levelsHierarchy } = optionColumns;
  const breakdownColumns =
    entity === 'RSC'
      ? [
          {
            name: 'pbs',
            title: 'SBS',
          },

          {
            name: 'obs',
            title: 'OBS',
          },
        ]
      : [
          {
            name: 'pbs',
            title: 'PBS',
          },
          {
            name: 'abs',
            title: 'ABS',
          },
          {
            name: 'obs',
            title: 'OBS',
          },
        ];

  const regExpCol = [];
  if (isRegExp) {
    regExpCol.push('ReqID');
  }

  // export into EXCEL
  if (isExported) {
    if (targetColumn) {
      return [
        'id',
        ...Array.from(new Set([targetColumn, ...displayColumns])),
        'Characterization',
        'Original_Hierarchy',
        {
          name: 'kpi_lifecycle',
          title: 'Lifecycles',
        },
        {
          name: 'lifecycle',
          title: 'Lifecycle Multi',
        },
        ...breakdownColumns,
        'receivers',
        ...commonColumns,
      ];
    }
    return [
      'id',
      ...regExpCol,
      'Text',
      'Characterization',
      'Original_Hierarchy',
      ...levelsHierarchy,
      {
        name: 'kpi_lifecycle',
        title: 'Lifecycles',
      },
      {
        name: 'lifecycle',
        title: 'Lifecycle Multi',
      },
      ...breakdownColumns,
      'receivers',
      ...commonColumns,
    ];
  }

  // guess its during excel
  if (targetColumn) {
    return [
      // 'Menu',
      'id',
      ...Array.from(new Set([targetColumn, ...displayColumns])),
      'Characterization',
      'Original_Hierarchy',
      ...levelsHierarchy,
      {
        name: 'kpi_lifecycle',
        title: 'Lifecycles',
      },
      'clarify',
      {
        name: 'own',
        title: 'Own multi',
      },
      ...commonColumns,
    ];
  }

  // default PDF
  return [
    // 'Menu',
    'id',
    ...regExpCol,
    'Text',
    'Characterization',
    'PageNo',
    'Hierarchy',
    'Original_Hierarchy',
    ...levelsHierarchy,
    'History',
    {
      name: 'kpi_lifecycle',
      title: 'Lifecycles',
    },
    'clarify',
    {
      name: 'own',
      title: 'Own multi',
    },
    'SubType',
    ...commonColumns,
  ];
};

export const columnsOwnMulti: columnFuncType = (
  optionColumns: optionsColumnsType
): (Column | string)[] => {
  const { entity } = optionColumns;
  const breakdownColumns =
    entity === 'RSC'
      ? [
          {
            name: 'pbs',
            title: 'SBS',
          },

          {
            name: 'obs',
            title: 'OBS',
          },
        ]
      : [
          {
            name: 'pbs',
            title: 'PBS',
          },
          {
            name: 'abs',
            title: 'ABS',
          },
          {
            name: 'obs',
            title: 'OBS',
          },
        ];
  return [
    {
      name: 'selectionCustom',
      title: 'Select',
    },
    {
      name: 'lifecycle',
      title: 'Lifecycle Multi',
    },
    ...breakdownColumns,
    {
      name: 'receiver', // non plurial for allocation
      title: 'Receivers',
    },
  ];
};
