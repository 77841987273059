import isEqual from 'react-fast-compare';
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { IverifierConfigWrite } from 'WorkloadType';

export interface Run3ContextType {
  runId: string;
  setRunId: (d: any) => any;
  config: IverifierConfigWrite;
  setConfig: (d: IverifierConfigWrite) => any;
}

const Run3Context = React.createContext<Run3ContextType>({
  runId: '',
  setRunId: (): any => {},
  config: {} as IverifierConfigWrite,
  setConfig: () => {},
});

export const configInit = {
  file_separator: ',',
};

const Run3ContextProvider = ({ children }: { children: any }): any => {
  const [runId, setRunId] = useState<string>('');
  const [config, setConfig] = useState<IverifierConfigWrite>(configInit);
  return (
    <Run3Context.Provider
      value={{
        runId,
        setRunId: (_input) => {
          if (!isEqual(runId, _input)) setRunId(_input);
        },
        config,
        setConfig: (_input) => {
          if (!isEqual(config, _input)) setConfig(_input);
        },
      }}
    >
      {children}
    </Run3Context.Provider>
  );
};

export default Run3Context;

export { Run3ContextProvider };
