/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Grid, Modal, Paper, Typography } from '@mui/material';
import { Button, Divider } from '@material-ui/core';
import {
  BoxObjectTree,
  ContainerObjectTree,
  ContentObjectTree,
  HeaderObjectTree,
  ModalObjectTree,
  ModalPaperObjectTree,
  ObjectivesObjectTree,
  TextObjectiveObjectTree,
} from '../styles';

interface Node {
  First_Name: string;
  Last_Name: string;
  Objectives: string;
  Level: string;
  children?: Node[];
}

// Color array for dynamic coloring
const colors = ['#DC3223', '#19AA6E', '#002A45'];

const ObjectiveTree: React.FC<{ node: Node; index: number }> = ({ node, index }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleObjectivesClick = (objectives: string) => {
    if (objectives.length > 150) {
      setModalContent(objectives);
      setModalOpen(true);
    }
  };

  return (
    <ContainerObjectTree>
      <BoxObjectTree>
        <HeaderObjectTree style={{ backgroundColor: colors[index % colors.length] }}>
          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
            {`${node.First_Name} ${node.Last_Name} (${node.Level})`}
          </Typography>
        </HeaderObjectTree>
        <ContentObjectTree>
          <ObjectivesObjectTree onClick={() => handleObjectivesClick(node.Objectives)}>
            {node.Objectives.length > 150 ? (
              <div dangerouslySetInnerHTML={{ __html: `${node.Objectives.slice(0, 150)}...` }} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: node.Objectives }} />
            )}
          </ObjectivesObjectTree>
        </ContentObjectTree>
      </BoxObjectTree>
      {node.children && node.children.length > 0 && (
        <>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            {node.children.map((child, index) => (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ObjectiveTree key={index} node={child} index={index} />
              </div>
            ))}
          </div>
        </>
      )}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalObjectTree>
          <ModalPaperObjectTree>
            <TextObjectiveObjectTree>
              <Typography variant="h3">Objectives</Typography>
            </TextObjectiveObjectTree>
            <div style={{ fontSize: '1rem' }} dangerouslySetInnerHTML={{ __html: modalContent }} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModalOpen(false)}
              style={{ marginTop: '20px', marginLeft: 'auto' }}
            >
              Close
            </Button>
          </ModalPaperObjectTree>
        </ModalObjectTree>
      </Modal>
    </ContainerObjectTree>
  );
};

export default ObjectiveTree;
