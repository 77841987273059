/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import isEqual from 'react-fast-compare';
import { Workload3Create } from 'WorkloadType';
import { ScopeOutput } from '../../api/module1';

export const settingsDefault: SettingsRun = {
  StartPage: 1,
  EndPage: 100,
  HeaderMargin: 0,
  FooterMargin: 0,
};

export interface SettingsRun {
  FileName?: string;
  FilePath?: string;
  FileType?: string;
  Language?: string;
  Container?: string;
  BlobName?: string;
  StartPage?: number;
  EndPage?: number;
  HeaderMargin?: number;
  FooterMargin?: number;
  NameOfCapture?: string;
  TypesOfCapture?: string;
  RegId?: string;
  AutoHeaderFooterDetection?: boolean;
  TableSwitch?: any;
  ExtractTables?: any;
  MergeBullet?: any;
  SmartHeaderFooter?: any;
  SmartTableOfContent?: any;
  LevelWiseHierarchy?: any;
  // csv excel
  ColumnNames?: any;
  TargetColumn?: any;
  Header?: boolean;
}

export interface SettingsForm {
  dataframe?: any;
  fileName?: string;
  filePath?: string;
  fileType?: string;
  language: string;
  captureAll?: any;
  startPage?: number;
  endPage?: number;
  headerMargin?: number;
  footerMargin?: number;
  nameOfCapture?: string;
  typesOfCapture?: string;
  regId?: any;
  autoHeaderFooterDetection?: boolean;
  extractTables?: any;
  mergeBullet?: any;
  smartHeaderFooter?: any;
  smartTableOfContent?: any;
  LevelWiseHierarchy?: any;
  // csv excel
  columnNames?: any;
  targetColumn?: any;
  sheetname?: any;
  Header?: any;
}

export interface Resource {
  scopeMessage?: string;
  scopeError?: string;
  filename: string;
  etag?: string;
  container?: string;
  date?: string;
  version?: string;
  size?: string;
  id?: string;
  folderName?: string;
  folderId?: number;
  scope: ScopeOutput; // default values  of settings
  settings?: SettingsForm; // form settings
  settingsModule?: SettingsRun; // module settings
  results?: any;
  ownings?: any;
}

export interface Run1ContextType {
  workload?: Workload3Create;
  setWorkload?: (d: any) => any;
  resources: Resource[];
  setResources: (d: any) => any;
  configGlobal: any;
  setConfigGlobal: (d: any) => any;
  toggleCapture: boolean;
  setToggleCapture: (d: boolean) => any;
}

const Run1Context = React.createContext<Run1ContextType>({
  resources: [],
  setResources: (): any => {},
  configGlobal: '',
  setConfigGlobal: (): any => {},
  toggleCapture: true,
  setToggleCapture: (): any => {},
});

const Run1ContextProvider = ({
  configGlobal: _configGlobal,
  workload: _workload,
  resources: _resources,
  children,
}: {
  configGlobal?: any;
  resources?: any;
  workload?: Workload3Create;
  children: any;
}): any => {
  const [workload, setWorkload] = useState(_workload);
  const [resources, setResources] = useState(_resources);
  const [configGlobal, setConfigGlobal] = useState<string>(_configGlobal);
  const [toggleCapture, setToggleCapture] = useState(true);
  return (
    <Run1Context.Provider
      value={{
        workload,
        setWorkload,
        resources,
        setResources: (_input) => {
          if (!isEqual(resources, _input)) setResources(_input);
        },
        configGlobal,
        setConfigGlobal: (_input) => {
          if (!isEqual(configGlobal, _input)) setConfigGlobal(_input);
        },
        toggleCapture,
        setToggleCapture,
      }}
    >
      {children}
    </Run1Context.Provider>
  );
};

export default Run1Context;

export { Run1ContextProvider };
