import React, { useState, useMemo } from 'react';
import InputLabel from '@mui/material/InputLabel';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ColumnDef,
  MRT_ToggleDensePaddingButton as ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton as ToggleFullScreenButton,
  // MRT_ShowHideColumnsButton as ShowHideColumnsButton,
  MRT_GlobalFilterTextField as GlobalFilterTextField,
} from 'material-react-table';
import { Box, Button, Checkbox, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
  AddCircle as AddCircleIcon,
  Send as SendIcon,
  PlaylistAdd as PlaylistAddIcon,
  Merge as MergeIcon,
  CallSplit,
  HorizontalSplit as HorizontalSplitIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  ControlPoint as ControlPointIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  Save as SaveIcon,
  FactCheck as FactCheckIcon,
  ViewColumn as ViewColumnIcon,
} from '@mui/icons-material';
import stylesRequiremt from '../../Styles/requirement';
import { extractABS, extractOBS, extractUserName, extractUsersList } from '../../utils/extractdata';
import Toogle from '../../components/Toggle';
import { charScoreColor, lifeCycle } from '../../const/allocatorConst';
import FullScreenToogle from '../../components/FullScreenToogle';
import HoverToolTipForText from '../../Modal/HoverToolTipForText';
import { ButtonMUI, IOSSwitch, FormControl } from '../../Styles/utils';
import Refresh from '../../components/Refresh';
import useIconsForTable from '../../Hooks/useIconsForTable';
import ShowHideColumnsButton from '../../core/ShowHideColumnsButton';
import useRequirementColumn from './useRequirementColumn';
import hiddenColumns from '../../const/allData';

function useRequirement(args) {
  const {
    data,
    loading,
    List_of_pbs,
    groups,
    users,
    onChangeToggle,
    toggleTable,
    isFullScreen,
    onChangeFullScreen,
    rowSelection,
    setRowSelection,
    editedUsers,
    setEditedUsers,
    favouriteToggle,
    setFavouriteToggle,
    AddAllocation,
    handleSaveAllocation,
    handleAssingAllocation,
    herarchySelection,
    deleteAllocation,
    targetColumn,
    onChangeModalData,
    refreshDATA,
    displayColumns,
    levelsHierarchy,
    type,
  } = args;

  const textColum = targetColumn ?? 'Text';
  const selectedColumns = displayColumns ?? [];

  const { columns } = useRequirementColumn({
    targetColumn,
    favouriteToggle,
    setEditedUsers,
    editedUsers,
    List_of_pbs,
    displayColumns,
    levelsHierarchy,
    type,
    onChangeModalData,
    herarchySelection,
    data,
    groups,
    users,
  });

  const checkDeleteStatus = (table: any) => {
    const { rows } = table.getSelectedRowModel();
    // const { original } = rows;

    const temp = rows.map((Data) => {
      return Data.original;
    });

    if (temp.length === 1 && temp[0].lenOfAllocations === 1) {
      return true;
    }

    const values = Object.keys(table.getState().rowSelection).filter((data) => {
      return data.split(':').length === 1;
    });

    return values.length !== 1;
  };

  const checkAssginStatus = (table) => {
    const { rows } = table.getSelectedRowModel();

    const values = Object.keys(table.getState().rowSelection).filter((data) => {
      return data.split(':').length === 1;
    });

    return values.length === 0;
  };

  const { IconsForTable } = useIconsForTable();

  // console.log('displayColumns', type);

  const requirementObjcts = useMemo(
    () => ({
      columns,
      data,
      autoResetPageIndex: false,
      enableColumnResizing: true,
      enableGrouping: true,
      enableStickyHeader: true,
      enableRowSelection: (row) => !['analyzed'].includes(row.original.lifecycle),

      editDisplayMode: 'table',
      enableEditing: true,
      enableRowActions: true,
      enableColumnDragging: false,
      enableColumnPinning: true,
      positionToolbarAlertBanner: 'none',
      paginationDisplayMode: 'pages',
      getRowId: (row) => row.rowId,
      ...stylesRequiremt,
      // filterFns: {
      //   customFn: (row, _columnIds, filterValue) => {
      //     console.info('customFn', row, _columnIds, filterValue);
      //     return row.getValue<string>('state').toLowerCase().startsWith(filterValue.toLowerCase());
      //   },
      // },
      onRowSelectionChange: setRowSelection, // connect internal row selection state to your own
      initialState: {
        density: 'compact',
        showColumnFilters: true,
        columnVisibility: { ...hiddenColumns },
        // showGlobalFilter: true,
        expanded: true, // expand all groups by default
        grouping: ['requirementText'], // an array of columns to group by by default (can be multiple)
        pagination: { pageIndex: 0, pageSize: 20 },
        //   sorting: [{ id: "state", desc: false }], //sort by state by default
      },
      state: { rowSelection, editedUsers, showProgressBars: loading },
      muiToolbarAlertBannerChipProps: { color: 'primary' },
      muiLinearProgressProps: ({ isTopToolbar }) => ({
        color: 'secondary',
        sx: {
          display: isTopToolbar ? 'block' : 'none',
          height: 7,
        },
      }),
      renderTopToolbarCustomActions: ({ table }) => {
        return (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <ButtonMUI
              // color="secondary"
              startIcon={<SendIcon />}
              disabled={checkAssginStatus(table)}
              sx={{ backgroundColor: 'green' }}
              onClick={() => {
                const { rows } = table.getSelectedRowModel();
                // const { original } = rows;
                const filterdrow = rows.filter((Data) => {
                  return (
                    List_of_pbs.includes(Data.original.pbs) ||
                    Object.keys(editedUsers).includes(Data.id)
                  );
                });

                const temp = filterdrow.map((Data) => {
                  return Data.original;
                });

                handleAssingAllocation(editedUsers, temp);
              }}
              variant="contained"
            >
              Assign
            </ButtonMUI>
            <ButtonMUI
              color="error"
              startIcon={<DeleteIcon />}
              disabled={checkDeleteStatus(table)}
              onClick={() => {
                // alert('Delete Selected Accounts');
                const { rows } = table.getSelectedRowModel();
                // const { original } = rows;

                const temp = rows.map((Data) => {
                  return Data.original;
                });

                deleteAllocation(temp);
              }}
              variant="contained"
            >
              Delete
            </ButtonMUI>
            <ButtonMUI
              // color="secondary"
              startIcon={<SaveIcon />}
              disabled={Object.keys(table.getState()?.editedUsers)?.length === 0}
              sx={{ backgroundColor: 'green' }}
              onClick={() => {
                const rows = table.getSelectedRowModel();
                handleSaveAllocation(editedUsers);
                // AddAllocation();
              }}
              variant="contained"
            >
              Save
            </ButtonMUI>
            <ButtonMUI
              // color="secondary"
              endIcon={<FactCheckIcon />}
              disabled={checkAssginStatus(table)}
              sx={{ backgroundColor: 'green' }}
              onClick={() => {
                const { rows } = table.getSelectedRowModel();
                // const { original } = rows;

                const temp = rows.map((Data) => {
                  return Data.original;
                });

                const ids = rows.map((Data) => {
                  return {
                    parentId: Data.original.parentRow_id,
                    allocationId: Data.original.allocation_id,
                    owning_id: Data.original.owning_id,
                  };
                });

                onChangeModalData((prev) => {
                  return { ...prev, multiAllocator: { open: true, requirements: temp, keys: ids } };
                });

                // handleAssingAllocation(editedUsers, temp);
              }}
              variant="contained"
            >
              Multi-Alloc
            </ButtonMUI>
            {/* <GlobalFilterTextField table={table} /> */}
          </Box>
        );
      },

      renderRowActions: ({ row }) => {
        return (
          <>
            <Box style={{ display: row.original.addMoreAllocation ? 'flex' : 'none' }}>
              {/* <Checkbox onChange={ChangeInCheckBox} value={row.original.Id} /> */}
              <IconButton
                onClick={() => {
                  const { original } = row;
                  const { owning_id, row_id } = original;
                  AddAllocation(owning_id, row_id);
                }}
                color="info"
              >
                <AddCircleIcon />
              </IconButton>
            </Box>
          </>
        );
      },

      renderToolbarInternalActions: ({ table }) => {
        return (
          <>
            <Box style={{ display: 'flex' }}>
              {/* <GlobalFilterTextField table={table} /> */}
              <Toogle onChangeToggle={onChangeToggle} toggleTable={toggleTable} />
              <Refresh
                refreshDATA={(bol) => {
                  setEditedUsers({});
                  refreshDATA(bol);
                }}
              />
              {((selectedColumns?.includes('Hierarchy') && type === 'xlsx') || type === 'pdf') && (
                <FormControl
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={favouriteToggle}
                      onChange={(event) => {
                        setFavouriteToggle(event.target.checked);
                      }}
                    />
                  }
                  label="Hierarchy"
                />
              )}

              <ShowHideColumnsButton table={table} />

              {/* <ShowHideColumnsButton table={table} /> */}
              <ToggleDensePaddingButton table={table} />

              {/* <ToggleFullScreenButton table={table} /> */}
              <FullScreenToogle
                isFullScreen={isFullScreen}
                onChangeFullScreen={onChangeFullScreen}
              />
            </Box>
          </>
        );
      },

      icons: IconsForTable,
    }),
    [
      data,
      columns,
      rowSelection,
      editedUsers,
      isFullScreen,
      toggleTable,
      loading,
      favouriteToggle,
      selectedColumns,
      type,
    ]
  );

  // console.log('requirementObjcts', requirementObjcts);

  return requirementObjcts;
}

export default useRequirement;
