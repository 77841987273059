const similaritySearchFilterReducer = (
  state: {
    simi_AlstomEntitiesDef: any;
    simi_FilterProjectsDef: any;
    simi_FilterDocumentProfilesDef: any;
    simi_FilterPlatformsDef: any;
    simi_FilterSolutionDef: any;
    simi_FilterProductDef: any;
  },
  action: {
    type: any;
    payload: {
      alstomEntitiesDef: any;
      filterProjectsDef: any;
      filterDocumentProfilesDef: any;
      filterPlatformsDef: any;
      filterSolutionDef: any;
      filterProductDef: any;
      filterPlatforms: any;
      filterProjects: any;
      filterDocumentProfiles: any;
      alstomEntities: any;
      filterSolution: any;
      filterProduct: any;
    };
  }
) => {
  switch (action.type) {
    case 'set_simi_default_fill': {
      return {
        ...state,
        simi_AlstomEntitiesDef: action.payload.alstomEntitiesDef,
        simi_FilterProjectsDef: action.payload.filterProjectsDef,
        simi_FilterDocumentProfilesDef: action.payload.filterDocumentProfilesDef,
        simi_FilterPlatformsDef: action.payload.filterPlatformsDef,
        simi_FilterSolutionDef: action.payload.filterSolutionDef,
        simi_FilterProductDef: action.payload.filterProductDef,
        simi_AlstomEntities: action.payload.alstomEntitiesDef,
        simi_FilterProjects: action.payload.filterProjectsDef,
        simi_FilterDocumentProfiles: action.payload.filterDocumentProfilesDef,
        simi_FilterPlatforms: action.payload.filterPlatformsDef,
        simi_FilterSolution: action.payload.filterSolutionDef,
        simi_FilterProduct: action.payload.filterProductDef,
      };
    }
    case 'clear_simiFilters': {
      return {
        ...state,
        simi_AlstomEntities: state.simi_AlstomEntitiesDef,
        simi_FilterProjects: state.simi_FilterProjectsDef,
        simi_FilterDocumentProfiles: state.simi_FilterDocumentProfilesDef,
        simi_FilterPlatforms: state.simi_FilterPlatformsDef,
        simi_FilterSolution: state.simi_FilterSolutionDef,
        simi_FilterProduct: state.simi_FilterProductDef,
        dropDown1: [],
        dropDown2: [],
        dropDown3: [],
        dropDown4: [],
        dropDown5: [],
        dropDown6: [],
        countValue: 5,
        threshold: 69,
      };
    }

    case 'change_simi_entity': {
      return {
        ...state,
        dropDown1: action.payload,
      };
    }

    case 'change_simi_Platform': {
      return {
        ...state,
        dropDown2: action.payload,
      };
    }
    case 'change_simi_Project': {
      return {
        ...state,
        dropDown3: action.payload,
      };
    }
    case 'change_simi_DocumentProfile': {
      return {
        ...state,
        dropDown4: action.payload,
      };
    }

    case 'change_simi_Solution': {
      return {
        ...state,
        dropDown5: action.payload,
      };
    }

    case 'change_simi_Product': {
      return {
        ...state,
        dropDown6: action.payload,
      };
    }

    case 'simi_noChange_in_entity': {
      return {
        ...state,
        simi_FilterPlatforms: action.payload.filterPlatforms,
        simi_FilterProjects: action.payload.filterProjects,
        simi_FilterDocumentProfiles: action.payload.filterDocumentProfiles,
        simi_FilterSolution: action.payload.filterSolution,
        simi_FilterProduct: action.payload.filterProduct,
      };
    }

    case 'simi_noChange_in_platform': {
      return {
        ...state,
        simi_AlstomEntities: action.payload.alstomEntities,
        simi_FilterProjects: action.payload.filterProjects,
        simi_FilterDocumentProfiles: action.payload.filterDocumentProfiles,
        simi_FilterSolution: action.payload.filterSolution,
        simi_FilterProduct: action.payload.filterProduct,
      };
    }

    case 'simi_noChange_in_project': {
      return {
        ...state,
        simi_AlstomEntities: action.payload.alstomEntities,
        simi_filterPlatforms: action.payload.filterPlatforms,
        simi_FilterDocumentProfiles: action.payload.filterDocumentProfiles,
        simi_FilterSolution: action.payload.filterSolution,
        simi_FilterProduct: action.payload.filterProduct,
      };
    }

    case 'simi_noChange_in_documentProfile': {
      return {
        ...state,
        simi_AlstomEntities: action.payload.alstomEntities,
        simi_FilterPlatforms: action.payload.filterPlatforms,
        simi_FilterProjects: action.payload.filterProjects,
        simi_FilterSolution: action.payload.filterSolution,
        simi_FilterProduct: action.payload.filterProduct,
      };
    }

    case 'simi_noChange_in_solution': {
      return {
        ...state,
        simi_AlstomEntities: action.payload.alstomEntities,
        simi_FilterPlatforms: action.payload.filterPlatforms,
        simi_FilterProjects: action.payload.filterProjects,
        simi_FilterDocumentProfiles: action.payload.filterDocumentProfiles,
        simi_FilterProduct: action.payload.filterProduct,
      };
    }

    case 'simi_noChange_in_product': {
      return {
        ...state,
        simi_AlstomEntities: action.payload.alstomEntities,
        simi_FilterPlatforms: action.payload.filterPlatforms,
        simi_FilterProjects: action.payload.filterProjects,
        simi_FilterDocumentProfiles: action.payload.filterDocumentProfiles,
        simi_FilterSolution: action.payload.filterSolution,
      };
    }

    case 'simi_change_count': {
      return {
        ...state,
        countValue: action.payload,
      };
    }

    case 'simi_change_Threshold': {
      return {
        ...state,
        threshold: action.payload,
      };
    }
    default:
      return state;
  }
};

export default similaritySearchFilterReducer;
