/**
 * This component renders of a Context M2Global comp of the page
 * new api iSearcherCEPGetFilters changes done by @Ranjan for the Filter First time loaded
 * new object atrributes added
 * @param {string} props
 * @returns {ReactNode} A React element that renders M2Global component of the page.
 *  Copyright @ Alstom 2023
 *  auther @Ranjan
 */
import React, { useEffect, useReducer } from 'react';
import {
  getHistoryTranslation,
  iSearcherCEPGetFilters,
  iSearcherGetArtifactEntityFilters,
  iSearcherGetFilters,
  RexFilters,
  textToTextInitialFilter,
} from '../../api/module2';
import useAppToken from '../../app/useAppToken';
import { reducers, initialState } from '../Reducers/combineReducer';

export interface M2GlobalContextType {
  state: any;
  dispatch: any;
}

type Props = {
  children: any;
};

const M2GlobalContext = React.createContext<M2GlobalContextType>({
  state: {} as any,
  dispatch: {} as any,
});

const M2GlobalContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducers, initialState);
  const { appJWT } = useAppToken();

  useEffect(() => {
    // getHistoryTranslation(Number(appJWT?.user_id))
    //   .then((data) => {
    //     if (data.length > 0) setHistoryTranslation(data[0]?.translated_data);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
    if (sessionStorage.getItem('rex_def_filter')) {
      dispatch({
        type: 'set_default_filter',
        payload: JSON.parse(sessionStorage.getItem('rex_def_filter')),
      });
      // console.log();
    } else {
      RexFilters().then((payload: any) => {
        if (payload) {
          // console.log('payload', payload);
          const issuerS = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.sites) {
            const item = payload.filters.sites[i];
            issuerS.push({
              label: item,
              value: item,
            });
          }
          const plat = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.platforms) {
            const item = payload.filters.platforms[i];
            plat.push({
              label: item,
              value: item,
            });
          }
          const rexSource = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.sources) {
            const item = payload.filters.sources[i];
            rexSource.push({
              label: item,
              value: item,
            });
          }
          const statusFil = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.statuses) {
            const item = payload.filters.statuses[i];
            statusFil.push({
              label: item,
              value: item,
            });
          }
          const typologyREX = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.typologies) {
            const item = payload.filters.typologies[i];
            typologyREX.push({
              label: item,
              value: item,
            });
          }
          const productLine = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.productLines) {
            const item = payload.filters.productLines[i];
            productLine.push({
              label: item,
              value: item,
            });
          }
          const product = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.products) {
            const item = payload.filters.products[i];
            product.push({
              label: item,
              value: item,
            });
          }
          const solution = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.solutions) {
            const item = payload.filters.solutions[i];
            solution.push({
              label: item,
              value: item,
            });
          }
          const project = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.projects) {
            const item = payload.filters.projects[i];
            project.push({
              label: item,
              value: item,
            });
          }
          const country = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.countries) {
            const item = payload.filters.countries[i];
            country.push({
              label: item,
              value: item,
            });
          }
          const obj = {
            issureSiteDef: issuerS,
            rexPlatformDef: plat,
            rexSourceDef: rexSource,
            rexStatusDef: statusFil,
            rexTypologydef: typologyREX,
            rexProductLineDef: productLine,
            rexProductDef: product,
            rexSolutionDef: solution,
            rexProjectDef: project,
            rexCountryDef: country,
          };

          sessionStorage.setItem('rex_def_filter', JSON.stringify(obj));
          dispatch({ type: 'set_default_filter', payload: obj });
        }
      });
    }

    if (sessionStorage.getItem('iSearcher_def_filter')) {
      dispatch({
        type: 'set_default_fill',
        payload: JSON.parse(sessionStorage.getItem('iSearcher_def_filter')),
      });
    } else {
      iSearcherCEPGetFilters()
        .then((payload: any) => {
          if (payload) {
            const ent: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.productLines) {
              const item = payload.filters.productLines[i];
              ent.push({
                label: item,
                value: item,
              });
            }
            const plat: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.platforms) {
              const item = payload.filters.platforms[i];
              plat.push({
                label: item,
                value: item,
              });
            }
            const pro: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.projects) {
              const item = payload.filters.projects[i];
              pro.push({
                label: item,
                value: item,
              });
            }
            const docpro: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.documentProfiles) {
              const item = payload.filters.documentProfiles[i];
              docpro.push({
                label: item,
                value: item,
              });
            }
            const sol: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.solutions) {
              const item = payload.filters.solutions[i];
              sol.push({
                label: item,
                value: item,
              });
            }
            const prod: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.products) {
              const item = payload.filters.products[i];
              prod.push({
                label: item,
                value: item,
              });
            }
            const cont: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.countries) {
              const item = payload.filters.countries[i];
              cont.push({
                label: item,
                value: item,
              });
            }
            const obj = {
              productLineDef: ent,
              filterProjectsDef: pro,
              filterDocumentProfilesDef: docpro,
              filterPlatformsDef: plat,
              filterSolutionDef: sol,
              filterProductDef: prod,
              filterCountryDef: cont,
            };
            sessionStorage.setItem('iSearcher_def_filter', JSON.stringify(obj));
            dispatch({ type: 'set_default_fill', payload: obj });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
    if (sessionStorage.getItem('artifact_def_filter')) {
      dispatch({
        type: 'set_artifact_fill',
        payload: JSON.parse(sessionStorage.getItem('artifact_def_filter')),
      });
    } else {
      iSearcherGetArtifactEntityFilters()
        .then((payload: any) => {
          if (payload) {
            const ent: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload) {
              const item = payload[i];
              ent.push({
                label: item,
                value: item,
              });
            }
            sessionStorage.setItem('artifact_def_filter', JSON.stringify(ent));
            dispatch({ type: 'set_artifact_fill', payload: ent });
            // setArtiFact(ent);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }

    if (sessionStorage.getItem('simi_def_filter')) {
      dispatch({
        type: 'set_simi_default_fill',
        payload: JSON.parse(sessionStorage.getItem('simi_def_filter')),
      });
      // console.log();
    } else {
      textToTextInitialFilter()
        .then((payload: any) => {
          if (payload) {
            const ent = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.alstomEntities) {
              const item = payload.filters.alstomEntities[i];
              ent.push({
                label: item,
                value: item,
              });
            }
            const plat: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.platforms) {
              const item = payload.filters.platforms[i];
              plat.push({
                label: item,
                value: item,
              });
            }
            const sol: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.solutions) {
              const item = payload.filters.solutions[i];
              sol.push({
                label: item,
                value: item,
              });
            }
            const prod: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.products) {
              const item = payload.filters.products[i];
              prod.push({
                label: item,
                value: item,
              });
            }
            const pro: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.projects) {
              const item = payload.filters.projects[i];
              pro.push({
                label: item,
                value: item,
              });
            }
            const docpro: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.documentProfiles) {
              const item = payload.filters.documentProfiles[i];
              docpro.push({
                label: item,
                value: item,
              });
            }

            const obj = {
              alstomEntitiesDef: ent,
              filterProjectsDef: pro,
              filterSolutionDef: sol,
              filterProductDef: prod,
              filterDocumentProfilesDef: docpro,
              filterPlatformsDef: plat,
            };
            sessionStorage.setItem('simi_def_filter', JSON.stringify(obj));
            dispatch({ type: 'set_simi_default_fill', payload: obj });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
  }, []);
  return (
    <M2GlobalContext.Provider value={{ state, dispatch }}>{children}</M2GlobalContext.Provider>
  );
};

export default M2GlobalContext;

export { M2GlobalContextProvider };
