import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Similarizer from '../Similarizer';

export default function FindSimilarity({ open, onClose, rowsSim, targetColumn }) {
  const rows = React.useMemo(() => {
    return rowsSim.map((data) => {
      return data.extractedText;
    });
  }, [rowsSim, targetColumn]);

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" sx={{ padding: 0 }}>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="secondary">
            Cancel
          </Button>
        </DialogActions>
        <DialogContent sx={{ padding: 0, margin: 0 }}>
          <Similarizer inputSimList={rows} />
        </DialogContent>
      </Dialog>
    </>
  );
}
