import React from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AssignHistoryContainer from '../AssignHistoryContainer';

const AllocationHistory = ({ open, handleClose, project_id, workload_id, rows_allocation }) => {
  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl" sx={{ padding: 0 }}>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Cancel
          </Button>
        </DialogActions>
        <DialogContent sx={{ padding: 0, margin: 0 }}>
          <AssignHistoryContainer
            projectId={project_id}
            workloadId={workload_id}
            allocationId={rows_allocation}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AllocationHistory;
