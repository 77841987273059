import { Chip, Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getTimseries, KpiInput } from '../../api/analysis';
import { getChararcterizerFlowKpi } from '../../api/assign';

const COLORS = [
  '#1e3246',
  '#dc3223',
  '#19aa6e',
  '#4b5a69',
  '#788291',
  '#d2d7dc',
  '#eb827d',
  '#fad7d2',
  '#d2f0e1',
  '#73cdaa',
  '#9b875f',
  '#afa082',
  '#ebe6dc',
  '#c3b9a0',
  '#C3DDF6',
  '#468AD1',
  '#F8A600',
  '#2f475f',
  '#95372f',
  '#7a231b',
  '#94a2b8',
  '#5b8f75',
  '#546c60',
  '#36443d',
  '#748c80',
  '#4fa7ff',
  '#174471',
  '#384a5c',
  '#acacad',
  '#bdbdbd',
];

type Props = {
  project_id: number;
  user_id?: number;
  title: string;
  onClose?: any;
  workload_id?: number;
  file_name?: string;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (
  { cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }: any,
  full = false
) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const displayLabel = () => {
    if (full) return <>{percent !== 0 ? `${`${name} - ${(percent * 100).toFixed(0)}%`}` : ''}</>;
    return <>{percent !== 0 ? `${`${(percent * 100).toFixed(3)}%`}` : ''}</>;
  };
  return (
    <text
      transform={`translate(${x},${y}) rotate(320)`} // 330
      fill="black"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize="9px"
      fontStretch="collapsed"
      fontFamily="sans-serif, Helvetica Neue, Lucida Grande, Arial"
    >
      {/* {`${(percent * 100).toFixed(0)}%`} */}
      {/* to remove 0% from graph display */}
      {percent !== 0 ? `${`${name.substring(0, 5)} ${(percent * 100).toFixed(3)}%`}` : ''}
      {/* {percent !== 0 ? `${`${(percent * 100).toFixed(3)}%`}` : ''} */}
      {/* by David {displayLabel()} */}
    </text>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '20px',
    border: `1px solid`,
    borderColor: theme.palette.primary.main,
    opacity: 1,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

export default function ChararcterizerKpiContainer({
  project_id,
  workload_id,
  user_id,
  file_name,
  title,
  onClose,
}: Props) {
  const classes = useStyles();
  const [pieDataLifecycle, setPieDataLifecycle] = useState([] as any);
  const [barGraphStandardsChart, setBarGraphStandardsChart] = useState<any>([]);

  useEffect(() => {
    getChararcterizerFlowKpi(project_id, workload_id).then((resp) => {
      setBarGraphStandardsChart(resp?.graph_data);
      setPieDataLifecycle(resp?.graph_data_pie);
    });
  }, [project_id, workload_id]);

  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        spacing={0}
        className={classes.root}
      >
        <Grid item xs={8}>
          <Typography variant="subtitle1">Characterization status</Typography>
          {barGraphStandardsChart.length > 0 && (
            <Grid lg={12} sm={12} md={12}>
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <BarChart
                  width={800}
                  height={350}
                  data={barGraphStandardsChart}
                  margin={{
                    top: 35,
                    right: 2,
                    left: 15,
                    bottom: 8,
                  }}
                >
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {/* {barGraphStandards.map((entry, index) => (
                  ))} */}
                  <Bar dataKey="High" fill="rgb(120, 130, 145)" />
                  <Bar dataKey="Medium" fill=" rgb(25, 170, 110)" />
                  <Bar dataKey="Low" fill="rgb(0, 42, 69)" />
                </BarChart>
              </Box>
              <div style={{ textAlign: 'center', marginTop: 40, marginLeft: 600 }}>
                {pieDataLifecycle.map((d, index) => (
                  <Chip
                    label={`${d.name} (${d.value})`}
                    color="primary"
                    style={{
                      backgroundColor: d.name,
                      marginRight: '3px',
                      marginTop: '5px',
                    }}
                    key={`chip-${index}`}
                  />
                ))}
              </div>
            </Grid>
          )}
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={10}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PieChart width={400} height={400}>
                  <Pie
                    isAnimationActive={true}
                    data={pieDataLifecycle}
                    cx={210}
                    cy="center"
                    outerRadius={150}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    fill="#8884d8"
                    dataKey="value"
                    legendType="circle"
                    paddingAngle={1}
                    minAngle={5}
                  >
                    {pieDataLifecycle.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
