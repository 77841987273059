import { AppBar, Box, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GroupIcon from '@mui/icons-material/Group';
import { Category, GroupData, GroupTransfertItems, Model } from 'GroupType';
import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { getAllImportedBreakdown } from '../../api/breakdowns';
import { getAllCustomedGroups } from '../../api/projects';
import useProjectId from '../../app/useProjectId';
import Loading from '../../components/Core/Loading';
import { a11yProps } from '../../components/Datagrid/TStyles';
import TabStyle from '../../components/Layout/TabStyle';
import { IS_DEBUG_MODE } from '../../const';
import { BreakdownStructure } from '../../types/group.d';
import ProjectGroupsCustom from './components/breakdowns/BreakdownCustom';
import ProjectGroupsLeaders from './components/breakdowns/Leaders';
import ProjectGroupsContext from './PorjectGroupsContext';
import ProjectContext from './ProjectContext';

export const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, padding: theme.spacing(2) },
  content: {
    width: '100%',
    // border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: '0px 16px 32px #00000029',
    '&[aria-selected="true"][aria-expanded="true"]>div:nth-of-type(1)': {
      backgroundColor: 'red',
    },
  },
  bloc: {
    minHeight: 250,
    borderRadius: 10,
    border: 'solid 1px;',
    borderColor: theme.palette.primary.light,
    margin: 10,
  },
  error: {
    color: theme.palette.error.main,
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
  },
  treeitem: {
    width: '95%',
    marginTop: '2rem',
  },
  treeLabel: {
    borderRadius: '13px',
    paddingLeft: 10,
    backgroundColor: theme.palette.primary.light,
    color: 'white',
  },
  box: {
    padding: '0px 0px 40px 0px',
    borderRadius: '34px',
    border: `1px solid`,
    borderTopColor: 'transparent',
    borderColor: theme.palette.primary.main,
    backgroundColor: 'white',
    margin: theme.spacing(1),
    opacity: 1,
  },
}));

export const convertModelToItem = (modelList: Model[]): BreakdownStructure[] => {
  if (!modelList) return [];
  return modelList
    .map((el: Model) => ({ label: el.label, value: el.slug, project_id: el.project_id }))
    .sort((a, b) => (a.label < b.label ? -1 : 1));
};

const ProjectGroupsContainer = () => {
  const classes = useStyles();
  const classesTab = TabStyle();
  const { projectId } = useParams() as any;
  const { projects } = useContext(ProjectContext);
  const { project } = useProjectId(projectId, projects);
  const [tab, setTab] = useState<any>(0); // 0 = nc
  const [datatype, setDatatype] = useState<'leaders' | Category>('leaders');
  const [customGroup, setCustomGroup] = useState<GroupData>(undefined);
  const [loading, setLoading] = useState(true);

  // default list uploaded by user
  const [allDefaultGroups, setAllDefaultGroups] = useState<GroupTransfertItems>({
    RBS: [],
    SBS: [],
    PBS: [],
    ABS: [],
    OBS: [],
  });
  // lists customed by user
  const [RBSCustom, setRBSCustom] = useState<GroupData>({
    name: `RBS-${projectId}`,
    project_id: projectId,
    category: 'RBS',
    label_ids: [],
  } as GroupData);
  const [SBSCustom, setSBSCustom] = useState<GroupData>({
    name: `SBS-${projectId}`,
    project_id: projectId,
    category: 'SBS',
    label_ids: [],
  } as GroupData);
  const [PBSCustom, setPBSCustom] = useState<GroupData>({
    name: `PBS-${projectId}`,
    project_id: projectId,
    category: 'PBS',
    label_ids: [],
  } as GroupData);
  const [ABSCustom, setABSCustom] = useState<GroupData>({
    name: `ABS-${projectId}`,
    project_id: projectId,
    category: 'ABS',
    label_ids: [],
  });
  const [OBSCustom, setOBSCustom] = useState<GroupData>({
    name: `OBS-${projectId}`,
    project_id: projectId,
    category: 'OBS',
    label_ids: [],
  });

  useEffect(() => {
    const filObj = projects.filter((data) => {
      return data.id === Number(projectId);
    });
    const dis_nonpm = filObj[0]?.modelpm === 'dis_nonpm2020' ? 'dis_nonpm2020' : undefined;
    getAllImportedBreakdown(projectId, dis_nonpm).then((payload) => {
      // data for left list in TransferList
      setAllDefaultGroups({
        RBS: convertModelToItem(payload.RBS),
        SBS: convertModelToItem(payload.SBS),
        PBS: convertModelToItem(payload.PBS),
        ABS: convertModelToItem(payload.ABS),
        OBS: convertModelToItem(payload.OBS),
      });
      // all customed groups
      getAllCustomedGroups(projectId)
        .then((allGroupCustom) => {
          if ('RBS' in allGroupCustom) setRBSCustom({ ...PBSCustom, ...allGroupCustom.RBS });
          if ('SBS' in allGroupCustom) setSBSCustom({ ...SBSCustom, ...allGroupCustom.SBS });
          if ('PBS' in allGroupCustom) setPBSCustom({ ...RBSCustom, ...allGroupCustom.PBS });
          if ('ABS' in allGroupCustom) setABSCustom({ ...PBSCustom, ...allGroupCustom.ABS });
          if ('OBS' in allGroupCustom) setOBSCustom({ ...OBSCustom, ...allGroupCustom.OBS });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [projectId]);

  const handleTabChange = async (event, newTabValue) => {
    setLoading(true);
    const filObj = projects.filter((data) => {
      return data.id === Number(projectId);
    });
    const dis_nonpm = filObj[0]?.modelpm === 'dis_nonpm2020' ? 'dis_nonpm2020' : undefined;
    getAllImportedBreakdown(projectId, dis_nonpm).then((payload) => {
      // data for left list in TransferList
      setAllDefaultGroups({
        RBS: convertModelToItem(payload.RBS),
        SBS: convertModelToItem(payload.SBS),
        PBS: convertModelToItem(payload.PBS),
        ABS: convertModelToItem(payload.ABS),
        OBS: convertModelToItem(payload.OBS),
      });
      // all customed groups
      getAllCustomedGroups(projectId)
        .then((allGroupCustom) => {
          if ('RBS' in allGroupCustom) setRBSCustom({ ...PBSCustom, ...allGroupCustom.RBS });
          if ('SBS' in allGroupCustom) setSBSCustom({ ...SBSCustom, ...allGroupCustom.SBS });
          if ('PBS' in allGroupCustom) setPBSCustom({ ...RBSCustom, ...allGroupCustom.PBS });
          if ('ABS' in allGroupCustom) setABSCustom({ ...PBSCustom, ...allGroupCustom.ABS });
          if ('OBS' in allGroupCustom) setOBSCustom({ ...OBSCustom, ...allGroupCustom.OBS });
        })
        .finally(() => {
          setLoading(false);
        });
    });
    setTab(newTabValue);
    switch (newTabValue) {
      default:
      case 0:
        setDatatype('leaders');
        break;
      case 1:
        if (project?.entity === 'RSC') {
          // setDatatype('RBS');
          // setCustomGroup(RBSCustom);
          setDatatype('SBS');
          setCustomGroup(SBSCustom);
        } else {
          setDatatype('PBS');
          setCustomGroup(PBSCustom);
        }
        break;
      case 2:
        setDatatype('ABS');
        setCustomGroup(ABSCustom);
        break;
      case 3:
        setDatatype('OBS');
        setCustomGroup(OBSCustom);
        break;
    }
  };

  if (!project || loading) return <Loading />;

  return (
    <div className={classes.root}>
      {IS_DEBUG_MODE && (
        <ReactJson
          src={{
            datatype,
            allDefaultGroups,
            RBSCustom,
            SBSCustom,
            PBSCustom,
            ABSCustom,
            OBSCustom,
          }}
          collapsed={true}
          theme="monokai"
        />
      )}
      <ProjectGroupsContext.Provider
        value={{
          allDefaultGroups,
          datatype,
          RBSCustom,
          SBSCustom,
          PBSCustom,
          ABSCustom,
          OBSCustom,
          setRBSCustom,
          setSBSCustom,
          setPBSCustom,
          setABSCustom,
          setOBSCustom,
        }}
      >
        <Grid container>
          {/* <Grid item xs={12}>
            <ProjectBreadCrumbs projectId={projectId} />
          </Grid> */}
          <Grid item xs={12} className={classes.content}>
            <Box className={classes.box}>
              <AppBar position="static" className={classesTab.appStyle}>
                <Tabs
                  className={classesTab.root}
                  value={tab}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="RBS/ABS/OBS"
                  TabIndicatorProps={{ style: { display: 'none' } }}
                >
                  <Tab
                    label={
                      <>
                        <Typography
                          variant="subtitle2"
                          title="leaders"
                          className={classesTab.tabLebelText}
                        >
                          <span>
                            <GroupIcon className={classesTab.iconLabelWrapper} />
                          </span>
                          Project leaders
                        </Typography>
                      </>
                    }
                    {...a11yProps(0)}
                    className={tab === 0 ? classesTab.tabstyle : ''}
                  />
                  <Tab
                    label={
                      <>
                        <Typography
                          variant="subtitle2"
                          title="SBS"
                          className={classesTab.tabLebelText}
                        >
                          {project?.entity === 'RSC' ? 'SBS' : 'PBS'}
                        </Typography>
                      </>
                    }
                    {...a11yProps(1)}
                    className={tab === 1 ? classesTab.tabstyle : ''}
                  />
                  {project?.entity === 'RSC' ? (
                    <Tab {...a11yProps(2)} style={{ display: 'none' }} />
                  ) : (
                    <Tab
                      label={
                        <>
                          <Typography
                            variant="subtitle2"
                            title="ABS"
                            className={classesTab.tabLebelText}
                          >
                            ABS
                          </Typography>
                        </>
                      }
                      {...a11yProps(2)}
                      className={tab === 2 ? classesTab.tabstyle : ''}
                    />
                  )}
                  <Tab
                    label={
                      <>
                        <Typography
                          variant="subtitle2"
                          title="OBS"
                          className={classesTab.tabLebelText}
                        >
                          OBS
                        </Typography>
                      </>
                    }
                    {...a11yProps(3)}
                    className={tab === 3 ? classesTab.tabstyle : ''}
                  />
                </Tabs>
              </AppBar>
              <ProjectGroupsContext.Consumer>
                {() => {
                  return (
                    <>
                      {tab === 0 && <ProjectGroupsLeaders />}
                      {tab >= 1 && <ProjectGroupsCustom group={customGroup} />}
                    </>
                  );
                }}
              </ProjectGroupsContext.Consumer>
            </Box>
          </Grid>
        </Grid>
      </ProjectGroupsContext.Provider>
    </div>
  );
};

export default ProjectGroupsContainer;
