/* eslint-disable no-loop-func */
import React, { memo, useCallback, useContext, useRef, useState } from 'react';
import {
  UserWorkloadCreate,
  userWorkloadRunPostForTranslator,
  workloadCreate,
  workloadRunPostForTranslator,
} from '../../../api/workloads';
import useAppToken from '../../../app/useAppToken';
import ProjectUploadFile, { ProjectUploadGlossaryFile } from '../API/ProjectUploadFile';
import UserUploadFile, { UserUploadGlossaryFile } from '../API/UserUploadFile';
import FileTranslatorContext from '../FileTranslatorContext';
import TabContainer from './TabContainer';

const ProjectTableContainer = (props) => {
  const { appJWT } = useAppToken();
  const [workLoads, setWorkloads] = useState([]);
  const { user_id } = appJWT;
  const {
    setSnack,
    files,
    setFiles,
    mode,
    glossaryFiles,
    languageCode,
    fileSelection,
    setGlossaryFiles,
    state,
    dispatch,
  } = useContext(FileTranslatorContext);
  const [transformLine, setTransformLine] = useState('0rem');
  const inputRefFile = useRef();
  const inputGrossary = useRef();
  const { selectedFileData, GlossaryFromLang, GlossaryToLang, FromLang, ToLang } = state || [];

  // console.log(state);
  const handleChangeFiles = async (event: any) => {
    const result = await ProjectUploadFile(event, files, mode.id, mode.project?.folders[0]?.id);

    // console.log(result);

    if (result.status === 'error') {
      setSnack(result.message, 'error');
    } else if (result.status === 'success') {
      setFiles((prev: any) => {
        return [...result.data, ...prev];
      });
      setSnack(result.message, 'success');
    }
  };

  const draghandleChangeFiles = (event: any) => {
    const arr = [];
    arr.push(event);
    handleChangeFiles(arr);
  };

  const handleChangeGlossaryFiles = async (event: any) => {
    const result = await ProjectUploadGlossaryFile(
      event,
      glossaryFiles,
      user_id,
      mode.id,
      GlossaryFromLang,
      GlossaryToLang
    );

    // console.log('result', result);
    if (result.status === 'error') {
      setSnack(result.message, 'error');
    } else if (result.status === 'success') {
      setGlossaryFiles(result.data);
      setSnack(result.message, 'success');
    }
  };

  const draghandleChangGlossaryeFiles = (event: any) => {
    if (!GlossaryFromLang) {
      setSnack(`please select from Language`, 'error');
      return;
    }

    if (!GlossaryToLang) {
      setSnack(`please select To Language`, 'error');
      return;
    }
    const arr = [];
    arr.push(event);
    handleChangeGlossaryFiles(arr);
  };

  const UploadFile = () => {
    inputRefFile.current.click();
    // if (project && project.folders && !isEmpty(project.folders)) {
    //   inputRef.current.click();
    // } else {
    //   setSnack('Please Create folder', 'error');
    // }
  };

  const UploadFileGlossary = () => {
    if (!GlossaryFromLang) {
      setSnack(`please select from Language`, 'error');
      return;
    }

    if (!GlossaryToLang) {
      setSnack(`please select To Language`, 'error');
      return;
    }
    inputGrossary.current.click();
    // if (project && project.folders && !isEmpty(project.folders)) {
    //   inputRef.current.click();
    // } else {
    //   setSnack('Please Create folder', 'error');
    // }
  };

  const filehandleChangeFiles = (event: any) => {
    const e = event?.target?.files || '';
    handleChangeFiles(e);
  };

  const filehandleChangeGlossaryFiles = (event: any) => {
    const e = event?.target?.files || '';
    handleChangeGlossaryFiles(e);
  };

  // console.log(state);

  const TranslateFile = () => {
    // if (!state.FromLang) {
    //   setSnack('Please select From language', 'error');
    //   return;
    // }

    // if (!state.ToLang) {
    //   setSnack('Please select To language', 'error');
    //   return;
    // }
    // if (fileSelection.length === 0) {
    //   setSnack('Please select any file', 'error');
    //   return;
    // }
    const selectedRows = selectedFileData.map((data, index) => {
      const { document, glossaryFile } = data || null;

      if (glossaryFile) {
        return {
          document: {
            FileName: document.filename,
            Container: document.container,
            size: document.size,
          },
          glossaryFile: {
            FileName: glossaryFile.filename,
            Container: glossaryFile.container,
            size: glossaryFile.size,
          },
          fromLanguage: state.FromLang,
          toLanguage: state.ToLang,
        };
      }

      const obj = {
        document: {
          FileName: document.filename,
          Container: document.container,
          size: document.size,
        },
        fromLanguage: state.FromLang,
        toLanguage: state.ToLang,
      };

      return obj;
    });
    // console.log('selected Payload', selectedRows);
    const promisses = [];
    const res = [] as any;
    for (const eachpayload of selectedRows) {
      const obj = {
        ...eachpayload,
      };
      res.push(obj);
      const workloadCreatePayload = {
        ...eachpayload.document,
        toLanguage: eachpayload.toLanguage,
      };
      // console.log('selected create workload', workloadCreatePayload);
      promisses.push(() =>
        workloadCreate({
          project_id: mode.id,
          app: 'module2',
          action: 'Translator',
          resources: [workloadCreatePayload],
        })
      );
    }
    // console.log('res', res);
    Promise.all(promisses.map((fn) => fn()))
      .then((result) => {
        const wl = result.map((data) => {
          return data.id;
        });
        setWorkloads(wl);
        const translatorCALL = result.map((data, index) => {
          return () => {
            workloadRunPostForTranslator(Number(data.id), mode.id, state.ToLang, [res[index]]);
          };
        });
        Promise.all(translatorCALL.map((fn) => fn()))
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .then((payload) => {
            // setShowProcessFile(true);
            // setToggleTab((prev) => {
            //   return {
            //     ...prev,
            //     left: '5rem',
            //   };
            // });
            // setRunLoad(true);
            setTransformLine('6rem');
          })
          .catch(() => {
            setSnack('Error while workload post.', 'error');
          })
          .finally(() => {});
      })
      .catch(() => {
        setSnack('Error while workload create.', 'error');
      })
      .finally(() => {});
  };

  const OnChangeFromLangugae = useCallback((lang, str) => {
    dispatch({ type: str, payload: lang });
  }, []);

  const OnChangeToLangugae = useCallback((lang, str) => {
    dispatch({ type: str, payload: lang });
  }, []);

  return (
    <>
      <input type="file" multiple ref={inputRefFile} hidden onChange={filehandleChangeFiles} />
      <input
        type="file"
        multiple
        ref={inputGrossary}
        hidden
        onChange={filehandleChangeGlossaryFiles}
      />

      <TabContainer
        draghandleChangeFiles={draghandleChangeFiles}
        draghandleChangGlossaryeFiles={draghandleChangGlossaryeFiles}
        workLoads={workLoads}
        OnChangeFromLangugae={OnChangeFromLangugae}
        OnChangeToLangugae={OnChangeToLangugae}
        selectedFileData={selectedFileData}
        languageCode={languageCode}
        fileSelection={fileSelection}
        TranslateFile={TranslateFile}
        UploadFile={UploadFile}
        GlossaryFromLang={GlossaryFromLang}
        GlossaryToLang={GlossaryToLang}
        FromLang={FromLang}
        ToLang={ToLang}
        setSnack={setSnack}
        UploadFileGlossary={UploadFileGlossary}
        filehandleChangeFiles={filehandleChangeFiles}
        filehandleChangeGlossaryFiles={filehandleChangeGlossaryFiles}
        transformLine={transformLine}
        setTransformLine={setTransformLine}
        mode={mode}
      />
    </>
  );
};
export default ProjectTableContainer;

// const UserWorkSpaceEnhancedComponent = withUserWorkSpaceTableContainer(<TabContainer />);

// export default UserWorkSpaceEnhancedComponent;
