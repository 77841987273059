import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactJson from 'react-json-view';
import { saveTemplate } from '../../../api/iverifier';
import useAppToken from '../../../app/useAppToken';
import { IS_DEBUG_MODE } from '../../../const';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    backgroundColor: theme.palette.action.hover,
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
  textField: {
    margin: '15px 5px',
    paddingRight: 10,
  },
  radio: {
    textAlign: 'center',
  },
  label: {
    marginRight: '30px',
  },
}));

type Props = {
  rows?: any[];
  onClose?: any;
};

export default function ModalTemplate({ rows, onClose }: Props) {
  const classes = useStyles();
  // bottom buttons
  const [saving, setSaving] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  // handle main form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {},
  });

  const handleClose = () => {
    onClose();
  };

  const handleSaveTemplate = (data) => {
    if (String(data.template_version).trim().length === 0 || String(name).trim().length === 0)
      return;
    setSaving(true);
    saveTemplate({
      version: data.template_version,
      name,
      is_published: false,
    }).then((response) => {
      onClose(response);
      setSaving(false);
    });
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="sm"
        fullWidth={true}
      >
        {IS_DEBUG_MODE && (
          <ReactJson
            src={{ rows, name, versions: rows?.filter((r) => r.name === name) }}
            collapsed={true}
            theme="monokai"
          />
        )}
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          Create a template
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={3}
            style={{ margin: 0 }}
          >
            <Grid item lg={12} sm={12} md={12}>
              <>
                <Autocomplete
                  freeSolo
                  id="combo-box-template-name"
                  // remove duplications
                  options={rows.filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i)}
                  getOptionLabel={(option) => option.name}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // {...register('template_name')}
                      label="Template name"
                      placeholder="Template name"
                      margin="normal"
                      variant="outlined"
                      onChange={(e) => setName(e.currentTarget.value)}
                      onBlur={(e) => setName(e.currentTarget.value)}
                    />
                  )}
                />

                <Autocomplete
                  freeSolo
                  id="combo-box-template-version"
                  // filter based on template name
                  options={rows?.filter((r) => r.name === name)}
                  getOptionLabel={(option) => option.version}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...register('template_version')}
                      label="Version"
                      placeholder="Version"
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />
              </>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogContent}>
          {saving && (
            <span>
              <CircularProgress size={26} color="secondary" />
            </span>
          )}
          <Button
            variant="contained"
            color="secondary"
            disabled={saving}
            onClick={handleSubmit(handleSaveTemplate)}
          >
            Create template
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
