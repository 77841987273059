import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { fileDetails } from '../../../../api/module1';
import Loading from '../../../../components/Core/Loading';
import { IS_DEBUG_MODE } from '../../../../const';
import Run1Context, { Resource } from '../../RunContext';
import FileSettings from './FileSettings';
import FileSettingsCsv from './FileSettingsCsv';
import FileSettingsExcel from './FileSettingsExcel';
import FileSettingsDoc from './FileSettingsDoc';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
      '& .MuiTreeItem-iconContainer svg': {
        fontSize: '18px',
        position: 'relative',
        left: '-20px',
      },
      // border: '2px solid #002A45',
      borderRadius: '5px',
      // padding: '43px 40px 20px 51px',
      // boxShadow: '0px 16px 32px #00000029',
      // '&[aria-selected="true"][aria-expanded="true"]>div:nth-of-type(1)': {
      //   backgroundColor: 'red',
      // },
      '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
        background: 'none !important',
        // backGroundColor: '#fafafa',
      },
    },
    ArowupStyle: {
      height: '24px',
      width: '24px',
      marginRight: '15px',
    },
    ArowdownStyle: {
      height: '24px',
      width: '24px',
      marginRight: '15px',
    },
    buttonContainer: {
      alignItems: 'flex-end',
      '& > *': {
        margin: theme.spacing(3),
      },
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    control: {
      padding: theme.spacing(2),
    },
    paper: {
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '95%',
    },
    formControl1: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    paper1: {
      color: theme.palette.text.secondary,
      width: '100%',
    },
    treeitem: {
      width: '100%',
    },
    treeLabel: {
      paddingLeft: 10,
      color: '#002A45',
      font: 'normal normal bold 23px/23px Alstom',
      letterSpacing: '0px',
      padding: '0 0 10px 0',
      borderBottom: '1px solid #002A45',
      '&:hover': {
        background: 'none',
      },
      // '&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      //   backgroundColor: '#F4F5F9',
      // },
    },
    treeLabelNotExtractable: {
      borderRadius: '13px',
      paddingLeft: 10,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
    },
    // label: {
    //   backgroundColor: 'white',
    //   borderRadius: '13px',
    // },
    label: {
      '&hover': {
        backgroundColor: 'none',
      },
      '&.MuiTreeItem-label': {
        width: '100%',
        position: 'relative',
        paddingLeft: '0px',
      },
    },
    content: {
      flexDirection: 'row-reverse',
    },
    group: {
      margin: 0,
      padding: 0,
      marginLeft: '0px',
      marginRight: '17px',
    },
    treeIcon: {
      width: '40px',
      height: '40px',
      transform: 'translateY(8px)',
      marginRight: '10px',
    },
    radiobtn: {
      textAlign: 'left',
    },
  })
);

const settingsDefault = {
  typesOfCapture: 'Paragraph_Capture',
  smartTableOfContent: 'true',
  smartHeaderFooter: 'true',
};

function StepSettings() {
  const classes = useStyles();
  const runContext = useContext(Run1Context);
  const { resources, setResources } = runContext;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    // get file scope
    // because of Text in scope we need to fetch scopesettings for all files
    Promise.all(
      resources.map((res: Resource) => fileDetails(res.filename, res.filename, res.container || ''))
    )
      .then((payload2) => {
        // console.log(payload2);
        // merge resources with url + new resources with data from payload
        setResources(
          resources.map((res, iRes) => {
            return {
              ...res,
              scope: payload2[iRes].output.Text !== null ? payload2[iRes].output : undefined,
              scopeError: payload2[iRes].error,
              scopeMessage: payload2[iRes].message,
              // previous settings or defaulft
              settings: res.settings || settingsDefault,
            };
          })
        );
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setError('Error getFile Scope');
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSettings = (filename: string, newSettings: any, overwrite = false) => {
    const resourceIndex = resources.findIndex((res) => res.filename === filename);
    resources[resourceIndex].results = undefined;
    if (overwrite) resources[resourceIndex].settings = newSettings;
    else
      resources[resourceIndex].settings = {
        ...resources[resourceIndex].settings,
        ...newSettings,
      };
    setResources(resources);
  };

  if (error !== '')
    return (
      <Typography variant="body1" style={{ color: 'red' }}>
        {error}
      </Typography>
    );
  if (loading) return <Loading />;

  return (
    <>
      <Grid item xs={12}>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon className={classes.ArowdownStyle} />}
          defaultExpandIcon={<KeyboardArrowUpOutlinedIcon className={classes.ArowupStyle} />}
          defaultExpanded={['treeitem-0']} // expand first element
        >
          {resources
            .filter((res: Resource) => {
              // remove empty scope and error
              return res.scope !== undefined && res.scopeMessage === 'Extractable';
            })
            .map((res: Resource, index: number) => (
              <TreeItem
                key={`treeitem-${index}`}
                nodeId={`treeitem-${index}`}
                label={
                  <>
                    <Typography variant="subtitle1" className={classes.treeLabel}>
                      {res.scope.FileType === 'PDF' ? (
                        <span>
                          <PictureAsPdfIcon className={classes.treeIcon} />
                        </span>
                      ) : (
                        <span>
                          <FileCopyOutlinedIcon className={classes.treeIcon} />
                        </span>
                      )}
                      {res.filename}
                    </Typography>
                    <span style={{ float: 'right', fontSize: '14px', color: 'red' }}>
                      {res.scopeError && `Error:${res.scopeError}`}
                      {/* Message: {res.scopeMessage} */}
                    </span>
                  </>
                }
                className={classes.treeitem}
                classes={{
                  label: classes.label,
                  selected: classes.label,
                  content: classes.content,
                  group: classes.group,
                }}
              >
                {/* {IS_DEBUG_MODE && <ReactJson src={{ res }} collapsed={true} theme="monokai" />} */}
                {res.scope?.FileType.toLowerCase() === 'pdf' ? (
                  <FileSettings onChange={handleChangeSettings} resource={res} />
                ) : (
                  ''
                )}
                {res.scope?.FileType.toLowerCase() === 'csv' ? (
                  <FileSettingsCsv onChange={handleChangeSettings} resource={res} />
                ) : (
                  ''
                )}
                {res.scope?.FileType.toLowerCase() === 'xlsx' ||
                res.scope?.FileType.toLowerCase() === 'xls' ||
                res.scope?.FileType.toLowerCase() === 'excel' ? (
                  <FileSettingsExcel onChange={handleChangeSettings} resource={res} />
                ) : (
                  ''
                )}
                {res.scope?.FileType.toLowerCase() === 'doc' ||
                res.scope?.FileType.toLowerCase() === 'word' ||
                res.scope?.FileType.toLowerCase() === 'docx' ||
                res.scope?.FileType.toLowerCase() === 'docm' ? (
                  <FileSettingsDoc onChange={handleChangeSettings} resource={res} />
                ) : (
                  ''
                )}
              </TreeItem>
            ))}
        </TreeView>

        {/* error on  scope settings */}
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {resources
            .filter((res: Resource) => {
              return (
                (res.scope === undefined && res.scopeError !== null) ||
                res.scopeMessage !== 'Extractable'
              );
            })
            .map((res: Resource, index: number) => (
              <TreeItem
                key={`treeitem-${index}`}
                nodeId={`treeitem-${index}`}
                label={
                  <Typography variant="subtitle1" className={classes.treeLabelNotExtractable}>
                    {res.filename} (*) <br />
                    <span style={{ float: 'right', fontSize: '14px', color: 'red' }}>
                      Error: {res.scopeError}
                      {/* Message: {res.scopeMessage} */}
                    </span>
                  </Typography>
                }
                className={classes.treeitem}
                classes={{ label: classes.label, selected: classes.label }}
              />
            ))}
          {resources.filter((res: Resource) => {
            return res.scope === undefined;
          }).length > 0 && (
            <Grid item xs={12}>
              <Typography variant="body1">Not extractable (*)</Typography>
            </Grid>
          )}
        </TreeView>
      </Grid>
    </>
  );
}

export default StepSettings;
