import React, { useContext, useMemo } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import useProjectId from '../../app/useProjectId';
import ProjectContext from '../Project/ProjectContext';
import Module6 from './Module6';
import Module6Context, { Module6ContextProvider } from './Module6Context';

const Module6Router = React.memo(() => {
  const { projectId, moduleroute, workloadId } = useParams() as any;
  const { projects } = useContext(ProjectContext);
  const { project } = useProjectId(projectId, projects);

  if (project === undefined) return null;
  return (
    <Module6ContextProvider project={project}>
      <Module6Context.Consumer>
        {() => {
          return (
            <Switch>
              <Route
                path="/projects/:projectId/module6/:moduleroute/new"
                render={() => {
                  return (
                    <>
                      <Redirect to={`/projects/${projectId}/module6/:moduleroute/run`} />
                    </>
                  );
                }}
              />
              <Route
                path="/projects/:projectId/module6/:moduleroute/results/:workloadId"
                render={() => {
                  // return <Module2 />;
                  return <Module6 />;
                }}
              />
              <Route
                path="/projects/:projectId/module6/:moduleroute"
                render={() => {
                  return (
                    <>
                      <Module6 />
                    </>
                  );
                }}
              />
              <Route
                path="/projects/:projectId/module6/:moduleroute/run"
                render={() => {
                  return (
                    <>
                      <Module6 />
                    </>
                  );
                }}
              />
            </Switch>
          );
        }}
      </Module6Context.Consumer>
    </Module6ContextProvider>
  );
});

export default Module6Router;
