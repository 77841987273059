import * as msal from '@azure/msal-browser';
import { BACKEND_URL, OAUTH_AUTHORITY, OAUTH_CLIENT_ID, OAUTH_REDIRECT_URI } from '../const';
import axios from './axios';

const msalConfig = {
  auth: {
    clientId: OAUTH_CLIENT_ID,
    authority: OAUTH_AUTHORITY,
    redirectUri: OAUTH_REDIRECT_URI,
  },
};

export const scopes = ['User.Read'];

const msalInstance = new msal.PublicClientApplication(msalConfig as any);

export const isValidToken = async () => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/public/token/valid`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    }
    throw new Error(error);
  }
};
export default msalInstance;
