import { AlertProps } from '@material-ui/lab';
import React, { useState } from 'react';
import isEqual from 'react-fast-compare';
import AlertSnack from '../../components/Alert/AlertSnack';

export interface Module5ContextType {
  project: Project;
  setProject: (d: Project) => any;
  // setSnackMsg: any;
  // setSnackType: any;
  setSnackMsg: (msg: string) => any;
  setSnackSeverity: (severity: AlertProps['severity']) => any;
  setSnack: (msg: string, severity?: AlertProps['severity']) => any;
}

const Module5Context = React.createContext<Module5ContextType>({
  project: {} as any,
  setProject: (): any => {},
  setSnackMsg: (): any => {},
  setSnackSeverity: (): any => {},
  // combi msg + severity
  setSnack: (): any => {},
});

type Props = {
  project: Project;
  children: any;
};

const Module5ContextProvider = ({ project, children }: Props): any => {
  const [_project, _setProject] = useState(project);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackSeverity, setSnackSeverity] = useState<AlertProps['severity']>('success');

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackMsg('');
  };
  return (
    <Module5Context.Provider
      value={{
        project: _project,
        setProject: (_input) => {
          if (!isEqual(project, _input)) _setProject(_input);
        },
        // setSnackMsg,
        // setSnackType,
        setSnackMsg,
        setSnackSeverity,
        setSnack: (msg: string, severity = 'success' as AlertProps['severity']) => {
          setSnackMsg(msg);
          setSnackSeverity(severity);
        },
      }}
    >
      {snackMsg !== '' && (
        <AlertSnack
          msg={snackMsg}
          severity={snackSeverity}
          handleClose={handleClose}
          duration={3000}
        />
      )}
      {children}
    </Module5Context.Provider>
  );
};

export default Module5Context;

export { Module5ContextProvider };
