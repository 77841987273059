import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  Button,
  Box,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import MultiSelect from 'react-multi-select-component';
import { makeStyles } from '@material-ui/core/styles';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { height } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  formControlMulti1: {
    // margin: theme.spacing(1),
    minWidth: 220,
    maxWidth: 220,
  },
  customDatePicker: {
    '& .MuiInputLabel-root': {
      color: '#002A45 !important', // Custom label color
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#002A45 !important', // Custom error label color
      height: '40px',
    },
    '& .MuiOutlinedInput-root': {
      color: '#002A45 !important', // Custom text color
      borderColor: '#002A45 !important', // Custom border color
      height: '40px',
      '& .Mui-focused fieldset': {
        borderColor: '#002A45 !important', // Custom border color on focus
        height: '40px',
      },
      '&:hover fieldset': {
        borderColor: '#002A45 !important', // Custom border color on hover
        height: '40px',
      },
      '&.Mui-error fieldset': {
        borderColor: '#002A45 !important', // Custom border color on error
        height: '40px',
      },
    },
  },
}));

const today = dayjs();
const yesterday = dayjs().add(1, 'day');

const WorkloadFilter = ({ getWorkLoadAll, filterValues, setFilterValues, setAnchorEl }) => {
  const classes = useStyles();
  const handleActivityIdChange = (event) => {
    setFilterValues(() => ({ ...filterValues, activityId: event.target.value }));
  };
  const handleModulesChange = (selectedModules) => {
    setFilterValues(() => ({ ...filterValues, modules: selectedModules }));
  };
  const handleActionChange = (selectedActions) => {
    setFilterValues(() => ({ ...filterValues, action: selectedActions }));
  };
  const handleFromDateChange = (date: Dayjs | null) => {
    const dateFrom = date?.format('YYYY-MM-DD');
    setFilterValues(() => ({ ...filterValues, fromDate: dateFrom }));
  };

  const handleToDateChange = (date: Dayjs | null) => {
    const dateTo = date?.format('YYYY-MM-DD');
    setFilterValues(() => ({ ...filterValues, toDate: dateTo }));
  };

  const handleClearFilter = () => {
    setFilterValues(() => ({
      ...filterValues,
      activityId: '',
      modules: [],
      action: [],
      fromDate: '',
      toDate: '',
    }));
    setAnchorEl(null);
    getWorkLoadAll(0, 'reset');
  };
  const fromDateDayjs = dayjs(filterValues.fromDate);
  const toDateDayjs = dayjs(filterValues.toDate);

  const ActivityFilterWithParams = () => {
    setAnchorEl(null);
    getWorkLoadAll(0);
  };
  const modules_service: any[] = [
    { value: 'module1', label: 'Module1' },
    { value: 'module2', label: 'Module2' },
    { value: 'module3', label: 'Module3' },
    // { label: 'module4', value: 'Module4' },
    // { label: 'module5', value: 'Module5' },
    { value: 'module6', label: 'Module6' },
  ];

  const action_service: any[] = [
    { value: 'capture', label: 'iCapturer' },
    { value: 'characterizer', label: 'iCharacterizer' },
    { value: 'allocator', label: 'iAllocator' },
    { value: 'DocToDataset', label: 'Similarity Analysis' },
    { value: 'DocToDoc', label: 'Document similiarity' },
    { value: 'docdiffer', label: 'Compare versions' },
    { value: 'SelfDoc', label: 'Find duplicates' },
    { value: 'ner', label: 'Extract topics' },
    { value: 'Translator', label: 'Translator' },
    { value: 'RexBook', label: 'RexBook' },
    { value: 'ScheduleAnalysis', label: 'Schedule Analysis' },
  ];

  const customAction = (selected) => {
    const labels = selected.map(({ label }) => label).join(', ');
    return labels ? (
      <b style={{ color: '#002A45' }}>{labels}</b>
    ) : (
      <b style={{ color: '#002A45' }}>Action</b>
    );
  };

  // const customModule = () => {
  //   return <b style={{ color: '#002A45' }}>Module</b>;
  // };
  const customModule = (selected) => {
    const labels = selected.map(({ label }) => label).join(', ');
    return labels ? (
      <b style={{ color: '#002A45' }}>{labels}</b>
    ) : (
      <b style={{ color: '#002A45' }}>Modules</b>
    );
  };

  const fomdate = !filterValues.toDate ? today : dayjs(filterValues.toDate);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            style={{ flex: 1, display: 'flex', gap: '4px', padding: '0.8rem 0.8rem 0rem 0.8rem' }}
          >
            <TextField
              style={{ flex: 1, backgroundColor: 'white', color: '#002A45', height: '40px' }}
              size="small"
              type="number"
              id="outlined-basic"
              label="Activity Id"
              variant="outlined"
              value={filterValues.activityId}
              onChange={handleActivityIdChange}
            />
            <DatePicker
              label="Form Date"
              // slotProps={{
              //   textField: { size: 'small', sx: { backgroundColor: 'white', color: '#002A45' } },
              // }}
              value={fromDateDayjs}
              maxDate={fomdate}
              onChange={handleFromDateChange}
              slotProps={{
                textField: {
                  size: 'small',
                  error: 'false',
                  sx: {
                    backgroundColor: 'white',
                    color: '#002A45',
                  },
                  className: classes.customDatePicker,
                },
              }}
            />

            <DatePicker
              label="To Date"
              // slotProps={{
              //   textField: { size: 'small', sx: { backgroundColor: 'white', color: '#002A45' } },
              // }}
              minDate={fromDateDayjs}
              value={toDateDayjs}
              onChange={handleToDateChange}
              maxDate={yesterday}
              slotProps={{
                textField: {
                  size: 'small',
                  sx: {
                    backgroundColor: 'white',
                    color: '#002A45',
                  },
                  className: classes.customDatePicker,
                },
              }}
            />
          </Box>
          <Box
            style={{
              flex: 1,
              display: 'flex',
              gap: '4px',
              //   alignItems: 'center',
              padding: '0.8rem',
            }}
          >
            <FormControl style={{ flex: 1 }}>
              <MultiSelect
                options={modules_service}
                value={filterValues.modules}
                onChange={handleModulesChange}
                valueRenderer={customModule}
                labelledBy="Modules"
                className={classes.formControlMulti1}
                disableSearch={true}
              />
            </FormControl>

            <FormControl style={{ flex: 1 }}>
              <MultiSelect
                options={action_service}
                value={filterValues.action}
                // labelledBy="Select"
                isCreatable={true}
                onChange={handleActionChange}
                valueRenderer={customAction}
                labelledBy="Select"
                disableSearch={true}
                className={classes.formControlMulti1}
              />
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              startIcon={<FilterAltOffIcon />}
              onClick={handleClearFilter}
            >
              Clear Filter
            </Button>
            <Button variant="contained" color="primary" onClick={ActivityFilterWithParams}>
              Submit
            </Button>
          </Box>
        </Box>
      </LocalizationProvider>
    </>
  );
};

export default WorkloadFilter;
