/**
 * Format number with currency if empty or NaN return '-'
 * @param val
 * @param options
 */
type Options = {
  undo?: any; // real number
  abs?: any; // > 0
  currency?: any; // decimal + euro symbol
  percent?: any; // % symbol
};
export default function formatNumber(val: any, options = {} as Options): any {
  const valNew = String(val)
    .replace(/[^(0-9|,|.|\-)]/gi, '')
    .replace(/,/gi, '.');

  // If we have undo options
  if (options.undo !== undefined) {
    if (options.abs !== undefined) {
      return Math.abs(Number(valNew));
    }
    return Number(valNew);
  }
  if (valNew === '0' || val === 0) {
    return '-';
  }
  if (Number(valNew) === 0 || String(valNew).trim() === '' || Number.isNaN(Number(valNew))) {
    /**
     * we want > 0 if not number return empty
     * confusion with '-' as negative number
     */
    if (options.abs !== undefined) {
      return '';
    }
    return '-';
  }

  let configNumberFormat = { maximumFractionDigits: 2 };

  // If we have a currency
  if (options.currency !== undefined) {
    configNumberFormat = {
      ...configNumberFormat,
      ...{ style: 'currency', currency: options.currency },
    };
  }

  if (options.percent !== undefined) {
    return `${new Intl.NumberFormat('fr-FR', configNumberFormat).format(Number(valNew))} %`;
  }
  // if options abs, we return an absolute otherwise return the number
  return new Intl.NumberFormat('fr-FR', configNumberFormat).format(
    options.abs !== undefined ? Math.abs(Number(valNew)) : Number(valNew)
  );
}
