import { Table } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import MultiallocTableStyles from './MultiallocTableStyles';

const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const MultiAllocTableComp = withStyles(MultiallocTableStyles, { name: 'MultiAllocTableComp' })(
  TableComponentBase
) as any;

export default MultiAllocTableComp;
