/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppJwtType } from 'Auth';
import { Tree, TreeNode } from 'react-organizational-chart';
import { Avatar, Badge, Button, CircularProgress } from '@material-ui/core';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { makeStyles } from '@mui/styles';
import Employee from './Employees';
import { getAllEmployees, getMyDBObjectives, getObjectives } from '../../../../../api/users';
import useAppToken from '../../../../../app/useAppToken';
import JobDialog from './JobDialog';
import ProjectContext from '../../../../Project/ProjectContext';
import Loading from '../../../../../components/Core/Loading';
import {
  BoxMyObjectives,
  ButtonBackgroundMyObjectives,
  MessageContainerMyObjectives,
  NodeMyObjectives,
  SubmitButtonMyObjectives,
  TreeMyObjectives,
  UpdatedMyObjectives,
} from '../styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MyObjectivesProps {
  // Define the props for your component here
}

const EmployeesNode = ({ data, loginFirstName, employeeDetails, setEmployeeDetails }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { setSnack } = useContext(ProjectContext);
  const [updated, setUpdated] = useState(false); // State to track if updated
  const [dbValue, setDbValue] = useState([]);

  const markdownToHtml = (markdown) => {
    // Replace newline characters
    let html = markdown.replace(/\n/g, '<br/>');

    // Convert headers (####, ###, ##, #)
    html = html.replace(/#### (.*?)(<br\/>|$)/g, '<h4>$1</h4>');
    html = html.replace(/### (.*?)(<br\/>|$)/g, '<h3>$1</h3>');
    html = html.replace(/## (.*?)(<br\/>|$)/g, '<h2>$1</h2>');
    html = html.replace(/# (.*?)(<br\/>|$)/g, '<h1>$1</h1>');

    // Convert bold text
    html = html.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Convert specific keyword formatting (without the need for a line break)
    html = html.replace(
      /(Specific:|Measurable:|Achievable:|Relevant:|Time-bound:)/g,
      '<strong>$1</strong>'
    );

    // Ensure empty lines are handled to prevent formatting issues
    html = html.replace(/(<br\/>){2,}/g, '<br/>'); // Replace multiple line breaks with a single one

    return html;
  };

  async function fetchObejctive(fName: String, lName: String) {
    try {
      // Check if MyObjectives is not empty and has the objectives property
      const myObjectives = await getMyDBObjectives(fName, lName);

      // Check if myObjectives is not empty and has the objectives property
      if (myObjectives && myObjectives.length > 0 && myObjectives[0].objectives) {
        const objectivesHtml = markdownToHtml(myObjectives[0].objectives.replace(/\\/g, ''));
        myObjectives[0].objectives = objectivesHtml; // Update the objectives to HTML format
        setDbValue(myObjectives); // Set the state with the updated objectives
      } else {
        setDbValue([]); // Handle case where no objectives are available
      }
    } catch (error) {
      console.error('Error fetching objectives:', error);
      setDbValue([]); // Reset or handle error scenario
    } finally {
      // setLoading(false); // Hide loading indicator
    }
  }

  const handleDialogOpen = () => {
    setDialogOpen(true);
    // setLoading(true);
    const firstName = data?.First_Name;
    const lastName = data?.Last_Name;
    fetchObejctive(firstName, lastName);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSubmit = (formData: any) => {
    if (!formData.jobTitle || !formData.jobDescription) {
      setSnack('Please fill in all fields', 'error');
      return;
    }

    const payload =
      data?.First_Name === loginFirstName
        ? {
            ...employeeDetails,
            manager_info: {
              job_title: formData.jobTitle,
              job_description: formData.jobDescription,
              objectives: formData.objectives,
              project_title: formData.projectTitle,
              project_details: formData.projectDescription,
              firstname: data.First_Name,
              lastname: data.Last_Name,
            },
          }
        : {
            ...employeeDetails,
            employees_info: {
              ...employeeDetails.employees_info,
              [`${data.First_Name}_${data.Last_Name}`]: {
                job_title: formData.jobTitle,
                job_description: formData.jobDescription,
                firstname: data.First_Name,
                lastname: data.Last_Name,
              },
            },
          };
    setEmployeeDetails(payload);
    setUpdated(true); // Update state to show check mark
    handleDialogClose(); // Close the dialog after submission
    setSnack('Updated successfully', 'success');
  };

  return (
    <>
      <NodeMyObjectives>
        <BoxMyObjectives as={updated ? UpdatedMyObjectives : BoxMyObjectives}>
          <Avatar style={{ backgroundColor: '#002a45', marginRight: '10px' }}>
            {data?.First_Name.charAt(0)}
            {data?.Last_Name.charAt(0)} {/* You can customize the avatar */}
          </Avatar>
          <div>
            <span
              onClick={handleDialogOpen}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                color: '#002a45',
                fontSize: '16px',
                fontWeight: 'normal',
              }}
            >
              <strong>{data?.First_Name}</strong> <strong>{data?.Last_Name}</strong>
            </span>
            <br />
            <small style={{ fontSize: '12px' }}>{data?.Job_Title}</small>
          </div>
        </BoxMyObjectives>
      </NodeMyObjectives>
      <JobDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onSubmit={handleSubmit}
        data={data}
        firstName={loginFirstName}
        dbData={dbValue}
      />
    </>
  );
};

const MyObjectives: React.FC<MyObjectivesProps> = (props) => {
  const history = useHistory();
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTree, setLoadingTree] = useState(false);
  const [objectives, setObjectives] = useState<any>([]);
  const [showTree, setShowTree] = useState(true);
  const [employeeDetails, setEmployeeDetails] = useState<any>({
    manager_info: {},
    employees_info: {},
  });
  const { setSnack } = useContext(ProjectContext);
  const token = JSON.parse(localStorage.getItem('azJWT') || '{}');
  useEffect(() => {
    async function fetchMyAPI() {
      setLoadingTree(true);

      const body = {
        manager_firstname: token?.account?.idTokenClaims.name.split(' ')[1],
        manager_lastname: token?.account?.idTokenClaims.name.split(' ')[0],
      };

      getAllEmployees(body)
        .then((data) => {
          setLoadingTree(false);
          setEmployees(data?.employees_info);
        })
        .catch((error) => console.error('Error:', error));
    }
    fetchMyAPI();
  }, []);

  const data = {
    parent: {
      First_Name: token?.account?.idTokenClaims.name.split(' ')[1],
      Last_Name: token?.account?.idTokenClaims.name.split(' ')[0],
      Job_Title: token?.account?.idTokenClaims.jobTitle,
    },
    children: employees || [],
  };

  const genderTree = (node) => {
    // Check if node is an array and not empty
    if (Array.isArray(node) && node.length > 0) {
      return node.map((item) => (
        <TreeNode
          key={item.First_Name + item.Last_Name}
          label={
            <EmployeesNode
              data={item}
              loginFirstName={data?.parent.First_Name}
              employeeDetails={employeeDetails}
              setEmployeeDetails={setEmployeeDetails}
            />
          }
        >
          {genderTree(item)}
        </TreeNode>
      ));
    }
    return null; // Return null if node is undefined or empty
  };

  const handleGoBack = () => {
    history.goBack();
  };

  // Function to convert Markdown to HTML
  const markdownToHtml = (markdown) => {
    // Replace newline characters with <br/>
    let html = markdown.replace(/\n/g, '<br/>');

    // Convert headers (####)
    html = html.replace(/#### (.*?)<br\/>/g, '<h4>$1</h4>');

    // Convert bold text
    html = html.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Replace additional headers if necessary (e.g., ###, ##, #)
    html = html.replace(/### (.*?)<br\/>/g, '<h3>$1</h3>');
    html = html.replace(/## (.*?)<br\/>/g, '<h2>$1</h2>');
    html = html.replace(/# (.*?)<br\/>/g, '<h1>$1</h1>');

    return html;
  };

  const submitObjectives = async () => {
    // Utility function to check if an object is empty
    const isObjectEmpty = (obj: any) => {
      return Object.keys(obj).length === 0;
    };

    // Check if the state is empty
    const isManagerInfoEmpty = isObjectEmpty(employeeDetails.manager_info);
    const isEmployeesInfoEmpty = isObjectEmpty(employeeDetails.employees_info);

    // If either the manager_info or employees_info is empty, show an error message
    if (isManagerInfoEmpty || isEmployeesInfoEmpty) {
      setSnack('Please fill in the job details for all employees', 'error');
      return;
    }
    setLoading(true);
    const payload = {
      manager_info: employeeDetails.manager_info,
      employees_info: Object.values(employeeDetails.employees_info).filter(
        (employee) => Object.keys(employee).length > 0
      ),
    };

    const response = await getObjectives(payload);
    if (response?.output) {
      setShowTree(false);
      setLoading(false);
    }

    // Remove escaping and parse the JSON string
    const parsedData = JSON.parse(response?.output);

    // Process the Objectives
    const processedData = parsedData.map((item) => ({
      ...item,
      Objectives: markdownToHtml(item.Objectives.replace(/\\/g, '')),
    }));
    // Log the parsed data
    setObjectives(processedData);
  };

  return (
    <>
      {showTree ? (
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoBack}
            style={{ margin: '20px' }}
          >
            Go Back
          </Button>
          {loadingTree ? (
            <Loading />
          ) : employees.length !== 0 ? (
            <>
              <TreeMyObjectives>
                <Tree
                  lineWidth="2px"
                  lineColor="#002a45"
                  lineBorderRadius="10px"
                  label={
                    <EmployeesNode
                      data={data?.parent}
                      loginFirstName={data?.parent.First_Name}
                      employeeDetails={employeeDetails}
                      setEmployeeDetails={setEmployeeDetails}
                    />
                  }
                >
                  {genderTree(data?.children)}
                </Tree>
              </TreeMyObjectives>
              <br />

              <ButtonBackgroundMyObjectives>
                <SubmitButtonMyObjectives onClick={submitObjectives}>
                  {loading && <CircularProgress style={{ color: 'white' }} size={20} />}
                  Generate Objectives with AI
                </SubmitButtonMyObjectives>
              </ButtonBackgroundMyObjectives>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <MessageContainerMyObjectives>
                <p
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  The login user does not have any employees
                </p>
              </MessageContainerMyObjectives>
            </div>
          )}
        </div>
      ) : (
        <Employee
          objectives={objectives}
          setObjectives={setObjectives}
          setShowTree={setShowTree}
          employeeDetails={employeeDetails}
          setEmployeeDetails={setEmployeeDetails}
        />
      )}
    </>
  );
};

export default MyObjectives;
