import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Card } from '@mui/material';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    // maxWidth: 1500,
    backgroundColor: '#002A45',
    border: '1px solid black',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: 1,
    transition: 'opacity 1s',
    '& .MuiTooltip-arrow': {
      color: '#eef1f9',
      '&:before': {
        backgroundColor: '#002A45',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid black`,
        fontSize: 50,
      },
    },
  },
});

const HoverToolTipForText = ({ renderedCellValue, children }) => {
  return (
    <>
      <CustomWidthTooltip
        placement="bottom-start"
        arrow
        // open={true}
        title={
          <>
            <Card
              style={{
                background: 'while',
                color: '#002A45',
                fontWeight: 'bold',
                margin: 0,
                padding: 5,
              }}
            >
              {renderedCellValue}
            </Card>
          </>
        }
      >
        {children}
      </CustomWidthTooltip>
    </>
  );
};

export default HoverToolTipForText;
