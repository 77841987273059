import React, { useCallback, useContext, useMemo, useState } from 'react';
import useAppToken from '../../../app/useAppToken';
import DragAndDrop from '../../Core/DragAndDrop';
import Filescolumns from '../const/DocFiles';
import FileTranslatorContext from '../FileTranslatorContext';
import FileDataTable from './FileDataTable';

const GlossaryFiles = () => {
  const { glossaryFiles, glossarySelection, setGlossarySelection } = useContext(
    FileTranslatorContext
  );

  const column = useMemo(() => {
    const col = [
      { name: 'fromLang', title: 'From Language' },
      { name: 'toLang', title: 'To Language' },
    ];
    return [...Filescolumns.slice(0, 1), ...col, ...Filescolumns.slice(1, Filescolumns.length)];
  }, [Filescolumns]);

  const handleGlossarySelectionChange = useCallback(
    (selectedKeys: any) => {
      const ArrayFiles = selectedKeys;
      if (ArrayFiles.length > 3) {
        // setSnack('more than 3 file not allowed!', 'error');
      } else {
        // const data = selectedKeys.map((data) => {
        //   return glossaryFiles[data];
        // });
        //   setProcessCards(data);
        setGlossarySelection(selectedKeys);
      }
    },
    [glossaryFiles]
  );

  return (
    <>
      <FileDataTable
        selection={glossarySelection}
        setSelection={setGlossarySelection}
        Filescolumns={column}
        handleSelectionChange={handleGlossarySelectionChange}
        files={glossaryFiles}
      />
    </>
  );
};

export default GlossaryFiles;
