export const pdfInitialState = {
  fileType: 'PDF',
  filename: '',
};

const pdfFilereducers = (state, action) => {
  switch (action.type) {
    case 'change_filname': {
      return { ...state, sheetName: action.payload };
    }

    default:
      return state;
  }
};

export default pdfFilereducers;
