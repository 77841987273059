import { AlertProps } from '@material-ui/lab';
import React, { useState } from 'react';
import isEqual from 'react-fast-compare';
import AlertSnack from '../../components/Alert/AlertSnack';

export interface Module4ContextType {
  project: Project;
  setProject: (d: Project) => any;
  setSnackMsg: any;
  setSnackType: any;
}

const Module4Context = React.createContext<Module4ContextType>({
  project: {} as any,
  setProject: (): any => {},
  setSnackMsg: (): any => {},
  setSnackType: (): any => {},
});

type Props = {
  project: Project;
  children: any;
};

const Module4ContextProvider = ({ project, children }: Props): any => {
  const [_project, _setProject] = useState(project);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackType, setSnackType] = useState<AlertProps['severity']>('success');
  return (
    <Module4Context.Provider
      value={{
        project: _project,
        setProject: (_input) => {
          if (!isEqual(project, _input)) _setProject(_input);
        },
        setSnackMsg,
        setSnackType,
      }}
    >
      {snackMsg !== '' && (
        <AlertSnack
          msg={snackMsg}
          severity={snackSeverity}
          handleClose={handleClose}
          duration={3000}
        />
      )}
      {children}
    </Module4Context.Provider>
  );
};

export default Module4Context;

export { Module4ContextProvider };
