import { Table } from '@devexpress/dx-react-grid-material-ui';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

export function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const MultiallocTableStyles = (Theme: any) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd):hover': {
      backgroundColor: 'none',
    },
    '& tbody tr:nth-of-type(even):hover': {
      backgroundColor: 'none',
    },
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: '#EEF1F9 !important',
    },
    '& thead tr:nth-of-type(odd)': {
      backgroundColor: `${Theme.palette.primary.main} !important`,
      '& :hover': {
        color: 'white !important',
        opacity: 0.8,
      },
      '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
        color: 'White !important',
      },
      '& .MuiTableSortLabel-icon': {
        color: 'white !important',
        opacity: 1,
      },
      '& .MuiCheckbox-root': {
        color: 'white !important',
        opacity: 1,
      },
    },
    '& tr': {
      backgroundColor: 'white',
    },
    '& th': {
      color: 'white',
      fontWeight: 'bold',
      paddingTop: 2,
      paddingBottom: 2,
    },
    '& .MuiTableCell-body': {
      // paddingTop: 0,
      // paddingBottom: 0,
      padding: 10,
      // backgroundColor: 'white',
      '& .TableCell-cell': {
        // paddingTop: 0,
        backgroundColor: 'black',
      },
    },
  },
});

export const tUseStyles = makeStyles((theme: Theme) => ({
  resultPanel: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: '0px !important',
  },
  panelMain: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
    padding: '0px !important',
  },
  tableRadius: {
    borderRadius: '10px',
  },
}));

export const rowComponent = ({ ...restProps }) => {
  return <Table.Row {...restProps} style={{ backgroundColor: '#E4EFF9' }} />;
};
export default MultiallocTableStyles;
