/* eslint-disable react/destructuring-assignment */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { Paper } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import React, { useContext, useEffect, useState } from 'react';
import TStyles, { rowComponent, tUseStyles } from '../../../components/Datagrid/TStyles';
import ProjectContext from '../../Project/ProjectContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
// table header
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

// table Filter style

const columns: any = [
  { name: 'name', title: 'Name' },
  { name: 'profile', title: 'Job Title' },
  // { name: 'id', title: 'ID' },
  // { name: 'role_id', title: 'Job Title' },
  { name: 'email', title: 'Email' },
  { name: 'department', title: 'Department' },
  { name: 'site_code', title: 'Site Code' },
  // { name: 'project_name', title: 'Projects' },
];

function UserContact() {
  const classes = useStyles();
  const tableUseStyles = tUseStyles();
  const { projects } = useContext(ProjectContext);
  // console.log(projects);
  const [files, setFiles] = useState<any[]>([]);
  const [selection, setSelection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const [apiData, setApiData] = useState<any>({});
  const [pageSizes] = useState([5, 10, 20, 50]);

  useEffect(() => {
    // const users = projects.map((project) => {
    //   return project.users.map((project) => {
    //     return project.id;
    //   });
    // });
    // const { Users } = projects.map((project) => {
    //   return project.users?.map((p) => {
    //     return p.user.name;
    //   });
    // });
    const usersArray = [];
    projects.forEach((item) => {
      (item.users || []).forEach((users) => {
        usersArray.push({
          id: users.user_id,
          name: users.user.name,
          department: users.user.details?.department,
          site_code: users.user.details?.site_code,
          email: users.user.email,
          profile: users.user.details?.job_function,
          project_name: projects
            .map((project) => {
              if (users.project_id === project.id) {
                return project.name;
              }
              return null;
            })
            .filter((user) => {
              return user;
            }),
        });
      });
    });
    const arr = usersArray;
    const ids = arr.map((o) => o.id);
    const usersArrayFinal = arr.filter(({ id }, index) => !ids.includes(id, index + 1));

    // const allResources = [] as any[];
    // if (Users) {
    //   for (const eachFolder of Users) {
    //     if (eachFolder.resources && eachFolder.resources.length > 0)
    //       for (const r of eachFolder.resources) {
    //         console.log('hi');
    //         // get file extension(only xls, xlsx, csv) before that convert to lower case
    //         //         const fileExtension = r.filename.toLowerCase().split('.').pop();
    //         //         allResources.push({
    //         //           ...r,
    //         //           Name: ,
    //         //         });
    //         // if (fileExtension === 'zip')
    //         allResources.push({
    //           ...r,
    //           id: eachFolder.id,
    //           // size: formatBytes(Number(r.size), 0),
    //           // id: r.etag,
    //           // folderName: eachFolder.name,
    //           // folderId: eachFolder.id,
    //         });
    //       }
    //   }
    // }
    // const users = projects.map((project) => {
    //   return project.users[0];
    // })
    // setFiles(
    //   projects.map((project) => {
    //     return project.users[0];
    //   })
    // );
    setFiles(usersArrayFinal);
  }, []);

  let fileIs1: any;
  let fileContainer1: any;
  if (selection.length > 0 && selection[0] !== undefined && selection.length < 2) {
    fileIs1 = files[selection[0]].filename || '';
    fileContainer1 = files[selection[0]].container || '';
    // console.log(fileIs1);
  }

  function callIframeAPI() {
    // console.log(fileIs1);
    setDisplay(false);
    setTimeout(() => {
      setDisplay(true);
      setApiData(
        // eslint-disable-next-line max-len
        `https://isens-m4.azurewebsites.net/explorer/banner=true&card=true/container=${fileContainer1}&blob_name=${fileIs1}`
      );
    }, 1000);
  }

  const onChangeAttribute = (selection: any) => {
    const newselection: any = [0];
    if (selection[0] === undefined) {
      // eslint-disable-next-line no-param-reassign
      selection[0] = selection.slice(-1).pop();
    }
    // eslint-disable-next-line prefer-destructuring
    newselection[0] = selection[1];
    setSelection(newselection);
    if (selection.length === 1) {
      // console.log('if condition no action required: ', selection);
    } else {
      // console.log('else', selection.slice(-1).pop());
      const fileno = selection.slice(-1).pop();
      // getFileDetails(files[fileno].filename);
    }
    // console.log(files[selection[0]]);
  };

  return (
    <>
      {/* <div>
        <span>
          <b>Select one zip file:</b>
        </span>
        <span>
          <br />
          {/* Total rows selected: {selection.length} 
          <span style={{ marginRight: '10px' }}>
            Selected File Name:<b>{fileIs1 || ''}</b>
          </span>
        </span>
        <br />
      </div> */}
      <div>
        <Paper className={tableUseStyles.panelMain} style={{ margin: 10 }}>
          <ExpressGrid rows={files} columns={columns}>
            <DataTypeProvider
              for={['name']}
              formatterComponent={({ value }) => <span title={value}>{value}</span>}
            />
            <SelectionState />
            <FilteringState defaultFilters={[{ columnName: 'Name', value: '' }]} />
            <IntegratedFiltering />
            <SortingState />
            <IntegratedSorting />
            <PagingState defaultCurrentPage={0} defaultPageSize={5} />
            <IntegratedPaging />
            <VirtualTable />
            <Table tableComponent={TableComponent} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
            <PagingPanel pageSizes={pageSizes} />
          </ExpressGrid>

          {/* <span style={{ float: 'right', marginRight: '10px' }}>
              Selected File Name: <b>{fileIs1 || 'NA'}</b>
            </span> */}
          <br />
          {/* {loading ? (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <CircularProgress color="secondary" />
              </div>
            ) : (
              ''
            )} */}
          {/* <Button
            type="button"
            disabled={fileIs1 === 'NA' || fileIs1 === undefined}
            variant="contained"
            color="primary"
            onClick={callIframeAPI}
            style={{
              marginTop: '10px',
              height: '50px',
              width: '150px',
              float: 'right',
              marginRight: '10px',
              marginBottom: '10px',
            }}
          >
            Analyse
          </Button>
        </Paper>
        <br />
        {display && (
          <Grid>
            <Iframe
              url={apiData}
              width="99.99%"
              height="800px"
              id="myId"
              className="myClassname"
              // display="initial"
              position="relative"
              overflow="hidden"
              scrolling="no"
            />
          </Grid>
        )} */}
        </Paper>
      </div>
    </>
  );
}

export default UserContact;
