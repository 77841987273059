import React, { useState } from 'react';
import addAllocation, {
  addMultiAllocation,
  deleteMultiAllocation,
  removeAllocation,
} from '../../../../../../../api/allocations';
import { saveAllocations } from '../../../../../../../api/ownings';
import {
  AllocationsReceiverExtraction,
  MutliAllocationExtraction,
} from '../../utils/allocationExtraction';
import { allocationKeys } from '../../utils/convertToPairs';
import { extractUserName } from '../../utils/extractdata';

function useRequirementOperation(props) {
  const {
    appJWT,
    metaData,
    onChangeTableData,
    groups,
    users,
    onChangeEdit,
    projectId,
    workloadId,
    filename,
    setNotify,
  } = props;
  const [loading, setLoading] = useState(false);

  function AddAllocation(owning_id, row_id) {
    const allo = {
      allocation: `${appJWT.user_id}_${Date.now()}`,
      owning_id: Number(owning_id),
      allocation_score: '',
      created_by: Number(appJWT.user_id),
      lifecycle: 'characterized',
      compliance: 'tbd',
    } as any;
    setLoading(true);
    addAllocation(allo.owning_id, allo).then((res) => {
      if (res) {
        const newTableData = {
          ...metaData,
          [row_id]: {
            ...metaData[row_id],
            kpi_lifecycle: res.kpi_lifecycle,
            kpi_compliance: res.kpi_compliance,
            kpi_multiallocations: res.kpi_multiallocations,
            allocations: AllocationsReceiverExtraction(res?.allocations?.reverse(), groups, users),
            lifecycles: res.lifecycles,
            updated_at: res.updated_at,
            id: res.id,
          },
        };

        onChangeTableData(newTableData);
        setNotify('Allocation added successfully', 'success');
        setLoading(false);
        onChangeEdit({});
      }
    });
  }

  function saveMetaData(newTableData, item) {
    const temp = newTableData;
    temp[item.row_id] = {
      ...temp[item.row_id],
      allocations: {
        ...temp[item.row_id].allocations,
        [item.allocationId]: {
          ...temp[item.row_id].allocations[item.allocationId],
          pbs: item.pbs,
          abs: item.abs,
          obs: item.obs,
          receiver: item.receiver,
        },
      },
    };
  }

  function handleSaveAllocation(data) {
    setLoading(true);
    const newTableData = {
      ...metaData,
    };

    const valuesforData = Object.values(data);

    if (valuesforData.length > 0) {
      for (const item of valuesforData) {
        saveMetaData(newTableData, item);
      }
    }

    const ids = valuesforData.map((Data: any) => {
      return {
        parentId: Data.row_id,
        allocationId: Data.allocationId,
      };
    });

    const payload = ids.map((Data) => {
      const eachObj = { ...newTableData[Data.parentId].allocations[Data.allocationId] };

      return { ...eachObj, receiver: Number(extractUserName(eachObj.receiver, 'id')) };
    });

    saveAllocations(payload, projectId, Number(workloadId), filename)
      .then((repsonse) => {
        onChangeEdit({});
        onChangeTableData(newTableData);
        setLoading(false);
        setNotify('Allocation saved successfully', 'success');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleAssingAllocation(editedRow, selectedRow) {
    if (selectedRow.length === 0) {
      return;
    }
    setLoading(true);
    const newTableData = {
      ...metaData,
    };

    const valuesforData = Object.values(editedRow);

    if (valuesforData.length > 0) {
      for (const item of valuesforData) {
        saveMetaData(newTableData, item);
      }
    }

    const ids = selectedRow.map((Data) => {
      return {
        parentId: Data.row_id,
        allocationId: Data.id,
        owning_id: Data.owning_id,
      };
    });

    const payload = ids.map((Data) => {
      const eachObj = { ...newTableData[Data.parentId].allocations[Data.allocationId] };

      return { ...eachObj, receiver: Number(extractUserName(eachObj.receiver, 'id')) };
    });
    saveAllocations(payload, projectId, Number(workloadId), filename, undefined, {
      withNotifs: true,
    }).then((response) => {
      const resposeKeys = {};
      for (const resItem of response) {
        resposeKeys[resItem.id] = resItem;
        resposeKeys[resItem.id].allocations = AllocationsReceiverExtraction(
          resItem.allocations,
          groups,
          users
        );
      }

      for (const idItem of ids) {
        newTableData[idItem.parentId] = {
          ...newTableData[idItem.parentId],
          ...resposeKeys[idItem.owning_id],
        };
      }

      onChangeTableData(newTableData);
      onChangeEdit({});
      setNotify('Allocation assigned successfully', 'success');
      setLoading(false);
    });
  }

  function deleteAllocation(row) {
    setLoading(true);
    const newTableData = {
      ...metaData,
    };

    const ids = row.map((Data) => {
      return {
        parentId: Data.row_id,
        allocationId: Data.id,
        owning_id: Data.owning_id,
        allocation: Data.allocation,
      };
    });

    const { allocation, owning_id, allocationId } = ids[0];

    removeAllocation(owning_id, { allocation, id: allocationId }).then((res: any) => {
      const response = [];
      response.push(res);
      const resposeKeys = {};
      for (const resItem of response) {
        resposeKeys[resItem.id] = resItem;
        resposeKeys[resItem.id].allocations = AllocationsReceiverExtraction(
          resItem.allocations,
          groups,
          users
        );
      }

      for (const idItem of ids) {
        newTableData[idItem.parentId] = {
          ...newTableData[idItem.parentId],
          ...resposeKeys[idItem.owning_id],
        };
      }

      onChangeTableData(newTableData);
      onChangeEdit({});
      setNotify('Allocation deleted successfully', 'success');
      setLoading(false);
    });
  }

  const handleMultiAllocSubmit = (selectedPBS, handleClose, keys, user_id) => {
    setLoading(true);
    const newMetaData = {
      ...metaData,
    };

    const selectedValus = selectedPBS.map((item: any) => {
      return item.name;
    });

    const parentKeys = keys.map((item) => {
      return item.parentId;
    });

    const alloationId = keys.map((item) => {
      return item.allocationId;
    });

    const meta_id = [...new Set(parentKeys)];
    const alloc_id = [...new Set(alloationId)];

    const needToSaveDatas = [] as any;
    const needToCreateNewAllocations = [] as any;
    const needToDeleteAllocations = [] as any;

    for (const eachKey of meta_id) {
      const { allocations, id } = newMetaData[eachKey];

      const allocatValues = Object.values(allocations);

      let track_values = 0;
      let allocationIndex;

      for (
        allocationIndex = 0;
        allocationIndex < allocatValues.length && track_values < selectedValus.length;
        allocationIndex += 1
      ) {
        const eachItem = allocatValues[allocationIndex] as any;

        if (alloc_id.includes(eachItem.id)) {
          newMetaData[eachKey] = {
            ...newMetaData[eachKey],
            allocations: {
              ...newMetaData[eachKey].allocations,
              [eachItem.id]: {
                ...newMetaData[eachKey].allocations[eachItem.id],
                pbs: selectedValus[track_values],
                ...MutliAllocationExtraction(selectedValus[track_values], groups, users),
              },
            },
          };

          const { allocations } = newMetaData[eachKey];
          const obj = { ...allocations[eachItem.id] };
          const payload = { ...obj, receiver: Number(extractUserName(obj.receiver, 'id')) };
          needToSaveDatas.push(payload);
          track_values += 1;
        }
      }

      while (allocationIndex < allocatValues.length) {
        const eachItem = allocatValues[allocationIndex] as any;
        if (eachItem.lifecycle === 'characterized') {
          needToDeleteAllocations.push(eachItem.id);
          delete newMetaData[eachKey].allocations[eachItem.id];
        }

        allocationIndex += 1;
      }

      while (track_values < selectedValus.length) {
        const obj = {
          ...MutliAllocationExtraction(selectedValus[track_values], groups, users),
        };
        const allo = {
          allocation: `${user_id}_${Date.now()}${track_values}`,
          owning_id: id,
          allocation_score: '',
          created_by: Number(user_id),
          lifecycle: 'characterized',
          compliance: 'tbd',
          pbs: selectedValus[track_values],
          ...obj,
          receiver: Number(extractUserName(obj.receiver, 'id')),
        } as any;
        needToCreateNewAllocations.push(allo);
        track_values += 1;
      }
    }

    saveAllocations(needToSaveDatas, projectId, Number(workloadId), filename)
      .then(() => {
        if (needToDeleteAllocations.length > 0) {
          return deleteMultiAllocation(needToDeleteAllocations);
        }
        return Promise.resolve();
      })
      .then(() => {
        if (needToCreateNewAllocations.length > 0) {
          return addMultiAllocation(needToCreateNewAllocations);
        }

        return Promise.resolve([]);
      })
      .then((response) => {
        if (response.length === 0) {
          onChangeTableData(newMetaData);
          handleClose();
          return Promise.resolve();
        }
        const resposeKeys = {} as any;
        for (const resItem of response) {
          resposeKeys[resItem.id] = {
            allocations: AllocationsReceiverExtraction(resItem.allocations, groups, users),
            kpi_lifecycle: resItem.kpi_lifecycle,
            kpi_compliance: resItem.kpi_compliance,
            lifecycles: resItem.lifecycles,
            updated_at: resItem.updated_at,
            id: resItem.id,
          };
        }

        for (const idItem of keys) {
          newMetaData[idItem.parentId] = {
            ...newMetaData[idItem.parentId],
            ...resposeKeys[idItem.owning_id],
          };
        }

        onChangeTableData(newMetaData);
        setLoading(false);
        handleClose();

        return newMetaData;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return {
    AddAllocation,
    loading,
    handleSaveAllocation,
    handleAssingAllocation,
    saveMetaData,
    deleteAllocation,
    handleMultiAllocSubmit,
  };
}

export default useRequirementOperation;
