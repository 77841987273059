import { AlertProps, Modal } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Box } from '@mui/system';
import React from 'react';

type Props = {
  msg: string;
  severity: AlertProps['severity'];
  duration?: number;
  handleClose?: any;
};

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '& .MuiAlert-message': { 'font-size': '26px' },
  '& .MuiSvgIcon-fontSizeInherit': {
    'font-size': '40px',
  },
  '& .MuiAlert-action': {
    padding: '10px 0 0 10px',
  },
};

function AlertSnack({ msg, severity, handleClose, duration }: Props) {
  if (duration && duration > 0) {
    setTimeout(() => {
      handleClose();
    }, duration);
  }
  return (
    <Modal open={msg !== ''} onClose={handleClose}>
      <Box sx={style}>
        <Alert variant="filled" severity={severity ?? 'success'} onClose={handleClose}>
          {msg}
        </Alert>
      </Box>
    </Modal>
  );
}

export default AlertSnack;
