/* eslint-disable react/button-has-type */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, ClickAwayListener, Typography, Button, IconButton, Tooltip } from '@material-ui/core';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import NotesIcon from '@mui/icons-material/Notes';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const DropDownLanguages = React.memo(
  ({ languageCode, onChange, valueLang, palceHolder, mode = true, row_gap = 18 }: any) => {
    const [pin, setPin] = useState(false);
    const [text, setText] = useState('');
    const [filterLang, setFilterLang] = useState(languageCode);

    useEffect(() => {
      setText('');
      setFilterLang(languageCode);
    }, [languageCode, pin]);

    const handleInput = (e) => {
      const filteredData = languageCode.filter((data: any) => {
        const t = data.toLowerCase();
        const r = e.target.value.toLowerCase();
        return t.includes(r);
      });
      if (filteredData.length > 0) setFilterLang(filteredData);
      else setFilterLang(languageCode);
      setText(e.target.value);
    };

    const handleLangugeValue = (data) => {
      if (valueLang !== data) {
        onChange(data);
      }
      setPin(false);
    };

    return (
      <>
        <div className="main-menu-wrapper">
          <div className="main-menu-btn">
            <button
              style={!valueLang ? { justifyContent: 'flex-end' } : {}}
              className="btn"
              onClick={() => {
                setPin(true);
              }}
            >
              {mode ? valueLang?.split('-')?.slice(0, 1) : valueLang?.split(' ').slice(0, 1)}
              <KeyboardArrowDownIcon />
            </button>
          </div>
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => pin && setPin(false)}
          >
            <div
              style={
                pin ? { display: 'block', opacity: '1', zIndex: '998', pointerEvents: 'auto' } : {}
              }
              className="sub-menu-wrapper d-none"
            >
              <div
                style={pin ? { opacity: '1', animation: 'fadeInUp 0.8s both' } : {}}
                className="sub-menu-inner-wrapper"
              >
                {/* <div className="arrow">{}</div> */}
                <div className="container">
                  {mode && (
                    <div className="container-header">
                      <input
                        value={text}
                        placeholder={palceHolder}
                        className="input"
                        onChange={handleInput}
                      />

                      <div
                        style={{ display: 'flex', justifyContent: 'flex-end', padding: '0.2rem' }}
                      >
                        <button
                          className="close-btn"
                          onClick={() => {
                            setPin(false);
                          }}
                          label="btn"
                        >
                          <CloseIcon fontSize="small" />
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="sub-menu-list-wrapper">
                    <div className="sub-menu-list">
                      {filterLang.slice(0, 1 * row_gap).map((data: string) => {
                        const text = data.split('-');
                        return (
                          <div key={data} className="sub-menu-item">
                            <button
                              className={`sub-menu-item-btn${
                                valueLang === data ? '-selected' : ''
                              }`}
                              // className="sub-menu-item-btn"
                              onClick={() => {
                                handleLangugeValue(data);
                              }}
                            >
                              {text[0]}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    <div className="sub-menu-list">
                      {filterLang.slice(1 * row_gap, 2 * row_gap).map((data: string) => {
                        const text = data.split('-');
                        return (
                          <div key={data} className="sub-menu-item">
                            <button
                              className={`sub-menu-item-btn${
                                valueLang === data ? '-selected' : ''
                              }`}
                              // className="sub-menu-item-btn"
                              onClick={() => {
                                handleLangugeValue(data);
                              }}
                            >
                              {text[0]}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    <div className="sub-menu-list">
                      {filterLang.slice(2 * row_gap, 3 * row_gap).map((data: string) => {
                        const text = data.split('-');
                        return (
                          <div key={data} className="sub-menu-item">
                            <button
                              className={`sub-menu-item-btn${
                                valueLang === data ? '-selected' : ''
                              }`}
                              // className="sub-menu-item-btn"
                              onClick={() => {
                                handleLangugeValue(data);
                              }}
                            >
                              {text[0]}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    <div className="sub-menu-list">
                      {filterLang.slice(3 * row_gap, 5 * row_gap).map((data: string) => {
                        const text = data.split('-');
                        return (
                          <div key={data} className="sub-menu-item">
                            <button
                              className={`sub-menu-item-btn${
                                valueLang === data ? '-selected' : ''
                              }`}
                              // className="sub-menu-item-btn"
                              onClick={() => {
                                handleLangugeValue(data);
                              }}
                            >
                              {text[0]}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </>
    );
  }
);

export default DropDownLanguages;
