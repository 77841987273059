/* eslint-disable no-self-compare */
import { Grid, makeStyles } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAllProjects } from '../../api/module1';
import Loading from '../../components/Core/Loading';
import formatDate from '../../tools/formatDate';
import ProjectCard from './components/ProjectCard';
import ProjectViews from './components/ProjectViews';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  arrangingIcon: {
    textAlign: 'right',
  },
  typographyText: {
    color: '#202020',
    font: 'normal normal bold 34px/44px Alstom',
    opacity: 1,
  },
  MeniItemsStyle: {
    '& .MuiMenuItem-root:hover': {
      background: '#002A45 0% 0% no-repeat padding-box',
      color: 'white',
    },
    '& .MuiPopover-paper': {
      border: '1px solid #002A45',
      borderRadius: '4px',
      marginLeft: -5,
      opacity: 1,
      boxSadow: '0px 3px 6px #00000029',
    },
  },
  SwitchStyle: {
    '& .gui-switch > input': {
      appearance: 'none',
      border: 'none',
      outlineOffset: '5px',
      boxSizing: 'content-box',

      padding: 'var(--track-padding)',
      background: 'var(--track-color-inactive)',
      inlineSize: 'var(--track-size)',
      blockSize: 'var(--thumb-size)',
      borderRadius: 'var(--track-size)',
    },
  },
  CardIcon: {},
  IconButton: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'none',
    },
  },
  IconDesignMain: {
    display: 'flex',
    border: '1px solid #002A45',
    borderRadius: 70,
  },
  IconDesign: {
    padding: '6px 20px',
    backgroundColor: '#002A45',
    borderRadius: 70,
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    marginTop: -1,
  },
  IconDesign2: {
    padding: '6px 5px',
    width: 30,
    // backgroundColor: 'white',
    borderRadius: 70,
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    color: '#002A45',
  },
}));

const ProjectDashboardContainer = (props: any) => {
  const [listProjects, setlistProjects] = useState(null);
  const arrange = ['Arrange By Name', 'Arrange By Entity', 'Arrange By Date'];
  const classes = useStyles();
  const [sortType, setSortType] = useState<any>('asc');
  const [projects, setProjects] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const history = useHistory();
  const [checked, setChecked] = React.useState(true);

  useEffect(() => {
    getAllProjects()
      .then((payload) => {
        setProjects(payload);
        setlistProjects(
          payload.map((project: any) => (
            <ProjectCard key={`card-project-${project.id}`} project={project} />
          ))
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);

  const handleSelect = (index) => {
    setSelectedIndex(index);
    if (index === 0) {
      const sorted = projects.sort((a, b) => {
        const isReversed = sortType === 'asc' ? 1 : -1;
        return isReversed * a.name.localeCompare(b.name);
      });
      setlistProjects(
        sorted.map((project: any) => (
          <ProjectCard key={`card-project-${project.id}`} project={project} />
        ))
      );
    }
    if (index === 1) {
      const sorted = projects.sort((a, b) => {
        const isReversed = sortType === 'asc' ? 1 : -1;
        return isReversed * a.entity.localeCompare(b.entity);
      });
      setlistProjects(
        sorted.map((project: any) => (
          <ProjectCard key={`card-project-${project.id}`} project={project} />
        ))
      );
    }
    if (index === 2) {
      const sorted = projects.sort((a, b) => {
        const aDate = formatDate(a.created_at).split('/').reverse().join();
        const bDate = formatDate(b.created_at).split('/').reverse().join();
        const isReversed = sortType === 'asc' ? 1 : -1;
        return isReversed * aDate.localeCompare(bDate);
      });
      setlistProjects(
        sorted.map((project: any) => (
          <ProjectCard key={`card-project-${project.id}`} project={project} />
        ))
      );
    }
  };

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(e) {
    setAnchorEl(null);
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  if (projects.length === 0) return <Loading />;

  return (
    <div className={classes.root}>
      {/* {IS_DEBUG_MODE && <ReactJson src={{ projects }} collapsed={true} theme="monokai" />} */}
      <Grid container justify="flex-end" alignItems="center">
        <Grid item style={{ marginRight: 90 }}>
          <IconButton
            sx={{
              ml: 1,
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
            }}
            onClick={() => setChecked(!checked)}
          >
            {checked ? (
              <Grid className={classes.IconDesignMain}>
                <div className={classes.IconDesign2}>
                  <FormatListBulletedOutlinedIcon />
                </div>
                <div className={classes.IconDesign}>
                  <AppsOutlinedIcon />
                  <span style={{ fontSize: 16 }}>Card View</span>
                </div>
              </Grid>
            ) : (
              <Grid className={classes.IconDesignMain}>
                <div className={classes.IconDesign}>
                  <FormatListBulletedOutlinedIcon />
                  <span style={{ fontSize: 16 }}>Detail View</span>
                </div>
                <div className={classes.IconDesign2}>
                  <AppsOutlinedIcon />
                </div>
              </Grid>
            )}
          </IconButton>
        </Grid>
        {checked === true ? (
          <>
            <Grid item>
              <span>
                <MoreVertOutlinedIcon onClick={handleClick} onMouseEnter={handleClick} />
              </span>
              <div>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  onMouseLeave={handleClose}
                  defaultValue="one"
                  className={classes.MeniItemsStyle}
                >
                  {arrange.map((items, index) => {
                    return (
                      <MenuItem key={`menu-${index}`} onClick={() => handleSelect(index)}>
                        {items}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
            </Grid>
          </>
        ) : null}
      </Grid>
      {checked === true ? (
        <>
          {/* <Typography variant="h4" className={classes.typographyText}>
            My Projects
          </Typography> */}
          <Grid
            container
            spacing={3}
            item
            xs={12}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            style={{ marginTop: '16px' }}
          >
            {listProjects && <>{listProjects}</>}
          </Grid>
        </>
      ) : (
        <ProjectViews />
      )}
    </div>
  );
};

export default ProjectDashboardContainer;
