import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  AppBar,
  Box,
  Button,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { getAlocationHistory } from '../../../../../api/assign';
import TabStyle from '../../../../../components/Layout/TabStyle';
import formatDate from '../../../../../tools/formatDate';

export const useStyles = makeStyles((theme) => ({
  root: {},
  bloc: {
    height: 250,
    borderRadius: 10,
    border: 'solid 1px;',
    borderColor: theme.palette.primary.light,
    margin: 10,
    backgroundColor: '#EEF1F9',
    color: '#002A45',
  },
  overflow: {
    height: 250,
    overflowY: 'scroll',
    wordWrap: 'break-word',
  },
  iconMargin: {
    marginRight: '16px',
    // color: theme.palette.primary.dark,
  },
  title: {
    textAlign: 'center',
    color: '#FFFFFF',
  },
  error: {
    color: theme.palette.error.main,
  },
  // tabs: {
  //   borderBottom: `2px solid ${theme.palette.primary.main}`,
  // },
  // tabstyle: {
  //   border: `1px solid ${theme.palette.primary.main}`,
  //   borderRadius: '5px 5px 0 0',
  //   backgroundColor: theme.palette.primary.main,
  //   color: '#fff !important',
  //   fontSize: 14,
  //   fontWeight: 700,
  // },
  pdfPanel: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    margin: 10,
    padding: '0px !important',
    minHeight: 250,
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
  },
  active: {
    background: theme.palette.success.main,
    border: 'solid 1px green',
    borderRadius: 5,
    padding: 5,
    color: 'white',
  },
  inactive: {
    background: theme.palette.error.main,
    border: 'solid 1px red',
    borderRadius: 5,
    padding: 5,
    color: 'white',
  },
  formControlMulti: {
    margin: theme.spacing(1),
    marginTop: -2,
    minWidth: 200,
    maxWidth: 400,
    border: 2,
    borderColor: 'black',
  },
  captureTypography: {
    backgroundColor: '#002A45',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    color: 'white',
  },
  captureBody: {
    backgroundColor: '#EEF1F9',
  },
}));
type Props = {
  projectId?: any;
  workloadId?: any;
  allocationId?: any;
};

function AssignHistoryContainer({ projectId, workloadId, allocationId }: Props) {
  const theme = useTheme();
  const classes = useStyles();
  const classesTab = TabStyle();
  const [value] = React.useState(0);
  const [pageSizes] = useState([5, 10, 20, 50, 100]);
  const [loading, setLoading] = useState(true);
  const [display, setDisplay] = useState<any>({});
  const [historyData, setHistoryData] = useState<any>([]);
  const [historyRes, setHistoryRes] = useState<any>([]);
  useEffect(() => {
    const usersArray: { entity: any }[] = [];
    setLoading(true);
    setDisplay(false);
    getAlocationHistory(projectId, workloadId, allocationId).then((reshistory) => {
      reshistory.forEach(
        (item: {
          user_id: any;
          content: any;
          action: any;
          lifecycle_date: any;
          allocation_id: any;
        }) => {
          usersArray.push({
            user_id: item.user_id,
            content: item.content,
            action: item.action,
            lifecycle_date: item.lifecycle_date,
            allocation_id: item.allocation_id,
          });
        }
      );
      setTimeout(() => {
        setLoading(false);
        setDisplay(true);
        setHistoryData(usersArray);
        setHistoryRes(reshistory);
      }, 2000);
    });
  }, [allocationId]);

  return (
    <>
      {loading ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        ''
      )}
      {display && (
        <>
          <div style={{ border: '1px solid #002A45', borderRadius: 5 }}>
            <Grid container style={{ color: '#002A45' }}>
              <div style={{ margin: 5 }}>
                <Grid item xs={12}>
                  <b>Id</b> : {historyRes[0]?.id}
                </Grid>
                <Grid item xs={12}>
                  <b>Characterization</b> : {historyRes[0]?.characterization}
                </Grid>
                <Grid item xs={12}>
                  <b>Text</b> : {historyRes[0]?.Text}
                </Grid>
                <Grid item xs={12}>
                  <b>LifeCycle Status</b> : {historyRes[0]?.kpi_lifecycle}
                </Grid>
                <Grid item xs={12}>
                  <b>Created on</b> : {new Date(historyRes[0]?.created_at).toLocaleDateString()}
                </Grid>
              </div>
            </Grid>
            {historyData.map((history) => {
              return (
                <>
                  {history.lifecycle_date && history.user_id ? (
                    <>
                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        style={{
                          borderTop: '1px solid black',
                          color: '#002A45',
                        }}
                      >
                        <Grid item xs={4}>
                          <center>
                            <h5>{history.user_id ? <> {history.user_id}</> : null}</h5>
                          </center>
                        </Grid>
                        {/* <Grid item xs={2}>
                      {history.content ? <>{history.content}</> : <>{history.user_id}</>}
                    </Grid> */}
                        <Grid item xs={4}>
                          <center>
                            {history.action === 'add' ? (
                              <>
                                Added allocation-
                                {history.allocation_id}
                              </>
                            ) : (
                              <>
                                {history.action === 'assign' ? (
                                  <>
                                    <>
                                      Assigned allocation -{history.allocation_id} to{' '}
                                      {history.content ? (
                                        <>({history.content})</>
                                      ) : (
                                        <>({history.user_id})</>
                                      )}
                                    </>
                                  </>
                                ) : (
                                  <>
                                    {history.action === 'delete' ? (
                                      <>
                                        <>
                                          Deleted allocation-
                                          {history.allocation_id}
                                        </>
                                      </>
                                    ) : (
                                      <>
                                        {history.action === 'reject' ? (
                                          <>
                                            Rejected allocation-
                                            {history.allocation_id}{' '}
                                            {history.content ? (
                                              <>({history.content})</>
                                            ) : (
                                              <>({history.user_id})</>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {history.action === 'accept' ? (
                                              <>
                                                Accepted allocation-
                                                {history.allocation_id} to{' '}
                                                {history.content ? (
                                                  <>({history.content})</>
                                                ) : (
                                                  <>({history.user_id})</>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {history.action.charAt(0).toUpperCase() +
                                                  history.action.slice(1)}{' '}
                                                allocation-
                                                {history.allocation_id} to{' '}
                                                {history.content ? (
                                                  <>({history.content})</>
                                                ) : (
                                                  <>({history.user_id})</>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </center>
                          {/* {history.content ? <>({history.content})</> : <>({history.user_id})</>} */}
                        </Grid>
                        <Grid item xs={4}>
                          <center>
                            {history.lifecycle_date ? (
                              <>{new Date(history.lifecycle_date).toLocaleDateString()}</>
                            ) : null}
                          </center>
                          {}
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}

export default AssignHistoryContainer;
