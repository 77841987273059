import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { addFunc, editFunc } from '../../../api/iverifier';
import useAppToken from '../../../app/useAppToken';
import { IS_DEBUG_MODE } from '../../../const';
import ProjectContext from '../../Project/ProjectContext';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    backgroundColor: theme.palette.action.hover,
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
  textField: {
    margin: '15px 5px',
    paddingRight: 10,
  },
  radio: {
    textAlign: 'center',
  },
  label: {
    marginRight: '30px',
  },
}));

type Props = {
  data?: any;
  onClose?: any;
};

export default function ModalFunctionEditor({ data, onClose }: Props) {
  const classes = useStyles();
  const { appJWT } = useAppToken();
  const { projectId } = useParams() as any;

  const { setSnack } = useContext(ProjectContext);

  // bottom buttons
  const [saving, setSaving] = useState<boolean>(false);

  // handle main form
  const { register, handleSubmit } = useForm<any>({
    defaultValues: {
      script_rule: 'a==b OR c!=d',
    },
  });

  const handleClose = (response) => {
    onClose(response);
  };
  const handleReset = () => {};

  const handleSaveFunc: SubmitHandler<any> = (data) => {
    setSaving(true);
    // eslint-disable-next-line eqeqeq
    if (data.id == null || data.id == '') {
      // addition of new rules
      const body = {
        created_by: Number(appJWT.user_id),
        project_id: Number(projectId),
        function: data?.function,
        mapping: data?.mapping,
        example: data?.example,
        standard: false,
      };
      addFunc(body)
        .then((response) => {
          handleClose(response);
          setSnack('new function added', 'success');
        })
        .catch(() => {
          setSnack('error while adding the new function', 'error');
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      const id = data?.id;
      const body = {
        id,
        function: data?.function,
        mapping: data?.mapping,
        example: data?.example,
        standard: false,
        updated_by: Number(appJWT.user_id),
      };
      editFunc(id, body)
        .then((response) => {
          handleClose(response);
          setSnack('function updated', 'success');
        })
        .catch(() => {
          setSnack('error while updating the function', 'error');
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  return (
    <>
      <Dialog
        onClose={() => handleClose(undefined)}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="lg"
        fullWidth={true}
      >
        {IS_DEBUG_MODE && <ReactJson src={{}} collapsed={true} theme="monokai" />}
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => handleClose(undefined)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={3}
            style={{ margin: 0 }}
          >
            <Grid item lg={12} sm={12} md={12}>
              <>
                <TextField
                  {...register('id')}
                  variant="outlined"
                  label="id"
                  placeholder="id"
                  fullWidth
                  className={classes.textField}
                  key="id"
                  defaultValue={data?.id}
                  style={{ display: 'none' }}
                />
                <TextField
                  {...register('function')}
                  variant="outlined"
                  label="Function"
                  placeholder="Function"
                  fullWidth
                  className={classes.textField}
                  key="function"
                  defaultValue={data?.function}
                />
                <TextField
                  {...register('mapping')}
                  variant="outlined"
                  label="Mapping"
                  placeholder="Mapping"
                  fullWidth
                  className={classes.textField}
                  key="mapping"
                  defaultValue={data?.mapping}
                />
                <TextField
                  {...register('example')}
                  multiline
                  rows={2}
                  variant="outlined"
                  label="Example"
                  placeholder="Example"
                  fullWidth
                  className={classes.textField}
                  key="example"
                  defaultValue={data?.example}
                />
              </>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogContent}>
          {saving && (
            <span>
              <CircularProgress size={26} color="secondary" />
            </span>
          )}
          <Button autoFocus onClick={handleReset} color="secondary" variant="outlined">
            Reset
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={saving}
            onClick={handleSubmit(handleSaveFunc)}
          >
            {data?.id ? 'Save Function' : 'Create Function'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
