import { useHistory } from 'react-router-dom';

function Logout() {
  // localStorage.clear();
  localStorage.removeItem('appJWT');
  localStorage.removeItem('azJWT');
  localStorage.removeItem('Module_1_column_state');
  sessionStorage.clear();
  const history = useHistory();
  history.push('/login');
  return null;
}

export default Logout;
