// import { createStyles, makeStyles, Theme } from '@material-ui/core';

// function getModalStyle() {
//   return {
//     top: '20%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   };
// }
// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     paper: {
//       color: theme.palette.primary.main,
//       position: 'absolute',
//       minWidth: 400,
//       backgroundColor: theme.palette.background.paper,
//       border: `1px solid ${theme.palette.primary.main}`,
//       boxShadow: theme.shadows[5],
//       padding: theme.spacing(0, 4, 3),
//       textAlign: 'center',
//       borderRadius: '10px',
//       opacity: 1,
//       marginTop: 60,
//     },
//     input: {
//       marginBottom: '15px',
//     },
//     error: {
//       color: theme.palette.error.main,
//     },
//     button: {
//       backgroundColor: theme.palette.primary.main,
//       color: theme.palette.secondary.main,
//     },
//   })
// );

// export { getModalStyle, useStyles };
import { createStyles, makeStyles, Theme } from '@material-ui/core';

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

function getModalStyle() {
  return {
    top: '20%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  };
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      color: theme.palette.primary.main,
      position: 'absolute',
      minWidth: 400,
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(0, 4, 3),
      textAlign: 'center',
      borderRadius: '10px',
      opacity: 1,
      marginTop: 60,
    },
    input: {
      // marginBottom: '15px',
    },
    error: {
      color: theme.palette.error.main,
    },
    button: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
    paper2: {
      color: theme.palette.primary.main,
      // position: 'absolute',
      // minWidth: 100,
      // maxWidth: 550,
      backgroundColor: theme.palette.background.paper,
      // border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: theme.shadows[5],
      // padding: theme.spacing(0, 4, 3),
      padding: 10,
      textAlign: 'center',
      borderRadius: '10px',
      opacity: 1,
      // top: 'auto',
      // left: '30%',
      // marginTop: 90,
      // paddingBottom: 10,
    },
    PaperHeight: {
      '& .MuiDialog-paperScrollPaper': {
        maxHeight: '100vh',
      },
    },
    modal: {
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
    },
    inputField: {
      width: '175px',
      fontSize: '0.875rem',
      // height: '5vh',
      // marginTop: 2,
      // marginBottom: 2,
    },
    input2: {
      marginBottom: '15px',
      borderRadius: '4px 4px 0px 0px',
      border: '1px solid #707070',
      borderRadius: '10px',
      padding: 10,
    },
    error2: {
      color: theme.palette.error.main,
    },
    button2: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
    projectName: {
      display: 'flex',
      marginLeft: '5px',
    },
    entity: {
      textAlign: 'left',
      border: '1px solid #707070',
      borderRadius: '10px',
      padding: 10,
      color: '#002A45',
    },
    type: {
      textAlign: 'left',
      border: '1px solid #707070',
      borderRadius: '10px',
      padding: 5,
    },
  })
);

export { getModalStyle, useStyles };
