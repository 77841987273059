import { Box, Tooltip, Typography, FormLabel, Grid, Card, CardContent } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import React, { useState, useContext, useEffect } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps, useParams } from 'react-router-dom';
import PublishIcon from '@mui/icons-material/Publish';
import { isEmpty } from 'lodash';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import FontDownloadOutlinedIcon from '@material-ui/icons/FontDownloadOutlined';
import CompareOutlinedIcon from '@material-ui/icons/CompareOutlined';
import CircularProgressWithLabel from '@mui/material/CircularProgress';
import BrandingWatermarkOutlinedIcon from '@material-ui/icons/BrandingWatermarkOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { List } from '@mui/material';
import { ContainerStyle, ListItem } from '../../style/ContainerStyle';
// eslint-disable-next-line import/order
import DocSimilarity from './Components/DocSimilarity';
import SimilarityAnalysisNew from './Components/SimilarityAnalysisNew';
import CompareVersions from './Components/CompareVersions';
import FindDuplicates from './Components/FindDuplicates';
import ExtractTopics from './Components/ExtractTopics';
import { BACKEND_URL } from '../../../../../const';
// import { BACKEND_URL } from '../../../../const';
import axios from '../../../../../api/axios';
import ProjectContext from '../../../../Project/ProjectContext';
import { getAllProjectCreationLanguages } from '../../../../../api/projects';
import formatBytes from '../../../../../tools/formatBytes';
import CustomDialog from '../../../../../components/Core/CustomDialoge';
import WorkSpaceContext from '../../../WorkspceContext';
// import UploadContainer from '../../core/components/UplaodContainer';
// import UploadButton from '../../core/components/UploadButton';
// import TabContainer from '../../core/TabContainer';
import ExcelFileSettings from '../../core/components/ExcelFileSettings';
import CSVFileSettings from '../../core/components/CSVFileSettings';
// import FileSettingsContainer from '../../core/components/FileSettingsContainer';
import Activities from './Components/Activities';
import DocAnalysisContext from '../DocAnalysisContext';
import UploadButton from './core/components/UploadButton';
import TabContainer from './core/TabContainer';
import UploadContainer from './core/components/UplaodContainer';
import FileSettingsContainer from './core/components/FileSettingsContainer';

const docOperation = [
  'Similarity Analysis',
  'Document Similarity',
  'Compare Versions',
  'Extract Topics',
  // 'Translate',
  'Find Duplicates',
];

const poopOverContent = [
  'Find similar text in meaning from the database',
  'Compare similar indivation and the overall similarities',
  'Compare the difference between two document versions',
  'Identify domain specific topics',
  // 'Translate pdf, excel or word documents to other languages',
  'Find duplicate indivation by meaning within the document',
];
const ButtonIconAry = [
  <FormatListBulletedOutlinedIcon />,
  <FontDownloadOutlinedIcon />,
  <CompareOutlinedIcon />,
  <BrandingWatermarkOutlinedIcon />,
  // <TranslateIcon />,
  <WysiwygIcon />,
];

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

function DocumentAnalysis({ asynOperations }) {
  // const { setSnack, getProject, setProject } = useContext(ProjectContext);
  // const [operation, setOperation] = useState(0);
  const [open, setOpen] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('service');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const { docFiles, setDocFiles, files, setFiles } = useContext(WorkSpaceContext);
  const {
    operation,
    setOperation,
    scopeSetting,
    selection,
    setSelection,
    setScopeSetting,
    setFileSettings,
    toggleTab,
    setToggleTab,
  } = useContext(DocAnalysisContext);
  // const [filesRow, setFilesRow] = useState([]);
  // const [toggleTab, setToggleTab] = useState({
  //   left: '0rem',
  // });
  const classes = ContainerStyle();
  // const { workloadId, action } = useParams() as any;
  const { projectId } = useParams() as any;
  // const project = getProject(Number(projectId));
  const [languageCode, setLanguageCode] = useState([]);
  const [workloadId, setWorkload] = useState();
  const realName = ['DocToDataset', 'DocToDoc', 'docdiffer', 'iner', 'SelfDoc'];

  useEffect(() => {
    if (files.length > 0) {
      if (operation !== 3) {
        const temp = files.filter((data) => {
          const fileExtension = data.filename.toLowerCase().split('.').pop();

          return ['pdf', 'csv', 'xls', 'xlsx', 'xlsm', 'xlsb'].includes(fileExtension);
        });
        setDocFiles(temp);
      } else {
        const temp = files.filter((data) => {
          const fileExtension = data.filename.toLowerCase().split('.').pop();

          return ['pdf', 'csv', 'xls', 'xlsx', 'xlsm', 'xlsb'].includes(fileExtension);
        });
        setDocFiles(temp);
      }
    }
  }, [files, operation]);
  // console.log(files);

  useEffect(() => {
    switch (actionIs) {
      case 'DocToDataset':
        setOperation(0);
        break;
      case 'DocToDoc':
        setOperation(1);
        break;
      case 'docdiffer':
        setOperation(2);
        break;
      case 'iner':
        setOperation(3);
        break;
      case 'SelfDoc':
        setOperation(4);
        break;
      default:
        break;
    }
  }, [actionIs]);

  const HandleResultComponent = (operation: string | number) => {
    switch (docOperation[operation]) {
      case 'Similarity Analysis':
        return <SimilarityAnalysisNew id={workloadId ? Number(workloadId) : undefined} />;
      case 'Document Similarity':
        return <DocSimilarity id={workloadId ? Number(workloadId) : undefined} />;
      case 'Compare Versions':
        return <CompareVersions id={workloadId ? Number(workloadId) : undefined} />;
      case 'Extract Topics':
        return <ExtractTopics id={workloadId ? Number(workloadId) : undefined} />;
      case 'Find Duplicates':
        return <FindDuplicates id={workloadId ? Number(workloadId) : undefined} />;
      default:
        return null;
    }
  };

  const handleToggleComponent = (Rem: any) => {
    switch (Rem) {
      case '0rem':
        return <UploadContainer />;
      case '5rem':
        return <FileSettingsContainer serivceName={docOperation[operation]} />;
      case '10rem':
        return <Activities serivceName={docOperation[operation]} setWorkload={setWorkload} />;
      case '15rem':
        return HandleResultComponent(operation);
      default:
        return null;
    }
  };

  return (
    <>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          justifyContent: 'center',
        }}
      >
        <Box
          style={{
            height: '4rem',
            borderBottom: '1px solid #D1D9EF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // padding: '1rem',
            position: 'relative',
            gap: '10px',
          }}
        >
          <List className={classes.docMainListParent}>
            {docOperation.map((item, i) => {
              return (
                <Tooltip title={`${poopOverContent[i]}`} arrow>
                  <ListItem
                    button
                    onClick={() => {
                      setSelection([]);
                      setToggleTab((prev) => {
                        return {
                          ...prev,
                          left: '0rem',
                        };
                      });
                      // dispatch({ type: 'clear_sheets' });
                      setScopeSetting([]);
                      setFileSettings({});
                      setOperation(i);
                      setWorkload(undefined);
                    }}
                    className={classes.docOperationListItem}
                    selected={operation === i}
                  >
                    {ButtonIconAry[i]}
                    <Typography>{item}</Typography>
                  </ListItem>
                </Tooltip>
              );
            })}
          </List>
          <UploadButton />
        </Box>
        <TabContainer>{handleToggleComponent(toggleTab.left)}</TabContainer>
      </Box>
    </>
  );
}

export default DocumentAnalysis;
