const allDataStyles = {
  muiTablePaperProps: {
    elevation: 0,
    sx: {
      borderRadius: '0',
    },
  },
  muiTopToolbarProps: {
    sx: {
      bgcolor: '#EEF1F9',
    },
  },
  muiBottomToolbarProps: {
    sx: {
      bgcolor: '#EEF1F9',
    },
  },
  muiTableHeadCellProps: {
    // simple styling with the `sx` prop, works just like a style prop in this example
    sx: {
      fontWeight: 'normal',
      fontFamily: 'AlstomRegular',
      fontSize: '14px',
      display: 'flex',
      padding: '0rem',
      paddingBottom: '0rem',
      paddingTop: '0rem',
      // margin: 0,
      // border: 0,
      // flexWrap: 'wrap',
      // justifyContent: 'center',
      // flexDirection: 'column',
      // alignItems: 'flex-start',
      '& .Mui-TableHeadCell-Content': {
        paddingLeft: '5px',
        backgroundColor: '#002A45',
        color: 'white',
        // maxHeight: '5rem',
        flexBasis: '50%',
      },
      '& .MuiCollapse-root': {
        // padding: '0',
        flexBasis: '50%',
        border: 0,
        backgroundColor: 'white',
        color: 'white',
        width: '100%',
        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
  muiSelectAllCheckboxProps: {
    color: 'success',
    sx: {
      color: 'white',
      display: 'flex',
      '& .MuiCheckbox-colorPrimary .Mui-checked .MuiCheckbox-indeterminate': {
        // padding: '0',
        color: 'white',
      },
      // '& .MuiCheckbox-colorPrimary': {
      //   color: 'white',
      // },
      // '& .MuiCheckbox-indeterminate': {
      //   color: 'white',
      // },
    },
  },
  muiSelectCheckboxProps: {
    color: 'secondary',
  },
  muiColumnActionsButtonProps: {
    sx: {
      color: 'white',
      // '& .Mui-TableHeadCell-Content': {
      //   // padding: '0',
      //   color: 'white',
      // },
    },
  },
  muiColumnDragHandleProps: {
    sx: {
      color: 'white',
      // '& .Mui-TableHeadCell-Content': {
      //   // padding: '0',
      //   color: 'white',
      // },
    },
  },
  muiExpandAllButtonProps: {
    sx: {
      color: 'white',
    },
  },

  muiExpandButtonProps: {
    sx: {
      color: '#002A45',
    },
  },
  muiFilterTextFieldProps: {
    sx: {
      color: 'white',
      // backgroundColor: 'white',
      width: '100%',
      padding: '0.2rem',
      marginBottom: '0.78rem',
      // '& .Mui-TableHeadCell-Content': {
      //   // padding: '0',
      //   color: 'white',
      // },
    },
  },
  muiTableBodyProps: {
    sx: (theme) => ({
      '& tr:nth-of-type(odd) > td': {
        backgroundColor: '#EEF1F9',
        borderBottom: 0,
      },
      '& tr:nth-of-type(odd):hover > td': {
        backgroundColor: '#EEF1F9',
        borderBottom: 0,
      },
      '& tr:nth-of-type(even) > td': {
        backgroundColor: 'white',
        borderBottom: 0,
      },
      '& tr:nth-of-type(even):hover > td': {
        backgroundColor: 'white',
        borderBottom: 0,
      },
    }),
  },
};

export default allDataStyles;
