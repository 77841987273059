/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import {
  Column,
  DataTypeProvider,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableEditColumn,
  TableEditRow,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import TableChartIcon from '@mui/icons-material/TableChart';
import ImageIcon from '@mui/icons-material/Image';
import { Button, Card } from '@material-ui/core';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {
  insertNewCapture,
  mergeCapture,
  mergeRepeatCapture,
  PayloadInsertNewCapture,
  PayloadSplit,
  splitCapture,
} from '../../../../../api/module1';
import { deleteOwning, saveOwning } from '../../../../../api/ownings';
import useProjectId from '../../../../../app/useProjectId';
import TableComponent from '../../../../../components/Datagrid/TableComponent';
import TableHeaderCellWrap from '../../../../../components/Datagrid/TableHeaderCellWrap';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import { IS_DEBUG_MODE } from '../../../../../const';
import { handleColumns } from '../../../../../tools/datagrid';
import ProjectContext from '../../../../Project/ProjectContext';
import Module1Context from '../../../Module1Context';
import { allColumns } from '../../../tools/allColumns';
import CaptureModalKpi from './CaptureModalKpi';
import ModalAddCaptureRow from './ModalAddCaptureRow';
import ModalSplit from './ModalSplit';
import Similarizer from './Similarizer';
import { textWidth } from './useStyle';
import {
  multiTableRowComponent,
  tableAllocatorComponent,
  tableContainerCaptureSplitComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../../../components/Datagrid/TStyles';
import ModalRawdata from './ModalRawdata';
import SplitNavigationGoto from '../../../../../components/Core/SplitNavigationGoto';
import IAllocatorStyle from '../../../../../components/Datagrid/TableAllNewStyle/IAllocatorStyle';
import MultiAllocTableComp from '../../../../../components/Datagrid/MultiAllocTableComp';
import NavigationGotoNew from '../../../../../components/Core/NavigationGotoNew';
import NavigationGotoSplit from './NavigationGotoSplit';

dayjs.extend(utc);
const stylingObject = {
  ahref: {
    textDecoration: 'none',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    color: '#002A45',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    // maxWidth: 1500,
    backgroundColor: '#002A45',
    border: '1px solid black',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: 1,
    transition: 'opacity 1s',
    '& .MuiTooltip-arrow': {
      color: '#eef1f9',
      '&:before': {
        backgroundColor: '#002A45',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid black`,
        fontSize: 50,
      },
    },
  },
});
const CustomWidthImageTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 700,
    backgroundColor: '#002A45',
    border: '1px solid black',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: 1,
    transition: 'opacity 1s',
    '& .MuiTooltip-arrow': {
      color: '#eef1f9',
      '&:before': {
        backgroundColor: '#002A45',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid black`,
        fontSize: 50,
      },
    },
  },
});
const CustomWidthTableTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    backgroundColor: '#002A45',
    border: '1px solid black',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: 1,
    transition: 'opacity 1s',
    '& .MuiTooltip-arrow': {
      color: '#eef1f9',
      '&:before': {
        backgroundColor: '#002A45',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid black`,
        fontSize: 50,
      },
    },
  },
});

export const renderMix1 = ({ row }: DataTypeProvider.ValueFormatterProps) => (
  <>
    P{row.PageNo},
    <br />
    {row.Hierarchy}
  </>
);

type Props = {
  dispatch?: any;
  filename: string;
  onChange?: any;
  onReset?: any;
  onSearchText?: any;
  onDownload?: any;
  rows: any[];
  isRegExp?: boolean;
  targetColumn?: string;
  displayColumns?: string[];
  workloadId: number;
  pageDefault?: number;
};

function CaptureFlowSplit({
  dispatch,
  filename,
  onReset,
  onSearchText,
  onDownload,
  rows,
  onChange,
  targetColumn,
  displayColumns,
  isRegExp,
  workloadId,
  pageDefault,
}: Props) {
  // get the project
  const { projectId } = useParams() as any;
  const { projects_lite } = useContext(ProjectContext);
  const { setSnack } = useContext(Module1Context);
  const { project } = useProjectId(projectId, projects_lite);
  const classes1 = IAllocatorStyle();

  const [toggleHeight, setToggleHeight] = useState(false);
  const [idsChanged, setIdsChanged] = useState<any>([]);
  const [rowsDeleted, setRowsDeleted] = useState<any>([]);
  const [selection, setSelection] = useState<any>([]);
  const [openDashboard, setOpenDashboard] = useState(false);
  const [rowsEdited, setRowsEdited] = useState<any>(
    rows
    // .filter((row: any) => {
    //   return row.Characterization === 'Requirement';
    // })
    // .slice(0, 10)
  );
  // modal raw data
  const [imagesList, setImagesList] = useState<any[]>([]);
  const [tablesList, setTablesList] = useState<any[]>([]);
  const [rawType, setRawType] = useState<rawTypeEnum>('images');
  const [toggleRawdata, setToggleRawdata] = useState(false);
  // state for split
  const [openModalSplit, setOpenModalSplit] = useState(false);
  // state for new owning
  const [openModalAddCaptureRow, setOpenModalAddCaptureRow] = useState(false);
  // state for iSimilarizer
  const [openSim, setOpenSim] = useState(false);
  const [rowsSim, setRowsSim] = useState<any>([]);
  const [tempPage, setTemPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [dis, setDis] = useState(true);
  // const [currentPage, setCurrentPage] = useState(pageDefault || 0);
  //   const [columns] = useState<any>(
  //     handleColumns(
  //       allColumns('capture_split', {
  //         targetColumn,
  //         displayColumns,
  //         isRegExp,
  //       })
  //     )
  //   );
  const columns: any = [{ name: 'Text', title: 'Text' }];
  const [currentPage, setCurrentPage] = useState(pageDefault || 0);

  const pageSizes = [5, 10, 20, 50];
  const targetPage = `${workloadId}CAPTURE${projectId}`;

  const exporterRef = useRef(null);
  useEffect(() => {
    if (sessionStorage.getItem(targetPage) == null) {
      setCurrentPage(0);
    } else {
      const previouspage = sessionStorage.getItem(targetPage, currentPage);
      setCurrentPage(previouspage);
    }
  }, []);
  useEffect(() => {
    sessionStorage.setItem(targetPage, currentPage);
  }, [currentPage]);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const openSimilarizer = () => {
    const selectedRows = rowsEdited.filter((row, index) => selection.includes(index));
    // validation text to similarize
    const templistText = selectedRows.map((row) => {
      return row.Text ?? row[targetColumn];
    });
    if (templistText?.length === 0) {
      setSnack('Select at least one row to run iSimialarizer', 'warning');
      setRowsSim([]);
      setOpenSim(false);
      return;
    }
    setRowsSim(templistText);
    setOpenSim(true);
  };
  const handleModalClose = () => {
    setOpenModalSplit(false);
    setOpenModalAddCaptureRow(false);
    setSelection([]);
  };
  const handleSplit = () => {
    setOpenModalSplit(true);
  };

  const handleRefesh = useCallback(
    (tmp, reset = true) => {
      setRowsEdited(tmp ?? rowsEdited);
      onChange(tmp ?? rowsEdited);
      setRowsDeleted([]);
      setSelection([]);
      setIdsChanged([]);
      if (!reset) return;
      if (onReset || reset) onReset(tmp ?? rowsEdited);
    },
    [rowsEdited]
  );
  const commentField = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    return <a>{value}</a>;
  };
  const handleSplitSubmit = (formBody) => {
    const body = {
      project_id: Number(projectId),
      workload_id: workloadId,
      id: rowsEdited.find((row, index) => selection.includes(index)).id,
      column_name: targetColumn ?? 'Text',
      ...formBody,
    } as PayloadSplit;
    // console.log(body);
    // return;
    splitCapture(body).then((response: any) => {
      const tmp = response.map((row) => ({
        ...row.row_capture,
        updated_at: row.updated_at,
        id: row.id,
      }));
      setOpenModalSplit(false);
      setTimeout(() => {
        handleRefesh(tmp, false);
        dispatch({ type: 'init' });
      }, 500);
    });
  };
  useEffect(() => {
    if (pageSize > rowsEdited.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < rowsEdited.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);
  const handleInsertCaptureRowSubmit = (formBody: any) => {
    const columnName = targetColumn ?? 'Text';
    const body = {
      Context: formBody?.context,
      comment: formBody?.comment,
      Hierarchy: formBody?.hierarchy,
      SubType: formBody.subtype,
      capture: formBody.capture,
      column_name: columnName,
    } as PayloadInsertNewCapture;
    // console.log(body);
    // return;
    const id = Number(rowsEdited.find((row, index) => selection.includes(index)).id);
    insertNewCapture(id, body).then((response: any) => {
      const tmp = response.map((row) => ({
        ...row.row_capture,
        updated_at: row.updated_at,
        id: row.id,
      }));
      setOpenModalAddCaptureRow(false);
      setTimeout(() => {
        handleRefesh(tmp, false);
        dispatch({ type: 'init' });
      }, 500);
    });
  };
  const handleConcat = (concatStr = ' ') => {
    // sort selection
    // check sequential
    // send ids to API
    selection.sort((a, b) => (a < b ? -1 : 1));
    try {
      if (selection.length === 0) throw new Error('selection must have .....');

      selection.forEach((element, index) => {
        if (index === 0) return;
        if (Number(element) !== selection[index - 1] + 1) {
          throw new Error('selection must be squential');
        }
      });

      const rowsSelection = rowsEdited
        .filter((row, index) => selection.includes(index))
        .map((r) => r.id);
      const body = {
        project_id: Number(projectId),
        workload_id: workloadId,
        ids: rowsSelection,
        column_name: targetColumn ?? 'Text',
        concat_str: concatStr,
      };
      mergeCapture(body).then((response: any) => {
        dispatch({ type: 'init' });
        const tmp = response.map((row) => ({
          ...row.row_capture,
          updated_at: row.updated_at,
          id: row.id,
        }));
        setTimeout(() => {
          handleRefesh(tmp, false);
        }, 500);
      });
    } catch (error: any) {
      setSnack(error.message, 'warning');
    }
  };
  const handlePageNum = () => {
    if (Number.isNaN(tempPage)) {
      return;
    }
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  const handleMerge = () => {
    handleConcat('\n');
  };

  const handleRepeatMerge = (concatStr = '\n') => {
    // sort selection
    // check sequential
    // send ids to API
    selection.sort((a, b) => (a < b ? -1 : 1));
    try {
      if (selection.length === 0) throw new Error('selection must have .....');

      // selection.forEach((element, index) => {
      //   if (index === 0) return;
      //   if (Number(element) !== selection[index - 1] + 1) {
      //     throw new Error('selection must be squential');
      //   }
      // });

      const rowsSelection = rowsEdited
        .filter((row, index) => selection.includes(index))
        .map((r) => r.id);
      const body = {
        project_id: Number(projectId),
        workload_id: workloadId,
        ids: rowsSelection,
        column_name: targetColumn ?? 'Text',
        concat_str: concatStr,
      };
      mergeRepeatCapture(body).then((response: any) => {
        dispatch({ type: 'init' });
        const tmp = response.map((row) => ({
          ...row.row_capture,
          updated_at: row.updated_at,
          id: row.id,
        }));
        setTimeout(() => {
          handleRefesh(tmp, false);
        }, 500);
      });
    } catch (error: any) {
      setSnack(error.message, 'warning');
    }
  };

  const handleSave = () => {
    const rowsEditedFiltered = rowsEdited.filter((row) => idsChanged.includes(row.id));

    if (rowsEditedFiltered.length > 0) {
      saveOwning(rowsEditedFiltered, 'capture', projectId, Number(workloadId), filename)
        .then(() => {
          dispatch({ type: 'init' });
          if (rowsDeleted.length > 0) {
            deleteOwning(rowsDeleted, projectId, Number(workloadId), filename)
              .then(() => {
                const deleteIds = rowsDeleted.map((row) => row.id);
                onChange(rowsEdited.filter((row) => !deleteIds.includes(row.id)));
                setSnack('save & delete ok', 'success');
              })
              .catch((reason) => {
                console.error(reason);
              });
          } else {
            setSnack('save ok', 'success');
            onChange(rowsEdited);
          }
        })
        .catch((reason) => {
          console.error(reason);
        })
        .finally(() => {});
    } else if (rowsDeleted.length > 0) {
      deleteOwning(rowsDeleted, projectId, Number(workloadId), filename)
        .then(() => {
          const deleteIds = rowsDeleted.map((row) => row.id);
          onChange(rowsEdited.filter((row) => !deleteIds.includes(row.id)));
          setSnack('delete ok', 'success');
        })
        .catch((reason) => {
          console.error(reason);
        });
    } else {
      setSnack('everything is ok', 'success');
    }
  };

  const handleDeleteRows = () => {
    const rowsNotDeleted = rowsEdited.filter((row, index) => !selection.includes(index));
    setRowsEdited(rowsNotDeleted);
    onChange(rowsNotDeleted);

    const rowsDeleted = rowsEdited.filter((row, index) => selection.includes(index));
    setRowsDeleted(rowsDeleted);
    setSelection([]);
    setSnack('Click on Save button');
    if (dispatch) dispatch({ type: 'init' });
  };

  const handleOwning = () => {
    const selectedRows = rowsEdited.filter((row, index) => selection.includes(index));
    if (selectedRows.length === 1) setOpenModalAddCaptureRow(true);
    else setOpenModalAddCaptureRow(false);
  };

  const handleEdit = ({ changed }) => {
    let changedRows;
    if (changed) {
      changedRows = rowsEdited.map((row, index) =>
        changed[index] ? { ...row, ...changed[index], updated_at: dayjs.utc().format() } : row
      );
      const idsChangedNew = rowsEdited.filter((row, index) => changed[index]).map((row) => row.id);
      setIdsChanged([...new Set([...idsChanged, ...idsChangedNew])]);
      setRowsEdited(changedRows);
      if (dispatch) dispatch({ type: 'init' });
    }
  };
  // KPI
  const showDashboardHandler = () => {
    setOpenDashboard(true);
  };
  const modalImage = (row, rawType = 'images' as rawTypeEnum) => {
    if (rawType === 'images') {
      const imageIndex = imagesList.findIndex((r) => row?.id === r.id);
      const imagesListSorted = [
        ...imagesList.slice(imageIndex),
        ...imagesList.slice(0, imageIndex).reverse(),
      ];
      setImagesList(imagesListSorted);
    }

    if (rawType === 'tables') {
      const itemIndex = tablesList.findIndex((r) => row?.id === r.id);
      const imagesListSorted = [
        ...tablesList.slice(itemIndex),
        ...tablesList.slice(0, itemIndex).reverse(),
      ];
      setTablesList(imagesListSorted);
    }

    setRawType(rawType);
    setToggleRawdata(true);
  };
  const renderSubType = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    if (row.SubType === 'Image') {
      return (
        <Button title="Image" onClick={() => modalImage(row, 'images')}>
          <ImageIcon />
          {value}
        </Button>
      );
    }
    if (row.SubType === 'Table') {
      return (
        <Button title="Table" onClick={() => modalImage(row, 'tables')}>
          <TableChartIcon />
          {value}
        </Button>
      );
    }

    return <>{value}</>;
  };
  // table container style
  const tableChildContainer: React.FunctionComponent<Table.CellProps> = (props) => {
    return (
      <Table.Container
        {...props}
        style={{
          maxHeight: '70vh',
          overflowY: 'auto',
          border: '1px solid black',
        }}
      />
    );
  };
  const renderText = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    if (row.SubType === 'Image') {
      return (
        <>
          <CustomWidthImageTooltip
            placement="right-start"
            arrow
            // open={true}
            title={
              <>
                <Card
                  style={{
                    background: 'while',
                    // color: '#002A45',
                    // fontWeight: 'bold',
                    margin: 0,
                    padding: 5,
                    maxHeight: 400,
                    overflowY: 'scroll',
                    maxWidth: 500,
                    overflowX: 'scroll',
                  }}
                >
                  <img
                    style={{ objectFit: 'cover' }}
                    src={`data:image/png;base64, ${row.RawData}`}
                    alt=""
                  />
                </Card>
              </>
            }
          >
            <a
              id={`search-${row.id}`}
              href={`#top-pdf-${filename}`}
              onClick={() => {
                onSearchText(String(row.Text).trim().substr(0, 100), row.Coordinates);
              }}
              title=""
              label="a"
            >
              <ImageIcon />
            </a>
          </CustomWidthImageTooltip>
        </>
      );
    }
    if (row.SubType === 'Table') {
      return (
        <>
          {row.RawData?.rows && (
            <CustomWidthTableTooltip
              placement="right-start"
              arrow
              // open={true}
              title={
                <>
                  <Card
                    style={{
                      background: 'while',
                      // color: '#002A45',
                      // fontWeight: 'bold',
                      margin: 0,
                      padding: 5,
                      // maxHeight: '30vh',
                      // overflowY: 'scroll',
                      // maxWidth: 700,
                    }}
                  >
                    <ExpressGrid
                      rows={row.RawData.rows}
                      columns={handleColumns(row.RawData.columns)}
                    >
                      <Table
                        tableComponent={TableComponent}
                        containerComponent={tableChildContainer}
                        headComponent={tableHeaderComponent}
                        columnExtensions={handleColumns(row.RawData.columns).map((c) => ({
                          columnName: c.name,
                          wordWrapEnabled: true,
                        }))}
                      />
                      <TableHeaderRow
                        cellComponent={TableHeaderCellWrap}
                        rowComponent={multiTableRowComponent}
                      />
                    </ExpressGrid>
                  </Card>
                </>
              }
            >
              <a
                id={`search-${row.id}`}
                href={`#top-pdf-${filename}`}
                onClick={() => {
                  onSearchText(String(row.Text).trim().substr(0, 100), row.Coordinates);
                }}
                title=""
                label="b"
                style={{ textDecoration: 'none' }}
              >
                <TableChartIcon />
              </a>
            </CustomWidthTableTooltip>
          )}
        </>
      );
    }
    // default text
    return (
      <CustomWidthTooltip
        placement="bottom-start"
        arrow
        // open={true}
        title={
          <>
            <Card
              style={{
                background: 'while',
                color: '#002A45',
                fontWeight: 'bold',
                margin: 0,
                padding: 5,
              }}
            >
              {value}
            </Card>
          </>
        }
      >
        <a
          id={`search-${row.id}`}
          href={`#top-pdf-${filename}`}
          onMouseEnter={() => {
            onSearchText(String(row.Text).trim().substr(0, 100), row.Coordinates);
          }}
          style={
            row.SubType === 'Heading'
              ? {
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  whiteSpace: toggleHeight ? 'pre-line' : 'inherit',
                  color: '#002A45',
                }
              : {
                  fontWeight: 'none',
                  textDecoration: 'none',
                  whiteSpace: toggleHeight ? 'pre-line' : 'inherit',
                  color: '#002A45',
                }
          }
          // title={text2display}
        >
          {value}
        </a>
      </CustomWidthTooltip>
    );
  };
  useEffect(() => {
    // populate images
    const imageArray = [];
    const tableArray = [];
    for (const row of rowsEdited) {
      if (row.SubType === 'Image') {
        imageArray.push({ id: row.id, title: row.Text, data: row.RawData });
      }
      if (row.SubType === 'Table') {
        tableArray.push({ id: row.id, data: row.RawData });
      }
    }
    setImagesList(imageArray);
    setTablesList(tableArray);
  }, []);
  // const imageArray = [];
  // const tableArray = [];
  // for (const row of rowsEdited) {
  //   if (row.Characterization === 'Image') {
  //     console.log('rowsEdited', row);
  //     imageArray.push({ id: row.id, title: row.Text, data: row.RawData });
  //   }
  //   if (row.Characterization === 'Table') {
  //     tableArray.push({ id: row.id, data: row.RawData });
  //   }
  // }
  // table container style
  const tableContainerComponentMain: React.FunctionComponent<Table.CellProps> = (props) => {
    return <Table.Container {...props} style={{ maxHeight: '50vh', overflowY: 'auto' }} />;
  };

  const renderExcelColumn = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    // text with translation if available
    const { i18n } = row;
    let text2display = value;
    if (
      translationCode &&
      translationCode.length === 2 &&
      translationCode !== 'en' &&
      translationCode !== languageOriginCode
    ) {
      try {
        text2display = i18n[translationCode];
      } catch (error) {
        text2display = value;
      }
    }

    return (
      <span
        style={{
          textDecoration: 'none',
          whiteSpace: toggleHeight ? 'pre-line' : 'inherit',
        }}
        title={text2display}
      >
        {text2display}
      </span>
    );
  };

  if (!project) return null;

  const cond = [
    'SubType',
    'Text',
    'Context',
    'comment',
    'Hierarchy',
    'Text_original',
    'Context_original',
  ];
  if (targetColumn && targetColumn.length > 0) {
    cond.push(targetColumn);
  }

  const columnsEdit = columns.map((col: Column) => {
    // if (targetColumn && targetColumn.length > 0) {
    //   return { columnName: col.name, editingEnabled: col.name === targetColumn };
    // }
    // if (col.name === 'SubType') {
    //   return { columnName: col.name, editingEnabled: col.name === 'SubType' };
    // }
    // if (col.name === 'Context') {
    //   return { columnName: col.name, editingEnabled: col.name === 'Context' };
    // }
    // return { columnName: col.name, editingEnabled: col.name === 'Text' };
    if (cond.includes(col.name)) {
      return { columnName: col.name, editingEnabled: true };
    }
    return { columnName: col.name, editingEnabled: col.name === 'comment' };
  });
  return (
    <>
      {IS_DEBUG_MODE && (
        <ReactJson
          src={{
            currentPage,
            rowsEdited,
            targetColumn,
            columns,
            displayColumns,
          }}
          collapsed={true}
          theme="monokai"
        />
      )}
      <ExpressGrid rows={rowsEdited} columns={columns}>
        <DataTypeProvider for={['Text']} formatterComponent={renderText} />
        {displayColumns && (
          <DataTypeProvider for={displayColumns} formatterComponent={renderExcelColumn} />
        )}
        <DataTypeProvider for={['comment']} formatterComponent={commentField} />
        {displayColumns && <DataTypeProvider for={['comment']} formatterComponent={commentField} />}

        <DataTypeProvider for={['key_mix1']} formatterComponent={renderMix1} />
        <DataTypeProvider
          for={['RecordStatus']}
          formatterComponent={({ value }) => (
            <span className={value === 'Active' ? classes.active : classes.inactive}>{value}</span>
          )}
        />
        <DataTypeProvider for={['SubType']} formatterComponent={renderSubType} />
        {/* selection */}
        <SelectionState selection={selection} onSelectionChange={setSelection} />
        <IntegratedSelection />

        <SortingState />
        <IntegratedSorting />

        <FilteringState />
        <PagingState
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          defaultCurrentPage={0}
          currentPage={currentPage}
          defaultPageSize={5}
          onCurrentPageChange={(p) => {
            setCurrentPage(p);
          }}
        />

        <IntegratedFiltering />
        <IntegratedPaging />
        <Table
          columnExtensions={columns.map((c) => ({
            columnName: c.name,
            wordWrapEnabled: toggleHeight,
          }))}
          tableComponent={MultiAllocTableComp}
          containerComponent={tableContainerCaptureSplitComponent}
          headComponent={tableHeaderComponent}
        />
        <TableColumnResizing
          defaultColumnWidths={[
            ...columns.map((c) => {
              if (c.name === 'Text') return { columnName: c.name, width: textWidth };
              if (c.name === 'Text_original') return { columnName: c.name, width: textWidth };
              if (c.name === 'comment') return { columnName: c.name, width: 150 };
              if (c.name === targetColumn) return { columnName: targetColumn, width: textWidth };
              if (c.name === 'Context') return { columnName: c.name, width: 300 };
              if (c.name === 'Context_original') return { columnName: c.name, width: 300 };
              if (c.name === 'updated_at') return { columnName: c.name, width: 200 };
              if (c.name === 'id') return { columnName: c.name, width: 80 };
              if (c.name === 'PageNo') return { columnName: c.name, width: 80 };
              if (c.name === 'Hierarchy') return { columnName: c.name, width: 120 };
              if (c.name === 'Char_Score') return { columnName: c.name, width: 80 };
              if (c.name === 'SubType') return { columnName: c.name, width: 120 };
              return {
                columnName: c.name,
                width: Math.ceil(1400 / columns.length),
              };
            }),
          ]}
        />
        <TableHeaderRow showSortingControls cellComponent={TableHeaderCellWrap} />
        <TableFilterRow />
        <TableColumnVisibility
          defaultHiddenColumnNames={['updated_at', 'PageNo', 'Text_original', 'Context_original']}
        />
        <Toolbar rootComponent={toolbarComponent} />
        {/* selection */}
        <TableSelection showSelectAll />
        {/* <ColumnChooser /> */}
        {/* <ToolBarPlugin
          name="Concat"
          title="Concat, add a space between text"
          onClick={handleConcat}
        />
        <ToolBarPlugin
          name="Merge"
          title="Merge, add a new line between text"
          onClick={handleMerge}
        />
        <ToolBarPlugin name="MergeRepeat" title="Repeat header" onClick={handleRepeatMerge} />
        <ToolBarPlugin name="Split" onClick={handleSplit} />
        <ToolBarPlugin name="NewCapture" onClick={handleOwning} title="Insert new capture row" />
        <ToolBarPlugin name="Save" onClick={handleSave} />
        <ToolBarPlugin name="Delete" onClick={handleDeleteRows} />
        <ToolBarPlugin name="Refresh" onClick={handleRefesh} />
        <ToolBarPlugin name="Dashboard" onClick={showDashboardHandler} title="KPIs" />
        <ToolBarPlugin name="iSimilarizer" title="Find Similar Items" onClick={openSimilarizer} /> 
        */}
        <ToolBarPlugin
          name="Height"
          title="Wrap Text"
          onClick={() => {
            setToggleHeight(!toggleHeight);
          }}
        />
        {/* <ToolBarPlugin name="Download" onClick={startExport} /> */}
        {/* edition */}
        {/* <EditingState onCommitChanges={handleEdit} columnExtensions={columnsEdit} /> */}
        {/* <TableEditRow />
        <TableEditColumn showEditCommand /> */}

        <PagingPanel
          pageSizes={pageSizes}
          containerComponent={(props) => (
            <>
              <PagingPanel.Container
                {...props}
                className={classes1.pagingPanelContainerSplitView}
              />
            </>
          )}
        />
      </ExpressGrid>
      {openSim && rowsSim.length && <Similarizer inputSimList={rowsSim} />}
      {openModalSplit && selection.length === 1 && (
        <ModalSplit
          requirement={rowsEdited.find((row, index) => selection.includes(index))}
          onClose={handleModalClose}
          onSubmit={handleSplitSubmit}
          targetColumn={targetColumn}
        />
      )}
      {openModalAddCaptureRow && selection.length === 1 && (
        <ModalAddCaptureRow
          onClose={handleModalClose}
          onSubmit={handleInsertCaptureRowSubmit}
          targetColumn={targetColumn}
        />
      )}
      {toggleRawdata && (
        <ModalRawdata
          imagesList={imagesList}
          tablesList={tablesList}
          rawType={rawType}
          onClose={() => {
            setToggleRawdata(false);
          }}
        />
      )}
      {openDashboard && (
        <CaptureModalKpi
          project_id={projectId}
          workload_id={workloadId}
          file_name={filename}
          // user_id={userJWT.user_id}
          onClose={() => {
            setOpenDashboard(false);
          }}
        />
      )}
      <GridExporter ref={exporterRef} rows={rowsEdited} columns={columns} onSave={onDownload} />
      <NavigationGotoSplit
        setPageSize={setPageSize}
        fileLength={rowsEdited.length}
        pageSize={pageSize}
        dis={dis}
        setTemPage={setTemPage}
        handlePageNum={handlePageNum}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}

export default CaptureFlowSplit;
