import { Box, Button, Card, Typography } from '@material-ui/core';
import { useParams, Link } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableHeaderRow,
  TableFilterRow,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  tableHeaderComponent,
  toolbarComponent,
  tUseStyles,
} from '../../../components/Datagrid/TStyles';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import NavigationGoto from '../../../components/Core/NavigationGoto';
import NavigationGotoNew from '../../../components/Core/NavigationGotoNew';
import IsearcherStyle from '../../../components/Layout/IsearcherStyle';
import MultiAllocTableComp from '../../../components/Datagrid/MultiAllocTableComp';

const columns: any = [
  { name: 'Rank', title: 'Rank' },
  { name: 'Product Line', title: 'Product Line' },
  { name: 'Platform', title: 'Platform' },
  { name: 'Solution', title: 'Solution' },
  { name: 'Product', title: 'Product' },
  { name: 'Text', title: 'Text' },
  { name: 'Score', title: 'Score' },
  { name: 'Artifact Type', title: 'Artifact Type' },
  { name: 'Artifact ID', title: 'Artifact ID' },
  { name: 'Artifact MoP Class', title: 'Artifact MoP Class' },
  { name: 'Artifact Covering Documents', title: 'Artifact Covering Documents' },
  { name: 'Project', title: 'Project' },
  { name: 'CT Code', title: 'CT Code' },
  { name: 'RBS', title: 'RBS' },
  { name: 'Compliance', title: 'Compliance' },
  { name: 'Document Title', title: 'Document Title' },
  { name: 'PBS Allocation', title: 'PBS Allocation' },
  { name: 'Document Profile', title: 'Document Profile' },
  { name: 'Document Path', title: 'Document Path' },
  { name: 'Document Version', title: 'Document Version' },
  { name: 'Document ABS', title: 'Document ABS' },
  { name: 'Document OBS', title: 'Document OBS' },
  { name: 'Created By', title: 'Created By' },
  { name: 'Created On', title: 'Created On' },
  { name: 'Module Created On', title: 'Module Created On' },
  { name: 'Module Create By', title: 'Module Created By' },
  { name: 'Last Modified By', title: 'Last Modified By' },
  { name: 'Last Modified On', title: 'Last Modified On' },
  { name: 'Module Last Modified By', title: 'Module Last Modified By' },
  { name: 'Module Last Modified On', title: 'Module Last Modified On' },
  { name: 'URL', title: 'URL' },
  { name: 'Module URL', title: 'Module URL' },
];
const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    // maxWidth: 1500,
    backgroundColor: '#002A45',
    border: '1px solid black',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: 1,
    transition: 'opacity 1s',
    '& .MuiTooltip-arrow': {
      color: '#eef1f9',
      '&:before': {
        backgroundColor: '#002A45',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid black`,
        fontSize: 50,
      },
    },
  },
});
const useStyles = makeStyles({
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
  customScrollbar: {
    '&::-webkit-scrollbar': {
      width: '8px', // Set the width of the scrollbar
    },
    // '&::-webkit-scrollbar-thumb': {
    //   background: '#888', // Color of the thumb
    //   borderRadius: '5px', // Rounded corners for the thumb
    // },
    // '&::-webkit-scrollbar-thumb:hover': {
    //   background: '#555', // Color of the thumb on hover
    // },
  },
});

const renderText = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
  const link = document.createElement('a');
  link.target = '_blank';
  link.href = `${value}`;
  document.body.appendChild(link);
  return (
    <>
      <Button
        onClick={() => {
          link.click();
        }}
        style={{ textDecoration: 'none' }}
        title={value}
      >
        {value}
      </Button>
    </>
  );
};

const onSave = (workbook: any) => {
  workbook.xlsx.writeBuffer().then((buffer: any) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'iSimilarizer_Report.xlsx');
  });
};

const renderModuleURL = ({ value, row }: DataTypeProvider.ValueFormatterProps) => (
  <Button
    component={Link}
    onClick={() => {
      urlSearch(String(row['Module URL']));
    }}
    style={{ textDecoration: 'none' }}
    title={value}
  >
    {value}
  </Button>
);
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

function FindSimilarTexts({ rowResult }) {
  const [hiddenColumnNames, setHiddenColumnNames] = useState([
    'Document Profile',
    'Document Path',
    'Document Version',
    'Document ABS',
    'Document OBS',
    'Created By',
    'Created On',
    'Last Modified By',
    'Last Modified On',
    'Module Created On',
    'Module Create By',
    'Module Last Modified By',
    'Module Last Modified On',
    'Module URL',
    'URL',
  ]);
  const classes = useStyles();
  const [toggleHeight, setToggleHeight] = useState(false);
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };
  const [pageSizes] = useState([5, 10, 20, 50]);

  const exporterRef = useRef(null);

  useEffect(() => {
    if (sessionStorage.getItem('similaritySearch') !== null) {
      setHiddenColumnNames(sessionStorage.getItem('similaritySearch')?.split(','));
    }
  }, []);
  useEffect(() => {
    sessionStorage.setItem('similaritySearch', hiddenColumnNames);
  }, [hiddenColumnNames]);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  useEffect(() => {
    if (pageSize > rowResult.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < rowResult.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);

  return (
    <>
      <Box style={{ width: '100%' }}>
        <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
          <ExpressGrid rows={rowResult} columns={columns}>
            <DataTypeProvider
              for={[
                'Rank',
                'Product Line',
                'Solution',
                'Product',
                'Project',
                'CT Code',
                'Platform',
                'Document Title',
                // 'Text',
                'RBS',
                'PBS Allocation',
                'Compliance',
                'Score',
                'Artifact Type',
                'Document Profile',
                'Document Path',
                'Document Version',
                'Document ABS',
                'Document OBS',
                'Created By',
                'Created On',
                'Module Created On',
                'Module Create By',
                'Module Last Modified By',
                'Module Last Modified On',
                'URL',
                'Module URL',
                'Last Modified By',
                'Last Modified On',
              ]}
              formatterComponent={({ value }) => (
                <CustomWidthTooltip
                  // placement="bottom-start"
                  arrow
                  // style={{
                  //   whiteSpace: `${newLine}`,
                  //   fontWeight: 'bold',
                  //   color: '#002A45',
                  //   overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                  //   maxHeight: '15vh',
                  //   padding: 5,
                  // }}
                  // open={true}
                  title={
                    <>
                      <Card
                        style={{
                          background: 'while',
                          color: '#002A45',
                          fontWeight: 'bold',
                          margin: 0,
                          padding: 5,
                          // overflowY: 'scroll',
                          // maxHeight: '25vh',
                          // padding: 5,
                        }}
                        // className={tClasses.customScrollbar}
                      >
                        {value}
                      </Card>
                    </>
                  }
                >
                  <span
                    title={value}
                    style={{ whiteSpace: `${toggleHeight}`, fontWeight: 'bold', color: '#002A45' }}
                  >
                    {value}
                  </span>
                </CustomWidthTooltip>
              )}
            />
            <DataTypeProvider
              for={['Text']}
              formatterComponent={({ value }) => (
                <CustomWidthTooltip
                  // placement="bottom-start"
                  arrow
                  // style={{
                  //   whiteSpace: `${newLine}`,
                  //   fontWeight: 'bold',
                  //   color: '#002A45',
                  //   overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                  //   maxHeight: '15vh',
                  //   padding: 5,
                  // }}
                  // open={true}
                  title={
                    <>
                      <Card
                        style={{
                          background: 'while',
                          color: '#002A45',
                          fontWeight: 'bold',
                          margin: 0,
                          padding: 5,
                          overflowY: 'scroll',
                          maxHeight: '25vh',
                          // padding: 5,
                        }}
                        className={classes.customScrollbar}
                      >
                        {value.replace(/-{28,}/g, '')}
                      </Card>
                    </>
                  }
                >
                  <Card
                    // title={value.replace(/-{28,}/g, '')}
                    style={{
                      whiteSpace: `${toggleHeight}`,
                      fontWeight: 'bold',
                      color: '#002A45',
                      // overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                      maxHeight: '15vh',
                      padding: 5,
                    }}
                    className={classes.customScrollbar}
                  >
                    {value}
                  </Card>
                </CustomWidthTooltip>
                // <Card
                //   title={value.replace(/-{28,}/g, '')}
                //   style={{
                //     whiteSpace: `${newLine}`,
                //     fontWeight: 'bold',
                //     color: '#002A45',
                //     overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                //     maxHeight: '15vh',
                //     padding: 5,
                //   }}
                // >
                //   {value.replace(/-{28,}/g, '')}
                // </Card>
              )}
            />
            <DataTypeProvider for={['URL']} formatterComponent={renderText} />
            <DataTypeProvider for={['Module URL']} formatterComponent={renderModuleURL} />
            <FilteringState defaultFilters={[{ columnName: 'Rank', value: '' }]} />
            <IntegratedFiltering />
            <SortingState />
            <IntegratedSorting />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <Table
              // tableComponent={TableComponent}
              tableComponent={MultiAllocTableComp}
              containerComponent={tableAllocatorComponent}
              headComponent={tableHeaderComponent}
              columnExtensions={[
                { columnName: 'Rank', width: '100px' },
                { columnName: 'Product Line', width: '100px' },
                { columnName: 'Platform', width: '100px' },
                { columnName: 'Score', width: '100px' },
                { columnName: 'Artifact Type', width: '100px' },
                {
                  columnName: 'Text',
                  width: '400px',
                  wordWrapEnabled: toggleHeight,
                },
                {
                  columnName: 'Project',
                  width: '100px',
                  wordWrapEnabled: toggleHeight,
                },
                { columnName: 'CT Code', width: '100px' },
                { columnName: 'RBS', width: '180px' },
                { columnName: 'Compliance', width: '150px' },
                { columnName: 'Document Title', width: '180px' },
                { columnName: 'PBS Allocation', width: '150px' },
                { columnName: 'Compliance', width: '200px' },
                { columnName: 'Document Profile', width: '150px' },
                { columnName: 'Document Path', width: '100px' },
                { columnName: 'Document Version', width: '150px' },
                { columnName: 'Document ABS', width: '150px' },
                { columnName: 'Document OBS', width: '150px' },
                { columnName: 'Created By', width: '150px' },
                { columnName: 'Created On', width: '150px' },
                { columnName: 'Module Created On', width: '150px' },
                { columnName: 'Module Create By', width: '150px' },
                { columnName: 'Last Modified By', width: '200px' },
                { columnName: 'Last Modified On', width: '200px' },
                { columnName: 'Module Last Modified By', width: '200px' },
                { columnName: 'Module Last Modified On', width: '200px' },
                { columnName: 'URL', width: '200px' },
                { columnName: 'Module URL', width: '200px' },
                { columnName: 'Artifact ID', width: '200px' },
                { columnName: 'Artifact MoP Class', width: '200px' },
                { columnName: 'Artifact Covering Documents', width: '200px' },
              ]}
            />
            <TableColumnResizing
              defaultColumnWidths={[
                { columnName: 'Rank', width: '100px' },
                { columnName: 'Product Line', width: '120px' },
                { columnName: 'Platform', width: '100px' },
                { columnName: 'Solution', width: '100px' },
                { columnName: 'Product', width: '100px' },
                { columnName: 'Score', width: '100px' },
                { columnName: 'Artifact Type', width: '100px' },
                { columnName: 'Text', width: '400px' },
                { columnName: 'Project', width: '100px' },
                { columnName: 'CT Code', width: '100px' },
                { columnName: 'RBS', width: '180px' },
                { columnName: 'Compliance', width: '150px' },
                { columnName: 'Document Title', width: '180px' },
                { columnName: 'PBS Allocation', width: '150px' },
                { columnName: 'Compliance', width: '200px' },
                { columnName: 'Document Profile', width: '150' },
                { columnName: 'Document Path', width: '100px' },
                { columnName: 'Document Version', width: '150px' },
                { columnName: 'Document ABS', width: '150px' },
                { columnName: 'Document OBS', width: '150px' },
                { columnName: 'Created By', width: '150px' },
                { columnName: 'Created On', width: '150px' },
                { columnName: 'Module Created On', width: '150px' },
                { columnName: 'Module Create By', width: '150px' },
                { columnName: 'Last Modified By', width: '200px' },
                { columnName: 'Last Modified On', width: '200px' },
                { columnName: 'Module Last Modified By', width: '200px' },
                { columnName: 'Module Last Modified On', width: '200px' },
                { columnName: 'URL', width: '200px' },
                { columnName: 'Module URL', width: '200px' },
                { columnName: 'Artifact ID', width: '200px' },
                { columnName: 'Artifact MoP Class', width: '200px' },
                { columnName: 'Artifact Covering Documents', width: '200px' },
              ]}
            />
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={setHiddenColumnNames}
            />
            <Toolbar rootComponent={toolbarComponent} />
            <ColumnChooser />
            <ToolBarPlugin name="Download" onClick={startExport} />
            <ToolBarPlugin
              name="Height"
              title="Wrap Text"
              onClick={() => setToggleHeight(!toggleHeight)}
            />
            {/* <PagingPanel pageSizes={pageSizes} /> */}
            <PagingPanel
              pageSizes={pageSizes}
              containerComponent={(props) => (
                <>
                  <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
                </>
              )}
            />
          </ExpressGrid>
          <GridExporter ref={exporterRef} rows={rowResult} columns={columns} onSave={onSave} />
          <NavigationGotoNew
            setPageSize={setPageSize}
            fileLength={rowResult.length}
            pageSize={pageSize}
            dis={dis}
            setTemPage={setTemPage}
            handlePageNum={handlePageNum}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Box>
      </Box>
    </>
  );
}

export default FindSimilarTexts;
