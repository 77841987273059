import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
const formatLocal = (local = 'en') => {
  switch (local) {
    case 'fr':
      return 'DD/MM/YYYY HH:mm:ss';

    default:
      return 'YYYY-MM-DD HH:mm:ss';
  }
};

export function fromDate(valTimestamp: string) {
  return dayjs(`${valTimestamp}Z`).fromNow();
}

export default function formatDate(val: string | undefined, format = 'DD/MM/YYYY'): string {
  return val ? dayjs(val).format(format) : '-';
}

export { formatLocal };
