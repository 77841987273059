import { Chip, Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  BarChart,
} from 'recharts';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getAssignRequirementAllocation, getProjectManagement } from '../../api/assign';
import { getTimseries, KpiInput } from '../../api/analysis';
import { getComplianceKPIs, getLifecycleKPIs } from '../../api/kpi';
import {
  COMPLIANCE_COLORS,
  ASSIGN_LIFECYCLE_COLORS,
  COMPLIANCE_LABELS,
  LIFECYCLE_COLORS,
  LIFECYCLE_LABELS,
  ALLOCATION_COLORS,
  ALLOCATION_LABELS,
  ASSIGN_LIFECYCLE_LABELS,
} from '../../const';

type Props = {
  project_id: number;
  user_id?: number;
  title: string;
  onClose?: any;
  workload_id?: number;
  file_name?: string;
};
const COLORS = [
  '#1e3246',
  '#dc3223',
  '#19aa6e',
  '#4b5a69',
  '#788291',
  '#d2d7dc',
  '#eb827d',
  '#fad7d2',
  '#d2f0e1',
  '#73cdaa',
  '#9b875f',
  '#afa082',
  '#ebe6dc',
  '#c3b9a0',
  '#C3DDF6',
  '#468AD1',
  '#F8A600',
  '#2f475f',
  '#95372f',
  '#7a231b',
  '#94a2b8',
  '#5b8f75',
  '#546c60',
  '#36443d',
  '#748c80',
  '#4fa7ff',
  '#174471',
  '#384a5c',
  '#acacad',
  '#bdbdbd',
];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fontSize="16px"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '20px',
    border: `1px solid`,
    borderColor: theme.palette.primary.main,
    opacity: 1,
    backgroundColor: 'white',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

export default function AnalysisKpiContainer({
  project_id,
  workload_id,
  user_id,
  file_name,
  title,
  onClose,
}: Props) {
  const classes = useStyles();
  const [pieDataCompliance, setPieDataCompliance] = useState([] as any);
  const [pieDataLifecycle, setPieDataLifecycle] = useState([] as any);
  const [timeseriesCompliance, setTimeseriesCompliance] = useState([] as any[]);
  const [timeseriesLifecycle, setTimeseriesLifecycle] = useState([] as any[]);
  const [projectManagement, setProjectManagement] = useState<any>([]);
  const [projectLeaqder, setProjectLeaqder] = useState<any>([]);
  const [pieDataLifecycleAllocation, setPieDataLifecycleAllocation] = useState<any>([]);
  const [pieDataLifecycles, setPieDataLifecycles] = useState([] as any);
  const [obsValue, setObsValue] = React.useState('obs');

  useEffect(() => {
    const kpiInput = { project_id } as KpiInput;
    if (workload_id) kpiInput.workload_id = workload_id;
    if (file_name) kpiInput.file_name = file_name;
    if (user_id) kpiInput.user_id = user_id;
    setProjectLeaqder([]);
    setProjectManagement([]);
    if (workload_id && file_name) {
      getProjectManagement(project_id, workload_id, obsValue, user_id).then((resp) => {
        setProjectManagement(resp);
        const leadercount = [];
        for (const objs of resp) {
          let values = 0;
          let name = '';
          const payload = {};
          for (const val of Object.values(objs)) {
            if (Number.isInteger(val)) {
              values += val;
            } else {
              name = val;
            }
          }
          payload.name = name;
          payload.value = values;
          leadercount.push(payload);
        }
        setProjectLeaqder(leadercount);
      });

      const usersArray: { entity: any }[] = [];
      const usersArrayValue: { entity: any }[] = [];
      getAssignRequirementAllocation(project_id, workload_id).then((AssignData) => {
        AssignData.forEach((item: { id: any; allocation: any; count: any }) => {
          return usersArray.push({
            name: item.allocation,
            value: item.count,
          });
        });
        let counts = 0;
        let names = '';
        for (const user of usersArray) {
          const resp = {};
          if (user?.name && user.name.includes('_')) {
            counts += user.value;
            names = user.name;
          } else {
            resp.name = user.name;
            resp.value = user.value;
            usersArrayValue.push(resp);
          }
        }
        const resp = {};
        resp.name = names;
        resp.value = counts;
        usersArrayValue.push(resp);
        setPieDataLifecycleAllocation(usersArrayValue);
        setPieDataLifecycles(usersArray);
      });
    }
    // timeseries compliance
    getTimseries(kpiInput, 'compliance').then((output) => {
      setTimeseriesCompliance(output.sort((a, b) => (a.name < b.name ? -1 : 1)));
    });
    // timeseries lifecycle
    getTimseries(kpiInput, 'lifecycle').then((output) => {
      setTimeseriesLifecycle(output.sort((a, b) => (a.name < b.name ? -1 : 1)));
    });
    // for lifecycle
    getLifecycleKPIs(kpiInput).then((output) => {
      const outputWithoutOwned = output.filter((user) => user.name !== 'owned');
      setPieDataLifecycle(outputWithoutOwned);
      // setPieDataLifecycle(output);
    });
    // for compliance
    getComplianceKPIs(kpiInput).then((output) => {
      setPieDataCompliance(output);
    });
  }, [project_id, workload_id, file_name, user_id, obsValue]);

  const handleChange = (event: SelectChangeEvent) => {
    setObsValue(event.target.value);
  };
  return (
    <>
      {projectManagement.length > 0 && (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={0}
          className={classes.root}
        >
          <FormControl sx={{ m: 1, minWidth: 120 }} style={{ marginLeft: 950 }} size="small">
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={obsValue}
              onChange={handleChange}
            >
              <MenuItem value="obs">OBS</MenuItem>
              <MenuItem value="pbs">PBS</MenuItem>
              <MenuItem value="abs">ABS</MenuItem>
            </Select>
          </FormControl>
          <Grid item lg={12} sm={12} md={12}>
            <Typography variant="subtitle1">Requirements allocation status</Typography>

            <Grid lg={12} sm={12} md={12}>
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <BarChart
                  width={1100}
                  height={350}
                  data={projectManagement}
                  margin={{
                    top: 5,
                    right: 1,
                    left: 1,
                    bottom: 8,
                  }}
                >
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="leader" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {/* {barGraphStandards.map((entry, index) => (
                  ))} */}
                  <Bar dataKey="characterized" fill="rgb(120, 130, 145)" />
                  <Bar dataKey="assigned" fill=" rgb(25, 170, 110)" />
                  {/* <Bar dataKey="owned" fill="rgb(0, 42, 69)" /> */}
                  <Bar dataKey="analyzed" fill="rgb(175, 160, 130)" />
                  <Bar dataKey="need-clarification" fill="rgb(3, 86, 138)" />
                  <Bar dataKey="recaptured" fill="rgb(255, 102, 51)" />
                  <Bar dataKey="rejected" fill="rgb(225, 90, 80)" />
                </BarChart>
              </Box>
              <div style={{ textAlign: 'center' }}>
                {projectLeaqder.map((d, index) => (
                  <Chip
                    label={`${d.name} (${d.value})`}
                    color="primary"
                    style={{
                      backgroundColor: d.name,
                      marginRight: '3px',
                      marginTop: '5px',
                    }}
                    key={`chip-${index}`}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* {pieDataLifecycles.length > 0 && (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={0}
          className={classes.root}
        >
          <Grid item lg={12} sm={12} md={12}>
            <Typography variant="subtitle1">Requirements Allocation</Typography>
            <Grid container>
              <Grid item xs={6}>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <PieChart width={400} height={400}>
                    <Pie
                      isAnimationActive={true}
                      data={pieDataLifecycles}
                      // cx={510}
                      cy="center"
                      outerRadius={150}
                      labelLine={false}
                      label={renderCustomizedLabel}
                      fill="#8884d8"
                      dataKey="value"
                      legendType="circle"
                      paddingAngle={1}
                      minAngle={5}
                    >
                      {pieDataLifecycle.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </Box>
              </Grid>
              <Grid xs={6}>
                <div style={{ textAlign: 'center', marginTop: '100px', marginLeft: '-200px' }}>
                  {pieDataLifecycleAllocation.map((d, index) => (
                    <Chip
                      label={`${ALLOCATION_LABELS[d.name]} (${d.value})`}
                      color="primary"
                      style={{
                        backgroundColor: ALLOCATION_COLORS[d.name],
                        marginRight: '3px',
                        marginTop: '5px',
                      }}
                      key={`chip-${index}`}
                    />
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )} */}
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        spacing={0}
        className={classes.root}
      >
        <Grid item lg={12} sm={12} md={12}>
          <Typography variant="subtitle1">Requirements lifecycle</Typography>
        </Grid>
        <Grid item lg={6} sm={6} md={6}>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <PieChart width={500} height={400}>
              <Pie
                data={pieDataLifecycle}
                cy="center"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={140}
                fill="#8884d8"
                dataKey="value"
              >
                {pieDataLifecycle.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={LIFECYCLE_COLORS[entry.name]} />
                ))}
              </Pie>
            </PieChart>
          </Box>
          <div style={{ textAlign: 'center' }}>
            {pieDataLifecycle.map((d, index) => (
              <Chip
                label={`${LIFECYCLE_LABELS[d.name]} (${d.value})`}
                color="primary"
                style={{
                  backgroundColor: LIFECYCLE_COLORS[d.name],
                  marginRight: '3px',
                  marginTop: '5px',
                }}
                key={`chip-${index}`}
              />
            ))}
          </div>
        </Grid>
        <Grid item lg={6} sm={6} md={6}>
          <ResponsiveContainer>
            <LineChart
              height={500}
              data={timeseriesLifecycle}
              margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="analyzed" stroke={LIFECYCLE_COLORS.analyzed} />
              <Line type="monotone" dataKey="assigned" stroke={LIFECYCLE_COLORS.assigned} />
              <Line
                type="monotone"
                dataKey="characterized"
                stroke={LIFECYCLE_COLORS.characterized}
              />
              <Line
                type="monotone"
                dataKey="clarification"
                stroke={LIFECYCLE_COLORS.clarification}
              />
              <Line type="monotone" dataKey="rejected" stroke={LIFECYCLE_COLORS.rejected} />
              {/* <Line type="monotone" dataKey="owned" stroke={LIFECYCLE_COLORS.owned} /> */}
              <Line type="monotone" dataKey="recaptured" stroke={LIFECYCLE_COLORS.recaptured} />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        spacing={0}
        className={classes.root}
      >
        <Grid item lg={12} sm={12} md={12}>
          <Typography variant="subtitle1">Requirements compliance</Typography>
        </Grid>
        <Grid item lg={6} sm={6} md={6}>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <PieChart width={500} height={400}>
              <Pie
                data={pieDataCompliance}
                cy="center"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={140}
                fill="#8884d8"
                dataKey="value"
              >
                {pieDataCompliance.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COMPLIANCE_COLORS[entry.name]} />
                ))}
              </Pie>
            </PieChart>
          </Box>
          <div style={{ textAlign: 'center' }}>
            {pieDataCompliance.map((d: any, index) => (
              <Chip
                label={`${COMPLIANCE_LABELS[d.name]} (${d.value})`}
                color="primary"
                style={{
                  backgroundColor: COMPLIANCE_COLORS[String(d.name)],
                  marginRight: '3px',
                  marginTop: '5px',
                }}
                key={`chip-${index}`}
              />
            ))}
          </div>
        </Grid>
        <Grid item lg={6} sm={6} md={6}>
          <ResponsiveContainer>
            <LineChart
              height={230}
              data={timeseriesCompliance}
              margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              {/* <Line type="monotone" dataKey="not_started" stroke={COLORS_MAP.not_started} /> */}
              <Line type="monotone" dataKey="compliant" stroke={COMPLIANCE_COLORS.compliant} />
              <Line type="monotone" dataKey="nc" stroke={COMPLIANCE_COLORS.nc} />
              <Line type="monotone" dataKey="gap" stroke={COMPLIANCE_COLORS.gap} />
              <Line type="monotone" dataKey="tbd" stroke={COMPLIANCE_COLORS.tbd} />
              <Line
                type="monotone"
                dataKey="need-clarification"
                stroke={COMPLIANCE_COLORS['need-clarification']}
              />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </>
  );
}
