import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type UserState = User | null;

const initialState: UserState = null as UserState;

const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    login: (state, action: PayloadAction<UserState>): UserState => {
      return action.payload as UserState;
    },
    logout: (): UserState => {
      return null;
    },
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
