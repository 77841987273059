/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable import/no-extraneous-dependencies */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@mui/system';
import OrganizationChart from '@dabeng/react-orgchart';
import './MyOrgChart.css';
import { Button, Modal, Paper, Typography } from '@material-ui/core';
import PersonIcon from '@mui/icons-material/Person';
import { getEmp_colleagues_tree_ofuser, getMyDBObjectives } from '../../../../../api/users';
import Loading from '../../../../../components/Core/Loading';

const useStyles = makeStyles((theme: Theme) => ({
  containerMyOrgChart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #002a45',
    borderRadius: '8px',
    margin: '8px',
    height: '100%',
    width: '100%',
  },
  goBackButtonMyOrgChart: {
    margin: '8px',
  },
  headerMyOrgChart: {
    color: '#ffffff',
    fontWeight: 'bold',
    backgroundColor: '#002a45',
    width: '100%',
    borderRadius: '8px 8px 0 0',
    textAlign: 'center',
  },
  customNodeMyOrgChart: {
    // padding: '10px',
    // border: '1px solid #ccc',
    // borderRadius: '8px',
    textAlign: 'center',
  },
  buttonMyOrgChart: {
    marginTop: '8px', // Adds space between the name and the button
    backgroundColor: '#19aa6e', // Change button color
    marginBottom: '8px', // Adds space between buttons
    // color: '#002a45',
  },
  modalMyOrgChart: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    borderRadius: '8px',
    margin: '20px',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  modalPaperMyOrgChart: {
    padding: '20px',
    maxWidth: '600px',
    maxHeight: '80vh',
    backgroundColor: 'white',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 'large',
    overflowY: 'auto', // Enable vertical scrolling if content exceeds height
  },
}));

const DefaultChart = () => {
  const classes = useStyles();
  const history = useHistory();
  const [ds, setDs] = useState({});
  const [dbObjective, setDbObjective] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingEmp, setLoadingEmp] = useState(false);
  const token = JSON.parse(localStorage.getItem('azJWT') || '{}');

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const formatData = (data: { name: any; title: any; children: any[] }) => {
    return {
      name: data.name || 'unknown',
      title: data.title || 'No Title',
      children: data.children ? data.children.map(formatData) : [],
    };
  };

  // Function to convert Markdown to HTML
  const markdownToHtml = (markdown) => {
    // Replace newline characters with <br/>
    let html = markdown.replace(/\n/g, '<br/>');

    // Convert headers (####)
    html = html.replace(/#### (.*?)<br\/>/g, '<h4>$1</h4>');

    // Convert bold text
    html = html.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Replace additional headers if necessary (e.g., ###, ##, #)
    html = html.replace(/### (.*?)<br\/>/g, '<h3>$1</h3>');
    html = html.replace(/## (.*?)<br\/>/g, '<h2>$1</h2>');
    html = html.replace(/# (.*?)<br\/>/g, '<h1>$1</h1>');

    return html;
  };

  async function fetchData() {
    const body = {
      user_firstname: token?.account?.idTokenClaims.name.split(' ')[1],
      user_lastname: token?.account?.idTokenClaims.name.split(' ')[0],
    };

    const response = await getEmp_colleagues_tree_ofuser(body);
    setLoadingEmp(false);
    // Formatting the response data
    const formattedData = formatData(response);
    setDs(formattedData);
  }

  async function fetchObejctive(fName: String, lName: String) {
    try {
      const MyObjectives = await getMyDBObjectives(fName, lName);

      // Check if MyObjectives is not empty and has the objectives property
      if (MyObjectives.length > 0 && MyObjectives[0]?.objectives) {
        const objectivesHtml = markdownToHtml(MyObjectives[0].objectives.replace(/\\/g, ''));
        setDbObjective(objectivesHtml);
      } else {
        setDbObjective(''); // Handle case where no objectives are available
      }
    } catch (error) {
      console.error('Error fetching objectives:', error);
      setDbObjective(''); // Reset or handle error scenario
    } finally {
      setLoading(false); // Hide loading indicator
    }
  }

  useEffect(() => {
    setLoadingEmp(true);
    fetchData();
  }, []);

  const openModal = useCallback((name) => {
    setModalOpen(true);
    setLoading(true);
    const firstName = name.split(' ')[0];
    const lastName = name.split(' ')[1];
    fetchObejctive(firstName, lastName);
  }, []);

  const handleGoBack = () => {
    history.goBack();
  };

  // Custom node renderer
  const CustomNode = memo(({ nodeData }) => {
    return (
      <div className={classes.customNodeMyOrgChart}>
        <div
          style={{
            display: 'flex',
            gap: '5px',
            backgroundColor: '#002a45',
            color: 'white',
            marginBottom: '10px',
          }}
        >
          {nodeData?.children?.length > 0 && <PersonIcon />}
          <Typography variant="h6">{nodeData?.name || 'N/A'}</Typography>
        </div>
        <Typography variant="subtitle2">{nodeData?.title || 'N/A'}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => openModal(nodeData?.name)}
          className={classes.buttonMyOrgChart}
        >
          Objectives
        </Button>
      </div>
    );
  });

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoBack}
        className={classes.goBackButtonMyOrgChart}
      >
        Go Back
      </Button>
      <div className={classes.containerMyOrgChart}>
        <Typography variant="h5" className={classes.headerMyOrgChart}>
          Check Objective
        </Typography>
        {loadingEmp ? (
          <Loading />
        ) : (
          <OrganizationChart datasource={ds} NodeTemplate={CustomNode} pan={true} zoom={false} />
        )}
      </div>
      {/* Modal for displaying objective details */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <div className={classes.modalMyOrgChart}>
          <Paper className={classes.modalPaperMyOrgChart}>
            {loading ? (
              <Typography variant="h3">Loading...</Typography>
            ) : dbObjective === '' ? (
              <Typography variant="h3">No objectives available</Typography>
            ) : (
              <>
                <Typography variant="h3">Objectives</Typography>
                <div
                  style={{ fontSize: '1rem' }}
                  dangerouslySetInnerHTML={{ __html: dbObjective }}
                />
              </>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModalOpen(false)}
              style={{ marginTop: '20px', marginLeft: 'auto' }}
            >
              Close
            </Button>
          </Paper>
        </div>
      </Modal>
    </>
  );
};

export default DefaultChart;
