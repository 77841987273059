import { AnalysisData } from 'OwningType';
import axios from './axios';
import { BACKEND_URL } from '../const';

export interface KpiInput {
  project_id: Number;
  user_id?: Number;
  workload_id?: Number;
  file_name?: string;
  file_id?: Number;
}

const createAnalysis = async (body: AnalysisData[]): Promise<any[]> => {
  try {
    const response = await axios.put(`${BACKEND_URL.root}/analysis`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

type getKPIpayload = {
  group_by_user: {
    completed: number;
    not_started: number;
    started: number;
  };
  timeseries_user: {
    name: string;
    started: number;
    not_started: number;
    completed: number;
  }[];
  group_by_all: {
    completed: number;
    not_started: number;
    started: number;
  };
  timeseries_all: {
    name: string;
    started: number;
    not_started: number;
    completed: number;
  }[];
};

// for project dashboard
const getLifecycleKPIs = async (kpiInput: KpiInput): Promise<any> => {
  try {
    let querystring = `project_id=${kpiInput.project_id}`;
    if (kpiInput.workload_id) querystring = `${querystring}&workload_id=${kpiInput.workload_id}`;
    if (kpiInput.file_name) querystring = `${querystring}&filename=${kpiInput.file_name}`;
    if (kpiInput.file_id) querystring = `${querystring}&file_id=${kpiInput.file_id}`;
    if (kpiInput.user_id) querystring = `${querystring}&user_id=${kpiInput.user_id}`;
    const response = await axios.get(`${BACKEND_URL.root}/kpi/lifecycle?${querystring}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getComplianceKPIs = async (kpiInput: KpiInput): Promise<any> => {
  try {
    let querystring = `project_id=${kpiInput.project_id}`;
    if (kpiInput.user_id) querystring = `${querystring}&user_id=${kpiInput.user_id}`;
    if (kpiInput.workload_id) querystring = `${querystring}&workload_id=${kpiInput.workload_id}`;
    if (kpiInput.file_name) querystring = `${querystring}&filename=${kpiInput.file_name}`;
    if (kpiInput.file_id) querystring = `${querystring}&file_id=${kpiInput.file_id}`;
    if (kpiInput.user_id) querystring = `${querystring}&user_id=${kpiInput.user_id}`;
    const response = await axios.get(`${BACKEND_URL.root}/kpi/compliance?${querystring}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
// Admin total KPI
const getAdminKpi = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/users/user/history/kpi`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const getAdminProjectUserSelectedKPI = async (
  projectId: any,
  user_id: any,
  from_date: any,
  to_date: any
): Promise<any> => {
  try {
    let querystring = ``;
    if (projectId) querystring = `${querystring}&projectId=${projectId}`;
    if (user_id) querystring = `${querystring}&user_id=${user_id}`;
    if (from_date) querystring = `${querystring}&from_date=${from_date}`;
    if (to_date) querystring = `${querystring}&to_date=${to_date}`;
    const a = `${BACKEND_URL.root}/users/user/history/kpi?${querystring}`;
    const response = await axios.get(a);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
// Region Name
const getClusterName = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/engg_readiness/getregion`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// Sub Region Name
const getSubRegionName = async (region): Promise<any> => {
  try {
    let querystring = '';
    if (region) querystring = `region=${region}`;
    const response = await axios.get(
      `${BACKEND_URL.root}/engg_readiness/getsubregion?${querystring}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
// RM name API
const getRmName = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/engg_readiness/getrmname`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
// Readiness Project name API
const getRadinessProjectName = async (
  rm_name = undefined,
  sub_region = undefined
): Promise<any> => {
  try {
    let querystring = '';
    if (rm_name) querystring = `rm_name=${rm_name}`;
    if (sub_region) querystring = `sub_region=${sub_region}`;
    const response = await axios.get(
      `${BACKEND_URL.root}/engg_readiness/getprojectname?${querystring}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const getRadinessProjects = async (program_name): Promise<any> => {
  try {
    let querystring = '';
    if (program_name) querystring = `project_program_name=${program_name}`;
    const response = await axios.get(
      `${BACKEND_URL.root}/engg_readiness/getenggreadiness?${querystring}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const getRadinessSingleRowProjects = async (program_name, textValueToText): Promise<any> => {
  try {
    let querystring = `project=enggrediness`;
    if (program_name) querystring = `${querystring}&project_program_name=${program_name}`;
    if (textValueToText) querystring = `${querystring}&scopes=${textValueToText}`;
    const response = await axios.get(
      `${BACKEND_URL.root}/engg_readiness/getenggreadinesssinglerow?${querystring}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const getNonChangeRedinessProject = async (
  selectedFinalClusterName,
  selectedFinalRmName,
  selectedFinalProgramName
): Promise<any> => {
  try {
    let querystring = `project=enggrediness`;
    if (selectedFinalClusterName)
      querystring = `${querystring}&cluster_name=${selectedFinalClusterName}`;
    if (selectedFinalRmName) querystring = `${querystring}&rm_name=${selectedFinalRmName}`;
    if (selectedFinalProgramName)
      querystring = `${querystring}&project_program_name=${selectedFinalProgramName}`;
    const response = await axios.get(
      `${BACKEND_URL.root}/engg_readiness/getenggreadiness?${querystring}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const saveEnggRediness = async (body: any) => {
  // console.log(body);
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/engg_readiness/updateenggreadines`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export {
  createAnalysis,
  getLifecycleKPIs,
  getComplianceKPIs,
  getClusterName,
  getRmName,
  getRadinessProjectName,
  getRadinessProjects,
  getNonChangeRedinessProject,
  getRadinessSingleRowProjects,
  saveEnggRediness,
  getSubRegionName,
  getAdminKpi,
  getAdminProjectUserSelectedKPI,
};
