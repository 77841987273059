import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { makeStyles } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import { BreakdownStructure, Category, GroupData } from 'GroupType';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import XLSX from 'xlsx';
import importBreakdownCustomRowsProject from '../../../../api/breakdowns';
import { createProjectGroup, updateProjectGroup } from '../../../../api/projects';
import useProjectId from '../../../../app/useProjectId';
import TableComponent from '../../../../components/Datagrid/TableComponent';
import ToolBarPlugin from '../../../../components/Datagrid/ToolBarPlugin';
import { IS_DEBUG_MODE } from '../../../../const';
import { Project } from '../../../../react-app-env';
import { handleColumns } from '../../../../tools/datagrid';
import ProjectGroupsContext from '../../PorjectGroupsContext';
import { convertModelToItem } from '../../ProjectBreakdownsContainer';
import ProjectContext from '../../ProjectContext';
import useAppToken from '../../../../app/useAppToken';
import {
  rowComponent,
  tableContainerComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../../components/Datagrid/TStyles';
import Module1Context from '../../../Module1/Module1Context';

const columnsBreakdown = handleColumns(['label', 'value', 'selected']);

export const useStyles = makeStyles(() => ({
  root: { flexGrow: 1 },
}));

type Props = {
  group: GroupData;
};

const ProjectGroupsCustom = ({ group: customGroup }: Props) => {
  const {
    allDefaultGroups,
    datatype,
    RBSCustom,
    SBSCustom,
    PBSCustom,
    ABSCustom,
    OBSCustom,
    setRBSCustom,
    setSBSCustom,
    setPBSCustom,
    setABSCustom,
    setOBSCustom,
  } = useContext(ProjectGroupsContext);
  const classes = useStyles();
  const { projectId } = useParams() as any;
  const { projects_lite, setProject, setSnack } = useContext(ProjectContext);
  const { project } = useProjectId(projectId, projects_lite);
  const [selectionRows, setSelectionRows] = useState<any[]>([]);
  const { appJWT } = useAppToken();
  const [rows, setRows] = useState<BreakdownStructure[]>(allDefaultGroups.PBS);
  console.log(project);
  useEffect(() => {
    if (!project) return;
    switch (datatype) {
      case 'ABS':
        setRows(allDefaultGroups.ABS.sort((a, b) => (a.label < b.label ? -1 : 1)));
        break;
      case 'OBS':
        setRows(allDefaultGroups.OBS.sort((a, b) => (a.label < b.label ? -1 : 1)));
        break;
      case 'RBS':
        setRows(allDefaultGroups.RBS.sort((a, b) => (a.label < b.label ? -1 : 1)));
        break;
      case 'SBS':
        setRows(allDefaultGroups.SBS.sort((a, b) => (a.label < b.label ? -1 : 1)));
        break;
      case 'PBS':
        setRows(allDefaultGroups.PBS.sort((a, b) => (a.label < b.label ? -1 : 1)));
        break;
      default:
        break;
    }
  }, [
    allDefaultGroups.ABS,
    allDefaultGroups.OBS,
    allDefaultGroups.PBS,
    allDefaultGroups.RBS,
    allDefaultGroups.SBS,
    datatype,
    project,
    project?.entity,
  ]);

  const saveCustomedGroup = (groupData: GroupData): Promise<any> => {
    if (!project) throw new Error('no project');

    if (groupData.id) {
      return updateProjectGroup(groupData);
    }
    return createProjectGroup(groupData).then((allGroupCustom) => {
      if ('RBS' in allGroupCustom) setRBSCustom({ ...PBSCustom, ...allGroupCustom.RBS });
      if ('SBS' in allGroupCustom) setSBSCustom({ ...PBSCustom, ...allGroupCustom.SBS });
      if ('PBS' in allGroupCustom) setPBSCustom({ ...SBSCustom, ...allGroupCustom.PBS });
      if ('ABS' in allGroupCustom) setABSCustom({ ...PBSCustom, ...allGroupCustom.ABS });
      if ('OBS' in allGroupCustom) setOBSCustom({ ...OBSCustom, ...allGroupCustom.OBS });
    });
  };

  const _getDefaultGroup = () => {
    switch (datatype) {
      case 'ABS':
        return allDefaultGroups.ABS;
      case 'OBS':
        return allDefaultGroups.OBS;
      case 'RBS':
        return allDefaultGroups.RBS;
      case 'SBS':
        return allDefaultGroups.SBS;
      case 'PBS':
        return allDefaultGroups.PBS;
      default:
        return null;
    }
  };

  const handlePluginSave = async () => {
    // eslint-disable-next-line eqeqeq
    const foundValue: any = project?.users?.find((obj) => obj.user_id == appJWT.user_id);
    if (foundValue?.role_id === 3) {
      setSnack('You are not allowed as you are a performer', 'warning');
      return;
    }
    try {
      // validation : duplucation
      const duplicatedRows = rows.filter((row, indexRow) => {
        return (
          rows.findIndex((rowTofind, indexRowToFind) => {
            return (
              String(row.label).toLowerCase() === String(rowTofind.label).toLowerCase() &&
              indexRow !== indexRowToFind
            );
          }) !== -1
        );
      });

      if (duplicatedRows.length) {
        setSnack(
          `Duplication found: ${Array.from(new Set(duplicatedRows.map((r) => r.label))).join(
            ', '
          )}`,
          'error'
        );
        return;
      }

      // extract lines added by user
      // lines with project_id == labels only for this project
      const rowsByUser = rows
        .filter((r) => Number(r.project_id) > 0 && r?.value && r.value.trim() !== '')
        .map((r) => ({ ...r, slug: r.value, category: datatype as Category }));
      // console.log(rowsByUser);
      // return;
      // step 1 save new entries
      importBreakdownCustomRowsProject(rowsByUser, Number(projectId), datatype as Category).then(
        (rows) => {
          const rowsCategory = convertModelToItem(
            rows.filter((r: BreakdownStructure) => r.category === datatype)
          );
          // setRows(rowsCategory);
          setSnack(`${datatype} group saved`);

          // save label_ids in project_groups
          // step 2 save selection
          saveCustomedGroup(customGroup)
            .then(() => {
              switch (datatype) {
                case 'ABS':
                  setProject({
                    ...project,
                    groups: [PBSCustom, OBSCustom, SBSCustom, customGroup],
                  } as Project);
                  break;
                case 'OBS':
                  setProject({
                    ...project,
                    groups: [ABSCustom, PBSCustom, SBSCustom, customGroup],
                  } as Project);
                  break;
                case 'PBS':
                  setProject({
                    ...project,
                    groups: [ABSCustom, OBSCustom, SBSCustom, customGroup],
                  } as Project);
                  break;
                case 'RBS':
                  setProject({
                    ...project,
                    groups: [ABSCustom, OBSCustom, PBSCustom, customGroup],
                  } as Project);
                  break;
                case 'SBS':
                  setProject({
                    ...project,
                    groups: [ABSCustom, OBSCustom, PBSCustom, customGroup],
                  } as Project);
                  break;
                default:
                  break;
              }
            })
            .catch((error) => {
              setSnack(JSON.stringify(error.detail), 'error');
            })
            .finally(() => {
              setTimeout(() => setRows(rowsCategory), 500);
              setTimeout(() => setSnack(`${datatype} selection saved`), 500);
            });
        }
      );
    } catch (error) {
      setSnack(JSON.stringify(error.detail), 'error');
    }
  };

  const renderSelected = ({ row }: any) => {
    if (!customGroup) return null;
    const { label_ids } = customGroup;
    if (label_ids.includes(row.value))
      return (
        <>
          <CheckIcon />
        </>
      );
    return null;
  };

  const handleImport = async (e: React.ChangeEvent<any>) => {
    // fix undefined files

    if (!e.currentTarget.files || !e.currentTarget.files[0]) return;
    const myFile = e.currentTarget.files[0];
    const reader = new FileReader();

    reader.readAsArrayBuffer(myFile);
    reader.onload = () => {
      const data = new Uint8Array(reader.result as any);
      const wb = XLSX.read(data, { type: 'array' });
      const rowsImport = XLSX.utils
        .sheet_to_json(wb.Sheets[wb.SheetNames[0]])
        .filter((r) => r.label && r.label.trim().length > 0);
      // validation values empty
      const rowEmpty = rowsImport.find((r) => !r.value || r.value.trim() === '');
      if (rowEmpty) {
        setSnack(`${rowEmpty.label} has empty value`, 'error');
        return;
      }
      const defaultValues =
        _getDefaultGroup()
          ?.filter((group) => Number.isNaN(parseInt(String(group.project_id), 10)))
          .map((group) => group.value) || [];
      setRows(
        rowsImport.map((r: any) => {
          if (defaultValues.includes(r.value)) {
            return { ...r, project_id: undefined };
          }
          return { ...r, project_id: Number(projectId) };
        })
      );
    };
    e.currentTarget.value = '';
  };

  const exporterRef = useRef(null);

  const handleExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const handleSaveAs = (filenameSave: string) => (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filenameSave);
    });
  };
  const handleAddSelected = (action = 'add') => {
    // eslint-disable-next-line
    const foundValue: any = project?.users?.find((obj) => obj.user_id == appJWT.user_id);
    if (foundValue?.role_id === 3) {
      setSnack('You are not allowed as you are a performer', 'warning');
      return;
    }
    const selectedRows = rows.filter(
      (row, index) => selectionRows.includes(index) && row.value?.length > 0
    );
    const selectedRowsIds = selectedRows.map((r: any) => r.value);
    let label_ids_new: any;
    if (action === 'add')
      label_ids_new = Array.from(new Set([...(customGroup?.label_ids || []), ...selectedRowsIds]));
    if (action === 'delete') {
      label_ids_new = customGroup?.label_ids.reduce((acc, label_id) => {
        if (selectedRowsIds.includes(label_id)) {
          return acc;
        }
        return [...acc, label_id];
      }, []);
    }
    // eslint-disable-next-line no-param-reassign
    customGroup.label_ids = label_ids_new;
    // force reload rows to update selected rows
    setRows([...rows]);
    // clear selection
    setSelectionRows([]);

    handlePluginSave().catch((error) => {
      setSnack(JSON.stringify(error.detail), 'error');
    });
  };

  const handleRemoveSelected = () => {
    // eslint-disable-next-line eqeqeq
    const foundValue: any = project?.users?.find((obj) => obj.user_id == appJWT.user_id);
    if (foundValue?.role_id === 3) {
      setSnack('You are not allowed as you are a performer', 'warning');
      return;
    }
    handleAddSelected('delete');
  };
  return (
    <div className={classes.root}>
      {IS_DEBUG_MODE && (
        <ReactJson
          src={{
            customGroup,
            selectionRows,
            datatype,
            rows,
          }}
          collapsed={true}
          theme="monokai"
        />
      )}
      <ExpressGrid rows={rows} columns={columnsBreakdown}>
        <DataTypeProvider for={['selected']} formatterComponent={renderSelected} />
        <SelectionState selection={selectionRows} onSelectionChange={setSelectionRows} />
        <IntegratedSelection />
        {/* filtering */}
        <FilteringState />
        <SortingState />
        <IntegratedSorting />
        <IntegratedFiltering />
        {/* <PagingState defaultCurrentPage={0} defaultPageSize={10} />
        <IntegratedPaging /> */}
        <Table
          columnExtensions={[{ columnName: 'selected', width: '100px' }]}
          tableComponent={TableComponent}
          containerComponent={tableContainerComponent}
          headComponent={tableHeaderComponent}
        />
        <TableHeaderRow showSortingControls />
        {/* filtering */}
        <TableFilterRow rowComponent={rowComponent} />

        <Toolbar rootComponent={toolbarComponent} />
        {/* selection */}
        <TableSelection showSelectAll />
        <ToolBarPlugin name="Check" title="Activate Item" onClick={handleAddSelected} />
        <ToolBarPlugin name="Deselect" title="Deactivate Item" onClick={handleRemoveSelected} />
        <ToolBarPlugin name="ExcelImport" title="Import excel file" onClick={handleImport} />
        <ToolBarPlugin name="Download" title="Download to excel file" onClick={handleExport} />
        {/* <PagingPanel pageSizes={[10, 20, 50]} /> */}
      </ExpressGrid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columnsBreakdown.map((c) => ({ name: c.name, title: c.name }))}
        onSave={handleSaveAs(`projectbreakdown-${projectId}-${datatype}.xlsx`)}
      />
    </div>
  );
};

export default ProjectGroupsCustom;
