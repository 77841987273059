import { Divider, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import { ListItemButton } from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { chatGPTApiDelete, chatGPTApiGet } from '../../../../api/chat_gpt';
import Loading from '../../../../components/Core/Loading';
import sleep from '../../../../tools/sleep';
import ModalConfirm from '../../../Admin/components/ModalConfirm';
import Module2Context from '../../Module2Context';
import { ChatContext } from './ChatContext';
import { gptEngineType } from './ChatReducer';
import DiscussListItem from './DiscussListItem';
import { DiscussionType } from './chat';

type Props = {
  gptEngine?: gptEngineType;
  onClick?: any;
};
const useStyles = makeStyles((theme) => ({
  li: {
    '&.MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'rgba(0, 0, 0, 0.87)',
      '&.Mui-selected': {
        color: theme.palette.common.white,
      },
      '&:hover': {
        color: theme.palette.common.white,
        background: '#DC3223 0% 0% no-repeat padding-box !important',
      },
    },
  },
  liDate: {
    '&.MuiListItem-gutters': {
      paddingLeft: 20,
      paddingRight: 0,
      fontSize: '14',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  activeButton: {
    background: '#DC3223 0% 0% no-repeat padding-box !important', // Change this to your desired color
    color: theme.palette.common.white, // Change this to your desired text color
    '&:hover': {
      backgroundColor: theme.palette.primary.dark, // Change this to your desired hover color
    },
  },
  normalButton: {
    '& :MuiListItemIcon-root *': {
      background: '#DC3223 0% 0% no-repeat padding-box !important', // Change this to your desired color
      color: theme.palette.common.white, // Change this to your desired text color
    },
  },
  icon: {
    fontSize: '22',
    marginRight: '5px',
    padding: '2px',
    border: '1px solid white',
    borderRadius: '8px',
  },
  textEllip: {
    textOverflow: 'ellipsis',
  },
}));
function DiscussListScroll({ gptEngine, onClick }: Props) {
  const pageSize = 10;
  const [modalOpenId, setModalOpenId] = useState<number>(0);

  const { state, dispatch } = useContext(ChatContext);
  const { setSnack } = useContext(Module2Context);
  const [page, setPage] = useState(0);
  const [loadingPage, setLoadingPage] = useState(false);
  const [hasmore, setHasMore] = useState(true);

  const classes = useStyles();

  const getTitleFromDiscussion = useMemo(() => {
    return (discussion: DiscussionType): string => {
      if (discussion.title?.length) return discussion.title;
      const discussionUser = discussion.cache.find((d) => d.role === 'user');
      if (discussion.cache.length > 0 && discussionUser) return discussionUser.content;
      return '';
    };
  }, []);

  const handleMore = async () => {
    if (loadingPage === false) {
      setLoadingPage(true);
      await sleep(1200);
      chatGPTApiGet(gptEngine, pageSize * page)
        .then((response) => {
          dispatch({ type: 'SCROLL', payload: response });
          if (response.length < 10) {
            console.log('response.length', response.length);
            setHasMore(false);
          }
          setPage(page + 1);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadingPage(false);
        });
    }
  };

  let monthIndex = -1;

  useEffect(() => {
    setHasMore(true);
    setPage(0);
    setLoadingPage(true);
    chatGPTApiGet(gptEngine, pageSize * 0)
      .then((response) => {
        dispatch({ type: 'INIT', payload: response });
        if (response && response.length < pageSize) {
          setHasMore(false);
        }
        setPage(1);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingPage(false);
      });
  }, [gptEngine]);

  return (
    <>
      <List>
        {state.discussions.map((d: DiscussionType, k: number) => {
          const title = getTitleFromDiscussion(d);
          // header date
          let lastDate = d.created_at;
          if (d.updated_at > lastDate) lastDate = d.updated_at;
          if (d.replied_at > lastDate) lastDate = d.replied_at;
          const monthItem = dayjs(lastDate).month();
          let ListItemDate = null;
          if (monthIndex !== monthItem) {
            monthIndex = monthItem;
            const titleDate = dayjs(lastDate).format('MMMM YYYY');

            ListItemDate = (
              <>
                <ListItem key={`list-date-${k}`} className={`${classes.liDate}`}>
                  {titleDate}
                </ListItem>
                <Divider />
              </>
            );
          }
          return (
            <DiscussListItem
              key={`list-discuss-item-${k}`}
              listItemDate={ListItemDate}
              discussion={d}
              title={title}
              isActiveDiscussion={state.discussionId === d.id}
              classes={classes}
              dispatch={dispatch}
              setSnack={setSnack}
              setModalOpenId={setModalOpenId}
              onClick={onClick}
            />
          );
        })}

        {hasmore && (
          <>
            <ListItemButton
              key="list-item-more"
              // className={`${classes.li}}`}
              onClick={handleMore}
              onMouseOver={handleMore}
            >
              {loadingPage ? (
                <Loading />
              ) : (
                <>
                  <ListItemText primary="Load more" />
                  <MoreHorizSharpIcon style={{ alignContent: 'left' }} />
                </>
              )}
            </ListItemButton>
          </>
        )}
      </List>
      {modalOpenId > 0 && (
        <ModalConfirm
          open={modalOpenId > 0}
          onConfirm={() => {
            chatGPTApiDelete(modalOpenId)
              .then(() => {
                dispatch({ type: 'EDIT_DELETE_DISCUSS', payload: modalOpenId });
                // setSnack('Discussion is deleted', 'success');
              })
              .catch((err) => {
                console.log(err);
                setSnack('Delete error', 'error');
              })
              .finally(() => {
                setModalOpenId(0);
              });
          }}
          onCancel={() => {
            setModalOpenId(0);
          }}
          label="isDeleteTitle"
        />
      )}
    </>
  );
}

export default DiscussListScroll;
