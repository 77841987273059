import React, { useCallback, useEffect, useRef, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  Table,
  PagingPanel,
  TableFilterRow,
  TableColumnVisibility,
  TableHeaderRow,
  Toolbar,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import MultiSelect from 'react-multi-select-component';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Theme } from '@material-ui/core/styles';
import { makeStyles, withStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { FormControl, FormControlLabel, IconButton } from '@material-ui/core';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { cssFilters, customerNames, customerSearch } from '../../../../api/module2';
import ToolBarPlugin from '../../../../components/Datagrid/ToolBarPlugin';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  toolbarComponent,
} from '../../../../components/Datagrid/TStyles';
import MultiAllocTableComp from '../../../../components/Datagrid/MultiAllocTableComp';
import Loading from '../../../../components/Core/Loading';
import NavigationGotoNew from '../../../../components/Core/NavigationGotoNew';
import ScrollableTabsButtonAuto from './ScrollableTabsButtonAuto';

const options = [
  { label: 'Grapes', value: 'grapes' },
  { label: 'Mango', value: 'mango' },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  contain: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
    padding: '5px',
  },
  formControlMultiTextlebel: {
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  formControlMulti: {
    // margin: theme.spacing(1),
    minWidth: '95%',
    maxWidth: '50%',
  },
  formControlMultiFile: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
  },
  formControlMulti1: {
    margin: theme.spacing(1),
    minWidth: 220,
    maxWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tabstyle: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '33px',
    background: '#DC3223 0% 0% no-repeat padding-box',
    color: '#fff !important',
    fontSize: 14,
    fontWeight: 700,
  },
  MultiSelect: {
    // border: '2px solid red',
    // opacity: 1,
    '& .dropdown-container': {
      border: '1px solid #002A45',
      opacity: 1,
      // position: 'fixed',
    },
    '& .gray': {
      color: '#002A45 !important',
    },
    '& .dropdown-container[aria-disabled="true"]': {
      // overFlow: 'visible',
      // position: 'fixed',
      // backgroundColor: '#C4D4EC',
    },
    // '& .dropdown-content': {
    //   // overflowY: 'auto',
    //   backgroundColor: 'red',
    //   position: 'initial',
    // },
    '& .select-menu-outer': {
      position: 'fixed',
    },
    // '& .rmsc': {
    //   borderColor: 'white',
    // },
    // '& .dropdown-heading': {
    //   color: 'red',
    // },
  },
  customScrollbar: {
    '&::-webkit-scrollbar': {
      width: '8px', // Set the width of the scrollbar
    },
    // '&::-webkit-scrollbar-thumb': {
    //   background: '#888', // Color of the thumb
    //   borderRadius: '5px', // Rounded corners for the thumb
    // },
    // '&::-webkit-scrollbar-thumb:hover': {
    //   background: '#555', // Color of the thumb on hover
    // },
  },
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function CSSProject() {
  const classes = useStyles();

  const [customerText, setCustomerText] = useState<any>('');
  const [testDataHere, setTestDataHere] = useState<any>([]);
  const [payload, setPayload] = useState<any>();
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [tempPage, setTemPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSize2, setPageSize2] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [newLine, setNewLine] = useState<any>('pre-line');
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [surveyNo, setSurveyNo] = useState<any>(-1);
  const [toggleButtonHere, setToggleButtonHere] = useState<boolean>(false);
  const exporterRef = useRef(null);
  const [displayHere, setDisplayHere] = useState<boolean>(false);
  const [columnWidths, setColumnWidths] = useState([] as any);
  const [loader, setLoader] = useState<boolean>(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState<any>([]);
  const [initialDropDownLoader, setInitialDropDownLoader] = useState<boolean>(true);
  const [initialFilter, setInitialFilter] = useState({
    cluster: [],
    country: [],
    currentPhase: [],
    leadingBusiness: [],
    region: [],
    scope: [],
  });

  const [selectedFilterScope, setSelectedFilterScope] = useState([]);
  const [selectedFilterCluster, setSelectedFilterCluster] = useState([]);
  const [selectedFilterRegion, setSelectedFilterRegion] = useState([]);
  const [selectedFilterCountry, setSelectedFilterCountry] = useState([]);
  const [selectedFilterLeadingBusiness, setSelectedFilterLeadingBusiness] = useState([]);
  const [selectedFilterCurrentPhase, setSelectedFilterCurrentPhase] = useState([]);

  const [selectedDisplay, setSelectedDisplay] = useState<any>('none');

  const [errorDisplay, setErrorDisplay] = useState<boolean>(false);
  const [valueSearch, setValueSearch] = useState<any>('');

  const [columnsMain] = useState([
    { name: 'survey', title: 'Survey' },
    { name: 'project', title: 'Project' },
    { name: 'country', title: 'Country' },
    { name: 'ctCode', title: 'CT Code' },
    { name: 'averageNPS', title: 'Average NPS' },
    { name: 'averageOSS', title: 'Average OSS' },
    { name: 'cluster', title: 'Cluster' },
    {
      name: 'currentPhaseOfProjectExecution',
      title: 'Current Phase Of Project Execution',
    },
    { name: 'customerName', title: 'Customer Name' },
    { name: 'customerResponseDate', title: 'Customer Response Date' },
    { name: 'fiscalYear', title: 'Fiscal Year' },
    // { name: 'id', title: 'ID' },
    { name: 'leadingBusiness', title: 'Leading Business' },
    { name: 'projectTargetedStatus', title: 'Project Targeted Status' },
    { name: 'region', title: 'Region' },
    { name: 'scopeOfContract', title: 'Scope Of Contract' },
  ]);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };

  const customerSearchTable = (event: any, value: any) => {
    // console.log(event._reactName);
    setValueSearch(value);
    // let valueIs: any = '';
    // if (event._reactName === 'onClick') {
    //   valueIs = value;
    //   setLoader(true);
    //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
    //   finalAPI(valueIs);
    //   setDisplayHere(false);
    // }
    // if (event._reactName === 'onKeyUp') {
    //   valueIs = event.target.value;
    //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
    //   // searchForDropDown(valueIs);
    //   setDisplayHere(false);
    // }
  };

  const buttonAnalyze = () => {
    setErrorDisplay(false);
    setLoader(true);
    setDisplayHere(false);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    finalAPI(valueSearch);
  };

  useEffect(() => {
    customerNames('test')
      .then((res) => {
        // console.log(res);
        if (res?.length > 0) {
          const arr: any = [];
          // eslint-disable-next-line array-callback-return
          res.map((val: any) => {
            arr.push({ label: val, value: val });
          });
          setTestDataHere(arr);
        } else {
          setTestDataHere([]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // console.log('search api called');
      });

    cssFilters('sample')
      .then((res) => {
        setInitialFilter({
          cluster: res?.filters?.cluster || [],
          country: res?.filters?.country || [],
          currentPhase: res?.filters?.currentPhase || [],
          leadingBusiness: res?.filters?.leadingBusiness || [],
          region: res?.filters?.region || [],
          scope: res?.filters?.scope || [],
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // console.log('CSS API Hitted');
        setInitialDropDownLoader(false);
      });
  }, []);

  // eslint-disable-next-line consistent-return
  const renderScope = () => {
    if (initialFilter?.cluster.length) {
      return <b style={{ color: '#002A45' }}>Scope</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const renderCluster = () => {
    if (initialFilter?.cluster.length) {
      return <b style={{ color: '#002A45' }}>Cluster</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const renderRegion = () => {
    if (initialFilter?.region.length) {
      return <b style={{ color: '#002A45' }}>Region</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const renderCountry = () => {
    if (initialFilter?.country.length) {
      return <b style={{ color: '#002A45' }}>Country</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const renderCUrrentPhase = () => {
    if (initialFilter?.currentPhase.length) {
      return <b style={{ color: '#002A45' }}>Current Phase</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const renderLeadingBusiness = () => {
    if (initialFilter?.leadingBusiness.length) {
      return <b style={{ color: '#002A45' }}>Leading Business</b>;
    }
  };

  const onChangeScope = (option: any) => {
    setSelectedFilterScope(option);
  };
  const onChangeCluster = (option: any) => {
    setSelectedFilterCluster(option);
  };
  const onChangeCountry = (option: any) => {
    setSelectedFilterCountry(option);
  };
  const onChangeCurrentPhase = (option: any) => {
    setSelectedFilterCurrentPhase(option);
  };
  const onChangeLeadingBusiness = (option: any) => {
    setSelectedFilterLeadingBusiness(option);
  };
  const onChangeRegion = (option: any) => {
    setSelectedFilterRegion(option);
  };

  const getValues = (val: any) => {
    const values = [];
    if (val.length > 0) {
      // eslint-disable-next-line array-callback-return
      val.map((v: any) => {
        values.push(v.value);
      });
    } else {
      values.push('*');
    }
    return values;
  };

  const finalAPI = (valueIs: any) => {
    const body = {
      customerName: valueIs.trim(),
      leadingBusiness: getValues(selectedFilterLeadingBusiness),
      cluster: getValues(selectedFilterCluster),
      region: getValues(selectedFilterRegion),
      country: getValues(selectedFilterCountry),
      scope: getValues(selectedFilterScope),
      currentPhase: getValues(selectedFilterCurrentPhase),
    };
    // console.log(body);
    customerSearch(body)
      .then((res) => {
        setSurveyNo(res?.numberOfSurveys);
        if (res.surveys.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          handleSetRowsColumnsdataset(res.surveys);
          setDisplayHere(true);
        } else {
          setDisplayHere(false);
        }
        setPayload(res);
      })
      .catch((err) => {
        console.log(err);
        setErrorDisplay(true);
      })
      .finally(() => {
        setLoader(false);
        // console.log('final api called');
      });
  };

  const tableHeaderComponents: React.FunctionComponent<Table.RowProps> = (props) => {
    return (
      <Table.TableHead
        {...props}
        style={{
          position: 'unset',
          top: 0,
          zIndex: 400,
        }}
      />
    );
  };
  // table header
  const TableComponentBase = ({ classes, ...restProps }: any) => (
    <Table.Table {...restProps} className={classes.tableStriped} />
  );

  const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

  const handleChangeToggleButton = () => {
    setToggleButtonHere(!toggleButtonHere);
  };

  const [dis, setDis] = useState(true);
  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
    setCurrentPage2(rel);
  };

  const handleSetRowsColumnsdataset = async (myRows: {}[]) => {
    const myCols = Object.keys(myRows[0]).map((key) => ({
      name: key,
      title: key.toUpperCase(),
    })) as Column[];
    const resizeColumns = myCols.map((c, i) => {
      return {
        columnName: c.name,
        width: Math.ceil(1750 / myCols.length),
      };
    });
    setColumnWidths(resizeColumns);
    setColumns(myCols);
    setRows(myRows);
  };

  const clickHandler = (e: any) => {
    if (e.target.checked === true) {
      setSelectedDisplay('');
    } else {
      setSelectedDisplay('none');
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* <Grid container spacing={1}>
        <Grid item xs={12}>
          <h1>
            <center>CSS</center>
          </h1>
        </Grid>
      </Grid> */}
      <Paper elevation={3} style={{ padding: '10px', marginTop: -20 }}>
        <br />
        <Grid container>
          <Grid item xs={11}>
            <div>
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                style={{ borderRadius: '50px', width: '99%' }}
                options={testDataHere.map((option) => option.label)}
                onChange={(event: any, value: any) => customerSearchTable(event, value)}
                // onKeyUpCapture={(event: any, value: any) => customerSearchTable(event, value)}
                onKeyPress={buttonAnalyze}
                renderInput={(params, i = 0) => (
                  <TextField
                    {...params}
                    id={i + 1}
                    label="Search by customer name or project or ct code"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '50px',

                        legend: {
                          marginLeft: '30px',
                        },
                      },
                      '& .MuiAutocomplete-inputRoot': {
                        paddingLeft: '20px !important',
                        borderRadius: '50px',
                      },
                      '& .MuiInputLabel-outlined': {
                        paddingLeft: '20px',
                      },
                      '& .MuiInputLabel-shrink': {
                        marginLeft: '20px',
                        paddingLeft: '10px',
                        paddingRight: 0,
                        background: 'white',
                      },
                    }}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={1}>
            <Button
              startIcon={<SearchTwoToneIcon />}
              variant="contained"
              style={{
                float: 'right',
                marginTop: '5px',
                backgroundColor: '#002a45',
                color: 'white',
                padding: 10,
                width: '100%',
              }}
              onClick={buttonAnalyze}
              disabled={!(valueSearch?.length > 2)}
            >
              search
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={0} style={{ marginTop: 10 }}>
          <Grid item xs={2}>
            <FormControl variant="outlined" className={classes.formControlMulti}>
              <MultiSelect
                hasSelectAll={true}
                options={initialFilter.scope.map((a: any) => {
                  return { label: a, value: a };
                })}
                value={selectedFilterScope}
                onChange={onChangeScope}
                labelledBy="Scope"
                isLoading={initialDropDownLoader}
                valueRenderer={renderScope}
                className={classes.MultiSelect}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" className={classes.formControlMulti}>
              <MultiSelect
                hasSelectAll={true}
                options={initialFilter.cluster.map((a: any) => {
                  return { label: a, value: a };
                })}
                value={selectedFilterCluster}
                onChange={onChangeCluster}
                labelledBy="Cluster"
                isLoading={initialDropDownLoader}
                valueRenderer={renderCluster}
                className={classes.MultiSelect}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" className={classes.formControlMulti}>
              <MultiSelect
                hasSelectAll={true}
                options={initialFilter.region.map((a: any) => {
                  return { label: a, value: a };
                })}
                value={selectedFilterRegion}
                onChange={onChangeRegion}
                labelledBy="region"
                isLoading={initialDropDownLoader}
                valueRenderer={renderRegion}
                className={classes.MultiSelect}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" className={classes.formControlMulti}>
              <MultiSelect
                hasSelectAll={true}
                options={initialFilter.country.map((a: any) => {
                  return { label: a, value: a };
                })}
                value={selectedFilterCountry}
                onChange={onChangeCountry}
                labelledBy="country"
                isLoading={initialDropDownLoader}
                valueRenderer={renderCountry}
                className={classes.MultiSelect}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" className={classes.formControlMulti}>
              <MultiSelect
                hasSelectAll={true}
                options={initialFilter.leadingBusiness.map((a: any) => {
                  return { label: a, value: a };
                })}
                value={selectedFilterLeadingBusiness}
                onChange={onChangeLeadingBusiness}
                labelledBy="leadingBusiness"
                isLoading={initialDropDownLoader}
                valueRenderer={renderLeadingBusiness}
                className={classes.MultiSelect}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" className={classes.formControlMulti}>
              <MultiSelect
                hasSelectAll={true}
                options={initialFilter.currentPhase.map((a: any) => {
                  return { label: a, value: a };
                })}
                value={selectedFilterCurrentPhase}
                onChange={onChangeCurrentPhase}
                labelledBy="currentPhase"
                isLoading={initialDropDownLoader}
                valueRenderer={renderCUrrentPhase}
                className={classes.MultiSelect}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={0} style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox />}
              label="Display selected filters"
              onClick={(e) => clickHandler(e)}
              style={{ marginTop: -10, marginLeft: 'auto' }}
            />
            {/* <Button
            variant="contained"
            style={{ float: 'right', marginRight: '10px' }}
            onClick={buttonAnalyze}
            disabled={!(valueSearch?.length > 2)}
          >
            Analyze
          </Button> */}

            <IconButton
              aria-labelledby="clear"
              aria-label="clear"
              style={{ float: 'right', marginRight: '10px', marginTop: -8 }}
              onClick={() => {
                setSelectedFilterCountry([]);
                setSelectedFilterCurrentPhase([]);
                setSelectedFilterCluster([]);
                setSelectedFilterLeadingBusiness([]);
                setSelectedFilterScope([]);
                setSelectedFilterRegion([]);
              }}
            >
              <CancelScheduleSendIcon />
              <span style={{ fontSize: '15px' }}>&nbsp;Clear filters</span>
            </IconButton>
          </Grid>
          <Grid item xs={12} style={{ display: selectedDisplay, marginTop: 0 }}>
            <div elevation={0} style={{ width: '90%', padding: '10px' }}>
              <span>
                <b>Scope: </b>
                {selectedFilterScope.length > 0 &&
                  selectedFilterScope?.map((a: any) => {
                    return `${a.value},`;
                  })}
              </span>
              <br />
              <span>
                <b>Cluster: </b>
                {selectedFilterCluster.length > 0 &&
                  selectedFilterCluster?.map((a: any) => {
                    return `${a.value},`;
                  })}
              </span>
              <br />
              <span>
                <b>Region: </b>
                {selectedFilterRegion.length > 0 &&
                  selectedFilterRegion?.map((a: any) => {
                    return `${a.value},`;
                  })}
              </span>
              <br />
              <span>
                <b>Country: </b>
                {selectedFilterCountry.length > 0 &&
                  selectedFilterCountry?.map((a: any) => {
                    return `${a.value},`;
                  })}
              </span>
              <br />
              <span>
                <b>Leading Business: </b>
                {selectedFilterLeadingBusiness.length > 0 &&
                  selectedFilterLeadingBusiness?.map((a: any) => {
                    return `${a.value},`;
                  })}
              </span>
              <br />
              <span>
                <b>Current Phase: </b>
                {selectedFilterCurrentPhase.length > 0 &&
                  selectedFilterCurrentPhase?.map((a: any) => {
                    return `${a.value},`;
                  })}
              </span>
            </div>
          </Grid>
        </Grid>
      </Paper>
      {loader && <Loading />}
      {surveyNo === 0 && <h2 style={{ color: 'red' }}>No results found!!!</h2>}
      {errorDisplay && <h4 style={{ color: 'red' }}>API Error!!!</h4>}
      {displayHere && (
        <div style={{ marginTop: 5 }}>
          <div style={{ marginTop: 5 }}>
            <span style={{ fontSize: 20 }}>
              &nbsp;&nbsp;
              <b>{surveyNo}</b> surveys found
            </span>
          </div>
          <ExpressGrid rows={rows} columns={columnsMain}>
            <DataTypeProvider
              for={[
                'customerName',
                'scopeOfContract',
                'cluster',
                'region',
                'project',
                'leadingBusiness',
                'projectTargetedStatus',
                'currentPhaseOfProjectExecution',
                'customerResponseDate',
              ]}
              formatterComponent={({ value }) => (
                <span
                  title={value}
                  style={{
                    whiteSpace: `${newLine}`,
                    // fontWeight: 'bold',
                    color: '#002A45',
                  }}
                >
                  {value}
                </span>
              )}
            />
            <FilteringState defaultFilters={[{ columnName: 'customerName', value: '' }]} />
            <IntegratedFiltering />
            <SortingState />
            <IntegratedSorting />
            <PagingState
              currentPage={currentPage2}
              onCurrentPageChange={setCurrentPage2}
              pageSize={pageSize2}
              onPageSizeChange={setPageSize2}
            />
            <IntegratedPaging />
            <Table
              tableComponent={MultiAllocTableComp}
              containerComponent={tableAllocatorComponent}
              headComponent={tableHeaderComponents}
            />
            <TableColumnResizing
              defaultColumnWidths={[
                ...columnsMain.map((c) => {
                  return {
                    columnName: c.name,
                    width: Math.ceil(2400 / columnsMain.length),
                    wordWrapEnabled: toggleHeight,
                  };
                }),
              ]}
            />
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={setHiddenColumnNames}
            />
            <Toolbar rootComponent={toolbarComponent} />
            <ToolBarPlugin name="Download" onClick={startExport} />
            <ToolBarPlugin
              name="Height"
              title="Wrap Text"
              onClick={() => {
                setToggleHeight(!toggleHeight);
                setNewLine('');
              }}
            />
            <ToolBarPlugin
              name="NewLine"
              onClick={() => (newLine !== 'pre-line' ? setNewLine('pre-line') : setNewLine(''))}
            />
            <PagingPanel
              pageSizes={pageSizes}
              containerComponent={(props) => (
                <>
                  <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
                </>
              )}
            />
          </ExpressGrid>
          <GridExporter ref={exporterRef} rows={rows} columns={columnsMain} onSave={onSave} />
          <NavigationGotoNew
            setPageSize={setPageSize2}
            fileLength={rows?.length}
            pageSize={pageSizes}
            dis={dis}
            setTemPage={setTemPage}
            handlePageNum={handlePageNum}
            currentPage={currentPage2}
            setCurrentPage={setCurrentPage2}
          />
        </div>
      )}
      {displayHere && <ScrollableTabsButtonAuto data={payload} />}
    </Box>
  );
}
