import { AllocationData, LifeCycleData, Owning } from 'OwningType';
import axios from './axios';
import { BACKEND_URL } from '../const';

const getOwning = async (
  project_id: number,
  workload_id: number,
  filename: any = undefined,
  file_id: any = undefined,
  join_lifecycle: any = false,
  join_allocation: any = false,
  join_analysis: any = false,
  row_type: string | undefined = undefined
): Promise<Owning[]> => {
  try {
    let querystring = `project_id=${project_id}&workload_id=${workload_id}`;
    querystring = filename ? `${querystring}&filename=${filename}` : querystring;
    querystring = file_id ? `${querystring}&file_id=${file_id}` : querystring;
    querystring = join_lifecycle ? `${querystring}&join_lifecycle=true` : querystring;
    querystring = join_allocation ? `${querystring}&join_allocation=true` : querystring;
    querystring = join_analysis ? `${querystring}&join_analysis=true` : querystring;
    querystring = row_type ? `${querystring}&row_type=${row_type}` : querystring;
    const response = await axios.get(`${BACKEND_URL.root}/ownings?${querystring}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const deleteOwning = async (
  rows: any[],
  project_id: number,
  workload_id: number,
  filename: any = undefined,
  file_id: any = undefined
): Promise<any[]> => {
  try {
    let querystring = `project_id=${project_id}&workload_id=${workload_id}`;
    querystring = filename ? `${querystring}&filename=${filename}` : querystring;
    querystring = file_id ? `${querystring}&file_id=${file_id}` : querystring;
    const response = await axios.put(`${BACKEND_URL.root}/ownings/delete?${querystring}`, rows);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const deleteAssignOwning = async (
  rows: any[],
  project_id: number,
  workload_id: number,
  filename: any = undefined,
  file_id: any = undefined
): Promise<any[]> => {
  try {
    let querystring = `project_id=${project_id}&workload_id=${workload_id}`;
    querystring = filename ? `${querystring}&filename=${filename}` : querystring;
    querystring = file_id ? `${querystring}&file_id=${file_id}` : querystring;
    const response = await axios.put(
      `${BACKEND_URL.root}/ownings/assigned_delete?${querystring}`,
      rows
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

type OptionSave = {
  withNotifs?: boolean;
};

const saveOwning = async (
  rows: any[],
  row_type = 'capture',
  project_id: number,
  workload_id: number,
  language_code: string | undefined = undefined,
  column_name = 'Text',
  filename: any = undefined,
  file_id: any = undefined,
  options: OptionSave = {}
): Promise<Owning[]> => {
  try {
    let querystring = `project_id=${project_id}&workload_id=${workload_id}&row_type=${row_type}`;
    querystring = `${querystring}&language_code=${language_code}`;
    querystring = `${querystring}&column_name=${column_name}`;
    querystring = filename ? `${querystring}&filename=${filename}` : querystring;
    querystring = file_id ? `${querystring}&file_id=${file_id}` : querystring;
    querystring = options?.withNotifs ? `${querystring}&with_notifs=true` : querystring;
    const rowsOwnings = rows.map((r) => ({
      ...r,
      lifecycles: undefined,
      analysis: undefined,
    }));
    const response = await axios.put(
      `${BACKEND_URL.root}/ownings/save?${querystring}`,
      rowsOwnings
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const saveOwning2 = async (
  rows: any[],
  row_type = 'capture',
  project_id: number,
  workload_id: number,
  filename: any = undefined,
  language_codes: any = undefined,
  column_name = 'Text'
): Promise<Owning[]> => {
  try {
    let querystring = `project_id=${project_id}&workload_id=${workload_id}&row_type=${row_type}`;
    querystring = filename ? `${querystring}&filename=${filename}` : querystring;
    querystring = language_codes ? `${querystring}&language_code=${language_codes}` : querystring;
    querystring = `${querystring}&column_name=${column_name}`;
    // querystring = options?.withNotifs ? `${querystring}&with_notifs=true` : querystring;
    const rowsOwnings = rows.map((r) => ({
      ...r,
      lifecycles: undefined,
      analysis: undefined,
    }));
    const response = await axios.put(
      `${BACKEND_URL.root}/ownings/save?${querystring}`,
      rowsOwnings
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const saveAllocations = async (
  rows: AllocationData[],
  project_id: number,
  workload_id: number,
  filename: any = undefined,
  file_id: any = undefined,
  options: OptionSave = {}
): Promise<Owning> => {
  try {
    let querystring = `project_id=${project_id}&workload_id=${workload_id}`;
    querystring = filename ? `${querystring}&filename=${filename}` : querystring;
    querystring = file_id ? `${querystring}&file_id=${file_id}` : querystring;
    querystring = options?.withNotifs ? `${querystring}&with_notifs=true` : querystring;
    const rowsOwnings = rows.map((r) => ({
      ...r,
      lifecycles: undefined,
      analysis: undefined,
    }));
    const response = await axios.put(`${BACKEND_URL.root}/allocations?${querystring}`, rowsOwnings);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const createBulkOwning = async (body: LifeCycleData[]): Promise<{ output: any }> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/owning_lifecycle`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getRBSLabels = async (): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m1-breakdowns-rbs-labels`,
      {}
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export {
  getOwning,
  saveOwning2,
  saveOwning,
  deleteOwning,
  deleteAssignOwning,
  createBulkOwning,
  saveAllocations,
  getRBSLabels,
};
