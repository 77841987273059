import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { getFunctions } from '../../../../api/iverifier';
import Loading from '../../../../components/Core/Loading';
import TableComponent from '../../../../components/Datagrid/TableComponent';
import ToolBarPlugin from '../../../../components/Datagrid/ToolBarPlugin';
import {
  tableContainerComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../../components/Datagrid/TStyles';
import { IS_DEBUG_MODE } from '../../../../const';
import { handleColumns } from '../../../../tools/datagrid';
import formatDate, { formatLocal } from '../../../../tools/formatDate';
import Run3Context from '../../RunContext';

const columns = handleColumns(['id', 'function', 'mapping', 'example', 'created_at', 'updated_at']);

type Props = {
  selected?: any[];
  data?: any[];
};

function StepFunctions({ data, selected: selectedDefault }: Props) {
  const { projectId } = useParams() as any;
  const { config, runId, setConfig } = useContext(Run3Context);
  const [rows, setRows] = useState<any>(data ?? []);
  const [loading, setLoading] = useState(true);
  const [selection, setSelection] = useState<any>(selectedDefault ?? []);
  const [toggleHeight, setToggleHeight] = useState(false);

  useEffect(() => {
    getFunctions(projectId).then((response) => {
      setRows(response);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectionChange = (selectionRows: any) => {
    setSelection(selectionRows);
    setConfig({ ...config, function_ids: selectionRows });
  };

  const renderFunction = ({ value }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <>
        <b>{value}</b>
      </>
    );
  };

  if (loading) return <Loading />;
  return (
    <div>
      <>
        {IS_DEBUG_MODE && (
          <ReactJson
            src={{ selection, selectedDefault, config, rows }}
            collapsed={true}
            theme="monokai"
          />
        )}
      </>
      <ExpressGrid rows={rows} columns={columns} getRowId={(r) => r.id}>
        <DataTypeProvider for={['function']} formatterComponent={renderFunction} />
        <DataTypeProvider
          for={['created_at', 'updated_at']}
          formatterComponent={({ value }) => <>{formatDate(value, formatLocal())}</>}
        />
        {/* selection */}
        <SelectionState selection={selection} onSelectionChange={handleSelectionChange} />
        <IntegratedSelection />
        {/* filtering */}
        <FilteringState />
        <SortingState />
        <IntegratedSorting />
        <IntegratedFiltering />
        <PagingState defaultCurrentPage={0} defaultPageSize={10} />
        <IntegratedPaging />
        <Table
          columnExtensions={columns.map((c) => ({
            columnName: c.name,
            wordWrapEnabled: true,
          }))}
          tableComponent={TableComponent}
          containerComponent={tableContainerComponent}
          headComponent={tableHeaderComponent}
        />
        <TableColumnResizing
          defaultColumnWidths={[
            ...columns.map((c) => {
              if (c.name === 'id') return { columnName: c.name, width: 80 };
              if (c.name === 'mapping') return { columnName: c.name, width: 400 };
              if (c.name === 'created_at') return { columnName: c.name, width: 150 };
              if (c.name === 'updated_at') return { columnName: c.name, width: 150 };
              return {
                columnName: c.name,
                width: Math.ceil(1400 / (columns.length - 4)),
              };
            }),
          ]}
        />
        <TableHeaderRow showSortingControls />
        <TableFilterRow />
        <TableColumnVisibility defaultHiddenColumnNames={[]} />
        <Toolbar rootComponent={toolbarComponent} />
        {/* selection */}
        <TableSelection showSelectAll />
        <ColumnChooser />
        <ToolBarPlugin
          name="Height"
          title="Wrap Text"
          onClick={() => {
            setToggleHeight(!toggleHeight);
          }}
        />
        <PagingPanel pageSizes={[10, 20, 50]} />
      </ExpressGrid>
    </div>
  );
}

export default StepFunctions;
