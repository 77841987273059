/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, ClickAwayListener, Typography, Button, IconButton, Tooltip } from '@material-ui/core';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ClearIcon from '@mui/icons-material/Clear';
import Module2Context from '../../../../Module2/Module2Context';
import M2GlobalContext from '../../../../ContextMager/M2GlobalContext';
import useAppToken from '../../../../../app/useAppToken';

const HistoryCard = ({ data, selectedHis, setSelectHis, label }: any) => {
  const { sourceText, result, sourceLang, distLang, inDB, id } = data;
  const { historyTranslation, setHistoryTranslation } = React.useContext(Module2Context);
  // const { textTranslationHistory, setTextTranslationHistory } = useContext(ProjectContext);
  const { state, dispatch } = React.useContext(M2GlobalContext);
  const { projectId } = useParams() as any;
  const { appJWT }: { appJWT: AppJwtType } = useAppToken();

  // function for remove data from DB
  const handleRemove = (e) => {
    e.stopPropagation();
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    if (label === 'History') {
      const temp = historyTranslation.filter((data) => data.id !== id);
      setHistoryTranslation(temp);
      const body = {
        user_id: Number(appJWT?.user_id),
        project_id: Number(projectId),
        translated_data: temp,
        created_at: formattedDate,
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      updataHistoryTranslation(body).then((data) => {});
    } else {
      // const temp = textTranslationHistory.filter((data) => data.id !== id);
      // setTextTranslationHistory(temp);
      dispatch({ type: 'remove_recent_history', payload: id });
    }
  };

  return (
    <>
      <Box
        className={`textTrans-history-container${selectedHis === id ? '-selected' : ''}`}
        onClick={() => {
          setSelectHis(id);
          const obj = {
            text: sourceText,
            destText: result,
            sourceLanguage: sourceLang,
            destinationLanguage: distLang,
          };
          dispatch({ type: 'click_card_update', payload: obj });
        }}
      >
        <Box className="textTrans-history-container-rowone">
          <div className="Card-label">
            {`${sourceLang?.split('-')?.slice(0, 1)} > ${distLang?.split('-')?.slice(0, 1)}`}
          </div>
          <Tooltip title="remove">
            <IconButton onClick={handleRemove} style={{ padding: 0 }}>
              {/* {checkState ? <StarIcon /> : <StarBorderIcon />} */}
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="textTrans-history-container-rowsecond">{sourceText?.slice(0, 50)}...</Box>
        <Box className="textTrans-history-container-rowthird">{result?.slice(0, 50)}...</Box>
      </Box>
    </>
  );
};

export default HistoryCard;
